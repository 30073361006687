import { Button, ButtonBadge, Duration, Icon } from '~/components';
import { useApi, useSubscription, useTimeEntries, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import CalendarPage from './CalendarPage';
import { CalendarStats } from './stats';
import { useTimesheets } from './timesheets/TimesheetContext';
import TimesheetSubmittedTooltip from './timesheets/TimesheetSubmittedTooltip';

// isToday is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledLink = ({ isHoliday, isToday, ...props }) => <Link {...props} />;

const Month = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 2rem;
  margin-bottom: auto;
  background-color: ${colors.grey10};
  border: solid 1px ${colors.grey10};
`;

const DayHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: -1px;
  margin-bottom: 0;
  padding-bottom: 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-color: ${colors.white};
`;

const DayWrapper = styled.div`
  position: relative;
  height: 6rem;
`;

const DayOutsideMonth = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.625rem;
  border: solid 1px transparent;
  background-color: ${colors.grey5};
`;

const DayTop = styled.span`
  display: flex;
  padding: 0.625rem;
  transition: padding 0.2s ease-in-out;
`;

const DayIcons = styled.span`
  display: flex;
  font-size: 0.5rem;
`;

const DayNumber = styled.span`
  margin-left: auto;
  margin-top: -0.25rem;
  font-size: 0.75rem;
`;

const DayHours = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ isTimesheetSubmitted }) => (isTimesheetSubmitted ? colors.grey25 : colors.black)};
  font-size: 1.25rem;
  text-align: center;
`;

const DayHoliday = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1.25rem;
  margin-top: auto;
  background: repeating-linear-gradient(
    -45deg,
    ${colors.primary10},
    ${colors.primary10} 5px,
    ${colors.white} 5px,
    ${colors.white} 10px
  );
  border-top: solid 1px ${colors.primary25};
  transition: height 0.2s ease-in-out;
`;

const HolidayName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  margin-top: -0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0 0.75rem;
  font-size: 0.75rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.primary25};
  border-radius: 999rem;
  overflow: hidden;
  transition: margin 0.2s ease-in-out;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DayLink = styled(StyledLink)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: ${({ isToday }) => (isToday ? colors.primary : colors.black)};
  background-color: ${colors.white};
  border: solid 1px ${({ isToday }) => (isToday ? colors.primary : 'transparent')};
  transition: all 0.2s ease-in-out;

  &:hover {
    top: -0.1875rem;
    left: -0.1875rem;
    right: -0.1875rem;
    bottom: -0.1875rem;
    color: ${({ isToday }) => (isToday ? colors.primary : colors.black)};
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
    z-index: 1;

    ${DayTop} {
      padding: 0.8125rem;
    }

    ${DayHoliday} {
      height: 1.4375rem;
    }

    ${HolidayName} {
      margin-left: 0.4375rem;
      margin-right: 0.4375rem;
    }
  }
`;

function Day({ date, day, holidays }) {
  const { workspace } = useWorkspace();
  const now = moment();
  const dayDate = moment(day.date);
  const isMonth = dayDate.isSame(date, 'month');
  const isToday = dayDate.isSame(now, 'day');
  const dayOccurred = dayDate.isSameOrBefore(now, 'day');
  const dayNumber = dayDate.format('D');

  const dayHolidays = useMemo(() => {
    return _.filter(holidays, (holiday) => dayDate.isSame(holiday.date, 'day'));
  }, [holidays, dayDate]);

  const isHoliday = useMemo(() => dayHolidays.length > 0, [dayHolidays]);

  const statusColor = useMemo(() => {
    const hasRejected = !!_.find(day.entries, (entry) => entry.status?.id === 'rejected');

    const hasUnsbumittted = !!_.find(day.entries, (entry) => entry.status?.id === 'not_submitted');

    const hasPendingApproval = !!_.find(day.entries, (entry) => entry.status?.id === 'pending_approval');

    if (hasRejected) {
      return colors.danger;
    } else if (hasUnsbumittted) {
      return colors.black;
    } else if (hasPendingApproval) {
      return colors.warning;
    } else if (day.entries.length > 0) {
      return colors.primary;
    } else {
      return null;
    }
  }, [day]);

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () => timesheetContext.isTimesheetSubmitted({ start: day.date, end: day.date }),
    [day.date, timesheetContext],
  );

  return (
    <DayWrapper>
      {isMonth ? (
        <DayLink to={`/app/${workspace.key}/time/day?date=${day.date}`} isToday={isToday}>
          <DayTop>
            <DayIcons style={{ color: statusColor }}>{statusColor && <Icon icon="circle" />}</DayIcons>
            <DayNumber>{dayNumber}</DayNumber>
          </DayTop>
          <DayHours isTimesheetSubmitted={isTimesheetSubmitted}>
            {(day.minutes > 0 || day.timerStartedAt || dayOccurred || isTimesheetSubmitted) && (
              <Duration minutes={day.minutes} timerStartedAt={day.timerStartedAt} />
            )}
          </DayHours>
          {isHoliday && (
            <DayHoliday>
              <HolidayName>
                <span>
                  {_(dayHolidays)
                    .sortBy(['name'])
                    .map((holiday) => holiday.name)
                    .value()
                    .join(', ')}
                </span>
              </HolidayName>
            </DayHoliday>
          )}

          {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
        </DayLink>
      ) : (
        <DayOutsideMonth>
          <div />
          <DayNumber>{dayNumber}</DayNumber>
        </DayOutsideMonth>
      )}
    </DayWrapper>
  );
}

function MonthCalendar({ view, date, setDate, member, memberTargets, setMember, getHolidays }) {
  useDocumentTitle('Month Calendar');

  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const { entries, updateEntries } = useTimeEntries();
  const [isReady, setIsReady] = useState(false);
  const [submittingEntries, setSubmittingEntries] = useState(false);
  const { notify } = useSubscription();

  const fetchData = useCallback(async () => {
    const query = { date };
    if (member) {
      query.memberId = member.id;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getMonth(query);

      updateEntries(data);
    } finally {
      setIsReady(true);
    }
  }, [api, workspace, member, date, updateEntries]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memberId = useMemo(() => (member ? member.id : workspace.member.id), [member, workspace]);

  const holidays = useMemo(() => {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');
    return getHolidays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
  }, [getHolidays, date]);

  const viewEntries = useMemo(
    () => _.filter(entries, (entry) => entry.memberId === memberId && moment(entry.date).isSame(date, 'month')),
    [date, memberId, entries],
  );

  const days = useMemo(() => {
    const start = moment(date).startOf('month').startOf('isoWeek').startOf('day');

    const end = moment(date).endOf('month').endOf('isoWeek').endOf('day');

    const days = Math.round(moment.duration(end.diff(start)).asDays());

    start.subtract(1, 'days');

    return _.times(days, () => {
      const date = start.add(1, 'days').format('YYYY-MM-DD');
      const dateEntries = _.filter(viewEntries, { date });
      const minutes = _.sumBy(dateEntries, 'minutes');
      const timerStartedAt = _.find(dateEntries, (entry) => !!entry.timerStartedAt)?.timerStartedAt;
      return { date, entries: dateEntries, minutes, timerStartedAt };
    });
  }, [date, viewEntries]);

  const unsubmittedEntryCount = useMemo(
    () =>
      _.filter(
        viewEntries,
        (entry) => !entry.timerStartedAt && (entry.status?.id === 'not_submitted' || entry.status?.id === 'rejected'),
      ).length,
    [viewEntries],
  );

  const handleSubmitEntries = async () => {
    setSubmittingEntries(true);

    await api.www.workspaces(workspace.id).timeEntries().submitMonth({ date, memberId: member?.id });

    await fetchData();

    setSubmittingEntries(false);

    notify(useSubscription.keys.refresh_time_approval_count);

    toast.success('Time entries have been submitted for approval.');
  };

  const timesheetContext = useTimesheets();

  const pageActions = timesheetContext.useTimesheets
    ? null // Timesheets can't be submitted on the month view
    : unsubmittedEntryCount > 0 && (
        <Button isLoading={submittingEntries} onClick={handleSubmitEntries}>
          Submit for Approval <ButtonBadge visible={!submittingEntries}>{unsubmittedEntryCount}</ButtonBadge>
        </Button>
      );

  return (
    <CalendarPage actions={pageActions} view={view} date={date} setDate={setDate} member={member} setMember={setMember}>
      {isReady ? (
        <>
          <Month>
            <DayHeader>Mon</DayHeader>
            <DayHeader>Tue</DayHeader>
            <DayHeader>Wed</DayHeader>
            <DayHeader>Thu</DayHeader>
            <DayHeader>Fri</DayHeader>
            <DayHeader>Sat</DayHeader>
            <DayHeader>Sun</DayHeader>
            {days.map((day) => (
              <Day key={day.date} date={date} day={day} holidays={holidays} />
            ))}
          </Month>
          <CalendarStats
            entries={viewEntries}
            startDate={moment(date).startOf('month').format('YYYY-MM-DD')}
            endDate={moment(date).endOf('month').format('YYYY-MM-DD')}
            member={member}
            memberTargets={memberTargets}
          />
        </>
      ) : (
        <PageLoader />
      )}
    </CalendarPage>
  );
}

export default MonthCalendar;
