import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function CaptureCredentials({ partnerUserId, partnerUserSecret, onClose, onNext }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = {
    partnerUserId,
    partnerUserSecret,
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      const { data: policies } = await api.www.workspaces(workspace.id).expensify.getPolicies(values);
      onNext({ ...values, policies });
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        partnerUserId: Yup.string().label('partnerUserID').max(255).required(),
        partnerUserSecret: Yup.string().label('partnerUserSecret').max(255).required(),
      })}>
      {() => {
        return (
          <Form>
            <ModalCard.Body>
              <Description>
                To setup an integration with Expensify, add your <code>partnerUserId</code> and{' '}
                <code>partnerUserSecret</code>.{' '}
                <a href="https://www.expensify.com/tools/integrations/" target="_blank" rel="noopener noreferrer">
                  Click here
                </a>{' '}
                to view or generate your Expensify credentials.
              </Description>
              <Form.Control>
                <Field.Text name="partnerUserId" placeholder="partnerUserID" maxLength={255} />
              </Form.Control>
              <Form.Control>
                <Field.Text name="partnerUserSecret" placeholder="partnerUserSecret" maxLength={255} />
              </Form.Control>
              {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                  Close
                </CancelButton>
                <Button type="submit" isLoading={isLoading}>
                  Next
                </Button>
              </Buttons>
            </ModalCard.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

function SelectPolicy({ policies, partnerUserId, partnerUserSecret, onBack, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = {
    policyId: '',
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      const body = { ...values, partnerUserId, partnerUserSecret };
      await api.www.workspaces(workspace.id).expensify.setup(body);

      onFinish();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        policyId: Yup.string().label('Expensify Policy').required(),
      })}>
      {() => {
        return (
          <Form>
            <ModalCard.Body>
              <Description>Please select the Expensify policy you wish to integrate with.</Description>
              <Description>
                Note: The supplied credentials must be tied to a user who is an admin of the desired policy.
              </Description>
              <Form.Control>
                <Field.SingleSelect name="policyId" placeholder="Expensify Policy">
                  {policies?.map((policy) => (
                    <option key={policy.id} value={policy.id}>
                      {policy.name}
                    </option>
                  ))}
                </Field.SingleSelect>
              </Form.Control>
              {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <Button isOutline={true} onClick={onBack} style={{ marginRight: 'auto' }}>
                  Back
                </Button>
                <Button type="submit" isLoading={isLoading}>
                  Finish Setup
                </Button>
              </Buttons>
            </ModalCard.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default function ExpensifySetup({ onClose, onFinish }) {
  const [partnerUserId, setPartnerUserId] = useState('');
  const [partnerUserSecret, setPartnerUserSecret] = useState('');
  const [policies, setPolicies] = useState(null);
  const [step, setStep] = useState('capture-credentials');

  const handleSetCredentials = ({ partnerUserId, partnerUserSecret, policies }) => {
    setPartnerUserId(partnerUserId);
    setPartnerUserSecret(partnerUserSecret);
    setPolicies(policies);
    setStep('select-policy');
  };

  return (
    <ModalCard title="Setup Expensify Integration" onClose={onClose}>
      {step === 'capture-credentials' && (
        <CaptureCredentials
          partnerUserId={partnerUserId}
          partnerUserSecret={partnerUserSecret}
          onClose={onClose}
          onNext={handleSetCredentials}
        />
      )}
      {step === 'select-policy' && (
        <SelectPolicy
          partnerUserId={partnerUserId}
          partnerUserSecret={partnerUserSecret}
          policies={policies}
          onBack={() => setStep('capture-credentials')}
          onFinish={onFinish}
        />
      )}
    </ModalCard>
  );
}
