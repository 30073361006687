import { Buttons, CancelButton, Drawer, Form, Icon } from '~/components';
import { useDocumentTitle } from '~/hooks';
import React from 'react';
import colors from '~/styles/colors.js';
import styled from 'styled-components';
import ReadForm from '~/components/read-only/ReadForm.jsx';
import ReadTextbox from '~/components/read-only/ReadTextbox.jsx';

const StandardMessage = styled.div`
  margin-bottom: 2.5rem;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: ${colors.grey5};
`;

const StandardMessageIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

export default function ViewDrawer({ pipelineActivityType, onClose }) {
  const title = 'View Pipeline Activity Type';
  useDocumentTitle(title);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Drawer isOpen title={title} byline="Custom Data" onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => closeDrawer();

        return (
          <ReadForm>
            <StandardMessage>
              <StandardMessageIcon icon="lock" color={colors.grey75} />
              This is a standard pipeline activity managed by Ruddr.
            </StandardMessage>

            <ReadForm.Section title="General">
              <Form.Control>
                <ReadTextbox label="Name" value={pipelineActivityType.name} />
              </Form.Control>
              <Form.Control>
                <ReadTextbox label="Type" value={pipelineActivityType.Type.name} />
              </Form.Control>
              <Form.Control>
                <ReadTextbox label="Description" value={pipelineActivityType.description} textarea />
              </Form.Control>
            </ReadForm.Section>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </ReadForm>
        );
      }}
    </Drawer>
  );
}
