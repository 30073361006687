import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';

const Div = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    color: ${colors.white};
    background-color: ${colors.primary50};
  }
`;

function PlaceholderAvatar({ size = 32, fontSize = `${size / 32}rem`, initialsFontSize = fontSize }) {
  return (
    <Div>
      <figure style={{ width: size, height: size, fontSize: initialsFontSize }}>
        <Icon icon="users" />
      </figure>
    </Div>
  );
}

export default PlaceholderAvatar;
