import { AutoComplete } from '~/components';
import Fuse from 'fuse.js';
import React from 'react';

function CreditNoteProjectSelect({ options = [], ...props }) {
  const handleSearch = (q) => {
    if (!q) {
      return options;
    }

    const fuse = new Fuse(options, { keys: ['name'], threshold: 0.4 });
    const results = fuse.search(q);
    return results.map((result) => result.item);
  };

  return (
    <AutoComplete
      materialPlaceholder="Project"
      placeholder="All"
      materialAlwaysVisible
      defaultOptions={options}
      renderOption={(option) => option.name}
      displayText={props.value?.name || ''}
      onSearch={handleSearch}
      {...props}
    />
  );
}

export default CreditNoteProjectSelect;
