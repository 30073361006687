import { FieldControl } from '~/components';
import { useField } from '~/hooks';
import React from 'react';
import InvoiceProjectSelect from './InvoiceProjectSelect';

export default function InvoiceProjectSelectField(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <InvoiceProjectSelect {...field} {...props} />
    </FieldControl>
  );
}
