import _ from 'lodash';
import React from 'react';
import { Icon } from '~/components';
import { colors } from '~/styles';
import {
  AccordionButton,
  Action,
  Actions,
  Box,
  Content,
  Empty,
  Group,
  Header,
  HeaderContent,
  Info,
  NoResultsLabel,
  Row,
  SidebarContainer,
  SidebarContent,
} from './SidebarStyles';

export default function Sidebar({
  groups,
  toggleGroup,
  toggleGroups,
  hasExpandedGroups,
  styles,
  rows,
  components: {
    sidebar: { Empty: EmptyContent, Group: GroupContent, Row: RowContent, HeaderLabel },
  },
}) {
  return (
    <SidebarContainer style={{ width: styles.sidebar.width }}>
      <SidebarContent>
        {rows.length === 0 && (
          <Empty>
            <Box>
              <NoResultsLabel>
                <EmptyContent />
              </NoResultsLabel>
            </Box>
          </Empty>
        )}

        {rows.map((row, index) => {
          if (_.isFunction(row.component)) {
            const Component = row.component;
            return <Component key={row.id} row={row} groups={groups} toggleGroup={toggleGroup} />;
          }

          switch (row.type) {
            case 'header':
              return (
                <Header key={row.id} style={row.meta.style}>
                  <HeaderContent>
                    <HeaderLabel row={row} />

                    <Actions>
                      {row.showExpandCollapse && (
                        <Action>
                          <AccordionButton
                            isAnchor
                            onClick={(event) => {
                              event.preventDefault();
                              toggleGroups();
                            }}>
                            <Icon
                              icon={hasExpandedGroups ? 'angle-double-up' : 'angle-double-down'}
                              color={colors.grey55}
                            />
                          </AccordionButton>
                        </Action>
                      )}
                    </Actions>
                  </HeaderContent>
                </Header>
              );

            case 'group': {
              const group = groups[row.id];

              // Groups return as undefined when the schedule is updating.
              // This shouldn't happen, may need to review the logic to set the expanded/collapsed status.
              if (!group) return null;

              const icon = group.state === 'expanded' ? 'angle-up' : 'angle-down';

              const handleGroupClick = (event) => {
                event.stopPropagation();
                toggleGroup({ id: row.id, ...group });
              };

              return (
                <Group
                  key={row.id}
                  style={row.meta.style}
                  data-testid={`row_${row.id}`}
                  data-testid-lanes={row.meta.lanes}
                  state={group.state}>
                  <Box>
                    <Content>
                      <GroupContent row={row} />
                    </Content>

                    <Actions>
                      {group.hasAllocations && (
                        <Action>
                          <AccordionButton isAnchor onClick={handleGroupClick} data-testid="group_accordion">
                            <Icon icon={icon} />
                          </AccordionButton>
                        </Action>
                      )}
                    </Actions>
                  </Box>
                </Group>
              );
            }

            case 'row': {
              const last = rows[index + 1]?.type !== 'row';

              return (
                <Row
                  key={row.id}
                  style={row.meta.style}
                  last={last}
                  data-testid={`row_${row.id}`}
                  data-testid-lanes={row.meta.lanes}>
                  <Box>
                    <Info>
                      <RowContent row={row} />
                    </Info>
                  </Box>
                </Row>
              );
            }
          }
        })}
      </SidebarContent>
    </SidebarContainer>
  );
}
