import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import { colors } from '~/styles';
import countryCodes from '~/lookups/sms-country-codes';
import SingleSelect from './SingleSelect';

const CodeWrapper = styled.span`
  color: ${colors.grey55};
`;

function ValueDisplay({ name, code }) {
  return (
    <>
      {name} <CodeWrapper>+{code}</CodeWrapper>
    </>
  );
}

function SMSCountryCodeSelect({ onChange, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return countryCodes;
    }

    const fuse = new Fuse(countryCodes, { keys: ['name', 'code'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [filterValue]);

  const selectedOption = useMemo(() => _.find(countryCodes, { iso: props.value }), [props.value]);

  const handleChange = (event) => {
    if (event.target.value) {
      const opt = _.find(countryCodes, { iso: event.target.value });
      if (opt) {
        event.target.code = opt.code;
      }
    }
    if (_.isFunction(onChange)) {
      onChange(event);
    }
  };

  return (
    <SingleSelect
      placeholder="Country Code"
      showFilter={true}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption ? <ValueDisplay {...selectedOption} /> : ''}
      onChange={handleChange}
      {...props}>
      {options.map(({ iso, name, code }) => (
        <option key={iso} value={iso}>
          <ValueDisplay name={name} code={code} />
        </option>
      ))}
    </SingleSelect>
  );
}

export default SMSCountryCodeSelect;
