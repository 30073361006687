import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { Tag, Tooltip } from '~/components';

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const ScheduleInfo = styled.span`
  margin-left: 0.5rem;
`;

export default function HolidaySchedules({ holiday }) {
  if (_.isEmpty(holiday.schedules)) return null;

  const schedule = holiday.schedules[0];

  const schedules = holiday.schedules;

  return (
    <>
      <ScheduleInfo>{schedule.name}</ScheduleInfo>

      {schedules.length > 1 && (
        <Tooltip
          placement="right"
          message={
            <div style={{ fontSize: '1rem' }}>
              <Title>Holiday Schedules</Title>

              {schedules.map((schedule) => (
                <Tag style={{ backgroundColor: colors.grey5 }} key={schedule.id}>
                  <small>{schedule.name}</small>
                </Tag>
              ))}
            </div>
          }>
          <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
            <small>+{schedules.length - 1}</small>
          </Tag>
        </Tooltip>
      )}
    </>
  );
}
