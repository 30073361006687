import { useCurrencyFormat } from '~/hooks';
import _ from 'lodash';

export default function Currency({ value, useDash = true, ...props }) {
  const formatter = useCurrencyFormat(props);

  if (!_.isNumber(value) || isNaN(value)) return useDash ? '-' : '';

  const amount = formatter.format(value);

  return amount;
}
