import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDirtyCheck, useForm } from '~/hooks';
import { useApi, useMember, useWorkspace } from '~/contexts';
import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage } from '~/components';
import { emptyStringToNull, mergeValues } from '~/utils';

export default function EditContact({ customer, onClose, onSaved }) {
  const api = useApi();
  const { member } = useMember();
  const { workspace } = useWorkspace();
  const formRef = useRef();
  const [{ status, message, isSubmitting }, form] = useForm();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const initialValues = mergeValues(
    {
      email: member.email || '',
      name: workspace.companyName || '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressPostalCode: '',
      addressCountry: '',
    },
    customer,
  );

  const schema = Yup.object().shape({
    email: Yup.string().email().label('Billing Email Address').max(255).required(),
    name: Yup.string().label('Contact or Company Name').max(255).required(),
    addressLine1: Yup.string().label('Address Line 1').max(255).required(),
    addressLine2: Yup.string().label('Address Line 2').max(255),
    addressCity: Yup.string().label('City').max(255).required(),
    addressState: Yup.string().label('State / Province').max(255).required(),
    addressPostalCode: Yup.string().label('ZIP / Postal Code').max(16).required(),
    addressCountry: Yup.string().label('Country').max(2).required(),
  });

  return (
    <Drawer
      isOpen
      title="Billing Contact"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={() => onClose()}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSubmit = async (values) => {
          try {
            form.submit();

            const body = emptyStringToNull(values);
            const { data } = await api.www.workspaces(workspace.id).stripe.updateContact(body);

            onSaved(data);

            form.save();

            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        };

        return (
          <Formik
            innerRef={formRef}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {(formik) => (
              <Form>
                {status && <FormMessage.Error>{message}</FormMessage.Error>}
                <Form.Section title="Email">
                  <Form.Control>
                    <Field.Text name="email" type="email" placeholder="Billing Email Address" maxLength="255" />
                  </Form.Control>
                </Form.Section>
                <Form.Section title="Name">
                  <Form.Control>
                    <Field.Text name="name" placeholder="Contact or Company Name" maxLength="255" />
                  </Form.Control>
                </Form.Section>
                <Form.Section title="Address">
                  <Form.Control>
                    <Field.Text name="addressLine1" placeholder="Address Line 1" maxLength="255" />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text name="addressLine2" placeholder="Address Line 2" maxLength="255" />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text name="addressCity" placeholder="City" maxLength="255" />
                    <Field.Text name="addressState" placeholder="State / Province" maxLength="255" />
                    <Field.Text name="addressPostalCode" placeholder="ZIP / Postal Code" maxLength="16" />
                  </Form.Control>
                  <Form.Control>
                    <Field.StripeCountrySelect name="addressCountry" placeholder="Country" />
                  </Form.Control>
                </Form.Section>
                <Drawer.Actions>
                  <Buttons align="right">
                    <CancelButton onClick={handleCloseClick}>Cancel</CancelButton>
                    <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                      Save
                    </Button>
                  </Buttons>
                </Drawer.Actions>
              </Form>
            )}
          </Formik>
        );
      }}
    </Drawer>
  );
}
