import styled from 'styled-components';
import { colors } from '~/styles';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export default Tags;
