import styled, { css } from 'styled-components';
import { colors } from '~/styles';

export const Cell = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: ${({ flex = '1' }) => flex};
  padding: ${({ padding = '0.5rem 1rem' }) => padding};

  ${({ $width }) =>
    $width &&
    css`
      flex: 0 ${$width};
    `}

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
      text-align: right;
    `}

    ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const NoResultsCell = styled(Cell)`
  flex: 1;
  color: ${colors.grey40};
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.9 : 1)};
`;
