import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, DateTime, DeleteButton, Icon, Tooltip } from '~/components';
import { useIsMounted } from '~/hooks';
import { colors, weights } from '~/styles';
import CategorySettingsDrawer from './CategorySettingsDrawer';
import DeleteDataConfirmation from './DeleteDataConfirmation';
import ManualSyncModal from './ManualSyncModal';
import MemberMappingDrawer from './MemberMappingDrawer';
import MemberMappingTypeModal from './MemberMappingTypeModal';
import ProjectMappingsDrawer from './ProjectMappingsDrawer';
import ProjectRequiredModal from './ProjectRequiredModal';
import SyncResults from './SyncResults';
import SyncSettingsDrawer from './SyncSettingsDrawer';

const REFRESH_DELAY_SECONDS = 5;

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const FullBlock = styled(Block)`
  width: calc(100% - 2rem);
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey10};
  }
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

export default function ExpensifySettings({ integration, reloadIntegration }) {
  const isMounted = useIsMounted();

  const [showManualSync, setShowManualSync] = useState(false);
  const [showProjectMapping, setShowProjectMapping] = useState(false);
  const [showProjectRequired, setShowProjectRequired] = useState(false);
  const [showMemberMapping, setShowMemberMapping] = useState(false);
  const [showMemberMappingType, setShowMemberMappingType] = useState(false);
  const [showCategorySettings, setShowCategorySettings] = useState(false);
  const [showSyncSettings, setShowSyncSettings] = useState(false);
  const [showDeleteData, setShowDeleteData] = useState(false);
  const [forceRefreshSyncsDate, setForceRefreshSyncsDate] = useState(moment().format());
  const [canRefresh, setCanRefresh] = useState(true);

  const reloadIntegrationWithDelay = () => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    reloadIntegration();
    setTimeout(() => {
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  };

  return (
    <>
      <Blocks>
        <Block>
          <Title>Connection Information</Title>
          <Table>
            <Row>
              <Attribute>
                <code>partnerUserID</code>
              </Attribute>
              <Value>{integration.info.partnerUserId}</Value>
            </Row>
            <Row>
              <Attribute>
                <code>partnerUserSecret</code>
              </Attribute>
              <Value>******</Value>
            </Row>
            <Row>
              <Attribute>Expensify Policy</Attribute>
              <Value>{integration.info.policyName}</Value>
            </Row>
          </Table>
        </Block>
        <Block>
          <Title>Expense Category Settings</Title>
          <Table>
            <Row>
              <Attribute>Create Categories</Attribute>
              <Value>{integration.settings.createCategories ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Default Category</Attribute>
              <Value>
                {integration.settings.defaultExpenseCategory ? integration.settings.defaultExpenseCategory.name : ''}
              </Value>
            </Row>
          </Table>
          <Actions>
            <Button onClick={() => setShowCategorySettings(true)}>Change Settings</Button>
          </Actions>
        </Block>
        <Block>
          <Title>Member Settings</Title>
          <Table>
            <Row>
              <Attribute>Mapping Type</Attribute>
              <Value>{integration.settings.autoMemberMapping ? 'Automatic' : 'Manual'}</Value>
            </Row>
            {!integration.settings.autoMemberMapping && (
              <Row>
                <Attribute>Mapped Members</Attribute>
                <Value>{integration.settings.mappedMembers}</Value>
              </Row>
            )}
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowMemberMappingType(true)}>Change Mapping Type</Button>
              {!integration.settings.autoMemberMapping && (
                <Button onClick={() => setShowMemberMapping(true)}>Edit Mappings</Button>
              )}
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <Title>Project Settings</Title>
          <Table>
            <Row>
              <Attribute>Project Required</Attribute>
              <Value>{integration.settings.requireProject ? 'Yes' : 'No'}</Value>
            </Row>
            <Row>
              <Attribute>Mapped Projects</Attribute>
              <Value>{integration.settings.mappedProjects}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowProjectRequired(true)}>Change Requirement</Button>
              <Button onClick={() => setShowProjectMapping(true)}>Edit Mappings</Button>
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <Title>Sync Settings</Title>
          <Table>
            <Row>
              <Attribute>Automatically Sync</Attribute>
              <Value>{integration.settings.autoSync ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Start Date</Attribute>
              <Value>
                <DateTime value={integration.settings.syncStartDate} format="l" titleFormat="LL" />
              </Value>
            </Row>
            <Row>
              <Attribute>Expensify Statuses</Attribute>
              <Value>{integration.settings.syncExpensifyStatuses.join(', ')}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowSyncSettings(true)}>Change Settings</Button>
              <Button onClick={() => setShowManualSync(true)}>New Manual Sync</Button>
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <TitleRow>
            <Title>Imported Data</Title>
            <RefreshButton disabled={!canRefresh} onClick={() => reloadIntegrationWithDelay()}>
              <Tooltip message="Refresh data counts">
                <Icon icon="sync" />
              </Tooltip>
            </RefreshButton>
          </TitleRow>
          <Table>
            <Row>
              <Attribute>Expense Reports</Attribute>
              <Value>{integration.data.reports}</Value>
            </Row>
            <Row>
              <Attribute>Expense Items</Attribute>
              <Value>{integration.data.expenses}</Value>
            </Row>
            <Row>
              <Attribute>Expense Receipts</Attribute>
              <Value>{integration.data.receipts}</Value>
            </Row>
          </Table>
          <Actions>
            <DeleteButton onClick={() => setShowDeleteData(true)}>Delete All Imported Data</DeleteButton>
          </Actions>
        </Block>
        <FullBlock>
          <SyncResults refreshDate={forceRefreshSyncsDate} />
        </FullBlock>
      </Blocks>
      {showManualSync && (
        <ManualSyncModal
          integration={integration}
          onClose={() => setShowManualSync(false)}
          onFinish={() => setForceRefreshSyncsDate(moment().format())}
        />
      )}
      {showMemberMapping && (
        <MemberMappingDrawer onClose={() => setShowMemberMapping(false)} onSave={() => reloadIntegration()} />
      )}
      {showMemberMappingType && (
        <MemberMappingTypeModal
          integration={integration}
          onClose={() => setShowMemberMappingType(false)}
          onSave={(openMappings) => {
            reloadIntegration();
            if (openMappings) {
              setShowMemberMapping(true);
            }
          }}
        />
      )}
      {showProjectMapping && (
        <ProjectMappingsDrawer onClose={() => setShowProjectMapping(false)} onSave={() => reloadIntegration()} />
      )}
      {showProjectRequired && (
        <ProjectRequiredModal
          integration={integration}
          onClose={() => setShowProjectRequired(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showCategorySettings && (
        <CategorySettingsDrawer
          integration={integration}
          onClose={() => setShowCategorySettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showSyncSettings && (
        <SyncSettingsDrawer
          integration={integration}
          onClose={() => setShowSyncSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showDeleteData && (
        <DeleteDataConfirmation onClose={() => setShowDeleteData(false)} onDelete={() => reloadIntegration()} />
      )}
    </>
  );
}
