import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';

const projectTaskTags =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'project-task-tags', id]).compact().join('/');

    return {
      get: (query = {}, config) => get(url.concat(new QueryString(query).toString(true)), config),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      delete: () => del(url),
    };
  };

export default projectTaskTags;
