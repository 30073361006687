import { BackLink, Icon, Level, Page, Tooltip } from '~/components';
import { useIntegrations, useWorkspace } from '~/contexts';
import path from 'path-browserify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { Tag, Tags } from '../components/HeaderStyledComponents';
import SalesforceIndicator from '../components/SalesforceIndicator';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const CompanyName = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const EditLink = styled(Link)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.grey10};
  }
`;

function CompanyHeader({ company }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/companies`} />
      <InfoContainer>
        <Eyebrow>Company Dashboard</Eyebrow>
        <CompanyName>{company.name}</CompanyName>

        {integrations.salesforce && company?.salesforceCompany?.salesforceId && (
          <Tags>
            <Tag>
              <SalesforceIndicator />
            </Tag>
          </Tags>
        )}
      </InfoContainer>

      <Level right>
        {company.permissions.manage && (
          <Level.Item>
            <Tooltip message="Edit Company">
              <EditLink to={`${resolveEditPath('edit', location)}${location.search}`}>
                <Icon icon="pencil-alt" />
              </EditLink>
            </Tooltip>
          </Level.Item>
        )}
      </Level>
    </Page.Header>
  );
}

export default CompanyHeader;
