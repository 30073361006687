import { lighten } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;

  > div {
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 5px;
    background-color: ${colors.grey40};
    color: ${colors.grey40};

    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -1rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 5px;
      background-color: ${colors.grey40};
      color: ${colors.grey40};
      animation: dot-flashing 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: 1rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 5px;
      background-color: ${colors.grey40};
      color: ${colors.grey40};
      animation: dot-flashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dot-flashing {
      0% {
        background-color: ${colors.grey40};
      }

      50% {
        background-color: ${lighten(0.1, colors.grey40)};
      }

      100% {
        background-color: ${lighten(0.2, colors.grey40)};
      }
    }
  }
`;

export default function InfiniteScrollingObserver({ onIntersecting }) {
  const [loaderElement, setLoaderElement] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0]?.isIntersecting) {
          onIntersecting();
        }
      },
      { threshold: 1 },
    );

    if (loaderElement instanceof Element) {
      // Observe after a brief delay to avoid triggering if the results haven't rendered yet
      setTimeout(() => {
        observer.observe(loaderElement);
      }, 10);
    }

    return () => observer?.disconnect();
  }, [onIntersecting, loaderElement]);

  return (
    <Loader>
      <div ref={setLoaderElement} />
    </Loader>
  );
}
