import { Tooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import getGradientColor from './components/gradient';

const BarContainer = styled.div`
  width: 8rem;
  padding-top: 0.1rem;
`;

const Meter = styled.div`
  display: flex;
  min-height: 0.75rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey10};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.125rem;
  min-width: 100%;
`;

const InsideBar = styled.div`
  min-width: 0.1rem;
  border-radius: 0.25rem;
  width: ${({ width }) => width};
  background: ${({ color }) => color};
`;

export default function StageProbabilityBar({ probability, stageStatus }) {
  const barWidth = stageStatus === 'lost' ? '100%' : `${probability}%`;
  const probabilityColor = {
    open: getGradientColor(probability),
    won: colors.success,
    lost: colors.danger,
  }[stageStatus];

  return (
    <BarContainer>
      <Tooltip message={`Probability to close: ${probability}%`}>
        <Meter>
          <InsideBar width={barWidth} color={probabilityColor}></InsideBar>
        </Meter>
      </Tooltip>
    </BarContainer>
  );
}
