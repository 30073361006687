import {
  ClientSelect,
  InlineTooltip,
  Level,
  MemberSelect,
  PracticeSelect,
  ProjectBillingTypeSelect,
  ProjectStatusSelect,
  ProjectTagFilter,
  SearchInput,
  SingleSelect,
  LinkButton,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ExportDropdown from './ExportDropdown.jsx';
import mimeTypes from '~/utils/mimeTypes.js';

function ProjectsListFilters({
  filters: { q, practice, status, recordStatusId, client, billingTypeId, tags, administrator } = {},
  showCreateButton = true,
  onChange,
  onExport,
}) {
  const auth = useAuth();
  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Level>
        <Level.Item flex="1">
          <SearchInput
            value={q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
          />
        </Level.Item>

        {features.practices && (
          <Level.Item flex="1">
            <PracticeSelect
              name="practice"
              value={practice}
              placeholder="All"
              materialPlaceholder="Practice"
              materialAlwaysVisible
              onChange={handleChange}
            />
          </Level.Item>
        )}

        <Level.Item flex="1">
          <ProjectTagFilter name="tags" value={tags} onChange={handleChange} />
        </Level.Item>

        <Level.Item flex="1">
          <MemberSelect
            name="administrator"
            placeholder="All"
            materialPlaceholder="Project Admin"
            materialAlwaysVisible
            value={administrator}
            onChange={handleChange}
          />
        </Level.Item>

        <Level.Item narrow right>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>

        {showCreateButton && (
          <Level.Item right narrow>
            <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
              Create a Project
              {!auth.projects.create && (
                <InlineTooltip message="Your security role prohibits you from creating projects." />
              )}
            </LinkButton>
          </Level.Item>
        )}
      </Level>

      <Level style={{ marginTop: '1.5rem' }}>
        <Level.Item flex="1">
          <ClientSelect
            name="client"
            placeholder="All"
            materialPlaceholder="Client"
            materialAlwaysVisible
            activeOnly={false}
            value={client}
            onChange={handleChange}
          />
        </Level.Item>

        <Level.Item flex="1">
          <ProjectStatusSelect
            name="status"
            placeholder="All"
            materialPlaceholder="Project Status"
            materialAlwaysVisible
            value={status}
            showEmptyOption
            onChange={handleChange}
          />
        </Level.Item>

        <Level.Item flex="1">
          <ProjectBillingTypeSelect
            name="billingTypeId"
            placeholder="All"
            materialPlaceholder="Billing Type"
            materialAlwaysVisible
            value={billingTypeId}
            showEmptyOption
            onChange={handleChange}
          />
        </Level.Item>

        <Level.Item flex="1">
          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Project Archived"
            materialAlwaysVisible
            value={recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>
        </Level.Item>
      </Level>
    </>
  );
}

export default ProjectsListFilters;
