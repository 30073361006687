import { AutoComplete, Button, Icon } from '~/components';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { Indicator, Search } from './AutoComplete';
import { Container } from './Dropdown';

const MultiSelect = styled.div`
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0.625em;
  padding-right: 2.5em;

  &:focus {
    border-color: ${colors.primary};
  }

  ${Container} {
    flex: 1;
    position: unset;
  }

  ${Search} {
    border: none;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }

  ${Indicator} {
    top: 0;
  }
`;

const TagItem = styled.div`
  border-radius: 0.3125rem;
  border-color: ${colors.grey10};
  background-color: ${colors.grey5};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;

  margin: 0.25rem;

  > .button {
    margin-left: 0.5rem;
    color: ${colors.black};

    &:hover {
      color: ${colors.grey55};
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;

  input {
    &:disabled {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const compareOptions = (a, b) => a === b;

function AutoCompleteMulti({
  value,
  name,
  placeholder,
  materialPlaceholder = true,
  materialAlwaysVisible = false,
  compare = compareOptions,
  renderTag,
  onChange,
  onAdd,
  onRemove,
  ...props
}) {
  const autoCompleteRef = useRef();

  const handleChange = (value) => {
    // Adhere to standard HTML events
    onChange({ target: { name, value } });
  };

  const handleAdd = ({ target: { value: selectedValue } }) => {
    if (onAdd) onAdd(selectedValue);
    else if (!_.some(value, (v) => compare(v, selectedValue))) {
      handleChange([...value, selectedValue]);
    }
  };

  const handleRemove = (option) => {
    if (onRemove) onRemove(option);
    else handleChange(value.filter((v) => !compare(v, option)));
  };

  const materialPlaceholderValue = useMemo(() => {
    if (!materialPlaceholder) {
      return '';
    } else if (_.isString(materialPlaceholder)) {
      return materialPlaceholder;
    } else if (_.isString(placeholder) && !!placeholder) {
      return placeholder;
    }
    return '';
  }, [placeholder, materialPlaceholder]);

  const hasValue = useMemo(() => {
    return value?.length > 0;
  }, [value]);

  return (
    <InputContainer>
      <MultiSelect
        materialPlaceholder={value.length > 0 ? materialPlaceholder : false}
        value={value}
        style={{ flex: 1 }}
        onClick={() => autoCompleteRef.current.focus()}>
        {value?.map((option, index) =>
          _.isFunction(renderTag) ? (
            renderTag(option, { onRemove: () => handleRemove(option) })
          ) : (
            <Tag key={option?.id ?? index} onRemove={() => handleRemove(option)}>
              {props.renderOption(option)}
            </Tag>
          ),
        )}
        <AutoComplete
          ref={autoCompleteRef}
          onChange={handleAdd}
          clearable={false}
          placeholder={hasValue ? null : placeholder}
          value={null}
          {...props}
        />
      </MultiSelect>

      {!!materialPlaceholderValue && (
        <Placeholder isVisible={materialAlwaysVisible || hasValue}>{materialPlaceholderValue}</Placeholder>
      )}
    </InputContainer>
  );
}

function Tag({ children, onRemove, ...props }) {
  return (
    <TagItem {...props}>
      {children}
      <Button isAnchor onClick={onRemove}>
        <Icon icon="times-circle" />
      </Button>
    </TagItem>
  );
}

AutoCompleteMulti.Tag = Tag;

export default AutoCompleteMulti;
