import { useField } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import projectStatuses from '../lookups/project-statuses';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

const options = _.values(projectStatuses);

function ProjectStatusSelect(props) {
  return (
    <SingleSelect {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldProjectStatusSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectStatusSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectStatusSelect;
export { FieldProjectStatusSelect };
