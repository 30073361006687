import { get, post } from './agent';

const url = '/www';

const auth = {
  session: () => get(`${url}/session`),
  credentials: ({ email, workspaceKey }) => post(`${url}/credentials`, { email, workspaceKey }),
  login: ({ email, password, workspaceKey }) => post(`${url}/login`, { email, password, workspaceKey }),
  loginMfa: ({ challengeId, code }) => post(`${url}/login/mfa`, { challengeId, code }),
  loginRecoveryCode: ({ challengeId, code }) => post(`${url}/login/recovery-code`, { challengeId, code }),
  loginSaml: ({ authProviderId }) => post(`${url}/login/saml`, { authProviderId }),
  logout: (workspaceId) => post(`${url}/logout`, { workspaceId }),
  signup: (body) => post(`${url}/signup`, body),
  sendVerificationEmail: ({ email, password }) => post(`${url}/send-verification-email`, { email, password }),
  verifyEmail: ({ token }) => post(`${url}/verify-email`, { token }),
  validateJoinWorkspaceToken: ({ token }) => post(`${url}/validate-join-workspace-token`, { token }),
  joinWorkspace: ({ token, locale, name, password, passwordVerification }) =>
    post(`${url}/join-workspace`, { token, name, locale, password, passwordVerification }),
  sendPasswordResetEmail: ({ email, workspaceKey }) =>
    post(`${url}/send-password-reset-email`, { email, workspaceKey }),
  sendWorkspaceLinksEmail: ({ email }) => post(`${url}/send-workspace-links-email`, { email }),
  validatePasswordResetToken: ({ token }) => post(`${url}/validate-password-reset-token`, { token }),
  resetPassword: ({ token, password, passwordVerification }) =>
    post(`${url}/reset-password`, { token, password, passwordVerification }),
  authProviders: (authProviderId) => ({
    authorizeUrl: () => get(`${url}/auth-providers/${authProviderId}/authorize-url`),
    joinWorkspace: (body) => post(`${url}/auth-providers/${authProviderId}/join-workspace`, body),
  }),
  useLoginToken: (token) => post(`${url}/login-token`, { token }),
  verifyLoginToken: (token) => post(`${url}/login-token/verify`, { token }),
};

export default auth;
