import React from 'react';
import Tag from './Tag';

export default function PaymentMethodTag({ value, ...props }) {
  if (!value || value.length === 0) return null;

  return (
    <Tag label="Payment Method" {...props}>
      {[value]
        .flat()
        .map((v) => v.name)
        .join('; ')}
    </Tag>
  );
}
