import React from 'react';
import styled from 'styled-components';
import Daily from './Daily';
import Hourly from './Hourly';
import Monthly from './Monthly';
import Weekly from './Weekly';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Activity() {
  return (
    <Wrapper>
      <Hourly />
      <Daily />
      <Weekly />
      <Monthly />
    </Wrapper>
  );
}
