import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import DeleteDialog from '../dialogs/DeleteDialog';
import DraftForm from './DraftForm';
import PublishedView from './PublishedView';
import SendCreditNoteDrawer from '../send-drawer/SendCreditNoteDrawer';

export default function CreditNoteDetails() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { creditNoteId } = useParams();
  const confirmation = useConfirmation();
  const history = useHistory();
  const [send, setSend] = useState(false);

  const [{ isReady, creditNote }, setCreditNote] = useState({ isReady: false, creditNote: null });

  const ready = ({ creditNote }) => setCreditNote({ isReady: true, creditNote });

  useDocumentTitle(creditNote ? `Credit Note #${creditNote.number}` : undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data: creditNote } = await api.www.workspaces(workspace.id).creditNotes(creditNoteId).get();

      ready({ creditNote });
    } catch (error) {
      ready({ creditNote: null });
    }
  }, [workspace.id, creditNoteId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <DeleteDialog
        creditNote={creditNote}
        onClose={resolve}
        onDelete={async () => {
          history.push(`/app/${workspace.key}/billing/credit-notes`);
          resolve();
        }}
      />
    ));
  };

  const handleClose = () => {
    setSend(false);
  };

  const handlePreview = () =>
    window.open(`${window.location.origin}/${workspace.key}/credit-notes/${creditNote.id}?preview=true`);

  const handleSend = () => {
    setSend(true);
  };

  if (!isReady) return <PageLoader />;
  if (!creditNote) return <ErrorPage.NotFound publicSite={false} />;

  if (creditNote.statusId === 'draft')
    return (
      <DraftForm
        creditNote={creditNote}
        onChange={fetchData}
        onDelete={handleDelete}
        onReset={fetchData}
        onPreview={handlePreview}
        onSend={handleSend}
      />
    );

  return (
    <>
      <PublishedView
        creditNote={creditNote}
        onChange={fetchData}
        onDelete={handleDelete}
        onReset={fetchData}
        onPreview={handlePreview}
        onSend={handleSend}
      />
      {send && <SendCreditNoteDrawer creditNote={creditNote} onSent={fetchData} onClose={handleClose} />}
    </>
  );
}
