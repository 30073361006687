import { Hours, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useHoursFormat } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import { dateFormats, QueryString } from '~/utils';

const initialState = { isReady: false, data: null };
const handlers = { load: () => ({ isReady: false }), ready: ({ data }) => ({ isReady: true, data }) };

function MemmberTimeSummaryWidget({ start, end, period, interval, memberId }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    actions.load();

    let groupBy;
    switch (period) {
      case 'year':
      case 'quarter':
        groupBy = 'month';
        break;

      case 'month':
      case 'week':
        groupBy = 'day';
    }

    const { data } = await api.www
      .workspaces(workspace.id)
      .personalDashboard()
      .timeSummaryReport({ start, end, groupBy, memberId });

    actions.ready({ data });
  }, [actions, workspace.id, start, end, period, api, memberId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '25.125rem' }}></Widget.Preview>
      <Widget.Content>
        {data && <Data data={data} start={start} end={end} period={period} interval={interval} memberId={memberId} />}
      </Widget.Content>
    </Widget>
  );
}

const ChartColumn = styled.div`
  padding: 0 1.5rem 1.5rem;
  text-align: center;
`;

const Title = styled.div`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: ${weights.light};
`;

const ChartContainer = styled.div`
  height: 15rem;
  width: 100%;

  canvas {
    /* Force width to prevent shrink/resize issue */
    width: 100% !important;
  }
`;

const Boxes = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  margin: 0 -1.25rem -1.25rem -1.25rem;
`;

const Value = styled.div`
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${colors.primary};
      }
    `}

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SmallBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.grey10};
  }

  ${Value} {
    font-size: 0.75rem;
  }
`;

const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

const getMax = (periods, defaultMax = 8, precision = 2) => {
  let max = Math.max(...periods.map(({ total }) => Math.round(total) || 0), defaultMax);
  max = Math.floor(max / precision) * precision + precision;

  return max;
};

// These dates are not localized because they include the string representation of the month.
// For the time being, it doesn't look good if some parts are translated and some aren't,
// so anytime dates include day of week or month strings they will use the US locale.
const getTitle = ({ period, start }) =>
  ({
    week: `Time for the Week of ${moment(start).format('MMMM D, YYYY')}`,
    month: `Time for ${moment(start).format('MMMM YYYY')}`,
    quarter: `Time for ${moment(start).format('[Q]Q YYYY')}`,
    year: `Time for ${moment(start).format('YYYY')}`,
  })[period];

function Data({ data, start, end, period, interval, memberId }) {
  const { workspace } = useWorkspace();
  const { periods, billableSummary, statusSummary } = data;
  const history = useHistory();

  const hoursFormat = {
    tooltip: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
  };

  switch (interval) {
    case 'this_week_to_date':
    case 'this_month_to_date':
    case 'this_quarter_to_date':
    case 'this_year_to_date': {
      const config = {
        this_week_to_date: { unit: 'days', endOf: 'isoWeek' },
        this_month_to_date: { unit: 'days', endOf: 'month' },
        this_quarter_to_date: { unit: 'months', endOf: 'quarter' },
        this_year_to_date: { unit: 'months', endOf: 'year' },
      }[interval];

      let units = moment.duration(moment(start).endOf(config.endOf).diff(start, config.unit), config.unit);

      switch (config.unit) {
        case 'months':
          units = units.asMonths();
          break;

        default:
          units = units.asDays();
          break;
      }

      const min = periods.length;
      const max = units;
      for (let index = min; index <= max; index++) {
        periods.push({ date: moment(start).add(index, config.unit) });
      }
      break;
    }

    default:
      break;
  }

  // Dates are not localized because they include the string representation of the month.
  // For the time being, it doesn't look good if some parts are translated and some aren't,
  // so anytime dates include day of week or month strings they will use the US locale.
  const settings = {
    week: {
      labels: periods.map(({ date }) => moment(date).format('ddd').toUpperCase()),
      max: getMax(periods, 8, 2),
      stepSize: 2,
      path: 'day',
    },
    month: {
      labels: periods.map(({ date }) => moment(date).format('MMM-D')),
      rotation: 45,
      max: getMax(periods, 8, 2),
      stepSize: 2,
      path: 'day',
    },
    quarter: {
      labels: periods.map(({ date }) => moment(date).format('MMM')),
      max: getMax(periods, 160, 40),
      stepSize: 40,
      path: 'month',
    },
    year: {
      labels: periods.map(({ date }) => moment(date).format('MMM')),
      max: getMax(periods, 160, 40),
      stepSize: 40,
      path: 'month',
    },
  }[period];

  const chartData = {
    labels: settings.labels,
    datasets: [
      {
        id: 'billable',
        // There's no option to configure the legend spacing, so adding spaces to separate each legend
        // TODO: find a way to render a custom legend to match the design more closely
        label: 'Client Billable    ',
        data: periods.map(({ billable }) => billable),
        backgroundColor: colors.success,
      },
      {
        id: 'non-billable',
        label: 'Client Non-Billable    ',
        data: periods.map(({ nonBillable }) => nonBillable),
        backgroundColor: colors.danger,
      },
      {
        id: 'internal',
        label: 'Internal    ',
        data: periods.map(({ internal }) => internal),
        backgroundColor: colors.warning,
      },
      {
        id: 'time-off',
        label: 'Time Off',
        data: periods.map(({ timeOff }) => timeOff),
        backgroundColor: colors.primary50,
      },
      {
        id: 'total',
        label: 'Total',
        data: periods.map(({ total }) => (total ? 0 : 0.25)),
        backgroundColor: colors.grey5,
      },
    ],
  };

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString({
      start,
      end,
      member: memberId,
      ...query,
    }).toString(true)}`;

  const url = {
    billable: timeDetail({ billableType: 'billable' }),
    non_billable: timeDetail({ billableType: 'non_billable' }),
    internal: timeDetail({ billableType: 'internal' }),
    time_off: timeDetail({ billableType: 'time_off' }),
    total: timeDetail({}),
    not_submitted: timeDetail({ status: 'not_submitted' }),
    pending_approval: timeDetail({ status: 'pending_approval' }),
    approved: timeDetail({ status: 'approved' }),
    rejected: timeDetail({ status: 'rejected' }),
  };

  const handleClick = (_event, [element]) => {
    if (!element) return;

    const billableTypes = ['billable', 'non_billable', 'internal', 'time_off'];

    const billableType = billableTypes[element.datasetIndex];
    const date = periods[element.index].date;

    let start;
    let end;

    switch (period) {
      case 'week':
      case 'month':
        start = moment(date).format(dateFormats.isoDate);
        end = moment(date).format(dateFormats.isoDate);
        break;

      case 'quarter':
      case 'year':
        start = moment(date).startOf('month').format(dateFormats.isoDate);
        end = moment(date).endOf('month').format(dateFormats.isoDate);
        break;
    }

    // Prevent throwing an exception because of navigating away from the page.
    setTimeout(() => history.push(timeDetail({ start, end, billableType })), 1);
  };

  const chartOptions = {
    maintainAspectRatio: false,

    layout: {
      padding: { top: 12 },
    },

    plugins: {
      legend: {
        position: 'bottom',
        onClick: null,
        labels: {
          font: {
            size: 12,
          },
          pointStyleWidth: 14,
          boxHeight: 10,
          filter: (item) => !item.text.includes('Total'),
          usePointStyle: true,
        },
      },

      tooltip: {
        filter: (item) => item.datasetIndex <= 3,
        callbacks: {
          title: () => '',
          label: (tooltip) => {
            let label = (tooltip.dataset.label || '').trim();
            if (label) {
              label += ': ';
            }
            label += hoursFormat.tooltip.format(tooltip.parsed.y);
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          color: colors.grey40,
          minRotation: settings.rotation,
          maxRotation: settings.rotation,
        },
      },

      y: {
        display: true,
        stacked: true,
        grid: { display: true, color: colors.grey10 },
        border: { display: false },
        max: settings.max,
        ticks: { stepSize: settings.stepSize, color: colors.grey40 },
      },
    },

    onHover: (e, chartElement) => {
      e.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
    },

    onClick: handleClick,
  };

  return (
    <div>
      <ChartColumn>
        <Title>{getTitle({ start, period })}</Title>
        <ChartContainer>
          <Bar data={chartData} options={chartOptions} />
        </ChartContainer>
      </ChartColumn>
      <Boxes>
        <Box>
          <RouteLink to={url.billable}>
            <Value>
              <Hours value={billableSummary.billable || 0} minimumFractionDigits={0} />
            </Value>
            <Label color={colors.success}>Client Billable</Label>
          </RouteLink>
        </Box>
        <Box>
          <RouteLink to={url.non_billable}>
            <Value>
              <Hours value={billableSummary.nonBillable || 0} minimumFractionDigits={0} />
            </Value>
            <Label>Client Non-Billable</Label>
          </RouteLink>
        </Box>
        <Box>
          <RouteLink to={url.internal}>
            <Value>
              <Hours value={billableSummary.internal || 0} minimumFractionDigits={0} />
            </Value>
            <Label>Internal</Label>
          </RouteLink>
        </Box>
        <Box>
          <RouteLink to={url.time_off}>
            <Value>
              <Hours value={billableSummary.timeOff || 0} minimumFractionDigits={0} />
            </Value>
            <Label>Time Off</Label>
          </RouteLink>
        </Box>
        <Box>
          <RouteLink to={url.total}>
            <Value>
              <Hours value={billableSummary.total || 0} minimumFractionDigits={0} />
            </Value>
            <Label>Total</Label>
          </RouteLink>
        </Box>
        <Box size={0.7}>
          <SmallBox>
            <RouteLink to={url.not_submitted}>
              <Value>
                <Hours value={statusSummary.notSubmitted || 0} minimumFractionDigits={0} />
              </Value>
              <Label>Not Submitted</Label>
            </RouteLink>
          </SmallBox>
          <SmallBox>
            <RouteLink to={url.pending_approval}>
              <Value>
                <Hours value={statusSummary.pendingApproval || 0} minimumFractionDigits={0} />
              </Value>
              <Label color={colors.warning}>Pending Approval</Label>
            </RouteLink>
          </SmallBox>
        </Box>
        <Box size={0.7}>
          <SmallBox>
            <RouteLink to={url.rejected}>
              <Value>
                <Hours value={statusSummary.rejected || 0} minimumFractionDigits={0} />
              </Value>
              <Label color={colors.danger}>Rejected</Label>
            </RouteLink>
          </SmallBox>
          <SmallBox>
            <RouteLink to={url.approved}>
              <Value>
                <Hours value={statusSummary.approved || 0} minimumFractionDigits={0} />
              </Value>
              <Label color={colors.success}>Approved</Label>
            </RouteLink>
          </SmallBox>
        </Box>
      </Boxes>
    </div>
  );
}

export default MemmberTimeSummaryWidget;
