import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Field as FormikField } from 'formik';
import styled from 'styled-components';
import { FieldControl, Icon, SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const PrivateIcon = styled(Icon)`
  margin-left: 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

function Option({ slackChannel }) {
  if (!slackChannel) {
    return null;
  }
  return (
    <Wrapper>
      #{slackChannel.name}
      {slackChannel.isPrivate && <PrivateIcon icon="lock" />}
    </Wrapper>
  );
}

const SlackChannelSelect = React.forwardRef(({ value, disabled, ...props }, ref) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [slackChannels, setSlackChannels] = useState();
  const [filterValue, setFilterValue] = useState('');
  const isMountedRef = useRef(false);

  const filteredChannels = useMemo(
    () => _.filter(slackChannels, (channel) => channel.name.includes(filterValue)),
    [slackChannels, filterValue],
  );

  const slackChannel = useMemo(() => _.find(slackChannels, { id: value }), [slackChannels, value]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { data } = await api.www.workspaces(workspace.id).integrations().slack.getChannels();

      if (!isMountedRef.current) {
        return;
      }

      setIsLoading(false);
      setSlackChannels(data);
    })();
  }, [api, workspace.id]);

  return (
    <SingleSelect
      {...props}
      showEmptyOption={true}
      showFilter={true}
      placeholder="Project Channel"
      noOptionsMessage="No slack users available"
      value={value}
      valueRenderer={<Option slackChannel={slackChannel} />}
      disabled={disabled || isLoading}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      ref={ref}>
      {filteredChannels?.map((slackChannel) => (
        <option key={slackChannel.id} value={slackChannel.id}>
          <Option slackChannel={slackChannel} />
        </option>
      ))}
    </SingleSelect>
  );
});

const FieldSlackChannelSelect = React.forwardRef(
  ({ field, form: { touched, errors }, label, hideErrorMessage, ...props }, ref) => {
    const error = touched[field.name] && errors[field.name];
    return (
      <FieldControl {...{ label, error, hideErrorMessage }}>
        <SlackChannelSelect {...props} value={field.value} onChange={field.onChange} name={field.name} ref={ref} />
      </FieldControl>
    );
  },
);

const FormikFieldSlackChannelSelect = (props) => <FormikField {...props} component={FieldSlackChannelSelect} />;

export default FormikFieldSlackChannelSelect;
