import { Field, Form } from '~/components';
import React from 'react';
import CapMonthlyHoursCheckbox from './CapMonthlyHoursCheckbox';

function BudgetForm({ projectModel }) {
  const currency = projectModel.currency;

  return (
    <>
      {projectModel.isBillable && (
        <Form.Section title="Monthly Services Revenue">
          <Form.Control>
            <Field.Currency name="estimatedMonthlyServiceRevenue" placeholder="Services Revenue" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}

      <Form.Section title="Monthly Hours">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Number name="estimatedMonthlyBillableHours" min={0} precision={2} placeholder="Billable Hours" />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Number
            name="estimatedMonthlyNonBillableHours"
            min={0}
            precision={2}
            placeholder="Non-Billable Hours"
          />
        </Form.Control>

        <Form.Control>
          <CapMonthlyHoursCheckbox />
        </Form.Control>
      </Form.Section>
      <Form.Section title="Monthly Expenses">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Currency
              name="monthlyBudgetExpensesBillableAmount"
              placeholder="Billable Expenses"
              currency={currency}
            />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Currency
            name="monthlyBudgetExpensesNonBillableAmount"
            placeholder="Non-Billable Expenses"
            currency={currency}
          />
        </Form.Control>
      </Form.Section>
      {projectModel.isBillable && (
        <Form.Section
          title={
            <>
              Monthly
              <br />
              Other Items
              <br />
              to Bill
            </>
          }>
          <Form.Control help="The amount we charge the client for Other Items.">
            <Field.Currency name="monthlyBudgetOtherItemsFee" placeholder="Amount" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}
    </>
  );
}

export default BudgetForm;
