import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

export default function useSchemaForm({ initialValues, schema, schemaContext, schemaConditionals }) {
  const [values, setValues] = useState(initialValues || {});
  const [errors, setErrors] = useState({});
  const [resetParams, setResetParams] = useState();
  const [resetForm, setResetForm] = useState(false);
  const initialValuesRef = useRef(initialValues);
  const conditionalsRef = useRef({});
  const isMountedRef = useRef(false);

  const conditionals = useMemo(() => {
    const context = { ...values, ...(schemaContext || {}) };
    const result = _.mapValues(schemaConditionals, (value) => (_.isFunction(value) ? value(context) : value));
    if (!_.isEqual(result, conditionalsRef.current)) {
      conditionalsRef.current = result;
    }
    return conditionalsRef.current;
  }, [values, schemaConditionals, schemaContext]);

  const unifiedSchema = useMemo(() => {
    if (!schema) {
      return schema;
    }
    const toMerge = _(conditionals)
      .pickBy((conditional) => !conditional)
      .mapValues(() => undefined)
      .value();
    return schema.clone().shape(toMerge);
  }, [schema, conditionals]);

  const isDirty = useMemo(() => {
    if (!unifiedSchema) {
      return !_.isEqual(initialValues, values);
    }
    const fields = _(unifiedSchema.fields)
      .pickBy((field) => !_.isUndefined(field))
      .keys()
      .value();
    return !_.isEqual(_.pick(initialValues, fields), _.pick(values, fields));
  }, [initialValues, values, unifiedSchema]);

  const setFieldValue = useCallback((name, value) => {
    setValues((values) => {
      const newValues = _.cloneDeep(values);
      newValues[name] = value;
      return _.isEqual(values, newValues) ? values : newValues;
    });
  }, []);

  const resetAfterEffect = useCallback((params) => {
    setResetParams(params);
    setResetForm(true);
  }, []);

  const reset = useCallback((fields) => {
    setValues((values) => {
      if (fields) {
        const newValues = _.cloneDeep(values);
        _.forEach(fields, (field) => {
          if (_.has(newValues, field) && _.has(initialValuesRef.current, field)) {
            const initialValue = _.get(initialValuesRef.current, field);
            _.set(newValues, field, initialValue);
          }
        });
        return _.isEqual(values, newValues) ? values : newValues;
      }
      return _.isEqual(values, initialValuesRef.current) ? values : initialValuesRef.current;
    });
    setErrors((errors) => {
      if (fields) {
        const newErrors = _.cloneDeep(errors);
        _.forEach(fields, (field) => {
          if (_.has(newErrors, field)) {
            _.set(newErrors, field, {});
          }
        });
        return _.isEqual(errors, newErrors) ? errors : newErrors;
      }
      return _.isEqual(errors, {}) ? errors : {};
    });
  }, []);

  const parseYupError = useCallback((error) => {
    const errors = {};
    if (_.isArray(error.inner)) {
      for (const inner of error.inner) {
        if (!(inner.path in errors)) {
          errors[inner.path] = inner.message;
        }
      }
    } else {
      errors[error.path] = error.message;
    }
    return errors;
  }, []);

  const validate = useCallback(async () => {
    if (!unifiedSchema) {
      return {};
    }
    try {
      const parsedValues = await unifiedSchema.validate(values, {
        context: schemaContext,
        stripUnknown: true,
        abortEarly: false,
      });
      return { values: parsedValues };
    } catch (error) {
      return { errors: parseYupError(error) };
    }
  }, [values, unifiedSchema, schemaContext, parseYupError]);

  const handleSubmit = useCallback(
    (callback) => async (event) => {
      if (event) {
        if (_.isFunction(event.preventDefault)) {
          event.preventDefault();
        }
        if (_.isFunction(event.stopPropagation)) {
          event.stopPropagation();
        }
      }
      if (unifiedSchema) {
        const { values: parsedValues, errors: newErrors = {} } = await validate();
        setErrors((errors) => (_.isEqual(errors, newErrors) ? errors : newErrors));
        if (parsedValues && _.isFunction(callback)) {
          callback(parsedValues);
        }
      } else if (_.isFunction(callback)) {
        callback(_.cloneDeep(values));
      }
    },
    [unifiedSchema, validate, values],
  );

  const handleChanges = useCallback(async () => {
    // TODO: This can currently cause problems when using cascading fields as it might
    // fire validation on a field that is untouched. To fix it:
    // 1) Create a way to store the previous state of `values`
    // 2) Add `changedFields` to track which fields changed
    // 3) Update this code to only change error states on fields that have changed
    // 4) Consider using the concept of 'touching' fields to negate premature validation
    if (!_.isEmpty(errors)) {
      const { errors: newErrors = {} } = await validate();
      setErrors((errors) => (_.isEqual(errors, newErrors) ? errors : newErrors));
    }
  }, [errors, validate]);

  // This is correct, even if eslint complains it cant detect dependencies.
  // Wrapping it in an inline function would break debouncing.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChanges = useCallback(_.debounce(handleChanges), [handleChanges]);

  useEffect(() => {
    debounceChanges();
    return debounceChanges.cancel;
  }, [debounceChanges, values]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    initialValuesRef.current = initialValues;
  }, [initialValues]);

  // This is sort of a hack to get around the way things are processed in the newer version of
  // React. Previously, reset() would get called right after setting the new initial values, but
  // the initial values would not be updated in time for when the function ran. This forces the
  // function to get called in a `useEffect`, which should be scheduled after the initial values
  // have been updated.
  useEffect(() => {
    if (resetForm) {
      reset(resetParams);
      setResetForm(false);
      setResetParams();
    }
  }, [reset, resetForm, resetParams]);

  return {
    values,
    errors,
    isDirty,
    conditionals,
    setFieldValue,
    reset: resetAfterEffect,
    handleSubmit,
  };
}
