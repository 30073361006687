import Big from 'big.js';
import { Button, Confirmation, Field, Icon, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation } from '~/contexts';
import { FieldArray } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { arrayMove } from '~/utils';
import InvoiceLineDrawer from './InvoiceLineDrawer';
import LineAmountOffTooltip from './LineAmountOffTooltip';
import LineTypeTooltip from './LineTypeTooltip';

const InvoiceItemContainer = styled.div`
  flex: 1;
`;

const LineDetails = styled.div`
  width: 100%;

  textarea {
    font-size: 0.8125rem;
    margin-top: 0.2rem;
    min-height: 2.5rem;
    padding: 0.125rem 0.25rem;
    width: 100%;
    resize: none;
    overflow: hidden;
  }

  small {
    display: block;
    font-size: 0.75rem;
    color: ${colors.grey55};

    margin-top: 1rem;
  }
`;

const CheckboxContainer = styled.div`
  label > div {
    margin-right: 0;
  }
`;

const Container = styled.div`
  font-size: 0.875rem;

  input {
    font-size: inherit;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const BoxRow = styled(Table.BoxRow)`
  position: relative;

  ${({ $dragging }) =>
    $dragging &&
    css`
      > div {
        transition: 150ms all ease-in-out;
        opacity: 0.5;
      }
    `}

  ${({ $over }) =>
    $over &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -7px;
        left: 0;
        border-top: 2px solid ${colors.primary};
      }
    `}
`;

const Row = styled(Table.Row)`
  position: relative;

  ${({ $over }) =>
    $over &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 5px;
        left: 0;
        border-top: 2px solid ${colors.primary};
      }
    `}
`;

const Handle = styled.div`
  cursor: move;
  font-size: 1rem;
  color: ${colors.grey40};
  transition: 150ms all ease-in-out;
  padding: 0.5rem 0.25rem;

  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
  }
`;

function HiddenColumnTooltip() {
  return (
    <Tooltip
      style={{ marginLeft: '0.5rem' }}
      message="This column will not be shown on the published version of this invoice.">
      <Icon icon="info-circle" />
    </Tooltip>
  );
}

function InvoiceLinesTable({ invoice, formik }) {
  const [drag, setDrag] = useState({ index: null, over: null });
  const [drawer, setDrawer] = useState(null);
  const confirmation = useConfirmation();

  const addLine = () => {
    const projects = _.reduce(
      formik.values.lines,
      (a, line) => {
        if (!line.project || _.find(a, { id: line.project.id })) {
          return a;
        }
        a.push(line.project);
        return a;
      },
      [],
    );

    const line = {
      amount: '',
      description: '',
      id: _.uniqueId('line_'),
      invoiceItem: null,
      lineNumber: formik.values.lines.length + 1,
      project: projects.length === 1 ? projects[0] : null,
      quantity: '',
      rate: '',
      taxable: false,
      timeEntries: [],
      expenses: [],
      projectExpenses: [],
      milestones: [],
      otherItems: [],
    };

    formik.setFieldValue('lines', [...formik.values.lines, line]);
    formik.setStatus({ ...formik.status, newLineId: line.id });
  };

  const handleDragStart = (index) => {
    setDrag({ index, over: null });
  };

  const handleDragEnd = () => {
    setDrag({ index: null, over: null });
  };

  const handleDragOver = (index) => {
    if (!drag || drag.over === index) return;
    setDrag({ ...drag, over: index });
    return false;
  };

  const handleDrop = (index) => {
    const lines = arrayMove(formik.values.lines, drag.index, index);
    formik.setFieldValue('lines', lines);
  };

  const handleClose = () => setDrawer(null);

  const handleApply = (values) => {
    formik.setValues({
      ...formik.values,
      lines: formik.values.lines.map((l) => (l.id === values.id ? { ...l, ...values } : l)),
    });
  };

  const currency = formik.values.currency;

  const column = (name) => formik.values.displayColumns.includes(name);

  return (
    <Container>
      <Table>
        <Table.BoxHeader>
          <Table.Column width="0" />
          <Table.Column width="18rem">
            Item
            {!column('item') && <HiddenColumnTooltip />}
          </Table.Column>
          <Table.Column>
            Details
            {!column('details') && <HiddenColumnTooltip />}
          </Table.Column>
          <Table.Column align="right" width="7rem">
            QTY
            {!column('quantity') && <HiddenColumnTooltip />}
          </Table.Column>
          <Table.Column align="right" width="11rem">
            Rate
            {!column('rate') && <HiddenColumnTooltip />}
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Amount
          </Table.Column>
          <Table.Column align="center" width="4rem">
            Tax
            {!column('tax') && <HiddenColumnTooltip />}
          </Table.Column>
          <Table.Column align="center" width="3.5rem">
            <Icon icon="link" />
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>

        <FieldArray name="lines">
          <Table.Body>
            {_.map(formik.values.lines, (line, index) => {
              const handleDelete = async () => {
                if (line.transactionType) {
                  const confirm = await confirmation.prompt((resolve) => (
                    <Confirmation resolve={resolve}>
                      Are you sure you want to remove these{' '}
                      {
                        {
                          time: 'hours',
                          expense: 'expenses',
                          milestone: 'project milestones',
                          other_item: 'other items',
                        }[line.transactionType]
                      }{' '}
                      from the invoice? They will be moved back to the Ready to Bill list.
                    </Confirmation>
                  ));
                  if (!confirm) return;
                }

                formik.setFieldValue(
                  'lines',
                  formik.values.lines.filter(({ id }) => id !== line.id),
                );
              };

              const prefix = `lines[${index}].`;

              const updateLine = ({ quantity = line.quantity, rate = line.rate, amount = line.amount }) => {
                formik.setValues({
                  ...formik.values,
                  lines: formik.values.lines.map((l) =>
                    l.id === line.id
                      ? {
                          ...l,
                          quantity,
                          rate,
                          amount,
                        }
                      : l,
                  ),
                });
              };

              const handleFocus = (event) => {
                event.target.previousValue = event.target.value;
              };

              const handleQuantityRateBlur = (event) => {
                if (event.target.previousValue === event.target.value) return;

                const quantity = _.isNumber(line.quantity) ? _.round(line.quantity, 7) : line.quantity;
                const rate = _.isNumber(line.rate) ? _.round(line.rate, 7) : line.rate;

                // If quantity is not a number, consider it as "1"
                const amount = Big(_.isNumber(quantity) ? quantity : 1)
                  // If rate is not a number, consider it as "0"
                  .times(_.isNumber(rate) ? rate : 0)
                  .round(2)
                  .toNumber();

                updateLine({ quantity, rate, amount });
              };

              const handleAmountBlur = (event) => {
                if (event.target.previousValue === event.target.value) return;

                const quantity = _.isNumber(line.quantity) ? line.quantity : 1;
                const amount = _.isNumber(line.amount) ? _.round(line.amount, 2) : '';

                let rate = line.rate;
                if (!_.isNumber(amount) || quantity === 0) rate = '';
                else if (_.isNumber(rate)) rate = Big(amount).div(quantity).round(7).toNumber();

                updateLine({ amount, rate });
              };

              const isNewLine = formik.status?.newLineId === line.id;

              const handleEdit = () => {
                setDrawer({ line });
              };

              return (
                <BoxRow
                  key={line.id}
                  draggable={index === drag?.index}
                  $dragging={index === drag?.index}
                  $over={index === drag?.over}
                  onDragEnter={(e) => e.preventDefault()}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => {
                    e.preventDefault();
                    handleDragOver(index);
                  }}
                  onDrop={() => handleDrop(index)}>
                  <Table.Cell>
                    <Handle onMouseDown={() => handleDragStart(index)}>
                      <Icon icon="grip-vertical" />
                    </Handle>
                  </Table.Cell>

                  <Table.Cell>
                    <InvoiceItemContainer>
                      <Field.InvoiceItemSelect name={prefix.concat('invoiceItem')} autoFocus={isNewLine} />
                    </InvoiceItemContainer>
                  </Table.Cell>

                  <Table.Cell>
                    <LineDetails>
                      <Field.TextArea name={prefix.concat('description')} rows={2} maxLength={4000} />
                    </LineDetails>
                  </Table.Cell>

                  <Table.Cell align="right">
                    <Field.Number
                      name={prefix.concat('quantity')}
                      min={0}
                      max={99999999999}
                      precision={2}
                      onFocus={handleFocus}
                      onBlur={handleQuantityRateBlur}
                    />
                  </Table.Cell>

                  <Table.Cell align="right">
                    <Field.Currency
                      name={prefix.concat('rate')}
                      currency={currency}
                      min={-99999999999}
                      max={99999999999}
                      precision={7}
                      onFocus={handleFocus}
                      onBlur={handleQuantityRateBlur}
                    />
                  </Table.Cell>

                  <Table.Cell align="right">
                    <Field.Currency
                      name={prefix.concat('amount')}
                      currency={currency}
                      min={-99999999999}
                      max={99999999999}
                      onFocus={handleFocus}
                      onBlur={handleAmountBlur}
                    />

                    <LineAmountOffTooltip line={line} amount={line.amount} currency={currency} />
                  </Table.Cell>

                  <Table.Cell>
                    <CheckboxContainer>
                      <Field.Checkbox name={prefix.concat('taxable')} />
                    </CheckboxContainer>
                  </Table.Cell>

                  <Table.Cell>
                    <LineTypeTooltip line={line} />
                  </Table.Cell>

                  <TableBoxRowActions>
                    <TableBoxRowActions.Edit onClick={handleEdit}>
                      <Icon icon="pencil" />
                    </TableBoxRowActions.Edit>

                    <hr />

                    <TableBoxRowActions.Delete onClick={handleDelete}>
                      <Icon icon="trash" />
                    </TableBoxRowActions.Delete>
                  </TableBoxRowActions>
                </BoxRow>
              );
            })}

            <Row
              $over={formik.values.lines.length === drag?.over}
              onDragEnter={(event) => event.preventDefault()}
              onDragOver={(event) => {
                event.preventDefault();
                handleDragOver(formik.values.lines.length);
              }}
              onDrop={() => handleDrop(formik.values.lines.length - 1)}>
              <Table.Cell>
                <Button isAnchor isStrong onClick={addLine}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Add Line
                </Button>
              </Table.Cell>
            </Row>
          </Table.Body>
        </FieldArray>
      </Table>
      {drawer && (
        <InvoiceLineDrawer
          invoice={invoice}
          line={drawer.line}
          projects={formik.values.projects}
          currency={currency}
          onApply={handleApply}
          onClose={handleClose}
        />
      )}
    </Container>
  );
}
export default InvoiceLinesTable;
