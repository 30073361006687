import { AutoCompleteMulti, HelpTooltip } from '~/components';
import Fuse from 'fuse.js';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import timeBillableTypes from '~/lookups/time-billable-types';
import React, { useCallback, useEffect, useState } from 'react';

const options = _.values(timeBillableTypes);

export default function VarianceBillableTypeFilter({ value, ...props }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(async (q) => {
    if (!q) {
      return options;
    }

    const fuse = new Fuse(options, { keys: ['id', 'name'] });
    const results = fuse.search(q);
    return results.map((result) => result.item);
  }, []);

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <AutoCompleteMulti
          value={value}
          defaultOptions={filterOptions(defaultOptions)}
          renderOption={(option) => option.name}
          compare={(a, b) => a.id === b.id}
          onSearch={handleFilteredSearch}
          placeholder="All"
          materialPlaceholder="Variance Billability"
          materialAlwaysVisible
          style={{ flex: 1 }}
          {...props}
        />
      </div>
      <HelpTooltip
        message="Applying this filter will provide you with the variance for hours and allocations of this billable type."
        style={{ marginLeft: '0.5rem' }}
      />
    </div>
  );
}
