import { Percent } from '~/components';
import { mix } from 'polished';
import React, { useMemo } from 'react';
import { theme } from '../../components/schedule';
import { GroupCell } from '../../components/schedule/cells';
import CellHeatmap from './CellHeatmap';
import Tooltip from './Tooltip';

function TargetAttainmentCell({ cell }) {
  const { background, color, heatmap } = useMemo(() => {
    switch (cell.mode) {
      case 'header':
        return {
          background: theme.cell.total.background,
          color:
            !cell.billable || !cell.capacity || !cell.targetBillable ? theme.cell.empty.color : theme.cell.total.color,
        };

      case 'heatmap': {
        const ratio = cell.billable / cell.capacity || 0;

        let background;
        let color;
        let heatmap;

        if (!cell.billable || !cell.capacity || !cell.targetBillable) {
          background = theme.cell.empty.background;
          color = theme.cell.empty.color;
        } else if (cell.targetAttainment > 0.5) {
          background = theme.cell.filled.background;
          heatmap = mix(Math.min(ratio, 1), theme.cell.heatmap.success.dark, theme.cell.heatmap.success.light);
          color = theme.cell.filled.color;
        } else {
          background = theme.cell.filled.background;
          heatmap = mix(ratio, theme.cell.heatmap.danger.light, theme.cell.heatmap.danger.dark);
          color = theme.cell.filled.color;
        }

        return {
          background,
          color,
          heatmap,
        };
      }

      case 'placeholder':
        return {
          background: theme.cell.empty.background,
          color: theme.cell.empty.color,
        };
    }
  }, [cell.mode, cell.billable, cell.capacity, cell.targetAttainment, cell.targetBillable]);

  return (
    <GroupCell style={{ background, color }}>
      <Tooltip cell={cell}>
        {cell.capacity && cell.targetAttainment < Infinity ? (
          <Percent value={cell.targetAttainment} minimumFractionDigits={0} maximumFractionDigits={0} />
        ) : (
          '-'
        )}
        {cell.mode === 'heatmap' && <CellHeatmap style={{ background: heatmap }} />}
      </Tooltip>
    </GroupCell>
  );
}

export default React.memo(TargetAttainmentCell);
