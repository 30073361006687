import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';

function getScrollPosition() {
  const viewportHeight = document.documentElement.clientHeight;
  const { top: scrollTop, height: bodyHeight } = document.body.getBoundingClientRect();

  const toTop = Math.abs(scrollTop);
  const toBottom = bodyHeight - viewportHeight - toTop;

  return { toTop, toBottom };
}

export default function useScrollPosition() {
  const [position, setPosition] = useState(getScrollPosition());
  const lastPosition = useRef(position);
  const request = useRef();

  const updatePosition = useCallback(() => {
    const currentPosition = getScrollPosition();
    if (!_.isEqual(currentPosition, lastPosition.current)) {
      lastPosition.current = currentPosition;
      setPosition(currentPosition);
    }
  }, []);

  const handleRequest = useCallback(() => {
    updatePosition();
    request.current = window.requestAnimationFrame(handleRequest);
  }, [updatePosition]);

  useLayoutEffect(() => {
    request.current = window.requestAnimationFrame(handleRequest);
    return () => window.cancelAnimationFrame(request.current);
  }, [handleRequest]);

  return { position, updatePosition };
}
