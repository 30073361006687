import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';
import careerInterest from './careerInterest';
import clientApprovals from './clientApprovals';
import demoRequest from './demoRequest';
import quoteRequest from './quoteRequest';

export default {
  careerInterest,
  demoRequest,
  quoteRequest,
  timeZones: (id = '') => {
    const url = _(['time-zones', id]).compact().join('/');
    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
    };
  },
  invoices: ({ workspaceKey, invoiceId }) => ({
    webLink: () => get(`/${workspaceKey}/invoices/${invoiceId}`),
    pdf: () => get(`/${workspaceKey}/invoices/${invoiceId}/pdf`, { responseType: 'arraybuffer' }),
    receipts: () => get(`/${workspaceKey}/invoices/${invoiceId}/receipts`, { responseType: 'arraybuffer' }),
    time: () => get(`/${workspaceKey}/invoices/${invoiceId}/time`),
    timeCsv: () => get(`/${workspaceKey}/invoices/${invoiceId}/time/csv`),
  }),
  creditNotes: ({ workspaceKey, creditNoteId }) => ({
    webLink: () => get(`/${workspaceKey}/credit-notes/${creditNoteId}`),
    pdf: () => get(`/${workspaceKey}/credit-notes/${creditNoteId}/pdf`, { responseType: 'arraybuffer' }),
  }),
  clientApprovals: clientApprovals.public,
  companyServiceTypes: () => ({
    get: () => get('company-service-types'),
  }),
  currencies: () => ({
    get: (query = {}) => get('currencies'.concat(new QueryString(query).toString(true))),
  }),
  locales: () => ({
    get: () => get('locales'),
  }),
  sicCodes: () => ({
    get: (query = {}) => get('sic-codes'.concat(new QueryString(query).toString(true))),
  }),
};
