import React, { useEffect, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, Table } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import ItemSelect from './components/ItemSelect';

export default function InvoiceItemMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ invoiceItems, items, isReady }, setState] = useState({ invoiceItems: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: invoiceItems } = await api.www.workspaces(workspace.id).invoiceItems().get({ size: 1000 });

      const { data: items } = await api.www.workspaces(workspace.id).qbo.getItems();

      if (!isMounted.current) return;
      setState({ invoiceItems, items, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updateInvoiceItemMapping = ({ invoiceItemId, qboItemId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      invoiceItems: invoiceItems.map((i) => (i.id === invoiceItemId ? { ...i, qboItemId } : i)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Invoice Item Mappings"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = invoiceItems.map((invoiceItem) => ({
              invoiceItemId: invoiceItem.id,
              qboItemId: invoiceItem.qboItemId,
            }));
            await api.www.workspaces(workspace.id).qbo.updateInvoiceItemMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column width="40%">Invoice Item</Table.Column>

                <Table.Column>
                  <strong>QuickBooks Product/Service</strong>
                </Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {invoiceItems.map((invoiceItem) => {
                  const handleItemChange = (event) => {
                    updateInvoiceItemMapping({
                      invoiceItemId: invoiceItem.id,
                      qboItemId: event.target.value || null,
                    });
                  };

                  return (
                    <Table.BoxRow key={invoiceItem.id}>
                      <Table.Cell>{invoiceItem.name}</Table.Cell>
                      <Table.Cell>
                        <ItemSelect items={items} value={invoiceItem.qboItemId} onChange={handleItemChange} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
