import { Buttons, CancelButton, Drawer, Icon, Tab, Tabs } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { getFileDataUrl } from '~/utils';
import UploadingWarning from './UploadingWarning';
import DeleteConfirmation from './DeleteConfirmation';
import FilePreview from './FilePreview';

const MAX_FILE_SIZE = 20971520; // 20mb in bytes

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 1rem 1.5rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const InfoContainer = styled.div`
  display: flex;
  margin: 1.625rem 0;
  padding: 1rem 1.5rem;
  background-color: ${colors.primary10};
  border-radius: 0.25rem;
`;

const InfoIcon = styled(Icon)`
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  color: ${colors.primary};
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

export default function ClientFilesDrawer({ client, onClose, onChanged }) {
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();

  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filesChanged, setFilesChanged] = useState(false);
  const [showUploadingWarning, setShowUploadingWarning] = useState(false);

  const isConfidential = useMemo(() => tabIndex === 0, [tabIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { data } = await api.www.workspaces(workspace.id).clients(client.id).files().get();
        setFiles(data);
      } catch (error) {
        if (error?.message) {
          setError(error.message);
        } else {
          setError('There was a problem getting your files.');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace.id, client.id]);

  const onBeforeClose = (callback) => {
    if (_.some(files, { status: 'uploading' })) {
      setShowUploadingWarning(true);
    } else if (typeof callback === 'function') {
      if (filesChanged && typeof onChanged === 'function') {
        onChanged();
      }
      callback();
    }
  };

  const addFiles = async (files) => {
    setFilesChanged(true);

    // Generate placeholder files, run in parallel
    const tempFiles = await Promise.all(
      files.map(async (fileData) => ({
        id: _.uniqueId('temp_file_'),
        name: fileData.name,
        type: fileData.type || 'application/octet-stream',
        status: 'uploading',
        dataUrl: await getFileDataUrl(fileData),
        isConfidential,
      })),
    );

    // Set the files all at once to prevent missing files
    setFiles((files) => [...tempFiles, ...files]);

    // Upload the files, run in parallel
    await Promise.all(
      tempFiles.map(async (file) => {
        try {
          const response = await api.www
            .workspaces(workspace.id)
            .clients(client.id)
            .files()
            .upload({
              name: file.name,
              type: file.type,
              data: file.dataUrl.split(';base64,').pop(),
              isConfidential: file.isConfidential,
            });

          const updatedFile = {
            ...response.data,
            status: 'uploaded',
            dataUrl: file.dataUrl,
          };

          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        } catch (error) {
          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              const updatedFile = _.assign({}, files[fileIndex], {
                status: 'error',
              });
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        }
      }),
    );
  };

  const removeFile = (file) => {
    setFilesChanged(true);

    setFiles((files) => _.reject(files, { id: file.id }));
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 20 MB.`);
      }
    }
    if (acceptedFiles.length > 0) {
      addFiles(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ maxSize: MAX_FILE_SIZE, onDrop });

  return (
    <>
      <Drawer
        isOpen
        title="Files"
        byline={client.name}
        onBeforeClose={({ setIsOpen }) => onBeforeClose(() => setIsOpen(false))}
        onClose={onClose}>
        {(closeDrawer) =>
          isLoading ? (
            <PageLoader />
          ) : (
            <>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Tabs selectedIndex={tabIndex} onChange={setTabIndex}>
                <Tab>Administrator Files</Tab>
                <Tab>Team Files</Tab>
              </Tabs>
              {isConfidential ? (
                <InfoContainer>
                  <InfoIcon type="far" icon="question-circle" />
                  These files are accessible to workspace members that can edit this client.
                </InfoContainer>
              ) : (
                <InfoContainer>
                  <InfoIcon type="far" icon="question-circle" />
                  These files are accessible to workspace members that can access this client.
                </InfoContainer>
              )}
              <DropzoneContainer>
                <DropzoneInterior {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drop files here or click to select files</p>
                </DropzoneInterior>
              </DropzoneContainer>
              <Previews>
                {_(files)
                  .filter({ isConfidential })
                  .map((file) => <FilePreview key={file.id} file={file} onRemove={setFileToDelete} />)
                  .value()}
              </Previews>
              <Drawer.Actions>
                <Buttons align="right">
                  <CancelButton onClick={() => onBeforeClose(() => closeDrawer())}>Close</CancelButton>
                </Buttons>
              </Drawer.Actions>
            </>
          )
        }
      </Drawer>
      {fileToDelete && (
        <DeleteConfirmation
          file={fileToDelete}
          client={client}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
      {showUploadingWarning && <UploadingWarning onClose={() => setShowUploadingWarning(false)} />}
    </>
  );
}
