import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth, useFeatures } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import MyAllocations from './MyAllocations';
import MyExports from './MyExports';
import MyProjects from './MyProjects';
import MyTasks from './MyTasks';
import ExecutiveDashboard from './executive-dashboard/ExecutiveDashboard';
import MyProfileArea from './my-profile/MyProfileArea';
import PersonalDashboard from './personal-dashboard/PersonalDashboard';

function HomeArea({ routeMatch }) {
  const features = useFeatures();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={`${routeMatch.path}/dashboard`} exact>
        <Redirect
          to={
            auth.executiveDashboard.view
              ? `${routeMatch.url}/dashboard/executive`
              : `${routeMatch.url}/dashboard/personal`
          }
        />
      </Route>
      <Route path={`${routeMatch.path}/dashboard/executive`} exact>
        {auth.executiveDashboard.view ? <ExecutiveDashboard /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>
      <Route path={`${routeMatch.path}/dashboard/personal`} exact>
        <PersonalDashboard />
      </Route>
      <Route path={`${routeMatch.path}/my-projects`}>
        <MyProjects />
      </Route>
      <Route path={`${routeMatch.path}/my-tasks`}>
        <MyTasks />
      </Route>
      {features.allocations ? (
        <Route path={`${routeMatch.path}/my-allocations`}>
          <MyAllocations />
        </Route>
      ) : (
        <Redirect from={`${routeMatch.path}/my-allocations`} to={routeMatch.url} />
      )}
      <Route path={`${routeMatch.path}/my-exports`}>
        <MyExports />
      </Route>
      <Route path={`${routeMatch.path}/my-profile`}>
        <MyProfileArea />
      </Route>
    </Switch>
  );
}

export default HomeArea;
