import { useField } from '~/hooks';
import React from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function FieldSingleSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <SingleSelect {...field} {...props} />
    </FieldControl>
  );
}

export default FieldSingleSelect;
