import styled from 'styled-components';
import { weights } from '~/styles';

const GroupCell = styled.div`
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;

  /* Styles */
  pointer-events: all;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  padding: 1px;
  overflow: hidden;

  > div {
    overflow: hidden;
  }

  &:hover {
    filter: brightness(90%);
  }
`;

export default GroupCell;
