import React from 'react';
import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';

export default function DeleteDataConfirmation({ onDelete, onClose }) {
  const [{ isSubmitting, message, status }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    try {
      await api.www.workspaces(workspace.id).expensify.deleteData();

      onDelete();
      onClose();
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  return (
    <ModalCard title="Remove Expensify data from Ruddr" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        This will remove <strong>all data imported from Expensify</strong> from this workspace. Are you sure?
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton isLoading={isSubmitting} onClick={handleSubmit}>
            Delete
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
