import { darken, rgba } from 'polished';
import styled from 'styled-components';
import { colors } from '~/styles';

export const ProgressContainer = styled.div`
  font-size: 0.75rem;
  flex: 1;
`;

export const ProgressLine = styled.div`
  position: relative;
  display: block;
  width: ${({ $width }) => $width ?? '100%'};
  background-color: ${({ color }) => color ?? colors.grey5};
  overflow: hidden;
  transition: width 0.5s ease-out;
  transition: box-shadow 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 3px 15px ${rgba(colors.grey100, 0.25)};
    background-color: ${({ color }) => darken(0.05, color ?? colors.grey5)};
  }
`;

export const Progress = styled.div`
  height: ${({ $height }) => $height ?? '0.5rem'};
  margin: 0.25rem 0;
  display: flex;
  border-radius: 999rem;
  overflow: hidden;
`;

export const Legend = styled.legend`
  display: flex;
  justify-content: space-between;
  color: ${colors.grey55};
  width: -webkit-fill-available;

  strong {
    font-size: 0.875rem;
    padding-bottom: 0.25rem;
  }
`;
