import React from 'react';
import Tag from './Tag';

export default function MemberStatusTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Member Status" {...props}>
      {
        {
          active: 'Active',
          inactive: 'Inactive',
        }[value]
      }
    </Tag>
  );
}
