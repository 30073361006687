import { Buttons, CancelButton, DeleteButton, Field, Form, ModalCard } from '~/components';
import { Formik } from 'formik';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import * as Yup from 'yup';

export default function SetToRejectedConfirmation({ count, onResolve }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onResolve(null);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await onResolve(values.notes);
  };

  return (
    <ModalCard title="Set Time to Rejected" onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={{ notes: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          notes: Yup.string().label('Reason').max(255).required(),
        })}>
        <Form>
          <ModalCard.Body>
            <p style={{ marginBottom: '.5rem' }}>
              This action will bypass the approval workflow and set {count} time {pluralize('entry', count)} to
              Rejected.
            </p>
            <Form.Control>
              <Field.Text name="notes" placeholder="Reason" maxLength={255} autoFocus />
            </Form.Control>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Buttons align="right">
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <DeleteButton type="submit" isLoading={isLoading}>
                Set {count} time {pluralize('entry', count)} to Rejected
              </DeleteButton>
            </Buttons>
          </ModalCard.Footer>
        </Form>
      </Formik>
    </ModalCard>
  );
}
