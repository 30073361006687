import { useApi, useTimeEntries, useWorkspace } from '~/contexts';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CalendarRedirect from './CalendarRedirect';
import DayCalendar from './DayCalendar';
import ListCalendar from './ListCalendar';
import MonthCalendar from './MonthCalendar';
import { TimesheetProvider } from './timesheets/TimesheetContext';
import { WeekFilesProvider } from './week-files';
import WeekCalendar from './WeekCalendar';

function Calendar() {
  const history = useHistory();
  const location = useLocation();
  const { view } = useParams();

  const api = useApi();
  const { workspace } = useWorkspace();
  const { clearEntries } = useTimeEntries();

  const initialDateParam = new URLSearchParams(location.search).get('date');
  const initialParsedDate = initialDateParam ? moment(initialDateParam, 'YYYY-MM-DD', true) : moment.invalid();
  const initialDate = initialParsedDate.isValid() ? initialParsedDate : moment();
  const [dateLoaded, setDateLoaded] = useState(false);
  const [date, setDate] = useState(initialDate.format('YYYY-MM-DD'));
  const [member, setMember] = useState();
  const [memberTargets, setMemberTargets] = useState();
  const [memberHolidays, setMemberHolidays] = useState([]);

  const isMountedRef = useRef();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Keeps target stats in sync with member
  const currentMember = member || workspace.member;
  const { id: memberId } = currentMember;
  const { id: workspaceId } = workspace;
  useEffect(() => {
    if (!memberId) {
      setMemberTargets();
      return;
    }
    (async () => {
      const { data } = await api.www.workspaces(workspaceId).timeEntries().memberTargets({ memberId });
      if (!isMountedRef.current) {
        return;
      }
      setMemberTargets(data);
    })();
  }, [api, memberId, workspaceId]);

  // Keeps holidays in sync with member
  useEffect(() => {
    if (!memberId) {
      setMemberHolidays([]);
      return;
    }
    (async () => {
      const { data } = await api.www.workspaces(workspaceId).members(memberId).holidays();

      if (!isMountedRef.current) {
        return;
      }

      setMemberHolidays(data);
    })();
  }, [api, memberId, workspaceId]);

  const getHolidays = useCallback(
    (startDate = null, endDate = null) => {
      if (!memberHolidays || memberHolidays.length === 0) {
        return [];
      }

      const start = moment(startDate);
      if (!start.isValid()) {
        return [];
      }

      let end = moment(endDate);
      if (!end.isValid()) {
        end = null;
      }

      return _.reduce(
        memberHolidays,
        (result, holiday) => {
          const dates = _.filter(holiday.dates, (date) => {
            if (!end) {
              return start.isSame(date, 'day');
            } else {
              return moment(date).isBetween(start, end, 'day', '[]');
            }
          });

          const entryHoliday = _.pick(holiday, ['id', 'name', 'description']);

          const entries = _.map(dates, (date) => ({ ...entryHoliday, date }));

          return result.concat(entries);
        },
        [],
      );
    },
    [memberHolidays],
  );

  // Handles page load, and when `date` changes
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dateParam = queryParams.get('date');
    if (dateParam !== date) {
      queryParams.set('date', date);

      if (!dateLoaded) {
        history.replace(`?${queryParams}`);
      } else {
        history.push(`?${queryParams}`);
      }
    }
    setDateLoaded(true);
  }, [history, date, dateLoaded]);

  // Handles navigation changes
  useEffect(() => {
    return history.listen(({ search }) => {
      const dateParam = new URLSearchParams(search).get('date');
      const parsedDate = moment(dateParam, 'YYYY-MM-DD', true);
      if (!parsedDate.isValid()) {
        return;
      }

      setDate(parsedDate.format('YYYY-MM-DD'));
    });
  }, [history]);

  // Keeps the last view in sync with storage
  useEffect(() => {
    if (view) {
      localStorage.setItem('time-view', view);
    }
  }, [view]);

  useEffect(() => {
    clearEntries();

    return () => {
      clearEntries();
    };
  }, [clearEntries]);

  const props = { view, date, setDate, member, memberTargets, setMember, getHolidays };

  return (
    <TimesheetProvider memberId={memberId}>
      <CalendarView {...props} />
    </TimesheetProvider>
  );
}

function CalendarView(props) {
  switch (props.view) {
    case 'list':
      return <ListCalendar {...props} />;
    case 'day':
      return <DayCalendar {...props} />;
    case 'week':
      return (
        <WeekFilesProvider {...props}>
          <WeekCalendar {...props} />
        </WeekFilesProvider>
      );
    case 'month':
      return <MonthCalendar {...props} />;
    default:
      return <CalendarRedirect />;
  }
}

export default Calendar;
