import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalCard } from '~/components';
import { CaptureCredentials } from './CaptureCredentials';

export const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function SalesforceSetup({ onClose, onFinish }) {
  const [username, setUsername] = useState('');
  const [passphrase, setPassphrase] = useState('');

  const handleSetCredentials = ({ username, passphrase }) => {
    setUsername(username);
    setPassphrase(passphrase);
  };

  return (
    <ModalCard title="Setup Salesforce Integration" onClose={onClose}>
      <CaptureCredentials
        username={username}
        passphrase={passphrase}
        onClose={onClose}
        onNext={handleSetCredentials}
        onFinish={onFinish}
      />
    </ModalCard>
  );
}
