import { Currency } from '~/components';
import { useNumberFormat } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AverageDealSizeIcon from '../assets/average-deal.svg?react';
import GrossPipelineIcon from '../assets/conversion-rate.svg?react';
import OpportunitiesIcon from '../assets/opportunities.svg?react';
import WeightedPipelineIcon from '../assets/weighted-pipeline.svg?react';
import { useApi, useWorkspace } from '~/contexts';

const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0 3px 15px ${colors.grey10};
  border-radius: 5px;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
  min-height: 8rem;
`;

const SummaryBox = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SummaryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
`;

const SummaryAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  font-weight: ${weights.bold};
  font-size: 1.25rem;

  p {
    font-size: 0.75rem;
    font-weight: ${weights.normal};
    line-height: 1rem;
    white-space: nowrap;
  }
`;

function OpportunitiesSummaryWidget({
  q,
  opportunityStageStatusId,
  opportunityStageId,
  opportunityTypeId,
  salesforceSynced,
  ownerId,
}) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).opportunities().getSummary({
      q,
      opportunityStageStatusId,
      opportunityStageId,
      opportunityTypeId,
      salesforceSynced,
      ownerId,
    });

    setQuery({ data, isReady: true });
  }, [
    q,
    opportunityStageStatusId,
    opportunityStageId,
    workspace.id,
    api,
    opportunityTypeId,
    salesforceSynced,
    ownerId,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return (
    <Summary fade={!isReady}>
      {data && (
        <>
          <SummaryBox>
            <SummaryIcon>
              <OpportunitiesIcon />
            </SummaryIcon>
            <SummaryAmount>
              <div>
                {numberFormat.format(data.count)}
                <p>Opportunities</p>
              </div>
            </SummaryAmount>
          </SummaryBox>
          <SummaryBox>
            <SummaryIcon>
              <GrossPipelineIcon />
            </SummaryIcon>
            <SummaryAmount>
              <div>
                <Currency value={data.totalAmount} minimumFractionDigits={0} maximumFractionDigits={0} />
                <p>Gross Pipeline</p>
              </div>
            </SummaryAmount>
          </SummaryBox>
          <SummaryBox>
            <SummaryIcon>
              <WeightedPipelineIcon />
            </SummaryIcon>
            <SummaryAmount>
              <div>
                <Currency value={data.totalWeightedAmount} minimumFractionDigits={0} maximumFractionDigits={0} />
                <p>Weighted Pipeline</p>
              </div>
            </SummaryAmount>
          </SummaryBox>
          <SummaryBox>
            <SummaryIcon>
              <AverageDealSizeIcon />
            </SummaryIcon>
            <SummaryAmount>
              <div>
                <Currency value={data.averageDealSize} minimumFractionDigits={0} maximumFractionDigits={0} />
                <p>Average Deal Size</p>
              </div>
            </SummaryAmount>
          </SummaryBox>
        </>
      )}
    </Summary>
  );
}

export default OpportunitiesSummaryWidget;
