import { useEffect, useRef, useState } from 'react';

const defaultOptions = { base: 2, initialInterval: 1000, maxInterval: 30000 };

/**
 * A hook that invokes a callback function at logarithmically increasing intervals.
 *
 * @param {function} callback - The function to call.
 * @param {{ base, initialInterval, maxInterval }} options - The interval options.
 */
export default function useLogarithmicInterval(callback, options = {}) {
  const { base, initialInterval, maxInterval } = { ...defaultOptions, ...options };

  const [interval, setIntervalState] = useState(initialInterval);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
      setIntervalState((prevInterval) => Math.min(prevInterval * base, maxInterval));
    }

    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval, base, maxInterval]);
}
