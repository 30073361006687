import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Icon, ModalCard, Tooltip } from '~/components';
import { useLocale } from '~/hooks';
import { colors } from '~/styles';

// Set how often to check for the maintenance window
// 1 hour in milliseconds
const notificationCheckInterval = 3600000;

// Controls the dismiss action save state in local storage
const maintenanceId = 'maintenance.2024-10-02';

// Maintenance start/end in UTC (include the Z)
const maintenanceStart = '2024-10-03 02:00Z';
const maintenanceEnd = '2024-10-03 04:00Z';

// Get stuff into moment for easy manipulation
const start = moment(maintenanceStart);
const end = moment(maintenanceEnd);
const duration = moment.duration(end.diff(start));

// We're required to give 48 hours notice, set to 72 hours to be safe
const notificationStart = start.clone().subtract(3, 'days');

const MaintenanceIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
`;

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
`;

const Description = styled.p`
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

function isNotificationActive() {
  const now = moment();
  return now.isAfter(notificationStart) && now.isBefore(end);
}

export default function Maintenance() {
  const [notificationActive, setNotificationActive] = useState(isNotificationActive());
  const [notificationHidden, setNotificationHidden] = useState(
    localStorage ? localStorage.getItem(maintenanceId) === 'hide' : false,
  );
  const [showAlert, setShowAlert] = useState(false);

  const locale = useLocale();

  // Checks to see if it's needed/unneeded
  useEffect(() => {
    const cb = () => {
      setNotificationActive(isNotificationActive());
    };
    const interval = setInterval(cb, notificationCheckInterval);
    return () => clearInterval(interval);
  }, []);

  const handleClose = () => setShowAlert(false);

  const handleHide = () => {
    if (localStorage) {
      localStorage.setItem(maintenanceId, 'hide');
    }
    setShowAlert(false);
    setNotificationHidden(true);
  };

  if (!notificationActive || notificationHidden) {
    return null;
  }
  return (
    <>
      <Tooltip
        Container={MaintenanceIndicator}
        message="System maintenance has been scheduled."
        onClick={() => setShowAlert(true)}>
        <WarningIcon icon="screwdriver-wrench" />
      </Tooltip>
      {showAlert && (
        <ModalCard
          title={
            <>
              <WarningIcon icon="screwdriver-wrench" /> System Maintenance
            </>
          }
          onClose={handleClose}>
          <ModalCard.Body>
            <Description>
              System maintenance has been scheduled. Ruddr may be inaccessible during this period.
            </Description>
            <Description>
              Start: <strong>{start.locale(locale).format('LLLL')}</strong>
              <br />
              End: <strong>{end.locale(locale).format('LLLL')}</strong>
              <br />
              Duration:{' '}
              <strong>
                {duration.locale(locale).asHours()} {duration.locale(locale).asHours() === 1 ? 'hour' : 'hours'}
              </strong>
            </Description>
            <Description>To dismiss this notification, click the "Hide" button below.</Description>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Buttons align="right">
              <CancelButton onClick={handleHide}>Hide</CancelButton>
              <ActionButton onClick={handleClose}>OK</ActionButton>
            </Buttons>
          </ModalCard.Footer>
        </ModalCard>
      )}
    </>
  );
}
