import { Checkbox, Confirmation } from '~/components';
import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxArea = styled.div`
  padding-top: 0.75rem;
`;

function ArchiveProjectConfirmation({ project, onResolve, onSubmit }) {
  const [shouldUpdateStatus, setShouldUpdateStatus] = useState(false);

  return (
    <Confirmation
      resolve={(confirm) => {
        if (confirm) onSubmit(shouldUpdateStatus);
        onResolve();
      }}>
      Are you sure that you want to archive this project? Other projects within this client will not be affected.
      {project.statusId !== 'completed' && project.permissions.edit && (
        <CheckboxArea>
          <Checkbox
            label="Set this project's status to Completed."
            checked={shouldUpdateStatus}
            onChange={(e) => {
              setShouldUpdateStatus(e.target.checked);
            }}
          />
        </CheckboxArea>
      )}
    </Confirmation>
  );
}

export default ArchiveProjectConfirmation;
