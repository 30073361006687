import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

function ProjectTaskCreateForm({ project, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull(values);
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).tasks(undefined).upsert(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    end: null,
    isBillable: true,
    name: '',
    start: null,
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ModalCard title="New Task" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          end: Yup.date()
            .label('End Date')
            .nullable()
            .when('start', (start, schema) =>
              start ? schema.min(Yup.ref('start'), 'End Date must be after Start Date') : schema,
            ),
          isBillable: Yup.bool(),
          name: Yup.string().label('Name').max(255).trim().required(),
          start: Yup.date().label('Start Date').nullable(),
        })}>
        {(formik) => {
          return (
            <Form>
              <ModalCard.Body>
                <>
                  <Form.Control>
                    <Field.Text
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                      autoFocus
                      name="name"
                      style={{ minWidth: '37rem' }}
                      placeholder="Task Name"
                      maxLength={255}
                    />
                  </Form.Control>
                </>

                <Form.Control>
                  <Field.DayPicker name="start" placeholder="Start Date" />
                  <Field.DayPicker name="end" placeholder="End Date" />
                </Form.Control>
                {project.isBillable && (
                  <Form.Control>
                    <Field.RadioGroup name="isBillable">
                      <Radio value={true} label="Billable" />
                      <Radio value={false} label="Non-billable" />
                    </Field.RadioGroup>
                  </Form.Control>
                )}
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button onClick={formik.submitForm} isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ProjectTaskCreateForm;
