import { Currency, DateTime, Hours, Percent, Table } from '~/components';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

const TableInfo = styled.span`
  font-size: 0.875rem;
  font-style: italic;
`;

export default function ProjectDataList({ data }) {
  return (
    <>
      <TableInfo>
        Report Created: <DateTime value={data.createdAt} />
      </TableInfo>

      <Table style={{ marginTop: '0.8rem' }}>
        <Table.Header>
          <Table.Column>Metric</Table.Column>
          <Table.Column align="right" width="10rem" isVisible={data.useBudget}>
            Budget
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Actual
          </Table.Column>
        </Table.Header>
        <Table.Body>
          {_.isNumber(data.billableHours) && (
            <Table.Row>
              <Table.Cell>Billable Hours</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetBillableHours) && (
                  <Hours value={data.budgetBillableHours} minimumFractionDigits={2} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Hours value={data.billableHours} minimumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.nonBillableHours) && (
            <Table.Row>
              <Table.Cell>Non-Billable Hours</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetNonBillableHours) && (
                  <Hours value={data.budgetNonBillableHours} minimumFractionDigits={2} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Hours value={data.nonBillableHours} minimumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.internalHours) && (
            <Table.Row>
              <Table.Cell>Internal Hours</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetInternalHours) && (
                  <Hours value={data.budgetInternalHours} minimumFractionDigits={2} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Hours value={data.internalHours} minimumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.totalHours) && (
            <Table.Row>
              <Table.Cell>Total Hours</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetTotalHours) && <Hours value={data.budgetTotalHours} minimumFractionDigits={2} />}
              </Table.Cell>
              <Table.Cell>
                <Hours value={data.totalHours} minimumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.expensesRevenue) && (
            <Table.Row>
              <Table.Cell>Expenses Revenue</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetExpensesRevenue) && (
                  <Currency
                    value={data.budgetExpensesRevenue}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.expensesRevenue}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.expensesCost) && (
            <Table.Row>
              <Table.Cell>Expenses Cost</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetExpensesCost) && (
                  <Currency
                    value={data.budgetExpensesCost}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.expensesCost}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.servicesRevenue) && (
            <Table.Row>
              <Table.Cell>Services Revenue</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetServicesRevenue) && (
                  <Currency
                    value={data.budgetServicesRevenue}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.servicesRevenue}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.servicesCost) && (
            <Table.Row>
              <Table.Cell>Services Cost</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetServicesCost) && (
                  <Currency
                    value={data.budgetServicesCost}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.servicesCost}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.servicesGrossProfit) && (
            <Table.Row>
              <Table.Cell>Services Gross Profit</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetServicesGrossProfit) && (
                  <Currency
                    value={data.budgetServicesGrossProfit}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.servicesGrossProfit}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.servicesGrossMargin) && (
            <Table.Row>
              <Table.Cell>Services Gross Margin</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetServicesGrossMargin) && (
                  <Percent value={data.budgetServicesGrossMargin} minimumFractionDigits={2} maximumFractionDigits={2} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Percent value={data.servicesGrossMargin} minimumFractionDigits={2} maximumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.totalRevenue) && (
            <Table.Row>
              <Table.Cell>Total Revenue</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetTotalRevenue) && (
                  <Currency
                    value={data.budgetTotalRevenue}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.totalRevenue}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.totalCost) && (
            <Table.Row>
              <Table.Cell>Total Cost</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetTotalCost) && (
                  <Currency
                    value={data.budgetTotalCost}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.totalCost}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.totalGrossProfit) && (
            <Table.Row>
              <Table.Cell>Total Gross Profit</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetTotalGrossProfit) && (
                  <Currency
                    value={data.budgetTotalGrossProfit}
                    currency={data.project.currency}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  value={data.totalGrossProfit}
                  currency={data.project.currency}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {_.isNumber(data.totalGrossMargin) && (
            <Table.Row>
              <Table.Cell>Total Gross Margin</Table.Cell>
              <Table.Cell>
                {_.isNumber(data.budgetTotalGrossMargin) && (
                  <Percent value={data.budgetTotalGrossMargin} minimumFractionDigits={2} maximumFractionDigits={2} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Percent value={data.totalGrossMargin} minimumFractionDigits={2} maximumFractionDigits={2} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
