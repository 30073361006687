import styled, { css } from 'styled-components';

const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ align }) =>
    ({ right: 'flex-end', left: 'flex-start', center: 'center' })[align] || 'flex-start'};
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;

  ${({ align }) =>
    align === 'right' &&
    css`
      margin-left: auto;
    `}

  > .button,
  > button {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  > .button:not(:last-child):not(.is-fullwidth),
  > button:not(:last-child):not(.is-fullwidth) {
    margin-right: 1.5rem;
  }
`;

export default Buttons;
