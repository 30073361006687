import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, Form, FormMessage, ModalCard, Tooltip } from '~/components';
import { useForm } from '~/hooks';
import { colors } from '~/styles';
import { Field, ProjectSelect } from '../fields';
import WeekFilePreview from './WeekFilePreview';

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  margin-top: 0;
`;

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 10.5rem;
  margin: 0.5rem;
`;

const PreviewInfo = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;

const PreviewData = styled.p`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

export default function SetProject({ files, member, onClose, onSave }) {
  const [formState, form] = useForm();
  const { isSubmitting, status, message } = formState;
  const [projectId, setProjectId] = useState(null);

  async function handleSave() {
    try {
      form.submit();
      onClose();
      onSave(projectId);
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }

  return (
    <ModalCard title="Set a Project" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error>{message}</FormMessage.Error>}
        <Form.Control>
          <Field name="projectId">
            <ProjectSelect
              name="projectId"
              placeholder="Project"
              showEmptyOption={true}
              memberId={member ? member.id : undefined}
              value={projectId}
              onChange={(event) => setProjectId(event.target.value ?? null)}
            />
          </Field>
        </Form.Control>
        <Previews>
          {files.map((file) => (
            <Preview key={file.id}>
              <PreviewInfo>
                <Tooltip message={file.name}>
                  <PreviewData>
                    File: <strong>{file.name}</strong>
                  </PreviewData>
                </Tooltip>
              </PreviewInfo>
              <WeekFilePreview file={file} />
            </Preview>
          ))}
        </Previews>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button type="submit" disabled={isSubmitting} onClick={handleSave}>
            Save
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
