import React from 'react';
import { useAuth } from '~/hooks';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import ExpenseApprovalsPage from './approvals/ExpenseApprovalsPage';
import ExpenseAuditingPage from './auditing/ExpenseAuditingPage';
import ReportsArea from './reports/ReportsArea';

function ExpensesArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  const area = (
    <>
      <Switch>
        <Route path={[`${path}/reports`]}>
          <ReportsArea />
        </Route>
        {auth.expenses.approve && (
          <Route path={[`${path}/approvals/item/:expenseItemId`, `${path}/approvals`]}>
            <ExpenseApprovalsPage parentUrl={`${url}/approvals`} />
          </Route>
        )}
        <Route path={[`${path}/auditing/item/:expenseItemId/:mode`, `${path}/auditing`]}>
          <ExpenseAuditingPage parentUrl={`${url}/auditing`} />
        </Route>
        <Redirect path={`${path}/`} to={`${url}/reports`} />
      </Switch>
    </>
  );
  return area;
}
export default ExpensesArea;
