import React from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, FormMessage, ModalCard } from '~/components';
import { useApi } from '~/contexts';
import { useForm } from '~/hooks';
import { weights } from '~/styles';

const Description = styled.p`
  margin-bottom: 1rem;

  > span {
    font-weight: ${weights.bold};
  }
`;

export default function RemoveConnectionModal({ connection, onChange, onClose }) {
  const [{ isSubmitting, message, status }, form] = useForm();
  const api = useApi();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    try {
      await api.admin.xero.removeConnection(connection.id);
      onChange();
      onClose();
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  return (
    <ModalCard title="Disconnect from Xero" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        <Description> Are you sure you want to disconnect {connection.tenantName} from Xero?</Description>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Disconnect
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
