import classNames from 'classnames';
import { Button, Buttons } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { useField } from 'formik';

const Day = styled(Button)`
  border-radius: 4px;
  font-weight: normal;
  padding-left: 0;
  padding-right: 0;
  width: 3.45rem;

  &:not(.active) {
    background: none;
    color: ${colors.grey25};
    border: 1px solid ${colors.grey25};
  }
`;

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function BusinessDays({ name }) {
  const [field, , helpers] = useField(name);
  const { value } = field;

  const handleChange = (index) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(i === index ? !value[i] : !!value[i]);
    }
    helpers.setValue(days);
  };

  return (
    <Buttons>
      {days.map((day, index) => (
        <Day key={index} className={classNames({ active: value[index] })} onClick={() => handleChange(index)}>
          {day}
        </Day>
      ))}
    </Buttons>
  );
}

export default BusinessDays;
