import _ from 'lodash';
import { del, get, patch, post } from './agent';

const stripe = {
  public: {
    getCountries: () => get('stripe/countries'),
  },
  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'stripe']).compact().join('/');
    return {
      get: () => get(url),
      getInvoices: () => get(url.concat('/invoices')),
      cancelSubscription: () => del(url.concat('/subscription')),
      create: () => post(url),
      deletePaymentMethod: () => del(url.concat('/payment-method')),
      undoCancelSubscription: () => patch(url.concat('/subscription')),
      updateContact: (body) => patch(url.concat('/contact'), body),
      updatePaymentMethod: (body) => patch(url.concat('/payment-method'), body),
      updatePlan: (body) => patch(url.concat('/plan'), body),
    };
  },
};

export default stripe;
