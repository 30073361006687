import {
  ActionButton,
  Confirmation,
  DateTime,
  Dropdown,
  Icon,
  InlineTooltip,
  Level,
  Page,
  SearchInput,
  SingleSelect,
  Spinner,
  SplitButton,
  Stack,
  Tooltip,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import ContactSelect from '../contacts/ContactSelect';
import ViewContactDrawer from '../contacts/ViewContactDrawer';
import ContactCreateForm from '../contacts/ContactCreateForm';
import ContactForm from '../contacts/ContactForm';

const ContactInfo = styled.div`
  line-height: 1;
  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const AddContactButton = styled(ActionButton)`
  margin-left: 0.5rem;
  border-radius: 999rem 0rem 0rem 999rem;
`;

const AddContactDropdown = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 20rem;
`;

const StyledLink = styled(SplitButton.Link)`
  white-space: nowrap;
`;

function CompanyContactsTab({ company }) {
  const documentTitle = useDocumentTitle();

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const { contactId, action } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    isActive: 'true',
    companyIds: [company.id],
    sort: new QuerySort('name', 'asc'),
  });

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).contacts().get(params);

      const contacts = data.map((c) => {
        // Find the companyContact for the specific companyId
        const companyContact = c.companyContacts.find((cc) => cc.companyId === company.id);

        // Return a new object with the original contact data and the isActive and isPrimary properties from the relation
        return {
          ...c,
          isActive: companyContact.isActive,
          isPrimary: companyContact.isPrimary,
        };
      });

      setQuery({ status: 'ready', data: contacts, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params, company.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  async function handleDeleted() {
    fetchData();
    handleClose();
  }

  async function handleCreated(contact) {
    fetchData();
    handleEdit(contact);
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, sort: new QuerySort(column, direction) }));
  };

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts`);
    documentTitle.set(company.name);
  }

  const handleDeleteConfirmation = async (contact) => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to remove the association with this contact?</Confirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).remove();

    await handleDeleted();
  };

  async function handleActiveStatusChange(contact, flag) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).setActiveStatus(flag);

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the contact status. Please try again.');
    }
  }

  async function handlePrimaryStatusChange(contact, flag) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).setPrimaryStatus(flag);

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the contact status. Please try again.');
    }
  }

  async function handleAddContact(contact) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).add();

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem adding the contact. Please try again.');
    }
  }

  const handleView = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts/${id}/view`);
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts/${id}/edit`);
  };

  if (query.status === 'loading') return <PageLoader />;
  if (!query.data) return <ErrorPage />;

  return (
    <Stack margin="2rem 0 0">
      <Level padding="0.5rem 0 0 0">
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Contact Name"
            materialAlwaysVisible
            onChange={handleChange}
          />
        </Level.Item>

        <Level.Item width="20rem">
          <SingleSelect
            name="isActive"
            value={params.isActive}
            placeholder="All"
            materialPlaceholder="Status"
            materialAlwaysVisible
            showEmptyOption
            onChange={handleChange}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </SingleSelect>
        </Level.Item>

        <Level.Item narrow right>
          <AddContactDropdown>
            {({ setIsOpen, isOpen }) => (
              <>
                <Dropdown.Trigger>
                  <SplitButton style={{ alignItems: 'center' }}>
                    <AddContactButton onClick={() => setIsOpen(!isOpen)} disabled={!auth.pipeline.manage}>
                      Associate a Contact{' '}
                      {!auth.pipeline.manage && (
                        <InlineTooltip message="Your security role prohibits you from associating contacts." />
                      )}
                    </AddContactButton>

                    <SplitButton.Menu disabled={!auth.pipeline.manage}>
                      <StyledLink to={`${url}/new`}>Create a Contact</StyledLink>
                    </SplitButton.Menu>
                  </SplitButton>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Panel>
                    <ContactSelect
                      filterContacts={query.data}
                      onChange={async ({ target: { value } }) => {
                        handleAddContact(value);
                        setIsOpen(false);
                      }}
                    />
                  </Dropdown.Panel>
                </Dropdown.Menu>
              </>
            )}
          </AddContactDropdown>
        </Level.Item>
      </Level>

      <Page.Section>
        <Table.Status>
          {query.status !== 'ready' && <Spinner />}
          <Table.Total value={query.data.length} label="Contact" />
        </Table.Status>
        <Table>
          <Table.BoxHeader sticky>
            <Table.Column width="3.5rem" align="center" />
            <Table.Column name="name" onSort={handleSort} sort={params.sort}>
              Contact
            </Table.Column>
            <Table.Column name="email" onSort={handleSort} sort={params.sort}>
              Email
            </Table.Column>
            <Table.Column name="createdAt" onSort={handleSort} sort={params.sort} width="8rem" align="right">
              Created
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>

          <Table.Body fade={query.status === 'filtering'}>
            {query.data.map((contact) => {
              const { id, createdAt, name, title, isActive, isPrimary, email } = contact;

              async function handleRowClick(contact) {
                if (contact.permissions.manage) {
                  handleEdit(contact);
                } else {
                  handleView(contact);
                }
              }

              return (
                <Table.BoxRow isDisabled={!isActive} key={id} onClick={() => handleRowClick(contact)}>
                  <Table.Cell>
                    {isPrimary && (
                      <Tooltip message="This is a primary contact.">
                        <Icon color={colors.grey40} spaceLeft icon="star" />
                      </Tooltip>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <ContactInfo>
                      <p>{name}</p>
                      {title && <small>{title}</small>}
                    </ContactInfo>
                  </Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>
                    <DateTime value={createdAt} />
                  </Table.Cell>

                  <TableBoxRowActions>
                    {contact.permissions.manage ? (
                      <TableBoxRowActions.Edit onClick={() => handleEdit(contact)} />
                    ) : (
                      <TableBoxRowActions.View onClick={() => handleView(contact)} />
                    )}

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        return (
                          <>
                            <Dropdown.Item onClick={() => handleAction(handleView(contact))}>View</Dropdown.Item>

                            <Dropdown.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? 'Insufficient permissions to edit this contact.'
                                  : undefined
                              }
                              onClick={() => handleAction(handleEdit(contact))}>
                              Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? 'Insufficient permissions to remove this contact.'
                                  : undefined
                              }
                              onClick={() => handleAction(handleDeleteConfirmation(contact))}>
                              Remove
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this contact.`
                                  : undefined
                              }
                              onClick={() => handleAction(handleActiveStatusChange(contact, !isActive))}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={!contact.permissions.manage}
                              tooltip={
                                !contact.permissions.manage
                                  ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this contact.`
                                  : undefined
                              }
                              onClick={() => handleAction(handlePrimaryStatusChange(contact, !isPrimary))}>
                              {isPrimary ? 'Set as not primary' : 'Set as primary'}
                            </Dropdown.Item>
                          </>
                        );
                      }}
                    </TableBoxRowActions.Dropdown>
                  </TableBoxRowActions>
                </Table.BoxRow>
              );
            })}
          </Table.Body>
        </Table>
      </Page.Section>

      {action &&
        {
          new: <ContactCreateForm companyId={company.id} onSaved={handleCreated} onClose={handleClose} />,
          edit: contactId && (
            <ContactForm
              companyId={company.id}
              onSaved={handleSaved}
              onDeleted={handleDeleteConfirmation}
              onClose={handleClose}
            />
          ),
          view: contactId && <ViewContactDrawer companyId={company.id} onClose={handleClose} />,
        }[action]}
    </Stack>
  );
}

export default CompanyContactsTab;
