import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDocumentTitle } from '~/hooks';
import { Header, Page } from '../components';
import Activity from './activity';

const dashboards = ['activity'];

function Dashboard({ dashboard, ...props }) {
  switch (dashboard) {
    case 'activity':
      return <Activity {...props} />;
    default:
      return null;
  }
}

export default function Dashboards() {
  useDocumentTitle('Dashboards - Admin');

  const history = useHistory();
  const route = useRouteMatch('/admin/dashboards/:dashboard?');
  const routeDashboard = route?.params?.dashboard;
  const [dashboard, setDashboard] = useState();

  useEffect(() => {
    if (!routeDashboard || !dashboards.includes(routeDashboard)) {
      setDashboard('activity');
      history.replace({ pathname: '/admin/dashboards/activity', search: window.location.search });
    } else {
      setDashboard(routeDashboard);
    }
  }, [history, routeDashboard]);

  useEffect(() => {
    const pathname = `/admin/dashboards/${dashboard}`;
    if (dashboard === undefined || window.location.pathname === pathname) {
      return;
    }
    history.push({ pathname, search: window.location.search });
  }, [history, dashboard]);

  return (
    <Page>
      <Header />
      {dashboard && <Dashboard dashboard={dashboard} />}
    </Page>
  );
}
