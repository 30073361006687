import React from 'react';
import { CanvasGroupRow, CanvasHeaderRow, CanvasRow } from './CanvasStyles';

function CanvasRows({ rows }) {
  return (
    <>
      {rows.map((row, index) => {
        let Row;

        switch (row.type) {
          case 'group':
            Row = CanvasGroupRow;
            break;

          case 'row':
            Row = CanvasRow;
            break;

          case 'header':
            Row = CanvasHeaderRow;
            break;
        }

        return <Row key={row.id} style={row.meta.style} last={index === rows.length - 1} />;
      })}
    </>
  );
}

export default React.memo(CanvasRows);
