import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, DeleteButton, Icon, Table, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import { ReportLoader } from '../reports/components';
import RemoveConnectionModal from './RemoveConnectionModal';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  user-select: none;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TitleInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 10rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  min-width: 5rem;
  margin-right: 2rem;
  align-items: ${({ align = 'left' }) => align};
`;

const StatTitle = styled.div`
  font-weight: ${weights.medium};
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatItems = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
`;

export default function Integrations() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [connection, setConnection] = useState(null);

  const { totalConnections, totalIntegrations } = useMemo(() => {
    return {
      totalConnections: searchResults.filter((connection) => connection.id).length,
      totalIntegrations: searchResults.length,
    };
  }, [searchResults]);

  const search = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.admin.xero.getIntegrations();
      setSearchResults(data);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const filteredResults = useMemo(() => {
    return searchResults.filter((result) => {
      return result.workspaceName.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchResults, searchText]);

  useEffect(() => {
    search();
    return search.cancel;
  }, [search]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleModalClose = async () => {
    setConnection(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <SearchArea>
      <SearchFilters>
        <SearchInput>
          <input
            autoFocus
            value={searchText}
            onChange={handleSearchChange}
            type="search"
            placeholder="Find a workspace"
          />
          {!!searchText && (
            <ClearButton isAnchor={true} onClick={handleClearSearch}>
              <Icon icon="times" />
            </ClearButton>
          )}
        </SearchInput>
      </SearchFilters>
      {isLoading && <ReportLoader />}
      <SearchResults>
        <Table.Status>
          <Table.Total label="Integrations" value={totalIntegrations} style={{ marginRight: '0.5rem' }} />
          <Table.Total label={`(${totalConnections} connected)`} />
        </Table.Status>
        {!!filteredResults.length &&
          filteredResults.map((connection) => (
            <SearchResult key={connection.workspaceName}>
              <Result>
                <TitleInfo>
                  <Title>
                    {connection.workspaceName + (connection.tenantName ? ` - ${connection.tenantName}` : '')}
                  </Title>
                </TitleInfo>
                <Items>
                  {connection.id && (
                    <>
                      <StatInfo align="center">
                        <StatTitle>{connection.tenantId}</StatTitle>
                        <StatItems>
                          <Subtitle>Tenant ID</Subtitle>
                          <Action onClick={() => copyToClipboard(connection.tenantId)}>
                            <Tooltip message={`Copy Tenant ID to Clipboard`}>
                              <Icon icon="clipboard" color={colors.primary} />
                            </Tooltip>
                          </Action>
                        </StatItems>
                      </StatInfo>
                      <StatInfo width="7rem" align="center">
                        <StatTitle>{moment(connection.createdAt).format(dateFormats.compactDate)}</StatTitle>
                        <Subtitle>{`connected ${moment(connection.createdAt).fromNow(true)} ago`}</Subtitle>
                      </StatInfo>
                    </>
                  )}
                  <Action>
                    <DeleteButton disabled={!connection.tenantName} onClick={() => setConnection(connection)}>
                      Disconnect
                    </DeleteButton>
                  </Action>
                </Items>
              </Result>
            </SearchResult>
          ))}
        {connection && <RemoveConnectionModal connection={connection} onChange={search} onClose={handleModalClose} />}
      </SearchResults>
    </SearchArea>
  );
}
