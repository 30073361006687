import moment from 'moment';
import React, { useMemo } from 'react';
import { dateFormats } from '~/utils';
import { BackgroundCanvasColumn } from './CanvasStyles';

function WeekBackgroundCanvas({ start, end, dateWidth }) {
  const weeks = useMemo(() => {
    const weekCount = moment(end).diff(start, 'weeks') + 1;

    const weeks = [];

    for (let index = 0; index < weekCount; index++) {
      const date = moment(start).add(index, 'weeks');
      const width = dateWidth * 7;

      weeks.push({
        key: date.format(dateFormats.isoDate),
        style: {
          left: width * index,
          width,
        },
        today: moment().isSame(date, 'isoWeek'),
      });
    }

    return weeks;
  }, [start, end, dateWidth]);

  return weeks.map((week, index) => {
    return <BackgroundCanvasColumn key={index} style={week.style} index={index} today={week.today} />;
  });
}

export default React.memo(WeekBackgroundCanvas);
