import { Tooltip } from '~/components';
import { useHoursFormat, usePercentFormat } from '~/hooks';
import _ from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

export default function ProjectedBudgetHoursTooltip({ value, children }) {
  const percentFormat = usePercentFormat();

  const hoursFormat = useHoursFormat();

  if (!_.isNumber(value.percent)) return children;

  const difference = Math.abs(value.difference);
  const percent = percentFormat.format(Math.abs(value.percent - 1));

  let message;
  if (value.percent === 1) message = 'On budget.';
  else if (value.percent > 1) {
    message = `${hoursFormat.format(difference)} ${pluralize('hour', difference)} (${percent}) over budget.`;
  } else if (value.percent < 1) {
    message = `${hoursFormat.format(difference)} ${pluralize('hour', difference)} (${percent}) under budget.`;
  }

  return (
    <Tooltip placement="top" message={message}>
      {children}
    </Tooltip>
  );
}
