import React, { useState } from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { weights } from '~/styles';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  span,
  code {
    font-weight: ${weights.bold};
  }
`;

export default function RemoveApiKey({ apiKey, onClose, onRemove }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isRemoving, setIsRemoving] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  async function handleRemove() {
    setIsRemoving(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).apiKeys(apiKey.id).delete();

      if (typeof onRemove === 'function') {
        await onRemove();
      }
      if (typeof onClose === 'function') {
        await onClose();
      }
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }

  return (
    <ModalCard title="Remove API Key" onClose={onClose}>
      <ModalCard.Body>
        <Description>
          This will remove the following key and prevent it from being used to access the Ruddr workspace API:
        </Description>
        <Description>
          {apiKey.name ? (
            <>
              <span>{apiKey.name}</span> (...<code>{apiKey.tokenLast4}</code>)
            </>
          ) : (
            <>
              ...<code>{apiKey.tokenLast4}</code>
            </>
          )}
        </Description>
        {errorMessage && <FormMessage.Error spaceTop>{errorMessage}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
            Cancel
          </CancelButton>
          <DeleteButton isLoading={isRemoving} onClick={handleRemove}>
            Remove
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
