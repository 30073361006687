import { Button, Buttons, CancelButton, Field, Form, FormMessage, Icon, Level, ModalCard, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import { darken } from 'polished';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

const OpportunityCreateFormContext = React.createContext({ forms: [] });

const NewCompanyButton = styled(Button)`
  background: ${colors.grey5};
  color: ${colors.primary};
  border-radius: 5px;
  font-weight: ${weights.bold};

  &:last-child {
    padding-right: 0.75rem;
  }

  .icon {
    font-size: 0.75rem;
  }
`;

export default function OpportunityCreateForm({ onClose, onSaved, company }) {
  const api = useApi();
  const features = useFeatures();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const [companyFormVisible, setCompanyFormVisible] = useState(false);
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [forms, setForms] = useState([]);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['company', 'opportunityStage']),
        companyId: values.company?.id ?? null,
        opportunityStageId: values.opportunityStage?.id ?? null,
        probability: values.opportunityStage?.probability ?? null,
      });

      const { data } = await api.www.workspaces(workspace.id).opportunities().upsert(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    amount: '',
    closeDate: null,
    company,
    currency: workspace.currency,
    name: '',
    opportunityStage: null,
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <OpportunityCreateFormContext.Provider value={{ forms, setForms }}>
      <ModalCard title="New Opportunity" onClose={handleClose}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            amount: Yup.number().label('Amount').min(0.01).max(99999999999).nullable(),
            company: Yup.mixed().label('Company').required(),
            opportunityStage: Yup.mixed().label('Stage').required(),
            closeDate: Yup.date().label('Expected Close Date').nullable(),
            currency: Yup.string().label('Currency').required(),
            name: Yup.string().label('Opportunity Name').max(255).required(),
          })}>
          {(formik) => {
            const handleCompanyChange = async ({ target: { value } }) => {
              const values = { ...formik.values, company: value };
              if (value) {
                values.currency = features.multicurrency ? value.currency : workspace.currency;
              }

              await formik.setValues(values);

              if (formik.errors.company) formik.validateField('company');
            };

            const handleCompanySaved = async (company) => {
              await formik.setFieldValue('company', company);
              setCompanyFormVisible(false);
              if (formik.errors['company']) formik.validateField('company');
            };

            const submit = async () => {
              // Submit all open inline forms
              for await (const form of forms) {
                await form.ref.current.submitForm();
              }

              // If at least one form is invalid, don't submit the drawer
              for await (const form of forms) {
                if (form.ref.current && !form.ref.current.isValid) return;
              }

              formik.submitForm();
            };

            return (
              <Form>
                <ModalCard.Body>
                  <>
                    <Form.Control>
                      <Field.Text
                        autoFocus
                        name="name"
                        style={{ minWidth: '37rem' }}
                        placeholder="Opportunity Name"
                        maxLength={127}
                      />
                    </Form.Control>
                    {companyFormVisible ? (
                      <NewCompanyForm onCancel={() => setCompanyFormVisible(false)} onSaved={handleCompanySaved} />
                    ) : (
                      <Form.Control>
                        <div style={{ flex: 2.5 }}>
                          <Field.CompanySelect
                            name="company"
                            placeholder="Company"
                            disabled={!!company}
                            clearable={!company}
                            onChange={handleCompanyChange}
                          />
                        </div>
                        {!company && (
                          <NewCompanyButton onClick={() => setCompanyFormVisible(true)}>
                            <Icon icon="plus" spaceRight /> Create Company
                          </NewCompanyButton>
                        )}
                      </Form.Control>
                    )}
                    <Form.Control>
                      <Field.DayPicker name="closeDate" placeholder="Expected Close Date" />
                      <Field.OpportunityStageSelect name="opportunityStage" placeholder="Opportunity Stage" />
                    </Form.Control>
                    <Form.Control>
                      <Field.Currency
                        name="amount"
                        placeholder="Amount"
                        currency={formik.values.currency ?? workspace.currency}
                      />
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        placeholder="Currency"
                        clearable={false}
                        disabled={!features.multicurrency}
                      />
                    </Form.Control>
                  </>

                  {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={handleClose}>Close</CancelButton>
                    <Button onClick={submit} isLoading={isSubmitting} disabled={companyFormVisible}>
                      Save
                    </Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </OpportunityCreateFormContext.Provider>
  );
}

const CompanyForm = styled.div`
  background: ${colors.grey5};
  padding: 0.75rem 1.5rem;
  margin: 0 -1.5rem;
`;

const TimesButton = styled(Button)`
  width: 1.375rem;
  height: 1.375rem;
  padding: 0;
  background: ${colors.danger};
  color: ${colors.white};
  font-size: 0.9rem;

  .icon {
    margin: 0;
  }

  &:hover {
    background: ${darken(0.1, colors.danger)};
  }
`;

const CheckButton = styled(Button)`
  width: 1.375rem;
  height: 1.375rem;
  padding: 0;
  font-size: 0.9rem;
`;

function NewCompanyForm({ onCancel, onSaved }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();

  const form = useRef();
  const { setForms } = useContext(OpportunityCreateFormContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'company', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'company'));
    };
  }, [setForms]);

  async function handleSubmit(values, formik) {
    try {
      formik.setSubmitting(true);
      const { data } = await api.www.workspaces(workspace.id).companies().upsert(values);
      await onSaved(data);
    } catch ({ message }) {
      formik.setSubmitting(false);
      toast.error(message);
    }
  }
  const [blockSubmit, setBlockSubmit] = useState(true);
  const [duplicateCompany, setDuplicateCompany] = useState(null);

  function DuplicateCompanyWarning({ company }) {
    return (
      <Tooltip
        placement="left"
        style={{ display: 'flex', alignItems: 'center', padding: '0.4rem' }}
        message={
          <>
            There is already a company named <b>{company.name}</b>.
          </>
        }>
        <Icon icon="exclamation-triangle" style={{ fontSize: '1.5rem' }} color={colors.warning} />
      </Tooltip>
    );
  }

  const findDuplicate = async (name) => {
    if (name) {
      const { data: company } = await api.www.workspaces(workspace.id).companies().findDuplicate({ name });
      return company ? company : null;
    } else {
      return null;
    }
  };

  const handleNameChange = useRef(
    _.debounce(async ({ target: { value } }) => {
      setDuplicateCompany(await findDuplicate(value));
      if (value.trim()) {
        setBlockSubmit(false);
      }
    }, 300),
  ).current;

  return (
    <CompanyForm>
      <Formik
        innerRef={form}
        initialValues={{ name: '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('Company Name').max(255).trim().required(),
        })}
        onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Level gap=".4rem">
              <Level.Item>
                <Field.Text
                  onChange={(event) => {
                    setBlockSubmit(true);
                    handleNameChange(event);
                    formik.handleChange(event);
                  }}
                  autoFocus
                  name="name"
                  placeholder="New Company Name"
                />
              </Level.Item>
              {duplicateCompany && formik.values.name && <DuplicateCompanyWarning company={duplicateCompany} />}

              <Level.Item narrow right>
                <TimesButton onClick={onCancel}>
                  <Icon icon="times" />
                </TimesButton>
              </Level.Item>

              <Level.Item narrow right>
                <CheckButton
                  disabled={blockSubmit}
                  isLoading={formik.isSubmitting}
                  type="button"
                  onClick={formik.submitForm}>
                  <Icon icon="check" />
                </CheckButton>
              </Level.Item>
            </Level>
          );
        }}
      </Formik>
    </CompanyForm>
  );
}
