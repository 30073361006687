import { AutoComplete, Dropdown } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function OpportunitySelect({ value, company, initialValue, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .opportunities()
        .selectOptions({
          q,
          companyId: company ? company.id : undefined,
          include: initialValue ? initialValue.id : undefined,
        });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.company.id;

          a[key] = a[key] || { company: v.company, opportunities: [] };
          a[key].opportunities.push(v);
          return a;
        }, {}),
        'key',
      );

      groups.forEach((group) => {
        group.opportunities = _.orderBy(group.opportunities, 'name');
      });

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', company: group.company });
        group.opportunities.forEach((opportunity) => {
          options.push({ type: 'opportunity', opportunity });
        });
      }

      return options;
    },
    [workspace.id, initialValue, company, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target?.name, value: event.target.value?.opportunity } };
    onChange(changeArgument);
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      renderValue={(option) => option.opportunity?.name}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.company?.id}>
                    <strong>{o.company?.name}</strong>
                  </Group>
                );
              case 'opportunity':
                return (
                  <AutoComplete.Option
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.opportunity?.id}
                    value={o}
                    index={index++}>
                    {o.opportunity?.name}
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

export default OpportunitySelect;
