import { useMember } from '~/contexts';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { formatDuration, parseTime } from '~/utils';
import Duration from './Duration';
import TextInput from './TextInput';

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled(TextInput)`
  && {
    padding-right: 3.5rem;

    &.transparent {
      background: transparent;
      color: transparent;

      &::placeholder {
        color: transparent;
      }
    }
  }
`;

const Label = styled.span`
  position: absolute;
  right: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  pointer-events: none;
`;

const Timer = styled.span`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  padding: 0.5em 0.875em;
  margin-right: 3.5rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
`;

const TimeInput = React.forwardRef(
  (
    {
      value,
      onKeyDown,
      onChange,
      onFocus,
      onBlur,
      timerStartedAt,
      useDecimal,
      disabled,
      materialPlaceholder = 'Time',
      ...props
    },
    ref,
  ) => {
    const {
      member: { locale },
    } = useMember();

    const duration = moment.duration(parseInt(value), 'minutes');
    const defaultValue = duration.asMinutes() > 0 ? formatDuration(duration, { useDecimal, locale }) : '';

    const [tempValue, setTempValue] = useState();
    const displayValue = tempValue !== undefined ? tempValue : defaultValue;

    const handleFocus = (event) => {
      setTempValue(defaultValue);
      if (typeof onFocus === 'function') {
        onFocus(event);
      }
    };

    const handleChange = (event) => {
      setTempValue(event.target.value);
      if (typeof onChange === 'function') {
        const time = parseTime(event.target.value);
        const value = time > 0 ? time : '';
        onChange(value);
      }
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'Enter':
          event.target.blur();
          break;
        default:
          break;
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(event);
      }
    };

    const handleBlur = (event) => {
      setTempValue(undefined);
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    };

    return (
      <Container>
        {timerStartedAt && (
          <Timer>
            <Duration minutes={duration.asMinutes()} timerStartedAt={timerStartedAt} showSeconds={!!timerStartedAt} />
          </Timer>
        )}
        <StyledInput
          {...props}
          ref={ref}
          type="text"
          value={displayValue}
          materialPlaceholder={materialPlaceholder}
          materialAlwaysVisible={true}
          maxLength={5}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={timerStartedAt || disabled}
          className={timerStartedAt ? 'transparent' : undefined}
        />
        <Label>hrs</Label>
      </Container>
    );
  },
);

export default TimeInput;
