import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { SingleSelect } from '~/components';

export default function InvoiceItemSelect({ invoiceItems, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return invoiceItems;
    }

    const fuse = new Fuse(invoiceItems, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [invoiceItems, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return _.find(invoiceItems, { id: value });
  }, [invoiceItems, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
