import { Level, SingleSelect } from '~/components';
import React, { useState } from 'react';
import MonthlyBudget from './monthly-budget/MonthlyBudget';
import OverallBudget from './overall-budget/OverallBudget';

function BudgetTab({ formik, project }) {
  const [view, setView] = useState(() => {
    if (formik.values.useBudget) return 'overall_budget';
    return 'monthly_budget';
  });

  return (
    <>
      {formik.values.useBudget && formik.values.useMonthlyBudget && (
        <>
          <Level>
            <Level.Item narrow right>
              <SingleSelect
                style={{ width: '12rem' }}
                value={view}
                onChange={({ target: { value } }) => setView(value)}>
                <option value="overall_budget">Overall Budget</option>
                <option value="monthly_budget">Monthly Budget</option>
              </SingleSelect>
            </Level.Item>
          </Level>

          <hr style={{ margin: '1.5rem 0' }} />
        </>
      )}

      {{
        overall_budget: () => (
          <OverallBudget
            project={project}
            projectModel={formik.values}
            onRolesChange={(value) => formik.setFieldValue('roles', value)}
            onMembersChange={(value) => formik.setFieldValue('members', value)}
            onExpensesChange={(value) => formik.setFieldValue('budgetExpenses', value)}
            onOtherItemsChange={(value) => formik.setFieldValue('budgetOtherItems', value)}
          />
        ),

        monthly_budget: () => (
          <MonthlyBudget
            projectModel={formik.values}
            onRolesChange={(value) => formik.setFieldValue('roles', value)}
            onMembersChange={(value) => formik.setFieldValue('members', value)}
            onExpensesChange={(value) => formik.setFieldValue('monthlyBudgetExpenses', value)}
            onOtherItemsChange={(value) => formik.setFieldValue('monthlyBudgetOtherItems', value)}
          />
        ),
      }[view]()}
    </>
  );
}

export default BudgetTab;
