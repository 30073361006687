import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  FieldControl,
  Form,
  FormMessage,
  Icon,
  Table,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import { colors } from '~/styles';
import { dateFormats, mergeValues, emptyStringToNull } from '~/utils';
import HolidayOccurrenceRow from './HolidayOccurrenceRow';

const StandardMessage = styled.div`
  margin-bottom: 2.5rem;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: ${colors.grey5};
`;

const StandardMessageIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

function HolidayDrawer({ onSaved, onClose, onDelete }) {
  const toast = useToast();
  const api = useApi();
  const confirmation = useConfirmation();
  const { holidayId } = useParams();
  const { workspace } = useWorkspace();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const [holiday, setHoliday] = useState(null);
  const [hasDependencies, setHasDependencies] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [editForm, setEditForm] = useState(null);

  const readOnly = !!holiday && !holiday.workspaceId;

  const titleVerb = readOnly ? 'View' : holidayId ? 'Edit' : 'New';
  const title = `${titleVerb}${!readOnly ? ' Custom' : ''} Holiday`;
  useDocumentTitle(title);

  useEffect(() => {
    if (!holidayId) {
      return;
    }
    async function fetchData() {
      const { data } = await api.www.workspaces(workspace.id).holidays(holidayId).get();
      setHoliday(data);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, holidayId, workspace]);

  useEffect(() => {
    if (!holidayId) {
      return;
    }
    async function fetchData() {
      const { data: hasDependencies } = await api.www.workspaces(workspace.id).holidays(holidayId).hasDependencies();

      setHasDependencies(hasDependencies);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, holidayId, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      description: '',
      occurrences: [],
    },
    holiday,
  );

  if (readOnly) {
    const since = moment().subtract(1, 'year').format(dateFormats.isoDate);
    initialValues.occurrences = _.filter(initialValues.occurrences, ({ date }) => date >= since);
  }

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const cleanedValues = _.cloneDeep(values);
            cleanedValues.occurrences = _.map(cleanedValues.occurrences, (occurrence) => {
              if (occurrence.id?.startsWith('temp_')) {
                delete occurrence.id;
              }
              delete occurrence.holidayId;
              delete occurrence.createdAt;
              return occurrence;
            });

            const body = emptyStringToNull(cleanedValues);
            const { data } = await api.www.workspaces(workspace.id).holidays(holiday?.id).upsert(body);

            if (onSaved) {
              await onSaved(data);
            }
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Holiday successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this holiday?</DeleteConfirmation>
          ));
          if (!confirm) {
            return;
          }
          const deleted = await onDelete(holiday);
          if (deleted) {
            closeDrawer();
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().label('Name').max(255).required(),
              occurrences: Yup.array()
                .of(Yup.object().shape({ date: Yup.date().required() }))
                .test('unique', 'Duplicate dates not allowed.', (values) => {
                  const dates = values.map((value) => moment(value.date).format(dateFormats.isoDate));
                  return new Set(dates).size === dates.length;
                }),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                if (!editForm) {
                  formik.submitForm();
                }

                if (editForm && editForm.current) {
                  await editForm.current.submitForm();
                }
              };

              const handleDeleteOccurrence = ({ id }) => {
                const occurrences = _.reject(formik.values.occurrences, { id });
                formik.setFieldValue('occurrences', occurrences);
              };

              const handleSaveOccurrence = (occurrence) => {
                // This is currently only being used to save new occurrences.
                // The function will need updating if we decide to allow editing.
                if (!occurrence.id) {
                  occurrence.id = _.uniqueId('temp_');
                  const occurrences = _.sortBy([...formik.values.occurrences, occurrence], 'date').reverse();
                  formik.setFieldValue('occurrences', occurrences);
                }
                setEditIndex(null);
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  {readOnly && (
                    <StandardMessage>
                      <StandardMessageIcon icon="lock" color={colors.grey75} />
                      This is a standard holiday managed by Ruddr.
                    </StandardMessage>
                  )}

                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} disabled={readOnly} />
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        disabled={readOnly}
                        style={{ height: '5.25rem' }}
                      />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section title="Occurrences">
                    <FieldControl error={formik.touched.occurrences && formik.errors.occurrences}>
                      <Table>
                        <Table.BoxHeader>
                          <Table.Column>Date</Table.Column>
                          <Table.BoxActionsColumn />
                        </Table.BoxHeader>
                        <Table.Body>
                          {formik.values.occurrences.map((occurrence, index) => (
                            <HolidayOccurrenceRow
                              key={occurrence.id}
                              occurrence={occurrence}
                              readOnly={readOnly}
                              isEditing={editIndex === index}
                              setEditForm={setEditForm}
                              onCancel={() => setEditIndex(null)}
                              onDelete={handleDeleteOccurrence}
                              onEdit={() => setEditIndex(index)}
                              onSave={handleSaveOccurrence}
                            />
                          ))}
                          <HolidayOccurrenceRow
                            readOnly={readOnly}
                            isEditing={editIndex === -1}
                            setEditForm={setEditForm}
                            onCancel={() => setEditIndex(null)}
                            onDelete={handleDeleteOccurrence}
                            onEdit={() => setEditIndex(-1)}
                            onSave={handleSaveOccurrence}
                          />
                        </Table.Body>
                      </Table>
                    </FieldControl>
                  </Form.Section>

                  <Drawer.Actions>
                    {holiday?.workspaceId && !readOnly && !hasDependencies && (
                      <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                    )}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!holidayId && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}
                      {!readOnly && (
                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          type="submit"
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      )}
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default HolidayDrawer;
