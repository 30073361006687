import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Icon from './Icon';
import InlineTooltip from './InlineTooltip';

const isoDateFormat = 'YYYY-MM-DD';

const Picker = styled.div`
  width: 16.75rem;
  padding: 1rem;
  padding-bottom: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

const ViewButton = styled.button`
  height: auto;
  margin-left: 0.5rem;
  margin-right: auto;
  padding: 0;
  color: ${colors.black};
  font-size: inherit;
  font-weight: ${weights.medium};
  background-color: transparent;
  border: none;

  &:hover {
    color: ${colors.grey75};
    background-color: transparent;
  }

  .icon {
    margin-left: 0.5rem;
    transform: rotate(${({ isHome }) => (isHome ? 0 : 180)}deg);
    transition: transform 0.1s;
  }
`;

const DirectionButton = styled.button`
  width: 2rem;
  height: auto;
  padding: 0;
  color: ${colors.grey40};
  font-size: 1.25rem;
  font-weight: ${weights.medium};
  background-color: transparent;
  border: none;

  &:hover {
    color: ${colors.grey55};
    background-color: transparent;
  }
`;

const Days = styled.div`
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(7, 1fr);
  font-size: 0.75rem;
`;

const DayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.grey55};
`;

const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.black)};
  border: solid 1px
    ${({ isActive, isToday }) => (isToday ? (isActive ? colors.primary25 : colors.primary) : 'transparent')};
  border-radius: 999rem;
`;

const DayOutsideMonth = styled(Day)`
  color: ${({ isActive }) => (isActive ? colors.primary50 : colors.grey10)};
`;

const Weeks = styled.div`
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(1, 1fr);
  font-size: 0.75rem;
`;

const WeekHeader = styled.div`
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(7, 1fr);
`;

const Week = styled(WeekHeader)`
  background-color: ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.grey5)};
  }
`;

const Months = styled.div`
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(3, 1fr);
`;

const Years = styled.div`
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(3, 1fr);
`;

const DateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0;
  color: ${({ isActive }) => (isActive ? colors.white : colors.black)};
  font-weight: ${weights.normal};
  background-color: ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  border: solid 1px ${({ isToday }) => (isToday ? colors.primary : 'transparent')};
  border-radius: 999rem;

  &:hover {
    color: ${({ isActive }) => (isActive ? colors.white : colors.black)};
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.grey5)};
    border-color: ${({ isActive, isToday }) => (isActive ? colors.accent : isToday ? colors.primary : 'transparent')};
  }
`;

const DayButton = styled(DateButton)`
  position: relative;
  width: 2rem;
  font-size: 0.75rem;

  &:disabled {
    color: ${colors.grey10};
    cursor: not-allowed;
  }
`;

function DayPicker({ value = null, getDayProps, onChange, scope = 'day' }) {
  const parsedValue = moment(value);
  const date = parsedValue.isValid() ? parsedValue.format(isoDateFormat) : null;
  const today = moment().format(isoDateFormat);

  const [view, setView] = useState(scope === 'month' ? 'month' : 'day');
  const [selected, setSelected] = useState(date ?? today);

  // Reset the selected view/date when `value`/`scope` changes
  useEffect(() => {
    const today = moment().format(isoDateFormat);
    setView(scope === 'month' ? 'month' : 'day');
    setSelected(date ?? today);
  }, [date, scope]);

  const days = useMemo(() => {
    const start = moment(selected).startOf('month').startOf('isoWeek').startOf('day');

    const end = moment(selected).endOf('month').endOf('isoWeek').endOf('day');

    const totalDays = Math.round(moment.duration(end.diff(start)).asDays());

    start.subtract(1, 'days');

    return _.times(totalDays, () => start.add(1, 'days').format(isoDateFormat));
  }, [selected]);

  const months = useMemo(() => {
    const start = moment(selected).month(0).subtract(1, 'month');
    return _.times(12, () => start.add(1, 'month').format(isoDateFormat));
  }, [selected]);

  const years = useMemo(() => {
    const selectedYear = moment(selected).year();
    const start = selectedYear - 7;
    return _.times(15, (index) => start + index);
  }, [selected]);

  const handleToggleView = () => {
    if (scope === 'month') {
      setView((view) => (view === 'month' ? 'year' : 'month'));
    } else {
      setView((view) => (view === 'day' ? 'year' : 'day'));
    }
  };

  const handlePrevious = () => {
    const increment = view === 'day' ? 'month' : 'year';
    const amount = view === 'year' ? 15 : 1;
    setSelected((selected) => moment(selected).subtract(amount, increment).format(isoDateFormat));
  };

  const handleNext = () => {
    const increment = view === 'day' ? 'month' : 'year';
    const amount = view === 'year' ? 15 : 1;
    setSelected((selected) => moment(selected).add(amount, increment).format(isoDateFormat));
  };

  const handleChangeYear = (year) => {
    setSelected((selected) => moment(selected).year(year).format(isoDateFormat));
    setView('month');
  };

  const handleChangeMonth = (month) => {
    const selectedMonth = moment(month);
    if (scope === 'month') {
      onChange(selectedMonth.format(isoDateFormat));
    } else {
      setSelected((selected) =>
        moment(selected).year(selectedMonth.year()).month(selectedMonth.month()).format(isoDateFormat),
      );
      setView('day');
    }
  };

  const handleChangeWeek = (week) => {
    if (typeof onChange === 'function') {
      // Ensures the selected day falls within the current month
      const day = _.find(week, (day) => moment(day).isSame(moment(selected), 'month'));
      onChange(day);
    }
  };

  const handleChangeDay = (day) => {
    if (typeof onChange === 'function') {
      onChange(day);
    }
  };

  return (
    <Picker>
      <Header>
        <ViewButton
          type="button"
          isHome={(scope !== 'month' && view === 'day') || (scope === 'month' && view === 'month')}
          onClick={handleToggleView}>
          {moment(selected).format(scope === 'month' ? 'YYYY' : 'MMMM YYYY')}
          <Icon icon="angle-down" />
        </ViewButton>
        <DirectionButton type="button" onClick={handlePrevious}>
          <Icon icon="angle-left" />
        </DirectionButton>
        <DirectionButton type="button" onClick={handleNext}>
          <Icon icon="angle-right" />
        </DirectionButton>
      </Header>
      {view === 'day' && scope === 'day' && (
        <Days>
          <DayHeader>Mo</DayHeader>
          <DayHeader>Tu</DayHeader>
          <DayHeader>We</DayHeader>
          <DayHeader>Th</DayHeader>
          <DayHeader>Fr</DayHeader>
          <DayHeader>Sa</DayHeader>
          <DayHeader>Su</DayHeader>
          {days.map((day) => {
            const dayProps = getDayProps ? getDayProps(day) : null;

            return moment(day).isSame(moment(selected), 'month') ? (
              <DayButton
                type="button"
                key={day}
                isActive={day === date}
                isToday={day === today}
                disabled={dayProps?.disabled}
                onClick={handleChangeDay.bind(this, day)}>
                {moment(day).format('D')}
                {dayProps?.tooltip && <InlineTooltip message={dayProps?.tooltip} />}
              </DayButton>
            ) : (
              <DayOutsideMonth key={day}>{moment(day).format('D')}</DayOutsideMonth>
            );
          })}
        </Days>
      )}
      {view === 'day' && scope === 'week' && (
        <Weeks>
          <WeekHeader>
            <DayHeader>Mo</DayHeader>
            <DayHeader>Tu</DayHeader>
            <DayHeader>We</DayHeader>
            <DayHeader>Th</DayHeader>
            <DayHeader>Fr</DayHeader>
            <DayHeader>Sa</DayHeader>
            <DayHeader>Su</DayHeader>
          </WeekHeader>
          {_.chunk(days, 7).map((week, weekIndex) => (
            <Week
              key={`${moment(week[0]).format('YYYY-MM')}_W${weekIndex}`}
              isActive={_.includes(week, date)}
              onClick={handleChangeWeek.bind(this, week)}>
              {week.map((day) =>
                moment(day).isSame(moment(selected), 'month') ? (
                  <Day key={day} isToday={day === today} isActive={_.includes(week, date)}>
                    {moment(day).format('D')}
                  </Day>
                ) : (
                  <DayOutsideMonth key={day} isActive={_.includes(week, date)}>
                    {moment(day).format('D')}
                  </DayOutsideMonth>
                ),
              )}
            </Week>
          ))}
        </Weeks>
      )}
      {view === 'month' && (
        <Months>
          {months.map((month) => (
            <DateButton
              type="button"
              key={month}
              isActive={moment(month).isSame(moment(date), 'month')}
              isToday={moment(month).isSame(moment(today), 'month')}
              onClick={handleChangeMonth.bind(this, month)}>
              {moment(month).format('MMM')}
            </DateButton>
          ))}
        </Months>
      )}
      {view === 'year' && (
        <Years>
          {years.map((year) => (
            <DateButton
              type="button"
              key={year}
              isActive={date && moment(date).year() === year}
              isToday={moment(today).year() === year}
              onClick={handleChangeYear.bind(this, year)}>
              {year}
            </DateButton>
          ))}
        </Years>
      )}
    </Picker>
  );
}

export default DayPicker;
