import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

//http://localhost:3000/api/www/workspaces/4ff1cb07-b0a1-40fa-95f1-998ee5cb8017/invoices/4be643ab-f244-4036-bc83-2632643ae758/transactions/time?q=&projectId=354e4b4a-8f9a-49b6-8037-9a9080f98bbb

export default function InvoiceLineInstruction() {
  return (
    <>
      <p>Organize your CSV contents for invoice line imports into the following columns:</p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Invoice Number</td>
            <td>Yes</td>
            <td>Unique invoice number for identification.</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>No</td>
            <td>Description or details about the invoice line item.</td>
          </tr>
          <tr>
            <td>Line Number</td>
            <td>Yes</td>
            <td>Order number for the line on the invoice.</td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td>Yes</td>
            <td>Quantity of the item or service.</td>
          </tr>
          <tr>
            <td>Rate</td>
            <td>Yes</td>
            <td>Rate or price per unit of the item or service.</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>Yes</td>
            <td>Total amount for the line (typically, Quantity * Rate).</td>
          </tr>
          <tr>
            <td>Taxable</td>
            <td>Yes</td>
            <td>Whether the line item is taxable or not (true or false).</td>
          </tr>
          <tr>
            <td>Project Name</td>
            <td>No</td>
            <td>Project Name, if related to a project for the invoiced client.</td>
          </tr>
          <tr>
            <td>Transaction Type</td>
            <td>Yes</td>
            <td>Type of transaction: 'time', 'milestone', 'expense', 'other_item'.</td>
          </tr>
          <tr>
            <td>Item</td>
            <td>No</td>
            <td>Name or description of the item.</td>
          </tr>
          <tr>
            <td>QBO Line ID</td>
            <td>No</td>
            <td>QuickBooks Online Line ID, if integrating with QBO.</td>
          </tr>
          <tr>
            <td>Legacy ID</td>
            <td>No</td>
            <td>Previous system's identifier for the line, if applicable.</td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
