import React from 'react';
import InvoiceMilestonesTable from './InvoiceMilestonesTable';
import OtherItemsTable from './OtherItemsTable';
import ProjectExpenses from './ProjectExpenses';
import RevenueRecognitionLedger from './RevenueRecognitionLedger';

export default function AccountingTab({
  project,
  formik,
  projectModel,
  onProjectExpensesChange,
  onInvoiceMilestonesChange,
  onOtherItemsChange,
  onRevenueRecognitionLedgerChange,
  onRefetch,
}) {
  return (
    <>
      {['fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) && (
        <InvoiceMilestonesTable projectModel={projectModel} onChange={onInvoiceMilestonesChange} />
      )}

      {['fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) &&
        ['manual', 'automated_hours', 'automated_tm_basis'].includes(projectModel.revenueRecognitionMethod) && (
          <RevenueRecognitionLedger
            project={project}
            formik={formik}
            projectModel={projectModel}
            onRefetch={onRefetch}
            onChange={onRevenueRecognitionLedgerChange}
          />
        )}

      {['tm', 'fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) && (
        <OtherItemsTable projectModel={projectModel} onChange={onOtherItemsChange} />
      )}

      <ProjectExpenses projectModel={projectModel} onChange={onProjectExpensesChange} />
    </>
  );
}
