import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function QBOProjectSelect({ disabled, qboCustomerId, include, ...props }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [query, setQuery] = useState({ isReady: false, data: [] });
  const [filterValue, setFilterValue] = useState('');
  const isMounted = useIsMounted();

  const options = useMemo(() => {
    let options = query.data.filter(
      (project) => project.id === include || (!!qboCustomerId && project.customer?.id === qboCustomerId),
    );

    if (!filterValue) {
      return options;
    }

    const fuse = new Fuse(options, { keys: ['id', 'name'] });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [query.data, filterValue, qboCustomerId, include]);

  const selectedOption = useMemo(() => _.find(query.data, { id: props.value }), [query.data, props.value]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).qbo.projects.qbo();
        if (!isMounted.current) return;
        setQuery({ isReady: true, data });
      } catch {
        // Ignore
      }
    })();
  }, [api, workspace.id, isMounted]);

  return (
    <SingleSelect
      disabled={disabled || !query.isReady}
      showFilter={true}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption?.name || ''}
      {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldQBOProjectSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <QBOProjectSelect {...field} {...props} />
    </FieldControl>
  );
}

export default QBOProjectSelect;
export { FieldQBOProjectSelect };
