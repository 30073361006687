import _ from 'lodash';
import projectStatuses from '~/lookups/project-statuses';
import React from 'react';
import Tag from './Tag';

export default function ProjectStatusTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Project Status" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? projectStatuses[v].name)
        .join('; ')}
    </Tag>
  );
}
