import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from '~/components';
import { colors } from '~/styles';
import { getCachedPreviewUrl, getIconFromMIME } from '~/utils';
import { imagePreviewFileTypes } from '~/utils/fileTypes';
import _ from 'lodash';

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.3125rem;
`;

const Container = styled(Tooltip)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.5rem;
  height: 10.5rem;
  margin: 0.5rem;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  overflow: hidden;

  &:hover ${Overlay} {
    display: flex;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black};
  border-radius: 0.3125rem;
  opacity: 0.5;
`;

const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PreviewIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const PreviewIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 3rem;
`;

const FileNameInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const FileExt = styled.div`
  flex-shrink: 0;
`;

const Buttons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  z-index: 2;
`;

const ButtonTooltip = styled(Tooltip)`
  width: 2rem;
  height: 2rem;
  margin: 0.25rem;
  padding: 0;
  font-size: 0.825rem;
  background-color: ${colors.white};
  border-radius: 50%;
  z-index: 2;

  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

function FilePreview({ file }) {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [icon, setIcon] = useState(null);

  const fileNameInfo = useMemo(() => {
    const nameArray = file.name.split('.');
    if (nameArray.length < 2) {
      return {
        name: file.name,
        ext: '',
      };
    }
    const ext = '.' + nameArray.pop();
    const name = nameArray.join('.');
    return { name, ext };
  }, [file]);

  const downloadFileUrl = useMemo(() => {
    if (file.url && file.url.includes('?')) {
      return file.url + '&download=1';
    } else if (file.url) {
      return file.url + '?download=1';
    } else {
      return null;
    }
  }, [file.url]);

  const fetchPreview = useCallback(async (id, url) => {
    setIsLoading(true);
    try {
      const cacheKey = `time_attachment_file_preview__${id}`;
      const previewUrl = await getCachedPreviewUrl(cacheKey, url);
      setPreview(previewUrl);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Set the icon
    setIcon(getIconFromMIME(file.type));
    // Set or download the preview image, if available
    if (imagePreviewFileTypes.includes(file.type)) {
      if (file.dataUrl) {
        setPreview(file.dataUrl);
      } else if (file.url) {
        fetchPreview(file.id, file.url);
      }
    }
    return fetchPreview.cancel;
  }, [file, fetchPreview]);

  return (
    <Container data-testid="file">
      {preview ? (
        <PreviewImage src={preview} />
      ) : (
        <PreviewIconContainer>
          {icon && <PreviewIcon icon={icon}></PreviewIcon>}
          <FileNameInfo>
            <FileName>{fileNameInfo.name}</FileName>
            <FileExt>{fileNameInfo.ext}</FileExt>
          </FileNameInfo>
        </PreviewIconContainer>
      )}
      <Overlay>
        <Buttons>
          {file.url && (
            <ButtonTooltip message="Open">
              <Button isAnchor onClick={() => window.open(file.url, '_blank')}>
                <Icon icon="external-link-alt" />
              </Button>
            </ButtonTooltip>
          )}
          {file.url && (
            <ButtonTooltip message="Download">
              <Button isAnchor onClick={() => window.open(downloadFileUrl)}>
                <Icon icon="download" />
              </Button>
            </ButtonTooltip>
          )}
        </Buttons>
        <Background />
      </Overlay>
      {isLoading && (
        <StatusContainer>
          <Icon icon="spinner" spin color={colors.grey55} />
        </StatusContainer>
      )}
    </Container>
  );
}

export default function AttachmentsTab({ files }) {
  return (
    <Files>
      {_(files)
        .map((file) => <FilePreview key={file.id} file={file} />)
        .value()}
    </Files>
  );
}
