import { Button, Buttons, CancelButton, Form, Field, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import { emptyStringToNull } from '~/utils';
import { Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import * as Yup from 'yup';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function ClientCollapseConfirmation({ client, onClose, onCollapse }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['targetClient']),
        targetClientId: values.targetClient?.id ?? null,
      });
      const { data } = await api.www.workspaces(workspace.id).clients(client.id).collapse(body);

      await onCollapse(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const [hasDependencies, setHasDependencies] = useState(null);

  useEffect(() => {
    (async () => {
      // const { data: hasDependencies } = await api.www.workspaces(workspace.id).clients(client.id).hasDependencies();
      setHasDependencies(false);
    })();
  }, [api, client.id, workspace.id]);

  // async function handleCollapse() {
  //   try {
  //     form.submit();
  //     await api.www.workspaces(workspace.id).clients(client.id).collapse(client.id);

  //     await onCollapse();
  //   } catch ({ status, message }) {
  //     return form.error({ message });
  //   } finally {
  //     // Refresh the timer because deleting a client may delete its related time entry
  //     notify(useSubscription.keys.refresh_timer);
  //     notify(useSubscription.keys.refresh_time_approval_count);
  //     notify(useSubscription.keys.refresh_expense_approval_count);
  //   }
  // }

  const initialValues = {
    name: '',
    currency: workspace.currency,
    owner: null,
  };

  if (hasDependencies === null) return null;

  return (
    <ModalCard title="Collapse Client" onClose={onClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          targetClient: Yup.object().label('Target Client').nullable(),
        })}>
        {(formik) => {
          return (
            <Form>
              <ModalCard.Body>
                <P>
                  This will collapse the <strong>{client.name}</strong> client into the selected "Target Client" below.
                  All its related data including any projects, tasks, time entries, expense items, invoices, and
                  payments will be moved to the client selected, <strong>{client.name}</strong> will not be deleted.
                </P>

                <Form.Control>
                  <Field.ClientSelect
                    name="targetClient"
                    placeholder="Client"
                    allowNew
                    isInternal={false}
                    permission="manageDraftInvoices"
                    clearable={false}
                    // onChange={handleClientChange}
                  />
                </Form.Control>
                {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose}>Close</CancelButton>
                  <Button onClick={formik.submitForm} isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
