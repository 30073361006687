import { useApi } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function TimeZoneSelect({ value, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  const handleSearch = (q) => {
    return options.filter((o) => [o.text, o.offset].some((f) => f.toLowerCase().includes(q.toLowerCase())));
  };

  useEffect(() => {
    (async () => {
      const { data } = await api.timeZones().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value?.id ?? '',
      },
    });
  };

  const selectedOption = useMemo(() => {
    return options?.find((o) => o.id === value);
  }, [options, value]);

  return (
    <AutoComplete
      value={selectedOption}
      defaultOptions={options}
      displayText={selectedOption ? `${selectedOption.offset} ${selectedOption.text}` : ''}
      renderOption={(option) => `${option.offset} ${option.text}`}
      onSearch={handleSearch}
      onChange={handleChange}
      {...props}
    />
  );
}

function FieldTimeZoneSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <TimeZoneSelect {...field} {...props} />
    </FieldControl>
  );
}

export default TimeZoneSelect;
export { FieldTimeZoneSelect };
