import { weights } from '~/styles';
import styled from 'styled-components';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
  }
`;

export default Section;
