import React from 'react';
import styled from 'styled-components';
import { BackLink } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  padding: 0.5rem 0;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const Description = styled.p`
  color: ${colors.grey55};
  font-size: 0.875rem;
`;

const Buttons = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: auto;
  padding-left: 1.5rem;
`;

function CustomDataHeader({ title, description, children }) {
  const { workspace } = useWorkspace();

  return (
    <Wrapper>
      <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />
      {(title || description) && (
        <Details>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
        </Details>
      )}
      {children}
    </Wrapper>
  );
}

CustomDataHeader.Details = Details;
CustomDataHeader.Title = Title;
CustomDataHeader.Description = Description;
CustomDataHeader.Buttons = Buttons;

export default CustomDataHeader;
