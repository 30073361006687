import { Currency, DateTime, Dropdown, ExpenseLockIcon, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ExpenseAmountWidget from '../ExpenseAmountWidget';
import expenseLockedReasons from '../expenseLockedReason';
import DeleteExpenseItemConfirmation from '../DeleteExpenseItemConfirmation';

const SelectionArea = styled.div`
  display: flex;
`;

function ExpenseItemRow({ item, onSaved }) {
  const {
    id,
    vendorName,
    lockStatusId,
    expenseCategory,
    unitAmount,
    unitCount,
    permissions,
    project,
    date,
    isReimbursable,
    isReimbursed,
  } = item;
  const isLocked = lockStatusId !== 'unlocked';
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();
  const confirmation = useConfirmation();
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleOpenItem = (item, readOnly = false) => {
    history.push({
      pathname: readOnly || isLocked ? `${url}/item/${item.id}/view` : `${url}/item/${item.id}/edit`,
      search: location.search,
      state: { scrollToTop: false },
    });
  };

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteExpenseItemConfirmation
        id={id}
        onClose={resolve}
        onDelete={async () => {
          await onSaved();
          resolve();
        }}
      />
    ));
    if (!confirm) return;
  };

  const handleReimbursedStatusChange = async (item, flag) => {
    await api.www.workspaces(workspace.id).expenseAdmin(item.id).setReimbursedStatus(flag);
    onSaved();
  };

  return (
    <>
      <Table.BoxRow key={id} onClick={() => handleOpenItem(item)}>
        <Table.Cell>
          <SelectionArea>
            {isLocked && <ExpenseLockIcon value={lockStatusId} message={expenseLockedReasons[lockStatusId]} />}
          </SelectionArea>
        </Table.Cell>
        <Table.Cell>
          <DateTime value={date} />
        </Table.Cell>
        <Table.Cell>
          <Table.CellContent>
            {expenseCategory.name}
            {vendorName && !expenseCategory.unitName && <small>{vendorName}</small>}
            {expenseCategory.unitName && (
              <small>
                {unitCount} {expenseCategory.unitName}(s) x <Currency value={unitAmount} maximumFractionDigits={4} />{' '}
                per {expenseCategory.unitName}
              </small>
            )}
          </Table.CellContent>
        </Table.Cell>
        <Table.Cell>
          {project && (
            <Table.CellContent>
              {project.name}
              <small>{project.client.name}</small>
            </Table.CellContent>
          )}
        </Table.Cell>
        <Table.Cell>
          <ExpenseAmountWidget item={item} />
        </Table.Cell>
        <TableBoxRowActions>
          {isLocked ? (
            <TableBoxRowActions.View onClick={() => handleOpenItem(item, true)} />
          ) : (
            <TableBoxRowActions.Edit onClick={() => handleOpenItem(item)} />
          )}

          <hr />
          <TableBoxRowActions.Dropdown>
            {({ setIsOpen }) => {
              const handleAction = (action) => setIsOpen(false) || action;

              return (
                <>
                  <Dropdown.Item onClick={() => handleOpenItem(item, true)}>View</Dropdown.Item>
                  <Dropdown.Item
                    disabled={isLocked}
                    tooltip={isLocked ? expenseLockedReasons[lockStatusId] : undefined}
                    onClick={() => handleAction(handleOpenItem(item))}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={isLocked}
                    tooltip={isLocked ? expenseLockedReasons[lockStatusId] : undefined}
                    onClick={() => handleAction(handleDelete())}>
                    Delete
                  </Dropdown.Item>

                  <Dropdown.Item
                    disabled={!permissions.reimburse || !isReimbursable}
                    tooltip={
                      !permissions.reimburse
                        ? 'You do not have administrative privileges to set the reimbursed status.'
                        : !isReimbursable
                          ? 'Expense item is not set as reimbursable.'
                          : undefined
                    }
                    onClick={async () => {
                      handleAction(await handleReimbursedStatusChange(item, !isReimbursed));
                    }}>
                    {isReimbursed ? 'Set as Not Reimbursed' : 'Set as Reimbursed'}
                  </Dropdown.Item>
                </>
              );
            }}
          </TableBoxRowActions.Dropdown>
        </TableBoxRowActions>
      </Table.BoxRow>
    </>
  );
}

export default ExpenseItemRow;
export { ExpenseItemRow };
