import { useCallback, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useApi, useTimeEntries, useWorkspace } from '~/contexts';

const size = 10;

function useDirectionalEntries(date, member, direction) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { id: workspaceId } = workspace;
  const { entries, updateEntries } = useTimeEntries();
  const page = useRef(0);
  const [ids, setIds] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const fetch = useCallback(async () => {
    const currentPage = page.current;
    const query = { date, page: currentPage, size, direction };
    if (member) {
      query.memberId = member.id;
    }

    const { data } = await api.www.workspaces(workspaceId).timeEntries().getList(query);

    const results = data?.results ?? [];
    const total = data?.total ?? 0;

    const resultIds = _.map(results, (entity) => entity.id);
    setIds((ids) => _.union(ids, resultIds));
    updateEntries(results);
    setHasMore(total - currentPage * size - results.length > 0);
  }, [api, date, member, direction, workspaceId, updateEntries]);

  const reset = useCallback(async () => {
    page.current = 0;
    setIds([]);
    setHasMore(false);
    await fetch();
  }, [fetch]);

  const load = useCallback(async () => {
    if (hasMore) {
      page.current += 1;
      await fetch();
    }
  }, [fetch, hasMore]);

  const memberId = useMemo(() => (member ? member.id : workspace.member.id), [member, workspace]);

  const data = useMemo(() => {
    return _(ids)
      .map((id) => _.find(entries, { id, memberId }))
      .filter((entry) => !!entry)
      .value();
  }, [ids, entries, memberId]);

  return { data, hasMore, reset, load };
}

export default function useListEntries(date, member) {
  const after = useDirectionalEntries(date, member, 'after');
  const before = useDirectionalEntries(date, member, 'before');

  const { data: afterData, hasMore: afterHasMore, reset: resetAfter, load: loadAfter } = after;
  const { data: beforeData, hasMore: beforeHasMore, reset: resetBefore, load: loadBefore } = before;

  const data = useMemo(
    () => _.orderBy(_.unionBy(beforeData, afterData, 'id'), ['date', 'createdAt', 'id'], ['desc', 'desc', 'asc']),
    [afterData, beforeData],
  );

  const load = useCallback(async () => {
    await resetAfter();
    await resetBefore();
  }, [resetAfter, resetBefore]);

  return { data, afterHasMore, beforeHasMore, load, loadAfter, loadBefore };
}
