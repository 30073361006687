import {
  Avatar,
  Grid,
  Icon,
  Level,
  MemberContactPopover,
  MultilineText,
  Page,
  PipelineActivityTypeFilter,
  SearchInput,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useNumberFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { ActivityItem } from '../components/ActivityItem';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
  }
`;

const Panel = styled.div`
  position: absolute;
  width: calc(20% + 2.5rem);
  background: ${colors.grey5};
  right: -2rem;
  top: calc(-2.5rem - 1px);
  min-height: calc(100% + 4.5rem + 1px);
  padding: 2.75rem 2rem;
`;

const Info = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
`;

const Tag = styled(Link)`
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 0.2rem;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

function Assignments({ assignments }) {
  const { workspace } = useWorkspace();

  const assignmentsByType = assignments.reduce((acc, assignment) => {
    acc[assignment.type] = acc[assignment.type] || [];
    acc[assignment.type].push(assignment);
    acc[assignment.type] = _.orderBy(acc[assignment.type], ['name'], ['asc']);

    return acc;
  }, {});

  return _.map(assignmentsByType, (assignments, type) => (
    <InfoBlock title={pluralize(type)} key={type}>
      {assignments.map((assignment) => {
        const { clientId, companyId, opportunityId, id } = assignment;
        const getRoute = (type) => {
          switch (type) {
            case 'client':
              return `clients/${clientId}/overview`;
            case 'company':
              return `pipeline/companies/${companyId}/overview`;
            case 'opportunity':
              return `pipeline/opportunities/${opportunityId}/overview`;
          }
        };

        return (
          <Tag to={`/app/${workspace.key}/${getRoute(type)}`} key={id}>
            <p>{assignment.name}</p>
          </Tag>
        );
      })}
    </InfoBlock>
  ));
}

function ContactDetailsPage({ contact }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          contactId: contact.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });
      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          options.push({ type: 'activity', activity, group });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, contact, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!contact) return <ErrorPage.NotFound />;

  return (
    <Page.Section style={{ position: 'relative', flex: 1 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="75%">
            <Section>
              <Level>
                <Level.Item>
                  <h3>Activities</h3>
                </Level.Item>
                <Level.Item right>
                  <SearchInput
                    value={params.q}
                    placeholder="Search"
                    materialPlaceholder="Subject or Content"
                    materialAlwaysVisible
                    onChange={handleChange}
                  />
                </Level.Item>
                <Level.Item>
                  <PipelineActivityTypeFilter
                    name="typeIds"
                    value={params.typeIds}
                    entity={'contact'}
                    onChange={handleChange}
                  />
                </Level.Item>
              </Level>

              <Stack style={{ marginTop: '3rem', opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
                {status === 'loading' && <PageLoader />}
                {groupedOptions?.length > 0 &&
                  groupedOptions.map((o) => {
                    switch (o.type) {
                      case 'group':
                        return (
                          <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                            <div style={{ textTransform: 'uppercase' }}>
                              <strong>
                                {
                                  {
                                    upcoming: 'Upcoming',
                                    last30Days: 'Last 30 Days',
                                    olderThan30Days: ' Older Than 30 Days',
                                  }[o.group.key]
                                }
                              </strong>
                              <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                            </div>

                            <div style={{ fontSize: '0.8rem' }}>
                              {pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}
                            </div>
                          </GroupHeader>
                        );
                      case 'activity': {
                        const item = o.activity;
                        const participants = [
                          ...item.members.map((activityMember) => activityMember.member.name),
                          ...item.contacts.map((activityContact) => activityContact.contact.name),
                        ];

                        if (!showActivities[o.group.key]) return null;

                        return <ActivityItem key={item.id} item={item} participants={participants} entity="contact" />;
                      }
                    }
                  })}
                {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
              </Stack>
            </Section>
          </Grid.Column>
          <Grid.Column width="2rem"></Grid.Column>
        </Grid.Row>
      </Grid>
      <Panel>
        <Section>
          <h3>Contact Details</h3>
          <InfoBlock title="First Name">{contact.firstName}</InfoBlock>
          <InfoBlock title="Last Name">{contact.lastName}</InfoBlock>
          <InfoBlock title="Email">{contact.email}</InfoBlock>
          <Assignments assignments={contact.assignments} />
          {contact.owner && (
            <InfoBlock title="Relationship Owner">
              <Owner>
                <MemberContactPopover member={contact.owner}>
                  <Avatar value={contact.owner} isCircle hasBackground initialsFontSize=".9rem" />
                </MemberContactPopover>
                <OwnerInfo>{contact.owner.name}</OwnerInfo>
              </Owner>
            </InfoBlock>
          )}
          {contact.mobilePhone && <InfoBlock title="Mobile Phone">{contact.mobilePhone}</InfoBlock>}
          {contact.officePhone && <InfoBlock title="Office Phone">{contact.officePhone}</InfoBlock>}
          {contact.fax && <InfoBlock title="Fax">{contact.fax}</InfoBlock>}
          {contact.title && <InfoBlock title="Title">{contact.title}</InfoBlock>}
          {contact.description && (
            <InfoBlock title="Description">
              <MultilineText value={contact.description} chars={280} buttonProps={{ isAnchor: true }} />
            </InfoBlock>
          )}
          {contact.websiteUrl && (
            <InfoBlock title="Website">
              <a href={contact.websiteUrl}>{contact.websiteUrl}</a>
            </InfoBlock>
          )}
        </Section>
      </Panel>
    </Page.Section>
  );
}
export default ContactDetailsPage;
export { ContactDetailsPage };
