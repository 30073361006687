import _ from 'lodash';
import { useMemo } from 'react';
import useLocale from './useLocale';

const defaultOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

/**
 *
 * @param {{ locale, minimumFractionDigits, maximumFractionDigits }} options
 * @returns A localized percent formatter.
 */
export default function usePercentFormat({ locale, ...options } = {}) {
  locale = useLocale(locale);

  return useMemo(
    () =>
      Intl.NumberFormat(
        locale,
        _.defaults(
          {
            style: 'percent',
          },
          options,
          defaultOptions,
        ),
      ),
    [locale, options],
  );
}
