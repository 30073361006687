import _ from 'lodash';
import { memberInvitationStatuses } from '~/lookups';
import React from 'react';
import Tag from './Tag';

export default function MemberInvitationStatusTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Member Invitation Status" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? memberInvitationStatuses[v].name)
        .join('; ')}
    </Tag>
  );
}
