import { useApi } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function SicCodeSelect({ value, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.sicCodes().get({ q });

      return data;
    },
    [api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value?.id ?? '',
      },
    });
  };

  const selectedOption = useMemo(() => {
    return defaultOptions?.find((o) => o.id === value);
  }, [defaultOptions, value]);

  return (
    <AutoComplete
      value={selectedOption}
      defaultOptions={defaultOptions}
      displayText={selectedOption ? `${selectedOption.code} - ${selectedOption.industryTitle}` : ''}
      renderOption={(option) => `${option.code} - ${option.industryTitle}`}
      onSearch={handleSearch}
      onChange={handleChange}
      {...props}
    />
  );
}

function FieldSicCodeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <SicCodeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default SicCodeSelect;
export { FieldSicCodeSelect };
