import {
  ActionButton,
  Button,
  Buttons,
  CancelButton,
  Icon,
  InlineTooltip,
  ModalCard,
  SplitButton,
  Table,
} from '~/components';
import { Card } from '~/components/ModalCard';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { arrayMove } from '~/utils';
import ApproverMultiSelect from './ApproverMultiSelect';

const BoxRow = styled(Table.BoxRow)`
  position: relative;

  ${({ $dragging }) =>
    $dragging &&
    css`
      > div {
        transition: 150ms all ease-in-out;
        opacity: 0.5;
      }
    `}

  ${({ $over }) =>
    $over &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -7px;
        left: 0;
        border-top: 2px solid ${colors.primary};
      }
    `}
`;

const Row = styled(Table.Row)`
  position: relative;
  border-bottom: 0;

  ${({ $over }) =>
    $over &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 5px;
        left: 0;
        border-top: 2px solid ${colors.primary};
      }
    `}
`;

const Handle = styled.div`
  cursor: move;
  font-size: 1rem;
  color: ${colors.grey40};
  transition: 150ms all ease-in-out;
  padding: 0.5rem 0.25rem;

  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
  }
`;

const Modal = styled(ModalCard)`
  ${Card} {
    width: 54rem;
  }
`;

const ModalCardBody = styled(ModalCard.Body)`
  overflow-y: scroll;
  height: 75vh;
`;

export default function ApprovalWorkflowEditor({
  value,
  onSubmit,
  onClose,
  onLoadWorkspaceWorkflow,
  title = 'Edit Workflow',
}) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [steps, setSteps] = useState(() => value?.steps || [{ approvers: [] }]);

  // Load the options once and reuse for every step.
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).members().options({ isActive: true, size: 1000 });

      setOptions([
        { type: 'role', approvalRoleKey: 'project_admin', approvalRole: { name: 'Project Admin' } },
        { type: 'role', approvalRoleKey: 'client_owner', approvalRole: { name: 'Client Relationship Owner' } },
        { type: 'role', approvalRoleKey: 'member_manager', approvalRole: { name: `Member Manager` } },
        ...data.map((member) => ({ type: 'member', memberId: member.id, member: { name: member.name } })),
      ]);
    })();
  }, [api, workspace.id]);

  const [drag, setDrag] = useState({ index: null, over: null });

  const handleDragStart = (index) => {
    setDrag({ index, over: null });
  };

  const handleDragEnd = () => {
    setDrag({ index: null, over: null });
  };

  const handleDragOver = (index) => {
    if (!drag || drag.over === index) {
      return;
    }
    setDrag({ ...drag, over: index });
    return false;
  };

  const handleDrop = (index) => {
    const reorderedSteps = arrayMove(steps, drag.index, index);
    setSteps(reorderedSteps);
  };

  const handleAddStep = () => {
    setSteps((steps) => [...steps, { approvers: [] }]);
  };

  const [errors, setErrors] = useState([]);

  const validate = useCallback(() => {
    const errors = [];

    if (steps.length === 0) errors.push('The approval workflow must have at least one step.');
    if (steps.some((s) => s.approvers.length === 0)) errors.push('Each step must have at least one approver.');

    setErrors(errors);

    return errors;
  }, [steps]);

  const [{ isSubmitting }, form] = useForm();

  const handleSubmit = async () => {
    const errors = validate();

    if (errors.length > 0) return;

    form.submit();
    await onSubmit({
      steps: steps.map((s) => ({ approvers: s.approvers.map((a) => _.pick(a, 'memberId', 'approvalRoleKey')) })),
    });
    form.done();
  };

  useEffect(() => {
    validate();
  }, [validate]);

  return (
    <Modal title={title} onClose={onClose}>
      <ModalCardBody>
        <Table>
          <Table.BoxHeader>
            <Table.Column width="0" />
            <Table.Column width="4rem" align="center">
              Step
            </Table.Column>
            <Table.Column>Approvers</Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>

          <Table.Body>
            {steps.map((step, index) => {
              const handleApproversChange = ({ target: { value } }) => {
                setSteps((steps) => steps.map((s, i) => (index === i ? { ...s, approvers: value } : s)));
              };

              const handleRemoveStep = () => {
                setSteps((steps) => steps.filter((s, i) => i !== index));
              };

              const isOnlyStep = steps.length <= 1;

              return (
                <BoxRow
                  key={index}
                  draggable={index === drag?.index}
                  $dragging={index === drag?.index}
                  $over={index === drag?.over}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => {
                    e.preventDefault();
                    handleDragOver(index);
                  }}
                  onDrop={() => handleDrop(index)}>
                  <Table.Cell>
                    <Handle onMouseDown={() => handleDragStart(index)} onMouseUp={handleDragEnd}>
                      <Icon icon="grip-vertical" />
                    </Handle>
                  </Table.Cell>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    <div style={{ width: '100%' }}>
                      <ApproverMultiSelect
                        options={options}
                        value={step.approvers}
                        offsetToShowMenuOnTop={200}
                        onChange={handleApproversChange}
                      />
                    </div>
                  </Table.Cell>
                  <TableBoxRowActions>
                    <TableBoxRowActions.Delete
                      disabled={isOnlyStep}
                      tooltip={isOnlyStep ? 'The approval workflow must have at least one step.' : null}
                      onClick={handleRemoveStep}
                    />
                  </TableBoxRowActions>
                </BoxRow>
              );
            })}

            <Row
              $over={steps.length === drag?.over}
              onDragEnter={(event) => event.preventDefault()}
              onDragOver={(event) => {
                event.preventDefault();
                handleDragOver(steps.length);
              }}
              onDrop={() => handleDrop(steps.length - 1)}>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAddStep}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Add Step
                </Button>
              </Table.Cell>
            </Row>
          </Table.Body>
        </Table>
      </ModalCardBody>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>

          {_.isFunction(onLoadWorkspaceWorkflow) ? (
            <SplitButton>
              <ActionButton
                isLoading={isSubmitting}
                style={{ position: 'relative' }}
                type="submit"
                disabled={errors.length > 0}
                onClick={handleSubmit}>
                Update Workflow
                {errors.length > 0 && <InlineTooltip message={errors[0]} />}
              </ActionButton>

              <SplitButton.Menu position="top">
                {({ setIsOpen }) => {
                  const handleLoadWorkspaceWorkflow = async () => {
                    setIsOpen(false);

                    const { data } = await onLoadWorkspaceWorkflow();
                    setSteps(data.steps);
                  };

                  return (
                    <SplitButton.Item onClick={handleLoadWorkspaceWorkflow}>Load Workspace Workflow</SplitButton.Item>
                  );
                }}
              </SplitButton.Menu>
            </SplitButton>
          ) : (
            <ActionButton
              isLoading={isSubmitting}
              style={{ position: 'relative' }}
              type="submit"
              disabled={errors.length > 0}
              onClick={handleSubmit}>
              Save & Close
              {errors.length > 0 && <InlineTooltip message={errors[0]} />}
            </ActionButton>
          )}
        </Buttons>
      </ModalCard.Footer>
    </Modal>
  );
}
