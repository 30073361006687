import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Buttons, Confirmation, DeleteConfirmation, Dropdown, Icon, Level, Page, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useSession, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useIsMounted } from '~/hooks';
import { authMethods, authProviders } from '~/lookups';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { ActiveStatusSelect } from '../custom-data/components';
import AuthProviderForm from './AuthProviderForm';
import SamlSettings from './SamlSettings';

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
  font-size: 2.5rem;
`;

export default function AuthProviders() {
  const documentTitle = useDocumentTitle('Authentication');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const toast = useToast();
  const [params, setParams] = useState({ isActive: 'true' });
  const isMounted = useIsMounted();
  const {
    session: { memberSessions },
    isAdmin,
  } = useSession();

  const authProviderId = memberSessions?.find((ms) => ms.workspaceId === workspace.id).authProviderId;

  const { path, url } = useRouteMatch();
  const history = useHistory();

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).authProviders().get(params);
    if (!isMounted.current) return;
    setData(data);
  }, [workspace.id, isMounted, api, params]);

  async function handleActiveStatusChange(item, flag) {
    if (!flag) {
      const confirm = await confirmation.prompt((resolve) => (
        <DeleteConfirmation resolve={resolve} title="Deactivate Auth Provider" deleteLabel="Deactivate">
          <Level>
            <Level.Item narrow>
              <WarningIcon icon="exclamation-triangle" />
            </Level.Item>
            <Level.Item>
              <p>
                Workspace members won't be able to login using the <strong>{item.name}</strong> provider. Do you want to
                deactivate this auth provider?
              </p>
            </Level.Item>
          </Level>
        </DeleteConfirmation>
      ));
      if (!confirm) return;
    }

    try {
      await api.www.workspaces(workspace.id).authProviders(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleSetDefault(item) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve} title="Set Default Auth Provider">
        <Level>
          <Level.Item narrow>
            <WarningIcon icon="exclamation-triangle" />
          </Level.Item>
          <Level.Item>
            <p>
              By setting <strong>{item.name}</strong> as the default auth provider, the login page for all workspace
              members will show the login form for this provider. Do you want to set this auth provider as the default?
            </p>
          </Level.Item>
        </Level>
      </Confirmation>
    ));
    if (!confirm) return;

    try {
      await api.www.workspaces(workspace.id).authProviders(item.id).setDefault();
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const confirmation = useConfirmation();

  const handleDelete = async (item) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve} title="Delete Auth Provider">
        <Level>
          <Level.Item narrow>
            <WarningIcon icon="exclamation-triangle" />
          </Level.Item>
          <Level.Item>
            <p>
              By deleting <strong>{item.name}</strong>, workspace members won't be able to login using this provider. Do
              you want to delete this auth provider?
            </p>
          </Level.Item>
        </Level>
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).authProviders(item.id).delete();

    fetchData();

    return true;
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = async (authProviderId) => {
    history.push(url.concat(`/${authProviderId}/edit`));
  };

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/authentication`);
    documentTitle.set('Authentication');
  }

  if (!data) return <PageLoader />;

  return (
    <Page.Section>
      <Level>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
        </Level.Item>

        <Level.Item right narrow>
          <Buttons>
            <Link to={url.concat('/new')} className="button">
              New Auth Provider
            </Link>
          </Buttons>
        </Level.Item>
      </Level>

      <Page.Section>
        <Table.Total value={data.length} label="Auth Provider" />
        <Table>
          <Table.BoxHeader>
            <Table.Column>Name</Table.Column>
            <Table.Column>Key</Table.Column>
            <Table.Column>Provider</Table.Column>
            <Table.Column>Method</Table.Column>
            <Table.Column width="6.5rem" align="right">
              Sessions
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>

          <Table.Body>
            {data.map((ap) => {
              const isLastActive = data.length === 1;
              const isDefault = ap.isDefault;
              const isRuddr = ap.provider === 'ruddr';
              const isCurrent = ap.id === authProviderId;

              return (
                <Table.BoxRow key={ap.id} isDisabled={!ap.isActive}>
                  <Table.Cell>
                    {ap.name}{' '}
                    {ap.isDefault && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="The default auth provider.">
                        <Icon icon="badge-check" />
                      </Tooltip>
                    )}
                    {!ap.isValid && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="This auth provider configuration is invalid.">
                        <Icon icon="exclamation-triangle" color={colors.warning} />
                      </Tooltip>
                    )}
                  </Table.Cell>
                  <Table.Cell>{ap.key}</Table.Cell>
                  <Table.Cell>{authProviders[ap.provider].name}</Table.Cell>
                  <Table.Cell>{authMethods[ap.method].name}</Table.Cell>
                  <Table.Cell>{ap.memberSessionCount}</Table.Cell>
                  <TableBoxRowActions>
                    {isRuddr ? (
                      <TableBoxRowActions.View onClick={() => handleEdit(ap.id)} />
                    ) : (
                      <TableBoxRowActions.Edit onClick={() => handleEdit(ap.id)} />
                    )}
                    <hr />
                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return (
                          <>
                            <Dropdown.Item onClick={() => handleAction(() => handleEdit(ap.id))}>
                              {isRuddr ? 'View' : 'Edit'}
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={isLastActive || isDefault || isCurrent}
                              tooltip={
                                isDefault
                                  ? 'Cannot deactivate the default auth provider.'
                                  : isLastActive
                                    ? 'Cannot deactivate the last active auth provider.'
                                    : isCurrent
                                      ? `Cannot deactivate the auth provider that you're currently logged in with.`
                                      : undefined
                              }
                              onClick={async () => {
                                await handleActiveStatusChange(ap, !ap.isActive);
                                setIsOpen(false);
                              }}>
                              {ap.isActive ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={isRuddr || isLastActive || isDefault || isCurrent}
                              tooltip={
                                isRuddr
                                  ? 'Cannot delete the Ruddr auth provider.'
                                  : isDefault
                                    ? 'Cannot delete the default auth provider.'
                                    : isLastActive
                                      ? 'Cannot delete the last active auth provider.'
                                      : isCurrent
                                        ? `Cannot delete the auth provider that you're currently logged in with.`
                                        : undefined
                              }
                              onClick={() => setIsOpen(false) || handleDelete(ap)}>
                              Delete
                            </Dropdown.Item>

                            {!ap.isDefault && (
                              <Dropdown.Item
                                disabled={!ap.isActive || (!isCurrent && !isAdmin)}
                                tooltip={
                                  !ap.isActive
                                    ? 'Cannot set an inactive auth provider as default.'
                                    : !isCurrent && !isAdmin
                                      ? `Login with this auth provider to set it as the default.`
                                      : undefined
                                }
                                onClick={async () => {
                                  await handleSetDefault(ap);
                                  setIsOpen(false);
                                }}>
                                Set to default
                              </Dropdown.Item>
                            )}

                            {ap.method === 'saml' && (
                              <Dropdown.Item
                                onClick={() => {
                                  history.push(url.concat(`/${ap.id}/saml`));
                                }}>
                                SAML Settings
                              </Dropdown.Item>
                            )}
                          </>
                        );
                      }}
                    </TableBoxRowActions.Dropdown>
                  </TableBoxRowActions>
                </Table.BoxRow>
              );
            })}
          </Table.Body>
        </Table>
      </Page.Section>
      <Switch>
        <Route path={`${path}/new`}>
          <AuthProviderForm onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>

        <Route path={`${path}/:authProviderId/edit`}>
          <AuthProviderForm onClose={handleCloseDrawer} onSaved={fetchData} onDelete={handleDelete} />
        </Route>

        <Route path={`${path}/:authProviderId/saml`}>
          <SamlSettings onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>
      </Switch>
    </Page.Section>
  );
}
