import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Drawer, FormMessage, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import MemberMappingRow from './MemberMappingRow';

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const ErrorPeople = styled.ul`
  margin-bottom: 0.5rem;

  > li {
    margin-top: 0.5rem;
  }
`;

const ErrorMembers = styled.ul`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  list-style: inside disc;
`;

export default function MemberMappingDrawer({ onClose, onSave }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, saved, status }, form] = useForm();
  const [editIndex, setEditIndex] = useState(null);
  const [memberMappings, setMemberMappings] = useState([]);
  const [people, setPeople] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);
  const drawerRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: memberMappings } = await api.www.workspaces(workspace.id).expensify.getMemberMappings();
        const { data: people } = await api.www.workspaces(workspace.id).expensify.getPeople();

        setMemberMappings(memberMappings);
        setPeople(people);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  const saveMemberMapping = (mapping) => {
    setDirty(true);
    setMemberMappings((memberMappings) => {
      const memberMapping = _.find(memberMappings, { id: mapping.id });
      if (memberMapping) {
        return memberMappings.map((m) => (m.id === mapping.id ? mapping : m));
      }
      return [...memberMappings, mapping];
    });
    setEditIndex(null);
  };

  const deleteMemberMapping = (mapping) => {
    setDirty(true);
    setMemberMappings((memberMappings) => memberMappings.filter((m) => m.id !== mapping.id));
  };

  const validateMemberMappings = () => {
    const simpleMappings = _.map(memberMappings, (mapping) => ({
      member: mapping.member.name,
      person: mapping.expensifyEmail,
    }));

    const duplicatePeople = _(simpleMappings)
      .groupBy((mapping) => mapping.person)
      .pickBy((group) => group.length > 1)
      .value();

    const people = _.keys(duplicatePeople).sort();

    if (people.length === 0) {
      return null;
    }
    return (
      <>
        <p>Duplicate people identified:</p>
        <ErrorPeople>
          {people.map((person) => (
            <li key={person}>
              {person}
              <ErrorMembers>
                {duplicatePeople[person].map((duplicate, dupIndex) => (
                  <li key={`${dupIndex}_${duplicate.member}`}>{duplicate.member}</li>
                ))}
              </ErrorMembers>
            </li>
          ))}
        </ErrorPeople>
        <p>All Expensify people must be unique to help ensure expenses are routed to a single member.</p>
      </>
    );
  };

  return (
    <Drawer
      isOpen
      title="Member Mappings"
      byline="Expensify Integration"
      ref={drawerRef}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          const message = validateMemberMappings();
          if (message) {
            form.error({ message });
            drawerRef.current.scrollTo({ top: 0 });
            return;
          }

          const mappings = memberMappings.map((mapping) => ({
            id: mapping.id.startsWith('temp_') ? undefined : mapping.id,
            ..._.pick(mapping, ['memberId', 'expensifyEmail']),
          }));

          try {
            await await api.www.workspaces(workspace.id).expensify.updateMemberMappings(mappings);
            form.save();
            onSave();
            closeDrawer();
          } catch (error) {
            form.error({ message: error.message });
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}
            <Table>
              <Table.BoxHeader>
                <Table.Column>Ruddr Member</Table.Column>
                <Table.Column>Expensify Person</Table.Column>
                <Table.BoxActionsColumn />
              </Table.BoxHeader>
              <Table.Body>
                {memberMappings.map((mapping, index) => (
                  <MemberMappingRow
                    key={mapping.id}
                    mapping={mapping}
                    people={people}
                    disableActions={editIndex !== null}
                    isEditing={editIndex === index}
                    onEdit={() => setEditIndex(index)}
                    onCancel={() => setEditIndex(null)}
                    onSave={saveMemberMapping}
                    onDelete={deleteMemberMapping}
                  />
                ))}
                <MemberMappingRow
                  isNew={true}
                  people={people}
                  disableActions={editIndex !== null}
                  isEditing={editIndex === -1}
                  onEdit={() => setEditIndex(-1)}
                  onCancel={() => setEditIndex(null)}
                  onSave={saveMemberMapping}
                />
              </Table.Body>
            </Table>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
