import { ActionButton, Buttons, CancelButton, Checkbox, Icon, Level, ModalCard } from '~/components';
import { useApi, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const WarningIcon = styled(Icon)`
  font-size: 2.5rem;
`;

const Warning = ({ children }) => {
  return (
    <Level margin="1rem 0">
      <Level.Item narrow>
        <WarningIcon icon="exclamation-triangle" color={colors.warning} />
      </Level.Item>
      <Level.Item>{children}</Level.Item>
    </Level>
  );
};

function PublishInvoiceDialog({ invoiceId, resolve, onClose }) {
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();
  const [lineItemStatus, setLineItemStatus] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [saveToQuickBooks, setSaveToQuickBooks] = useState(() => integrations.qbo);
  const [saveToXero, setSaveToXero] = useState(() => integrations.xero);
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).invoices(invoiceId).getLineItemStatus();

      const { data: invoice } = await api.www.workspaces(workspace.id).invoices(invoiceId).get();

      setLineItemStatus(data);
      setInvoice(invoice);
    })();
  }, [api, workspace.id, invoiceId]);

  const handleSubmit = async () => {
    form.submit();

    let result;

    const { data } = await api.www.workspaces(workspace.id).invoices(invoiceId).publish();

    result = data;

    if (saveToQuickBooks) {
      try {
        const { data } = await api.www.workspaces(workspace.id).invoices(invoiceId).saveToQuickBooks();

        result = data.invoice;
      } catch (error) {
        toast.error(
          error.message ??
            'An error has occurred. The invoice has not been saved to QuickBooks. Please review the QuickBooks integration settings and try again.',
        );
      }
    } else if (saveToXero) {
      try {
        const { data } = await api.www.workspaces(workspace.id).invoices(invoiceId).saveToXero();

        result = data.invoice;
      } catch (error) {
        toast.error(
          error.message ??
            'An error has occurred. The invoice has not been saved to Xero. Please review the Xero integration settings and try again.',
        );
      }
    }

    await resolve(result);
    form.save();
    toast.success(`Invoice has been published with number #${result.number}.`);
  };

  const handleClose = () => {
    onClose();
  };

  const showLineAmountMismatch = invoice?.lines.some(
    (line) => !!line.transactionType && line.transactionAmount !== line.amount,
  );

  if (!invoice) return null;
  if (!lineItemStatus) return null;

  return (
    <ModalCard title="Publish Invoice" onClose={handleClose}>
      <ModalCard.Body>
        <P>
          Are you sure you want to publish this invoice? Once an invoice is published, it cannot be changed unless it is
          unpublished.
        </P>
        {(lineItemStatus.unapprovedTime || lineItemStatus.unapprovedExpenses) && (
          <Warning>
            There are unapproved time and/or expense entries associated with this invoice. Those will be automatically
            approved when this invoice is published.
          </Warning>
        )}

        {lineItemStatus.runningTimer && (
          <Warning>
            There are one or more timers running that are associated with this invoice. Those timers will be stopped
            when this invoice is approved.
          </Warning>
        )}

        {showLineAmountMismatch && (
          <Warning>
            One or more rows on this invoice has an amount that differs from the amount that Ruddr is expecting. Any
            such rows are indicated by a warning sign on the row.
          </Warning>
        )}
      </ModalCard.Body>

      <ModalCard.Footer>
        <Level>
          {integrations.qbo && (
            <Level.Item>
              <Checkbox
                label="Save to QuickBooks"
                checked={saveToQuickBooks}
                onChange={() => setSaveToQuickBooks(!saveToQuickBooks)}
              />
            </Level.Item>
          )}

          {integrations.xero && (
            <Level.Item>
              <Checkbox label="Save to Xero" checked={saveToXero} onChange={() => setSaveToXero(!saveToXero)} />
            </Level.Item>
          )}

          <Level.Item>
            <Buttons align="right">
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <ActionButton
                isLoading={isSubmitting}
                ok={saved}
                data-testid="publish_invoice_confirm"
                onClick={handleSubmit}>
                Publish
              </ActionButton>
            </Buttons>
          </Level.Item>
        </Level>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default PublishInvoiceDialog;
