import slackIcon from '~/assets/public/slack-icon.svg';
import { Icon } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import RemoveSlackConfirmation from './RemoveSlackConfirmation';
import SlackError from './SlackError';
import SlackSettings from './SlackSettings';
import { BambooIntegration } from './bamboohr';
import ExpensifyIntegration from './expensify/ExpensifyIntegration';
import QBOIntegration from './qbo/QBOIntegration';
import XeroIntegration from './xero/XeroIntegration';
import QBDIntegration from './qbd/QBDIntegration';
import SalesforceIntegration from './salesforce/SalesforceIntegration';

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.625rem;
  border-bottom: solid 1px ${colors.grey10};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: ${weights.light};
  line-height: 1.2;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.25rem -0.625rem;
  padding: 2rem 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10.25rem;
  height: 9.375rem;
  margin: 1.25rem 0.625rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border: solid 0.125rem ${colors.white};
  border-radius: 0.3125rem;
  overflow: hidden;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 2.625rem;
  height: 2.625rem;
`;

const Name = styled.p`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const Actions = styled.div`
  display: flex;
  height: 2rem;
  background-color: ${colors.grey10};
`;

const ActionStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0;
  color: ${colors.black};
  font-size: 0.75rem;
  font-weight: ${weights.normal};
  background-color: ${colors.grey5};
  border-radius: 0;

  &:not(:first-child) {
    margin-left: 1px;
  }

  &:hover {
    color: ${colors.black};
    background-color: ${colors.grey10};
  }
`;

const ActionAnchor = styled.a`
  ${ActionStyle}
`;

const ActionButton = styled.button`
  ${ActionStyle}
`;

const CSVIcon = styled(Icon)`
  height: 2.625rem;
`;

function SlackIntegration({ integration, onChange }) {
  const scopes = integration.scopes.join(',');
  const confirmation = useConfirmation();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleRemove = () => {
    confirmation.prompt((resolve) => (
      <RemoveSlackConfirmation
        onClose={resolve}
        onRemove={() => {
          if (typeof onChange === 'function') {
            onChange();
          }
          resolve(true);
        }}
      />
    ));
  };

  return (
    <Item>
      <Description>
        <Image src={slackIcon} />
        <Name>Slack</Name>
      </Description>
      {integration.isConnected ? (
        <Actions>
          <ActionButton onClick={() => history.push(`${url}/slack`)}>Settings</ActionButton>
          <ActionButton onClick={handleRemove}>Remove</ActionButton>
        </Actions>
      ) : (
        <Actions>
          <ActionAnchor
            href="https://help.ruddr.io/hc/en-us/articles/360059111573-Slack-integration-overview"
            target="_blank"
            rel="noopener noreferrer">
            Details
          </ActionAnchor>
          <ActionAnchor
            href={`https://slack.com/oauth/v2/authorize?client_id=${integration.clientId}&scope=${scopes}&state=${workspace.id}`}>
            Install
          </ActionAnchor>
        </Actions>
      )}
    </Item>
  );
}

function CSVImport() {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <Item>
      <Description>
        <CSVIcon icon="file-csv" size="2x" />
        <Name>CSV Import</Name>
      </Description>
      <Actions>
        <ActionButton onClick={() => history.push(`${url}/csv`)}>Import</ActionButton>
      </Actions>
    </Item>
  );
}

function Integration(props) {
  switch (props.integration?.id) {
    case 'bamboohr':
      return <BambooIntegration {...props} />;
    case 'expensify':
      return <ExpensifyIntegration {...props} />;
    case 'slack':
      return <SlackIntegration {...props} />;
    case 'qbo':
      return <QBOIntegration {...props} />;
    case 'xero':
      return <XeroIntegration {...props} />;
    case 'csv':
      return <CSVImport {...props} />;
    case 'qbd':
      return <QBDIntegration {...props} />;
    case 'salesforce':
      return <SalesforceIntegration {...props} />;

    default:
      return null;
  }
}

export default function Integrations() {
  const documentTitle = useDocumentTitle('Integrations');

  const api = useApi();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [integrations, setIntegrations] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const isMounted = useIsMounted();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get('error');

  const activeIntegrations = useMemo(
    () => (integrations ? _.filter(integrations, { isConnected: true }) : []),
    [integrations],
  );
  const availableIntegrations = useMemo(
    () => (integrations ? _.filter(integrations, { isConnected: false }) : []),
    [integrations],
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).integrations().get();

      if (!isMounted.current) return;

      setIntegrations(data);
      setIsReady(true);
    } catch (error) {
      setIntegrations([]);
      throw error;
    }
  }, [workspace, api, isMounted]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeSettings = () => {
    documentTitle.set('Integrations');
    history.push(url);
  };

  const closeError = () => {
    if (!params.has('error')) {
      return;
    }

    params.delete('error');

    const query = params.toString();
    if (query.length > 0) {
      history.push(`${location.pathname}?${query}`);
    } else {
      history.push(location.pathname);
    }
  };

  if (!isReady && !integrations.length) return <PageLoader />;
  if (!integrations.length) return <ErrorPage publicSite={false} />;
  return (
    <Page>
      {activeIntegrations.length > 0 && (
        <Section>
          <Title>Active Integrations</Title>
          <List>
            {activeIntegrations.map((integration) => (
              <Integration key={integration.id} integration={integration} onChange={fetchData} />
            ))}
          </List>
        </Section>
      )}
      {availableIntegrations.length > 0 && (
        <Section>
          <Title>Available Integrations</Title>
          <List>
            {availableIntegrations.map((integration) => (
              <Integration key={integration.id} integration={integration} onChange={fetchData} />
            ))}
          </List>
        </Section>
      )}
      <Switch>
        <Route path={`${path}/slack`} exact>
          <SlackSettings onClose={closeSettings} />
        </Route>
      </Switch>
      {error === 'slack-in-use' && <SlackError onClose={closeError} />}
    </Page>
  );
}
