import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { emptyStringToNull, mergeValues } from '~/utils';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

export default function ApiKeyForm({ apiKey, onClose, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = mergeValues(
    {
      name: '',
    },
    apiKey,
  );

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .apiKeys(apiKey ? apiKey.id : null)
        .upsert(emptyStringToNull(values));

      onFinish(data);
      onClose();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <ModalCard title={apiKey ? 'Edit API Key' : 'Create API Key'} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('Name').max(255),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Description>
                  Add a name to your API key
                  {apiKey ? (
                    <>
                      {' '}
                      (...<code>{apiKey.tokenLast4}</code>)
                    </>
                  ) : null}{' '}
                  to make it easier to identify (not required).
                </Description>
                <Form.Control>
                  <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                </Form.Control>
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                    Cancel
                  </CancelButton>
                  <Button type="submit" isLoading={isLoading}>
                    {apiKey ? 'Save' : 'Submit'}
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
