import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import {
  BackLink,
  ClientSelect,
  Currency,
  DateTime,
  DayPickerInput,
  Grid,
  Level,
  Stack,
  Table,
  Widget,
  WorkspaceCurrencySelect,
} from '~/components';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import { PageLoader } from '~/routes/public/pages';
import Header from '../components/Header';
import ExportDropdown from '../components/ExportDropdown';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import moment from 'moment';
import ExportDialogAsync from '../components/ExportDialogAsync';

export default function ClientStatement() {
  useDocumentTitle('Client Statement');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      table: false,
    },
  });

  const [params, setParams] = useState({
    start: null,
    end: null,
    date: null,
    client: null,
    currency: null,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        start: {
          default: intervalOptions.last_month.start,
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        end: {
          default: intervalOptions.last_month.end,
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        client: searchParamsConfig.client,
        currency: { ...searchParamsConfig.currency, default: null },
      }),
      [searchParamsConfig],
    ),
    onChage: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.start ?? undefined,
      end: params.end ?? undefined,
      date: params.date ?? undefined,
      clientId: params.client ? params.client.id : undefined,
      currency: params.currency ?? undefined,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    if (!urlSearchParams.clientId) {
      setQuery((state) => ({
        ...state,
        data: {},
        status: 'ready',
        loading: { table: false },
      }));
      return;
    }
    const { data } = await api.www.workspaces(workspace.id).reports().clientStatement(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus, params]);

  const handleClientChange = (client) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, client, currency: features.multicurrency ? client.currency : params.currency });
    searchParams.set({ client, currency: features.multicurrency ? client.currency : params.currency });
  };

  const handleStartDateChange = (start) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, start });
    searchParams.set({ start });
  };

  const handleEndDateChange = (end) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, end });
    searchParams.set({ end });
  };

  const handleStatementDateChange = (date) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, date });
    searchParams.set({ date });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .clientStatement(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header defaultPath={`/app/${workspace.key}/reports/financial`}>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />
        <Stack>
          <h1>Client Statement</h1>
        </Stack>
        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => {
                const disabled = !query.data;

                return (
                  <>
                    <ExportDropdown.Item
                      tooltip={disabled ? 'Missing required filters' : undefined}
                      onClick={async () => {
                        await handleExport(mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      tooltip={disabled ? 'Missing required filters' : undefined}
                      onClick={async () => {
                        await handleExport(mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      tooltip={disabled ? 'Missing required filters' : undefined}
                      onClick={async () => {
                        await handleExport(mimeTypes.pdf);
                        setIsOpen(false);
                      }}>
                      Export to PDF
                    </ExportDropdown.Item>
                  </>
                );
              }}
            </ExportDropdown>
          </Level.Item>
        </Level>
      </Header>

      <Level style={{ marginTop: '2rem' }}>
        <Level.Item width="20rem">
          <ClientSelect
            name="client"
            placeholder="Select a Client"
            materialAlwaysVisible
            materialPlaceholder="Client"
            activeOnly={false}
            permission="viewPublishedInvoices"
            value={params.client}
            clearable={false}
            onChange={({ target: { value } }) => handleClientChange(value)}
          />
        </Level.Item>

        <Level.Item width="20rem">
          <DayPickerInput
            value={params.start}
            placeholder="Start Date"
            clearable={false}
            scope={'day'}
            onChange={(value) => handleStartDateChange(value)}
          />
        </Level.Item>

        <Level.Item width="20rem">
          <DayPickerInput
            value={params.end}
            placeholder="End Date"
            clearable={false}
            scope={'day'}
            onChange={(value) => handleEndDateChange(value)}
          />
        </Level.Item>

        <Level.Item width="20rem">
          <DayPickerInput
            value={params.date}
            placeholder="Statement Date"
            clearable={false}
            scope={'day'}
            onChange={(value) => handleStatementDateChange(value)}
          />
        </Level.Item>

        {features.multicurrency && (
          <Level.Item width="20rem">
            <WorkspaceCurrencySelect
              value={params.currency}
              placeholder="Currency"
              clearable={false}
              onChange={({ target: { value } }) => handleCurrencyChange(value)}
            />
          </Level.Item>
        )}
      </Level>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return <Data query={query} params={params} />;
        }
      })()}
    </Report>
  );
}

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const Value = styled.div`
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

const OpenColumn = styled(Grid.Column)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    ${Box} {
      border-right: 1px solid ${colors.grey10};
    }
  }

  ${Box} {
    width: 110%;

    ${Value} {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }
`;

const StyledWidget = styled(Widget)`
  margin-top: 2rem;
  margin-bottom: -2rem;
  background-color: ${colors.white};
  padding: 1;
`;

function Data({ query, params }) {
  const { rows, currency, totals, openingBalance } = query.data;

  if (!params.client) return;

  return (
    <>
      <StyledWidget>
        <Grid style={{ flex: 1 }}>
          <Grid.Row style={{ flex: 1 }}>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals.current} currency={currency} />
                </Value>
                <Label>Current Due</Label>
              </Box>
            </OpenColumn>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals['01-30']} currency={currency} />
                </Value>
                <Label>1 - 30 Days Past Due</Label>
              </Box>
            </OpenColumn>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals['31-60']} currency={currency} />
                </Value>
                <Label>31 - 60 Days Past Due</Label>
              </Box>
            </OpenColumn>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals['61-90']} currency={currency} />
                </Value>
                <Label>61 - 90 Days Past Due</Label>
              </Box>
            </OpenColumn>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals['91+']} currency={currency} />
                </Value>
                <Label>90+ Days Past Due</Label>
              </Box>
            </OpenColumn>
            <OpenColumn size={2}>
              <Box>
                <Value>
                  <Currency value={totals.total} currency={currency} />
                </Value>
                <Label>Amount Due</Label>
              </Box>
            </OpenColumn>
          </Grid.Row>
        </Grid>
      </StyledWidget>
      <Report.Table>
        <Table>
          <Table.Header sticky>
            <Table.Column width="20rem" name="date">
              Date
            </Table.Column>
            <Table.Column name="description">Description</Table.Column>
            <Table.Column width="10rem" align="right" name="date">
              Amount
            </Table.Column>
            <Table.Column width="10rem" align="right" name="date">
              Balance
            </Table.Column>
          </Table.Header>
          <TableBody>
            <Table.Row>
              <Table.Cell>
                <DateTime value={params.start} />
              </Table.Cell>
              <Table.Cell>Opening Balance</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Currency value={openingBalance.amount} currency={currency} />
              </Table.Cell>
            </Table.Row>
            {rows.map((row) => {
              return (
                <Table.Row key={row.id}>
                  <Table.Cell>
                    <DateTime value={row.date} />
                  </Table.Cell>
                  <Table.Cell>{row.description}</Table.Cell>
                  <Table.Cell>
                    <Currency value={row.amount} currency={currency} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency value={row.balance} currency={currency} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}
