import { Field, HelpTooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function CapHoursCheckbox() {
  return (
    <Control>
      <Field.Checkbox name="capHours" label="Cap the hours" />

      <HelpTooltip
        message="Do not allow a time entry to be saved if it will cause the project to exceed its billable or non-billable hours budget."
        style={{ marginLeft: '.5rem' }}
      />
    </Control>
  );
}
