// List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
// See also: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
const mimeTypeIcons = {
  // Documents
  'application/pdf': 'file-pdf',
  'application/msword': 'file-word',
  'application/vnd.ms-word': 'file-word',
  'application/vnd.oasis.opendocument.text': 'file-word',
  'application/vnd.openxmlformatsfficedocument.wordprocessingml': 'file-word',
  'application/vnd.ms-excel': 'file-excel',
  'application/vnd.openxmlformatsfficedocument.spreadsheetml': 'file-excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
  'application/vnd.ms-powerpoint': 'file-powerpoint',
  'application/vnd.openxmlformatsfficedocument.presentationml': 'file-powerpoint',
  'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
  'text/plain': 'file-alt',
  'text/csv': 'file-alt',
  'text/html': 'file-code',
  'application/json': 'file-code',

  // Archives
  'application/gzip': 'file-archive',
  'application/zip': 'file-archive',
};

export default function getIconFromMIME(mimeType) {
  if (!mimeType) {
    return 'file';
  } else if (mimeType.startsWith('audio')) {
    return 'file-audio';
  } else if (mimeType.startsWith('image')) {
    return 'file-image';
  } else if (mimeType.startsWith('video')) {
    return 'file-video';
  } else if (mimeType in mimeTypeIcons) {
    return mimeTypeIcons[mimeType];
  } else {
    return 'file';
  }
}
