import { useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import Tag from './Tag';

export default function PeriodTag({ value, label = 'Date Range', ...props }) {
  const dateTimeFormat = useDateTimeFormat();

  if (!value?.start && !value?.end) return null;

  return (
    <Tag label={label} {...props}>
      {_.compact([
        value?.start ? dateTimeFormat.format(value.start) : 'Not Set',
        value?.end ? dateTimeFormat.format(value.end) : 'Not Set',
      ]).join(' - ')}
    </Tag>
  );
}
