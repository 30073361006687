function getInitials(name = '') {
  const parts = (name || '').replace(/[¡!@#$%^&*()_+\-=[\]{};':"\\|,.÷×<>/¿?]+/g, '').split(' ');
  let initials = '';
  if (parts.length === 1) {
    initials = parts[0].slice(0, 2);
  } else {
    const first = parts[0].slice(0, 1);
    const last = parts[parts.length - 1].slice(0, 1);
    initials = first + last;
  }
  return initials.toUpperCase();
}

export default getInitials;
