import React, { useEffect, useState } from 'react';
import ProjectTimeByMember from './ProjectTimeByMember';
import ProjectTimeByRole from './ProjectTimeByRole';
import ProjectTimesheets from './ProjectTimesheets';

function ProjectTimeTab({ project }) {
  const [view, setView] = useState(project.useRoles ? 'role' : 'member');

  const handleViewChange = ({ target: { value } }) => setView(value);

  useEffect(() => {
    if (view === 'role' && !project.useRoles) {
      setView('member');
    }
  }, [project.useRoles, view]);

  return (
    <>
      {{
        role: () => <ProjectTimeByRole project={project} view={view} onViewChange={handleViewChange} />,
        member: () => <ProjectTimeByMember project={project} view={view} onViewChange={handleViewChange} />,
        timesheets: () => <ProjectTimesheets project={project} view={view} onViewChange={handleViewChange} />,
      }[view]()}
    </>
  );
}

export default ProjectTimeTab;
