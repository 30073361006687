import { NavLink } from 'react-router-dom';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import classNames from 'classnames';
import { Button } from '~/components';

const Container = styled.ul`
  display: flex;
  border-bottom: 1px solid ${colors.grey10};
  font-size: 0.875rem;
`;

const Item = styled.li`
  margin: 0 1.25rem;

  > a,
  > button {
    display: block;
    padding: 0.625rem 0 calc(0.625rem - 3px);
    border-bottom: 3px solid transparent;
    margin-bottom: -1px;
    text-transform: uppercase;
    color: ${colors.black};
    background-color: transparent;
    border-radius: 0;

    &:hover {
      color: ${colors.black};
    }

    &.active {
      font-weight: ${weights.bold};
      border-bottom: 3px solid ${colors.primary};
    }

    &:not(.active):hover {
      border-bottom-color: ${rgba(colors.primary, 0.4)};
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

function Tabs({ children, selectedIndex, onChange, ...props }) {
  const mappedChildren = React.Children.map(children, (child, index) =>
    child
      ? child.to
        ? child
        : React.cloneElement(child, {
            active: selectedIndex === index,
            index,
            onClick: onChange,
          })
      : null,
  );

  return <Container {...props}>{mappedChildren}</Container>;
}

function Tab({ active, onClick, index, ...props }) {
  return (
    <Item>
      {props.to ? (
        <NavLink {...props} className={classNames({ active })} />
      ) : (
        <Button isAnchor {...props} onClick={() => onClick(index)} className={classNames({ active })} />
      )}
    </Item>
  );
}

export { Tabs, Tab };
