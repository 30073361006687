import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import SendInvoiceDrawer from '../invoice-actions/send-invoice-drawer/SendInvoiceDrawer';
import DeleteInvoiceDialog from '../invoice-dialogs/DeleteInvoiceDialog';
import DraftInvoiceForm from './DraftInvoiceForm';
import PublishedInvoiceView from './PublishedInvoiceView';

function InvoiceDetails() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { invoiceId } = useParams();
  const confirmation = useConfirmation();
  const history = useHistory();
  const [send, setSend] = useState(false);

  const [{ isReady, invoice }, setInvoice] = useState({ isReady: false, invoice: null });

  const ready = ({ invoice }) => setInvoice({ isReady: true, invoice });

  useDocumentTitle(invoice ? `Invoice #${invoice.number}` : undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data: invoice } = await api.www.workspaces(workspace.id).invoices(invoiceId).get();

      ready({ invoice });
    } catch (error) {
      ready({ invoice: null });
    }
  }, [workspace.id, invoiceId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <DeleteInvoiceDialog
        invoice={invoice}
        onClose={resolve}
        onDelete={async () => {
          history.push(`/app/${workspace.key}/billing/invoices`);
          resolve();
        }}
      />
    ));
  };

  const handleClose = () => {
    setSend(false);
  };

  const handlePreview = () =>
    window.open(`${window.location.origin}/${workspace.key}/invoices/${invoice.id}?preview=true`);

  const handleSend = () => {
    setSend(true);
  };

  if (!isReady) return <PageLoader />;
  if (!invoice) return <ErrorPage.NotFound publicSite={false} />;

  if (invoice.statusId === 'draft')
    return (
      <DraftInvoiceForm
        invoice={invoice}
        onChange={fetchData}
        onDelete={handleDelete}
        onReset={fetchData}
        onPreview={handlePreview}
        onSend={handleSend}
      />
    );

  return (
    <>
      <PublishedInvoiceView
        invoice={invoice}
        onChange={fetchData}
        onDelete={handleDelete}
        onReset={fetchData}
        onPreview={handlePreview}
        onSend={handleSend}
      />

      {send && <SendInvoiceDrawer invoice={invoice} onSent={fetchData} onClose={handleClose} />}
    </>
  );
}

export default InvoiceDetails;
