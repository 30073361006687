import checkCSVHeaders from './check-csv-headers';
import checkCSVRows from './check-csv-rows';

export default function checkCSV({ file, rules, onProgress }) {
  return new Promise((resolve, reject) => {
    // Start with initial progress and phases
    onProgress({ percent: 1, status: 'Starting', inProgress: true, phasesCompleted: [] });

    setTimeout(() => {
      checkCSVHeaders({ file, rules })
        .then((headerValidationResult) => {
          if (headerValidationResult.summary?.passesCheck) {
            onProgress({
              percent: 5,
              status: 'Headers checked, validating rows',
              inProgress: true,
              result: headerValidationResult,
              stepsPassed: 1,
            });
            return checkCSVRows({ file, rules, onProgress });
          }

          // HEADERS DIDN'T PASS CHECKS
          else {
            onProgress({
              percent: 100,
              status: 'Done',
              inProgress: false,
              result: headerValidationResult,
              stepsPassed: 1,
            });
            resolve(headerValidationResult);
          }
        })
        .then((rowValidationResult) => {
          if (rowValidationResult) {
            onProgress({
              percent: 100,
              status: 'Done',
              inProgress: false,
              result: rowValidationResult,
              stepsPassed: 2,
            });

            resolve(rowValidationResult);
          }
        })
        .catch((error) => {
          reject(error);
          onProgress({
            percent: 100,
            status: 'Error',
            inProgress: false,
            error: error.message,
          });
        });
    }, 500); // Simulate delay for async operation
  });
}
