import { Icon } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';

import projectStatuses from '~/lookups/project-statuses';
import _ from 'lodash';

const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1;
  width: 15rem;
  padding: 0.25rem 0.5rem;

  cursor: pointer;

  &:hover {
    background-color: ${colors.grey5};
    border-radius: 0.5rem;
  }

  .color-indicator {
    font-size: 0.625rem;

    color: ${({ status }) => {
      return {
        tentative: colors.grey25,
        not_started: colors.warning,
        in_progress: colors.success,
        paused: colors.danger,
        completed: colors.black,
        cancelled: colors.grey55,
      }[status]; // Fallback to 'warning0' if status is not recognized
    }};
  }

  .middle-content {
    flex-grow: 1; /* This will make the div take up any remaining space */
    padding: 0 15px; /* This adds some padding so text doesn't touch the icons, adjust as needed */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3125rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  position: relative;
  transition: opacity 50ms ease-out;
  z-index: 300;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: calc(50% - 0.875rem);
    border: solid 0.875rem transparent;
    border-bottom-color: ${colors.white};
  }
`;

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

function ProjectStatusesPopover({ currentStatus, projectId, onStatusChange, ...props }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const hovering = useRef(false);
  const forceVisible = useRef(false);
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10], // The second value is to set the vertical spacing on the popover.
        },
      },
    ],
  });

  const selectStatus = async (status) => {
    try {
      await api.www.workspaces(workspace.id).projects(projectId).setStatus({ statusId: status.id });

      setVisible(false);
      onStatusChange();

      toast.success(`Project status has been successfully updated to '${status.name}'`);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleMouseEnter = () => {
    hovering.current = true;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, 100);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const filteredProjectStatuses = useMemo(() => {
    return _.filter(projectStatuses, (status) => status.id !== currentStatus.id);
  }, [currentStatus]);

  return (
    <>
      <div ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        ReactDOM.createPortal(
          <Container
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Content style={{ width: '16rem' }}>
              {filteredProjectStatuses.map((status) => {
                return (
                  <Selector
                    key={status.id}
                    isSelected={false}
                    isGreen={false}
                    status={status.id}
                    onClick={() => {
                      selectStatus(status);
                    }}>
                    <Icon icon="circle" className={'color-indicator'} />
                    <div className="middle-content">{status.name}</div>
                  </Selector>
                );
              })}
            </Content>
          </Container>,
          document.body,
        )}
    </>
  );
}

export default ProjectStatusesPopover;
