import { DateTime, Form } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Comments = styled.div`
  padding: 1rem 1.25rem;
  color: ${colors.danger};
  font-size: 0.75rem;
  background-color: ${colors.danger5};
  border-radius: 0.3125rem;

  span {
    font-style: italic;
  }
`;

function RejectionMessage({ historyItem }) {
  return (
    <Form.Control>
      <div>
        <Comments>
          Rejected on <DateTime value={historyItem.createdAt} /> by {historyItem.member.name}
          {historyItem.notes ? (
            <>
              &nbsp;with the following comments: <br />
              <strong>"{historyItem.notes}"</strong>
            </>
          ) : (
            '.'
          )}
        </Comments>
      </div>
    </Form.Control>
  );
}
export default RejectionMessage;
