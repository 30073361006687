import { Hours } from '~/components';
import { mix } from 'polished';
import React, { useMemo } from 'react';
import { theme } from '../../components/schedule';
import { GroupCell } from '../../components/schedule/cells';
import CellHeatmap from './CellHeatmap';
import convertScale from './convertScale';
import Tooltip from './Tooltip';

function AvailableHoursCell({ cell }) {
  const { background, color, heatmap } = useMemo(() => {
    switch (cell.mode) {
      case 'header':
        if (cell.group?.resourceType === 'placeholder')
          return {
            background: theme.cell.total.background,
            color: theme.cell.empty.color,
          };

        return {
          background: theme.cell.total.background,
          color: !cell.hasWorkingDays ? theme.cell.empty.color : theme.cell.total.color,
        };

      case 'heatmap': {
        const ratio = cell.allocated / cell.capacity || 0;

        let background;
        let color;
        let heatmap;

        if (!cell.capacity) {
          background = theme.cell.empty.background;
          color = theme.cell.empty.color;
        } else if (ratio <= 1) {
          background = theme.cell.filled.background;
          heatmap = mix(ratio, theme.cell.heatmap.success.light, theme.cell.heatmap.success.dark);
          color = theme.cell.filled.color;
        } else {
          background = theme.cell.filled.background;
          heatmap = mix(
            Math.min(convertScale(ratio, { min: 1, max: 1.25 }, { min: 0, max: 1 }), 1),
            theme.cell.heatmap.danger.dark,
            theme.cell.heatmap.danger.light,
          );
          color = theme.cell.filled.color;
        }

        return {
          background,
          color,
          heatmap,
        };
      }

      case 'placeholder':
        return {
          background: theme.cell.empty.background,
          color: theme.cell.empty.color,
        };
    }
  }, [cell.mode, cell.capacity, cell.allocated, cell.hasWorkingDays, cell.group?.resourceType]);

  return (
    <GroupCell style={{ background, color }}>
      <Tooltip cell={cell}>
        {cell.capacity ? <Hours value={cell.available} minimumFractionDigits={2} /> : '-'}
        {cell.mode === 'heatmap' && <CellHeatmap style={{ background: heatmap }} />}
      </Tooltip>
    </GroupCell>
  );
}

export default React.memo(AvailableHoursCell);
