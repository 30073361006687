import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Buttons, CancelButton, Drawer, Icon, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ isActive }) => (isActive === false ? colors.grey55 : colors.black)};

  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

const ContactName = styled.div``;

const ContactEmail = styled.div`
  font-size: 0.875rem;
`;

const NotFound = styled.div`
  color: ${colors.grey25};
`;

export default function MemberPreview({ onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [members, setMembers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const mergedData = useMemo(() => {
    const list = members.map((member) => {
      const employee = _.find(employees, (emp) => emp.email?.toLowerCase() === member.email?.toLowerCase());
      return {
        id: member.id,
        member,
        employee,
      };
    });

    for (const employee of employees) {
      const member = _.find(members, (mem) => mem.email?.toLowerCase() === employee.email?.toLowerCase());
      if (!member) {
        list.push({
          id: employee.id,
          member: null,
          employee,
        });
      }
    }

    return list;
  }, [members, employees]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: members } = await api.www.workspaces(workspace.id).bamboohr.getMembers();
        const { data: employees } = await api.www.workspaces(workspace.id).bamboohr.getEmployees();

        setMembers(members);
        setEmployees(employees);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  return (
    <Drawer isOpen title="Member Matching" byline="BambooHR Integration" onClose={onClose}>
      {(closeDrawer) => {
        if (!isReady) {
          return <PageLoader />;
        }
        return (
          <>
            <Table style={{ marginBottom: '2.5rem' }}>
              <Table.BoxHeader>
                <Table.Column>Ruddr Member</Table.Column>
                <Table.Column>BambooHR Employee</Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {mergedData.map((data) => (
                  <Table.BoxRow key={data.id}>
                    <Table.Cell>
                      {data.member ? (
                        <>
                          <Avatar value={data.member} hasBackground isCircle size={40} />
                          <Contact isActive={data.member.isActive}>
                            <ContactName>{data.member.name}</ContactName>
                            <ContactEmail>{data.member.email}</ContactEmail>
                          </Contact>
                        </>
                      ) : (
                        <NotFound>
                          {data.employee?.email ? (
                            data.employee?.isActive ? (
                              <>
                                <Icon icon="check-circle" color={colors.primary} /> Member will be created when synced
                              </>
                            ) : (
                              <>
                                <Icon icon="exclamation-triangle" color={colors.warning} /> Employee is not active and
                                will not be created
                              </>
                            )
                          ) : (
                            <>
                              <Icon icon="exclamation-triangle" color={colors.warning} /> No matching email address
                            </>
                          )}
                        </NotFound>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {data.employee ? (
                        <Contact>
                          <ContactName>{data.employee.name}</ContactName>
                          <ContactEmail>
                            {data.employee.email ? data.employee.email : <NotFound>No email address</NotFound>}
                          </ContactEmail>
                        </Contact>
                      ) : (
                        <NotFound>
                          <Icon icon="exclamation-triangle" color={colors.warning} /> No matching email address
                        </NotFound>
                      )}
                    </Table.Cell>
                  </Table.BoxRow>
                ))}
              </Table.Body>
            </Table>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={closeDrawer}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
