import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

const TimeOffTypeSelect = React.forwardRef(({ memberId, initialTimeOffType, value: timeOffTypeId, ...props }, ref) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [timeOffTypes, setTimeOffTypes] = useState();
  const isMountedRef = useRef(false);

  const timeOffType = useMemo(
    () =>
      _.find(timeOffTypes, { id: timeOffTypeId }) ||
      (initialTimeOffType && _.isEqual(initialTimeOffType.id, timeOffTypeId) ? initialTimeOffType : undefined),
    [timeOffTypes, timeOffTypeId, initialTimeOffType],
  );

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { data } = await api.www.workspaces(workspace.id).timeEntries().timeOffTypes({ memberId });

      if (!isMountedRef.current) {
        return;
      }

      setIsLoading(false);
      setTimeOffTypes(data);
    })();
  }, [api, memberId, workspace.id]);

  return (
    <SingleSelect {...props} value={timeOffTypeId} valueRenderer={timeOffType?.name} disabled={isLoading} ref={ref}>
      {timeOffTypes?.map((timeOffType) => (
        <option key={timeOffType.id} value={timeOffType.id}>
          {timeOffType.name}
        </option>
      ))}
    </SingleSelect>
  );
});

export default TimeOffTypeSelect;
