import { Button, Buttons, FormMessage, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger};
  border-color: ${colors.danger};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.danger};
    border-color: ${colors.danger};
  }
`;

export default function DeleteExpenseItemConfirmation({ id, onClose, onDelete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [formState, form] = useForm();
  const { isSubmitting, status, message } = formState;
  const toast = useToast();

  async function handleDelete() {
    try {
      form.submit();
      await api.www.workspaces(workspace.id).expenseItems(id).delete();
      await onDelete();
      toast.success('Expense Item deleted.');
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }
  return (
    <ModalCard title="Delete Expense Item" onClose={onClose}>
      <ModalCard.Body>
        Are you sure you want to delete this expense item?
        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton isOutline disabled={isSubmitting} onClick={handleDelete}>
            Delete
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
