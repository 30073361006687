import * as React from "react";
const SvgIcloud = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 80 52", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs57" }, /* @__PURE__ */ React.createElement("pattern", { y: 0, x: 0, height: 6, width: 6, patternUnits: "userSpaceOnUse", id: "EMFhbasepattern" }), /* @__PURE__ */ React.createElement("linearGradient", { xlinkHref: "#linearGradient1070", id: "linearGradient1072", x1: -108.90347, y1: 2124.834, x2: -1137.1982, y2: 2110.6506, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(0.07740541,0,0,0.07740541,88.373952,-128.02586)" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "linearGradient1070" }, /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#3e82f4",
  stopOpacity: 1
}, offset: 0, id: "stop1066" }), /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#93dcf7",
  stopOpacity: 1
}, offset: 1, id: "stop1068" }))), /* @__PURE__ */ React.createElement("g", { id: "#ffffffff", transform: "translate(0,-1089)" }), /* @__PURE__ */ React.createElement("g", { id: "#2acbffff-4", transform: "matrix(0.74035173,0,0,0.75447038,-840.95699,-775.15387)", style: {
  fill: "#00ff00"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 1,
  fill: "url(#linearGradient1072)",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 0,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, d: "M 45.864371,0.751258 A 21.518704,21.518704 0 0 0 27.127725,11.764594 11.804325,11.804325 0 0 0 21.976186,10.573131 11.804325,11.804325 0 0 0 10.35494,20.48888 16.255137,16.255137 0 0 0 0.37795502,35.481798 16.255137,16.255137 0 0 0 16.640956,51.721206 16.255137,16.255137 0 0 0 18.679799,51.57426 h 45.187983 a 15.130063,15.202083 0 0 0 0.712824,0.03447 15.130063,15.202083 0 0 0 0.679264,-0.03447 h 1.082164 V 51.49383 A 15.130063,15.202083 0 0 0 79.703385,36.406573 V 36.3774 A 15.130063,15.202083 0 0 0 67.386251,21.477308 21.518704,21.518704 0 0 0 45.864371,0.751258 Z", id: "rect955" }));
export default SvgIcloud;
