import styled from 'styled-components';
import theme from '../theme';

const RowCell = styled.div`
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;

  /* Styles */
  pointer-events: all;
  padding: 1px;

  &:hover {
    background: ${theme.cell.hover.background};
  }
`;

export default RowCell;
