import styled, { css } from 'styled-components';
import theme from '../theme';

const Cell = styled.div`
  /* Layout */
  position: absolute;
  min-width: 1px;
  min-height: 1px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ selectable }) =>
    selectable &&
    css`
      cursor: cell;
    `}

  ${({ selecting }) =>
    selecting &&
    css`
      z-index: 6;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: ${theme.cell.selected.background};

      > div:hover {
        background: transparent;
        filter: none;
      }
    `}
`;

export default Cell;
