import { Percent, Tooltip } from '~/components';
import _ from 'lodash';
import React from 'react';

export default function BudgetTooltip({ value, children }) {
  if (!_.isNumber(value)) return children;

  return (
    <Tooltip
      placement="top"
      message={
        <>
          <ul>
            <li>
              <Percent value={value} /> of budget used.
            </li>
            <li>
              <Percent value={1 - value} /> of budget left.
            </li>
          </ul>
        </>
      }>
      {children}
    </Tooltip>
  );
}
