import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post } from './agent';

const allocations =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'allocations', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      clone: () => post(url.concat('/clone')),
      detailsCard: () => get(url.concat('/details-card')),
      history: () => get(url.concat('/history')),
      split: ({ date }) => post(url.concat('/split'), { date }),
      splitByDay: () => post(url.concat('/split-by-day')),
      splitByDayCount: () => get(url.concat('/split-by-day-count')),
      splitByWeek: () => post(url.concat('/split-by-week')),
      splitByWeekCount: () => get(url.concat('/split-by-week-count')),
      splitByMonth: () => post(url.concat('/split-by-month')),
      splitByMonthCount: () => get(url.concat('/split-by-month-count')),
      removeOverlapping: () => post(url.concat('/remove-overlapping')),
      delete: () => del(url),

      getProjectDetails: (param) => get(url.concat(`/project-details/${param}`)),

      getClientDetails: (params) => get(url.concat(`/client-details/${params}`)),

      memberSchedule: {
        resources: (query = {}) =>
          get(url.concat('/member-schedule/resources').concat(new QueryString(query, { multi: true }).toString(true))),

        assignments: (query = {}) =>
          get(
            url.concat('/member-schedule/assignments').concat(new QueryString(query, { multi: true }).toString(true)),
          ),

        allocations: (query = {}) =>
          get(
            url.concat('/member-schedule/allocations').concat(new QueryString(query, { multi: true }).toString(true)),
          ),

        metrics: {
          headers: (query = {}) => post(url.concat('/member-schedule/metrics/headers'), query),
          resources: (query = {}) => post(url.concat('/member-schedule/metrics/resources'), query),
        },

        export: (query = {}, headers = {}) =>
          get(
            url.concat('/member-schedule/export').concat(new QueryString(query, { multi: true }).toString(true)),
            headers,
          ),
      },

      projectSchedule: {
        projects: (query = {}) =>
          get(url.concat('/project-schedule/projects').concat(new QueryString(query, { multi: true }).toString(true))),

        resources: (query = {}) =>
          get(url.concat('/project-schedule/resources').concat(new QueryString(query, { multi: true }).toString(true))),

        allocations: (query = {}) =>
          get(
            url.concat('/project-schedule/allocations').concat(new QueryString(query, { multi: true }).toString(true)),
          ),

        projectTeam: (query = {}) =>
          get(url.concat('/project-schedule/project-team').concat(new QueryString(query).toString(true))),

        metrics: {
          headers: (query = {}) => post(url.concat('/project-schedule/metrics/headers'), query),
          clients: (query = {}) => post(url.concat('/project-schedule/metrics/clients'), query),
          projects: (query = {}) => post(url.concat('/project-schedule/metrics/projects'), query),
        },

        export: (query = {}, headers = {}) =>
          get(
            url.concat('/project-schedule/export').concat(new QueryString(query, { multi: true }).toString(true)),
            headers,
          ),
      },
    };
  };

export default allocations;
