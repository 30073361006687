import moment from 'moment';
import { useMemo } from 'react';
import { dateFormats } from '~/utils';
import useLocale from './useLocale';

const defaultOptions = { format: dateFormats.compactDate };

/**
 *
 * @param {{ locale, format }} options
 * @returns A localized date and time formatter.
 */
export default function useDateTimeFormat({ locale, format } = {}) {
  locale = useLocale(locale);

  return useMemo(
    () => ({
      format: (value) =>
        moment(value)
          .locale(locale)
          .format(format ?? defaultOptions.format),
    }),
    [locale, format],
  );
}
