import { calculateDaysInPeriod } from '.';

function calculateTotalDaysInPeriods(periods, member) {
  const { activeStartDate, activeEndDate } = member;
  return periods.reduce((a, v) => {
    const daysInPeriod = calculateDaysInPeriod(v.start || activeStartDate, v.end || activeEndDate);
    return a + daysInPeriod || 0;
  }, 0);
}

export default calculateTotalDaysInPeriods;
