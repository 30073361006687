import {
  BackLink,
  Buttons,
  ClientLink,
  Confirmation,
  Currency,
  Hours,
  Icon,
  Level,
  Page,
  ProjectLink,
  Tab,
  Tabs,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import ClientApprovalWebLinkModal from '../actions/ClientApprovalWebLinkModal';
import SendClientApprovalDrawer from '../actions/SendClientApprovalDrawer';
import DateRange from '../components/DateRange';
import { CircleButton, Eyebrow, HeaderTitle, InfoContainer, TabContent, Tag, Tags } from '../components/PageComponents';
import StatusTag from '../components/StatusTag';
import ClientApprovalHistoryDrawer from './ClientApprovalHistoryDrawer';
import ClientApprovalSettingsForm from './ClientApprovalSettingsForm';
import ExpensesTab from './ExpensesTab';
import ProjectActions from './ProjectActions';
import TimeTab from './TimeTab';
import TransactionsDrawer from './TransactionsDrawer';

export default function ClientApprovalProjectPage({ project }) {
  const documentTitle = useDocumentTitle(project.name);

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [dialog, setDialog] = useState(null);
  const { workspace } = useWorkspace();
  const api = useApi();
  const { clientApprovalId } = useParams();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const confirmation = useConfirmation();
  const toast = useToast();
  const { notify } = useSubscription();

  const clientApprovalsListUrl = `/app/${workspace.key}/projects/${project.client.key}/${project.key}/client-approvals`;

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientApprovals(clientApprovalId)
        .get();

      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, api, project.id, clientApprovalId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure that you want to delete this client approval?</Confirmation>
    ));
    if (!confirm) return;

    try {
      await api.www.workspaces(workspace.id).projects(project.id).clientApprovals(clientApprovalId).delete();

      history.push(clientApprovalsListUrl);
      toast.success('Client approval has been deleted.');
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleSend = () => {
    setDialog(
      <SendClientApprovalDrawer clientApproval={clientApproval} onSent={fetchData} onClose={handleCloseDialog} />,
    );
  };

  const handleGetWebLink = () => {
    setDialog(<ClientApprovalWebLinkModal clientApproval={clientApproval} onClose={handleCloseDialog} />);
  };

  const handleCloseDialog = () => setDialog(null);

  // Time
  const handleTimeEntryClick = (timeEntry, mode) => {
    switch (mode) {
      case 'edit':
        setDialog(
          <EditTimeEntry
            id={timeEntry.id}
            memberId={timeEntry.member.id}
            onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(timeEntry.id).update(body)}
            onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
            onSaved={fetchData}
            onDeleted={fetchData}
            onClose={handleTimeEntryDrawerClose}
          />,
        );
        break;

      case 'view':
        setDialog(<ViewTimeEntry id={timeEntry.id} onClose={handleTimeEntryDrawerClose} />);
        break;
    }
  };

  const handleTimeEntryDrawerClose = () => {
    documentTitle.set(project.name);
    handleCloseDialog();
  };

  // Expenses
  const handleExpenseItemClick = (item) => history.push(`${url}/expenses/${item.id}`);

  const handleExpenseItemDrawerClose = () => {
    history.push(`${url}/expenses`);
    documentTitle.set(project.name);
  };

  const handleApplyTransactions = async ({ timeEntries, expenseItems }) => {
    try {
      await api.www
        .workspaces(workspace.id)
        .projects(clientApproval.projectId)
        .clientApprovals(clientApproval.id)
        .updateTransactions({
          timeEntryIds: timeEntries.map((entry) => entry.id),
          expenseItemIds: expenseItems.map((item) => item.id),
        });

      notify(useSubscription.keys.refresh_timer);

      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteTime = async (ids) => {
    await handleApplyTransactions({
      timeEntries: query.data.timeEntries.filter((te) => !ids.includes(te.id)),
      expenseItems: query.data.expenseItems,
    });
  };

  const handleDeleteExpenses = async (ids) => {
    await handleApplyTransactions({
      timeEntries: query.data.timeEntries,
      expenseItems: query.data.expenseItems.filter((e) => !ids.includes(e.id)),
    });
  };

  const handleEditTransactions = (tab) => {
    setDialog(
      <TransactionsDrawer
        clientApproval={clientApproval}
        tab={tab}
        onApply={handleApplyTransactions}
        onClose={handleCloseDialog}
      />,
    );
  };

  const handleEditSettings = () => {
    setDialog(
      <ClientApprovalSettingsForm clientApproval={clientApproval} onSaved={fetchData} onClose={handleCloseDialog} />,
    );
  };

  const handleViewHistory = () => {
    setDialog(
      <ClientApprovalHistoryDrawer
        clientApprovalId={clientApproval.id}
        projectId={project.id}
        onClose={handleCloseDialog}
      />,
    );
  };

  if (!query.isReady) return <PageLoader />;

  if (query.error) return <ErrorPage publicSite={false} />;

  const clientApproval = query.data;

  const currency = clientApproval.project.currency;

  return (
    <>
      <Page>
        <Page.Header data-testid="header">
          <BackLink defaultPath={clientApprovalsListUrl} />

          <InfoContainer>
            <Eyebrow>Client Approval</Eyebrow>

            <HeaderTitle>
              <ProjectLink project={project} />
            </HeaderTitle>

            <Tags>
              <Tag>
                <ClientLink client={project.client} />
              </Tag>

              <StatusTag clientApproval={clientApproval} />

              <Tag>
                <DateRange clientApproval={clientApproval} />
              </Tag>

              {clientApproval.totalHours > 0 && (
                <Tag>
                  <Hours value={clientApproval.totalHours} /> total hours
                </Tag>
              )}

              {clientApproval.totalExpenses && (
                <Tag>
                  <Currency value={clientApproval.totalExpenses} currency={currency} /> total expenses
                </Tag>
              )}
            </Tags>
          </InfoContainer>

          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <Buttons>
              <CircleButton onClick={handleViewHistory} type="button">
                <Icon icon="history" />
              </CircleButton>

              <CircleButton onClick={handleEditSettings} type="button">
                <Icon icon="cog" />
              </CircleButton>
            </Buttons>
          </div>
        </Page.Header>

        <Level style={{ marginTop: '1.6rem', position: 'relative' }}>
          <Level.Item style={{ padding: '0' }}>
            <Tabs>
              <Tab to={`${url}/time`} data-testid="time_tab">
                <TabContent>Time</TabContent>
              </Tab>

              <Tab to={`${url}/expenses`} data-testid="expenses_tab">
                <TabContent>Expenses</TabContent>
              </Tab>
            </Tabs>
          </Level.Item>
        </Level>

        <Page.Section>
          <Switch>
            <Route path={`${path}/time`}>
              <TimeTab
                clientApproval={clientApproval}
                onTimeEntryClick={handleTimeEntryClick}
                onDelete={handleDeleteTime}
              />
            </Route>

            <Route path={`${path}/expenses`}>
              <ExpensesTab
                view="project"
                clientApproval={clientApproval}
                onExpenseItemClick={handleExpenseItemClick}
                onDelete={handleDeleteExpenses}
              />
            </Route>

            <Redirect path="/" to={`${url}/time`} />
          </Switch>
        </Page.Section>
      </Page>

      <Route path={`${path}/time`}>
        <ProjectActions
          onDelete={handleDelete}
          onGetWebLink={handleGetWebLink}
          onSelectTimeAndExpenses={() => handleEditTransactions('time')}
          onSend={handleSend}
        />
      </Route>

      <Route path={`${path}/expenses`}>
        <ProjectActions
          onDelete={handleDelete}
          onGetWebLink={handleGetWebLink}
          onSelectTimeAndExpenses={() => handleEditTransactions('expenses')}
          onSend={handleSend}
        />

        <Route path={path.concat('/expenses/:expenseItemId')}>
          <ExpenseItemDrawer onSaved={fetchData} onClose={handleExpenseItemDrawerClose} />
        </Route>
      </Route>

      {dialog}
    </>
  );
}
