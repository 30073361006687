import React from 'react';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

function InternalClientTooltip() {
  return (
    <Tooltip message="This is your company.">
      <Icon icon="building" color={colors.grey40} spaceLeft />
    </Tooltip>
  );
}

export default InternalClientTooltip;
