import { Pill, Pills, DetailsPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

const Info = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-align: center;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  flex: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

function ProjectPopover({ projectId, placement = 'auto', ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  useEffect(() => {
    if (!projectId || !visible || query.isReady) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).projects(projectId).detailsCard();
        setQuery({ isReady: true, data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, projectId, visible, query.isReady]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const project = query.data;

  return (
    <>
      <span ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        query.isReady &&
        ReactDOM.createPortal(
          <DetailsPopover
            ref={setPopperElement}
            style={{ ...styles.popper, width: '18rem' }}
            {...attributes.popper}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Content data-testid="project_popover">
              <InfoBlock title="Project Name">{project.name}</InfoBlock>
              {project.code && <InfoBlock title="Project Code">{project.code}</InfoBlock>}
              {project.client && <InfoBlock title="Client Name">{project.client.name}</InfoBlock>}
              {project.projectType && <InfoBlock title="Project Type">{project.projectType.name}</InfoBlock>}
              {project.status && <InfoBlock title="Project Status">{project.status.name}</InfoBlock>}
              {project.practice && <InfoBlock title="Practice">{project.practice.name}</InfoBlock>}
              {project.administrators?.length > 0 && (
                <InfoBlock title="Project Admin">
                  {project.administrators.map(({ id, member }) => (
                    <div key={id}>{member.name}</div>
                  ))}
                </InfoBlock>
              )}
              {project.salesRepresentative && (
                <InfoBlock title="Sales Representative">{project.salesRepresentative.name}</InfoBlock>
              )}
              {project.tags?.length > 0 && (
                <InfoBlock title="Tags">
                  <Pills style={{ justifyContent: 'center' }}>
                    {project.tags.map((tag) => (
                      <Pill key={tag.id}>{tag.name}</Pill>
                    ))}
                  </Pills>
                </InfoBlock>
              )}
            </Content>
          </DetailsPopover>,
          document.body,
        )}
    </>
  );
}

export default ProjectPopover;
