import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function PipelineActivityTypeSelect({ value, typeId = null, exclude = null, initialValue, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .pipelineActivityTypes()
        .get({
          q,
          isActive: true,
          typeId: typeId ? typeId : undefined,
          exclude: exclude ? exclude : undefined,
          isRestricted: typeId === 'file' ? undefined : false,
          include: initialValue ? initialValue.id : undefined,
        });

      return data;
    },
    [workspace.id, typeId, exclude, initialValue, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => option.name}
      {...props}>
      {({ options }) => {
        return <AutoComplete.Results showNoResults={!options.length} />;
      }}
    </AutoComplete>
  );
}

function FieldPipelineActivityTypeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <PipelineActivityTypeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default PipelineActivityTypeSelect;
export { FieldPipelineActivityTypeSelect };
