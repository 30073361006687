import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from '~/components';
import { colors } from '~/styles';
import { getCachedPreviewUrl, getIconFromMIME } from '~/utils';
import { imagePreviewFileTypes } from '~/utils/fileTypes';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  border-radius: 0.3125rem;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Container = styled(Tooltip)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.5rem;
  height: 10.5rem;
  margin: 0.5rem;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  overflow: hidden;

  &:hover ${Overlay} {
    display: flex;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black};
  border-radius: 0.3125rem;
  opacity: 0.5;
`;

const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PreviewIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const PreviewIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 3rem;
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const FileNameInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const FileExt = styled.div`
  flex-shrink: 0;
`;

const Buttons = styled.div`
  display: inline;
  flex: 1;
  z-index: 2;
  font-size: 0.825rem;

  & > .button {
    background-color: ${colors.white};
    z-index: 2;
    height: 2rem;
    width: 2rem;
    padding: 0;
    margin-right: 0.5rem;
  }

  & > .button:last-child {
    margin-right: 0;
  }

  & > .button:hover {
    background-color: ${colors.white};
  }
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger50};
  &:hover {
    color: ${colors.danger100};
  }
`;

export default function FilePreview({ file, onRemove }) {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [icon, setIcon] = useState(null);

  const fileNameInfo = useMemo(() => {
    const nameArray = file.name.split('.');
    if (nameArray.length < 2) {
      return {
        name: file.name,
        ext: '',
      };
    }
    const ext = '.' + nameArray.pop();
    const name = nameArray.join('.');
    return { name, ext };
  }, [file]);

  const message = useMemo(() => {
    switch (file.status) {
      case 'uploading':
        return 'Your file is being uploaded to Ruddr.';
      case 'uploaded':
        return 'Your file has been uploaded successfully.';
      case 'error':
        return 'There was a problem uploading your file.';
      default:
        return undefined;
    }
  }, [file]);

  const fetchPreview = useCallback(async (id, url) => {
    setIsLoading(true);
    try {
      const cacheKey = `credit_note_file_preview__${id}`;
      const previewUrl = await getCachedPreviewUrl(cacheKey, url);
      setPreview(previewUrl);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Set the icon
    setIcon(getIconFromMIME(file.type));
    // Set or download the preview image, if available
    if (imagePreviewFileTypes.includes(file.type)) {
      if (file.dataUrl) {
        setPreview(file.dataUrl);
      } else if (file.url) {
        fetchPreview(file.id, file.url);
      }
    }
    return fetchPreview.cancel;
  }, [file, fetchPreview]);

  return (
    <Container message={message ? message : preview ? file.name : undefined}>
      {preview ? (
        <PreviewImage src={preview} />
      ) : (
        <PreviewIconContainer>
          {icon && <PreviewIcon icon={icon}></PreviewIcon>}
          <FileNameInfo>
            <FileName>{fileNameInfo.name}</FileName>
            <FileExt>{fileNameInfo.ext}</FileExt>
          </FileNameInfo>
        </PreviewIconContainer>
      )}
      <Overlay>
        <Buttons>
          {file.url && (
            <Button isAnchor onClick={() => window.open(file.url, '_blank')}>
              <Icon icon="external-link-alt" />
            </Button>
          )}
          {file.url && (
            <Button isAnchor onClick={() => window.open(file.url + '?download=1')}>
              <Icon icon="download" />
            </Button>
          )}
          {file.url && onRemove && (
            <DeleteButton isAnchor onClick={() => onRemove(file)}>
              <Icon icon="trash" />
            </DeleteButton>
          )}
        </Buttons>
        <Background />
      </Overlay>
      {(file.status || isLoading) && (
        <StatusContainer>
          {(file.status === 'uploading' || isLoading) && (
            <Icon icon="spinner" spin color={isLoading ? colors.grey55 : colors.black} />
          )}
          {file.status === 'uploaded' && <Icon icon="check-circle" color={colors.success} />}
          {file.status === 'error' && <Icon icon="exclamation-circle" color={colors.error} />}
        </StatusContainer>
      )}
    </Container>
  );
}
