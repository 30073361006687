import { Buttons, CancelButton, Drawer, Stack } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';

const initialState = { contact: null, isReady: false };
const handlers = {
  ready: ({ contact }) => ({ isReady: true, contact }),
};

const Control = ({ visible = true, children }) => (visible ? <ReadForm.Control>{children}</ReadForm.Control> : null);

const Description = ({ visible = true, label, children }) =>
  visible ? <ReadTextbox label={label} value={children} /> : null;

function ViewContactDrawer({ clientId, companyId, opportunityId, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, contact }, actions] = useActions(handlers, initialState);
  const { contactId, parentTab } = useParams();

  const title = 'Contact Details';

  useDocumentTitle(title);

  const fetchData = useCallback(async () => {
    try {
      const { data: contact } = await api.www
        .workspaces(workspace.id)
        .contacts(contactId)
        .get({ clientId, companyId, opportunityId });

      actions.ready({ contact });
    } catch (error) {
      actions.ready({ contact: null });
    }
  }, [actions, api.www, workspace.id, clientId, companyId, opportunityId, contactId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    if (onClose) {
      onClose(parentTab);
    }
  }

  if (!isReady) return null;
  if (!contact) return <ErrorPage.NotFound publicSite={false} />;
  if (!contact.permissions?.view) return <ErrorPage.Forbidden publicSite={false} />;

  return (
    <Drawer isOpen title={title} onClose={handleClose}>
      {(closeDrawer) => {
        return (
          <Stack>
            <ReadForm.Section title="Contact Details">
              <Control visible={!!contact.firstName}>
                <Description label="First Name">{contact.firstName}</Description>
              </Control>

              <Control visible={!!contact.lastName}>
                <Description label="Last Name">{contact.lastName}</Description>
              </Control>

              <Control visible={!!contact.name}>
                <Description label="Full Name">{contact.name}</Description>
              </Control>

              <Control visible={!!contact.email}>
                <Description label="Email Address">{contact.email}</Description>
              </Control>

              <Control visible={!!contact.owner}>
                <Description label="Owner">{contact.owner?.name}</Description>
              </Control>

              <Control visible={!!contact.timeZone}>
                <Description label="Time Zone">{contact.timeZone?.name}</Description>
              </Control>

              <Control visible={!!contact.title}>
                <Description label="Title">{contact.title}</Description>
              </Control>

              <Control visible={!!contact.description}>
                <ReadTextbox label="Description" textarea value={contact.description} />
              </Control>

              <Control visible={!!contact.officePhone}>
                <Description label="Office Phone">{contact.officePhone}</Description>
              </Control>

              <Control visible={!!contact.mobilePhone}>
                <Description label="Mobile Phone">{contact.mobilePhone}</Description>
              </Control>

              <Control visible={!!contact.fax}>
                <Description label="Fax">{contact.fax}</Description>
              </Control>

              <Control visible={!!contact.websiteUrl}>
                <Description label="Website URL">{contact.websiteUrl}</Description>
              </Control>
            </ReadForm.Section>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={closeDrawer}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </Stack>
        );
      }}
    </Drawer>
  );
}
export default ViewContactDrawer;
