import { BillableIcon, Button, Buttons, CancelButton, Checkbox, Drawer, Hours, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useForm } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const SubmitButton = styled(Button)`
  width: 10.5rem;
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 1rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

function ProjectTaskTemplatesDrawer({ project, onSaved, onClose }) {
  useDocumentTitle('Add Tasks');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [{ isSubmitting }, form] = useForm();

  function handleClose() {
    onClose();
  }

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projectTaskTemplates().get();
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: {} });
    }
  }, [workspace.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!query.data) return;

    return {
      tasks: query.data.filter((template) => taskTemplates.some((t) => t.id === template.id)),

      get some() {
        return this.tasks.length > 0;
      },
      get all() {
        return this.tasks.length === query.data.length;
      },
    };
  }, [query.data, taskTemplates]);

  const handleBulkSelectionChange = () => {
    setTaskTemplates(
      selected.some
        ? taskTemplates.filter(({ id }) => !query.data.some((template) => id === template.id))
        : [...taskTemplates, ...query.data.map(({ id }) => ({ id }))],
    );
  };

  if (!query.isReady) return null;
  if (!query.data) return <ErrorPage.NotFound />;

  return (
    <Drawer isOpen title="Add Tasks" onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => closeDrawer();
        const ids = taskTemplates.map((template) => template.id);

        async function handleSubmit() {
          if (!ids.length) {
            closeDrawer();
            return;
          }
          try {
            form.submit();
            await api.www.workspaces(workspace.id).projects(project.id).tasks().createFromTemplates({ ids });
            await onSaved();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <>
            <StyledTable>
              <Table.Header>
                <Table.Column align="center" width="3rem">
                  <StyledCheckbox>
                    <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
                  </StyledCheckbox>
                </Table.Column>
                <Table.Column>Name</Table.Column>
                <Table.Column align="right">
                  <BillableIcon value={false} showTooltip={false} />
                </Table.Column>
                <Table.Column align="right">Hours Budget</Table.Column>
              </Table.Header>

              <Table.Body>
                {query.data.map((template) => {
                  const checked = taskTemplates.some(({ id }) => id === template.id);

                  const handleSelectionChange = () => {
                    setTaskTemplates(
                      checked
                        ? taskTemplates.filter(({ id }) => id !== template.id)
                        : [...taskTemplates, { id: template.id }],
                    );
                  };

                  return (
                    <Table.Row key={template.id} stretch>
                      <Table.Cell>
                        <StyledCheckbox>
                          <Checkbox checked={checked} onChange={handleSelectionChange} />
                        </StyledCheckbox>
                      </Table.Cell>
                      <Table.Cell>{template.name}</Table.Cell>
                      <Table.Cell>
                        <BillableIcon value={template.isBillable} />
                      </Table.Cell>
                      <Table.Cell align="right">
                        <Hours value={template.budgetHours} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </StyledTable>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <SubmitButton onClick={handleSubmit} isLoading={isSubmitting}>
                  Add
                </SubmitButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

export default ProjectTaskTemplatesDrawer;
