import { ActionButton, ButtonBadge, Buttons, InlineTooltip, Level, SplitButton, TooltipButton } from '~/components';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { colors } from '~/styles';
import { Actions } from '../components/PageComponents';
import { Legend, Progress, ProgressContainer, ProgressLine } from '../components/Progress';

export default function ClientActions({ clientApproval, selection, isSubmitting, saved, onAction, onSubmit }) {
  const readyToSubmit = useMemo(() => {
    if (!clientApproval) return null;

    return (
      _.every(clientApproval.timeEntries, (entry) => !!entry.clientStatusId) &&
      _.every(clientApproval.expenseItems, (item) => !!item.clientStatusId)
    );
  }, [clientApproval]);

  const submitTooltip =
    clientApproval.statusId === 'submitted'
      ? 'This client approval has been submitted already.'
      : !readyToSubmit
        ? 'Please approve or reject every time entry and expense item to submit.'
        : null;

  const progress = useMemo(() => {
    const transactions = clientApproval.timeEntries
      .concat(clientApproval.expenseItems)
      .map((trx) => trx.clientStatusId);

    return {
      approved: transactions.filter((status) => status === 'approved').length,
      rejected: transactions.filter((status) => status === 'rejected').length,
      pending: transactions.filter((status) => !status).length,
      total: transactions.length,

      get ready() {
        return this.approved + this.rejected;
      },

      get readyPercentage() {
        return `${_.round((this.ready / this.total) * 100)}%`;
      },

      get approvedPercentage() {
        return `${(this.approved / this.total) * 100}%`;
      },

      get rejectedPercentage() {
        return `${(this.rejected / this.total) * 100}%`;
      },

      get pendingPercentage() {
        return `${(this.pending / this.total) * 100}%`;
      },
    };
  }, [clientApproval]);

  return (
    <Actions>
      <Level>
        <Level.Item>
          {progress.total > 0 && (
            <ProgressContainer>
              <Legend>
                <strong>Progress</strong>

                <strong>{progress.readyPercentage}</strong>
              </Legend>

              <Progress $height="1rem">
                <ProgressLine $width={progress.approvedPercentage} color={colors.success}>
                  <InlineTooltip message={`${progress.approved} approved`} placement="top" />
                </ProgressLine>
                <ProgressLine $width={progress.rejectedPercentage} color={colors.danger}>
                  <InlineTooltip message={`${progress.rejected} rejected`} placement="top" />
                </ProgressLine>
                <ProgressLine $width={progress.pendingPercentage} color={colors.grey5}>
                  <InlineTooltip message={`${progress.pending} pending approval`} placement="top" />
                </ProgressLine>
              </Progress>

              <Legend>
                <span />

                <span>
                  {progress.pending} of {progress.total} pending approval
                </span>
              </Legend>
            </ProgressContainer>
          )}
        </Level.Item>

        <Level.Item right>
          <Buttons align="right">
            {selection.length > 0 && (
              <SplitButton style={{ marginRight: '2rem' }}>
                <ActionButton
                  isLoading={isSubmitting === 'batch'}
                  ok={saved === 'batch'}
                  disabled={selection.length === 0}
                  onClick={() => onAction('approved')}>
                  Approve <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                </ActionButton>

                <SplitButton.Menu position="top" disabled={selection.length === 0}>
                  {({ setIsOpen }) => (
                    <>
                      <SplitButton.Item onClick={() => setIsOpen(false) || onAction('rejected')}>
                        Reject
                      </SplitButton.Item>

                      <SplitButton.Item onClick={() => setIsOpen(false) || onAction(null)}>
                        Set to Pending Approval
                      </SplitButton.Item>
                    </>
                  )}
                </SplitButton.Menu>
              </SplitButton>
            )}

            <TooltipButton
              component={ActionButton}
              disabled={!!submitTooltip}
              isLoading={isSubmitting === 'submit'}
              ok={saved === 'submit'}
              tooltip={submitTooltip}
              onClick={onSubmit}>
              Submit
            </TooltipButton>
          </Buttons>
        </Level.Item>
      </Level>
    </Actions>
  );
}
