import { Button, DateTime, Field, Icon } from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';

const FullWidthDayPicker = styled.div`
  display: flex;
  flex: 1;

  > * {
    flex: 1;
  }
`;

function HolidayOccurrenceForm({ occurrence, setEditForm, onCancel, onSave }) {
  const formRef = useRef();

  const initialValues = mergeValues(
    {
      id: null,
      date: '',
    },
    occurrence,
  );

  useEffect(() => {
    setEditForm(formRef);
    return () => {
      setEditForm(null);
    };
  }, [setEditForm]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        date: Yup.date().label('Date').required(),
      })}>
      {({ submitForm }) => {
        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              <FullWidthDayPicker>
                <Field.DayPicker name="date" placeholder="Date" />
              </FullWidthDayPicker>
            </Table.Cell>
            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

function HolidayOccurrenceRow({ occurrence, readOnly, isEditing, setEditForm, onCancel, onDelete, onEdit, onSave }) {
  if (readOnly && !occurrence) {
    return null;
  }
  if (!isEditing && !occurrence && !readOnly) {
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
  if (!isEditing) {
    return (
      <Table.BoxRow>
        <Table.Cell>
          <DateTime value={occurrence.date} format="dddd, MMMM D, YYYY" />
        </Table.Cell>
        {!readOnly && (
          <TableBoxRowActions>
            <TableBoxRowActions.Delete onClick={() => onDelete(occurrence)} />
          </TableBoxRowActions>
        )}
      </Table.BoxRow>
    );
  }
  return (
    <HolidayOccurrenceForm occurrence={occurrence} setEditForm={setEditForm} onCancel={onCancel} onSave={onSave} />
  );
}

export default HolidayOccurrenceRow;
