import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, post } from './agent';

const timesheets =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'timesheets', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      submit: (body) => post(url, body),
      resubmitRejectedTime: () => post(url.concat('/resubmit-rejected-time')),
      list: (query = {}) => get(url.concat('/list').concat(new QueryString(query).toString(true))),
      timeEntries: () => get(url.concat('/time-entries')),
      submissionSummary: (query = {}) =>
        get(url.concat('/submission-summary').concat(new QueryString(query).toString(true))),
      unsubmit: () => del(url),
    };
  };

export default timesheets;
