import Form from '~/components/Form';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import TableFormActions from './TableFormActions';

function TableForm({ children, onSubmit, ...props }) {
  const cells = _.map(React.Children.toArray(children), (child, index) => React.cloneElement(child, { index }));

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') onSubmit();
  };

  return (
    <Form onKeyDown={onSubmit ? handleKeyDown : undefined} {...props}>
      {cells}
    </Form>
  );
}

TableForm.Actions = TableFormActions;

export default styled(TableForm)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .field {
    width: 100%;
  }
`;
