import _ from 'lodash';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  input {
    &:disabled {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }
  ${({ prefix = false }) =>
    prefix
      ? css`
          input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px solid ${colors.primary25};
          }
        `
      : null}
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Prefix = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 0 0.75rem;
  background-color: ${colors.primary10};
  color: ${colors.primary100};
  border-width: 1px 0 1px 1px;
  border-style: solid;
  font-weight: ${weights.medium};
  border-color: ${colors.grey10};
`;

const DefaultInput = React.forwardRef((props, ref) => <input {...props} ref={ref} />);

const TextInput = React.forwardRef(
  (
    {
      component,
      container,
      value,
      placeholder,
      prefix,
      materialPlaceholder = true,
      materialAlwaysVisible = false,
      ...props
    },
    ref,
  ) => {
    const Component = component || DefaultInput;
    const Container = container || InputContainer;

    const materialPlaceholderValue = useMemo(() => {
      if (!materialPlaceholder) {
        return '';
      } else if (_.isString(materialPlaceholder)) {
        return materialPlaceholder;
      } else if (_.isString(placeholder) && !!placeholder) {
        return placeholder;
      }
      return '';
    }, [placeholder, materialPlaceholder]);

    const hasValue = useMemo(() => {
      if (_.isNumber(value)) return !!String(value);
      return !!value;
    }, [value]);

    return (
      <Container prefix={prefix}>
        {!!materialPlaceholderValue && (
          <Placeholder isVisible={materialAlwaysVisible || hasValue}>{materialPlaceholderValue}</Placeholder>
        )}
        {prefix && <Prefix>{prefix}</Prefix>}
        <Component {...props} ref={ref} value={value} placeholder={placeholder} />
      </Container>
    );
  },
);

export default TextInput;
