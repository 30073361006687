import { Button, Buttons, CancelButton, Drawer, Field, Form, InvoiceVariablesDialog, TextInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useConfirmation } from '~/contexts/ConfirmationContext';
import { Formik } from 'formik';
import { useDirtyCheck } from '~/hooks';
import moment from 'moment';
import React, { useRef } from 'react';
import { dateFormats, mergeValues } from '~/utils';
import * as Yup from 'yup';
import BaseTransactionNumberDialog from './BaseTransactionNumberDialog';
import DuplicateTransactionNumberDialog from './DuplicateTransactionNumberDialog';
import extractTransactionNumber from './extract-transaction-number';
import styled from 'styled-components';

const VariablesContainer = styled.div`
  display: flex;
  flex-direction: column;

  > a {
    margin-left: auto;
  }
`;

const StyledLink = styled.a`
  margin-bottom: -0.5rem;
`;

function HeaderDrawer({ invoice, values, onApply, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const formRef = useRef();
  const draftInvoiceFor = useRef(null);
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      discountAmount: '',
      dueOn: null,
      draftInvoiceFor: '',
      issuedOn: null,
      number: values.transactionNumber,
      transactionNumber: values.transactionNumber,
      paymentTermsId: '',
      poNumber: '',
      servicesThrough: null,
      billTo: '',
    },
    values,
  );

  const schema = Yup.object().shape({
    // Header
    dueOn: Yup.date().label('Due On').nullable().required(),
    draftInvoiceFor: Yup.string().label('Invoice Subject').max(255),
    issuedOn: Yup.date().label('Issued On').nullable().required(),
    number: Yup.string().label('Number').max(255).nullable().required(),
    poNumber: Yup.string().label('P.O. Number').max(255),
    servicesThrough: Yup.date().label('Services Through').nullable(),
    billTo: Yup.string().label('Bill To').max(5000).required(),
  });

  return (
    <>
      <Drawer
        isOpen
        title="Invoice Header"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(formValues) {
            // If the transaction number changed, check if it's used by another invoice
            if (formValues.transactionNumber !== values.transactionNumber) {
              const { data: validateTransactionNumberResult } = await api.www
                .workspaces(workspace.id)
                .invoices(invoice.id)
                .validateTransactionNumber({ transactionNumber: formValues.transactionNumber });

              if (validateTransactionNumberResult === 'duplicate') {
                const confirmDuplicate = await confirmation.prompt((resolve) => (
                  <DuplicateTransactionNumberDialog
                    transactionNumber={formValues.transactionNumber}
                    onConfirm={() => resolve(true)}
                    onClose={() => resolve(false)}
                  />
                ));

                if (!confirmDuplicate) return;
              }
            }

            if (formValues.transactionNumber !== values.transactionNumber) {
              const setBaseTransactionNumber = await confirmation.prompt((resolve) => (
                <BaseTransactionNumberDialog
                  originalTransactionNumber={values.transactionNumber}
                  newTransactionNumber={formValues.transactionNumber}
                  onConfirm={(result) => resolve(result)}
                  onClose={() => resolve(null)}
                />
              ));

              if (setBaseTransactionNumber == null) return;

              formValues.setBaseTransactionNumber = setBaseTransactionNumber;
            }

            onApply(formValues);
            closeDrawer();
          }

          return (
            <>
              <Formik
                innerRef={formRef}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}>
                {(formik) => {
                  const handleIssuedOnChange = (value) => {
                    const values = { ...formik.values, issuedOn: value };

                    const issuedOn = moment(value);
                    if (issuedOn.isValid()) {
                      values.dueOn = getDueDate(issuedOn, values.paymentTermsId);
                    }

                    formik.setValues(values);
                  };

                  const handlePaymentTermsChange = ({ target: { value } }) => {
                    const values = { ...formik.values, paymentTermsId: value };

                    const issuedOn = moment(formik.values.issuedOn);
                    if (issuedOn.isValid()) {
                      values.dueOn = getDueDate(issuedOn, values.paymentTermsId);
                    }

                    formik.setValues(values);
                  };

                  const handleInvoiceNumberChange = ({ target: { value } }) => {
                    const transactionNumber = extractTransactionNumber(value);
                    formik.setValues({
                      ...formik.values,
                      number: value,
                      transactionNumber: transactionNumber || invoice.transactionNumber,
                    });
                  };

                  const handleOpenInvoiceVariablesDialog = async () => {
                    await confirmation.prompt((resolve) => (
                      <InvoiceVariablesDialog
                        onClose={() => resolve(true)}
                        onSaved={(variables) => {
                          let selectionStart = draftInvoiceFor.current.selectionStart;
                          if (!formik.touched.draftInvoiceFor) {
                            selectionStart = formik.values.draftInvoiceFor.length;
                          }

                          const updatedValue =
                            formik.values.draftInvoiceFor.slice(0, selectionStart) +
                            variables.join(' ') +
                            formik.values.draftInvoiceFor.slice(selectionStart);

                          formik.setFieldValue('draftInvoiceFor', updatedValue);
                          resolve(true);
                        }}
                      />
                    ));
                  };

                  return (
                    <Form>
                      <Form.Section title="Client Information">
                        <Form.Control>
                          <TextInput placeholder="Client" value={invoice.client.name} disabled />
                        </Form.Control>

                        <Form.Control>
                          <Field.TextArea name="billTo" placeholder="Bill To" maxLength={5000} rows={4} />
                        </Form.Control>
                      </Form.Section>

                      <Form.Section title="Invoice Information">
                        <Form.Control>
                          <Field.Text
                            name="number"
                            placeholder="Invoice Number"
                            maxLength={255}
                            onChange={handleInvoiceNumberChange}
                          />
                          <Field.DayPicker
                            name="issuedOn"
                            placeholder="Issued On"
                            clearable={false}
                            onChange={handleIssuedOnChange}
                          />
                        </Form.Control>
                        <Form.Control>
                          <div>
                            <Field.DayPicker name="servicesThrough" placeholder="Services Through" />
                          </div>
                          <Field.PaymentTermsSelect
                            name="paymentTermsId"
                            placeholder="Payment Terms"
                            onChange={handlePaymentTermsChange}
                          />
                        </Form.Control>
                        <Form.Control>
                          <Field.Text name="poNumber" placeholder="P.O. Number" maxLength={255} />
                          <Field.DayPicker
                            name="dueOn"
                            placeholder="Due On"
                            clearable={false}
                            disabled={formik.values.paymentTermsId === 'due_on_receipt'}
                          />
                        </Form.Control>

                        <VariablesContainer>
                          <StyledLink onClick={() => handleOpenInvoiceVariablesDialog()}>Insert Variables</StyledLink>
                          <Form.Control>
                            <Field.Text
                              name="draftInvoiceFor"
                              placeholder="Invoice Subject"
                              maxLength={255}
                              ref={draftInvoiceFor}
                            />
                          </Form.Control>
                        </VariablesContainer>
                      </Form.Section>
                      <Drawer.Actions>
                        <Buttons align="right">
                          <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                          <Button onClick={formik.submitForm}>Apply</Button>
                        </Buttons>
                      </Drawer.Actions>
                    </Form>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </Drawer>
    </>
  );
}

function getDueDate(issuedOn, paymentTermsId) {
  let dueOn;

  switch (paymentTermsId) {
    case 'due_on_receipt':
      dueOn = issuedOn.format(dateFormats.isoDate);
      break;
    case 'net_10':
      dueOn = issuedOn.add(10, 'days').format(dateFormats.isoDate);
      break;
    case 'net_15':
      dueOn = issuedOn.add(15, 'days').format(dateFormats.isoDate);
      break;
    case 'net_21':
      dueOn = issuedOn.add(21, 'days').format(dateFormats.isoDate);
      break;
    case 'net_30':
      dueOn = issuedOn.add(30, 'days').format(dateFormats.isoDate);
      break;
    case 'net_45':
      dueOn = issuedOn.add(45, 'days').format(dateFormats.isoDate);
      break;
    case 'net_60':
      dueOn = issuedOn.add(60, 'days').format(dateFormats.isoDate);
      break;
    case 'net_65':
      dueOn = issuedOn.add(65, 'days').format(dateFormats.isoDate);
      break;
    case 'net_75':
      dueOn = issuedOn.add(75, 'days').format(dateFormats.isoDate);
      break;
    case 'net_90':
      dueOn = issuedOn.add(90, 'days').format(dateFormats.isoDate);
      break;
    case 'net_120':
      dueOn = issuedOn.add(120, 'days').format(dateFormats.isoDate);
      break;
    case 'net_1_end_of_month':
      dueOn = issuedOn.endOf('month').add(1, 'days').format(dateFormats.isoDate);
      break;
    case 'net_15_end_of_month':
      dueOn = issuedOn.endOf('month').add(15, 'days').format(dateFormats.isoDate);
      break;
    case 'net_20_end_of_month':
      dueOn = issuedOn.endOf('month').add(20, 'days').format(dateFormats.isoDate);
      break;
    case 'net_30_end_of_month':
      dueOn = issuedOn.endOf('month').add(30, 'days').format(dateFormats.isoDate);
      break;
    case 'net_60_end_of_month':
      dueOn = issuedOn.endOf('month').add(60, 'days').format(dateFormats.isoDate);
      break;
    case 'net_65_end_of_month':
      dueOn = issuedOn.endOf('month').add(65, 'days').format(dateFormats.isoDate);
      break;
    case 'last_day_of_next_month':
      dueOn = issuedOn.add(1, 'month').endOf('month').format(dateFormats.isoDate);
      break;
  }

  return dueOn;
}

export default HeaderDrawer;
