import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';

const personalDashboard = (workspacesUrl) => () => {
  const url = _([workspacesUrl, 'personal-dashboard']).compact().join('/');

  return {
    timeSummaryReport: (query = {}) => get(url.concat('/time-summary').concat(new QueryString(query).toString(true))),
    utilizationReport: (query = {}) => get(url.concat('/utilization').concat(new QueryString(query).toString(true))),
    realizationRate: (query = {}) => get(url.concat('/realization-rate').concat(new QueryString(query).toString(true))),
    allocatedHours: (query = {}) => get(url.concat('/allocated-hours').concat(new QueryString(query).toString(true))),
    variance: (query = {}) => get(url.concat('/variance').concat(new QueryString(query).toString(true))),
    myProjects: (query = {}) => get(url.concat('/my-projects').concat(new QueryString(query).toString(true))),
    myTasks: (query = {}) =>
      get(url.concat('/my-tasks').concat(new QueryString(query, { multi: true }).toString(true))),
    myAllocations: (query = {}) =>
      get(url.concat('/my-allocations').concat(new QueryString(query, { multi: true }).toString(true))),
    exportMyAllocations: (query = {}, headers = {}) =>
      get(url.concat('/export-my-allocations').concat(new QueryString(query, { multi: true }).toString(true)), headers),
    myExports: (query = {}) =>
      get(url.concat('/my-exports').concat(new QueryString(query, { multi: true }).toString(true))),
  };
};

export default personalDashboard;
