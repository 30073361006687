import React, { useContext } from 'react';
import { useSubscription as useSubscriptionHook } from '~/hooks';

const SubscriptionContext = React.createContext();

function SubscriptionProvider({ children }) {
  const { subscribe, unsubscribe, notify } = useSubscriptionHook();

  return (
    <SubscriptionContext.Provider value={{ subscribe, unsubscribe, notify }}>{children}</SubscriptionContext.Provider>
  );
}

function useSubscription() {
  return useContext(SubscriptionContext);
}

useSubscription.keys = {
  integrations_changed: 'integrations_changed',
  refresh_time_approval_count: 'refresh_time_approval_count',
  refresh_expense_approval_count: 'refresh_expense_approval_count',
  refresh_timer: 'refresh_timer',
  workspace_changed: 'workspace_changed',
  workspace_deleted: 'workspace_deleted',
};

export { SubscriptionContext, useSubscription, SubscriptionProvider };
