import React from 'react';
import { Icon, Tooltip } from '~/components';
import { colors } from '~/styles';

export default function LineTypeTooltip({ line }) {
  if (!line.project) {
    return (
      <Tooltip message="This ad-hoc line is not associated with a project.">
        <Icon icon="exclamation-triangle" color={colors.warning} />
      </Tooltip>
    );
  }
  switch (line.transactionType) {
    case 'time':
      if (line.timeEntries.length === 0)
        return (
          <Tooltip
            message={`This line was associated with time entries that no longer exist on the ${line.project.name} project. It will be converted to an ad-hoc line upon save.`}>
            <Icon icon="clock" color={colors.warning} />
          </Tooltip>
        );

      return (
        <Tooltip message={`This line is associated with time entries on the ${line.project.name} project.`}>
          <Icon icon="clock" color={colors.grey40} />
        </Tooltip>
      );

    case 'milestone':
      if (line.milestones.length === 0)
        return (
          <Tooltip
            message={`This line was associated with fixed fee milestones that no longer exist on the ${line.project.name} project. It will be converted to an ad-hoc line upon save.`}>
            <Icon icon="file-invoice-dollar" color={colors.warning} />
          </Tooltip>
        );

      return (
        <Tooltip message={`This line is associated with fixed fee milestones on the ${line.project.name} project.`}>
          <Icon icon="file-invoice-dollar" color={colors.grey40} />
        </Tooltip>
      );

    case 'expense':
      if (line.expenses.length === 0 && line.projectExpenses.length === 0)
        return (
          <Tooltip
            message={`This line was associated with expenses that no longer exist on the ${line.project.name} project. It will be converted to an ad-hoc line upon save.`}>
            <Icon icon="receipt" color={colors.warning} />
          </Tooltip>
        );

      return (
        <Tooltip message={`This line is associated with expenses on the ${line.project.name} project.`}>
          <Icon icon="receipt" color={colors.grey40} />
        </Tooltip>
      );

    case 'other_item':
      if (line.otherItems.length === 0)
        return (
          <Tooltip
            message={`This line was associated with other items that no longer exist on the ${line.project.name} project. It will be converted to an ad-hoc line upon save.`}>
            <Icon icon="file-alt" color={colors.warning} />
          </Tooltip>
        );

      return (
        <Tooltip message={`This line is associated with other items on the ${line.project.name} project.`}>
          <Icon icon="file-alt" color={colors.grey40} />
        </Tooltip>
      );

    default:
      return (
        <Tooltip message={`This ad-hoc line is associated with the ${line.project.name} project.`}>
          <Icon icon="edit" color={colors.grey40} />
        </Tooltip>
      );
  }
}
