import React from 'react';
import Tag from './Tag';

export default function ExpenseReimbursableTypeTag({ value, ...props }) {
  if (!value) return null;
  return (
    <Tag label="Reimbursable Type" {...props}>
      {{ reimbursable: 'Reimbursable', non_reimbursable: 'Non-Reimbursable' }[value] || 'All'}
    </Tag>
  );
}
