import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';

const search = (workspacesUrl) => () => {
  const url = _([workspacesUrl, 'search']).compact().join('/');

  return {
    get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
  };
};

export default search;
