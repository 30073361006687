import { useApi } from '~/contexts';
import { useActions, useField } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

const initialState = {
  options: [],
  isReady: false,
};

const handlers = {
  ready: (options) => ({ options, isReady: true }),
};

function CompanyServiceTypeSelect(props) {
  const api = useApi();
  const [{ options, isReady }, actions] = useActions(handlers, initialState);

  useEffect(() => {
    (async () => {
      const { data } = await api.companyServiceTypes().get();
      actions.ready(data);
    })();
  }, [actions, api]);

  const text = useMemo(() => _.find(options, { id: props.value })?.name, [options, props.value]);

  return (
    <SingleSelect disabled={!isReady} valueRenderer={text} {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldCompanyServiceTypeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <CompanyServiceTypeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default CompanyServiceTypeSelect;
export { FieldCompanyServiceTypeSelect };
