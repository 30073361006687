import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Icon, SingleSelect, Tooltip } from '~/components';
import { colors } from '~/styles';

export default function ItemSelect({ items, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return items;
    }

    const fuse = new Fuse(items, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [items, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const item = _.find(items, { id: value });
    if (!item)
      return {
        id: value,
        name: (
          <Tooltip message="Product/Service not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Product/Service ID: {value}
          </Tooltip>
        ),
      };
    return item;
  }, [items, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        // eslint-disable-next-line react/no-unknown-property
        <option key={option.id} value={option.id} tooltip={option.name}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
