import React from 'react';
import Tag from './Tag';

export default function InvoiceSentTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Sent" {...props}>
      {{ no: 'No', yes: 'Yes' }[value]}
    </Tag>
  );
}
