import { ActionButton, Buttons, CancelButton, Checkbox, FormMessage, HelpTooltip, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function CreateClientAndProjectDialog({ opportunity, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, status }, form] = useForm();
  const [createClient, setCreateClient] = useState(opportunity.company.client ? false : true);
  const [createProject, setCreateProject] = useState(true);

  async function handleSave() {
    try {
      form.submit();
      await api.www
        .workspaces(workspace.id)
        .opportunities(opportunity.id)
        .createProjectClient({ createProject, createClient });

      onSaved();
      await onClose();
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }

  const handleCreateClient = (value) => {
    setCreateClient(value);
    if (!value) {
      setCreateProject(false);
    }
  };

  return (
    <ModalCard title="Create Client and Project" onClose={onClose}>
      <ModalCard.Body>
        <p>Would you like to create a client and project from this opportunity?</p>

        <CheckboxContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              label="Create a Client"
              checked={createClient}
              disabled={opportunity.company.client ? true : false}
              onChange={() => handleCreateClient(!createClient)}
            />
            {opportunity.company.client && (
              <HelpTooltip
                style={{ marginLeft: '.5rem' }}
                message="A client already exists for this company. You cannot create another client."
              />
            )}
          </div>

          <Checkbox
            label="Create a Project"
            checked={createProject}
            disabled={createClient || opportunity.company.client ? false : true}
            onChange={() => setCreateProject(!createProject)}
          />
        </CheckboxContainer>

        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
          <ActionButton isLoading={isSubmitting} onClick={handleSave}>
            Save
          </ActionButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default CreateClientAndProjectDialog;
