import { Button, Buttons, CancelButton, Field, Form, FormMessage, HelpTooltip, ModalCard } from '~/components';
import { Card } from '~/components/ModalCard';
import { useApi, useSubscription, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { emptyStringToNull, intervalOptions } from '~/utils';
import * as Yup from 'yup';
import { ContainerControl } from './components/PageComponents';
import { FieldPeriodSelect } from './components/PeriodSelect';

const Modal = styled(ModalCard)`
  ${Card} {
    width: 48rem;
  }
`;

export default function CreateClientApprovalForm({ project, onClose, onSaved }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const { notify } = useSubscription();

  const initialValues = {
    period: intervalOptions.this_month,
    includeHours: true,
    includeExpenses: true,
    sendReminder: false,
    sendReminderAfterDays: 3,
    autoApprove: false,
    autoApproveAfterDays: 5,
  };

  const schema = Yup.object().shape({
    period: Yup.object().shape({
      end: Yup.date()
        .label('End Date')
        .nullable()
        .when('start', (start, schema) =>
          start ? schema.min(Yup.ref('start'), 'End Date must be after Start Date') : schema,
        )
        .required(),
      start: Yup.date().label('Start Date').nullable().required(),
    }),
    sendReminder: Yup.bool(),
    sendReminderAfterDays: Yup.number().integer().min(1).max(20),
    autoApprove: Yup.bool(),
    autoApproveAfterDays: Yup.number()
      .integer()
      .min(1)
      .max(20)
      .when('sendReminder', {
        is: true,
        then: (schema) =>
          schema.when('autoApprove', {
            is: true,
            then: (schema) =>
              schema.moreThan(
                Yup.ref('sendReminderAfterDays'),
                'Auto-approve must be set to a value greater than the reminder email.',
              ),
          }),
      }),
  });

  const handleClose = () => dirtyCheck(onClose);

  const handleSubmit = async (values) => {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, 'period'),
        periodStart: values.period.start,
        periodEnd: values.period.end,
      });

      const { data } = await api.www.workspaces(workspace.id).projects(project.id).clientApprovals().create(body);

      form.save();

      notify(useSubscription.keys.refresh_timer);

      if (onSaved) await onSaved(data);
    } catch (error) {
      form.error(error);
    }
  };

  return (
    <Modal title="New Client Approval" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {(formik) => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <FieldPeriodSelect
                    name="period"
                    intervals={[
                      intervalOptions.custom,
                      intervalOptions.this_week,
                      intervalOptions.this_month,
                      intervalOptions.this_quarter,
                      intervalOptions.this_year,
                      intervalOptions.last_week,
                      intervalOptions.last_month,
                      intervalOptions.last_quarter,
                      intervalOptions.last_year,
                    ]}
                    showDayPicker
                  />
                </Form.Control>

                <Form.Control>Request client approval for the following:</Form.Control>

                <Form.Control>
                  <Field.Checkbox name="includeHours" label="Billable hours" />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox name="includeExpenses" label="Billable expenses" />
                </Form.Control>

                <Form.Control>
                  <ContainerControl>
                    <Field.Checkbox
                      name="sendReminder"
                      label="Send a daily reminder email if the client does not submit a response within"
                    />
                    <Field.SingleSelect
                      name="sendReminderAfterDays"
                      materialPlaceholder={false}
                      disabled={!formik.values.sendReminder}
                      style={{ width: '5rem', margin: '0 0.5rem', textAlign: 'right' }}>
                      {[...Array(20).keys()].map((_, idx) => (
                        <option key={idx} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </Field.SingleSelect>
                    <span>days.</span>
                    <HelpTooltip
                      style={{ marginLeft: '0.3rem' }}
                      message="A daily reminder will be sent for one week."
                      placement="right"
                    />
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <ContainerControl>
                    <Field.Checkbox
                      name="autoApprove"
                      label="Auto-approve all items if the client does not submit a response within"
                    />
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="autoApproveAfterDays"
                        materialPlaceholder={false}
                        disabled={!formik.values.autoApprove}
                        style={{ width: '5rem', textAlign: 'right' }}>
                        {[...Array(20).keys()].map((_, idx) => (
                          <option key={idx} value={idx + 1}>
                            {idx + 1}
                          </option>
                        ))}
                      </Field.SingleSelect>
                    </div>
                    <span>days.</span>
                  </ContainerControl>
                </Form.Control>

                <p style={{ margin: '1rem 0' }}>
                  Any running timers for the associated time entries will be stopped when this client approval is
                  created.
                </p>

                {status && <FormMessage.Error>{message}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>

                  <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
