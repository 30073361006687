import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import AccountsReceivableAging from './AccountsReceivableAging';
import FinancialReportsPage from './FinancialReportsPage';
import FixedFeeRevenue from './FixedFeeRevenue';
import HourlyFees from './HourlyFees';
import InvoiceDetail from './InvoiceDetail';
import Invoices from './Invoices';
import InvoicesByClient from './InvoicesByClient';
import Payments from './Payments';
import PerformanceByClient from './PerformanceByClient';
import PerformanceByClientPractice from './PerformanceByClientPractice';
import PerformanceByMember from './PerformanceByMember';
import PerformanceByMemberPractice from './PerformanceByMemberPractice';
import PerformanceByProject from './PerformanceByProject';
import PerformanceByProjectAndMember from './PerformanceByProjectAndMember';
import PerformanceByProjectAndTask from './PerformanceByProjectAndTask';
import PerformanceByProjectPractice from './PerformanceByProjectPractice';
import ScheduledItems from './ScheduledItems';
import TransactionSummaryByInvoiceItem from './TransactionSummaryByInvoiceItem';
import UninvoicedRevenue from './UninvoicedRevenue';
import CreditNotes from './CreditNotes';
import CreditNoteDetail from './CreditNoteDetail';
import ClientStatement from './ClientStatement';
import PerformanceByProjectType from './PerformanceByProjectType';

function FinancialReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={path} exact>
        {auth.reports.financial ? <FinancialReportsPage /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/performance-by-project')}>
        {auth.reports.performanceByProject ? <PerformanceByProject /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/performance-by-project-and-member')}>
        {auth.reports.performanceByProjectAndMember ? (
          <PerformanceByProjectAndMember />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-by-project-and-task')}>
        {auth.reports.performanceByProjectAndTask ? (
          <PerformanceByProjectAndTask />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-by-project-practice')}>
        {auth.reports.performanceByProjectPractice ? (
          <PerformanceByProjectPractice />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-by-project-type')}>
        {auth.reports.performanceByProjectType ? (
          <PerformanceByProjectType />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-by-member')}>
        {auth.reports.performanceByMember ? <PerformanceByMember /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/performance-by-member-practice')}>
        {auth.reports.performanceByMemberPractice ? (
          <PerformanceByMemberPractice />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-by-client')}>
        {auth.reports.performanceByClient ? <PerformanceByClient /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/performance-by-client-practice')}>
        {auth.reports.performanceByClientPractice ? (
          <PerformanceByClientPractice />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/invoices')}>
        {auth.invoices.view.project ? <Invoices /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/payments')}>
        {auth.invoices.view.project ? <Payments /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/invoices-by-client')}>
        {auth.invoices.view.project ? <InvoicesByClient /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/invoice-detail')}>
        {auth.invoices.view.project ? <InvoiceDetail /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/accounts-receivable-aging')}>
        {auth.payments.view ? <AccountsReceivableAging /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/credit-notes')}>
        {auth.creditNotes.view ? <CreditNotes /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/credit-note-detail')}>
        {auth.creditNotes.view ? <CreditNoteDetail /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/hourly-revenue-by-client-and-project')}>
        {auth.reports.hourlyFees ? <HourlyFees /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/scheduled-items')}>
        {auth.projects.viewRevenue ? <ScheduledItems /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/fixed-fee-revenue')}>
        {auth.reports.fixedFeeRevenue ? <FixedFeeRevenue /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/uninvoiced-revenue')}>
        {auth.reports.uninvoicedRevenue ? <UninvoicedRevenue /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/transaction-summary-by-invoice-item')}>
        {auth.invoices.view.project ? <TransactionSummaryByInvoiceItem /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/client-statement')}>
        {auth.invoices.view.client ? <ClientStatement /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}

export default FinancialReportsArea;
