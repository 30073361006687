import { Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useCurrencyFormat } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors } from '~/styles';

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function ProjectRevenueWidget({ project, period }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .getProjectRevenueKPI({ date: period.start ?? undefined });

    actions.ready({ data });
    // Intentionally passing the full "project" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, project, period, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!isReady}>
      <Widget.Header>
        <Widget.Title>Revenue</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '14rem' }} />
      <Widget.Content style={{ minHeight: '14rem' }}>
        {data && <Data data={data} currency={project.currency} />}
      </Widget.Content>
    </Widget>
  );
}

function Data({ data, currency }) {
  const currencyFormat = {
    tooltip: useCurrencyFormat({ currency }),
    ticks: useCurrencyFormat({ currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  };

  const chartData = {
    labels: ['Invoiced', 'Earned'],
    datasets: [
      {
        label: 'Revenue',
        data: [data.invoiced, data.earned],
        backgroundColor: [colors.primary, colors.warning],
        maxBarThickness: 100,
      },
    ],
  };

  if (data.budget != null) {
    chartData.labels.push('Budget');
    chartData.datasets[0].data.push(data.budget);
    chartData.datasets[0].backgroundColor.push(colors.grey55);
  }

  const chartOptions = {
    maintainAspectRatio: false,

    layout: {
      padding: { top: 12 },
    },

    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltip) => {
            let label = (tooltip.label || '').trim();
            if (label) {
              label += ' Revenue: ';
            }
            label += currencyFormat.tooltip.format(tooltip.parsed.y);
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          color: colors.grey40,
        },
      },

      y: {
        display: true,
        grid: { display: true, color: colors.grey10 },
        border: { display: false },
        min: 0,
        suggestedMax: 1000,
        ticks: {
          maxTicksLimit: 10,
          color: colors.grey40,
          callback: (label) => currencyFormat.ticks.format(label),
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
}

export default ProjectRevenueWidget;
