import styled from 'styled-components';
import { colors } from '~/styles';

export const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;
