import {
  BillableIcon,
  ClientProjectSelect,
  DateTime,
  DeleteConfirmation,
  Dropdown,
  Icon,
  InternalClientTooltip,
  Level,
  MemberSelect,
  Page,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  SearchInput,
  SortTag,
  Spinner,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import projectTaskStatuses from '~/lookups/project-task-statuses';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats, QueryString, QuerySort } from '~/utils';
import ProjectTaskDetails from '../project-tasks/ProjectTaskDetails';
import ProjectTaskForm from '../project-tasks/ProjectTaskForm';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Status = styled.span`
  color: ${({ status }) =>
    ({
      not_started: colors.warning,
      in_progress: colors.success,
      completed: colors.black,
    })[status]};
`;

export default function MyTasks() {
  const documentTitle = useDocumentTitle('My Tasks');

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const toast = useToast();
  const [dialog, setDialog] = useState(null);
  const confirmation = useConfirmation();
  const [timeEntryDrawer, setTimeEntryDrawer] = useState(null);
  const [member, setMember] = useState();
  const { notify } = useSubscription();
  const currentMember = member || workspace.member;
  const { id: memberId } = currentMember;

  const [params, setParams] = useState({
    project: null,
    name: '',
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    sort: null,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        project: searchParamsConfig.project,
        name: { default: '' },
        projectTaskStatuses: {
          ...searchParamsConfig.projectTaskStatuses,
          default: [projectTaskStatuses.not_started, projectTaskStatuses.in_progress],
        },
        projectTaskRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
        sort: { default: null, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'my_tasks',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      projectId: params.project?.id,
      name: params.name,
      memberId: memberId,
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      sort: params.sort ?? undefined,
    }),
    [params, memberId],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).personalDashboard().myTasks(urlSearchParams);

      setQuery((state) => ({ ...state, data, status: 'ready' }));
    } catch (error) {
      toast.error(error.message);
    }
  }, [api, workspace.id, toast, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus === 'pending') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const closeDialog = () => {
    setDialog(null);
    documentTitle.set('My Tasks');
  };

  async function handleDelete(task) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this task?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www.workspaces(workspace.id).projects(task.projectId).tasks(task.id).delete();
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set({ ...values });
  };

  const handleSort = (values) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    if (!values) {
      setParams({ ...params, sort: null });
      searchParams.set({ sort: null });
      return;
    }

    const { column, sort } = values;
    const direction = column === sort?.column && sort?.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const filterMembers = useMemo(() => {
    return [workspace.member.id];
  }, [workspace]);

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Level style={{ marginBottom: '1.5rem' }}>
          <Level.Item flex="1">
            <SearchInput
              name="name"
              placeholder="Search"
              materialPlaceholder="Task Name"
              materialAlwaysVisible
              onChange={({ target: { value } }) => handleApplyFilters({ name: value })}
              value={params.name}
            />
          </Level.Item>

          <Level.Item flex="1">
            <ClientProjectSelect
              name="project"
              placeholder="All"
              materialPlaceholder="Project"
              materialAlwaysVisible
              activeOnly={false}
              pastTime={false}
              onChange={({ target: { value } }) => handleApplyFilters({ project: value })}
              value={params.project}
            />
          </Level.Item>

          <Level.Item flex="1">
            <ProjectTaskRecordStatusFilter
              value={params.projectTaskRecordStatusId}
              onChange={({ target: { value } }) => handleApplyFilters({ projectTaskRecordStatusId: value })}
            />
          </Level.Item>

          {auth.members.manageTimeAndExpenses && (
            <Level.Item flex="1">
              <MemberSelect
                filterMembers={filterMembers}
                placeholder="Teammates"
                permission="manageTimeAndExpenses"
                onChange={(event) => setMember(event.target.value)}
                value={member}
                align="right"
              />
            </Level.Item>
          )}
        </Level>

        <Level style={{ marginBottom: '1.5rem' }}>
          <Level.Item flex="1">
            <ProjectTaskStatusFilter
              value={params.projectTaskStatuses}
              onChange={({ target: { value } }) => handleApplyFilters({ projectTaskStatuses: value })}
            />
          </Level.Item>
          <Level.Item flex="1" />
          {auth.members.manageTimeAndExpenses && <Level.Item flex="1" />}
        </Level>

        <Level style={{ height: '2.25rem' }}>
          <Level.Item flex="1">
            {params.sort && (
              <SortTag
                value={
                  {
                    name: 'Task Name',
                    'project.name': 'Project Name',
                    statusId: 'Task Status',
                    start: 'Task Start Date',
                    end: 'Task End Date',
                  }[params.sort?.column]
                }
                onRemove={() => handleSort()}
              />
            )}
          </Level.Item>
        </Level>

        <Level>
          <Level.Item>
            <Table.Status>
              {query.status === 'filtering' && <Spinner />}
              <Table.Total value={query.data.length} label="Task" />
            </Table.Status>
          </Level.Item>
        </Level>

        <Table>
          <Table.BoxHeader sticky>
            <Table.Column width="3.5rem" align="center">
              <BillableIcon />
            </Table.Column>

            <Table.Column name="name" onSort={handleSort} sort={params.sort}>
              Name
            </Table.Column>

            <Table.Column name="project.name" onSort={handleSort} sort={params.sort}>
              Project
            </Table.Column>

            <Table.Column width="8rem" name="statusId" onSort={handleSort} sort={params.sort}>
              Status
            </Table.Column>

            <Table.Column width="7.5rem" align="right" name="start" onSort={handleSort} sort={params.sort}>
              Start
            </Table.Column>

            <Table.Column width="7.5rem" align="right" name="end" onSort={handleSort} sort={params.sort}>
              End
            </Table.Column>

            <Table.BoxActionsColumn />
          </Table.BoxHeader>

          <Table.Body fade={query.status === 'filtering'}>
            {query.data.map((task) => {
              const { id, name, status, start, end, isActuallyBillable, project, lockTime } = task;

              function handleView() {
                setDialog({ type: 'view', task });
              }

              function handleEdit() {
                setDialog({ type: 'edit', task });
              }

              return (
                <Table.BoxRow
                  key={id}
                  onClick={task.permissions.manage ? handleEdit : handleView}
                  isDisabled={task.recordStatusId === 'archived'}>
                  <Table.Cell>
                    <BillableIcon value={isActuallyBillable} />
                  </Table.Cell>

                  <Table.Cell>
                    <ProjectInfo>
                      <p>{name}</p>
                    </ProjectInfo>
                    {lockTime && (
                      <Tooltip message="Time has been locked for this task.">
                        <Icon icon="lock" color={colors.grey40} spaceLeft />
                      </Tooltip>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <ProjectInfo>
                      <p>{project.name}</p>
                      <small>
                        {project.client.name}
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>

                  <Table.Cell>
                    <Status status={status.id}>{status.name}</Status>
                  </Table.Cell>

                  <Table.Cell>{start && <DateTime value={start} />}</Table.Cell>

                  <Table.Cell>{end && <DateTime value={end} />}</Table.Cell>

                  <TableBoxRowActions>
                    {task.permissions.manage ? (
                      <TableBoxRowActions.Edit onClick={handleEdit} />
                    ) : (
                      <TableBoxRowActions.View onClick={handleView} />
                    )}

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {() => {
                        return (
                          <>
                            <Dropdown.Item onClick={handleView}>View</Dropdown.Item>

                            <Dropdown.Item
                              disabled={!task.permissions.manage}
                              tooltip={
                                !task.permissions.manage ? 'Insufficient permissions to edit this task.' : undefined
                              }
                              onClick={handleEdit}>
                              Edit
                            </Dropdown.Item>

                            <Dropdown.Link
                              to={`/app/${workspace.key}/reports/time/time-entries?${new QueryString({
                                start: 'not_set',
                                end: 'not_set',
                                project: project.id,
                                projectTask: id,
                                member: workspace.member.id,
                              })}`}>
                              View Time Entries
                            </Dropdown.Link>

                            <Dropdown.Item
                              onClick={() => setTimeEntryDrawer({ project, task })}
                              disabled={task.lockTime || project.lockTimeAndExpenses}
                              tooltip={
                                project.lockTimeAndExpenses
                                  ? "This task's project does not allow adding new time entries."
                                  : task.lockTime
                                    ? 'This task does not allow adding new time entries.'
                                    : null
                              }>
                              Track Time
                            </Dropdown.Item>
                            <Dropdown.DeleteItem
                              disabled={!task.permissions.manage}
                              tooltip={
                                !task.permissions.manage ? 'Insufficient permissions to delete this task.' : undefined
                              }
                              onCheckDependencies={async (workspace) =>
                                task.permissions.manage &&
                                (await workspace.projects(project.id).tasks(id).hasDependencies()).data
                              }
                              onClick={() => handleDelete(task)}>
                              Delete
                            </Dropdown.DeleteItem>
                          </>
                        );
                      }}
                    </TableBoxRowActions.Dropdown>
                  </TableBoxRowActions>
                </Table.BoxRow>
              );
            })}
          </Table.Body>
        </Table>
      </Page>

      {dialog &&
        {
          view: () => (
            <ProjectTaskDetails taskId={dialog.task.id} project={dialog.task.project} onClose={closeDialog} />
          ),
          edit: () => (
            <ProjectTaskForm
              taskId={dialog.task.id}
              project={dialog.task.project}
              onSaved={fetchData}
              onClose={closeDialog}
              onDelete={handleDelete}
            />
          ),
        }[dialog?.type]()}

      {timeEntryDrawer && (
        <EditTimeEntry
          initialValues={{
            date: moment().format(dateFormats.isoDate),
            projectId: timeEntryDrawer.project.id,
            projectTaskId: timeEntryDrawer.task.id,
          }}
          onClose={() => {
            documentTitle.set('My Tasks');
            setTimeEntryDrawer(null);
          }}
          onSaved={() => {
            notify(useSubscription.keys.refresh_timer);
            fetchData();
          }}
        />
      )}
    </>
  );
}
