import { useAuth, useDocumentTitle, useFeatures } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

function WorkspaceReportsPage() {
  useDocumentTitle('Workspace Reports');

  const { url } = useRouteMatch();

  const auth = useAuth();
  const features = useFeatures();

  return (
    <LandingPage>
      <NavBox to={url.concat('/client-contacts')}>
        <NavBox.Title>Client Contacts</NavBox.Title>
        <NavBox.Description>
          Client contacts grouped by client including the contact name, title, email address, and phone numbers.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/clients')}>
        <NavBox.Title>Clients</NavBox.Title>
        <NavBox.Description>
          {features.practices
            ? "Clients including each client's practice and relationship owner."
            : 'A list of clients in the workspace.'}
        </NavBox.Description>
      </NavBox>

      {auth.workspace.manage && (
        <NavBox to={url.concat('/history')}>
          <NavBox.Title>History</NavBox.Title>
          <NavBox.Description>
            History of actions taken in the workspace including the event time, entity type, event type, member and
            source.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.workspace.manage && (
        <NavBox to={url.concat('/members')}>
          <NavBox.Title>Members</NavBox.Title>
          <NavBox.Description>
            Workspace members including each member's type, billability, security role, status, and manager.
          </NavBox.Description>
        </NavBox>
      )}

      <NavBox to={url.concat('/project-team-members')}>
        <NavBox.Title>Project Team Members</NavBox.Title>
        <NavBox.Description>
          {features.disciplines && features.practices
            ? "Project team members including each member's status, type, security role, practice and discipline."
            : 'A list of projects and the members assigned to each project.'}
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/projects')}>
        <NavBox.Title>Projects</NavBox.Title>
        <NavBox.Description>
          {features.practices
            ? "Projects including each project's client, project type, status, project admin, billing type and practice."
            : 'A list of projects in the workspace.'}
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/project-tasks')}>
        <NavBox.Title>Project Tasks</NavBox.Title>
        <NavBox.Description>
          Project Tasks grouped by project, including each task's status, assignment, hours, revenue, and budget.
        </NavBox.Description>
      </NavBox>

      {auth.reports.projectHealthByProject && (
        <NavBox to={url.concat('/project-health-by-project')}>
          <NavBox.Title>Project Health By Project</NavBox.Title>
          <NavBox.Description>A list of projects and their current project health scoring.</NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}

export default WorkspaceReportsPage;
