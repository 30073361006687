import { colors } from '~/styles';
import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

// eslint-disable-next-line no-unused-vars
const ButtonBadge = styled(({ className, visible = true, ...props }) => (
  <span className={classNames('badge', className)} {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.375rem;
  height: 1.375rem;
  margin-left: 0.5rem;
  padding: 0 0.375rem;
  white-space: nowrap;
  border-radius: 999rem;
  background-color: ${colors.accent};
  color: ${colors.white};
  font-size: 0.875rem;
  ${({ visible = true }) => {
    if (!visible)
      return css`
        visibility: hidden;
      `;
  }}
`;

export default ButtonBadge;
