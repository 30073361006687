import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dateFormats } from '~/utils';

export default function useDrag({ allocation, dateWidth, canvas, onDrag, onUpdate, onClick, onDrop }) {
  // Drag & Drop
  const [event, setEvent] = useState(null);
  const [x, setX] = useState(allocation.meta.coords.x);
  const [offsetLeft, setOffsetLeft] = useState(null);

  const { start, end } = useMemo(() => {
    const daysOffset = Math.floor((x + dateWidth / 2) / dateWidth) - Math.floor(allocation.meta.coords.x / dateWidth);

    let start;
    let end;
    switch (allocation.unit) {
      case 'day':
      case 'allocation':
      case 'ratio_of_capacity': {
        const days = moment.duration(moment(allocation.end).diff(allocation.start, 'days'), 'days').asDays();
        start = moment(allocation.start).add(daysOffset, 'days');
        end = moment(start).add(days, 'days');
        break;
      }

      case 'week': {
        start = moment(allocation.start).add(daysOffset, 'days').startOf('isoWeek');
        const weeks = moment.duration(moment(allocation.end).diff(allocation.start, 'weeks'), 'weeks').asWeeks();
        end = moment(start).add(weeks, 'weeks').endOf('isoWeek');
        break;
      }

      case 'month': {
        start = moment(allocation.start).add(daysOffset, 'days').startOf('month');
        const months = moment.duration(moment(allocation.end).diff(allocation.start, 'months'), 'months').asMonths();
        end = moment(start).add(months, 'months').endOf('month');
        break;
      }
    }

    return {
      start: start.format(dateFormats.isoDate),
      end: end.format(dateFormats.isoDate),
    };
  }, [x, dateWidth, allocation.meta.coords.x, allocation.unit, allocation.start, allocation.end]);

  // Drag & Drop
  const handleDragStart = useCallback((event) => {
    if (event.button !== 0) return;

    // Store the mouse position when clicking the the draggable element
    setOffsetLeft(event.pageX - event.currentTarget.getBoundingClientRect().left);
    setEvent('down');
  }, []);

  const handleDrag = useCallback(
    (event) => {
      const container = canvas.current;

      const coords = {
        mouse: {
          // The mouse X coordinates.
          x: event.pageX,

          // The mouse left offset relative to the clicked element.
          offsetLeft: offsetLeft,
        },

        canvas: {
          // The canvas' left offset relative to the page.
          offsetLeft: container.getBoundingClientRect().left,

          // The canvas' left scrolling offset.
          scrollLeft: container.scrollLeft,
        },

        element: {
          // The X coordinates of the element
          get x() {
            return coords.mouse.x - coords.mouse.offsetLeft - coords.canvas.offsetLeft + coords.canvas.scrollLeft;
          },
        },
      };

      // Prevent the drag event if the element wasn't moved at least one pixel
      if (Math.abs(allocation.meta.coords.x - coords.element.x) < 1) return;
      setEvent('move');
      setX(coords.element.x);

      // Update the tooltip position
      if (onDrag) onDrag();
    },
    [canvas, offsetLeft, onDrag, allocation.meta.coords.x],
  );

  const handleDragEnd = useCallback(
    async (e) => {
      setEvent(null);
      setOffsetLeft(null);

      switch (event) {
        case 'down':
          if (onClick) onClick(allocation, e);
          break;

        case 'move':
          {
            if (onDrop) {
              onUpdate(true);
              setX(allocation.meta.coords.x);
              await onDrop({ ...allocation, start: start, end: end });
              onUpdate(false);
            }
          }
          break;
      }
    },
    [event, allocation, start, end, onClick, onDrop, onUpdate],
  );

  useEffect(() => {
    setX(allocation.meta.coords.x);
  }, [allocation.meta.coords.x]);

  useEffect(() => {
    if (event) {
      window.addEventListener('pointermove', handleDrag);
      window.addEventListener('pointerup', handleDragEnd);
    }

    return () => {
      window.removeEventListener('pointermove', handleDrag);
      window.removeEventListener('pointerup', handleDragEnd);
    };
  }, [event, handleDrag, handleDragEnd]);

  return { handleDragStart, x, event, start, end };
}
