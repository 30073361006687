export const parseDate = (value) => {
  // Check for "YYYY-MM-DD" format
  if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    return new Date(value);
  }

  // Check for "M/D/YY" or "MM/DD/YYYY" format
  if (/^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/.test(value)) {
    let [month, day, year] = value.split('/');
    if (year.length === 2) {
      // Assuming cutoff for 1900s and 2000s is year '50'
      // Adjust as per your requirement
      year = parseInt(year, 10) + (year < 50 ? 2000 : 1900);
    }
    return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
  }

  // // Check for Excel numeric date format
  // if (/^\d+$/.test(value)) {
  //   const excelDate = parseInt(value, 10);
  //   const jsDate = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
  //   return !isNaN(jsDate.getTime()) ? jsDate : null;
  // }

  return null;
};
