import _ from 'lodash';

function calculateAmounts(creditNote) {
  const transactions = creditNote.lines.filter((line) => _.isNumber(line.amount));

  const linesSubtotal = _.round(_.sumBy(transactions, 'amount'), 2);

  const nonTaxableSubtotal = _.round(
    _(transactions)
      .filter((line) => !line.taxable)
      .sumBy((line) => line.amount),
    2,
  );

  const taxableSubtotal = _.round(
    _(transactions)
      .filter((line) => line.taxable)
      .sumBy((line) => line.amount),
    2,
  );

  const tax = _.round(
    _(transactions)
      .filter((line) => line.taxable)
      .sumBy((line) => line.amount * ((creditNote.taxRate || 0) / 100)),
    2,
  );

  const total = _.round(linesSubtotal + tax, 2);

  return {
    nonTaxableSubtotal,
    taxableSubtotal,
    tax,
    total,
  };
}

export default calculateAmounts;
