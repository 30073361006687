import React, { useEffect, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';

export default function WorkspaceLoader() {
  const api = useApi();
  const { workspace, navigateWorkspace, loginRedirect } = useWorkspace();
  const [noWorkspace, setNoWorkspace] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');

    (async () => {
      if (workspace) {
        navigateWorkspace(workspace.key, page, true);
      } else {
        try {
          const { data } = await api.www.workspaces().lastAccessed();
          navigateWorkspace(data.key, page, true);
        } catch (error) {
          if (error.status === 401) {
            loginRedirect();
          } else if (error.status === 404) {
            setNoWorkspace(true);
          }
        }
      }
    })();
  }, [api, workspace, navigateWorkspace, loginRedirect]);

  if (noWorkspace) {
    return <ErrorPage.NotFound />;
  }
  return <PageLoader />;
}
