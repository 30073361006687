import { Page, Tab, Tabs } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import OpportunityContactsTab from './OpportunityContactsTab';
import OpportunityDetailsPage from './OpportunityDetailsPage';
import OpportunityHeader from './OpportunityHeader';
import { OpportunityFilesTab } from './files';
import OpportunityForm from './OpportunityForm';

export default function OpportunityArea() {
  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const history = useHistory();
  const documentTitle = useDocumentTitle(query.data?.name);

  const api = useApi();
  const { workspace } = useWorkspace();

  const { opportunityId: opportunityId } = useParams();
  const { url, path } = useRouteMatch();

  const fetchData = useCallback(async () => {
    try {
      const { data: opportunity } = await api.www.workspaces(workspace.id).opportunities(opportunityId).get();
      setQuery({ isReady: true, data: opportunity });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, opportunityId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage />;

  const opportunity = query.data;

  return (
    <Page>
      <OpportunityHeader opportunity={opportunity} onStageChange={fetchData} />

      <Tabs style={{ marginTop: '1.6rem' }}>
        <Tab to={`${url}/overview`}>Overview</Tab>
        <Tab to={`${url}/contacts`}>Contacts</Tab>
        <Tab to={`${url}/files`}>Files</Tab>
      </Tabs>

      <Switch>
        <Route path={`${path}/overview`}>
          <OpportunityDetailsPage opportunity={opportunity} onChange={fetchData} />
        </Route>

        <Route path={[`${path}/contacts`, `${path}/contacts/:action`, `${path}/contacts/:contactId/:action`]} exact>
          <OpportunityContactsTab opportunity={opportunity} />
        </Route>

        <Route path={`${path}/files`}>
          <OpportunityFilesTab opportunity={opportunity} />
        </Route>

        <Redirect path="/" to={`${url}/overview`} />
      </Switch>

      <Route path={`${path}/:opportunityTab/edit`}>
        <OpportunityForm
          onSaved={handleSaved}
          onClose={(opportunityTab) => {
            history.push({ pathname: `${url}/${opportunityTab}`, search: location.search });
            documentTitle.set(opportunity.name);
          }}
        />
      </Route>
    </Page>
  );
}
