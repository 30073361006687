import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  border: solid 1px ${colors.grey5};
  border-radius: 0.625rem;
`;

const Title = styled.h2`
  color: ${colors.grey25};
  font-size: 1.5rem;
`;

export default function EmptyReport() {
  return (
    <Container>
      <Title>No data within the selected timespan</Title>
    </Container>
  );
}
