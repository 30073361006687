import { Currency, Grid, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const StyledWidget = styled(Widget)`
  background-color: ${colors.white};
  padding: 0;
`;

const initialState = { isReady: false, data: null };
const handlers = { load: () => ({ isReady: false }), ready: ({ data }) => ({ isReady: true, data }) };

function InvoicesSummaryWidget({ start, end }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    actions.load();

    const { data } = await api.www.workspaces(workspace.id).invoices().openInvoicesKpi({ start, end });

    actions.ready({ data });
  }, [actions, workspace.id, start, end, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <StyledWidget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '13.375rem' }}></Widget.Preview>
      <Widget.Content style={{ minHeight: '13.375rem' }}>{data && <Data data={data} />}</Widget.Content>
    </StyledWidget>
  );
}

const Boxes = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  margin: auto 0 0 -1.5rem;
  height: 5.875rem;
`;

const Value = styled.div`
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }

  ${({ corner }) =>
    corner &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-style: solid;
        border-color: transparent ${corner} transparent transparent;
        border-width: 0 0.75rem 0.75rem 0;
      }
    `}
`;

const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

const OpenColumn = styled(Grid.Column)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Box} {
    width: 100%;
    border-right: 1px solid ${colors.grey10};

    ${Value} {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }
`;

const MeterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: center;
  height: 100%;
  flex: 1;
  padding: 0 2.75rem 0 1.25rem;
`;

const Meter = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  height: 2.5rem;
  background: ${colors.grey10};
  position: relative;
  border-radius: 1rem;
  padding: 0.25rem;
`;

const MeterValue = styled.div`
  flex: ${({ value }) => value};
  flex-basis: 1rem;
  background-color: ${({ color }) => color};
  height: 2rem;

  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

function Data({ data }) {
  return (
    <Grid style={{ flex: 1 }}>
      <Grid.Row style={{ flex: 1 }}>
        <OpenColumn size={3}>
          <Box>
            <Value>
              <Currency value={data.open} />
            </Value>
            <Label>Open Invoices</Label>
          </Box>
        </OpenColumn>
        <Grid.Column>
          <MeterContainer>
            <Meter>
              {data.current > 0 && <MeterValue value={data.current} color={colors.success} />}
              {data.late30 > 0 && <MeterValue value={data.late30} color={colors.warning} />}
              {data.laterThan30 > 0 && <MeterValue value={data.laterThan30} color={colors.danger} />}
            </Meter>
          </MeterContainer>
          <Boxes>
            <Box corner={colors.success}>
              <Value>
                <Currency value={data.current} />
              </Value>
              <Label>Current</Label>
            </Box>
            <Box corner={colors.warning}>
              <Value>
                <Currency value={data.late30} />
              </Value>
              <Label>1 - 30 Days Late</Label>
            </Box>
            <Box corner={colors.danger}>
              <Value>
                <Currency value={data.laterThan30} />
              </Value>
              <Label>&gt; 30 Days Late</Label>
            </Box>
          </Boxes>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default InvoicesSummaryWidget;
