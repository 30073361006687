import Big from 'big.js';
import _ from 'lodash';

// sum and sumBy use big.js to prevent JavaScript rounding issues.
function sum(arr) {
  const sum = arr.reduce((a, b) => {
    return a.plus(b);
  }, Big(0));
  return sum.toNumber();
}

function sumBy(arr, iteratee) {
  if (!_.isArray(arr)) return 0;

  return sum(
    arr
      .map((item) => (_.isFunction(iteratee) ? iteratee(item) : _.get(item, iteratee)))
      .filter((item) => _.isNumber(item)),
  );
}

export { sum, sumBy };
