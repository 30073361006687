import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';
import availabilityPeriods from './availabilityPeriods';
import costPeriods from './costPeriods';
import memberFiles from './memberFiles';
import utilizationTargetPeriods from './utilizationTargetPeriods';

const members =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'members', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      options: (query = {}) => get(url.concat('/options').concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      list: (query = {}) => get(url.concat('/list').concat(new QueryString(query, { multi: true }).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      isEmailAvailable: (query = {}) => post(url.concat('/is-email-available'), query),
      invite: (values) => post(url, values),
      sendInvitation: () => post(url.concat('/send-invitation')),
      remove: () => del(url),
      setActiveStatus: ({ isActive, activeEndDate, activeStartDate }) =>
        put(url.concat('/is-active'), { isActive, activeEndDate, activeStartDate }),
      upsert: (body) => (id ? patch : post)(url, body),
      import: (body) => post(url.concat('/import'), body),
      setImage: (file) =>
        put(url.concat('/image'), file?.bytes, file ? { headers: { 'Content-Type': file?.type } } : undefined),
      setResume: (body) => put(url.concat('/resume'), body),
      availabilityPeriods: availabilityPeriods(url),
      costPeriods: costPeriods(url),
      utilizationTargetPeriods: utilizationTargetPeriods(url),
      holidays: () => get(url.concat('/holidays')),
      holidaySchedule: () => get(url.concat('/holiday-schedule')),
      contactCard: () => get(url.concat('/contact-card')),
      history: () => get(url.concat('/history')),
      projects: {
        assigned: (query = {}) => get(url.concat('/projects/assigned').concat(new QueryString(query).toString(true))),
        unassigned: (query = {}) =>
          get(url.concat('/projects/unassigned').concat(new QueryString(query).toString(true))),
        assign: (values) => post(url.concat('/projects/assign'), values),
      },
      files: memberFiles(url.concat('/files')),
      getAssociations: () => get(url.concat('/associations')),
    };
  };

export default members;
