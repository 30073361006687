import { Currency, Form } from '~/components';
import _ from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { Field } from '../fields';

const Label = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const Value = styled.div`
  margin-top: 0.25rem;
`;

export default function BillingTab({ entry }) {
  const isActuallyBillable = useMemo(() => {
    if (!entry || entry?.type?.id === 'time_off') return false;
    const fields = [entry.project?.isBillable, entry.task?.isBillable];
    if (entry.project?.useRoles) {
      fields.push(entry.role?.isBillable);
    }
    const checks = _.filter(fields, _.isBoolean);
    return checks.length > 0 ? _.every(checks, (check) => !!check) : false;
  }, [entry]);

  const unifiedIsBillable = useMemo(() => {
    if (typeof entry?.isBillable === 'boolean') return entry.isBillable;
    return isActuallyBillable.toString();
  }, [entry, isActuallyBillable]);

  const unifiedRate = useMemo(() => {
    if (typeof entry?.isBillable === 'boolean') return entry.rate;
    if (!isActuallyBillable) return null;

    if (entry?.project?.useRoles) return entry?.role?.rate || null;
    return entry?.projectMember?.rate || null;
  }, [entry, isActuallyBillable]);

  const currency = entry.project?.currency;

  return (
    <div>
      <Form.Section title="Billing Information">
        <Form.Control>
          <Field>
            <Label>Billing overridden?</Label>
            <Value>{typeof entry?.isBillable === 'boolean' ? 'Yes' : 'No'}</Value>
          </Field>
        </Form.Control>
        <Form.Control>
          <Field>
            <Label>Billability</Label>
            <Value>{unifiedIsBillable ? 'Billable' : 'Non-billable'}</Value>
          </Field>
        </Form.Control>
        {unifiedIsBillable && (
          <Form.Control>
            <Field>
              <Label>Rate</Label>
              <Value>
                {typeof unifiedRate === 'number' ? (
                  <>
                    <Currency value={unifiedRate} currency={currency} /> / HR
                  </>
                ) : (
                  'N/A'
                )}
              </Value>
            </Field>
          </Form.Control>
        )}
      </Form.Section>
    </div>
  );
}
