import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import agent from '~/api/agent.js';
import { TimeEntryProvider, useSession, useWorkspace } from '~/contexts';
import { useAuthCheck } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { WorkspaceHeader, WorkspaceMain, WorkspaceMessage, WorkspaceSidebar } from './layout';

export default function WorkspaceArea() {
  const { isReady: isSessionReady } = useSession();
  const { loadWorkspace, refreshWorkspace, workspace, isReady: isWorkspaceReady } = useWorkspace();
  const isBillingLocked = workspace && !workspace.billingStatus?.isValid;
  const isDisabled = workspace && workspace.statusId === 'disabled';

  const { workspaceKey } = useParams();
  const history = useHistory();

  const initialSidebarExpandedRaw = localStorage.getItem('sidebar-expanded');
  const initialSidebarExpanded = initialSidebarExpandedRaw ? initialSidebarExpandedRaw === 'true' : true;
  const [sidebarExpanded, setSidebarExpanded] = useState(initialSidebarExpanded);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
  }, [sidebarExpanded]);

  useAuthCheck();

  useEffect(() => {
    loadWorkspace(workspaceKey);
  }, [workspaceKey, loadWorkspace]);

  // API interceptor for handling special workspace states
  useEffect(() => {
    const interceptor = agent.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handling for a 402 response (workspace subscription issue).
        // This should only happen if a subscription issue occurs during the use of a
        // workspace and should refresh the workspace information and redirect home.
        if (error?.response?.status === 402) {
          // Figure out if a redirect is needed
          const currentPath = window.location.pathname;
          const parts = currentPath.split('/').filter(Boolean);
          const homePath = `/${parts[0]}/${parts[1]}`;
          const billingPath = `/${parts[0]}/${parts[1]}/settings/billing`;
          // Only redirect if not already at the correct path
          if (currentPath !== homePath && currentPath !== billingPath) {
            history.push(homePath);
          }
          // Refresh the current workspace info
          if (!isBillingLocked) {
            refreshWorkspace();
          }
        }
        // Handling for a 423 response (workspace is locked/disabled)
        if (error?.response?.status === 423) {
          const currentPath = window.location.pathname;
          const parts = currentPath.split('/').filter(Boolean);
          const homePath = `/${parts[0]}/${parts[1]}`;
          // Only redirect if not already at the correct path
          if (currentPath !== homePath) {
            history.push(homePath);
          }
          // Refresh the current workspace info
          if (!isDisabled) {
            refreshWorkspace();
          }
        }
        return Promise.reject(error);
      },
    );
    return () => {
      agent.interceptors.response.eject(interceptor);
    };
  }, [history, refreshWorkspace, isBillingLocked, isDisabled]);

  if (!isSessionReady || !isWorkspaceReady) return <PageLoader />;
  if (!workspace) return <ErrorPage.NotFound />;
  return (
    <TimeEntryProvider>
      <WorkspaceMessage />
      <WorkspaceSidebar sidebarExpanded={sidebarExpanded} />
      <WorkspaceHeader sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
      <WorkspaceMain sidebarExpanded={sidebarExpanded} />
    </TimeEntryProvider>
  );
}
