import { Button, Dropdown, Icon } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Component = styled(Dropdown)`
  font-size: 0.875rem;
  white-space: nowrap;
  width: 8rem;
  text-align: left;
  justify-self: center;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.grey75};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

const Menu = styled(Dropdown.Menu)`
  max-height: 16rem;
  overflow-y: auto;
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
`;

export default function CurrencyDropdown({ value, onChange }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);
  const { workspace } = useWorkspace();

  useEffect(() => {
    (async () => {
      const { data } = await api.currencies().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted, workspace.currency, workspace.currencies, value]);

  const selectedOption = useMemo(() => {
    return options?.find((o) => o.code === value);
  }, [options, value]);

  const availableOptions = useMemo(() => {
    return options?.filter(
      (o) => o.isActive && (o.code === value || o.code === workspace.currency || workspace.currencies.includes(o.code)),
    );
  }, [options, value, workspace.currencies, workspace.currency]);

  return (
    <Component position="bottom">
      {({ setIsOpen, ...props }) => {
        return (
          <>
            <Dropdown.Trigger>
              <Button isAnchor onClick={() => setIsOpen(true)}>
                {selectedOption ? (
                  <>
                    {selectedOption.name} ({selectedOption.code})
                  </>
                ) : (
                  'Select Currency'
                )}{' '}
                <Icon spaceLeft icon="angle-down" />
              </Button>
            </Dropdown.Trigger>

            <Menu {...props}>
              {availableOptions.map((option) => (
                <Dropdown.Item
                  key={option.code}
                  value={option.code}
                  onClick={() => {
                    onChange(option.code);
                    setIsOpen(false);
                  }}>
                  {option.name} ({option.code})
                </Dropdown.Item>
              ))}
            </Menu>
          </>
        );
      }}
    </Component>
  );
}
