import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, del, patch, put } from './agent';

const integrations = (workspacesUrl) => () => {
  const url = _([workspacesUrl, 'integrations']).compact().join('/');

  return {
    get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
    getStatus: () => get(url.concat('/status')),

    harvest: {
      get: (query = {}) => get(url.concat('/harvest').concat(new QueryString(query).toString(true))),
      save: (body) => {
        return post(url.concat('/harvest'), body);
      },
      export: (area, config) =>
        get(url.concat('/harvest/export').concat(new QueryString({ area }).toString(true)), config),
      import: (area, importAsRoles = true) => {
        return get(
          url
            .concat('/harvest/import')
            .concat(new QueryString({ area: area, importAsRoles: !!importAsRoles }).toString(true)),
        );
      },
    },

    importCSV: {
      ledgers: (area, config) => get(url.concat('/csv-import/ledgers').concat(), config),
      send: (file, importType) => {
        return put(url.concat('/csv-import').concat(new QueryString({ importType }).toString(true)), file, {
          headers: { 'Content-Type': 'text/csv' },
        });
      },
    },

    slack: {
      get: () => get(url.concat('/slack')),
      getChannel: (channelId) => get(url.concat(`/slack/channel/${channelId}`)),
      getChannels: () => get(url.concat('/slack/channels')),
      getConnected: () => get(url.concat('/slack/connected')),
      getUsers: () => get(url.concat('/slack/users')),
      updateNotifications: (body) => patch(url.concat('/slack/notifications'), body),
      remove: () => del(url.concat('/slack')),
    },
  };
};

export default integrations;
