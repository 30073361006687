import { useMember } from '~/contexts';

/**
 *
 * @param locale
 * @returns Resolves the locale.
 */
export default function useLocale(locale) {
  const { member } = useMember();
  if (!locale) {
    if (member) locale = member.locale;
    else locale = 'en-US';
  }

  return locale;
}
