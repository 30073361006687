import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Page } from '~/components';
import { colors } from '~/styles';
import CalendarHeader from './CalendarHeader';

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};
  z-index: 50;

  &:empty {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }

  & > * {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

function CalendarPage({ actions, children, date, setDate, view, member, setMember }) {
  return (
    <>
      <Page>
        <CalendarHeader view={view} date={date} setDate={setDate} member={member} setMember={setMember} />
        {children}
      </Page>
      {actions && <Actions>{actions}</Actions>}
    </>
  );
}

export default CalendarPage;
