import {
  BackLink,
  Button,
  ClientLink,
  DateTime,
  Icon,
  InternalClientTooltip,
  Level,
  ProjectLink,
  Stack,
  Table,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { intervalOptions, QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import PartialResultsTooltip from '../components/PartialResultsTooltip';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const LinkButton = styled(Button)`
  text-align: left;
  white-space: normal;
`;

function TimeAttachments() {
  useDocumentTitle('Time Attachments');

  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    sort: new QuerySort('date', 'desc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.past_30_days },
        sort: { default: new QuerySort('date', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, projectFilters, clientFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, projectFilters, clientFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then(
      (params) => {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      },
      [searchParams, searchParamsStatus],
    );
  });

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeAttachments(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction =
      column === sort.column ? (sort.direction === 'asc' ? 'desc' : 'asc') : column === 'date' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeAttachments(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />
        <Stack>
          <h1>Time Attachments</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>
        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>
      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;
          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function FileLink({ file }) {
  return (
    <LinkButton isAnchor onClick={() => window.open(file.url, '_blank')}>
      {file.name}
    </LinkButton>
  );
}

function Data({ query, params, onSort }) {
  const data = query.data;

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <TotalResults>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total value={data.records.length} />
        {data.partialResults && <PartialResultsTooltip />}
      </TotalResults>
      <Table>
        <Table.Header sticky>
          <Table.Column name="date" width="10rem" onSort={onSort} sort={params.sort}>
            Week Of
          </Table.Column>
          <Table.Column name="member" onSort={onSort} sort={params.sort}>
            Member
          </Table.Column>
          <Table.Column name="project" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column name="attachment" width="30rem" onSort={onSort} sort={params.sort}>
            Attachment
          </Table.Column>
        </Table.Header>
        <TableBody fade={query.status === 'sorting'}>
          {data.records.map((file) => {
            return (
              <Table.Row key={file.id}>
                <Table.Cell>
                  <DateTime value={file.date} />
                </Table.Cell>
                <Table.Cell>{file.member.name}</Table.Cell>
                <Table.Cell>
                  {file.project && (
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={file.project} />
                      </p>
                      <small>
                        <ClientLink client={file.project.client} />
                        {file.project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FileLink file={file} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}

export default TimeAttachments;
