import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

// isAnchor/isStrong/isOutline is only used by "styled" and should not be passed through to the button
// eslint-disable-next-line no-unused-vars
function Button({ className, isLoading = false, isAnchor = false, isStrong = false, isOutline = false, ...props }) {
  return <button className={classNames('button', className)} disabled={isLoading} type="button" {...props} />;
}

export default styled(Button)`
  &:disabled {
    box-shadow: none;
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  ${(props) =>
    props.isAnchor &&
    css`
      height: auto;
      padding: 0;
      color: ${colors.primary};
      font-size: inherit;
      font-weight: ${props.isStrong ? weights.bold : weights.normal};
      background-color: transparent;
      border: none;

      &:hover {
        color: ${colors.accent};
        background-color: transparent;
      }
    `}

  ${({ isOutline }) =>
    isOutline &&
    css`
      padding: calc(0.5em - 1px) calc(1.75em - 1px);
      color: ${colors.primary};
      background-color: ${colors.white};
      border-color: ${colors.primary};
      border-width: 2px;

      &:hover {
        color: ${colors.accent};
        background-color: ${colors.white};
        border-color: ${colors.accent};
      }
    `}

    ${({ isLoading }) =>
    isLoading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &::after {
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        animation: spinAround 0.5s infinite linear;
        border: 2px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 1em;
        width: 1em;
      }
    `}

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
