import salesforceIcon from '~/assets/public/salesforce1.svg';
import { Tooltip } from '~/components';
import { useIntegrations } from '~/contexts';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0.5rem;
`;

const Image = styled.img`
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
`;

export default function SalesforceIndicator({ message = 'Synced from Salesforce' }) {
  const integrations = useIntegrations();
  if (!integrations.salesforce) return null;

  return (
    <Wrapper>
      <Tooltip message={message}>
        <Image src={salesforceIcon} />
      </Tooltip>
    </Wrapper>
  );
}
