import { Button } from '~/components';
import Icon from '~/components/Icon';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import SplitButton from '../SplitButton';

const TableSplitButton = styled(SplitButton)`
  > .button:first-child {
    background-color: ${colors.white};
    color: ${colors.primary};
    border: 2px solid ${colors.grey5};
    padding: 0.5rem 0;
    width: 3.5rem;

    .icon {
      font-size: 0.8rem;
      margin-left: 0.2rem;
    }
  }
`;

const Success = styled.div`
  border-top-left-radius: 999rem;
  border-bottom-left-radius: 999rem;
  height: 2.5em;
  padding: 0.5em 0;
  width: 3.5rem;
  color: ${colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.success};

  .icon {
    font-size: 0.8rem;
    margin-left: 0.2rem;
  }
`;

function TableSplitButtonSuccess(props) {
  return (
    <Success {...props}>
      <Icon icon="check" />
    </Success>
  );
}

function TableSplitButtonEdit(props) {
  return (
    <Button {...props}>
      <Icon icon="pencil-alt" />
    </Button>
  );
}

TableSplitButton.Success = TableSplitButtonSuccess;
TableSplitButton.Edit = TableSplitButtonEdit;

export default TableSplitButton;
