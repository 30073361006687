import { DateTime, Icon } from '~/components';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { AllocationTooltip, Arrow } from './AllocationStyles';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function ResizeTooltip({ start, end, handle, referenceElement, updatePopperRef }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'flip', options: { flipVariations: false } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
    placement: { start: 'top-start', end: 'top-end' }[handle],
  });

  useEffect(() => {
    updatePopperRef.current = update;

    return () => {
      updatePopperRef.current = null;
    };
  }, [updatePopperRef, update]);

  return (
    <AllocationTooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Row>
        <Icon icon="calendar" type="far" spaceRight color={colors.primary} />
        <DateTime value={start} format={dateFormats.standardDate} />
      </Row>

      <Row>
        <Icon icon="calendar" type="far" spaceRight color={colors.primary} />
        <DateTime value={end} format={dateFormats.standardDate} />
      </Row>

      <Arrow
        ref={setArrowElement}
        style={{
          left: handle === 'start' ? 5 : undefined,
          right: handle === 'end' ? 5 : undefined,
        }}
      />
    </AllocationTooltip>
  );
}
