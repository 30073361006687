const dateFormats = {
  isoDate: 'YYYY-MM-DD',
  standardDate: 'L',
  compactDate: 'l',
  longDate: 'LL',
  shortDate: 'll',
  compactDateTime: 'l LT',
  dateTime: 'l LTS',
  monthYear: 'MMM-YYYY',
};

export default dateFormats;
