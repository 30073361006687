import React from 'react';
import Tag from './Tag';

export default function ExpenseTypeTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Expense Type" {...props}>
      {
        {
          member: 'Member',
          project: 'Project',
        }[value]
      }
    </Tag>
  );
}
