export const PUBLIC_BASE_URL = 'https://www.ruddr.com';

const changedPaths = {
  '/privacy': '/privacy-policy',
};

export default function redirectPublic(path) {
  const url = path ? new URL(changedPaths[path] ?? path, PUBLIC_BASE_URL).href : PUBLIC_BASE_URL;
  window.location.assign(url);
}
