import { DayPickerInput } from '~/components';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { dateFormats } from '~/utils';

const Filter = styled.div`
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

function ApprovalsPeriodFilter({ clearable = false, maxDays, name, onChange, value }) {
  const handleStartChange = (start) => {
    if (!start) return;

    let end = value.end;
    if (start) {
      if (!end || moment(end).diff(start, 'days') < 0 || Math.abs(moment(end).diff(start, 'days')) > maxDays) {
        end = moment(start).add(maxDays, 'days').format('YYYY-MM-DD');
      }
    }

    onChange({
      target: {
        name,
        value: {
          start,
          end,
        },
      },
    });
  };

  const handleEndChange = (end) => {
    if (!end) return;

    let start = value.start;
    if (end && start && (moment(end).diff(start, 'days') < 0 || Math.abs(moment(end).diff(start, 'days')) > maxDays)) {
      start = moment(end).subtract(maxDays, 'days').format('YYYY-MM-DD');
    }

    onChange({
      target: {
        name,
        value: {
          start,
          end,
        },
      },
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <Filter>
        <DayPickerInput
          data-testid="start_date"
          value={value.start}
          placeholder="Start"
          displayFormat={dateFormats.compactDate}
          clearable={clearable}
          onChange={handleStartChange}
        />
      </Filter>

      <Filter>
        <DayPickerInput
          data-testid="end_date"
          value={value.end}
          placeholder="End"
          displayFormat={dateFormats.compactDate}
          clearable={clearable}
          onChange={handleEndChange}
        />
      </Filter>
    </div>
  );
}

export default ApprovalsPeriodFilter;
