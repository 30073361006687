import Icon from '~/components/Icon';
import Tooltip from '~/components/Tooltip';
import React from 'react';
import styled from 'styled-components';
import colors from '~/styles/colors';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${colors.grey25};
`;

function PartialResultsTooltip(props) {
  return (
    <IconContainer style={{ marginTop: '0.15rem' }}>
      <Tooltip message="Your assigned security role may not allow you to see all results.">
        <Icon {...props} icon="info-circle" />
      </Tooltip>
    </IconContainer>
  );
}

export default PartialResultsTooltip;
