import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  ProjectTaskTagMultiSelect,
  Radio,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function ProjectTaskTemplateDrawer({ template, onClose, onSaved, onDeleted }) {
  const title = `${template?.id ? 'Edit' : 'New'} Task Template`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues({ budgetHours: '', name: '', notes: '', isBillable: true, tags: [] }, template);

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const body = emptyStringToNull({
              ..._.omit(values, ['tags']),
              tagAssignments: values.tags.map((tag) => ({ projectTaskTagId: tag.id })),
            });

            const { data } = await api.www.workspaces(workspace.id).projectTaskTemplates(template?.id).upsert(body);

            await onSaved(data);
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Task Template successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>
              Are you sure that you want to delete this Task Template?
            </DeleteConfirmation>
          ));
          if (!confirm) return;
          try {
            await api.www.workspaces(workspace.id).projectTaskTemplates(template.id).delete();
            await onDeleted();
            closeDrawer();
          } catch ({ message }) {
            toast.error(message);
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().label('Name').max(255).required(),
              isBillable: Yup.bool().label('Is Billable').required(),
              budgetHours: Yup.number().label('Hours Budget').min(0).max(9999.99).nullable(),
              notes: Yup.string().label('Notes').max(5000),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                formik.submitForm();
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <Field.Control>
                        <ProjectTaskTagMultiSelect
                          name="tags"
                          placeholder="Tags"
                          value={formik.values.tags}
                          allowNew
                          onChange={({ target: { value } }) => formik.setFieldValue('tags', value)}
                        />
                      </Field.Control>
                    </Form.Control>
                    <Form.Control>
                      <Field.Number name="budgetHours" placeholder="Hours Budget" min={0} precision={2} />
                    </Form.Control>
                    <Form.Control>
                      <Field.RadioGroup name="isBillable">
                        <Radio value={true} label="Billable" />
                        <Radio value={false} label="Non-billable" />
                      </Field.RadioGroup>
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea name="notes" placeholder="Notes" maxLength={5000} />
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    {template.id && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!template?.id && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}

                      <ActionButton
                        isLoading={isSubmitting === 'close'}
                        ok={saved === 'close'}
                        type="submit"
                        onClick={() => submit('close')}>
                        Save &amp; Close
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
