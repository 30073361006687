import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, Icon, SingleSelect, Table, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

export default function PaymentMethodMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ paymentMethods, qboPaymentMethods, isReady }, setState] = useState({ paymentMethods: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: paymentMethods } = await api.www.workspaces(workspace.id).paymentMethods().get({ size: 1000 });
      const { data: qboPaymentMethods } = await api.www.workspaces(workspace.id).qbo.getPaymentMethods();

      if (!isMounted.current) return;
      setState({ paymentMethods, qboPaymentMethods, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updatePaymentMethodMapping = ({ paymentMethodId, qboPaymentMethodId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      paymentMethods: paymentMethods.map((c) => (c.id === paymentMethodId ? { ...c, qboPaymentMethodId } : c)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Payment Method Mappings"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = paymentMethods.map((paymentMethod) => ({
              paymentMethodId: paymentMethod.id,
              qboPaymentMethodId: paymentMethod.qboPaymentMethodId,
            }));
            await api.www.workspaces(workspace.id).qbo.updatePaymentMethodMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table>
              <Table.BoxHeader>
                <Table.Column width="40%">Payment Method</Table.Column>
                <Table.Column>QuickBooks Payment Method</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {paymentMethods.map((paymentMethod) => {
                  const handlePaymentMethodChange = (event) => {
                    updatePaymentMethodMapping({
                      paymentMethodId: paymentMethod.id,
                      qboPaymentMethodId: event.target.value || null,
                    });
                  };

                  return (
                    <Table.BoxRow key={paymentMethod.id}>
                      <Table.Cell>{paymentMethod.name}</Table.Cell>
                      <Table.Cell>
                        <PaymentMethodSelect
                          paymentMethods={qboPaymentMethods}
                          value={paymentMethod.qboPaymentMethodId}
                          onChange={handlePaymentMethodChange}
                        />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function PaymentMethodSelect({ paymentMethods, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return paymentMethods;
    }

    const fuse = new Fuse(paymentMethods, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [paymentMethods, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const paymentMethod = _.find(paymentMethods, { id: value });
    if (!paymentMethod)
      return {
        id: value,
        name: (
          <Tooltip message="Payment Method not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Payment Method ID: {value}
          </Tooltip>
        ),
      };
    return paymentMethod;
  }, [paymentMethods, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
