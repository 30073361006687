import * as Sentry from '@sentry/react';
import {
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import ChartAnnotation from 'chartjs-plugin-annotation';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'moment/dist/locale/de.js';
import 'moment/dist/locale/en-au.js';
import 'moment/dist/locale/en-ca.js';
import 'moment/dist/locale/en-gb.js';
import 'moment/dist/locale/en-ie.js';
import 'moment/dist/locale/en-nz.js';
import 'moment/dist/locale/es-mx.js';
import 'moment/dist/locale/es.js';
import 'moment/dist/locale/fr-ca.js';
import 'moment/dist/locale/fr.js';
import 'moment/dist/locale/id.js';
import 'moment/dist/locale/it.js';
import 'moment/dist/locale/ja.js';
import 'moment/dist/locale/nl.js';
import 'moment/dist/locale/pt-br.js';
import 'moment/dist/locale/pt.js';
import 'moment/dist/locale/sv.js';
import 'moment/dist/locale/th.js';
import 'moment/dist/locale/zh-cn.js';
import { rgba } from 'polished';
import settings from './settings';
import { colors } from './styles';

// Setup moment
momentDurationFormatSetup(moment);

// Setup Sentry
if (settings.useSentry) {
  Sentry.init({
    dsn: 'https://a08dd67b7ac1467ca07845cfd419e5da@o154018.ingest.sentry.io/5409737',
    environment: import.meta.env.VITE_SENTRY_ENV || 'development',
  });
}

const canvasBackground = {
  id: 'canvasBackground',
  beforeDraw: (chart, args, options) => {
    if (options?.color === 'transparent') return;

    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options?.color ?? colors.white;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

// Setup Chart.js
Chart.register(
  BarElement,
  CategoryScale,
  ChartAnnotation,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  canvasBackground,
);
Chart.defaults.borderColor = rgba(colors.black, 0.1);
Chart.defaults.backgroundColor = rgba(colors.black, 0.1);
Chart.defaults.color = colors.grey75;
Chart.defaults.font.family = "'Roboto', sans-serif";
Chart.defaults.font.lineHeight = 1.4;
Chart.defaults.plugins.title.font.size = 18;
Chart.defaults.plugins.tooltip.backgroundColor = rgba(colors.black, 0.8);
Chart.defaults.plugins.tooltip.borderColor = colors.black;
