import { Confirmation } from '~/components';
import React from 'react';

function UseRolesProjectConfirmation({ onResolve, onSubmit }) {
  return (
    <Confirmation
      resolve={(confirm) => {
        if (confirm) onSubmit();
        onResolve();
      }}>
      Are you sure that you want to convert this project to Use Roles?
    </Confirmation>
  );
}

export default UseRolesProjectConfirmation;
