import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '~/assets/logo-reversed.svg';
import { Logo } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const Container = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 3.75rem;
  border-bottom: solid 1px ${colors.grey75};
  z-index: 3;
`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const LogoImage = styled.img`
  display: block;
  width: 5.625rem;
  margin-right: 3.875rem;
`;

const LogoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
`;

export default function SidebarLogo({ sidebarExpanded }) {
  const { workspace } = useWorkspace();
  return (
    <Container>
      <HomeLink to={workspace ? `/app/${workspace.key}` : '/'}>
        {sidebarExpanded ? (
          <LogoImage src={logo} alt="Ruddr" />
        ) : (
          <LogoIcon>
            <Logo />
          </LogoIcon>
        )}
      </HomeLink>
    </Container>
  );
}
