import _ from 'lodash';
import invoiceStatuses from '~/lookups/invoice-statuses';
import React from 'react';
import Tag from './Tag';

export default function InvoiceStatusTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Invoice Status" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? invoiceStatuses[v].name)
        .join('; ')}
    </Tag>
  );
}
