import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  CurrencyInput,
  DateTime,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  Level,
  Table,
  Tooltip,
} from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import QBOIndicator from '../../../invoices/components/QBOIndicator';
import XeroIndicator from '../../../invoices/components/XeroIndicator';

const Container = styled.div`
  font-size: 0.875rem;

  input {
    font-size: inherit;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  margin-bottom: 1rem;
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding: 1rem 3rem 1rem 0;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const TotalRow = styled(Table.Row)`
  border-bottom: none;
  font-weight: ${weights.bold};
  text-transform: uppercase;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export default function PublishDialog({ creditNoteId, onClose, onSaved }) {
  useDocumentTitle('Publish Credit Note');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ creditNote, ...creditNoteQuery }, setCreditNote] = useState({ creditNote: null, isReady: false });
  const [invoices, setInvoices] = useState({ data: [], isReady: false });
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const toast = useToast();

  const integrations = useIntegrations();
  const [saveToQuickBooks, setSaveToQuickBooks] = useState(() => integrations.qbo);
  const [saveToXero, setSaveToXero] = useState(() => integrations.xero);

  const fetchInvoices = useCallback(
    async ({ clientId, currency }) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .creditNotes()
        .findOpenInvoices({ clientId, currency, creditNoteId });

      setInvoices({ data, isReady: true });
      return data;
    },
    [api, workspace.id, creditNoteId],
  );

  const fetchData = useCallback(async () => {
    try {
      const { data: creditNote } = await api.www.workspaces(workspace.id).creditNotes(creditNoteId).get();
      setCreditNote({ creditNote, isReady: true });
      fetchInvoices({ clientId: creditNote.clientId, currency: creditNote.currency });
    } catch (error) {
      toast.error('An error occurred while loading the credit note.');
      setCreditNote({ creditNote: null, isReady: true });
      onClose();
    }
  }, [workspace.id, creditNoteId, api, fetchInvoices, toast, onClose]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    onClose();
  }

  const creditNoteInvoices = useMemo(() => {
    if (!creditNote) return;
    if (!invoices.isReady) return;
    if (creditNote.invoices.length) return creditNote.invoices;

    const applyTo = _.orderBy(invoices.data, (i) => {
      if (creditNote.invoiceId === i.id) return -1;
      return moment(i.dueOn).valueOf() || Infinity;
    });

    const creditNoteInvoices = [];

    let remaining = creditNote.total;
    for (const invoice of applyTo) {
      if (remaining <= 0) break;

      const amount = Math.min(remaining, invoice.balance);
      creditNoteInvoices.push({ invoiceId: invoice.id, amount });
      remaining -= amount;
    }

    return creditNoteInvoices;
  }, [creditNote, invoices]);

  if (!creditNoteQuery.isReady) return null;
  if (!creditNote) return <ErrorPage publicSite={false} />;
  if (!invoices.isReady) return null;

  const calculateBalance = (invoiceId) => {
    const invoice = invoices.data.find((invoice) => invoice.id === invoiceId);

    let balance = invoice.balance;

    const applied = creditNote.invoices.find((i) => i.invoiceId === invoice.id);
    if (applied) balance += applied.amount;

    return balance;
  };

  function getQuickBooksErrorMessage(values) {
    if (!integrations.qbo) return;
    if (!creditNote.client.qboCustomerId) return 'The selected client is not mapped to a QuickBooks Customer.';
    if (!invoices.isReady) return;
    if (
      !values.creditNoteInvoices.every((cni) => {
        const invoice = invoices.data.find((i) => i.id === cni.invoiceId);
        return invoice.qboInvoiceId;
      })
    )
      return 'One or more invoices are not in QuickBooks.';
  }

  function getXeroErrorMessage(values) {
    if (!integrations.xero) return;
    if (!creditNote.client.xeroContactId) return 'The selected client is not mapped to a Xero Contact.';
    if (!invoices.isReady) return;
    if (
      !values.creditNoteInvoices.every((cni) => {
        const invoice = invoices.data.find((i) => i.id === cni.invoiceId);
        return invoice.xeroInvoiceId;
      })
    )
      return 'One or more invoices are not in Xero.';
  }

  const initialValues = mergeValues({
    creditNoteInvoices,
  });

  const schema = Yup.object()
    .shape({
      creditNoteInvoices: Yup.array().of(
        Yup.object().shape({
          amount: Yup.number()
            .label('Amount')
            .min(0)
            .max(99999999999)
            .nullable()
            .test('amount', function (value) {
              const balance = calculateBalance(this.parent.invoiceId);

              if (balance < value)
                return this.createError({
                  message: 'The applied credit cannot exceed the invoice balance.',
                });

              return value;
            }),
        }),
      ),
    })
    .test('total', function (values) {
      if (creditNote.total !== _.round(_.sumBy(values.creditNoteInvoices, 'amount'), 2))
        return this.createError({
          path: 'total',
          message: 'The applied credit must match the total credit.',
        });

      return true;
    });

  return (
    <Drawer
      isOpen
      title="Publish Credit Note"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSubmit = async (values) => {
          try {
            form.submit();
            let result;
            const body = emptyStringToNull({ ...values, target: integrations.qbo ? 'payment' : 'invoice' });

            const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).publish(body);
            result = data;

            if (saveToQuickBooks && !getQuickBooksErrorMessage(values)) {
              try {
                const { data } = await api.www.workspaces(workspace.id).creditNotes(result.id).saveToQuickBooks();
                result = data.creditNote;
              } catch (error) {
                toast.error(
                  error.message ??
                    'An error has occurred. The credut note has not been saved to QuickBooks. Please review the QuickBooks integration settings and try again.',
                );
              }
            }

            if (saveToXero && !getXeroErrorMessage(values)) {
              try {
                const { data } = await api.www.workspaces(workspace.id).creditNotes(result.id).saveToXero();
                result = data.creditNote;
              } catch (error) {
                toast.error(
                  error.message ??
                    'An error has occurred. The credit note has not been saved to Xero. Please review the Xero integration settings and try again.',
                );
              }
            }

            form.save();
            closeDrawer();
            if (onSaved) onSaved(result);
            toast.success(`Credit Note has been published with number #${result.number}.`);
          } catch (error) {
            form.error(error);
          }
        };

        return (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {(formik) => {
              const total = _.round(
                _.sumBy(formik.values.creditNoteInvoices, (cni) => Number(cni.amount) || 0),
                2,
              );

              const qboErrorMessage = getQuickBooksErrorMessage(formik.values);
              const xeroErrorMessage = getXeroErrorMessage(formik.values);

              const currency = creditNote.currency;

              return (
                <Form>
                  <Form.Control>
                    <ReadTextbox label="Client" value={creditNote.client.name} />
                    <ReadTextbox
                      label="Total Credit"
                      value={
                        <p style={{ textAlign: 'right' }}>
                          <Currency value={creditNote.total} currency={currency} />
                        </p>
                      }
                    />
                  </Form.Control>

                  <Container>
                    <SectionTitle>Open Invoices</SectionTitle>

                    {invoices.data.length === 0 ? (
                      'There are no open invoices for the selected client and currency.'
                    ) : (
                      <Table>
                        <Table.BoxHeader>
                          <Table.Column width="2.5rem" />
                          <Table.Column>Invoice #</Table.Column>
                          <Table.Column width="7rem">Due Date</Table.Column>
                          <Table.Column width="7.5rem" align="right">
                            Original Amount
                          </Table.Column>
                          <Table.Column width="7.5rem" align="right">
                            Open Balance
                          </Table.Column>
                          <Table.Column align="right">Credit</Table.Column>
                        </Table.BoxHeader>

                        <Table.Body>
                          {invoices.data.map((invoice) => {
                            const index = _.findIndex(
                              formik.values.creditNoteInvoices,
                              (cni) => cni.invoiceId === invoice.id,
                            );
                            const creditNoteInvoice = formik.values.creditNoteInvoices[index];
                            const amount = creditNoteInvoice?.amount || '';

                            const balance = calculateBalance(invoice.id);

                            const errors = formik.errors.creditNoteInvoices
                              ? formik.errors.creditNoteInvoices[index]
                              : {};

                            const handleAmountChange = (value) => {
                              let creditNoteInvoices = [...formik.values.creditNoteInvoices];

                              if (!value || Number(value) === 0) {
                                creditNoteInvoices = creditNoteInvoices.filter((cni) => cni.invoiceId !== invoice.id);
                              } else {
                                creditNoteInvoices = creditNoteInvoice
                                  ? creditNoteInvoices.map((cni) =>
                                      cni.invoiceId === invoice.id ? { ...cni, amount: value } : cni,
                                    )
                                  : [...creditNoteInvoices, { invoiceId: invoice.id, amount: value }];
                              }

                              formik.setValues({ ...formik.values, creditNoteInvoices });
                            };

                            const handleAmountBlur = () => {
                              formik.setFieldTouched('creditNoteInvoices', true, false);

                              if (formik.errors.total || errors?.amount) formik.validateForm();
                            };

                            return (
                              <Table.BoxRow key={invoice.id}>
                                <Table.Cell>
                                  {!!creditNoteInvoice && <Icon icon="check" color={colors.success} />}
                                </Table.Cell>

                                <Table.Cell>
                                  #{invoice.number}
                                  {invoice.qboInvoiceId && (
                                    <span>
                                      <QBOIndicator />
                                    </span>
                                  )}
                                  {invoice.xeroInvoiceId && (
                                    <span>
                                      <XeroIndicator />
                                    </span>
                                  )}
                                </Table.Cell>

                                <Table.Cell>
                                  <DateTime value={invoice.dueOn} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Currency value={invoice.total} currency={currency} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Currency value={balance} currency={currency} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Field.Control error={errors?.amount}>
                                    <CurrencyInput
                                      value={amount}
                                      currency={currency}
                                      onChange={handleAmountChange}
                                      onBlur={handleAmountBlur}
                                    />
                                  </Field.Control>
                                </Table.Cell>
                              </Table.BoxRow>
                            );
                          })}

                          <TotalRow>
                            <Table.Cell />
                            <Table.Cell>Total</Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell>
                              <Field.Control error={formik.errors.total} style={{ alignItems: 'center' }}>
                                <Currency value={total} currency={currency} />
                              </Field.Control>
                            </Table.Cell>
                          </TotalRow>
                        </Table.Body>
                      </Table>
                    )}
                  </Container>

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Drawer.Actions>
                    <Level>
                      {integrations.qbo && (
                        <Level.Item>
                          <CheckboxContainer>
                            {qboErrorMessage && (
                              <Tooltip message={qboErrorMessage}>
                                <Icon icon="exclamation-circle" color={colors.warning} spaceRight />
                              </Tooltip>
                            )}

                            <Checkbox
                              label="Save to QuickBooks"
                              disabled={qboErrorMessage}
                              checked={saveToQuickBooks && !qboErrorMessage}
                              onChange={() => setSaveToQuickBooks(!saveToQuickBooks)}
                            />
                          </CheckboxContainer>
                        </Level.Item>
                      )}

                      {integrations.xero && (
                        <Level.Item>
                          <CheckboxContainer>
                            {xeroErrorMessage && (
                              <Tooltip message={xeroErrorMessage}>
                                <Icon icon="exclamation-circle" color={colors.warning} spaceRight />
                              </Tooltip>
                            )}

                            <Checkbox
                              label="Save to Xero"
                              disabled={xeroErrorMessage}
                              checked={saveToXero && !xeroErrorMessage}
                              onChange={() => setSaveToXero(!saveToXero)}
                            />
                          </CheckboxContainer>
                        </Level.Item>
                      )}
                    </Level>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button type="submit" isLoading={isSubmitting}>
                        Publish
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
