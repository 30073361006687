import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RedirectPublic } from '~/components';
import { useSession, useWorkspace } from '~/contexts';
import CreditNotePreviewPage from '~/routes/app/billing/credit-notes/preview/CreditNotePreviewPage';
import InvoicePreviewPage from '~/routes/app/invoices/invoice-preview/InvoicePreviewPage';
import ClientApprovalPublicPage from '~/routes/app/projects/client-approvals/public/ClientApprovalPublicPage';
import { ErrorPage } from '../public/pages';
import {
  EmailVerified,
  ForgotPasswordForm,
  ForgotWorkspacePage,
  JoinWorkspace,
  Login,
  LoginError,
  LoginToken,
  PasswordResetForm,
  Signup,
  VerifyEmail,
} from './auth';
import { DeviceLoginError, DeviceLoginToken, DeviceLoginTokenVerified } from './auth/device';
import { Home } from './home';
import { QBORedirect, SlackAuthorization, SlackRegistration, XeroRedirect, XeroSignup } from './integrations';

function PublicRoutes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>

      <Route path="/features" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/dashboards-kpis" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/expense-tracking" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/invoicing" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/multicurrency" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/project-budgeting" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/project-management" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/reporting" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/resourcing" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/slack-integration" exact>
        <RedirectPublic />
      </Route>

      <Route path="/features/time-tracking" exact>
        <RedirectPublic />
      </Route>

      <Route path="/pricing" exact>
        <RedirectPublic />
      </Route>

      <Route path="/applications" exact>
        <RedirectPublic />
      </Route>

      <Route path="/about" exact>
        <RedirectPublic />
      </Route>

      <Route path="/careers" exact>
        <RedirectPublic />
      </Route>

      <Route path="/contact" exact>
        <RedirectPublic />
      </Route>

      <Route path="/book-a-demo" exact>
        <RedirectPublic />
      </Route>

      <Route path="/request-a-quote" exact>
        <RedirectPublic />
      </Route>

      <Route path="/privacy" exact>
        <RedirectPublic />
      </Route>

      <Route path="/terms" exact>
        <RedirectPublic to="/master-subscription-agreement" />
      </Route>

      <Route path="/master-subscription-agreement" exact>
        <RedirectPublic />
      </Route>

      <Route path="/service-level-agreement" exact>
        <RedirectPublic />
      </Route>

      <Route path="/resources" exact>
        <RedirectPublic />
      </Route>

      <Route path="/resources/videos" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.1-what-are-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.2-characteristics-of-professional-services-companies" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.3-business-of-satisfaction-in-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.4-balance-supply-demand-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.5-kpis-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/1.6-financial-statements-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.1-start-professional-services-niche" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.2-marketing-for-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.3-professional-services-sales-pipeline" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.4-sales-best-practices-for-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.5-provide-professional-services-clients-options" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.6-all-revenue-not-equal-in-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.7-forecasting-for-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.8-bookings-billings-revenue-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.9-project-estimation-for-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.10-legal-contracts-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.11-trusted-advisor-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.12-invest-existing-clients-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/2.13-sales-compensation-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/3.1-satisfaction-experience-expectations-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/3.2-value-delivery-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/3.3-net-promoter-score-nps-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/3.4-relationship-equity-piggy-bank-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/4.1-work-life-balance-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/4.2-culture-competitive-advantage-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/4.3-performance-evaluation-okrs-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/4.4-anonymous-employee-survey-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/5.1-calculating-utilization-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/5.2-revenue-backlog-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/5.3-leverage-model-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/5.4-resource-optimization-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/6.1-managing-bench-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/6.2-effective-bill-rates-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/6.3-project-management-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/6.4-process-definition-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/compass/book/6.5-gross-margin-professional-services" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/bamboohr" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/expensify" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/slack" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/slack/authorization">
        <SlackAuthorization />
      </Route>

      <Route path="/integrations/slack/registration/:token">
        <SlackRegistration />
      </Route>

      <Route path="/integrations/qbo" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/qbo/authorization">
        <QBORedirect />
      </Route>

      <Route path="/integrations/xero" exact>
        <RedirectPublic />
      </Route>

      <Route path="/integrations/xero/authorization">
        <XeroRedirect />
      </Route>

      <Route path="/integrations/xero/signup" exact>
        <XeroSignup />
      </Route>

      <Route path="/login" exact>
        <Login />
      </Route>

      <Route path="/login/token" exact>
        <LoginToken />
      </Route>

      <Route path="/login/error" exact>
        <LoginError />
      </Route>

      <Route path="/device/login/token" exact>
        <DeviceLoginToken />
      </Route>

      <Route path="/device/login/token/verified" exact>
        <DeviceLoginTokenVerified />
      </Route>

      <Route path="/device/login/error" exact>
        <DeviceLoginError />
      </Route>

      {/* Permanent redirect to the Signup page */}
      <Redirect from="/new-workspace" to="/signup" />

      <Route path={'/signup'}>
        <Signup />
      </Route>

      <Route path="/verify-email/:token">
        <VerifyEmail />
      </Route>

      <Route path="/email-verified">
        <EmailVerified />
      </Route>

      <Route path="/join-workspace/:token">
        <JoinWorkspace />
      </Route>

      <Route exact path="/password-reset">
        <ForgotPasswordForm />
      </Route>

      <Route path="/password-reset/:token">
        <PasswordResetForm />
      </Route>

      <Route path="/get-workspace-links">
        <ForgotWorkspacePage />
      </Route>

      <Route path="/logout">
        <Logout />
      </Route>

      {/* Permanent redirect to the My Profile page */}
      <Route path="/settings">
        <Redirect to={`/app?page=${encodeURIComponent('/my-profile')}`} />
      </Route>

      <Route path="/:workspaceKey/invoices/:invoiceId">
        <InvoicePreviewPage />
      </Route>

      <Route path="/:workspaceKey/receipts/:receiptId">
        <InvoicePreviewPage />
      </Route>

      <Route path="/:workspaceKey/credit-notes/:creditNoteId">
        <CreditNotePreviewPage />
      </Route>

      <Route path="/:workspaceKey/client-approvals/:clientApprovalId">
        <ClientApprovalPublicPage />
      </Route>

      <Route path="/500" exact>
        <ErrorPage />
      </Route>

      <Route path="/403" exact>
        <ErrorPage.Forbidden />
      </Route>

      <Route>
        <ErrorPage.NotFound />
      </Route>
    </Switch>
  );
}

function Logout() {
  const { logout, isLoggedIn } = useSession();
  const { clearWorkspace } = useWorkspace();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const workspace = new URLSearchParams(location.search).get('workspace');
        const session = await logout(workspace);
        clearWorkspace();
        if (!session) {
          history.replace('/');
        } else {
          history.replace('/app');
        }
      })();
    }
  }, [isLoggedIn, logout, clearWorkspace, history, location.search]);

  return null;
}

export default PublicRoutes;
