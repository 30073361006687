import React, { useState } from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function RemoveExpensifyConfirmation({ onClose, onRemove }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isRemoving, setIsRemoving] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  async function handleRemove() {
    setIsRemoving(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).expensify.remove();

      if (typeof onRemove === 'function') {
        await onRemove();
      }
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }

  return (
    <ModalCard title="Remove Expensify Integration" onClose={onClose}>
      <ModalCard.Body>
        <Description>This will remove the Expensify integration and its settings.</Description>
        {errorMessage && <FormMessage.Error spaceTop>{errorMessage}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
            Cancel
          </CancelButton>
          <DeleteButton isLoading={isRemoving} onClick={handleRemove}>
            Remove
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
