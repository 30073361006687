import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function CostInstruction() {
  return (
    <>
      <p>
        Organize your CSV contents into the following columns, and include all columns even if the values are not
        required:
      </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Start Date</td>
            <td>No</td>
            <td>The start date of the cost period. If left blank, the existing cost record will be updated.</td>
          </tr>
          <tr>
            <td>Member Email</td>
            <td>Yes</td>
            <td>The email of the member.</td>
          </tr>
          <tr>
            <td>Cost Per Hour</td>
            <td>Yes</td>
            <td>The cost per hour for the member.</td>
          </tr>
          <tr>
            <td>Overhead Cost Per Hour</td>
            <td>No</td>
            <td>
              The overhead cost per hour for the member. Leave this blank if not needed, but the column should be
              present in the file.
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>No</td>
            <td>
              The currency used for the costs. If nothing is provided the member's default rate currency will be used.
            </td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
