import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { validate } from 'uuid';
import { Button, DeleteButton, FormMessage, Icon, TextInput, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import { ReportLoader } from '../reports/components';
import RemoveConnectionModal from './RemoveConnectionModal';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
  margin-right: 1rem;
`;

const ClearButton = styled(Button)`
  position: absolute;
  height: 50%;
  width: 0.875rem;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  user-select: none;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TitleInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 10rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  min-width: 5rem;
  margin-right: 2rem;
  align-items: ${({ align = 'left' }) => align};
`;

const StatTitle = styled.div`
  font-weight: ${weights.medium};
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatItems = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
`;

export default function Connections() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [userId, setUserId] = useState('');
  const [connections, setConnections] = useState([]);
  const [connection, setConnection] = useState(null);
  const searchRef = useRef();

  const search = async () => {
    if (!tenantId && !userId) {
      setConnections([]);
      return;
    }
    setIsLoading(true);
    try {
      validateInputs();
      const { data } = await api.admin.xero.getConnections({ tenantId, userId });
      setConnections(data);
    } catch (error) {
      setConnections([]);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validateInputs = () => {
    if (tenantId && !validate(tenantId)) {
      throw new Error('Tenant ID is not a valid UUID');
    }
    if (userId && !validate(userId)) {
      throw new Error('User ID is not a valid UUID');
    }
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  const handleTenantIdChange = (event) => {
    setErrorMessage('');
    setTenantId(event.target.value);
  };

  const handleUserIdChange = (event) => {
    setErrorMessage('');
    setUserId(event.target.value);
  };

  const handleClearTenantId = () => {
    setErrorMessage('');
    setTenantId('');
  };
  const handleClearUserId = () => {
    setErrorMessage('');
    setUserId('');
  };

  const handleModalClose = async () => {
    setConnection(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <SearchArea>
      <SearchFilters>
        <SearchInput>
          <TextInput
            ref={searchRef}
            value={tenantId}
            onChange={handleTenantIdChange}
            type="search"
            placeholder="Tenant ID"
          />
          {!!tenantId && (
            <ClearButton isAnchor={true} onClick={handleClearTenantId}>
              <Icon icon="times" />
            </ClearButton>
          )}
        </SearchInput>
        <SearchInput>
          <TextInput value={userId} onChange={handleUserIdChange} type="search" placeholder="User ID" />
          {!!userId && (
            <ClearButton isAnchor={true} onClick={handleClearUserId}>
              <Icon icon="times" />
            </ClearButton>
          )}
        </SearchInput>
        <Button onClick={search}>Search</Button>
      </SearchFilters>
      {isLoading && <ReportLoader />}
      {errorMessage && <FormMessage.Error style={{ marginTop: '1rem' }}>{errorMessage}</FormMessage.Error>}
      {!!connections.length && (
        <SearchResults>
          {connections.map((connection) => (
            <SearchResult key={connection.tenantId}>
              <Result>
                <TitleInfo>
                  <Title>{connection.tenantName}</Title>
                </TitleInfo>
                <Items>
                  <StatInfo align="center">
                    <StatTitle>{connection.tenantId}</StatTitle>
                    <StatItems>
                      <Subtitle>Tenant ID</Subtitle>
                      <Action onClick={() => copyToClipboard(connection.tenantId)}>
                        <Tooltip message={`Copy Tenant ID to Clipboard`}>
                          <Icon icon="clipboard" color={colors.primary} />
                        </Tooltip>
                      </Action>
                    </StatItems>
                  </StatInfo>
                  <StatInfo width="7rem" align="center">
                    <StatTitle>{moment(connection.createdDateUtc).format(dateFormats.compactDate)}</StatTitle>
                    <Subtitle>{`connected ${moment(connection.createdDateUtc).fromNow(true)} ago`}</Subtitle>
                  </StatInfo>
                  <Action>
                    <DeleteButton onClick={() => setConnection(connection)}>Disconnect</DeleteButton>
                  </Action>
                </Items>
              </Result>
            </SearchResult>
          ))}
          {connection && <RemoveConnectionModal connection={connection} onChange={search} onClose={handleModalClose} />}
        </SearchResults>
      )}
    </SearchArea>
  );
}
