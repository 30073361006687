import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del } from './agent';

const availabilityPeriods =
  (membersUrl) =>
  (id = '') => {
    const url = _([membersUrl, 'availability-periods', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
    };
  };

export default availabilityPeriods;
