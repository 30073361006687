import { AutoCompleteMulti, BillableIcon, Currency } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

function AssignProjectRoleMultiSelect({ value, name, project, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).roles().get({
        q,
        isActive: true,
        size: 1000,
      });

      return data;
    },
    [workspace.id, project.id, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(value, { id: selectedValue.id })) {
      handleChange([...value, { id: selectedValue.id, projectRole: selectedValue }]);
    }
  };

  const handleRemove = (item) => {
    handleChange(value.filter(({ id }) => id !== item.id));
  };

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.id} {...props}>
          <BillableIcon value={option.projectRole.isActuallyBillable} />
          {option.projectRole.name}

          {option.projectRole.isActuallyBillable && option.projectRole.rate > 0 && (
            <>
              &nbsp;(
              <Currency value={option.projectRole.rate} currency={project.currency} /> per hour)
            </>
          )}
        </AutoCompleteMulti.Tag>
      )}
      value={value}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}
    />
  );
}

export default AssignProjectRoleMultiSelect;
