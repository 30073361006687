import { useState, useMemo } from 'react';
import _ from 'lodash';
import { useIsMounted } from '~/hooks';

function useActions(actions, initialState) {
  const isMounted = useIsMounted();
  const [state, setState] = useState(initialState);
  actions = useMemo(
    () =>
      _.mapValues(actions, (value) => (args) => {
        if (!isMounted.current) return;
        setState((state) => ({ ...state, ...value(args, state, actions) }));
      }),
    [actions, isMounted],
  );
  return [state, actions];
}

export default useActions;
