const settings = {
  defaultProjectDrawerTabIndex: 0,
  drawerAnimationDuration:
    import.meta.env.VITE_DRAWER_ANIMATION_DURATION != null
      ? Number(import.meta.env.VITE_DRAWER_ANIMATION_DURATION)
      : 250,
  refreshApprovalCountSeconds: import.meta.env.VITE_REFRESH_APPROVAL_COUNT_SECONDS
    ? Number(import.meta.env.VITE_REFRESH_APPROVAL_COUNT_SECONDS)
    : null,
  isDev: import.meta.env.NODE_ENV === 'development',
  apiProxy: import.meta.env.VITE_PROXY,
  useSentry: import.meta.env.VITE_USE_SENTRY === 'false' ? false : true,
  stripePubKey: import.meta.env.VITE_STRIPE_PUB_KEY,
};

export default settings;
