import { useMemo } from 'react';
import useLocale from './useLocale';

/**
 *
 * @param {{ locale, minimumFractionDigits, maximumFractionDigits }} options
 * @returns A localized number formatter.
 */
export default function useNumberFormat({ locale, ...options } = {}) {
  locale = useLocale(locale);

  return useMemo(() => Intl.NumberFormat(locale, options), [locale, options]);
}
