import _ from 'lodash';
import { del, patch, post } from './agent';

const projectMemberRoles =
  (projectMemberRolesUrl) =>
  (id = '') => {
    const url = _([projectMemberRolesUrl, id]).compact().join('/');

    return {
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
    };
  };

export default projectMemberRoles;
