import styled from 'styled-components';

const SidebarLayout = styled.div`
  display: flex;
  margin-top: 2.5rem;
`;

SidebarLayout.Aside = styled.aside`
  margin-right: 1.5rem;
`;

SidebarLayout.Main = styled.main`
  flex: 1;
`;

export default SidebarLayout;
