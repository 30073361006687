import { useApi, useWorkspace } from '~/contexts';
import React, { useEffect, useState } from 'react';
import Button from './Button';
import { colors } from '~/styles';
import styled from 'styled-components';
import InlineTooltip from './InlineTooltip';

const Item = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${colors.black};
  text-align: left;
  position: relative;

  &:hover {
    background-color: ${colors.grey5};
    color: ${colors.black};
  }

  &:disabled {
    cursor: not-allowed;

    &&&:hover {
      background: none;
      font-weight: normal;
      color: ${colors.black};
    }
  }
`;

function DropdownDeleteItem({ children, tooltip, onCheckDependencies, ...props }) {
  const [disabled, setDisabled] = useState(true);
  const { workspace } = useWorkspace();
  const api = useApi();

  useEffect(() => {
    if (!onCheckDependencies) {
      setDisabled(false);
      return;
    }

    (async () => {
      const hasDependencies = await onCheckDependencies(api.www.workspaces(workspace.id));
      setDisabled(hasDependencies);
    })();
  }, [onCheckDependencies, workspace.id, api]);

  const isDisabled = props.disabled || disabled;

  return (
    <Item {...props} isAnchor disabled={isDisabled}>
      {children}
      {isDisabled && tooltip && <InlineTooltip message={tooltip} />}
    </Item>
  );
}

export default DropdownDeleteItem;
