import { useAuth, useDocumentTitle } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

export default function ForecastReportsPage() {
  useDocumentTitle('Forecast Reports');

  const { url } = useRouteMatch();
  const auth = useAuth();

  return (
    <LandingPage>
      {auth.reports.hoursForecast && (
        <NavBox to={url.concat('/hours-forecast-by-project-and-resource')}>
          <NavBox.Title>Hours Forecast by Project and Resource</NavBox.Title>
          <NavBox.Description>A summary of allocated hours grouped by project and resource.</NavBox.Description>
        </NavBox>
      )}

      {auth.reports.hoursForecast && (
        <NavBox to={url.concat('/hours-forecast-by-resource-and-project')}>
          <NavBox.Title>Hours Forecast by Resource and Project</NavBox.Title>
          <NavBox.Description>A summary of allocated hours grouped by resource and project.</NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceForecastByProject && (
        <NavBox to={url.concat('/performance-forecast-by-project')}>
          <NavBox.Title>Performance Forecast by Project</NavBox.Title>
          <NavBox.Description>
            A performance forecast by project. The forecast is derived from resource allocations, billing schedule
            milestones, manual revenue recognition ledger entries, expense items, and other items to bill.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.revenueForecast && (
        <NavBox to={url.concat('/services-revenue-forecast-by-client-and-project')}>
          <NavBox.Title>Services Revenue Forecast by Client and Project</NavBox.Title>
          <NavBox.Description>
            A forecast of services revenue grouped by client and project. The forecast is derived from resource
            allocations, billing schedule milestones, and manual revenue recognition ledger entries.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.utilizationForecastByMember && (
        <NavBox to={url.concat('/utilization-forecast-by-member')}>
          <NavBox.Title>Utilization Forecast by Member</NavBox.Title>
          <NavBox.Description>
            A forecast of utilization based on allocated hours and grouped by member.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.utilizationForecastByMemberPractice && (
        <NavBox to={url.concat('/utilization-forecast-by-member-practice')}>
          <NavBox.Title>Utilization Forecast by Member Practice</NavBox.Title>
          <NavBox.Description>
            A forecast of utilization based on allocated hours by member practice.
          </NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}
