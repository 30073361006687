import React from 'react';
import GenericButton from './Button';
import InlineTooltip from './InlineTooltip';

function TooltipButton({ tooltip, tooltipPlacement, component = GenericButton, children, ...props }) {
  const Component = component;

  return (
    <Component {...props} style={{ position: 'relative' }}>
      {children}
      {tooltip && <InlineTooltip message={tooltip} placement={tooltipPlacement} />}
    </Component>
  );
}

export default TooltipButton;
