import {
  AllocationBillableTypeFilter,
  Checkbox,
  ClientFilter,
  ClientTagFilter,
  DisciplineFilter,
  EmploymentTypeFilter,
  IndustryFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberFilter,
  MemberTagFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  SkillFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import React, { useEffect, useState } from 'react';
import FiltersGroup from '~/routes/app/reports/components/FiltersGroup';
import Filter from '../../components/Filter';
import FiltersDrawer from '../../components/FiltersDrawer';
import ResourceStatusFilter from '../../member-schedule/filters/ResourceStatusFilter';
import ResourceTypeFilter from '../../member-schedule/filters/ResourceTypeFilter';
import RecordStatusFilter from '../filters/RecordStatusFilter';

export default function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.onlyAllocatedProjects}
          label="Only show projects with allocations"
          onChange={({ target: { checked } }) => handleFilter({ onlyAllocatedProjects: checked })}
        />
      </Filter>

      <FiltersGroup
        label="Client Filters"
        filters={[
          filters.client,
          filters.clientOwner,
          filters.clientTags,
          filters.clientLocations,
          filters.clientIndustries,
        ]}>
        <Filter>
          <ClientFilter
            value={filters.client}
            onChange={({ target: { value } }) => handleFilter({ client: value, project: [] })}
          />
        </Filter>

        <Filter>
          <MemberFilter
            value={filters.clientOwner}
            materialPlaceholder="Client Relationship Owner"
            onChange={({ target: { value } }) => handleFilter({ clientOwner: value })}
          />
        </Filter>

        <Filter>
          <ClientTagFilter
            value={filters.clientTags}
            onChange={({ target: { value } }) => handleFilter({ clientTags: value })}
          />
        </Filter>

        <Filter>
          <LocationFilter
            value={filters.clientLocations}
            materialPlaceholder="Client Location"
            onChange={({ target: { value } }) => handleFilter({ clientLocations: value })}
          />
        </Filter>

        <Filter>
          <IndustryFilter
            value={filters.clientIndustries}
            materialPlaceholder="Client Industry"
            onChange={({ target: { value } }) => handleFilter({ clientIndustries: value })}
          />
        </Filter>
      </FiltersGroup>

      <FiltersGroup
        label="Project Filters"
        filters={[
          filters.project,
          filters.projectAdmin,
          filters.billingType,
          filters.status,
          filters.recordStatusId,
          filters.projectTags,
          filters.projectPractice,
          filters.projectTypes,
        ]}>
        <Filter>
          <ProjectFilter
            clients={filters.client}
            value={filters.project}
            onChange={({ target: { value } }) => handleFilter({ project: value })}
          />
        </Filter>

        <Filter>
          <MemberFilter
            value={filters.projectAdmin}
            materialPlaceholder="Project Admin"
            onChange={({ target: { value } }) => handleFilter({ projectAdmin: value })}
          />
        </Filter>

        <Filter>
          <ProjectBillingTypeFilter
            value={filters.billingType}
            onChange={({ target: { value } }) => handleFilter({ billingType: value })}
          />
        </Filter>

        <Filter>
          <ProjectStatusFilter
            value={filters.status}
            onChange={({ target: { value } }) => handleFilter({ status: value })}
          />
        </Filter>

        <Filter>
          <RecordStatusFilter
            value={filters.recordStatusId}
            onChange={({ target: { value } }) => handleFilter({ recordStatusId: value })}
          />
        </Filter>

        <Filter>
          <ProjectTagFilter
            value={filters.projectTags}
            onChange={({ target: { value } }) => handleFilter({ projectTags: value })}
          />
        </Filter>

        {features.practices && (
          <Filter>
            <PracticeFilter
              materialPlaceholder="Project Practice"
              value={filters.projectPractice}
              onChange={({ target: { value } }) => handleFilter({ projectPractice: value })}
            />
          </Filter>
        )}

        <Filter>
          <ProjectTypeFilter
            value={filters.projectTypes}
            onChange={({ target: { value } }) => handleFilter({ projectTypes: value })}
          />
        </Filter>
      </FiltersGroup>

      <FiltersGroup
        label="Member Filters"
        filters={[
          filters.member,
          filters.memberEmploymentType,
          filters.memberBillableTypeId,
          filters.jobTitles,
          filters.memberTags,
          filters.memberLocations,
        ]}>
        <Filter>
          <MemberFilter
            value={filters.member}
            materialPlaceholder="Member"
            onChange={({ target: { value } }) => handleFilter({ member: value })}
          />
        </Filter>

        <Filter>
          <EmploymentTypeFilter
            value={filters.memberEmploymentType}
            onChange={({ target: { value } }) => handleFilter({ memberEmploymentType: value })}
          />
        </Filter>

        <Filter>
          <MemberBillableTypeFilter
            value={filters.memberBillableTypeId}
            onChange={({ target: { value } }) => handleFilter({ memberBillableTypeId: value })}
          />
        </Filter>

        <Filter>
          <JobTitleFilter
            value={filters.jobTitles}
            onChange={({ target: { value } }) => handleFilter({ jobTitles: value })}
          />
        </Filter>

        <Filter>
          <MemberTagFilter
            value={filters.memberTags}
            onChange={({ target: { value } }) => handleFilter({ memberTags: value })}
          />
        </Filter>

        <Filter>
          <LocationFilter
            value={filters.memberLocations}
            materialPlaceholder="Member Location"
            onChange={({ target: { value } }) => handleFilter({ memberLocations: value })}
          />
        </Filter>
      </FiltersGroup>

      <FiltersGroup
        label="Resource Filters"
        filters={[
          filters.resourcePractice,
          filters.resourceDiscipline,
          filters.resourceSkill,
          filters.resourceLocation,
          filters.resourceStatusId,
          filters.resourceTypeId,
        ]}>
        {features.practices && (
          <Filter>
            <PracticeFilter
              materialPlaceholder="Resource Practice"
              value={filters.resourcePractice}
              onChange={({ target: { value } }) => handleFilter({ resourcePractice: value })}
            />
          </Filter>
        )}

        {features.disciplines && (
          <Filter>
            <DisciplineFilter
              value={filters.resourceDiscipline}
              materialPlaceholder="Resource Discipline"
              onChange={({ target: { value } }) => handleFilter({ resourceDiscipline: value })}
            />
          </Filter>
        )}

        <Filter>
          <LocationFilter
            value={filters.resourceLocation}
            materialPlaceholder="Resource Location"
            onChange={({ target: { value } }) => handleFilter({ resourceLocation: value })}
          />
        </Filter>

        <Filter>
          <SkillFilter
            value={filters.resourceSkill}
            materialPlaceholder="Resource Skills"
            onChange={({ target: { value } }) => handleFilter({ resourceSkill: value })}
          />
        </Filter>

        <Filter>
          <ResourceStatusFilter
            value={filters.resourceStatusId}
            onChange={({ target: { value } }) => handleFilter({ resourceStatusId: value })}
          />
        </Filter>

        <Filter>
          <ResourceTypeFilter
            value={filters.resourceTypeId}
            onChange={({ target: { value } }) => handleFilter({ resourceTypeId: value })}
          />
        </Filter>
      </FiltersGroup>

      <FiltersGroup label="Allocation Filters" filters={[filters.allocationBillableTypes]}>
        <Filter>
          <AllocationBillableTypeFilter
            value={filters.allocationBillableTypes}
            onChange={({ target: { value } }) => handleFilter({ allocationBillableTypes: value })}
          />
        </Filter>
      </FiltersGroup>
    </FiltersDrawer>
  );
}
