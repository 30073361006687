import { Button, Icon } from '~/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const MoreButton = styled(Button)`
  margin-top: 0.625rem;
  background-color: ${colors.grey5};
  color: ${colors.primary};
  font-size: 0.75rem;

  .icon {
    font-size: 0.5rem;
    margin-left: 0.5rem;
  }

  &:active {
    background-color: ${colors.grey5};
    color: ${colors.primary};
  }
`;

const Text = styled.div`
  text-align: ${({ align }) => align};
  font-size: ${({ fontSize }) => fontSize};
`;

function MultilineText({ value, chars, align = 'justify', fontSize, buttonProps, ...props }) {
  const [expanded, setExpanded] = useState(false);

  if (!value) return null;

  const text = (expanded || !chars || value.length <= chars ? value : value.slice(0, chars).concat('...'))
    .split('\n')
    .map((line, index) => (line ? <p key={index}>{line}</p> : <br key={index} />));

  return (
    <div {...props}>
      <Text align={align} fontSize={fontSize}>
        {text}
      </Text>
      {value.length > chars &&
        (expanded ? (
          <MoreButton {...buttonProps} onClick={() => setExpanded(false)}>
            Less <Icon icon="chevron-up" />
          </MoreButton>
        ) : (
          <MoreButton {...buttonProps} onClick={() => setExpanded(true)}>
            More <Icon icon="chevron-down" />
          </MoreButton>
        ))}
    </div>
  );
}

export default MultilineText;
