import { rgba } from 'polished';
import { colors } from '~/styles';

const theme = {
  canvas: {
    background: colors.white,
  },

  sidebar: {
    background: colors.white,

    total: {
      background: colors.grey5,
      color: colors.grey75,
    },
  },

  line: {
    soft: colors.grey5,
    strong: colors.grey10,
  },

  cell: {
    selected: {
      background: rgba(colors.grey25, 0.6),
    },

    hover: {
      background: rgba(colors.grey5, 0.5),
    },

    empty: {
      background: rgba(colors.white, 0.1),
      color: colors.grey10,
    },

    filled: {
      background: rgba(colors.grey10, 0.1),
      color: colors.grey55,
    },

    total: {
      background: rgba(colors.grey10, 0.5),
      color: colors.grey75,
    },

    heatmap: {
      danger: {
        light: colors.danger5,
        dark: colors.danger,
      },

      success: {
        light: colors.success10,
        dark: colors.success,
      },
    },
  },

  today: {
    color: colors.primary,
    background: colors.primary5,
  },
};

export default theme;
