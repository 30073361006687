import { Button } from '~/components';
import { useConfirmation, useTimeEntries, useWorkspace } from '~/contexts';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import ResubmitRejectedTimeConfirmation from './ResubmitRejectedTimeConfirmation';
import { useTimesheets } from './TimesheetContext';

export default function ResubmitRejectedTimeButton({ date, period = 'day', member, onSubmit }) {
  const { workspace } = useWorkspace();
  const { entries } = useTimeEntries();
  const timesheetContext = useTimesheets();

  const memberId = useMemo(() => (member ? member.id : workspace.member.id), [member, workspace]);

  const { start, end } = useMemo(() => {
    switch (period) {
      case 'day':
        return { start: date, end: date };

      case 'week':
        return { start: moment(date).startOf('isoWeek'), end: moment(date).endOf('isoWeek') };
    }
  }, [date, period]);

  const rejectedTimeEntries = useMemo(
    () =>
      _.filter(
        entries,
        (entry) =>
          !!entry.timesheetId &&
          entry.status?.id === 'rejected' &&
          entry.memberId === memberId &&
          moment(entry.date).isBetween(start, end, 'day', '[]'),
      ),
    [entries, start, end, memberId],
  );

  const confirmation = useConfirmation();

  const handleSubmitted = () => {
    onSubmit();
    timesheetContext.fetchTimesheets();
  };

  const handleSubmit = async () => {
    await confirmation.prompt((resolve) => (
      <ResubmitRejectedTimeConfirmation
        timeEntries={rejectedTimeEntries}
        member={member}
        onSubmit={handleSubmitted}
        resolve={resolve}
        timesheetContext={timesheetContext}
      />
    ));
  };

  if (rejectedTimeEntries.length === 0) return null;

  return <Button onClick={handleSubmit}>Resubmit Rejected Time</Button>;
}
