import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApprovalStatusFilter,
  BackLink,
  Checkbox,
  ClientApprovalStatusFilter,
  Currency,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  Icon,
  InvoicedFilter,
  Level,
  Percent,
  RouteLink,
  Stack,
  Table,
  Widget,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExpenseCategoryTag from '../components/ExpenseCategoryTag';
import ExpenseReimbursableTypeTag from '../components/ExpenseReimbursableTypeTag';
import ExpenseStatusTag from '../components/ExpenseStatusTag';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import InvoicedTag from '../components/InvoicedTag';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import ReimbursedTag from '../components/ReimbursedTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

function ExpenseSummaryByMember() {
  useDocumentTitle('Expense Summary by Member');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    memberActive: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    expenseCategories: [],
    includeMembersWithoutExpenses: false,
    isReimbursed: null,
    reimbursableType: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        memberActive: reportsSearchParamsConfig.memberActivePeriod,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        includeMembersWithoutExpenses: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        isReimbursed: searchParamsConfig.isReimbursed,
        reimbursableType: searchParamsConfig.expenseReimbursableType,
        expenseCategories: searchParamsConfig.expenseCategories,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      memberActiveStart: params.memberActive?.start ?? undefined,
      memberActiveEnd: params.memberActive?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      expenseCategoryId: params.expenseCategories?.map((v) => v.id),
      includeMembersWithoutExpenses: params.includeMembersWithoutExpenses ? 'true' : undefined,
      isReimbursed: params.isReimbursed ?? undefined,
      reimbursableTypeId: params.reimbursableType ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().expenseSummaryByMember(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        summary: false,
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .expenseSummaryByMember(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/expenses`} />

        <Stack>
          <h1>Expense Summary by Member</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            <ExpenseStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />

            <ExpenseCategoryTag
              value={params.expenseCategories}
              onRemove={() => handleApplyFilters({ expenseCategories: [] })}
            />

            <ReimbursedTag value={params.isReimbursed} onRemove={() => handleApplyFilters({ isReimbursed: null })} />

            <ExpenseReimbursableTypeTag
              value={params.reimbursableType}
              onRemove={() => handleApplyFilters({ reimbursableType: null })}
            />

            {params.includeMembersWithoutExpenses && <Tag>Include members without expenses</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const expenseDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/expenses/expense-items?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          projectRole: params.projectRoles?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          expenseCategory: params.expenseCategories?.map((v) => v.id),
          isReimbursed: params.isReimbursed ?? undefined,
          reimbursableType: params.reimbursableType ?? undefined,
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            <Meter.Value
              label="Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Currency value={report.totals.billable} currency={report.currency} /> (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Currency value={report.totals.nonBillable} currency={report.currency} /> (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <RouteLink to={expenseDetail({ billableType: 'billable', expenseType: 'member' })}>
                <Metric.Value>
                  <Currency value={report.totals.billable} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={expenseDetail({ billableType: 'non_billable', expenseType: 'member' })}>
                <Metric.Value>
                  <Currency value={report.totals.nonBillable} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Non-Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={expenseDetail({ expenseType: 'member' })}>
                <Metric.Value>
                  <Currency value={report.totals.total} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Level>
          {features.multicurrency && (
            <Level.Item>
              <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
            </Level.Item>
          )}

          <Level.Item right>
            <TotalResults>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total label="Member" value={report.records.length} />
              {report.partialResults && <PartialResultsTooltip />}
            </TotalResults>
          </Level.Item>
        </Level>

        <Table>
          <Table.Header sticky>
            <Table.Column name="name" onSort={onSort} sort={params.sort}>
              Member
            </Table.Column>
            <Table.Column name="billable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Billable
            </Table.Column>
            <Table.Column name="nonBillable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Non-Billable
            </Table.Column>
            <Table.Column name="total" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Total
            </Table.Column>
          </Table.Header>

          <TableBody fade={query.loading.table}>
            {report.records.map((member) => {
              return (
                <Table.Row key={member.id}>
                  <Table.Cell>{member.name}</Table.Cell>
                  <Table.Cell>
                    <RouteLink to={expenseDetail({ billableType: 'billable', member: member.id })}>
                      <Currency value={member.billable} currency={report.currency} />
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={expenseDetail({ billableType: 'non_billable', member: member.id })}>
                      <Currency value={member.nonBillable} currency={report.currency} />
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={expenseDetail({ member: member.id })}>
                      <Currency value={member.total} currency={report.currency} />
                    </RouteLink>
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <RouteLink to={expenseDetail({ billableType: 'billable', expenseType: 'member' })}>
                  <Currency value={report.totals.billable} currency={report.currency} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={expenseDetail({ billableType: 'non_billable', expenseType: 'member' })}>
                  <Currency value={report.totals.nonBillable} currency={report.currency} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={expenseDetail({ expenseType: 'member' })}>
                  <Currency value={report.totals.total} currency={report.currency} />
                </RouteLink>
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeMembersWithoutExpenses}
          label="Include members without tracked expenses"
          onChange={({ target: { checked } }) => handleFilter({ includeMembersWithoutExpenses: checked })}
        />
      </Filter>

      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      {auth.expenses.view && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <FiltersGroup
        label="Expense Filters"
        filters={[
          filters.approvalStatuses,
          filters.clientApprovalStatuses,
          filters.invoiced,
          filters.expenseCategories,
          filters.reimbursableType,
          filters.isReimbursed,
        ]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>
        <Filter>
          <ExpenseCategoryFilter
            value={filters.expenseCategories}
            onChange={({ target: { value } }) => handleFilter({ expenseCategories: value })}
          />
        </Filter>

        <Filter>
          <ExpenseReimbursableTypeFilter
            name="reimbursableType"
            value={filters.reimbursableType}
            onChange={({ target: { value } }) => handleFilter({ reimbursableType: value })}
          />
        </Filter>

        <Filter>
          <ReimbursedFilter
            name="isReimbursed"
            value={filters.isReimbursed}
            onChange={({ target: { value } }) => handleFilter({ isReimbursed: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}

export default ExpenseSummaryByMember;
