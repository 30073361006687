import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BackArrowLink, Icon, Page } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import SalesforceSettings from './SalesforceSettings'; // Replace with your Salesforce settings page component import
import icon from '~/assets/public/salesforce-logo.png';

const Header = styled.div`
  padding: 0rem 0rem 2rem 0rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  margin-bottom: 0.75rem;
  font-size: 3rem;
  font-weight: 300;
`;

const Byline = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const SalesforceLogo = styled.img`
  display: block;
  height: 3.875rem;
  margin-left: 2rem;
`;

export default function SalesforceSettingsArea() {
  useDocumentTitle('Salesforce Integration Settings');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [integration, setIntegration] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const isConnected = useMemo(() => Boolean(integration && integration.isConnected), [integration]);
  const fetchIntegration = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).salesforce.get();
      setIntegration(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  useEffect(() => {
    fetchIntegration();
    return fetchIntegration.cancel;
  }, [fetchIntegration]);

  return (
    <>
      <Header>
        <Page.Header>
          <BackArrowLink to={`/app/${workspace.key}/settings/integrations`} />
          <InfoContainer>
            <Info>
              <Byline>
                <strong>Integration Settings</strong>
              </Byline>
              <Title>Salesforce</Title>
            </Info>
            <SalesforceLogo src={icon} />
          </InfoContainer>
        </Page.Header>
        {isLoading ? (
          <PageLoader />
        ) : isConnected ? (
          <SalesforceSettings integration={integration} reloadIntegration={fetchIntegration} />
        ) : (
          <ErrorMessage />
        )}
      </Header>
      <SalesforceSettings />
    </>
  );
}

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

const ErrorIcon = styled(Icon)`
  color: ${colors.error};
  font-size: 2rem;
  margin-right: 1rem;
`;

const ErrorText = styled.p`
  font-size: 1.25rem;
`;

function ErrorMessage() {
  return (
    <ErrorContainer>
      <ErrorIcon icon="exclamation-triangle" />
      <ErrorText>There was a problem getting your information or the Salesforce integration is not enabled.</ErrorText>
    </ErrorContainer>
  );
}
