import { Button, DeleteConfirmation, Dropdown, ExportDialog, Level, SearchInput, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect, CustomDataHeader } from '../custom-data/components';
import ExportDropdown from '../ExportDropdown';
import PracticeDrawer from './PracticeDrawer';

function PracticesPage() {
  const documentTitle = useDocumentTitle('Practices');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).practices().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .practices()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (practice) => {
    setDialog(
      <PracticeDrawer
        practice={practice}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Practices');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).practices(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(practice) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Practice?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .practices(practice ? practice.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Practices</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Larger professional services companies often organize service offerings and personnel into practices. You
            can assign a practice to each member, client, and project in Ruddr. Reports can then be filtered by
            practice.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Button onClick={() => handleEdit({})}>New Practice</Button>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Name"
            materialAlwaysVisible
            onChange={handleFilterChange}
          />
        </Level.Item>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </Level.Item>
        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('practices.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('practices.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Practice" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column>Name</Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((practice) => {
            const { name, isActive, id } = practice;

            return (
              <Table.BoxRow onClick={() => handleEdit(practice)} key={id} isDisabled={!isActive}>
                <Table.Cell>{name}</Table.Cell>
                <TableBoxRowActions>
                  <>
                    <TableBoxRowActions.Edit onClick={() => handleEdit(practice)} />

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => (
                        <>
                          <Dropdown.Item onClick={() => handleEdit(practice)}>Edit</Dropdown.Item>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleActiveStatusChange(practice, !isActive);
                              setIsOpen(false);
                            }}>
                            {isActive ? 'Deactivate' : 'Activate'}
                          </Dropdown.Item>
                          <Dropdown.DeleteItem
                            tooltip="This item is currently in use."
                            onCheckDependencies={async (workspace) =>
                              (await workspace.practices(id).hasDependencies()).data
                            }
                            onClick={() => handleDelete(practice)}>
                            Delete
                          </Dropdown.DeleteItem>
                        </>
                      )}
                    </TableBoxRowActions.Dropdown>
                  </>
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </Table>

      {dialog}
    </>
  );
}

export default PracticesPage;
