export default function checkCSVHeaders({ file, rules }) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n').filter((line) => line);
      const headers = lines[0].split(',').map((header, index, array) => {
        // Check if the header is the last one in the array and contains a carriage return
        if (index === array.length - 1 && header.endsWith('\r')) {
          return header.slice(0, -1); // Remove the carriage return
        }
        return header; // Return the header as-is if no carriage return
      });

      let headerAnalysis = {};
      let summary = {
        totalHeaders: headers.length,
        missingRequired: [],
        closeMatches: [],
        noMatches: [],
        suggestions: [],
        passesCheck: true,
      };

      headers.forEach((header) => {
        if (Object.prototype.hasOwnProperty.call(rules, header)) {
          headerAnalysis[header] = 'match';
        } else if (isCloseMatch(header, Object.keys(rules))) {
          headerAnalysis[header] = 'close';
          summary.closeMatches.push(header);
          summary.passesCheck = false;
        } else {
          headerAnalysis[header] = 'no_match';
          summary.noMatches.push(header);
          summary.passesCheck = false;
        }
      });

      Object.keys(rules).forEach((ruleHeader) => {
        if (!headers.includes(ruleHeader) && rules[ruleHeader].requirementType === 'required') {
          headerAnalysis[ruleHeader] = 'missing_required';
          summary.missingRequired.push(ruleHeader);
          summary.passesCheck = false;
        }
      });

      if (summary.missingRequired.length > 0) {
        summary.suggestions.push(`Add missing required headers: ${summary.missingRequired.join(', ')}`);
      }
      if (summary.closeMatches.length > 0) {
        summary.suggestions.push(
          `Check for typos, extra spaces, or formatting issues in headers: ${summary.closeMatches.join(', ')}`,
        );
      }
      if (summary.noMatches.length > 0) {
        summary.suggestions.push(`Remove or rename unnecessary headers: ${summary.noMatches.join(', ')}`);
      }

      resolve({
        type: 'analysis',
        headerAnalysis,
        summary,
      });
    };

    reader.onerror = () => {
      reject({
        type: 'error',
        messages: ['There was an error reading the file. Please try again.'],
      });
    };

    reader.readAsText(file);
  });
}
function isCloseMatch(header, headersList) {
  const result = headersList.some((h) => h.trim().toLowerCase().includes(header.trim().toLowerCase()));
  return result;
}
