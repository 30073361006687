import { Field, HelpTooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';
import BudgetAlertThresholdSelect from '../BudgetAlertThresholdSelect';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function BudgetHoursAlertSetting({ project }) {
  return (
    <Control>
      <Field.Checkbox name="budgetHoursAlert" label="Notify at" />

      <BudgetAlertThresholdSelect name="budgetHoursAlertThreshold" disabled={!project.budgetHoursAlert} />

      <span>of the hours budget</span>

      <HelpTooltip
        message={
          <>
            A notification will be sent to the Project Admin and Client Relationship Owner when the project reaches the
            stated percentage and again when the project reaches 100% of the budget.
          </>
        }
        style={{ marginLeft: '.5rem' }}
      />
    </Control>
  );
}
