import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors } from '~/styles';

// Some properties are only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledIcon = ({ sidebarExpanded, ...props }) => <Icon {...props} />;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 100%;
  background-color: ${colors.grey5};
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey10};
  }
`;

const ArrowIcon = styled(StyledIcon)`
  color: ${colors.primary};
  font-size: 0.6875rem;
  transform: rotate(${({ sidebarExpanded }) => (sidebarExpanded ? '0deg' : '180deg')});
`;

export default function SidebarExpander({ sidebarExpanded, setSidebarExpanded }) {
  return (
    <Container
      id="sidebar-expander"
      data-expanded={sidebarExpanded}
      onClick={() => setSidebarExpanded((expanded) => !expanded)}>
      <ArrowIcon icon="angle-left" sidebarExpanded={sidebarExpanded} />
    </Container>
  );
}
