import { useFeatures } from '~/hooks';
import _ from 'lodash';
import { projectHealthRatings } from '~/lookups';
import React from 'react';
import Tag from './Tag';

export default function ProjectTeamSatisfactionTag({ value, ...props }) {
  const features = useFeatures();

  if (_.isEmpty(value) || !features.projectHealth) return null;

  return (
    <Tag label="Team Satisfaction" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? projectHealthRatings[v].name)
        .join('; ')}
    </Tag>
  );
}
