import { AutoComplete, AutoCompleteMulti, Dropdown } from '~/components';
import Fuse from 'fuse.js';
import { useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  color: ${colors.grey100};
`;

export default function ApproverMultiSelect({ value, options, ...props }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      let results = options;

      if (q) {
        const fuse = new Fuse(options, { keys: ['approvalRole.name', 'member.name'], threshold: 0.4 });
        results = fuse.search(q).map((result) => result.item);
      }

      return [
        { type: 'group', key: 'role', label: 'Roles', disabled: true },
        ...results.filter((r) => r.type === 'role'),

        { type: 'group', key: 'member', label: 'Members', disabled: true },
        ...results.filter((r) => r.type === 'member'),
      ];
    },
    [options],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) =>
    options.filter(
      (o) =>
        !value.some((v) => {
          switch (o.type) {
            case 'role':
              return v.approvalRoleKey === o.approvalRoleKey;

            case 'member':
              return v.memberId === o.memberId;
          }
        }),
    );

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      value={value}
      defaultOptions={filterOptions(defaultOptions)}
      renderTag={(option, props) => {
        switch (option.type) {
          case 'role':
            return (
              <AutoCompleteMulti.Tag
                key={option.approvalRoleKey}
                style={{ backgroundColor: colors.primary10, fontSize: '.75rem' }}
                {...props}>
                {option.approvalRole.name}
              </AutoCompleteMulti.Tag>
            );

          case 'member':
            return (
              <AutoCompleteMulti.Tag
                key={option.memberId}
                style={{ backgroundColor: colors.grey5, fontSize: '.75rem' }}
                {...props}>
                {option.member.name}
              </AutoCompleteMulti.Tag>
            );
        }
      }}
      compare={(a, b) => {
        if (a.type !== b.type) return false;

        switch (a.type) {
          case 'role':
            return a.approvalRoleKey === b.approvalRoleKey;

          case 'member':
            return a.memberId === b.memberId;
        }
      }}
      onSearch={handleFilteredSearch}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.some((o) => !o.disabled) ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                if (!options.some((opt) => opt.type === o.key)) return null;

                return (
                  <Group key={o.label}>
                    <strong>{o.label}</strong>
                  </Group>
                );

              case 'role':
                return (
                  <AutoComplete.Option
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.approvalRoleKey}
                    value={o}
                    index={index++}>
                    {o.approvalRole.name}
                  </AutoComplete.Option>
                );

              case 'member':
                return (
                  <AutoComplete.Option padding="0.25rem 0 0.25rem 2rem" key={o.memberId} value={o} index={index++}>
                    {o.member.name}
                  </AutoComplete.Option>
                );
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoCompleteMulti>
  );
}
