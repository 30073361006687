import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';

const clientApprovalSchedules =
  (clientApprovalSchedulesUrl) =>
  (id = '') => {
    const url = _([clientApprovalSchedulesUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      run: () => post(url.concat('/run')),
    };
  };

export default clientApprovalSchedules;
