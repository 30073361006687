// index.js
import allocations from './allocations.js';
import projectLedger from './project-ledger.js';
import projectTasks from './project-tasks.js';

const csvRules = {
  allocations,
  projectLedger,
  projectTasks,
};

const samplefiles = {
  allocations: 'allocations.csv',
  projectLedger: 'project-ledger.csv',
  projectTasks: 'project-tasks.csv',
};

export default { csvRules, samplefiles };
