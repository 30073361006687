import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FilePreview from './FilePreview';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import DeleteConfirmation from './DeleteConfirmation';
import { Field, Form, HelpTooltip } from '~/components';
import _ from 'lodash';

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

function AttachmentsTab({ invoice, onDrop, files, error, isLoading, removeFile, maxFileSize }) {
  const [fileToDelete, setFileToDelete] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: maxFileSize,
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/*': ['.bmp', '.jpg', '.jpeg', '.png', '.gif', '.tif', '.tiff'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
    },
  });

  const settings = {
    time: {
      hasTime: _.some(invoice.lines, (line) => line.transactionType === 'time'),
      get props() {
        const props = {
          disabled: !this.hasTime,
        };

        if (!this.hasTime) props.checked = false;

        return props;
      },
      get tooltip() {
        if (!this.hasTime)
          return (
            <HelpTooltip
              message="There are no time entries associated with this invoice."
              style={{ marginLeft: '0.5rem' }}
            />
          );
        return undefined;
      },
    },
    expenses: {
      hasExpenses: _.some(invoice.lines, (line) => line.transactionType === 'expense'),
      hasReceipts: _.some(invoice.lines, (line) =>
        line.expenses.length ? _.some(line.expenses, (expense) => expense.receipts.length) : false,
      ),
      get props() {
        const props = {
          disabled: !this.hasExpenses || !this.hasReceipts,
        };

        if (!this.hasExpenses || !this.hasReceipts) props.checked = false;

        return props;
      },
      get tooltip() {
        if (!this.hasExpenses) return 'There are no expenses associated with this invoice.';
        if (!this.hasReceipts) return 'There are no receipts for the expenses associated with this invoice.';
        return undefined;
      },
    },
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div>
        <Form.Control>
          <Field.Checkbox name="includePdfAttachment" label="Attach a PDF of this invoice" />
        </Form.Control>
        <Form.Control>
          <CheckboxContainer>
            <Field.Checkbox
              name="includeTimeReportPdfAttachment"
              label="Attach a PDF of the detailed time report"
              {...settings.time.props}
            />
            {settings.time.tooltip}
          </CheckboxContainer>
        </Form.Control>
        <Form.Control>
          <CheckboxContainer style={{ marginBottom: '1.625rem' }}>
            <Field.Checkbox
              name="includeReceiptsZipAttachment"
              label="Attach a ZIP file of the expense receipts"
              data-testid={'attach_receipts_checkbox'}
              {...settings.expenses.props}
            />
            {settings.expenses.tooltip && (
              <HelpTooltip
                message={settings.expenses.tooltip}
                style={{ marginLeft: '0.5rem' }}
                data-testid={'no_receipts_tooltip'}
              />
            )}
          </CheckboxContainer>
        </Form.Control>
      </div>
      <DropzoneContainer>
        <DropzoneInterior {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drop files here or click to select files</p>
        </DropzoneInterior>
      </DropzoneContainer>
      <Previews>
        {files.map((file) => (
          <FilePreview key={file.id} file={file} onRemove={setFileToDelete} />
        ))}
      </Previews>
      {fileToDelete && (
        <DeleteConfirmation
          file={fileToDelete}
          invoice={invoice}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
    </>
  );
}

export default AttachmentsTab;
