import { Field, Percent } from '~/components';
import React from 'react';

export default function BudgetAlertThresholdSelect(props) {
  return (
    <Field.SingleSelect
      materialPlaceholder={false}
      style={{ width: '7.5rem', margin: '0 0.5rem', textAlign: 'right' }}
      {...props}>
      {[0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95].map(
        (value) => (
          <option key={value} value={value}>
            <Percent value={value} minimumFractionDigits={0} maximumFractionDigits={0} />
          </option>
        ),
      )}
    </Field.SingleSelect>
  );
}
