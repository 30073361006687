import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Buttons, CancelButton, Drawer, Form, Icon, TooltipButton } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useMember, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors } from '~/styles';

export default function SamlSettings({ onClose }) {
  const { authProviderId } = useParams();
  const title = `SAML Settings`;
  useDocumentTitle(title);

  const api = useApi();
  const { workspace } = useWorkspace();
  const { member } = useMember();
  const [authProvider, setAuthProvider] = useState(null);

  useEffect(() => {
    (async function fetchData() {
      const { data } = await api.www.workspaces(workspace.id).authProviders(authProviderId).get();
      setAuthProvider(data);
    })();
  }, [api, authProviderId, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  if (!authProvider) return null;

  return (
    <Drawer isOpen title={title} byline="Authentication" onClose={handleClose}>
      {(closeDrawer) => {
        const entityId = window.location.origin;
        const acsUrl = `${window.location.origin}/api/auth-providers/${authProvider.id}/saml`;
        const loginUrl = `${window.location.origin}/login?email=${member.email}&workspace=${workspace.key}&auth=${authProvider.key}`;

        return (
          <ReadForm>
            <Form.Section title="SAML IDP Configuration" subtitle="Use these values to configure your IDP.">
              <Form.Control>
                <ReadTextbox label="Entity ID (Issuer)" value={entityId} />{' '}
                <div style={{ flex: 0 }}>
                  <TooltipButton onClick={() => navigator.clipboard.writeText(entityId)} tooltip="Copy to clipboard">
                    <Icon color={colors.white} icon="clipboard" />
                  </TooltipButton>
                </div>
              </Form.Control>

              <Form.Control style={{ alignItems: 'center' }}>
                <ReadTextbox label="Assertion Consumer Service URL" value={acsUrl} />
                <div style={{ flex: 0 }}>
                  <TooltipButton onClick={() => navigator.clipboard.writeText(acsUrl)} tooltip="Copy to clipboard">
                    <Icon color={colors.white} icon="clipboard" />
                  </TooltipButton>
                </div>
              </Form.Control>
            </Form.Section>

            <Form.Section title="Test Auth Provider" subtitle="Use this login URL to test the auth provider.">
              <a href={loginUrl} target="_blank" rel="noreferrer noopener">
                {loginUrl}
              </a>
            </Form.Section>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={() => closeDrawer()}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </ReadForm>
        );
      }}
    </Drawer>
  );
}
