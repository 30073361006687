import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, Icon, SingleSelect, Table, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

export default function ClientMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ clients, customers, isReady }, setState] = useState({ clients: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: clients } = await api.www.workspaces(workspace.id).clients().get({ isInternal: false, size: 1000 });
      const { data: customers } = await api.www.workspaces(workspace.id).qbo.getCustomers();

      if (!isMounted.current) return;
      setState({ clients, customers, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updateClientMapping = ({ clientId, qboCustomerId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      clients: clients.map((c) => (c.id === clientId ? { ...c, qboCustomerId } : c)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Client Mappings"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = clients.map((client) => ({ clientId: client.id, qboCustomerId: client.qboCustomerId }));
            await api.www.workspaces(workspace.id).qbo.updateClientMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column width="40%">Client</Table.Column>
                <Table.Column>QuickBooks Customer</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {clients.map((client) => {
                  const handleCustomerChange = (event) => {
                    updateClientMapping({
                      clientId: client.id,
                      qboCustomerId: event.target.value || null,
                    });
                  };

                  return (
                    <Table.BoxRow key={client.id}>
                      <Table.Cell>{client.name}</Table.Cell>
                      <Table.Cell>
                        <CustomerSelect
                          customers={customers}
                          value={client.qboCustomerId}
                          onChange={handleCustomerChange}
                        />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function CustomerSelect({ customers, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return customers;
    }

    const fuse = new Fuse(customers, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [customers, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const customer = _.find(customers, { id: value });
    if (!customer)
      return {
        id: value,
        name: (
          <Tooltip message="Customer not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Customer ID: {value}
          </Tooltip>
        ),
      };
    return customer;
  }, [customers, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
