import { colors } from '~/styles';
import styled from 'styled-components';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  border-radius: 0.3125rem;
  border-color: ${colors.grey10};
  background-color: ${colors.grey5};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  margin: 0.25rem;

  background-color: ${({ variant }) => colors[variant] || colors.primary};

  > .button {
    margin-left: 0.5rem;
    color: ${colors.black};

    &:hover {
      color: ${colors.grey55};
    }
  }
`;

export default Tag;
export { Tags };
