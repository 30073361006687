export default {
  white: '#fff',
  black: '#131e29',
  grey0: '#fff',
  grey5: '#f0f1f2',
  grey10: '#e3e4e6',
  grey20: '#cacdd1',
  grey25: '#bcbfc3',
  grey40: '#979ca1',
  grey55: '#747981',
  grey75: '#474e58',
  grey100: '#131e29',
  teal: '#00b2a9',
  green: '#00b140',
  yellow: '#ffb81c',
  red: '#ce0058',
  primary: '#00b2a9',
  primary0: '#fff',
  primary5: '#f2fbfb',
  primary10: '#e5f7f6',
  primary25: '#bfece9',
  primary50: '#80d8d4',
  primary100: '#00b2a9',
  accent: '#008983',
  success: '#00b140',
  success0: '#fff',
  success5: '#f2f7f2',
  success10: '#e5f7ec',
  success25: '#bfebcf',
  success50: '#80d89f',
  success100: '#00b140',
  warning: '#ffb81c',
  warning0: '#fff',
  warning10: '#fff8e8',
  warning25: '#ffedc6',
  warning50: '#ffdb8d',
  warning100: '#ffb81c',
  error: '#ce0058',
  danger: '#ce0058',
  danger0: '#fff',
  danger5: '#fdf2f7',
  danger10: '#fae6ee',
  danger25: '#f3bfd5',
  danger50: '#e680ab',
  danger100: '#ce0058',
};
