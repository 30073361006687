import { DeleteButton } from '~/components';
import React, { useState } from 'react';
import Buttons from './Buttons';
import CancelButton from './CancelButton';
import ModalCard from './ModalCard';

function DeleteConfirmation({ resolve, title = 'Confirm Delete', deleteLabel = 'Delete', children }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await resolve(true);
  };

  return (
    <ModalCard title={title} onClose={() => resolve(false)}>
      <ModalCard.Body>{children}</ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <DeleteButton isLoading={isLoading} onClick={handleSubmit}>
            {deleteLabel}
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DeleteConfirmation;
