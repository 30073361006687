import styled, { css } from 'styled-components';

const TableContents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ small }) =>
    small &&
    css`
      div.table-cell {
        min-height: 2rem;
        font-size: 0.875rem;
        input {
          font-size: 0.875rem;
        }
      }
      button {
        min-height: 1.75rem;
        height: 1.75rem;
      }
    `};

  div.table-cell {
    color: ${({ fontColor }) => {
      return fontColor;
    }};
  }
`;

export default TableContents;
