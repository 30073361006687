import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Avatar, Button, Field, Form, FormMessage, HelpTooltip, Hours, RegionalFormatExamples } from '~/components';
import { useApi, useMember, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useForm } from '~/hooks';
import { colors, devices } from '~/styles';

const FormWrapper = styled.div`
  display: flex;

  @media ${devices.touch} {
    flex-direction: column;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HelpText = styled.div`
  margin: 0.5rem;
  margin-bottom: 0;
  color: ${colors.grey75};
  font-size: 0.875rem;
`;

const FormImage = styled.div`
  margin-left: 2rem;

  @media ${devices.touch} {
    margin-left: 0;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
`;

const FormAction = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export default function MyProfile() {
  useDocumentTitle('My Profile');

  const api = useApi();
  const [formState, form] = useForm();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const { member, updateMember } = useMember();

  const [workspaceTimeZone, setWorkspaceTimeZone] = useState();

  useEffect(() => {
    (async () => {
      setWorkspaceTimeZone();
      if (workspace && workspace.timeZoneId) {
        const { data } = await api.timeZones(workspace.timeZoneId).get();
        setWorkspaceTimeZone(data);
      }
    })();
  }, [api, workspace]);

  async function handleSubmit(values) {
    try {
      form.submit();
      const { data } = await api.www
        .workspaces(workspace.id)
        .myProfile.patch({ ...values, locale: values.locale ?? '' });
      updateMember(data);
      form.done();
      toast.success('Your profile was successfully saved.');
    } catch ({ message }) {
      form.error({ message: message || 'There was a problem updating your profile.' });
      window.scrollTo({ top: 0 });
    }
  }

  if (!member) return null;

  const { email, imageUrl, locale, name, timeZoneId, useDecimalTimeEntry } = member;
  const { status, message, isSubmitting } = formState;

  return (
    <Formik
      initialValues={{
        email,
        image: false,
        locale,
        name,
        timeZoneId: timeZoneId || '',
        useDecimalTimeEntry: useDecimalTimeEntry?.toString(),
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        name: Yup.string().label('Name').max(255).required(),
      })}>
      {(formik) => (
        <Form>
          <FormWrapper>
            <FormSection>
              {status && <FormMessage.Error spaceBottom>{message}</FormMessage.Error>}
              <Form.Control>
                <div>
                  <Field.Text name="email" placeholder="Email" type="email" disabled />
                  <HelpText>
                    If you need to change this email address, please contact your workspace administrator.
                  </HelpText>
                </div>
              </Form.Control>
              <Form.Control>
                <Field.Text name="name" placeholder="Name" maxLength={255} disabled={!workspace.manageMemberProfile} />
              </Form.Control>
              <Form.Control>
                <div>
                  <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                  {workspace && (!workspace.timeZoneId || workspaceTimeZone) && (
                    <HelpText>
                      <Link to={`/app/${workspace.key}`}>{workspace.name}</Link>:{' '}
                      {workspaceTimeZone ? workspaceTimeZone.text : 'US/Eastern'}{' '}
                      <HelpTooltip message="If not set, the setting on the workspace will be used." />
                    </HelpText>
                  )}
                </div>
              </Form.Control>
              <Form.Control>
                <div>
                  <Field.SingleSelect name="useDecimalTimeEntry" placeholder="Time Format" showEmptyOption>
                    <option value="false">Clock format (1:30)</option>
                    <option value="true">
                      Decimal format <Hours value={1.5} locale={formik.values.locale} />
                    </option>
                  </Field.SingleSelect>
                  {workspace && (
                    <HelpText>
                      <Link to={`/app/${workspace.key}`}>{workspace.name}</Link>:{' '}
                      {workspace.useDecimalTimeEntry ? (
                        <>
                          Decimal format (<Hours value={1.5} locale={formik.values.locale} />)
                        </>
                      ) : (
                        'Clock format (1:30)'
                      )}{' '}
                      <HelpTooltip message="If not set, the setting on the workspace will be used." />
                    </HelpText>
                  )}
                </div>
              </Form.Control>
              <Form.Control>
                <div>
                  <Field.RegionalFormatSelect name="locale" clearable={false} />
                  <RegionalFormatExamples locale={formik.values.locale} />
                </div>
              </Form.Control>
            </FormSection>
            <FormImage>
              {workspace.manageMemberProfile ? (
                <Field.AvatarFileInput
                  name="image"
                  label="Photo"
                  preview={{ imageUrl, name: name || email }}
                  size={200}
                  variant="circle"
                />
              ) : (
                <Avatar size={200} value={member} isCircle hasBackground hasBorder />
              )}
            </FormImage>
          </FormWrapper>
          <FormAction>
            <Button type="submit" isLoading={isSubmitting}>
              Update profile
            </Button>
          </FormAction>
        </Form>
      )}
    </Formik>
  );
}
