import classNames from 'classnames';
import { Button } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Nav = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  border-color: ${colors.white};
  overflow: hidden;
  background: ${colors.white};

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5rem;
    height: 1.625rem;
    margin-left: 1px;
    padding: 0 0.75rem;
    color: ${colors.white};
    background-color: ${colors.primary};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    border-radius: 0;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: ${colors.accent};
    }

    &:not(.is-active) {
      color: ${colors.grey40};
      background-color: ${colors.grey5};

      &:hover {
        background-color: ${colors.grey10};
      }
    }
  }
`;

export default function ViewNav({ view, onViewChange }) {
  return (
    <Nav>
      <Button className={classNames({ 'is-active': view === 'progress' })} onClick={() => onViewChange('progress')}>
        Progress
      </Button>
      <Button className={classNames({ 'is-active': view === 'month' })} onClick={() => onViewChange('month')}>
        By Month
      </Button>
    </Nav>
  );
}
