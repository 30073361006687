import { Checkbox, CurrencyInput, Form, Radio } from '~/components';
import _ from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { Field, RadioGroup } from '../fields';

const RateContainer = styled.div`
  position: relative;
`;

const RateInput = styled(CurrencyInput)`
  && {
    padding-right: 3.25rem;
  }
`;

const RateLabel = styled.span`
  position: absolute;
  right: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  pointer-events: none;
`;

export default function BillingTab({ form, entry, project, role, task }) {
  const { values, errors, conditionals, setFieldValue } = form;
  const { typeId, overrideBilling, isBillable, rate } = values;

  const isActuallyBillable = useMemo(() => {
    if (typeId === 'time_off') return false;
    const fields = [project?.isBillable, task?.isBillable];
    if (project?.useRoles) {
      fields.push(role?.isBillable);
    }
    const checks = _.filter(fields, _.isBoolean);
    return checks.length > 0 ? _.every(checks, (check) => !!check) : false;
  }, [typeId, project, role, task]);

  const unifiedIsBillable = useMemo(() => {
    if (overrideBilling) return isBillable;
    return isActuallyBillable.toString();
  }, [isActuallyBillable, overrideBilling, isBillable]);

  const unifiedRate = useMemo(() => {
    if (overrideBilling) return rate;
    if (!isActuallyBillable) return '';

    if (project?.useRoles) return role?.rate || '';
    return entry.projectMember?.rate || '';
  }, [isActuallyBillable, overrideBilling, rate, role, entry, project]);

  const showRate = useMemo(() => {
    if (overrideBilling) return conditionals.rate;
    return isActuallyBillable;
  }, [isActuallyBillable, overrideBilling, conditionals]);

  if (!conditionals.overrideBilling) {
    return null;
  }

  return (
    <div>
      <Form.Section title="Billing Information">
        <Form.Control>
          <Field name="overrideBilling" errors={errors}>
            <Checkbox
              name="overrideBilling"
              label="Override the billing settings for this time entry"
              checked={overrideBilling}
              onChange={(event) => setFieldValue('overrideBilling', event.target.checked)}
            />
          </Field>
        </Form.Control>
        {!!conditionals.isBillable && (
          <Form.Control>
            <Field name="isBillable" errors={errors} center style={{ minHeight: '2.5rem' }}>
              <RadioGroup
                name="isBillable"
                value={unifiedIsBillable}
                onChange={(event) => setFieldValue('isBillable', event.target.value)}>
                <Radio value="true" label="Billable" disabled={!overrideBilling} />
                <Radio value="false" label="Non-billable" disabled={!overrideBilling} />
              </RadioGroup>
            </Field>
            {!!showRate && (
              <Field name="rate" errors={errors}>
                <RateContainer>
                  <RateInput
                    name="rate"
                    placeholder="Rate"
                    min={0}
                    disabled={!overrideBilling}
                    value={unifiedRate}
                    precision={7}
                    currency={project.currency}
                    onChange={(value) => setFieldValue('rate', value)}
                  />
                  <RateLabel>/ HR</RateLabel>
                </RateContainer>
              </Field>
            )}
          </Form.Control>
        )}
      </Form.Section>
    </div>
  );
}
