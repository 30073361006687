import Fuse from 'fuse.js';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import { events } from '../lookups';
import React, { useCallback, useEffect, useState } from 'react';
import { AutoCompleteMulti } from '~/components';

const options = _.values(events);

export default function HistoryEventFilter({ value, ...props }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(async (q) => {
    if (!q) {
      return options;
    }

    const fuse = new Fuse(options, { keys: ['name'] });
    const results = fuse.search(q);
    return results.map((result) => result.item);
  }, []);

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      value={value}
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      placeholder="All"
      materialAlwaysVisible
      {...props}
    />
  );
}
