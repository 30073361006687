import Button from '~/components/Button';
import Icon from '~/components/Icon';
import InlineTooltip from '~/components/InlineTooltip';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import { rgba } from 'polished';
import { usePopper } from 'react-popper';
import { useIsMounted, useOnClickOutside } from '~/hooks';

export const Container = styled.div`
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 3.25rem;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    height: calc(100% + 2px);
    width: 1px;
    background: ${colors.grey10};
  }

  hr {
    width: calc(3.25rem + 1px);
  }

  > .button {
    flex: 1;
    padding: 0;
    background: transparent;
    color: ${colors.primary};
    border-radius: 0;
    transition: all 150ms ease-in-out;
    border-width: 0;
    position: relative;

    .icon {
      font-size: 0.75rem;
      pointer-events: none;
    }

    &:hover:not(:disabled),
    &:focus {
      border-color: ${colors.primary};
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  > .button {
    &:first-child {
      border-top-right-radius: 5px;
      margin-top: -1px;
      margin-right: -1px;
      padding-top: 1px;
      padding-right: 1px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      margin-bottom: -1px;
      margin-right: -1px;
      padding-bottom: 1px;
      padding-right: 1px;
    }
  }
`;

export const DeleteButton = styled(Button)`
  &&& {
    color: ${colors.danger};

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: ${colors.white};
        border-color: ${colors.danger};
        background-color: ${colors.danger};
      }
    }
  }
`;

const Success = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: ${colors.white};
  background-color: ${colors.success};
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;

  .icon {
    font-size: 0.75rem;
  }
`;

const Loading = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: ${colors.white};
  background-color: ${colors.primary50};
  border-color: ${colors.primary50};
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  position: relative;

  &:hover {
    cursor: default;
  }

  &::after {
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    animation: spinAround 0.5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 1em;
    width: 1em;
  }
`;

const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 20px;
    height: 20px;
    background: ${colors.white};
    right: 14px;
  }
  z-index: -1;
  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const Message = styled.div`
  max-width: ${({ maxWidth }) => maxWidth ?? '16rem'};
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  border-radius: 8px;
  z-index: 20;
  padding: 1rem;
  box-shadow: 0 3px 15px ${rgba(colors.grey100, 0.25)};
  cursor: default;

  button,
  a {
    line-height: 1.5rem;
    font-size: 0.875rem;

    &:hover,
    &:focus,
    &:active {
      border-radius: 16rem;
      background: ${colors.primary};
      color: ${colors.white};
    }
  }

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -10px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -10px;
  }
`;

const PopperDropdownContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  vertical-align: top;
  .icon {
    font-size: 0.75rem;
  }
`;

const PopperDropdownTrigger = styled.div`
  flex: 1;
  display: flex;

  .button {
    flex: 1;
    height: calc(100% + 1px);
    background: transparent;
    color: ${colors.primary};
    border-width: 0;
    border-radius: 0 0 5px 0;
    margin: 0 -1px -1px 0;
    padding: 1px 1px 1px 0;

    .icon {
      pointer-events: none;
    }
  }

  &.active,
  &:hover,
  &:focus-within {
    .button:not(:disabled) {
      border-color: ${colors.primary};
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }
`;

const PopperDropdownContext = React.createContext();

const PopperDropdown = forwardRef(function Dropdown(
  { children, align = 'left', position = 'auto', offsetToShowMenuOnTop = 25, className, onClose, ...props },
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const isMounted = useIsMounted();

  useOnClickOutside(
    ref,
    useCallback(() => {
      if (!isOpen && !props.isOpen) return;

      if (props.isOpen === undefined) {
        setIsOpen(false);
      } else {
        onClose && onClose(false);
      }
    }, [isOpen, props.isOpen, onClose]),
  );

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSetIsOpen = (flag) => {
    if (!isMounted.current) return;
    setIsOpen(!!flag);
  };

  return (
    <PopperDropdownContext.Provider value={{ align, position, isOpen, offsetToShowMenuOnTop }}>
      <PopperDropdownContainer ref={ref} className={className} {...props}>
        {typeof children === 'function' ? children({ setIsOpen: handleSetIsOpen, isOpen }) : children}
      </PopperDropdownContainer>
    </PopperDropdownContext.Provider>
  );
});

PopperDropdown.Trigger = PopperDropdownTrigger;

function Popper({ anchor, children, placement = 'top-start', maxWidth, offsetTop = 8, offsetLeft = 0, visible }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers = useMemo(() => {
    const modifiers = [
      { name: 'offset', options: { offset: [offsetLeft, offsetTop] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top-start', 'bottom-start'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ];

    return modifiers;
  }, [arrowElement, offsetTop, offsetLeft]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement,
    strategy: 'fixed',
  });

  return (
    <div ref={setReferenceElement}>
      {visible &&
        children &&
        ReactDOM.createPortal(
          <Message ref={setPopperElement} maxWidth={maxWidth} style={styles.popper} {...attributes.popper}>
            {children}
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </Message>,
          anchor.current,
        )}
    </div>
  );
}

const TableBoxRowActions = ({ isVisible = true, ...props }) => {
  if (!isVisible) return null;
  return <Container onClick={(e) => e.stopPropagation()} {...props} />;
};

function TableBoxRowActionsSuccess(props) {
  return (
    <Success {...props}>
      <Icon icon="check" />
    </Success>
  );
}

function TableBoxRowActionsLoading(props) {
  return <Loading {...props} />;
}

function TableBoxRowActionsEdit({ tooltip, ...props }) {
  return (
    <Button {...props} data-testid="edit_button">
      <Icon icon="pencil-alt" />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </Button>
  );
}

function TableBoxRowActionsView({ tooltip, ...props }) {
  return (
    <Button {...props}>
      <Icon icon="eye" />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </Button>
  );
}

function TableBoxRowActionsDelete({ tooltip, icon = 'trash', ...props }) {
  return (
    <DeleteButton {...props}>
      <Icon icon={icon} />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </DeleteButton>
  );
}

function TableBoxRowActionsDropdown({ tooltip, children, disabled, ...props }) {
  const dropdownRef = useRef();
  return (
    <PopperDropdown ref={dropdownRef} align="right" {...props} onClick={(e) => e.stopPropagation()}>
      {({ setIsOpen, ...props }) => (
        <>
          <PopperDropdown.Trigger className={props.isOpen ? 'active' : undefined}>
            <Button
              disabled={disabled}
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}>
              <Icon icon="chevron-down" />
              {tooltip && <InlineTooltip message={tooltip} placement="left" />}
            </Button>
          </PopperDropdown.Trigger>
          <Popper anchor={dropdownRef} onClick={(event) => event.stopPropagation()} visible={props.isOpen}>
            {typeof children === 'function' ? children({ setIsOpen }) : children}
          </Popper>
        </>
      )}
    </PopperDropdown>
  );
}

function TableBoxRowFormActions({ submit, onCancel, onSubmit, isLoading, disabled }) {
  return (
    <TableBoxRowActions>
      {isLoading ? (
        <TableBoxRowActionsLoading />
      ) : (
        <>
          <Button
            type={submit ? 'submit' : 'button'}
            onClick={onSubmit}
            disabled={disabled}
            data-testid="submit_button">
            <Icon icon="check" onClick={onSubmit} />
          </Button>
          <hr />
          <DeleteButton type="button" onClick={onCancel}>
            <Icon icon="times" />
          </DeleteButton>
        </>
      )}
    </TableBoxRowActions>
  );
}

TableBoxRowActions.Success = TableBoxRowActionsSuccess;
TableBoxRowActions.Edit = TableBoxRowActionsEdit;
TableBoxRowActions.View = TableBoxRowActionsView;
TableBoxRowActions.Delete = TableBoxRowActionsDelete;
TableBoxRowActions.Dropdown = TableBoxRowActionsDropdown;
TableBoxRowActions.Form = TableBoxRowFormActions;

export default TableBoxRowActions;
