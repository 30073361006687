import React from 'react';
import { intervalOptions } from '~/utils';
import intervalsByScope from '~/utils/intervalsByScope';
import CreatedPeriodFilter from './CreatedPeriodFilter';

function CreatedPeriodReportFilter({ clearable = true, scope = 'day', value, onChange }) {
  let intervals = intervalsByScope[scope];
  if (clearable) intervals = [intervalOptions.all_dates].concat(intervals);

  return (
    <CreatedPeriodFilter
      intervals={intervals}
      clearable={clearable}
      placeholder="All"
      materialPlaceholder="Created Date Range"
      materialAlwaysVisible
      scope={scope}
      onChange={onChange}
      value={value}
    />
  );
}

export default CreatedPeriodReportFilter;
