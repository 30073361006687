import { Percent, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

const RealizationRate = styled.div`
  font-size: 3.5rem;
`;

const Preview = styled.div`
  min-height: 14rem;
`;

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function ClientRealizationRateWidget({ client }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).clients(client.id).getRealizationRateKPI();
    actions.ready({ data });
    // Intentionally passing the full "client" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, client, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!isReady} style={{ height: '18.75rem' }}>
      <Widget.Header>
        <Widget.Help message="Realization rate is calculated by dividing the billable hours by the total hours worked" />
        <Widget.Title>Realization Rate</Widget.Title>
      </Widget.Header>
      <Widget.Preview>
        <Preview />
      </Widget.Preview>
      <Widget.Content>
        <RealizationRate>
          <Percent value={data?.realizationRate} minimumFractionDigits={0} maximumFractionDigits={1} />
        </RealizationRate>
      </Widget.Content>
    </Widget>
  );
}

export default ClientRealizationRateWidget;
