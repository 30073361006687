import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function TimeEntryInstruction() {
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>Yes</td>
            <td>
              The ID of the time entry to import, it will be used to check for duplicates if multiple imports are run.
            </td>
          </tr>
          <tr>
            <td>Member Email</td>
            <td>Yes</td>
            <td>
              Email address of the member for the time entry. It should be lowercase and match the member's profile in
              Ruddr.
            </td>
          </tr>
          <tr>
            <td>Date</td>
            <td>Yes</td>
            <td>Date of the time entry in 'MM/DD/YYYY' format.</td>
          </tr>
          <tr>
            <td>Minutes</td>
            <td>Yes</td>
            <td>The duration of the time entry in minutes.</td>
          </tr>
          <tr>
            <td>Client Name</td>
            <td>Yes</td>
            <td>
              The name of the client associated with the time entry. This field must match the name of a client exactly,
              including spaces, punctuation and capitalization, otherwise the time entry may be skipped during import.
            </td>
          </tr>
          <tr>
            <td>Project&nbsp;Name</td>
            <td>Yes</td>
            <td>
              The name of the project associated with the time entry. This field must match the name of a project
              exactly, including spaces, punctuation and capitalization, otherwise the time entry may be skipped during
              import.
            </td>
          </tr>
          <tr>
            <td>Role Name</td>
            <td>No</td>
            <td>
              If the time entry is associated with a project role (i.e. 'Senior Project Manager') you may include it
              here. If the role does not exist on the project, it will be automatically created.
            </td>
          </tr>
          <tr>
            <td>Task Name</td>
            <td>No</td>
            <td>
              If the time entry is associated with a task (i.e. 'Build homepage banner design') you may include it here.
              If the task does not exist on the project, it will be automatically created assigned to the member.
            </td>
          </tr>
          <tr>
            <td>Billable</td>
            <td>No</td>
            <td>Set this value to 'Yes' if billable, otherwise will be treated as non-billable role.</td>
          </tr>
          <tr>
            <td>Rate</td>
            <td>No</td>
            <td>
              Hourly rate should be a numeric value, and will be associated with the Role if the role is created via
              this import. Otherwise this field will be ignored.
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>No</td>
            <td>Provide some description or notes related to the time entry.</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>No</td>
            <td>
              'approved', 'pending_approval' or 'not_submitted' are valid options for this field. If none of these are
              provided the default will be 'not_submitted'.
            </td>
          </tr>
          <tr>
            <td>Time Off Type</td>
            <td>No</td>
            <td>
              Leave this blank otherwise the time entry will be treated as Time Off. If there is a value it must match
              one of the time off types listed on the "Settings - Custom Data - Time Off Types" page.
            </td>
          </tr>
          <tr>
            <td>Invoiced</td>
            <td>No</td>
            <td>
              Set this value to 'Yes' if the time entry has been invoiced in another system or prior to running this
              import. By default all billable and imported time entries will be considered ready for invoicing.
            </td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
