import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import path from 'path-browserify';
import { useActions } from '~/hooks';
import { dateFormats, QueryString } from '~/utils';
import { useApi } from './ApiContext';
import { useSubscription } from './SubscriptionContext';

const WorkspaceContext = React.createContext();

const initialState = { isReady: false, workspace: null };
const handlers = {
  loading: () => ({ isReady: false, workspace: null }),
  ready: (workspace) => ({ isReady: true, workspace }),
  updateWorkspace: (workspace, state) => ({ isReady: true, workspace: { ...state.workspace, ...workspace } }),
  updateMember: (member, state) => ({
    workspace: { ...state.workspace, member: { ...state.workspace.member, ...member } },
  }),
};

function WorkspaceProvider({ children }) {
  const api = useApi();
  const [{ isReady, workspace }, actions] = useActions(handlers, initialState);
  const { notify } = useSubscription();
  const isRefreshing = useRef(false);
  const loadingKey = useRef(null);
  const isSelected = !!workspace;
  const workspaceId = workspace?.id;

  const history = useHistory();

  // Currently we only show a message at the top of the screen when a
  // subscription is past due or:
  // 1) There is a trial with > 0 days and <= 5 days left
  // 2) The workspace has > 3 active/billable members
  // 3) The workspace billing is not configured, which requires:
  //    a) A billing address has been set
  //    b) A credit card has been added or is set to manual billing
  // The billing status message is only visible by workspace admins.
  const message = useMemo(() => {
    if (!workspace?.member?.securityRole?.manageWorkspace) return null;
    if (workspace?.billingStatus?.isPastDue) {
      return 'Your subscription is past due.';
    }
    if (
      !workspace?.billingStatus?.trialEndsAt ||
      workspace?.billingStatus?.isConfigured === true ||
      workspace?.billingStatus?.memberCount <= 3
    ) {
      return null;
    }
    const trialEnd = moment(workspace.billingStatus.trialEndsAt);
    const trialDaysLeft = Math.max(trialEnd.diff(moment(), 'days', true), 0);
    if (trialDaysLeft > 0 && trialDaysLeft <= 5) {
      const trialEndDate = trialEnd.format(dateFormats.longDate);
      return `Your free trial will end soon on ${trialEndDate}.`;
    }
    return null;
  }, [workspace]);

  const hasMessage = !!message;

  const navigateWorkspace = useCallback(
    (workspaceKey, workspacePath, replace = false) => {
      const segments = ['/app'];
      if (workspaceKey) {
        segments.push(workspaceKey);

        if (workspacePath) {
          segments.push(workspacePath);
        }
      }

      let navigatePath = path.join(...segments);
      if (!workspaceKey && workspacePath) {
        navigatePath = `${navigatePath}?page=${encodeURIComponent(workspacePath)}`;
      }

      if (navigatePath !== window.location.pathname) {
        if (replace) {
          history.replace(navigatePath);
        } else {
          history.push(navigatePath);
        }
      }
    },
    [history],
  );

  const loginRedirect = useCallback(
    (workspaceKey) => {
      // Pass forward the `source` query string param
      let source = undefined;
      const currentParams = new URLSearchParams(window.location.search);
      if (currentParams.has('source')) {
        source = currentParams.get('source');
        currentParams.delete('source');
      }
      const search = currentParams.toString().length > 0 ? '?' + currentParams.toString() : '';
      const params = {
        redirect: encodeURIComponent(window.location.pathname + search),
        source,
        workspace: workspaceKey ?? undefined,
      };
      history.push(`/login`.concat(new QueryString(params).toString(true)));
    },
    [history],
  );

  const loadWorkspace = useCallback(
    async (workspaceKey) => {
      // Prevents any double loading of the same key or reloading info on the same loaded workspace
      if (workspaceKey === workspace?.key || workspaceKey === loadingKey.current) {
        return;
      }

      loadingKey.current = workspaceKey;

      try {
        actions.loading();
        const { data } = await api.www.workspaces(workspaceKey).select();
        actions.ready(data);
      } catch (error) {
        if (error.status === 401) {
          loginRedirect(workspaceKey);
        }
        actions.ready(null);
      }

      loadingKey.current = null;
    },
    [actions, api, workspace, loginRedirect],
  );

  const updateWorkspace = useCallback(
    (workspace) => {
      actions.updateWorkspace(workspace);
      notify(useSubscription.keys.workspace_changed);
    },
    [notify, actions],
  );

  const updateMember = useCallback(
    (member) => {
      actions.updateMember(member);
    },
    [actions],
  );

  const clearWorkspace = useCallback(() => {
    actions.ready(null);
  }, [actions]);

  const deleteWorkspace = useCallback(
    async (values) => {
      await api.www.workspaces(workspace.id).delete(values);
      clearWorkspace();
      navigateWorkspace();
      notify('workspace-deleted');
    },
    [clearWorkspace, navigateWorkspace, workspace, notify, api],
  );

  const refreshWorkspace = useCallback(async () => {
    if (!workspaceId || isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;

    const { data } = await api.www.workspaces(workspaceId).get();
    updateWorkspace(data);

    isRefreshing.current = false;
  }, [api, workspaceId, updateWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        isReady,
        workspace,
        workspaceId,
        isSelected,
        hasMessage,
        message,
        navigateWorkspace,
        loginRedirect,
        loadWorkspace,
        clearWorkspace,
        updateWorkspace,
        updateMember,
        deleteWorkspace,
        refreshWorkspace,
      }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

function useWorkspace() {
  return useContext(WorkspaceContext);
}

export { WorkspaceContext, useWorkspace, WorkspaceProvider };
