import React from 'react';

import { useDocumentTitle } from '~/hooks';
import { Header, Page } from '../components';
import Search from './Search';

export default function Workspaces() {
  useDocumentTitle('Workspaces - Admin');

  return (
    <Page>
      <Header />
      <Search />
    </Page>
  );
}
