import { useWorkspace } from '~/contexts';
import React from 'react';
import RouteLink from './RouteLink';
import { ClientPopover } from '~/components';

function ClientLink({ client, children, ...props }) {
  const { workspace } = useWorkspace();
  const url = `/app/${workspace.key}/clients/${client.key}`;

  return (
    <RouteLink to={url} {...props}>
      <ClientPopover clientId={client.id} placement="right">
        {children || client.name}
      </ClientPopover>
    </RouteLink>
  );
}

export default ClientLink;
