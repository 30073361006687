import { SingleSelect } from '~/components';
import React from 'react';

function ProjectTeamStatusFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Project Team Status"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
    </SingleSelect>
  );
}

export default ProjectTeamStatusFilter;
