import React, { useMemo, useState } from 'react';
import { Field } from '~/components';

export default function ProjectTagSelect({ group, groupIndex }) {
  const [filterValue, setFilterValue] = useState('');

  const tags = useMemo(() => {
    if (filterValue) {
      return group.tags.filter((tag) => tag.name.toLowerCase().includes(filterValue.toLowerCase()));
    }
    return group.tags;
  }, [group, filterValue]);

  return (
    <Field.SingleSelect
      name={`tag_${groupIndex}`}
      materialPlaceholder={`Expensify Tag: ${group.name}`}
      materialAlwaysVisible={true}
      showEmptyOption={true}
      showFilter={true}
      onFilterChange={(event) => setFilterValue(event.target.value)}>
      {tags.map((tag, tagIndex) => (
        <option key={`${tagIndex}_${tag.name}`} value={tag.name.trim()}>
          {tag.name.trim()}
        </option>
      ))}
    </Field.SingleSelect>
  );
}
