import { Button, Dropdown, Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const StyledButton = styled(Button)`
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0;
  border: none;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }

  transition: all 100ms ease-in-out;
`;

function ExportDropdown({ children, ...props }) {
  return (
    <Dropdown align="right" {...props}>
      {({ setIsOpen, ...props }) => (
        <>
          <Dropdown.Trigger>
            <StyledButton onClick={() => setIsOpen(true)}>
              <Icon icon="download" />
            </StyledButton>
          </Dropdown.Trigger>
          <Dropdown.Menu {...props}>
            {typeof children === 'function' ? children({ setIsOpen }) : children}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
}

ExportDropdown.Item = Dropdown.Item;

export default ExportDropdown;
