import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  Button,
  Buttons,
  CancelButton,
  Drawer,
  FormMessage,
  Icon,
  InternalClientTooltip,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import ProjectMappingModal from './ProjectMappingModal';

const Project = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
`;

const Client = styled.div`
  display: flex;
  padding-top: 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const ErrorTags = styled.ul`
  margin-bottom: 0.5rem;

  > li {
    margin-top: 0.5rem;
  }
`;

const ErrorProjects = styled.ul`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  list-style: inside disc;
`;

export default function ProjectMappingsDrawer({ onClose, onSave }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, saved, status }, form] = useForm();
  const [editMapping, setEditMapping] = useState();
  const [projectMappings, setProjectMappings] = useState([]);
  const [expensifyTags, setExpensifyTags] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);
  const drawerRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: projectMappings } = await api.www.workspaces(workspace.id).expensify.getProjectMappings();
        const { data: tags } = await api.www.workspaces(workspace.id).expensify.getTags();

        setProjectMappings(projectMappings);
        setExpensifyTags(tags);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  const saveProjectMapping = (mapping) => {
    setDirty(true);
    setProjectMappings((mappings) => {
      const projectMapping = _.find(mappings, { id: mapping.id });
      if (projectMapping) {
        return mappings.map((m) => (m.id === mapping.id ? mapping : m));
      }
      return [...mappings, mapping];
    });
    setEditMapping();
  };

  const deleteProjectMapping = (mapping) => {
    setDirty(true);
    setProjectMappings((mappings) => mappings.filter((m) => m.id !== mapping.id));
  };

  const validateProjectMappings = () => {
    const simpleMappings = _.map(projectMappings, (mapping) => ({
      project: `${mapping.project.client.name} - ${mapping.project.name}`,
      tag: mapping.tags.map((tag) => tag.replaceAll(':', '\\:')).join(':'),
    }));

    const duplicateTags = _(simpleMappings)
      .groupBy((mapping) => mapping.tag)
      .pickBy((group) => group.length > 1)
      .value();

    const tags = _.keys(duplicateTags).sort();

    if (tags.length === 0) {
      return null;
    }
    return (
      <>
        <p>Duplicate tags identified:</p>
        <ErrorTags>
          {tags.map((tag) => (
            <li key={tag}>
              <code>{tag}</code>
              <ErrorProjects>
                {duplicateTags[tag].map((duplicate, dupIndex) => (
                  <li key={`${dupIndex}_${duplicate.project}`}>{duplicate.project}</li>
                ))}
              </ErrorProjects>
            </li>
          ))}
        </ErrorTags>
        <p>All tags must be unique to help ensure expenses are routed to a single project.</p>
      </>
    );
  };

  return (
    <>
      <Drawer
        isOpen
        title="Project Mappings"
        byline="Expensify Integration"
        ref={drawerRef}
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={onClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          const handleSave = async () => {
            form.submit();

            const message = validateProjectMappings();
            if (message) {
              form.error({ message });
              drawerRef.current.scrollTo({ top: 0 });
              return;
            }

            const mappings = projectMappings.map((mapping) => ({
              id: mapping.id.startsWith('temp_') ? undefined : mapping.id,
              ..._.pick(mapping, ['projectId', 'rawInput', 'tags']),
            }));

            try {
              await await api.www.workspaces(workspace.id).expensify.updateProjectMappings(mappings);
              form.save();
              onSave();
              closeDrawer();
            } catch (error) {
              form.error({ message: error.message });
              drawerRef.current.scrollTo({ top: 0 });
            }
          };

          if (!isReady) return <PageLoader />;

          return (
            <>
              {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}
              <Table>
                <Table.BoxHeader>
                  <Table.Column>Ruddr Project</Table.Column>
                  <Table.Column>Expensify Tag</Table.Column>
                  <Table.BoxActionsColumn />
                </Table.BoxHeader>
                <Table.Body>
                  {projectMappings.map((mapping) => (
                    <Table.BoxRow key={mapping.id}>
                      <Table.Cell>
                        <Project>
                          {mapping.project.name}
                          <Client>
                            {mapping.project.client.name}
                            {mapping.project.client.isInternal && <InternalClientTooltip />}
                          </Client>
                        </Project>
                      </Table.Cell>
                      <Table.Cell>
                        <code>{mapping.tags.map((tag) => tag.replaceAll(':', '\\:')).join(':')}</code>
                      </Table.Cell>
                      <TableBoxRowActions>
                        <TableBoxRowActions.Edit onClick={() => setEditMapping(mapping)} />
                        <hr />
                        <TableBoxRowActions.Delete onClick={() => deleteProjectMapping(mapping)} />
                      </TableBoxRowActions>
                    </Table.BoxRow>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <Button isAnchor isStrong onClick={() => setEditMapping(null)}>
                        <Icon icon="plus" size="xs" spaceRight />
                        Add Mapping
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Drawer.Actions>
                <Buttons align="right">
                  <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                  <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                    Save &amp; Close
                  </ActionButton>
                </Buttons>
              </Drawer.Actions>
            </>
          );
        }}
      </Drawer>
      {editMapping !== undefined && (
        <ProjectMappingModal
          mapping={editMapping}
          expensifyTags={expensifyTags}
          onClose={() => setEditMapping()}
          onSave={saveProjectMapping}
        />
      )}
    </>
  );
}
