import { ActionButton, Button, Buttons, CancelButton, FormMessage, ModalCard } from '~/components';
import { Formik } from 'formik';
import { useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { mergeValues, getFileBytes } from '~/utils';
import { colors, weights } from '~/styles';

const BodyContainer = styled.div`
  p {
    margin-top: 1rem;
    color: ${colors.grey55};
    font-size: 0.75rem;
    a {
      padding: 0 0.375rem;
      font-weight: ${weights.bold};
    }
  }
`;

const ControlBox = styled.div`
  && {
    background-color: ${colors.grey5};
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
    margin: 1rem 0;
  }
`;

const Actions = styled(Buttons)`
  margin-top: 1.75rem;

  ${Button} {
    width: 11rem;

    &:last-child {
      margin-left: 1rem;
    }
  }
`;

export default function ImportFileModal({ expenseReport, onClose, onSaved }) {
  const { workspace } = useWorkspace();
  const [formModel] = useState({});
  const toast = useToast();
  const [data, setData] = useState(null);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const initialValues = mergeValues({ selectedDataType: null }, formModel);

  async function handleSaved() {
    onSaved();
  }

  async function handleImport(values) {
    try {
      form.submit();
      const bytes =
        values.csvFile !== null && values.csvFile.target.files[0]
          ? await getFileBytes(values.csvFile.target.files[0])
          : null;

      await api.www.workspaces(workspace.id).expenseItems().import(bytes, expenseReport.id);
      toast.success(`Imported expense items`);
      form.save();
      await handleSaved();
    } catch (error) {
      form.error(error);
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleImport} validateOnBlur={false} validateOnChange={false}>
      {(formik) => {
        const onFileChanged = (content) => {
          formik.setFieldValue('csvFile', content);
          setData(content);
        };
        return (
          <ModalCard title="Import Expense Items" onClose={onClose}>
            <ModalCard.Body>
              <BodyContainer>
                To add your expenses to this expense report in bulk, first put your expenses in the appropriate CSV file
                format and then upload your file below.
                <p>
                  Here is a sample file to help get you started:
                  <a href="/csv/expenses-template.csv" target="_blank">
                    expenses.csv
                  </a>
                </p>
                <ControlBox>
                  <input name="csvFile" type="file" accept="text/csv" onChange={onFileChanged}></input>
                </ControlBox>
                <Actions align="center"></Actions>
              </BodyContainer>
              {status && <FormMessage.Error>{message}</FormMessage.Error>}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton onClick={onClose}>Close</CancelButton>
                {data && (
                  <ActionButton disabled={isSubmitting} onClick={formik.submitForm}>
                    Import Expenses
                  </ActionButton>
                )}
              </Buttons>
            </ModalCard.Footer>
          </ModalCard>
        );
      }}
    </Formik>
  );
}
