import { useFeatures } from '~/hooks';
import React from 'react';
import ProjectTypeTag from '~/routes/app/reports/components/ProjectTypeTag';
import Tag from '../../components/Tag';
import Tags from '../../components/Tags';

export default function FilterTags({ params, setParam }) {
  const features = useFeatures();

  return (
    <Tags>
      <Tag
        label="Client"
        value={params.client?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ client: [] })}
      />

      <Tag
        label="Client Owner"
        value={params.clientOwner?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ clientOwner: [] })}
      />

      <Tag
        label="Client Tags"
        value={params.clientTags?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ clientTags: [] })}
      />

      <Tag
        label="Client Location"
        value={params.clientLocations?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ clientLocations: [] })}
      />

      <Tag
        label="Client Industry"
        value={params.clientIndustries?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ clientIndustries: [] })}
      />

      <Tag
        label="Project"
        value={params.project?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ project: [] })}
      />

      <Tag
        label="Project Admin"
        value={params.projectAdmin?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ projectAdmin: [] })}
      />

      {features.practices && (
        <Tag
          label="Project Practice"
          value={params.projectPractice?.map((v) => v.name).join('; ')}
          onRemove={() => setParam({ projectPractice: [] })}
        />
      )}

      <ProjectTypeTag value={params.projectTypes} onRemove={() => setParam({ projectTypes: [] })} />

      <Tag
        label="Project Archived"
        value={{ active: 'No', archived: 'Yes' }[params.recordStatusId]}
        onRemove={() => setParam({ recordStatusId: null })}
      />

      <Tag
        label="Project Billing Type"
        value={params.billingType?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ billingType: [] })}
      />

      <Tag
        label="Project Status"
        value={params.status?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ status: [] })}
      />

      <Tag
        label="Project Tags"
        value={params.projectTags?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ projectTags: [] })}
      />

      {features.practices && (
        <Tag
          label="Resource Practice"
          value={params.resourcePractice?.map((v) => v.name).join('; ')}
          onRemove={() => setParam({ resourcePractice: [] })}
        />
      )}

      {features.disciplines && (
        <Tag
          label="Resource Discipline"
          value={params.resourceDiscipline?.map((v) => v.name).join('; ')}
          onRemove={() => setParam({ resourceDiscipline: [] })}
        />
      )}

      <Tag
        label="Resource Skills"
        value={params.resourceSkill?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ resourceSkill: [] })}
      />

      <Tag
        label="Resource Location"
        value={params.resourceLocation?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ resourceLocation: [] })}
      />

      <Tag
        label="Resource Active"
        value={{ active: 'Yes', inactive: 'No' }[params.resourceStatusId]}
        onRemove={() => setParam({ resourceStatusId: null })}
      />

      <Tag
        label="Resource Type"
        value={{ member: 'Member', placeholder: 'Placeholder' }[params.resourceTypeId]}
        onRemove={() => setParam({ resourceTypeId: null })}
      />

      <Tag
        label="Member"
        value={params.member?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ member: [] })}
      />

      <Tag
        label="Member Workspace Billability"
        value={{ billable: 'Billable', non_billable: 'Non-Billable' }[params.memberBillableTypeId]}
        onRemove={() => setParam({ memberBillableTypeId: null })}
      />

      <Tag
        label="Member Type"
        value={params.memberEmploymentType.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ memberEmploymentType: [] })}
      />

      <Tag
        label="Member Job Title"
        value={params.jobTitles?.map((ps) => ps.name).join('; ')}
        onRemove={() => setParam({ jobTitles: [] })}
      />

      <Tag
        label="Member Tags"
        value={params.memberTags?.map((pt) => pt.name).join('; ')}
        onRemove={() => setParam({ memberTags: [] })}
      />

      <Tag
        label="Member Location"
        value={params.memberLocations?.map((pt) => pt.name).join('; ')}
        onRemove={() => setParam({ memberLocations: [] })}
      />

      {params.onlyAllocatedProjects && (
        <Tag
          label="Only show projects with allocations"
          value="Yes"
          onRemove={() => setParam({ onlyAllocatedProjects: false })}
        />
      )}

      <Tag
        label="Allocation Billability"
        value={params.allocationBillableTypes?.map((v) => v.name).join('; ')}
        onRemove={() => setParam({ allocationBillableTypes: [] })}
      />
    </Tags>
  );
}
