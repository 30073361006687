import { ActionButton, Buttons, CancelButton, Confirmation, Drawer, Field, Form, FormMessage } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

function SendClientApprovalDrawer({ clientApproval, onSent, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const confirmation = useConfirmation();

  const drawerRef = useRef();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      email: '',
      emails: [],
      ccEmail: '',
      ccEmails: [],
      replyTo: '',
      sendBcc: true,
      emailFromName: '',
      emailSubject: '',
      emailBody: '',
    },
    {
      ...clientApproval,
      email: clientApproval.emails ? clientApproval.emails.join(', ') : '',
      ccEmail: clientApproval.ccEmails ? clientApproval.ccEmails.join(', ') : '',
    },
  );

  const schema = Yup.object().shape({
    emails: Yup.array()
      .of(Yup.string().email().max(255).required())
      .label('To')
      .min(1, 'To field must have at least one email')
      .required(),
    ccEmails: Yup.array().of(Yup.string().email().max(255)).label('Cc'),
    replyTo: Yup.string().label('Reply To').email().max(255).required(),
    sendBcc: Yup.boolean().label('Bcc Me').required(),
    emailFromName: Yup.string().label('From Name').max(255),
    emailSubject: Yup.string().label('Subject').max(255).required(),
    emailBody: Yup.string().label('Body').max(5000).required(),
  });

  return (
    <Drawer
      isOpen
      title="Send Client Approval via Email"
      ref={drawerRef}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSendTest = async (values) => {
          try {
            form.submit('test');

            const sendValues = emptyStringToNull(_.omit(values, ['email', 'ccEmail']));
            await api.www
              .workspaces(workspace.id)
              .projects(clientApproval.projectId)
              .clientApprovals(clientApproval.id)
              .sendTestEmail(sendValues);

            form.save('test');
            toast.success(`Client approval sent to ${workspace.member.email}`);
            if (onSent) onSent();
          } catch (error) {
            form.error(error);
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        const handleSend = async (values) => {
          try {
            form.submit('send');

            const sendValues = emptyStringToNull(_.omit(values, ['email', 'ccEmail']));
            await api.www
              .workspaces(workspace.id)
              .projects(clientApproval.projectId)
              .clientApprovals(clientApproval.id)
              .sendEmail(sendValues);

            form.save('send');
            toast.success(`Client approval sent to ${sendValues.emails.join(', ')}`);
            closeDrawer();
            if (onSent) onSent();
          } catch (error) {
            form.error(error);
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        async function handleSubmit(values, formik) {
          switch (formRef.current.status) {
            case 'test':
              await handleSendTest(values);
              formik.resetForm({ values });
              break;

            case 'send': {
              const result = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  {clientApproval.statusId === 'sent'
                    ? `This client approval has already been submitted. If you send this approval again, the approval status of all associated time entries and expense items will be reset. Are you sure you want to proceed?`
                    : 'Are you sure you want to send this client approval?'}
                </Confirmation>
              ));
              if (!result) return;

              await handleSend(values);
              break;
            }

            default:
              break;
          }
        }

        return (
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(formik) => {
              const handleEmailChange = (event) => {
                const value = event.target.value;
                formik.setFieldValue('email', value);

                const values = value.split(/[\s,;]+/).filter(Boolean);
                formik.setFieldValue('emails', values);
              };

              const handleCcEmailChange = (event) => {
                const value = event.target.value;
                formik.setFieldValue('ccEmail', value);

                const values = value.split(/[\s,;]+/).filter(Boolean);
                formik.setFieldValue('ccEmails', values);
              };

              const processEmailErrors = (errors) => {
                if (!errors || !errors.map) {
                  return errors;
                }
                return errors.map((error) => {
                  if (typeof error !== 'string') {
                    return error;
                  }
                  return error.replace(/emails\[(\d+)\]/, (match, p1) => `To email #${parseInt(p1) + 1}`);
                });
              };

              const processCcEmailErrors = (errors) => {
                if (!errors || !errors.map) {
                  return errors;
                }
                return errors.map((error) => {
                  if (typeof error !== 'string') {
                    return error;
                  }
                  return error.replace(/ccEmails\[(\d+)\]/, (match, p1) => `Cc email #${parseInt(p1) + 1}`);
                });
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Form.Section title="Email Details">
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="emails"
                        placeholder="To"
                        value={formik.values.email}
                        onChange={handleEmailChange}
                        onProcessErrors={processEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control help='The name to display in the "from" part of the email. If not provided, "Ruddr" will be used.'>
                      <Field.Text name="emailFromName" placeholder="From Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control help='In order to ensure deliverability, all emails will come from approvals@ruddr.io, but this will be used as the "Reply-To" email adddress.'>
                      <Field.Text name="replyTo" placeholder="Reply To" type="email" maxLength={255} />
                    </Form.Control>
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="ccEmails"
                        placeholder="Cc"
                        value={formik.values.ccEmail}
                        onChange={handleCcEmailChange}
                        onProcessErrors={processCcEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.Checkbox name="sendBcc" label="Bcc Me" />
                    </Form.Control>
                    <Form.Control>
                      <Field.Text name="emailSubject" placeholder="Subject" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea name="emailBody" placeholder="Body" maxLength={5000} />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <ActionButton
                        isOutline
                        isLoading={isSubmitting === 'test'}
                        ok={saved === 'test'}
                        onClick={() => formik.setStatus('test') || formik.submitForm()}>
                        Send Test to Me
                      </ActionButton>
                      <ActionButton
                        isLoading={isSubmitting === 'send'}
                        ok={saved === 'send'}
                        onClick={() => formik.setStatus('send') || formik.submitForm()}>
                        Send
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default SendClientApprovalDrawer;
