import { DateTime } from '~/components';
import React from 'react';

function PeriodDateRange({ period: { start, end }, member: { activeStartDate, activeEndDate } }) {
  const startDate = start || activeStartDate;
  const endDate = end || activeEndDate;

  if (!startDate && !endDate) {
    return 'Default';
  } else {
    return (
      <>
        {startDate ? <DateTime value={startDate} /> : 'Joined'}
        &nbsp;to&nbsp;
        {endDate ? <DateTime value={endDate} /> : 'Current'}
      </>
    );
  }
}

export default PeriodDateRange;
