import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, Tooltip } from '~/components';
import { colors } from '~/styles';

const FieldContainer = styled.div`
  display: flex;
  ${({ center }) =>
    center &&
    css`
      align-items: center;
    `}
`;

const FieldControls = styled.div`
  flex: 1;
`;

const ErrorTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;

export default function Field({ name, errors, children, ...props }) {
  const error = errors && name in errors ? errors[name] : null;
  return (
    <FieldContainer {...props}>
      <FieldControls>{children}</FieldControls>
      {error && (
        <ErrorTooltip message={error}>
          <Icon icon="exclamation-circle" color={colors.danger} />
        </ErrorTooltip>
      )}
    </FieldContainer>
  );
}
