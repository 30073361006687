import {
  ActionButton,
  Buttons,
  CancelButton,
  Drawer,
  HelpTooltip,
  Icon,
  SingleSelect,
  Table,
  Tooltip,
} from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import Fuse from 'fuse.js';
import { colors } from '~/styles';
import _ from 'lodash';

export default function ConfigurationDrawer({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [configuration, setConfiguration] = useState({ data: null, isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).xero.getConnection();

      if (!isMounted.current) return;
      setConfiguration({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [items, setItems] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).xero.getItems();

      if (!isMounted.current) return;
      setItems({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [invoiceItems, setInvoiceItems] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).invoiceItems().get();

      if (!isMounted.current) return;
      setInvoiceItems({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [paymentAccounts, setPaymentAccounts] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).xero.getPaymentAccounts();

      if (!isMounted.current) return;
      setPaymentAccounts({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  return (
    <Drawer
      isOpen
      title="Configuration"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            await api.www.workspaces(workspace.id).xero.updateConfiguration({
              defaultXeroItemId: configuration.data.defaultXeroItemId,
              defaultXeroItemCode:
                items.data.find((item) => item.id === configuration.data.defaultXeroItemId)?.code ?? null,
              defaultInvoiceItemId: configuration.data.defaultInvoiceItemId,
              xeroPaymentAccountId: configuration.data.xeroPaymentAccountId,
            });
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        const handleChange = (event) => {
          setDirty(true);

          setConfiguration((state) => ({
            ...state,
            data: { ...state.data, [event.target.name]: event.target.value || null },
          }));
        };

        if (!configuration.isReady || !items.isReady || !invoiceItems.isReady || !paymentAccounts.isReady)
          return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column width="40%">Setting</Table.Column>

                <Table.Column>
                  <strong>Value</strong>
                </Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                <Table.BoxRow>
                  <Table.Cell>
                    Default Xero Product/Service
                    <HelpTooltip
                      message="Ruddr will use this default Product/Service when publishing an invoice to Xero if an Invoice Item in Ruddr is not mapped to a Product/Service."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <OptionSelect
                      defaultOptions={items.data}
                      name="defaultXeroItemId"
                      typeLabel="Xero Product/Service"
                      value={configuration.data.defaultXeroItemId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>

                <Table.BoxRow>
                  <Table.Cell>
                    Default Ruddr Invoice Item
                    <HelpTooltip
                      message="Ruddr will use this default Invoice Item when updating a Ruddr invoice from Xero if the invoice uses a Product/Service that is not mapped to an Invoice Item in Ruddr."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <OptionSelect
                      defaultOptions={invoiceItems.data}
                      name="defaultInvoiceItemId"
                      typeLabel="Ruddr Invoice Item"
                      value={configuration.data.defaultInvoiceItemId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>

                <Table.BoxRow>
                  <Table.Cell>
                    Payment Account
                    <HelpTooltip
                      message="Ruddr will use this Account when saving a payment to Xero."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <OptionSelect
                      defaultOptions={paymentAccounts.data}
                      name="xeroPaymentAccountId"
                      typeLabel="Xero Payment Account"
                      value={configuration.data.xeroPaymentAccountId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function OptionSelect({ defaultOptions, value, typeLabel, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return defaultOptions;
    }

    const fuse = new Fuse(defaultOptions, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [defaultOptions, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const option = _.find(defaultOptions, { id: value });
    if (!option)
      return {
        id: value,
        name: (
          <Tooltip message={`${typeLabel} not found`}>
            <Icon icon="warning" color={colors.warning} spaceRight />
            {typeLabel} ID: {value}
          </Tooltip>
        ),
      };
    return option;
  }, [defaultOptions, value, typeLabel]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options?.map((option) => (
        // eslint-disable-next-line react/no-unknown-property
        <option key={option.id} value={option.id} tooltip={option.name}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
