import styled from 'styled-components';

const LandingPage = styled.div`
  padding: 2rem;
  display: grid;
  grid-row-gap: 0.75rem;
  grid-column-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
`;

export default LandingPage;
