import { Button, Dropdown, Icon, InlineTooltip, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useIntegrations, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import ClientMappings from './ClientMappings';
import ConfigurationDrawer from './ConfigurationDrawer';
import InvoiceItemMappings from './InvoiceItemMappings';
import PaymentMethodMappings from './PaymentMethodMappings';
import ProjectMappings from './ProjectMappings';
import { Page } from './StyledComponents';
import TaxRateMappings from './TaxRateMappings';

const Loading = () => <Icon icon="spinner" spin />;

const OK = () => <Icon icon="check" color={colors.success} />;

const Error = ({ message = "Something's not right. Try reconnecting to your QuickBooks company." }) => (
  <Tooltip message={message}>
    <Icon icon="exclamation-circle" color={colors.danger} />
  </Tooltip>
);

const Status = ({ status, message }) => {
  switch (status) {
    case 'ready':
      return <OK />;

    case 'error':
      return <Error message={message} />;

    default:
      return <Loading />;
  }
};

const Errors = styled.ul`
  font-size: 0.75rem;
  list-style: inside;

  li {
    list-style-type: disc;
  }
`;

export default function QBOSettingsPage() {
  const { workspace } = useWorkspace();
  const api = useApi();
  const isMounted = useIsMounted();
  const toast = useToast();
  const location = useLocation();

  const [company, setCompany] = useState({ data: null, status: 'loading', message: null });
  const [configuration, setConfiguration] = useState({ data: null, status: 'loading', message: null });
  const [integration, setIntegration] = useState({ data: null, status: 'loading', message: null });
  const [clients, setClients] = useState({ data: null, status: 'loading', message: null });
  const [invoiceItems, setInvoiceItems] = useState({ data: null, status: 'loading', message: null });
  const [paymentMethods, setPaymentMethods] = useState({ data: null, status: 'loading', message: null });
  const [taxRates, setTaxRates] = useState({ data: null, status: 'loading', message: null });

  const [dialog, setDialog] = useState(null);

  const setup = new URLSearchParams(location.search).get('action') === 'setup';

  const loadCompany = useCallback(async () => {
    setCompany((state) => ({ ...state, status: 'loading' }));

    try {
      const { data } = await api.www.workspaces(workspace.id).qbo.getCompany();

      if (!isMounted.current) return;
      setCompany({ data, status: 'ready', message: null });
    } catch (error) {
      setCompany({ data: null, status: 'error', message: error.message });
    }
  }, [api, workspace.id, isMounted]);

  const loadConfiguration = useCallback(async () => {
    setConfiguration((state) => ({ ...state, status: 'loading' }));

    try {
      const { data } = await api.www.workspaces(workspace.id).qbo.getConfiguration();

      if (!isMounted.current) return;
      if (data.code === 'ready') setConfiguration({ data, status: 'ready', message: null });
      else setConfiguration({ data, status: 'error', message: 'Review the configuration settings.' });
    } catch (error) {
      setConfiguration({ data: null, status: 'error', message: error.message });
    }
  }, [api, workspace.id, isMounted]);

  const loadIntegration = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).qbo.getIntegration();

    if (!isMounted.current) return;
    setIntegration({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const loadClients = useCallback(async () => {
    setClients((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).qbo.getClientMappings();

    if (!isMounted.current) return;
    setClients({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const loadInvoiceItems = useCallback(async () => {
    setInvoiceItems((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).qbo.getInvoiceItemMappings();

    if (!isMounted.current) return;
    setInvoiceItems({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const loadPaymentMethods = useCallback(async () => {
    setPaymentMethods((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).qbo.getPaymentMethodMappings();

    if (!isMounted.current) return;
    setPaymentMethods({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const loadTaxRates = useCallback(async () => {
    setTaxRates((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).qbo.getTaxRateMappings();

    if (!isMounted.current) return;
    setTaxRates({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const closeDialog = () => {
    setDialog(null);
  };

  const handleEditConfiguration = () => {
    setDialog('configuration');
  };

  const handleCloseConfiguration = async () => {
    await loadConfiguration();
    closeDialog();
  };

  const handleEditClients = () => {
    setDialog('clients');
  };

  const handleCloseClients = async () => {
    await loadClients();
    closeDialog();
  };

  const setupConfiguration = useCallback(async () => {
    setConfiguration((state) => ({ ...state, status: 'loading' }));
    await api.www.workspaces(workspace.id).qbo.setupConfiguration();
    toast.success(`Loaded default configuration settings from QuickBooks.`);
    await loadConfiguration();
  }, [api, workspace.id, toast, loadConfiguration]);

  const setupClients = useCallback(async () => {
    setClients((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).qbo.setupClients();
    toast.success(`Loaded ${data.length} Customers from QuickBooks.`);
    await loadClients();
  }, [api, workspace.id, toast, loadClients]);

  const handleEditInvoiceItems = () => {
    setDialog('invoice_items');
  };

  const handleCloseInvoiceItems = async () => {
    await loadInvoiceItems();
    closeDialog();
  };

  const setupInvoiceItems = useCallback(async () => {
    setInvoiceItems((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).qbo.setupInvoiceItems();
    toast.success(`Loaded ${data.length} Products/Services from QuickBooks.`);
    await loadInvoiceItems();
  }, [api, workspace.id, toast, loadInvoiceItems]);

  const handleEditPaymentMethods = () => {
    setDialog('payment_methods');
  };

  const setupPaymentMethods = useCallback(async () => {
    setPaymentMethods((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).qbo.setupPaymentMethods();
    toast.success(`Loaded ${data.length} Payment Methods from QuickBooks.`);
    await loadPaymentMethods();
  }, [api, workspace.id, toast, loadPaymentMethods]);

  const handleClosePaymentMethods = async () => {
    await loadPaymentMethods();
    closeDialog();
  };

  const handleEditTaxRates = () => {
    setDialog('tax_rates');
  };

  const setupTaxRates = useCallback(async () => {
    setTaxRates((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).qbo.setupTaxRates();
    toast.success(`Loaded ${data.length} Tax Codes from QuickBooks.`);
    await loadTaxRates();
  }, [api, workspace.id, toast, loadTaxRates]);

  const handleCloseTaxRates = async () => {
    await loadTaxRates();
    closeDialog();
  };

  useEffect(() => {
    loadCompany();
  }, [loadCompany]);

  useEffect(() => {
    if (setup) setupConfiguration();
    else loadConfiguration();
  }, [loadConfiguration, setupConfiguration, setup]);

  useEffect(() => {
    loadIntegration();
  }, [loadIntegration]);

  const integrations = useIntegrations();
  const [QBOProjectsEnabled, setQBOProjectsEnabled] = useState(false);
  useEffect(() => {
    if (!integrations.qbo) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).qbo.preferences.projectsEnabled();
        setQBOProjectsEnabled(data);
      } catch {
        // Ignore
      }
    })();
  }, [workspace.id, integrations.qbo, api]);

  const [projects, setProjects] = useState({ data: null, status: 'loading', message: null });

  const loadProjects = useCallback(async () => {
    setProjects((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).qbo.getProjectMappings();

    if (!isMounted.current) return;
    setProjects({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  useEffect(() => {
    if (!QBOProjectsEnabled) return;
    loadProjects();
  }, [loadProjects, QBOProjectsEnabled]);

  const handleEditProjects = () => {
    setDialog('projects');
  };

  const handleCloseProjects = async () => {
    await loadProjects();
    closeDialog();
  };

  useEffect(() => {
    if (setup) setupClients();
    else loadClients();
  }, [loadClients, setupClients, setup]);

  useEffect(() => {
    if (setup) setupInvoiceItems();
    else loadInvoiceItems();
  }, [loadInvoiceItems, setupInvoiceItems, setup]);

  useEffect(() => {
    if (setup) setupPaymentMethods();
    else loadPaymentMethods();
  }, [loadPaymentMethods, setupPaymentMethods, setup]);

  useEffect(() => {
    if (setup) setupTaxRates();
    else loadTaxRates();
  }, [loadTaxRates, setupTaxRates, setup]);

  useEffect(() => {
    if (setup) window.history.pushState(null, null, window.location.href.split('?')[0]);
  }, [setup]);

  const { notify } = useSubscription();
  useEffect(() => {
    if (setup) {
      notify(useSubscription.keys.integrations_changed);
    }
  }, [setup, notify]);

  const disabled = company.status !== 'ready';

  return (
    <>
      <Page>
        <Table>
          <Table.Header>
            <Table.Column width="12rem">Category</Table.Column>
            <Table.Column width="8rem" align="center">
              Status
            </Table.Column>
            <Table.Column>Details</Table.Column>
            <Table.BoxActionsColumn />
          </Table.Header>

          <Table.Body>
            <Table.BoxRow>
              <Table.Cell>
                <strong>Company</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={company.status} message={company.message} />
              </Table.Cell>
              <Table.Cell>
                {company.status === 'ready' && company.data.name}

                {company.status === 'error' && integration.status === 'ready' && (
                  <a href={integration.data.authorizeUri}>Fix Connection</a>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <Button
                  disabled={integration.status !== 'ready'}
                  onClick={() => {
                    window.location = integration.data.authorizeUri;
                  }}>
                  <Icon icon="wrench" />
                  <InlineTooltip message="Reauthorize the QuickBooks connection." placement="left" />
                </Button>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Configuration</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={configuration.status} message={configuration.message} />
              </Table.Cell>
              <Table.Cell>
                {configuration.status !== 'loading' &&
                  (configuration.data?.code === 'ready' ? (
                    'Ready'
                  ) : (
                    <Errors>
                      {configuration.data?.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </Errors>
                  ))}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditConfiguration} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditConfiguration()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupConfiguration()}>
                          Load from QuickBooks
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Clients</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={clients.status} message={clients.message} />
              </Table.Cell>
              <Table.Cell>
                {clients.data && (
                  <>
                    {clients.data.filter((client) => !!client.qboCustomerId).length} of {clients.data.length} connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditClients} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditClients()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupClients()}>
                          Load from QuickBooks
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Invoice Items</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={invoiceItems.status} message={invoiceItems.message} />
              </Table.Cell>
              <Table.Cell>
                {invoiceItems.data && (
                  <>
                    {invoiceItems.data.filter((invoiceItem) => !!invoiceItem.qboItemId).length} of{' '}
                    {invoiceItems.data.length} connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditInvoiceItems} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditInvoiceItems()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupInvoiceItems()}>
                          Load from QuickBooks
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Payment Methods</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={paymentMethods.status} message={paymentMethods.message} />
              </Table.Cell>
              <Table.Cell>
                {paymentMethods.data && (
                  <>
                    {paymentMethods.data.filter((paymentMethod) => !!paymentMethod.qboPaymentMethodId).length} of{' '}
                    {paymentMethods.data.length} connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditPaymentMethods} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditPaymentMethods()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupPaymentMethods()}>
                          Load from QuickBooks
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Tax Rates</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={taxRates.status} message={taxRates.message} />
              </Table.Cell>
              <Table.Cell>
                {taxRates.data && (
                  <>
                    {taxRates.data.filter((taxRate) => !!taxRate.qboTaxCodeId).length} of {taxRates.data.length}{' '}
                    connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditTaxRates} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditTaxRates()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupTaxRates()}>
                          Load from QuickBooks
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            {QBOProjectsEnabled && (
              <Table.BoxRow>
                <Table.Cell>
                  <strong>Projects</strong>
                </Table.Cell>
                <Table.Cell>
                  <Status status={projects.status} message={projects.message} />
                </Table.Cell>
                <Table.Cell>
                  {projects.data && (
                    <>
                      {projects.data.filter((project) => !!project.qboProjectId).length} of {projects.data.length}{' '}
                      connected
                    </>
                  )}
                </Table.Cell>

                <TableBoxRowActions>
                  <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditProjects} />

                  <hr />

                  <TableBoxRowActions.Dropdown disabled={disabled}>
                    {({ setIsOpen }) => {
                      return (
                        <>
                          <Dropdown.Item onClick={() => setIsOpen(false) || handleEditProjects()}>
                            Edit Mappings
                          </Dropdown.Item>
                        </>
                      );
                    }}
                  </TableBoxRowActions.Dropdown>
                </TableBoxRowActions>
              </Table.BoxRow>
            )}
          </Table.Body>
        </Table>
      </Page>

      {dialog &&
        {
          configuration: () => <ConfigurationDrawer onClose={handleCloseConfiguration} />,
          clients: () => <ClientMappings onClose={handleCloseClients} />,
          invoice_items: () => <InvoiceItemMappings onClose={handleCloseInvoiceItems} />,
          payment_methods: () => <PaymentMethodMappings onClose={handleClosePaymentMethods} />,
          tax_rates: () => <TaxRateMappings onClose={handleCloseTaxRates} />,
          projects: () => <ProjectMappings onClose={handleCloseProjects} />,
        }[dialog]()}
    </>
  );
}
