import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    return history.listen((e) => {
      if (e?.state?.scrollToTop === false) return;

      window.scrollTo(0, 0);
    });
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);
