import { DateTime, Field } from '~/components';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { calculatePeriodEnd } from '../utils';

const DayPickerContainer = styled.div`
  input {
    width: 9rem;
  }
`;

function PeriodDateRangeField({
  periods,
  period,
  member: { activeStartDate, activeEndDate },
  start,
  isDefault,
  autoFocus,
}) {
  const end = calculatePeriodEnd(periods, period, start) || activeEndDate;

  if (period.id && !period.start && !activeStartDate && !end) {
    return 'Default';
  } else {
    const disabled = (day) => {
      day = moment(day);
      return (
        // The day is before the member's active start date
        (activeStartDate && day.isSameOrBefore(activeStartDate, 'day')) ||
        // The day is after the member's active end date
        (activeEndDate && day.isAfter(activeEndDate, 'day')) ||
        // The day matches an existing period start date
        periods.some((ap) => ap.id !== period.id && day.isSame(ap.start, 'day'))
      );
    };

    // TODO: set disabled dates once the new DayPicker supports this feature
    return (
      <>
        <DayPickerContainer>
          {isDefault ? (
            activeStartDate ? (
              <DateTime value={activeStartDate} />
            ) : (
              'Joined'
            )
          ) : (
            <Field.DayPicker autoFocus={autoFocus} name="start" clearable={false} modifiers={{ disabled }} />
          )}
        </DayPickerContainer>
        &nbsp;to&nbsp;
        {end ? <DateTime value={end} /> : 'Current'}
      </>
    );
  }
}

export default PeriodDateRangeField;
