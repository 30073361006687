import { useEffect } from 'react';

function usePolling(callback, timeout) {
  useEffect(() => {
    let interval = null;
    if (timeout != null) interval = setInterval(callback, timeout);

    return () => {
      if (interval) clearTimeout(interval);
    };
  }, [callback, timeout]);
}

export default usePolling;
