import { Currency, Icon, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';

const Rate = styled.div`
  font-size: 3.5rem;

  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.2;
    `}
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;

  .icon {
    margin-right: 0.5rem;
    color: ${colors.warning};
    font-size: 1rem;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const Message = ({ children }) => (
  <Info>
    <Icon color={colors.warn} icon="exclamation-circle" />
    <div>{children}</div>
  </Info>
);

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function EffectiveRateWidget({ client }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).clients(client.id).getEffectiveRateKPI();
    actions.ready({ data });
    // Intentionally passing the full "client" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, client, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const helpMessage = 'Effective rate is calculated by dividing client revenue by hours worked';

  return (
    <Widget stretched centered loading={!isReady}>
      <Widget.Header>
        {data && helpMessage && <Widget.Help message={helpMessage} />}
        <Widget.Title>Effective Rate</Widget.Title>
      </Widget.Header>
      <Widget.Content>
        {data && (
          <>
            <Rate blurred={data.errors.length > 0}>
              {data.errors.length === 0 ? <Currency value={data.effectiveRate} currency={client.currency} /> : 'N/A'}
            </Rate>

            {data.errors.includes('missing_project_hours') ? (
              <Message>No time has been recorded on this client's projects</Message>
            ) : data.errors.includes('missing_bill_rates') ? (
              <Message>One or more bill rates are missing</Message>
            ) : null}
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}

export default EffectiveRateWidget;
