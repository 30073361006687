import React, { useCallback, useContext, useEffect, useRef } from 'react';
import _ from 'lodash';

const TimerContext = React.createContext();

function TimerProvider({ children }) {
  const subscriptionsRef = useRef([]);
  const nowRef = useRef(new Date());

  useEffect(() => {
    const cb = () => {
      nowRef.current = new Date();
      _.forEach(subscriptionsRef.current, (sub) => sub.cb());
    };
    const interval = setInterval(cb, 500);
    return () => clearInterval(interval);
  }, []);

  const subTimer = useCallback((cb) => {
    const subscription = { id: _.uniqueId('timer_sub_'), cb };
    subscriptionsRef.current.push(subscription);
    return subscription.id;
  }, []);

  const unsubTimer = useCallback((id) => {
    subscriptionsRef.current = _.filter(subscriptionsRef.current, (sub) => sub.id !== id);
  }, []);

  return <TimerContext.Provider value={{ nowRef, subTimer, unsubTimer }}>{children}</TimerContext.Provider>;
}

function useTimer() {
  return useContext(TimerContext);
}

export { TimerContext, useTimer, TimerProvider };
