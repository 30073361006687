import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button, Buttons, CancelButton, Icon, ModalCard } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';

const AnchorButton = styled.a`
  display: flex;
  align-items: center;
  height: 2.5em;
  padding: 0.5em 1.75em;
  color: ${colors.white};
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border: solid 1px transparent;
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

function InvoicePdfDialog({ invoice, onClose }) {
  const api = useApi();
  const { workspaceKey, invoiceId } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await api.invoices({ workspaceKey, invoiceId }).pdf();
        setData(data);
      } catch {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [api, workspaceKey, invoiceId]);

  const downloadUrl = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const blob = new Blob([data], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  }, [data]);

  const downloadName = useMemo(() => {
    let name = `Invoice`;
    if (invoice?.status?.id === 'draft') {
      name = `DRAFT_${name}`;
    }
    if (invoice?.number) {
      name = `${name}_${invoice.number}`;
    }
    return `${name}.pdf`;
  }, [invoice]);

  return (
    <ModalCard title="Download Invoice PDF" onClose={onClose}>
      <ModalCard.Body>
        {!!isLoading && <p>Hold on one second while we generate your PDF...</p>}
        {!!hasError && (
          <p>
            <Icon icon="exclamation-circle" color={colors.danger} /> There was a problem getting your PDF, please try
            again later.
          </p>
        )}
        {!!downloadUrl && <p>Your invoice PDF is now ready to download.</p>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
          {!!isLoading && <Button isLoading={true} />}
          {!!downloadUrl && (
            <AnchorButton href={downloadUrl} download={downloadName}>
              Download
            </AnchorButton>
          )}
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default InvoicePdfDialog;
