import { Currency, ExportDialog, Level, RouteLink, Table, MemberSelect } from '~/components';
import moment from 'moment';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';

export default function ExpensesByCategory({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [{ isReady, data }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({ member: null });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .expensesByCategory({ projectMemberId: params.member?.id });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .expensesByCategory(
            { projectMemberId: params.member?.id },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const expenseDetail = (query = {}) =>
    `/app/${workspace.key}/reports/expenses/expense-items${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      member: params.member?.id,
      ...query,
    }).toString(true)}`;

  const url = {
    total: (expenseCategory) => expenseDetail({ expenseCategory }),
    billable: (expenseCategory) => expenseDetail({ billableType: 'billable', expenseCategory }),
    non_billable: (expenseCategory) => expenseDetail({ billableType: 'non_billable', expenseCategory }),
  };

  const currency = project.currency;

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Table>
        <Level>
          <Level.Item flex="0.3">
            <MemberSelect
              name="member"
              placeholder="All"
              materialPlaceholder="Member"
              materialAlwaysVisible
              value={params.member}
              onChange={({ target: { value } }) => handleFilter({ member: value })}
            />
          </Level.Item>

          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>
        </Level>
        <Table.Total value={records.length} label="Result" style={{ marginTop: '2.5rem' }} />
        <Table.Header sticky>
          <Table.Column>Category</Table.Column>

          <Table.Column align="right" width="5rem">
            Items
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.totalAmount}>
            Total Spent
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.budgetBillableAmount}>
            Budget Billable
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.billableAmount}>
            Billable Spent
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.budgetNonBillableAmount}>
            <p>
              Budget <nobr>Non-billable</nobr>
            </p>
          </Table.Column>

          <Table.Column align="right" width="8rem">
            Non-billable Spent
          </Table.Column>
        </Table.Header>

        <Table.Body showNoResults={false}>
          {records.map((category) => {
            return (
              <Table.Row key={category.id} data-testid="row">
                <Table.Cell>
                  <RouteLink to={url.total(category.id)}>{category.name}</RouteLink>
                </Table.Cell>

                <Table.Cell>{category.itemCount}</Table.Cell>

                <Table.Cell>
                  <Table.CellContent align="right">
                    <RouteLink to={url.total(category.id)}>
                      <Currency value={category.totalAmount} currency={currency} />
                    </RouteLink>
                  </Table.CellContent>
                </Table.Cell>

                <Table.Cell>
                  <Table.CellContent align="right">
                    <Currency value={category.budgetBillableAmount} currency={currency} />
                  </Table.CellContent>
                </Table.Cell>

                <Table.Cell>
                  <Table.CellContent align="right">
                    <RouteLink to={url.billable(category.id)}>
                      <Currency value={category.billableAmount} currency={currency} />
                    </RouteLink>
                  </Table.CellContent>
                </Table.Cell>

                <Table.Cell>
                  <Table.CellContent align="right">
                    <Currency value={category.budgetNonBillableAmount} currency={currency} />
                  </Table.CellContent>
                </Table.Cell>

                <Table.Cell>
                  <Table.CellContent align="right">
                    <RouteLink to={url.non_billable(category.id)}>
                      <Currency value={category.nonBillableAmount} currency={currency} />
                    </RouteLink>
                  </Table.CellContent>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Row style={{ fontWeight: weights.bold }}>
          <Table.Cell>
            <RouteLink to={url.total()}>Total</RouteLink>
          </Table.Cell>

          <Table.Cell>{totals.itemCount}</Table.Cell>

          <Table.Cell>
            <RouteLink to={url.total()}>
              <Currency value={totals.totalAmount} currency={currency} />
            </RouteLink>
          </Table.Cell>

          <Table.Cell>
            <Currency value={totals.budgetBillableAmount} currency={currency} />
          </Table.Cell>

          <Table.Cell>
            <RouteLink to={url.billable()}>
              <Currency value={totals.billableAmount} currency={currency} />
            </RouteLink>
          </Table.Cell>

          <Table.Cell>
            <Currency value={totals.budgetNonBillableAmount} currency={currency} />
          </Table.Cell>

          <Table.Cell>
            <RouteLink to={url.non_billable()}>
              <Currency value={totals.nonBillableAmount} currency={currency} />
            </RouteLink>
          </Table.Cell>
        </Table.Row>
      </Table>
    </div>
  );
}
