import React, { useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import expensifyIcon from '~/assets/public/expensify-icon.svg';
import { useConfirmation, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import RemoveExpensifyConfirmation from './RemoveExpensifyConfirmation';
import ExpensifySetup from './ExpensifySetup';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10.25rem;
  height: 9.375rem;
  margin: 1.25rem 0.625rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border: solid 0.125rem ${colors.white};
  border-radius: 0.3125rem;
  overflow: hidden;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 2.625rem;
  height: 2.625rem;
`;

const Name = styled.p`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const Actions = styled.div`
  display: flex;
  height: 2rem;
  background-color: ${colors.grey10};
`;

const ActionStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0;
  color: ${colors.black};
  font-size: 0.75rem;
  font-weight: ${weights.normal};
  background-color: ${colors.grey5};
  border-radius: 0;

  &:not(:first-child) {
    margin-left: 1px;
  }

  &:hover {
    color: ${colors.black};
    background-color: ${colors.grey10};
  }
`;

const ActionAnchor = styled.a`
  ${ActionStyle}
`;

const ActionButton = styled.button`
  ${ActionStyle}
`;

const AnchorLink = styled(Link)`
  ${ActionStyle}
`;

export default function ExpensifyIntegration({ integration, onChange }) {
  const confirmation = useConfirmation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { workspace } = useWorkspace();
  const [setupIntegration, setSetupIntegration] = useState(false);

  const handleRemove = () => {
    confirmation.prompt((resolve) => (
      <RemoveExpensifyConfirmation
        onClose={resolve}
        onRemove={() => {
          if (typeof onChange === 'function') {
            onChange();
          }
          resolve(true);
        }}
      />
    ));
  };

  const handleFinish = () => {
    if (typeof onChange === 'function') {
      onChange();
    }
    history.push(`/app/${workspace.key}/settings/integrations/expensify`);
  };

  return (
    <Item>
      <Description>
        <Image src={expensifyIcon} />
        <Name>Expensify</Name>
      </Description>
      {integration.isConnected ? (
        <Actions>
          <AnchorLink to={`${url}/expensify`}>Settings</AnchorLink>
          <ActionButton onClick={handleRemove}>Remove</ActionButton>
        </Actions>
      ) : (
        <Actions>
          <ActionAnchor
            href="https://help.ruddr.io/hc/en-us/articles/4414780378775-Expensify-integration-overview"
            target="_blank"
            rel="noopener noreferrer">
            Details
          </ActionAnchor>
          <ActionButton onClick={() => setSetupIntegration(true)}>Install</ActionButton>
        </Actions>
      )}
      {setupIntegration && <ExpensifySetup onClose={() => setSetupIntegration(false)} onFinish={handleFinish} />}
    </Item>
  );
}
