import { Icon } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useEffect, useState } from 'react';
import { colors } from '~/styles';

export default function SlackChannel({ project: { slackChannelId } }) {
  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).integrations().slack.getChannel(slackChannelId);
        setQuery({ status: 'ready', data });
      } catch {
        setQuery({ status: 'error', data: null });
      }
    })();
  }, [slackChannelId, workspace.id, api]);

  if (query.status === 'loading') return <Icon icon="spinner" spin color={colors.primary} />;

  if (!query.data)
    return (
      <>
        <Icon icon="exclamation-triangle" color={colors.warning} spaceRight />
        Slack channel not available
      </>
    );

  const channel = query.data;
  const link = `slack://channel?id=${channel.id}&team=${channel.teamId}`;
  return <a href={link}>#{query.data.name}</a>;
}
