import React, { useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, DeleteButton, Icon, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, devices, weights } from '~/styles';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 41.5rem;
`;

const SecurityIcon = styled(Icon)`
  color: ${({ color }) => color};
  font-size: 2rem;
`;

const Title = styled.h2`
  margin-top: 1.5rem;
  font-size: 1.75rem;
  font-weight: ${weights.light};
  line-height: 1.2;
  text-align: center;
`;

const Description = styled.p`
  margin-top: 0.5rem;
  text-align: center;
`;

const Configuration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const PhoneConfiguration = styled.div`
  margin-top: 0.5rem;
`;

const TypeIcon = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 999rem;

  @media ${devices.mobile} {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const ChangeLink = styled.span`
  margin-left: 0.5rem;

  @media ${devices.mobile} {
    margin-left: 0;
    margin-top: 0.25rem;
  }
`;

const EnableButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  margin-top: 1.5rem;
  padding: 0.5em 1.75em;
  color: ${colors.white};
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border: solid 1px transparent;
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

const DisableButton = styled(Button)`
  margin-top: 1.5rem;
`;

export default function TwoFactorAuth() {
  useDocumentTitle('Two-factor Authentication');

  const api = useApi();
  const { url } = useRouteMatch();

  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [mfa, setMfa] = useState(null);
  const [showDisable, setShowDisable] = useState(false);
  const [disabling, setDisabling] = useState(false);

  const getMfaStatus = useCallback(async () => {
    if (!workspace?.id) return;

    const { data } = await api.www.workspaces(workspace.id).mfa().get();
    setMfa(data ?? null);
    setIsLoading(false);
  }, [api, workspace?.id]);

  useEffect(() => {
    getMfaStatus();
  }, [api, getMfaStatus]);

  const disableMfa = async () => {
    setDisabling(true);
    try {
      await api.www.workspaces(workspace.id).mfa().disable();
      await getMfaStatus();
    } finally {
      setDisabling(false);
      setShowDisable(false);
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        <PageLoader />
      </Wrapper>
    );
  }
  if (mfa) {
    return (
      <Wrapper>
        <SecurityIcon color={colors.primary} icon="shield-check" />
        <Title>Two-factor authentication is enabled.</Title>
        <Description>
          Two-factor authentication adds an additional layer of security to your account by requiring more than just a
          password to sign in.
        </Description>
        <Configuration>
          <TypeIcon>
            <Icon icon={mfa.typeId === 'app' ? 'mobile' : 'message-sms'} />
          </TypeIcon>
          Configured: {mfa.typeId === 'app' ? 'Authenticator app' : 'SMS'}
          <ChangeLink>
            (<Link to={`${url}/setup`}>Change</Link>)
          </ChangeLink>
        </Configuration>
        {mfa.typeId === 'sms' && (
          <PhoneConfiguration>
            SMS delivery is currently configured for a number ending in <strong>{mfa.phoneLast4}</strong>.
          </PhoneConfiguration>
        )}
        <DisableButton onClick={() => setShowDisable(true)}>Disable Two-factor Authentication</DisableButton>
        {showDisable && (
          <ModalCard title="Disable Two-factor Authentication" onClose={() => setShowDisable(false)}>
            <ModalCard.Body>Are you sure you want to disable your two-factor authentication?</ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton onClick={() => setShowDisable(false)}>Cancel</CancelButton>
                <DeleteButton isLoading={disabling} onClick={disableMfa}>
                  Disable
                </DeleteButton>
              </Buttons>
            </ModalCard.Footer>
          </ModalCard>
        )}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <SecurityIcon color={colors.warning} icon="shield-exclamation" />
      <Title>Two-factor authentication is not enabled yet.</Title>
      <Description>
        Two-factor authentication adds an additional layer of security to your account by requiring more than just a
        password to sign in.
      </Description>
      <EnableButton to={`${url}/setup`}>Enable Two-factor Authentication</EnableButton>
    </Wrapper>
  );
}
