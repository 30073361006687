import _ from 'lodash';
import { get, post } from './agent';
import { QueryString } from '~/utils';

const ratings =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'ratings', id]).compact().join('/');

    return {
      checkEligibility: (query = {}) =>
        get(url.concat('/check-eligibility').concat(new QueryString(query).toString(true))),
      create: (body) => {
        return post(url, body);
      },
      setReviewed: () => post(url.concat('/reviewed')),
    };
  };

export default ratings;
