import React from 'react';
import expenseLockedReasons from '~/routes/app/expenses/expenseLockedReason';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

function ExpenseLockIcon({ value, ...props }) {
  const locked = value !== 'unlocked';

  if (!locked) return null;

  const tooltip = expenseLockedReasons[value];

  return (
    <Tooltip message={tooltip}>
      <Icon icon="lock" color={colors.grey40} {...props} />
    </Tooltip>
  );
}

export default ExpenseLockIcon;
