import React, { useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';
import FieldControl from './FieldControl';
import Icon from './Icon';
import { useToast } from '~/contexts';
import { getIconFromMIME } from '~/utils';

const MAX_FILE_SIZE = 10485760; // 10mb in bytes

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  border-radius: 0.3125rem;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.5rem;
  height: 10.5rem;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  overflow: hidden;
  cursor: pointer;

  &:hover ${Overlay} {
    display: flex;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black};
  border-radius: 0.3125rem;
  opacity: 0.5;
`;

const PreviewIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const Buttons = styled.div`
  display: inline;
  flex: 1;
  z-index: 2;
  font-size: 0.825rem;

  & > .button {
    background-color: ${colors.grey5};
    z-index: 2;
    height: 2rem;
    width: 2rem;
    padding: 0;
    margin-right: 0.5rem;
  }

  & > .button:last-child {
    margin-right: 0;
  }

  & > .button:hover {
    background-color: ${colors.grey10};
  }
`;

const AddButton = styled(Button)`
  display: flex;
  flex-direction: column;

  font-size: 5rem;
  color: ${colors.grey25};

  &:hover {
    color: ${colors.grey40};
  }
`;

const Input = styled.input`
  display: none;
`;

const ContainerLabel = styled.label`
  position: absolute;
  margin-top: -1.5rem;
`;

const Label = styled.div`
  z-index: 1;
  position: absolute;
  top: 1.5rem;
  left: 0.625rem;
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger50};
  &:hover {
    color: ${colors.danger100};
  }
`;

const PreviewIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 3rem;
`;

const FileNameInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

function ResumeFileInput({ resume, onChange, value, ...props }) {
  const inputRef = useRef();
  const toast = useToast();

  const [icon, setIcon] = useState(props.fileUrl ? getIconFromMIME(resume?.fileType) : 'file');

  const handleChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      try {
        if (file.size > MAX_FILE_SIZE)
          return toast.error(`The file ${file.name} is too large to upload. The file size limit is 10 MB.`);
        const reader = new FileReader();
        setIcon(getIconFromMIME(file.type));
        reader.onload = () => {
          onChange(file, reader.result);
          inputRef.current.value = null;
        };
        reader.readAsDataURL(file);
      } catch {
        toast.error('There was an error uploading your file.');
      }
    }
  };

  const handleRemove = (event) => {
    event.preventDefault();
    setIcon('file');
    onChange(null, '');
    inputRef.current.value = null;
  };

  return (
    <div style={{ width: '10rem', marginTop: '1.5rem' }}>
      <ContainerLabel>
        <Label>Resume</Label>
      </ContainerLabel>

      <Container onClick={() => inputRef.current.click()}>
        {!props.fileUrl && (
          <AddButton isAnchor onClick={() => inputRef.current.click()}>
            <PreviewIcon icon={'fa-upload'} />
            <FileNameInfo>
              <FileName>Click to upload a file</FileName>
            </FileNameInfo>
          </AddButton>
        )}
        {props.fileUrl && (
          <PreviewIconContainer>
            <PreviewIcon icon={icon} />
            <FileNameInfo>
              <FileName>{!value ? resume.name : value.name}</FileName>
            </FileNameInfo>
          </PreviewIconContainer>
        )}

        <Overlay>
          <Input ref={inputRef} type="file" disabled={!!props.fileUrl} onChange={handleChange} {...props} />
          {(props.fileUrl || value) && (
            <>
              <Buttons>
                {props.fileUrl && !value && (
                  <Button isAnchor onClick={() => window.open(props.fileUrl, '_blank')}>
                    <Icon icon="external-link-alt" />
                  </Button>
                )}
                {props.fileUrl && !value && (
                  <Button isAnchor onClick={() => window.open(props.fileUrl + '?download=1')}>
                    <Icon icon="download" />
                  </Button>
                )}
                {props.fileUrl && handleRemove && (
                  <DeleteButton isAnchor onClick={handleRemove}>
                    <Icon icon="trash" />
                  </DeleteButton>
                )}
              </Buttons>
              <Background />
            </>
          )}
        </Overlay>
      </Container>
    </div>
  );
}

function FieldResumeFileInput({ urlFieldName, ...props }) {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = (file, fileUrl) => {
    formik.setFieldValue(field.name, file);
    if (urlFieldName) {
      formik.setFieldValue(urlFieldName, fileUrl);
    }
    if (error) {
      formik.validateField(field.name);
    }
  };

  const fileUrl = urlFieldName ? formik.values[urlFieldName] : undefined;

  return (
    <FieldControl error={error}>
      <ResumeFileInput {...field} fileUrl={fileUrl} onChange={handleChange} {...props} />
    </FieldControl>
  );
}

export default ResumeFileInput;
export { FieldResumeFileInput };
