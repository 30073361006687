import { useDateTimeFormat, useHoursFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const TopPadding = {
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 10;
    };
  },
};

function getActualDataset(data) {
  if (!data.actual.dates.length) return null;

  let rollingTotal = 0;
  const points = _.reduce(
    data.actual.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.hours, 2);
      result.push({ x: value.date, y: rollingTotal });
      return result;
    },
    [],
  );

  let end = moment(points[points.length - 1]?.x);

  if (data.forecast?.dates.length > 0 && end.isBefore(moment(), 'day')) {
    points.push({
      x: moment().format(dateFormats.isoDate),
      y: points[points.length - 1]?.y ?? 0,
    });
  }

  return {
    id: 'hours',
    label: 'Actual Hours      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    tension: 0,
  };
}

function getForecastDataSet(data) {
  if (!data.forecast?.dates.length) return null;

  let rollingTotal = data.actual.total;
  const points = _.reduce(
    data.forecast.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.hours, 2);
      result.push({ x: value.date, y: rollingTotal });
      return result;
    },
    [],
  );

  let start = moment(points[0]?.x);
  if (start.isAfter(moment(), 'day')) start = moment();

  if (data.actual.dates.length > 0) {
    points.unshift({ x: moment().format(dateFormats.isoDate), y: data.actual.total });
  }

  return {
    id: 'forecast',
    label: 'Projected Hours      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary25,
    borderColor: colors.primary25,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
  };
}

export default function MyHoursProgressChart({ project, data }) {
  const hoursFormat = {
    tooltip: useHoursFormat(),
    ticks: useHoursFormat(useMemo(() => ({ minimumFractionDigits: 0, maximumFractionDigits: 0 }), [])),
  };

  const dateTimeFormat = useDateTimeFormat();

  const [version, setVersion] = useState(0);

  // Set a new version when the chart metrics change
  useEffect(() => {
    setVersion((version) => version + 1);
  }, [data, project.start, project.end]);

  const { chartData, chartConfig } = useMemo(() => {
    const chartConfig = {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },

        plugins: {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
            padding: 12,
            labels: {
              font: {
                size: 12,
              },
              usePointStyle: true,
              pointStyleWidth: 14,
              boxHeight: 10,
            },
          },

          tooltip: {
            intersect: false,
            callbacks: {
              title: ([tooltip]) => {
                if (!tooltip) return;
                return dateTimeFormat.format(tooltip.raw.x);
              },
              label: (tooltip) => {
                // Prevents showing a projected label for today's date. It should only display "Actual Hours".
                if (tooltip.raw.x === moment().format(dateFormats.isoDate) && tooltip.dataset.id === 'forecast')
                  return null;
                let label = (tooltip.dataset.label || '').trim();
                if (label) {
                  label += ': ';
                }
                label += hoursFormat.tooltip.format(tooltip.parsed.y);
                return label;
              },
            },
          },

          annotation: {
            annotations: {},
          },
        },

        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            ticks: {
              maxTicksLimit: 10,
            },
            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM DD',
                quarter: 'MMM DD',
                year: 'MMM DD',
              },
            },
          },

          y: {
            count: 10,
            ticks: {
              color: colors.primary,
              font: {
                weight: 'bold',
              },
              callback: function (value) {
                return hoursFormat.ticks.format(value);
              },
            },
            grid: { display: false },
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y',
            min: 0,
          },
        },
      },
    };

    const chartData = { datasets: _.compact([getActualDataset(data), getForecastDataSet(data)]) };

    let max = data.actual.total;

    if (data.forecast) max = max + data.forecast.total.left;

    max = Math.max(max, 100);

    chartConfig.options.scales.y.suggestedMax = max;

    // Add a vertical line on today's date
    chartConfig.options.plugins.annotation.annotations.today = {
      type: 'line',
      scaleID: 'x',
      value: moment().format(dateFormats.isoDate),
      borderWidth: 3,
      borderColor: rgba(colors.grey75, 0.5),
      label: {
        content: 'Today',
        display: true,
        position: 'start',
        padding: { top: 4, bottom: 2, left: 6, right: 6 },
        backgroundColor: colors.grey55,
        font: { weight: 'normal' },
        yAdjust: -4,
      },
    };

    let start = moment.min(
      _.compact([_.first(data.actual.dates)?.date, _.first(data.forecast?.dates)?.date, project.start])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!start.isValid()) start = moment();

    chartConfig.options.scales.x.min = start.startOf('isoWeek').format(dateFormats.isoDate);

    if (project.start) {
      chartConfig.options.plugins.annotation.annotations.start = {
        type: 'line',
        scaleID: 'x',
        value: moment(project.start).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.success, 0.5),
        label: {
          content: 'Start',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.success,
          font: { weight: 'normal' },
          xAdjust: 6,
          yAdjust: -4,
        },
      };
    }

    let end = moment.max(
      _.compact([_.last(data.actual.dates)?.date, _.last(data.forecast?.dates)?.date, project.end])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!end.isValid()) end = moment();

    chartConfig.options.scales.x.max = end.endOf('isoWeek').add(1, 'day').format(dateFormats.isoDate);

    if (project.end) {
      chartConfig.options.plugins.annotation.annotations.end = {
        type: 'line',
        scaleID: 'x',
        value: moment(project.end).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.danger, 0.5),
        label: {
          content: 'End',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          textAlign: 'center',
          xAdjust: -8,
          yAdjust: -4,
        },
      };
    }

    return { chartConfig, chartData };
  }, [data, project.start, project.end, hoursFormat.ticks, hoursFormat.tooltip, dateTimeFormat]);

  return <Line key={version} data={chartData} options={chartConfig.options} plugins={[TopPadding]} />;
}
