import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Checkbox, Drawer, Form, FormMessage } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';

const UL = styled.ul`
  li:not(:first-child) {
    margin-top: 0.75rem;
  }
`;

export default function WidgetSettings({ onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const features = useFeatures();
  const widgets = useMemo(
    () =>
      [
        { key: 'services_revenue', name: 'Services Revenue' },
        { key: 'services_gross_profit', name: 'Services Gross Profit' },
        { key: 'services_gross_margin', name: 'Services Gross Margin' },
        { key: 'billable_utilization', name: 'Billable Utilization' },
        { key: 'top_clients_by_services_revenue', name: 'Top Clients by Services Revenue' },
        { key: 'top_clients_by_billable_hours', name: 'Top Clients by Billable Hours' },
        { key: 'services_effective_bill_rate', name: 'Services Effective Bill Rate' },
        { key: 'services_realization_rate', name: 'Services Realization Rate' },
        { key: 'client_billable_hours', name: 'Client Billable Hours' },
        { key: 'client_non_billable_hours', name: 'Client Non-Billable Hours' },
        { key: 'most_healthy_projects', name: 'Most Healthy Projects', hidden: !features.projectHealth },
        { key: 'least_healthy_projects', name: 'Least Healthy Projects', hidden: !features.projectHealth },
        { key: 'gross_pipeline', name: 'Gross Pipeline', hidden: !features.pipeline },
        { key: 'weighted_pipeline', name: 'Weighted Pipeline', hidden: !features.pipeline },
      ].filter(({ hidden }) => !hidden),
    [features],
  );

  const [memberWidgets, setMemberWidgets] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => isDirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).executiveDashboard().widgets().get();
      setMemberWidgets(data);
    })();
  }, [api, workspace.id]);

  const handleSubmit = async () => {
    try {
      form.submit();
      await api.www
        .workspaces(workspace.id)
        .executiveDashboard()
        .widgets()
        .save(
          widgets.map((widget) => ({ key: widget.key, visible: memberWidgets.some(({ key }) => key === widget.key) })),
        );
      await onSaved();
      form.done();
    } catch (error) {
      form.error({ message: error.message });
    }
  };

  return (
    <Drawer
      isOpen
      title="Personalize Dashboard"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        return (
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {status && <FormMessage.Error>{message}</FormMessage.Error>}

            <Form.Section title="Dashboard Widgets">
              <UL>
                {widgets.map((widget) => {
                  const checked = memberWidgets?.some(({ key }) => key === widget.key) ?? false;

                  return (
                    <li key={widget.key}>
                      <Checkbox
                        label={widget.name}
                        checked={checked}
                        onChange={({ target: { checked } }) => {
                          setMemberWidgets((prev) => {
                            if (checked) {
                              return [...prev, widget];
                            } else {
                              return prev.filter(({ key }) => key !== widget.key);
                            }
                          });
                          setIsDirty(true);
                        }}
                      />
                    </li>
                  );
                })}
              </UL>
            </Form.Section>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} type="submit" onClick={() => handleSubmit()}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </div>
        );
      }}
    </Drawer>
  );
}
