import styled from 'styled-components';
import { Icon, ActionButton } from '~/components'; // Ensure this path is correct
import { colors, weights } from '~/styles';

const BaseContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.grey100};

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.25rem;
  }
`;

export const DetailedValidationResults = styled(BaseContainer)`
  border: 1px solid ${colors.accent}; /* Accent color for error/validation messages */
`;

export const ProgressWrapper = styled(BaseContainer)`
  border: 1px solid ${colors.primary50}; /* Primary color for progress indication */
`;

export const ValidationSummary = styled.div`
  margin-top: 1rem;
`;

export const ResultMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  li {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
`;

export const StepTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .icon {
    margin-right: 0.5rem; // Added spacing to the right of the icon
  }

  strong {
    margin-right: 0.5rem;
  }
`;

export const LoadingIcon = styled(Icon)`
  display: flex;
  font-size: 1rem;
`;

export const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

export const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
  padding: 1rem;
`;
