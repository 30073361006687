import React from 'react';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, devices, weights } from '~/styles';
import { Footer, Header } from '../components';
import boatSinking from './boat-sinking.jpg';
import buoy from './buoy.jpg';
import notFoundGraphic from './not-found.svg';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 4.375rem;

  @media ${devices.mobile} {
    width: 15rem;
  }
`;

const Graphic = styled.img`
  display: flex;
`;

const ImageContainer = styled.div`
  width: 15.625rem;
  height: 15.625rem;
  border: solid 0.625rem ${colors.white};
  border-radius: 50%;
  box-shadow: 0 0.1875rem 2rem ${rgba(colors.black, 0.16)};
  overflow: hidden;

  @media ${devices.mobile} {
    width: 11.25rem;
    height: 11.25rem;
    border-width: 0.5rem;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  margin-top: 2.5rem;
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 1.2;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 1.875rem;
  }

  strong {
    font-weight: ${weights.black};
  }
`;

const Description = styled.p`
  margin-top: 0.625rem;
  color: ${colors.grey55};
  font-size: 1.25rem;
  text-align: center;

  @media ${devices.mobile} {
    margin-top: 0.875rem;
    font-size: 1.125rem;
  }
`;

const ActionLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  margin-top: 3rem;
  padding: 0 2em;
  color: ${colors.white};
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }

  @media ${devices.mobile} {
    margin-top: 1.5rem;
  }
`;

function ErrorPage({ pageTitle = 'Error', publicSite = true, children }) {
  useDocumentTitle(pageTitle);

  const { workspace } = useWorkspace();
  const homePath = workspace ? `/app/${workspace.key}` : '/';

  const content = children || (
    <>
      <ImageContainer>
        <Image src={boatSinking} />
      </ImageContainer>
      <Title>
        <strong>Whoops.</strong> Something went wrong.
      </Title>
      <Description>An unexpected error has occured. The Ruddr team has been notified of this error.</Description>
    </>
  );

  if (!publicSite) {
    return (
      <Content>
        {content}
        <ActionLink to={homePath}>Back to Ruddr</ActionLink>
      </Content>
    );
  }
  return (
    <>
      <Header />
      <Content>
        {content}
        <ActionLink to={homePath}>Back to Ruddr</ActionLink>
      </Content>
      <Footer />
    </>
  );
}

ErrorPage.Forbidden = function Forbidden({ publicSite }) {
  return (
    <ErrorPage pageTitle="Forbidden" publicSite={publicSite}>
      <ImageContainer>
        <Image src={buoy} />
      </ImageContainer>
      <Title>
        <strong>Hold up.</strong> This area is forbidden.
      </Title>
      <Description>
        Please make sure you're logged into the correct account before trying to access this area.
      </Description>
    </ErrorPage>
  );
};

ErrorPage.NotFound = function NotFound({ publicSite }) {
  return (
    <ErrorPage pageTitle="Not Found" publicSite={publicSite}>
      <Graphic src={notFoundGraphic} alt="Not found" data-testid="not_found" />
      <Title>
        <strong>Oh no.</strong> Looks like this page is missing.
      </Title>
    </ErrorPage>
  );
};

export default ErrorPage;
