import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, HelpTooltip, Icon, Tooltip } from '~/components';
import { useChat, useWorkspace } from '~/contexts';
import settings from '~/settings.js';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import CancelSubscription from './CancelSubscription';
import EditContact from './EditContact';
import EditPaymentMethod from './EditPaymentMethod';
import EditPlan from './EditPlan';
import RemovePaymentMethod from './RemovePaymentMethod';
import UndoCancelSubscription from './UndoCancelSubscription';
import ViewInvoices from './ViewInvoices';

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem -0.75rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 1.5rem);
  margin: 1rem 0.75rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const WarningIndicator = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: ${({ isError }) => (isError ? colors.error : colors.warning)};
  font-size: 2rem;
`;

const Content = styled.div`
  padding: 1rem 1.5rem;
`;

const Title = styled.h3`
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const Label = styled.div`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  text-align: ${({ align }) => (align === 'right' ? 'right' : 'left')};
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;

const NoValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  color: ${colors.grey55};
`;

const Description = styled.div`
  margin-top: 0.75rem;
`;

const Message = styled.div`
  margin: 1rem 0.75rem;
  padding: 1rem 1.5rem;
  background-color: ${colors.grey5};
  border-radius: 0.25rem;
`;

const WarningMessage = styled(Message)`
  background-color: ${colors.warning};
`;

const ErrorMessage = styled(Message)`
  color: ${colors.white};
  background-color: ${colors.error};
`;

const Actions = styled.div`
  margin-top: auto;
  padding: 0.75rem 1.5rem;
  border-top: solid 1px ${colors.grey10};

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

const Invoices = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1.5fr 1.5fr 1fr;
  row-gap: 0.25rem;
  width: 100%;

  ${Label} {
    margin-bottom: 0.25rem;
  }
`;

const InvoiceData = styled.div`
  display: flex;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
  color: ${({ noValue }) => (noValue ? colors.grey55 : colors.black)};
`;

const TextTooltip = styled(Tooltip)`
  display: inline-flex;
  margin-left: 0.5rem;
  color: ${colors.grey25};
`;

const ExternalAnchor = styled.a`
  display: inline-flex;
  align-items: center;
`;

const ExternalIcon = styled(Icon)`
  margin-left: 0.25rem;
  font-size: 0.75rem;
`;

const currency = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function InlineTooltip({ message }) {
  return (
    <TextTooltip message={message}>
      <Icon icon="info-circle" />
    </TextTooltip>
  );
}

export default function BillingInfo({ customer, setCustomer }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const chat = useChat();
  const { workspace } = useWorkspace();

  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [editPlan, setEditPlan] = useState(params.has('changePlan'));
  const [removePaymentMethod, setRemovePaymentMethod] = useState(false);
  const [undoCancelSubscription, setUndoCancelSubscription] = useState(false);
  const [viewInvoices, setViewInvoices] = useState(false);

  const planInfo = useMemo(() => {
    let billingIntervalId = 'month';
    if (customer.subscription?.price) {
      const { recurringInterval, recurringIntervalCount } = customer.subscription.price;
      if (recurringInterval === 'month' && recurringIntervalCount === 1) {
        billingIntervalId = 'month';
      } else if (recurringInterval === 'month' && recurringIntervalCount === 3) {
        billingIntervalId = 'quarter';
      } else if (recurringInterval === 'year' && recurringIntervalCount === 1) {
        billingIntervalId = 'year';
      }
    } else if (workspace.billingIntervalId) {
      billingIntervalId = workspace.billingIntervalId;
    }

    let productKey = 'pro';
    if (customer.subscription?.price?.product) {
      productKey = customer.subscription.price.product.key;
    } else if (workspace.stripeProductKey) {
      productKey = workspace.stripeProductKey;
    }

    let name = 'Team Plan';
    if (customer.subscription?.price?.product) {
      name = customer.subscription.price.product.name;
    } else if (productKey) {
      name = _.find(customer.products, { key: productKey })?.name;
      if (!name) {
        switch (productKey) {
          case 'pro':
            name = 'Pro Plan';
            break;
          case 'team':
            name = 'Team Plan';
            break;
        }
      }
    }

    let cost = 0;
    if (customer.subscription?.price) {
      cost = customer.subscription.price.price;
    } else if (customer.products?.length > 0) {
      const product = _.find(customer.products, { key: productKey });
      if (product) {
        let recurringInterval = null;
        let recurringIntervalCount = null;

        switch (billingIntervalId) {
          case 'month':
            recurringInterval = 'month';
            recurringIntervalCount = 1;
            break;
          case 'quarter':
            recurringInterval = 'month';
            recurringIntervalCount = 3;
            break;
          case 'year':
            recurringInterval = 'year';
            recurringIntervalCount = 1;
            break;
        }

        const price = _.find(product.prices, { recurringInterval, recurringIntervalCount });
        if (price) {
          cost = price.price;
        }
      }
    }

    const info = {
      name,
      members: customer.subscription ? customer.subscription.itemQuantity : customer.memberCount,
      frequency: null,
      cost,
    };
    if (billingIntervalId === 'month') {
      info.frequency = 'Monthly';
    } else if (billingIntervalId === 'quarter') {
      info.frequency = 'Quarterly';
    } else if (billingIntervalId === 'year') {
      info.frequency = 'Annually';
    }
    return info;
  }, [customer, workspace]);

  const trialInfo = useMemo(() => {
    if (customer.subscription?.status !== 'trialing') {
      return null;
    }
    const trialEnd = moment.unix(customer.subscription.trialEnd);
    // TODO: Revist day diff rounding
    return {
      daysLeft: Math.max(Math.ceil(trialEnd.diff(moment(), 'days', true)), 0),
      expirationDate: trialEnd.format(dateFormats.longDate),
    };
  }, [customer]);

  let subscriptionMessage = null;
  if (!customer.subscription) {
    subscriptionMessage = (
      <ErrorMessage>
        A subscription is required to use Ruddr. Please ensure that your contact information, credit card, and billing
        frequency are provided below.
      </ErrorMessage>
    );
  } else if (!customer.subscription.isActive) {
    subscriptionMessage = (
      <ErrorMessage>
        Your subscription has an issue. Please ensure that your contact information, credit card, and plan selection are
        provided below.
      </ErrorMessage>
    );
  } else if (customer.subscription.isPastDue) {
    subscriptionMessage = (
      <ErrorMessage>
        Your subscription is past due. Please ensure that a valid credit card has been added to prevent getting locked
        out of your workspace.
      </ErrorMessage>
    );
  } else if (trialInfo) {
    if (trialInfo.daysLeft <= 5 && planInfo.members > 3) {
      subscriptionMessage = (
        <WarningMessage>
          Your trial is ending soon. In order to avoid an interruption of service, ensure that your contact information,
          credit card, and plan selection are provided below. You will not be charged until your trial ends.
        </WarningMessage>
      );
    } else if (planInfo.members > 3) {
      subscriptionMessage = (
        <Message>
          You are currently in a trial. Please provide your billing information below to prevent an interruption of
          service at the end of the trial.
        </Message>
      );
    } else {
      subscriptionMessage = (
        <Message>
          You are currently in a trial. Since this workspace has three or fewer active billable members, you can
          continue using Ruddr for free after the trial ends.
        </Message>
      );
    }
  }

  const paymentEnabled = !!settings.stripePubKey && !!customer.addressLine1;
  let paymentMessage = null;
  if (!settings.stripePubKey) {
    paymentMessage = 'Credit card processing is currently disabled.';
  } else if (!customer.addressLine1) {
    paymentMessage = 'Add your billing address before modifying the payment information.';
  }

  const formatCurrency = (amount) => (amount % 1 != 0 ? amount.toFixed(2) : amount);

  return (
    <>
      <Blocks>
        {subscriptionMessage}
        <Block>
          <Content>
            <Title>Plan Details</Title>
            <Label>Name</Label>
            <Value>{planInfo.name}</Value>
            {!!planInfo.frequency && (
              <>
                <Label>Plan Renewal Frequency</Label>
                <Value>{planInfo.frequency}</Value>
              </>
            )}
            {!!planInfo.cost && (
              <>
                <Label>Monthly Cost</Label>
                <Value>
                  ${planInfo.members > 3 ? formatCurrency(planInfo.cost) : 0} per member
                  {planInfo.members <= 3 && <InlineTooltip message="3 or fewer active billable members." />}
                </Value>
              </>
            )}
            <Label>Active/Billable Members</Label>
            <Value>{planInfo.members}</Value>
          </Content>
          <Actions>
            <Button isAnchor={true} onClick={() => setEditPlan(true)}>
              Change Plan
            </Button>
          </Actions>
        </Block>
        <Block>
          {(!customer.subscription?.isActive || customer.subscription?.isPastDue) && (
            <WarningIndicator isError={true}>
              <Tooltip message="There is a problem with your subscription. Ensure a valid credit card has been added.">
                <Icon icon="exclamation-triangle" />
              </Tooltip>
            </WarningIndicator>
          )}
          <Content>
            <Title>Subscription Status</Title>
            <Label>Status</Label>
            {customer.subscription ? (
              <Value>
                {customer.subscription.stripeStatus.name}
                {customer.subscription.cancelAtPeriodEnd && <> (Pending Cancellation)</>}
              </Value>
            ) : (
              <NoValue>No subscription</NoValue>
            )}
            {!!customer.subscription?.cancelAtPeriodEnd && (
              <>
                <Label>Cancellation Date</Label>
                <Value>{moment.unix(customer.subscription.cancelAt).format(dateFormats.longDate)}</Value>
              </>
            )}
            {trialInfo ? (
              <>
                <Label>Trial Days Left</Label>
                <Value>{trialInfo.daysLeft}</Value>
                <Label>Trial Expiration Date</Label>
                <Value>{trialInfo.expirationDate}</Value>
              </>
            ) : customer.subscription && !customer.subscription.cancelAtPeriodEnd ? (
              <>
                <Label>Next Billing Period</Label>
                <Value>{moment.unix(customer.subscription.currentPeriodEnd).format(dateFormats.longDate)}</Value>
              </>
            ) : null}
            <Label>Workspace Balance</Label>
            <Value>
              {currency.format(customer.balance / 100) + ' USD '}
              {customer.balance < 0 && (
                <>
                  (credit)
                  <HelpTooltip
                    style={{ marginLeft: '0.25rem' }}
                    message="Your credit balance will automatically be applied to your next invoice. Credit balances are not applied to past open invoices."
                    placement="right"
                  />
                </>
              )}
            </Value>
          </Content>
          {!!customer.subscription?.isActive && (
            <Actions>
              {customer.subscription.cancelAtPeriodEnd ? (
                <Button isAnchor={true} onClick={() => setUndoCancelSubscription(true)}>
                  Undo Cancellation
                </Button>
              ) : (
                <Button isAnchor={true} onClick={() => setCancelSubscription(true)}>
                  Cancel Subscription
                </Button>
              )}
            </Actions>
          )}
        </Block>
        <Block>
          {!customer.addressLine1 && (
            <WarningIndicator>
              <Tooltip message="Please add a valid address.">
                <Icon icon="exclamation-triangle" />
              </Tooltip>
            </WarningIndicator>
          )}
          <Content>
            <Title>Billing Contact</Title>
            <Label>Email</Label>
            {customer.email ? <Value>{customer.email}</Value> : <NoValue>No email</NoValue>}
            <Label>Name</Label>
            {customer.name ? <Value>{customer.name}</Value> : <NoValue>No name</NoValue>}
            <Label>Address</Label>
            {customer.addressLine1 ? (
              <Value>
                {customer.addressLine1}
                <br />
                {customer.addressLine2 && (
                  <>
                    {customer.addressLine2}
                    <br />
                  </>
                )}
                {customer.addressCity} {customer.addressState}, {customer.addressPostalCode}
                <br />
                {customer.country?.name || customer.addressCountry}
              </Value>
            ) : (
              <NoValue>No address</NoValue>
            )}
          </Content>
          <Actions>
            <Button isAnchor={true} onClick={() => setEditContact(true)}>
              Update Contact Info
            </Button>
          </Actions>
        </Block>
        <Block>
          {!customer.paymentMethod && !customer.subscription?.isManualPayment && (
            <WarningIndicator>
              <Tooltip
                message={
                  planInfo.members > 3
                    ? 'Please add a credit card.'
                    : 'Workspaces with three or more billable members should add a credit card to avoid a disruption of service.'
                }>
                <Icon icon="exclamation-triangle" />
              </Tooltip>
            </WarningIndicator>
          )}
          <Content>
            <Title>Payment Information</Title>
            {customer.subscription?.isManualPayment ? (
              <Description>
                This workspace is set up for manual payments. An invoice will be emailed to the billing contact at the
                end of each billing period.
              </Description>
            ) : (
              <>
                <Label>Credit Card</Label>
                {customer.paymentMethod ? (
                  <>
                    <Value>
                      {customer.paymentMethod.stripeCardBrand.name} ending in {customer.paymentMethod.cardLast4}
                    </Value>
                    <Label>Expires</Label>
                    <Value>
                      {customer.paymentMethod.cardExpMonth.toString().padStart(2, '0')} /{' '}
                      {customer.paymentMethod.cardExpYear}
                    </Value>
                  </>
                ) : (
                  <>
                    <NoValue>No card</NoValue>
                    {trialInfo && trialInfo.daysLeft > 0 ? (
                      <Description>Your credit card will not be charged until the free trial ends.</Description>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Content>
          {!customer.subscription?.isManualPayment && (
            <Actions>
              <Button isAnchor={true} disabled={!paymentEnabled} onClick={() => setEditPaymentMethod(true)}>
                <Tooltip message={paymentMessage}>{customer.paymentMethod ? 'Change' : 'Add'} Credit Card</Tooltip>
              </Button>
              {!!customer.paymentMethod && (
                <Button isAnchor={true} onClick={() => setRemovePaymentMethod(true)}>
                  Remove Credit Card
                </Button>
              )}
            </Actions>
          )}
        </Block>
        {customer.invoiceCount > 0 && (
          <Block>
            <Content>
              <Title>Invoices</Title>
              <Invoices>
                <Label>Number</Label>
                <Label>Date</Label>
                <Label>Status</Label>
                <Label align="right">Total</Label>
                <Label align="right">PDF</Label>
                {customer.latestInvoices.map((invoice) => (
                  <React.Fragment key={invoice.id}>
                    <InvoiceData noValue={!invoice.number}>{invoice.number ?? 'N/A'}</InvoiceData>
                    <InvoiceData>{moment.unix(invoice.created).format(dateFormats.compactDate)}</InvoiceData>
                    <InvoiceData>{invoice.stripeStatus.name}</InvoiceData>
                    <InvoiceData align="right">${(invoice.total / 100).toFixed(2)}</InvoiceData>
                    <InvoiceData align="right">
                      {invoice.invoicePdf && (
                        <a href={invoice.invoicePdf}>
                          <Icon icon="file-pdf" />
                        </a>
                      )}
                    </InvoiceData>
                  </React.Fragment>
                ))}
              </Invoices>
            </Content>
            {customer.invoiceCount > 5 && (
              <Actions>
                <Button isAnchor={true} onClick={() => setViewInvoices(true)}>
                  View All Invoices
                </Button>
              </Actions>
            )}
          </Block>
        )}
        <Block>
          <Content>
            <Title>Contact Us</Title>
            <Description>
              For billing questions, check out the help article below or contact our support team via chat or email.
            </Description>
          </Content>
          <Actions>
            <ExternalAnchor
              href="https://help.ruddr.io/hc/en-us/articles/1500003367762-Billing"
              target="_blank"
              rel="noreferrer">
              Help Article <ExternalIcon icon="external-link-alt" />
            </ExternalAnchor>
            <Button isAnchor={true} onClick={() => chat.open()}>
              Chat
            </Button>
            <a href="mailto:help@ruddr.io">Email</a>
          </Actions>
        </Block>
      </Blocks>
      {cancelSubscription && (
        <CancelSubscription
          onClose={() => setCancelSubscription(false)}
          onSaved={(customer) => setCustomer(customer)}
        />
      )}
      {undoCancelSubscription && (
        <UndoCancelSubscription
          onClose={() => setUndoCancelSubscription(false)}
          onSaved={(customer) => setCustomer(customer)}
        />
      )}
      {editContact && (
        <EditContact
          customer={customer}
          onClose={() => setEditContact(false)}
          onSaved={(customer) => setCustomer(customer)}
        />
      )}
      {editPaymentMethod && (
        <EditPaymentMethod onClose={() => setEditPaymentMethod(false)} onSaved={(customer) => setCustomer(customer)} />
      )}
      {removePaymentMethod && (
        <RemovePaymentMethod
          onClose={() => setRemovePaymentMethod(false)}
          onSaved={(customer) => setCustomer(customer)}
        />
      )}
      {editPlan && (
        <EditPlan
          customer={customer}
          onClose={() => setEditPlan(false)}
          onSaved={(customer) => setCustomer(customer)}
        />
      )}
      {viewInvoices && <ViewInvoices onClose={() => setViewInvoices(false)} />}
    </>
  );
}
