import { useDocumentTitle, useFeatures } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

function TimeReportsPage() {
  useDocumentTitle('Time Reports');

  const { url } = useRouteMatch();
  const features = useFeatures();

  return (
    <LandingPage>
      <NavBox to={url.concat('/time-approval-summary-by-member')}>
        <NavBox.Title>Time Approval Summary by Member</NavBox.Title>
        <NavBox.Description>
          A summary of hours by workspace member including not submitted, pending approval, approved, and rejected
          hours.
        </NavBox.Description>
      </NavBox>

      {features.timeAttachments && (
        <NavBox to={url.concat('/time-attachments')}>
          <NavBox.Title>Time Attachments</NavBox.Title>
          <NavBox.Description>Files that have been uploaded to the week view of the time screen.</NavBox.Description>
        </NavBox>
      )}

      <NavBox to={url.concat('/time-entries')}>
        <NavBox.Title>Time Entries</NavBox.Title>
        <NavBox.Description>
          Individual time entries including the date, member, project, client, role, task, and approval status.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-entries-pending-approval')}>
        <NavBox.Title>Time Entries Pending Approval</NavBox.Title>
        <NavBox.Description>
          Individual time entries in the Pending Approval status including the names of the pending approvers.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-off-by-member')}>
        <NavBox.Title>Time Off by Member</NavBox.Title>
        <NavBox.Description>A summary of hours grouped by member and time off type.</NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-client')}>
        <NavBox.Title>Time Summary by Client</NavBox.Title>
        <NavBox.Description>
          A summary of hours by client including client billable, client non-billable, internal, and time off hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-client-and-member')}>
        <NavBox.Title>Time Summary by Client and Member</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by client and member including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-client-and-project')}>
        <NavBox.Title>Time Summary by Client and Project</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by client and project including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-client-by-time-unit')}>
        <NavBox.Title>Time Summary by Client by Time Unit</NavBox.Title>
        <NavBox.Description>A summary of hours by client by time unit.</NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-member')}>
        <NavBox.Title>Time Summary by Member</NavBox.Title>
        <NavBox.Description>
          A summary of hours by workspace member including client billable, client non-billable, internal, and time off
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-member-and-client')}>
        <NavBox.Title>Time Summary by Member and Client</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by member and client including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-member-and-project')}>
        <NavBox.Title>Time Summary by Member and Project</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by member and project including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      {features.practices && (
        <NavBox to={url.concat('/time-summary-by-member-practice')}>
          <NavBox.Title>Time Summary by Member Practice</NavBox.Title>
          <NavBox.Description>
            A summary of hours by member practice including client billable, client non-billable, internal, and time off
            hours.
          </NavBox.Description>
        </NavBox>
      )}

      <NavBox to={url.concat('/time-summary-by-project')}>
        <NavBox.Title>Time Summary by Project</NavBox.Title>
        <NavBox.Description>
          A summary of hours by project including client billable, client non-billable, and internal hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-project-and-member')}>
        <NavBox.Title>Time Summary by Project and Member</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by project and member including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-project-and-role')}>
        <NavBox.Title>Time Summary by Project and Role</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by project and role including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-project-and-task')}>
        <NavBox.Title>Time Summary by Project and Task</NavBox.Title>
        <NavBox.Description>
          A summary of hours grouped by project and task including client billable, client non-billable and internal
          hours.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-project-by-time-unit')}>
        <NavBox.Title>Time Summary by Project by Time Unit</NavBox.Title>
        <NavBox.Description>A summary of hours by project by time unit.</NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/time-summary-by-project-type')}>
        <NavBox.Title>Time Summary by Project Type</NavBox.Title>
        <NavBox.Description>
          A summary of hours by project type including client billable, client non-billable, and internal hours.
        </NavBox.Description>
      </NavBox>

      {features.timesheets && (
        <NavBox to={url.concat('/unsubmitted-timesheets')}>
          <NavBox.Title>Unsubmitted Timesheets</NavBox.Title>
          <NavBox.Description>
            Unsubmitted timesheets including the timesheet period, member, tracked hours and capacity.
          </NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}

export default TimeReportsPage;
