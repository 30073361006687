import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Metrics = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  margin: 0 -1.25rem -1.25rem -1.25rem;
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};
  min-height: 6rem;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${colors.primary};
      }
    `}

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const Value = styled.div`
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

Metric.Label = Label;
Metric.Value = Value;

export { Metric, Metrics };
