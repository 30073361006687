import React, { useMemo } from 'react';
import Allocation from './Allocation';

function Allocations({
  allocations,
  rows,
  canvasRef,
  styles,
  readOnly,
  onAllocationDatesChange,
  onView,
  onEdit,
  onClone,
  onSplit,
  onSplitByDay,
  onSplitByWeek,
  onSplitByMonth,
  onRemoveOverlapping,
  onDelete,
}) {
  const virtualAllocations = useMemo(() => {
    const indexes = {
      start: rows[0].meta.index,
      end: rows[rows.length - 1].meta.index,
    };

    const virtualAllocations = allocations.filter(
      (allocation) => allocation.meta.rowIndex >= indexes.start && allocation.meta.rowIndex <= indexes.end,
    );

    return virtualAllocations;
  }, [allocations, rows]);

  return virtualAllocations.map((allocation) => {
    return (
      <Allocation
        key={allocation.id}
        allocation={allocation}
        canvas={canvasRef}
        dateWidth={styles.date.width}
        readOnly={readOnly}
        onView={onView}
        onEdit={onEdit}
        onClone={onClone}
        onSplit={onSplit}
        onSplitByDay={onSplitByDay}
        onSplitByWeek={onSplitByWeek}
        onSplitByMonth={onSplitByMonth}
        onRemoveOverlapping={onRemoveOverlapping}
        onDelete={onDelete}
        onDrop={onAllocationDatesChange}
        onResize={onAllocationDatesChange}
      />
    );
  });
}

export default React.memo(Allocations);
