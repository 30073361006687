import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import theme from '../theme';

export const Canvas = styled.div`
  /* Layout */
  flex: 1 1 0%;
  overflow-y: hidden;
  position: relative;
  display: inline-flex;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    height: 0;
  }

  scrollbar-width: none;

  /* Styles */
  background: ${theme.canvas.background};
`;

export const CanvasHeaderRow = styled.div`
  /* Layout */
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;

  /* Styles */
  border-top: 4px solid ${theme.line.strong};
`;

export const CanvasGroupRow = styled.div`
  /* Layout */
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;

  /* Styles */
  border-top: 4px solid ${theme.line.strong};

  ${({ last }) =>
    last &&
    css`
      box-shadow: 0rem 0.25rem 0rem ${theme.line.strong};
    `}
`;

export const CanvasRow = styled.div`
  /* Layout */
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;

  /* Styles */
  border-top: 1px solid ${theme.line.strong};

  ${({ last }) =>
    last &&
    css`
      box-shadow: 0rem 0.25rem 0rem ${theme.line.strong};
    `}
`;

export const BackgroundCanvasStyles = styled.div`
  /* Layout */
  z-index: 1;
  height: 100%;
  position: absolute;
  pointer-events: none;
`;

export const BackgroundCanvasColumn = styled.div`
  /* Layout */
  height: 100%;
  position: absolute;
  border-right: 1px solid ${theme.line.strong};

  ${({ today }) =>
    today &&
    css`
      background: ${theme.today.background};
    `}
`;

export const ForegroundCanvas = styled.div`
  /* Layout */
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: clip;
  height: 100%;
`;

export const CanvasOutside = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  opacity: 0.75;
  z-index: 1;
  background: repeating-linear-gradient(
    45deg,
    ${colors.grey5},
    ${colors.grey5} 5px,
    ${colors.white} 5px,
    ${colors.white} 10px
  );

  width: ${({ canvasWidth }) => `calc(100% - ${canvasWidth}px)`};
`;
