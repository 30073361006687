import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors, devices, weights } from '~/styles';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 17rem;
  margin: -0.25rem 0;
  margin-right: 2rem;
  padding-left: 0.5rem;

  @media ${devices.tablet} {
    width: 14rem;
  }

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 2.5rem;
  }
`;

const MenuLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  padding: 0.5rem 0.75rem;
  color: ${colors.black};
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.25rem;

  &:hover {
    color: ${colors.black};
    background-color: ${colors.grey5};
  }

  &.is-active {
    font-weight: ${weights.bold};
    background-color: ${colors.grey5};

    :after {
      content: '';
      position: absolute;
      top: 0.125rem;
      bottom: 0.125rem;
      left: -0.5rem;
      width: 0.25rem;
      background-color: ${colors.primary};
      border-radius: 0.25rem;
    }
  }
`;

const MenuIcon = styled(Icon)`
  color: ${colors.grey40};
  margin-right: 0.5rem;
`;

function NavItem({ name, path, icon, iconType, exact }) {
  const { url } = useRouteMatch();

  return (
    <MenuLink to={`${url}${path}`} activeClassName="is-active" exact={exact}>
      <MenuIcon type={iconType} icon={icon} />
      {name}
    </MenuLink>
  );
}

export default function Navigation() {
  return (
    <Sidebar>
      <NavItem name="My Profile" path="" icon="id-card" iconType="far" exact />
      <NavItem name="Change Password" path="/change-password" icon="key" />
      <NavItem name="Two-factor Authentication" path="/two-factor-auth" icon="shield" />
    </Sidebar>
  );
}
