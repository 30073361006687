import React from 'react';
import { useField } from '~/hooks';
import FieldControl from './FieldControl';
import TextArea from './TextArea';

const FieldTextArea = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <TextArea ref={ref} rows={8} {...field} {...props} />
    </FieldControl>
  );
});

export default FieldTextArea;
