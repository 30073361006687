import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del } from './agent';

const weekFiles =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'week-files', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      report: (query = {}, config) =>
        get(url.concat('/report').concat(new QueryString(query, { multi: true }).toString(true)), config),
      upload: (body, query = {}) => post(url.concat(new QueryString(query).toString(true)), body),
      update: (body, query = {}) => patch(url.concat(new QueryString(query).toString(true)), body),
      delete: (query = {}) => del(url.concat(new QueryString(query).toString(true))),
    };
  };

export default weekFiles;
