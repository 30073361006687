import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import OpportunityDetail from './OpportunityDetail';
import PipelineReportsPage from './PipelineReportsPage';
import PipelineOpportunityTimeSeries from './OpportunitiesByMonth';

export default function PipelineReportsArea() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <PipelineReportsPage />
      </Route>

      <Route path={path.concat('/opportunity-detail')}>
        <OpportunityDetail />
      </Route>

      <Route path={path.concat('/opportunities-by-month')}>
        <PipelineOpportunityTimeSeries />
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}
