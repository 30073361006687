import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import logo from '~/assets/logo-reversed.svg';
import { Icon } from '~/components';
import { useSession } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors } from '~/styles';
import { redirectPublic } from '~/utils';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #262c36;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const Logo = styled.img`
  display: block;
  width: 17.625rem;
`;

const Loading = styled(Icon)`
  margin-top: 2rem;
  color: ${colors.white};
  font-size: 2.5rem;
`;

export default function Home() {
  useDocumentTitle('Ruddr - A Powerful and Intuitive Professional Services Platform', false);

  const history = useHistory();
  const { isReady, isLoggedIn } = useSession();

  const isProd = useMemo(() => window.location.host.endsWith('ruddr.io'), []);

  useEffect(() => {
    if (isReady) {
      if (isLoggedIn) {
        history.push('/app');
      } else if (isProd) {
        redirectPublic();
      } else {
        history.push('/login');
      }
    }
  }, [isReady, isLoggedIn, isProd, history]);

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Logo src={logo} alt="Ruddr" />
        <Loading icon="spinner" spin={true} />
      </Wrapper>
    </>
  );
}
