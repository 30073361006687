import {
  Button,
  Buttons,
  CancelButton,
  CompanyMultiSelect,
  Confirmation,
  ContactMultiSelect,
  DeleteButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  MemberMultiSelect,
  OpportunityMultiSelect,
  TimeInput,
} from '~/components';
import { useApi, useConfirmation, useMember, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function PipelineActivityForm({ opportunity, company, activity, onClose, onSaved }) {
  const [selectedActivityType, setSelectedActivityType] = useState(
    activity?.pipelineActivityType.Type?.name || 'Activity',
  );
  const isEdit = !!activity?.id;
  const title = `${isEdit ? 'Edit' : 'New'} ${selectedActivityType}`;
  useDocumentTitle(title);

  const owner = opportunity?.owner || company?.owner;

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();

  const { member } = useMember();
  const [{ status, message }, form] = useForm();
  const timeInputRef = useRef();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this activity?</Confirmation>
    ));

    if (!confirm) return;
    await api.www.workspaces(workspace.id).pipelineActivities(activity.id).delete();

    await onSaved();
  };

  const initialValues = mergeValues(
    {
      content: '',
      subject: '',
      direction: '',
      date: moment().format('YYYY-MM-DD'),
      duration: '',
      pipelineActivityType: null,
      members: isEdit ? activity?.members.map((activityMember) => activityMember.member) ?? [] : owner ? [owner] : [],
      contacts: activity?.contacts.map((activityContact) => activityContact.contact) ?? [],
      companies: isEdit
        ? activity?.companies.map((activityCompany) => activityCompany.company) ?? []
        : company
          ? [company]
          : [opportunity.company],
      opportunities: isEdit
        ? activity?.opportunities.map((activityOpportunity) => activityOpportunity.opportunity) ?? []
        : opportunity
          ? [opportunity]
          : company.opportunities ?? [],
    },
    activity,
  );

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => activity && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, formik) {
          try {
            formik.setSubmitting(true);

            const body = emptyStringToNull({
              ..._.omit(values, ['companies', 'opportunities', 'pipelineActivityType', 'members', 'contacts']),
              companyIds: values.companies.map((company) => company.id),
              typeId: values.pipelineActivityType?.id ?? null,
              memberIds: values.members?.map((v) => v.id),
              contactIds: values.contacts?.map((v) => v.id),
              opportunityIds: values.opportunities?.map((v) => v.id),
            });

            const { data } = await api.www.workspaces(workspace.id).pipelineActivities(activity?.id).upsert(body);

            await onSaved(data);
          } catch ({ message }) {
            formik.setSubmitting(false);
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              content: Yup.string().label('This').max(5000).required(),
              subject: Yup.string().label('Subject').max(255).required(),
              pipelineActivityType: Yup.mixed().label('Activity Type').required(),
              direction: Yup.string().label('Direction').oneOf(['inbound', 'outbound']),
              date: Yup.string()
                .matches(
                  /^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/,
                  'Invalid date format. Please use the format YYYY-MM-DD.',
                )
                .nullable() // Nullable here to show the required validation message.
                .required()
                .label('Date'),
              duration: Yup.number()
                .nullable()
                .transform((value) => (isNaN(value) ? null : value))
                .label('Duration'),
            })}>
            {(formik) => {
              const isCall = formik.values.pipelineActivityType?.typeId === 'call';
              const isEmail = formik.values.pipelineActivityType?.typeId === 'email';
              const isNote = formik.values.pipelineActivityType?.typeId === 'note';

              const handleActivityTypeChange = ({ target: { value } }) => {
                const newValues = { pipelineActivityType: value };

                switch (value.typeId) {
                  case 'note':
                    newValues.direction = '';
                    newValues.duration = '';
                    break;
                  case 'other':
                    newValues.direction = '';
                    setSelectedActivityType('Activity');
                    break;
                  case 'email':
                    newValues.duration = '';
                    break;
                  case 'meeting':
                    newValues.direction = '';
                    break;
                }

                if (value.typeId !== 'other') {
                  setSelectedActivityType(value.Type.name);
                }

                formik.setValues((prevValues) => ({
                  ...prevValues,
                  ...newValues,
                }));
              };

              return (
                <Form>
                  <Form.Section title="Pipeline Activity">
                    <Form.Control>
                      <Field.PipelineActivityTypeSelect
                        onChange={handleActivityTypeChange}
                        name="pipelineActivityType"
                        placeholder="Pipeline Activity Type"
                        exclude="file"
                        initialValue={activity?.pipelineActivityType}
                        clearable={false}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        name="subject"
                        autoFocus
                        style={{ minWidth: '37rem' }}
                        placeholder="Subject"
                        maxLength={255}
                      />
                    </Form.Control>

                    {(isCall || isEmail) && (
                      <Form.Control>
                        <Field.SingleSelect name="direction" placeholder="Direction" showEmptyOption>
                          <option value="inbound">Inbound</option>
                          <option value="outbound">Outbound</option>
                        </Field.SingleSelect>
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.DayPicker
                        name="date"
                        placeholder="Date"
                        onChange={(value) => formik.setFieldValue('date', value)}
                      />
                      {!isEmail && !isNote && (
                        <TimeInput
                          ref={timeInputRef}
                          name="duration"
                          placeholder={member?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry ? '0.00' : '0:00'}
                          materialPlaceholder="Duration"
                          useDecimal={member?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry}
                          value={formik.values.duration}
                          onChange={(value) => formik.setFieldValue('duration', value)}
                        />
                      )}
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        name="content"
                        style={{ minWidth: '37rem' }}
                        rows={4}
                        placeholder={isEmail ? 'Body' : 'Description'}
                        maxLength={5000}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Form.Section title="Associations">
                    <Form.Control>
                      <Field.Control>
                        <CompanyMultiSelect
                          value={formik.values.companies}
                          placeholder="Companies"
                          name="contacts"
                          onChange={({ target: { value } }) => formik.setFieldValue('companies', value)}
                        />
                      </Field.Control>
                    </Form.Control>

                    <Form.Control>
                      <Field.Control>
                        <OpportunityMultiSelect
                          value={formik.values.opportunities}
                          placeholder="Opportunities"
                          name="opportunities"
                          onChange={({ target: { value } }) => formik.setFieldValue('opportunities', value)}
                        />
                      </Field.Control>
                    </Form.Control>

                    <Form.Control>
                      <Field.Control>
                        <MemberMultiSelect
                          value={formik.values.members}
                          placeholder="Members"
                          name="members"
                          onChange={({ target: { value } }) => formik.setFieldValue('members', value)}
                          initialValue={initialValues.members}
                        />
                      </Field.Control>
                    </Form.Control>

                    <Form.Control>
                      <Field.Control>
                        <ContactMultiSelect
                          value={formik.values.contacts}
                          placeholder="Contacts"
                          name="contacts"
                          onChange={({ target: { value } }) => formik.setFieldValue('contacts', value)}
                          initialValue={initialValues.contacts}
                        />
                      </Field.Control>
                    </Form.Control>
                  </Form.Section>

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Drawer.Actions>
                    {!activity && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
