import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { dateFormats, emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

function CreateClientInvoiceDialog({ onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const history = useHistory();

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull({
        ..._.omit(values, ['client']),
        clientId: values.client.id,
        issuedOn: moment().format(dateFormats.isoDate),
        servicesThrough: moment().format(dateFormats.isoDate),
      });

      const { data } = await api.www.workspaces(workspace.id).invoices().create(body);

      history.push(`/app/${workspace.key}/billing/invoices/${data.id}`);
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    client: null,
    currency: workspace.currency,
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ModalCard title="Create Blank Invoice" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          client: Yup.mixed().label('Client').required(),
          currency: Yup.string().label('Currency').required(),
        })}>
        {(formik) => {
          const handleClientChange = ({ target: { value } }) => {
            formik.setValues({
              ...formik.values,
              client: value,
              currency: features.multicurrency ? value.currency : workspace.currency,
            });
          };

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.ClientSelect
                    name="client"
                    placeholder="Client"
                    allowNew
                    isInternal={false}
                    permission="manageDraftInvoices"
                    clearable={false}
                    onChange={handleClientChange}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.WorkspaceCurrencySelect name="currency" clearable={false} disabled={!features.multicurrency} />
                </Form.Control>

                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default CreateClientInvoiceDialog;
