import { useModal } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import settings from '~/settings.js';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Icon from './Icon';

const duration = settings.drawerAnimationDuration;

const Overlay = styled.div`
  position: fixed;
  background-color: ${colors.black};
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: all ${duration}ms ease-in-out;
  z-index: 100;

  &.drawer-overlay {
    &-appear,
    &-enter {
      opacity: 0;

      &-active,
      &-done {
        opacity: 0.8;
      }
    }

    &-exit {
      opacity: 0;
    }
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  position: fixed;
  right: -60rem;
  top: 0;
  bottom: 0;
  width: ${({ $width }) => $width};
  min-width: ${({ $width }) => $width};
  max-width: 100%;
  transition: all ${duration}ms ease-in-out;
  z-index: 110;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    width: 1rem;
    background-image: linear-gradient(to left, ${rgba(colors.black, 0.6)}, ${rgba(colors.black, 0)});
  }

  &.drawer {
    &-appear,
    &-enter {
      right: -60rem;

      &-active,
      &-done {
        right: 0;
      }
    }

    &-exit {
      right: -70rem;
    }
  }
`;

const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  /* https://www.w3.org/TR/css-transforms-1/#containing-block-for-all-descendants */
  transform: translateZ(0);
`;

const Close = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
  color: ${colors.primary};
  font-size: 1.125rem;
  border-radius: 0;
  background-color: ${colors.grey75};

  &:hover {
    color: ${colors.primary};
    background-color: ${colors.grey55};
  }
`;

const Header = styled.header`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8.5rem;
  padding: 0 4rem;
  background-color: ${colors.black};
`;

const Byline = styled.h6`
  color: ${colors.primary};
  font-size: 0.875rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Title = styled.h5`
  color: ${colors.white};
  font-size: 2.5rem;
  font-weight: ${weights.light};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 4rem;
`;

const Actions = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 4rem;
  background-color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;

const DrawerActions = styled(Actions)`
  margin: auto -4rem -2.5rem -4rem;
`;

const Drawer = React.forwardRef(
  (
    {
      children,
      title,
      byline,
      actions,
      isOpen: isOpenProp,
      closeOnClickAway = true,
      width = '54rem',
      onOpen,
      onClose,
      onBeforeClose,
    },
    ref,
  ) => {
    const { addInstance, removeInstance } = useModal();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const [state, setState] = useState();
    const isMounted = useIsMounted();

    useEffect(() => {
      if (isOpen) {
        addInstance();
      }
      return () => {
        if (isOpen) {
          removeInstance();
        }
      };
    }, [isOpen, addInstance, removeInstance]);

    useEffect(() => {
      setIsOpen(isOpenProp);
    }, [isOpenProp]);

    const handleEntered = () => {
      onOpen && onOpen(state);
    };

    const handleExited = () => {
      onClose && onClose(state);
    };

    const close = (state) => {
      if (isMounted.current) {
        setIsOpen(false);
        setState(state);
      }
    };

    const handleClose = () => {
      if (onBeforeClose) onBeforeClose({ setIsOpen });
      else close();
    };

    return ReactDOM.createPortal(
      <>
        <CSSTransition in={isOpen} appear unmountOnExit timeout={duration} classNames="drawer-overlay">
          <Overlay onClick={closeOnClickAway ? handleClose : undefined} />
        </CSSTransition>
        <CSSTransition
          in={isOpen}
          appear
          unmountOnExit
          timeout={duration}
          classNames="drawer"
          onEntered={handleEntered}
          onExited={handleExited}>
          <Container $width={width}>
            <Scrollable ref={ref}>
              <Close>
                <CloseButton aria-label="close" onClick={handleClose}>
                  <Icon icon="times" />
                </CloseButton>
              </Close>
              {title && (
                <Header>
                  {byline && <Byline>{byline}</Byline>}
                  <Title>{title}</Title>
                </Header>
              )}
              <Content>{_.isFunction(children) ? children(close) : children}</Content>
              {actions && <Actions>{_.isFunction(actions) ? actions(close) : actions}</Actions>}
            </Scrollable>
          </Container>
        </CSSTransition>
      </>,
      document.body,
    );
  },
);

Drawer.Actions = DrawerActions;

export default Drawer;
