import React, { useCallback, useMemo } from 'react';
import { useSelectableCells, useVirtualCells } from '../../components/schedule';
import { Cell, RowCell } from '../../components/schedule/cells';
import AllocatedHoursCell from './AllocatedHoursCell';

function Cells({ bodyRef, start, end, unit, styles, rows, cells, onSelectEnd }) {
  const virtualCells = useVirtualCells({
    rows,
    cells,
    parentRef: bodyRef,
    start,
    end,
    unit,
    dateWidth: styles.date.width,
    paddingStart: styles.sidebar.width,
  });

  const cellsData = useMemo(() => {
    return virtualCells.map((cell) => {
      return {
        ...cell,

        get selectable() {
          return ['group', 'row'].includes(this.type) && cell.manage;
        },
      };
    });
  }, [virtualCells]);

  const { selection, onSelectStart, onSelect } = useSelectableCells({
    cells: virtualCells,
    onSelectEnd: useCallback(
      (cells) => {
        onSelectEnd(cells);
      },
      [onSelectEnd],
    ),
  });

  return (
    <>
      {cellsData.map((cell) => {
        const selected = selection?.cells.some((c) => c?.key === cell?.key);
        const selecting = selection?.rowIndex === cell.meta.rowIndex;

        const handlePointerDown = (event) => {
          if (event.button !== 0) return;
          onSelectStart(cell);
        };
        const handlePointerEnter = () => onSelect(cell);

        return (
          <Cell
            key={cell.key}
            style={cell.meta.style}
            selected={selected}
            selecting={selecting}
            data-testid={`cell_${cell.meta.row}`}
            selectable={cell.selectable}
            onPointerDown={cell.selectable ? handlePointerDown : undefined}
            onPointerEnter={selecting ? handlePointerEnter : undefined}>
            {cell.type === 'row' ? <RowCell /> : <AllocatedHoursCell cell={cell}>{cell.allocated}</AllocatedHoursCell>}
          </Cell>
        );
      })}
    </>
  );
}

export default React.memo(Cells);
