import React from 'react';
import styled from 'styled-components';
import { Currency } from '~/components/index.js';

const Small = styled.small`
  display: block;
`;

export default function MultiCurrency({ value }) {
  if (!value.length) return null;
  const [primary, secondary] = value;
  return (
    <div>
      <Currency value={primary.value} currency={primary.currency} />
      {!!secondary.value && secondary.currency !== primary.currency && (
        <Small>
          <Currency value={secondary.value} currency={secondary.currency} />
        </Small>
      )}
    </div>
  );
}
