import { useField, useFormikContext } from 'formik';
import React from 'react';
import FieldControl from './FieldControl';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

const FieldNumber = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = (value) => {
    formik.setFieldValue(field.name, value);
    if (error) formik.validateField(field.name);
  };

  return (
    <FieldControl error={error}>
      <TextInput ref={ref} component={NumberInput} {...field} onChange={handleChange} {...props} />
    </FieldControl>
  );
});

export default FieldNumber;
