import { Button, Buttons, FormMessage, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger};
  border-color: ${colors.danger};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.danger};
    border-color: ${colors.danger};
  }
`;

function WeekTimeEntryDeleteConfirmation({ date, member, data, onClose, onDelete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [formState, form] = useForm();
  const toast = useToast();

  async function handleDelete() {
    try {
      form.submit();

      const entryData = {
        date,
        typeId: data.typeId,
        timeOffTypeId: data.timeOffType?.id,
        projectId: data.project?.id,
        projectRoleId: data.role?.id,
        projectTaskId: data.task?.id,
      };
      if (member) {
        entryData.memberId = member.id;
      }

      await api.www.workspaces(workspace.id).timeEntries().deleteWeek(entryData);

      if (onDelete) await onDelete(data);

      toast.success('Time entries deleted.');
    } catch ({ message }) {
      return form.error({ message });
    }
  }

  return (
    <ModalCard title="Delete time entries?" onClose={onClose}>
      <ModalCard.Body>
        <p>
          Are you sure you want to delete time entries for the week of{' '}
          <strong>{moment(date).startOf('isoWeek').format('M/D')}</strong> for
          <br />
          <br />
        </p>
        {data.typeId === 'project_time' ? (
          <>
            <p>
              <strong>
                {data.project.client.name} / {data.project.name}
              </strong>
            </p>
            {data.role && <p>{data.role.name}</p>}
            {data.task && <p>{data.task.name}</p>}
          </>
        ) : (
          <p>
            <strong>Time Off</strong>
          </p>
        )}
        {formState.status && <FormMessage.Error spaceTop>{formState.message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton isOutline disabled={formState.isSubmitting} onClick={handleDelete}>
            Delete
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default WeekTimeEntryDeleteConfirmation;
