import React from 'react';
import Tag from './Tag';

export default function MemberCostMethodTag({ value, ...props }) {
  if (!value) return null;
  return (
    <Tag label="Member Labor Cost Type" {...props}>
      {{ fixed: 'Fixed Cost', hourly: 'Hourly Cost' }[value]}
    </Tag>
  );
}
