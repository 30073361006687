import { useWorkspace } from '~/contexts';
import React from 'react';
import RouteLink from './RouteLink';
import { CompanyPopover } from '~/components';

function CompanyLink({ company, children, ...props }) {
  const { workspace } = useWorkspace();
  const url = `/app/${workspace.key}/pipeline/companies/${company.id}/overview`;

  return (
    <RouteLink to={url} {...props}>
      <CompanyPopover companyId={company.id} placement="right">
        {children || company.name}
      </CompanyPopover>
    </RouteLink>
  );
}

export default CompanyLink;
