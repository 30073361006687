import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Field from './Field';
import Icon from './Icon';

const Box = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: ${({ clearMargin }) => {
    return clearMargin ? '0;' : '0.5rem;';
  }};
  color: ${colors.primary};
  font-size: 0.875rem;
  border: solid 1px ${colors.grey25};
  border-radius: 0.25rem;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  color: ${colors.grey75};
  cursor: pointer;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
    color: ${colors.grey40};

    ${Box} {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }

  &:focus-within {
    ${Box} {
      border-color: ${colors.primary};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  display: inline-block;
  outline: none;
  opacity: 0;
  user-select: none;
  cursor: pointer;
`;

const Text = styled.span`
  padding-top: 0.0625rem;
  color: ${colors.black};
`;
const SubText = styled.span`
  color: ${colors.grey25};
  display: block;
  font-size: 0.75rem;
`;

const Checkbox = React.forwardRef(({ label, subText, disabled, partial, ...props }, ref) => {
  return (
    <Label className={classNames({ disabled })}>
      <Input {...props} ref={ref} type="checkbox" disabled={disabled} />
      <Box clearMargin={props.clearMargin}>
        {props.checked ? partial ? <Icon icon="minus" /> : <Icon icon="check" /> : null}
      </Box>
      <Text>
        {label}
        {subText && <SubText>{subText}</SubText>}
      </Text>
    </Label>
  );
});

function FieldCheckbox(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <Field.Control error={error}>
      <Checkbox checked={field.value} {...field} {...props} />
    </Field.Control>
  );
}

export default Checkbox;
export { FieldCheckbox };
