import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import Confirmation from './Confirmation';

function PromptNavigation({ when }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetLocation, setTargetLocation] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const closeModal = () => {
    setShowConfirmation(false);
  };

  const handleBlock = (targetLocation) => {
    if (!confirmed && location.pathname !== targetLocation.pathname) {
      setShowConfirmation(true);
      setTargetLocation(targetLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setShowConfirmation(false);
    setConfirmed(true);
  };

  useEffect(() => {
    if (confirmed && targetLocation) {
      history.push(targetLocation);
    }
  }, [confirmed, targetLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleBlock} />

      {showConfirmation && (
        <Confirmation
          resolve={(confirm) => {
            if (confirm) handleConfirmNavigationClick();
            else closeModal();
          }}>
          Unsaved changes will be lost. Do you want to continue?
        </Confirmation>
      )}
    </>
  );
}

export default PromptNavigation;
