import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, weights } from '~/styles';

const getProps = (props) => {
  const { isPartiallyCurrent } = props;
  return {
    className: isPartiallyCurrent ? 'is-active' : undefined,
  };
};

const Menu = styled.ul`
  width: 16rem;
  padding: 1.5rem;
  background-color: ${colors.grey5};
  border-radius: 0.625rem;

  li:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  a {
    display: block;
    padding: 0.5625em 1.125em;
    color: ${colors.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    border-radius: 999rem;

    &:hover {
      background-color: ${colors.grey10};
    }

    &.is-active {
      color: ${colors.white};
      font-weight: ${weights.black};
      background-color: ${colors.primary};
    }
  }
`;

function SideMenu({ children, ...props }) {
  return <Menu {...props}>{children}</Menu>;
}

function SideMenuLink(props) {
  return (
    <li>
      <Link {...props} getProps={getProps} />
    </li>
  );
}

SideMenu.Link = SideMenuLink;

export default SideMenu;
