import React from 'react';
import Tag from './Tag';

export default function OpportunityStageTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Stage" {...props}>
      {{ open: 'Open', won: 'Won', lost: 'Lost' }[value]}
    </Tag>
  );
}
