import { css } from 'styled-components';
import colors from '../colors';

export default css`
  html {
    min-width: 300px;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;

    &.no-scroll {
      overflow: hidden;
    }
  }

  body {
    color: ${colors.black};
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4;
    background-color: ${colors.white};
    -ms-overflow-style: scrollbar;
    overflow-y: scroll;
    overscroll-behavior-y: none;
  }

  html,
  body,
  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: 'Roboto', sans-serif;
  }

  hr {
    height: 1px;
    border: none;
    border-top: solid 1px ${colors.grey10};
  }

  code,
  pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: monospace;
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${colors.accent};
    }
  }
`;
