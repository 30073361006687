import { Duration } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import RejectionMessage from '~/components/RejectionMessage';
import moment from 'moment';
import React from 'react';
import TaskDetails from '../edit-time-entry/TaskDetails';
import { RoleDisplay } from '../fields/RoleSelect';
import { TaskDisplay } from '../fields/TaskSelect';

export default function GeneralTab({ entry, isOwner, rejectedHistoryItem }) {
  return (
    <ReadForm>
      <ReadForm.Section title="Entry Details">
        {entry?.statusId === 'rejected' && rejectedHistoryItem && (
          <RejectionMessage historyItem={rejectedHistoryItem}></RejectionMessage>
        )}

        {!isOwner && (
          <ReadForm.Control>
            <ReadTextbox label="Member" value={entry?.member.name} />
          </ReadForm.Control>
        )}

        <ReadForm.Control>
          <ReadTextbox label="Date" value={entry?.date ? moment(entry.date).format('dddd, MMMM D, YYYY') : 'N/A'} />
        </ReadForm.Control>

        <ReadForm.Control>
          <ReadTextbox
            label="Time"
            value={
              <>
                <Duration
                  minutes={entry?.minutes}
                  timerStartedAt={entry?.timerStartedAt}
                  showSeconds={!!entry?.timerStartedAt}
                  trim={!entry?.timerStartedAt}
                />{' '}
                hrs
              </>
            }
          />

          <ReadTextbox label="Type" value={entry?.type && entry.type.name} />
        </ReadForm.Control>
        {entry?.type?.id === 'time_off' && entry?.timeOffType && (
          <ReadForm.Control>
            <ReadTextbox label="Time Off Type" value={entry.timeOffType.name} />
          </ReadForm.Control>
        )}
        {entry?.type?.id === 'project_time' && (
          <>
            {entry.project && entry.project.client && (
              <ReadForm.Control>
                <ReadTextbox label="Project" value={`${entry.project.client.name} / ${entry.project.name}`} />
              </ReadForm.Control>
            )}
            {entry.role && (
              <ReadForm.Control>
                <ReadTextbox label="Role" value={<RoleDisplay role={entry.role} />} />
              </ReadForm.Control>
            )}
            {entry.task && (
              <ReadForm.Control>
                <ReadTextbox label="Task" value={<TaskDisplay task={entry.task} />} />
              </ReadForm.Control>
            )}
          </>
        )}

        <ReadForm.Control>
          <ReadTextbox label="Notes" value={entry.notes} textarea />
        </ReadForm.Control>
      </ReadForm.Section>
      {entry?.type?.id === 'project_time' && entry?.project && entry?.task && (
        <TaskDetails
          taskId={entry.task.id}
          memberId={entry?.memberId}
          projectRoleId={entry?.role?.id}
          entryRoleWorkedMinutes={entry.minutes}
          entryMinutes={entry.minutes}
          entryWorkedMinutes={entry.minutes}
        />
      )}
    </ReadForm>
  );
}
