import React from 'react';
import Tag from './Tag';

export default function InvoicedTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Invoiced" {...props}>
      {
        {
          yes: 'Yes',
          no: 'No',
        }[value]
      }
    </Tag>
  );
}
