import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { useFeatures } from '~/hooks';

const WeekFilesContext = React.createContext();

function WeekFilesProvider({ date, member, children }) {
  const api = useApi();
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const [files, setFiles] = useState([]);

  const isEnabled = features.timeAttachments === true;
  const weekStart = useMemo(() => moment(date).startOf('isoWeek').format('YYYY-MM-DD'), [date]);

  const fetchData = useCallback(async () => {
    if (!isEnabled) {
      return;
    }
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .weekFiles()
        .get({ date: weekStart, memberId: member ? member.id : undefined });

      setFiles(data);
    } catch {
      // Do nothing
    }
  }, [api, member, workspace, weekStart, isEnabled]);

  useEffect(() => {
    fetchData();
    return fetchData.cancel;
  }, [fetchData]);

  const providerValue = {
    isEnabled,
    files,
    fetchData,
  };
  return <WeekFilesContext.Provider value={providerValue}>{children}</WeekFilesContext.Provider>;
}

function useWeekFiles() {
  return useContext(WeekFilesContext);
}

export { useWeekFiles, WeekFilesContext, WeekFilesProvider };
