import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  Inline,
  Tab,
  Tabs,
  Tooltip,
} from '~/components';
import ReadCheckbox from '~/components/read-only/ReadCheckbox';
import RejectionMessage from '~/components/RejectionMessage';
import { useApi, useSubscription, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { capitalize, dateFormats, emptyStringToNull, getFileBytes, mergeValues } from '~/utils';
import * as Yup from 'yup';
import ReadTextbox from '../../../../components/read-only/ReadTextbox';
import DeleteExpenseItemConfirmation from '../DeleteExpenseItemConfirmation';
import ExpenseItemHistory from './ExpenseItemHistory';
import ReceiptFiles from './ReceiptFiles';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

function ExpenseItemForm({
  expenseItem,
  expenseReport,
  status,
  message,
  member,
  onSubmit,
  onDelete,
  closeDrawer,
  overrideShowMember,
  formRef,
  isSubmitting,
  drawerLoaded,
  rejectedHistoryItem,
  ...props
}) {
  const isNew = !expenseItem || !expenseItem.id;

  useDocumentTitle(isNew ? 'New Expense Item' : `Edit Expense Item`);

  const [tabIndex, setTabIndex] = useState(props.selectedTabIndex || 0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [receiptsAdded, setReceiptsAdded] = useState([]);
  const [receiptsRemoved, setReceiptsRemoved] = useState([]);
  const [billableProject, setBillableProject] = useState(expenseItem?.project?.isBillable);
  const { workspace } = useWorkspace();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const firstFieldRef = useRef();

  const existingReceipts = _.map(expenseItem.receipts, (r) => ({
    id: r.id,
    contentType: r.contentType,
    fileName: r.fileName,
    expenseItemId: expenseItem.id,
    workspaceId: expenseItem.workspaceId,
    status: 'download',
  }));

  const visibleReceipts = _.filter(existingReceipts, (r) => {
    return !receiptsRemoved.includes(r.id);
  });

  const hasHistory = useMemo(() => {
    const history = expenseItem?.history;
    return history && history.length > 0;
  }, [expenseItem]);

  useEffect(() => {
    if (isNew && drawerLoaded && firstFieldRef.current) {
      firstFieldRef.current.focus();
    }
  }, [isNew, drawerLoaded]);

  const initialValues = mergeValues(
    {
      isBillable: false,
      isReimbursable: false,
      notes: '',
      date: moment().format(dateFormats.isoDate),
      vendorName: '',
      expenseAmount: '',
      expenseCategory: null,
      attendees: '',
      unitCount: '',
      unitAmount: '',
      project: null,
      expenseReportId: null,
      statusId: 'not_submitted',
    },
    expenseItem,
  );

  async function handleReceiptRemoved(receiptId) {
    const i = _.findIndex(receiptsAdded, (r) => r.id === receiptId);
    if (i > -1) {
      receiptsAdded.splice(i, 1);
      setReceiptsAdded(receiptsAdded);
    }
    if (receiptsRemoved.indexOf(receiptId) === -1) {
      receiptsRemoved.push(receiptId);
      setReceiptsRemoved(receiptsRemoved);
    }
  }

  async function handleReceiptsAdded(newFiles) {
    for (let i = 0; i < newFiles.length; i++) {
      const r = newFiles[i];
      if (r.status === 'upload') {
        const bytes = r !== null ? await getFileBytes(r) : null;
        r.image = bytes;
      }
      receiptsAdded.push(r);
    }

    setReceiptsAdded(receiptsAdded);
  }

  async function submitExpenseItem(values) {
    values.receiptsAdded = receiptsAdded;
    values.receiptsRemoved = receiptsRemoved;
    return await onSubmit(values, closeDrawer);
  }

  const handleCloseClick = () => dirtyCheck(() => closeDrawer());

  const validationSchema = Yup.object()
    .shape({
      expenseCategory: Yup.object().label('Expense Category').nullable().required(),
      vendorName: Yup.string().label('Vendor Name').max(255),
      isBillable: Yup.bool().label().required(),
      isReimbursable: Yup.bool(),
      date: Yup.string()
        .matches(/^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/)
        .typeError('Please provide a valid date in "mm/dd/yyyy" format.')
        .nullable()
        .required()
        .label('Expense Date')
        .test({
          message: `You cannot create an expense item with a date that is this far in the past.`,
          test: (value) => {
            if (
              member?.permissions.manageTimeAndExpenses ||
              expenseItem?.permissions?.manageMemberExpenses ||
              !workspace.lockTimeAndExpenses
            )
              return true;
            const age = moment.duration(moment().diff(moment(value), 'days'), 'days').asDays();
            return age <= workspace.lockTimeAndExpensesAfterDays;
          },
        })
        .test({
          message: `You cannot create an expense item with a date that is this far in the past.`,
          test: (value) => {
            if (
              member?.permissions.manageTimeAndExpenses ||
              expenseItem?.permissions?.manageMemberExpenses ||
              !workspace.lockTimeAndExpensesAfterWeekEnds
            )
              return true;

            const age = moment.duration(moment().diff(moment(value).endOf('isoWeek'), 'days'), 'days').asDays();
            return age < workspace.lockTimeAndExpensesAfterWeekEndsDays;
          },
        })
        .test({
          message: `You cannot create an expense item with a date that is this far in the past.`,
          test: (value) => {
            if (
              member?.permissions.manageTimeAndExpenses ||
              expenseItem?.permissions?.manageMemberExpenses ||
              !workspace.lockTimeAndExpensesAfterMonthEnds
            )
              return true;

            return moment(value)
              .startOf('month')
              .isAfter(
                moment()
                  .subtract(moment().date() > workspace.lockTimeAndExpensesAfterMonthEndsDays ? 1 : 2, 'months')
                  .startOf('month'),
              );
          },
        }),
      expenseAmount: Yup.number().label('Expense Amount').min(-99999999999).max(99999999999).nullable().required(),
      notes: Yup.string()
        .label('Notes')
        .max(5000)
        .when('expenseCategory', (expenseCategory, schema) => {
          return expenseCategory?.requireNotes
            ? schema.required(`'Notes' is required with this Expense Category`)
            : schema;
        }),
      unitCount: Yup.number()
        .label('Units')
        .min(0)
        .max(999999.99)
        .nullable()
        .when('expenseCategory', (expenseCategory, schema) => {
          return expenseCategory?.unitName != null
            ? schema.required(
                `'Number of ${capitalize(expenseCategory?.unitName)}(s)' is required with this Expense Category`,
              )
            : schema;
        }),
      unitAmount: Yup.number()
        .label('Unit Amount')
        .min(0)
        .max(99999999999.9999)
        .nullable()
        .when('expenseCategory', (expenseCategory, schema) => {
          return expenseCategory?.unitName != null
            ? schema.required(
                `'Cost per ${capitalize(expenseCategory?.unitName)}' is required with this Expense Category`,
              )
            : schema;
        }),
      attendees: Yup.string()
        .label('Attendees')
        .max(5000)
        .when('expenseCategory', (expenseCategory, schema) => {
          return expenseCategory?.requireAttendees
            ? schema.required(`'Attendees' is required with this Expense Category`)
            : schema;
        }),
      project: workspace.requireExpenseProject
        ? Yup.object()
            .label('Project')
            .required('This workspace requires expenses to be associated with a project.')
            .nullable()
        : Yup.object().label('Project').nullable(),
    })
    .test('requireReceipt', function (values) {
      if (!values.expenseCategory || !values.expenseCategory.requireReceipt) return true;

      const receipts = expenseItem?.receipts || [];
      const receiptsCount = receipts.filter((r) => !receiptsRemoved.includes(r.id)).length + receiptsAdded.length;

      if (receiptsCount > 0) return true;

      return this.createError({
        path: 'requireReceipt',
        message: 'At least one receipt must be uploaded for this expense category.',
      });
    });

  const tabErrors = {
    one: [
      'category',
      'vendorName',
      'isBillable',
      'isReimbursable',
      'unitAmount',
      'unitCount',
      'date',
      'projectId',
      'notes',
      'expenseAmount',
      'attendees',
    ],
  };

  const isOwner = expenseItem?.memberId === workspace.member.id;
  const manageMemberExpenses = expenseItem?.permissions?.manageMemberExpenses;
  const showMemberBox = !isOwner || overrideShowMember;
  const assignedMemberId = expenseItem?.memberId || expenseReport?.memberId;
  const showStatusSelect = !isNew && manageMemberExpenses;
  const projectRights = isNew || isOwner || manageMemberExpenses ? 'write' : 'read';
  const invoiced = !isNew && !!expenseItem?.invoiceId;
  const clientApproval = !isNew && !!expenseItem?.clientApprovalId;

  const projectSelectDisabledTooltip = invoiced
    ? 'This expense item is associated with an invoice and its project cannot be changed.'
    : clientApproval
      ? 'This expense item is associated with a client approval and its project cannot be changed.'
      : projectRights !== 'write'
        ? `Insufficient permissions to edit this expense item's project.`
        : null;

  const billableCheckboxDisabledTooltip = invoiced
    ? 'This expense item is associated with an invoice and this setting cannot be changed.'
    : clientApproval
      ? 'This expense item is associated with a client approval and this setting cannot be changed.'
      : projectRights !== 'write'
        ? `Insufficient permissions to edit this expense item's setting.`
        : null;

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submitExpenseItem}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}>
      {(formik) => {
        const isUnitCategory = formik.values.expenseCategory?.unitName != null;
        const isAttendeesRequired = formik.values.expenseCategory?.requireAttendees;

        if (isUnitCategory && !formik.values.unitAmount) {
          formik.values.unitAmount = formik.values.expenseCategory.unitAmount;
          formik.values.expenseAmount = +(
            Math.round(formik.values.unitCount * formik.values.unitAmount + 'e+2') + 'e-2'
          );
        }

        const getDayProps = (day) => {
          const { project } = formik.values;

          if (
            project &&
            workspace.timeAndExpensesWithinProjectDates &&
            ((project?.start && !moment(day).isSameOrAfter(project?.start, 'day')) ||
              (project?.end && !moment(day).isSameOrBefore(project?.end, 'day')))
          ) {
            return {
              disabled: true,
              tooltip: `This day is outside of the project's start and end dates.`,
            };
          }
        };

        return (
          <Form>
            <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab>
                Expense
                {tabErrors.one.some((key) => !!formik.errors[key]) && (
                  <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                )}
              </Tab>
              <Tab>
                <Inline>
                  Receipts
                  {!!formik.errors.requireReceipt && (
                    <Tooltip message={formik.errors.requireReceipt}>
                      <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                    </Tooltip>
                  )}
                </Inline>
              </Tab>
              {!isNew && hasHistory && <Tab>History</Tab>}
            </Tabs>
            <Content>
              {status && <FormMessage.Error>{message}</FormMessage.Error>}
              {
                [
                  <>
                    <Form.Section title="Details">
                      {expenseItem?.statusId === 'rejected' && rejectedHistoryItem && (
                        <RejectionMessage historyItem={rejectedHistoryItem}></RejectionMessage>
                      )}
                      {showMemberBox && (
                        <Form.Control>
                          <ReadTextbox label="Member" value={expenseItem?.member?.name || expenseReport?.member.name} />
                        </Form.Control>
                      )}
                      {showStatusSelect && (
                        <Form.Control>
                          <Field.SingleSelect name="statusId" placeholder="Approval Status">
                            <option value="not_submitted">Not Submitted</option>
                            <option value="pending_approval">Pending Approval</option>
                            <option value="rejected">Rejected</option>
                            <option value="approved">Approved</option>
                          </Field.SingleSelect>
                        </Form.Control>
                      )}
                      <Form.Control>
                        <Field.ExpenseCategorySelect
                          ref={firstFieldRef}
                          name="expenseCategory"
                          placeholder="Expense Category"
                          initialValue={expenseItem && expenseItem.expenseCategoryId}
                        />
                      </Form.Control>
                      {isUnitCategory && (
                        <Form.Control>
                          <Field.Number
                            name="unitCount"
                            placeholder={'Number of ' + capitalize(formik.values.expenseCategory.unitName) + '(s)'}
                            min={0}
                            precision={2}
                            onChange={async (value) => {
                              await formik.setValues({
                                ...formik.values,
                                expenseAmount: +(Math.round(value * formik.values.unitAmount + 'e+2') + 'e-2'),
                                unitCount: value,
                              });
                              if (formik.errors.unitCount) formik.validateField('unitCount');
                            }}
                          />
                          <Field.Number
                            name="unitAmount"
                            prefix="$"
                            min={0}
                            precision={4}
                            placeholder={'Cost per ' + capitalize(formik.values.expenseCategory.unitName)}
                            onChange={(value) => {
                              formik.setValues({
                                ...formik.values,

                                expenseAmount: +(Math.round(value * formik.values.unitCount + 'e+2') + 'e-2'),
                                unitAmount: value,
                              });
                              if (formik.errors.unitAmount) formik.validateField('unitAmount');
                            }}
                          />
                        </Form.Control>
                      )}

                      <Form.Control>
                        <Field.Text name="vendorName" placeholder="Vendor Name" maxLength={255} />
                      </Form.Control>

                      <Form.Control>
                        {projectSelectDisabledTooltip ? (
                          <Tooltip message={projectSelectDisabledTooltip}>
                            <ReadTextbox label="Project" value={expenseItem?.project?.name} />
                          </Tooltip>
                        ) : (
                          <Field.ClientProjectSelect
                            name="project"
                            placeholder="Project"
                            assignedMemberId={assignedMemberId}
                            assignedOnly={true}
                            unlockedOnly
                            enforceBillability={true}
                            disabled={projectRights !== 'write'}
                            initialValue={expenseItem && expenseItem.projectId}
                            onChange={async ({ target: { value } }) => {
                              const values = { ...formik.values, project: value };

                              setBillableProject(!!value?.isBillable);
                              if (!value?.isBillable) values.isBillable = false;

                              await formik.setValues(values);

                              if (formik.errors.project) formik.validateField('project');
                            }}
                          />
                        )}
                      </Form.Control>

                      <Form.Control>
                        <Field.DayPicker name="date" placeholder="Expense Date" getDayProps={getDayProps} />
                        <Field.Currency
                          disabled={isUnitCategory}
                          name="expenseAmount"
                          placeholder="Expense Amount"
                          currency={formik.values.project?.currency}
                        />
                      </Form.Control>

                      <Form.Control>
                        <Field.Checkbox name="isReimbursable" label="Reimburse to me" />
                        {billableCheckboxDisabledTooltip ? (
                          <Tooltip message={billableCheckboxDisabledTooltip}>
                            <ReadCheckbox label="Bill to client" checked={formik.values.isBillable} />
                          </Tooltip>
                        ) : (
                          <Field.Checkbox name="isBillable" label="Bill to client" disabled={!billableProject} />
                        )}
                      </Form.Control>

                      {isAttendeesRequired && (
                        <Form.Control>
                          <Field.TextArea name="attendees" placeholder="Attendees" rows={4} maxLength={5000} />
                        </Form.Control>
                      )}
                      <Form.Control>
                        <Field.TextArea name="notes" placeholder="Notes" rows={4} maxLength={5000} />
                      </Form.Control>
                    </Form.Section>
                  </>,
                  <>
                    <ReceiptFiles
                      onAdd={handleReceiptsAdded}
                      onRemove={handleReceiptRemoved}
                      isLocked={expenseItem?.isLocked}
                      receipts={visibleReceipts}
                      receiptsAdded={receiptsAdded}
                      name="receipts"></ReceiptFiles>
                  </>,
                  <>
                    <ExpenseItemHistory history={expenseItem?.history}></ExpenseItemHistory>
                  </>,
                ][tabIndex]
              }
            </Content>
            {confirmDelete && (
              <DeleteExpenseItemConfirmation
                id={expenseItem.id}
                onClose={() => setConfirmDelete(false)}
                onDelete={() => onDelete(closeDrawer)}
              />
            )}
            <Drawer.Actions>
              {expenseItem.id && (
                <DeleteButton isOutline onClick={() => setConfirmDelete(true)}>
                  Delete
                </DeleteButton>
              )}
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <Button type="submit" isLoading={isSubmitting}>
                  Save &amp; Close
                </Button>
              </Buttons>
            </Drawer.Actions>
          </Form>
        );
      }}
    </Formik>
  );
}

const initialState = { expenseItem: null, isReady: false };
const handlers = {
  ready: ({ expenseItem }) => ({ isReady: true, expenseItem }),
};

export default function ({
  expenseReport,
  drawerLoaded,
  formRef,
  member,
  onSaved,
  onDeleted,
  onClose,
  closeDrawer,
  rejectedHistoryItem,
  overrideShowMember = null,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, expenseItem }, actions] = useActions(handlers, initialState);
  const [formState, form] = useForm();
  const { expenseItemId } = useParams();
  const { notify } = useSubscription();

  const fetchData = useCallback(async () => {
    if (!expenseItemId) {
      actions.ready({
        expenseItem: {
          memberId: expenseReport.memberId,
          expenseReportId: expenseReport.id,
          statusId: 'not_submitted',
        },
      });
      return;
    }
    try {
      const { data: expenseItem } = await api.www.workspaces(workspace.id).expenseItems(expenseItemId).get();

      actions.ready({ expenseItem });
    } catch (error) {
      actions.ready({ expenseItem: null });
    }
  }, [actions, workspace.id, expenseItemId, expenseReport, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit(values, closeDrawer) {
    try {
      form.submit();
      const images = _.map(values.receiptsAdded, (r) => ({ id: r.id, image: r.image, contentType: r.type }));
      const body = emptyStringToNull({
        ..._.omit(values, ['expenseCategory', 'project']),
      });
      body.expenseCategoryId = values.expenseCategory?.id;
      body.projectId = values.project ? values.project.id : null;
      body.attendees = values.expenseCategory.requireAttendees ? values.attendees : null;
      body.receiptsAdded = _.map(values.receiptsAdded, (r) => {
        return { id: r.id, contentType: r.type ? r.type : 'application/octet-stream', fileName: r.name };
      });

      if (expenseItem?.permissions?.manageMemberExpenses && values.statusId !== expenseItem.statusId) {
        body.statusModeId = 'manual';
      }

      const { data } = await api.www.workspaces(workspace.id).expenseItems(expenseItemId).upsert(body);

      await images.forEach((r) => {
        api.www.workspaces(workspace.id).expenseItems(data.id).uploadReceipt(r);
        r.status = 'download';
      });

      await onSaved(data);
      form.done();
      closeDrawer();
      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (err) {
      form.error({ message: err.message });
    }
  }

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleDelete() {
    if (onDeleted) {
      onDeleted(expenseItem);
    } else if (onClose && onSaved) {
      onSaved();
      onClose();
    }

    notify(useSubscription.keys.refresh_expense_approval_count);
  }

  if (!isReady) return null;
  if (!expenseItem) return <ErrorPage.NotFound publicSite={false} />;
  return (
    <ExpenseItemForm
      member={member}
      overrideShowMember={overrideShowMember}
      closeDrawer={closeDrawer}
      expenseItem={expenseItem}
      expenseReport={expenseReport}
      rejectedHistoryItem={rejectedHistoryItem}
      formRef={formRef}
      drawerLoaded={drawerLoaded}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onClose={handleClose}
      {...formState}
    />
  );
}

export { ExpenseItemForm };
