import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useModal } from '~/contexts';
import { colors } from '~/styles';
import Icon from './Icon';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 200;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(colors.black, 0.8)};
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 42rem;
  background-color: ${colors.white};
`;

const Header = styled.header`
  display: flex;
  padding: 1.375rem 1.625rem;
  background-color: ${colors.black};
`;

const Title = styled.p`
  flex-grow: 1;
  flex-shrink: 0;
  color: ${colors.white};
  font-size: 2.25rem;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button {
    width: 3.125rem;
    height: 3.125rem;
    padding: 0;
    color: ${colors.primary};
    font-size: 1.125rem;
    background-color: ${colors.grey75};
    border: none;
    border-radius: 0;

    &:hover {
      color: ${colors.accent};
    }
  }
`;

const Body = styled.main`
  padding: 1.625rem;
`;

const Footer = styled.footer`
  position: relative;
  padding: 1rem 1.625rem;
  background-color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: 0;
    right: 0;
    height: 0.5rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;

function ModalCard({ title, children, onClose, className = '', ...props }) {
  const { manageInstances } = useModal();

  useEffect(manageInstances, [manageInstances]);

  return ReactDOM.createPortal(
    <Modal className={className} {...props}>
      <Background />
      <Card>
        <Header>
          <Title>{title}</Title>
          <Close>
            <button aria-label="close" onClick={onClose}>
              <Icon icon="times" />
            </button>
          </Close>
        </Header>
        {children}
      </Card>
    </Modal>,
    document.body,
  );
}

ModalCard.Body = function (props) {
  return <Body {...props} />;
};

ModalCard.Footer = function ({ children }) {
  return <Footer>{children}</Footer>;
};

export default ModalCard;
