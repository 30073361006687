import { Link } from 'react-router-dom';
import styled from 'styled-components';

const RouteLink = styled(Link)`
  color: inherit;
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.55;
    color: inherit;
  }
`;

export default RouteLink;
