import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  Icon,
  Level,
  PaymentMethodFilter,
  ProjectLink,
  RouteLink,
  Stack,
  Table,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QBOIndicator from '~/routes/app/invoices/components/QBOIndicator';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PaymentMethodTag from '../components/PaymentMethodTag';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const Link = styled.a`
  color: inherit;
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.55;
    color: inherit;
  }
`;

const Byline = styled.small`
  display: block;
`;

export default function Payments() {
  useDocumentTitle('Payments');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    paymentMethods: [],
    currency: workspace.currency,
    sort: new QuerySort('receivedOn', 'desc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.all_dates },
        paymentMethods: searchParamsConfig.paymentMethods,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('receivedOn', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      paymentMethodId: params.paymentMethods?.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().payments(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .payments(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Payments</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <PaymentMethodTag
              value={params.paymentMethods}
              onRemove={() => handleApplyFilters({ paymentMethods: [] })}
            />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, onSort, params, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  return (
    <>
      <Report.Table style={{ marginTop: '1rem' }}>
        <Level>
          {features.multicurrency && (
            <Level.Item>
              <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
            </Level.Item>
          )}

          <Level.Item right>
            <TotalResults>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total label="Payment" value={report.records.length} />
              {report.partialResults && <PartialResultsTooltip />}
            </TotalResults>
          </Level.Item>
        </Level>

        <Table>
          <Table.Header sticky>
            <Table.Column name="receivedOn" width="7.5rem" onSort={onSort} sort={params.sort}>
              Date
            </Table.Column>
            <Table.Column name="client.name" onSort={onSort} sort={params.sort}>
              Client
            </Table.Column>
            <Table.Column>Projects</Table.Column>
            <Table.Column width="10rem">Invoices</Table.Column>
            <Table.Column width="10rem" name="referenceNumber" onSort={onSort} sort={params.sort}>
              Ref #
            </Table.Column>
            <Table.Column width="12rem" name="paymentMethod.name" onSort={onSort} sort={params.sort}>
              Payment Method
            </Table.Column>
            <Table.Column width="10rem" align="right" name="convertedAmount" onSort={onSort} sort={params.sort}>
              Amount
            </Table.Column>
          </Table.Header>

          <TableBody fade={query.loading.table}>
            {report.records.map((payment) => {
              const {
                id,
                receivedOn,
                referenceNumber,
                client,
                paymentInvoices,
                amount,
                convertedAmount,
                paymentMethod,
              } = payment;

              const projects = payment.paymentInvoices.map(({ invoice }) => invoice.projects).flat();

              return (
                <Table.Row style={{ flexWrap: 'wrap' }} key={id}>
                  <Table.Cell>
                    <DateTime value={receivedOn} />
                  </Table.Cell>
                  <Table.Cell>
                    <ClientLink client={client} />
                    {payment.qboPaymentId && <QBOIndicator message="This payment is in QuickBooks" />}
                  </Table.Cell>
                  <Table.Cell>
                    <FirstProject projects={projects} payment={payment} />
                    <Projects projects={projects} />
                  </Table.Cell>
                  <Table.Cell>
                    <ul>
                      {paymentInvoices.map((paymentInvoice) => (
                        <li key={paymentInvoice.invoiceId}>
                          <Link
                            target="_blank"
                            href={`/${workspace.key}/invoices/${paymentInvoice.invoiceId}?preview=true`}>
                            Invoice #{paymentInvoice.invoice.number}{' '}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Table.Cell>
                  <Table.Cell>{referenceNumber}</Table.Cell>
                  <Table.Cell>{paymentMethod?.name}</Table.Cell>
                  <Table.Cell>
                    <p>
                      <RouteLink to={`/app/${workspace.key}/billing/payments/${payment.id}/view`}>
                        <Currency value={convertedAmount} currency={report.currency} />
                      </RouteLink>
                      {payment.currency !== report.currency && (
                        <Byline>
                          <Currency value={amount} currency={payment.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.amount} currency={report.currency} />
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Payment Filters" filters={[filters.paymentMethods]}>
        <Filter>
          <PaymentMethodFilter
            value={filters.paymentMethods}
            onChange={({ target: { value } }) => handleFilter({ paymentMethods: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}

const FirstProject = ({ projects, payment }) => {
  const project = projects[0];
  if (!project) return null;

  return <ProjectLink project={project} client={payment.client} />;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ projects }) => {
  let projectsCount = projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      placement="right"
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};
