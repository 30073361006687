import React, { useState } from 'react';
import styled from 'styled-components';
import { Buttons, Button, CancelButton, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const ErrorMessage = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

export default function CancelSubscription({ onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setIsSubmitting(true);

    try {
      const { data } = await api.www.workspaces(workspace.id).stripe.cancelSubscription();

      onSaved(data);
    } catch (error) {
      setError(error.message ?? true);
      setIsSubmitting(false);
      return;
    }

    onClose();
  };

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  };

  return (
    <ModalCard title="Subscription Cancellation" onClose={handleClose}>
      <ModalCard.Body>
        {error && (
          <ErrorMessage>
            {typeof error === 'string'
              ? error
              : 'There was a problem cancelling your subscription. Please try again later.'}
          </ErrorMessage>
        )}
        Are you sure you want to cancel your subscription? Your subscription will remain active through the remainder of
        the current billing period.
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Confirm Cancellation
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
