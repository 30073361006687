import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del } from './agent';

const projectMembers =
  (projectMembersUrl) =>
  (id = '') => {
    const url = _([projectMembersUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat(`/has-dependencies`)),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
    };
  };

export default projectMembers;
