import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Icon, SingleSelect, Tooltip } from '~/components';
import { colors } from '~/styles';

export default function AccountSelect({ accounts, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return accounts;
    }

    const fuse = new Fuse(accounts, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [accounts, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const account = _.find(accounts, { id: value });
    if (!account)
      return {
        id: value,
        name: (
          <Tooltip message="Account not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Account ID: {value}
          </Tooltip>
        ),
      };
    return account;
  }, [accounts, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
