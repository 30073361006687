import { Buttons, CancelButton, Currency, DateTime, Drawer, Icon, Level, Table } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import QBOIndicator from '../components/QBOIndicator';

const Container = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding: 1rem 3rem 1rem 0;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const initialState = { payment: null, isReady: false };
const handlers = {
  ready: ({ payment }) => ({ isReady: true, payment }),
};

function ClientPaymentView({ paymentId, onClose }) {
  useDocumentTitle('View Payment');

  const params = useParams();
  if (!paymentId) paymentId = params.paymentId;

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, payment }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    if (!paymentId) {
      actions.ready({ payment: {} });
      return;
    }

    try {
      const { data: payment } = await api.www.workspaces(workspace.id).clientPayments(paymentId).get();

      actions.ready({ payment });
    } catch (error) {
      actions.ready({ client: null });
    }
  }, [actions, workspace.id, paymentId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    onClose();
  }

  if (!isReady) return null;
  if (!payment) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Drawer isOpen title="View Payment" onClose={handleClose}>
      {(closeDrawer) => {
        return (
          <ReadForm>
            <ReadForm.Control>
              <Level>
                <Level.Item>
                  <ReadTextbox label="Client" value={payment.client.name} />
                </Level.Item>
                {payment.qboPaymentId && (
                  <Level.Item narrow right>
                    <QBOIndicator message="This payment is in QuickBooks" />
                  </Level.Item>
                )}
              </Level>
            </ReadForm.Control>

            <ReadForm.Control>
              <ReadTextbox
                name="amount"
                label="Amount Received"
                value={
                  <div style={{ textAlign: 'right' }}>
                    <Currency value={payment.amount} currency={payment.currency} />
                  </div>
                }
              />

              <ReadTextbox label="Received On" value={<DateTime value={payment.receivedOn} />} />
            </ReadForm.Control>

            {(payment.paymentMethod || payment.referenceNumber) && (
              <ReadForm.Control>
                {payment.paymentMethod && <ReadTextbox label="Payment Method" value={payment.paymentMethod?.name} />}
                {payment.referenceNumber && <ReadTextbox label="Reference Number" value={payment.referenceNumber} />}
              </ReadForm.Control>
            )}

            {payment.notes && (
              <ReadForm.Control>
                <ReadTextbox label="Notes" textarea value={payment.notes} />
              </ReadForm.Control>
            )}

            <Container>
              <SectionTitle>Invoices</SectionTitle>
              <Table>
                <Table.BoxHeader>
                  <Table.Column width="2.5rem" />
                  <Table.Column width="6.5rem">Invoice #</Table.Column>
                  <Table.Column width="7rem">Due Date</Table.Column>
                  <Table.Column align="right">Original Amount</Table.Column>
                  <Table.Column align="right">Open Balance</Table.Column>
                  <Table.Column align="right">Payment</Table.Column>
                </Table.BoxHeader>

                <Table.Body>
                  {payment.paymentInvoices.map((pi) => {
                    return (
                      <Table.BoxRow key={pi.invoiceId}>
                        <Table.Cell>
                          <Icon icon="check" color={colors.success} />
                        </Table.Cell>

                        <Table.Cell>
                          #{pi.invoice.number}
                          {pi.invoice.qboInvoiceId && <QBOIndicator />}
                        </Table.Cell>

                        <Table.Cell>
                          <DateTime value={pi.invoice.dueOn} />
                        </Table.Cell>

                        <Table.Cell>
                          <Currency value={pi.invoice.total} currency={payment.currency} />
                        </Table.Cell>

                        <Table.Cell>
                          <Currency value={pi.invoice.balance} currency={payment.currency} />
                        </Table.Cell>

                        <Table.Cell>
                          <Currency value={pi.amount} currency={payment.currency} />
                        </Table.Cell>
                      </Table.BoxRow>
                    );
                  })}
                </Table.Body>
              </Table>
            </Container>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={() => closeDrawer()}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </ReadForm>
        );
      }}
    </Drawer>
  );
}

export default ClientPaymentView;
