import React from 'react';
import styled from 'styled-components';

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  margin: -0.375rem -0.75rem;

  > * {
    margin: 0.375rem 0.75rem;
  }
`;

function RadioGroup({ value, direction = 'horizontal', children, onChange, ...props }) {
  return (
    <Group direction={direction} {...props}>
      {React.Children.map(children, (child) => {
        if (!child) {
          return child;
        }
        return React.cloneElement(child, {
          onChange: () => onChange(child.props.value),
          checked: child.props.checked ?? child.props.value === value,
        });
      })}
    </Group>
  );
}

export default RadioGroup;
