import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function ContactInstruction() {
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Client Name</td>
            <td>Yes</td>
            <td>
              The Name of the client associated with this contact. This must match the Name of a previously imported
              Client and match exactly on white space, case, and punctuations.
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>Yes</td>
            <td>Name of the contact.</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>No</td>
            <td>Email address of the contact.</td>
          </tr>
          <tr>
            <td>Title</td>
            <td>No</td>
            <td>Job title or position of the contact.</td>
          </tr>
          <tr>
            <td>First Name</td>
            <td>No</td>
            <td>First Name of the contact.</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>Yes</td>
            <td>Last Name of the contact.</td>
          </tr>
          <tr>
            <td>Office Phone</td>
            <td>No</td>
            <td>Office Phone number of the contact.</td>
          </tr>
          <tr>
            <td>Mobile Phone</td>
            <td>No</td>
            <td>Mobile Phone number of the contact.</td>
          </tr>
          <tr>
            <td>Fax</td>
            <td>No</td>
            <td>Fax number of the contact.</td>
          </tr>
          <tr>
            <td>Webste URL</td>
            <td>No</td>
            <td>Website of the contact.</td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
