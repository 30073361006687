import { Page, Tab, Tabs } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useFeatures } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { regex } from '~/utils';
import { ProjectTasksListPage } from '../project-tasks';
import ProjectHeader from './ProjectHeader';
import ProjectInvoicesTab from './ProjectInvoicesTab';
import ProjectTeamTab from './ProjectTeamTab';
import ProjectAllocationsTab from './allocations/ProjectAllocationsTab';
import ClientApprovalsTab from './client-approvals/ClientApprovalsTab';
import ClientApprovalProjectPage from './client-approvals/project/ClientApprovalProjectPage';
import ExpensesByCategory from './expenses-tab/ExpensesByCategory';
import { ProjectFilesTab } from './files';
import ProjectHealthTab from './health-tab/ProjectHealthTab';
import ProjectOverviewTab from './overview/ProjectOverviewTab';
import ProjectDrawer from './project-drawer/ProjectDrawer';
import ProjectTimeTab from './time-tab/ProjectTimeTab';
import ProjectHistoryDrawer from './project-drawer/ProjectHistoryDrawer';

const initialState = {
  status: 'loading',
  project: null,
};
const handlers = {
  ready: ({ project }) => ({ status: 'ready', project }),
  forbidden: () => ({ status: 'forbidden' }),
  error: () => ({ status: 'error' }),
  notFound: () => ({ status: 'not-found' }),
};

function ProjectArea({ children }) {
  const features = useFeatures();

  const [{ status, project }, actions] = useActions(handlers, initialState);

  const documentTitle = useDocumentTitle(project?.name);

  const api = useApi();
  const { workspace } = useWorkspace();

  const { clientKey, projectKey } = useParams();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const fetchData = useCallback(async () => {
    try {
      const { data: project } = await api.www.workspaces(workspace.id).clients(clientKey).projects(projectKey).get();

      actions.ready({ project });
    } catch (error) {
      switch (error.status) {
        case 403:
          actions.forbidden();
          break;
        case 404:
          actions.notFound();
          break;
        default:
          actions.error();
      }
    }
  }, [actions, workspace.id, clientKey, projectKey, api]);

  async function handleSaved() {
    await fetchData();
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (status === 'loading') return <PageLoader />;
  if (status === 'error') return <ErrorPage publicSite={false} />;
  if (status === 'forbidden') return <ErrorPage.Forbidden publicSite={false} />;
  if (status === 'not-found') return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Switch>
      {features.clientApprovals && (
        <Route path={path.concat(`/client-approvals/:clientApprovalId(${regex.route_uuid})`)}>
          <ClientApprovalProjectPage project={project} />
        </Route>
      )}

      <Route path={path}>
        <Page>
          <ProjectHeader project={project} onChange={fetchData} />

          <Tabs style={{ marginTop: '1.6rem' }}>
            <Tab to={`${url}/overview`}>Overview</Tab>

            <Tab to={`${url}/team`}>Team</Tab>

            <Tab to={`${url}/tasks`}>Tasks</Tab>

            {project.permissions.viewTimeAndExpenses && <Tab to={`${url}/time`}>Time</Tab>}

            {project.permissions.viewTimeAndExpenses && <Tab to={`${url}/expenses`}>Expenses</Tab>}

            {project.isBillable && project.permissions.viewPublishedInvoices && (
              <Tab to={`${url}/invoices`}>Invoices</Tab>
            )}

            {features.clientApprovals && project.enableClientApprovals && project.permissions.manageTimeAndExpenses && (
              <Tab to={`${url}/client-approvals`}>Client Approvals</Tab>
            )}

            {features.allocations && project.permissions.viewAllocations && (
              <Tab to={`${url}/allocations`}>Allocations</Tab>
            )}

            {features.projectHealth && project.permissions.edit && project.useHealthReports && (
              <Tab to={`${url}/health`}>Health</Tab>
            )}

            {features.clientAndProjectFiles && <Tab to={`${url}/files`}>Files</Tab>}
          </Tabs>

          {children || (
            <Switch>
              <Route path={`${path}/overview`}>
                <ProjectOverviewTab project={project} />
              </Route>

              <Route path={`${path}/team`}>
                <ProjectTeamTab project={project} />
              </Route>

              <Route path={`${path}/tasks`}>
                <ProjectTasksListPage project={project} onChange={fetchData} />
              </Route>

              <Route path={`${path}/time`}>
                {project.permissions.viewTimeAndExpenses ? (
                  <ProjectTimeTab project={project} />
                ) : (
                  <ErrorPage.Forbidden publicSite={false} />
                )}
              </Route>

              <Route path={`${path}/expenses`}>
                {project.permissions.viewTimeAndExpenses ? (
                  <ExpensesByCategory project={project} />
                ) : (
                  <ErrorPage.Forbidden publicSite={false} />
                )}
              </Route>

              {project.isBillable && (
                <Route path={`${path}/invoices`}>
                  {project.permissions.viewPublishedInvoices ? (
                    <ProjectInvoicesTab project={project} />
                  ) : (
                    <ErrorPage.Forbidden publicSite={false} />
                  )}
                </Route>
              )}

              {features.clientApprovals && project.enableClientApprovals && (
                <Route path={`${path}/client-approvals`}>
                  {project.permissions.manageTimeAndExpenses ? (
                    <ClientApprovalsTab project={project} />
                  ) : (
                    <ErrorPage.Forbidden publicSite={false} />
                  )}
                </Route>
              )}

              {features.allocations && (
                <Route path={`${path}/allocations`}>
                  {project.permissions.viewAllocations ? (
                    <ProjectAllocationsTab project={project} />
                  ) : (
                    <ErrorPage.Forbidden publicSite={false} />
                  )}
                </Route>
              )}

              {features.projectHealth && project.permissions.edit && project.useHealthReports && (
                <Route
                  path={[
                    `${path}/health`,
                    `${path}/health/edit`,
                    `${path}/health/history`,
                    `${path}/health/:projectHealthReportId/:action`,
                  ]}
                  exact>
                  <ProjectHealthTab project={project} onChange={fetchData} />
                </Route>
              )}

              {features.clientAndProjectFiles && (
                <Route path={`${path}/files`}>
                  <ProjectFilesTab project={project} />
                </Route>
              )}

              <Redirect path="/" to={`${url}/overview`} />
            </Switch>
          )}

          <Switch>
            {/* Don't open the project edit drawer when opening the allocation edit drawer */}
            <Route path={`${path}/allocations/edit/:allocationId`} />

            <Route path={`${path}/:projectTab/history`}>
              {project.permissions.edit ? (
                <ProjectHistoryDrawer
                  projectId={project.id}
                  onClose={(projectTab) => {
                    history.push({ pathname: `${url}/${projectTab}`, search: location.search });
                    documentTitle.set(project.name);
                  }}
                />
              ) : (
                <Redirect path="/" to={`${url}/overview`} />
              )}
            </Route>

            <Route path={`${path}/:projectTab/edit`}>
              <ProjectDrawer
                project={project}
                onSaved={handleSaved}
                onClose={(projectTab) => {
                  history.push({ pathname: `${url}/${projectTab}`, search: location.search });
                  documentTitle.set(project.name);
                }}
                onDeleted={() => {
                  history.push(`/app/${workspace.key}/projects`);
                }}
              />
            </Route>
          </Switch>
        </Page>
      </Route>
    </Switch>
  );
}

export default ProjectArea;
