import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Avatar from './Avatar';
import MemberContactPopover from './MemberContactPopover';
import Tag from './Tag';
import Tooltip from './Tooltip';

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

export default function ProjectAdmins({ project }) {
  if (_.isEmpty(project.administrators)) return null;

  const admin = project.administrators[0];

  const admins = project.administrators;

  return (
    <>
      <Member>
        <MemberContactPopover member={admin.member} placement="left">
          <Avatar value={admin.member} isCircle hasBackground initialsFontSize=".9rem" />
        </MemberContactPopover>
        <MemberInfo>{admin.member.name}</MemberInfo>
      </Member>

      {admins.length > 1 && (
        <Tooltip
          placement="right"
          message={
            <div style={{ fontSize: '1rem' }}>
              <Title>Project Admins</Title>

              {admins.map((admin) => (
                <Tag style={{ backgroundColor: colors.grey5 }} key={admin.id}>
                  <small>{admin.member.name}</small>
                </Tag>
              ))}
            </div>
          }>
          <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
            <small>+{admins.length - 1}</small>
          </Tag>
        </Tooltip>
      )}
    </>
  );
}
