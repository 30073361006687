import { Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import Box from './assets/box.svg?react';
import DropBox from './assets/dropbox.svg?react';
import GoogleDrive from './assets/google-drive.svg?react';
import ICloud from './assets/icloud.svg?react';
import MicrosoftSharePoint from './assets/microsoft-sharepoint.svg?react';
import MicrosoftTeams from './assets/microsoft-teams.svg?react';
import OneDrive from './assets/onedrive.svg?react';

const Anchor = styled.a`
  display: block;

  .icon {
    font-size: 2rem;
  }

  svg {
    width: 2.625rem;
    height: auto;
    display: block;
  }

  transition: 50ms all;

  &:hover {
    filter: brightness(0.9);
  }
`;

export default function CloudFolderLink({ url }) {
  return (
    <Anchor href={url} target="_blank" rel="noopener noreferrer">
      <CloudFolderIcon url={url} />
    </Anchor>
  );
}

function CloudFolderIcon({ url }) {
  if (/drive\.google\.com/i.test(url)) return <GoogleDrive title="Google Drive" />;
  if (/1drv\.ms|onedrive\.live\.com/i.test(url)) return <OneDrive title="OneDrive" />;
  if (/app\.box\.com/i.test(url)) return <Box title="Box" />;
  if (/dropbox\.com/i.test(url)) return <DropBox title="Dropbox" />;
  if (/icloud\.com/i.test(url)) return <ICloud title="iCloud Drive" />;
  if (/teams\.microsoft\.com/i.test(url)) return <MicrosoftTeams title="Microsoft Teams" />;
  if (/sharepoint\.com/i.test(url)) return <MicrosoftSharePoint title="Microsoft SharePoint" />;

  return <Icon icon="folder" />;
}
