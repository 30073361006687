import React from 'react';
import { Admin } from './admin';
import { WorkspaceArea, WorkspaceLoader } from './app';
import { PublicRoutes } from './public';
import { Switch, Route } from 'react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route path="/app" exact>
        <WorkspaceLoader />
      </Route>

      <Route path="/app/:workspaceKey">
        <WorkspaceArea />
      </Route>

      <Route path="/admin">
        <Admin />
      </Route>

      <Route path="/">
        <PublicRoutes />
      </Route>
    </Switch>
  );
}

export default Routes;
