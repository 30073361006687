import { Icon } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';
import projectStatuses from '~/lookups/project-statuses';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ProjectPopover from '../../components/ProjectPopover';
import { Assignment, Info, Label } from '../../components/schedule/sidebar';
import {
  AccordionButton,
  Action,
  Actions,
  Box,
  Content,
  Group as GroupContainer,
} from '../../components/schedule/sidebar/SidebarStyles';
import SidebarMenu from '../../components/schedule/sidebar/SidebarMenu';

const OpenNewTabIcon = styled(Icon)`
  margin-left: 1rem;
  &:hover {
    color: ${colors.accent};
  }
`;

const GroupActions = styled(Actions)`
  flex-direction: row;
`;

const GroupAction = styled(Action)`
  width: 1rem;
  margin-left: 0.5rem;
`;

export default function Group({ row, groups, toggleGroup, showGroupActions, hasMembers, onShowTeam }) {
  const { workspace } = useWorkspace();
  const history = useHistory();

  const group = groups[row.id];

  // Groups return as undefined when the schedule is updating.
  // This shouldn't happen, may need to review the logic to set the expanded/collapsed status.
  if (!group) return null;

  const icon = group.state === 'expanded' ? 'angle-up' : 'angle-down';

  const handleGroupClick = (event) => {
    event.stopPropagation();
    toggleGroup({ id: row.id, ...group });
  };

  return (
    <GroupContainer
      key={row.id}
      style={row.meta.style}
      data-testid={`row_${row.id}`}
      data-testid-lanes={row.meta.lanes}
      state={group.state}>
      <Box>
        <Content>
          <Assignment tentative={row.project?.statusId === projectStatuses.tentative.id}>
            <Info>
              <Label>
                <ProjectPopover projectId={row.project.id}>{row.project.name}</ProjectPopover>
              </Label>
            </Info>
          </Assignment>
        </Content>

        {showGroupActions && (
          <GroupActions>
            <GroupAction>
              <SidebarMenu>
                {({ setIsOpen }) => {
                  const handleViewProject = () => {
                    history.push(`/app/${workspace.key}/projects/${row.project.client.key}/${row.project.key}`);
                  };

                  const handleViewProjectNewTab = () => {
                    window.open(`/app/${workspace.key}/projects/${row.project.client.key}/${row.project.key}`);
                  };

                  const handleShowTeam = async () => {
                    setIsOpen(false);
                    const team = await onShowTeam(row.project.id);
                    if (team.length > 0 && group.state !== 'expanded') toggleGroup({ id: row.id, ...group });
                  };

                  return (
                    <>
                      <SidebarMenu.Item>
                        <div onClick={handleViewProject}>View Project Dashboard</div>
                        <OpenNewTabIcon
                          onClick={handleViewProjectNewTab}
                          icon="arrow-up-right-from-square"></OpenNewTabIcon>
                      </SidebarMenu.Item>
                      <SidebarMenu.Item disabled={!onShowTeam} onClick={handleShowTeam}>
                        Show All Project Team Members
                      </SidebarMenu.Item>
                    </>
                  );
                }}
              </SidebarMenu>
            </GroupAction>

            <GroupAction>
              {(group.hasAllocations || hasMembers) && (
                <AccordionButton isAnchor onClick={handleGroupClick} data-testid="group_accordion">
                  <Icon icon={icon} />
                </AccordionButton>
              )}
            </GroupAction>
          </GroupActions>
        )}
      </Box>
    </GroupContainer>
  );
}
