import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { rgba } from 'polished';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, DayPickerDropdown, Icon, MemberSelect } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { useAuth } from '~/hooks';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
`;

const StickyHeader = styled(Header)`
  position: sticky;
  top: 5.75rem;
  background-color: ${colors.white};
  z-index: 20;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 2.5rem;
    background-color: ${colors.white};
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 2rem;
    background-image: linear-gradient(to bottom, ${rgba(colors.white, 1)}, ${rgba(colors.white, 0)});
  }
`;

const HeaderColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const ViewNav = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 1.625rem;
    margin-left: 1px;
    padding: 0;
    color: ${colors.white};
    background-color: ${colors.primary};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    border-radius: 0;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: ${colors.accent};
    }

    &:not(.is-active) {
      color: ${colors.grey40};
      background-color: ${colors.grey5};

      &:hover {
        background-color: ${colors.grey10};
      }
    }
  }
`;

const DateNav = styled.div`
  display: flex;
  align-items: center;
`;

const DateNavButton = styled(Button)`
  width: 1.625rem;
  height: 1.625rem;
  color: ${colors.grey40};
  font-size: 1.125rem;
  background-color: ${colors.grey5};
  border-radius: 50%;

  &:hover {
    color: ${colors.grey40};
    background-color: ${colors.grey10};
  }
`;

const DatePickerToggleButton = styled(Button)`
  width: 10rem;
  margin: 0 1rem;
  color: ${colors.primary};
  font-weight: ${weights.medium};
  text-align: center;

  &:hover {
    color: ${colors.accent};
  }
`;

function TimeHeader({ view, date, setDate, member, setMember }) {
  const { workspace } = useWorkspace();
  const auth = useAuth();
  const location = useLocation();

  const handlePrevious = () => {
    setDate(
      moment(date)
        .subtract(1, view === 'list' ? 'day' : view)
        .format('YYYY-MM-DD'),
    );
  };

  const handleNext = () => {
    setDate(
      moment(date)
        .add(1, view === 'list' ? 'day' : view)
        .format('YYYY-MM-DD'),
    );
  };

  const handleViewNav = (nextView, event) => {
    // When clicking an already selected view, reset the current day to today
    if (view === nextView) {
      event.preventDefault();
      setDate(moment().format('YYYY-MM-DD'));
    }
  };

  const dateDisplay = useMemo(() => {
    if (view === 'day' || view === 'list') {
      return moment(date).format('ddd, MMM D, YYYY');
    } else if (view === 'week') {
      const start = moment(date).startOf('isoWeek');
      const end = moment(date).endOf('isoWeek');
      return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
    } else if (view === 'month') {
      return moment(date).format('MMMM YYYY');
    }
  }, [date, view]);

  const pickerScope = useMemo(() => {
    if (view === 'list') {
      return 'day';
    }
    return view;
  }, [view]);

  const filterMembers = useMemo(() => {
    return [workspace.member.id];
  }, [workspace]);

  const HeaderComponent = view === 'list' ? StickyHeader : Header;

  return (
    <HeaderComponent>
      <HeaderColumn>
        <DateNav>
          <DateNavButton onClick={handlePrevious} isAnchor>
            <Icon icon="angle-left" />
          </DateNavButton>
          <DayPickerDropdown
            value={date}
            onChange={(date) => setDate(date)}
            scope={pickerScope}
            alignOffset="1rem"
            positionOffset="1rem">
            {({ setIsOpen }) => (
              <DatePickerToggleButton isAnchor onClick={() => setIsOpen((isOpen) => !isOpen)}>
                {dateDisplay}
              </DatePickerToggleButton>
            )}
          </DayPickerDropdown>
          <DateNavButton onClick={handleNext} isAnchor>
            <Icon icon="angle-right" />
          </DateNavButton>
        </DateNav>
      </HeaderColumn>
      <HeaderColumn justify="center">
        <ViewNav>
          <Link
            to={`/app/${workspace.key}/time/month${location.search}`}
            onClick={handleViewNav.bind(this, 'month')}
            className={classNames({ 'is-active': view === 'month' })}>
            Month
          </Link>
          <Link
            to={`/app/${workspace.key}/time/week${location.search}`}
            onClick={handleViewNav.bind(this, 'week')}
            className={classNames({ 'is-active': view === 'week' })}>
            Week
          </Link>
          <Link
            to={`/app/${workspace.key}/time/day${location.search}`}
            onClick={handleViewNav.bind(this, 'day')}
            className={classNames({ 'is-active': view === 'day' })}>
            Day
          </Link>
          <Link
            to={`/app/${workspace.key}/time/list${location.search}`}
            onClick={handleViewNav.bind(this, 'list')}
            className={classNames({ 'is-active': view === 'list' })}>
            List
          </Link>
        </ViewNav>
      </HeaderColumn>
      <HeaderColumn justify="flex-end">
        {auth.members.manageTimeAndExpenses && (
          <MemberSelect
            filterMembers={filterMembers}
            placeholder="Teammates"
            permission="manageTimeAndExpenses"
            withPermissions
            onChange={(event) => setMember(event.target.value)}
            value={member}
            align="right"
          />
        )}
      </HeaderColumn>
    </HeaderComponent>
  );
}

export default TimeHeader;
