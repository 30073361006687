import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useApi, useWorkspace } from '~/contexts';
import { useField } from '~/hooks';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function HolidayScheduleSelect({ disabled, onDefault, holidayScheduleId, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    let options = data;

    if (filterValue) {
      const fuse = new Fuse(data, { keys: ['id', 'name'] });
      const results = fuse.search(filterValue);
      options = results.map((result) => result.item);
    }

    return options.filter((item) => item.isActive || item.id === holidayScheduleId);
  }, [data, filterValue, holidayScheduleId]);

  const selectedOption = useMemo(() => _.find(data, { id: props.value }), [data, props.value]);

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).holidaySchedules().lookup();
      setData(data);
      setIsReady(true);
    })();
  }, [api, workspace]);

  useEffect(() => {
    const defaultValue = _.find(data, { isDefault: true });
    if (defaultValue && _.isFunction(onDefault)) {
      onDefault(defaultValue.id);
    }
  }, [data, onDefault]);

  return (
    <SingleSelect
      disabled={disabled || !isReady}
      showFilter={true}
      noOptionsMessage="No holiday schedules available."
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption?.name || ''}
      {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldHolidayScheduleSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <HolidayScheduleSelect {...field} {...props} />
    </FieldControl>
  );
}

export default HolidayScheduleSelect;
export { FieldHolidayScheduleSelect };
