import { useWorkspace } from '~/contexts';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { TimesheetProvider } from '../time/timesheets/TimesheetContext';
import { SidebarExpander, WorkspaceMenu, WorkspaceSearch, WorkspaceTimer } from './header';

const Header = styled.header`
  display: flex;
  position: fixed;
  top: ${({ hasMessage }) => (hasMessage ? '2.5rem' : '0')};
  left: ${({ sidebarExpanded }) => (sidebarExpanded ? '11.5rem' : '4.75rem')};
  right: 0;
  height: 3.75rem;
  border-bottom: 1px solid ${colors.grey10};
  background-color: ${colors.white};
  z-index: 60;
`;

export default function WorkspaceHeader({ sidebarExpanded, setSidebarExpanded }) {
  const { hasMessage, workspace } = useWorkspace();

  return (
    <Header hasMessage={hasMessage} sidebarExpanded={sidebarExpanded}>
      <SidebarExpander sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
      <WorkspaceSearch />
      <TimesheetProvider memberId={workspace.member.id}>
        <WorkspaceTimer />
      </TimesheetProvider>
      <WorkspaceMenu />
    </Header>
  );
}
