import Slack from '~/assets/icon-slack.svg?react';
import { Pill, Pills, DetailsPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useAuth } from '~/hooks';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Avatar from './Avatar';

const Links = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    a:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

const Figure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

const Info = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-align: center;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  flex: 1;
  text-align: center;
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

const Label = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
`;

function MemberContactPopover({ member, placement = 'auto', ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  });

  const auth = useAuth();
  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  useEffect(() => {
    if (!visible || query.isReady) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).members(member.id).contactCard();
        setQuery({ isReady: true, data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, member.id, visible, query.isReady]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  let contact = { ...member, ...(query.data || {}) };

  return (
    <>
      <span ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        query.isReady &&
        ReactDOM.createPortal(
          <DetailsPopover
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Links>
              {contact.slackUserId && contact.workspace.slackAuthorization?.teamId && (
                <a href={`slack://user?team=${contact.workspace.slackAuthorization.teamId}&id=${contact.slackUserId}`}>
                  <Slack style={{ width: '1rem', height: '1rem' }} />
                </a>
              )}
            </Links>

            <Content>
              <Figure>
                <Avatar value={contact} isCircle hasBackground size={70} />

                <Label>{contact.name}</Label>
              </Figure>

              {contact.email && (
                <InfoBlock title="Email">
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </InfoBlock>
              )}
              {contact.employmentType && <InfoBlock title="Member Type">{contact.employmentType.name}</InfoBlock>}
              {contact.jobTitle && <InfoBlock title="Job Title">{contact.jobTitle.name}</InfoBlock>}
              {auth.allocations.view && contact.resume && (
                <InfoBlock title="Resume">
                  <a target="_blank" rel="noreferrer" href={`${contact.resume.url}?download=1`}>
                    Download Resume
                  </a>
                </InfoBlock>
              )}
              {contact.discipline && <InfoBlock title="Discipline">{contact.discipline.name}</InfoBlock>}
              {contact.practice && <InfoBlock title="Practice">{contact.practice.name}</InfoBlock>}
              {contact.manager && <InfoBlock title="Manager">{contact.manager.name}</InfoBlock>}
              {contact.tags?.length > 0 && (
                <InfoBlock title="Tags">
                  <Pills style={{ justifyContent: 'center' }}>
                    {contact.tags.map((tag) => (
                      <Pill key={tag.id}>{tag.name}</Pill>
                    ))}
                  </Pills>
                </InfoBlock>
              )}
              {contact.location && <InfoBlock title="Location">{contact.location.name}</InfoBlock>}
            </Content>
          </DetailsPopover>,
          document.body,
        )}
    </>
  );
}

export default MemberContactPopover;
