import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoCompleteMulti from '../AutoCompleteMulti';

export default function SecurityRoleFilter({ isActive, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).securityRoles().get({ q, isActive, size: 1000 });

      return data;
    },
    [workspace.id, isActive, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !props.value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      placeholder="All"
      materialPlaceholder="Security Role"
      materialAlwaysVisible
      {...props}
    />
  );
}
