import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApi } from '~/contexts';

const AdminSessionContext = React.createContext({ adminSession: null });

function AdminSessionProvider({ children }) {
  const api = useApi();
  const [isReady, setReady] = useState(false);
  const [adminSession, setAdminSession] = useState(null);

  const identify = useCallback(async () => {
    try {
      const { data } = await api.admin.session();
      setAdminSession(data);
    } catch (error) {
      if (error.status === 401) {
        setAdminSession(null);
      } else {
        throw error;
      }
    } finally {
      setReady(true);
    }
  }, [api]);

  const login = useCallback(
    async (credentials) => {
      const { data } = await api.admin.login(credentials);
      await identify();
      return data;
    },
    [api, identify],
  );

  const logout = useCallback(async () => {
    await api.admin.logout();
    setAdminSession(null);
  }, [api]);

  // Identify session on load
  useEffect(() => {
    identify();
  }, [identify]);

  return (
    <AdminSessionContext.Provider
      value={{
        adminSession,
        admin: adminSession?.admin,
        impersonatedMemberId: adminSession?.impersonatedMemberId,
        isImpersonation: !!adminSession?.impersonatedMemberId,
        setAdminSession,
        identify,
        login,
        logout,
        isReady,
        isLoggedIn: !!adminSession,
      }}>
      {children}
    </AdminSessionContext.Provider>
  );
}

function useAdminSession() {
  return useContext(AdminSessionContext);
}

export { AdminSessionContext, AdminSessionProvider, useAdminSession };
