function arrayMove(arr, old_index, new_index) {
  const clone = arr.slice();
  if (new_index >= clone.length) {
    var k = new_index - clone.length + 1;
    while (k--) {
      clone.push(undefined);
    }
  }
  clone.splice(new_index, 0, clone.splice(old_index, 1)[0]);
  return clone;
}

export default arrayMove;
