import { Hours, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { QueryString, dateFormats } from '~/utils';
import BudgetTooltip from '../components/BudgetTooltip';
import ProjectedBudgetHoursTooltip from '../components/ProjectedBudgetHoursTooltip';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import HoursByMonthChart from './HoursByMonthChart';
import HoursProgressChart from './HoursProgressChart';

export default function HoursWidget({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().progress.hours();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      ...query,
    }).toString(true)}`;

  const url = {
    total: timeDetail(),
    billable: timeDetail({ billableType: 'billable' }),
    non_billable: timeDetail({ billableType: project.client.isInternal ? 'internal' : 'non_billable' }),
  };

  const boxes = {
    budget: !!data?.budget,
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.budget || this.forecast;
    },
  };

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '32.4rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <HoursProgressChart project={project} data={data} />,
                  month: () => <HoursByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>
            <Boxes>
              {project.isBillable && (
                <Box>
                  <UpperBox>
                    <RouteLink to={url.billable}>
                      <Value>
                        <Hours value={data.actual.billable} minimumFractionDigits={0} />
                      </Value>
                      <Label>Billable Hours</Label>
                    </RouteLink>
                  </UpperBox>

                  {boxes.any && (
                    <LowerBoxes>
                      {boxes.budget && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Hours value={data.budget.billable.estimated ?? 0} minimumFractionDigits={0} />
                            </Value>
                            <Label>Budget</Label>
                          </Metric>
                          <Metric status={data.budget.billable.status}>
                            <BudgetTooltip value={data.budget.billable.percent}>
                              <Value>
                                <Hours value={data.budget.billable.remaining ?? 0} minimumFractionDigits={0} />
                              </Value>
                              <Label>Budget Left</Label>
                            </BudgetTooltip>
                          </Metric>
                        </SmallBox>
                      )}

                      {boxes.forecast && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Hours value={data.forecast.billable.projected} minimumFractionDigits={0} />
                            </Value>
                            <Label>Projected</Label>
                          </Metric>
                          <Metric status={data.forecast.billable.status} data-testid="projected-left-billable">
                            <ProjectedBudgetHoursTooltip value={data.forecast.billable}>
                              <Value>
                                <Hours value={data.forecast.billable.left} minimumFractionDigits={0} />
                              </Value>
                              <Label>Projected Left</Label>
                            </ProjectedBudgetHoursTooltip>
                          </Metric>
                        </SmallBox>
                      )}
                    </LowerBoxes>
                  )}
                </Box>
              )}

              <Box>
                <UpperBox>
                  <RouteLink to={url.non_billable}>
                    <Value>
                      <Hours value={data.actual.nonBillable} minimumFractionDigits={0} />
                    </Value>
                    <Label>Non-billable Hours</Label>
                  </RouteLink>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.budget && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.budget.nonBillable.estimated ?? 0} minimumFractionDigits={0} />
                          </Value>
                          <Label>Budget</Label>
                        </Metric>
                        <Metric status={data.budget.nonBillable.status}>
                          <BudgetTooltip value={data.budget.nonBillable.percent}>
                            <Value>
                              <Hours value={data.budget.nonBillable.remaining ?? 0} minimumFractionDigits={0} />
                            </Value>
                            <Label>Budget Left</Label>
                          </BudgetTooltip>
                        </Metric>
                      </SmallBox>
                    )}

                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.nonBillable.projected} minimumFractionDigits={0} />
                          </Value>
                          <Label>Projected</Label>
                        </Metric>
                        <Metric status={data.forecast.nonBillable.status} data-testid="projected-left-non-billable">
                          <ProjectedBudgetHoursTooltip value={data.forecast.nonBillable}>
                            <Value>
                              <Hours value={data.forecast.nonBillable.left} minimumFractionDigits={0} />
                            </Value>
                            <Label>Projected Left</Label>
                          </ProjectedBudgetHoursTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>

              <Box>
                <UpperBox>
                  <RouteLink to={url.total}>
                    <Value>
                      <Hours value={data.actual.total} minimumFractionDigits={0} />
                    </Value>
                    <Label>Total Hours</Label>
                  </RouteLink>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.budget && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.budget.total.estimated ?? 0} minimumFractionDigits={0} />
                          </Value>
                          <Label>Budget</Label>
                        </Metric>
                        <Metric status={data.budget.total.status}>
                          <BudgetTooltip value={data.budget.total.percent}>
                            <Value>
                              <Hours value={data.budget.total.remaining ?? 0} minimumFractionDigits={0} />
                            </Value>
                            <Label>Budget Left</Label>
                          </BudgetTooltip>
                        </Metric>
                      </SmallBox>
                    )}

                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.total.projected} minimumFractionDigits={0} />
                          </Value>
                          <Label>Projected</Label>
                        </Metric>
                        <Metric status={data.forecast.total.status} data-testid="projected-left-total">
                          <ProjectedBudgetHoursTooltip value={data.forecast.total}>
                            <Value>
                              <Hours value={data.forecast.total.left} minimumFractionDigits={0} />
                            </Value>
                            <Label>Projected Left</Label>
                          </ProjectedBudgetHoursTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
