import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useSelectableCells({ cells, onSelectEnd }) {
  const [selection, setSelection] = useState(null);

  const handleSelectionEnd = useCallback(() => {
    if (selection) {
      setSelection(null);
      onSelectEnd(selection.cells);
    }
  }, [selection, onSelectEnd]);

  useEffect(() => {
    if (selection) {
      window.addEventListener('pointerup', handleSelectionEnd);
    }

    return () => {
      window.removeEventListener('pointerup', handleSelectionEnd);
    };
  }, [selection, handleSelectionEnd]);

  const onSelectStart = useCallback((cell) => {
    setSelection({
      rowIndex: cell.meta.rowIndex,
      cellIndex: cell.meta.cellIndex,
      cells: [cell],
    });
  }, []);

  const onSelect = useCallback(
    (cell) => {
      const rowCells = cells.filter((c) => c.meta.rowIndex === cell.meta.rowIndex);

      const startIndex = Math.min(selection.cellIndex, cell.meta.cellIndex);
      const endIndex = Math.max(selection.cellIndex, cell.meta.cellIndex) + 1;

      const selectedCells = rowCells.slice(startIndex, endIndex);

      setSelection((state) => ({ ...state, cells: selectedCells }));
    },
    [cells, selection?.cellIndex],
  );

  return useMemo(() => {
    return {
      selection,
      onSelectStart,
      onSelect,
    };
  }, [selection, onSelectStart, onSelect]);
}
