import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import TableCell from './TableCell';
import TableSplitButton from './TableSplitButton';

export const Row = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.grey10};
  border-radius: 5px;
  margin-top: 0.375rem;

  &.is-clickable:hover,
  &.focused {
    border-color: transparent;
    box-shadow: 0 3px 15px ${colors.grey10};
  }

  &.is-clickable {
    cursor: pointer;
    transition: all 250ms ease-in-out;

    ${TableSplitButton} {
      .icon {
        pointer-events: none;
      }
    }
  }

  &.is-disabled > ${TableCell} {
    opacity: 0.4;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.stretch {
    align-items: stretch;
  }
`;

const TableBoxRow = React.forwardRef(
  ({ className, isDisabled, children, focused, wrap, stretch, onClick, onEnter, ...props }, ref) => {
    const cells = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));

    const handleKeyDown = onEnter
      ? (event) => {
          if (event.key === 'Enter') onEnter();
        }
      : undefined;

    return (
      <Row
        ref={ref}
        onKeyDown={handleKeyDown}
        {...props}
        className={classNames(className, {
          'is-disabled': isDisabled,
          'is-clickable': !!onClick,
          focused,
          wrap,
          stretch,
        })}
        onClick={
          onClick
            ? (event) => {
                if (event.target.tagName === 'BUTTON' || event.target.tagName === 'A') return;
                onClick(event);
              }
            : undefined
        }>
        {cells}
      </Row>
    );
  },
);

export default TableBoxRow;
