import { calculatePeriodEnd, calculateDaysInPeriod } from '../utils';

function DaysInPeriod({ period, member: { activeEndDate, activeStartDate }, periods, start }) {
  // If a specific start date is provided, recalculate the end date
  // Otherwise, use the end date from the period
  // This allows to dynamically calculate the value if the form value changes
  let end = period.end;
  if (start) {
    end = calculatePeriodEnd(periods, period, start);
  } else {
    start = period.start;
  }

  const daysInPeriod = calculateDaysInPeriod(start || activeStartDate, end || activeEndDate);
  return daysInPeriod;
}

export default DaysInPeriod;
