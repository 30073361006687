import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';
import { getCachedPreviewUrl, getIconFromMIME } from '~/utils';
import { imagePreviewFileTypes } from '~/utils/fileTypes';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  border-radius: 0.3125rem;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.5rem;
  height: 10.5rem;
  margin: 0.5rem;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  overflow: hidden;

  &:hover ${Overlay} {
    display: flex;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black};
  border-radius: 0.3125rem;
  opacity: 0.5;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const LoadingIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 2rem;
`;

const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PreviewIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const PreviewIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 3rem;
`;

const FileNameInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const FileExt = styled.div`
  flex-shrink: 0;
`;

const Buttons = styled.div`
  display: inline;
  flex: 1;
  z-index: 2;
  font-size: 0.825rem;

  & > .button {
    background-color: ${colors.white};
    z-index: 2;
    height: 2rem;
    width: 2rem;
    padding: 0;
    margin-right: 0.5rem;
  }

  & > .button:last-child {
    margin-right: 0;
  }

  & > .button:hover {
    background-color: ${colors.white};
  }
`;

const DeleteButton = styled(Button)`
  color: ${colors.danger50};
  &:hover {
    color: ${colors.danger100};
  }
`;

function ReceiptPreview({ receipt, isLocked, onRemove }) {
  const context = useWorkspace();
  const [hideAll, setHideAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [icon, setIcon] = useState(null);

  const fileNameInfo = useMemo(() => {
    const fullName = receipt.fileName ?? receipt.name;
    const nameArray = fullName.split('.');
    if (nameArray.length < 2) {
      return {
        name: fullName,
        ext: '',
      };
    }
    const ext = '.' + nameArray.pop();
    const name = nameArray.join('.');
    return { name, ext };
  }, [receipt]);

  const fetchImage = useCallback(async () => {
    setIsLoading(true);
    try {
      const url = `/api/www/workspaces/${receipt.workspaceId}/expense-items/${receipt.expenseItemId}/receipt-image/${receipt.id}`;
      const cacheKey = `receipt_preview__${receipt.id}`;
      const previewUrl = await getCachedPreviewUrl(cacheKey, url);
      setPreview(previewUrl);
    } finally {
      setIsLoading(false);
    }
  }, [receipt]);

  useEffect(() => {
    if (receipt.status === 'upload') {
      if (receipt.preview) {
        // if not an image and needs an icon
        if (!imagePreviewFileTypes.includes(receipt.type)) {
          setIcon(getIconFromMIME(receipt.type));
        } else {
          setPreview(receipt.preview);
        }
      }
    } else if (receipt.status === 'download') {
      if (imagePreviewFileTypes.includes(receipt.contentType)) {
        fetchImage();
      } else {
        setIcon(getIconFromMIME(receipt.contentType));
      }
    }
    return fetchImage.cancel;
  }, [fetchImage, receipt]);

  const handleRemove = () => {
    onRemove(receipt);
    setHideAll(true);
  };

  const downloadURL = receipt.status === 'download' ? `/api/${context.workspace.key}/receipts/${receipt.id}` : null;
  const downloadReceipt = () => window.open(downloadURL + '?download=1');
  const openReceipt = () => window.open(downloadURL, '_blank');

  if (hideAll) {
    return null;
  }
  return (
    <Container>
      {!preview && isLoading && (
        <LoadingContainer>
          <LoadingIcon icon="spinner" spin />
          <FileNameInfo>
            <FileName>{fileNameInfo.name}</FileName>
            <FileExt>{fileNameInfo.ext}</FileExt>
          </FileNameInfo>
        </LoadingContainer>
      )}
      {preview && <PreviewImage src={preview} />}
      {!preview && icon && (
        <PreviewIconContainer>
          <PreviewIcon icon={icon}></PreviewIcon>
          <FileNameInfo>
            <FileName>{fileNameInfo.name}</FileName>
            <FileExt>{fileNameInfo.ext}</FileExt>
          </FileNameInfo>
        </PreviewIconContainer>
      )}
      <Overlay>
        <Buttons>
          {openReceipt && downloadURL && (
            <Button isAnchor onClick={openReceipt}>
              <Icon icon="external-link-alt" />
            </Button>
          )}
          {downloadURL && (
            <Button isAnchor onClick={downloadReceipt}>
              <Icon icon="download" />
            </Button>
          )}
          {onRemove && !isLocked && (
            <DeleteButton isAnchor onClick={handleRemove}>
              <Icon icon="trash" />
            </DeleteButton>
          )}
        </Buttons>
        <Background />
      </Overlay>
    </Container>
  );
}

export default ReceiptPreview;
export { ReceiptPreview };
