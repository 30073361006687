import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Dropdown from '../Dropdown';
import Button from '../Button';
import Icon from '../Icon';
import { colors } from '~/styles';

const ActionButton = styled(Button)`
  width: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: transparent;
  border: none;

  &:hover {
    color: ${colors.accent};
    background-color: transparent;
  }
`;

function TableMenu({ children }) {
  const onClick = (setIsOpen, event) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  return (
    <Dropdown align="right">
      {({ setIsOpen }) => (
        <>
          <Dropdown.Trigger>
            <ActionButton onClick={onClick.bind(this, setIsOpen)}>
              <Icon icon="ellipsis-h" />
            </ActionButton>
          </Dropdown.Trigger>
          <Dropdown.Menu>{_.isFunction(children) ? children({ setIsOpen }) : children}</Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
}

function TableMenuItem({ isLink = false, ...props }) {
  if (isLink) return <Dropdown.Link {...props} />;
  return <Dropdown.Item {...props} />;
}

export { TableMenu, TableMenuItem };
