import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';
import _ from 'lodash';

const expenseAdmin =
  (expenseAdminUrl) =>
  (id = '') => {
    const url = _([expenseAdminUrl, id]).compact().join('/');

    return {
      batchUpdateStatus: (body) => post(url.concat('/approvals/batch'), body),
      batchUpdateReimbursedStatus: (body) => post(url.concat('/is-reimbursed/batch'), body),
      auditing: (query = {}) => get(url.concat('/auditing?', new QueryString(query).toString())),
      exportAuditing: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      approvals: (query = {}) => get(url.concat('/approvals?', new QueryString(query).toString())),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      getPendingApprovalsCount: (query = {}) =>
        get(url.concat('/pending-approvals-count', new QueryString(query).toString(true))),
      setReimbursedStatus: (flag = true) => put(url.concat('/is-reimbursed'), { isReimbursed: flag }),
    };
  };

export default expenseAdmin;
