import { Duration } from '~/components';
import { usePercentFormat } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const ProgressContainer = styled.div`
  margin-top: 1rem;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
`;

const Legend = styled.legend`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;

  &:first-child {
    font-weight: ${weights.bold};
  }

  &:last-child {
    font-size: 0.75rem;
  }
`;

const Worked = styled.span`
  color: ${({ color }) => color};
`;

const Remaining = styled.span`
  color: ${colors.grey25};
`;

function TaskProgress({ worked = 0, pending = 0, total = null }) {
  const totalWorked = worked + pending;
  const totalRemaining = total ? total - totalWorked : 0;
  const totalWorkedPercent = total && total > 0 ? totalWorked / total : totalWorked > 0 ? 1 : 0;
  const totalRemainingPercent = total ? (total - totalWorked) / total : null;

  const percentFormat = usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 1 });
  const workedLabel = totalWorkedPercent <= 1 ? percentFormat.format(totalWorkedPercent) : null;
  const remainingLabel = percentFormat.format(totalRemainingPercent);

  let statusColor = colors.primary;
  if (total != null) {
    if (totalWorkedPercent > 0.8) statusColor = colors.warning;
    if (totalWorkedPercent > 1) statusColor = colors.danger;
  }

  return (
    <ProgressContainer>
      <Legend>
        <Worked color={statusColor}>
          <Duration minutes={totalWorked} useDecimal trim /> Worked
        </Worked>
        {_.isNumber(total) && (
          <Remaining>
            <Duration minutes={totalRemaining} useDecimal trim /> Remaining
          </Remaining>
        )}
      </Legend>
      <Progress>
        <ProgressLine width={`${Math.min(totalWorkedPercent * 100, 100)}%`} color={statusColor} />
      </Progress>
      <Legend>
        <Worked color={statusColor}>{workedLabel}</Worked>
        {_.isNumber(total) && <Remaining>{remainingLabel}</Remaining>}
      </Legend>
    </ProgressContainer>
  );
}

export default TaskProgress;
