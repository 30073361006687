import { Button, Icon } from '~/components';
import { useIsMounted, useOnClickOutside } from '~/hooks';
import { rgba } from 'polished';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';

const Trigger = styled(Button)`
  padding: 0;
  color: ${colors.grey55};
  background-color: transparent;
  border: none;
  opacity: 0.5;

  &:hover {
    color: ${colors.grey55};
    background-color: transparent;
    opacity: 1;
  }

  transition: opacity 150ms ease-in-out;
`;

const Popper = styled.div`
  z-index: 300;
  transition: opacity 50ms ease-out;
  font-size: 0.75rem;

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }
`;

const Content = styled.div`
  padding: 0.5rem 0;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  box-shadow:
    0 0.5em 1em -0.125em ${rgba(colors.black, 0.1)},
    0 0 0 1px ${rgba(colors.black, 0.02)};
  min-width: 10rem;
`;

function Menu({ children, onClickOutside }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const menuRef = useRef();
  useOnClickOutside(menuRef, onClickOutside);

  return (
    <div ref={setReferenceElement}>
      {ReactDOM.createPortal(
        <Popper ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <Content ref={menuRef}>{children}</Content>
        </Popper>,
        document.body,
      )}
    </div>
  );
}

const ItemButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${colors.black};
  text-align: left;
  position: relative;

  &:hover {
    background-color: ${colors.grey5};
    color: ${colors.black};
  }

  &:disabled {
    cursor: not-allowed;

    &&&:hover {
      background: none;
      font-weight: normal;
      color: ${colors.black};
    }
  }
`;

function Item({ children, ...props }) {
  return (
    <ItemButton isAnchor {...props}>
      {children}
    </ItemButton>
  );
}

export default function SidebarMenu({ children }) {
  const [isOpen, setIsOpen] = useState();
  const menuRef = useRef();
  const isMounted = useIsMounted();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetIsOpen = (flag) => {
    if (!isMounted.current) return;
    setIsOpen(!!flag);
  };

  const handleClickOutside = (event) => {
    if (isOpen) {
      if (menuRef.current?.contains(event.target)) return;
      setIsOpen(false);
    }
  };

  return (
    <div ref={menuRef}>
      <div>
        <Trigger onClick={handleToggle}>
          <Icon icon="ellipsis-h" />
        </Trigger>
      </div>
      {isOpen && (
        <Menu onClickOutside={handleClickOutside}>
          {typeof children === 'function' ? children({ setIsOpen: handleSetIsOpen, isOpen }) : children}
        </Menu>
      )}
    </div>
  );
}

SidebarMenu.Item = Item;
