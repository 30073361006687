import { InlineTooltip } from '~/components';
import { useWorkspace } from '~/contexts';
import { useAuth } from '~/hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { dateFormats } from '~/utils';
import HeaderSchedule from './HeaderSchedule';
import {
  HeaderNav,
  HeaderNavButton,
  HeaderScrollPad,
  HeaderSidebar,
  HeaderSidebarContent,
  HeaderStyles,
  HeaderTimeAxis,
} from './HeaderStyles';

function Header({ start, end, unit, styles, navigation, onDateChange }) {
  const { workspace } = useWorkspace();
  const auth = useAuth();
  const url = useMemo(() => `/app/${workspace.key}/resources/allocations`, [workspace.key]);

  const endDate = useMemo(
    () =>
      ({
        day: moment(end).add(3, 'weeks').format(dateFormats.isoDate),
        week: moment(end).add(6, 'weeks').format(dateFormats.isoDate),
        month: moment(end).add(6, 'months').format(dateFormats.isoDate),
      })[unit],

    [end, unit],
  );

  const history = useHistory();
  const routeMatch = useRouteMatch();

  return (
    <HeaderStyles>
      <HeaderSidebar style={{ width: styles.sidebar.width }}>
        <HeaderSidebarContent>
          {navigation && (
            <HeaderNav style={{ margin: '0 auto' }}>
              <HeaderNavButton
                $isActive={routeMatch.path.endsWith('/members')}
                onClick={() => history.push(url.concat('/members'))}
                disabled={!auth.allocations.members.view}>
                Members
                {!auth.allocations.members.view && (
                  <InlineTooltip message="Insufficient permissions to access this view." />
                )}
              </HeaderNavButton>

              <HeaderNavButton
                $isActive={routeMatch.path.endsWith('/projects')}
                onClick={() => history.push(url.concat('/projects'))}
                disabled={!auth.allocations.projects.view}>
                Projects
                {!auth.allocations.projects.view && (
                  <InlineTooltip message="Insufficient permissions to access this view." />
                )}
              </HeaderNavButton>
            </HeaderNav>
          )}
        </HeaderSidebarContent>
      </HeaderSidebar>

      <HeaderTimeAxis>
        <HeaderSchedule start={start} end={endDate} styles={styles} unit={unit} onDateChange={onDateChange} />
      </HeaderTimeAxis>

      <HeaderScrollPad style={{ flexBasis: styles.scrollbar.width }} />
    </HeaderStyles>
  );
}

export default Header;
