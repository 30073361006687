import { Button, Currency, Icon, Level, SingleSelect, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import RuddrLogo from '../assets/ruddr-logo.svg?react';
import PdfDialog from './PdfDialog';
import CreditNotePreview from './Preview';

const Container = styled.div`
  width: 100%;
  padding: 0 3.125rem;
  background: ${colors.grey5};
  flex: 1;
  display: flex;
  flex-direction: column;

  @media print {
    background: none;
    padding: 0;
  }
`;

const HeaderSection = styled.div`
  padding: 1.875rem 0;

  @media print {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  align-self: center;
  width: 13.125rem;
  height: 2.5rem;
`;

const ActionButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  color: ${colors.primary};
  background-color: ${colors.white};
  padding: 0;
  margin-left: auto;
`;

const FooterSection = styled.div`
  padding: 1.875rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  @media print {
    display: none;
  }
`;

const initialState = {
  isReady: false,
  creditNote: null,
  creditNotes: [],
};

const handlers = {
  ready: ({ creditNote, creditNotes }) => ({ isReady: true, creditNote, creditNotes }),
};

function CreditNotePreviewPage() {
  const api = useApi();
  const [{ isReady, creditNote, creditNotes }, actions] = useActions(handlers, initialState);

  const { workspaceKey, creditNoteId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [pdfDialogVisible, setPdfDialogVisible] = useState(false);

  const preview = new URLSearchParams(location.search).get('preview') === 'true';
  const title = useDocumentTitle(preview ? 'Credit Note (Preview)' : 'Credit Note', preview);

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { creditNote, creditNotes },
      } = await api.creditNotes({ workspaceKey, creditNoteId }).webLink();

      actions.ready({ creditNote, creditNotes });
    } catch (error) {
      actions.ready({ creditNote: null });
    }
  }, [actions, workspaceKey, creditNoteId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (creditNote && creditNote.number) {
      title.set(preview ? `Credit Note #${creditNote.number} (Preview)` : `Credit Note #${creditNote.number}`);
    }
  }, [creditNote, preview, title]);

  if (!isReady) return <PageLoader />;
  if (!creditNote) return <ErrorPage.NotFound publicSite={false} />;

  const handleClose = () => {
    window.close();
  };

  const handleCreditNoteSelectChange = (event) => {
    history.push(`/${workspaceKey}/credit-notes/${event.target.value}`);
  };

  const locale = creditNote.client?.locale ?? creditNote.workspace.locale;

  return (
    <Container>
      <HeaderSection>
        <Level>
          <Level.Item>
            {!preview && creditNote.status.id !== 'draft' && creditNotes && (
              <SingleSelect value={creditNote.id} onChange={handleCreditNoteSelectChange}>
                {creditNotes.map((item) => (
                  <option key={item.id} value={item.id}>
                    Credit Note #{item.number} for{' '}
                    <Currency value={item.total} currency={item.currency} locale={locale} />
                  </option>
                ))}
              </SingleSelect>
            )}
          </Level.Item>
          <Level.Item>{preview ? <CloseButton onClick={handleClose}>Close</CloseButton> : null}</Level.Item>

          <Level.Item right>
            <Level>
              <Level.Item narrow>
                <Tooltip message="Download Credit Note PDF.">
                  <ActionButton onClick={() => setPdfDialogVisible((isVisible) => !isVisible)}>
                    <Icon icon="file-pdf" />
                  </ActionButton>
                </Tooltip>
              </Level.Item>
            </Level>
          </Level.Item>
        </Level>
      </HeaderSection>

      <Switch>
        <Route path={path} exact>
          <CreditNotePreview creditNote={creditNote} />
        </Route>
      </Switch>

      <FooterSection>
        <Link to="/">
          <RuddrLogo />
        </Link>
      </FooterSection>

      {pdfDialogVisible && <PdfDialog creditNote={creditNote} onClose={() => setPdfDialogVisible(false)} />}
    </Container>
  );
}

export default CreditNotePreviewPage;
