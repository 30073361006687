import styled from 'styled-components';
import React from 'react';
import { colors, weights } from '~/styles';

const IntegrationStatus = styled.span`
  padding: 0.125rem 0.5rem;
  background-color: ${colors.white};
  color: ${({ color }) => color ?? colors.red};
  font-weight: ${weights.bold};
`;

const IntegrationTable = styled.table``;

const TableHeader = styled.thead`
  font-weight: bold;
  color: ${colors.grey25};
`;

const TableHeaderCell = styled.th`
  padding: 0 0.5rem;
  &:first-child {
    padding: 0 0rem;
  }
`;

const TableCell = styled.td`
  text-align: right;
`;

const integrationLabels = {
  bamboo: 'Bamboo HR',
  qbo: 'Quickbooks Online',
  slack: 'Slack Connection',
  expensify: 'Expensify Connection',
  xero: 'Xero Connection',
  salesforce: 'Salesforce Connection',
};

const IntegrationList = ({ integrations }) => {
  return (
    <IntegrationTable>
      <TableHeader>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Weight</TableHeaderCell>
          <TableHeaderCell>Score</TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        {Object.entries(integrations).map(([integration, details]) => {
          const { connected, weight, usageScore } = details;
          return (
            <tr key={integration}>
              <TableCell>{integrationLabels[integration]}</TableCell>
              <TableCell>
                <IntegrationStatus color={connected ? colors.success100 : colors.danger}>
                  {connected ? 'On' : 'Off'}
                </IntegrationStatus>
              </TableCell>
              <TableCell>{weight}</TableCell>
              <TableCell>{usageScore}</TableCell>
            </tr>
          );
        })}
      </tbody>
    </IntegrationTable>
  );
};

export default IntegrationList;
