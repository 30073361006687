import { DateTime } from '~/components';
import React from 'react';
import { Tag } from './PageComponents';

export default function StatusTag({ clientApproval }) {
  return (
    <Tag>
      {
        {
          not_sent: clientApproval.status.name,
          sent: (
            <span>
              Sent to{' '}
              {clientApproval.emails.map((email, index) => [
                index > 0 && ', ',
                <a key={index} href={`mailto:${email}`}>
                  {email}
                </a>,
              ])}{' '}
              on <DateTime value={clientApproval.sentAt} />
            </span>
          ),
          submitted: (
            <span>
              Submitted {clientApproval.reviewerName && `by ${clientApproval.reviewerName}`} on{' '}
              <DateTime value={clientApproval.submittedAt} />
            </span>
          ),
        }[clientApproval.statusId]
      }
    </Tag>
  );
}
