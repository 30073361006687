import _ from 'lodash';
import { del, get, post } from './agent';

const expensify = (workspacesUrl) => {
  const url = _([workspacesUrl, 'expensify']).compact().join('/');

  return {
    get: () => get(url),
    getSyncs: () => get(url.concat('/syncs')),
    getSyncLog: (id) => get(url.concat('/syncs/', id, '/log')),
    setup: (body) => post(url, body),
    remove: () => del(url),
    getPolicies: (body) => post(url.concat('/policies'), body),
    getPeople: () => get(url.concat('/people')),
    getTags: () => get(url.concat('/tags')),
    getProjectMappings: () => get(url.concat('/project-mappings')),
    updateProjectMappings: (body) => post(url.concat('/project-mappings'), body),
    updateProjectRequired: (body) => post(url.concat('/project-required'), body),
    getMemberMappings: () => get(url.concat('/member-mappings')),
    updateMemberMappings: (body) => post(url.concat('/member-mappings'), body),
    updateMemberMappingType: (body) => post(url.concat('/member-mapping-type'), body),
    updateCatgorySettings: (body) => post(url.concat('/category-settings'), body),
    updateSyncSettings: (body) => post(url.concat('/sync-settings'), body),
    manualSync: (body) => post(url.concat('/manual-sync'), body),
    deleteData: () => del(url.concat('/data')),
  };
};

export default expensify;
