import { Checkbox, Currency, DateTime, DayPickerInput, Level, MemberSelect, SingleSelect, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 2rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

const Amount = styled.div`
  position: relative;
  padding: 0rem 0.75rem;
  border-radius: 999rem;
  text-align: right;
  font-weight: ${weights.bold};

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          background: ${colors.grey10};
          color: ${colors.black};
        `,
        pending_approval: css`
          background: ${colors.warning10};
          color: ${colors.warning};
        `,
        rejected: css`
          background: ${colors.danger10};
          color: ${colors.danger};
        `,
        approved: css`
          background: ${colors.primary10};
          color: ${colors.primary};
        `,
      })[status]}
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
`;

const DetailsContent = styled.dl`
  display: flex;
  align-items: center;
  padding: 1.125rem 0;
  border-top: 1px solid ${colors.grey10};
  width: 100%;

  dt {
    font-size: 0.75rem;
    font-weight: ${weights.black};
    color: ${colors.grey40};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    margin-right: 2rem;
  }

  dd {
    font-size: 0.75rem;
    color: ${colors.grey55};

    &:not(:last-child) {
      margin-right: 5rem;
    }
  }
`;

const initialState = {
  isReady: false,
  data: null,
  query: {
    q: '',
    member: null,
  },
};
const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data, editIndex: null }),
  filter: (query, state) => ({ query: { ...state.query, ...query } }),
};

function TransactionsDrawerExpensesTab({ clientApproval, expenses, start, end, onStartChange, onEndChange, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, query }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const q = {
        ..._.omit(query, 'member'),
        start: start || undefined,
        end: end || undefined,
        memberId: query.member?.id,
      };
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(clientApproval.projectId)
        .clientApprovals(clientApproval.id)
        .getExpenseTransactions(q);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, query, api, clientApproval, start, end]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!data) return;

    return {
      expenses: _.filter(data, (expense) => expenses.some((e) => e.id === expense.id)),

      get amount() {
        return _.sumBy(this.expenses, (expense) => expense.expenseAmount);
      },

      get some() {
        return this.expenses.length > 0;
      },

      get all() {
        return this.expenses.length === data.length;
      },
    };
  }, [data, expenses]);

  if (!data) return null;

  const handleBulkSelectionChange = () => {
    onChange(
      selected.some
        ? expenses.filter(({ id }) => !data.some((expense) => id === expense.id))
        : [...expenses, ...data.map(({ id }) => ({ id }))],
    );
  };

  const currency = clientApproval.project.currency;

  return (
    <div>
      <Level>
        <Level.Item>
          <MemberSelect
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={query.member}
            onChange={({ target: { value } }) => actions.filter({ member: value })}
          />
        </Level.Item>
        <Level.Item>
          <SingleSelect
            name="statusId"
            placeholder="All"
            materialPlaceholder="Approval Status"
            materialAlwaysVisible
            showEmptyOption
            value={query.statusId}
            onChange={({ target: { value } }) => actions.filter({ statusId: value })}>
            <option value="not_submitted">Not Submitted</option>
            <option value="pending_approval">Pending Approval</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </SingleSelect>
        </Level.Item>
      </Level>

      <Level margin="2rem 0">
        <Level.Item>
          <DayPickerInput value={start} placeholder="Start" onChange={(value) => onStartChange(value)} />
        </Level.Item>
        <Level.Item>
          <DayPickerInput value={end} placeholder="End" onChange={(value) => onEndChange(value)} />
        </Level.Item>
      </Level>

      <StyledTable>
        <Table.BoxHeader>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column width="6rem">Date</Table.Column>
          <Table.Column>Category</Table.Column>
          <Table.Column width="6rem" align="right">
            Amount
          </Table.Column>
        </Table.BoxHeader>
        <Table.Body>
          {data.map((expense) => {
            const checked = _(expenses).some(({ id }) => id === expense.id);

            const handleSelectionChange = () => {
              onChange(checked ? expenses.filter(({ id }) => id !== expense.id) : [...expenses, { id: expense.id }]);
            };

            return (
              <Table.BoxRow key={expense.id} wrap>
                <Table.Cell>
                  <StyledCheckbox>
                    <Checkbox checked={checked} onChange={handleSelectionChange} />
                  </StyledCheckbox>
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={expense.date} />
                </Table.Cell>
                <Table.Cell>{expense.expenseCategory.name}</Table.Cell>
                <Table.Cell>
                  <Amount status={expense.statusId}>
                    <Currency value={expense.expenseAmount} currency={currency} />
                  </Amount>
                </Table.Cell>
                <DetailsContainer>
                  <DetailsContent>
                    <dt>Details</dt>
                    <dd>{expense.member.name}</dd>
                    {expense.notes && <dd>"{expense.notes}"</dd>}
                  </DetailsContent>
                </DetailsContainer>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </StyledTable>
    </div>
  );
}

export default TransactionsDrawerExpensesTab;
