import {
  Avatar,
  Button,
  ClientLink,
  Currency,
  Grid,
  Icon,
  Level,
  MemberContactPopover,
  MultilineText,
  Page,
  PipelineActivityTypeFilter,
  SearchInput,
  SocialMediaLink,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useNumberFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import PipelineActivityForm from '../activity/PipelineActivityForm';
import { ActivityItem } from '../components/ActivityItem';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
  }
`;

const Panel = styled.div`
  position: absolute;
  width: calc(20% + 2.5rem);
  background: ${colors.grey5};
  right: -2rem;
  top: calc(-2.5rem - 1px);
  min-height: calc(100% + 4.5rem + 1px);
  padding: 2.75rem 2rem;
`;

const Info = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
`;

const NewButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

const SocialMediaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;

    &:hover {
      color: ${colors.grey55};
    }
  }
`;

function CompanyDetailsPage({ company }) {
  const documentTitle = useDocumentTitle(company.name);

  const { workspace } = useWorkspace();
  const api = useApi();

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [dialog, setDialog] = useState(null);
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          companyId: company.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });

      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          const participants = [
            ...activity.members.map((activityMember) => activityMember.member.name),
            ...activity.contacts.map((activityContact) => activityContact.contact.name),
          ];
          options.push({ type: 'activity', activity, group, participants });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, company, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  function handleOpenActivityForm({ activity }) {
    setDialog(
      <PipelineActivityForm
        company={company}
        activity={activity}
        onClose={() => {
          setDialog(null);
          documentTitle.set(company.name);
        }}
        onSaved={() => {
          setDialog(null);
          documentTitle.set(company.name);
          fetchData();
        }}
      />,
    );
  }

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!company) return <ErrorPage.NotFound />;

  return (
    <Page.Section style={{ position: 'relative', flex: 1 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="75%">
            <Section>
              <Level>
                <Level.Item>
                  <h3>Activities</h3>
                </Level.Item>
                <Level.Item right>
                  <SearchInput
                    value={params.q}
                    placeholder="Search"
                    materialPlaceholder="Subject or Content"
                    materialAlwaysVisible
                    onChange={handleChange}
                  />
                </Level.Item>
                <Level.Item>
                  <PipelineActivityTypeFilter
                    name="typeIds"
                    value={params.typeIds}
                    entity="company"
                    onChange={handleChange}
                  />
                </Level.Item>
                {company.permissions.manage && (
                  <Level.Item right narrow>
                    <NewButton onClick={() => handleOpenActivityForm({})}>
                      <Icon color={colors.primary} icon="plus" />
                    </NewButton>
                  </Level.Item>
                )}
              </Level>
              <Stack style={{ marginTop: '3rem', opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
                {status === 'loading' && <PageLoader />}
                {groupedOptions?.length > 0 &&
                  groupedOptions.map((o) => {
                    switch (o.type) {
                      case 'group':
                        return (
                          <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                            <div style={{ textTransform: 'uppercase' }}>
                              <strong>
                                {
                                  {
                                    upcoming: 'Upcoming',
                                    last30Days: 'Last 30 Days',
                                    olderThan30Days: ' Older Than 30 Days',
                                  }[o.group.key]
                                }
                              </strong>
                              <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                            </div>

                            <div>{pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}</div>
                          </GroupHeader>
                        );
                      case 'activity': {
                        if (!showActivities[o.group.key]) return null;

                        return (
                          <ActivityItem
                            key={o.activity.id}
                            item={o.activity}
                            handleOpenActivityForm={handleOpenActivityForm}
                            participants={o.participants}
                            entity="company"
                          />
                        );
                      }
                      default:
                        return null;
                    }
                  })}
                {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
              </Stack>
            </Section>
          </Grid.Column>
          <Grid.Column width="2rem"></Grid.Column>
        </Grid.Row>
      </Grid>

      <Panel>
        <Section>
          <h3>Company Details</h3>

          {company.client && (
            <InfoBlock title="Client Name">
              <ClientLink client={company.client} />
            </InfoBlock>
          )}

          {company.owner && (
            <InfoBlock title="Relationship Owner">
              <Owner>
                <MemberContactPopover member={company.owner}>
                  <Avatar value={company.owner} isCircle hasBackground initialsFontSize=".9rem" />
                </MemberContactPopover>

                <OwnerInfo>{company.owner.name}</OwnerInfo>
              </Owner>
            </InfoBlock>
          )}

          {company.description && (
            <InfoBlock title="Description">
              <MultilineText value={company.description} align={'left'} chars={280} buttonProps={{ isAnchor: true }} />
            </InfoBlock>
          )}

          {company.industry && <InfoBlock title="Industry">{company.industry.name}</InfoBlock>}

          {company.practice && <InfoBlock title="Practice">{company.practice.name}</InfoBlock>}

          {company.companySpendTier && (
            <InfoBlock title="Company Spend Tier">{company.companySpendTier.name}</InfoBlock>
          )}

          {company.companyType && <InfoBlock title="Company Type">{company.companyType.name}</InfoBlock>}

          <InfoBlock title="Currency">
            {company.currencyName} ({company.currency})
          </InfoBlock>

          {company.revenue && (
            <InfoBlock title="Revenue">
              <Currency value={company.revenue} currency={company.currency} />
            </InfoBlock>
          )}

          {company.yearFounded && <InfoBlock title="Year Founded">{company.yearFounded}</InfoBlock>}

          {company.ownershipClass && (
            <InfoBlock title="Ownership Class">
              {
                {
                  public: 'Public',
                  private: 'Private',
                  subsidiary: 'Subsidiary',
                  other: 'Other',
                }[company.ownershipClass]
              }
            </InfoBlock>
          )}

          {company.tickerSymbol && <InfoBlock title="Ticker Symbol">{company.tickerSymbol}</InfoBlock>}

          {company.sicCode && (
            <InfoBlock title="SIC Code">
              {company.sicCode.code} ({company.sicCode.industryTitle})
            </InfoBlock>
          )}

          {company.phoneNumber && <InfoBlock title="Phone Number">{company.phoneNumber}</InfoBlock>}

          {company.numberOfEmployees && <InfoBlock title="Number of Employees">{company.numberOfEmployees}</InfoBlock>}

          {company.billingAddress && <InfoBlock title="Billing Address">{company.billingAddress}</InfoBlock>}

          {company.shippingAddress && <InfoBlock title="Shipping Address">{company.shippingAddress}</InfoBlock>}

          {company.websiteUrl && (
            <InfoBlock title="Website">
              <a href={company.websiteUrl} target="_blank" rel="noopener noreferrer">
                {company.websiteUrl}
              </a>
            </InfoBlock>
          )}
          {(company.twitterUrl || company.facebookUrl || company.linkedinUrl) && (
            <InfoBlock title="Social Media">
              <SocialMediaBlock>
                {company.twitterUrl && <SocialMediaLink icon={'twitter-square'} url={company.twitterUrl} />}
                {company.linkedinUrl && <SocialMediaLink icon={'linkedin'} url={company.linkedinUrl} />}
                {company.facebookUrl && <SocialMediaLink icon={'facebook-square'} url={company.facebookUrl} />}
              </SocialMediaBlock>
            </InfoBlock>
          )}
        </Section>
      </Panel>
      {dialog}
    </Page.Section>
  );
}
export default CompanyDetailsPage;
