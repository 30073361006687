import { HelpMessage, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { PeriodsFooterRow, SettingsSection } from '../components';
import AvailabilityPeriodRow from './AvailabilityPeriodRow';

const initialState = { isReady: false, data: null };
const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data, editIndex: null }),
  edit: (editIndex) => ({ editIndex }),
};

function AvailabilityPeriods({ member }) {
  useDocumentTitle(`${member.name} Capacity`);

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, data, editIndex }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).availabilityPeriods().get();

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, member.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage publicSite={false} />;
  return (
    <>
      <HelpMessage spaceTop spaceBottom>
        In order to allocate team members to project work and accurately calculate billable utilization, Ruddr needs to
        know which days each team member is available to work and the number of hours available each day.
      </HelpMessage>
      <SettingsSection>
        <Table>
          <Table.BoxHeader>
            <Table.Column width="18rem">Date Range</Table.Column>
            <Table.Column align="center">Capacity</Table.Column>
            <Table.Column align="right" width="9rem">
              Weekly Hours
            </Table.Column>
            <Table.Column align="right" width="9rem">
              Days in Period
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {data.map((item, index) => (
              <AvailabilityPeriodRow
                key={item.id}
                period={item}
                periods={data}
                member={member}
                disableActions={editIndex !== null}
                isEditing={editIndex === index}
                onEdit={() => actions.edit(index)}
                onCancel={() => actions.edit(null)}
                onSaved={fetchData}
                onDeleted={fetchData}
              />
            ))}
            <AvailabilityPeriodRow
              period={{}}
              periods={data}
              member={member}
              isEditing={editIndex === -1}
              disableActions={editIndex !== null}
              onEdit={() => actions.edit(-1)}
              onCancel={() => actions.edit(null)}
              onSaved={fetchData}
            />
            <PeriodsFooterRow periods={data} member={member} label="capacity" />
          </Table.Body>
        </Table>
      </SettingsSection>
    </>
  );
}

export default AvailabilityPeriods;
