const combineNames = (firstName, lastName) => {
  // Check if either first name or last name is null or contains only whitespace
  if ((!firstName && !lastName) || (firstName.trim() === '' && lastName.trim() === '')) {
    return 'Invalid name input';
  }

  // Trim leading/trailing whitespace from both names and concatenate them with a space in between
  return [firstName, lastName]
    .map((name) => name.trim())
    .filter((name) => name !== '')
    .join(' ');
};

export default combineNames;
