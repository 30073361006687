import { Button } from '~/components';
import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: 0.5rem;

  ${Button} {
    font-size: 0.875rem;
  }

  &:not(:first-child) {
    padding-top: 1rem;
  }
`;
