import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { Button, Table } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { DownloadArea, EmptyReport, Report, ReportLoader, Workspace } from './components';

const formatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 2,
});

const Created = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreatedBy = styled.div`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CreatedOn = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

export default function MembersPerWorkspace({ start, end, showPaying }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getMembersPerWorkspace({ start, end, showPaying });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api, start, end, showPaying]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getMembersPerWorkspace({ start, end, showPaying });
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <Table>
        <Table.Header>
          <Table.Column>Workspace</Table.Column>
          <Table.Column width="20rem">Created</Table.Column>
          <Table.Column width="10rem" align="right">
            Billable Members
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Non-Billable Members
          </Table.Column>
          <Table.Column width="10rem" align="right" name="total" sort={{ column: 'total', direction: 'desc' }}>
            Total Members
          </Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              <Workspace data={item} />
            </Table.Cell>
            <Table.Cell>
              <Created>
                <CreatedBy>{item.email}</CreatedBy>
                <CreatedOn>{moment(item.created).format(dateFormats.compactDate)}</CreatedOn>
              </Created>
            </Table.Cell>
            <Table.Cell>{formatter.format(item.billable)}</Table.Cell>
            <Table.Cell>{formatter.format(item.nonBillable)}</Table.Cell>
            <Table.Cell>{formatter.format(item.total)}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
