import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { ChartWrapper, Loading } from './components';

export default function Hourly() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const now = moment();
    const end = now.format(dateFormats.isoDate);
    const start = now.subtract(3, 'days').format(dateFormats.isoDate);
    const getData = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getHourlyActiveUsers({ start, end });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
    return getData.cancel;
  }, [api]);

  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      labels: data.map((item) => moment(item.date).add(item.hour, 'hours').toDate()),
      datasets: [
        {
          label: 'Users',
          data: data.map((item) => item.count),
          backgroundColor: colors.primary25,
          borderColor: colors.primary,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }, [data]);

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'ddd, MMM D',
          },
        },
      },

      y: {
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Hourly Activity (3 days)',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ([tooltip]) => {
            const time = moment(tooltip.parsed.x);
            return [time.format('dddd, MMMM D, YYYY'), time.format('h:mm a')].join('\n');
          },
        },
      },
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ChartWrapper>
      <Line data={chartData} options={options} />
    </ChartWrapper>
  );
}
