import { usePercentFormat } from '~/hooks';
import _ from 'lodash';

export default function Percent({ value, ...props }) {
  const percentFormat = usePercentFormat(props);

  if (!_.isNumber(value) || isNaN(value)) return '-';

  return percentFormat.format(value);
}
