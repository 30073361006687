import _ from 'lodash';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import React from 'react';
import Tag from './Tag';

export default function TimeClientApprovalStatusTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;

  return (
    <Tag label="Time Client Approval Status" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? clientApprovalStatuses[v].name)
        .join('; ')}
    </Tag>
  );
}
