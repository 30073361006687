import styled from 'styled-components';
import { colors, weights } from '~/styles';

export const TableTitle = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  color: ${colors.grey75};
  font-size: 0.875rem;
  font-weight: ${weights.bold};

  &:not(:first-child) {
    padding-top: 1rem;
  }
`;
