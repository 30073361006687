import { css } from 'styled-components';
import colors from '../colors';
import weights from '../weights';

// https://github.com/FortAwesome/Font-Awesome/blob/master/svgs/solid/angle-down.svg
const arrow = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" fill="${colors.grey25}"/></svg>`;

export default css`
  button,
  input,
  select,
  textarea {
    font-size: 1rem;
    line-height: 1.4;
    color: ${colors.black};
    outline: none;
    box-shadow: none;
  }

  /* Button-like */
  .button,
  button,
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    padding: 0.5em 1.75em;
    color: ${colors.white};
    font-weight: ${weights.bold};
    white-space: nowrap;
    background-color: ${colors.primary};
    border: solid 1px transparent;
    border-radius: 999rem;
    cursor: pointer;

    &:hover {
      color: ${colors.white};
      background-color: ${colors.accent};
    }
  }

  /* Text-like */
  input:not([type]),
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    max-width: 100%;
    padding: 0.5em 0.875em;
    background-color: ${colors.white};
    border: solid 1px ${colors.grey10};
    border-radius: 0.3125rem;

    &:focus {
      border-color: ${colors.primary};
    }
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  select {
    padding-right: 2.375em;
    background-image: url('data:image/svg+xml;base64,${btoa(arrow)}');
    background-position: right 0.875em top 50%;
    background-repeat: no-repeat;
    background-size: 0.625em auto;

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      color: ${colors.grey40};
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }

    &:invalid {
      color: ${colors.grey40};
    }
  }

  textarea {
    resize: vertical;
  }

  ::placeholder {
    color: ${colors.grey40};
    opacity: 1;
  }
`;
