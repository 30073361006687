import moment from 'moment';
import React, { useMemo } from 'react';
import { dateFormats } from '~/utils';
import theme from '../theme';
import { HeaderCell, HeaderCellContent, HeaderColumn, HeaderGroupCellContent, HeaderRow } from './HeaderStyles';

function MonthHeader({ start, end, styles, onDateChange }) {
  const months = useMemo(() => {
    const monthCount = moment(end).diff(start, 'months') + 1;

    const months = [];

    for (let index = 0; index < monthCount; index++) {
      const date = moment(start).add(index, 'months');

      months.push({
        key: date.format(dateFormats.isoDate),
        month: date.format('MMMM'),
        today: moment().isSame(date, 'month'),
        style: {
          width: styles.date.width * date.daysInMonth(),
          borderColor: theme.line.strong,
        },
      });
    }

    return months;
  }, [start, end, styles.date.width]);

  const years = useMemo(() => {
    const yearCount = moment(end).diff(start, 'years') + 1;

    const years = [];

    const yearStart = moment(start).startOf('year').format(dateFormats.isoDate);

    for (let index = 0; index < yearCount; index++) {
      const date = moment(yearStart).add(index, 'years');

      const year = {
        start: moment.max(moment(date).startOf('year'), moment(start)),
        end: moment.min(moment(date).endOf('year'), moment(end)),

        get days() {
          return moment(this.end).diff(this.start, 'days') + 1;
        },
      };

      years.push({
        key: date.format(dateFormats.isoDate),
        label: date.format('YYYY'),
        style: {
          width: styles.date.width * year.days,
        },
      });
    }

    return years;
  }, [start, end, styles.date.width]);

  return (
    <HeaderColumn>
      <HeaderRow>
        {years.map((year) => {
          const handleClick = () => onDateChange(year.key);

          return (
            <HeaderCell key={year.key} style={year.style} onClick={handleClick}>
              <HeaderGroupCellContent>
                <span>{year.label}</span>
                <span>{year.label}</span>
              </HeaderGroupCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>

      <HeaderRow>
        {months.map((month) => {
          const handleClick = () => onDateChange(month.key);

          return (
            <HeaderCell key={month.key} style={month.style} today={month.today} onClick={handleClick}>
              <HeaderCellContent>{month.month}</HeaderCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>
    </HeaderColumn>
  );
}

export default React.memo(MonthHeader);
