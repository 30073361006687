import { BackLink, ClientLink, Level, Stack, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import Icon from '../../../../components/Icon';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import useClientFilters from '~/hooks/useClientFilters';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import TotalResults from '../components/TotalResults';
import PartialResultsTooltip from '../components/PartialResultsTooltip';

const Byline = styled.div`
  display: flex;
  padding-top: 0.5rem;
  font-size: 0.875rem;
`;
const ContactIcon = styled.div`
  display: flex;
  color: ${colors.primary};
  align-items: center;
  justify-content: center;
  width: 2rem;
`;
const ContactLabel = styled.div`
  text-align: left;
  color: ${colors.grey100};
`;
const Contact = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export default function ClientContacts() {
  useDocumentTitle('Client Contacts');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters(() => ({
    clientRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    sort: new QuerySort('client.name', 'asc'),
    ...clientFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        sort: { default: new QuerySort('client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().clientContacts(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .clientContacts(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

        <Stack>
          <h1>Client Contacts</h1>

          <Tags>
            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  const groups = useMemo(() => {
    return _.groupBy(report.records, (record) => `${record.client.id}`);
  }, [report.records]);

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <TotalResults>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Client" value={_.size(groups)} />
        {report.partialResults && <PartialResultsTooltip />}
      </TotalResults>

      <Table>
        <Table.Header sticky>
          <Table.Column name="client.name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column name="contact.title">Title</Table.Column>
          <Table.Column name="contact" align="left">
            Contact Info
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.status === 'sorting'}>
          {_.map(groups, (contacts, clientId) => {
            return (
              <React.Fragment key={clientId}>
                <GroupRow top="2.95rem">
                  <Table.Cell>
                    <strong>
                      <ClientLink client={contacts[0].client} />
                    </strong>
                  </Table.Cell>
                </GroupRow>

                {contacts.map((contact) => {
                  return (
                    <Table.Row key={contact.id}>
                      <Table.Cell>
                        <Contact>{contact.name}</Contact>
                      </Table.Cell>
                      <Table.Cell>{contact.title}</Table.Cell>
                      <Table.Cell>
                        <div>
                          {contact.email && (
                            <a href={`mailto:${contact.email}`}>
                              <Byline>
                                <ContactIcon>
                                  <Icon icon="at" />
                                </ContactIcon>
                                <ContactLabel>{contact.email}</ContactLabel>
                              </Byline>
                            </a>
                          )}
                          {contact.mobilePhone && (
                            <Byline>
                              <ContactIcon>
                                <Icon icon="mobile" />
                              </ContactIcon>
                              <ContactLabel>{contact.mobilePhone}</ContactLabel>
                            </Byline>
                          )}
                          {contact.officePhone && (
                            <Byline>
                              <ContactIcon>
                                <Icon icon="phone-office" />
                              </ContactIcon>
                              <ContactLabel>{contact.officePhone}</ContactLabel>
                            </Byline>
                          )}
                          {contact.fax > 0 && (
                            <Byline>
                              <ContactIcon>
                                <Icon icon="fax" />
                              </ContactIcon>
                              <ContactLabel>{contact.fax}</ContactLabel>
                            </Byline>
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
