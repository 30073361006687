import React, { useState } from 'react';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useToast } from '~/contexts';
import { colors, weights } from '~/styles';
import { ReceiptPreview } from './ReceiptPreview';

const MAX_FILE_SIZE = 10485760; // 10mb in bytes

const ReceiptsContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
`;

function ReceiptFiles({ onRemove, onAdd, receiptsAdded, receipts, isLocked }) {
  const toast = useToast();
  const [newReceipts, setNewReceipts] = useState(receiptsAdded);

  const handleRemove = (removedReceipt) => {
    onRemove(removedReceipt.id);
  };

  const onDrop = (newFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 10 MB.`);
      }
      if (_.find(file.errors, { code: 'file-invalid-type' })) {
        toast.error(`The file type "${file.file.type}" is not supported.`);
      }
    }

    newFiles.forEach((file) => {
      file.preview = URL.createObjectURL(file);
      file.status = 'upload';
      file.id = uuid();
    });
    onAdd(newFiles);
    setNewReceipts(newReceipts.concat(newFiles));
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    onDrop,
    accept: {
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/rtf': ['.rtf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/xml': ['.xml'],
      'image/jpg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/tif': ['.tif', '.tiff'],
      'image/heic': ['.heic'],
      'text/html': ['.htm', '.html'],
      'text/plain': ['.txt'],
    },
  });

  return (
    <ReceiptsContent classname="receiptsContent">
      {onAdd && (
        <DropzoneContainer classname="dropzoneContainer">
          <DropzoneInterior {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drop files here or click to select files</p>
          </DropzoneInterior>
        </DropzoneContainer>
      )}
      <Previews>
        {receipts &&
          receipts?.length > 0 &&
          receipts.map((r) => (
            <ReceiptPreview key={r.id} receipt={r} isLocked={isLocked} onRemove={handleRemove}></ReceiptPreview>
          ))}

        {newReceipts &&
          newReceipts?.length > 0 &&
          newReceipts.map((r) => (
            <ReceiptPreview key={r.id} receipt={r} isLocked={isLocked} onRemove={handleRemove}></ReceiptPreview>
          ))}
      </Previews>
    </ReceiptsContent>
  );
}

export default ReceiptFiles;
