import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '~/assets/logo-reversed.svg';
import { useChat } from '~/contexts';
import { colors } from '~/styles';
import ResponsiveContainer from './ResponsiveContainer';
import { PUBLIC_BASE_URL } from '~/utils/redirectPublic';

const Wrapper = styled.footer`
  width: 100%;
  margin-top: auto;
  background-color: ${colors.black};
`;

const Container = styled(ResponsiveContainer)`
  max-width: none;
  padding: 2.5rem 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: ${colors.white};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Logo = styled.img`
  display: block;
  height: 2.5rem;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-top: 2.5rem;
  margin-right: auto;
`;

const InfoLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoLink = styled.a`
  color: ${colors.white};
  font-size: 0.75rem;

  &:hover {
    color: ${colors.grey25};
  }
`;

const InfoSeperator = styled.div`
  width: 1px;
  height: 9px;
  margin: 0 0.5rem;
  background-color: ${colors.grey25};
`;

const InfoText = styled.div`
  margin-top: 0.625rem;
  font-size: 0.75rem;
`;

export default function Footer() {
  const chat = useChat();

  useEffect(() => {
    chat.show();
    return () => chat.hide();
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <LogoContainer>
            <Link to="/">
              <Logo src={logo} alt="Ruddr" />
            </Link>
          </LogoContainer>
          <Information>
            <InfoLinks>
              <InfoLink href={`${PUBLIC_BASE_URL}/privacy-policy`}>Privacy Policy</InfoLink>
              <InfoSeperator />
              <InfoLink href="https://status.ruddr.io">System Status</InfoLink>
              <InfoSeperator />
              <InfoLink href={`${PUBLIC_BASE_URL}/service-level-agreement`}>SLA</InfoLink>
              <InfoSeperator />
              <InfoLink href={`${PUBLIC_BASE_URL}/master-subscription-agreement`}>MSA</InfoLink>
            </InfoLinks>
            <InfoText>We Do Not Sell or Share Your Personal Information</InfoText>
            <InfoText>&copy; 2018-2024. All Rights Reserved.</InfoText>
          </Information>
        </Content>
      </Container>
    </Wrapper>
  );
}
