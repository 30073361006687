import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Button, Icon, SingleSelect, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull } from '~/utils';
import Actions from './Actions';
import Members from './Members';
import Usage from './Usage';
import { useImpersonation } from '../contexts';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

const StatusInput = styled.div`
  width: 13rem;
  margin-left: 1rem;
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
  user-select: none;
`;

const QuickActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  width: 5rem;
`;

const QuickImpersonate = styled.div`
  display: flex;
  margin-left: 2rem;
`;

const QuickKey = styled.div`
  display: flex;
  margin-left: 1rem;
`;

const ResultIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  transform: rotate(-90deg);
  transition: transform 0.1s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(0deg);
    `}
`;

const ResultIcon = styled(Icon)`
  color: ${colors.grey55};
`;

const TitleInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 10rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  min-width: 5rem;
  margin-left: 0.5rem;
  align-items: ${({ align = 'left' }) => align};
`;

const StatTitle = styled.div`
  font-weight: ${weights.medium};
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Logo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

const WorkspaceImage = styled.img`
  max-height: 100%;
  object-fit: contain;
`;

const BuildingIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

const DetailNav = styled.ul`
  display: flex;
  padding: 1rem 1.25rem;
  border-top: solid 1px ${colors.grey10};
`;

const DetailNavItem = styled.li`
  font-weight: ${({ isActive }) => (isActive ? weights.bold : weights.normal)};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &:hover {
    color: ${colors.grey75};
  }
`;

const StatusIndicator = styled.span`
  .icon {
    font-size: 0.625rem;
    margin-right: 0.7rem;
    color: ${({ status }) =>
      ({
        active: colors.green,
        restricted: colors.warning,
        disabled: colors.danger,
      })[status]};
  }
`;

const Tag = styled.div`
  border-radius: 2rem;
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  white-space: nowrap;
  color: ${({ color = colors.accent }) => color};
  background-color: ${({ background = colors.primary10 }) => background};
`;

export default function Search() {
  const api = useApi();
  const { impersonate } = useImpersonation();
  const { navigateWorkspace } = useWorkspace();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('active,restricted');
  const [searchResults, setSearchResults] = useState();
  const [viewState, setViewState] = useState({});
  const searchRef = useRef();

  const search = useCallback(async () => {
    const params = { text: searchText, statusId: searchStatus };
    const cleanedParams = _.omitBy(emptyStringToNull(params), _.isNil);

    setIsSearching(true);
    try {
      const { data } = await api.admin.workspaces.get(cleanedParams);
      setSearchResults(data);
    } finally {
      setIsSearching(false);
    }
  }, [api, searchText, searchStatus]);

  useEffect(() => {
    search();
    return search.cancel;
  }, [search]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitSearchChange = useCallback(
    _.debounce((value) => setSearchText(value), 250),
    [],
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    emitSearchChange(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const toggleDetails = (workspaceId) => {
    setViewState((current) => {
      const next = { ...current };
      if (!next[workspaceId]) {
        next[workspaceId] = {
          open: false,
          view: 'usage',
        };
      }
      next[workspaceId].open = !next[workspaceId].open;
      return next;
    });
  };

  const changeView = (workspaceId, view) => {
    setViewState((current) => {
      const next = { ...current };
      next[workspaceId].view = view;
      return next;
    });
  };

  return (
    <SearchArea>
      <SearchFilters>
        <SearchInput>
          <input
            ref={searchRef}
            value={searchValue}
            onChange={handleSearchChange}
            type="search"
            placeholder="Find a workspace"
          />
          {!!searchValue && (
            <ClearButton isAnchor={true} onClick={handleClearSearch}>
              <Icon icon="times" />
            </ClearButton>
          )}
          {isSearching && (
            <SearchIndicator>
              <Icon icon="spinner" spin={true} />
            </SearchIndicator>
          )}
        </SearchInput>
        <StatusInput>
          <SingleSelect
            placeholder="Status"
            showEmptyOption={true}
            value={searchStatus}
            onChange={(event) => setSearchStatus(event.target.value)}>
            <option value="active">Active</option>
            <option value="restricted">Restricted</option>
            <option value="disabled">Disabled</option>
            <option value="active,restricted">Not Disabled</option>
          </SingleSelect>
        </StatusInput>
      </SearchFilters>
      {searchResults && (
        <SearchResults>
          {searchResults.map((workspace) => (
            <SearchResult key={`workspace_${workspace.id}`}>
              <Result onClick={() => toggleDetails(workspace.id)}>
                <Logo>
                  {workspace.imageUrl ? (
                    <WorkspaceImage src={workspace.imageUrl} alt={workspace.name} />
                  ) : (
                    <BuildingIcon icon="building" />
                  )}
                </Logo>
                <TitleInfo>
                  <Title>{workspace.name}</Title>
                  <Subtitle>{workspace.key}</Subtitle>
                </TitleInfo>
                <StatInfo width="7rem" align="center">
                  <Tag
                    color={{ pro: colors.accent, team: colors.grey100 }[workspace.stripeProductKey]}
                    background={{ pro: colors.primary10, team: colors.grey10 }[workspace.stripeProductKey]}>
                    {{ pro: 'Pro Plan', team: 'Team Plan' }[workspace.stripeProductKey]}
                  </Tag>
                </StatInfo>
                <StatInfo width="6rem">
                  <StatTitle>
                    <StatusIndicator status={workspace.statusId}>
                      <>
                        <Icon icon="circle" />
                        <span>
                          {{ active: 'Active', restricted: 'Restricted', disabled: 'Disabled' }[workspace.statusId]}
                        </span>
                      </>
                    </StatusIndicator>
                  </StatTitle>
                </StatInfo>
                <StatInfo width="7rem" align="center">
                  <StatTitle>{moment(workspace.createdAt).format(dateFormats.compactDate)}</StatTitle>
                  <Subtitle>{`created ${moment(workspace.createdAt).fromNow(true)} ago`}</Subtitle>
                </StatInfo>

                <StatInfo width="5rem" align="center">
                  <StatTitle>{workspace.memberCount} </StatTitle>
                  <Subtitle>{workspace.memberCount === 1 ? 'member' : 'members'}</Subtitle>
                </StatInfo>

                <QuickActions>
                  <QuickImpersonate
                    onClick={async (event) => {
                      await impersonate(workspace.defaultImpersonatedMemberId);
                      await navigateWorkspace(workspace.key);
                      event.stopPropagation();
                    }}>
                    <Tooltip message="Default Impersonate">
                      <Icon icon="user-secret" color={colors.primary} />
                    </Tooltip>
                  </QuickImpersonate>
                  <QuickKey
                    onClick={(event) => {
                      navigator.clipboard.writeText(workspace.id);
                      event.stopPropagation();
                    }}>
                    <Tooltip message={`Copy Workspace ID to Clipboard`}>
                      <Icon icon="clipboard" color={colors.primary} />
                    </Tooltip>
                  </QuickKey>
                </QuickActions>
                <ResultIndicator isOpen={viewState[workspace.id]?.open === true}>
                  <ResultIcon icon="angle-down" />
                </ResultIndicator>
              </Result>
              {viewState[workspace.id]?.open === true && (
                <>
                  <DetailNav>
                    <DetailNavItem
                      isActive={viewState[workspace.id]?.view === 'usage'}
                      onClick={() => changeView(workspace.id, 'usage')}>
                      Usage
                    </DetailNavItem>
                    <DetailNavItem
                      isActive={viewState[workspace.id]?.view === 'members'}
                      onClick={() => changeView(workspace.id, 'members')}>
                      Members
                    </DetailNavItem>
                    <DetailNavItem
                      isActive={viewState[workspace.id]?.view === 'actions'}
                      onClick={() => changeView(workspace.id, 'actions')}>
                      Actions
                    </DetailNavItem>
                  </DetailNav>
                  {viewState[workspace.id]?.view === 'usage' && <Usage workspaceId={workspace.id} />}
                  {viewState[workspace.id]?.view === 'members' && <Members workspaceId={workspace.id} />}
                  {viewState[workspace.id]?.view === 'actions' && (
                    <Actions workspace={workspace} onChange={() => search()} />
                  )}
                </>
              )}
            </SearchResult>
          ))}
        </SearchResults>
      )}
    </SearchArea>
  );
}
