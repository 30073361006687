import React from 'react';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import ExpenseDetail from './ExpenseDetail';
import ExpenseReportsPage from './ExpenseReportsPage';
import ExpenseSummaryByExpenseCategory from './ExpenseSummaryByExpenseCategory';
import ExpenseSummaryByClientAndProject from './ExpenseSummaryByClientAndProject';
import ExpenseSummaryByMember from './ExpenseSummaryByMember';
import ExpenseSummaryByMemberPractice from './ExpenseSummaryByMemberPractice';
import ExpenseSummaryByProject from './ExpenseSummaryByProject';
import ExpenseSummaryByProjectType from './ExpenseSummaryByProjectType';
import { useFeatures } from '~/hooks';

function ExpenseReportsArea() {
  const { path, url } = useRouteMatch();
  const features = useFeatures();
  const { search: searchParams } = useLocation();

  return (
    <Switch>
      <Route path={path} exact>
        <ExpenseReportsPage />
      </Route>

      <Route path={path.concat('/expense-items')}>
        <ExpenseDetail />
      </Route>
      <Redirect from={path.concat('/expense-detail')} to={path.concat(`/expense-items${searchParams}`)} />

      <Route path={path.concat('/expense-summary-by-client-and-project')}>
        <ExpenseSummaryByClientAndProject />
      </Route>

      <Route path={path.concat('/expense-summary-by-expense-category')}>
        <ExpenseSummaryByExpenseCategory />
      </Route>

      {features.expenseReporting && (
        <Route path={path.concat('/expense-summary-by-member')}>
          <ExpenseSummaryByMember />
        </Route>
      )}

      {features.expenseReporting && features.practices && (
        <Route path={path.concat('/expense-summary-by-member-practice')}>
          <ExpenseSummaryByMemberPractice />
        </Route>
      )}

      <Route path={path.concat('/expense-summary-by-project')}>
        <ExpenseSummaryByProject />
      </Route>

      <Route path={path.concat('/expense-summary-by-project-type')}>
        <ExpenseSummaryByProjectType />
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}

export default ExpenseReportsArea;
