import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';
import opportunityFiles from './opportunityFiles';

const opportunities =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'opportunities', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query, { multi: true }).toString(true))),
      getSummary: (query = {}) =>
        get(url.concat('/summary').concat(new QueryString(query, { multi: true }).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      detailsCard: () => get(url.concat('/details-card')),
      selectOptions: (query = {}) => get(url.concat('/select-options').concat(new QueryString(query).toString(true))),
      projects: (query = {}) => {
        return get(url.concat('/projects', new QueryString(query).toString(true)));
      },
      integration: (query = {}) => {
        return get(url.concat('/integration', new QueryString(query).toString(true)));
      },
      openProject: (body) => post(url.concat('/open-project'), body),
      createProjectClient: (body) => post(url.concat('/create-project-client'), body),
      files: opportunityFiles(url.concat('/files')),
      upsert: (body) => (id ? patch : post)(url, body),
      setStage: (body) => patch(url.concat('/set-stage'), body),
      clone: (body) => post(url.concat('/clone'), body),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      delete: () => del(url),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      projectStats: () => get(url.concat('/project-stats')),
    };
  };

export default opportunities;
