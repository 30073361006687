import { DayPickerInput, SingleSelect } from '~/components';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { dateFormats, intervalOptions } from '~/utils';
import { sortIntervals } from '~/utils/intervalOptions';

const Filter = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

function EndPeriodFilter({ intervals, value, clearable = true, scope = 'day', onChange, ...props }) {
  const allowCustom = intervals.some((i) => i.key === intervalOptions.custom.key);

  const handleChange = (newValue) => {
    onChange({
      target: {
        value: {
          ...value,
          ...newValue,
        },
      },
    });
  };

  const handlePeriodChange = (event) => {
    const interval = _.find(intervals, { key: event.target.value });

    handleChange({
      key: interval.key,
      start: interval.start !== undefined ? interval.start : value.start,
      end: interval.end !== undefined ? interval.end : value.end,
      unit: interval.unit,
    });
  };

  const handleStartChange = (start) => {
    if (clearable || start) {
      switch (scope) {
        case 'month':
          start = moment(start).startOf('month').format('YYYY-MM');
          break;

        case 'week':
          start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
          break;
      }

      const option = _.find(sortIntervals(intervals), (i) => i.start === start && i.end === value.end);
      handleChange({
        start: start ?? null,
        key: option ? option.key : intervalOptions.custom.key,
        unit: option ? option.unit : intervalOptions.custom.unit,
      });
    }
  };

  const handleEndChange = (end) => {
    if (clearable || end) {
      switch (scope) {
        case 'month':
          end = moment(end).endOf('month').format(dateFormats.isoDate);
          break;

        case 'week':
          end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
          break;
      }

      const option = _.find(sortIntervals(intervals), (i) => i.start === value.start && i.end === end);
      handleChange({
        end: end ?? null,
        key: option ? option.key : intervalOptions.custom.key,
        unit: option ? option.unit : intervalOptions.custom.unit,
      });
    }
  };

  const dayPickerProps = {
    day: { displayFormat: dateFormats.compactDate },
    week: { displayFormat: dateFormats.compactDate },
    month: { displayFormat: dateFormats.monthYear, locale: 'en-US' },
  }[scope];

  return (
    <>
      <Filter>
        <SingleSelect {...props} value={value.key} onChange={handlePeriodChange}>
          {_.map(intervals, (value) => {
            return (
              <option key={value.key} value={value.key}>
                {value.label}
              </option>
            );
          })}
        </SingleSelect>
      </Filter>

      {allowCustom && (
        <>
          <Filter>
            <DayPickerInput
              value={value.start}
              placeholder="From"
              displayFormat={dayPickerProps.displayFormat}
              locale={dayPickerProps.locale}
              clearable={clearable}
              scope={scope}
              onChange={handleStartChange}
            />
          </Filter>

          <Filter>
            <DayPickerInput
              value={value.end}
              placeholder="To"
              displayFormat={dayPickerProps.displayFormat}
              locale={dayPickerProps.locale}
              clearable={clearable}
              scope={scope}
              onChange={handleEndChange}
            />
          </Filter>
        </>
      )}
    </>
  );
}

export default EndPeriodFilter;
