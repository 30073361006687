import React from 'react';
import Tag from './Tag';

export default function MemberBillableTypeTag({ value, ...props }) {
  if (!value) return null;
  return (
    <Tag label="Member Workspace Billability" {...props}>
      {{ billable: 'Billable', non_billable: 'Non-Billable' }[value]}
    </Tag>
  );
}
