import Fuse from 'fuse.js';
import { useIsMounted, useAuth } from '~/hooks';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import AutoCompleteMulti from '../AutoCompleteMulti';
import invoiceStatuses from '~/lookups/invoice-statuses';

export default function InvoiceStatusFilter({ value, ...props }) {
  const auth = useAuth();

  const options = useMemo(() => {
    const statusOptions = [];

    if (auth.invoices.manage.project) {
      statusOptions.push(invoiceStatuses.draft);
    }

    statusOptions.push(invoiceStatuses.open);
    statusOptions.push(invoiceStatuses.paid);

    return statusOptions;
  }, [auth.invoices.manage.project]);

  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      if (!q) {
        return options;
      }

      const fuse = new Fuse(options, { keys: ['id', 'name'] });
      const results = fuse.search(q);
      return results.map((result) => result.item);
    },
    [options],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      value={value}
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      placeholder="All"
      materialPlaceholder="Status"
      materialAlwaysVisible
      {...props}
    />
  );
}
