import moment from 'moment';
import dateFormats from './dateFormats';

// input `value` is expected to be in the format specified by `locale`
// return value is in ISO 8601 format (yyyy-mm-dd)
export default function parseDate(value, format, locale) {
  if (typeof value !== 'string') {
    return null;
  }

  const date = moment(value, dateFormats.compactDate, locale, true);
  if (!date.isValid()) {
    return null;
  }

  return date.format(dateFormats.isoDate);
}
