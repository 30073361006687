import { Checkbox, Field, Form, Radio } from '~/components';
import _ from 'lodash';
import React from 'react';

const groups = {
  project: 'Project',
  category: 'Category',
  category_and_person: 'Category and Person',
  person: 'Person',
};

const fields = {
  date: 'Date',
  category: 'Category',
  vendor: 'Vendor',
  person: 'Person',
  notes: 'Notes',
};

function LineSettingsExpensesTab({ formik: { values, setFieldValue } }) {
  const handleExpenseFieldChange = (name) => {
    let fields;
    if (values.expenseFields.some((v) => v === name)) {
      fields = values.expenseFields.filter((v) => v !== name);
    } else {
      fields = [...values.expenseFields, name];
    }
    setFieldValue('expenseFields', fields);
  };

  return (
    <>
      <Form.Section title="General">
        <Form.Control>
          <Field.Checkbox label="Include the project name on each expense invoice line" name="includeExpenseProject" />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Expense Grouping">
        <Form.Control>
          <Field.RadioGroup name="groupExpenses">
            <Radio value={true} label="Group expense entries" />
            <Radio value={false} label="Show all expense entries" />
          </Field.RadioGroup>
        </Form.Control>
        {values.groupExpenses ? (
          <div style={{ marginTop: '1rem' }}>
            Group by:
            <Form.Control style={{ marginTop: '1rem' }}>
              <Field.RadioGroup name="groupExpensesBy">
                {_.map(groups, (value, key) => (
                  <Radio key={key} value={key} label={value} />
                ))}
              </Field.RadioGroup>
            </Form.Control>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            Include these fields:
            <Form.Control style={{ marginTop: '1rem' }}>
              {_.map(fields, (value, key) => (
                <Checkbox
                  key={key}
                  label={value}
                  checked={values.expenseFields.some((v) => v === key)}
                  onChange={() => handleExpenseFieldChange(key)}
                />
              ))}
            </Form.Control>
          </div>
        )}
      </Form.Section>
    </>
  );
}

export default LineSettingsExpensesTab;
