import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAuth } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import MemberSchedule from './allocations/member-schedule/MemberSchedule';
import ProjectSchedule from './allocations/project-schedule/ProjectSchedule';

export default function ResourcesArea({ children }) {
  const { path, url } = useRouteMatch();

  const auth = useAuth();

  return (
    children || (
      <Switch>
        <Route path={`${path}/allocations/members`}>
          {auth.allocations.members.view ? <MemberSchedule /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>
        <Route path={`${path}/allocations/projects`}>
          {auth.allocations.projects.view ? <ProjectSchedule /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>

        <Redirect
          path="/"
          to={auth.allocations.members.view ? `${url}/allocations/members` : `${url}/allocations/projects`}
        />
      </Switch>
    )
  );
}
