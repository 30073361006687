import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import classNames from 'classnames';
import { Hours } from '~/components';

const Days = styled.div`
  display: flex;
`;

const Day = styled.div`
  margin-right: 0.5rem;
  text-align: center;

  > span {
    font-size: 0.625rem;
    color: ${colors.grey40};
    font-weight: ${weights.medium};
    text-transform: uppercase;
  }
`;

const Box = styled.div`
  border-radius: 4px;
  font-weight: normal;
  width: 3rem;
  height: 2.5rem;
  color: ${colors.grey25};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grey25};
  cursor: default;

  &.active {
    background: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function HoursPerDay({ value }) {
  return (
    <Days>
      {days.map((day, index) => (
        <Day key={index}>
          <span>{day}</span>
          <Box className={classNames({ active: value[index] > 0 })}>
            <Hours value={value[index]} minimumFractionDigits={0} />
          </Box>
        </Day>
      ))}
    </Days>
  );
}

export default HoursPerDay;
