import _ from 'lodash';
import { get, put } from './agent';

const reportFiles =
  (reportFilesUrl) =>
  (id = '') => {
    const url = _([reportFilesUrl, id]).compact().join('/');

    return {
      get: () => get(url),
      getContent: (filename) =>
        get(url.concat(`/${filename}?download=1`), {
          headers: {
            responseType: 'blob',
          },
        }),
      sendEmail: () => put(url.concat('/send-email')),
    };
  };

export default reportFiles;
