import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

function CompanyCloneForm({ target: company, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull({
        ...values,
      });

      const { data } = await api.www.workspaces(workspace.id).companies(company.id).clone(body);
      await onSaved(data);
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  if (!company) return <ErrorPage.NotFound publicSite={false} />;

  const initialValues = {
    name: company.name ? `Copy of ${company.name}` : '',
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ModalCard title="Clone Company" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('New Company Name').max(255).required(),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.Text autoFocus name="name" placeholder="New Company Name" maxLength={255} />
                </Form.Control>
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default CompanyCloneForm;
