import { SingleSelect } from '~/components';
import React from 'react';

export default function CreditNoteStatusSelect({ value, onChange }) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Status"
      materialAlwaysVisible
      showEmptyOption
      value={value}
      onChange={onChange}>
      {[
        { value: 'draft,open', label: 'Draft & Open' },
        { value: 'draft', label: 'Draft' },
        { value: 'open', label: 'Open' },
        { value: 'applied', label: 'Applied' },
      ].map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SingleSelect>
  );
}
