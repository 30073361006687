import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';

const projectDashboard = (projectDashboardUrl) => () => {
  const url = _([projectDashboardUrl]).compact().join('/');

  return {
    timesheets: (query = {}, config) =>
      get(url.concat('/timesheets').concat(new QueryString(query).toString(true)), config),
    progress: {
      myHours: () => get(url.concat('/progress/my-hours')),
      hours: () => get(url.concat('/progress/hours')),
      servicesRevenue: () => get(url.concat('/progress/services-revenue')),
      totalRevenue: () => get(url.concat('/progress/total-revenue')),
      servicesCost: () => get(url.concat('/progress/services-cost')),
      totalCost: () => get(url.concat('/progress/total-cost')),
      servicesProfit: () => get(url.concat('/progress/services-profit')),
      totalProfit: () => get(url.concat('/progress/total-profit')),
    },
    timeByMember: (query = {}, config = {}) =>
      get(url.concat('/time-by-member').concat(new QueryString(query).toString(true)), config),
    timeByRole: (query = {}, config = {}) =>
      get(url.concat('/time-by-role').concat(new QueryString(query).toString(true)), config),
    expensesByCategory: (query = {}, config = {}) =>
      get(url.concat('/expenses-by-category').concat(new QueryString(query).toString(true)), config),
    allocations: (query = {}) => get(url.concat('/allocations').concat(new QueryString(query).toString(true))),
    team: (query = {}) => get(url.concat('/team').concat(new QueryString(query).toString(true))),
  };
};

export default projectDashboard;
