import { Button, Buttons, CancelButton, ModalCard } from '~/components';
import React from 'react';

function DuplicateTransactionNumberDialog({ transactionNumber, onConfirm, onClose }) {
  return (
    <ModalCard title="Duplicate Transaction Number" onClose={onClose}>
      <ModalCard.Body>
        There is another transaction with number {transactionNumber}. Are you sure you want to continue?
      </ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button onClick={() => onConfirm(true)}>OK</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DuplicateTransactionNumberDialog;
