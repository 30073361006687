import { Icon, Tooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.grey25};
`;

export default function SentIndicator({ sentAt }) {
  const message = `Sent on ${moment(sentAt).format(dateFormats.compactDate)}`;
  return (
    <Wrapper>
      <Tooltip message={message}>
        <StyledIcon type="fas" icon="envelope-circle-check" />
      </Tooltip>
    </Wrapper>
  );
}
