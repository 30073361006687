import _ from 'lodash';
import { del, post } from './agent';

const features = (workspacesUrl) => {
  const url = _([workspacesUrl, 'features']).compact().join('/');

  return {
    activate: (feature) => post(`${url}/${feature}`),
    deactivate: (feature) => del(`${url}/${feature}`),
  };
};

export default features;
