import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  Icon,
  InternalClientTooltip,
  Level,
  OpportunityLink,
  ProjectLink,
  SingleSelect,
  Stack,
  Table,
} from '~/components';
import CompanyLink from '~/components/CompanyLink';
import { Filter } from '~/components/filters';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import PageLoader from '~/routes/public/pages/PageLoader';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import CreatedPeriodReportFilter from '../components/CreatedPeriodReportFilter';
import ClosedPeriodReportFilter from '../components/ClosedPeriodReportFilter';
import OpportunityStageTag from '../components/OpportunityStageTag';
import PeriodTag from '../components/PeriodTag';

const Byline = styled.small`
  display: block;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

function OpportunityDetail() {
  useDocumentTitle('Opportunity Detail');
  const { workspace } = useWorkspace();
  const api = useApi();
  const isMounted = useIsMounted();

  const [query, setQuery] = useState({ report: null, status: 'loading', loading: { table: false } });
  const [params, setParams] = useState({
    period: null,
    closedPeriod: null,
    sort: new QuerySort('closeDate', 'asc'),
    opportunityStageStatusId: 'open',
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        closedPeriod: reportsSearchParamsConfig.closedPeriod,
        currency: searchParamsConfig.currency,
        opportunityStageStatusId: {
          default: 'open',
          valid: ['open', 'won', 'lost', 'all'],
          key: 'opportunityStageStatusId',
          serialize: (value) => ({ opportunityStageStatusId: value ? value : 'all' }),
          deserialize: (value) => (value === 'all' ? '' : value),
        },
        sort: { default: new QuerySort('closeDate', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      closedStart: params.closedPeriod?.start ?? undefined,
      closedEnd: params.closedPeriod?.end ?? undefined,
      opportunityStageStatusId: params.opportunityStageStatusId ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().opportunityDetail(urlSearchParams);
    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .opportunityDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/pipeline`} />

        <Stack>
          <h1>Opportunity Detail</h1>
          <Tags>
            <PeriodTag label="Created Date Range" value={params.createdPeriod} />
            <PeriodTag label="Expected Closed Date Range" value={params.closedPeriod} />
            <OpportunityStageTag
              value={params.opportunityStageStatusId}
              onRemove={() => {
                handleApplyFilters({ opportunityStageStatusId: null });
              }}
            />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Opportunity" value={report.records.length} />
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="opportunity.name" onSort={onSort} sort={params.sort}>
            Name
          </Table.Column>
          <Table.Column name="project" onSort={onSort} sort={params.sort}>
            Project
          </Table.Column>
          <Table.Column name="opportunityStageId" onSort={onSort} sort={params.sort} width="12rem">
            Stage
          </Table.Column>
          <Table.Column name="createdAt" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Created
          </Table.Column>
          <Table.Column name="closeDate" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Close
          </Table.Column>
          <Table.Column name="probability" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Probability
          </Table.Column>
          <Table.Column name="convertedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Amount
          </Table.Column>
          <Table.Column name="convertedWeightedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Weighted
          </Table.Column>
        </Table.Header>

        <TableBody>
          {report.records.map((opportunity) => (
            <Table.Row key={opportunity.id}>
              <Table.Cell>
                <InfoContainer>
                  <OpportunityLink opportunity={opportunity} />
                  <small>
                    <CompanyLink company={opportunity.company} />
                  </small>
                </InfoContainer>
              </Table.Cell>
              <Table.Cell>
                {opportunity.project && (
                  <InfoContainer>
                    <p>
                      <ProjectLink project={opportunity.project} />
                    </p>
                    <small>
                      <ClientLink client={opportunity.project?.client} />
                      {opportunity.project?.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </InfoContainer>
                )}
              </Table.Cell>
              <Table.Cell>
                <p>
                  {`${opportunity.opportunityStage.name}`}
                  <Byline>
                    {
                      {
                        open: 'Open',
                        won: 'Won',
                        lost: 'Lost',
                      }[opportunity.opportunityStage.statusId]
                    }
                  </Byline>
                </p>
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.createdAt} />
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.closeDate} />
              </Table.Cell>
              <Table.Cell>{`${opportunity.probability}%`}</Table.Cell>
              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.amount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>

              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedWeightedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.weightedAmount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>
            </Table.Row>
          ))}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.convertedAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.convertedWeightedAmount} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          name="opportunityStageStatusId"
          placeholder="All"
          showEmptyOption
          materialPlaceholder="Stage Category"
          materialAlwaysVisible
          value={filters.opportunityStageStatusId}
          onChange={({ target: { value } }) => handleFilter({ opportunityStageStatusId: value })}>
          <option value="open">Open</option>
          <option value="won">Won</option>
          <option value="lost">Lost</option>
        </SingleSelect>
      </Filter>
      <CreatedPeriodReportFilter
        value={filters.createdPeriod}
        onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
      />
      <ClosedPeriodReportFilter
        value={filters.closedPeriod}
        onChange={({ target: { value } }) => handleFilter({ closedPeriod: value })}
      />
    </Report.Filters>
  );
}

export default OpportunityDetail;
