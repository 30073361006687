import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export default function useGroups({ rows, defaultGroupStatus, onGroupsToggle }) {
  const groupStatusRef = useRef(defaultGroupStatus);
  const [groups, setGroups] = useState({});

  useEffect(() => {
    if (!rows) return;

    setGroups((groups) =>
      rows
        .filter((row) => row.type === 'group')
        .reduce(
          (a, v) => ({
            ...a,
            [v.id]: {
              state:
                // If the default group status didn't change, preserve the previous status of each row.
                // Otherwise, force the default group status into every row.
                // This is mostly to handle the default group status change when the browser history changes.
                groupStatusRef.current === defaultGroupStatus
                  ? groups[v.id]?.state ?? defaultGroupStatus
                  : defaultGroupStatus,
              hasAllocations: v.hasAllocations,
            },
          }),
          {},
        ),
    );

    groupStatusRef.current = defaultGroupStatus;
  }, [rows, defaultGroupStatus]);

  const hasExpandedGroups = useMemo(
    () => _.some(groups, (group) => group.hasAllocations && group.state === 'expanded'),
    [groups],
  );

  const toggleGroup = useCallback((group) => {
    setGroups((groups) => {
      let currentGroup = groups[group.id];

      if (!currentGroup) return groups;

      return {
        ...groups,
        [group.id]: {
          ...currentGroup,
          state: currentGroup.state === 'expanded' ? 'collapsed' : 'expanded',
        },
      };
    });
  }, []);

  const toggleGroups = useCallback(() => {
    const state = hasExpandedGroups ? 'collapsed' : 'expanded';
    const toggledGroups = { ...groups };

    for (const key in toggledGroups) {
      groups[key].state = state;
    }

    setGroups(toggledGroups);

    if (onGroupsToggle) onGroupsToggle(state);

    return state;
  }, [groups, hasExpandedGroups, onGroupsToggle]);

  return { groups, toggleGroup, toggleGroups, hasExpandedGroups };
}
