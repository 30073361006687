import {
  ActionButton,
  Buttons,
  CancelButton,
  Checkbox,
  Drawer,
  Form,
  FormMessage,
  HelpTooltip,
  Icon,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const Connected = styled.div`
  position: relative;
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const ConnectedLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

const ConnectedInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.875rem;
`;

const SlackWorkspaceImage = styled.img`
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 0.5rem;
`;

const SlackWorkspaceName = styled.span`
  font-weight: ${weights.bold};
  margin-right: auto;
`;

const SlackWorkspaceLink = styled.a`
  margin-left: 1rem;
  font-size: 0.875rem;

  .icon {
    font-size: 0.75rem;
  }
`;

const Relationship = styled.div`
  position: relative;
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const RelationshipLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

const RelationshipInfo = styled.span`
  display: flex;
  align-items: center;
  padding: 0.75rem 0.875rem;
`;

const RelationshipData = styled.span`
  font-weight: ${weights.bold};
  margin-right: auto;
`;

const RelationshipLink = styled(Link)`
  margin-left: 1rem;
  font-size: 0.875rem;

  .icon {
    font-size: 0.75rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;

  > label:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const Notification = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export default function SlackSettings({ onClose }) {
  useDocumentTitle('Slack Settings');

  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, saved, status }, form] = useForm();
  const [integration, setIntegration] = useState();
  const [notifications, setNotifications] = useState();
  const [isReady, setIsReady] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);
  const drawerRef = useRef();

  const scopes = useMemo(() => (integration ? integration.scopes.join(',') : ''), [integration]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).integrations().slack.get();

    if (!isMounted.current) return;

    setIntegration(data);
    setNotifications(data.notifications);
    setIsReady(true);
  }, [workspace, api, isMounted]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNotificationChange = (event) => {
    setDirty(true);
    setNotifications((notifications) => ({ ...notifications, [event.target.name]: event.target.checked }));
  };

  const features = useFeatures();

  if (!isReady || !integration || !integration.isConnected) return null;
  return (
    <Drawer
      isOpen
      ref={drawerRef}
      title="Slack Settings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();
          try {
            await api.www.workspaces(workspace.id).integrations().slack.updateNotifications(notifications);
            form.save();
            closeDrawer();
          } catch (error) {
            form.error({ message: error.message });
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        return (
          <>
            {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}
            <Form.Section title="Slack Workspace">
              <Form.Control>
                <Connected>
                  <ConnectedLabel>Connected Workspace</ConnectedLabel>
                  <ConnectedInfo>
                    {integration.info.image && (
                      <SlackWorkspaceImage src={integration.info.image} alt={integration.info.name} />
                    )}
                    {integration.info.name && <SlackWorkspaceName>{integration.info.name}</SlackWorkspaceName>}
                    <SlackWorkspaceLink
                      href={`https://slack.com/apps/${integration.appId}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      App Info <Icon icon="external-link-alt" />
                    </SlackWorkspaceLink>
                    <SlackWorkspaceLink
                      href={`https://slack.com/oauth/v2/authorize?client_id=${integration.clientId}&scope=${scopes}&state=${workspace.id}`}>
                      Reauthorize <Icon icon="external-link-alt" />
                    </SlackWorkspaceLink>
                  </ConnectedInfo>
                </Connected>
              </Form.Control>
            </Form.Section>
            <Form.Section title="Members">
              <Form.Control>
                <Relationship>
                  <RelationshipLabel>Connected Members</RelationshipLabel>
                  <RelationshipInfo>
                    <RelationshipData>
                      {integration.members.connected} of {integration.members.total}
                    </RelationshipData>
                    <RelationshipLink to={`/app/${workspace.key}/settings/members`}>Manage</RelationshipLink>
                  </RelationshipInfo>
                </Relationship>
              </Form.Control>
            </Form.Section>
            <Form.Section title="Projects">
              <Form.Control>
                <Relationship>
                  <RelationshipLabel>Connected Projects</RelationshipLabel>
                  <RelationshipInfo>
                    <RelationshipData>
                      {integration.projects.connected} of {integration.projects.total}
                    </RelationshipData>
                    <RelationshipLink to={`/app/${workspace.key}/projects`}>Manage</RelationshipLink>
                  </RelationshipInfo>
                </Relationship>
              </Form.Control>
            </Form.Section>
            <Form.Section title="Notifications">
              <Form.Control>
                <Checkboxes>
                  <Checkbox
                    name="missingTimeReminders"
                    label="Missing time reminders"
                    checked={notifications.missingTimeReminders}
                    onChange={handleNotificationChange}
                  />
                  <Checkbox
                    name="unsubmittedTimeReminders"
                    label="Unsubmitted time reminders"
                    checked={notifications.unsubmittedTimeReminders}
                    onChange={handleNotificationChange}
                  />
                  {features.timesheets && (
                    <Checkbox
                      name="unsubmittedTimesheetReminders"
                      label="Unsubmitted timesheet reminders"
                      checked={notifications.unsubmittedTimesheetReminders}
                      onChange={handleNotificationChange}
                    />
                  )}
                  <Checkbox
                    name="timeExpenseApprovalReminders"
                    label="Time and expense approval reminders"
                    checked={notifications.timeExpenseApprovalReminders}
                    onChange={handleNotificationChange}
                  />
                  {/* This setting isn't currently used, hiding for now */}
                  {/*
                  <Checkbox
                    name="timeExpenseApproved"
                    label="Time and expenses have been approved"
                    checked={notifications.timeExpenseApproved}
                    onChange={handleNotificationChange}
                  />
                  */}
                  <Checkbox
                    name="timeExpenseRejected"
                    label="Time and expenses have been rejected"
                    checked={notifications.timeExpenseRejected}
                    onChange={handleNotificationChange}
                  />
                  <Notification>
                    <Checkbox
                      name="projectBudgetHoursWarning"
                      label="Project budget hours warning"
                      checked={notifications.projectBudgetHoursWarning}
                      onChange={handleNotificationChange}
                    />
                    <HelpTooltip
                      message="A Slack notification will be sent when the budget used reaches 80% and 100%."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Notification>
                  <Checkbox
                    name="projectMemberAdded"
                    label="Project team member added"
                    checked={notifications.projectMemberAdded}
                    onChange={handleNotificationChange}
                  />
                  <Checkbox
                    name="projectStatusChanged"
                    label="Project status changed"
                    checked={notifications.projectStatusChanged}
                    onChange={handleNotificationChange}
                  />
                  <Checkbox
                    name="projectInvoicePublished"
                    label="Project invoice published"
                    checked={notifications.projectInvoicePublished}
                    onChange={handleNotificationChange}
                  />
                  <Checkbox
                    name="projectTaskStatusChanged"
                    label="Project task status changed"
                    checked={notifications.projectTaskStatusChanged}
                    onChange={handleNotificationChange}
                  />
                  <Notification>
                    <Checkbox
                      name="projectTaskBudgetExceeded"
                      label="Project task budget hours warning"
                      checked={notifications.projectTaskBudgetExceeded}
                      onChange={handleNotificationChange}
                    />
                    <HelpTooltip
                      message="A Slack notification will be sent when the budget used reaches 80% and 100%."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Notification>
                  <Checkbox
                    name="clientApprovalSubmitted"
                    label="Client approval submitted"
                    checked={notifications.clientApprovalSubmitted}
                    onChange={handleNotificationChange}
                  />
                </Checkboxes>
              </Form.Control>
            </Form.Section>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
