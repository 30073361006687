import { Button, Buttons, CancelButton, Field, Form, ModalCard } from '~/components';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export default function SubmitDialog({ clientApproval, onResolve }) {
  const handleClose = () => {
    onResolve(null);
  };

  const handleSubmit = (values) => {
    onResolve(values);
  };

  return (
    <ModalCard title="Submit Approval Request" onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={{ reviewerName: clientApproval.reviewerName || '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          reviewerName: Yup.string().label('Name').max(255).required(),
        })}>
        <Form>
          <ModalCard.Body>
            <p style={{ marginBottom: '1rem' }}>
              You have provided a disposition on all of the items within this Client Approval. To submit the Client
              Approval now, type in your name below:
            </p>

            <Form.Control>
              <Field.Text name="reviewerName" placeholder="Please enter your name" maxLength={255} autoFocus />
            </Form.Control>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Buttons align="right">
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <Button type="submit">Submit</Button>
            </Buttons>
          </ModalCard.Footer>
        </Form>
      </Formik>
    </ModalCard>
  );
}
