import {
  Checkbox,
  DateTime,
  DayPickerInput,
  Hours,
  Icon,
  Level,
  MemberSelect,
  SingleSelect,
  Table,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 2rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

const Time = styled.div`
  position: relative;
  padding: 0rem 1.5rem;
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          background: ${colors.grey10};
          color: ${colors.black};
        `,
        pending_approval: css`
          background: ${colors.warning10};
          color: ${colors.warning};
        `,
        rejected: css`
          background: ${colors.danger10};
          color: ${colors.danger};
        `,
        approved: css`
          background: ${colors.primary10};
          color: ${colors.primary};
        `,
      })[status]}
  }
`;

const Stopwatch = styled.div`
  position: absolute;
  top: 0.1rem;
  left: 0.25rem;
  font-size: 0.75rem;
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
`;

const DetailsContent = styled.dl`
  display: flex;
  align-items: center;
  padding: 1.125rem 0;
  border-top: 1px solid ${colors.grey10};
  width: 100%;

  dt {
    font-size: 0.75rem;
    font-weight: ${weights.black};
    color: ${colors.grey40};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    margin-right: 2rem;
  }

  dd {
    font-size: 0.75rem;
    color: ${colors.grey55};

    &:not(:last-child) {
      margin-right: 5rem;
    }
  }
`;

const Task = styled.p`
  font-size: 0.75rem;
  color: ${colors.grey55};
`;

const initialState = {
  isReady: false,
  data: null,
  query: {
    q: '',
    member: null,
  },
};
const handlers = {
  ready: ({ data }) => ({ isReady: true, data }),
  filter: (query, state) => ({ query: { ...state.query, ...query } }),
};

function TransactionsDrawerTimeTab({ clientApproval, timeEntries, start, end, onStartChange, onEndChange, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, query }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const q = {
        ..._.omit(query, 'member'),
        start: start || undefined,
        end: end || undefined,
        memberId: query.member?.id,
      };
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(clientApproval.projectId)
        .clientApprovals(clientApproval.id)
        .getTimeTransactions(q);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, query, start, end, api, clientApproval]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!data) return;

    return {
      entries: _.filter(data, (entry) => timeEntries.some((e) => e.id === entry.id)),

      get amount() {
        return _.sumBy(this.entries, (entry) => entry.revenue);
      },

      get hours() {
        return _.sumBy(selected.entries, (entry) => entry.hours);
      },

      get some() {
        return this.entries.length > 0;
      },

      get all() {
        return this.entries.length === data.length;
      },
    };
  }, [data, timeEntries]);

  if (!data) return null;

  const handleBulkSelectionChange = () => {
    onChange(
      selected.some
        ? timeEntries.filter(({ id }) => !data.some((entry) => id === entry.id))
        : [...timeEntries, ...data.map(({ id }) => ({ id }))],
    );
  };

  return (
    <div>
      <Level>
        <Level.Item>
          <MemberSelect
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={query.member}
            onChange={({ target: { value } }) => actions.filter({ member: value })}
          />
        </Level.Item>

        <Level.Item>
          <SingleSelect
            name="statusId"
            placeholder="All"
            materialPlaceholder="Approval Status"
            materialAlwaysVisible
            showEmptyOption
            value={query.statusId}
            onChange={({ target: { value } }) => actions.filter({ statusId: value })}>
            <option value="not_submitted">Not Submitted</option>
            <option value="pending_approval">Pending Approval</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </SingleSelect>
        </Level.Item>

        <Level.Item>
          <SingleSelect
            name="isBillable"
            placeholder="All"
            materialPlaceholder="Billable"
            materialAlwaysVisible
            showEmptyOption
            value={query.isBillable}
            onChange={({ target: { value } }) => actions.filter({ isBillable: value })}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </SingleSelect>
        </Level.Item>
      </Level>

      <Level margin="2rem 0">
        <Level.Item>
          <DayPickerInput placeholder="Start" value={start} onChange={(value) => onStartChange(value)} />
        </Level.Item>
        <Level.Item>
          <DayPickerInput placeholder="End" value={end} onChange={(value) => onEndChange(value)} />
        </Level.Item>
      </Level>

      <StyledTable>
        <Table.BoxHeader>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column width="6.5rem">Date</Table.Column>
          <Table.Column>Role/Task</Table.Column>
          <Table.Column width="6.5rem" align="right">
            Hours
          </Table.Column>
        </Table.BoxHeader>
        <Table.Body>
          {data.map((entry) => {
            const checked = _(timeEntries).some(({ id }) => id === entry.id);

            const handleSelectionChange = () => {
              onChange(checked ? timeEntries.filter(({ id }) => id !== entry.id) : [...timeEntries, { id: entry.id }]);
            };

            return (
              <Table.BoxRow key={entry.id} wrap stretch>
                <Table.Cell>
                  <StyledCheckbox>
                    <Checkbox checked={checked} onChange={handleSelectionChange} />
                  </StyledCheckbox>
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={entry.date} />
                </Table.Cell>
                <Table.Cell>
                  <div>
                    {entry.project.useRoles && entry.role && <p>{entry.role.name}</p>}
                    {entry.task && <Task>{entry.task.name}</Task>}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Time status={entry.statusId}>
                    {entry.timerStartedAt && (
                      <Stopwatch>
                        <Icon icon="stopwatch" type="far" />
                      </Stopwatch>
                    )}{' '}
                    <Hours value={entry.hours} />
                  </Time>
                </Table.Cell>

                <DetailsContainer>
                  <DetailsContent>
                    <dt>Details</dt>
                    <dd>{entry.member.name}</dd>
                    {entry.notes && <dd>"{entry.notes}"</dd>}
                  </DetailsContent>
                </DetailsContainer>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </StyledTable>
    </div>
  );
}

export default TransactionsDrawerTimeTab;
