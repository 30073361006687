import moment from 'moment';
import React, { useMemo } from 'react';
import { dateFormats } from '~/utils';
import { BackgroundCanvasColumn } from './CanvasStyles';

function MonthBackgroundCanvas({ start, end, dateWidth }) {
  const months = useMemo(() => {
    const monthsCount = moment(end).diff(start, 'months') + 1;

    const months = [];

    for (let index = 0; index < monthsCount; index++) {
      const date = moment(start).add(index, 'months');
      const width = dateWidth * moment(date).daysInMonth();
      const previousMonth = months[index - 1];
      const left = previousMonth ? previousMonth.style.left + previousMonth.style.width : 0;

      months.push({
        key: date.format(dateFormats.isoDate),
        style: {
          left,
          width,
        },
        today: moment().isSame(date, 'month'),
      });
    }

    return months;
  }, [start, end, dateWidth]);

  return months.map((month, index) => {
    return <BackgroundCanvasColumn key={index} style={month.style} index={index} today={month.today} />;
  });
}

export default React.memo(MonthBackgroundCanvas);
