import { DateTime } from '~/components';
import React from 'react';

export default function DateRange({ clientApproval, locale }) {
  if (!clientApproval.periodStart && !clientApproval.periodEnd) return 'All Time';

  if (clientApproval.periodStart && !clientApproval.periodEnd)
    return (
      <span>
        Since <DateTime value={clientApproval.periodStart} locale={locale} />
      </span>
    );

  if (!clientApproval.periodStart && clientApproval.periodEnd)
    return (
      <span>
        Until <DateTime value={clientApproval.periodEnd} locale={locale} />
      </span>
    );

  return (
    <span>
      <DateTime value={clientApproval.periodStart} locale={locale} /> -{' '}
      <DateTime value={clientApproval.periodEnd} locale={locale} />
    </span>
  );
}
