import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Avatar, Buttons, CancelButton, Drawer, FormMessage, SingleSelect, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useFuzzyFilter } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const MissingId = styled.span`
  color: ${colors.error};
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ isActive }) => (isActive === false ? colors.grey55 : colors.black)};

  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

const MemberName = styled.div``;

const MemberEmail = styled.div`
  font-size: 0.875rem;
`;

export default function MemberMapping({ onClose, onSave }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, saved, status }, form] = useForm();
  const [memberMappings, setMemberMappings] = useState([]);
  const [members, setMembers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);
  const drawerRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: memberMappings } = await api.www.workspaces(workspace.id).bamboohr.getMemberMappings();
        const { data: members } = await api.www.workspaces(workspace.id).bamboohr.getMembers();
        const { data: employees } = await api.www.workspaces(workspace.id).bamboohr.getEmployees();

        setMemberMappings(memberMappings);
        setMembers(members);
        setEmployees(employees);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  const usedBamboohrIds = useMemo(() => memberMappings.map((m) => m.bamboohrId), [memberMappings]);

  const changeMapping = (member, selected) => {
    const { value } = selected.target;

    setMemberMappings((mappings) => {
      // Flag the form as dirty
      setDirty(true);
      // Check if the current mapping exists
      const mapping = _.find(mappings, { memberId: member.id });
      // If no value, then delete the mapping
      if (mapping && !value) {
        return mappings.filter((m) => m.id !== mapping.id);
      }
      // If there is already a mapping, update the value
      else if (mapping) {
        mapping.bamboohrId = value;
        return mappings.map((m) => (m.id === mapping.id ? mapping : m));
      }
      // If there is no mapping, but a value, then add it
      else if (value) {
        return [
          ...mappings,
          {
            id: _.uniqueId('temp_'),
            memberId: member.id,
            bamboohrId: value,
          },
        ];
      }
      // There was no mapping and the value is empty
      // This shouldn't happen, but it just returns the orignial list if it does
      return mappings;
    });
  };

  return (
    <Drawer
      isOpen
      title="Member Mappings"
      byline="BambooHR Integration"
      ref={drawerRef}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          const mappings = memberMappings.map((mapping) => ({
            id: mapping.id.startsWith('temp_') ? undefined : mapping.id,
            ..._.pick(mapping, ['memberId', 'bamboohrId']),
          }));

          try {
            await await api.www.workspaces(workspace.id).bamboohr.updateMemberMappings(mappings);
            form.save();
            onSave();
            closeDrawer();
          } catch (error) {
            form.error({ message: error.message });
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}
            <Table style={{ marginBottom: '2.5rem' }}>
              <Table.BoxHeader>
                <Table.Column>Ruddr Member</Table.Column>
                <Table.Column>BambooHR Employee</Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {members.map((member) => {
                  const mapping = _.find(memberMappings, { memberId: member.id });
                  const selectedEmployee = mapping ? _.find(employees, { id: mapping.bamboohrId }) : null;
                  const availableEmployees = _.filter(
                    employees,
                    (employee) => selectedEmployee?.id === employee.id || !usedBamboohrIds.includes(employee.id),
                  );
                  return (
                    <Table.BoxRow key={member.id}>
                      <Table.Cell>
                        <Avatar value={member} hasBackground isCircle size={40} />
                        <Member isActive={member.isActive}>
                          <MemberName>{member.name}</MemberName>
                          <MemberEmail>{member.email}</MemberEmail>
                        </Member>
                      </Table.Cell>
                      <Table.Cell>
                        <EmployeeSelect
                          employees={availableEmployees}
                          value={selectedEmployee}
                          mapping={mapping}
                          onChange={(selected) => changeMapping(member, selected)}
                        />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function EmployeeSelect({ employees, value, mapping, ...props }) {
  const { filteredItems, setFilterValue } = useFuzzyFilter(employees, { keys: ['name'], threshold: 0.1 });

  return (
    <SingleSelect
      placeholder="BambooHR Employee"
      showFilter
      showEmptyOption
      noOptionsMessage="No available employees"
      value={mapping ? mapping.id : undefined}
      valueRenderer={
        value ? (
          <>
            {value.name}
            {value.email && <> ({value.email})</>}
          </>
        ) : mapping ? (
          <MissingId>Unknown BambooHR ID: {mapping.bamboohrId}</MissingId>
        ) : null
      }
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {filteredItems.map((employee) => (
        <option key={employee.id} value={employee.id}>
          {employee.name}
          {employee.email && <> ({employee.email})</>}
        </option>
      ))}
    </SingleSelect>
  );
}
