import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

const ProjectSelect = React.forwardRef(({ memberId, initialProject, value: projectId, disabled, ...props }, ref) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState();
  const [filterValue, setFilterValue] = useState('');
  const [filteredProjects, setFilteredProjects] = useState();
  const isMountedRef = useRef(false);

  const project = useMemo(
    () =>
      _.find(projects, { id: projectId }) ||
      _.find(filteredProjects, { id: projectId }) ||
      (initialProject && _.isEqual(initialProject.id, projectId) ? initialProject : undefined),
    [projectId, projects, filteredProjects, initialProject],
  );

  const groupedProjects = useMemo(() => {
    const projectsToUse = filterValue && filteredProjects ? filteredProjects : projects;
    const groups = _.reduce(
      projectsToUse,
      (acc, project) => {
        const { client } = project;

        let obj = _.find(acc, { client });
        if (!obj) {
          obj = { client, projects: [] };
          acc.push(obj);
        }

        obj.projects.push(project);
        obj.projects = _.sortBy(obj.projects, ['name']);

        return acc;
      },
      [],
    );
    return _.sortBy(groups, ['client.name']);
  }, [projects, filteredProjects, filterValue]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { data } = await api.www
        .workspaces(workspace.id)
        .timeEntries()
        .projects()
        .get({ memberId, assignedOnly: true, activeOnly: true });

      if (!isMountedRef.current) {
        return;
      }

      setIsLoading(false);
      setProjects(data.results);
    })();
  }, [api, workspace.id, memberId]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }

    (async () => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .timeEntries()
        .projects()
        .get({ memberId, query: filterValue, assignedOnly: true, activeOnly: true });

      if (!isMountedRef.current) {
        return;
      }

      setFilteredProjects(data.results);
    })();
  }, [api, workspace.id, memberId, filterValue]);
  return (
    <SingleSelect
      showEmptyOption={false}
      {...props}
      showFilter
      filterPlaceholder="Find projects"
      noOptionsMessage="No projects available"
      value={projectId}
      valueRenderer={project ? `${project.client.name} / ${project.name}` : ''}
      disabled={isLoading || disabled}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      ref={ref}>
      {groupedProjects?.map((group) => (
        <optgroup
          key={group.client.id}
          label={group.client.name}
          icon={group.client.isInternal ? { key: 'building', message: 'This is your company' } : undefined}>
          {group.projects.map((project) => (
            // eslint-disable-next-line react/no-unknown-property
            <option key={project.id} tooltip={project.name} value={project.id}>
              {project.name}
            </option>
          ))}
        </optgroup>
      ))}
    </SingleSelect>
  );
});

export default ProjectSelect;
