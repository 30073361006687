import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function ProjectTaskSelect({ value, project, initialValue, onSearch, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      if (onSearch) {
        return onSearch(q);
      }

      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .tasks()
        .get({
          q,
          isActive: true,
          projectId: project.id,
          include: initialValue ? initialValue.id : undefined,
          recordStatusId: 'active',
          size: 1000,
        });

      return data;
    },
    [workspace.id, initialValue, api, project.id, onSearch],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value?.name ?? ''}
      defaultOptions={defaultOptions}
      renderValue={(value) => value.name}
      renderOption={(option) => option.name}
      {...props}
    />
  );
}

function FieldProjectTaskSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectTaskSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectTaskSelect;
export { FieldProjectTaskSelect };
