import { Buttons, CancelButton, Drawer, Field, Form, Tab, Tabs } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { getFileDataUrl } from '~/utils';
import DeleteConfirmation from './DeleteConfirmation';
import FilePreview from './FilePreview';
import UploadingWarning from './UploadingWarning';

const MAX_FILE_SIZE = 20971520; // 20mb in bytes

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 1rem 1.5rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const DropzoneContainer = styled.div`
  margin-top: 1.625rem;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`;

const Previews = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

const GroupFiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TypeTitle = styled.div`
  margin: 0.5rem;
`;

export default function OpportunityFilesDrawer({ opportunity, onClose, onChanged }) {
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const formRef = useRef();

  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [filesChanged, setFilesChanged] = useState(false);
  const [showUploadingWarning, setShowUploadingWarning] = useState(false);
  const [pipelineActivityType, setPipelineActivityType] = useState(null);

  const fetchDefaultActivityType = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).pipelineActivityTypes().get({
        isActive: true,
        typeId: 'file',
        isRestricted: true,
      });

      setPipelineActivityType(data[0]);
    } catch (error) {
      setPipelineActivityType(null);
    }
  }, [workspace.id, api]);

  useEffect(() => {
    fetchDefaultActivityType();
  }, [fetchDefaultActivityType]);

  const onBeforeClose = (callback) => {
    if (_.some(files, { status: 'uploading' })) {
      setShowUploadingWarning(true);
    } else if (typeof callback === 'function') {
      if (filesChanged && typeof onChanged === 'function') {
        onChanged();
      }
      callback();
    }
  };

  const addFiles = async (files) => {
    if (!pipelineActivityType) {
      setError('Please select a Pipeline Activity Type');
      return;
    }

    setFilesChanged(true);

    // Generate placeholder files, run in parallel
    const tempFiles = await Promise.all(
      files.map(async (fileData) => ({
        id: _.uniqueId('temp_file_'),
        name: fileData.name,
        type: fileData.type || 'application/octet-stream',
        status: 'uploading',
        dataUrl: await getFileDataUrl(fileData),
      })),
    );

    // Set the files all at once to prevent missing files
    setFiles((files) => [...tempFiles, ...files]);

    // Upload the files, run in parallel
    await Promise.all(
      tempFiles.map(async (file) => {
        try {
          const response = await api.www
            .workspaces(workspace.id)
            .opportunities(opportunity.id)
            .files()
            .upload({
              pipelineActivityTypeId: pipelineActivityType.id,
              name: file.name,
              type: file.type,
              data: file.dataUrl.split(';base64,').pop(),
            });

          const updatedFile = {
            ...response.data,
            status: 'uploaded',
            dataUrl: file.dataUrl,
          };

          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        } catch (error) {
          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              const updatedFile = _.assign({}, files[fileIndex], {
                status: 'error',
              });
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        }
      }),
    );
  };

  const removeFile = (file) => {
    setFilesChanged(true);
    setFiles((files) => _.reject(files, { id: file.id }));
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 20 MB.`);
      }
    }
    if (acceptedFiles.length > 0) {
      addFiles(acceptedFiles);
    }
  };

  const groups = _.orderBy(
    files.reduce((a, file) => {
      const key = file.pipelineActivity?.pipelineActivityType.name;
      a[key] = a[key] || { key, files: [] };
      a[key].files.push(file);
      return a;
    }, {}),
    'key',
  );

  const handleSelectType = (event) => {
    setPipelineActivityType(event.target.value ?? null);
    setError(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ maxSize: MAX_FILE_SIZE, onDrop });
  return (
    <>
      <Drawer
        isOpen
        title="Files"
        byline={opportunity.name}
        onBeforeClose={({ setIsOpen }) => onBeforeClose(() => setIsOpen(false))}
        onClose={onClose}>
        {(closeDrawer) => (
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Tabs selectedIndex={tabIndex} onChange={setTabIndex}>
              <Tab>Opportunity Files</Tab>
            </Tabs>

            <div style={{ marginTop: '1.5rem' }}>
              <Formik innerRef={formRef} enableReinitialize validateOnBlur={false} validateOnChange={false}>
                {() => {
                  return (
                    <Form>
                      <Form.Control>
                        <Field.PipelineActivityTypeSelect
                          name="pipelineActivityType"
                          placeholder="Pipeline Activity Type"
                          typeId="file"
                          value={pipelineActivityType}
                          clearable={false}
                          onChange={(event) => handleSelectType(event)}
                        />
                      </Form.Control>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            <DropzoneContainer>
              <DropzoneInterior
                {...(!pipelineActivityType ? {} : getRootProps())}
                isActive={pipelineActivityType ? true : false}>
                <input {...getInputProps()} />
                <p>Drop files here or click to select files</p>
              </DropzoneInterior>
            </DropzoneContainer>
            <Previews>
              {groups.map(
                (group) =>
                  group.key && (
                    <div key={group.key}>
                      <TypeTitle>
                        Pipeline Activity Type: <strong>{group.key}</strong>
                      </TypeTitle>
                      <GroupFiles>
                        {group.files.map((file) => (
                          <FilePreview key={file.id} file={file} onRemove={setFileToDelete} />
                        ))}
                      </GroupFiles>
                    </div>
                  ),
              )}
            </Previews>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={() => onBeforeClose(() => closeDrawer())}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </>
        )}
      </Drawer>

      {fileToDelete && (
        <DeleteConfirmation
          file={fileToDelete}
          opportunity={opportunity}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
      {showUploadingWarning && <UploadingWarning onClose={() => setShowUploadingWarning(false)} />}
    </>
  );
}
