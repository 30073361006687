import {
  BillableIcon,
  DateTime,
  DeleteConfirmation,
  Dropdown,
  Duration,
  Icon,
  InfiniteScrollingObserver,
  Spinner,
  Stack,
  Table,
  TimeApprovalPopover,
  TimeLockIcon,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation } from '~/contexts';
import React from 'react';
import styled, { css } from 'styled-components';
import { NoResultsCell } from './StyledComponents';
import { colors, weights } from '~/styles';

const Time = styled.div`
  position: relative;
  padding: 0.2rem 1.5rem;
  font-weight: ${weights.medium};
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  ${({ status }) =>
    ({
      not_submitted: css`
        background: ${colors.grey10};
        color: ${colors.black};
      `,
      pending_approval: css`
        background: ${colors.warning10};
        color: ${colors.warning};
      `,
      rejected: css`
        background: ${colors.danger10};
        color: ${colors.danger};
      `,
      approved: css`
        background: ${colors.primary10};
        color: ${colors.primary};
      `,
    })[status]}
`;

const Stopwatch = styled(Tooltip)`
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div:first-child {
    margin-right: 1rem;
  }
`;

function TimeAuditingResults({
  data,
  sort,
  showProjectColumn = true,
  onLoadMore,
  onSort,
  onDelete,
  onResultClick,
  action,
}) {
  const confirmation = useConfirmation();

  return (
    <Stack>
      <Table.Status>
        {!!action && <Spinner />}
        <Table.Total value={data.total} label="Time Entry" />
      </Table.Status>

      <Table>
        <Table.BoxHeader sticky>
          <Table.Column width="3.5rem" align="center" />
          <Table.Column name="date" width="7.5rem" sort={sort} onSort={onSort}>
            Date
          </Table.Column>
          <Table.Column name="member" sort={sort} onSort={onSort}>
            Member
          </Table.Column>
          <Table.Column name="project" align="left" sort={sort} isVisible={showProjectColumn} onSort={onSort}>
            Project/Client
          </Table.Column>
          <Table.Column>Role/Task</Table.Column>
          <Table.Column align="center" width="11rem">
            Time
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body fade={action === 'filter'}>
          {data.results.map((entry) => {
            const locked = entry.isLocked;
            const tooltip = locked ? 'The time entry is locked.' : undefined;

            const handleEdit = () => onResultClick(entry, 'edit');
            const handleView = () => onResultClick(entry, 'view');

            const handleDelete = async () => {
              const confirm = await confirmation.prompt((resolve) => (
                <DeleteConfirmation resolve={resolve} title="Delete Time Entry">
                  Are you sure you want to delete this time entry?
                </DeleteConfirmation>
              ));
              if (!confirm) return;

              onDelete(entry);
            };

            return (
              <Table.BoxRow key={entry.id} onClick={locked ? handleView : handleEdit}>
                <Table.Cell>{locked && <TimeLockIcon value={entry.lockStatusId} />}</Table.Cell>
                <Table.Cell>
                  <DateTime value={entry.date} />
                </Table.Cell>
                <Table.Cell>{entry.member.name}</Table.Cell>
                <Table.Cell>
                  {entry.typeId === 'project_time' ? (
                    entry.project && (
                      <Table.CellContent>
                        {entry.project.name}
                        <small>{entry.project.client.name}</small>
                      </Table.CellContent>
                    )
                  ) : (
                    <Table.CellContent>{entry.type.name}</Table.CellContent>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {entry.typeId === 'project_time' ? (
                    entry.project && (
                      <Table.CellContent>
                        {entry.project?.useRoles && entry.role?.name}
                        {entry.task && <small>{entry.task.name}</small>}
                      </Table.CellContent>
                    )
                  ) : (
                    <Table.CellContent>{entry.timeOffType?.name}</Table.CellContent>
                  )}
                </Table.Cell>
                <Table.Cell flex="1">
                  <TimeApprovalPopover timeEntryId={entry.id}>
                    <TimeEntryInfo>
                      <BillableIcon value={entry.isActuallyBillable} />
                      <Time status={entry.statusId}>
                        {entry.timerStartedAt && (
                          <Stopwatch message="This time entry has a running timer">
                            <Icon icon="stopwatch" type="far" />
                          </Stopwatch>
                        )}
                        <Duration minutes={entry.minutes} timerStartedAt={entry.timerStartedAt} trim />
                      </Time>
                    </TimeEntryInfo>
                  </TimeApprovalPopover>
                </Table.Cell>
                <TableBoxRowActions>
                  {locked ? (
                    <TableBoxRowActions.View onClick={handleView} />
                  ) : (
                    <TableBoxRowActions.Edit onClick={handleEdit} />
                  )}

                  <hr />

                  <TableBoxRowActions.Dropdown>
                    {({ setIsOpen }) => {
                      return (
                        <>
                          <Dropdown.Item onClick={handleView}>View</Dropdown.Item>

                          <Dropdown.Item disabled={locked} tooltip={tooltip} onClick={handleEdit}>
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item
                            disabled={locked}
                            tooltip={tooltip}
                            onClick={() => setIsOpen(false) || handleDelete()}>
                            Delete
                          </Dropdown.Item>
                        </>
                      );
                    }}
                  </TableBoxRowActions.Dropdown>
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
          {data.results.length === 0 && (
            <Table.Row>
              <NoResultsCell style={{ fontSize: '0.875rem' }} fade={action === 'filter'}>
                No results
              </NoResultsCell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {data.total > data.results.length && (
        <InfiniteScrollingObserver key={data.results.length} onIntersecting={onLoadMore} />
      )}
    </Stack>
  );
}

export default TimeAuditingResults;
