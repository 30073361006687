import { InvoiceStatusSelect, Level, SearchInput, SingleSelect, Stack } from '~/components';
import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import React from 'react';
import intervals from '../invoices/invoices-list/intervals';
import InvoicesListPage from '../invoices/invoices-list/InvoicesListPage';
import { useHistory } from 'react-router-dom';

function Filters({ query, handleFilter }) {
  return (
    <Level padding="0.5rem 0 2.5rem 0">
      <Level.Item width="20rem">
        <SearchInput
          value={query.q}
          placeholder="Search"
          materialPlaceholder="Invoice #"
          materialAlwaysVisible
          onChange={({ target: { value } }) => handleFilter({ q: value })}
        />
      </Level.Item>

      <Level.Item width="20rem">
        <InvoiceStatusSelect
          value={query.statusIds}
          onChange={({ target: { value } }) => handleFilter({ statusIds: value })}
        />
      </Level.Item>

      <Level.Item width="20rem">
        <SingleSelect
          placeholder="All"
          materialPlaceholder="Issue Date"
          materialAlwaysVisible
          showEmptyOption
          value={query.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}>
          {_.map(intervals, ({ label }, key) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </SingleSelect>
      </Level.Item>
    </Level>
  );
}

function ProjectInvoicesTab({ project }) {
  const { workspace } = useWorkspace();
  const history = useHistory();

  return (
    <Stack margin="2rem 0 0">
      <InvoicesListPage
        mode="tab"
        showProjectColumn={false}
        projectId={project.id}
        renderFilters={Filters}
        onRowClick={(invoice) => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
      />
    </Stack>
  );
}

export default ProjectInvoicesTab;
