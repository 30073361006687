import { useState, useMemo } from 'react';
import Fuse from 'fuse.js';

function useFuzzyFilter(items, options = {}) {
  const [filterValue, setFilterValue] = useState('');

  const filteredItems = useMemo(() => {
    if (!filterValue) {
      return items;
    }

    const fuse = new Fuse(items, options);
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [items, filterValue, options]);

  return { filteredItems, setFilterValue };
}

export default useFuzzyFilter;
