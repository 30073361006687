import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';
import { Select } from './SingleSelect';
import Tooltip from './Tooltip';

const Container = styled.div`
  display: flex;

  &.has-error {
    input,
    ${Select} > div[role='button'] {
      border-color: ${colors.danger};
    }
  }
`;

const Controls = styled.div`
  flex: 1;
  display: flex;

  > * {
    flex: 1;
    padding: 0 0.75rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const ErrorTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;

const FieldControl = React.forwardRef(
  ({ children, error, hideErrorMessage, className, isVisible = true, errorPlacement = 'auto', ...props }, ref) => {
    let errorMessage = error;
    if (_.isArray(error) && error.length > 1) {
      errorMessage = (
        <ul>
          {error.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      );
    }
    return isVisible ? (
      <Container ref={ref} className={classNames('field', { 'has-error': !!error }, className)} {...props}>
        <Controls>{children}</Controls>
        {!hideErrorMessage && errorMessage && (
          <ErrorTooltip message={errorMessage} placement={errorPlacement}>
            <Icon icon="exclamation-circle" color={colors.danger} />
          </ErrorTooltip>
        )}
      </Container>
    ) : (
      children
    );
  },
);

export default FieldControl;
