import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Icon, SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const RoleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BillableIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  color: white;
  font-size: 0.6rem;
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey40)};
  border-radius: 50%;
`;

export function RoleDisplay({ role }) {
  if (!role) return null;
  return (
    <RoleContainer>
      {role.name}
      <BillableIcon isBillable={role.isBillable}>
        <Icon icon="dollar-sign" />
      </BillableIcon>
    </RoleContainer>
  );
}

const RoleSelect = React.forwardRef(({ projectId, memberId, initialRole, value: roleId, ...props }, ref) => {
  const { name, onChange } = props;

  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [assignRole, setAssignRole] = useState(false);
  const [roles, setRoles] = useState();
  const isMountedRef = useRef(false);

  const role = useMemo(
    () => _.find(roles, { id: roleId }) || (initialRole && _.isEqual(initialRole.id, roleId) ? initialRole : undefined),
    [roles, roleId, initialRole],
  );

  const groupedRoles = useMemo(() => {
    const groups = _.reduce(
      roles,
      (acc, role) => {
        const { assignedToMe } = role;

        let obj = _.find(acc, { assignedToMe });
        if (!obj) {
          obj = { assignedToMe, roles: [] };
          acc.push(obj);
        }

        obj.roles.push(role);
        obj.roles = _.sortBy(obj.roles, ['name', 'isBillable']);

        return acc;
      },
      [],
    );
    return _.orderBy(groups, ['assignedToMe'], ['desc']);
  }, [roles]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!projectId) {
      setRoles();
      return;
    }

    (async () => {
      setIsLoading(true);

      const { data } = await api.www
        .workspaces(workspace.id)
        .timeEntries()
        .projects(projectId)
        .roles()
        .get({ memberId });

      if (!isMountedRef.current) {
        return;
      }

      setIsLoading(false);
      setRoles(data);

      if (data?.length === 1) {
        setAssignRole(true);
      }
    })();
  }, [api, workspace.id, projectId, memberId]);

  // Auto-selects the role if there is only one available and none currently assigned
  useEffect(() => {
    if (!assignRole) {
      return;
    }
    setAssignRole(false);

    if (roleId || roles.length !== 1 || !_.isFunction(onChange)) {
      return;
    }

    onChange({ target: { name, value: roles[0].id } });
  }, [assignRole, roles, roleId, name, onChange]);

  return (
    <SingleSelect
      showEmptyOption={true}
      {...props}
      value={roleId}
      valueRenderer={<RoleDisplay role={role} />}
      disabled={isLoading}
      ref={ref}>
      {groupedRoles?.map((group) => (
        <optgroup key={`assigned_${group.assignedToMe}`} label={group.assignedToMe ? 'My Roles' : 'Other Roles'}>
          {group.roles.map((role) => (
            // eslint-disable-next-line react/no-unknown-property
            <option key={role.id} tooltip={role.name} value={role.id}>
              <RoleDisplay role={role} />
            </option>
          ))}
        </optgroup>
      ))}
    </SingleSelect>
  );
});

export default RoleSelect;
