import { useIsMounted } from '~/hooks';
import { rgba } from 'polished';
import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';

export const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.white};
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const Message = styled.div`
  max-width: ${({ maxWidth }) => maxWidth ?? '16rem'};
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 300;

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }

  @media print {
    display: none;
  }
`;

function Tooltip({
  message,
  placement = 'auto',
  maxWidth,
  showArrow,
  offsetTop = 8,
  offsetLeft = 0,
  delay,
  Container = 'div',
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const hoverHandler = useRef(null);
  const isMounted = useIsMounted();

  const modifiers = useMemo(() => {
    const modifiers = [{ name: 'offset', options: { offset: [offsetLeft, offsetTop] } }];

    if (showArrow) {
      modifiers.push({ name: 'arrow', options: { element: arrowElement } });
    }

    return modifiers;
  }, [showArrow, arrowElement, offsetTop, offsetLeft]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement,
    strategy: 'fixed',
  });

  const handleMouseEnter = () => {
    if (!delay) {
      setVisible(true);
    } else {
      if (hoverHandler.current) clearTimeout(hoverHandler.current);
      hoverHandler.current = () => setVisible(true);

      setTimeout(() => {
        if (hoverHandler.current && isMounted.current) hoverHandler.current();
        hoverHandler.current = null;
      }, delay);
    }
  };

  const handleMouseLeave = () => {
    setVisible(false);

    if (hoverHandler.current) {
      clearTimeout(hoverHandler.current);
      hoverHandler.current = null;
    }
  };

  return (
    <>
      <Container ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        message &&
        ReactDOM.createPortal(
          <Message ref={setPopperElement} maxWidth={maxWidth} style={styles.popper} {...attributes.popper}>
            {message}
            {showArrow && <Arrow ref={setArrowElement} style={styles.arrow} />}
          </Message>,
          document.body,
        )}
    </>
  );
}

export default Tooltip;
