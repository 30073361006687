import { Buttons, CancelButton, DateTime, Drawer, Form, Hours, Percent, Tab, Tabs } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import AllocationHistory from './AllocationHistory';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

function AllocationDetails({ onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [tabIndex, setTabIndex] = useState(0);
  const allocation = query.data;

  const { allocationId } = useParams();

  const toast = useToast();

  const title = 'View Allocation';

  useDocumentTitle(title);

  const actions = useMemo(() => {
    return {
      ready: (data) => setQuery((state) => ({ ...state, status: 'ready', data })),
    };
  }, []);

  const fetchData = useCallback(async () => {
    if (!allocationId) {
      actions.ready({});
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).allocations(allocationId).get();

      actions.ready(data);
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this allocation. Please try again.');
    }
  }, [actions, workspace.id, allocationId, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (query.status === 'loading') return null;
  if (query.status === 'ready' && !allocation) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Drawer isOpen title={title} onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => closeDrawer();

        return (
          <ReadForm>
            <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab>Allocation</Tab>
              <Tab>History</Tab>
            </Tabs>
            <Content>
              {
                [
                  <>
                    <Form.Section title="Details">
                      <Form.Control>
                        <ReadTextbox
                          label="Resource Type"
                          value={{ member: 'Member', placeholder: 'Placeholder' }[allocation.resourceTypeId]}
                        />
                      </Form.Control>

                      {{
                        member: () => (
                          <Form.Control>
                            <ReadTextbox label="Member" value={allocation.member.name} />
                          </Form.Control>
                        ),

                        placeholder: () => (
                          <Form.Control>
                            <ReadTextbox label="Placeholder" value={allocation.placeholder.name} />
                          </Form.Control>
                        ),
                      }[allocation.resourceTypeId]()}

                      <Form.Control>
                        <ReadTextbox
                          label="Assignment Type"
                          value={{ project: 'Project', time_off: 'Time Off' }[allocation.assignmentTypeId]}
                        />
                      </Form.Control>

                      {{
                        project: () => (
                          <>
                            <Form.Control>
                              <ReadTextbox label="Project" value={allocation.project.name} />
                            </Form.Control>

                            {allocation.project.useRoles && allocation.projectRole && (
                              <Form.Control>
                                <ReadTextbox label="Role" value={allocation.projectRole?.name} />
                              </Form.Control>
                            )}

                            {allocation.projectTask && (
                              <Form.Control>
                                <ReadTextbox label="Task" value={allocation.projectTask?.name} />
                              </Form.Control>
                            )}
                          </>
                        ),

                        time_off: () => (
                          <Form.Control>
                            <ReadTextbox label="Time Off Type" value={allocation.timeOffType.name} />
                          </Form.Control>
                        ),
                      }[allocation.assignmentTypeId]()}

                      {allocation.notes && (
                        <Form.Control>
                          <ReadTextbox label="Notes" value={allocation.notes} />
                        </Form.Control>
                      )}
                    </Form.Section>

                    <Form.Section title="Scheduling">
                      <Form.Control>
                        <ReadTextbox label="Start Date" value={<DateTime value={allocation.start} />} />
                        <ReadTextbox label="End Date" value={<DateTime value={allocation.end} />} />
                        {
                          {
                            day: (
                              <ReadTextbox
                                label="Hours Per Day"
                                style={{ textAlign: 'right' }}
                                value={<Hours value={allocation.hoursPerDay} />}
                              />
                            ),
                            week: (
                              <ReadTextbox
                                label="Hours Per Week"
                                style={{ textAlign: 'right' }}
                                value={<Hours value={allocation.hoursPerWeek} />}
                              />
                            ),
                            month: (
                              <ReadTextbox
                                label="Hours Per Month"
                                style={{ textAlign: 'right' }}
                                value={<Hours value={allocation.hoursPerMonth} />}
                              />
                            ),
                            allocation: (
                              <ReadTextbox
                                label="Hours (Total)"
                                style={{ textAlign: 'right' }}
                                value={<Hours value={allocation.hoursPerAllocation} />}
                              />
                            ),
                            ratio_of_capacity: (
                              <ReadTextbox
                                label="Percentage of Capacity"
                                style={{ textAlign: 'right' }}
                                value={<Percent value={allocation.hoursRatioOfCapacity} minimumFractionDigits={0} />}
                              />
                            ),
                          }[allocation.unit]
                        }
                      </Form.Control>

                      {allocation.resourceTypeId === 'member' && allocation.assignmentTypeId === 'project' && (
                        <Form.Control>
                          <ReadTextbox
                            label="Allocate on days when the member has time off"
                            value={allocation.allocateOnTimeOffDays ? 'Yes' : 'No'}
                          />
                        </Form.Control>
                      )}
                    </Form.Section>
                  </>,
                  <>
                    <AllocationHistory allocationId={allocation.id} />
                  </>,
                ][tabIndex]
              }
            </Content>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </ReadForm>
        );
      }}
    </Drawer>
  );
}

export default AllocationDetails;
