import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard, TextInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function CompanyDeleteConfirmation({ company, onClose, onDelete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, status }, form] = useForm();
  const [hasDependencies, setHasDependencies] = useState(null);
  const [text, setText] = useState('');

  useEffect(() => {
    (async () => {
      const { data: hasDependencies } = await api.www.workspaces(workspace.id).companies(company.id).hasDependencies();

      setHasDependencies(hasDependencies);
    })();
  }, [api, company.id, workspace.id]);

  async function handleDelete() {
    try {
      form.submit();
      await api.www.workspaces(workspace.id).companies(company.id).delete();

      await onDelete();
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }

  const valid = !hasDependencies || text === 'DELETE';

  return (
    <ModalCard title="Delete Company" onClose={onClose}>
      <ModalCard.Body>
        <P>
          This will delete the <strong>{company.name}</strong> company and all its related data including any
          opportunities, files and activities.
          {!hasDependencies && ' Are you sure?'}
        </P>
        {hasDependencies && (
          <>
            <P>
              To delete this company now, type the word "<strong>DELETE</strong>" into the box below and click the
              Delete button. This action cannot be undone.
            </P>
            <TextInput value={text} onChange={({ target: { value } }) => setText(value)} />
          </>
        )}
        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton disabled={!valid} isLoading={isSubmitting} onClick={handleDelete}>
            Delete
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default CompanyDeleteConfirmation;
