import { DateTime } from '~/components';
import moment from 'moment';
import React from 'react';
import Tag from './Tag';

export default function DateTag({ value, ...props }) {
  if (!value || !moment(value).isValid()) return null;
  return (
    <Tag label="Date" {...props}>
      <DateTime value={value} />
    </Tag>
  );
}
