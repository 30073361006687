import { Checkbox, Field, HelpTooltip, Percent } from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import BudgetAlertThresholdSelect from '../BudgetAlertThresholdSelect';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function BudgetRevenueAlertSetting({ project }) {
  switch (project.billingTypeId) {
    case 'tm':
      return (
        <Control>
          <Field.Checkbox name="budgetRevenueAlert" label="Notify at" />

          <BudgetAlertThresholdSelect name="budgetRevenueAlertThreshold" disabled={!project.budgetRevenueAlert} />

          <span>of the services revenue budget</span>

          <HelpTooltip
            message={
              <>
                A notification will be sent to the Project Admin and Client Relationship Owner when the project reaches
                the stated percentage and again when the project reaches 100% of the budget.
              </>
            }
            style={{ marginLeft: '.5rem' }}
          />
        </Control>
      );

    case 'fixed':
    case 'fixed_recurring':
      return (
        <Control>
          <Checkbox name="budgetRevenueAlert" label="Notify at" disabled value={false} />

          <div style={{ margin: '0 0.5rem' }}>
            <ReadTextbox
              value={
                <Percent
                  value={project.budgetRevenueAlertThreshold}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              }
            />
          </div>

          <span style={{ color: colors.grey40 }}>of the services revenue budget</span>

          <HelpTooltip
            message={
              <>
                A notification will be sent to the Project Admin and Client Relationship Owner when the project reaches
                the stated percentage and again when the project reaches 100% of the budget.
              </>
            }
            style={{ marginLeft: '.5rem' }}
          />
        </Control>
      );
  }
}
