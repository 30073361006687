import { useAuth, useDocumentTitle } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

export default function VarianceReportsPage() {
  useDocumentTitle('Variance Reports');

  const { url } = useRouteMatch();
  const auth = useAuth();

  return (
    <LandingPage>
      {auth.reports.hoursVarianceByMemberAndProject && (
        <NavBox to={url.concat('/hours-variance-by-member-and-project')}>
          <NavBox.Title>Hours Variance by Member and Project</NavBox.Title>
          <NavBox.Description>
            The variance between planned hours and worked hours, grouped by member and project.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.hoursVarianceByProjectAndResource && (
        <NavBox to={url.concat('/hours-variance-by-project-and-resource')}>
          <NavBox.Title>Hours Variance by Project and Resource</NavBox.Title>
          <NavBox.Description>
            The variance between planned hours and worked hours, grouped by project and resource.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.hoursVarianceByProjectAndRole && (
        <NavBox to={url.concat('/hours-variance-by-project-and-role')}>
          <NavBox.Title>Hours Variance by Project and Role</NavBox.Title>
          <NavBox.Description>
            The variance between planned hours and worked hours, grouped by project and role.
          </NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}
