import { Buttons, CancelButton, DateTime, Drawer, Tab, Tabs } from '~/components';
import ReadCheckbox from '~/components/read-only/ReadCheckbox';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import RejectionMessage from '~/components/RejectionMessage';
import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ExpenseItemHistory from './ExpenseItemHistory';
import ReceiptFiles from './ReceiptFiles';

const Content = styled(ReadForm)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

function ExpenseItemView({ expenseItem, rejectedHistoryItem, closeDrawer, ...props }) {
  const [tabIndex, setTabIndex] = useState(props.selectedTabIndex || 0);
  const {
    project,
    vendorName,
    member,
    expenseCategory,
    date,
    expenseAmount,
    unitAmount,
    unitCount,
    notes,
    attendees,
    isReimbursable,
    isBillable,
  } = expenseItem;

  const { workspace } = useWorkspace();
  const isOwner = workspace.member.id === member.id;

  const existingReceipts = useMemo(() => {
    return _.map(expenseItem?.receipts || [], (r) => ({
      id: r.id,
      contentType: r.contentType,
      fileName: r.fileName,
      expenseItemId: expenseItem.id,
      workspaceId: expenseItem.workspaceId,
      status: 'download',
    }));
  }, [expenseItem]);

  const hasReceipts = useMemo(() => {
    return existingReceipts.length > 0;
  }, [existingReceipts]);

  const hasHistory = useMemo(() => {
    const history = expenseItem?.history;
    return history && history.length > 0;
  }, [expenseItem]);

  return (
    <ReadForm>
      <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
        <Tab>Expense</Tab>
        {hasReceipts && <Tab>Receipts</Tab>}
        {hasHistory && <Tab>History</Tab>}
      </Tabs>
      <Content>
        {tabIndex === 0 && (
          <ReadForm>
            <ReadForm.Section title="Details">
              {expenseItem?.statusId === 'rejected' && rejectedHistoryItem && (
                <RejectionMessage historyItem={rejectedHistoryItem}></RejectionMessage>
              )}
              {!isOwner && (
                <ReadForm.Control>
                  <ReadTextbox label="Member" value={member.name} />
                </ReadForm.Control>
              )}
              <ReadForm.Control>
                <ReadTextbox label="Expense Category" value={expenseCategory.name} />
              </ReadForm.Control>
              {expenseCategory.unitName && (
                <ReadForm.Control>
                  <ReadTextbox label={`Number of ${expenseCategory.unitName}(s)`} value={unitCount} />
                  <ReadTextbox prefix="$" label={`Cost per ${expenseCategory.unitName}`} value={unitAmount} />
                </ReadForm.Control>
              )}
              <ReadForm.Control>
                <ReadTextbox label="Vendor Name" value={vendorName} />
              </ReadForm.Control>
              <ReadForm.Control>
                <ReadTextbox label="Expense Date" value={<DateTime value={date} />} />
                <ReadTextbox label="Expense Amount" prefix="$" value={expenseAmount} />
              </ReadForm.Control>
              <ReadForm.Control>
                <ReadTextbox label="Project" value={project ? project.name : 'Project Not Assigned'} />
              </ReadForm.Control>
              <ReadForm.Control>
                <ReadCheckbox checked={isReimbursable} label="Reimburse to me"></ReadCheckbox>
                <ReadCheckbox checked={isBillable} label="Bill to client"></ReadCheckbox>
              </ReadForm.Control>
              <ReadForm.Control>
                <ReadTextbox label="Notes" textarea value={notes} />
              </ReadForm.Control>
              {attendees && (
                <ReadForm.Control>
                  <ReadTextbox label="Attendees" textarea value={attendees ? attendees : 'Not Specified'} />
                </ReadForm.Control>
              )}
              {/* <ReadForm.Control>
                <ReadTextbox
                  label="Invoice"
                  value={invoice ? `#${invoice.number} (${invoice.statusId})` : 'Not Invoiced'}
                />
              </ReadForm.Control> */}
            </ReadForm.Section>
          </ReadForm>
        )}
        {tabIndex === 1 && <ReceiptFiles receipts={existingReceipts} isLocked={true} name="receipts" />}
        {tabIndex === 2 && <ExpenseItemHistory history={expenseItem?.history} />}
      </Content>
      <Drawer.Actions>
        <Buttons align="right">
          <CancelButton onClick={closeDrawer}>Close</CancelButton>
        </Buttons>
      </Drawer.Actions>
    </ReadForm>
  );
}

export { ExpenseItemView };
