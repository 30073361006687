import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { DeleteConfirmation, Dropdown, ExportDialog, Icon, Level, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ActiveStatusSelect, CustomDataHeader } from '../custom-data/components';
import HolidayScheduleDrawer from './HolidayScheduleDrawer';
import ExportDropdown from '../ExportDropdown';
import { mimeTypes } from '~/utils';

function HolidaySchedulesPage() {
  const title = 'Holiday Schedules';
  const documentTitle = useDocumentTitle(title);

  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ isActive: 'true' });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).holidaySchedules().get(params);
      setData(data);
    } catch {
      setData([]);
    }
  }, [api, workspace, params]);

  useEffect(() => {
    fetchData();
    return fetchData.cancel;
  }, [fetchData]);

  function handleEdit(item) {
    history.push(url.concat(`/${item.id}/edit`));
  }

  async function handleDelete(item) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure that you want to delete this Holiday Schedule?
      </DeleteConfirmation>
    ));
    if (!confirm) {
      return false;
    }

    await api.www.workspaces(workspace.id).holidaySchedules(item.id).delete();
    fetchData();
    return true;
  }

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).holidaySchedules(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDefaultStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).holidaySchedules(item.id).setDefaultStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/custom-data/holiday-schedules`);
    documentTitle.set(title);
  }

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .holidaySchedules()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Holiday Schedules</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            You can create multiple holiday schedules for your workspace and apply a holiday schedule to each member.
            This allows you to have a different set of{' '}
            <Link to={`/app/${workspace.key}/settings/custom-data/holidays`}>holidays</Link> for each country where you
            have personnel.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Link to={url.concat('/new')} className="button">
            New Holiday Schedule
          </Link>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
        </Level.Item>

        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holiday_schedules.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holiday_schedules.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Holiday Schedule" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column width="15rem">Name</Table.Column>
          <Table.Column>Description</Table.Column>
          <Table.Column width="11.5rem" align="right">
            Number of Holidays
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((item) => (
            <Table.BoxRow key={item.id} onClick={() => handleEdit(item)} isDisabled={!item.isActive}>
              <Table.Cell>
                <div>
                  {item.name}
                  {item.isDefault && (
                    <Tooltip
                      placement="right"
                      style={{ display: 'inline', marginLeft: '0.5rem' }}
                      message="The default holiday schedule for newly invited members.">
                      <Icon icon="badge-check" />
                    </Tooltip>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>{item.description}</Table.Cell>
              <Table.Cell>{item.holidays.length}</Table.Cell>
              <TableBoxRowActions>
                <TableBoxRowActions.Edit onClick={() => handleEdit(item)} />
                <hr />
                <TableBoxRowActions.Dropdown>
                  {({ setIsOpen }) => (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          handleEdit(item);
                          setIsOpen(false);
                        }}>
                        Edit
                      </Dropdown.Item>

                      {((!item.isDefault && item.isActive) || item.isDefault) && (
                        <Dropdown.Item
                          onClick={async () => {
                            await handleDefaultStatusChange(item, !item.isDefault);
                            setIsOpen(false);
                          }}>
                          {item.isDefault ? 'Unset as default' : 'Set as default'}
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={async () => {
                          await handleActiveStatusChange(item, !item.isActive);
                          setIsOpen(false);
                        }}>
                        {item.isActive ? 'Deactivate' : 'Activate'}
                      </Dropdown.Item>

                      <Dropdown.DeleteItem
                        tooltip="One or more members are currently assigned to this holiday schedule."
                        onCheckDependencies={async (workspace) =>
                          (await workspace.holidaySchedules(item.id).hasDependencies()).data
                        }
                        onClick={async () => {
                          await handleDelete(item);
                          setIsOpen(false);
                        }}>
                        Delete
                      </Dropdown.DeleteItem>
                    </>
                  )}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>
          ))}
        </Table.Body>
      </Table>
      <Switch>
        <Route path={`${path}/new`}>
          <HolidayScheduleDrawer onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>
        <Route path={`${path}/:holidayScheduleId/edit`}>
          <HolidayScheduleDrawer onClose={handleCloseDrawer} onDelete={handleDelete} onSaved={fetchData} />
        </Route>
      </Switch>
    </>
  );
}

export default HolidaySchedulesPage;
