import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';
import _ from 'lodash';
import styled from 'styled-components';
import Dropdown from './Dropdown';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function OpportunityStageSelect({ value, initialValue, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .opportunityStages()
        .get({ q, isActive: true, include: initialValue ? initialValue.id : undefined });
      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.statusId;

          a[key] = a[key] || { key, opportunityStages: [] };
          a[key].opportunityStages.push(v);
          return a;
        }, {}),
        'key',
      );

      const groupOrder = ['open', 'won', 'lost'];
      const orderedGroups = groupOrder.map((statusId) => groups.find((group) => group.key === statusId));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group });
        group.opportunityStages.forEach((opportunityStage) => {
          options.push({ type: 'opportunityStage', opportunityStage });
        });
      }

      return options;
    },
    [workspace.id, initialValue, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      placeholder="Opportunity Stage"
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderValue={(option) => option.name}
      onSearch={handleSearch}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.group.key}>
                    <strong>{{ open: 'Open', won: 'Won', lost: 'Lost' }[o.group.key]}</strong>
                  </Group>
                );
              case 'opportunityStage':
                return (
                  <AutoComplete.Option
                    grey={!o.opportunityStage.isActive ? 'true' : undefined}
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.opportunityStage.id}
                    value={o?.opportunityStage}
                    index={index++}>
                    <span
                      style={{
                        marginLeft: '.5rem',
                      }}>{`${o.opportunityStage.name} (${o.opportunityStage.probability}%) `}</span>
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

function FieldOpportunityStageSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <OpportunityStageSelect {...field} {...props} />
    </FieldControl>
  );
}

export default OpportunityStageSelect;
export { FieldOpportunityStageSelect };
