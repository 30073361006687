import React from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, Icon, ModalCard } from '~/components';
import { colors, weights } from '~/styles';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  code {
    font-weight: ${weights.bold};
    white-space: normal;
    word-wrap: break-word;
  }
`;

const Warning = styled(Description)`
  display: flex;
  padding: 1rem;
  background-color: ${colors.warning10};
  border-radius: 0.3125rem;
`;

const WarningIcon = styled(Icon)`
  font-size: 1.5rem;
  margin-right: 1rem;
  color: ${colors.warning};
`;

export default function ViewApiKey({ apiKey, onClose }) {
  return (
    <ModalCard title={apiKey.name ? `API Key: ${apiKey.name}` : 'API Key'} onClose={onClose}>
      <ModalCard.Body>
        <Description>Here is your new API token:</Description>
        <Description>
          <code>{apiKey.token}</code>
        </Description>
        <Warning>
          <WarningIcon icon="exclamation-triangle" />
          <span>
            This is the last time you will see the full token. Please copy it down somewhere secure, like a password
            manager. For security, you will only see the last 4 characters (<code>{apiKey.tokenLast4}</code>) listed
            when referencing this moving forward.
          </span>
        </Warning>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
