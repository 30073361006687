import { BillableIcon, DateTime, Hours, Percent } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import allocationBillableTypes from '~/lookups/allocation-billable-types';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useMemo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { AllocationTooltip, Arrow } from './AllocationStyles';

const Container = styled.div`
  max-width: 25rem;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 13rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 4.1rem;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;

export default function HoverTooltip({ allocationId, referenceElement, left }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const virtualReference = useMemo(() => {
    const allocationRect = referenceElement.getBoundingClientRect();

    const allocationClientBoundingRect = {
      top: allocationRect.top,
      height: allocationRect.height,
      left,
      width: 0,
    };

    return {
      getBoundingClientRect() {
        return allocationClientBoundingRect;
      },
    };
  }, [referenceElement, left]);

  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
    placement: 'auto',
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  useEffect(() => {
    if (!allocationId || query.isReady) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).allocations(allocationId).detailsCard();
        setQuery({ isReady: true, data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, query.isReady, allocationId]);

  if (!query.isReady) return null;

  const allocation = query.data;

  return ReactDOM.createPortal(
    <AllocationTooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Container>
        {{
          allocation: () => (
            <>
              {allocation.role && (
                <Info>
                  <Label>Role</Label>
                  <Value>{allocation.role.name}</Value>
                </Info>
              )}

              {allocation.task && (
                <Info>
                  <Label>Task</Label>
                  <Value>{allocation.task.name}</Value>
                </Info>
              )}

              <Info>
                <Label>Type</Label>
                <Value>
                  <BillableIcon value={allocation.billableTypeId === allocationBillableTypes.billable.id} />{' '}
                  {allocationBillableTypes[allocation.billableTypeId].name}
                </Value>
              </Info>

              <Info>
                <Label>Date Range</Label>
                <Value>
                  <span>
                    <DateTime value={allocation.start} /> to <DateTime value={allocation.end} /> (
                    {pluralize('day', moment(allocation.end).diff(allocation.start, 'days') + 1, true)})
                  </span>
                </Value>
              </Info>

              <Info>
                {
                  {
                    day: (
                      <>
                        <Label>HRS/Day</Label>
                        <Value>
                          <Hours value={allocation.hoursPerDay} minimumFractionDigits={0} />
                        </Value>
                      </>
                    ),
                    week: (
                      <>
                        <Label>HRS/Week</Label>
                        <Value>
                          <Hours value={allocation.hoursPerWeek} minimumFractionDigits={0} />
                        </Value>
                      </>
                    ),
                    month: (
                      <>
                        <Label>HRS/Month</Label>
                        <Value>
                          <Hours value={allocation.hoursPerMonth} minimumFractionDigits={0} />
                        </Value>
                      </>
                    ),
                    allocation: (
                      <>
                        <Label>HRS over {pluralize('day', allocation.days, true)}</Label>
                        <Value>
                          <Hours value={allocation.hoursPerAllocation} minimumFractionDigits={0} />
                        </Value>
                      </>
                    ),
                    ratio_of_capacity: (
                      <>
                        <Label>Percentage of Capacity</Label>
                        <Value>
                          <Percent value={allocation.hoursRatioOfCapacity} minimumFractionDigits={0} />
                        </Value>
                      </>
                    ),
                  }[allocation.unit]
                }
              </Info>

              {allocation.assignmentTypeId === 'project' && (
                <Info>
                  <Label>Allocate on Time Off Days</Label>
                  <Value>{allocation.allocateOnTimeOffDays ? 'Yes' : 'No'}</Value>
                </Info>
              )}

              <Info>
                <Label>Total Allocated</Label>
                <Value>
                  <Hours value={allocation.totalHours} minimumFractionDigits={0} />{' '}
                  {pluralize('hour', allocation.totalHours)}
                </Value>
              </Info>

              <Info>
                <Label>Remaining</Label>
                <Value>
                  <Hours value={allocation.remainingHours} minimumFractionDigits={0} />{' '}
                  {pluralize('hour', allocation.remainingHours)}
                </Value>
              </Info>

              {allocation.notes && (
                <Info>
                  <Label>Notes</Label>
                  <Value>
                    <div>
                      <p>{allocation.notes}</p>
                    </div>
                  </Value>
                </Info>
              )}
            </>
          ),

          time_entry: () => (
            <>
              <Info>
                <Label>Type</Label>
                <Value>
                  <BillableIcon value={allocation.isBillable} />{' '}
                  {allocationBillableTypes[allocation.billableTypeId].name}
                </Value>
              </Info>

              <Info>
                <Label>Date</Label>
                <Value>
                  <DateTime value={allocation.start} />
                </Value>
              </Info>

              <Info>
                <Label>Hours</Label>
                <Value>
                  <Hours value={allocation.hoursPerDay} minimumFractionDigits={0} />{' '}
                  {pluralize('hour', allocation.hoursPerDay)}
                </Value>
              </Info>

              {allocation.notes && (
                <Info>
                  <Label>Notes</Label>
                  <Value>
                    <div>
                      <p>{allocation.notes}</p>
                    </div>
                  </Value>
                </Info>
              )}
            </>
          ),

          holiday: () => (
            <>
              <Info>
                <Label>Type</Label>
                <Value>
                  <BillableIcon value={allocation.isBillable} />{' '}
                  {allocationBillableTypes[allocation.billableTypeId].name}
                </Value>
              </Info>

              <Info>
                <Label>Date</Label>
                <Value>
                  <DateTime value={allocation.start} />
                </Value>
              </Info>

              <Info>
                <Label>Hours</Label>
                <Value>
                  <Hours value={allocation.hoursPerDay} minimumFractionDigits={0} />{' '}
                  {pluralize('hour', allocation.hoursPerDay)}
                </Value>
              </Info>

              {allocation.notes && (
                <Info>
                  <Label>Notes</Label>
                  <Value>
                    <div>
                      <p>{allocation.notes}</p>
                    </div>
                  </Value>
                </Info>
              )}
            </>
          ),
        }[allocation.entity]()}
      </Container>

      <Arrow ref={setArrowElement} style={styles.arrow} />
    </AllocationTooltip>,
    document.body,
  );
}
