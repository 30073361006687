import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CompaniesListPage from './CompaniesListPage';
import CompanyCreateForm from './CompanyCreateForm';
import CompanyArea from './CompanyArea';

export default function CompaniesArea() {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleCreate = (company) => {
    history.push(`${url}/${company.id}/overview`);
  };

  return (
    <>
      <Switch>
        <Route path={[`${path}/`, `${path}/new`, `${path}/edit/:companyId`]} exact>
          <CompaniesListPage />
        </Route>
        <Route path={`${path}/:companyId`}>
          <CompanyArea />
        </Route>
      </Switch>
      <Route path={`${path}/new`}>
        <CompanyCreateForm
          onSaved={handleCreate}
          onClose={() => {
            history.push({ pathname: url, search: location.search });
          }}
        />
      </Route>
    </>
  );
}
