import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DeleteButton } from '~/components';
import { colors } from '~/styles';
import { useImpersonation } from '../contexts';
import ChangeStatus from './ChangeStatus';

const ActionList = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
  border-top: solid 1px ${colors.grey10};

  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

export default function Actions({ member, onChange }) {
  const { impersonatedMemberId, impersonate, stopImpersonation } = useImpersonation();
  const [changeStatus, setChangeStatus] = useState();

  const handleChange = () => {
    if (onChange) {
      onChange(member);
    }
  };

  return (
    <>
      <ActionList>
        {member.id === impersonatedMemberId ? (
          <DeleteButton onClick={() => stopImpersonation()}>Deactivate</DeleteButton>
        ) : (
          <Button disabled={!member.isActive || !member.isVerified} onClick={() => impersonate(member.id)}>
            Impersonate
          </Button>
        )}
        <Button
          isOutline={true}
          onClick={(event) => {
            event.stopPropagation();
            setChangeStatus(member);
          }}>
          Change Internal Status
        </Button>
      </ActionList>
      {changeStatus && <ChangeStatus member={changeStatus} onChange={handleChange} onClose={() => setChangeStatus()} />}
    </>
  );
}
