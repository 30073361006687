import * as Yup from 'yup';
import moment from 'moment';

function periodStartDateValidator({ periods, period, member: { activeStartDate, activeEndDate } }) {
  return Yup.date()
    .label('Start Date')
    .test({
      message: 'There is another period with the same start date.',
      test: (value) => {
        if (period.isDefault) return true;
        const start = value ? moment(value).format('YYYY-MM-DD') : null;
        return !periods.some((ap) => ap.id !== period.id && start === ap.start);
      },
    })
    .test({
      message: "Period start date must be within the member's active date range.",
      test: (value) => {
        if (period.isDefault) return true;
        value = moment(value);
        return (
          !value.isValid() ||
          ((!activeStartDate || value.isAfter(activeStartDate, 'day')) &&
            (!activeEndDate || value.isSameOrBefore(activeEndDate, 'day')))
        );
      },
    })
    .nullable();
}

export default periodStartDateValidator;
