import { BillableIcon, Button, Duration, Icon, InlineTooltip, TimeApprovalPopover, TimeLockIcon } from '~/components';
import { useApi, useSubscription, useTimeEntries, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useTimeEntryTimer } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import CalendarPage from './CalendarPage';
import { useListEntries, useScrollPosition } from './contexts';
import EditTimeEntry from './edit-time-entry';
import { useTimesheets } from './timesheets/TimesheetContext';
import TimesheetSubmittedTooltip from './timesheets/TimesheetSubmittedTooltip';
import ViewTimeEntry from './view-time-entry';

// isActive is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledButton = ({ isActive, ...props }) => <Button {...props} />;

const StyledLink = styled(Link)`
  color: ${colors.black};

  &:hover {
    color: ${colors.grey55};
  }
`;

const Results = styled.div`
  flex: 1;
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: -2.5rem;
  padding-top: 2rem;
  border-left: solid 2px ${colors.grey10};
`;

const TimeEntryGroupHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.625rem;
  margin-left: -2rem;
  margin-right: -2rem;
  background-color: ${({ color }) => color};
  border-radius: 999rem;
`;

const TimeEntryGroupIndicator = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 0.375rem;
  margin-right: 0.75rem;
  color: ${({ color }) => color};
  background-color: ${colors.white};
  border: solid 2px ${colors.grey10};
  border-radius: 999rem;

  ${({ isHoliday }) =>
    isHoliday &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        ${colors.primary10},
        ${colors.primary10} 5px,
        ${colors.white} 5px,
        ${colors.white} 10px
      );
    `}

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    bottom: 100%;
    width: 2px;
    height: 1.125rem;
    background-color: ${colors.grey10};
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    top: 100%;
    width: 2px;
    height: 1.125rem;
    background-color: ${colors.grey10};
  }

  .icon {
    font-size: 0.625rem;
  }
`;

const TimeEntryGroupDate = styled.div`
  font-weight: ${weights.black};
`;

const TimeEntryGroupHoliday = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  margin-left: 0.75rem;
  padding: 0 0.75rem;
  font-size: 0.75rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.primary25};
  border-radius: 999rem;
`;

const TimeEntryGroupTime = styled.div`
  margin-left: auto;
  margin-right: 1.5rem;
  font-weight: ${weights.black};

  ${({ isTimesheetSubmitted }) =>
    isTimesheetSubmitted &&
    css`
      color: ${colors.grey25};
    `}

  span {
    margin-left: 0.5rem;
    color: ${colors.grey40};
    font-size: 0.75rem;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
  }
`;

const AddTimeEntryButton = styled(Button)`
  width: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.white};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }
`;

const AddTimeEntryButtonAlt = styled(AddTimeEntryButton)`
  background-color: ${colors.grey5};
`;

const NoTimeEntries = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`;

const TimeEntries = styled.div`
  display: grid;
  grid-row-gap: 1px;
  margin-top: 1.25rem;
  margin-left: 2.75rem;
  margin-right: 2.75rem;
  margin-bottom: 2.5rem;
  background-color: ${colors.grey10};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const TimeEntry = styled.div`
  display: flex;
  padding: 0.875rem 1.5rem;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  &:hover {
    margin: -0.1875rem;
    padding: 1.0625rem 1.6875rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
    z-index: 1;
  }
`;

const TimeEntryBody = styled.div`
  flex: 1;
  padding-right: 0.625rem;
`;

const TimeEntryControls = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

const TimeEntryDescription = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.875rem;

  strong {
    color: ${colors.black};
  }
`;

const TimeEntryRole = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
`;

const TimeEntryTask = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-weight: ${weights.medium};
`;

const TimeEntryNotes = styled.p`
  margin-top: 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  white-space: pre-wrap;
  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const TimeEntryHours = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 1.875rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-weight: ${weights.medium};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey10,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};
  border-radius: 999rem;

  .icon {
    margin-right: 0.5rem;
    font-size: 0.625rem;
    opacity: 0.5;
  }
`;

const PlayPauseButton = styled(StyledButton)`
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  padding-left: ${({ isActive }) => (isActive ? '0' : '0.125rem')};
  margin-left: 0.625rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.primary)};
  font-size: 0.75rem;
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  position: relative;

  &&:disabled {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }

  &:hover {
    color: ${colors.white};
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.primary)};
  }
`;

const TimeEntryLocked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 0.625rem;
`;

const ApprovalCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.375rem;
  height: 1.375rem;
  margin-left: 0.5rem;
  padding: 0 0.375rem;
  color: ${colors.black};
  white-space: nowrap;
  background-color: ${colors.white};
  border-radius: 999rem;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

function colorFromStatus(entries, soft = false) {
  const hasRejected = !!_.find(entries, (entry) => entry.status?.id === 'rejected');

  const hasUnsbumittted = !!_.find(entries, (entry) => entry.status?.id === 'not_submitted');

  const hasPendingApproval = !!_.find(entries, (entry) => entry.status?.id === 'pending_approval');

  if (hasRejected) {
    return soft ? colors.danger10 : colors.danger;
  } else if (hasUnsbumittted) {
    return soft ? colors.grey10 : colors.black;
  } else if (hasPendingApproval) {
    return soft ? colors.warning10 : colors.warning;
  } else if (entries?.length > 0) {
    return soft ? colors.primary10 : colors.primary;
  } else {
    return soft ? colors.grey5 : colors.black;
  }
}

function EntriesView({ date, member, entries, getHolidays, targetRef, onOpenForm, onPlayPause }) {
  const { workspace } = useWorkspace();

  const groupedEntries = useMemo(() => {
    const groups = _.reduce(
      entries,
      (acc, entry) => {
        const { date } = entry;
        let group = _.find(acc, { date });
        if (!group) {
          group = { date, minutes: 0, timerStartedAt: null, entries: [] };
          acc.push(group);
        }

        group.minutes += entry.minutes;
        if (entry.timerStartedAt) {
          group.timerStartedAt = entry.timerStartedAt;
        }
        group.entries.push(entry);
        group.entries = _.sortBy(group.entries, [
          'project.client.name',
          'project.name',
          'role.name',
          'task.name',
          'notes',
        ]);

        return acc;
      },
      [],
    );

    const dateGroup = _.find(groups, { date });
    if (!dateGroup) {
      groups.push({ date, minutes: 0, timerStartedAt: null, entries: [] });
    }

    return _.orderBy(groups, ['date'], ['desc']);
  }, [date, entries]);

  const timesheetContext = useTimesheets();

  return (
    <Results>
      {groupedEntries.map((entryGroup) => {
        const holidays = getHolidays(entryGroup.date);
        const isHoliday = holidays.length > 0;
        const isTimesheetSubmitted = timesheetContext.isTimesheetSubmitted({
          start: entryGroup.date,
          end: entryGroup.date,
        });

        return (
          <Fragment key={entryGroup.date}>
            <TimeEntryGroupHeader
              ref={moment(entryGroup.date).isSame(date, 'day') ? targetRef : undefined}
              color={colorFromStatus(entryGroup.entries, true)}>
              <TimeEntryGroupIndicator color={colorFromStatus(entryGroup.entries, false)} isHoliday={isHoliday}>
                <Icon icon="circle" />
              </TimeEntryGroupIndicator>
              <TimeEntryGroupDate>
                <StyledLink to={`/app/${workspace.key}/time/day?date=${entryGroup.date}`}>
                  {moment(entryGroup.date).format('dddd, MMMM D, YYYY')}
                  {moment(entryGroup.date).isSame(moment(), 'day') && ' : Today'}
                </StyledLink>
              </TimeEntryGroupDate>
              {isHoliday && (
                <TimeEntryGroupHoliday>
                  {_(holidays)
                    .sortBy(['name'])
                    .map((holiday) => holiday.name)
                    .value()
                    .join(', ')}
                </TimeEntryGroupHoliday>
              )}
              <TimeEntryGroupTime isTimesheetSubmitted={isTimesheetSubmitted}>
                <Duration minutes={entryGroup.minutes} timerStartedAt={entryGroup.timerStartedAt} />
                <span>hrs</span>
              </TimeEntryGroupTime>
              <AddTimeEntryButton
                disabled={isTimesheetSubmitted}
                style={{ position: 'relative' }}
                onClick={() =>
                  onOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date: entryGroup.date } })
                }>
                <Icon icon="plus" />
                {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
              </AddTimeEntryButton>
            </TimeEntryGroupHeader>
            {entryGroup.entries.length > 0 ? (
              <TimeEntries>
                {entryGroup.entries.map((entry) => {
                  let disableTimerReason;
                  if (member) {
                    disableTimerReason = 'You can only start timers on your own time entries.';
                  } else if (!entry.timerStartedAt) {
                    if (
                      entry.statusId === 'pending_approval' ||
                      (entry.statusId === 'approved' && entry.statusModeId === 'manual')
                    ) {
                      disableTimerReason =
                        'You can only start timers on time entries that are either "Not Submitted", "Rejected" or "Automatically Approved".';
                    } else if (entry.invoiceId) {
                      disableTimerReason =
                        'This time entry is associated with an invoice and its timer cannot be started.';
                    } else if (entry.clientApprovalId) {
                      disableTimerReason =
                        'This time entry is associated with a client approval and its timer cannot be started.';
                    } else if (entry.typeId === 'project_time' && entry.project.recordStatusId !== 'active') {
                      disableTimerReason = 'You can only start a timer on an active project.';
                    } else if (timesheetContext.useTimesheets && entry.timesheetId) {
                      disableTimerReason =
                        'This time entry is associated with a timesheet and its timer cannot be started.';
                    }
                  }

                  return (
                    <TimeEntry
                      key={entry.id}
                      onClick={() =>
                        onOpenForm({
                          mode: entry.isLocked ? 'view' : 'edit',
                          id: entry.id,
                          memberId: member?.id,
                        })
                      }>
                      <TimeEntryBody>
                        {entry.type.id === 'project_time' ? (
                          entry.project &&
                          entry.project.client && (
                            <TimeEntryDescription>
                              <strong>{entry.project.client.name}</strong> / {entry.project.name}
                            </TimeEntryDescription>
                          )
                        ) : (
                          <TimeEntryDescription>
                            <strong>Time Off</strong>
                            {entry.timeOffType && <> / {entry.timeOffType.name}</>}
                          </TimeEntryDescription>
                        )}
                        {entry.role?.name && <TimeEntryRole>{entry.role.name}</TimeEntryRole>}
                        {entry.task?.name && <TimeEntryTask>{entry.task.name}</TimeEntryTask>}
                        {entry.notes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
                      </TimeEntryBody>
                      <TimeEntryControls>
                        <BillableIcon value={entry.isActuallyBillable} />

                        <TimeApprovalPopover timeEntryId={entry.id}>
                          <TimeEntryHours status={entry.status?.id}>
                            <Duration
                              minutes={entry.minutes}
                              timerStartedAt={entry.timerStartedAt}
                              showSeconds={!!entry.timerStartedAt}
                              trim={!entry.timerStartedAt}
                            />
                          </TimeEntryHours>
                        </TimeApprovalPopover>

                        {entry.isLocked ? (
                          <TimeEntryLocked>
                            <TimeLockIcon value={entry.lockStatusId} />
                          </TimeEntryLocked>
                        ) : (
                          <PlayPauseButton
                            isAnchor
                            disabled={!!disableTimerReason}
                            isActive={!!entry.timerStartedAt}
                            onClick={onPlayPause.bind(this, entry)}>
                            <Icon icon={entry.timerStartedAt ? 'pause' : 'play'} />
                            {disableTimerReason && <InlineTooltip message={disableTimerReason} />}
                          </PlayPauseButton>
                        )}
                      </TimeEntryControls>
                    </TimeEntry>
                  );
                })}
              </TimeEntries>
            ) : (
              <NoTimeEntries>
                <AddTimeEntryButtonAlt
                  onClick={() =>
                    onOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date: entryGroup.date } })
                  }>
                  <Icon icon="plus" />
                </AddTimeEntryButtonAlt>
              </NoTimeEntries>
            )}
          </Fragment>
        );
      })}
    </Results>
  );
}

function ListCalendar({ view, date, setDate, member, setMember, getHolidays }) {
  const scrollBuffer = 400;
  const title = 'Time Entries';
  const documentTitle = useDocumentTitle(title);

  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const { removeEntry, updateEntry } = useTimeEntries();
  const { data, afterHasMore, beforeHasMore, load, loadAfter, loadBefore } = useListEntries(date, member);
  const { position, updatePosition } = useScrollPosition();
  const { startStopTimer, timerChanging } = useTimeEntryTimer();

  const [entries, setEntries] = useState(data);
  const [operation, setOperation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasInit, setHasInit] = useState(false);
  const [checkPosition, setCheckPosition] = useState(false);
  const [formProps, setFormProps] = useState();
  const [submittingEntries, setSubmittingEntries] = useState(false);
  const { notify } = useSubscription();

  const startHeight = useRef();

  const targetRef = useRef();

  const isReady = useMemo(() => hasInit && !isLoading && !timerChanging, [hasInit, isLoading, timerChanging]);

  const unsubmittedEntryCount = useMemo(() => {
    const unsubmittedEntries = _.filter(
      entries,
      ({ date: entryDate, statusId, timerStartedAt }) =>
        !timerStartedAt && (statusId === 'not_submitted' || statusId === 'rejected') && entryDate === date,
    );
    return unsubmittedEntries.length;
  }, [date, entries]);

  const loadEntries = useCallback(async () => {
    setHasInit(false);
    setCheckPosition(false);
    setIsLoading(true);
    setOperation('load');
    await load();
    setIsLoading(false);
    setHasInit(true);
  }, [load]);

  const loadEntriesAfter = useCallback(async () => {
    setCheckPosition(false);
    setIsLoading(true);
    setOperation('loadAfter');
    await loadAfter();
    setIsLoading(false);
  }, [loadAfter]);

  const loadEntriesBefore = useCallback(async () => {
    setCheckPosition(false);
    setIsLoading(true);
    setOperation('loadBefore');
    await loadBefore();
    setIsLoading(false);
  }, [loadBefore]);

  // Performs initial load, will refire if `date` or `workspace.id` changes
  useEffect(() => {
    loadEntries();
  }, [loadEntries]);

  // Will only update `entries` if `data` actually changed
  useEffect(() => {
    if (!_.isEqual(data, entries)) {
      // Used to grab page state before fresh data is rendered
      if (targetRef.current) {
        startHeight.current = targetRef.current.getBoundingClientRect().top;
      }
      setEntries(data);
    }
  }, [data, entries]);

  // Should only fire after new entries are loaded/rendered
  useEffect(() => {
    if (!isReady) {
      return;
    }

    if (operation === 'load' && targetRef.current) {
      const targetPosition = targetRef.current.getBoundingClientRect();
      const parentPosition = targetRef.current.offsetParent.getBoundingClientRect();
      window.scrollTo({ top: targetPosition.y - parentPosition.y - 32 });

      updatePosition();
    } else if (operation === 'loadAfter' && targetRef.current && startHeight.current) {
      const end = targetRef.current.getBoundingClientRect().top;
      const diff = end - startHeight.current;
      window.scrollTo({ top: window.scrollY + diff });

      updatePosition();
    }

    if (operation) {
      setCheckPosition(true);
    }
  }, [entries, isReady, operation, updatePosition]);

  // Should only fire after the position was potentially updated post-render
  useEffect(() => {
    if (!isReady || !checkPosition) {
      return;
    }
    if (afterHasMore && position.toTop < scrollBuffer) {
      loadEntriesAfter();
    }
    if (beforeHasMore && position.toBottom < scrollBuffer) {
      loadEntriesBefore();
    }
  }, [position, checkPosition, isReady, afterHasMore, beforeHasMore, loadEntriesAfter, loadEntriesBefore]);

  const handleCloseForm = () => {
    setFormProps(null);
    documentTitle.set(title);
  };

  const handleOpenForm = (props) => {
    setFormProps(props);
  };

  const handlePlayPause = async (entry, event) => {
    event.stopPropagation();
    setOperation(null);
    await startStopTimer(entry);
  };

  const handleSave = async (entry) => {
    updateEntry(entry);

    if (entry.date !== date) {
      setDate(entry.date);
    }

    if (!_.find(entries, { id: entry.id })) {
      await load();
    }
  };

  const handleDelete = (entry) => {
    removeEntry(entry);
  };

  const handleSubmitEntries = async () => {
    setSubmittingEntries(true);

    // TODO: This currently only submits entries for the current date, but the
    // full list may show entries outside this date. It could be fixed by
    // finding the range of dates and submit that, but the list is not
    // guarenteed to include all the entries for all dates, so a list of
    // specific ids might need to submitted instead.

    await api.www.workspaces(workspace.id).timeEntries().submitDay({ date, memberId: member?.id });

    await load();

    setSubmittingEntries(false);

    notify(useSubscription.keys.refresh_time_approval_count);

    toast.success('Time entries have been submitted for approval.');
  };

  const hideSubmitButton = true; // Temporarily remove list calendar time entry submit button #1147

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () => timesheetContext.isTimesheetSubmitted({ start: date, end: date }),
    [timesheetContext, date],
  );

  const pageActions = hasInit ? (
    <>
      <Button
        isOutline
        disabled={isTimesheetSubmitted}
        style={{ position: 'relative' }}
        onClick={() => handleOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date } })}>
        New Time Entry
        {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
      </Button>
      {!hideSubmitButton && unsubmittedEntryCount > 0 && (
        <Button isLoading={submittingEntries} onClick={handleSubmitEntries}>
          Submit for Approval <ApprovalCount visible={!submittingEntries}>{unsubmittedEntryCount}</ApprovalCount>
        </Button>
      )}
    </>
  ) : null;

  return (
    <>
      <CalendarPage
        actions={pageActions}
        view={view}
        date={date}
        setDate={setDate}
        member={member}
        setMember={setMember}>
        {hasInit ? (
          <EntriesView
            date={date}
            member={member}
            entries={entries}
            getHolidays={getHolidays}
            targetRef={targetRef}
            onOpenForm={handleOpenForm}
            onPlayPause={handlePlayPause}
          />
        ) : (
          <PageLoader />
        )}
      </CalendarPage>
      {formProps &&
        {
          edit: (
            <EditTimeEntry
              member={member}
              onClose={handleCloseForm}
              onSaved={handleSave}
              onDeleted={handleDelete}
              {...formProps}
            />
          ),
          view: <ViewTimeEntry member={member} onClose={handleCloseForm} {...formProps} />,
        }[formProps.mode]}
    </>
  );
}

export default ListCalendar;
