import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  Radio,
  SkillMultiSelect,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function ResourcePlaceholderDrawer({ resourcePlaceholder, onSaved, onClose, onDeleted }) {
  const title = `${resourcePlaceholder?.id ? 'Edit' : 'New'} Resource Placeholder`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [hasDependencies, setHasDependencies] = useState(true);
  const features = useFeatures();

  useEffect(() => {
    if (!resourcePlaceholder?.id) {
      setHasDependencies(false);
      return;
    }

    (async function fetchData() {
      const dependencies = await api.www
        .workspaces(workspace.id)
        .resourcePlaceholders(resourcePlaceholder.id)
        .hasDependencies();
      setHasDependencies(dependencies.data);
    })();
  }, [api, resourcePlaceholder, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      isBillable: true,
      rateCurrency: workspace.currency,
      rate: '',
      costCurrency: workspace.currency,
      costPerHour: '',
      discipline: null,
      practice: null,
      location: null,
      skills: [],
    },
    resourcePlaceholder,
  );

  return (
    <>
      <Drawer
        isOpen
        title={title}
        byline="Custom Data"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values, { resetForm }) {
            try {
              form.submit(formRef.current.status.action);

              const body = emptyStringToNull({
                ..._.omit(values, 'discipline', 'practice', 'location', 'skills'),
                disciplineId: values.discipline?.id ?? null,
                practiceId: values.practice?.id ?? null,
                locationId: values.location?.id ?? null,
                resourcePlaceholderSkills: values.skills.map((skill) => ({ skillId: skill.id })),
              });

              const { data } = await api.www
                .workspaces(workspace.id)
                .resourcePlaceholders(resourcePlaceholder ? resourcePlaceholder.id : undefined)
                .upsert(body);

              await onSaved(data);
              form.save(formRef.current.status.action);

              switch (formRef.current.status.action) {
                case 'new':
                  resetForm();
                  toast.success('Resource Placeholder successfully saved.');
                  break;
                case 'close':
                  closeDrawer();
                  break;
              }
              form.done();
            } catch ({ message }) {
              form.error({ message });
            }
          }

          async function handleDelete() {
            const confirm = await confirmation.prompt((resolve) => (
              <DeleteConfirmation resolve={resolve}>
                Are you sure you want to delete this Resource Placeholder?
              </DeleteConfirmation>
            ));
            if (!confirm) return;
            try {
              await api.www.workspaces(workspace.id).resourcePlaceholders(resourcePlaceholder.id).delete();
              await onDeleted();
              closeDrawer();
            } catch ({ message }) {
              toast.error(message);
            }
          }

          return (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                name: Yup.string().label('Name').max(255).required(),
                costPerHour: Yup.number().label('Average Cost per Hour').min(0).max(99999999999).nullable(),
                rate: Yup.number().label('Average Bill Rate').min(0).max(99999999999).nullable(),
              })}>
              {(formik) => {
                const submit = async (action) => {
                  formik.setStatus({ action });
                  formik.submitForm();
                };

                return (
                  <Form>
                    <Form.Section title="General">
                      <Form.Control>
                        <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                      </Form.Control>
                      {features.practices && (
                        <Form.Control>
                          <Field.PracticeSelect name="practice" placeholder="Practice" />
                        </Form.Control>
                      )}
                      {features.disciplines && (
                        <Form.Control>
                          <Field.DisciplineSelect name="discipline" placeholder="Discipline" />
                        </Form.Control>
                      )}
                      <Form.Control>
                        <Field.LocationSelect name="location" placeholder="Location" />
                      </Form.Control>
                      <Form.Control>
                        <SkillMultiSelect
                          name="skills"
                          placeholder="Skills"
                          value={formik.values.skills}
                          allowNew
                          onChange={({ target: { value } }) => formik.setFieldValue('skills', value)}
                        />
                      </Form.Control>
                      <Form.Control>
                        <Form.Control>
                          <Field.RadioGroup name="isBillable">
                            <Radio value={true} label="Billable" />
                            <Radio value={false} label="Non-billable" />
                          </Field.RadioGroup>
                        </Form.Control>
                      </Form.Control>
                      {formik.values.isBillable && (
                        <>
                          <Form.Control help="This placeholder can only be allocated to projects that use this currency.">
                            <Field.WorkspaceCurrencySelect
                              name="rateCurrency"
                              placeholder="Rate Currency"
                              clearable={false}
                              disabled={
                                hasDependencies !== false ||
                                (!features.multicurrency && resourcePlaceholder.rateCurrency === workspace.currency)
                              }
                            />
                            <Field.Currency
                              name="rate"
                              placeholder="Average Bill Rate"
                              precision={7}
                              currency={formik.values.rateCurrency}
                            />
                          </Form.Control>
                        </>
                      )}
                      <Form.Control>
                        <Field.WorkspaceCurrencySelect
                          name="costCurrency"
                          placeholder="Cost Currency"
                          clearable={false}
                        />

                        <Field.Currency
                          name="costPerHour"
                          placeholder="Average Cost per Hour"
                          precision={7}
                          currency={formik.values.costCurrency}
                        />
                      </Form.Control>
                    </Form.Section>

                    {status && <FormMessage.Error>{message}</FormMessage.Error>}

                    <Drawer.Actions>
                      {resourcePlaceholder.id && !hasDependencies && (
                        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                        {!resourcePlaceholder?.id && (
                          <ActionButton
                            isLoading={isSubmitting === 'new'}
                            ok={saved === 'new'}
                            type="submit"
                            onClick={() => submit('new')}>
                            Save &amp; New
                          </ActionButton>
                        )}

                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          type="submit"
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </>
  );
}
