import { useAuth } from '~/hooks';
import React, { useMemo } from 'react';
import SingleSelect from './SingleSelect';

export default function InvoiceStatusSelect({ value, onChange }) {
  const auth = useAuth();

  const statusOptions = useMemo(() => {
    const options = [];

    if (auth.invoices.manage.project) {
      options.push({ value: 'draft,open', label: 'Draft & Open' });
      options.push({ value: 'draft', label: 'Draft' });
    }

    options.push({ value: 'open', label: 'Open' });
    options.push({ value: 'paid', label: 'Paid' });

    return options;
  }, [auth.invoices.manage.project]);

  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Status"
      materialAlwaysVisible
      showEmptyOption
      value={value}
      onChange={onChange}>
      {statusOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SingleSelect>
  );
}
