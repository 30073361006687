import React from 'react';
import { Hours } from '~/components';
import { sum } from '~/utils/math';

function HoursPerWeek({ value }) {
  //Dynamically calculates (during and after editing) the total number of hours per week
  //Update null values to 0 and sum using Big.js
  const hours = value.map(function (number) {
    return number === null ? 0 : number;
  });

  const total = sum(hours);

  return <Hours value={total} minimumFractionDigits={0} />;
}

export default HoursPerWeek;
