import {
  Button,
  Confirmation,
  DateTime,
  Dropdown,
  HelpTooltip,
  Icon,
  InlineTooltip,
  Level,
  Page,
  SearchInput,
  Spinner,
  Stack,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import ClientFilesDrawer from './ClientFilesDrawer';
import FilePreview from './FilePreview';
import { Table, TableBoxRowActions } from '~/components/table';
import TableBody from '~/components/table/TableBody';
import { QuerySort } from '~/utils';
import { useSearchParams, useSearchParamsConfig } from '~/hooks';

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const WarningIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  margin-top: -4rem;
  font-weight: ${weights.light};
`;

export const AddButton = styled(Button)`
  position: relative;
  margin-left: auto;
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const NoFilesMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.bold};
`;

const ViewButton = styled(Button)`
  position: relative;
  margin-left: auto;
  font-size: 0.875rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  background: ${colors.grey5};
  transition: all 100ms ease-in-out;
  border: none;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }
`;

export default function ClientFilesTab({ client }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const [showDrawer, setShowDrawer] = useState(false);

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    sort: new QuerySort('name', 'asc'),
    view: 'list',
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: params.q },
        sort: { default: params.sort, ...searchParamsConfig.sort },
        view: { default: 'list', valid: ['grid', 'list'] },
      }),
      [params, searchParamsConfig],
    ),
    sessionKey: 'client_files_tab',
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).clients(client.id).files().get(urlSearchParams);
      setQuery({ status: 'ready', data, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [api, workspace.id, client.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const hasFiles = useMemo(() => query.data?.length > 0, [query.data]);
  const hasConfidentialFiles = useMemo(() => _.some(query.data, { isConfidential: true }), [query.data]);
  const hasPublicFiles = useMemo(() => _.some(query.data, { isConfidential: false }), [query.data]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams((state) => ({ ...state, sort: querySort }));
    searchParams.set({ sort: querySort });
  };

  const handleViewChange = (view) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const querySort = new QuerySort('name', 'asc');
    setParams((state) => ({ ...state, q: '', sort: querySort, view }));
    searchParams.set({ q: null, sort: null, view });
  };

  async function handleDeleteConfirmation(file) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this file?</Confirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).clients(client.id).files(file.id).delete();
    await fetchData();
  }

  return (
    <>
      <Stack margin="2.5rem 0 0">
        <Page.Section>
          <Level>
            {params.view === 'list' && (
              <Level.Item width="20rem">
                <SearchInput
                  value={params.q}
                  placeholder="Search"
                  materialPlaceholder="Filename"
                  materialAlwaysVisible
                  onChange={handleChange}
                />
              </Level.Item>
            )}
            <Level.Item right>
              {
                {
                  list: (
                    <ViewButton data-testid="grid-button" onClick={() => handleViewChange('grid')}>
                      <Tooltip message="Grid View" delay={500}>
                        <Icon icon="grid-2" />
                      </Tooltip>
                    </ViewButton>
                  ),
                  grid: (
                    <ViewButton data-testid="list-button" onClick={() => handleViewChange('list')}>
                      <Tooltip message="List View" delay={500}>
                        <Icon icon="table-rows" />
                      </Tooltip>
                    </ViewButton>
                  ),
                }[params.view]
              }
            </Level.Item>
            <Level.Item right narrow>
              <AddButton onClick={() => setShowDrawer(true)} disabled={!client.permissions.edit}>
                Add Files
                {!client.permissions.edit && (
                  <InlineTooltip message="Your security role prohibits you from adding files." />
                )}
              </AddButton>
            </Level.Item>
          </Level>
        </Page.Section>
        {query.status === 'loading' ? (
          <PageLoader />
        ) : query.error ? (
          <ErrorContainer>
            <ErrorMessage>
              <WarningIcon icon="exclamation-triangle" />
              There was a problem getting the client files
            </ErrorMessage>
            <Button onClick={() => fetchData()}>Retry</Button>
          </ErrorContainer>
        ) : (
          <Page.Section>
            <Table.Status>
              {query.status !== 'ready' && <Spinner />}
              <Table.Total label="Client File" value={query.data?.length} />
            </Table.Status>
            {
              {
                list: (
                  <Table data-testid="members-list">
                    <Table.BoxHeader sticky>
                      <Table.Column name="name" onSort={handleSort} sort={params.sort}>
                        Filename
                      </Table.Column>
                      <Table.Column name="isConfidential" onSort={handleSort} sort={params.sort}>
                        Access
                      </Table.Column>
                      <Table.Column width="8rem" align="right" name="createdAt" onSort={handleSort} sort={params.sort}>
                        Created
                      </Table.Column>

                      <Table.BoxActionsColumn />
                    </Table.BoxHeader>

                    <TableBody fade={query.status !== 'ready'}>
                      {query.data.map((file) => {
                        const { id, name, url, createdAt, isConfidential } = file;

                        return (
                          <Table.BoxRow key={id} onClick={() => window.open(url, '_blank')}>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{isConfidential ? 'Administrators' : 'Team'}</Table.Cell>
                            <Table.Cell>
                              <DateTime value={createdAt} />
                            </Table.Cell>
                            <TableBoxRowActions>
                              <Button onClick={() => window.open(url, '_blank')}>
                                <Icon icon="external-link-alt" />
                              </Button>

                              <hr />

                              <TableBoxRowActions.Dropdown>
                                {({ setIsOpen }) => {
                                  const handleAction = (action) => setIsOpen(false) || action;

                                  return (
                                    <>
                                      <Dropdown.Item onClick={() => handleAction(window.open(url, '_blank'))}>
                                        View
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleAction(window.open(url + '?download=1'))}>
                                        Download
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        disabled={!client.permissions.edit}
                                        tooltip={
                                          !client.permissions.edit
                                            ? 'Insufficient permissions to delete this file.'
                                            : undefined
                                        }
                                        onClick={() => handleAction(handleDeleteConfirmation(file))}>
                                        Delete
                                      </Dropdown.Item>
                                    </>
                                  );
                                }}
                              </TableBoxRowActions.Dropdown>
                            </TableBoxRowActions>
                          </Table.BoxRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ),
                grid: (
                  <>
                    {client.permissions.edit && hasConfidentialFiles && (
                      <>
                        <Title>
                          Administrator Files{' '}
                          <HelpTooltip message="These files are accessible to workspace members that can edit this client." />
                        </Title>
                        <Files fade={query.status !== 'ready'}>
                          {_(query.data)
                            .filter({ isConfidential: true })
                            .map((file) => (
                              <FilePreview key={file.id} file={file} onRemove={handleDeleteConfirmation} />
                            ))
                            .value()}
                        </Files>
                      </>
                    )}
                    {hasPublicFiles && (
                      <>
                        <Title>
                          Team Files{' '}
                          <HelpTooltip message="These files are accessible to workspace members that can access this client." />
                        </Title>

                        <Files fade={query.status !== 'ready'}>
                          {_(query.data)
                            .filter({ isConfidential: false })
                            .map((file) => (
                              <FilePreview key={file.id} file={file} onRemove={handleDeleteConfirmation} />
                            ))
                            .value()}
                        </Files>
                      </>
                    )}
                    {!hasFiles && <NoFilesMessage>No Files have been uploaded.</NoFilesMessage>}
                  </>
                ),
              }[params.view]
            }
          </Page.Section>
        )}
      </Stack>

      {showDrawer && (
        <ClientFilesDrawer client={client} onClose={() => setShowDrawer(false)} onChanged={() => fetchData()} />
      )}
    </>
  );
}
