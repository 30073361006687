import {
  Button,
  Buttons,
  CancelButton,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  ModalCard,
  SingleSelect,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useForm } from '~/hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import projectStatuses from '~/lookups/project-statuses';
import _ from 'lodash';

const CopyControls = styled.div`
  padding: 1rem 1rem;
  border-radius: 5px;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    padding-bottom: 0.75rem;
  }
`;

const initialState = { project: null, isReady: false };
const handlers = {
  ready: ({ project }) => ({ isReady: true, project }),
};

function ProjectCloneForm({ onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, project }, actions] = useActions(handlers, initialState);
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const { clientKey, projectKey } = useParams();

  const projectId = project?.id;

  const fetchData = useCallback(
    async (projectId) => {
      try {
        const { data: project } = projectId
          ? await api.www.workspaces(workspace.id).projects(projectId).get()
          : await api.www.workspaces(workspace.id).clients(clientKey).projects(projectKey).get();
        actions.ready({ project });
        return project;
      } catch (error) {
        actions.ready({ project: null });
      }
    },
    [actions, workspace.id, clientKey, projectKey, api],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull({
        ...values,
      });
      const { data } = await api.www.workspaces(workspace.id).projects(projectId).clone(body);
      await onSaved(data);
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  if (!isReady) return null;
  if (!project) return <ErrorPage.NotFound publicSite={false} />;

  const initialValues = {
    adjustDate: false,
    copyBudget: true,
    copyAccounting: true,
    copyAllocations: true,
    copyTeam: true,
    copyRoles: true,
    copyRates: true,
    copyTasks: true,
    statusId: 'not_started',
    start: null,
    name: project ? `Copy of ${project?.name}` : '',
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ModalCard title="Clone Project" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('New Project Name').max(255).required(),
        })}>
        {(formik) => {
          const handleStatusChange = (e) => {
            formik.setFieldValue('statusId', e.target.value);
          };

          const handleStartChange = (date) => {
            formik.setValues({
              ...formik.values,
              start: date,
              adjustDate: date ? formik.values.adjustDate : false,
            });
          };

          const filteredOptions = _.omit(projectStatuses, 'completed');

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.Text autoFocus name="name" placeholder="New Project Name" maxLength={255} />
                </Form.Control>
                <Form.Control>
                  <Field.DayPicker name="start" placeholder="Start Date" onChange={handleStartChange} />
                </Form.Control>
                <Form.Control>
                  <SingleSelect
                    name="statusId"
                    placeholder="Project Status"
                    value={formik.values.statusId}
                    onChange={handleStatusChange}>
                    {_.values(filteredOptions).map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </SingleSelect>
                </Form.Control>
                <CopyControls>
                  <Form.Section
                    title="Copy options"
                    subtitle="Select the project configuration items to copy to the new project.">
                    <Checkboxes>
                      <Field.Checkbox name="copyTeam" label="Project Team" />
                      {project?.useRoles && <Field.Checkbox name="copyRoles" label="Roles" />}
                      <Field.Checkbox name="copyTasks" label="Tasks" />
                      <Field.Checkbox name="copyBudget" label="Budget" />
                      {project?.billingTypeId !== 'non_billable' && <Field.Checkbox name="copyRates" label="Rates" />}
                      <Field.Checkbox name="copyAccounting" label="Accounting" />
                      <Field.Checkbox name="copyAllocations" label="Resource Allocations" />
                    </Checkboxes>
                  </Form.Section>
                </CopyControls>
                <Form.Control>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field.Checkbox
                      disabled={!formik.values.start || !project.start}
                      name="adjustDate"
                      label="Adjust the start date of the project tasks, allocations, and accounting items"
                    />
                    <HelpTooltip
                      style={{ marginLeft: '1rem' }}
                      message={
                        !project.start
                          ? 'The source project start date must be set in order to adjust the start date of the project tasks, allocations, and accounting items.'
                          : 'When this is checked, the start date of cloned tasks, allocations, and accounting items will be relative to the project start date provided above.'
                      }
                    />
                  </div>
                </Form.Control>
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ProjectCloneForm;
