import {
  ActionButton,
  Buttons,
  CancelButton,
  Checkbox,
  DeleteButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  ProjectTaskTagMultiSelect,
  Radio,
  Tab,
  Tabs,
  TextInput,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useCurrencyFormat, useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import CapFeesCheckbox from './CapFeesCheckbox';
import ProjectTaskMemberMultiSelect from './ProjectTaskMemberMultiSelect';
import ProjectTaskMembersTable from './ProjectTaskMembersTable';
import ProjectTaskRolesTable from './ProjectTaskRolesTable';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import ProjectTaskHistory from './ProjectTaskHistory';
import styled from 'styled-components';
import { colors } from '~/styles';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const ProjectTaskFormContext = React.createContext({ forms: [] });

const initialState = { task: null, isReady: false };
const handlers = {
  ready: ({ task }) => ({ isReady: true, task }),
};

function ProjectTaskForm({ taskId, project, onSaved, onClose, onDelete, onSaveAndNew, isNew = false }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, task }, actions] = useActions(handlers, initialState);
  const [{ isSubmitting, status, message, saved }, form] = useForm();
  const [hasDependencies, setHasDependencies] = useState(true);
  const currencyFormat = useCurrencyFormat({ currency: project.currency });
  const [tabIndex, setTabIndex] = useState(0);

  const params = useParams();
  if (!taskId) taskId = params.taskId;

  const [forms, setForms] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data: task } = await api.www.workspaces(workspace.id).projects(project.id).tasks(taskId).get();
      actions.ready({ task });
    } catch (error) {
      actions.ready({ task: null });
    }
  }, [actions, workspace.id, project.id, taskId, api]);

  useEffect(() => {
    (async () => {
      if (!task?.id) return;

      const { data } = await api.www.workspaces(workspace.id).projects(project.id).tasks(task.id).hasDependencies();

      setHasDependencies(data);
    })();
  }, [api, workspace.id, project.id, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const title = 'Edit Task';

  useDocumentTitle(title);

  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty || forms.length > 0);

  if (!isReady) return null;
  if (!task) return <ErrorPage.NotFound publicSite={false} />;

  const defaultValues = {
    code: '',
    description: '',
    end: null,
    isBillable: true,
    name: '',
    estimatedHours: '',
    estimatedFees: '',
    lockTime: false,
    requireNotes: false,
    forAssignedOnly: false,
    start: null,
    statusId: 'not_started',
    roles: [],
    members: [],
    capHours: false,
    capAssignedHours: false,
    capFees: false,
    tags: [],
  };

  const initialValues = mergeValues(defaultValues, task);

  const tabErrors = {
    task: ['code', 'description', 'end', 'isBillable', 'name', 'estimatedHours', 'estimatedFees', 'start', 'statusId'],
  };

  return (
    <ProjectTaskFormContext.Provider value={{ forms, setForms }}>
      <Drawer
        title={title}
        isOpen
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={onClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values) {
            try {
              form.submit(formRef.current.status.action);
              const body = emptyStringToNull({
                ..._.omit(values, ['tags']),
                roles: values.roles.map(({ projectRoleId, hours }) => ({
                  projectId: project.id,
                  projectRoleId,
                  hours,
                })),
                members: values.members.map(({ projectMemberId, hours }) => ({
                  projectId: project.id,
                  projectMemberId,
                  hours,
                })),
                projectTaskTagAssignments: values.tags.map((tag) => ({ projectTaskTagId: tag.id })),
              });

              const { data } = await api.www
                .workspaces(workspace.id)
                .projects(project ? project.id : undefined)
                .tasks(task.id)
                .upsert(body);

              await onSaved(data);
              form.save(formRef.current.status.action);

              switch (formRef.current.status.action) {
                case 'new':
                  await onSaveAndNew();
                  break;

                default:
                  closeDrawer();
                  break;
              }
            } catch ({ message }) {
              form.error({ message });
            }
          }

          async function handleDelete() {
            await onDelete(task);
            closeDrawer();
          }

          return (
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              initialStatus={{ next: 'done' }}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                code: Yup.string().label('Task Code').max(255),
                description: Yup.string().label('Notes').max(5000),
                end: Yup.date()
                  .label('End Date')
                  .nullable()
                  .when('start', (start, schema) =>
                    start ? schema.min(Yup.ref('start'), 'End Date must be after Start Date') : schema,
                  ),
                isBillable: Yup.bool(),
                name: Yup.string().label('Task Name').max(255).required(),
                estimatedHours: Yup.number().min(0).max(9999.99).nullable(),
                estimatedFees: Yup.number().min(-99999999.99).max(99999999.99).nullable(),
                start: Yup.date().label('Start Date').nullable(),
                statusId: Yup.string().label('Status').required(),
              })}>
              {({ errors, values, values: { roles, members }, setFieldValue, submitForm, setStatus, setValues }) => {
                const submit = async (action) => {
                  // Submit all open inline forms
                  for await (const form of forms) {
                    await form.ref.current.submitForm();
                  }

                  // If at least one form is invalid, don't submit the drawer
                  for await (const form of forms) {
                    if (form.ref.current && !form.ref.current.isValid) return;
                  }

                  setStatus({ action });
                  submitForm();
                };

                return (
                  <Form>
                    <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                      <Tab>
                        Task
                        {tabErrors.task.some((key) => !!errors[key]) && (
                          <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                        )}
                      </Tab>
                      {task.permissions.manage && <Tab>History</Tab>}
                    </Tabs>
                    <Content>
                      {
                        [
                          <>
                            <Form.Section title="Basic Information">
                              <Form.Control>
                                <Field.Text ref={firstFieldRef} name="name" placeholder="Task Name" maxLength={255} />
                              </Form.Control>
                              <Form.Control>
                                <Field.ProjectTaskStatusSelect name="statusId" placeholder="Status" />
                              </Form.Control>
                              <Form.Control>
                                {project.useRoles && roles.length > 0 ? (
                                  <TextInput
                                    placeholder="Hours Budget"
                                    disabled
                                    value={roles.reduce((a, v) => a + v.hours, 0)}
                                  />
                                ) : !project.useRoles && members.length > 0 ? (
                                  <TextInput
                                    placeholder="Hours Budget"
                                    disabled
                                    value={members.reduce((a, v) => a + v.hours, 0)}
                                  />
                                ) : (
                                  <Field.Number
                                    name="estimatedHours"
                                    placeholder="Hours Budget"
                                    min={0}
                                    precision={2}
                                  />
                                )}

                                {!project.isBillable || !values.isBillable ? null : project.useRoles &&
                                  roles.length > 0 ? (
                                  <ReadTextbox
                                    label="Services Revenue Budget"
                                    value={currencyFormat.format(
                                      roles.reduce((a, v) => a + v.role.actualRate * v.hours, 0),
                                    )}
                                  />
                                ) : !project.useRoles && members.length > 0 ? (
                                  <ReadTextbox
                                    label="Services Revenue Budget"
                                    value={currencyFormat.format(
                                      members.reduce((a, v) => a + v.projectMember.actualRate * v.hours, 0),
                                    )}
                                  />
                                ) : (
                                  <Field.Currency
                                    name="estimatedFees"
                                    currency={project.currency}
                                    placeholder="Services Revenue Budget"
                                  />
                                )}
                              </Form.Control>

                              <Form.Control>
                                <Field.DayPicker name="start" placeholder="Start Date" />
                                <Field.DayPicker name="end" placeholder="End Date" />
                              </Form.Control>
                              {project.isBillable && (
                                <Form.Control>
                                  <Field.RadioGroup name="isBillable">
                                    <Radio value={true} label="Billable" />
                                    <Radio value={false} label="Non-billable" />
                                  </Field.RadioGroup>
                                </Form.Control>
                              )}
                              <Form.Control>
                                <Field.Text name="code" placeholder="Task Code" maxLength={255} />
                              </Form.Control>
                              <Form.Control>
                                <Field.Control>
                                  <ProjectTaskTagMultiSelect
                                    name="tags"
                                    placeholder="Tags"
                                    value={values.tags}
                                    allowNew
                                    onChange={({ target: { value } }) => setFieldValue('tags', value)}
                                  />
                                </Field.Control>
                              </Form.Control>
                              <Form.Control>
                                <Field.TextArea name="description" placeholder="Notes" rows={4} maxLength={5000} />
                              </Form.Control>
                            </Form.Section>
                            <Form.Section title="Settings">
                              <Form.Control>
                                <Field.Checkbox name="lockTime" label="Lock time for this task" />
                              </Form.Control>
                              <Form.Control>
                                <Field.Checkbox
                                  name="capHours"
                                  label="Cap the hours to the total budgeted for this task"
                                  disabled={
                                    values.capAssignedHours &&
                                    (project?.useRoles ? roles.length > 0 : members.length > 0)
                                  }
                                />
                              </Form.Control>
                              {project.isBillable && (
                                <Form.Control>
                                  <CapFeesCheckbox project={project} />
                                </Form.Control>
                              )}
                              <Form.Control>
                                <Field.Checkbox
                                  name="forAssignedOnly"
                                  label="Only assigned members and roles can track time to this task"
                                />
                              </Form.Control>
                              <Form.Control>
                                {project?.requireNotes && (
                                  <Field.Checkbox
                                    name="projectOverrideRequireNotes"
                                    label="Require notes on time entries for this task"
                                    disabled
                                    checked
                                  />
                                )}
                                {!project?.requireNotes && (
                                  <Field.Checkbox
                                    name="requireNotes"
                                    label="Require notes on time entries for this task"
                                  />
                                )}
                              </Form.Control>
                            </Form.Section>
                            {project.useRoles ? (
                              <>
                                <Form.Section title="Role assignment &amp; Budget">
                                  <ProjectTaskRolesTable
                                    project={project}
                                    task={values}
                                    value={roles}
                                    onChange={(value) => {
                                      if (_.isEmpty(value)) {
                                        setValues({ ...values, roles: value, capAssignedHours: false });
                                      } else {
                                        if (values.capAssignedHours === true) {
                                          setValues({ ...values, roles: value, capHours: true });
                                        } else {
                                          setFieldValue('roles', value);
                                        }
                                      }
                                    }}
                                  />
                                  <Form.Control>
                                    {roles.length > 0 ? (
                                      <Field.Checkbox
                                        name="capAssignedHours"
                                        label="Cap the hours by role"
                                        onChange={({ target: { checked } }) => {
                                          if (checked) {
                                            setValues({ ...values, capAssignedHours: true, capHours: true });
                                          } else {
                                            setFieldValue('capAssignedHours', false);
                                          }
                                        }}
                                      />
                                    ) : (
                                      <Checkbox label="Cap the hours by role" checked={false} disabled={true} />
                                    )}
                                  </Form.Control>
                                </Form.Section>
                                <Form.Section title="Member assignment">
                                  <Form.Control data-testid="members_form">
                                    <Field.Control>
                                      <ProjectTaskMemberMultiSelect
                                        value={members}
                                        onChange={({ target: { value } }) => setFieldValue('members', value)}
                                        project={project}
                                        initialValue={task.members}
                                        position="top"
                                      />
                                    </Field.Control>
                                  </Form.Control>
                                </Form.Section>
                              </>
                            ) : (
                              <Form.Section title="Member assignment &amp; Budget">
                                <ProjectTaskMembersTable
                                  project={project}
                                  task={values}
                                  value={members}
                                  onChange={(value) => {
                                    if (_.isEmpty(value)) {
                                      setValues({ ...values, members: value, capAssignedHours: false });
                                    } else {
                                      if (values.capAssignedHours === true) {
                                        setValues({ ...values, members: value, capHours: true });
                                      } else {
                                        setFieldValue('members', value);
                                      }
                                    }
                                  }}
                                />
                                <Form.Control>
                                  {members.length > 0 ? (
                                    <Field.Checkbox
                                      name="capAssignedHours"
                                      label="Cap the hours by member"
                                      onChange={({ target: { checked } }) => {
                                        if (checked) {
                                          setValues({ ...values, capAssignedHours: true, capHours: true });
                                        } else {
                                          setFieldValue('capAssignedHours', false);
                                        }
                                      }}
                                    />
                                  ) : (
                                    <Checkbox label="Cap the hours by member" checked={false} disabled={true} />
                                  )}
                                </Form.Control>
                              </Form.Section>
                            )}
                          </>,
                          <>
                            <ProjectTaskHistory projectId={project.id} taskId={task.id} />
                          </>,
                        ][tabIndex]
                      }
                    </Content>

                    {status && <FormMessage.Error>{message}</FormMessage.Error>}
                    <Drawer.Actions>
                      {task.id && task.permissions.manage && _.isFunction(onDelete) && (
                        <DeleteButton disabled={hasDependencies} onClick={handleDelete}>
                          Delete
                        </DeleteButton>
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                        {isNew && (
                          <ActionButton
                            isOutline
                            isLoading={isSubmitting === 'new'}
                            ok={saved === 'new'}
                            onClick={() => submit('new')}>
                            Save &amp; New
                          </ActionButton>
                        )}

                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </ProjectTaskFormContext.Provider>
  );
}

export { ProjectTaskFormContext };
export default ProjectTaskForm;
