import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard, TextInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function RemoveQBOConfirmation({ onClose, onRemove }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace, navigateWorkspace } = useWorkspace();
  const [isRemoving, setIsRemoving] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [text, setText] = useState('');

  async function handleRemove() {
    setIsRemoving(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).qbo.remove();
      await navigateWorkspace(workspace.key, '/settings/integrations');

      if (typeof onRemove === 'function') {
        await onRemove();
      }
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }

  const valid = text === 'REMOVE';

  return (
    <ModalCard title="Remove QuickBooks Integration" onClose={onClose}>
      <ModalCard.Body>
        <P>
          This will remove the QuickBooks Online integration and its settings including associations with customers, tax
          codes, products/services and invoices.
        </P>

        {
          <>
            <P>
              To remove this integration now, type the word "<strong>REMOVE</strong>" into the box below and click the
              Remove button. This action cannot be undone.
            </P>
            <TextInput value={text} onChange={({ target: { value } }) => setText(value)} />
          </>
        }
        {errorMessage && <FormMessage.Error spaceTop>{errorMessage}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton disabled={!valid} isLoading={isRemoving} onClick={handleRemove}>
            Remove
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default RemoveQBOConfirmation;
