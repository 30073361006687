import _ from 'lodash';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Inline,
} from '~/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { mergeValues, emptyStringToNull } from '~/utils';
import { useWorkspace, useApi, useToast, useConfirmation } from '~/contexts';

function ExpenseCategoryDrawer({ expenseCategory, onSaved, onClose, onDeleted }) {
  const title = `${expenseCategory?.id ? 'Edit' : 'New'} Expense Category`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [hasDependencies, setHasDependencies] = useState(true);

  useEffect(() => {
    if (!expenseCategory?.id) {
      setHasDependencies(false);
      return;
    }

    (async function fetchData() {
      const dependencies = await api.www
        .workspaces(workspace.id)
        .expenseCategories(expenseCategory.id)
        .hasDependencies();
      setHasDependencies(dependencies.data);
    })();
  }, [api, expenseCategory, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      requireAttendees: false,
      requireReceipt: false,
      requireNotes: false,
      unitName: '',
      unitAmount: '',
      invoiceItem: null,
      code: '',
      unitOptions: expenseCategory
        ? !!expenseCategory.unitName || expenseCategory.unitAmount > 0
          ? true
          : false
        : false,
    },
    expenseCategory,
  );

  return (
    <>
      <Drawer
        isOpen
        title={title}
        byline="Custom Data"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values, { resetForm }) {
            try {
              form.submit(formRef.current.status.action);

              const body = emptyStringToNull({
                ..._.omit(values, 'invoiceItem', 'unitOptions'),
                invoiceItemId: values.invoiceItem?.id ?? null,
                unitName: values.unitOptions ? values.unitName : null,
                unitAmount: values.unitOptions ? values.unitAmount : null,
              });

              const { data } = await api.www
                .workspaces(workspace.id)
                .expenseCategories(expenseCategory?.id)
                .upsert(body);

              if (onSaved) {
                await onSaved(data);
              }
              form.save(formRef.current.status.action);

              switch (formRef.current.status.action) {
                case 'new':
                  resetForm();
                  toast.success('Expense Category successfully saved.');
                  break;
                case 'close':
                  closeDrawer();
                  break;
              }
              form.done();
            } catch ({ message }) {
              form.error({ message });
            }
          }

          async function handleDelete() {
            const confirm = await confirmation.prompt((resolve) => (
              <DeleteConfirmation resolve={resolve}>
                Are you sure that you want to delete this Expense Category?
              </DeleteConfirmation>
            ));
            if (!confirm) return;

            try {
              await api.www.workspaces(workspace.id).expenseCategories(expenseCategory.id).delete();
              await onDeleted();
              closeDrawer();
              return true;
            } catch ({ message }) {
              toast.error(message);
            }
          }

          return (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                invoiceItem: Yup.object().label('Invoice Item').nullable().required(),
                name: Yup.string().label('Name').max(255).required(),
                unitAmount: Yup.number()
                  .label('Unit Amount')
                  .min(0)
                  .max(99999999999.9999)
                  .nullable()
                  .when('unitOptions', (unitOptions, schema) => (unitOptions ? schema.required() : schema)),
                unitName: Yup.string()
                  .label('Unit Name')
                  .max(255)
                  .when('unitOptions', (unitOptions, schema) => (unitOptions ? schema.required() : schema)),
                code: Yup.string().label('Code').max(255),
              })}>
              {(formik) => {
                const submit = async (action) => {
                  formik.setStatus({ action });
                  formik.submitForm();
                };

                return (
                  <Form>
                    <Form.Section title="Details">
                      <Form.Control>
                        <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                      </Form.Control>
                      <Form.Control>
                        <Field.Text name="code" placeholder="General Ledger Code" maxLength={255} />
                      </Form.Control>
                      <Form.Control>
                        <Field.InvoiceItemSelect
                          name="invoiceItem"
                          placeholder="Invoice Item"
                          type="expense"
                          clearable={false}
                        />
                      </Form.Control>
                      <Form.Control>
                        <Inline>
                          <Field.Checkbox name="unitOptions" label="Unit Expense Type" />
                          <HelpTooltip
                            message="An example of a unit expense is mileage, where the member provides the number of miles and Ruddr calculates the expense amount automatically."
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </Inline>
                      </Form.Control>
                      {formik.values.unitOptions && (
                        <>
                          <Form.Control>
                            <Field.Text name="unitName" placeholder="Unit Name" maxLength={255} />
                          </Form.Control>
                          <Form.Control>
                            <Field.Number
                              prefix="$"
                              name="unitAmount"
                              placeholder="Default Unit Amount"
                              precision={4}
                            />
                          </Form.Control>
                        </>
                      )}
                    </Form.Section>

                    <Form.Section title="Expense Report Settings">
                      <Form.Control>
                        <Inline>
                          <Field.Checkbox name="requireAttendees" label="Require Attendees" />
                          <HelpTooltip
                            message="Require the member to provide a list of people associated with this expense (common for meals and entertainment)."
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </Inline>
                      </Form.Control>
                      <Form.Control>
                        <Inline>
                          <Field.Checkbox name="requireReceipt" label="Require Receipt" />
                          <HelpTooltip
                            message="Require the member to upload a receipt."
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </Inline>
                      </Form.Control>
                      <Form.Control>
                        <Inline>
                          <Field.Checkbox name="requireNotes" label="Require Notes" />
                          <HelpTooltip
                            message="Require the member to provide notes."
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </Inline>
                      </Form.Control>
                    </Form.Section>

                    {status && <FormMessage.Error>{message}</FormMessage.Error>}

                    <Drawer.Actions>
                      {expenseCategory.id && !hasDependencies && (
                        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                        {!expenseCategory?.id && (
                          <ActionButton
                            isLoading={isSubmitting === 'new'}
                            ok={saved === 'new'}
                            type="submit"
                            onClick={() => submit('new')}>
                            Save &amp; New
                          </ActionButton>
                        )}

                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          type="submit"
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </>
  );
}

export default ExpenseCategoryDrawer;
