import { useField as useFormikField, useFormikContext } from 'formik';

function useField(props) {
  const [field, meta, helpers] = useFormikField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const onChange = field.onChange;

  field.onChange = async (e) => {
    await onChange(e);
    if (error) formik.validateField(field.name);
  };

  return [field, meta, helpers];
}

export default useField;
