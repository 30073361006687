import { useMember } from '~/contexts';
import moment from 'moment';
import React from 'react';
import { dateFormats } from '~/utils';

function DateTime({ value, format = dateFormats.compactDate, titleFormat, locale, ...props }) {
  const { member } = useMember();

  if (!value) return null;

  const time = moment(value);
  if (locale) time.locale(locale);
  else if (member) time.locale(member.locale);

  if (!time.isValid()) return null;

  return (
    <time
      dateTime={moment(value).format(dateFormats.isoDate)}
      title={titleFormat ? time.format(titleFormat) : undefined}
      {...props}>
      {time.format(format)}
    </time>
  );
}

export default DateTime;
