import React from 'react';
import FieldControl from './FieldControl';
import Select from './Select';

const options = [
  { id: 'not_submitted', name: 'Not Submitted' },
  { id: 'pending_approval', name: 'Pending Approval' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
];

function ExpenseItemStatusSelect(props) {
  return (
    <Select {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}

function FieldExpenseItemStatusSelect({ field, form: { touched, errors }, label, ...props }) {
  const error = touched[field.name] && errors[field.name];
  return (
    <FieldControl {...{ label, error }}>
      <ExpenseItemStatusSelect placeholder={label} {...{ ...field, ...props }} />
    </FieldControl>
  );
}

export default ExpenseItemStatusSelect;
export { FieldExpenseItemStatusSelect };
