import { Button, Icon } from '~/components';
import { useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import intervalOptions, { sortIntervals } from '~/utils/intervalOptions';

const TagStyles = styled.span`
  border-radius: 0.3125rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  position: relative;

  strong {
    white-space: nowrap;
    padding-right: 0.25rem;
  }

  span {
    white-space: nowrap;
  }
`;

const PreviousButton = styled(Button)`
  color: ${colors.grey75};
`;

const NextButton = styled(Button)`
  color: ${colors.grey75};
`;

const Content = styled.div`
  width: ${({ notSet }) => (notSet ? undefined : '13rem')};
  margin: 0 0.5rem;
  display: flex;
`;

export default function PeriodNavTag({ value, intervals = sortIntervals(intervalOptions), onChange }) {
  const dateTimeFormat = useDateTimeFormat();

  if (!value?.start && !value?.end) return null;

  const notSet = !value?.start || !value?.end ? true : false;

  const handleArrowClick = (direction) => {
    const { start, end, unit } = value;
    let interval;

    switch (unit) {
      case 'custom':
        {
          if (
            moment(start).isSame(moment(start).startOf('month'), 'day') &&
            moment(end).isSame(moment(start).endOf('month'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'months')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'months')
                .endOf('months')
                .format(dateFormats.isoDate),
              unit: 'month',
            };
          } else if (
            moment(start).isSame(moment(start).startOf('year'), 'day') &&
            moment(end).isSame(moment(start).endOf('year'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              unit: 'year',
            };
          } else {
            const customDays = moment(end).diff(moment(start), 'days') + 1 || 1;
            interval = {
              start: moment(start)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              unit: 'custom',
            };
          }
        }
        break;
      case 'day':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          unit: 'day',
        };
        break;
      case 'week':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          unit: 'week',
        };
        break;
      case 'semi_month':
        interval = {
          start: (moment(start).date() < 16
            ? moment(start)
                .add(direction === 'next' ? 0 : -1, 'months')
                .date(16)
            : moment(start)
                .add(direction === 'next' ? 1 : 0, 'months')
                .startOf('month')
          ).format(dateFormats.isoDate),

          end: (moment(end).date() < 16
            ? moment(end)
                .add(direction === 'next' ? 0 : -1, 'months')
                .endOf('month')
            : moment(end)
                .add(direction === 'next' ? 1 : 0, 'months')
                .date(15)
          ).format(dateFormats.isoDate),
          unit: 'semi_month',
        };
        break;
      case 'month':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'months')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'months')
            .endOf('months')
            .format(dateFormats.isoDate),
          unit: 'month',
        };
        break;
      case 'quarter':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .endOf('quarters')
            .format(dateFormats.isoDate),
          unit: 'quarter',
        };
        break;
      case 'year':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          unit: 'year',
        };
        break;
    }

    const option = _.find(sortIntervals(intervals), (oi) => oi.start === interval.start && oi.end === interval.end);

    onChange({
      start: interval.start,
      end: interval.end,
      unit: option ? option.unit : interval.unit,
      key: option ? option.key : intervalOptions.custom.key,
    });
  };

  return (
    <TagStyles>
      {!notSet && (
        <PreviousButton isAnchor onClick={() => handleArrowClick('previous')}>
          <Icon icon="caret-left" size="lg" />
        </PreviousButton>
      )}

      <Content notSet={notSet}>
        <strong>Date Range:</strong>

        <span>
          {_.compact([
            value?.start ? dateTimeFormat.format(value.start) : 'Not Set',
            value?.end ? dateTimeFormat.format(value.end) : 'Not Set',
          ]).join(' - ')}
        </span>
      </Content>

      {!notSet && (
        <NextButton isAnchor onClick={() => handleArrowClick('next')}>
          <Icon icon="caret-right" size="lg" />
        </NextButton>
      )}
    </TagStyles>
  );
}
