import { useField, Field } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Container = styled.div`
  position: relative;
`;

const Box = styled.div`
  display: flex;
  position: relative;
  color: ${colors.grey100};
  padding: 0rem 1rem 0rem 1rem;
  font-size: 1rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.grey5};
  border-radius: 0.25rem;

  ${({ prefix }) =>
    prefix &&
    css`
      padding-left: 0;
    `}

  ${({ textarea }) =>
    textarea &&
    css`
      min-height: 6.5rem;
      white-space: pre-wrap;
    `}
`;

const Value = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  background-color: ${colors.grey5};

  ${({ prefix }) =>
    prefix
      ? css`
          padding: 0.625rem 0.75rem 0.375rem 0.875rem;
        `
      : css`
          padding: 0.625rem 0.75rem 0.375rem 0;
        `}
`;

const Prefix = styled.div`
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 0.625rem 0.75rem 0.375rem 0.75rem;
  background-color: ${colors.primary10};
  border-right: 1px solid ${colors.primary25};
  color: ${colors.primary100};
  font-weight: ${weights.medium};
`;

function ReadTextbox({ label, textarea = false, prefix = false, value }) {
  const materialPlaceholderValue = label;
  const materialAlwaysVisible = true;
  return (
    <Container>
      <Box textarea={textarea} prefix={(!!prefix).toString()}>
        {prefix && <Prefix>{prefix}</Prefix>}
        {value != null && <Value prefix={(!!prefix).toString()}>{value}</Value>}
        {!!materialPlaceholderValue && (
          <MaterialPlaceholder isVisible={materialAlwaysVisible || !!value}>
            {materialPlaceholderValue}
          </MaterialPlaceholder>
        )}
      </Box>
    </Container>
  );
}
function FieldReadTextbox(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <Field.Control error={error}>
      <ReadTextbox value={field.value} {...field} {...props} />
    </Field.Control>
  );
}

export default ReadTextbox;
export { FieldReadTextbox };
