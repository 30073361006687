import { useField } from '~/hooks';
import _ from 'lodash';
import paymentTerms from '~/lookups/payment-terms';
import React from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function PaymentTermsSelect(props) {
  return (
    <SingleSelect {...props}>
      {_.values(paymentTerms).map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldPaymentTermsSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <PaymentTermsSelect {...field} {...props} />
    </FieldControl>
  );
}

export default PaymentTermsSelect;
export { FieldPaymentTermsSelect };
