import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Table } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { DownloadArea, EmptyReport, Report, ReportLoader } from './components';

const Created = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreatedByName = styled.div`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CreatedByEmail = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

export default function Ratings({ start, end, showPaying }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getRatings({ start, end });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api, start, end, showPaying]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getRatings({ start, end });
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <Table>
        <Table.Header>
          <Table.Column width="10rem">Submitted On</Table.Column>
          <Table.Column width="12rem">Member</Table.Column>
          <Table.Column width="15rem">Workspace</Table.Column>
          <Table.Column width="30rem">Feedback</Table.Column>
          <Table.Column width="5rem" align="right">
            Rating
          </Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>{moment(item.createdAt).format(dateFormats.compactDate)}</Table.Cell>
            <Table.Cell>
              <Created>
                <CreatedByName>{item.member.name}</CreatedByName>
                <CreatedByEmail>{item.member.email}</CreatedByEmail>
              </Created>
            </Table.Cell>
            <Table.Cell>{item.workspace.name}</Table.Cell>
            <Table.Cell>{item.feedback}</Table.Cell>
            <Table.Cell>{item.score}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
