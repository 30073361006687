import { BackLink, Currency, Icon, InvoiceStatusFilter, Level, Stack, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import invoiceStatuses from '~/lookups/invoice-statuses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import InvoiceStatusTag from '../components/InvoiceStatusTag';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import CurrencyDropdown from '../components/CurrencyDropdown';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import ExportDialogAsync from '../components/ExportDialogAsync';

export default function TransactionSummaryByInvoiceItem() {
  useDocumentTitle('Transaction Summary by Invoice Item');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    data: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    statuses: [invoiceStatuses.open],
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.this_month_to_date },
        statuses: {
          multi: true,
          key: 'invoiceStatus',
          default: [invoiceStatuses.open],
          valid: ['all', ..._.map(invoiceStatuses, (v) => v.id)],
          serialize: function (value) {
            return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
          },
          deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => invoiceStatuses[id])),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('invoiceItem.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      statusId: params.statuses.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().transactionSummaryByInvoiceItem(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .transactionSummaryByInvoiceItem(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Transaction Summary by Invoice Item</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <InvoiceStatusTag value={params.statuses} onRemove={() => handleApplyFilters({ statuses: [] })} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const invoiceItems = query.data.records;
  const features = useFeatures();

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Invoice Item" value={invoiceItems.length} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="invoiceItem.name" onSort={onSort} sort={params.sort}>
            Invoice Item
          </Table.Column>
          <Table.Column name="invoiceItem.type" onSort={onSort} sort={params.sort}>
            Type
          </Table.Column>
          <Table.Column name="amount" align="right" onSort={onSort} sort={params.sort}>
            Amount
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {invoiceItems.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>
                  {
                    {
                      income: 'Income',
                      expense: 'Expense',
                    }[item.type]
                  }
                </Table.Cell>
                <Table.Cell>
                  <Currency value={item.amount} currency={report.currency} />
                </Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell />
            <Table.Cell>
              <Currency value={query.data.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Invoice Filters" filters={[filters.statuses]}>
        <Filter>
          <InvoiceStatusFilter
            value={filters.statuses}
            onChange={({ target: { value } }) => handleFilter({ statuses: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}
