import SingleSelect from '~/components/SingleSelect';
import React from 'react';

const options = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];

export default function InvoiceSentFilter(props) {
  return (
    <SingleSelect placeholder="All" materialPlaceholder="Sent" materialAlwaysVisible showEmptyOption {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}
