import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey40};
`;

function TableFooterRow({ children, ...props }) {
  const cells = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));
  return <Row {...props}>{cells}</Row>;
}

export default TableFooterRow;
