import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, Drawer, Icon, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { getFileDataUrl } from '~/utils';
import ChangeProject from './ChangeProject';
import DeleteConfirmation from './DeleteConfirmation';
import SetProject from './SetProject';
import UploadingWarning from './UploadingWarning';
import WeekFilePreview from './WeekFilePreview';

const MAX_FILE_SIZE = 10485760; // 10mb in bytes

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 1rem 1.5rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.625rem;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Icon)`
  margin-right: 1rem;
  font-size: 1.5rem;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
`;

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 10.5rem;
  margin: 0.5rem;
`;

const PreviewInfo = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;

const PreviewData = styled.p`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default function WeekFilesDrawer({ date, member, onClose, onChanged }) {
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();

  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileToChangeProject, setFileToChangeProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filesChanged, setFilesChanged] = useState(false);
  const [showUploadingWarning, setShowUploadingWarning] = useState(false);

  const startDate = useMemo(() => moment(date).startOf('isoWeek'), [date]);
  const endDate = useMemo(() => moment(date).endOf('isoWeek'), [date]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .weekFiles()
          .get({ date: startDate.format('YYYY-MM-DD'), memberId: member ? member.id : undefined });

        setFiles(data);
      } catch (error) {
        if (error?.message) {
          setError(error.message);
        } else {
          setError('There was a problem getting your files.');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, member, workspace, startDate]);

  const onBeforeClose = (callback) => {
    if (_.some(files, { status: 'uploading' })) {
      setShowUploadingWarning(true);
    } else if (typeof callback === 'function') {
      if (filesChanged && typeof onChanged === 'function') {
        onChanged();
      }
      callback();
    }
  };

  const addFiles = async (tempFiles, projectId) => {
    setFilesChanged(true);

    for (const tempFile of tempFiles) {
      tempFile.status = 'uploading';
    }

    // Set the files all at once to prevent missing files
    setFiles((files) => [...tempFiles, ...files]);

    // Upload the files, run in parallel
    await Promise.all(
      tempFiles.map(async (file) => {
        try {
          const response = await api.www
            .workspaces(workspace.id)
            .weekFiles()
            .upload(
              {
                date: startDate.format('YYYY-MM-DD'),
                projectId,
                name: file.name,
                type: file.type,
                data: file.dataUrl.split(';base64,').pop(),
              },
              {
                memberId: member ? member.id : undefined,
              },
            );

          const updatedFile = {
            ...response.data,
            status: 'uploaded',
            dataUrl: file.dataUrl,
          };

          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        } catch (error) {
          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              const updatedFile = _.assign({}, files[fileIndex], {
                status: 'error',
              });
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        }
      }),
    );
  };

  const saveFile = (file) => {
    setFilesChanged(true);

    setFiles((files) => {
      const fileIndex = _.findIndex(files, { id: file.id });
      if (fileIndex >= 0) {
        return _.assign([], files, { [fileIndex]: file });
      }
      return files;
    });
  };

  const removeFile = (file) => {
    setFilesChanged(true);

    setFiles((files) => _.reject(files, { id: file.id }));
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 10 MB.`);
      }
    }
    if (acceptedFiles.length > 0) {
      // Generate placeholder files, run in parallel
      const tempFiles = await Promise.all(
        acceptedFiles.map(async (fileData) => ({
          id: _.uniqueId('temp_file_'),
          name: fileData.name,
          type: fileData.type || 'application/octet-stream',
          dataUrl: await getFileDataUrl(fileData),
        })),
      );

      setFilesToUpload(tempFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ maxSize: MAX_FILE_SIZE, onDrop });

  return (
    <>
      <Drawer
        isOpen
        title="Attachments"
        byline="Time"
        onBeforeClose={({ setIsOpen }) => onBeforeClose(() => setIsOpen(false))}
        onClose={onClose}>
        {(closeDrawer) =>
          isLoading ? (
            <PageLoader />
          ) : (
            <>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <InfoContainer>
                <Info>
                  <InfoIcon icon="calendar-alt" type="far" /> {startDate.format('MMMM D')} -{' '}
                  {endDate.format('MMMM D, YYYY')}
                </Info>
                {member && (
                  <Info>
                    <InfoIcon icon="user-circle" /> {member.name}
                  </Info>
                )}
              </InfoContainer>
              <DropzoneContainer>
                <DropzoneInterior {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drop files here or click to select files</p>
                </DropzoneInterior>
              </DropzoneContainer>
              <Previews>
                {files.map((file) => (
                  <Preview key={file.id}>
                    <PreviewInfo>
                      <Tooltip message={file.name}>
                        <PreviewData>
                          File: <strong>{file.name}</strong>
                        </PreviewData>
                      </Tooltip>
                      <Tooltip message={file.project ? `${file.project.client.name} / ${file.project.name}` : null}>
                        <PreviewData>
                          {file.project ? (
                            <strong>
                              {file.project.client.name} / {file.project.name}
                            </strong>
                          ) : file.url ? (
                            <Button isAnchor onClick={() => setFileToChangeProject(file)}>
                              Select Project
                            </Button>
                          ) : (
                            'Loading...'
                          )}
                        </PreviewData>
                      </Tooltip>
                    </PreviewInfo>
                    <WeekFilePreview
                      file={file}
                      isDrawer={true}
                      onChangeProject={setFileToChangeProject}
                      onRemove={setFileToDelete}
                    />
                  </Preview>
                ))}
              </Previews>
              <Drawer.Actions>
                <Buttons align="right">
                  <CancelButton onClick={() => onBeforeClose(() => closeDrawer())}>Close</CancelButton>
                </Buttons>
              </Drawer.Actions>
            </>
          )
        }
      </Drawer>
      {filesToUpload.length > 0 && (
        <SetProject
          files={filesToUpload}
          member={member}
          onClose={() => setFilesToUpload([])}
          onSave={(projectId) => addFiles(filesToUpload, projectId)}
        />
      )}
      {fileToChangeProject && (
        <ChangeProject
          file={fileToChangeProject}
          member={member}
          onClose={() => setFileToChangeProject(null)}
          onSave={saveFile}
        />
      )}
      {fileToDelete && (
        <DeleteConfirmation
          file={fileToDelete}
          member={member}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
      {showUploadingWarning && <UploadingWarning onClose={() => setShowUploadingWarning(false)} />}
    </>
  );
}
