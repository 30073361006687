import { Button } from '~/components';
import { rgba } from 'polished';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

export const Actions = styled.div`
  position: sticky;
  bottom: 0;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  background-color: ${colors.danger};
  color: ${colors.white};
  font-size: 0.75rem;
  border-radius: 999rem;
  margin-left: 0.5rem;
`;

export const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

export const HeaderTitle = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};
  color: ${({ color }) => color || colors.grey55};
  font-size: 0.75rem;

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TabContent = styled.div`
  display: flex;
  align-items: center;
  height: 1.2rem;
`;

export const Tags = styled.div`
  display: flex;
  margin-top: 0.25rem;
`;

export const CircleButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: ${colors.grey5};
  transition: all 100ms ease-in-out;
  border: none;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }
`;

export const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;
