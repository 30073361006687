import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Duration, Logo } from '~/components';
import { useApi, useSubscription, useTimeEntries, useWorkspace } from '~/contexts';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { colors } from '~/styles';
import WorkspaceTimeWindow from './WorkspaceTimeWindow';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import { useTimesheets } from '../../time/timesheets/TimesheetContext';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-left: solid 1px ${colors.grey10};
`;

const Selector = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.875rem; /* 1.25 - border width */
  color: ${({ isActive }) => (isActive ? colors.black : colors.grey25)};
  border: solid 0.1875rem ${({ isOpen }) => (isOpen ? colors.grey5 : 'transparent')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:hover {
    border-color: ${({ isDisabled }) => (isDisabled ? 'transparent' : colors.grey5)};
  }
`;

const StyledLogo = styled(Logo)`
  width: 1.375rem;
  margin-right: 0.625rem;
`;

export default function WorkspaceTimer() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { entries, updateEntry, removeEntry } = useTimeEntries();

  const [startDate, setStartDate] = useState();
  const [openWindow, setOpenWindow] = useState(false);
  const [drawerMode, setDrawerMode] = useState(null);
  const [editId, setEditId] = useState();
  const [editDate, setEditDate] = useState();

  const { subscribe, unsubscribe } = useSubscription();

  const containerElement = useRef();

  const isLocked = useMemo(() => workspace.statusId === 'disabled' || !workspace.billingStatus?.isValid, [workspace]);

  const timerEntry = useMemo(
    () => _.find(entries, (e) => e.memberId === workspace.member.id && !!e.timerStartedAt),
    [entries, workspace],
  );

  const fetchTimer = useCallback(async () => {
    if (isLocked) {
      return;
    }

    const { data } = await api.www.workspaces(workspace.id).timeEntries().getTimer();

    updateEntry(data);
  }, [api, workspace.id, isLocked, updateEntry]);

  useEffect(() => {
    fetchTimer();
  }, [fetchTimer]);

  useEffect(() => {
    const sid = subscribe(useSubscription.keys.refresh_timer, async () => {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getTimer();

      if (data) updateEntry(data);
      else if (timerEntry) removeEntry(timerEntry);
    });

    return () => {
      unsubscribe(sid);
    };
  }, [fetchTimer, subscribe, unsubscribe, updateEntry, timerEntry, api, workspace.id, removeEntry]);

  useEffect(() => {
    function closeMenu(event) {
      // Prevent this firing when the user clicks inside the component
      if (containerElement.current && containerElement.current.contains(event.target)) {
        return false;
      }
      setOpenWindow(false);
    }

    // Only attach event listener if menu is open
    if (openWindow) {
      document.addEventListener('mousedown', closeMenu);
    } else {
      setStartDate(null);
    }

    return function cleanup() {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [openWindow]);

  const handleEdit = ({ mode, id = null, date = null }) => {
    setEditId(id);
    setEditDate(date);
    setDrawerMode(mode);
    setOpenWindow(false);
  };

  const handleSaveEdit = (entry) => {
    setStartDate(entry.date);
    updateEntry(entry);
  };

  const handleDeleteEdit = (entry) => {
    setStartDate(entry.date);
    removeEntry(entry);
  };

  const handleCloseEdit = () => {
    setOpenWindow(true);
    setDrawerMode(null);
    setEditId(null);
  };

  const timesheetContext = useTimesheets();

  const handleToggleWindow = () => {
    if (!openWindow && isLocked) {
      return;
    }

    const isOpen = !openWindow;
    setOpenWindow(isOpen);
    if (isOpen && timerEntry && !startDate) {
      setStartDate(timerEntry.date);
    }

    if (isOpen) {
      timesheetContext.fetchTimesheets();
    }
  };

  return (
    <Container ref={containerElement}>
      <Selector isOpen={openWindow} isDisabled={isLocked} isActive={!!timerEntry} onClick={handleToggleWindow}>
        <StyledLogo color={timerEntry ? colors.primary : colors.grey25} />
        {timerEntry ? (
          <Duration minutes={timerEntry.minutes} timerStartedAt={timerEntry.timerStartedAt} />
        ) : (
          <Duration minutes={0} />
        )}
      </Selector>
      {openWindow && <WorkspaceTimeWindow startDate={startDate} onEdit={handleEdit} />}
      {drawerMode &&
        {
          edit: (
            <EditTimeEntry
              id={editId}
              initialValues={{ date: editDate }}
              onSaved={handleSaveEdit}
              onDeleted={handleDeleteEdit}
              onClose={handleCloseEdit}
            />
          ),
          view: <ViewTimeEntry id={editId} onClose={handleCloseEdit} />,
        }[drawerMode]}
    </Container>
  );
}
