import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import TableCell from './TableCell';
import TableRow from './TableRow';

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: opacity 250ms;

  div.table-cell {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const NoResultsCell = styled(TableCell)`
  flex: 1;
  color: ${colors.grey40};
`;

function TableBody({ children, showNoResults = true, ...props }) {
  return (
    <Body {...props}>
      {!children || children.length === 0
        ? showNoResults && (
            <TableRow>
              <NoResultsCell>No results</NoResultsCell>
            </TableRow>
          )
        : children}
    </Body>
  );
}

export default TableBody;
