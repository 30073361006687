import { Currency, Tooltip, Percent } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const ProgressContainer = styled.div`
  font-size: 0.75rem;
  flex: 1;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
`;

const Legend = styled.legend`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
`;

const Earned = styled.span`
  color: ${({ color }) => color};
`;

const Budget = styled.span`
  color: ${colors.grey25};
`;

function RevenueProgress({ earned = 0, budgeted = null, currency }) {
  const percent = (budgeted && budgeted > 0 ? earned / budgeted : earned > 0 ? 1 : 0) * 100;

  let statusColor = earned > 0 ? colors.primary : colors.grey25;

  if (budgeted > 0) {
    if (percent > 100) statusColor = colors.danger;
    else if (percent > 80) statusColor = colors.warning;
  }

  return (
    <ProgressContainer data-testid="revenue_bar">
      <Tooltip
        message={
          budgeted > 0 && (
            <ul>
              <li>
                <Percent value={percent / 100} /> of budget used.
              </li>
              <li>
                <Percent value={1 - percent / 100} /> of budget left.
              </li>
            </ul>
          )
        }>
        <Legend>
          <Earned color={statusColor}>
            <Currency value={earned ?? 0} currency={currency} minimumFractionDigits={0} maximumFractionDigits={0} />
          </Earned>

          {budgeted > 0 && (
            <Budget>
              <Currency value={budgeted} currency={currency} minimumFractionDigits={0} maximumFractionDigits={0} />
            </Budget>
          )}
        </Legend>

        <Progress>
          <ProgressLine width={`${Math.min(percent, 100)}%`} color={statusColor} />
        </Progress>
      </Tooltip>
    </ProgressContainer>
  );
}

export default RevenueProgress;
