import SingleSelect from '~/components/SingleSelect';
import React from 'react';

function MemberCostMethodFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Member Labor Cost Type"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="fixed">Fixed Cost</option>
      <option value="hourly">Hourly Cost</option>
    </SingleSelect>
  );
}

export default MemberCostMethodFilter;
