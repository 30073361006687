import { useApi } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

export default function RegionalFormatSelect({ value, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  const handleSearch = (q) => {
    return options.filter((o) => [o.language, o.region].some((f) => f.toLowerCase().includes(q.toLowerCase())));
  };

  useEffect(() => {
    (async () => {
      const { data } = await api.locales().get();
      if (!isMounted.current) return;
      const regionNames = new Intl.DisplayNames('en-US', { type: 'region' });
      const languageNames = new Intl.DisplayNames('en-US', { type: 'language' });

      setOptions(
        _(data)
          .map((locale) => {
            const { language, region } = new Intl.Locale(locale);
            return {
              locale,
              language: languageNames.of(language),
              region: regionNames.of(region),
            };
          })
          .orderBy(['language', 'region'])
          .value(),
      );
    })();
  }, [api, isMounted]);

  const handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value?.locale ?? null,
      },
    });
  };

  const selectedOption = useMemo(() => {
    return options?.find((o) => o.locale === value);
  }, [options, value]);

  return (
    <AutoComplete
      placeholder="Regional Format"
      value={selectedOption}
      defaultOptions={options}
      displayText={selectedOption ? `${selectedOption.language} (${selectedOption.region})` : ''}
      renderOption={(option) => `${option.language} (${option.region})`}
      onSearch={handleSearch}
      onChange={handleChange}
      {...props}
    />
  );
}

export function FieldRegionalFormatSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <RegionalFormatSelect {...field} {...props} />
    </FieldControl>
  );
}
