import { useCallback, useEffect, useMemo } from 'react';

function useDocumentTitle(title, showName = true) {
  const set = useCallback(
    (title) => {
      if (title && showName) {
        document.title = `${title} | Ruddr`;
      } else if (title) {
        document.title = title;
      } else if (showName) {
        document.title = 'Ruddr';
      } else {
        document.title = '';
      }
    },
    [showName],
  );
  useEffect(() => {
    if (title != null) {
      set(title);
    }
  }, [set, title]);

  const documentTitle = useMemo(() => ({ set }), [set]);

  return documentTitle;
}

export default useDocumentTitle;
