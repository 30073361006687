import { Grid, Page } from '~/components';
import React from 'react';
import ProjectDetails from './ProjectDetails';
import ProjectKPI from './ProjectKPI';

export default function ProjectOverviewTab({ project }) {
  return (
    <Page.Section style={{ position: 'relative' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="75%">
            <ProjectKPI project={project} />
          </Grid.Column>

          <Grid.Column width="2rem" />
        </Grid.Row>
      </Grid>

      <ProjectDetails project={project} />
    </Page.Section>
  );
}
