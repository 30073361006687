import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Tooltip from './Tooltip';

const Styled = styled(Tooltip)`
  display: inline-flex;
  font-size: 1rem;
  vertical-align: text-top;
`;

function IconTooltip({ message, type = 'fas', icon, color = null, ...props }) {
  return (
    <Styled message={message} {...props}>
      <Icon type={type} icon={icon} color={color} />
    </Styled>
  );
}

export default IconTooltip;
