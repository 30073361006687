import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi, useToast } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';

export default function XeroSignup() {
  const api = useApi();
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const urlSearchParams = new URLSearchParams(location.search);
      const code = urlSearchParams.get('code');

      try {
        if (code) {
          const { data } = await api.xero.signup.user({ uri: window.location.href });
          history.push(
            `/signup?${new URLSearchParams({ email: data.email, name: data.name, utm_source: 'xero' }).toString()}`,
          );
        } else {
          const { data } = await api.xero.signup.consentUri();
          window.location.href = data;
        }
      } catch {
        history.push(`/signup?${new URLSearchParams({ utm_source: 'xero' }).toString()}`);
      }
    })();
  }, [api, location.search, toast, history]);

  return <PageLoader />;
}
