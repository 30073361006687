import { InlineTooltip, Level, ProjectStatusSelect, SearchInput, SingleSelect, Stack, LinkButton } from '~/components';
import { useAuth } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ProjectsListPage from '../projects/ProjectsListPage';

const Filters = ({ query, onFilter }) => {
  const auth = useAuth();
  const { url } = useRouteMatch();

  const handleChange = ({ target: { name, value } }) => {
    onFilter({ [name]: value });
  };

  return (
    <Level padding="0.5rem 0 0 0">
      <Level.Item width="20rem">
        <SearchInput
          value={query.q}
          placeholder="Search"
          materialPlaceholder="Project Name"
          materialAlwaysVisible
          onChange={handleChange}
        />
      </Level.Item>

      <Level.Item width="20rem">
        <ProjectStatusSelect
          name="status"
          placeholder="All"
          materialPlaceholder="Project Status"
          materialAlwaysVisible
          value={query.status}
          showEmptyOption
          onChange={handleChange}
        />
      </Level.Item>

      <Level.Item width="20rem">
        <SingleSelect
          name="recordStatusId"
          placeholder="All"
          materialPlaceholder="Project Archived"
          materialAlwaysVisible
          value={query.recordStatusId}
          showEmptyOption
          onChange={handleChange}>
          <option value="active">No</option>
          <option value="archived">Yes</option>
        </SingleSelect>
      </Level.Item>

      <Level.Item right narrow>
        <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
          Create a Project
          {!auth.projects.create && (
            <InlineTooltip message="Your security role prohibits you from creating projects." />
          )}
        </LinkButton>
      </Level.Item>
    </Level>
  );
};

function ClientProjectsTab({ client }) {
  return (
    <Stack margin="2rem 0 0">
      <ProjectsListPage
        mode="tab"
        clientId={client.id}
        client={client}
        renderFilters={(props) => <Filters {...props} />}
      />
    </Stack>
  );
}

export default ClientProjectsTab;
