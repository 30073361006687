import Big from 'big.js';
import {
  Button,
  Buttons,
  CancelButton,
  Currency,
  DeleteConfirmation,
  Field,
  Form,
  Icon,
  ModalCard,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

function BudgetExpensesTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = (value) => {
    value = emptyStringToNull(value);
    const expenses = value.id
      ? projectModel.budgetExpenses.map((be) =>
          be.id === value.id ? { ...be, ...value, expenseCategoryId: value.expenseCategory?.id } : be,
        )
      : [
          ...projectModel.budgetExpenses,
          { ...value, id: _.uniqueId('be_'), expenseCategoryId: value.expenseCategory?.id },
        ];

    setEditIndex(null);
    onChange(expenses);
  };

  const handleDelete = (item) => {
    onChange(projectModel.budgetExpenses.filter((r) => r.id !== item.id));
  };

  const expenses = {
    billableAmount: projectModel.isBillable ? projectModel.budgetExpenses.reduce((a, v) => a + v.billableAmount, 0) : 0,
    nonBillableAmount: projectModel.budgetExpenses.reduce((a, v) => a + v.nonBillableAmount, 0),
    get total() {
      return this.billableAmount + this.nonBillableAmount;
    },
  };

  const currency = projectModel.currency;

  return (
    <div>
      <Table small>
        <Table.BoxHeader>
          <Table.Column>Expense Category</Table.Column>
          <Table.Column align="right" width="10rem" isVisible={projectModel.isBillable}>
            Billable
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Non-billable
          </Table.Column>
          <Table.Column align="right" width="7rem">
            Total
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {projectModel.budgetExpenses.map((item, index) => (
            <BudgetExpenseRowDetails
              key={item.id}
              projectModel={projectModel}
              budgetExpense={item}
              disableActions={editIndex !== null}
              currency={currency}
              onEdit={() => setEditIndex(index)}
              onDelete={handleDelete}
            />
          ))}

          <BudgetExpenseRowDetails
            projectModel={projectModel}
            budgetExpense={{}}
            disableActions={editIndex !== null}
            onEdit={() => setEditIndex(-1)}
          />

          <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
            <Table.Cell />
            <Table.Cell>
              <Currency value={expenses.billableAmount} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={expenses.nonBillableAmount} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={expenses.total} currency={currency} />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Body>
      </Table>

      {editIndex != null && (
        <BudgetExpenseModalForm
          projectModel={projectModel}
          budgetExpense={editIndex >= 0 ? projectModel.budgetExpenses[editIndex] : null}
          currency={currency}
          onSubmit={handleSave}
          onCancel={() => setEditIndex(null)}
        />
      )}
    </div>
  );
}

function BudgetExpenseRowDetails({
  currency,
  projectModel,
  budgetExpense: { id, nonBillableAmount, billableAmount, expenseCategory },
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  const total = projectModel.isBillable ? billableAmount + nonBillableAmount : nonBillableAmount;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this budget expense?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete({ id });
  };

  return (
    <Table.BoxRow>
      <Table.Cell>{expenseCategory.name}</Table.Cell>
      <Table.Cell>
        <Currency value={billableAmount} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={nonBillableAmount} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={total} currency={currency} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Delete disabled={disableActions} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function BudgetExpenseModalForm({ currency, projectModel, budgetExpense, onSubmit, onCancel }) {
  const isNew = budgetExpense === null;

  const initialValues = mergeValues(
    {
      id: null,
      nonBillableAmount: '',
      billableAmount: '',
      expenseCategory: null,
      notes: '',
    },
    budgetExpense,
  );

  return (
    <ModalCard title={isNew ? 'Add Item' : 'Edit Item'} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          nonBillableAmount: Yup.number().label('Non-Billable Amount').min(0).max(99999999999).nullable(),
          billableAmount: Yup.number().label('Billable Amount').min(0).max(99999999999).nullable(),
          expenseCategory: Yup.mixed().label('Expense Category').required(),
          notes: Yup.string().label('Notes').max(5000),
        })}>
        {({ submitForm, values }) => {
          const total = projectModel.isBillable
            ? Big(values.billableAmount || 0)
                .plus(values.nonBillableAmount || 0)
                .toNumber()
            : Big(values.nonBillableAmount || 0).toNumber();

          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Field.ExpenseCategorySelect
                    autoFocus
                    name="expenseCategory"
                    materialPlaceholder="Expense Category"
                    materialAlwaysVisible
                    allowNew
                    exclude={projectModel.budgetExpenses.map((bp) => bp.expenseCategoryId)}
                  />
                </Form.Control>

                <Form.Control>
                  {projectModel.isBillable && (
                    <Field.Currency
                      name="billableAmount"
                      materialPlaceholder="Billable"
                      materialAlwaysVisible
                      currency={currency}
                    />
                  )}
                  <Field.Currency
                    name="nonBillableAmount"
                    materialPlaceholder="Non-Billable"
                    materialAlwaysVisible
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Currency
                    name="total"
                    value={total}
                    materialPlaceholder="Total"
                    disabled
                    materialAlwaysVisible
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.TextArea name="notes" placeholder="Notes" rows={2} maxLength={5000} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  <Button onClick={submitForm}>{isNew ? 'Add' : 'Save'}</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default BudgetExpensesTable;
