import { useWorkspace } from '~/contexts';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function InvoiceInstruction() {
  const { workspace } = useWorkspace();
  return (
    <>
      <p>Organize your CSV contents for invoice imports into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Client Name</td>
            <td>Yes</td>
            <td>Name of the client associated with the invoice.</td>
          </tr>
          <tr>
            <td>Invoice Number</td>
            <td>Yes</td>
            <td>Unique invoice number for identification.</td>
          </tr>
          <tr>
            <td>Invoice Bill To</td>
            <td>Yes</td>
            <td>The entity or person to which the invoice is billed.</td>
          </tr>
          <tr>
            <td>Invoice Company Name</td>
            <td>Yes</td>
            <td>Name of the company being invoiced.</td>
          </tr>
          <tr>
            <td>Invoice Company Street Address</td>
            <td>Yes</td>
            <td>Street address of the invoiced company.</td>
          </tr>
          <tr>
            <td>Payment Terms Id</td>
            <td>Yes</td>
            <td>
              ID representing the payment terms agreed upon, must be one of the folloing: 'due_on_receipt', 'net_10',
              'net_15', 'net_30', 'net_45', 'net_60', 'net_90'
            </td>
          </tr>
          <tr>
            <td>PO Number</td>
            <td>No</td>
            <td>Purchase Order number associated with the invoice, if applicable.</td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>Yes</td>
            <td>Currency code for the invoice amount (e.g., USD, EUR).</td>
          </tr>
          <tr>
            <td>Total Amount</td>
            <td>Yes</td>
            <td>Total invoiced amount before any discounts.</td>
          </tr>
          <tr>
            <td>Discount Amount</td>
            <td>No</td>
            <td>Amount of discount applied to the total invoice, if any.</td>
          </tr>
          <tr>
            <td>Due On</td>
            <td>Yes</td>
            <td>Date when the invoice amount is due.</td>
          </tr>
          <tr>
            <td>Issued On</td>
            <td>Yes</td>
            <td>Date when the invoice was issued.</td>
          </tr>
          <tr>
            <td>Subject</td>
            <td>Yes</td>
            <td>Description or reason for the invoice.</td>
          </tr>

          <tr>
            <td>Notes</td>
            <td>No</td>
            <td>Additional notes or comments related to the invoice.</td>
          </tr>
          <tr>
            <td>Status Id</td>
            <td>Yes</td>
            <td>ID representing the status of the invoice (draft or open).</td>
          </tr>
          <tr>
            <td>QBO Invoice Id</td>
            <td>No</td>
            <td>QuickBooks Online Invoice ID, if integrating with QBO.</td>
          </tr>
        </tbody>
      </MainTable>
      <p>
        Need a template? Download the example format here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/api/www/workspaces/${workspace.id}/integrations/csv-import/invoices.csv`}>
          invoices.csv
        </a>
      </p>
    </>
  );
}
