import { Field, Form } from '~/components';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import DeleteConfirmation from './DeleteConfirmation';
import FilePreview from './FilePreview';

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 1rem 1.5rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

function AttachmentsTab({ creditNote, onDrop, files, error, isLoading, removeFile, maxFileSize }) {
  const [fileToDelete, setFileToDelete] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: maxFileSize,
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/*': ['.bmp', '.jpg', '.jpeg', '.png', '.gif', '.tif', '.tiff'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
    },
  });

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div style={{ marginBottom: '1.625rem' }}>
        <Form.Control>
          <Field.Checkbox name="includePdfAttachment" label="Attach a PDF of this credit note" />
        </Form.Control>
      </div>
      <DropzoneContainer>
        <DropzoneInterior {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drop files here or click to select files</p>
        </DropzoneInterior>
      </DropzoneContainer>
      <Previews>
        {files.map((file) => (
          <FilePreview key={file.id} file={file} onRemove={setFileToDelete} />
        ))}
      </Previews>
      {fileToDelete && (
        <DeleteConfirmation
          file={fileToDelete}
          creditNote={creditNote}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
    </>
  );
}

export default AttachmentsTab;
