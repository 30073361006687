import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Buttons, DeleteButton, ModalCard } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

const CurrentButton = styled(Button)`
  color: ${colors.warning};
  border-color: ${colors.warning};

  &:hover:not(:disabled) {
    color: ${colors.white};
    background-color: ${colors.warning};
    border-color: ${colors.warning};
  }
`;

export default function LogoutConfirmation({ onResolve }) {
  const { workspace } = useWorkspace();
  const history = useHistory();

  const handleClose = () => onResolve(false);

  const handleLogout = (type) => {
    let url = '/logout';
    if (type === 'current') {
      url = `/logout?${new URLSearchParams({ workspace: workspace.id })}`;
    }
    history.push(url);
    onResolve(true);
  };

  return (
    <ModalCard title="Logout" onClose={handleClose}>
      <ModalCard.Body>
        Do you want to log out of <strong>{workspace.name}</strong> or all workspaces?
      </ModalCard.Body>
      <ModalCard.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CancelButton style={{ alignSelf: 'flex-start' }} onClick={handleClose}>
            Cancel
          </CancelButton>

          <Buttons align="right">
            <CurrentButton isOutline onClick={() => handleLogout('current')}>
              Log out of current
            </CurrentButton>
            <DeleteButton onClick={() => handleLogout('all')}>Log out of all</DeleteButton>
          </Buttons>
        </div>
      </ModalCard.Footer>
    </ModalCard>
  );
}
