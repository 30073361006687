import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles'; // Ensure this path is correct
import { LoadingIcon, ResultMessage, StepTitle } from './styles';
import { Icon } from '~/components';

const BaseIndicator = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.grey100};
  border: 1px solid ${(props) => (props.passesCheck ? colors.success : props.isInProgress ? colors.info : colors.error)};
`;

function HeaderCheckIndicator({ progress }) {
  if (!progress) return null;

  const { result } = progress;

  if (!result) {
    return (
      <BaseIndicator isInProgress>
        <StepTitle>
          <LoadingIcon icon="spinner" spin />
          <strong>Validation Step 1</strong> - Reviewing CSV Headers
        </StepTitle>
        <small>Check in progress...</small>
      </BaseIndicator>
    );
  }

  const { summary } = result;

  const statusIcon = summary.passesCheck ? (
    <Icon icon="check" className="icon" />
  ) : (
    <Icon icon="warning" color={colors.warning100} className="icon" />
  );
  return (
    <BaseIndicator
      passesCheck={summary.passesCheck}
      style={{ borderColor: summary.passesCheck ? colors.success : colors.error }}>
      <StepTitle>
        {statusIcon}
        <strong>Validation Step 1</strong> - Reviewing CSV Headers
      </StepTitle>
      <ResultMessage>
        {summary.passesCheck ? (
          'All headers are valid.'
        ) : (
          <>
            <span>Issues found:</span>
            <ul>
              {summary.suggestions.map((suggestion, index) => (
                <li key={`suggestion-${index}`}>{suggestion}</li> // Prefer more stable keys if available
              ))}
            </ul>
          </>
        )}
      </ResultMessage>
    </BaseIndicator>
  );
}

export default HeaderCheckIndicator;
