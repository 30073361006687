import { SingleSelect } from '~/components';
import React from 'react';

export default function ExpenseTypeFilter(props) {
  return (
    <SingleSelect placeholder="All" materialPlaceholder="Expense Type" materialAlwaysVisible showEmptyOption {...props}>
      <option value="member">Member</option>
      <option value="project">Project</option>
    </SingleSelect>
  );
}
