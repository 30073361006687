import axios from 'axios';
import _ from 'lodash';
import { update as updateVersion } from '../version';

const agent = axios.create({
  baseURL: '/api',
});

agent.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response) {
      error.status = error.response.status;
      error.message = _.get(error, 'response.data.message');
    }
    return Promise.reject(error);
  },
);

agent.interceptors.response.use((response) => {
  updateVersion(response.headers['app-version']);
  return response;
});

const { get, post, put, patch, delete: del } = agent;
export { get, post, put, patch, del };
export default agent;
