import styled from 'styled-components';
import { weights } from '~/styles';

const Header = styled.div`
  display: flex;

  h1 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

export default Header;
