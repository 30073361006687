import React from 'react';
import BackgroundCanvas from '../canvas/BackgroundCanvas';
import CanvasRows from '../canvas/CanvasRows';
import { Canvas, CanvasOutside, ForegroundCanvas } from '../canvas/CanvasStyles';
import Header from '../header/Header';
import { Body, Content, GridContainer } from './GridStyles';

export default function Grid({
  canvasRef,
  bodyRef,
  rows,
  styles,
  loading,
  children,
  Sidebar: SidebarComponent,
  start,
  end,
  unit,
  navigation = true,
  onDateChange,
}) {
  return (
    <GridContainer>
      <Header navigation={navigation} start={start} end={end} unit={unit} styles={styles} onDateChange={onDateChange} />

      <Body ref={bodyRef}>
        <Content
          style={{
            height: styles.canvas.height,
            opacity: loading ? 0.2 : undefined,
          }}>
          <SidebarComponent />

          <Canvas id="canvas" ref={canvasRef}>
            <BackgroundCanvas start={start} end={end} unit={unit} styles={styles} />

            <CanvasRows rows={rows} styles={styles} parentRef={bodyRef} />

            <ForegroundCanvas style={{ width: styles.canvas.width }}>{children}</ForegroundCanvas>

            <CanvasOutside canvasWidth={styles.canvas.width} />
          </Canvas>
        </Content>
      </Body>
    </GridContainer>
  );
}
