import styled from 'styled-components';

const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 79rem;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 0;
`;

export default Page;
