import { Field, Form } from '~/components';
import React from 'react';
import BudgetHoursAlertSetting from './BudgetHoursAlertSetting';
import BudgetRevenueAlertSetting from './BudgetRevenueAlertSetting';
import CapExpensesCheckbox from './CapExpensesCheckbox';
import CapHoursCheckbox from './CapHoursCheckbox';
import CapRevenueCheckbox from './CapRevenueCheckbox';

function BudgetForm({ projectModel }) {
  const currency = projectModel.currency;

  return (
    <>
      {projectModel.isBillable && (
        <Form.Section title="Services Revenue">
          <Form.Control>
            <Field.Currency name="estimatedServiceRevenue" placeholder="Services Revenue" currency={currency} />
          </Form.Control>

          <Form.Control>
            <CapRevenueCheckbox project={projectModel} />
          </Form.Control>

          <Form.Control>
            <BudgetRevenueAlertSetting project={projectModel} />
          </Form.Control>
        </Form.Section>
      )}

      <Form.Section title="Hours">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Number name="estimatedBillableHours" min={0} precision={2} placeholder="Billable Hours" />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Number name="estimatedNonBillableHours" min={0} precision={2} placeholder="Non-Billable Hours" />
        </Form.Control>

        <Form.Control>
          <CapHoursCheckbox />
        </Form.Control>

        <Form.Control>
          <BudgetHoursAlertSetting project={projectModel} />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Expenses">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Currency name="budgetExpensesBillableAmount" placeholder="Billable Expenses" currency={currency} />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Currency
            name="budgetExpensesNonBillableAmount"
            placeholder="Non-Billable Expenses"
            currency={currency}
          />
        </Form.Control>

        <Form.Control>
          <CapExpensesCheckbox />
        </Form.Control>
      </Form.Section>

      {projectModel.isBillable && (
        <Form.Section
          title={
            <>
              Other Items
              <br />
              to Bill
            </>
          }>
          <Form.Control help="The amount we charge the client for Other Items.">
            <Field.Currency name="budgetOtherItemsFee" placeholder="Amount" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}
    </>
  );
}

export default BudgetForm;
