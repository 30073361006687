import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard, Currency } from '~/components';
import { useApi, useWorkspace, useToast } from '~/contexts';
import { Formik } from 'formik';
import { useForm } from '~/hooks';
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import styled from 'styled-components';

const Description = styled.div`
  &:not(:last-child) {
    margin-bottom: 0rem;
  }

  ol {
    padding: 1rem 2rem;
  }
`;

function ReplaceRoleDialog({ roleId, projectId, currency, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const toast = useToast();
  const [role, setRole] = useState({ data: null, isReady: false });

  async function handleSubmit(values, { setSubmitting }) {
    try {
      setSubmitting(true);
      form.submit();
      const body = emptyStringToNull({
        ...values,
      });
      await api.www.workspaces(workspace.id).projects(projectId).roles(roleId).replace(body);
      form.done();
      onSaved();
    } catch (error) {
      form.error({ message: error.message });
    } finally {
      setSubmitting(false);
    }
  }

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(projectId)
        .roles()
        .get({ ids: [roleId] });

      setRole({ data: data && data[0] ? data[0] : data, isReady: true });
    } catch (error) {
      toast.error('An error occurred while loading the project role.');
      setRole({ data: null, isReady: true });
    }
  }, [workspace.id, roleId, projectId, toast, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    rate: role?.data?.rate || '',
    startDate: null,
  };

  // const handleClose = () => dirtyCheck(onClose);
  const handleClose = onClose;

  return (
    <ModalCard title="Replace Role" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        context={{ currentRate: initialValues.rate }}
        validationSchema={Yup.object().shape({
          rate: Yup.number()
            .label('New Rate')
            .min(0.01)
            .max(99999999999)
            .required()
            .test('different-from-current', 'The new rate must be different from the current rate', function (value) {
              return value !== initialValues.rate;
            }),

          startDate: Yup.date().label('Starting On Date').required(),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Description>
                  <p>
                    Replacing the role <strong>{role?.data?.name}</strong> (with a rate of{' '}
                    <Currency value={role?.data?.rate} currency={currency} />) performs the following steps:
                  </p>
                  <ol>
                    <li>Deactivate the current role and rename it automatically.</li>
                    <li>Create a new active project role with the same name, settings and members.</li>
                    <li>Assign time entries the new role starting on the specified date.</li>
                  </ol>
                </Description>

                <Form.Control>
                  <Field.Currency name="rate" placeholder="New Rate" currency={currency} />
                  <Field.DayPicker name="startDate" placeholder="Starting On Date" />
                </Form.Control>
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Replace
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ReplaceRoleDialog;
