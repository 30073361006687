import React from 'react';
import MemberInstruction from '../MemberInstruction';
import ClientInstruction from '../ClientInstruction';
import ContactInstruction from '../ContactInstruction';
import CostInstruction from '../CostInstruction';
import ProjectInstruction from '../ProjectInstruction';
import TimeEntryInstruction from '../TimeEntryInstruction';
import ExpenseInstruction from '../ExpenseInstruction';
import LedgerInstruction from '../LedgerInstruction';
import InvoiceInstruction from '../InvoiceInstruction';
import InvoiceLineInstruction from '../InvoiceLineInstruction';
import { ExampleBox } from '../CSVImportPage';

export function InstructionsSection(formModel) {
  return (
    <ExampleBox>
      {
        {
          members: <MemberInstruction />,
          clients: <ClientInstruction />,
          costs: <CostInstruction />,
          contacts: <ContactInstruction />,
          projects: <ProjectInstruction />,
          time: <TimeEntryInstruction />,
          expenses: <ExpenseInstruction />,
          ledger: <LedgerInstruction />,
          invoice: <InvoiceInstruction />,
          invoiceLines: <InvoiceLineInstruction />,
        }[formModel.selectedDataType]
      }
      You can use this sample file as a starting point for importing {formModel.selectedDataType}:&nbsp;
      <a target="blank" href={`/csv/${formModel.selectedDataType}.csv`}>
        {formModel.selectedDataType}.csv
      </a>
    </ExampleBox>
  );
}
