import React, { useEffect, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, HelpTooltip, Table } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import AccountSelect from './components/AccountSelect';
import InvoiceItemSelect from './components/InvoiceItemSelect';
import ItemSelect from './components/ItemSelect';
import TaxCodeSelect from './components/TaxCodeSelect';

export default function ConfigurationDrawer({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [configuration, setConfiguration] = useState({ data: null, isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).qbo.getConnection();

      if (!isMounted.current) return;
      setConfiguration({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [items, setItems] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).qbo.getItems();

      if (!isMounted.current) return;
      setItems({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [invoiceItems, setInvoiceItems] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).invoiceItems().get();

      if (!isMounted.current) return;
      setInvoiceItems({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const [taxCodes, setTaxCodes] = useState({ data: [], isReady: false });
  useEffect(() => {
    if (configuration?.data?.region !== 'international') return;

    (async () => {
      const { data } = await api.www.workspaces(workspace.id).qbo.getTaxCodes();

      if (!isMounted.current) return;
      setTaxCodes({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted, configuration?.data?.region]);

  const [accounts, setAccounts] = useState({ data: [], isReady: false });
  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).qbo.getDepositAccounts();

      if (!isMounted.current) return;
      setAccounts({ data, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  return (
    <Drawer
      isOpen
      title="Configuration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            await api.www.workspaces(workspace.id).qbo.updateConfiguration({
              qboItemId: configuration.data.qboItemId,
              defaultInvoiceItemId: configuration.data.defaultInvoiceItemId,
              qboPaymentDepositAccountId: configuration.data.qboPaymentDepositAccountId,
              qboTaxableTaxCodeId: configuration.data.qboTaxableTaxCodeId,
              qboNonTaxableTaxCodeId: configuration.data.qboNonTaxableTaxCodeId,
            });
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        const handleChange = (event) => {
          setDirty(true);

          setConfiguration((state) => ({
            ...state,
            data: { ...state.data, [event.target.name]: event.target.value || null },
          }));
        };

        if (!configuration.isReady || !items.isReady) return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column>Setting</Table.Column>

                <Table.Column>
                  <strong>Value</strong>
                </Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                <Table.BoxRow>
                  <Table.Cell>
                    Default QuickBooks Product/Service
                    <HelpTooltip
                      message="Ruddr will use this default Product/Service when publishing an invoice to QuickBooks if an Invoice Item in Ruddr is not mapped to a Product/Service."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ItemSelect
                      name="qboItemId"
                      items={items.data}
                      value={configuration.data.qboItemId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>

                <Table.BoxRow>
                  <Table.Cell>
                    Default Ruddr Invoice Item
                    <HelpTooltip
                      message="Ruddr will use this default Invoice Item when updating a Ruddr invoice from QuickBooks if the invoice uses a Product/Service that is not mapped to an Invoice Item in Ruddr."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <InvoiceItemSelect
                      name="defaultInvoiceItemId"
                      invoiceItems={invoiceItems.data}
                      value={configuration.data.defaultInvoiceItemId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>

                <Table.BoxRow>
                  <Table.Cell>
                    Payment Deposit Account
                    <HelpTooltip
                      message="Ruddr will use this Deposit Account when saving a payment to QuickBooks."
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <AccountSelect
                      name="qboPaymentDepositAccountId"
                      accounts={accounts.data}
                      value={configuration.data.qboPaymentDepositAccountId}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                </Table.BoxRow>

                {configuration?.data?.region === 'international' && taxCodes.isReady && (
                  <>
                    <Table.BoxRow>
                      <Table.Cell>
                        Default Taxable Tax Code
                        <HelpTooltip
                          message="Ruddr will use this Tax Code when publishing an invoice to QuickBooks if an invoice line is taxable but the invoice does not have a Tax Rate set."
                          style={{ marginLeft: '.5rem' }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TaxCodeSelect
                          name="qboTaxableTaxCodeId"
                          taxCodes={taxCodes.data}
                          value={configuration.data.qboTaxableTaxCodeId}
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.BoxRow>

                    <Table.BoxRow>
                      <Table.Cell>
                        Default Non-Taxable Tax Code
                        <HelpTooltip
                          message="Ruddr will use this Tax Code when publishing an invoice to QuickBooks if an invoice line is non-taxable."
                          style={{ marginLeft: '.5rem' }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TaxCodeSelect
                          name="qboNonTaxableTaxCodeId"
                          taxCodes={taxCodes.data}
                          value={configuration.data.qboNonTaxableTaxCodeId}
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.BoxRow>
                  </>
                )}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
