import { Button, DateTime, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import ApiKeyForm from './ApiKeyForm';
import RemoveApiKey from './RemoveApiKey';
import ViewApiKey from './ViewApiKey';

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  margin-right: auto;
  padding-right: 0.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const Description = styled.p`
  margin-top: 0.5rem;
`;

const NoKeys = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey55};
`;

export default function ApiKeys() {
  useDocumentTitle('API Keys');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteKey, setDeleteKey] = useState();
  const [editKey, setEditKey] = useState();
  const [viewKey, setViewKey] = useState();
  const [apiKeys, setApiKeys] = useState([]);

  const fetchKeys = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.www.workspaces(workspace.id).apiKeys().get();
      setApiKeys(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  useEffect(() => {
    fetchKeys();
    return fetchKeys.cancel;
  }, [fetchKeys]);

  const handleSave = (apiKey) => {
    if (apiKey.token) {
      setViewKey(apiKey);
    }
    fetchKeys();
  };

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <Header>
        <Content>
          <Title>API Keys</Title>
          <Description>
            Access your data using the Ruddr API by creating authentication keys. See the{' '}
            <a href="http://ruddr.readme.io" target="_blank" rel="noopener noreferrer">
              documentation
            </a>{' '}
            for more information.
          </Description>
        </Content>
        <Button onClick={() => setEditKey(null)}>Create an API Key</Button>
      </Header>
      <Table>
        <Table.BoxHeader>
          <Table.Column>Name</Table.Column>
          <Table.Column>Token</Table.Column>
          <Table.Column>Created</Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        {apiKeys.length > 0 ? (
          apiKeys.map((apiKey) => (
            <Table.BoxRow key={apiKey.id}>
              <Table.Cell>{apiKey.name}</Table.Cell>
              <Table.Cell>
                ...<code>{apiKey.tokenLast4}</code>
              </Table.Cell>
              <Table.Cell>
                <DateTime value={apiKey.createdAt} />
              </Table.Cell>
              <TableBoxRowActions>
                <TableBoxRowActions.Edit onClick={() => setEditKey(apiKey)} />
                <hr />
                <TableBoxRowActions.Delete onClick={() => setDeleteKey(apiKey)} />
              </TableBoxRowActions>
            </Table.BoxRow>
          ))
        ) : (
          <NoKeys>No API keys available</NoKeys>
        )}
      </Table>
      {deleteKey !== undefined && (
        <RemoveApiKey apiKey={deleteKey} onClose={() => setDeleteKey()} onRemove={() => fetchKeys()} />
      )}
      {editKey !== undefined && <ApiKeyForm apiKey={editKey} onClose={() => setEditKey()} onFinish={handleSave} />}
      {viewKey !== undefined && <ViewApiKey apiKey={viewKey} onClose={() => setViewKey()} />}
    </>
  );
}
