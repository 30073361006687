import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';

const StyledLink = styled(Link)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.625rem;
  height: auto;
  min-height: 2rem;
  margin-right: 1.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
  font-size: 0.6rem;

  &:hover {
    color: ${colors.primary};
    background-color: ${colors.grey10};
  }
`;

export default function BackArrowLink({ to }) {
  return (
    <StyledLink to={to}>
      <Icon icon="arrow-left" />
    </StyledLink>
  );
}
