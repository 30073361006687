import {
  ClientTagFilter,
  InlineTooltip,
  Level,
  MemberSelect,
  PracticeSelect,
  SearchInput,
  SingleSelect,
  LinkButton,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ExportDropdown from '../settings/ExportDropdown.jsx';
import mimeTypes from '~/utils/mimeTypes.js';

function ClientsListFilters({ filters: { q, practice, recordStatusId, owner, tags } = {}, onChange, onExport }) {
  const auth = useAuth();

  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
            value={q}
          />
        </Level.Item>

        <Level.Item width="20rem">
          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Client Archived"
            materialAlwaysVisible
            value={recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>
        </Level.Item>

        <Level.Item width="20rem">
          <MemberSelect
            value={owner}
            name="owner"
            placeholder="All"
            materialPlaceholder="Client Relationship Owner"
            materialAlwaysVisible
            onChange={handleChange}
          />
        </Level.Item>

        <Level right>
          <Level.Item right narrow>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await onExport(`clients.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await onExport(`clients.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item right narrow>
            <LinkButton to={`${url}/new${location.search}`} disabled={!auth.clients.create} className="button">
              Create a Client
              {!auth.clients.create && (
                <InlineTooltip message="Your security role prohibits you from creating clients." />
              )}
            </LinkButton>
          </Level.Item>
        </Level>
      </Level>

      <Level style={{ marginTop: '1.5rem' }}>
        {features.practices && (
          <Level.Item width="20rem">
            <PracticeSelect
              name="practice"
              value={practice}
              placeholder="All"
              materialPlaceholder="Practice"
              materialAlwaysVisible
              onChange={handleChange}
            />
          </Level.Item>
        )}

        <Level.Item width="40rem">
          <ClientTagFilter name="tags" value={tags} onChange={handleChange} />
        </Level.Item>
      </Level>
    </>
  );
}

export default ClientsListFilters;
