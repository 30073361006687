const pipelineActivityTypes = {
  opportunity: {
    amount_change: { id: 'amount_change', name: 'Amount Change' },
    call: { id: 'call', name: 'Call' },
    email: { id: 'email', name: 'Email' },
    expected_close_date_change: { id: 'expected_close_date_change', name: 'Expected Close Date Change' },
    file: { id: 'file', name: 'File' },
    meeting: { id: 'meeting', name: 'Meeting' },
    next_steps_change: { id: 'next_steps_change', name: 'Next Steps Change' },
    note: { id: 'note', name: 'Note' },
    other: { id: 'other', name: 'Other' },
    owner_change: { id: 'owner_change', name: 'Owner Change' },
    probability_change: { id: 'probability_change', name: 'Probability Change' },
    project_assignment: { id: 'project_assignment', name: 'Project Assignment' },
    stage_change: { id: 'stage_change', name: 'Stage Change' },
  },
  company: {
    call: { id: 'call', name: 'Call' },
    email: { id: 'email', name: 'Email' },
    file: { id: 'file', name: 'File' },
    meeting: { id: 'meeting', name: 'Meeting' },
    note: { id: 'note', name: 'Note' },
    other: { id: 'other', name: 'Other' },
    owner_change: { id: 'owner_change', name: 'Owner Change' },
    revenue_change: { id: 'revenue_change', name: 'Revenue Change' },
  },
  contact: {
    call: { id: 'call', name: 'Call' },
    email: { id: 'email', name: 'Email' },
    meeting: { id: 'meeting', name: 'Meeting' },
  },
};

export default pipelineActivityTypes;
