import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';

const holidays =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'holidays', id]).compact().join('/');

    return {
      get: (query = {}, config) => get(url.concat(new QueryString(query).toString(true)), config),
      lookup: () => get(url.concat('/lookup')),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      delete: () => del(url),
    };
  };

export default holidays;
