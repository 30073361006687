import {
  BillableIcon,
  Checkbox,
  Currency,
  Hours,
  Icon,
  Level,
  MemberSelect,
  SingleSelect,
  Table,
  Tooltip,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledHours = styled.div`
  color: ${({ statusId }) =>
    ({ approved: colors.green, pending_approval: colors.yellow, rejected: colors.red, not_submitted: colors.grey25 })[
      statusId
    ]};
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 2rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

const Stopwatch = styled.div`
  position: absolute;
  top: 0.1rem;
  left: 0.25rem;
  font-size: 0.75rem;
`;

const initialState = {
  isReady: false,
  data: null,
  query: {
    q: '',
    member: null,
  },
};
const handlers = {
  ready: ({ data }) => ({ isReady: true, data }),
  filter: (query, state) => ({ query: { ...state.query, ...query } }),
};

function TransactionsDrawerTimeTab({ invoice, timeEntries, start, end, projectId, currency, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, query }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const q = {
        ..._.omit(query, 'member'),
        start: start || undefined,
        end: end || undefined,
        projectId: projectId || undefined,
        memberId: query.member?.id,
      };
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).getTimeTransactions(q);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, query, start, end, projectId, api, invoice.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!data) return;

    return {
      entries: _.filter(data, (entry) => timeEntries.some((e) => e.id === entry.id)),

      get amount() {
        return _.sumBy(this.entries, (entry) => entry.revenue);
      },

      get hours() {
        return _.sumBy(selected.entries, (entry) => entry.hours);
      },

      get some() {
        return this.entries.length > 0;
      },

      get all() {
        return this.entries.length === data.length;
      },
    };
  }, [data, timeEntries]);

  const dateTimeFormat = useDateTimeFormat();

  if (!data) return null;

  const handleBulkSelectionChange = () => {
    onChange(
      selected.some
        ? timeEntries.filter(({ id }) => !data.some((entry) => id === entry.id))
        : [...timeEntries, ...data.map(({ id }) => ({ id }))],
    );
  };

  return (
    <div>
      <Level>
        <Level.Item>
          <MemberSelect
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={query.member}
            onChange={({ target: { value } }) => actions.filter({ member: value })}
          />
        </Level.Item>

        <Level.Item>
          <SingleSelect
            name="statusId"
            placeholder="All"
            materialPlaceholder="Approval Status"
            materialAlwaysVisible
            showEmptyOption
            value={query.statusId}
            onChange={({ target: { value } }) => actions.filter({ statusId: value })}>
            <option value="not_submitted">Not Submitted</option>
            <option value="pending_approval">Pending Approval</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </SingleSelect>
        </Level.Item>

        <Level.Item>
          <SingleSelect
            name="isBillable"
            placeholder="All"
            materialPlaceholder="Billable"
            materialAlwaysVisible
            showEmptyOption
            value={query.isBillable}
            onChange={({ target: { value } }) => actions.filter({ isBillable: value })}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </SingleSelect>
        </Level.Item>
      </Level>

      <StyledTable>
        <Table.Header>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column width="22rem">Details</Table.Column>
          <Table.Column width="2.5rem" align="right">
            <BillableIcon value={false} />
          </Table.Column>
          <Table.Column width="4.5rem" align="right">
            Hours
          </Table.Column>
          <Table.Column width="7rem" align="right">
            Rate
          </Table.Column>
          <Table.Column width="6rem" align="right">
            Amount
          </Table.Column>
        </Table.Header>
        <Table.Body>
          {data.map((entry) => {
            const checked = _(timeEntries).some(({ id }) => id === entry.id);

            const handleSelectionChange = () => {
              onChange(checked ? timeEntries.filter(({ id }) => id !== entry.id) : [...timeEntries, { id: entry.id }]);
            };

            return (
              <Table.Row key={entry.id} stretch>
                <Table.Cell>
                  <StyledCheckbox>
                    <Checkbox checked={checked} onChange={handleSelectionChange} />
                  </StyledCheckbox>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    {_.compact([
                      dateTimeFormat.format(entry.date),
                      entry.member?.name,
                      entry.project.useRoles ? entry.role?.name : null,
                      entry.task?.name,
                    ]).join(' - ')}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <BillableIcon value={entry.isBillable} />
                </Table.Cell>
                <Table.Cell>
                  {entry.timerStartedAt && (
                    <Stopwatch>
                      <Icon icon="stopwatch" type="far" />
                    </Stopwatch>
                  )}{' '}
                  <Tooltip message={entry.status.name}>
                    <StyledHours statusId={entry.statusId}>
                      <Hours value={entry.hours} />
                    </StyledHours>
                  </Tooltip>
                </Table.Cell>
                <Table.Cell>
                  <Currency value={entry.rate} maximumFractionDigits={7} currency={currency} />
                </Table.Cell>
                <Table.Cell>
                  <strong>
                    <Currency value={entry.revenue} currency={currency} />
                  </strong>
                </Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold, border: 'none' }}>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <p style={{ flex: 2, textAlign: 'right' }}>Total</p>
            </Table.Cell>
            <Table.Cell>
              <Hours value={selected.hours} />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              <Currency value={selected.amount} currency={currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </StyledTable>
    </div>
  );
}

export default TransactionsDrawerTimeTab;
