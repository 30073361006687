import { AutoComplete, Dropdown, FieldControl, InternalClientTooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const ClientGroup = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function AssignProjectSelect({ value, memberId, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).members(memberId).projects.unassigned({
        q,
      });

      const groups = data.reduce((a, v) => {
        a[v.client.id] = a[v.client.id] || { client: v.client, projects: [] };
        a[v.client.id].projects.push(v);
        return a;
      }, {});

      const options = [];
      for (var x in groups) {
        const g = groups[x];
        options.push({
          type: 'client',
          client: g.client,
          disabled: true,
          text: g.client.name,
        });
        g.projects.forEach((p) => {
          options.push({ type: 'project', project: p, disabled: false, text: p.name });
        });
      }

      return options;
    },
    [workspace.id, api, memberId],
  );

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target.name, value: event.target.value?.project } };
    onChange(changeArgument);
  };

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      renderValue={(option) => option.text}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'client':
                return (
                  <ClientGroup key={o.client.id}>
                    <strong>{o.client.name}</strong>
                    {o.client.isInternal && <InternalClientTooltip />}
                  </ClientGroup>
                );
              case 'project':
                return (
                  <AutoComplete.Option padding="0.25rem 0 0.25rem 2rem" key={o.project.id} value={o} index={index++}>
                    {o.project.name}
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

function FieldAssignProjectSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <AssignProjectSelect {...field} {...props} />
    </FieldControl>
  );
}

export default AssignProjectSelect;
export { FieldAssignProjectSelect };
