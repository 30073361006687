import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreditNoteDetails from './details/CreditNoteDetails';
import CreditNotesListPage from './list/CreditNotesListPage';

export default function CreditNotesArea() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <CreditNotesListPage />
      </Route>

      <Route path={`${path}/:creditNoteId`}>
        <CreditNoteDetails />
      </Route>

      <Redirect path="/" to={`${url}`} />
    </Switch>
  );
}
