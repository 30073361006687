import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;

  &:first-child {
    margin-top: -0.75rem;
  }

  &:last-child {
    margin-bottom: -0.75rem;
  }

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

const Column = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const GridRowContext = React.createContext();

function GridRow({ size = 12, ...props }) {
  return (
    <GridRowContext.Provider value={{ size }}>
      <Row {...props} />
    </GridRowContext.Provider>
  );
}

function GridColumn({ size, width, style, ...props }) {
  const row = useContext(GridRowContext);
  let columnStyle = { ...style };

  if (size) {
    const columnWidth = (size / row.size) * 100;
    columnStyle.flex = `0 0 ${columnWidth}%`;
    columnStyle.width = `${columnWidth}%`;
  } else if (width) {
    columnStyle.flex = `0 0 calc(${width} + 1.5rem)`;
    columnStyle.width = `calc(${width} + 1.5rem)`;
  }

  return <Column {...props} style={columnStyle} />;
}

Grid.Row = GridRow;
Grid.Column = GridColumn;

export default Grid;
export { GridRow, Column };
