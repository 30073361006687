import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import { colors } from '~/styles';
import { emptyStringToNull } from '~/utils';
import { Field, ProjectSelect } from '../fields';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

export default function ChangeProject({ file, member, onClose, onSave }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [formState, form] = useForm();
  const { isSubmitting, status, message } = formState;
  const [projectId, setProjectId] = useState(file.projectId);
  const toast = useToast();

  async function handleSave() {
    try {
      form.submit();
      const { data } = await api.www
        .workspaces(workspace.id)
        .weekFiles(file.id)
        .update(emptyStringToNull({ projectId }), { memberId: member ? member.id : undefined });
      onClose();
      onSave(data);
      toast.success('Project saved.');
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }

  return (
    <ModalCard title="Change Project" onClose={onClose}>
      <ModalCard.Body>
        <p>
          Change the project for <strong>{file.name}</strong>
        </p>
        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
        <Form.Control>
          <Field name="projectId">
            <ProjectSelect
              name="projectId"
              placeholder="Project"
              showEmptyOption={true}
              memberId={member ? member.id : undefined}
              value={projectId}
              onChange={(event) => setProjectId(event.target.value)}
            />
          </Field>
        </Form.Control>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button type="submit" disabled={isSubmitting} onClick={handleSave}>
            Save
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
