import React from 'react';
import { DateTime, Tooltip } from '~/components';
import { dateFormats } from '~/utils';

export default function SuppressedTooltip({ suppressedEmail, children }) {
  return (
    <Tooltip
      message={
        <>
          Ruddr is not sending emails to <strong>{suppressedEmail.email}</strong> because an undeliverable email
          occurred at{' '}
          <strong>
            <DateTime value={suppressedEmail.lastUpdated} format={dateFormats.compactDateTime} />
          </strong>
          . Please chat with support or email help@ruddr.io if you would like Ruddr to resume sending emails to this
          address.
        </>
      }>
      {children}
    </Tooltip>
  );
}
