import { useWorkspace } from '~/contexts';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function LedgerInstruction() {
  const { workspace } = useWorkspace();
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ruddr ID</td>
            <td>No</td>
            <td>The ID of the item to edit, it will be used to find and replace the values of an existing item.</td>
          </tr>
          <tr>
            <td>Legacy ID</td>
            <td>Yes</td>
            <td>
              The Legacy ID of the item to import, it will be used to check for duplicates if multiple imports are run.
            </td>
          </tr>
          <tr>
            <td>Date</td>
            <td>Yes</td>
            <td>Date of the expense item in 'MM/DD/YYYY' format.</td>
          </tr>
          <tr>
            <td>Expense Category</td>
            <td>Yes for expenses</td>
            <td>
              The expense category associated with the project expense item. This must match the Name of an expense
              category exactly on white space, case, and punctuations or the row will be skipped.
            </td>
          </tr>

          <tr>
            <td>Rate</td>
            <td>No</td>
            <td>The cost of each unit purchased for the item, used for other items to bill only.</td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td>No</td>
            <td>The quantity of each unit purchased for the item, used for other items to bill only.</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>Yes</td>
            <td>The amount should be a numeric value without any currency symbols.</td>
          </tr>
          <tr>
            <td>Vendor Name</td>
            <td>No</td>
            <td>Vendor or seller's name for a project expense item.</td>
          </tr>
          <tr>
            <td>Client Name</td>
            <td>No</td>
            <td>
              The Name of the client associated with the expense item. This must match the Name of a client exactly on
              white space, case, and punctuations. This will be used in conjunction with the project name to assign the
              project to the expense item.
            </td>
          </tr>
          <tr>
            <td>Project Name</td>
            <td>No</td>
            <td>
              The Name of the project associated with this expense item. This must match the Name of a project exactly
              on white space, case, and punctuations. This will be used in conjunction with the client name to assign
              the project to the expense item.
            </td>
          </tr>
          <tr>
            <td>Billable</td>
            <td>No</td>
            <td>
              Set this value to 'Yes' if billable, otherwise will be treated as non-billable. For project invoice
              milestones, this will set the exclude from billing status.
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>No</td>
            <td>Provide some description or notes related to the item.</td>
          </tr>
          <tr>
            <td>Invoice Item</td>
            <td>No</td>
            <td>This should be provided for project other items to bill, and will be used during invoicing.</td>
          </tr>
        </tbody>
      </MainTable>
      <p>
        <a target="blank" href={`/api/www/workspaces/${workspace.id}/integrations/csv-import/ledgers.csv`}>
          ledgers.csv
        </a>
      </p>
    </>
  );
}
