import React from 'react';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';
import styled from 'styled-components';

export const ProjectHealthCircles = styled.div`
  display: flex;

  > :first-child {
    margin-left: 0;
  }

  > :not(:first-child) {
    margin-left: 0.25rem;
  }
`;

export const Notes = styled.p`
  display: block;
  white-space: pre-wrap;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

export default function ProjectHealthCircle({ value, ...props }) {
  return (
    <Tooltip
      message={
        <div>
          <p>
            <strong>{value.label}</strong>
          </p>
          {value.notes && <Notes style={{ whiteSpace: 'pre-wrap' }}>{value.notes}</Notes>}
        </div>
      }>
      <Icon
        icon="circle"
        color={
          {
            excellent: colors.green,
            fair: colors.yellow,
            poor: colors.red,
          }[value.id]
        }
        {...props}
      />
    </Tooltip>
  );
}
