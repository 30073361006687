import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, FormMessage, ModalCard, SingleSelect } from '~/components';
import { useApi } from '~/contexts';
import { useForm } from '~/hooks';
import { weights } from '~/styles';

const Name = styled.p`
  margin-bottom: 1rem;

  > span {
    font-weight: ${weights.bold};
  }
`;

export default function ChangeStatus({ member, onChange, onClose }) {
  const [{ isSubmitting, message, status }, form] = useForm();
  const [statusId, setStatusId] = useState(member.statusId);
  const api = useApi();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    try {
      await api.admin.members.changeStatus(member.id, statusId);

      onChange();
      onClose();
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  return (
    <ModalCard title="Change Internal Member Status" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        <Name>
          <span>{member.name}</span> ({member.email})
        </Name>
        <SingleSelect placeholder="Status" value={statusId} onChange={(event) => setStatusId(event.target.value)}>
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </SingleSelect>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Save
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
