import { Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button, Currency, DeleteConfirmation, Field, Icon, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation } from '~/contexts';
import { weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import { ProjectDrawerContext } from '../../ProjectDrawer';

function BudgetOtherItemsTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = (value) => {
    value = emptyStringToNull(value);
    if (!value.fee) {
      value.fee = 0;
    }

    const otherItems = value.id
      ? projectModel.budgetOtherItems.map((boi) =>
          boi.id === value.id ? { ...boi, ...value, invoiceItemId: value.invoiceItem?.id } : boi,
        )
      : [...projectModel.budgetOtherItems, { ...value, id: _.uniqueId('boi_'), invoiceItemId: value.invoiceItem?.id }];

    setEditIndex(null);
    onChange(otherItems);
  };

  const handleDelete = (item) => {
    onChange(projectModel.budgetOtherItems.filter((r) => r.id !== item.id));
  };

  const totals = {
    fee: projectModel.budgetOtherItems.reduce((a, v) => a + v.fee, 0),
  };

  const currency = projectModel.currency;

  return (
    <div>
      <Table small>
        <Table.BoxHeader>
          <Table.Column>Invoice Item</Table.Column>
          <Table.Column>Description</Table.Column>
          <Table.Column align="right" width="10rem">
            Amount
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {projectModel.budgetOtherItems.map((item, index) => (
            <BudgetOtherItemRow
              key={item.id}
              budgetOtherItem={item}
              projectModel={projectModel}
              disableActions={editIndex !== null}
              isEditing={editIndex === index}
              currency={currency}
              onEdit={() => setEditIndex(index)}
              onCancel={() => setEditIndex(null)}
              onSaved={handleSave}
              onDeleted={handleDelete}
            />
          ))}

          <BudgetOtherItemRow
            budgetOtherItem={{}}
            projectModel={projectModel}
            isEditing={editIndex === -1}
            disableActions={editIndex !== null}
            currency={currency}
            onEdit={() => setEditIndex(-1)}
            onCancel={() => setEditIndex(null)}
            onSaved={handleSave}
          />

          <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <Currency value={totals.fee} currency={currency} />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

function BudgetOtherItemRow({
  currency,
  budgetOtherItem,
  disableActions,
  isEditing,
  onEdit,
  onCancel,
  onSaved,
  onDeleted,
}) {
  async function handleSubmit(values) {
    onSaved(values);
  }

  async function handleDelete() {
    onDeleted(budgetOtherItem);
  }
  if (!isEditing)
    return (
      <BudgetOtherItemRowDetails
        currency={currency}
        budgetOtherItem={budgetOtherItem}
        disableActions={disableActions}
        onEdit={onEdit}
        onDelete={handleDelete}
      />
    );

  return (
    <BudgetOtherItemsRowForm
      currency={currency}
      budgetOtherItem={budgetOtherItem}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
}

function BudgetOtherItemRowDetails({
  currency,
  budgetOtherItem: { id, invoiceItem, description, fee },
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this budget other item?</DeleteConfirmation>
    ));
    if (!confirm) {
      return;
    }
    onDelete();
  };

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  return (
    <Table.BoxRow>
      <Table.Cell>{invoiceItem.name}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>
        <Currency value={fee} currency={currency} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Delete disabled={disableActions} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function BudgetOtherItemsRowForm({ currency, budgetOtherItem, onSubmit, onCancel }) {
  // Submit the row if the drawer's "Save" button is clicked
  const form = useRef();
  const { setForms } = useContext(ProjectDrawerContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'budget_other_item', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'budget_other_item'));
    };
  }, [setForms]);

  const initialValues = mergeValues(
    {
      id: null,
      invoiceItem: null,
      description: '',
      fee: '',
    },
    budgetOtherItem,
  );

  return (
    <Formik
      innerRef={form}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        invoiceItem: Yup.mixed().label('Invoice Item').required(),
        description: Yup.string().label('Description').max(255).required(),
        fee: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable(),
      })}>
      {({ submitForm }) => {
        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              <Field.InvoiceItemSelect
                autoFocus
                name="invoiceItem"
                materialPlaceholder="Invoice Item"
                materialAlwaysVisible
                type="income"
                position="top"
              />
            </Table.Cell>
            <Table.Cell>
              <Field.Text name="description" materialPlaceholder="Description" materialAlwaysVisible maxLength={255} />
            </Table.Cell>
            <Table.Cell>
              <Field.Currency name="fee" materialPlaceholder="Amount" materialAlwaysVisible currency={currency} />
            </Table.Cell>
            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

export default BudgetOtherItemsTable;
