import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function MemberInstruction() {
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>Yes</td>
            <td>
              A reference value to identify the member during the overall import. This value will be assigned to the
              "Internal ID" field for the member.
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>Yes</td>
            <td>Full name of the member.</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>Yes</td>
            <td>Email address of the member.</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>No</td>
            <td>
              The employment type which can have a value of: 'employee', 'contractor', or 'other'. By default 'employee'
              will be used if no other matching value is provided.
            </td>
          </tr>

          <tr>
            <td>Billable</td>
            <td>Yes</td>
            <td>Use 'Yes' if the member is billable, otherwise the imported member will be considered non-billable.</td>
          </tr>

          <tr>
            <td>Status</td>
            <td>No</td>
            <td>Set this value to 'Inactive' otherwise the member will considered 'Active' when imported.</td>
          </tr>

          <tr>
            <td>Labor Cost Type</td>
            <td>No</td>
            <td>Use 'hourly' to set the member as an hourly labor cost type, otherwise will default to 'fixed'.</td>
          </tr>
          <tr>
            <td>Default Bill Rate</td>
            <td>No</td>
            <td>The default value to assign as a rate when the member is assigned to a project</td>
          </tr>
          <tr>
            <td>Default Currency</td>
            <td>No</td>
            <td>
              The currency of the default bill rate. This will be used in conversions if the project is not the same
              currency.
            </td>
          </tr>
          <tr>
            <td>Start Date</td>
            <td>No</td>
            <td>
              The date the member is made active with your organization. If no value is provided, the date of the import
              will be used.
            </td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>No</td>
            <td>The date the member ends activity at your organization. This is used for reporting purposes only.</td>
          </tr>
          <tr>
            <td>Security Role</td>
            <td>No</td>
            <td>
              Set's the member's security role assignment by matching the name of a security role already in the
              workspace (for example "Workspace Admin"). The name matching is strict and must be accurate on
              captialization, spacing, and punctuation. If no match is found the default security role will be assigned.
            </td>
          </tr>
          <tr>
            <td>Job Title</td>
            <td>No</td>
            <td>Assigns a job title to the member, and automatically creates the custom data record if necessary.</td>
          </tr>
          <tr>
            <td>Practice</td>
            <td>No</td>
            <td>
              Set's the member's practice assignment by matching the name of a Practice already in the workspace. The
              name matching is strict and must be accurate on captialization, spacing, and punctuation. If no match is
              found no practice will be assigned during this import.
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td>No</td>
            <td>
              Set's the member's location assignment by matching the name of a Location already in the workspace. The
              name matching is strict and must be accurate on captialization, spacing, and punctuation. If no match is
              found no location will be assigned during this import.
            </td>
          </tr>
          <tr>
            <td>Holiday Schedule</td>
            <td>No</td>
            <td>
              Set's the member's holiday schedule assignment by matching the name of a Holiday Schedules already in the
              workspace. The name matching is strict and must be accurate on captialization, spacing, and punctuation.
              If no match is found nothing, the assignment will not be made.
            </td>
          </tr>
          <tr>
            <td>Discipline</td>
            <td>No</td>
            <td>
              Set's the member's discipline assignment by matching the name of a Discipline already in the workspace.
              The name matching is strict and must be accurate on captialization, spacing, and punctuation. If no match
              is found no discipline will be assigned during this import.
            </td>
          </tr>
          <tr>
            <td>Manager Email</td>
            <td>No</td>
            <td>
              <strong>The email address of the member's manager.</strong> The value provided must match a Ruddr member's
              email as it appears in the member's profile or will appear after this import.
            </td>
          </tr>
          <tr>
            <td>Tags</td>
            <td>No</td>
            <td>
              Member tags are used in filtering and organizing members into groups. This field can include one tag or be
              a semi-colon delimited list of tags associated with the member. If a tag doesn't exist in your workspace,
              it will automatically be created.
            </td>
          </tr>
          <tr>
            <td>Skills</td>
            <td>No</td>
            <td>
              Member skills are used in allocations and reporting on members. This field can include one skill or be a
              semi-colon delimited list of to be associated with the member. If a skill doesn't exist in your workspace,
              it will automatically be created.
            </td>
          </tr>
          <tr>
            <td>Require Capacity</td>
            <td>No</td>
            <td>
              Use 'Yes' to require the member to fill capacity before submitting their timesheet, otherwise will be
              disabled by default.
            </td>
          </tr>
          <tr>
            <td>Time Off Tracking</td>
            <td>No</td>
            <td>Use 'No' to disable time off tracking, otherwise will default to enabled.</td>
          </tr>
          <tr>
            <td>Time Off Approval</td>
            <td>No</td>
            <td>Use 'manager', 'auto', or the email address of a member who would approve.</td>
          </tr>
          <tr>
            <td>Expense Approval</td>
            <td>No</td>
            <td>
              If an internal expense does not have an associated project, specify how it should be approved. Use
              'manager', 'auto', or the email address of a member who would approve.
            </td>
          </tr>
          <tr>
            <td>Missing Time Reminders</td>
            <td>No</td>
            <td>Use 'No' to disable this for the member, otherwise will be enabled by default.</td>
          </tr>
          <tr>
            <td>Timehsheet Submission Reminders</td>
            <td>No</td>
            <td>Use 'No' to disable this for the member, otherwise will be enabled by default.</td>
          </tr>
          <tr>
            <td>Automatic Timesheet Submission Confirmation</td>
            <td>No</td>
            <td>Use 'No' to disable this for the member, otherwise will be enabled by default.</td>
          </tr>
        </tbody>
      </MainTable>
      <p>
        When members are successfully imported they will also have a default Capacity, Cost, and Target created based on
        the Start Date.
      </p>
      <br />
    </>
  );
}
