import styled from 'styled-components';

const Page = styled.article`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ? backgroundColor : 'transparent';
  }};
  padding: ${({ padding }) => {
    return padding ? padding : '2rem';
  }};
`;

Page.Header = styled.header`
  display: flex;
`;

Page.Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
`;

Page.Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  &:first-child {
    margin-top: 0;
  }
`;

export default Page;
