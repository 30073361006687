import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted, usePercentFormat } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function TaxRateSelect({ value, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).taxRates().get({ q, size: 1000 });

      return data;
    },
    [workspace.id, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const percentFormat = usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 7 });

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? `${value.name} (${percentFormat.format(value.rate / 100)})` : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => `${option.name} (${percentFormat.format(option.rate / 100)})`}
      {...props}
    />
  );
}

function FieldTaxRateSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <TaxRateSelect {...field} {...props} />
    </FieldControl>
  );
}

export default TaxRateSelect;
export { FieldTaxRateSelect };
