import {
  ApprovalStatusFilter,
  BackLink,
  Checkbox,
  ClientApprovalStatusFilter,
  ClientLink,
  DateTime,
  DisciplineFilter,
  Hours,
  Icon,
  InternalClientTooltip,
  InvoicedFilter,
  Level,
  Percent,
  PeriodFilter,
  ProjectLink,
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
  RouteLink,
  SingleSelect,
  Stack,
  Table,
  TimeBillableTypeFilter,
  TimeOffTypeFilter,
  Tooltip,
  Widget,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import {
  useDateTimeFormat,
  useDocumentTitle,
  useFeatures,
  useHoursFormat,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QueryString, dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import InvoicedTag from '../components/InvoicedTag';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import PeriodNavTag from '../components/PeriodNavTag';
import PeriodTag from '../components/PeriodTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import ProjectTaskRecordStatusTag from '../components/ProjectTaskRecordStatusTag';
import ProjectTaskStatusTag from '../components/ProjectTaskStatusTag';
import ProjectTaskTag from '../components/ProjectTaskTag';
import ProjectTaskTags from '../components/ProjectTaskTags';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import RoleDisciplineTag from '../components/RoleDisciplineTag';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TimeBillableTypeTag from '../components/TimeBillableTypeTag';
import TimeOffTypeTag from '../components/TimeOffTypeTag';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const StyledProject = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
`;

const intervals = {
  get custom() {
    return intervalOptions.custom;
  },

  get past_30_days() {
    return {
      key: 'past_30_days',
      label: 'Past 30 days',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_60_days() {
    return {
      key: 'past_60_days',
      label: 'Past 60 days',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_90_days() {
    return {
      key: 'past_90_days',
      label: 'Past 90 days',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_4_weeks() {
    return {
      key: 'past_4_weeks',
      label: 'Past 4 weeks',
      start: moment().subtract(4, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'week').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_12_weeks() {
    return {
      key: 'past_12_weeks',
      label: 'Past 12 weeks',
      start: moment().subtract(12, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_26_weeks() {
    return {
      key: 'past_26_weeks',
      label: 'Past 26 weeks',
      start: moment().subtract(26, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_3_months() {
    return {
      key: 'past_3_months',
      label: 'Past 3 months',
      start: moment().subtract(3, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_6_months() {
    return {
      key: 'past_6_months',
      label: 'Past 6 months',
      start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_9_months() {
    return {
      key: 'past_9_months',
      label: 'Past 9 months',
      start: moment().subtract(9, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_12_months() {
    return {
      key: 'past_12_months',
      label: 'Past 12 months',
      start: moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
};

const intervalsByScope = {
  get day() {
    return [intervals.custom, intervals.past_30_days, intervals.past_60_days, intervals.past_90_days];
  },

  get week() {
    return [intervals.custom, intervals.past_4_weeks, intervals.past_12_weeks, intervals.past_26_weeks];
  },

  get month() {
    return [
      intervals.custom,
      intervals.past_3_months,
      intervals.past_6_months,
      intervals.past_9_months,
      intervals.past_12_months,
    ];
  },
};

export default function TimeSummaryByProjectByTimeUnit() {
  useDocumentTitle('Time Summary by Project by Time Unit');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    unit: '',

    // Time Filters
    createdPeriod: null,
    modifiedPeriod: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    billableTypes: [],
    timeOffTypes: [],
    invoiced: null,
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    projectRoles: [],
    roleDisciplines: [],
    includeProjectsWithoutTime: false,

    // Member Filters
    ...memberFilters.filters,

    // Client Filters
    ...clientFilters.filters,

    // Project filters
    ...projectFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervals.past_6_months,
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['day', 'week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'day':
                  return intervals.past_30_days;

                case 'week':
                  return intervals.past_12_weeks;

                case 'month':
                  return intervals.past_6_months;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },

        // Time Filters
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        billableTypes: searchParamsConfig.timeBillableTypes,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        invoiced: searchParamsConfig.invoiced,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        includeProjectsWithoutTime: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },

        // Member Filters
        ...memberFilters.searchParamsConfig,

        // Client Filters
        ...clientFilters.searchParamsConfig,

        // Project Filters
        ...projectFilters.searchParamsConfig,

        // TODO: add filters by Time properties (like Time Summary by Project)
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,

      // Time Filters
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      billableTypeId: params.billableTypes?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      includeProjectsWithoutTime: params.includeProjectsWithoutTime ? 'true' : undefined,

      // Member Filters
      ...memberFilters.mapUrlSearchParams(params),

      // Client Filters
      ...clientFilters.mapUrlSearchParams(params),

      // Project Filters
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByProjectByTimeUnit(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByProjectByTimeUnit(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />

        <Stack>
          <h1>Time Summary by Project by Time Unit</h1>

          <Tags>
            <PeriodNavTag
              value={params.period}
              intervals={intervalsByScope[params.unit]}
              onChange={(period) => handleApplyFilters({ period })}
            />

            <Tag label="Unit" value={{ month: 'Month', week: 'Week', day: 'Day' }[params.unit]} />

            <PeriodTag
              value={params.createdPeriod}
              label="Created Date Range"
              onRemove={() => handleApplyFilters({ createdPeriod: intervalOptions.all_dates })}
            />

            <PeriodTag
              value={params.modifiedPeriod}
              label="Modified Date Range"
              onRemove={() => handleApplyFilters({ modifiedPeriod: intervalOptions.all_dates })}
            />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            <TimeBillableTypeTag
              value={params.billableTypes}
              onRemove={() => handleApplyFilters({ billableTypes: [] })}
            />

            <TimeOffTypeTag value={params.timeOffTypes} onRemove={() => handleApplyFilters({ timeOffTypes: [] })} />

            <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />

            <RoleDisciplineTag
              value={params.roleDisciplines}
              onRemove={() => handleApplyFilters({ roleDisciplines: [] })}
            />

            <ProjectTaskTag value={params.projectTasks} onRemove={() => handleApplyFilters({ projectTasks: [] })} />

            <ProjectTaskStatusTag
              value={params.projectTaskStatuses}
              onRemove={() => handleApplyFilters({ projectTaskStatuses: [] })}
            />

            <ProjectTaskRecordStatusTag
              value={params.projectTaskRecordStatusId}
              onRemove={() => handleApplyFilters({ projectTaskRecordStatusId: null })}
            />

            <ProjectTaskTags
              value={params.projectTaskTags}
              onRemove={() => handleApplyFilters({ projectTaskTags: [] })}
            />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            {params.includeProjectsWithoutTime && <Tag>Include projects without time</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { projects, records, periods, start, end } = useMemo(() => {
    const projects = _.orderBy(report.projects, (p) => p.name.toLowerCase());

    const records = report.records.reduce(
      (a, v) => {
        a.total.billable += v.billable || 0;
        a.total.nonBillable += v.nonBillable || 0;
        a.total.internal += v.internal || 0;
        a.total.total += v.total || 0;

        // Projects
        a.projects[v.projectId] = a.projects[v.projectId] || {
          total: {
            billable: 0,
            nonBillable: 0,
            internal: 0,
            total: 0,
          },
          periods: {},
        };
        a.projects[v.projectId].total.billable += v.billable || 0;
        a.projects[v.projectId].total.nonBillable += v.nonBillable || 0;
        a.projects[v.projectId].total.internal += v.internal || 0;
        a.projects[v.projectId].total.total += v.total || 0;

        a.projects[v.projectId].periods[v.start] = v;

        // Periods
        if (!a.periods[v.start])
          a.periods[v.start] = {
            start: v.start,
            end: v.end,
            billable: 0,
            nonBillable: 0,
            internal: 0,
            total: 0,
          };
        a.periods[v.start].billable += v.billable || 0;
        a.periods[v.start].nonBillable += v.nonBillable || 0;
        a.periods[v.start].internal += v.internal || 0;
        a.periods[v.start].total += v.total || 0;

        return a;
      },
      {
        projects: {},
        periods: {},
        total: {
          billable: 0,
          nonBillable: 0,
          internal: 0,
          total: 0,
        },
      },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      const periodStart = moment(start).add(index, params.unit).format(dateFormats.isoDate);
      const periodEnd = moment(periodStart)
        .endOf({ day: 'day', week: 'isoWeek', month: 'month' }[params.unit])
        .format(dateFormats.isoDate);
      periods.push({ start: periodStart, end: periodEnd });
    }

    return { projects, records, periods, start, end };
  }, [report, params.period.start, params.period.end, params.unit]);

  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start,
          end,
          projectRole: params.projectRoles?.map((v) => v.id),
          projectTask: params.projectTasks?.map((v) => v.id),
          projectTaskStatus: params.projectTaskStatuses?.map((v) => v.id),
          projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
          projectTaskTag: params.projectTaskTags?.map((v) => v.id),
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          roleDiscipline: params.roleDisciplines?.map((v) => v.id),
          billableType: ['billable', 'non_billable', 'internal'],
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters, start, end],
  );

  const history = useHistory();

  const handleChartClick = ({ start, end, billableType }) => {
    history.push(timeDetail({ start, end, billableType }));
  };

  return (
    <>
      <Report.Summary>
        <Chart periods={periods} records={records} unit={params.unit} onClick={handleChartClick} />
      </Report.Summary>

      <Report.Table style={{ marginTop: '1rem' }}>
        <TotalResults>
          {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
          <Table.Total label="Project" value={projects.length} />
        </TotalResults>

        <Table style={{ position: 'absolute', minWidth: 'calc(100% - 4rem)' }}>
          <Table.Header sticky>
            <Table.Column width="16rem">Project/Client</Table.Column>

            {periods.map(({ start }) => (
              <Table.Column key={start} align="right" width="7rem">
                {
                  {
                    day: <DateTime value={start} />,
                    week: <DateTime value={start} />,
                    month: moment(start).format(dateFormats.monthYear),
                  }[params.unit]
                }
              </Table.Column>
            ))}

            <Table.Column align="right" width="7rem">
              Total
            </Table.Column>
          </Table.Header>

          <TableBody>
            {projects.map((project) => {
              return (
                <Table.Row key={project.id}>
                  <Table.Cell>
                    <ProjectInfo>
                      <StyledProject>
                        <ProjectLink project={project} />
                      </StyledProject>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>

                  {periods.map((period) => {
                    const value = records.projects[project.id]?.periods[period.start];

                    return (
                      <Table.Cell key={period.start}>
                        <CellValue
                          value={value}
                          showBudget={params.unit === 'month' && project.useMonthlyBudget}
                          project={project}
                          to={timeDetail({
                            project: project.id,
                            start: period.start,
                            end: period.end,
                          })}
                        />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <CellValue value={records.projects[project.id]?.total} to={timeDetail({ project: project.id })} />
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>

              {periods.map((period) => {
                const value = records.periods[period.start];

                return (
                  <Table.Cell key={period.start}>
                    <CellValue value={value} to={timeDetail({ start: period.start, end: period.end })} />
                  </Table.Cell>
                );
              })}

              <Table.Cell>
                <CellValue value={records.total} to={timeDetail()} />
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const features = useFeatures();

  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeProjectsWithoutTime}
          label="Include projects without tracked time"
          onChange={({ target: { checked } }) => handleFilter({ includeProjectsWithoutTime: checked })}
        />
      </Filter>

      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                day: intervals.past_30_days,
                week: intervals.past_12_weeks,
                month: intervals.past_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Task Filters"
        filters={[
          filters.projectTasks,
          filters.projectTaskRecordStatusId,
          filters.projectTaskStatuses,
          filters.projectTaskTags,
        ]}>
        {filters.projects.length === 1 && (
          <Filter>
            <ProjectTaskFilter
              project={filters.projects[0]}
              value={filters.projectTasks}
              onChange={({ target: { value } }) => handleFilter({ projectTasks: value })}
            />
          </Filter>
        )}
        <Filter>
          <ProjectTaskStatusFilter
            value={filters.projectTaskStatuses}
            onChange={({ target: { value } }) => handleFilter({ projectTaskStatuses: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskRecordStatusFilter
            value={filters.projectTaskRecordStatusId}
            onChange={({ target: { value } }) => handleFilter({ projectTaskRecordStatusId: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskTagFilter
            value={filters.projectTaskTags}
            onChange={({ target: { value } }) => handleFilter({ projectTaskTags: value })}
          />
        </Filter>
      </FiltersGroup>

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Time Filters"
        filters={[
          filters.createdPeriod?.start,
          filters.createdPeriod?.end,
          filters.modifiedPeriod?.start,
          filters.modifiedPeriod?.end,
          filters.billableTypes,
          filters.timeOffTypes,
          filters.approvalStatuses,
          filters.clientApprovalStatuses,
          filters.invoiced,
          filters.roleDisciplines,
        ]}>
        <Filter>
          <ReportPeriodFilter
            value={filters.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
          />
        </Filter>

        <Filter>
          <ReportPeriodFilter
            value={filters.modifiedPeriod}
            label="Modified Date Range"
            onChange={({ target: { value } }) => handleFilter({ modifiedPeriod: value })}
          />
        </Filter>

        <Filter>
          <TimeBillableTypeFilter
            value={filters.billableTypes}
            onChange={({ target: { value } }) => handleFilter({ billableTypes: value })}
          />
        </Filter>

        <Filter>
          <TimeOffTypeFilter
            value={filters.timeOffTypes}
            onChange={({ target: { value } }) => handleFilter({ timeOffTypes: value })}
          />
        </Filter>

        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>

        <Filter>
          <DisciplineFilter
            value={filters.roleDisciplines}
            materialPlaceholder="Role Discipline"
            onChange={({ target: { value } }) => handleFilter({ roleDisciplines: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}

const TooltipContainer = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const TooltipLabel = styled.div`
  width: 15rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const TooltipValue = styled.div`
  white-space: wrap;
`;

function TooltipHours({ value }) {
  return (
    <>
      <Hours value={value} /> {_.isNumber(value) && pluralize('hours', value)}
    </>
  );
}

function CellValue({ value, showBudget = false, to, project }) {
  if (!value)
    return to ? (
      <RouteLink to={to}>
        <Hours value={null} />
      </RouteLink>
    ) : (
      <Hours value={null} />
    );

  return (
    <Tooltip
      maxWidth="22rem"
      message={
        <TooltipContainer>
          <TooltipInfo>
            <TooltipLabel>Client Billable</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.billable} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Client Non-Billable</TooltipLabel>
            <TooltipHours value={value.nonBillable} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Internal</TooltipLabel>
            <TooltipHours value={value.internal} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Total</TooltipLabel>
            <TooltipHours value={value.total} />
          </TooltipInfo>

          {showBudget && (
            <>
              <TooltipInfo>
                <TooltipLabel>Monthly Billable Budget</TooltipLabel>
                <TooltipHours value={project.monthlyBudgetBillableHours} />
              </TooltipInfo>

              <TooltipInfo>
                <TooltipLabel>Monthly Non-Billable Budget</TooltipLabel>
                <TooltipHours value={project.monthlyBudgetNonBillableHours} />
              </TooltipInfo>

              <TooltipInfo>
                <TooltipLabel>Monthly Total Budget</TooltipLabel>
                <TooltipHours value={project.monthlyBudgetHours} />
              </TooltipInfo>

              <TooltipInfo>
                <TooltipLabel>Monthly Budget Used</TooltipLabel>
                <TooltipValue style={{ color: value.percentUsed > 1 ? colors.danger : colors.black }}>
                  <Percent value={value.percentUsed} />
                </TooltipValue>
              </TooltipInfo>
            </>
          )}
        </TooltipContainer>
      }>
      {to ? (
        <RouteLink to={to}>
          <Hours value={value.total} />
        </RouteLink>
      ) : (
        <Hours value={value.total} />
      )}

      {showBudget && value.percentUsed > 0 && (
        <p style={{ color: value.percentUsed > 1 ? colors.danger : colors.black }}>
          <small>
            <Percent value={value.percentUsed} />
          </small>
        </p>
      )}
    </Tooltip>
  );
}

const getMax = (periods, defaultMax = 8, precision = 2) => {
  let max = Math.max(...periods.map(({ total }) => Math.round(total) || 0), defaultMax) * 1.1;
  max = Math.floor(max / precision) * precision + precision;

  return max;
};

function Chart({ periods, records, unit, onClick }) {
  const dateTimeFormat = useDateTimeFormat(dateFormats.compactDate);

  const { settings, datasets } = useMemo(() => {
    const data = periods.map((period) => records.periods[period.start] ?? period);

    const settings = {
      day: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
        max: getMax(data, 8, 2),
        stepSize: 2,
      }),
      week: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
        max: getMax(data, 8, 2),
        stepSize: 10,
      }),
      month: () => ({
        labels: data.map(({ start }) => moment(start).format(dateFormats.monthYear)),
        max: getMax(data, 8, 2),
        stepSize: 20,
      }),
    }[unit]();

    const datasets = [
      {
        id: 'billable',
        label: 'Client Billable    ',
        data: data.map(({ billable }) => billable),
        backgroundColor: colors.success,
      },
      {
        id: 'non-billable',
        label: 'Client Non-Billable    ',
        data: data.map(({ nonBillable }) => nonBillable),
        backgroundColor: colors.danger,
      },
      {
        id: 'internal',
        label: 'Internal    ',
        data: data.map(({ internal }) => internal),
        backgroundColor: colors.warning,
      },
      {
        id: 'total',
        label: 'Total',
        data: data.map(({ total }) => (total ? 0 : 0.25)),
        backgroundColor: colors.grey5,
      },
    ];

    return { settings, datasets };
  }, [periods, records, unit, dateTimeFormat]);

  const hoursFormat = {
    tooltip: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
  };

  const handleClick = (_event, [element]) => {
    if (!element) return;

    const billableTypes = ['billable', 'non_billable', 'internal'];

    const billableType = billableTypes[element.datasetIndex];
    const { start, end } = periods[element.index];

    onClick({ start, end, billableType });
  };

  return (
    <Widget style={{ height: '20rem' }}>
      <Bar
        data={{
          labels: settings.labels,
          datasets: datasets,
        }}
        options={{
          maintainAspectRatio: false,

          layout: {
            padding: { top: 12 },
          },

          plugins: {
            legend: {
              onClick: null,
              labels: {
                font: { size: 12 },
                pointStyleWidth: 14,
                boxHeight: 10,
                filter: (item) => !item.text.includes('Total'),
                usePointStyle: true,
              },
            },

            tooltip: {
              filter: (item) => item.datasetIndex <= 3,
              callbacks: {
                title: () => '',
                label: (tooltip) => {
                  let label = (tooltip.dataset.label || '').trim();
                  if (label) {
                    label += ': ';
                  }
                  label += hoursFormat.tooltip.format(tooltip.parsed.y);
                  return label;
                },
              },
            },
          },

          scales: {
            x: {
              stacked: true,
              grid: { display: false },
              ticks: {
                font: {
                  size: 12,
                  weight: 'bold',
                },
                color: colors.grey40,
                minRotation: settings.rotation,
                maxRotation: settings.rotation,
              },
            },

            y: {
              display: true,
              stacked: true,
              grid: { display: true, color: colors.grey10 },
              border: { display: false },
              max: settings.max,
              ticks: { stepSize: settings.stepSize, color: colors.grey40 },
            },
          },

          onHover: (e, chartElement) => {
            e.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
          },

          onClick: handleClick,
        }}
      />
    </Widget>
  );
}
