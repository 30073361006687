import React from 'react';
import Tag from './Tag';

export default function ClientRecordStatusTag({ value, ...props }) {
  if (!value) return null;

  return (
    <Tag label="Client Archived" {...props}>
      {{ active: 'No', archived: 'Yes' }[value]}
    </Tag>
  );
}
