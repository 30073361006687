import { useDocumentTitle } from '~/hooks';
import React from 'react';
import { Switch, useRouteMatch, Route, useHistory } from 'react-router-dom';
import ListPage from './ListPage';

import DetailPage from './DetailPage';

function ViewController({ member, setMember }) {
  useDocumentTitle('Expense Reports');
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const props = { member, setMember, reportsUrl: url };
  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <ListPage {...props} />
      </Route>
      <Route exact path={[`${path}/new`]}>
        <ListPage {...props} drawer="new" />
      </Route>
      <Route exact path={[`${path}/edit/:number`]}>
        <ListPage {...props} drawer="edit" />
      </Route>
      <Route path={[`${path}/:number`]}>
        <DetailPage {...props} onClose={() => history.push(url)} />
      </Route>
    </Switch>
  );
}
export default ViewController;
export { ViewController };
