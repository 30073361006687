import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const urlKey = 'referrer.url';
const utmSourceKey = 'referrer.utmSource';
const utmCampaignKey = 'referrer.utmCampaign';

const ReffererContext = React.createContext();

function ReferrerProvider({ children }) {
  const location = useLocation();
  const [url, setUrl] = useState(localStorage ? localStorage.getItem(urlKey) : null);
  const [utmSource, setUtmSource] = useState(localStorage ? localStorage.getItem(utmSourceKey) : null);
  const [utmCampaign, setUtmCampaign] = useState(localStorage ? localStorage.getItem(utmCampaignKey) : null);

  // Capturing/storing the `document.referrer` if it comes from a different domain
  useEffect(() => {
    if (!document.referrer) {
      return;
    }

    try {
      const referrerUrl = new URL(document.referrer);
      const currentUrl = new URL(window.location.href);
      if (currentUrl.hostname === referrerUrl.hostname) {
        return;
      }

      setUrl(document.referrer);

      if (localStorage) {
        localStorage.setItem(urlKey, document.referrer);
      }
    } catch (TypeError) {
      // Occurs if URL() is called with a malformed URL
      return;
    }
  }, []);

  // Capturing/storing any updates to `utm_` vars in the query string
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Capture `utm_source`
    if (params.has('utm_source')) {
      const newUtmSource = params.get('utm_source');
      if (newUtmSource.length > 0) {
        setUtmSource(newUtmSource);

        if (localStorage) {
          localStorage.setItem(utmSourceKey, newUtmSource);
        }
      }
    }

    // Capture `utm_campaign`
    if (params.has('utm_campaign')) {
      const newUtmCampaign = params.get('utm_campaign');
      if (newUtmCampaign.length > 0) {
        setUtmCampaign(newUtmCampaign);

        if (localStorage) {
          localStorage.setItem(utmCampaignKey, newUtmCampaign);
        }
      }
    }
  }, [location]);

  const value = {
    url,
    utmSource,
    utmCampaign,
  };
  return <ReffererContext.Provider value={value}>{children}</ReffererContext.Provider>;
}

function useReferrer() {
  return useContext(ReffererContext);
}

export { ReffererContext, useReferrer, ReferrerProvider };
