import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import { emptyStringToNull, mergeValues } from '~/utils';
import ProjectDataList from './ProjectDataList';
import * as Yup from 'yup';

export default function ProjectHealthReportForm({ id, projectId, onClose, onSaved, onDeleted }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null });
  const fetchData = useCallback(async () => {
    if (!id) {
      setQuery({ isReady: true, data: {} });
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).projects(projectId).healthReports(id).get();
      setQuery({ isReady: true, data });
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this project health report. Please try again.');
    }
  }, [workspace.id, id, projectId, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const isNew = !id;
  const title = isNew ? 'New Project Health Report' : 'Edit Project Health Report';

  useDocumentTitle(title);

  const confirmation = useConfirmation();
  const handleDelete = async () => {
    await confirmation.prompt((resolve) => (
      <DeleteConfirmation
        resolve={async (confirm) => {
          if (!confirm) {
            resolve(false);
            return;
          }

          await api.www.workspaces(workspace.id).projects(projectId).healthReports(id).delete();

          await onDeleted();
          await onClose();

          resolve(true);
        }}>
        Are you sure you want to delete this project health report?
      </DeleteConfirmation>
    ));
  };

  if (!query.isReady) return null;
  if (!query.data) return <ErrorPage.NotFound publicSite={false} />;

  const initialValues = mergeValues(
    {
      date: moment().format('YYYY-MM-DD'),
      notes: '',
      budgetHealthId: 'fair',
      budgetHealthNotes: '',
      scheduleHealthId: 'fair',
      scheduleHealthNotes: '',
      clientSatisfactionId: 'fair',
      clientSatisfactionNotes: '',
      teamSatisfactionId: 'fair',
      teamSatisfactionNotes: '',
    },
    query.data,
  );

  const validationSchema = Yup.object().shape({
    date: Yup.date().label('Date').nullable().required(),
  });

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => isNew && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull(values);
            const { data } = await api.www.workspaces(workspace.id).projects(projectId).healthReports(id).upsert(body);
            await onSaved(data);
            form.save();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}>
            {(formik) => {
              return (
                <Form>
                  <Form.Section title="Project Health">
                    <Form.Control>
                      <Field.DayPicker name="date" placeholder="Date" clearable={false} />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea name="notes" placeholder="General Commentary" />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect name="budgetHealthId" placeholder="Budget Health">
                        <option value="excellent">Excellent</option>
                        <option value="fair">Fair</option>
                        <option value="poor">Poor</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea name="budgetHealthNotes" placeholder="Budget Commentary" />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect name="scheduleHealthId" placeholder="Schedule Health">
                        <option value="excellent">Excellent</option>
                        <option value="fair">Fair</option>
                        <option value="poor">Poor</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea name="scheduleHealthNotes" placeholder="Schedule Commentary" />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect name="clientSatisfactionId" placeholder="Client Satisfaction">
                        <option value="excellent">Excellent</option>
                        <option value="fair">Fair</option>
                        <option value="poor">Poor</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea name="clientSatisfactionNotes" placeholder="Client Satisfaction Commentary" />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect name="teamSatisfactionId" placeholder="Team Satisfaction">
                        <option value="excellent">Excellent</option>
                        <option value="fair">Fair</option>
                        <option value="poor">Poor</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea name="teamSatisfactionNotes" placeholder="Team Satisfaction Commentary" />
                    </Form.Control>
                  </Form.Section>

                  {!isNew && (
                    <Form.Section
                      title="Project Metrics"
                      help="The project's metrics at the time that this Project Health Report was created.">
                      <ProjectDataList data={query.data} />
                    </Form.Section>
                  )}

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Drawer.Actions>
                    {!isNew && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
