import { Button, Buttons, Form, ModalCard, SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useSchemaForm } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import * as Yup from 'yup';
import { Field, ProjectSelect, RoleSelect, TaskSelect, TimeOffTypeSelect } from './fields';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

function WeekTimeEntry({ memberId, member, onClose, onComplete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [timeOffType, setTimeOffType] = useState();
  const [project, setProject] = useState();
  const [role, setRole] = useState();
  const [task, setTask] = useState();

  const initialValues = {
    typeId: 'project_time',
    timeOffTypeId: '',
    projectId: '',
    projectRoleId: '',
    projectTaskId: '',
  };

  const schema = Yup.object().shape({
    typeId: Yup.string().oneOf(['project_time', 'time_off']).required().label('Type'),
    timeOffTypeId: Yup.string()
      .when('typeId', (typeId, schema) => (typeId === 'time_off' ? schema.required() : schema))
      .label('Time Off Type'),
    projectId: Yup.string()
      .when('typeId', (typeId, schema) => (typeId === 'project_time' ? schema.required() : schema))
      .label('Project'),
    projectRoleId: Yup.string()
      .when(['typeId', '$project'], (typeId, project, schema) =>
        typeId === 'project_time' && project?.useRoles ? schema.required() : schema,
      )
      .label('Role'),
    projectTaskId: Yup.string()
      .label('Task')
      .when(['typeId', '$project'], (typeId, project, schema) =>
        typeId === 'project_time' && project.requireTimeEntryTask ? schema.required() : schema,
      ),
  });

  const { values, errors, setFieldValue, handleSubmit } = useSchemaForm({
    initialValues,
    schema,
    schemaContext: { project },
  });

  const { typeId, timeOffTypeId, projectId, projectRoleId, projectTaskId } = values;

  const fetchTimeOffType = useCallback(async () => {
    if (!timeOffTypeId) {
      setTimeOffType();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeOffTypes(timeOffTypeId).get();
      setTimeOffType(data);
    } catch (error) {
      setTimeOffType();
    }
  }, [api, timeOffTypeId, workspace]);

  const fetchProject = useCallback(async () => {
    if (!projectId) {
      setProject();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().projects(projectId).get();
      setProject(data);
    } catch (error) {
      setProject();
    }
  }, [api, projectId, workspace]);

  const fetchRole = useCallback(async () => {
    if (!projectRoleId) {
      setRole();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().roles(projectRoleId).get();
      setRole(data);
    } catch (error) {
      setRole();
    }
  }, [api, projectRoleId, workspace]);

  const fetchTask = useCallback(async () => {
    if (!projectTaskId) {
      setTask();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().tasks(projectTaskId).get();
      setTask(data);
    } catch (error) {
      setTask();
    }
  }, [api, projectTaskId, workspace]);

  useEffect(() => {
    fetchTimeOffType();
  }, [fetchTimeOffType]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const onSubmit = () => {
    if (onComplete) {
      onComplete({ typeId, timeOffType, project, role, task });
    }
  };

  return (
    <ModalCard title="Add Time Row" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalCard.Body>
          <Form.Control>
            <Field name="typeId" errors={errors}>
              <SingleSelect
                name="typeId"
                placeholder="Type"
                value={typeId}
                onChange={(event) => {
                  setFieldValue('typeId', event.target.value);

                  switch (event.target.value) {
                    case 'project_time':
                      setFieldValue('timeOffTypeId', '');
                      break;

                    case 'time_off':
                      setFieldValue('projectId', '');
                      setFieldValue('projectRoleId', '');
                      setFieldValue('projectTaskId', '');
                      break;
                  }
                }}>
                <option value="project_time">Project Time</option>
                <option value="time_off" disabled={!member.timeOffAllowed}>
                  Time Off
                </option>
              </SingleSelect>
            </Field>
          </Form.Control>
          {typeId === 'time_off' && (
            <Form.Control>
              <Field name="timeOffTypeId" errors={errors}>
                <TimeOffTypeSelect
                  name="timeOffTypeId"
                  placeholder="Time Off Type"
                  memberId={memberId}
                  value={timeOffTypeId}
                  onChange={(event) => setFieldValue('timeOffTypeId', event.target.value)}
                />
              </Field>
            </Form.Control>
          )}
          {typeId === 'project_time' && (
            <>
              <Form.Control>
                <Field name="projectId" errors={errors}>
                  <ProjectSelect
                    name="projectId"
                    placeholder="Project"
                    memberId={memberId}
                    value={projectId}
                    onChange={(event) => {
                      setFieldValue('projectId', event.target.value);
                      setFieldValue('projectRoleId', '');
                      setFieldValue('projectTaskId', '');
                    }}
                  />
                </Field>
              </Form.Control>
              {project && (
                <>
                  {project.useRoles && (
                    <Form.Control>
                      <Field name="projectRoleId" errors={errors}>
                        <RoleSelect
                          name="projectRoleId"
                          placeholder="Role"
                          projectId={projectId}
                          memberId={memberId}
                          value={projectRoleId}
                          onChange={(event) => setFieldValue('projectRoleId', event.target.value)}
                        />
                      </Field>
                    </Form.Control>
                  )}
                  {(project.taskCount > 0 || project.requireTimeEntryTask) && (
                    <Form.Control>
                      <Field name="projectTaskId" errors={errors}>
                        <TaskSelect
                          name="projectTaskId"
                          placeholder="Task"
                          projectId={projectId}
                          projectRoleId={projectRoleId}
                          memberId={memberId}
                          value={projectTaskId}
                          onChange={(event) => setFieldValue('projectTaskId', event.target.value)}
                        />
                      </Field>
                    </Form.Control>
                  )}
                </>
              )}
            </>
          )}
        </ModalCard.Body>
        <ModalCard.Footer>
          <Buttons align="right">
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <Button type="submit">Add</Button>
          </Buttons>
        </ModalCard.Footer>
      </form>
    </ModalCard>
  );
}

export default WeekTimeEntry;
