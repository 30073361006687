import { useDocumentTitle } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

export default function PipelineReportsPage() {
  useDocumentTitle('Pipeline Reports');

  const { url } = useRouteMatch();

  return (
    <LandingPage>
      <NavBox to={url.concat('/opportunity-detail')}>
        <NavBox.Title>Opportunity Detail</NavBox.Title>
        <NavBox.Description>
          Individual opportunities in the sales pipeline including the opportunity name, stage, amount, probability, and
          expected close date.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/opportunities-by-month')}>
        <NavBox.Title>Opportunities by Month</NavBox.Title>
        <NavBox.Description>
          A summary of the opportunities created per month and expected to close per month, including both gross and
          probability-weighted opportunity values.
        </NavBox.Description>
      </NavBox>
    </LandingPage>
  );
}
