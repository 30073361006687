import { Button, HelpTooltip, Icon } from '~/components';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Container = styled.div`
  border-radius: 10px;
  padding: 1.25rem;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  display: flex;
  flex-direction: column;

  ${({ stretched }) =>
    stretched &&
    css`
      flex: 1;
    `}
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `}

  animation: fade-in;
  animation-duration: 500ms;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const Preview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

const Title = styled.h4`
  text-align: center;
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  justify-self: center;
  flex: 1;

  + ${Content}, + ${Preview} {
    margin-top: 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Title} {
    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  + ${Content}, + ${Preview} {
    margin-top: 1.25rem;
  }
`;

function Share(props) {
  return (
    <Button isAnchor {...props}>
      <Icon icon="share-alt" />
    </Button>
  );
}

const WidgetContext = React.createContext();

function Widget({ loading, centered, ...props }) {
  return (
    <WidgetContext.Provider value={{ loading, centered }}>
      <Container {...props} />
    </WidgetContext.Provider>
  );
}

function WidgetContent(props) {
  const widget = useContext(WidgetContext);

  if (widget.loading) return null;
  return <Content {...props} centered={widget.centered} />;
}

function WidgetPreview(props) {
  const widget = useContext(WidgetContext);

  if (!widget.loading) return null;
  return <Preview {...props} centered={widget.centered} />;
}

Widget.Title = Title;
Widget.Content = WidgetContent;
Widget.Header = Header;
Widget.Help = HelpTooltip;
Widget.Share = Share;
Widget.Preview = WidgetPreview;

export default Widget;
