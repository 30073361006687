import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color};
  &.space-left {
    margin-left: 0.5em;
  }

  &.space-right {
    margin-right: 0.5em;
  }
`;

function Icon({ type = 'fas', icon, spaceLeft = false, color = null, spaceRight = false, className, ...props }) {
  const classes = classNames('icon', { 'space-left': spaceLeft, 'space-right': spaceRight }, className);
  return <StyledIcon color={color} icon={[type, icon]} className={classes} {...props} />;
}

export default Icon;
