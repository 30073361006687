import { useField } from '~/hooks';
import React from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function ProjectBillingTypeSelect({ disabled, ...props }) {
  return (
    <SingleSelect data-testid="billingTypeId" disabled={disabled} {...props}>
      <option value="tm">Time &amp; Materials</option>
      <option value="fixed">Fixed Fee</option>
      {/* Fixed recurring may eventually support other intervals (weekly, etc.), but currently it only supports monthly */}
      <option value="fixed_recurring">Fixed Monthly</option>
      <option value="non_billable">Non-Billable</option>
    </SingleSelect>
  );
}

function FieldProjectBillingTypeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectBillingTypeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectBillingTypeSelect;
export { FieldProjectBillingTypeSelect };
