import { Button } from '~/components';
import { rgba } from 'polished';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';

const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.white};
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const Popper = styled.div`
  min-width: 7.5rem;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 300;

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 200;
`;

const MenuItem = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${colors.black};
  text-align: left;
  position: relative;

  &:hover {
    background-color: ${colors.grey5};
    color: ${colors.black};
  }

  &:disabled {
    cursor: not-allowed;

    &&&:hover {
      background: none;
      font-weight: normal;
      color: ${colors.black};
    }
  }
`;

function Item(props) {
  return <MenuItem isAnchor {...props} />;
}

export default function ContextMenu({ referenceElement, left, children, onClose }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const virtualReference = useMemo(() => {
    const containerRect = referenceElement.getBoundingClientRect();

    const containerClientBoundingRect = {
      top: containerRect.top,
      height: containerRect.height,
      left,
      width: 0,
    };
    return {
      getBoundingClientRect() {
        return containerClientBoundingRect;
      },
    };
  }, [referenceElement, left]);

  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
    placement: 'auto',
    strategy: 'fixed',
  });

  return ReactDOM.createPortal(
    <>
      <Overlay onClick={() => onClose()} />
      <Popper ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div>{children}</div>

        <Arrow ref={setArrowElement} style={styles.arrow} />
      </Popper>
    </>,
    document.body,
  );
}

ContextMenu.Item = Item;
