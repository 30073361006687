import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from './Button';
import Buttons from './Buttons';
import CancelButton from './CancelButton';
import ModalCard from './ModalCard';
import styled from 'styled-components';
import { TooltipButton as DTooltipButton, RouteLink } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { colors } from '~/styles';
import Icon from './Icon';
import _, { uniqueId } from 'lodash';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Associations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
const TooltipButton = styled(DTooltipButton)`
  display: flex;
  width: 107.25px;
  align-self: flex-end;
  margin-top: 0.25rem;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(ModalCard.Body)`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  gap: 0.6rem;
`;

const ListItem = styled.li`
  list-style: initial;
  list-style-position: inside;
  padding-left: 0.6rem;
`;

const Separator = styled.span`
  padding: 0rem 0.3rem;
`;

function DeactivateMemberConfirmation({ memberId, workspaceId, resolve, title = 'Deactivate Member' }) {
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    projectAdmin: [],
    clientSalesRepresentative: [],
    projectSalesRepresentative: [],
    timeApproval: [],
    expenseApproval: [],
    clientOwner: [],
  });

  const fetchData = useCallback(async () => {
    const { data: associations } = await api.www.workspaces(workspaceId).members(memberId).getAssociations();
    setData(associations);
  }, [memberId, workspaceId, api.www]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async () => {
    setIsLoading(true);

    await resolve(true);
  };

  const renderItem = ({ type, project, client, ...dataItem }) => {
    switch (type) {
      case 'projectAdmin':
        return (
          <>
            <RouteLink to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}`} target={'_blank'}>
              {project.name}
            </RouteLink>
            <Separator>/</Separator>
            <RouteLink to={`/app/${workspace.key}/clients/${project.client.key}`} target={'_blank'}>
              {project.client.name}
            </RouteLink>
          </>
        );
      case 'projectSalesRepresentative':
      case 'timeApproval':
      case 'expenseApproval':
        return (
          <>
            <RouteLink to={`/app/${workspace.key}/projects/${client.key}/${dataItem?.key}`} target={'_blank'}>
              {dataItem.name}
            </RouteLink>
            <Separator>/</Separator>
            <RouteLink to={`/app/${workspace.key}/clients/${client.key}`} target={'_blank'}>
              {client.name}
            </RouteLink>
          </>
        );
      case 'client':
        return (
          <>
            <RouteLink to={`/app/${workspace.key}/clients/${dataItem.key}`} target={'_blank'}>
              {dataItem.name}
            </RouteLink>
          </>
        );
    }
  };

  const formatDeactivationData = (data) => {
    const body = [
      { title: `${data.name} is a Project Admin on these projects`, data: data.projectAdmin },
      { title: `${data.name} is a Sales Representative on these clients`, data: data.clientSalesRepresentative },
      { title: `${data.name} is a Sales Representative on these projects`, data: data.projectSalesRepresentative },
      { title: `${data.name} is a named time approver on these projects`, data: data.timeApproval },
      { title: `${data.name} is a named expense approver on these projects`, data: data.expenseApproval },
      { title: `${data.name} is a Client Relationship Owner on these clients`, data: data.clientOwner },
    ];

    body.toString = () => {
      let str = ``;
      body.forEach(({ title, data }) => {
        if (data.length > 0) {
          str += data.length ? `\n${title}:\n` : '';
          data.forEach((item) => {
            switch (item.type) {
              case 'projectAdmin':
                str += ` - ${item.project.name} | ${item.project.client.name}\n`;
                break;
              case 'projectSalesRepresentative':
              case 'timeApproval':
              case 'expenseApproval':
                str += ` - ${item.name} | ${item.client.name}\n`;
                break;
              case 'client':
                str += ` - ${item.name}\n`;
            }
          });
        }
      });

      return str;
    };

    body.isEmpty = body.map((json) => _.isEmpty(json.data)).every((bool) => bool);

    return body;
  };

  const dataIsNotEmpty = useMemo(
    () =>
      [
        data.projectAdmin,
        data.clientSalesRepresentative,
        data.projectSalesRepresentative,
        data.timeApproval,
        data.expenseApproval,
        data.clientOwner,
      ].some((d) => d.length > 0),
    [data],
  );

  const handleClipboardCopy = () => {
    const deactivationData = formatDeactivationData(data).toString();
    navigator.clipboard.writeText(deactivationData);
    toast.success('Member associations copied to clipboard.');
  };

  return (
    <ModalCard title={title} onClose={() => resolve(false)}>
      <ModalBody>
        Are you sure you want to deactivate this member?
        {dataIsNotEmpty ? (
          <Body>
            {formatDeactivationData(data).map((item) =>
              item?.data.length ? (
                <Associations key={item.title}>
                  {item.title}:
                  <ul>
                    {item?.data.map((data) => (
                      <ListItem key={uniqueId()}>{renderItem(data)}</ListItem>
                    ))}
                  </ul>
                </Associations>
              ) : null,
            )}
          </Body>
        ) : null}
      </ModalBody>

      <ModalCard.Footer>
        <ButtonsContainer>
          {dataIsNotEmpty ? (
            <TooltipButton onClick={handleClipboardCopy} tooltip="Copy to clipboard">
              <Icon color={colors.white} icon="clipboard" />
            </TooltipButton>
          ) : null}
          <Buttons align="right">
            <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
            <Button isLoading={isLoading} onClick={handleSubmit}>
              OK
            </Button>
          </Buttons>
        </ButtonsContainer>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DeactivateMemberConfirmation;
