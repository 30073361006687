import { Table, HelpMessage } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { PeriodsFooterRow, SettingsSection } from '../components';
import UtilizationTargetPeriodRow from './UtilizationTargetPeriodRow';

const initialState = { isReady: false, data: null };
const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data, editIndex: null }),
  edit: (editIndex) => ({ editIndex }),
};

function UtilizationTargetPeriods({ member }) {
  useDocumentTitle(`${member.name} Target`);

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, data, editIndex }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).utilizationTargetPeriods().get();

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, member.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage publicSite={false} />;
  return (
    <>
      <HelpMessage spaceTop spaceBottom>
        To better help you manage workforce productivity, Ruddr needs a billable utilization target for each workspace
        member. Billable utilization is derived by dividing the billable hours worked in a time period by the available
        hours in that same time period.
      </HelpMessage>
      <SettingsSection>
        <Table>
          <Table.BoxHeader>
            <Table.Column>Date Range</Table.Column>
            <Table.Column align="center" width="19rem">
              Billable Utilization Target
            </Table.Column>
            <Table.Column align="right">Days in Period</Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {data.map((item, index) => (
              <UtilizationTargetPeriodRow
                key={item.id}
                period={item}
                periods={data}
                member={member}
                disableActions={editIndex !== null}
                isEditing={editIndex === index}
                onEdit={() => actions.edit(index)}
                onCancel={() => actions.edit(null)}
                onSaved={fetchData}
                onDeleted={fetchData}
              />
            ))}
            <UtilizationTargetPeriodRow
              period={{}}
              periods={data}
              member={member}
              isEditing={editIndex === -1}
              disableActions={editIndex !== null}
              onEdit={() => actions.edit(-1)}
              onCancel={() => actions.edit(null)}
              onSaved={fetchData}
            />
            <PeriodsFooterRow periods={data} member={member} label="billable utilization target" />
          </Table.Body>
        </Table>
      </SettingsSection>
    </>
  );
}

export default UtilizationTargetPeriods;
