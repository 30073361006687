import React from 'react';
import styled, { css } from 'styled-components';

const LevelItem = styled.div`
  display: flex;
  flex-direction: column;
  align-self: ${({ align = 'center' }) => align};
  align-items: ${({ alignItems = 'stretch' }) => alignItems};
  margin: ${({ margin = 'unset' }) => margin};
  flex: ${({ flex = '1' }) => flex};

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ width }) =>
    width &&
    css`
      flex: 0 ${width};
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      border: solid 1px ${highlight};
    `}

  ${({ right }) => {
    return (
      right &&
      css`
        margin-left: auto;

        ${Level} {
          justify-content: flex-end;

          ${LevelItem} {
            align-items: flex-end;
          }
        }
      `
    );
  }}


    ${({ narrow }) =>
    narrow &&
    css`
      flex: 0;
    `}
`;

// eslint-disable-next-line no-unused-vars
const Level = styled(({ isVisible = true, right, ...props }) => (isVisible ? <div {...props} /> : null))`
  display: flex;
  flex-direction: row;

  ${({ tall }) =>
    tall &&
    css`
      height: 100%;
    `};

  ${({ top }) =>
    top &&
    css`
      align-items: flex-start;
    `}

  ${({ right }) =>
    right &&
    css`
      margin-left: auto;
      justify-content: flex-end;
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${LevelItem} {
    padding-left: ${({ gap = '0.75rem' }) => gap};
    padding-right: ${({ gap = '0.75rem' }) => gap};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

Level.Item = LevelItem;

export default Level;
