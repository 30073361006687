import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;

  > .icon {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;

function PageLoader(props) {
  return (
    <Container {...props}>
      <Icon icon="spinner" spin={true} />
      <p>Loading...</p>
    </Container>
  );
}

export default PageLoader;
