import _ from 'lodash';
import projectBillingTypes from '~/lookups/project-billing-types';
import React from 'react';
import Tag from './Tag';

export default function ProjectBillingTypeTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Project Billing Type" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? projectBillingTypes[v].name)
        .join('; ')}
    </Tag>
  );
}
