import { Percent, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';

const RealizationRate = styled.div`
  font-size: 3.5rem;
`;

const Preview = styled.div`
  min-height: 14rem;
`;

const Footer = styled.div`
  margin-top: 1.5rem;
  border-top: 1px solid ${colors.grey10};
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0.75rem;
  position: relative;
`;

const Target = styled.div`
  position: absolute;
  top: -1rem;
  border-radius: 1rem;
  min-width: 9.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-align: center;
  color: ${colors.grey75};
  background-color: ${colors.grey10};

  ${({ status }) =>
    ({
      greener: css`
        color: ${colors.success};
        background-color: ${colors.success10};
      `,
      green: css`
        color: ${colors.success};
        background-color: ${colors.success10};
      `,
      yellow: css`
        color: ${colors.warning};
        background-color: ${colors.warning10};
      `,
      red: css`
        color: ${colors.danger};
        background-color: ${colors.danger10};
      `,
    })[status]}
`;

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function ProjectRealizationRateWidget({ project, period }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .getProjectRealizationRateKPI({ date: period.start ?? undefined });

    actions.ready({ data });
    // Intentionally passing the full "project" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, project, period, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!isReady} style={{ height: '18.75rem' }}>
      <Widget.Header>
        <Widget.Help message="Realization rate is calculated by dividing project billable hours by total hours" />
        <Widget.Title>Realization Rate</Widget.Title>
      </Widget.Header>
      <Widget.Preview>
        <Preview />
      </Widget.Preview>
      <Widget.Content>
        <RealizationRate>
          <Percent value={data?.actual} minimumFractionDigits={0} maximumFractionDigits={1} />
        </RealizationRate>
      </Widget.Content>
      {data?.budget != null && (
        <Footer>
          <Target status={data.status}>
            <BudgetLabel data={data} />{' '}
            <Percent value={data.budget} minimumFractionDigits={0} maximumFractionDigits={1} />
          </Target>
        </Footer>
      )}
    </Widget>
  );
}

function BudgetLabel({ data }) {
  switch (data.status) {
    case 'greener':
      return 'Above budget of';

    case 'green':
      return 'On budget of';

    case 'yellow':
    case 'red':
      return 'Below budget of';

    default:
      return 'Budget of';
  }
}

export default ProjectRealizationRateWidget;
