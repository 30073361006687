import React from 'react';
import Field from './Field';
import Input from './Input';
import TextInput from './TextInput';

function SearchInput(props) {
  return (
    <Field.Control>
      <TextInput component={Input} wait={300} name="q" type="search" {...props} />
    </Field.Control>
  );
}
export default SearchInput;
