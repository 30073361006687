import { Button } from '~/components';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import theme from '../theme';

export const SidebarContainer = styled.div`
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Styles */
  margin-right: 0.25rem;
  box-shadow: 0.25rem 0.25rem 0rem ${theme.line.strong};
  position: relative;
`;

export const SidebarContent = styled.div`
  /* Layout */
  flex: 1;
  display: flex;
  flex-direction: column;

  /* Styles */
  background: ${theme.sidebar.background};
  border-radius: 5px 0 0 5px;
`;

export const Actions = styled.div`
  flex: 0;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-size: 0.75rem;
`;

export const HeaderContent = styled.div`
  background: ${theme.sidebar.total.background};
  flex: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 0.5rem;
  font-weight: ${weights.bold};
  color: ${theme.sidebar.total.color};
  border-radius: 5px 0 0 5px;
`;

export const Header = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;

  /* Styles */
  border-top: 4px solid ${theme.line.strong};
  background: ${theme.line.strong};

  &:hover {
    ${HeaderContent} {
      filter: brightness(97%);
    }
  }
`;

export const Box = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  background: ${colors.white};
`;

export const Empty = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  width: 100%;

  /* Styles */
  border-top: 4px solid ${theme.line.strong};
  background: ${theme.line.strong};

  ${Box} {
    border-top-left-radius: 5px;
  }
`;

export const Group = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;

  /* Styles */
  border-top: 4px solid ${theme.line.strong};
  background: ${theme.line.strong};

  &:last-child {
    box-shadow: 0rem 0.25rem 0rem ${theme.line.strong};
  }

  ${Box} {
    border-top-left-radius: 5px;
  }

  ${({ state }) =>
    state !== 'expanded' &&
    css`
      ${Box} {
        border-bottom-left-radius: 5px;
      }
    `}

  &:hover {
    ${Box} {
      filter: brightness(98%);
    }
  }
`;

export const Row = styled.div`
  /* Layout */
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;

  /* Styles */
  border-top: 1px solid ${theme.line.strong};
  background: ${theme.line.strong};

  &:last-child {
    box-shadow: 0rem 0.25rem 0rem ${theme.line.strong};
  }

  ${({ last }) =>
    last &&
    css`
      ${Box} {
        border-bottom-left-radius: 5px;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  flex: 1;
`;

export const Info = styled.div`
  width: 100%;
  overflow: hidden;
  padding-right: 0.25rem;
`;

export const NoResultsLabel = styled.div`
  font-size: 0.875rem;
  color: ${colors.grey40};
  padding-top: 1rem;
`;

export const Label = styled.div`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Byline = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${colors.grey40};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Action = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;

  height: 100%;
`;

export const AccordionButton = styled(Button)`
  font-size: 1rem;
  color: ${colors.grey55};
  opacity: 0.5;
  height: 100%;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

export const Resource = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Assignment = styled.div`
  overflow: hidden;
  flex: 1;

  ${({ tentative }) =>
    tentative &&
    css`
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.25;
        height: 100%;
        width: 100%;
        background: repeating-linear-gradient(
          45deg,
          ${colors.grey10},
          ${colors.grey10} 5px,
          ${colors.white} 5px,
          ${colors.white} 10px
        );
        pointer-events: none;
      }
    `}
`;
