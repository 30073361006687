import { useWorkspace } from '~/contexts';
import { useMemo } from 'react';

export default function useFeatures() {
  const { workspace } = useWorkspace();

  const features = useMemo(() => {
    return workspace.features;
  }, [workspace]);

  return features;
}
