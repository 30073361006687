import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

export default function useVirtualCells({ cells, parentRef, rows, start, end, unit, dateWidth, paddingStart }) {
  const columns = useMemo(() => {
    const periodCount = moment(end).diff(start, unit) + 1;

    const columns = [];
    for (let index = 0; index < periodCount; index++) {
      const date = moment(start).add(index, unit);

      const width = {
        day: dateWidth,
        week: dateWidth * 7,
        month: dateWidth * date.daysInMonth(),
      }[unit];

      columns.push(width);
    }

    return columns;
  }, [start, end, unit, dateWidth]);

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columns.length,
    getScrollElement: useCallback(() => parentRef.current, [parentRef]),
    estimateSize: useCallback((index) => columns[index], [columns]),
    paddingStart,
    overscan: 0,
  });

  const virtualColumns = columnVirtualizer.getVirtualItems();

  const virtualCells = useMemo(() => {
    if (rows.length === 0 || virtualColumns.length === 0) return [];

    const indexes = {
      row: {
        start: rows[0].meta.index,
        end: rows[rows.length - 1].meta.index,
      },

      column: {
        start: virtualColumns[0].index,
        end: virtualColumns[virtualColumns.length - 1].index,
      },
    };

    const virtualCells = cells.filter(
      (cell) =>
        cell.meta.cellIndex >= indexes.column.start &&
        cell.meta.cellIndex <= indexes.column.end &&
        cell.meta.rowIndex >= indexes.row.start &&
        cell.meta.rowIndex <= indexes.row.end,
    );

    return virtualCells;
  }, [cells, rows, virtualColumns]);

  return virtualCells;
}
