import { useWorkspace } from '~/contexts';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Header = styled.div`
  display: flex;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-color: ${colors.grey5};
  border-bottom: 5px;
  border-radius: 5px;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: ${({ hasMessage }) => (hasMessage ? '6.25rem' : '3.75rem')};
      z-index: 1;
    `}
`;

function TableBoxHeader({ children, ...props }) {
  const { hasMessage } = useWorkspace();

  const columns = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));
  return (
    <Header {...props} hasMessage={hasMessage}>
      {columns}
    </Header>
  );
}

export { Header };
export default TableBoxHeader;
