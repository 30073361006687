import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import logo from '~/assets/logo.svg';
import { Button, Field, Form, FormMessage, Icon, TextInput } from '~/components';
import { useApi, useSession, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import { colors, devices, weights } from '~/styles';
import { slugValidator } from '~/utils/validators';
import { Footer, Header } from '../components';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.75rem;
  border-bottom: solid 1px ${colors.grey10};

  @media ${devices.mobile} {
    height: 4.75rem;
  }
`;

const Logo = styled.img`
  display: block;
  height: 2.5rem;

  @media ${devices.mobile} {
    height: 1.625rem;
  }
`;

const Loading = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
`;

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const HelpButton = styled(Button)`
  color: white;
  text-decoration: underline;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
`;

const Byline = styled.div`
  padding-top: 0.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
  text-align: center;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  margin-top: 0.5rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto 1rem;
  }
`;

export default function Login() {
  useDocumentTitle('Login');

  const { isReady, impersonatedMemberId } = useSession();
  const history = useHistory();

  useEffect(() => {
    if (impersonatedMemberId) {
      history.replace('/admin');
    }
  }, [history, impersonatedMemberId]);

  const toast = useToast();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      switch (error) {
        case 'idp':
          toast.error('There was an error trying to authenticate with your identity provider. Please try again.');
          break;

        default:
          toast.error('There was an error trying to authenticate. Please try again.');
          break;
      }

      history.replace('/login');
    }
  }, [location.search, toast, history]);

  // We need a minimal version of this page to get around Apple app submissions
  const minimal = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('source') && params.get('source').includes('app')) {
      return true;
    }
    return false;
  }, []);

  return (
    <>
      {!minimal ? (
        <Header />
      ) : (
        <LogoContainer>
          <Link to="/">
            <Logo src={logo} alt="Ruddr" />
          </Link>
        </LogoContainer>
      )}
      {!isReady ? (
        <Loading>
          <Icon icon="spinner" spin={true} />
        </Loading>
      ) : (
        <LoginForm />
      )}
      {!minimal && <Footer />}
    </>
  );
}

const initialState = { isSubmitting: false, status: null, message: null };
const handlers = {
  submit: () => ({ isSubmitting: true, status: null, message: null }),
  done: () => ({ isSubmitting: false, status: null, message: null }),
  error: ({ status = 'error', message }) => ({
    isSubmitting: false,
    status,
    message,
  }),
  passwordResetSent: () => ({
    isSubmitting: false,
    status: 'password_reset_sent',
  }),
  emailVerificationSent: () => ({
    isSubmitting: false,
    status: 'email_verification_sent',
  }),
};

function LoginForm() {
  const api = useApi();
  const { login, loginMfa, loginRecoveryCode } = useSession();
  const [{ isSubmitting, status, message }, actions] = useActions(handlers, initialState);

  const location = useLocation();

  const [isReady, setIsReady] = useState(false);
  const [authProviders, setAuthProviders] = useState(null);
  const [email, setEmail] = useState(null);
  const [workspaceKey, setWorkspaceKey] = useState(null);
  const [multipleWorkspaces, setMultipleWorkspaces] = useState(false);
  const [challengeId, setChallengeId] = useState();
  const [challengeType, setChallengeType] = useState();
  const [smsCodeError, setSmsCodeError] = useState(false);
  const [useRecoveryCode, setUseRecoveryCode] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(location.search);
      const email = params.get('email');
      const workspaceKey = params.get('workspace');

      if (email) {
        try {
          const {
            data: { authProviders },
          } = await api.www.credentials({ email, workspaceKey });
          setEmail(email);
          setWorkspaceKey(workspaceKey);
          setAuthProviders(authProviders);
        } catch ({ status }) {
          if (status === 409) {
            setEmail(email);
            setMultipleWorkspaces(true);
          }
        } finally {
          setIsReady(true);
        }
      } else {
        setIsReady(true);
      }
    })();
  }, [location.search, api, actions]);

  async function handleSubmitEmail({ email }) {
    try {
      actions.submit();
      const credentials = { email };
      if (workspaceKey) credentials.workspaceKey = workspaceKey;
      const {
        data: { authProviders },
      } = await api.www.credentials(credentials);
      setEmail(email);
      setAuthProviders(authProviders);
      actions.done();
    } catch ({ status, response }) {
      switch (status) {
        case 404:
          actions.error({ status: 'not_found' });
          break;
        case 409:
          setEmail(email);
          setMultipleWorkspaces(true);
          actions.done();
          break;
        default:
          actions.error({ status: response?.data?.code, message: response?.data?.message });
      }
    }
  }

  async function handleSubmitWorkspace({ workspaceKey }) {
    try {
      actions.submit();
      const {
        data: { authProviders },
      } = await api.www.credentials({ email, workspaceKey });
      setWorkspaceKey(workspaceKey);
      setAuthProviders(authProviders);
      actions.done();
    } catch ({ status, response }) {
      switch (status) {
        case 404:
          actions.error({ status: 'not_found' });
          break;
        default:
          actions.error({ status: response?.data?.code, message: response?.data?.message });
      }
    }
  }

  const { navigateWorkspace } = useWorkspace();

  const goToWorkspace = (workspaceKey) => {
    const params = new URLSearchParams(location.search);
    const url = params.get('redirect');
    if (url) {
      history.push(decodeURIComponent(url));
    } else {
      navigateWorkspace(workspaceKey);
    }
  };

  async function handleSubmitPassword({ password }) {
    try {
      actions.submit();
      const credentials = { email, password };
      if (workspaceKey) credentials.workspaceKey = workspaceKey;
      const memberSession = await login(credentials);
      goToWorkspace(memberSession.workspaceKey);
    } catch ({ status, response }) {
      const code = response?.data?.code;
      if (code === 'mfa_required') {
        const id = response?.data?.challengeId;
        const type = response?.data?.challengeType;
        const codeSent = response?.data?.codeSent;
        setChallengeId(id);
        setChallengeType(type);
        if (type === 'sms' && !codeSent) {
          setSmsCodeError(true);
          setUseRecoveryCode(true);
        }
        actions.done();
      } else {
        actions.error({ status: code, message: response?.data?.message });
      }
    }
  }

  async function handleSubmitMfa(values) {
    try {
      actions.submit();
      const code = values.code.replaceAll(/[-.\s]+/g, '');
      const memberSession = await loginMfa({ challengeId, code });
      goToWorkspace(memberSession.workspaceKey);
    } catch ({ response }) {
      setChallengeId();
      setChallengeType();
      actions.error({ status: response?.data?.code, message: response?.data?.message });
    }
  }

  async function handleSubmitRecoveryCode(values) {
    try {
      actions.submit();
      const code = values.code.replaceAll(/[-.\s]+/g, '');
      const memberSession = await loginRecoveryCode({ challengeId, code });
      goToWorkspace(memberSession.workspaceKey);
    } catch ({ response }) {
      setChallengeId();
      setChallengeType();
      setUseRecoveryCode(false);
      actions.error({ status: response?.data?.code, message: response?.data?.message });
    }
  }

  async function handleSendVerificationEmail({ email }) {
    await api.www.sendVerificationEmail({ email });
    actions.emailVerificationSent();
  }

  const authProvider = useMemo(() => {
    let authProvider = null;
    let selectedProvider = new URLSearchParams(location.search).get('auth');
    if (selectedProvider) authProvider = _(authProviders).find((p) => p.key === selectedProvider);
    if (!authProvider) authProvider = _(authProviders).find((p) => p.isDefault);
    return authProvider;
  }, [location.search, authProviders]);

  async function handleSubmitAuthProvider() {
    try {
      actions.submit();
      switch (authProvider.method) {
        case 'saml': {
          const {
            data: { authorizeUrl },
          } = await api.www.authProviders(authProvider.id).authorizeUrl();
          window.location = authorizeUrl;
          break;
        }
        default:
          actions.error({ status: 'unknown' });
          break;
      }
    } catch ({ response }) {
      actions.error({ status: response?.data?.code, message: response?.data?.message });
    }
  }

  function handleStartOver() {
    setEmail(null);
    setWorkspaceKey(null);
    setAuthProviders(null);
    setMultipleWorkspaces(false);
    setChallengeId(null);
    setChallengeType(null);
    setUseRecoveryCode(false);
    actions.done();
  }

  const view = useMemo(() => {
    if (!email) return 'email';
    if (multipleWorkspaces && !workspaceKey) return 'workspace';
    if (challengeId && useRecoveryCode) return 'recovery';
    if (challengeId) return 'mfa';
    if (authProvider) {
      if (authProvider.method === 'password') return 'password';
      return 'authProvider';
    }
    return 'unknown';
  }, [email, challengeId, useRecoveryCode, multipleWorkspaces, workspaceKey, authProvider]);

  if (!isReady)
    return (
      <Loading>
        <Icon icon="spinner" spin={true} />
      </Loading>
    );

  return (
    <Container>
      {{
        email: () => <EmailStep onSubmit={handleSubmitEmail} {...{ isSubmitting, status, message }} />,
        workspace: () => (
          <WorkspaceStep
            email={email}
            onSubmit={handleSubmitWorkspace}
            onStartOver={handleStartOver}
            {...{ isSubmitting, status, message }}
          />
        ),
        password: () => (
          <PasswordStep
            email={email}
            workspaceKey={workspaceKey}
            onSubmit={handleSubmitPassword}
            onStartOver={handleStartOver}
            onSendVerificationEmail={handleSendVerificationEmail}
            {...{ isSubmitting, status, message }}
          />
        ),
        mfa: () => (
          <MFAForm
            challengeType={challengeType}
            onSubmit={handleSubmitMfa}
            onUseRecoveryCode={() => setUseRecoveryCode(true)}
            {...{ isSubmitting, status, message }}
          />
        ),
        recovery: () => (
          <RecoveryCodeForm
            smsCodeError={smsCodeError}
            onSubmit={handleSubmitRecoveryCode}
            {...{ isSubmitting, status, message }}
          />
        ),
        authProvider: () => (
          <AuthProviderStep
            authProvider={authProvider}
            email={email}
            workspaceKey={workspaceKey}
            status={status}
            message={message}
            onSubmit={handleSubmitAuthProvider}
            onStartOver={handleStartOver}
          />
        ),
        unknown: () => <UnknownAuth email={email} workspaceKey={workspaceKey} onStartOver={handleStartOver} />,
      }[view]()}
    </Container>
  );
}

function EmailStep({ status, message, isSubmitting, onSubmit }) {
  const params = new URLSearchParams(location.search);
  const email = params.get('email') ?? '';
  return (
    <Box data-testid="login_form">
      <Title>Login</Title>
      <Byline>Enter your email address.</Byline>
      <Formik
        initialValues={{ email }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          email: Yup.string().label('Email').email().required(),
        })}>
        {() => (
          <Form>
            <Form.Control>
              <Field.Text autoFocus name="email" placeholder="Email" type="email" />
            </Form.Control>
            {(status &&
              {
                not_found: <FormMessage.Error spaceTop>Ruddr account not found.</FormMessage.Error>,
                error: <Error message={message} />,
              }[status]) ||
              (status && <Error message={message} />)}
            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Next
              </Button>
            </FormAction>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function WorkspaceStep({ email, status, message, isSubmitting, onSubmit, onStartOver }) {
  const params = new URLSearchParams(location.search);
  const workspaceKey = params.get('workspace') ?? '';
  return (
    <Box data-testid="login_form">
      <Title>Login</Title>
      <Byline>Enter your unique workspace ID.</Byline>
      <Formik
        initialValues={{ workspaceKey }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          workspaceKey: Yup.string()
            .label('Workspace URL ID')
            .max(255)
            .matches(slugValidator.expression, { message: slugValidator.message })
            .required(),
        })}>
        {() => (
          <Form>
            <Form.Control>
              <TextInput placeholder="Email" value={email} disabled />
            </Form.Control>

            <Form.Control>
              <Field.Text autoFocus name="workspaceKey" placeholder="Workspace URL ID" />
            </Form.Control>

            <div style={{ fontSize: '.875rem' }}>
              <Link to={`/get-workspace-links?${new URLSearchParams({ email }).toString()}`}>
                Forgot your workspace's URL ID?
              </Link>
            </div>

            {(status &&
              {
                not_found: <FormMessage.Error spaceTop>Ruddr workspace not found.</FormMessage.Error>,
                error: <Error message={message} />,
              }[status]) ||
              (status && <Error message={message} />)}

            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Next
              </Button>

              <div style={{ fontSize: '.875rem' }}>
                <Button isAnchor onClick={onStartOver}>
                  Made a mistake? Start over.
                </Button>
              </div>
            </FormAction>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function PasswordStep({
  email,
  workspaceKey,
  status,
  message,
  isSubmitting,
  onSubmit,
  onSendVerificationEmail,
  onStartOver,
}) {
  const params = new URLSearchParams({ email });
  if (workspaceKey) params.set('workspace', workspaceKey);

  return (
    <Box data-testid="login_form">
      <Title>Login</Title>
      <Byline>Enter your password.</Byline>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          password: Yup.string().label('Password').required(),
        })}>
        {({ values }) => (
          <Form>
            <Form.Control>
              <TextInput placeholder="Email" value={email} disabled />
            </Form.Control>
            {workspaceKey && (
              <Form.Control>
                <TextInput placeholder="Workspace URL ID" value={workspaceKey} disabled />
              </Form.Control>
            )}
            <Form.Control>
              <Field.Text autoFocus name="password" placeholder="Password" type="password" />
            </Form.Control>

            <div style={{ fontSize: '.875rem' }}>
              <Link to={`/password-reset?${params.toString()}`}>Forgot password?</Link>
            </div>

            {(status &&
              {
                invalid_credentials: <FormMessage.Error spaceTop>Invalid email or password.</FormMessage.Error>,
                email_not_verified: (
                  <EmailNotVerified onSendVerificationEmail={() => onSendVerificationEmail(values)} />
                ),
                email_verification_sent: (
                  <FormMessage variant="success" spaceTop>
                    Email verification sent. Please check your email for instructions.
                  </FormMessage>
                ),
                password_reset_sent: (
                  <FormMessage variant="success" spaceTop>
                    Password reset email sent. Please check your email for instructions.
                  </FormMessage>
                ),
                error: <Error message={message} />,
              }[status]) ||
              (status && <Error message={message} />)}
            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Login
              </Button>

              <div style={{ fontSize: '.875rem' }}>
                <Button isAnchor onClick={onStartOver}>
                  Made a mistake? Start over.
                </Button>
              </div>
            </FormAction>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function AuthProviderStep({ authProvider, email, workspaceKey, isSubmitting, status, message, onSubmit, onStartOver }) {
  return (
    <Box>
      <Title>Login</Title>
      <Byline>Use your {authProvider.name} credentials.</Byline>

      <div>
        <Form.Control>
          <TextInput placeholder="Email" value={email} disabled />
        </Form.Control>
        {workspaceKey && (
          <Form.Control>
            <TextInput placeholder="Workspace URL ID" value={workspaceKey} disabled />
          </Form.Control>
        )}

        {(status &&
          {
            error: <Error message={message} />,
          }[status]) ||
          (status && <Error message={message} />)}

        <FormAction>
          <Button isLoading={isSubmitting} onClick={onSubmit}>
            Login with {authProvider.name}
          </Button>
          <div style={{ fontSize: '.875rem' }}>
            <Button isAnchor onClick={onStartOver}>
              Made a mistake? Start over.
            </Button>
          </div>
        </FormAction>
      </div>
    </Box>
  );
}

function UnknownAuth({ email, workspaceKey, onStartOver }) {
  return (
    <Box>
      <Title>Login</Title>
      <Byline>Invalid authentication method.</Byline>

      <div>
        <Form.Control>
          <TextInput placeholder="Email" value={email} disabled />
        </Form.Control>
        {workspaceKey && (
          <Form.Control>
            <TextInput placeholder="Workspace URL ID" value={workspaceKey} disabled />
          </Form.Control>
        )}

        <Error
          message={
            <>
              The authentication method for these credentials is not supported by this application version. Please
              refresh the page and try again.
            </>
          }
        />

        <FormAction>
          <div style={{ fontSize: '.875rem' }}>
            <Button isAnchor onClick={onStartOver}>
              Made a mistake? Start over.
            </Button>
          </div>
        </FormAction>
      </div>
    </Box>
  );
}

function MFAForm({ challengeType, status, message, isSubmitting, onSubmit, onUseRecoveryCode }) {
  return (
    <Box>
      <Title>Two-Factor Authentication</Title>
      <Byline>Authentication code</Byline>
      <Formik
        initialValues={{ code: '' }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          code: Yup.string().label('Code').required(),
        })}>
        <Form>
          <Form.Control>
            <Field.Text autoFocus name="code" placeholder="6-digit code" />
          </Form.Control>
          {challengeType === 'app' ? (
            <Description>
              Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code.
            </Description>
          ) : (
            <Description>
              We just sent you a message via SMS with your authentication code. Enter the code in the form above to
              verify your identity.
            </Description>
          )}
          {status && <Error message={message || 'There was a problem verifying your 2FA code.'} />}
          <FormAction>
            <Button type="submit" isLoading={isSubmitting}>
              Verify
            </Button>
            <Button isAnchor onClick={onUseRecoveryCode}>
              Use a recovery code
            </Button>
          </FormAction>
        </Form>
      </Formik>
    </Box>
  );
}

function RecoveryCodeForm({ smsCodeError, status, message, isSubmitting, onSubmit }) {
  return (
    <Box>
      <Title>Two-Factor Recovery</Title>
      <Byline>Recovery code</Byline>
      <Formik
        initialValues={{ code: '' }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          code: Yup.string().label('Code').required(),
        })}>
        <Form>
          {smsCodeError && (
            <FormMessage.Error>
              An error has occurred sending your 2FA SMS authentication code. Please try again later or use a recovery
              code to login.
            </FormMessage.Error>
          )}
          <Form.Control>
            <Field.Text autoFocus name="code" placeholder="Code" />
          </Form.Control>
          <Description>
            If you are unable to access your mobile device, enter one of your recovery codes to verify your identity.
          </Description>
          {status && <Error message={message || 'There was a problem verifying your recovery code.'} />}
          <FormAction>
            <Button type="submit" isLoading={isSubmitting}>
              Verify
            </Button>
          </FormAction>
        </Form>
      </Formik>
    </Box>
  );
}

function EmailNotVerified({ onSendVerificationEmail }) {
  return (
    <FormMessage.Error spaceTop>
      Your email is not verified. Click{' '}
      <HelpButton isAnchor onClick={onSendVerificationEmail}>
        here
      </HelpButton>{' '}
      to re-send the verification link.
    </FormMessage.Error>
  );
}

function Error({ message }) {
  return <FormMessage.Error spaceTop>{message}</FormMessage.Error>;
}
