import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { ResponsiveContainer } from '../../components';

const Container = styled(ResponsiveContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const LoadingIcon = styled(Icon)`
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  font-size: 2rem;
`;

export default function DeviceLoginTokenVerified() {
  useDocumentTitle('Login');

  return (
    <Container>
      <Title>Login</Title>
      <Byline>We're logging you in, please wait.</Byline>
      <LoadingIcon icon="spinner" spin />
    </Container>
  );
}
