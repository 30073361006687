import React, { useState } from 'react';
import styled from 'styled-components';

import { useDocumentTitle } from '~/hooks';
import { Header, Page } from '../components';
import { SingleSelect } from '~/components';
import Integrations from './Integrations';
import Connections from './Connections';

const listType = {
  INTEGRATIONS: 'integrations',
  CONNECTIONS: 'connections',
};

const Filters = styled.div`
  width: 25%;
  margin-bottom: 1.5rem;
`;

function XeroList({ list }) {
  switch (list) {
    case listType.INTEGRATIONS:
      return <Integrations />;
    case listType.CONNECTIONS:
      return <Connections />;
    default:
      return null;
  }
}

export default function XeroConnections() {
  useDocumentTitle('Xero Connections - Admin');

  const [list, setList] = useState(listType.INTEGRATIONS);

  return (
    <Page>
      <Header />
      <Filters>
        <SingleSelect placeholder="Connection List" value={list} onChange={(event) => setList(event.target.value)}>
          <option value={listType.INTEGRATIONS}>Ruddr Integrations</option>
          <option value={listType.CONNECTIONS}>Xero Connections</option>
        </SingleSelect>
      </Filters>
      <XeroList list={list} />
    </Page>
  );
}
