import { Icon, Tooltip } from '~/components';
import { useCurrencyFormat } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Container = styled.div`
  flex: 0;
  align-self: center;
`;

const LineAmountOffTooltip = ({ line, amount, currency, ...props }) => {
  const currencyFormat = useCurrencyFormat({ currency });

  if (!line.transactionType || (line.transactionAmount || 0) === (amount || 0)) return null;

  return (
    <Container {...props}>
      <Tooltip
        message={`This total doesn't match the total of ${currencyFormat.format(
          line.transactionAmount,
        )} represented by ${
          {
            time: 'time entries',
            milestone: 'the billing schedule',
            expense: 'expense items',
            other_item: 'other items',
          }[line.transactionType]
        }.`}>
        <Icon spaceLeft icon="exclamation-circle" color={colors.warning} />
      </Tooltip>
    </Container>
  );
};

export default LineAmountOffTooltip;
