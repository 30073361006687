import {
  ApprovalStatusFilter,
  BackLink,
  Hours,
  Icon,
  Level,
  RouteLink,
  Stack,
  Table,
  TimeOffTypeFilter,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import PeriodNavTag from '../components/PeriodNavTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TimeOffTypeTag from '../components/TimeOffTypeTag';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import useMemberFilters from '~/hooks/useMemberFilters';
import MemberTagsGroup from '../components/MemberTagsGroup';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';

const Member = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

const Type = styled.div`
  margin-left: 1rem;
`;

export default function TimeOffByMember() {
  useDocumentTitle('Time Off by Member');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    timeOffTypes: [],
    sort: new QuerySort('member.name', 'asc'),
    ...memberFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        sort: { default: new QuerySort('member.name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeOffByMember(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeOffByMember(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />

        <Stack>
          <h1>Time Off by Member</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            <TimeOffTypeTag value={params.timeOffTypes} onRemove={() => handleApplyFilters({ timeOffTypes: [] })} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>
        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>
      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const groups = useMemo(() => {
    return _.groupBy(report.records, (record) => record.member.id);
  }, [report.records]);

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          timeOffType: params.timeOffTypes?.map((v) => v.id),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key],
  );

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <TotalResults>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Member" value={_.size(groups)} />
        {report.partialResults && <PartialResultsTooltip />}
      </TotalResults>

      <Table>
        <Table.Header sticky>
          <Table.Column name="member.name" onSort={onSort} sort={params.sort}>
            Member
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Hours
          </Table.Column>
        </Table.Header>
        <TableBody fade={query.status === 'sorting'}>
          {_.map(groups, (timeEntries) => {
            const member = timeEntries[0].member;

            const totalHours = _.sum(timeEntries.map((timeEntry) => timeEntry.sum));

            return (
              <React.Fragment key={member.id}>
                <GroupRow top="3rem">
                  <Table.Cell>
                    <Member>{member.name}</Member>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'time_off' })}>
                        <Hours value={totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                </GroupRow>
                {timeEntries.map((record) => {
                  return (
                    <Table.Row key={record.timeOffType ? record.timeOffType.id : 'no_time_off'}>
                      <Table.Cell>
                        <Type>{record.timeOffType?.name}</Type>
                      </Table.Cell>
                      <Table.Cell>
                        <RouteLink
                          to={timeDetail({
                            member: member.id,
                            timeOffType: record.timeOffType?.id,
                          })}>
                          <Hours value={record.sum} />
                        </RouteLink>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Hours value={report.totals.total} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <FiltersGroup label="Time Filters" filters={[filters.approvalStatuses, filters.timeOffTypes]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        <Filter>
          <TimeOffTypeFilter
            value={filters.timeOffTypes}
            onChange={({ target: { value } }) => handleFilter({ timeOffTypes: value })}
          />
        </Filter>
      </FiltersGroup>

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
