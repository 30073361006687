import { ExportDialog, InfiniteScrollingObserver, Page, Spinner, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort } from '~/utils';
import ClientRow from './ClientRow';
import ClientsListFilters from './ClientsListFilters';
import { ClientDrawer } from './client-drawer';

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    practice: null,
    recordStatusId: 'active',
    tags: [],
    owner: null,
    page: 0,
    size: 25,
    withGraph: 'owner',
    sort: new QuerySort('name', 'asc'),
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)),
    },
  }),
  removeItem: (id, { data }) => ({
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function ClientsListPage() {
  const documentTitle = useDocumentTitle('Clients');

  const { workspace } = useWorkspace();
  const api = useApi();

  const history = useHistory();
  const { clientId } = useParams();
  const location = useLocation();

  const confirmation = useConfirmation();

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        practice: searchParamsConfig.practice,
        recordStatusId: { default: initialState.query.recordStatusId, ...searchParamsConfig.recordStatusId },
        tags: searchParamsConfig.clientTags,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        owner: searchParamsConfig.member,
      }),
      [searchParamsConfig],
    ),

    sessionKey: 'clients_list',

    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .clients()
        .get({
          ..._.omit(query, ['practice']),
          practiceId: query.practice?.id,
          recordStatusId: query.recordStatusId ?? undefined,
          tagIds: query.tags.map((tag) => tag.id).join(',') || undefined,
          ownerId: query.owner?.id,
        });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [] });
    }
  }, [actions, workspace.id, query, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilter = (values) => {
    actions.setParams({ ...values });
    searchParams.set({ ..._.omit(values, 'sort') });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  async function handleSaved() {
    actions.load();
  }

  function handleClose() {
    history.push({ pathname: `/app/${workspace.key}/clients`, search: location.search });
    documentTitle.set('Clients');
  }

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .clients()
          .export(
            {
              ..._.omit(query, ['practice']),
              practiceId: query.practice?.id,
              recordStatusId: query.recordStatusId ?? undefined,
              size: null,
              tagIds: query.tags.map((tag) => tag.id).join(',') || undefined,
              ownerId: query.owner?.id,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady || !data) return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Section>
          <ClientsListFilters onExport={handleExport} filters={query} onChange={handleFilter} />
        </Page.Section>
        <Page.Section>
          <Table.Status>
            {!!action && <Spinner />}
            <Table.Total value={data.total} label="Client" />
          </Table.Status>
          <Table>
            <Table.BoxHeader sticky>
              <Table.Column name="name" onSort={handleSort} sort={query.sort}>
                Client Name
              </Table.Column>
              <Table.Column name="clientCode">Client Code</Table.Column>
              <Table.Column width="16rem">Relationship Owner</Table.Column>
              <Table.Column name="createdAt" onSort={handleSort} sort={query.sort} width="8rem" align="right">
                Created
              </Table.Column>
              <Table.BoxActionsColumn />
            </Table.BoxHeader>
            <Table.Body fade={action === 'filter'}>
              {data.results.map((item) => (
                <ClientRow
                  key={item.id}
                  client={item}
                  onSaved={actions.updateItem}
                  onDeleted={actions.removeItem}
                  onCollapse={actions.updateItem}
                />
              ))}
            </Table.Body>
          </Table>

          {data.total > data.results.length && (
            <InfiniteScrollingObserver key={data.results.length} onIntersecting={actions.loadMore} />
          )}
        </Page.Section>
      </Page>

      {clientId && (
        <ClientDrawer clientId={clientId} onSaved={handleSaved} onDeleted={actions.removeItem} onClose={handleClose} />
      )}
    </>
  );
}

export default ClientsListPage;
export { ClientsListPage };
