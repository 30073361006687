import {
  BillableIcon,
  Button,
  DeleteConfirmation,
  Dropdown,
  Field,
  HelpTooltip,
  Icon,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { colors } from '~/styles';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ProjectDrawerContext } from '../ProjectDrawer';
import ProjectRolePopover from './ProjectRolePopover';
import styled from 'styled-components';

const OpacityDiv = styled.div`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
  display: flex;
`;

function RoleAssignmentsTable({ projectModel, onChange }) {
  const [editId, setEditId] = useState(null);

  const activeMembers = projectModel.members.filter(({ isActuallyActive }) => isActuallyActive);
  const hasActiveMembers = activeMembers.length > 0;

  const activeRoles = projectModel.roles.filter(({ isActive }) => isActive);
  const hasActiveRoles = activeRoles.length > 0;

  const hasRemainingRoles = activeMembers.some((pm) =>
    activeRoles.some((pr) => !pm.roles.some((mr) => mr.projectRoleId === pr.id)),
  );

  const handleSave = (value) => {
    // TODO: attempt to find the original project's member -> role assignment
    const members = projectModel.members.map((pm) => {
      if (pm.id !== value.projectMemberId) return pm;

      let roles;
      if (value.id) {
        roles = pm.roles.map((pmr) =>
          pmr.id === value.id
            ? {
                ...pmr,
                projectMemberId: value.projectMemberId,
                projectRoleId: value.projectRoleId,
                isBillable: value.isBillable,
              }
            : pmr,
        );
      } else {
        roles = [
          ...pm.roles,
          {
            id: _.uniqueId('pmr_'),
            projectMemberId: value.projectMemberId,
            projectRoleId: value.projectRoleId,
            isBillable: value.isBillable,
          },
        ];
      }

      return { ...pm, roles };
    });

    setEditId(null);
    onChange(members);
  };

  const handleDelete = (value) => {
    const members = projectModel.members.map((pm) =>
      pm.id !== value.projectMemberId ? pm : { ...pm, roles: pm.roles.filter((pmr) => pmr.id !== value.id) },
    );

    onChange(members);
  };

  return (
    <Table small>
      <Table.BoxHeader>
        <Table.Column width="14.4rem">Member</Table.Column>
        <Table.Column>Project Role</Table.Column>
        <Table.BoxActionsColumn />
      </Table.BoxHeader>
      <Table.Body>
        {projectModel.members.map((pm) =>
          pm.roles.map((pmr) => (
            <RoleAssignmentRow
              key={pmr.id}
              project={projectModel}
              projectMember={pm}
              projectMemberRole={pmr}
              disableActions={editId !== null}
              isEditing={editId === pmr.id}
              onEdit={() => setEditId(pmr.id)}
              onCancel={() => setEditId(null)}
              onSaved={handleSave}
              onDeleted={handleDelete}
            />
          )),
        )}

        {!hasActiveRoles ? (
          <DisabledFooterRow message="At least one active role is required to assign team members." />
        ) : !hasActiveMembers ? (
          <DisabledFooterRow message="At least one active member is required to assign roles to." />
        ) : !hasRemainingRoles ? (
          <DisabledFooterRow message="Every active project member has been assigned to each active role." />
        ) : (
          <RoleAssignmentRow
            projectMemberRole={{}}
            project={projectModel}
            isEditing={editId === -1}
            disableActions={editId !== null}
            onEdit={() => setEditId(-1)}
            onCancel={() => setEditId(null)}
            onSaved={handleSave}
          />
        )}
      </Table.Body>
    </Table>
  );
}

function RoleAssignmentRow({
  project,
  projectMember,
  projectMemberRole,
  disableActions,
  isEditing,
  onEdit,
  onCancel,
  onSaved,
  onDeleted,
}) {
  async function handleSubmit(values) {
    onSaved(values);
  }

  async function handleDelete() {
    onDeleted(projectMemberRole);
  }

  if (!isEditing)
    return (
      <RoleAssignmentRowDetails
        project={project}
        projectMember={projectMember}
        projectMemberRole={projectMemberRole}
        disableActions={disableActions}
        onEdit={onEdit}
        onDelete={handleDelete}
      />
    );

  return (
    <RoleAssignmentRowForm
      project={project}
      projectMember={projectMember}
      projectMemberRole={projectMemberRole}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
}

function RoleAssignmentRowDetails({ project, projectMember, projectMemberRole, disableActions, onEdit, onDelete }) {
  const confirmation = useConfirmation();

  if (!projectMemberRole.id)
    return (
      <Table.Row style={{ borderBottom: 'none' }}>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  const projectRole = project.roles.find((pr) => pr.id === projectMemberRole.projectRoleId);

  const memberIsActive = projectMember.isActive && projectMember.member.isActive;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this role assignment?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow data-testid="row">
      <Table.Cell>
        <OpacityDiv isActive={memberIsActive}>{projectMember.member.name}</OpacityDiv>
        {!projectMember.member.isBillable && project.isBillable && (
          <Tooltip
            style={{ display: 'inline', marginLeft: '0.5rem' }}
            data-testid="non_billable_tooltip"
            message={`This is a non-billable workspace member that can't track time to billable projects.`}>
            <Icon icon="exclamation-triangle" color={colors.warning} />
          </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell>
        <OpacityDiv isActive={projectRole.isActive}>
          <BillableIcon value={project.isBillable && projectRole.isBillable} />
          <ProjectRolePopover
            data-testid="project_role_popover"
            style={{ cursor: 'default' }}
            project={project}
            projectRole={projectRole}
            placement="right">
            {projectRole.name}
          </ProjectRolePopover>
        </OpacityDiv>
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Dropdown disabled={disableActions}>
          <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
          <Dropdown.Item isAnchor onClick={handleDelete}>
            Delete
          </Dropdown.Item>
        </TableBoxRowActions.Dropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function RoleAssignmentRowForm({ projectMemberRole, project, onSubmit, onCancel }) {
  // Submit the row if the drawer's "Save" button is clicked
  const form = useRef();
  const { setForms } = useContext(ProjectDrawerContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'role_assignment', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'role_assignment'));
    };
  }, [setForms]);

  const initialValues = mergeValues(
    {
      id: null,
      projectMemberId: null,
      projectRoleId: null,
    },
    projectMemberRole,
  );

  const rolesCount = project.roles.filter(({ isActive }) => isActive).length;
  const excludedMemberIds = project.members
    .filter(
      ({ roles }) =>
        roles.filter(({ projectRoleId }) => project.roles.find((pr) => pr.id === projectRoleId).isActive).length >=
        rolesCount,
    )
    .map(({ id }) => id);

  return (
    <Formik
      innerRef={form}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        projectMemberId: Yup.string().label('Project Member').nullable().required(),
        projectRoleId: Yup.string().label('Project Role').nullable().required(),
      })}>
      {({ values, errors, submitForm, setFieldValue, validateField }) => {
        const projectMember = project.members.find((pm) => pm.id === values.projectMemberId);
        const projectRole = project.roles.find((pr) => pr.id === values.projectRoleId);

        const excludedRoles = project.members
          .find((m) => m.id === values.projectMemberId)
          ?.roles.map((pmr) => pmr.projectRoleId);

        const projectRoles = _.differenceWith(
          project.roles,
          excludedRoles,
          (pr, id) => pr.id === id && pr.id !== projectMemberRole.projectRoleId,
        ).map((pr) => ({
          ...pr,
          isActuallyBillable: project.isBillable && pr.isBillable,
        }));

        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              {projectMemberRole.projectMemberId ? (
                <>
                  {projectMember.member.name}
                  {!projectMember.member.isBillable && project.isBillable && (
                    <Tooltip
                      style={{ display: 'inline', marginLeft: '0.5rem' }}
                      message={`This is a non-billable workspace member that can't track time to billable projects.`}>
                      <Icon icon="exclamation-triangle" color={colors.warning} />
                    </Tooltip>
                  )}
                </>
              ) : (
                <Field.ProjectMemberSelect
                  autoFocus
                  name="projectMemberId"
                  materialPlaceholder="Member"
                  materialAlwaysVisible
                  offsetToShowMenuOnTop={80}
                  exclude={excludedMemberIds}
                  project={project}
                  value={projectMember}
                  // TODO: exclude members that don't have any available role
                  onSearch={(q) =>
                    project.members.filter(
                      (pm) =>
                        pm.member.isActive && pm.isActive && pm.member.name.toLowerCase().includes(q.toLowerCase()),
                    )
                  }
                  onChange={async ({ target: { name, value } }) => {
                    await setFieldValue(name, value?.id || null);
                    if (errors[name]) validateField(name);
                  }}
                />
              )}
            </Table.Cell>
            <Table.Cell>
              <div style={{ width: '100%' }}>
                <Field.ProjectRoleSelect
                  name="projectRoleId"
                  materialPlaceholder="Role"
                  materialAlwaysVisible
                  autoFocus={!!projectMemberRole.projectMemberId}
                  project={project}
                  disabled={!projectMember}
                  value={projectRole}
                  onSearch={(q) => projectRoles.filter((pr) => pr.name.toLowerCase().includes(q.toLowerCase()))}
                  onChange={async ({ target: { name, value } }) => {
                    await setFieldValue(name, value?.id || null);
                    if (errors[name]) validateField(name);
                  }}
                />
              </div>
            </Table.Cell>

            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

function DisabledFooterRow({ message }) {
  return (
    <Table.FooterRow>
      <Table.Cell>
        <Button isAnchor isStrong disabled>
          <Icon icon="plus" size="xs" spaceRight />
          Quick Add
        </Button>
        <HelpTooltip message={message} style={{ marginLeft: '.5rem' }} />
      </Table.Cell>
    </Table.FooterRow>
  );
}

export default RoleAssignmentsTable;
