import { ActionButton, Buttons, CancelButton, Level, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function UnpublishDialog({ creditNoteId, onClose, onSaved }) {
  const { workspace } = useWorkspace();
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNoteId).unpublish();
    await onSaved(data);
    form.save();
    toast.success(`Credit Note #${data.number} has been unpublished.`);
  };

  return (
    <ModalCard title="Unpublish Credit Note" onClose={onClose}>
      <ModalCard.Body>
        <P>Are you sure you want to unpublish this credit note? This will remove any credit applied to invoices.</P>
      </ModalCard.Body>

      <ModalCard.Footer>
        <Level>
          <Level.Item>
            <Buttons align="right">
              <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
              <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
                Unpublish
              </ActionButton>
            </Buttons>
          </Level.Item>
        </Level>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default UnpublishDialog;
