import { useDocumentTitle, useFeatures } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

function ExpenseReportsPage() {
  useDocumentTitle('Expense Reports');
  const { url } = useRouteMatch();
  const features = useFeatures();

  return (
    <LandingPage>
      <NavBox to={url.concat('/expense-items')}>
        <NavBox.Title>Expense Items</NavBox.Title>
        <NavBox.Description>
          Individual expense items including the date, member, project, client, category, vendor, and approval status.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/expense-summary-by-client-and-project')}>
        <NavBox.Title>Expense Summary by Client and Project</NavBox.Title>
        <NavBox.Description>
          A summary of expenses by client and project including billable and non-billable expenses.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/expense-summary-by-expense-category')}>
        <NavBox.Title>Expense Summary by Expense Category</NavBox.Title>
        <NavBox.Description>
          A summary of expenses by expense category including billable and non-billable expenses.
        </NavBox.Description>
      </NavBox>

      {features.expenseReporting && (
        <NavBox to={url.concat('/expense-summary-by-member')} data-testid="time_summary_by_member">
          <NavBox.Title>Expense Summary by Member</NavBox.Title>
          <NavBox.Description>
            A summary of expenses by member including billable and non-billable expenses.
          </NavBox.Description>
        </NavBox>
      )}

      {features.expenseReporting && features.practices && (
        <NavBox to={url.concat('/expense-summary-by-member-practice')} data-testid="time_summary_by_member_practice">
          <NavBox.Title>Expense Summary by Member Practice</NavBox.Title>
          <NavBox.Description>
            A summary of expenses by member practice including billable and non-billable expenses.
          </NavBox.Description>
        </NavBox>
      )}

      <NavBox to={url.concat('/expense-summary-by-project')}>
        <NavBox.Title>Expense Summary by Project</NavBox.Title>
        <NavBox.Description>
          A summary of expenses by project including billable and non-billable expenses.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/expense-summary-by-project-type')}>
        <NavBox.Title>Expense Summary by Project Type</NavBox.Title>
        <NavBox.Description>
          A summary of expenses by project type including billable and non-billable expenses.
        </NavBox.Description>
      </NavBox>
    </LandingPage>
  );
}

export default ExpenseReportsPage;
