import { Buttons, CancelButton, DeleteButton, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function DeleteMemberDialog({ member, onDelete, onClose }) {
  const [{ isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    await api.www.workspaces(workspace.id).members(member.id).remove();

    if (onDelete) await onDelete();
    form.done();
  };

  return (
    <ModalCard title="Remove member" onClose={onClose}>
      <ModalCard.Body>
        <P>
          This will remove <strong>{member.name}</strong> from this workspace. Are you sure?
        </P>
      </ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton isLoading={isSubmitting} onClick={handleSubmit}>
            Delete
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DeleteMemberDialog;
