import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard, Radio } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';

export default function GenerateRevRecEntries({ onClose, onRefetch, projectModel }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ status, message, isSubmitting }, form] = useForm();

  const toast = useToast();
  const handleSubmit = async (values) => {
    try {
      form.submit();
      await api.www.workspaces(workspace.id).projects(projectModel.id).generateRevRecEntries(values);
      await onRefetch();
      form.done();
      toast.success('Revenue recognition ledger has been generated.');
      onClose();
    } catch (error) {
      form.error({ message: error?.message });
    }
  };

  const momentInterval = { week: 'isoWeek', month: 'month' }[workspace.autoRevRecInterval];
  let start;
  if (projectModel.revenueRecognitionEntries.length === 0 && projectModel.start) {
    start = moment(projectModel.start);
  } else {
    start = moment().subtract(1, momentInterval);
  }

  const initialValues = {
    interval: workspace.autoRevRecInterval,
    start: start.startOf(momentInterval).format('YYYY-MM-DD'),
    revenueRecognitionMethod:
      projectModel.revenueRecognitionMethod === 'manual' ? 'automated_hours' : projectModel.revenueRecognitionMethod,
  };

  const disabledRevRecMethod = projectModel.revenueRecognitionMethod !== 'manual';

  return (
    <ModalCard title="Generate Revenue Recognition" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          interval: Yup.string().label('Interval').oneOf(['week', 'month']).required(),
          start: Yup.date().label('Date').required(),
          revenueRecognitionMethod: Yup.string().label('Revenue Recognition Method').required(),
        })}>
        {(formik) => {
          const handleIntervalChange = ({ target: { value } }) => {
            let values = { ...formik.values, interval: value };
            let momentInterval = { week: 'isoWeek', month: 'month' }[value];
            values.start = moment(values.start).startOf(momentInterval).format('YYYY-MM-DD');
            formik.setValues(values);
          };

          return (
            <Form>
              <ModalCard.Body>
                <p>Calculate the percent complete using the following revenue recognition method:</p>
                <Form.Control>
                  <Field.RadioGroup name="revenueRecognitionMethod" direction="vertical">
                    <Radio
                      value="automated_hours"
                      label="Percentage of Billable Hours"
                      disabled={disabledRevRecMethod}
                    />
                    <Radio
                      value="automated_tm_basis"
                      label="Percentage of Time & Materials Revenue"
                      disabled={disabledRevRecMethod}
                    />
                  </Field.RadioGroup>
                </Form.Control>

                <p>Generate revenue recognition ledger entries on the following interval:</p>
                <Form.Control>
                  <Field.RadioGroup name="interval" onChange={handleIntervalChange}>
                    <Radio value="week" label="Weekly" />
                    <Radio value="month" label="Monthly" />
                  </Field.RadioGroup>
                </Form.Control>

                <p>Generate revenue recognition ledger entries as of:</p>
                <Form.Control>
                  <Field.DayPicker name="start" placeholder="Date" scope={formik.values.interval} clearable={false} />
                </Form.Control>

                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Generate
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
