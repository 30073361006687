import { useWorkspace } from '~/contexts';
import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ProjectArea from './ProjectArea';
import ProjectCloneForm from './ProjectCloneForm';
import ProjectCreateForm from './ProjectCreateForm';
import ProjectsListPage from './ProjectsListPage';

function ProjectsArea() {
  const { workspace } = useWorkspace();

  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const auth = useAuth();

  return (
    <>
      <Switch>
        <Route
          path={[
            `${path}/`,
            `${path}/new`,
            `${path}/edit/:clientKey/:projectKey`,
            `${path}/clone/:clientKey/:projectKey`,
          ]}
          exact>
          <ProjectsListPage sessionKey="projects_list" />
        </Route>
        <Route path={`${path}/:clientKey/:projectKey`}>
          <ProjectArea />
        </Route>
      </Switch>

      <Route path={`${path}/new`}>
        {auth.projects.create ? (
          <ProjectCreateForm
            onSaved={(project) => history.push(`${url}/${project.client.key}/${project.key}/overview/edit`)}
            onClose={() => history.push({ pathname: url, search: location.search })}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/projects`} />
        )}
      </Route>
      <Route path={`${path}/clone/:clientKey/:projectKey`}>
        {auth.projects.create ? (
          <ProjectCloneForm
            onSaved={(project) =>
              history.push({
                pathname: `${url}/${project.client.key}/${project.key}/overview/edit`,
                search: location.search,
                state: { scrollToTop: false },
              })
            }
            onClose={() => history.push({ pathname: url, search: location.search })}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/projects`} />
        )}
      </Route>
    </>
  );
}

export default ProjectsArea;
