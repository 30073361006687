import { Field, Form } from '~/components';
import React from 'react';

function LineSettingsOtherItemsTab() {
  return (
    <Form.Section title="General">
      <Form.Control>
        <Field.Checkbox
          label="Include the project name on each other item invoice line"
          name="includeOtherItemProject"
        />
      </Form.Control>
    </Form.Section>
  );
}

export default LineSettingsOtherItemsTab;
