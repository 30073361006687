import { Checkbox, Currency, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 2rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

const initialState = {
  isReady: false,
  data: null,
};
const handlers = {
  ready: ({ data }) => ({ isReady: true, data }),
};

function TransactionsDrawerMilestonesTab({ invoice, milestones, start, end, projectId, currency, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const q = {
        start: start || undefined,
        end: end || undefined,
        projectId: projectId || undefined,
      };
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).getMilestoneTransactions(q);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, start, end, projectId, api, invoice.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!data) return;

    return {
      milestones: _.filter(data, (milestone) => milestones.some((e) => e.id === milestone.id)),

      get amount() {
        return _.sumBy(this.milestones, (milestone) => milestone.fee);
      },

      get some() {
        return this.milestones.length > 0;
      },

      get all() {
        return this.milestones.length === data.length;
      },
    };
  }, [data, milestones]);

  const dateTimeFormat = useDateTimeFormat();

  if (!data) return null;

  const handleBulkSelectionChange = () => {
    onChange(
      selected.some
        ? milestones.filter(({ id }) => !data.some((milestone) => id === milestone.id))
        : [...milestones, ...data.map(({ id }) => ({ id }))],
    );
  };

  return (
    <div>
      <StyledTable>
        <Table.Header>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column>Details</Table.Column>
          <Table.Column width="6rem" align="right">
            Amount
          </Table.Column>
        </Table.Header>
        <Table.Body>
          {data.map((milestone) => {
            const checked = _(milestones).some(({ id }) => id === milestone.id);

            const handleSelectionChange = () => {
              onChange(
                checked ? milestones.filter(({ id }) => id !== milestone.id) : [...milestones, { id: milestone.id }],
              );
            };

            return (
              <Table.Row key={milestone.id}>
                <Table.Cell>
                  <StyledCheckbox>
                    <Checkbox checked={checked} onChange={handleSelectionChange} />
                  </StyledCheckbox>
                </Table.Cell>
                <Table.Cell>
                  {_.compact([dateTimeFormat.format(milestone.date), milestone.details]).join(' - ')}
                </Table.Cell>
                <Table.Cell>
                  <strong>
                    <Currency value={milestone.fee} currency={currency} />
                  </strong>
                </Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold, border: 'none' }}>
            <Table.Cell />
            <Table.Cell>
              <p style={{ flex: 1, textAlign: 'right' }}>Total</p>
            </Table.Cell>
            <Table.Cell>
              <Currency value={selected.amount} currency={currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </StyledTable>
    </div>
  );
}

export default TransactionsDrawerMilestonesTab;
