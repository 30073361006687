import { Icon, SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const TaskContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Role = styled.div`
  display: flex;
  align-items: center;
  height: 1.125rem;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  color: ${colors.grey55};
  font-size: 0.625rem;
  font-weight: ${weights.medium};
  background-color: ${colors.grey10};
  border-radius: 999rem;
`;

const BillableIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  color: white;
  font-size: 0.6rem;
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey40)};
  border-radius: 50%;
`;

function labelForGroup(group, groups) {
  if (group.role && !group.isCompleted) {
    return `${group.role.name} Open Tasks`;
  } else if (group.assignedToMe && !group.isCompleted) {
    if (groups.some((g) => !!g.role && !g.isCompleted)) return 'My Other Open Tasks';
    return 'My Open Tasks';
  } else if (group.assignedToMe && group.isCompleted) {
    return 'My Completed Tasks';
  } else if (!group.assignedToMe && !group.isCompleted) {
    return 'Other Open Tasks';
  } else {
    return 'Other Completed Tasks';
  }
}

export function TaskDisplay({ task }) {
  if (!task) return null;
  return (
    <TaskContainer>
      <div>{task.name}</div>
      {_.map(task.roles, (role) => (
        <Role key={role.role.id}>{role.role.name}</Role>
      ))}
      <BillableIcon isBillable={task.isActuallyBillable}>
        <Icon icon="dollar-sign" data-testid="task_is_billable" />
      </BillableIcon>
    </TaskContainer>
  );
}

const TaskSelect = React.forwardRef(
  ({ projectId, projectRoleId, memberId, initialTask, value: taskId, ...props }, ref) => {
    const api = useApi();
    const { workspace } = useWorkspace();
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState();
    const [filterValue, setFilterValue] = useState('');
    const [filteredTasks, setFilteredTasks] = useState();
    const isMountedRef = useRef(false);

    const task = useMemo(
      () =>
        _.find(tasks, { id: taskId }) ||
        _.find(filteredTasks, { id: taskId }) ||
        (initialTask && _.isEqual(initialTask.id, taskId) ? initialTask : undefined),
      [tasks, taskId, initialTask, filteredTasks],
    );

    const groupedTasks = useMemo(() => {
      const tasksToUse = filterValue && filteredTasks ? filteredTasks : tasks;
      const groups = _.reduce(
        tasksToUse,
        (acc, task) => {
          const { assignedToMe, statusId, roles } = task;
          const isCompleted = statusId === 'completed';
          const role =
            !isCompleted && projectRoleId ? roles.find(({ role }) => role.id === projectRoleId)?.role ?? null : null;

          let obj = _.find(acc, { assignedToMe, isCompleted, role });
          if (!obj) {
            obj = { assignedToMe, role, isCompleted, tasks: [] };
            acc.push(obj);
          }

          obj.tasks.push(task);

          return acc;
        },
        [],
      );
      return _.orderBy(groups, (group) => group.assignedToMe && !!group.role, ['desc']);
    }, [tasks, filteredTasks, filterValue, projectRoleId]);

    useEffect(() => {
      isMountedRef.current = true;
      return () => {
        isMountedRef.current = false;
      };
    }, []);

    useEffect(() => {
      if (!projectId) {
        setTasks();
        return;
      }

      (async () => {
        setIsLoading(true);

        const { data } = await api.www
          .workspaces(workspace.id)
          .timeEntries()
          .projects(projectId)
          .tasks()
          .get({ memberId, projectRoleId, recordStatusId: 'active', limit: 1000 });

        if (!isMountedRef.current) {
          return;
        }

        setIsLoading(false);
        setTasks(data.results);
      })();
    }, [api, workspace.id, projectId, memberId, projectRoleId]);

    useEffect(() => {
      if (!filterValue) {
        return;
      }

      (async () => {
        const { data } = await api.www
          .workspaces(workspace.id)
          .timeEntries()
          .projects(projectId)
          .tasks()
          .get({ memberId, query: filterValue });

        if (!isMountedRef.current) {
          return;
        }
        setFilteredTasks(data.results);
      })();
    }, [api, workspace.id, projectId, memberId, filterValue]);

    return (
      <SingleSelect
        showEmptyOption={true}
        {...props}
        showFilter
        filterPlaceholder="Find tasks"
        noOptionsMessage="No tasks available"
        value={taskId}
        valueRenderer={<TaskDisplay task={task} />}
        disabled={isLoading}
        onFilterChange={(event) => setFilterValue(event.target.value)}
        ref={ref}>
        {groupedTasks?.map((group) => (
          <optgroup
            key={`assigned_${group.assignedToMe}_${group.isCompleted}${group.role ? `_${group.role.id}` : ''}`}
            label={labelForGroup(group, groupedTasks)}>
            {group.tasks.map((task) => {
              const disabled =
                task.forAssignedOnly &&
                !task.assignedToMember &&
                !task.taskRoles.some(({ role }) => role.id === projectRoleId);

              return (
                // eslint-disable-next-line react/no-unknown-property
                <option key={task.id} tooltip={task.name} value={task.id} disabled={disabled}>
                  <TaskDisplay task={task} disabled={disabled} />
                </option>
              );
            })}
          </optgroup>
        ))}
      </SingleSelect>
    );
  },
);

export default TaskSelect;
