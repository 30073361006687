import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Button, Table, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { DownloadArea, EmptyReport, Report, ReportLoader, Workspace } from './components';

const formatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 2,
});

function EndingAt({ trialEnd }) {
  const timestamp = moment.unix(trialEnd);
  return (
    <Tooltip message={timestamp.format('dddd, MMMM Do, YYYY [at] h:mm:ss a')}>
      {moment.duration(moment().diff(timestamp)).humanize()}
    </Tooltip>
  );
}

export default function TrialsEndingSoon({ showPaying }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getTrialsEndingSoon({ showPaying });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api, showPaying]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getTrialsEndingSoon({ showPaying });
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <Table>
        <Table.Header>
          <Table.Column>Workspace</Table.Column>
          <Table.Column width="14rem" align="right" name="ending" sort={{ column: 'ending', direction: 'asc' }}>
            Trial Ending
          </Table.Column>
          <Table.Column width="14rem" align="right">
            Members
            <br />
            (billable / total)
          </Table.Column>
          <Table.Column width="10rem" align="right">
            CC on File
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Will Cancel
          </Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              <Workspace data={item} />
            </Table.Cell>
            <Table.Cell>
              <EndingAt trialEnd={item.trialEnd} />
            </Table.Cell>
            <Table.Cell>
              {formatter.format(item.billable)} / {formatter.format(item.total)}
            </Table.Cell>
            <Table.Cell>{item.cardExists ? 'Yes' : 'No'}</Table.Cell>
            <Table.Cell>{item.willCancel ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
