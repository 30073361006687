import { useApi, useWorkspace } from '~/contexts';
import { useFeatures, useField, useIsMounted } from '~/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

export default function WorkspaceCurrencySelect({ value, onChange, ...props }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.currencies().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value?.code ?? null,
      },
    });
  };

  const selectedOption = useMemo(() => {
    return options?.find((o) => o.code === value);
  }, [options, value]);

  const availableOptions = useMemo(() => {
    return options?.filter(
      (o) =>
        o.isActive &&
        (o.code === value ||
          o.code === workspace.currency ||
          (features.multicurrency && workspace.currencies.includes(o.code))),
    );
  }, [options, value, workspace.currency, workspace.currencies, features.multicurrency]);

  const handleSearch = (q) => {
    return availableOptions.filter((o) => [o.code, o.name].some((f) => f.toLowerCase().includes(q.toLowerCase())));
  };

  return (
    <AutoComplete
      placeholder="Currency"
      value={selectedOption}
      defaultOptions={availableOptions}
      displayText={selectedOption ? `${selectedOption.name} (${selectedOption.code})` : ''}
      renderOption={(option) => `${option.name} (${option.code})`}
      onSearch={handleSearch}
      onChange={handleChange}
      {...props}
    />
  );
}

export function FieldWorkspaceCurrencySelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <WorkspaceCurrencySelect {...field} {...props} />
    </FieldControl>
  );
}
