import { Buttons, DeleteConfirmation, Dropdown, Icon, Level, Page, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ActiveStatusSelect } from '../custom-data/components';
import SecurityRoleCloneConfirmation from './SecurityRoleCloneConfirmation';
import SecurityRoleForm from './SecurityRoleForm';

function SecurityRolesListPage() {
  const documentTitle = useDocumentTitle('Security Roles');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState([]);
  const [cloneTarget, setCloneTarget] = useState(null);
  const toast = useToast();
  const [params, setParams] = useState({ isActive: 'true' });

  const { path, url } = useRouteMatch();
  const history = useHistory();

  const confirmation = useConfirmation();

  const isMounted = useIsMounted();

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).securityRoles().get(params);

    if (!isMounted.current) return;

    setData(data);
  }, [workspace.id, api, isMounted, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = (item) => {
    history.push(url.concat(`/${item.id}/edit`));
  };

  const handleDelete = async (item) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve} title="Delete Security Role">
        Are you sure that you want to delete this security role?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).securityRoles(item.id).delete();

    fetchData();

    return true;
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).securityRoles(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDefaultStatusChange(item) {
    try {
      await api.www.workspaces(workspace.id).securityRoles(item.id).setDefaultStatus();
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/security-roles`);
    documentTitle.set('Security Roles');
  }

  function handleCloned(item) {
    try {
      setCloneTarget(null);
      fetchData();
      handleEdit(item);
      toast.success('The security role has been cloned successfully.');
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  return (
    <>
      <Page.Section>
        <Level>
          <Level.Item width="20rem">
            <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
          </Level.Item>

          <Level.Item right narrow>
            <Buttons>
              <Link to={url.concat('/new')} className="button">
                New Security Role
              </Link>
            </Buttons>
          </Level.Item>
        </Level>
      </Page.Section>
      <Page.Section>
        <Table.Total value={data.length} label="Security Role" />

        <Table>
          <Table.BoxHeader>
            <Table.Column width="15rem">Name</Table.Column>
            <Table.Column>Description</Table.Column>
            <Table.Column width="10rem" align="right">
              Active Members
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {data.map((item) => (
              <Table.BoxRow key={item.id} onClick={() => handleEdit(item)} isDisabled={!item.isActive}>
                <Table.Cell>
                  <div>
                    {item.name}
                    {item.isDefault && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="The default security role for newly invited members.">
                        <Icon icon="badge-check" />
                      </Tooltip>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>{item.description}</Table.Cell>
                <Table.Cell>{item.activeMemberCount}</Table.Cell>
                <TableBoxRowActions>
                  {item.manageWorkspace ? (
                    <TableBoxRowActions.View onClick={() => handleEdit(item)} />
                  ) : (
                    <>
                      <TableBoxRowActions.Edit onClick={() => handleEdit(item)} />
                      <hr />
                      <TableBoxRowActions.Dropdown>
                        {({ setIsOpen }) => {
                          const handleAction = async (action) => {
                            setIsOpen(false);
                            await action();
                          };

                          const disabled = item.memberCount > 0;

                          return (
                            <>
                              <Dropdown.Item onClick={() => handleAction(() => handleEdit(item))}>Edit</Dropdown.Item>

                              <Dropdown.Item
                                onClick={async () => {
                                  await handleActiveStatusChange(item, !item.isActive);
                                  setIsOpen(false);
                                }}>
                                {item.isActive ? 'Deactivate' : 'Activate'}
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  setIsOpen(false);
                                  setCloneTarget({ projectId: item.id, ...item });
                                }}>
                                Clone
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={disabled}
                                tooltip={
                                  disabled
                                    ? 'There are active or inactive members currently assigned to this security role.'
                                    : undefined
                                }
                                onClick={() => setIsOpen(false) || handleDelete(item)}>
                                Delete
                              </Dropdown.Item>

                              {!item.isDefault && item.isActive && (
                                <Dropdown.Item
                                  onClick={async () => {
                                    await handleDefaultStatusChange(item);
                                    setIsOpen(false);
                                  }}>
                                  Set to default
                                </Dropdown.Item>
                              )}
                            </>
                          );
                        }}
                      </TableBoxRowActions.Dropdown>
                    </>
                  )}
                </TableBoxRowActions>
              </Table.BoxRow>
            ))}
          </Table.Body>
        </Table>
      </Page.Section>

      <Switch>
        <Route path={`${path}/new`}>
          <SecurityRoleForm onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>

        <Route path={`${path}/:securityRoleId/edit`}>
          <SecurityRoleForm onClose={handleCloseDrawer} onSaved={fetchData} onDelete={handleDelete} />
        </Route>
      </Switch>

      {cloneTarget && (
        <SecurityRoleCloneConfirmation
          target={cloneTarget}
          onClose={() => setCloneTarget(null)}
          onSaved={handleCloned}
        />
      )}
    </>
  );
}

export default SecurityRolesListPage;
