import { Tooltip } from '~/components';
import { useCurrencyFormat, usePercentFormat } from '~/hooks';
import _ from 'lodash';
import React from 'react';

export default function ProjectedBudgetTooltip({ value, currency, children }) {
  const percentFormat = usePercentFormat();
  const currencyFormat = useCurrencyFormat({ currency });

  if (!_.isNumber(value.percent)) return children;

  const difference = Math.abs(value.difference);
  const percent = percentFormat.format(Math.abs(value.percent - 1));

  let message;
  if (value.percent === 1) message = 'On budget.';
  else if (value.percent > 1) {
    message = `${currencyFormat.format(difference)} (${percent}) over budget.`;
  } else if (value.percent < 1) {
    message = `${currencyFormat.format(difference)} (${percent}) under budget.`;
  }

  return (
    <Tooltip placement="top" message={message}>
      {children}
    </Tooltip>
  );
}
