import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useIsMounted } from '~/hooks';
import { colors } from '~/styles';
import DayPicker from './DayPicker';

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: ${({ position, positionOffset }) => (position === 'bottom' ? `calc(100% + ${positionOffset})` : 'auto')};
  bottom: ${({ position, positionOffset }) => (position === 'bottom' ? 'auto' : `calc(100% + ${positionOffset})`)};
  left: ${({ align, alignOffset }) => (align === 'left' ? alignOffset : 'auto')};
  right: ${({ align, alignOffset }) => (align === 'left' ? 'auto' : alignOffset)};
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 5;
`;

function DayPickerDropdown({
  isOpen: isOpenExternal,
  onClose,
  onChange,
  align = 'left',
  alignOffset = 0,
  position = 'bottom',
  positionOffset = 0,
  container: Container = DropdownContainer,
  panel: Panel = DropdownPanel,
  children,
  ...props
}) {
  const isControlled = typeof isOpenExternal === 'boolean';
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const isMounted = useIsMounted();
  const containerElement = useRef();

  const isOpen = useMemo(
    () => (isControlled ? isOpenExternal : isOpenInternal),
    [isControlled, isOpenExternal, isOpenInternal],
  );

  const setIsOpen = useCallback(
    (...args) => {
      if (!isMounted.current) return;

      if (isControlled) {
        const isOpen = args.length > 0 ? args[0] : null;
        if (isOpen === false && typeof onClose === 'function') {
          onClose();
        }
      } else {
        setIsOpenInternal(...args);
      }
    },
    [isControlled, isMounted, onClose],
  );

  useEffect(() => {
    function handleClose(event) {
      // Prevent this firing when the user clicks inside the component
      if (containerElement.current && containerElement.current.contains(event.target)) {
        return false;
      }
      setIsOpen(false);
    }

    // Only attach event listener if menu is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClose);
    }

    return function cleanup() {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [isOpen, setIsOpen]);

  const handleChange = (...args) => {
    if (!isControlled) {
      setIsOpen(false);
    }
    if (typeof onChange === 'function') {
      onChange(...args);
    }
  };

  return (
    <Container ref={containerElement}>
      {typeof children === 'function' ? children({ setIsOpen, isOpen }) : children}
      {isOpen && (
        <Panel align={align} alignOffset={alignOffset} position={position} positionOffset={positionOffset}>
          <DayPicker {...props} onChange={handleChange} />
        </Panel>
      )}
    </Container>
  );
}

export { DropdownContainer, DropdownPanel };
export default DayPickerDropdown;
