import _ from 'lodash';
import { darken, rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import Button from './Button';
import Icon from './Icon';
import Input from './Input';
import Tooltip from './Tooltip';

const Select = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2.5rem;
  outline: none;
  box-shadow: none;
  cursor: default;
`;

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Control = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  // padding: 0.5rem 0.875rem;
  color: ${colors.black};
  background-color: ${({ disabled }) => (disabled ? colors.grey5 : colors.white)};
  border: solid 1px ${({ hasFocus }) => (hasFocus ? colors.primary : colors.grey10)};
  border-radius: 0.3125rem;
  user-select: none;
`;

const Display = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 1rem;
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
`;

const Indicator = styled.div`
  flex-shrink: 0;
  color: ${colors.grey25};
  padding: 0 0.9rem;
`;

const ClearIndicator = styled(Button)`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1.05rem;
  height: 100%;
  right: 2.5rem;
  border-right: 1px solid ${colors.grey5};
  color: ${colors.primary};
  font-size: 0.875rem;
  border-radius: 0;

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

const Window = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-height: 20rem;
  max-height: calc(40vh - 3rem);
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 1rem -0.125em ${rgba(colors.black, 0.15)};
  z-index: 2;
  user-select: none;
  overflow: auto;

  ${({ placement }) =>
    ({
      top: css`
        bottom: calc(100% + 0.375rem);
      `,

      bottom: css`
        top: calc(100% + 0.375rem);
      `,
    })[placement || 'bottom']}
`;

const Filter = styled.div`
  position: sticky;
  top: 0rem;
  left: 0;
  right: 0;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.875rem;
  background-color: ${colors.grey5};
  border-bottom: solid 1px ${colors.grey10};
`;

const Options = styled.div`
  padding: 0.5rem 0;
`;

const NoOptions = styled.div`
  padding: 1rem;
  color: ${colors.grey55};
  text-align: left;
`;

const OptionGroupLabel = styled.div`
  padding: 0.25rem 0.875rem;
  font-weight: ${weights.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
`;

const OptionGroupLabelText = styled.div`
  white-space: nowrap;
`;

const OptionGroupLabelIcon = styled(Tooltip)`
  margin-left: 0.5rem;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1.625rem;
  padding: 0.25rem 0.875rem;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${colors.grey5};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.grey25};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const OptionIndicator = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 0.75rem;
  margin-right: 0.5rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const OptionValue = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const OptionGroup = React.forwardRef(({ label, icon, children, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <OptionGroupLabel>
        <OptionGroupLabelText>
          <strong>{label}</strong>
        </OptionGroupLabelText>
        {icon && (
          <OptionGroupLabelIcon message={icon.message}>
            <Icon icon={icon.key} color={colors.grey40} />
          </OptionGroupLabelIcon>
        )}
      </OptionGroupLabel>
      <div>{children}</div>
    </div>
  );
});

const Option = React.forwardRef(({ isSelected, tooltip, children, ...props }, ref) =>
  tooltip ? (
    <Tooltip message={tooltip} delay={1000}>
      <OptionContainer role="menuitemradio" aria-checked={isSelected} {...props} ref={ref}>
        <OptionIndicator>{!!isSelected && <Icon icon="check" />}</OptionIndicator>
        <OptionValue>{children}</OptionValue>
      </OptionContainer>
    </Tooltip>
  ) : (
    <OptionContainer role="menuitemradio" aria-checked={isSelected} {...props} ref={ref}>
      <OptionIndicator>{!!isSelected && <Icon icon="check" />}</OptionIndicator>
      <OptionValue>{children}</OptionValue>
    </OptionContainer>
  ),
);

function OptionWindow({
  value,
  showFilter,
  filterPlaceholder,
  noOptionsMessage,
  // showEmptyOption,
  setFilterHasFocus,
  setFocusedValue,
  placement = 'bottom',
  onFilterChange,
  onOptionClick,
  children,
}) {
  const [filterValue, setFilterValue] = useState('');

  const isMountedRef = useRef(false);
  const windowRef = useRef();
  const filterRef = useRef();
  const filterInputRef = useRef();
  const selectedRef = useRef();

  // Used to ensure the component is still mounted when getting filter changes
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Used to scroll to the selected item if it's not visible in the scrollable area
  useEffect(() => {
    const win = windowRef.current;
    const sel = selectedRef.current;

    if (win && sel) {
      const winRect = win.getBoundingClientRect();
      const selRect = sel.getBoundingClientRect();

      const winCenter = winRect.y + winRect.height / 2;
      const selCenter = selRect.y + selRect.height / 2;

      let offset = 0;
      if (filterRef.current) {
        const filterRect = filterRef.current.getBoundingClientRect();
        offset = filterRect.height / 2;
      }
      win.scrollTo({ top: selCenter - winCenter - offset });
    }
  }, []);

  // Used to focus the filter input, if it exists
  useEffect(() => {
    if (filterInputRef.current) {
      filterInputRef.current.focus();
    }
  }, []);

  // On unmount, reset the filter to an empty string
  useEffect(
    () => {
      return () => {
        if (_.isFunction(onFilterChange)) {
          onFilterChange({ target: { value: '' } });
        }
      };
    },
    // TODO: REVIEW IF THIS IS A PROBLEM
    // Most `onChange` functions are not wrapped with `useCallback`, so this is
    // used to ensure this is only run on mount/unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const createOption = (obj) => {
    if (obj.type !== 'option') {
      return obj;
    }
    const props = {
      ...obj.props,
      tabIndex: obj.props.disabled ? '-1' : '0',
      onClick: onOptionClick.bind(this, obj.props.value),
      isSelected: _.isEqual(obj.props.value, value || ''),
      onFocus: () => setFocusedValue(obj.props.value),
      onBlur: () => setFocusedValue(undefined),
    };
    return <Option {...props} key={obj.key} ref={props.isSelected && !selectedRef.current ? selectedRef : undefined} />;
  };

  const createOptionGroup = (obj) => {
    if (obj.type !== 'optgroup') {
      return obj;
    }
    const props = {
      ...obj.props,
      children: React.Children.map(obj.props.children, createOption),
    };
    return <OptionGroup {...props} key={obj.key} />;
  };

  const optionElements = (() => {
    let elements = [];
    // if (showEmptyOption && !filterValue) {
    //   elements.push(createOption({ type: 'option', key: '', props: { value: '' } }));
    // }
    if (_.isArray(children)) {
      const childrenElements = React.Children.map(children, (child) => {
        if (child.type === 'optgroup') {
          return createOptionGroup(child);
        }
        return createOption(child);
      });
      elements = [...elements, ...childrenElements];
    }
    return elements;
  })();

  const handleFilterChange = (event) => {
    if (!isMountedRef.current) {
      return;
    }
    setFilterValue(event.target.value);
    if (_.isFunction(onFilterChange)) {
      onFilterChange(event);
    }
  };

  return (
    <Window role="menu" placement={placement} ref={windowRef}>
      {showFilter && (
        <Filter ref={filterRef}>
          <Input
            ref={filterInputRef}
            type="search"
            wait={300}
            placeholder={filterPlaceholder}
            value={filterValue}
            onFocus={() => setFilterHasFocus(true)}
            onBlur={() => setFilterHasFocus(false)}
            onChange={handleFilterChange}
          />
        </Filter>
      )}
      {optionElements.length ? (
        <Options>{optionElements}</Options>
      ) : (
        <NoOptions>{noOptionsMessage || 'None'}</NoOptions>
      )}
    </Window>
  );
}

function SingleSelect({
  placeholder,
  showFilter = false,
  showEmptyOption = false,
  debugWindow = false,
  disabled = false,
  name,
  value,
  valueRenderer,
  materialPlaceholder = true,
  materialAlwaysVisible = false,
  filterPlaceholder,
  noOptionsMessage,
  placement = 'bottom',
  onFocus,
  onBlur,
  onChange,
  onFilterChange,
  children,
  ...props
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const [filterHasFocus, setFilterHasFocus] = useState(false);
  const [focusedValue, setFocusedValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState();

  const focusMountedRef = useRef(false);
  const selectRef = useRef();

  const windowOpen = useMemo(
    () => (debugWindow ? true : !disabled && hasFocus && isOpen),
    [debugWindow, disabled, hasFocus, isOpen],
  );

  const materialPlaceholderValue = useMemo(() => {
    if (!materialPlaceholder) {
      return '';
    } else if (_.isString(materialPlaceholder)) {
      return materialPlaceholder;
    } else if (_.isString(placeholder) && !!placeholder) {
      return placeholder;
    }
    return '';
  }, [placeholder, materialPlaceholder]);

  useEffect(() => {
    const newOptions = React.Children.map(children, ({ props }) => ({ value: props.value, text: props.children }));
    setOptions((options) => (_.isEqual(options, newOptions) ? options : newOptions));
  }, [children]);

  const selectedOption = useMemo(() => _.find(options, (option) => _.isEqual(option.value, value)), [options, value]);

  useEffect(() => {
    if (!focusMountedRef.current) {
      focusMountedRef.current = true;
      return;
    }
    if (hasFocus) {
      if (_.isFunction(onFocus)) {
        onFocus();
      }
    } else {
      setIsOpen(false);

      // TODO: review why "onBlur" is invoked when the component "mounts".
      // Originally, this fired "onBlur" without an "event" parameter, which may be expected by the event handler.
      // For example, formik uses "onBlur" to "touch" a field. For that, it relies on the "event" parameter.
      // To avoid an exception on event handlers, an object with the target name is now passed as a parameter.
      if (_.isFunction(onBlur)) {
        onBlur({ target: { name } });
      }
    }
  }, [hasFocus, onBlur, onFocus, name]);

  // TODO: review why this doesn't work in some instances without the `useCallback`.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFocus = useCallback(
    _.debounce((hasFocus) => setHasFocus(hasFocus)),
    [],
  );

  const handleKeyDown = (event) => {
    const isCancel = event.key === 'Escape';
    const isConfirm = event.key === 'Enter' || event.key === ' ';

    if (isCancel) {
      if (!filterHasFocus) {
        setIsOpen(false);
      }
      selectRef.current.focus();
    } else if (isConfirm) {
      if (!filterHasFocus) {
        setIsOpen((isOpen) => !isOpen);
        selectRef.current.focus();
      }
      if (focusedValue && _.isFunction(onChange)) {
        onChange({ target: { name, value: focusedValue } });
      }
    }
  };

  const handleOptionClick = (value, event) => {
    event.preventDefault();
    setIsOpen(false);
    selectRef.current.focus();
    if (_.isFunction(onChange)) {
      onChange({ target: { name, value } });
    }
  };

  const valueDisplay = useMemo(() => {
    if (_.isFunction(valueRenderer)) {
      return valueRenderer(value);
    } else if (!_.isUndefined(valueRenderer)) {
      return valueRenderer;
    } else if (selectedOption) {
      return selectedOption.text;
    }
    return value;
  }, [value, valueRenderer, selectedOption]);
  const handleClear = (event) => {
    handleOptionClick('', event);
  };
  return (
    <Select
      tabIndex={disabled ? '-1' : '0'}
      data-testid={name}
      {...props}
      onFocus={() => debounceFocus(true)}
      onBlur={() => debounceFocus(false)}
      onKeyDown={handleKeyDown}
      ref={selectRef}>
      <Control
        role="button"
        hasFocus={!disabled && hasFocus}
        disabled={disabled}
        onMouseDown={() => setIsOpen((isOpen) => !isOpen)}>
        <Display>{value ? valueDisplay : <Placeholder>{placeholder}</Placeholder>}</Display>
        {value && showEmptyOption && (
          <ClearIndicator onMouseDown={(e) => e.stopPropagation()} isAnchor tabIndex={-1} onClick={handleClear}>
            <Icon icon="times" />
          </ClearIndicator>
        )}
        <Indicator>
          <Icon icon="angle-down" />
        </Indicator>
      </Control>
      {!!materialPlaceholderValue && (
        <MaterialPlaceholder isVisible={materialAlwaysVisible || !!value}>
          {materialPlaceholderValue}
        </MaterialPlaceholder>
      )}
      {windowOpen && (
        <OptionWindow
          value={value}
          showFilter={showFilter}
          filterPlaceholder={filterPlaceholder}
          noOptionsMessage={noOptionsMessage}
          showEmptyOption={showEmptyOption}
          setFilterHasFocus={setFilterHasFocus}
          setFocusedValue={setFocusedValue}
          placement={placement}
          onFilterChange={onFilterChange}
          onOptionClick={handleOptionClick}>
          {children}
        </OptionWindow>
      )}
    </Select>
  );
}

export default SingleSelect;
export { Select };
