import { Avatar, BackLink, Icon, Page, Tab, Tabs, Buttons, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useFeatures } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { AvailabilityPeriods } from './availability-periods';
import { CostPeriods } from './cost-periods';
import { SuppressedTooltip } from './components';
import MemberFilesTab from './files/MemberFilesTab';
import MemberDetailsForm from './MemberDetailsForm';
import MemberProjectsTab from './projects/MemberProjectsTab';
import { UtilizationTargetPeriods } from './utilization-target-periods';
import MemberHistoryDrawer from './MemberHistoryDrawer';
import MemberDropdown from './MemberDropdown.jsx';

const StyledTabs = styled(Tabs)`
  margin-top: 1.3rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const SuppressedIcon = styled(Icon)`
  margin-left: 0.5rem;
  font-size: 1rem;
`;

const StatusTag = styled(Tag)`
  color: ${({ isActive }) => (isActive ? colors.green : colors.grey25)};

  .icon {
    font-size: 0.625rem;
  }
`;

const Billable = styled.div`
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey40)};
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.4375rem;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HeaderEnd = styled.div`
  margin-left: auto;
  margin-right: 2.5rem;
  display: flex;
`;

const EditButton = styled.button`
  position: relative;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }

  transition: all 100ms ease-in-out;

  &:disabled {
    box-shadow: none;
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

const initialState = {
  isReady: false,
  project: null,
};
const handlers = {
  ready: ({ member, isSlackConnected, bamboohrIntegration }) => ({
    isReady: true,
    member,
    isSlackConnected,
    bamboohrIntegration,
  }),
};

function MemberDetailsArea(props) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, member, isSlackConnected, bamboohrIntegration }, actions] = useActions(handlers, initialState);

  const { path, url } = useRouteMatch();
  const { memberId } = useParams();
  const features = useFeatures();
  const [dialog, setDialog] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const [{ data: member }, { data: isSlackConnected }, { data: bamboohrIntegration }] = await Promise.all([
        api.www.workspaces(workspace.id).members(memberId).get(),
        api.www.workspaces(workspace.id).integrations().slack.getConnected(),
        api.www.workspaces(workspace.id).bamboohr.get(),
      ]);

      actions.ready({ member, isSlackConnected, bamboohrIntegration });
    } catch (error) {
      actions.ready({ member: null, isSlackConnected: false });
    }
  }, [actions, workspace.id, memberId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = (member) => {
    actions.ready({ member, isSlackConnected });
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  if (!isReady) return <PageLoader />;
  if (!member) return <ErrorPage.NotFound publicSite={false} />;
  return (
    <Page>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/members`} />
        <InfoContainer>
          <Eyebrow>Member Dashboard</Eyebrow>
          <MemberDropdown member={member} />
          <Tags>
            <StatusTag isActive={member.isActive}>
              <Icon icon="circle" spaceRight />
              {member.isActive ? 'Active' : 'Inactive'}
            </StatusTag>
            <Tag>
              {member.email}{' '}
              {member.suppressedEmail && (
                <SuppressedTooltip suppressedEmail={member.suppressedEmail}>
                  <SuppressedIcon icon="exclamation-circle" color={colors.danger} />
                </SuppressedTooltip>
              )}
            </Tag>
            {member.jobTitle && <Tag>{member.jobTitle?.name}</Tag>}
            <Tag>{member.securityRole.name}</Tag>
            <Tag style={{ padding: '0.3rem 1rem 0.4rem' }}>
              <Tooltip message={member.isBillable ? 'Billable' : 'Non-billable'}>
                <Billable isBillable={member.isBillable}>
                  <Icon icon="dollar-sign" />
                </Billable>
              </Tooltip>
            </Tag>
          </Tags>
        </InfoContainer>

        <HeaderEnd>
          <Buttons>
            <EditButton onClick={handleViewHistory} type="button">
              <Icon icon="history" />
            </EditButton>
          </Buttons>
        </HeaderEnd>

        <Avatar value={member} size={90} isCircle hasBackground hasBorder />
      </Page.Header>
      <StyledTabs>
        <Tab to={`${url}/general`}>General</Tab>
        <Tab to={`${url}/capacity`}>Capacity</Tab>
        <Tab to={`${url}/cost`}>Cost</Tab>
        {features.utilization && <Tab to={`${url}/target`}>Target</Tab>}
        <Tab to={`${url}/projects`}>Projects</Tab>
        <Tab to={`${url}/files`}>Files</Tab>
      </StyledTabs>
      <Content>
        {props.children || (
          <Switch>
            <Route path={`${path}/general`}>
              <MemberDetailsForm
                member={member}
                onSaved={handleSave}
                isSlackConnected={isSlackConnected}
                bamboohrIntegration={bamboohrIntegration}
              />
            </Route>

            <Route path={`${path}/capacity`}>
              <AvailabilityPeriods member={member} />
            </Route>

            <Route path={`${path}/cost`}>
              <CostPeriods member={member} />
            </Route>

            {features.utilization && (
              <Route path={`${path}/target`}>
                <UtilizationTargetPeriods member={member} />
              </Route>
            )}

            <Route path={`${path}/projects`}>
              <MemberProjectsTab member={member} />
            </Route>

            <Route path={`${path}/files`}>
              <MemberFilesTab member={member} />
            </Route>
            <Redirect from="/" to={`${url}/general`} />
          </Switch>
        )}
      </Content>

      {
        {
          history: <MemberHistoryDrawer memberId={member.id} onClose={handleCloseDialog} />,
        }[dialog?.type ?? dialog]
      }
    </Page>
  );
}

export default MemberDetailsArea;
