import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  margin: -0.375rem -0.75rem;

  > * {
    margin: 0.375rem 0.75rem;
  }
`;

const RadioGroup = React.forwardRef(({ name, value, onChange, children, ...props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { name, checked: child.props.value === value, onChange });
      })}
    </Container>
  );
});

export default RadioGroup;
