import { useField } from '~/hooks';
import _ from 'lodash';
import React, { useMemo } from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

const options = [
  { id: 'not_started', name: 'Not Started' },
  { id: 'in_progress', name: 'In Progress' },
  { id: 'completed', name: 'Completed' },
];

function ProjectTaskStatusSelect(props) {
  const text = useMemo(() => _.find(options, { id: props.value })?.name, [props.value]);

  return (
    <SingleSelect valueRenderer={text} {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldProjectTaskStatusSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectTaskStatusSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectTaskStatusSelect;
export { FieldProjectTaskStatusSelect };
