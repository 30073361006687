import { useApi, useSubscription, useWorkspace } from '~/contexts';
import { useAuth, useFeatures, usePolling } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import settings from '~/settings.js';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { CollapsedMenu, ExpandedMenu, SidebarLogo } from './sidebar';

const Sidebar = styled.aside`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: fixed;
  top: ${({ hasMessage }) => (hasMessage ? '2.5rem' : '0')};
  left: 0;
  bottom: 0;
  width: ${({ sidebarExpanded }) => (sidebarExpanded ? '11.5rem' : '4.75rem')};
  background-color: ${colors.black};
  z-index: 80;
`;

function WorkspaceSidebar({ sidebarExpanded }) {
  const api = useApi();
  const auth = useAuth();
  const features = useFeatures();
  const location = useLocation();
  const { subscribe, unsubscribe, notify } = useSubscription();
  const { workspace, hasMessage } = useWorkspace();
  const [timeApprovalsCount, setTimeApprovalsCount] = useState(null);
  const [expenseApprovalsCount, setExpenseApprovalsCount] = useState(null);

  useEffect(() => {
    if (!auth.time.approve) return;

    const fetchData = async (sid) => {
      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .timeAdmin()
          .getPendingApprovalsCount({ start: moment().subtract(45, 'days').format(dateFormats.isoDate) });
        setTimeApprovalsCount(data);
      } catch (error) {
        // If there's an API error, unsubscribe this event
        unsubscribe(sid);
      }
    };

    const sid = subscribe([useSubscription.keys.refresh_time_approval_count], (subscription) => {
      fetchData(subscription.id);
    });

    fetchData(sid);

    return () => {
      if (fetchData.cancel) {
        fetchData.cancel();
      }
      unsubscribe(sid);
    };
  }, [auth.time.approve, api, workspace, subscribe, unsubscribe]);

  useEffect(() => {
    if (!auth.expenses.approve || !features.expenseReporting) return;

    const fetchData = async (sid) => {
      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .expenseAdmin()
          .getPendingApprovalsCount({ start: moment().subtract(45, 'days').format(dateFormats.isoDate) });
        setExpenseApprovalsCount(data);
      } catch (error) {
        // If there's an API error, unsubscribe this event
        unsubscribe(sid);
      }
    };

    const sid = subscribe([useSubscription.keys.refresh_expense_approval_count], (subscription) => {
      fetchData(subscription.id);
    });

    fetchData(sid);

    return () => {
      if (fetchData.cancel) {
        fetchData.cancel();
      }
      unsubscribe(sid);
    };
  }, [auth.expenses.approve, api, workspace.id, subscribe, unsubscribe, features.expenseReporting]);

  usePolling(
    useCallback(() => {
      // Do not trigger the event if the tab/window is not visible
      if (document.hidden) return;

      notify(useSubscription.keys.refresh_time_approval_count);
      notify(useSubscription.keys.refresh_expense_approval_count);
    }, [notify]),
    settings.refreshApprovalCountSeconds != null ? settings.refreshApprovalCountSeconds * 1000 : null,
  );

  const navAreaId = useMemo(() => {
    if (!workspace?.key || !location?.pathname) {
      return null;
    }
    const path = location.pathname.replace(`/app/${workspace.key}`, '');
    if (
      path === '' ||
      ['dashboard', 'my-projects', 'my-tasks', 'my-allocations', 'my-exports', 'my-profile'].some((i) =>
        path.startsWith(`/${i}`),
      )
    ) {
      return 'home';
    }
    if (['projects', 'clients'].some((i) => path.startsWith(`/${i}`))) {
      return 'projects';
    }
    if (path.startsWith('/time')) {
      return 'time';
    }
    if (path.startsWith('/expenses')) {
      return 'expenses';
    }
    if (path.startsWith('/billing')) {
      return 'billing';
    }
    if (path.startsWith('/resources')) {
      return 'resources';
    }
    if (path.startsWith('/reports')) {
      return 'reports';
    }
    if (path.startsWith('/settings')) {
      return 'settings';
    }
    if (path.startsWith('/pipeline')) {
      return 'pipeline';
    }
    return null;
  }, [location, workspace]);

  const menuProps = {
    navAreaId,
    timeApprovalsCount,
    expenseApprovalsCount,
  };

  return (
    <Sidebar hasMessage={hasMessage} sidebarExpanded={sidebarExpanded}>
      <SidebarLogo sidebarExpanded={sidebarExpanded} />
      {sidebarExpanded ? <ExpandedMenu {...menuProps} /> : <CollapsedMenu {...menuProps} />}
    </Sidebar>
  );
}

export default WorkspaceSidebar;
