import { useAuth, useFeatures } from '~/hooks';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TimeApprovalsPage from './approvals/TimeApprovalsPage';
import TimeAuditingPage from './auditing/TimeAuditingPage';
import Calendar from './Calendar';
import CalendarRedirect from './CalendarRedirect';
import TimesheetsPage from './timesheets/TimesheetsPage';

function TimeArea() {
  const { path } = useRouteMatch();
  const auth = useAuth();
  const features = useFeatures();

  return (
    <Switch>
      {auth.time.approve && (
        <Route path={`${path}/approvals`}>
          <TimeApprovalsPage />
        </Route>
      )}

      <Route path={`${path}/auditing`}>
        <TimeAuditingPage />
      </Route>

      {features.timesheets && (
        <Route path={`${path}/timesheets`}>
          <TimesheetsPage />
        </Route>
      )}

      <Route path={`${path}/:view`}>
        <Calendar />
      </Route>

      <Route path={`${path}/`}>
        <CalendarRedirect />
      </Route>
    </Switch>
  );
}

export default TimeArea;
