import React from 'react';
import { Button, Buttons, ModalCard } from '~/components';

export default function UploadingWarning({ onClose }) {
  return (
    <ModalCard title="Files uploading" onClose={onClose}>
      <ModalCard.Body>
        There are one or more files currently being uploaded. Please wait for them to finish.
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <Button onClick={onClose}>OK</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
