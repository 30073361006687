import { Avatar, MemberContactPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';
import _ from 'lodash';

function ProjectMemberSelect({ value, project, initialValue, exclude, onSearch, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      if (onSearch) return onSearch(q);

      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .members()
        .get({
          q,
          isActive: true,
          include: initialValue ? initialValue.id : undefined,
          size: 1000,
          withGraph: 'member',
        });

      if (exclude) {
        _.remove(data, (x) => exclude.includes(x.id));
      }

      return data;
    },
    [workspace.id, initialValue, exclude, api, project.id, onSearch],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value?.member?.name ?? ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => (
        <>
          <MemberContactPopover member={option.member} placement="left">
            <Avatar value={option.member} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
          </MemberContactPopover>
          <span style={{ marginLeft: '.5rem' }}>{option.member.name}</span>
        </>
      )}
      {...props}></AutoComplete>
  );
}

function FieldProjectMemberSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectMemberSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectMemberSelect;
export { FieldProjectMemberSelect };
