const sources = {
  api: { id: 'api', name: 'Ruddr API' },
  bamboohr: { id: 'bamboohr', name: 'BambooHR' },
  csv: { id: 'csv', name: 'CSV Import' },
  expensify: { id: 'expensify', name: 'Expensify' },
  harvest: { id: 'harvest', name: 'Harvest Import' },
  macos: { id: 'macos', name: 'Ruddr MacOS App' },
  mobile: { id: 'mobile', name: 'Ruddr Mobile App' },
  qbo: { id: 'qbo', name: 'QuickBooks Online' },
  slack: { id: 'slack', name: 'Slack' },
  system: { id: 'system', name: 'Ruddr Internal Process' },
  web: { id: 'web', name: 'Ruddr Web App' },
  xero: { id: 'xero', name: 'Xero' },
};

export default sources;
