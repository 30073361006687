import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import { emptyStringToNull, mergeValues } from '~/utils';

function ProjectTypeDrawer({ projectType, onClose, onSaved, onDeleted }) {
  const title = `${projectType?.id ? 'Edit' : 'New'} Project Type`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [hasDependencies, setHasDependencies] = useState(true);

  useEffect(() => {
    if (!projectType?.id) {
      setHasDependencies(false);
      return;
    }

    (async function fetchData() {
      const dependencies = await api.www.workspaces(workspace.id).projectTypes(projectType.id).hasDependencies();
      setHasDependencies(dependencies.data);
    })();
  }, [api, projectType, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues({ name: '', invoiceItem: null }, projectType);

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const body = emptyStringToNull({
              ..._.omit(values, 'invoiceItem'),
              invoiceItemId: values.invoiceItem?.id ?? null,
            });

            const { data } = await api.www.workspaces(workspace.id).projectTypes(projectType?.id).upsert(body);

            await onSaved(data);
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Project Type successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>
              Are you sure that you want to delete this Project Type?
            </DeleteConfirmation>
          ));
          if (!confirm) return;
          try {
            await api.www.workspaces(workspace.id).projectTypes(projectType.id).delete();
            await onDeleted();
            closeDrawer();
          } catch ({ message }) {
            toast.error(message);
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().label('Name').max(255).required(),
              invoiceItem: Yup.object().label('Invoice Item').nullable().required(),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                formik.submitForm();
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <Field.InvoiceItemSelect
                        name="invoiceItem"
                        placeholder="Invoice Item"
                        type="income"
                        clearable={false}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    {projectType.id && !hasDependencies && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!projectType?.id && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}

                      <ActionButton
                        isLoading={isSubmitting === 'close'}
                        ok={saved === 'close'}
                        type="submit"
                        onClick={() => submit('close')}>
                        Save &amp; Close
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default ProjectTypeDrawer;
