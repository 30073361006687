import {
  ApprovalStatusFilter,
  BackLink,
  Checkbox,
  ClientApprovalStatusFilter,
  Hours,
  Icon,
  Level,
  Percent,
  RouteLink,
  Stack,
  Table,
  Inline,
  HelpTooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import employmentTypes from '~/lookups/employment-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import PeriodNavTag from '../components/PeriodNavTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import useMemberFilters from '~/hooks/useMemberFilters';
import MemberTagsGroup from '../components/MemberTagsGroup';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import ExportDialogAsync from '../components/ExportDialogAsync';

const columns = {
  capacity: { default: 'capacity', time_off_adjusted: 'capacityTimeOffAdjusted' },
  billableUtilization: { default: 'billableUtilization', time_off_adjusted: 'billableUtilizationTimeOffAdjusted' },
  clientUtilization: { default: 'clientUtilization', time_off_adjusted: 'clientUtilizationTimeOffAdjusted' },
  productiveUtilization: {
    default: 'productiveUtilization',
    time_off_adjusted: 'productiveUtilizationTimeOffAdjusted',
  },
  totalUtilization: { default: 'totalUtilization', time_off_adjusted: 'totalUtilizationTimeOffAdjusted' },
  targetAttainment: { default: 'targetAttainment', time_off_adjusted: 'targetAttainmentTimeOffAdjusted' },
};

function UtilizationByMember() {
  useDocumentTitle('Utilization by Member');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();

  const memberFilters = useMemberFilters(() => ({
    employmentTypes: { default: [employmentTypes.employee], ...searchParamsConfig.employmentTypes },
    memberBillableType: reportsSearchParamsConfig.utilization.memberBillableType,
  }));

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    utilizationMode: 'default',
    includeMembersWithoutCapacity: false,
    sort: new QuerySort('name', 'asc'),
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        utilizationMode: {
          valid: ['default', 'time_off_adjusted'],
          default: workspace.defaultAdjustForTimeOff ? 'time_off_adjusted' : 'default',
        },
        includeMembersWithoutCapacity: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, memberFilters, workspace.defaultAdjustForTimeOff],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      includeMembersWithoutCapacity: params.includeMembersWithoutCapacity ? 'true' : undefined,
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().utilizationByMember(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleUtilizationModeChange = (e) => {
    const utilizationMode = { true: 'time_off_adjusted', false: 'default' }[e.target.checked];

    let sort = params.sort;

    const key = _.findKey(columns, (v) => v[params.utilizationMode] === params.sort.column);
    if (key) {
      const column = columns[key][utilizationMode];
      sort = new QuerySort(column, params.sort.direction);
      setQuery((state) => ({ ...state, status: 'sorting' }));
    }

    setParams({ ...params, sort, utilizationMode });
    searchParams.set({ utilizationMode, sort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .utilizationByMember(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/utilization`} />

        <Stack>
          <h1>Utilization by Member</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            {params.includeMembersWithoutCapacity && <Tag>Include members without capacity</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data
                  query={query}
                  params={params}
                  onSort={handleSort}
                  onUtilizationModeChange={handleUtilizationModeChange}
                />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onUtilizationModeChange }) {
  const report = query.data;

  const { workspace } = useWorkspace();

  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start,
          end: params.period?.end,
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, memberFilters],
  );

  const totals = {
    default: {
      capacity: report.totals.capacity,
      billableUtilization: report.totals.billableUtilization,
      clientUtilization: report.totals.clientUtilization,
      productiveUtilization: report.totals.productiveUtilization,
      totalUtilization: report.totals.totalUtilization,
      targetAttainment: report.totals.targetAttainment,
    },

    time_off_adjusted: {
      capacity: report.totals.capacityTimeOffAdjusted,
      billableUtilization: report.totals.billableUtilizationTimeOffAdjusted,
      clientUtilization: report.totals.clientUtilizationTimeOffAdjusted,
      productiveUtilization: report.totals.productiveUtilizationTimeOffAdjusted,
      totalUtilization: report.totals.totalUtilizationTimeOffAdjusted,
      targetAttainment: report.totals.targetAttainmentTimeOffAdjusted,
    },
  }[params.utilizationMode];

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level margin="1rem 0">
        <Level.Item>
          <Inline>
            <Checkbox
              label="Adjust for Time Off"
              checked={params.utilizationMode === 'time_off_adjusted'}
              onChange={onUtilizationModeChange}
            />
            <HelpTooltip
              style={{ marginLeft: '.5rem' }}
              message="Reduces the capacity by the number of Time Off hours taken during the period."
            />
          </Inline>
        </Level.Item>
        <Level.Item>
          <TotalResults style={{ display: 'flex', alignItems: 'center' }}>
            {query.status !== 'ready' && <Icon style={{ marginBottom: 0 }} icon="spinner" spin spaceRight />}
            <Table.Total label="Member" style={{ marginBottom: 0 }} value={report.records.length} />
            {report.partialResults && <PartialResultsTooltip style={{ marginBottom: 0 }} />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="name" onSort={onSort} sort={params.sort}>
            Member
          </Table.Column>
          <Table.Column name="employmentTypeId" width="8rem" onSort={onSort} sort={params.sort}>
            Member Type
          </Table.Column>
          <Table.Column
            name={columns.capacity[params.utilizationMode]}
            width="6.5rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Capacity
          </Table.Column>
          <Table.Column name="billableHours" width="6.5rem" align="right" onSort={onSort} sort={params.sort}>
            Billable Hours
          </Table.Column>
          <Table.Column
            name={columns.billableUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Billable Utilization
          </Table.Column>
          <Table.Column
            name={columns.clientUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Client Utilization
          </Table.Column>
          <Table.Column
            name={columns.productiveUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Productive Utilization
          </Table.Column>
          <Table.Column
            name={columns.totalUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Total Utilization
          </Table.Column>
          <Table.Column name="targetBillableUtilization" width="7rem" align="right" onSort={onSort} sort={params.sort}>
            Target Billable Utilization
          </Table.Column>
          <Table.Column
            name={columns.targetAttainment[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Target Attainment
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.status === 'sorting'}>
          {report.records.map((member) => {
            const values = {
              default: {
                capacity: member.capacity,
                billableUtilization: member.billableUtilization,
                clientUtilization: member.clientUtilization,
                productiveUtilization: member.productiveUtilization,
                totalUtilization: member.totalUtilization,
                targetAttainment: member.targetAttainment,
              },

              time_off_adjusted: {
                capacity: member.capacityTimeOffAdjusted,
                billableUtilization: member.billableUtilizationTimeOffAdjusted,
                clientUtilization: member.clientUtilizationTimeOffAdjusted,
                productiveUtilization: member.productiveUtilizationTimeOffAdjusted,
                totalUtilization: member.totalUtilizationTimeOffAdjusted,
                targetAttainment: member.targetAttainmentTimeOffAdjusted,
              },
            }[params.utilizationMode];

            return (
              <Table.Row key={member.id}>
                <Table.Cell>{member.name}</Table.Cell>
                <Table.Cell>{member.employmentType.name}</Table.Cell>
                <Table.Cell>
                  <Hours value={values.capacity} />
                </Table.Cell>
                <Table.Cell>
                  <RouteLink to={timeDetail({ billableType: 'billable', member: member.id })}>
                    <Hours value={member.billableHours} />
                  </RouteLink>
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.billableUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.clientUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.productiveUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.totalUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={member.targetBillableUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.targetAttainment} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell />
            <Table.Cell>
              <Hours value={totals.capacity} />
            </Table.Cell>
            <Table.Cell>
              {/* This does not click-through because the Time Detail report doesn't support
                  including a subset of members based on member-permissions only. */}
              <Hours value={report.totals.billableHours} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.billableUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.clientUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.productiveUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.totalUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.targetBillableUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.targetAttainment} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeMembersWithoutCapacity}
          label="Include members without capacity"
          onChange={({ target: { checked } }) => handleFilter({ includeMembersWithoutCapacity: checked })}
        />
      </Filter>

      <Filter>
        <ReportPeriodFilter
          clearable={false}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      {auth.reports.utilization && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <FiltersGroup label="Time Filters" filters={[filters.approvalStatuses, filters.clientApprovalStatuses]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}
      </FiltersGroup>
    </Report.Filters>
  );
}

export default UtilizationByMember;
