import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Button, Currency, Table } from '~/components';
import { useApi } from '~/contexts';
import { DownloadArea, EmptyReport, Report, ReportLoader, Workspace } from './components';

const formatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 2,
});

export default function InvoicesPerWorkspace({ start, end }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getInvoicesPerWorkspace({ start, end });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api, start, end]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getInvoicesPerWorkspace({ start, end });
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <Table>
        <Table.Header>
          <Table.Column>Workspace</Table.Column>
          <Table.Column width="14rem" align="right">
            Published Invoices
          </Table.Column>
          <Table.Column width="14rem" align="right" name="total" sort={{ column: 'total', direction: 'desc' }}>
            Total Amount
          </Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              <Workspace data={item} />
            </Table.Cell>
            <Table.Cell>{formatter.format(item.invoiceCount)}</Table.Cell>
            <Table.Cell>
              <Currency value={item.invoiceTotal} currency="USD" />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
