import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIsMounted } from '~/hooks';
import { colors } from '~/styles';
import chatbotAvatar from '../assets/chatbot-avatar.png';
import { useMember } from './MemberContext';

const key = 'c178fbc0-a1a4-4e53-93fe-6e50746e9a3f';

const ChatContext = React.createContext();

function zE(...args) {
  if (!window.zE) {
    return;
  }
  return window.zE(...args);
}

function setStyles() {
  // For some reason, `webWidget` isn't available on "open" immediately.
  // So just setting a timeout as a quick hack. These styles are not critical.
  setTimeout(() => {
    const widget = document.getElementById('webWidget');
    if (!widget) {
      return;
    }

    const style = document.createElement('style');

    style.innerHTML = `
      .avatar-3raPF {
        border-radius: 0;
      }
    `;

    widget.contentDocument.getElementById('Embed').appendChild(style);
  }, 500);
}

function loadScript(onLoad) {
  // Comment out this next bit if you need the chat widget to load in local dev
  const hostname = window.location.hostname;
  if (!key || !(hostname.endsWith('ruddr.io') || hostname.endsWith('ruddr.dev'))) {
    return;
  }

  window.zESettings = {
    webWidget: {
      answerBot: {
        avatar: {
          name: { '*': 'Ruddy' },
          url: chatbotAvatar,
        },
      },
      color: {
        launcher: colors.primary,
        launcherText: colors.white,
      },
    },
  };

  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.async = true;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  script.onload = onLoad;

  document.body.appendChild(script);
}

function ChatProvider({ children }) {
  const isMounted = useIsMounted();
  const { member } = useMember();
  const [isReady, setIsReady] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const firstRun = useRef(true);

  // Load the script and initial settings
  useEffect(() => loadScript(() => setIsReady(true)), []);

  // Respond to events
  // TODO: Currently, there doesn't appear to be a way to unsubscribe from
  // events. Because of this, if this component remounts, there will be multiple
  // instances of each function run. The temporary workaround is to check to see
  // if the component is mounted before running the side effects. Because of
  // where this should be in the tree, that shouldn't happen in production, but
  // auto-reloads from dev will add an event listener function instance for each
  // event listed below.
  useEffect(() => {
    if (!isReady) {
      return;
    }
    zE('webWidget:on', 'chat:unreadMessages', (number) => {
      if (!isMounted.current) {
        return;
      }
      setHasUnreadMessages(number > 0);
    });
    zE('webWidget:on', 'open', () => {
      if (!isMounted.current) {
        return;
      }
      setIsOpen(true);
      setStyles();
    });
    zE('webWidget:on', 'close', () => {
      if (!isMounted.current) {
        return;
      }
      setIsOpen(false);
      setHasUnreadMessages(false);
    });
  }, [isReady, isMounted]);

  // Identify the current member
  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (member) {
      zE('webWidget', 'identify', {
        name: member.name,
        email: member.email,
      });
    } else {
      zE('webWidget', 'logout');
    }
  }, [isReady, member]);

  // Manages visibility
  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (isVisible || hasUnreadMessages) {
      if (!firstRun.current) {
        zE('webWidget', 'show');
      }
    } else if (!isOpen) {
      zE('webWidget', 'hide');
      zE('webWidget', 'close');
    }
    firstRun.current = false;
  }, [isReady, isOpen, isVisible, hasUnreadMessages]);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  const open = () => {
    zE('webWidget', 'show');
    zE('webWidget', 'open');
  };

  const value = {
    show,
    hide,
    open,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

function useChat() {
  return useContext(ChatContext);
}

export { ChatContext, useChat, ChatProvider };
