import { rgba } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';

const Box = styled.div`
  background-color: ${rgba(colors.primary, 0.1)};
  padding: ${({ addDismissPadding = true }) =>
    addDismissPadding ? '1rem 6.75rem 1rem 1.1875rem' : '1rem 1.75rem 1rem 1.1875rem'};

  font-size: 0.75rem;
  font-style: italic;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  line-height: 1.75;

  ${({ spaceTop }) =>
    spaceTop &&
    css`
      margin-top: 1rem;
    `}

  ${({ spaceBottom }) =>
    spaceBottom &&
    css`
      margin-bottom: 1rem;
    `}
`;

const Text = styled.div`
  text-align: justify;
  flex: 1;
`;

const Info = styled.div`
  font-size: 1.5rem;
  color: ${colors.primary};
  flex: 0;
  margin-right: 1rem;
`;

function HelpMessage({ messageKey, children, ...props }) {
  return (
    <Box {...props} addDismissPadding={!!messageKey}>
      <Info>
        <Icon icon="info-circle" />
      </Info>
      <Text>{children}</Text>
    </Box>
  );
}

export default HelpMessage;
