import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import AutoComplete from '~/components/AutoComplete';
import Dropdown from '~/components/Dropdown';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AutoCompleteMulti from '../AutoCompleteMulti';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

export default function OpportunityStageFilter({ value, opportunityStageStatusId, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .opportunityStages()
        .get({ q, isActive: true, statusId: opportunityStageStatusId ? opportunityStageStatusId : undefined });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.statusId;

          a[key] = a[key] || { key, opportunityStages: [] };
          a[key].opportunityStages.push(v);
          return a;
        }, {}),
        'key',
      );

      const groupOrder = ['open', 'won', 'lost'];
      const orderedGroups = groupOrder.map((statusId) => groups.find((group) => group.key === statusId));

      const options = [];
      for (const group of orderedGroups) {
        if (group) {
          options.push({ type: 'group', group });
          group.opportunityStages.forEach((opportunityStage) => {
            options.push({ type: 'opportunityStage', opportunityStage, status: group.key });
          });
        }
      }

      return options;
    },
    [workspace.id, opportunityStageStatusId, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.id === o.opportunityStage?.id));

  const handleFilteredSearch = (q) => {
    const options = handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      value={value}
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      placeholder="All"
      materialPlaceholder="Opportunity Stage"
      materialAlwaysVisible
      {...props}>
      {({ options }) => {
        let index = 0;
        const filteredOptions = options.filter((o) => {
          if (o.type === 'group') {
            const result = options.some((v) => v.status == o.group.key);
            return result;
          } else {
            return true;
          }
        });

        return filteredOptions.length > 0 ? (
          filteredOptions.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.group.key}>
                    <strong>{{ open: 'Open', won: 'Won', lost: 'Lost' }[o.group.key]}</strong>
                  </Group>
                );
              case 'opportunityStage':
                return (
                  <AutoComplete.Option
                    grey={!o.opportunityStage.isActive ? 'true' : undefined}
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.opportunityStage.id}
                    value={o?.opportunityStage}
                    index={index++}>
                    <span
                      style={{
                        marginLeft: '.5rem',
                      }}>{`${o.opportunityStage.name} (${o.opportunityStage.probability}%) `}</span>
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoCompleteMulti>
  );
}
