import React, { useRef } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useForm } from '~/hooks';
import { useApi, useWorkspace } from '~/contexts';
import { Button, Buttons, CancelButton, ModalCard, Radio, RadioGroup } from '~/components';
import { colors } from '~/styles';

const ErrorMessage = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const DowngradeMessage = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: ${colors.warning};
  border-radius: 0.25rem;
`;

const Plan = styled.div`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const Title = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

export default function EditPlan({ customer, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const formRef = useRef();
  const [{ status, message, isSubmitting }, form] = useForm();

  const initialValues = {
    billingIntervalId: 'year',
    stripeProductKey: 'pro',
  };

  if (customer.subscription?.price) {
    const { recurringInterval, recurringIntervalCount } = customer.subscription.price;
    if (recurringInterval === 'month' && recurringIntervalCount === 1) {
      initialValues.billingIntervalId = 'month';
    } else if (recurringInterval === 'month' && recurringIntervalCount === 3) {
      initialValues.billingIntervalId = 'quarter';
    } else if (recurringInterval === 'year' && recurringIntervalCount === 1) {
      initialValues.billingIntervalId = 'year';
    }
  } else if (workspace.billingIntervalId) {
    initialValues.billingIntervalId = workspace.billingIntervalId;
  }

  if (customer.subscription?.price?.product?.key) {
    initialValues.stripeProductKey = customer.subscription.price.product.key;
  } else if (workspace.stripeProductKey) {
    initialValues.stripeProductKey = workspace.stripeProductKey;
  }

  const schema = Yup.object().shape({
    billingIntervalId: Yup.string().label('Plan Renewal Frequency').oneOf(['month', 'quarter', 'year']).required(),
    stripeProductKey: Yup.string().label('Product Identifier').required(),
  });

  const handleSubmit = async (values) => {
    try {
      form.submit();

      const { data } = await api.www.workspaces(workspace.id).stripe.updatePlan(values);

      onSaved(data);

      form.save();

      onClose();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  };

  const formatCurrency = (amount) => (amount % 1 !== 0 ? amount.toFixed(2) : amount);

  return (
    <ModalCard title="Change Plan Details" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {(formik) => (
          <>
            <ModalCard.Body>
              {status && <ErrorMessage>{message}</ErrorMessage>}
              {initialValues.stripeProductKey !== formik.values.stripeProductKey &&
                formik.values.stripeProductKey === 'team' && (
                  <DowngradeMessage>
                    You are downgrading your plan. Pro Plan features will no longer be available.
                  </DowngradeMessage>
                )}
              {customer.products?.map((product) => {
                const monthPrice = _.find(product.prices, { recurringInterval: 'month', recurringIntervalCount: 1 });
                const quarterPrice = _.find(product.prices, { recurringInterval: 'month', recurringIntervalCount: 3 });
                const yearPrice = _.find(product.prices, { recurringInterval: 'year', recurringIntervalCount: 1 });
                return (
                  <Plan key={product.id}>
                    <Title>{product.name}</Title>
                    <RadioGroup
                      name={product.key}
                      value={formik.values.stripeProductKey === product.key ? formik.values.billingIntervalId : null}
                      onChange={(value) => {
                        formik.setFieldValue('stripeProductKey', product.key);
                        formik.setFieldValue('billingIntervalId', value);
                      }}>
                      {monthPrice && (
                        <Radio value="month" label={`Monthly ($${formatCurrency(monthPrice.price)} per month)`} />
                      )}
                      {quarterPrice && (
                        <Radio value="quarter" label={`Quarterly ($${formatCurrency(quarterPrice.price)} per month)`} />
                      )}
                      {yearPrice && (
                        <Radio value="year" label={`Annually ($${formatCurrency(yearPrice.price)} per month)`} />
                      )}
                    </RadioGroup>
                  </Plan>
                );
              })}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton disabled={isSubmitting} onClick={handleClose}>
                  Cancel
                </CancelButton>
                <Button isLoading={isSubmitting} type="submit" onClick={formik.submitForm}>
                  Save
                </Button>
              </Buttons>
            </ModalCard.Footer>
          </>
        )}
      </Formik>
    </ModalCard>
  );
}
