import styled from 'styled-components';
import Filters from './Filters';

const Report = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const Summary = styled.div`
  margin-top: 2rem;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};
`;

const Table = styled.div`
  margin-top: 4rem;
`;

Report.Summary = Summary;
Report.Table = Table;
Report.Filters = Filters;

export default Report;
