import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';
import _ from 'lodash';
import clientFiles from './clientFiles';

const clients =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'clients', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      detailsCard: () => get(url.concat('/details-card')),
      history: () => get(url.concat('/history')),
      collapse: ({ targetClientId }) => put(url.concat('/collapse'), { targetClientId }),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      setRecordStatus: ({ recordStatusId }) => put(url.concat('/record-status'), { recordStatusId }),
      projects: (projectKey) => ({
        get: () => get(url.concat(`/projects/${projectKey}`)),
        graph: () => get(url.concat(`/projects/${projectKey}/graph`)),
      }),
      files: clientFiles(url.concat('/files')),
      getRealizationRateKPI: () => get(url.concat('/kpi/realization-rate')),
      getEffectiveRateKPI: () => get(url.concat('/kpi/effective-rate')),
      getClientMarginKPI: () => get(url.concat('/kpi/margin')),
      getClientHoursKPI: () => get(url.concat('/kpi/hours')),
      getClientExpensesKPI: () => get(url.concat('/kpi/expenses')),
      getClientRevenueKPI: () => get(url.concat('/kpi/revenue')),
      findDuplicate: (query = {}) =>
        get(url.concat('/new/find-duplicate').concat(new QueryString(query).toString(true))),
    };
  };

export default clients;
