import axios from 'axios';
import { v4 as uuid } from 'uuid';

// `URL.createObjectURL` is only valid for as long as the `document` exists.
// Since `sessionStorage` can last through page refreshes, create a unique
// id on load to ensure we're not pulling outdated cache hits.
const documentId = uuid();

export default async function getCachedPreviewUrl(key, url) {
  // Attempt to grab the cached URL, assuming it has the same `documentId`
  if (sessionStorage) {
    const cachedItem = sessionStorage.getItem(key);
    if (cachedItem) {
      try {
        const cachedObject = JSON.parse(cachedItem);
        if (typeof cachedObject === 'object' && cachedObject.documentId === documentId) {
          // Success! Use the cached URL
          return cachedObject.url;
        } else {
          // The object was invalid or didn't match the `documentId`
          sessionStorage.removeItem(key);
        }
      } catch {
        // Could be hit if `JSON.parse` tries to parse invalid JSON
        sessionStorage.removeItem(key);
      }
    }
  }

  // At this point there is no cached URL or the cached URL was invalid
  const { data } = await axios.get(url, { responseType: 'blob' });
  const previewUrl = URL.createObjectURL(data);

  // Cache the result, if possible
  if (sessionStorage) {
    const data = {
      documentId,
      url: previewUrl,
    };
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  // Send the newly generated URL onward!
  return previewUrl;
}
