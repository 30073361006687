import icon from '~/assets/public/xero-icon.png';
import { Tooltip } from '~/components';
import { useIntegrations } from '~/contexts';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;

const Image = styled.img`
  width: 1rem;
  height: 1rem;
`;

export default function XeroIndicator({ message = 'This invoice is in Xero.' }) {
  const integrations = useIntegrations();
  if (!integrations.xero) return null;

  return (
    <Wrapper>
      <Tooltip message={message}>
        <Image src={icon} />
      </Tooltip>
    </Wrapper>
  );
}
