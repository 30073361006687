/* global clarity */
import { useEffect, useState } from 'react';
import { useMember } from '~/contexts';

export default function Clarity() {
  const { member } = useMember();
  const [isLoaded, setIsLoaded] = useState(false);

  // Detect when Clarity has been loaded from GTM
  useEffect(() => {
    if (typeof clarity !== 'undefined') {
      setIsLoaded(true);
    } else {
      window['_clarity_ready'] = () => {
        setIsLoaded(true);
      };
    }
    return () => {
      delete window['_clarity_ready'];
    };
  }, []);

  // Set the current member
  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (member) {
      clarity('set', 'userId', member.id);
      clarity('set', 'userName', member.name);
      clarity('set', 'userEmail', member.email);
    }
  }, [isLoaded, member]);

  return null;
}
