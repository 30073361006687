import {
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  MemberFilter,
  PracticeFilter,
  LocationFilter,
  IndustryFilter,
} from '~/components';
import { Filter } from '~/components/filters';
import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import FiltersGroup from './FiltersGroup';

function ClientFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Client Filters"
      filters={[
        filters.clients,
        filters.clientRecordStatusId,
        filters.clientPractices,
        filters.clientSalesRepresentatives,
        filters.clientOwners,
        filters.clientTags,
        filters.clientLocations,
        filters.clientIndustries,
      ]}>
      <Filter>
        <ClientFilter
          value={filters.clients}
          onChange={({ target: { value } }) => {
            const result = { clients: value };

            if (_.has(filters, 'projects')) result.projects = [];
            if (_.has(filters, 'projectRoles')) result.projectRoles = [];
            if (_.has(filters, 'projectTasks')) result.projectTasks = [];

            onChange(result);
          }}
        />
      </Filter>

      <Filter>
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ target: { value } }) => onChange({ clientRecordStatusId: value })}
        />
      </Filter>

      {features.practices && (
        <Filter>
          <PracticeFilter
            value={filters.clientPractices}
            materialPlaceholder="Client Practice"
            onChange={({ target: { value } }) => onChange({ clientPractices: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberFilter
          value={filters.clientSalesRepresentatives}
          materialPlaceholder="Client Sales Representative"
          onChange={({ target: { value } }) => onChange({ clientSalesRepresentatives: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.clientOwners}
          materialPlaceholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onChange({ clientOwners: value })}
        />
      </Filter>

      <Filter>
        <ClientTagFilter
          value={filters.clientTags}
          onChange={({ target: { value } }) => onChange({ clientTags: value })}
        />
      </Filter>

      <Filter>
        <LocationFilter
          value={filters.clientLocations}
          materialPlaceholder="Client Location"
          onChange={({ target: { value } }) => onChange({ clientLocations: value })}
        />
      </Filter>

      <Filter>
        <IndustryFilter
          value={filters.clientIndustries}
          materialPlaceholder="Client Industry"
          onChange={({ target: { value } }) => onChange({ clientIndustries: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}

export default ClientFiltersGroup;
