import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';

const Toggle = styled.div`
  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  width: 40px;
  height: 20px;
  background-color: ${colors.grey25};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;

  input[type='checkbox'] {
    width: 0;
    opacity: 0;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: ${colors.primary};

      ${Toggle}:after {
        transform: translateX(20px);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const Text = styled.span`
  white-space: nowrap;
  margin-left: 35px;
`;

const ToggleSwitch = React.forwardRef(({ label, ...props }, ref) => {
  return (
    <Label checked={props.checked} disabled={props.disabled}>
      <input {...props} type="checkbox" ref={ref} />
      <Toggle />
      <Text>{label}</Text>
    </Label>
  );
});

export default ToggleSwitch;
