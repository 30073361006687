import { DateTime, Form, Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const HistoryItem = styled.div`
  position: relative;
  margin-left: 1.25rem;
  padding-left: 3.75rem;
  padding-bottom: 2rem;
  border-left: solid 2px ${colors.grey10};

  &:last-child {
    padding-bottom: 0;
    border-color: transparent;
  }
`;

const HistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -1.3125rem;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${colors.white};
  border: solid 3px ${({ color }) => color};
  border-radius: 50%;
`;

const HistoryTitle = styled.div`
  font-weight: ${weights.bold};
`;

const HistoryDetails = styled.div`
  font-size: 0.875rem;
  color: ${colors.grey75};
`;

const HistoryNotes = styled.div`
  margin-top: 0.75rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-style: italic;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

function ExpenseItemHistoryIcon({ color = colors.black, size = '1rem', ...props }) {
  return (
    <HistoryIcon color={color} size={size}>
      <Icon {...props} />
    </HistoryIcon>
  );
}

export default function ExpenseItemHistory({ history }) {
  return (
    <div>
      <Form.Section title="Expense Item History">
        {history &&
          history.map((item) => (
            <HistoryItem key={item.id}>
              {
                {
                  created: <ExpenseItemHistoryIcon icon="plus" color={colors.primary} size="1rem" />,
                  imported: <ExpenseItemHistoryIcon icon="file-import" color={colors.primary} size="1rem" />,
                  updated: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                  submitted: <ExpenseItemHistoryIcon icon="inbox-in" color={colors.primary} size="0.875rem" />,
                  rejected: <ExpenseItemHistoryIcon icon="times" color={colors.primary} size="1rem" />,
                  approved: <ExpenseItemHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                  auto_approved: <ExpenseItemHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                  pending_approval: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                  not_submitted: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                  client_approved: <ExpenseItemHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                  client_rejected: <ExpenseItemHistoryIcon icon="times" color={colors.primary} size="0.875rem" />,
                  client_pending_approval: (
                    <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />
                  ),
                  approval_step_approved: (
                    <ExpenseItemHistoryIcon icon="thumbs-up" color={colors.primary} size="0.875rem" />
                  ),
                  approval_step_rejected: (
                    <ExpenseItemHistoryIcon icon="thumbs-down" color={colors.primary} size="0.875rem" />
                  ),
                  approval_step_reset: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                  reimbursed: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                  unreimbursed: <ExpenseItemHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
                }[item.status.id]
              }
              <HistoryTitle>{item.status?.name}</HistoryTitle>
              <HistoryDetails>
                <DateTime value={item.timestamp} format="l [at] LT" />{' '}
                {(item.member || !item.isExternal) && <>by {item.member ? item.member.name : 'Ruddr'} </>}
                {item.isExternal ? 'in' : 'via'} {item.source.name}
              </HistoryDetails>
              {item.notes && <HistoryNotes>{item.notes}</HistoryNotes>}
            </HistoryItem>
          ))}
      </Form.Section>
    </div>
  );
}
