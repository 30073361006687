import intervalOptions from './intervalOptions';

const intervalsByScope = {
  get day() {
    return [
      intervalOptions.custom,
      intervalOptions.today,
      intervalOptions.yesterday,
      intervalOptions.this_week,
      intervalOptions.this_week_to_date,
      intervalOptions.this_semi_month,
      intervalOptions.this_semi_month_to_date,
      intervalOptions.this_month,
      intervalOptions.this_month_to_date,
      intervalOptions.this_quarter,
      intervalOptions.this_quarter_to_date,
      intervalOptions.this_year,
      intervalOptions.this_year_to_date,
      intervalOptions.last_week,
      intervalOptions.last_semi_month,
      intervalOptions.last_month,
      intervalOptions.last_quarter,
      intervalOptions.last_year,
      intervalOptions.past_7_days,
      intervalOptions.past_30_days,
      intervalOptions.past_60_days,
      intervalOptions.past_90_days,
      intervalOptions.past_180_days,
      intervalOptions.past_365_days,
    ];
  },

  get week() {
    return [
      intervalOptions.custom,
      intervalOptions.this_week,
      intervalOptions.last_week,
      intervalOptions.past_4_weeks,
      intervalOptions.past_12_weeks,
      intervalOptions.past_26_weeks,
      intervalOptions.past_52_weeks,
    ];
  },

  get month() {
    return [
      intervalOptions.custom,
      intervalOptions.this_month,
      intervalOptions.this_quarter,
      intervalOptions.this_year,
      intervalOptions.last_month,
      intervalOptions.last_quarter,
      intervalOptions.last_year,
    ];
  },
};

export default intervalsByScope;
