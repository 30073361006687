import { Grid, Level, PeriodFilter, SingleSelect } from '~/components';
import { useLocalStorage } from '~/hooks';
import _ from 'lodash';
import React, { useState } from 'react';
import { intervalOptions } from '~/utils';
import Section from './components/Section';
import Separator from './components/Separator';
import ProjectEffectiveRateWidget from './widgets/ProjectEffectiveRateWidget';
import ProjectExpensesWidget from './widgets/ProjectExpensesWidget';
import ProjectHoursWidget from './widgets/ProjectHoursWidget';
import ProjectMarginWidget from './widgets/ProjectMarginWidget';
import ProjectRealizationRateWidget from './widgets/ProjectRealizationRateWidget';
import ProjectRevenueWidget from './widgets/ProjectRevenueWidget';
import HoursWidget from './widgets/project-progress/hours/HoursWidget';
import MyHoursWidget from './widgets/project-progress/my-hours/MyHoursWidget';
import ServicesCostWidget from './widgets/project-progress/services-cost/ServicesCostWidget';
import ServicesMarginWidget from './widgets/project-progress/services-margin/ServicesMarginWidget';
import ServicesProfitWidget from './widgets/project-progress/services-profit/ServicesProfitWidget';
import ServicesRevenueProgress from './widgets/project-progress/services-revenue/ServicesRevenueWidget';
import TotalCostWidget from './widgets/project-progress/total-cost/TotalCostWidget';
import TotalMarginWidget from './widgets/project-progress/total-margin/TotalMarginWidget';
import TotalProfitWidget from './widgets/project-progress/total-profit/TotalProfitWidget';
import TotalRevenueWidget from './widgets/project-progress/total-revenue/TotalRevenueWidget';

export default function ProjectKPI({ project }) {
  const [period, setPeriod] = useState(
    project.useMonthlyBudget ? intervalOptions.this_month : intervalOptions.all_time,
  );

  const kpi = {
    realizationRate: project.isBillable && project.permissions.viewTimeAndExpenses,
    effectiveRate: project.isBillable && project.permissions.viewRates,
    margin: project.isBillable && project.permissions.viewMargin,
    hours: project.permissions.viewTimeAndExpenses,
    expenses: project.permissions.viewTimeAndExpenses,
    revenue: project.isBillable && project.permissions.viewRevenue,
    cost: project.permissions.viewMargin,
    profit: project.isBillable && project.permissions.viewRevenue && project.permissions.viewMargin,
    get any() {
      return this.realizationRate || this.effectiveRate || this.margin || this.hours || this.expenses || this.revenue;
    },
    get progress() {
      const options = [{ value: 'myHours', label: 'My Hours' }];
      if (this.hours) options.push({ value: 'hours', label: 'Hours' });
      if (this.revenue) {
        options.push(
          { value: 'servicesRevenue', label: 'Services Revenue' },
          { value: 'totalRevenue', label: 'Total Revenue' },
        );
      }
      if (this.cost) {
        options.push({ value: 'servicesCost', label: 'Services Cost' });
        options.push({ value: 'totalCost', label: 'Total Cost' });
      }
      if (this.profit) {
        options.push({ value: 'profit', label: 'Services Gross Profit' });
        options.push({ value: 'totalProfit', label: 'Total Gross Profit' });
        options.push({ value: 'margin', label: 'Services Gross Margin' });
        options.push({ value: 'totalMargin', label: 'Total Gross Margin' });
      }

      return options;
    },
  };

  const projectKey = _.snakeCase(project.key);

  const lastProgressMetric = useLocalStorage({ key: `project_dashboard_${projectKey}_progress_metric` });
  const [progressMetric, setProgressMetric] = useState(
    () =>
      kpi.progress.find(({ value }) => value === lastProgressMetric.get())?.value ??
      (project.permissions.viewTimeAndExpenses ? 'hours' : 'myHours'),
  );
  const handleProgressMetricChange = ({ target: { value } }) => {
    setProgressMetric(value);
    lastProgressMetric.set(value);
  };

  const lastProgressView = useLocalStorage({ key: `project_dashboard_${projectKey}_progress_view` });
  const [progressView, setProgressView] = useState(() => lastProgressView.get() ?? 'progress');
  const handleProgressViewChange = (value) => {
    setProgressView(value);
    lastProgressView.set(value);
  };

  return (
    <>
      <Section>
        <Level style={{ marginBottom: '2rem' }}>
          <Level.Item>
            <h3>Project Progress</h3>
          </Level.Item>

          <Level.Item right flex="0.3">
            {kpi.progress && (
              <SingleSelect value={progressMetric} onChange={handleProgressMetricChange}>
                {kpi.progress.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SingleSelect>
            )}
          </Level.Item>
        </Level>

        {{
          myHours: () => (
            <MyHoursWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          hours: () => <HoursWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />,
          servicesRevenue: () => (
            <ServicesRevenueProgress project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalRevenue: () => (
            <TotalRevenueWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          servicesCost: () => (
            <ServicesCostWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalCost: () => (
            <TotalCostWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          profit: () => (
            <ServicesProfitWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalProfit: () => (
            <TotalProfitWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          margin: () => (
            <ServicesMarginWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalMargin: () => (
            <TotalMarginWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
        }[progressMetric]()}
      </Section>

      <Separator />

      {kpi.any && (
        <Section>
          <Level style={{ marginBottom: '2rem' }}>
            <Level.Item>
              <h3>Key Performance Indicators</h3>
            </Level.Item>

            <Level.Item right flex="0.3">
              <PeriodFilter
                scope="month"
                intervals={[
                  { ...intervalOptions.all_time, label: 'Performance to Date' },
                  intervalOptions.this_month,
                  intervalOptions.last_month,
                ]}
                value={period}
                onChange={({ target: { value } }) => setPeriod(value)}
              />
            </Level.Item>
          </Level>

          <Grid>
            <Grid.Row>
              {kpi.realizationRate && (
                <Grid.Column size={4}>
                  <ProjectRealizationRateWidget project={project} period={period} />
                </Grid.Column>
              )}

              {kpi.effectiveRate && (
                <Grid.Column size={4}>
                  <ProjectEffectiveRateWidget project={project} period={period} />
                </Grid.Column>
              )}

              {kpi.margin && (
                <Grid.Column size={4}>
                  <ProjectMarginWidget project={project} period={period} />
                </Grid.Column>
              )}

              {kpi.hours && (
                <Grid.Column size={4}>
                  <ProjectHoursWidget project={project} period={period} />
                </Grid.Column>
              )}

              {kpi.expenses && (
                <Grid.Column size={4}>
                  <ProjectExpensesWidget project={project} period={period} />
                </Grid.Column>
              )}

              {kpi.revenue && (
                <Grid.Column size={4}>
                  <ProjectRevenueWidget project={project} period={period} />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Section>
      )}
    </>
  );
}
