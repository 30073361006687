import { useToast } from '~/contexts';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { imageFileTypes, imagePreviewFileTypes } from '~/utils/fileTypes';
import Button from './Button';
import FieldControl from './FieldControl';
import Icon from './Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const ContainerLabel = styled.label`
  position: relative;
  padding: 1.5rem;
  cursor: pointer;
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem;
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Input = styled.input`
  display: none;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .fa-image-slash {
    font-size: 10rem;
  }
`;

const Image = styled.img`
  max-height: 10rem;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.5rem;
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  color: ${colors.grey5};
  font-size: 8rem;
`;

const EditButton = styled(Button)`
  position: absolute;
  bottom: -0.75rem;
  right: -0.75rem;
  width: 2.5rem;
  padding: 0;
`;

function ImageFileInput({
  imageUrl,
  imageAlt,
  allowRemove = true,
  children,
  label,
  onChange,
  // We don't want to pass `value` forward to the input
  // eslint-disable-next-line no-unused-vars
  value,
  ...props
}) {
  const inputRef = useRef();
  const toast = useToast();

  const handleChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          onChange(file, reader.result);
          inputRef.current.value = null;
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('This control supports uploading JPEG, PNG, WebP, GIF, AVIF, TIFF and SVG images.');
      }
    }
  };

  const handleRemove = (event) => {
    event.preventDefault();
    onChange(null, '');
    inputRef.current.value = null;
  };

  return (
    <Wrapper>
      <Container>
        <ContainerLabel>
          <Input ref={inputRef} type="file" onChange={handleChange} accept={imageFileTypes} {...props} />
          {!!label && <Label>{label}</Label>}
          {imageUrl || value ? (
            <Preview imageUrl={imageUrl} imageAlt={imageAlt} value={value} />
          ) : children ? (
            children
          ) : (
            <Placeholder>
              <Icon icon="image" />
            </Placeholder>
          )}
          {!!imageUrl && !!allowRemove && (
            <RemoveButton isAnchor onClick={handleRemove}>
              <Icon icon="times" />
            </RemoveButton>
          )}
          <EditButton isOutline onClick={() => inputRef.current.click()}>
            <Icon icon="pencil-alt" />
          </EditButton>
        </ContainerLabel>
      </Container>
    </Wrapper>
  );
}

function Preview({ imageUrl, imageAlt, value }) {
  if (value) {
    if (imagePreviewFileTypes.includes(value.type))
      return (
        <PreviewContainer>
          <Image src={imageUrl} alt={imageAlt} />
        </PreviewContainer>
      );
    else {
      return (
        <Placeholder>
          <Icon icon="image-slash" color={colors.grey55} />
        </Placeholder>
      );
    }
  } else if (imageUrl) {
    return (
      <PreviewContainer>
        <Image src={imageUrl} alt={imageAlt} />
      </PreviewContainer>
    );
  }
  return null;
}

function FieldImageFileInput({ urlFieldName, ...props }) {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = (file, imageUrl) => {
    formik.setFieldValue(field.name, file);
    if (urlFieldName) {
      formik.setFieldValue(urlFieldName, imageUrl);
    }
    if (error) {
      formik.validateField(field.name);
    }
  };

  const imageUrl = urlFieldName ? formik.values[urlFieldName] : undefined;

  return (
    <FieldControl error={error}>
      <ImageFileInput {...field} imageUrl={imageUrl} onChange={handleChange} {...props} />
    </FieldControl>
  );
}

export default ImageFileInput;
export { FieldImageFileInput };
