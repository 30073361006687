import moment from 'moment';
import { dateFormats } from '~/utils';

const agingIntervals = {
  get current() {
    return {
      key: 'current',
      label: 'Current',
      start: moment().format(dateFormats.isoDate),
      end: null,
    };
  },
  get late() {
    return {
      key: 'late',
      label: 'Late',
      start: null,
      end: moment().subtract(1, 'days').format(dateFormats.isoDate),
    };
  },
  get late_1_to_30_days() {
    return {
      key: 'late_1_to_30_days',
      label: '1-30 days late',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'days').format(dateFormats.isoDate),
    };
  },
  get late_31_to_60_days() {
    return {
      key: 'late_31_to_60_days',
      label: '31-60 days late',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: moment().subtract(31, 'days').format(dateFormats.isoDate),
    };
  },
  get late_61_to_90_days() {
    return {
      key: 'late_61_to_90_days',
      label: '61-90 days late',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: moment().subtract(61, 'days').format(dateFormats.isoDate),
    };
  },
  get late_more_than_90() {
    return {
      key: 'late_more_than_90',
      label: '>90 days late',
      start: null,
      end: moment().subtract(91, 'days').format(dateFormats.isoDate),
    };
  },
};

export default agingIntervals;
