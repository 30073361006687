import _ from 'lodash';
import { entities } from '../lookups';
import React from 'react';
import Tag from './Tag';

export default function HistoryEntityTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;

  return (
    <Tag label="Entity" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? entities[v].name)
        .join('; ')}
    </Tag>
  );
}
