import {
  DisciplineFilter,
  EmploymentTypeFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberCostMethodFilter,
  MemberFilter,
  MemberInvitationStatusFilter,
  MemberStatusFilter,
  MemberTagFilter,
  PracticeFilter,
  SecurityRoleFilter,
  SkillFilter,
} from '~/components/index';
import useFeatures from '~/hooks/useFeatures';
import _ from 'lodash';
import React from 'react';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

function MemberFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Member Filters"
      filters={[
        filters.members,
        filters.employmentTypes,
        filters.memberBillableType,
        filters.memberStatus,
        filters.memberDisciplines,
        filters.jobTitles,
        filters.memberPractices,
        filters.managers,
        filters.memberSkills,
        filters.memberTags,
        filters.memberLocations,
        filters.securityRoles,
        filters.memberCostMethod,
        filters.memberInvitationStatuses,
        filters.memberActive?.start,
        filters.memberActive?.end,
      ]}>
      <Filter>
        {_.has(filters, 'memberActive') && (
          <Filter>
            <ReportPeriodFilter
              label="Member Active Range"
              value={filters.memberActive}
              onChange={({ target: { value } }) => onChange({ memberActive: value })}
            />
          </Filter>
        )}

        <MemberFilter
          value={filters.members}
          materialPlaceholder="Member"
          onChange={({ target: { value } }) => onChange({ members: value })}
        />
      </Filter>

      {_.has(filters, 'securityRoles') && (
        <Filter>
          <SecurityRoleFilter
            value={filters.securityRoles}
            onChange={({ target: { value } }) => onChange({ securityRoles: value })}
          />
        </Filter>
      )}

      <Filter>
        <EmploymentTypeFilter
          value={filters.employmentTypes}
          onChange={({ target: { value } }) => onChange({ employmentTypes: value })}
        />
      </Filter>

      <Filter>
        <MemberBillableTypeFilter
          value={filters.memberBillableType}
          onChange={({ target: { value } }) => onChange({ memberBillableType: value })}
        />
      </Filter>

      {_.has(filters, 'memberStatus') && (
        <Filter>
          <MemberStatusFilter
            value={filters.memberStatus}
            onChange={({ target: { value } }) => onChange({ memberStatus: value })}
          />
        </Filter>
      )}

      {features.disciplines && _.has(filters, 'memberDisciplines') && (
        <Filter>
          <DisciplineFilter
            value={filters.memberDisciplines}
            materialPlaceholder="Member Discipline"
            onChange={({ target: { value } }) => onChange({ memberDisciplines: value })}
          />
        </Filter>
      )}

      <Filter>
        <JobTitleFilter
          value={filters.jobTitles}
          onChange={({ target: { value } }) => onChange({ jobTitles: value })}
        />
      </Filter>

      {features.practices && _.has(filters, 'memberPractices') && (
        <Filter>
          <PracticeFilter
            value={filters.memberPractices}
            materialPlaceholder="Member Practice"
            onChange={({ target: { value } }) => onChange({ memberPractices: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberFilter
          value={filters.managers}
          materialPlaceholder="Member Manager"
          onChange={({ target: { value } }) => onChange({ managers: value })}
        />
      </Filter>

      {_.has(filters, 'memberSkills') && (
        <Filter>
          <SkillFilter
            value={filters.memberSkills}
            materialPlaceholder="Member Skill"
            onChange={({ target: { value } }) => onChange({ memberSkills: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberTagFilter
          value={filters.memberTags}
          onChange={({ target: { value } }) => onChange({ memberTags: value })}
        />
      </Filter>

      <Filter>
        <MemberCostMethodFilter
          value={filters.memberCostMethod}
          onChange={({ target: { value } }) => onChange({ memberCostMethod: value })}
        />
      </Filter>

      {_.has(filters, 'memberLocations') && (
        <Filter>
          <LocationFilter
            value={filters.memberLocations}
            materialPlaceholder="Member Location"
            onChange={({ target: { value } }) => onChange({ memberLocations: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberInvitationStatusFilter
          value={filters.memberInvitationStatuses}
          onChange={({ target: { value } }) => onChange({ memberInvitationStatuses: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}

export default MemberFiltersGroup;
