import moment from 'moment';
import React, { useMemo } from 'react';
import { dateFormats } from '~/utils';
import { HeaderCell, HeaderCellContent, HeaderColumn, HeaderGroupCellContent, HeaderRow } from './HeaderStyles';

function WeekHeader({ start, end, styles, onDateChange }) {
  const weeks = useMemo(() => {
    const weekCount = moment(end).diff(start, 'weeks') + 1;

    const weeks = [];

    for (let index = 0; index < weekCount; index++) {
      const date = moment(start).add(index, 'weeks');

      weeks.push({
        key: date.format(dateFormats.isoDate),
        start: moment(date).startOf('isoWeek').format('MMM D'),
        number: moment(date).isoWeek(),
        today: moment().isSame(date, 'week'),
        style: {
          width: styles.date.width * 7,
        },
      });
    }

    return weeks;
  }, [start, end, styles.date.width]);

  const months = useMemo(() => {
    const monthCount = moment(end).diff(start, 'months') + 1;

    const months = [];
    const monthStart = moment(start).startOf('month');

    for (let index = 0; index < monthCount; index++) {
      const date = moment(monthStart).add(index, 'months');

      const month = {
        start: moment.max(moment(date).startOf('month'), moment(start)),
        end: moment.min(moment(date).endOf('month'), moment(end)),

        get days() {
          return moment(this.end).diff(this.start, 'days') + 1;
        },
      };

      months.push({
        key: date.format(dateFormats.isoDate),
        month: date.format('MMMM'),
        year: date.format('YYYY'),
        style: { width: styles.date.width * month.days },
      });
    }

    return months;
  }, [start, end, styles.date.width]);

  return (
    <HeaderColumn>
      <HeaderRow>
        {months.map((month) => {
          const handleClick = () => onDateChange(month.key);

          return (
            <HeaderCell key={month.key} style={month.style} onClick={handleClick}>
              <HeaderGroupCellContent>
                <span>{month.month}</span>
                <span>{month.year}</span>
              </HeaderGroupCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>

      <HeaderRow>
        {weeks.map((week) => {
          const handleClick = () => onDateChange(week.key);

          return (
            <HeaderCell key={week.key} style={week.style} today={week.today} onClick={handleClick}>
              <HeaderCellContent style={{ justifyContent: 'space-between' }}>
                <span>{week.start}</span>
                <span>Week {week.number}</span>
              </HeaderCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>
    </HeaderColumn>
  );
}

export default React.memo(WeekHeader);
