import { Button, Currency, Icon, Level, SingleSelect, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import settings from '~/settings.js';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import RuddrLogo from '../assets/invoice-logo.svg?react';
import InvoicePdfDialog from './InvoicePdfDialog';
import InvoicePreview from './InvoicePreview';
import InvoicePreviewReceipts from './InvoicePreviewReceipts';
import InvoiceTimeReport from './InvoiceTimeReport';

const Container = styled.div`
  width: 100%;
  padding: 0 3.125rem;
  background: ${colors.grey5};
  flex: 1;
  display: flex;
  flex-direction: column;

  @media print {
    background: none;
    padding: 0;
  }
`;

const HeaderSection = styled.div`
  padding: 1.875rem 0;

  @media print {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  align-self: center;
  width: 13.125rem;
  height: 2.5rem;
`;

const ActionButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  color: ${colors.primary};
  background-color: ${colors.white};
  padding: 0;
  margin-left: auto;
`;

const Balance = styled.div`
  align-self: center;
  color: ${colors.grey75};
  font-weight: ${weights.light};
`;

const FooterSection = styled.div`
  padding: 1.875rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  @media print {
    display: none;
  }
`;

const initialState = {
  isReady: false,
  invoice: null,
  invoices: [],
};

const handlers = {
  ready: ({ invoice, invoices, receipts }) => ({ isReady: true, invoice, invoices, receipts }),
};

function InvoicePreviewPage() {
  const api = useApi();
  const [{ isReady, invoice, invoices, receipts }, actions] = useActions(handlers, initialState);

  const { workspaceKey, invoiceId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [pdfDialogVisible, setPdfDialogVisible] = useState(false);

  const preview = new URLSearchParams(location.search).get('preview') === 'true';
  const title = useDocumentTitle(preview ? 'Invoice (Preview)' : 'Invoice', preview);

  const view = useMemo(() => {
    if (location.pathname.endsWith('/receipts')) {
      return 'receipts';
    } else if (location.pathname.endsWith('/time')) {
      return 'time';
    }
    return 'invoice';
  }, [location]);

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { invoice, invoices, receipts },
      } = await api.invoices({ workspaceKey, invoiceId }).webLink();

      actions.ready({
        invoice,
        invoices,
        receipts: receipts?.map((receipt) => ({
          ...receipt,
          imageUrl: (settings.isDev ? settings.apiProxy : '') + `/api/${workspaceKey}/receipts/${receipt.id}`,
        })),
      });
    } catch (error) {
      actions.ready({ invoice: null });
    }
  }, [actions, workspaceKey, invoiceId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (invoice && invoice.number) {
      title.set(preview ? `Invoice #${invoice.number} (Preview)` : `Invoice #${invoice.number}`);
    }
  }, [invoice, preview, title]);

  if (!isReady) return <PageLoader />;
  if (!invoice) return <ErrorPage.NotFound publicSite={false} />;

  const handleClose = () => {
    window.close();
  };

  const handleInvoiceSelectChange = (event) => {
    history.push(`/${workspaceKey}/invoices/${event.target.value}`);
  };

  const daysSinceDueDate = moment().diff(invoice.dueOn, 'days');

  const locale = invoice.client?.locale ?? invoice.workspace.locale;

  return (
    <Container>
      <HeaderSection>
        <Level>
          <Level.Item>
            {!preview && invoice.status.id !== 'draft' && invoices && (
              <SingleSelect value={invoice.id} onChange={handleInvoiceSelectChange}>
                {invoices.map((item) => (
                  <option key={item.id} value={item.id}>
                    Invoice #{item.number} for <Currency value={item.total} currency={item.currency} locale={locale} />
                  </option>
                ))}
              </SingleSelect>
            )}
          </Level.Item>
          <Level.Item>
            {preview ? (
              <CloseButton onClick={handleClose}>Close</CloseButton>
            ) : (
              invoice.statusId !== 'draft' &&
              invoice.balance > 0 && (
                <Balance>
                  Open Balance of <Currency value={invoice.balance} currency={invoice.currency} locale={locale} />
                  {daysSinceDueDate > 0 && (
                    <span style={{ color: colors.danger }}>
                      &nbsp;({daysSinceDueDate} {daysSinceDueDate === 1 ? 'day' : 'days'} late)
                    </span>
                  )}
                </Balance>
              )
            )}
          </Level.Item>

          <Level.Item right>
            <Level>
              {receipts?.length > 0 && (
                <Level.Item narrow>
                  {view === 'receipts' ? (
                    <Link className="button" to={`/${workspaceKey}/invoices/${invoiceId}${location.search}`}>
                      View Invoice <Icon icon="file-invoice-dollar" spaceLeft />
                    </Link>
                  ) : (
                    <Link className="button" to={`/${workspaceKey}/invoices/${invoiceId}/receipts${location.search}`}>
                      View Receipts <Icon icon="receipt" spaceLeft />
                    </Link>
                  )}
                </Level.Item>
              )}

              {!!invoice.includeTimeReport && (
                <Level.Item narrow>
                  {view === 'time' ? (
                    <Link className="button" to={`/${workspaceKey}/invoices/${invoiceId}${location.search}`}>
                      View Invoice <Icon icon="file-invoice-dollar" spaceLeft />
                    </Link>
                  ) : (
                    <Link className="button" to={`/${workspaceKey}/invoices/${invoiceId}/time${location.search}`}>
                      View Time Detail <Icon icon="clock" spaceLeft />
                    </Link>
                  )}
                </Level.Item>
              )}

              <Level.Item narrow>
                <Tooltip message="Download invoice PDF.">
                  <ActionButton onClick={() => setPdfDialogVisible((isVisible) => !isVisible)}>
                    <Icon icon="file-pdf" />
                  </ActionButton>
                </Tooltip>
              </Level.Item>
            </Level>
          </Level.Item>
        </Level>
      </HeaderSection>

      <Switch>
        <Route path={path} exact>
          <InvoicePreview invoice={invoice} />
        </Route>

        {receipts?.length > 0 && (
          <Route path={`${path}/receipts`}>
            <InvoicePreviewReceipts receipts={receipts} invoice={invoice} />
          </Route>
        )}

        {!!invoice.includeTimeReport && (
          <Route path={`${path}/time`}>
            <InvoiceTimeReport invoice={invoice} />
          </Route>
        )}
      </Switch>

      <FooterSection>
        <Link to="/">
          <RuddrLogo />
        </Link>
      </FooterSection>

      {pdfDialogVisible && <InvoicePdfDialog invoice={invoice} onClose={() => setPdfDialogVisible(false)} />}
    </Container>
  );
}

export default InvoicePreviewPage;
