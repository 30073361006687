import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDocumentTitle } from '~/hooks';
import {
  Button,
  CancelButton,
  Checkbox,
  FormMessage,
  Icon,
  ModalCard,
  Radio,
  SMSCountryCodeSelect,
  TextInput,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, devices, weights } from '~/styles';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 41.5rem;
`;

const Warning = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  background-color: ${colors.warning10};
  border-radius: 0.625rem;
  border: solid 0.0625rem ${colors.warning50};
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.3125rem;
  border-left: solid 0.125rem ${colors.grey10};
`;

const Step = styled.div`
  display: flex;
  position: relative;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  /* This hides the border on Steps for the last step */
  &:last-child {
    :before {
      content: '';
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -0.125rem;
      width: 0.125rem;
      background-color: ${colors.white};
    }
  }
`;

const Number = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -1.3125rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
  background-color: ${({ active }) => (active ? colors.primary : colors.grey10)};
  border: solid 0.125rem ${colors.white};
  border-radius: 999rem;
  z-index: 1;
`;

const CheckIcon = styled(Icon)`
  font-size: 0.875rem;
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  padding: 0.5rem 0;
  font-weight: ${weights.bold};
`;

const Container = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: solid 0.0625rem ${colors.grey10};
  border-radius: 0.625rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const BoxTitle = styled.h3`
  margin-top: 1.5rem;
  font-weight: ${weights.bold};
`;

const Description = styled.p`
  color: ${colors.grey75};
  font-size: 0.875rem;
`;

const ErrorMessage = styled(FormMessage.Error)`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const SuccessMessage = styled(FormMessage)`
  color: ${colors.white};
  background-color: ${colors.primary};

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const Option = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const OptionInfo = styled.span`
  display: flex;
  flex-direction: column;
`;

const OptionTitle = styled.span`
  display: flex;
  font-weight: ${weights.bold};
`;

const OptionIcon = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-top: -0.5rem;
  margin-right: 0.5rem;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 999rem;
`;

const OptionDescription = styled.span`
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const CodeWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-right: auto;
  padding: 0.25rem;
  border: solid 0.0625rem ${colors.grey10};
  border-radius: 0.625rem;
`;

const CodeImage = styled.img`
  display: block;
  width: 12.25rem;
  height: 12.25rem;
`;

const InputWrapper = styled.div`
  margin-top: 1.5rem;
`;

const RecoveryCodes = styled.ul`
  padding: 1.5rem 1rem;
  background-color: ${colors.grey5};
  border-radius: 0.625rem;
`;

const RecoveryCode = styled.li`
  display: inline-block;
  width: 50%;
  padding: 0.25rem;
  text-align: center;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const ConfirmCodes = styled.div`
  margin-top: 1.5rem;
`;

const ConfirmIcon = styled(Icon)`
  color: ${colors.primary};
  font-size: 2rem;
`;

export default function TwoFactorAuthSetup() {
  useDocumentTitle('Enable Two-factor Authentication');

  const api = useApi();
  const { workspace } = useWorkspace();
  const history = useHistory();

  const [currentMfa, setCurrentMfa] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [step, setStep] = useState(1);
  const [typeId, setTypeId] = useState('app');
  const [mfaId, setMfaId] = useState();
  const [challengeId, setChallengeId] = useState();
  const [secret, setSecret] = useState(null);
  const [secretUri, setSecretUri] = useState(null);
  const [qrDataUrl, setQrDataUrl] = useState(null);
  const [countryCodeISO, setCountryCodeISO] = useState('US');
  const [countryCode, setCountryCode] = useState('1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [initializing, setInitializing] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [recoveryCodesSaved, setRecoveryCodesSaved] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [showSecret, setShowSecret] = useState(false);

  useEffect(() => {
    if (!workspace?.id) return;

    const getStatus = async () => {
      const { data } = await api.www.workspaces(workspace.id).mfa().get();
      setCurrentMfa(data ?? null);
    };
    getStatus();
  }, [api, workspace?.id]);

  useEffect(() => {
    const getDataUrl = async () => {
      const dataUrl = await QRCode.toDataURL(secretUri);
      setQrDataUrl(dataUrl);
    };
    if (secretUri) {
      getDataUrl();
    } else {
      setQrDataUrl(null);
    }
  }, [secretUri]);

  useEffect(() => {
    if (!workspace?.id) return;

    const verifyCode = async (code) => {
      try {
        setErrorMessage();
        setVerifyingCode(true);
        await api.www.workspaces(workspace.id).mfa(mfaId).verify({ challengeId, code });
        // If no error is thrown, verification worked
        setIsVerified(true);
        setVerifyingCode(false);
      } catch (error) {
        if (error.status === 400) {
          setErrorMessage('Two-factor code verification failed. Please try again.');
        } else {
          setErrorMessage('There was a problem verifying your two-factor code.');
        }
        setVerifyingCode(false);
      }
    };

    const trimmedValue = codeValue.replaceAll(/[-.\s]+/g, '');
    const isValidCode = trimmedValue.match(/^\d{6}$/);
    if (isValidCode && !isVerified) {
      verifyCode(trimmedValue);
    }
  }, [api, workspace?.id, codeValue, mfaId, challengeId, isVerified]);

  const handleCancel = () => {
    history.push('/settings/two-factor-auth');
  };

  const handleInitialize = async () => {
    try {
      setErrorMessage();
      setInitializing(true);
      const { data } = await api.www.workspaces(workspace.id).mfa().initialize({ typeId });
      if (data) {
        setMfaId(data.id);
        setSecret(data.secret);
        setSecretUri(data.secretUri);
        setRecoveryCodes(data.formattedRecoveryCodes);
        setStep(2);
        setInitializing(false);
      }
    } catch (error) {
      if (error.response?.data?.code === 'sms_rate_limit') {
        setErrorMessage(
          'There have been too many attempts to setup SMS MFA on this account. Please contact support for further assistance.',
        );
      } else {
        setErrorMessage('There was a problem initializing your 2FA.');
      }
      setInitializing(false);
    }
  };

  const handleSendCode = async () => {
    const trimmedCountryCode = '+' + countryCode.replaceAll(/[^0-9]+/g, '');
    const trimmedPhoneNumber = phoneNumber.replaceAll(/[^0-9]+/g, '');

    if (trimmedPhoneNumber.length < 4 || trimmedPhoneNumber.length > 12) {
      setErrorMessage('Phone number must be between 4 and 12 digits.');
      return;
    }

    try {
      setErrorMessage();
      setSendingCode(true);
      const { data } = await api.www
        .workspaces(workspace.id)
        .mfa(mfaId)
        .setupSMS({ countryCode: trimmedCountryCode, phoneNumber: trimmedPhoneNumber });
      if (data) {
        setChallengeId(data.challengeId);
        setCodeSent(true);
        setSendingCode(false);
      }
    } catch (error) {
      setErrorMessage(error.message || 'There was a problem setting up your 2FA phone number.');
      setSendingCode(false);
    }
  };

  const handleEnable = async () => {
    try {
      setErrorMessage();
      setEnabling(true);
      await api.www.workspaces(workspace.id).mfa(mfaId).enable();
      // If no error is thrown, verification worked
      setStep(4);
      setEnabling(false);
    } catch {
      setErrorMessage('There was a problem enabling your 2FA.');
      setEnabling(false);
    }
  };

  return (
    <Wrapper>
      {currentMfa && (
        <Warning>
          You're about to change your two-factor authentication. This will invalidate your current two-factor
          authentication method and recovery codes.
        </Warning>
      )}
      <Steps>
        <Step>
          <Number active={step >= 1}>1</Number>
          <Details>
            <Title>Two-factor Authentication Method</Title>
            <Container active={step === 1}>
              <Box>
                <Description>
                  Two-factor authentication (2FA) is an extra layer of security used when logging into websites or apps.
                </Description>
                <Options>
                  <Option>
                    <Radio
                      value="app"
                      checked={typeId === 'app'}
                      onChange={(event) => setTypeId(event.target.value)}
                      label={
                        <OptionInfo>
                          <OptionTitle>
                            <OptionIcon>
                              <Icon icon="mobile" />
                            </OptionIcon>
                            Set up using an app
                          </OptionTitle>
                          <OptionDescription>
                            Use an application on your phone to get two-factor authentication codes when prompted. We
                            recommend using cloud-based TOTP apps such as:{' '}
                            <a
                              href="https://support.1password.com/one-time-passwords/"
                              target="_blank"
                              rel="noreferrer">
                              1Password
                            </a>
                            ,{' '}
                            <a href="https://authy.com" target="_blank" rel="noreferrer">
                              Authy
                            </a>
                            ,{' '}
                            <a
                              href="https://en.wikipedia.org/wiki/Google_Authenticator"
                              target="_blank"
                              rel="noreferrer">
                              Google Authenticator
                            </a>
                            ,{' '}
                            <a
                              href="https://support.lastpass.com/help/lastpass-authenticator-lp030014"
                              target="_blank"
                              rel="noreferrer">
                              LastPass Authenticator
                            </a>
                            , or{' '}
                            <a
                              href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                              target="_blank"
                              rel="noreferrer">
                              Microsoft Authenticator
                            </a>
                            .
                          </OptionDescription>
                        </OptionInfo>
                      }
                    />
                  </Option>
                  <Option>
                    <Radio
                      value="sms"
                      checked={typeId === 'sms'}
                      onChange={(event) => setTypeId(event.target.value)}
                      label={
                        <OptionInfo>
                          <OptionTitle>
                            <OptionIcon>
                              <Icon icon="message-sms" />
                            </OptionIcon>
                            Set up using SMS
                          </OptionTitle>
                          <OptionDescription>
                            Ruddr will send you an SMS with a two-factor authentication code when prompted. SMS cannot
                            be delivered in all countries.
                          </OptionDescription>
                        </OptionInfo>
                      }
                    />
                  </Option>
                </Options>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </Box>
              <Actions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <Button isLoading={initializing} onClick={handleInitialize}>
                  Continue
                </Button>
              </Actions>
            </Container>
          </Details>
        </Step>
        <Step>
          <Number active={step >= 2}>2</Number>
          <Details>
            <Title>Authentication Verification</Title>
            <Container active={step === 2 && typeId === 'app'}>
              <Box>
                <Description>
                  Scan the image below with the two-factor authentication app on your phone. If you can’t use a QR code,{' '}
                  <Button isAnchor onClick={() => setShowSecret(true)}>
                    enter this text code
                  </Button>{' '}
                  instead.
                </Description>
                {qrDataUrl && (
                  <CodeWrapper>
                    <CodeImage src={qrDataUrl} alt="Scan this QR code with your authenticator app on your phone." />
                  </CodeWrapper>
                )}
                <BoxTitle>Enter the code from the application</BoxTitle>
                <Description>
                  After scanning the QR code image, the app will display a code that you can enter below.
                </Description>
                <InputWrapper>
                  <TextInput
                    placeholder="6-digit code"
                    value={codeValue}
                    onChange={(event) => setCodeValue(event.target.value)}
                    disabled={verifyingCode || isVerified}
                  />
                </InputWrapper>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {isVerified && <SuccessMessage>Your two-factor code has been successfully verified.</SuccessMessage>}
              </Box>
              <Actions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <Button disabled={!isVerified} onClick={() => setStep(3)}>
                  Continue
                </Button>
              </Actions>
            </Container>
            <Container active={step === 2 && typeId === 'sms'}>
              <Box>
                <Description>We will send authentication codes to your mobile phone during sign in.</Description>
                <BoxTitle>SMS Phone Number</BoxTitle>
                <Description>Authentication codes will be sent here.</Description>
                <InputWrapper>
                  <SMSCountryCodeSelect
                    disabled={sendingCode || codeSent}
                    value={countryCodeISO}
                    onChange={(event) => {
                      setCountryCodeISO(event.target.value);
                      setCountryCode(event.target.code);
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    type="tel"
                    placeholder="2345556789"
                    materialPlaceholder="Phone Number"
                    materialAlwaysVisible={true}
                    disabled={sendingCode || codeSent}
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value.replaceAll(/[^0-9.\s-]+/g, ''))}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Button disabled={phoneNumber.length < 4 || sendingCode || codeSent} onClick={handleSendCode}>
                    Send Authentication Code
                  </Button>
                </InputWrapper>
                {codeSent && (
                  <>
                    <BoxTitle>Enter the code sent to your phone</BoxTitle>
                    <Description>It may take a minute to arrive.</Description>
                    <InputWrapper>
                      <TextInput
                        placeholder="6-digit code"
                        value={codeValue}
                        onChange={(event) => setCodeValue(event.target.value)}
                        disabled={verifyingCode || isVerified}
                      />
                    </InputWrapper>
                  </>
                )}
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {isVerified && <SuccessMessage>Your two-factor code has been successfully verified.</SuccessMessage>}
              </Box>
              <Actions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <Button disabled={!isVerified} onClick={() => setStep(3)}>
                  Continue
                </Button>
              </Actions>
            </Container>
          </Details>
        </Step>
        <Step>
          <Number active={step >= 3}>3</Number>
          <Details>
            <Title>Save Your Recovery Codes</Title>
            <Container active={step === 3}>
              <Box>
                <RecoveryCodes>
                  {recoveryCodes.map((code) => (
                    <RecoveryCode key={code}>
                      <code>{code}</code>
                      <br />
                    </RecoveryCode>
                  ))}
                </RecoveryCodes>
                <BoxTitle>Why is saving your recovery codes important?</BoxTitle>
                <Description>
                  If you lose access to your phone, you can authenticate to Ruddr using your recovery codes. We
                  recommend saving them with a secure password manager.
                </Description>
                <ConfirmCodes>
                  <Checkbox
                    label="I have saved my recovery codes"
                    checked={recoveryCodesSaved}
                    onChange={(event) => setRecoveryCodesSaved(event.target.checked)}
                  />
                </ConfirmCodes>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </Box>
              <Actions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <Button disabled={!recoveryCodesSaved} isLoading={enabling} onClick={handleEnable}>
                  Continue
                </Button>
              </Actions>
            </Container>
          </Details>
        </Step>
        <Step>
          <Number active={step >= 4}>
            <CheckIcon icon="check" />
          </Number>
          <Details>
            <Title>Two-factor Authentication Activated</Title>
            <Container active={step === 4}>
              <Box>
                <ConfirmIcon icon="shield-check" />
                <BoxTitle>Your two-factor authentication has been activated!</BoxTitle>
                <Description>
                  The next time you login from an unrecognized browser, you will need to provide a two-factor
                  authentication code.
                </Description>
              </Box>
              <Actions>
                <Button onClick={handleCancel}>Done</Button>
              </Actions>
            </Container>
          </Details>
        </Step>
      </Steps>
      {showSecret && (
        <ModalCard title="Your Two-factor Secret" onClose={() => setShowSecret(false)}>
          <ModalCard.Body>
            <code>{secret}</code>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Button onClick={() => setShowSecret(false)}>Continue</Button>
          </ModalCard.Footer>
        </ModalCard>
      )}
    </Wrapper>
  );
}
