import {
  Button,
  DayPickerDropdown,
  DelayedContent,
  Duration,
  Icon,
  InlineTooltip,
  TimeApprovalPopover,
  TimeLockIcon,
} from '~/components';
import { useApi, useTimeEntries, useWorkspace } from '~/contexts';
import { useTimeEntryTimer } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { useTimesheets } from '../../time/timesheets/TimesheetContext';
import TimesheetSubmittedTooltip from '../../time/timesheets/TimesheetSubmittedTooltip';

// isToday/isActive is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledButton = ({ isToday, isActive, ...props }) => <Button {...props} />;

const Window = styled.div`
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  max-height: calc(100vh - 5.75rem); /* 5.75 = header height + (0.375 x 2) padding */
  margin-left: -18.75rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.875rem;
    border: solid 0.875rem transparent;
    border-bottom-color: ${colors.primary};
  }
`;

const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 3.125rem;
  padding: 0 1.25rem;
  background-color: ${colors.primary};
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
`;

const HeaderDate = styled.span`
  margin-right: auto;
  color: ${colors.white};
  font-weight: ${weights.medium};

  .icon {
    margin-left: 0.5rem;
  }
`;

const DateSelectorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateSelector = styled(Button)`
  color: ${colors.white};
  font-size: 1.25rem;

  &:hover {
    color: ${colors.grey5};
  }
`;

const Week = styled.div`
  display: flex;
  padding-top: 1.25rem;
  padding-bottom: 1.125rem;
  border-bottom: solid 1px ${colors.grey10};
`;

const WeekDay = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const WeekDayName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.625rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
`;

const WeekDayHours = styled.span`
  margin-top: 0.125;
  color: ${colors.black};
  font-weight: ${weights.bold};
`;

const WeekDaySelector = styled(StyledButton)`
  flex: 1;
  margin: 0 0.25rem;
  font-size: 0.75rem;

  ${WeekDayName} {
    color: ${({ isActive }) => (isActive ? colors.white : colors.grey40)};
    background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  }

  ${WeekDayHours} {
    color: ${({ isToday }) => (isToday ? colors.primary : colors.black)};
  }

  &:hover {
    ${WeekDayName} {
      background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey10)};
    }
  }
`;

const WeekSelectorContainer = styled.div`
  display: flex;
  width: 5rem;

  &:last-child {
    justify-content: flex-end;
  }
`;

const WeekSelector = styled(Button)`
  height: 1.625rem;
  padding: 0 1.25rem;
  color: ${colors.grey40};

  &:hover {
    color: ${colors.grey55};
  }
`;

const TimeEntries = styled.div`
  border-bottom: solid 1px ${colors.grey10};
  overflow: auto;
`;

const TimeEntry = styled.div`
  display: flex;
  padding: 0.875rem 1.5rem;
  border-bottom: solid 1px ${colors.grey10};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const TimeEntryBody = styled.div`
  flex: 1;
  padding-right: 0.625rem;
`;

const TimeEntryDescription = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.875rem;

  strong {
    color: ${colors.black};
  }
`;

const TimeEntryRole = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
`;

const TimeEntryTask = styled.p`
  margin-top: 0.3125rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-weight: ${weights.medium};
`;

const TimeEntryNotes = styled.p`
  margin-top: 1rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-style: italic;
`;

const TimeEntryHours = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 1.875rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-weight: ${weights.medium};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey10,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};
  border-radius: 999rem;

  .icon {
    margin-right: 0.5rem;
    font-size: 0.625rem;
    opacity: 0.5;
  }
`;

const Footer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.375rem;
`;

const AddTimeEntryButton = styled(Button)`
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  color: ${colors.primary};
  font-size: 0.875rem;
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }
`;

const PlayPauseButton = styled(StyledButton)`
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  padding-left: ${({ isActive }) => (isActive ? '0' : '0.125rem')};
  margin-left: 0.625rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.primary)};
  font-size: 0.75rem;
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  position: relative;

  &&:disabled {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }

  &:hover {
    color: ${colors.white};
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.primary)};
  }
`;

const TimeEntryLocked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 0.625rem;
`;

export default function WorkspaceTimerWindow({ startDate, onEdit }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { entries, updateEntries } = useTimeEntries();
  const { startStopTimer } = useTimeEntryTimer();
  const [date, setDate] = useState(startDate || moment().format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getWeek({ date });
      updateEntries(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace.id, date, updateEntries]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memberId = useMemo(() => workspace.member.id, [workspace]);

  const week = useMemo(() => {
    const day = moment(date).startOf('isoWeek').subtract(1, 'days');
    return _.times(7, () => {
      const date = day.add(1, 'days').format('YYYY-MM-DD');
      const dateEntries = _.filter(entries, { date, memberId });
      const minutes = _.sumBy(dateEntries, 'minutes');
      const timerStartedAt = _.find(dateEntries, (entry) => !!entry.timerStartedAt)?.timerStartedAt;
      return { date, entries: dateEntries, minutes, timerStartedAt };
    });
  }, [date, entries, memberId]);

  const viewEntries = useMemo(
    () =>
      _(entries)
        .filter({ date, memberId })
        .sortBy(['project.client.name', 'project.name', 'role.name', 'task.name', 'notes'])
        .value(),
    [date, entries, memberId],
  );

  const handlePrevious = () => {
    setDate((date) => moment(date).subtract(1, 'day').format('YYYY-MM-DD'));
  };

  const handleNext = () => {
    setDate((date) => moment(date).add(1, 'day').format('YYYY-MM-DD'));
  };

  const handlePlayPause = async (entry, event) => {
    event.stopPropagation();
    await startStopTimer(entry);
  };

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () => timesheetContext.isTimesheetSubmitted({ start: date, end: date }),
    [timesheetContext, date],
  );

  return (
    <Window>
      <Header>
        <HeaderDate>
          {moment(date).isSame(moment(), 'day') && 'Today, '}
          {moment(date).format('LL')}
          {isLoading && (
            <DelayedContent>
              <Icon icon="spinner" spin={true} />
            </DelayedContent>
          )}
        </HeaderDate>
        <DayPickerDropdown value={date} onChange={(date) => setDate(date)} align="right" positionOffset="0.375rem">
          {({ setIsOpen }) => (
            <DateSelectorContainer>
              <DateSelector isAnchor onClick={() => setIsOpen((isOpen) => !isOpen)}>
                <Icon icon="calendar-alt" type="far" />
              </DateSelector>
            </DateSelectorContainer>
          )}
        </DayPickerDropdown>
      </Header>
      <Week>
        <WeekSelectorContainer>
          <WeekSelector isAnchor onClick={handlePrevious}>
            <Icon icon="angle-left" />
          </WeekSelector>
        </WeekSelectorContainer>
        {week.map((day) => (
          <WeekDaySelector
            isAnchor
            key={day.date}
            isActive={day.date === date}
            isToday={moment().isSame(day.date, 'day')}
            onClick={() => setDate(day.date)}>
            <WeekDay>
              <WeekDayName>{moment(day.date).format('ddd')}</WeekDayName>
              <WeekDayHours>
                <Duration minutes={day.minutes} timerStartedAt={day.timerStartedAt} trim />
              </WeekDayHours>
            </WeekDay>
          </WeekDaySelector>
        ))}
        <WeekSelectorContainer>
          <WeekSelector isAnchor onClick={handleNext}>
            <Icon icon="angle-right" />
          </WeekSelector>
        </WeekSelectorContainer>
      </Week>
      {viewEntries?.length > 0 && (
        <TimeEntries>
          {viewEntries.map((entry) => {
            let disableTimerReason;
            if (!entry.timerStartedAt) {
              if (
                entry.statusId === 'pending_approval' ||
                (entry.statusId === 'approved' && entry.statusModeId === 'manual')
              ) {
                disableTimerReason =
                  'You can only start timers on time entries that are either "Not Submitted", "Rejected" or "Automatically Approved".';
              } else if (entry.invoiceId) {
                disableTimerReason = 'This time entry is associated with an invoice and its timer cannot be started.';
              } else if (entry.clientApprovalId) {
                disableTimerReason =
                  'This time entry is associated with a client approval and its timer cannot be started.';
              } else if (entry.typeId === 'project_time' && entry.project.recordStatusId !== 'active') {
                disableTimerReason = 'You can only start a timer on an active project.';
              } else if (timesheetContext.useTimesheets && entry.timesheetId) {
                disableTimerReason = 'This time entry is associated with a timesheet and its timer cannot be started.';
              }
            }

            return (
              <TimeEntry
                key={entry.id}
                onClick={() =>
                  onEdit({
                    mode: entry.isLocked ? 'view' : 'edit',
                    id: entry.id,
                  })
                }>
                <TimeEntryBody>
                  {entry.type.id === 'project_time' ? (
                    <TimeEntryDescription>
                      {entry.project && entry.project.client && (
                        <>
                          <strong>{entry.project.client.name}</strong> / {entry.project.name}
                        </>
                      )}
                    </TimeEntryDescription>
                  ) : (
                    <TimeEntryDescription>
                      <strong>Time Off</strong>
                    </TimeEntryDescription>
                  )}
                  {entry.role && <TimeEntryRole>{entry.role.name}</TimeEntryRole>}
                  {entry.task && <TimeEntryTask>{entry.task.name}</TimeEntryTask>}
                  {entry.notes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
                </TimeEntryBody>

                <TimeApprovalPopover timeEntryId={entry.id}>
                  <TimeEntryHours status={entry.status?.id}>
                    <Duration
                      minutes={entry.minutes}
                      timerStartedAt={entry.timerStartedAt}
                      showSeconds={!!entry.timerStartedAt}
                      trim={!entry.timerStartedAt}
                    />
                  </TimeEntryHours>
                </TimeApprovalPopover>

                {entry.isLocked ? (
                  <TimeEntryLocked>
                    <TimeLockIcon value={entry.lockStatusId} />
                  </TimeEntryLocked>
                ) : (
                  <PlayPauseButton
                    isAnchor
                    disabled={!!disableTimerReason}
                    isActive={!!entry.timerStartedAt}
                    onClick={handlePlayPause.bind(this, entry)}>
                    <Icon icon={entry.timerStartedAt ? 'pause' : 'play'} />
                    {disableTimerReason && <InlineTooltip message={disableTimerReason} />}
                  </PlayPauseButton>
                )}
              </TimeEntry>
            );
          })}
        </TimeEntries>
      )}
      <Footer>
        <AddTimeEntryButton disabled={isTimesheetSubmitted} onClick={() => onEdit({ mode: 'edit', date })}>
          <Icon icon="plus" />
          {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
        </AddTimeEntryButton>
      </Footer>
    </Window>
  );
}
