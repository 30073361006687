import {
  Button,
  Buttons,
  CancelButton,
  Confirmation,
  Drawer,
  Dropdown,
  Level,
  SingleSelect,
  Stack,
  Table,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import ClientApprovalScheduleForm from './ClientApprovalScheduleForm';
import ClientApprovalScheduleRunModal from './ClientApprovalScheduleRunModal';

export default function ClientApprovalSchedulesDrawer({ project, onChange, onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [dialog, setDialog] = useState(null);

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ status: 'active' });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientApprovalSchedules()
        .get(params);

      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, api, project.id, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const closeDialog = () => setDialog(null);

  const handleOpenForm = (clientApprovalSchedule) =>
    setDialog(
      <ClientApprovalScheduleForm
        project={project}
        clientApprovalScheduleId={clientApprovalSchedule?.id}
        onSaved={fetchData}
        onClose={closeDialog}
      />,
    );

  return (
    <>
      <Drawer isOpen title="Client Approval Schedules" onClose={onClose}>
        {(closeDrawer) => {
          if (!query.isReady) return <PageLoader />;

          if (query.error) return <ErrorPage publicSite={false} />;

          return (
            <>
              <Stack>
                <Level>
                  <Level.Item width="12rem">
                    <SingleSelect
                      name="status"
                      placeholder="All"
                      materialPlaceholder="Status"
                      materialAlwaysVisible
                      showEmptyOption
                      value={params.status}
                      onChange={handleFilterChange}>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </SingleSelect>
                  </Level.Item>

                  <Level.Item right narrow>
                    <Button className="button" onClick={handleOpenForm}>
                      Create a Client Approval Schedule
                    </Button>
                  </Level.Item>
                </Level>

                <div style={{ margin: '2.5rem 0' }}>
                  <Table.Total value={query.data.length} label="Client Approval Schedule" />

                  <Table>
                    <Table.BoxHeader>
                      <Table.Column>Interval</Table.Column>

                      <Table.Column>Items</Table.Column>

                      <Table.Column>Project Members</Table.Column>

                      <Table.BoxActionsColumn />
                    </Table.BoxHeader>

                    <Table.Body>
                      {query.data.map((schedule) => {
                        const handleDelete = () => {
                          confirmation.prompt((resolve) => (
                            <Confirmation
                              resolve={async (result) => {
                                if (result) {
                                  try {
                                    await api.www
                                      .workspaces(workspace.id)
                                      .projects(project.id)
                                      .clientApprovalSchedules(schedule.id)
                                      .delete();

                                    toast.success('Client approval schedule has been deleted.');
                                    fetchData();
                                  } catch ({ message }) {
                                    toast.error(message);
                                  }
                                }

                                resolve(result);
                              }}>
                              Are you sure that you want to delete this client approval schedule?
                            </Confirmation>
                          ));
                        };

                        const handleEdit = () => handleOpenForm(schedule);

                        const handleStatusChange = async () => {
                          await api.www
                            .workspaces(workspace.id)
                            .projects(project.id)
                            .clientApprovalSchedules(schedule.id)
                            .setActiveStatus(!schedule.isActive);

                          fetchData();
                        };

                        const handleRun = async () => {
                          confirmation.prompt((resolve) => (
                            <ClientApprovalScheduleRunModal
                              projectId={project.id}
                              clientApprovalScheduleId={schedule.id}
                              onResolve={(result) => {
                                if (result) onChange();
                                resolve(result);
                              }}
                            />
                          ));
                        };

                        return (
                          <Table.BoxRow key={schedule.id} isDisabled={!schedule.isActive}>
                            <Table.Cell>
                              {
                                {
                                  weekly: 'Weekly',
                                  monthly: 'Monthly',
                                  semi_monthly: 'Semi-Monthly',
                                }[schedule.interval]
                              }
                            </Table.Cell>

                            <Table.Cell>
                              <Tags style={{ flexDirection: 'column' }}>
                                {schedule.includeTime && (
                                  <Tag style={{ backgroundColor: colors.grey5, fontSize: '.75rem', flex: 1 }}>Time</Tag>
                                )}
                                {schedule.includeExpenses && (
                                  <Tag style={{ backgroundColor: colors.grey5, fontSize: '.75rem', flex: 1 }}>
                                    Expenses
                                  </Tag>
                                )}
                              </Tags>
                            </Table.Cell>

                            <Table.Cell>
                              <Tags style={{ flexDirection: 'column' }}>
                                {schedule.members.length > 0 ? (
                                  <Members members={schedule.members} />
                                ) : (
                                  <Tag style={{ backgroundColor: colors.primary10, fontSize: '.75rem', flex: 1 }}>
                                    All Project Members
                                  </Tag>
                                )}
                              </Tags>
                            </Table.Cell>

                            <TableBoxRowActions>
                              <TableBoxRowActions.Edit onClick={handleEdit} />

                              <hr />

                              <TableBoxRowActions.Dropdown>
                                {({ setIsOpen }) => {
                                  return (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setIsOpen(false);
                                          handleStatusChange();
                                        }}>
                                        {schedule.isActive ? 'Deactivate' : 'Activate'}
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() => {
                                          setIsOpen(false);
                                          handleRun();
                                        }}>
                                        Run
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() => {
                                          setIsOpen(false);
                                          handleDelete();
                                        }}>
                                        Delete
                                      </Dropdown.Item>
                                    </>
                                  );
                                }}
                              </TableBoxRowActions.Dropdown>
                            </TableBoxRowActions>
                          </Table.BoxRow>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </Stack>

              <Drawer.Actions>
                <Buttons align="right">
                  <CancelButton onClick={closeDrawer}>Close</CancelButton>
                </Buttons>
              </Drawer.Actions>
            </>
          );
        }}
      </Drawer>

      {dialog}
    </>
  );
}

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Members = ({ members }) => {
  if (members.length === 0) return null;

  return (
    <Tags style={{ fontSize: '.75rem' }}>
      <Tag style={{ backgroundColor: colors.grey5 }}>{members[0].name}</Tag>

      {members.length > 1 && (
        <>
          <Tooltip
            placement="left"
            message={
              <div style={{ fontSize: '1rem' }}>
                <Title>Project Members</Title>

                {members.map((member) => (
                  <Tag style={{ backgroundColor: colors.grey5 }} key={member.id}>
                    <small>{member.name}</small>
                  </Tag>
                ))}
              </div>
            }>
            <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
              +{members.length - 1}
            </Tag>
          </Tooltip>
        </>
      )}
    </Tags>
  );
};
