import { BillableIcon, Currency, Hours, Table } from '~/components';
import React, { useEffect, useState } from 'react';
import { weights } from '~/styles';
import { calculateRevenue } from '~/utils';
import calculateServicesBudget from './calculateServicesBudget';
import { useApi, useWorkspace } from '~/contexts';

function AggregatedBudgetMembersTable({ projectModel }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [unassignedRow, setUnassignedRow] = useState({ data: null, isReady: false });
  const budget = calculateServicesBudget(projectModel, unassignedRow.data);
  const { unassignedBillableHours, unassignedNonBillableHours, unassignedRevenue } = unassignedRow.data || {};
  const currency = projectModel.currency;

  useEffect(() => {
    (async () => {
      if (!projectModel.id) return;

      try {
        const { data } = await api.www.workspaces(workspace.id).projects(projectModel.id).getUnassignedTasksBudget();
        setUnassignedRow({ data, isReady: true });
      } catch (error) {
        setUnassignedRow({ data: null, isReady: true });
      }
    })();
  }, [api, workspace.id, projectModel.id]);

  if (!unassignedRow.isReady) return null;

  return (
    <Table small>
      <Table.BoxHeader>
        <Table.Column>Project Member</Table.Column>
        <Table.Column align="right" width="7.5rem" isVisible={projectModel.isBillable}>
          Billable
        </Table.Column>
        <Table.Column align="right" width="7.5rem">
          Non-billable
        </Table.Column>
        <Table.Column
          align="right"
          width="7.5rem"
          isVisible={projectModel.isBillable && projectModel.billingTypeId === 'tm'}>
          Rate
        </Table.Column>
        <Table.Column
          align="right"
          width="7.5rem"
          isVisible={projectModel.isBillable && projectModel.billingTypeId === 'tm'}>
          Revenue
        </Table.Column>
        <Table.BoxActionsColumn />
      </Table.BoxHeader>
      <Table.Body>
        {projectModel.members.length > 0 && (
          <>
            {projectModel.members.map(
              ({ id, isActive, isBillable, member, taskBillableHours, taskNonBillableHours, rate }) => {
                const isActuallyBillable = projectModel.isBillable && isBillable;
                const actualRate = isActuallyBillable ? rate : null;
                const budgetRevenue = calculateRevenue(taskBillableHours, actualRate);

                return (
                  <Table.BoxRow key={id} isDisabled={!isActive}>
                    <Table.Cell>
                      <BillableIcon value={isActuallyBillable} />
                      {member.name}
                    </Table.Cell>
                    <Table.Cell>
                      <Hours value={taskBillableHours} />
                    </Table.Cell>
                    <Table.Cell>
                      <Hours value={taskNonBillableHours} />
                    </Table.Cell>
                    <Table.Cell>
                      <Currency value={actualRate} maximumFractionDigits={7} currency={currency} />
                    </Table.Cell>
                    <Table.Cell>
                      <Currency value={budgetRevenue} currency={currency} />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.BoxRow>
                );
              },
            )}
            {unassignedRow.data && (
              <Table.BoxRow key={projectModel.id}>
                <Table.Cell>{'Unassigned'}</Table.Cell>
                <Table.Cell>
                  <Hours value={unassignedBillableHours} />
                </Table.Cell>
                <Table.Cell>
                  <Hours value={unassignedNonBillableHours} />
                </Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  <Currency value={unassignedRevenue} currency={currency} />
                </Table.Cell>
                <Table.Cell />
              </Table.BoxRow>
            )}
            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell />
              <Table.Cell>
                <Hours value={budget.billableHours} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={budget.nonBillableHours} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={budget.effectiveRate} currency={currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={budget.revenue} currency={currency} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
}

export default AggregatedBudgetMembersTable;
