import {
  Button,
  DayPickerInput,
  Form,
  Icon,
  InlineTooltip,
  SingleSelect,
  TextArea,
  TimeInput,
  Tooltip,
} from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import RejectionMessage from '~/components/RejectionMessage';
import { useWorkspace, useMember } from '~/contexts';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { Field, ProjectSelect, RoleSelect, TaskSelect, TimeOffTypeSelect } from '../fields';
import { useTimesheets } from '../timesheets/TimesheetContext';
import TaskDetails from './TaskDetails';

// isActive is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledButton = ({ isActive, ...props }) => <Button {...props} />;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PlayPauseButton = styled(StyledButton)`
  flex-shrink: 0;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  padding-left: ${({ isActive }) => (isActive ? '0' : '0.125rem')};
  margin-left: 0.625rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.primary)};
  font-size: 0.75rem;
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  position: relative;

  &&:disabled {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.primary)};
  }
`;

export default function GeneralTab({
  form,
  entry,
  project,
  role,
  task,
  memberId,
  member,
  onPlayPause,
  isNew,
  drawerLoaded,
  rejectedHistoryItem,
}) {
  const { workspace } = useWorkspace();

  const { values, errors, conditionals, setFieldValue } = form;
  const { date, minutes, typeId, timeOffTypeId, projectId, projectRoleId, projectTaskId, notes, statusId } = values;

  const timeInputRef = useRef();
  const { member: currentMember } = useMember();

  useEffect(() => {
    if (isNew && drawerLoaded && timeInputRef.current) {
      timeInputRef.current.focus();
    }
  }, [isNew, drawerLoaded]);

  const timesheetContext = useTimesheets();
  const timesheet = useMemo(
    () => _.find(timesheetContext.timesheets, (ts) => ts.id === entry?.timesheetId),
    [timesheetContext, entry?.timesheetId],
  );

  const getDayProps = useCallback(
    (day) => {
      if (
        values.typeId === 'project_time' &&
        workspace.timeAndExpensesWithinProjectDates &&
        ((project?.start && !moment(day).isSameOrAfter(project?.start, 'day')) ||
          (project?.end && !moment(day).isSameOrBefore(project?.end, 'day')))
      ) {
        return {
          disabled: true,
          tooltip: `This day is outside of the project's start and end dates.`,
        };
      }

      if (timesheetContext.useTimesheets) {
        if (timesheet) {
          const disabled = !moment(day).isBetween(timesheet.start, timesheet.end, 'day', '[]');

          return {
            disabled,
            tooltip: disabled ? `This day is outside this time entry's timesheet period.` : null,
          };
        } else {
          const disabled = _.some(timesheetContext.timesheets, (ts) =>
            moment(day).isBetween(ts.start, ts.end, 'day', '[]'),
          );

          return {
            disabled,
            tooltip: disabled ? 'Timesheet has been submitted.' : null,
          };
        }
      }
    },
    [timesheetContext, timesheet, values, project, workspace],
  );

  return (
    <div>
      <Form.Section title="Entry Details">
        {entry?.statusId === 'rejected' && rejectedHistoryItem && (
          <RejectionMessage historyItem={rejectedHistoryItem}></RejectionMessage>
        )}

        {conditionals.member && (
          <ReadForm.Control>
            <ReadTextbox label="Member" value={conditionals.member} />
          </ReadForm.Control>
        )}

        {conditionals.statusRights && (
          <Form.Control>
            <Field name="statusId" errors={errors}>
              {entry?.timerStartedAt ? (
                <Tooltip message="Stop the timer to update the status of this time entry.">
                  <SingleSelect name="statusId" placeholder="Approval Status" value={statusId} disabled>
                    <option value="not_submitted">Not Submitted</option>
                    <option value="pending_approval">Pending Approval</option>
                    <option value="rejected">Rejected</option>
                    <option value="approved">Approved</option>
                  </SingleSelect>
                </Tooltip>
              ) : (
                <SingleSelect
                  name="statusId"
                  placeholder="Approval Status"
                  value={statusId}
                  disabled={conditionals.statusRights !== 'write'}
                  onChange={(event) => setFieldValue('statusId', event.target.value)}>
                  <option value="not_submitted" disabled={timesheetContext.useTimesheets && !!entry?.timesheetId}>
                    Not Submitted
                  </option>
                  <option value="pending_approval">Pending Approval</option>
                  <option value="rejected">Rejected</option>
                  <option value="approved">Approved</option>
                </SingleSelect>
              )}
            </Field>
          </Form.Control>
        )}

        <Form.Control>
          <Field name="date" errors={errors}>
            <DayPickerInput
              name="date"
              placeholder="Date"
              clearable={false}
              value={date}
              getDayProps={getDayProps}
              onChange={(value) => setFieldValue('date', value)}
            />
          </Field>
        </Form.Control>
        <Form.Control>
          <Field name="minutes" errors={errors}>
            <TimeContainer>
              <Tooltip message={!!entry?.timerStartedAt && 'Timer is currently running, pause to edit.'}>
                <TimeInput
                  ref={timeInputRef}
                  name="minutes"
                  placeholder={currentMember?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry ? '0.00' : '0:00'}
                  timerStartedAt={entry?.timerStartedAt}
                  useDecimal={currentMember?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry}
                  value={minutes}
                  onChange={(value) => setFieldValue('minutes', value)}
                />
              </Tooltip>
              {entry?.id && (
                <PlayPauseButton
                  disabled={conditionals.disableTimer}
                  isAnchor
                  isActive={!!entry.timerStartedAt}
                  onClick={onPlayPause.bind(this, entry)}>
                  <Icon icon={entry.timerStartedAt ? 'pause' : 'play'} />
                  {conditionals.disableTimer && <InlineTooltip message={conditionals.disableTimer} />}
                </PlayPauseButton>
              )}
            </TimeContainer>
          </Field>
          <Field name="typeId" errors={errors}>
            {conditionals.typeSelectDisabledTooltip ? (
              <Tooltip message={conditionals.typeSelectDisabledTooltip}>
                <ReadTextbox label="Type" value={entry?.type.name} />
              </Tooltip>
            ) : (
              <SingleSelect
                name="typeId"
                placeholder="Type"
                value={typeId}
                onChange={(event) => setFieldValue('typeId', event.target.value)}>
                <option value="project_time">Project Time</option>
                <option value="time_off" disabled={!member.timeOffAllowed}>
                  Time Off
                </option>
              </SingleSelect>
            )}
          </Field>
        </Form.Control>
        {!!conditionals.timeOffTypeId && (
          <Form.Control>
            <Field name="timeOffTypeId" errors={errors}>
              <TimeOffTypeSelect
                name="timeOffTypeId"
                placeholder="Time Off Type"
                memberId={memberId}
                initialTimeOffType={entry?.timeOffType}
                value={timeOffTypeId}
                onChange={(event) => setFieldValue('timeOffTypeId', event.target.value)}
              />
            </Field>
          </Form.Control>
        )}
        {!!conditionals.projectId && (
          <Form.Control>
            <Field name="projectId" errors={errors}>
              {conditionals.projectSelectDisabledTooltip ? (
                <Tooltip message={conditionals.projectSelectDisabledTooltip}>
                  <ReadTextbox label="Project" value={entry?.project?.name} />
                </Tooltip>
              ) : (
                <ProjectSelect
                  name="projectId"
                  placeholder="Project"
                  memberId={memberId}
                  initialProject={project || entry?.project}
                  value={projectId}
                  onChange={(event) => {
                    setFieldValue('projectId', event.target.value);
                    setFieldValue('projectRoleId', '');
                    setFieldValue('projectTaskId', '');
                  }}
                />
              )}
            </Field>
          </Form.Control>
        )}
        {!!conditionals.projectRoleId && (
          <Form.Control>
            <Field name="projectRoleId" errors={errors}>
              <RoleSelect
                name="projectRoleId"
                placeholder="Role"
                initialRole={role || entry?.role}
                projectId={projectId}
                memberId={memberId}
                value={projectRoleId}
                onChange={(event) => setFieldValue('projectRoleId', event.target.value)}
              />
            </Field>
          </Form.Control>
        )}
        {!!conditionals.projectTaskId && (
          <Form.Control>
            <Field name="projectTaskId" errors={errors}>
              <TaskSelect
                name="projectTaskId"
                placeholder="Task"
                initialTask={task || entry?.task}
                projectId={projectId}
                projectRoleId={projectRoleId}
                memberId={memberId}
                value={projectTaskId}
                onChange={(event) => setFieldValue('projectTaskId', event.target.value)}
              />
            </Field>
          </Form.Control>
        )}
        <Form.Control>
          <Field name="notes" errors={errors}>
            <TextArea
              name="notes"
              placeholder={`Notes ${conditionals.requireNotes ? '(required)' : ''}`}
              materialPlaceholder="Notes"
              rows={4}
              value={notes}
              onChange={(event) => setFieldValue('notes', event.target.value)}
            />
          </Field>
        </Form.Control>
      </Form.Section>
      {typeId === 'project_time' && projectTaskId && (
        <TaskDetails
          taskId={projectTaskId}
          memberId={entry?.memberId ?? memberId ?? member.id}
          projectRoleId={projectRoleId}
          entryRoleWorkedMinutes={
            entry?.task?.id === projectTaskId && entry?.role?.id === projectRoleId ? entry.minutes || 0 : 0
          }
          entryMinutes={Math.max(parseInt(minutes) || 0, 0)}
          entryWorkedMinutes={entry?.task?.id === projectTaskId ? entry.minutes || 0 : 0}
        />
      )}
    </div>
  );
}
