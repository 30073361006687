import { Checkbox, Currency, DateTime, Icon, Table } from '~/components';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { colors } from '~/styles';
import {
  ApprovalAction,
  ApprovalActions,
  Approve,
  Cell,
  Details,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  PublicList,
  Reject,
  RejectionReason,
  Row,
  SelectAll,
  ToggleSwitch,
} from '../components/ListComponents';

export default function ExpensesTab({
  readOnly,
  clientApproval,
  isSubmitting,
  selection,
  locale,
  onSelectionChange,
  onStatusChange,
  onGroupAction,
}) {
  const currency = clientApproval.project.currency;

  const groups = useMemo(() => {
    return _.orderBy(
      clientApproval.expenseItems.reduce((a, v) => {
        a[v.member.id] = a[v.member.id] || { member: v.member, items: [] };
        a[v.member.id].items.push(v);
        return a;
      }, {}),
      'member.name',
    );
  }, [clientApproval.expenseItems]);

  const handleSelectAllChange = () =>
    onSelectionChange(selection.length > 0 ? [] : clientApproval.expenseItems.map((item) => item.id));

  return (
    <PublicList>
      <Table.Total value={clientApproval.expenseItems.length} label="Expense Item" />

      <ListHeader>
        <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem">
          <SelectAll>
            <Checkbox
              checked={selection.length > 0}
              partial={selection.length < clientApproval.expenseItems.length}
              disabled={readOnly || isSubmitting || clientApproval.expenseItems.length === 0}
              onChange={handleSelectAllChange}
            />
          </SelectAll>
        </Cell>

        <Cell padding="0.875rem 1rem">Member</Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Pending
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Approved
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Rejected
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Total
        </Cell>

        <Cell $width="8rem" padding="0.875rem 1rem" />
      </ListHeader>

      {clientApproval.expenseItems.length === 0 && (
        <NoResultsCell>There are no expense items on this client approval.</NoResultsCell>
      )}

      {_.map(groups, (group, key) => {
        return (
          <Group
            key={key}
            group={group}
            selection={selection}
            readOnly={readOnly}
            isSubmitting={isSubmitting}
            currency={currency}
            locale={locale}
            onSelectionChange={onSelectionChange}
            onAction={onGroupAction}>
            {group.items.map((item) => {
              const checked = !!selection?.includes(item.id);

              const handleSelectionChange = () =>
                onSelectionChange(checked ? selection.filter((id) => id !== item.id) : [...selection, item.id]);

              const handleActionClick = (statusId) => {
                if (statusId === item.clientStatusId) statusId = null;
                onStatusChange(item, statusId);
              };

              const submitting =
                (isSubmitting === 'batch' && selection.includes(item.id)) ||
                isSubmitting?.group?.items.some((expenseItem) => expenseItem.id === item.id) ||
                isSubmitting === item.id;

              const disableActions = readOnly || submitting;

              return (
                <Row key={item.id} data-testid="row">
                  <Cell flex="0" $width="2.5rem" padding="0.5rem 0rem 0.5rem 2rem" onClick={(e) => e.stopPropagation()}>
                    <Checkbox checked={checked} disabled={disableActions} onChange={handleSelectionChange} />
                  </Cell>

                  <Cell $width="8rem">
                    <DateTime value={item.date} />
                  </Cell>

                  <Cell $width="12rem">{item.expenseCategory && <p>{item.expenseCategory.name}</p>}</Cell>

                  <Cell>
                    <Details>
                      {item.vendorName && <p>{item.vendorName}</p>}

                      <Notes>{item.notes ? <em>"{item.notes}"</em> : 'No notes provided'}</Notes>

                      {item.clientStatusId === 'rejected' && item.clientRejectionReason && (
                        <RejectionReason>{item.clientRejectionReason}</RejectionReason>
                      )}
                    </Details>
                  </Cell>

                  <Cell right $width="8rem">
                    <Currency value={item.expenseAmount} currency={currency} locale={locale} />
                  </Cell>

                  <Cell right $width="8rem">
                    <ApprovalActions onClick={(e) => e.stopPropagation()}>
                      <ApprovalAction>
                        <Approve
                          disabled={disableActions}
                          status={item.clientStatusId}
                          onClick={() => handleActionClick('approved')}>
                          <Icon icon="thumbs-up" />
                        </Approve>
                      </ApprovalAction>
                      <ApprovalAction>
                        <Reject
                          disabled={disableActions}
                          status={item.clientStatusId}
                          onClick={() => handleActionClick('rejected')}>
                          <Icon icon="thumbs-down" />
                        </Reject>
                      </ApprovalAction>
                    </ApprovalActions>
                  </Cell>
                </Row>
              );
            })}
          </Group>
        );
      })}
    </PublicList>
  );
}

const filter = (status) => (item) => item.clientStatusId === status;
const sum = (item) => _.round(item.expenseAmount, 2);

function Group({ group, selection, isSubmitting, readOnly, currency, locale, children, onSelectionChange, onAction }) {
  const [collapsed, setCollapsed] = useState(true);

  const amount = useMemo(() => {
    return {
      approved: _.round(_(group.items).filter(filter('approved')).sumBy(sum), 2),
      rejected: _.round(_(group.items).filter(filter('rejected')).sumBy(sum), 2),
      pending: _.round(_(group.items).filter(filter(null)).sumBy(sum), 2),
      total: _.round(_(group.items).sumBy(sum), 2),
    };
  }, [group]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const groupSelection = useMemo(() => {
    return selection.filter((id) => group.items.some((item) => item.id === id));
  }, [group, selection]);

  const groupSelected = useMemo(() => {
    return group.items.some((e) => groupSelection.includes(e.id));
  }, [group, groupSelection]);

  const handleGroupSelectionChange = () => {
    onSelectionChange(
      groupSelected
        ? selection.filter((id) => !group.items.map(({ id }) => id).includes(id))
        : [...selection, ...group.items.map(({ id }) => id)],
    );
  };

  const status = useMemo(() => {
    return {
      hasPending: _.some(group.items, (item) => item.clientStatusId === null),
      hasApproved: _.some(group.items, (item) => item.clientStatusId === 'approved'),
      hasRejected: _.some(group.items, (item) => item.clientStatusId === 'rejected'),

      get approved() {
        if (!this.hasApproved) return null;
        if (this.hasApproved && !this.hasRejected && !this.hasPending) return 'approved';
        return 'partially_approved';
      },

      get rejected() {
        if (!this.hasRejected) return null;
        if (this.hasRejected && !this.hasApproved && !this.hasPending) return 'rejected';
        return 'partially_rejected';
      },
    };
  }, [group.items]);

  const handleActionClick = (statusId) => {
    let items = group.items;

    switch (status[statusId]) {
      case 'approved':
      case 'rejected':
        statusId = null;
        break;

      case 'partially_approved':
      case 'partially_rejected':
        items = group.items.filter((item) => item.clientStatusId === statusId);
        statusId = null;
        break;
    }

    onAction({ ...group, items }, statusId);
  };

  const submitting = isSubmitting?.group?.member.id === group.member.id || (isSubmitting === 'batch' && groupSelected);

  const disableActions = readOnly || submitting;

  return (
    <ListGroup>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell flex="0" padding="0" onClick={(event) => event.stopPropagation()}>
          <Checkbox
            disabled={disableActions}
            checked={groupSelected}
            partial={groupSelection.length < group.items.length}
            onChange={handleGroupSelectionChange}
          />
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right color={colors.warning} $width="8rem">
          <Currency value={amount.pending} currency={currency} locale={locale} />
        </Cell>

        <Cell right color={colors.success} $width="8rem">
          <Currency value={amount.approved} currency={currency} locale={locale} />
        </Cell>

        <Cell right color={colors.danger} $width="8rem">
          <Currency value={amount.rejected} currency={currency} locale={locale} />
        </Cell>

        <Cell right $width="8rem">
          <Currency value={amount.total} currency={currency} locale={locale} />
        </Cell>

        <Cell right $width="8rem">
          <ApprovalActions onClick={(e) => e.stopPropagation()}>
            <ApprovalAction>
              <Approve disabled={disableActions} status={status.approved} onClick={() => handleActionClick('approved')}>
                <Icon icon="thumbs-up" />
              </Approve>
            </ApprovalAction>
            <ApprovalAction>
              <Reject disabled={disableActions} status={status.rejected} onClick={() => handleActionClick('rejected')}>
                <Icon icon="thumbs-down" />
              </Reject>
            </ApprovalAction>
          </ApprovalActions>
        </Cell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}
