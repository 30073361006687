import { SingleSelect } from '~/components';
import React from 'react';

const options = [
  { id: 'active', name: 'No' },
  { id: 'archived', name: 'Yes' },
];

export default function ProjectTaskRecordStatusFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Task Archived"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}
