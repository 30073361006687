import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useIsMounted } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

export default function ManualSyncModal({ integration, onClose, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = {
    startDate: integration.settings.syncStartDate,
    endDate: '',
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).expensify.manualSync(emptyStringToNull(values));
      onFinish();
      onClose();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <ModalCard title="Manual Expensify Sync" onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          startDate: Yup.date().label('Start Date').nullable().required(),
          endDate: Yup.date()
            .label('End Date')
            .nullable()
            .when('startDate', (startDate, schema) =>
              startDate ? schema.min(Yup.ref('startDate'), 'End Date must be on or after Start Date.') : schema,
            ),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Description>
                  Start a new manual sync to test your settings or import legacy data. Choose a start and optional end
                  date to sync your Expensify reports.
                </Description>
                <Form.Control>
                  <Field.DayPicker name="startDate" placeholder="Start Date" clearable={false} />
                </Form.Control>
                <Form.Control>
                  <Field.DayPicker name="endDate" placeholder="End Date" clearable={true} />
                </Form.Control>
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                    Close
                  </CancelButton>
                  <Button type="submit" isLoading={isLoading}>
                    Start Sync
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
