import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '~/components';
import { useApi } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { Footer, Header } from '../../components';
import AuthProviderForm from './AuthProviderForm';
import PasswordForm from './PasswordForm';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  text-align: center;
`;

const Description = styled.p`
  margin-top: 2rem;
`;

const LoadingIcon = styled(Icon)`
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  font-size: 2rem;
`;

const ErrorMessage = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  color: ${colors.white};
  background-color: ${colors.danger};
  border-radius: 0.625rem;
  text-align: center;

  a {
    color: ${colors.white};
    text-decoration: underline;

    &:hover {
      color: ${colors.grey10};
    }
  }
`;

export default function JoinWorkspace() {
  useDocumentTitle('Join Workspace');

  const api = useApi();
  const { token } = useParams();
  const [auth, setAuth] = useState(null);
  const [status, setStatus] = useState('validate-token');

  useEffect(() => {
    const joinWorkspace = async () => {
      try {
        const {
          data: { member, authProviders },
        } = await api.www.validateJoinWorkspaceToken({ token });
        const authProvider = authProviders.find((ap) => ap.isDefault);
        if (['password', 'saml'].includes(authProvider?.method)) {
          setAuth({ member, authProvider });
          setStatus('success');
        } else {
          setStatus('unknown-auth-provider');
        }
      } catch ({ status, response }) {
        if (status === 409 && response.data.code) {
          setStatus(response.data.code);
        } else {
          setStatus('error');
        }
      }
    };
    joinWorkspace();
    return joinWorkspace.cancel;
  }, [api, token]);

  return (
    <>
      <Header />
      <Container>
        <Box>
          {status === 'validate-token' && (
            <>
              <Title>Join Workspace</Title>
              <LoadingIcon icon="spinner" spin />
            </>
          )}
          {status === 'invitation-accepted' && (
            <>
              <Title>Invitation Accepted</Title>
              <Description>
                You have accepted your invitation and should now have access to the workspace. Send an email to{' '}
                <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
              </Description>
            </>
          )}
          {status === 'invalid-invitation' && (
            <>
              <Title>Join Workspace</Title>
              <ErrorMessage>
                There was a problem joining this workspace. You may need to request a new invitation from your workspace
                administrator. Send an email to <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further
                assistance.
              </ErrorMessage>
            </>
          )}
          {status === 'unknown-auth-provider' && (
            <>
              <Title>Join Workspace</Title>
              <ErrorMessage>
                The authentication provider for this workspace is not supported. Send an email to{' '}
                <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
              </ErrorMessage>
            </>
          )}
          {status === 'error' && (
            <>
              <Title>Join Workspace</Title>
              <ErrorMessage>
                An unexpected error has occured. The Ruddr team has been notified of this error. Send an email to{' '}
                <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
              </ErrorMessage>
            </>
          )}
          {status === 'success' && auth && (
            <>
              {{
                password: () => <PasswordForm member={auth.member} />,
                saml: () => <AuthProviderForm member={auth.member} authProvider={auth.authProvider} />,
              }[auth.authProvider.method]()}
            </>
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
