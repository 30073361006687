import { Button, Buttons, CancelButton, FormMessage, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import _ from 'lodash';
import React from 'react';

export default function ResubmitRejectedTimeConfirmation({ timeEntries, onSubmit, resolve }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, status }, form] = useForm();

  const toast = useToast();

  const handleSubmit = async () => {
    form.submit();

    const timesheetIds = _(timeEntries)
      .map((entry) => entry.timesheetId)
      .compact()
      .value();

    try {
      for await (const timesheetId of timesheetIds) {
        await api.www.workspaces(workspace.id).timesheets(timesheetId).resubmitRejectedTime();
      }

      form.done();

      await onSubmit();

      toast.success('The rejected time entries have been resubmitted.');

      resolve(true);
    } catch ({ message }) {
      form.error({ message });
      resolve(false);
    }
  };

  return (
    <ModalCard title="Resubmit Rejected Time" onClose={() => resolve(false)}>
      <ModalCard.Body>
        {timeEntries.length === 1 ? (
          <p>Do you want to resubmit this rejected time entry?</p>
        ) : (
          <p>Do you want to resubmit these {timeEntries.length} time entries?</p>
        )}

        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Resubmit Rejected Time
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
