const locales = [
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-NZ',
  'en-SG',
  'en-US',
  'es-419',
  'es-AR',
  'es-ES',
  'es-MX',
  'fr-CA',
  'fr-FR',
  'id-ID',
  'it-IT',
  'ja-JP',
  'nl-NL',
  'pt-BR',
  'pt-PT',
  'sv-SE',
  'th-TH',
  'zh-CN',
];

export default locales;
