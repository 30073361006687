/**
 * Validates if a given value can be interpreted as a boolean false.
 * Accepts certain string representations of false such as 'no', 'false', '0',
 * or any other falsy value.
 *
 * @param {any} value - The value to be validated.
 * @returns {boolean} - Returns true if the value can be considered as boolean false.
 */
export default function isFalsy(value) {
  const normalizedValue = String(value).trim().toLowerCase();
  const falseValues = ['n', 'no', 'false', '0'];
  return falseValues.includes(normalizedValue);
}
