import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

const Input = React.forwardRef(({ onChange, wait = null, ...props }, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitChange = useCallback(
    _.debounce((event) => onChange(event), wait),
    [onChange],
  );

  const handleChange = (event) => {
    if (wait) {
      event.persist();
      setValue(event.target.value);
      emitChange(event);
    } else {
      onChange(event);
    }
  };

  return <input {...props} ref={ref} value={value} onChange={handleChange} />;
});

export default Input;
