import {
  Button,
  Buttons,
  CancelButton,
  DateTime,
  FormMessage,
  Icon,
  Inline,
  ModalCard,
  Radio,
  RadioGroup,
  Tooltip,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const WarningIcon = styled(Icon)`
  font-size: 2.5rem;
  margin-right: 1rem;
`;

const dateFormat = 'dddd l';

export default function SubmitTimesheetConfirmation({ date, member, onSubmit, resolve, timesheetContext }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, status }, form] = useForm();

  const toast = useToast();

  const { notify } = useSubscription();

  const dates = useMemo(() => {
    return {
      startOfWeek: moment(date).startOf('isoWeek'),
      endOfWeek: moment(date).endOf('isoWeek'),

      get startOfMonth() {
        return moment(this.endOfWeek).startOf('month');
      },

      get endOfMonth() {
        return moment(this.startOfWeek).endOf('month');
      },

      get isMonthCrossOver() {
        return this.startOfWeek.month() !== this.endOfWeek.month();
      },
    };
  }, [date]);

  const [state, setState] = useState(null);

  useEffect(() => {
    (async () => {
      const isPreviousMonthTimesheetSubmitted =
        dates.isMonthCrossOver &&
        timesheetContext.isTimesheetSubmitted({
          start: dates.startOfWeek,
          end: dates.endOfMonth,
        });

      const isNextMonthTimesheetSubmitted =
        dates.isMonthCrossOver &&
        timesheetContext.isTimesheetSubmitted({
          start: dates.startOfMonth,
          end: dates.endOfWeek,
        });

      const periods = [];

      if (dates.isMonthCrossOver) {
        const month = {
          key: 'month',
          start: dates.startOfWeek,
          end: dates.endOfMonth,
          isSubmitted: isPreviousMonthTimesheetSubmitted,
        };

        const { data } = await api.www
          .workspaces(workspace.id)
          .timesheets()
          .submissionSummary({
            start: month.start.format(dateFormats.isoDate),
            end: month.end.format(dateFormats.isoDate),
            memberId: member?.id,
          });

        month.summary = data;

        periods.push(month);
      }

      const week = {
        key: 'week',
        start:
          isPreviousMonthTimesheetSubmitted || isNextMonthTimesheetSubmitted ? dates.startOfMonth : dates.startOfWeek,
        end: dates.endOfWeek,
        isSubmitted: isNextMonthTimesheetSubmitted,
      };

      const { data } = await api.www
        .workspaces(workspace.id)
        .timesheets()
        .submissionSummary({
          start: week.start.format(dateFormats.isoDate),
          end: week.end.format(dateFormats.isoDate),
          memberId: member?.id,
        });

      week.summary = data;

      periods.push(week);

      setState({ periods, period: periods.find((p) => !p.isSubmitted) ?? null });
    })();
  }, [dates, api, member?.id, workspace.id, timesheetContext]);

  if (!state) return null;

  const { period, periods } = state;

  const handleSubmit = async () => {
    form.submit();

    const { start, end } = period;

    try {
      await api.www
        .workspaces(workspace.id)
        .timesheets()
        .submit({
          start: start.format(dateFormats.isoDate),
          end: end.format(dateFormats.isoDate),
          memberId: member?.id,
        });

      form.done();

      await onSubmit();

      toast.success('The timesheet has been submitted.');

      resolve(true);
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch ({ message }) {
      form.error({ message });
    }
  };

  const handlePeriodChange = (value) => setState({ ...state, period: periods.find((p) => p.key === value) });

  const Modal = (props) => <ModalCard title="Submit Timesheet" onClose={() => resolve(false)} {...props} />;

  const SubmitFooter = () => (
    <ModalCard.Footer>
      <Buttons align="right">
        <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
        <Button isLoading={isSubmitting} onClick={handleSubmit}>
          Submit Timesheet
        </Button>
      </Buttons>
    </ModalCard.Footer>
  );

  const OKFooter = () => (
    <ModalCard.Footer>
      <Buttons align="right">
        <CancelButton onClick={() => resolve(false)}>OK</CancelButton>
      </Buttons>
    </ModalCard.Footer>
  );

  const Status = () => status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>;

  if (periods.length === 1) {
    if (period?.summary.canSubmit) {
      return (
        <Modal>
          <ModalCard.Body>
            <p>
              This will close the time period from <DateTime value={period.start} /> to <DateTime value={period.end} />{' '}
              and route all unapproved time entries for approval.
            </p>

            <Status />
          </ModalCard.Body>

          <SubmitFooter />
        </Modal>
      );
    } else {
      return (
        <Modal>
          <ModalCard.Body>
            <p>You cannot submit this timesheet because you do not have enough time entered for this period.</p>
            <Inline style={{ marginTop: '1rem' }}>
              <WarningIcon icon="exclamation-triangle" color={colors.warning} />
              <p>
                You are submitting {pluralize('hour', period?.summary.hours, true)} and have a capacity of{' '}
                {pluralize('hour', period?.summary.capacity, true)} for this period.
              </p>
            </Inline>

            <Status />
          </ModalCard.Body>

          <OKFooter />
        </Modal>
      );
    }
  } else {
    if (periods.some((p) => !p.isSubmitted && p.summary.canSubmit)) {
      return (
        <Modal>
          <ModalCard.Body>
            <div>
              <p>This will close the following time period and route all unapproved time entries for approval.</p>

              <RadioGroup
                style={{ marginTop: '1rem' }}
                direction="vertical"
                name="period"
                value={period?.summary?.canSubmit ? period.key : null}
                onChange={handlePeriodChange}>
                {periods.map((p) => (
                  <Radio
                    key={p.key}
                    value={p.key}
                    label={
                      <Tooltip
                        message={
                          p.isSubmitted
                            ? 'The timesheet has been submitted'
                            : !p.summary.canSubmit
                              ? `You do not have enough time entered for this period.`
                              : null
                        }>
                        {p.end.diff(p.start, 'days') === 6 ? 'Full' : 'Partial'} week from{' '}
                        <DateTime value={p.start} format={dateFormat} /> through{' '}
                        <DateTime value={p.end} format={dateFormat} />{' '}
                        {!p.summary.canSubmit && (
                          <Icon icon="exclamation-triangle" color={colors.warning} style={{ marginLeft: '0.5rem' }} />
                        )}
                      </Tooltip>
                    }
                    disabled={p.isSubmitted || !p.summary.canSubmit}
                  />
                ))}
              </RadioGroup>
            </div>

            <Status />
          </ModalCard.Body>

          <SubmitFooter />
        </Modal>
      );
    } else {
      return (
        <Modal>
          <ModalCard.Body>
            <Inline>
              <WarningIcon icon="exclamation-triangle" color={colors.warning} />
              <p>
                You cannot submit this timesheet because you do not have enough time entered for the available periods.
              </p>
            </Inline>

            <Status />
          </ModalCard.Body>

          <OKFooter />
        </Modal>
      );
    }
  }
}
