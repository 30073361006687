import slugify from '@sindresorhus/slugify';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage, Level } from '~/components';
import { useApi, useReferrer } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import locales from '~/lookups/locales';
import { colors, devices, weights } from '~/styles';
import { slugValidator } from '~/utils/validators';
import { Footer, Header } from '../components';
import EnvelopeIcon from './envelope.svg?react';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 39rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const BoxTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

const UniqueId = styled.div`
  display: flex;

  > div:last-child {
    flex: 1;
    flex-basis: 12rem;
  }

  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`;

const Origin = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  color: ${colors.grey40};
  font-weight: ${weights.bold};
  padding-right: 0;
  white-space: nowrap;
  padding-left: 1.125em;
  height: 2.5rem;

  + div {
    padding-left: 0.25rem;
  }
`;

const initialState = { isSubmitting: false, status: null, message: null };
const handlers = {
  submit: () => ({ isSubmitting: true, status: null, message: null }),
  error: ({ message } = {}) => ({
    isSubmitting: false,
    status: 'form-error',
    message,
  }),
};

function Signup() {
  useDocumentTitle('Signup');

  const [view, setView] = useState('form');

  return (
    <>
      <Header />
      <Container>
        {{
          form: () => <NewWorkspaceForm onSuccess={() => setView('success')} />,
          success: () => <Success />,
        }[view]()}
      </Container>
      <Footer />
    </>
  );
}

function NewWorkspaceForm({ onSuccess }) {
  const api = useApi();
  const [{ isSubmitting, status, message }, actions] = useActions(handlers, initialState);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const referrer = useReferrer();

  async function handleSubmit(values) {
    try {
      actions.submit();
      await api.www.signup({
        workspace: {
          companyName: values.companyName,
          companyServiceTypeId: values.companyServiceTypeId,
          name: values.workspaceName,
          key: values.key,
          currency: values.currency,
          locale: values.locale,
          timeZoneId: values.timeZoneId,
        },

        member: {
          email: values.email,
          name: values.name,
          password: values.password,
          passwordVerification: values.passwordVerification,
          referrerUrl: referrer.url,
          utmSource: referrer.utmSource,
          utmCampaign: referrer.utmCampaign,
        },
      });

      onSuccess();
    } catch (error) {
      switch (error.status) {
        case 409:
          actions.error({ message: error.message ?? 'There was a problem signing up for a new workspace.' });
          return;
        case 422:
          actions.error({ message: `The Workspace URL ID "${values.key}" is already taken.` });
          return;
        default:
          actions.error(error?.message);
      }
    }
  }

  return (
    <>
      <Heading>
        <Title>Sign up for a new workspace</Title>
        <Byline>
          Give your workspace a name, select the type of professional services firm and fill your account information.
        </Byline>
      </Heading>

      <Box>
        <Formik
          initialValues={{
            // Workspace details
            companyName: '',
            companyServiceTypeId: '',
            workspaceName: '',
            key: '',
            currency: 'USD',
            locale: locales.find((l) => l === navigator.language) ?? 'en-US',
            timeZoneId: null,

            // Account details
            email: searchParams.get('email') ?? '',
            name: searchParams.get('name') ?? '',
            password: '',
            passwordVerification: '',
            termsAgree: false,
          }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            // Workspace details
            companyName: Yup.string().label('Company Name').max(255).required(),
            companyServiceTypeId: Yup.string().label('Professional Service Type').required(),
            workspaceName: Yup.string().label('Workspace Name').max(255).required(),
            key: Yup.string()
              .label('Workspace URL ID')
              .max(255)
              .matches(slugValidator.expression, { message: slugValidator.message })
              .required(),

            // Account details
            email: Yup.string().label('Email').email().required(),
            name: Yup.string().label('Name').max(255).required(),
            password: Yup.string()
              .label('Password')
              .min(8, 'Password must be at least 8 characters long')
              .max(255, 'Password must be at most 255 characters long')
              .required(),
            passwordVerification: Yup.string()
              .label('Confirm Password')
              .required()
              .oneOf([Yup.ref('password')], 'Passwords do not match'),
            termsAgree: Yup.bool().oneOf([true], "You must agree to Ruddr's terms"),
          })}>
          {(formik) => {
            return (
              <Form autoComplete="off">
                <div>
                  <BoxTitle>Workspace Details</BoxTitle>
                  <Form.Control>
                    <Field.Text
                      autoFocus
                      name="companyName"
                      placeholder="Company Name"
                      maxLength={255}
                      onChange={({ target: { value } }) => {
                        formik.setValues({
                          ...formik.values,
                          companyName: value,
                          workspaceName: value,
                          key: slugify(value),
                        });
                      }}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.CompanyServiceTypeSelect
                      name="companyServiceTypeId"
                      placeholder="Professional Service Type"
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text
                      name="workspaceName"
                      placeholder="Workspace Name"
                      maxLength={255}
                      onChange={({ target: { value } }) => {
                        formik.setValues({ ...formik.values, workspaceName: value, key: slugify(value) });
                      }}
                    />
                  </Form.Control>
                  <Form.Control>
                    <UniqueId>
                      <Origin>{`${window.origin}/app/`}</Origin>
                      <Field.Control>
                        <Field.Text name="key" placeholder="Workspace URL ID" maxLength={255} />
                      </Field.Control>
                    </UniqueId>
                  </Form.Control>

                  <Form.Control>
                    <Field.CurrencySelect name="currency" clearable={false} />
                  </Form.Control>
                  <Form.Control>
                    <Field.RegionalFormatSelect name="locale" clearable={false} />
                  </Form.Control>
                  <Form.Control data-testid="timeZone">
                    <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                  </Form.Control>
                </div>

                <hr style={{ margin: '2.5rem 0' }} />

                <BoxTitle>Account Details</BoxTitle>
                <Form.Control>
                  <Field.Text name="email" placeholder="Email" type="email" />
                </Form.Control>
                <Form.Control>
                  <Field.Text name="name" placeholder="Name" maxLength={255} />
                </Form.Control>
                <Form.Control>
                  <Field.Text
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    maxLength={255}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.Text
                    name="passwordVerification"
                    placeholder="Confirm Password"
                    type="password"
                    maxLength={255}
                  />
                </Form.Control>

                <hr style={{ margin: '2.5rem 0' }} />

                <Form.Control>
                  <Field.Checkbox
                    name="termsAgree"
                    label={
                      <>
                        I agree to Ruddr's{' '}
                        <a href="/master-subscription-agreement" target="_blank">
                          terms
                        </a>
                      </>
                    }
                  />
                </Form.Control>

                {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}

                <FormAction>
                  <Button type="submit" isLoading={isSubmitting}>
                    Create Workspace
                  </Button>
                </FormAction>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}

function loadCapterraConversionScript() {
  const hostname = window.location.hostname;
  if (!hostname.endsWith('ruddr.io')) {
    return;
  }

  const id = '2154093';
  const key = 'cb2512e6561d3ef295aa47e7e06f39d2';

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://ct.capterra.com/capterra_tracker.js?vid=${id}&vkey=${key}`;

  document.body.appendChild(script);
}

function Success() {
  useEffect(() => {
    loadCapterraConversionScript();
  }, []);

  return (
    <Box>
      <Level gap="5%">
        <Level.Item narrow>
          <EnvelopeIcon />
        </Level.Item>
        <Level.Item>
          <BoxTitle>Check your email</BoxTitle>
          <Byline>
            We've sent an email to confirm your email address. Click the link in that email to get started with Ruddr.
          </Byline>
        </Level.Item>
      </Level>
    </Box>
  );
}

export default Signup;
