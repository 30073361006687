import React, { useEffect, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, Table } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import TaxCodeSelect from './components/TaxCodeSelect';

export default function TaxRateMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ taxRates, taxCodes, isReady }, setState] = useState({ taxRates: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: taxRates } = await api.www.workspaces(workspace.id).taxRates().get({ size: 1000 });
      const { data: taxCodes } = await api.www.workspaces(workspace.id).qbo.getTaxCodes();

      if (!isMounted.current) return;
      setState({ taxRates, taxCodes, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updateTaxRateMapping = ({ taxRateId, qboTaxCodeId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      taxRates: taxRates.map((c) => (c.id === taxRateId ? { ...c, qboTaxCodeId } : c)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Tax Rate Mappings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = taxRates.map((taxRate) => ({ taxRateId: taxRate.id, qboTaxCodeId: taxRate.qboTaxCodeId }));
            await api.www.workspaces(workspace.id).qbo.updateTaxRateMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table>
              <Table.BoxHeader>
                <Table.Column>Tax Rate</Table.Column>
                <Table.Column>QuickBooks Tax Code</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {taxRates.map((taxRate) => {
                  const handleTaxCodeChange = (event) => {
                    updateTaxRateMapping({
                      taxRateId: taxRate.id,
                      qboTaxCodeId: event.target.value || null,
                    });
                  };

                  return (
                    <Table.BoxRow key={taxRate.id}>
                      <Table.Cell>{taxRate.name}</Table.Cell>
                      <Table.Cell>
                        <TaxCodeSelect
                          taxCodes={taxCodes}
                          value={taxRate.qboTaxCodeId}
                          onChange={handleTaxCodeChange}
                        />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
