import { Icon, Drawer, Tooltip, ExpenseApprovalPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useActions, useDirtyCheck, useFeatures } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import { colors } from '~/styles';
import { rgba } from 'polished';
import ExpenseItemForm from './ExpenseItemForm';
import { ExpenseItemView } from './ExpenseItemView';
import expenseLockedReasons from '../expenseLockedReason';
import _ from 'lodash';

const Statuses = styled.div`
  position: absolute;
  top: 0;
  right: 4rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 0.625rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.875rem;
  height: 1.875rem;
  padding: 0 1rem;
  color: ${({ statusColor }) => statusColor};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 999rem;
  box-shadow: 0 0.1875rem 0.5rem ${rgba(colors.black, 0.2)};

  .icon {
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    font-size: 0.625rem;
  }
`;

const InvoiceStatus = styled(Status)`
  color: ${colors.grey100};
`;

const initialState = { expenseItem: null, isReady: false };
const handlers = {
  ready: ({ expenseItem }) => ({ isReady: true, expenseItem }),
};

function ExpenseItemDrawer({
  impersonatedMember,
  onClose,
  intitialExpenseItem,
  expenseReport,
  onSaved,
  onDeleted,
  overrideShowMember,
  mode = 'edit',
}) {
  const api = useApi();
  const { expenseItemId } = useParams();
  const { workspace } = useWorkspace();
  const [{ isReady, expenseItem }, actions] = useActions(handlers, initialState);
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current && formRef.current.dirty);
  const [impersonatedMemberId] = useState(impersonatedMember?.id);
  const [drawerLoaded, setDrawerLoaded] = useState(false);
  const features = useFeatures();

  const rejectedHistoryItem = useMemo(
    () => _.find(_.orderBy(expenseItem?.history, ['createdAt'], ['desc']), { statusId: 'rejected' }),
    [expenseItem],
  );

  const fetchData = useCallback(async () => {
    if (!expenseItemId && expenseReport) {
      actions.ready({ expenseItem: { expenseReportId: expenseReport.id } });
      return;
    }
    try {
      const { data: expenseItem } = await api.www
        .workspaces(workspace.id)
        .expenseItems(expenseItemId)
        .get({ memberId: impersonatedMemberId });
      actions.ready({ expenseItem });
    } catch (error) {
      actions.ready({ expenseItem: null });
    }
  }, [actions, workspace.id, expenseItemId, expenseReport, impersonatedMemberId, api]);

  useEffect(() => {
    if (!intitialExpenseItem) {
      fetchData();
    }
  }, [fetchData, intitialExpenseItem]);

  if (!isReady) return null;
  if (!expenseItem) return <ErrorPage.NotFound publicSite={false} />;
  const item = intitialExpenseItem || expenseItem;
  const isLocked = item?.lockStatusId !== 'unlocked' && item.id;
  if (isLocked) mode = 'view';
  const isNew = !item?.id;

  const statusColor =
    {
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    }[item?.status?.id] || colors.primary;

  const statusMessage = !item.status
    ? 'New expense item not yet submitted for approval.'
    : {
        approved: `Expense item has been ${item.statusModeId === `auto` ? 'automatically' : `manually`} approved.`,
        rejected: `Expense item has been rejected.`,
        not_submitted: 'Expense item has not been submitted for approval.',
        pending_approval: 'Expense item has been submitted and is pending approval.',
      }[item.status?.id];

  const clientStatusMessage = {
    approved: `Expense item has been approved by the client.`,
    rejected: `Expense item has been rejected by the client.`,
  }[item?.clientStatus?.id];

  return (
    <Drawer
      isOpen
      byline={`Expense`}
      title={mode === 'view' ? 'View Item' : isNew ? 'New Item' : 'Edit Item'}
      onOpen={() => setDrawerLoaded(true)}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        return (
          <>
            <Statuses>
              {item?.invoice?.number && (
                <Tooltip
                  message={`Expense item is associated with ${
                    { open: 'published', draft: 'draft' }[item.invoice.statusId]
                  } invoice #${item.invoice.number}.`}>
                  <InvoiceStatus>
                    <Icon icon="file-invoice" color={colors.grey40} />
                    Invoiced
                  </InvoiceStatus>
                </Tooltip>
              )}

              {item?.isReimbursed && (
                <Tooltip message={'Expense Item has been reimbursed.'}>
                  <InvoiceStatus>
                    <Icon icon="repeat" color={colors.green} />
                    Reimbursed
                  </InvoiceStatus>
                </Tooltip>
              )}

              {isLocked && (
                <Tooltip message={expenseLockedReasons[item?.lockStatusId]}>
                  <Status statusColor={colors.black}>
                    <Icon icon={'lock'} color={colors.grey40} />
                    Locked
                  </Status>
                </Tooltip>
              )}

              {features.clientApprovals && item?.clientStatus?.id && (
                <Tooltip message={clientStatusMessage}>
                  <Status
                    statusColor={
                      {
                        rejected: colors.danger,
                        approved: colors.primary,
                      }[item.clientStatus.id]
                    }>
                    <Icon icon="circle" />
                    {item.clientStatus.name}
                  </Status>
                </Tooltip>
              )}

              {item?.status?.id && (
                <>
                  {item.status.id === 'pending_approval' && !!item.projectId ? (
                    <ExpenseApprovalPopover expenseItemId={item.id}>
                      <Status status={item.status.id} statusColor={statusColor}>
                        <Icon icon="circle" color={statusColor} />
                        {item.status.name}
                      </Status>
                    </ExpenseApprovalPopover>
                  ) : (
                    <Tooltip message={statusMessage}>
                      <Status status={item.status.id} statusColor={statusColor}>
                        <Icon icon="circle" color={statusColor} />
                        {item.status.name}
                      </Status>
                    </Tooltip>
                  )}
                </>
              )}
            </Statuses>

            {mode === 'edit' ? (
              <ExpenseItemForm
                member={impersonatedMember}
                overrideShowMember={overrideShowMember}
                item={item}
                drawerLoaded={drawerLoaded}
                closeDrawer={closeDrawer}
                expenseReport={expenseReport}
                rejectedHistoryItem={rejectedHistoryItem}
                formRef={formRef}
                onSaved={onSaved}
                onDeleted={onDeleted}
                onClose={onClose}></ExpenseItemForm>
            ) : (
              <ExpenseItemView
                onClose={onClose}
                closeDrawer={closeDrawer}
                expenseItem={item}
                rejectedHistoryItem={rejectedHistoryItem}></ExpenseItemView>
            )}
          </>
        );
      }}
    </Drawer>
  );
}

export { ExpenseItemDrawer };
export default ExpenseItemDrawer;
