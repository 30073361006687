import { Button, Buttons, CancelButton, ModalCard } from '~/components';
import React from 'react';

function BaseTransactionNumberDialog({ originalTransactionNumber, newTransactionNumber, onConfirm, onClose }) {
  return (
    <ModalCard title="Confirm Transaction Number" onClose={onClose}>
      <ModalCard.Body>
        You just changed this invoice number from {originalTransactionNumber} to {newTransactionNumber}. Would you like
        to reset your invoice numbering to increment up from {newTransactionNumber}?
      </ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button onClick={() => onConfirm(false)}>No</Button>
          <Button onClick={() => onConfirm(true)}>Yes</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default BaseTransactionNumberDialog;
