import { Button, Buttons, CancelButton, ModalCard } from '~/components';
import React from 'react';

function SendInvoiceDialog({ invoice, onResolve }) {
  return (
    <ModalCard title="Send Invoice" onClose={() => onResolve(false)}>
      <ModalCard.Body>
        <p>
          Are you sure you want to send invoice #{invoice.number} to <strong>{invoice.client.name}</strong>?
        </p>
      </ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => onResolve(false)}>Cancel</CancelButton>
          <Button onClick={() => onResolve(true)}>Send</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default SendInvoiceDialog;
