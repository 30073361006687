import { useActions } from '~/hooks';

const initialState = { isEditing: false, saved: false };

const handlers = {
  edit: () => ({ isEditing: true }),
  save: (value, args, actions) => {
    setTimeout(actions.done, 1000);
    return { isEditing: false, saved: true };
  },
  done: () => ({ isEditing: false, saved: false }),
};

function useEditableRow() {
  const [state, actions] = useActions(handlers, initialState);
  return [state, actions];
}

export default useEditableRow;
