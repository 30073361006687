import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '~/styles';
import ChangePassword from './ChangePassword';
import MyProfile from './MyProfile';
import Navigation from './Navigation';
import TwoFactorAuth from './TwoFactorAuth';
import TwoFactorAuthSetup from './TwoFactorAuthSetup';

const Layout = styled.div`
  display: flex;
  margin: 2.5rem 2.5rem 2.5rem 0;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export default function MyProfileArea() {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Navigation />
      <Switch>
        <Route path={`${path}`} exact>
          <MyProfile />
        </Route>
        <Route path={`${path}/change-password`} exact>
          <ChangePassword />
        </Route>
        <Route path={`${path}/two-factor-auth`} exact>
          <TwoFactorAuth />
        </Route>
        <Route path={`${path}/two-factor-auth/setup`} exact>
          <TwoFactorAuthSetup />
        </Route>
        <Redirect path={path} to={`${path}`} exact />
      </Switch>
    </Layout>
  );
}
