import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CompaniesArea from './companies/CompaniesArea';
import OpportunitiesArea from './opportunities/OpportunitiesArea';
import ContactsArea from './contacts/ContactsArea';

export default function PipelineArea() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/opportunities`}>
        <OpportunitiesArea sessionKey="pipeline_opportunities" />
      </Route>

      <Route path={`${path}/companies`}>
        <CompaniesArea sessionKey="pipeline_companies" />
      </Route>

      <Route path={[`${path}/contacts`]}>
        <ContactsArea sessionKey="pipeline_contacts" />
      </Route>

      <Redirect path="/" to={`${url}/opportunities`} />
    </Switch>
  );
}
