import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Percent, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

const Container = styled.div`
  font-size: 3rem;

  sup {
    font-size: 1.5rem;
  }

  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.2;
    `}
`;

function RealizationRateWidget({ start, end, memberId }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [query, setQuery] = useState({ isReady: false, data: null, error: null });

  const fetchData = useCallback(async () => {
    setQuery({ isReady: false });

    const { data } = await api.www
      .workspaces(workspace.id)
      .personalDashboard()
      .realizationRate({ start, end, memberId });

    setQuery({ isReady: true, data });
  }, [workspace.id, api, start, end, memberId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!query.isReady}>
      <Widget.Header>
        <Widget.Help message="Your billable hours divided by your total hours." />
        <Widget.Title>Realization Rate</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '4.1875rem' }}></Widget.Preview>
      <Widget.Content>
        <Container blurred={query.data === null}>
          {query.data != null ? (
            <Percent value={query.data.realizationRate} minimumFractionDigits={0} maximumFractionDigits={1} />
          ) : (
            'N/A'
          )}
        </Container>
      </Widget.Content>
    </Widget>
  );
}

export default RealizationRateWidget;
