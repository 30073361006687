import { useActions } from '~/hooks';

const initialState = { isSubmitting: false, status: null, message: null };
const handlers = {
  submit: (isSubmitting = true) => ({ isSubmitting, saved: false, status: null, message: null }),
  save: (saved = true, state, actions) => {
    setTimeout(() => {
      actions.done();
    }, 1000);
    return { isSubmitting: false, saved, status: null, message: null };
  },
  error: ({ status = 'form_error', message = 'An error has occurred.' } = {}) => ({
    isSubmitting: false,
    saved: false,
    status,
    message,
  }),
  done: () => ({ isSubmitting: false, saved: false, status: null, message: null }),
  resetStatus: () => ({ status: null, message: null }),
};
function useForm() {
  return useActions(handlers, initialState);
}

export default useForm;
