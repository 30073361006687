import _ from 'lodash';
import React from 'react';
import Tag from './Tag';

export default function TimeMemberExcludeTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;

  return (
    <Tag label="Members Excluded" {...props}>
      {_.chain([value])
        .flatten()
        .map((v) => v?.name)
        .join('; ')
        .value()}
    </Tag>
  );
}
