import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useMember, useSession, useWorkspace } from '~/contexts';
import settings from '~/settings.js';

export default function SentryContext() {
  const { admin } = useSession();
  const { member } = useMember();
  const { workspace } = useWorkspace();

  // Set the current member
  useEffect(() => {
    if (!settings.useSentry) {
      return;
    }
    if (admin) {
      Sentry.setUser({
        id: admin.id,
        username: admin.name,
        email: admin.email,
        ip_address: '{{auto}}',
      });
    } else if (member) {
      Sentry.setUser({
        id: member.id,
        username: member.name,
        email: member.email,
        ip_address: '{{auto}}',
      });
    } else {
      Sentry.setUser(null);
    }
  }, [admin, member]);

  // Set the current workspace/member
  useEffect(() => {
    if (!settings.useSentry) {
      return;
    }
    if (workspace) {
      Sentry.setContext('workspace', {
        id: workspace.id,
        key: workspace.key,
        name: workspace.name,
      });
    } else {
      Sentry.setContext('workspace', null);
    }
    if (workspace && workspace.member) {
      Sentry.setContext('member', {
        id: workspace.member.id,
        name: workspace.member.name,
        email: workspace.member.email,
      });
    } else {
      Sentry.setContext('member', null);
    }
  }, [workspace]);

  return null;
}
