import { ActionButton, Buttons, CancelButton, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';

export default function LoadFromXeroDialog({ creditNote, resolve }) {
  const { workspace } = useWorkspace();
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    try {
      const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).loadFromXero();
      await resolve();

      switch (data?.status) {
        case 'voided':
          toast.warning('This credit note was voided in Xero. The Ruddr credit note is now unlinked from Xero.');
          break;

        case 'success':
          toast.success(`Credit Note #${data.creditNote.number} has been reloaded from Xero.`);
          break;
      }
    } catch (error) {
      toast.error(error.message ?? 'An error has occurred. The credit note has not been reloaded from Xero.');
      form.done();
    }
  };

  return (
    <ModalCard title="Reload from Xero" onClose={() => resolve(false)}>
      <ModalCard.Body>Are you sure you want to reload this credit note from Xero?</ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
            Load
          </ActionButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
