import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post } from './agent';

const clientPayments =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'client-payments', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      findOpenInvoices: (query = {}) => get(url.concat('/open-invoices').concat(new QueryString(query).toString(true))),
      client: (clientId) => get(url.concat(`/client/${clientId}`)),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      saveToQuickBooks: () => post(url.concat('/save-to-quickbooks')),
      loadFromQuickBooks: () => post(url.concat('/load-from-quickbooks')),
      removeFromQuickBooks: () => post(url.concat('/remove-from-quickbooks')),
      saveToXero: () => post(url.concat('/save-to-xero')),
      removeFromXero: () => post(url.concat('/remove-from-xero')),
    };
  };

export default clientPayments;
