import { AutoComplete, Avatar, Dropdown, MemberContactPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AutoCompleteMulti from '../AutoCompleteMulti';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

export default function MemberFilter({ isActive, permission, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .members()
        .options({ q, isActive, permission, size: 1000 });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.isActive ? 'Active' : 'Inactive';

          a[key] = a[key] || { key, members: [] };
          a[key].members.push(v);
          return a;
        }, {}),
        'key',
      );

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', group, disabled: true });
        group.members.forEach((member) => {
          options.push({ type: 'member', member, disabled: false });
        });
      }

      return options;
    },
    [workspace.id, isActive, permission, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name: props.name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(props.value, { id: selectedValue.member.id })) {
      handleChange([...props.value, selectedValue.member]);
    }
  };

  const handleRemove = (item) => {
    handleChange(props.value.filter(({ id }) => id !== item.id));
  };

  const filterOptions = (options) => options.filter((o) => !props.value.some((v) => v.id === o.member?.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.member.name}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.id} {...props}>
          {option.name}
        </AutoCompleteMulti.Tag>
      )}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      placeholder="All"
      materialAlwaysVisible
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.group.key}>
                    <strong>{o.group.key}</strong>
                  </Group>
                );
              case 'member':
                return (
                  <AutoComplete.Option
                    grey={!o.member.isActive ? 'true' : undefined}
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.member.id}
                    value={o}
                    index={index++}>
                    <MemberContactPopover member={o.member} placement="left">
                      <Avatar value={o.member} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
                    </MemberContactPopover>
                    <span style={{ marginLeft: '.5rem' }}>{o.member.name}</span>
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoCompleteMulti>
  );
}
