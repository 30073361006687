import { Button, Buttons, CancelButton, Confirmation, Drawer, Tab, Tabs } from '~/components';
import { useConfirmation } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import TransactionsDrawerExpensesTab from './TransactionsDrawerExpensesTab';
import TransactionsDrawerTimeTab from './TransactionsDrawerTimeTab';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const ApplyButton = styled(Button)`
  width: 10.5rem;
`;

function TransactionsDrawer({ clientApproval, tab, onApply, onClose }) {
  const [tabIndex, setTabIndex] = useState({ time: 0, expenses: 1 }[tab] || 0);
  const [{ isSubmitting }, form] = useForm();
  const [timeEntries, setTimeEntries] = useState(() => clientApproval.timeEntries);
  const [expenseItems, setExpenseItems] = useState(() => clientApproval.expenseItems);
  const [start, setStart] = useState(clientApproval.periodStart);
  const [end, setEnd] = useState(clientApproval.periodEnd);
  const confirmation = useConfirmation();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  function handleClose() {
    onClose();
  }

  const handleStartChange = (start) => {
    setStart(start);
  };

  const handleEndChange = (end) => {
    setEnd(end);
  };

  const handleTimeChange = (value) => {
    setTimeEntries(value);
    setDirty(true);
  };

  const handleExpensesChange = (value) => {
    setExpenseItems(value);
    setDirty(true);
  };

  return (
    <Drawer
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      isOpen
      title="Select Items"
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleApply() {
          const confirm = await confirmation.prompt((resolve) => (
            <Confirmation resolve={resolve}>
              <p style={{ marginBottom: '1rem' }}>This will stop any running timers for the selected time entries.</p>
              <p>Are you sure you want to apply these changes?</p>
            </Confirmation>
          ));
          if (!confirm) return;

          form.submit();

          await onApply({
            timeEntries,
            expenseItems,
          });

          closeDrawer();
        }

        return (
          <>
            <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab>Time</Tab>
              <Tab>Expenses</Tab>
            </Tabs>
            <Content>
              {
                [
                  <TransactionsDrawerTimeTab
                    key="time"
                    clientApproval={clientApproval}
                    timeEntries={timeEntries}
                    start={start}
                    end={end}
                    onStartChange={handleStartChange}
                    onEndChange={handleEndChange}
                    onChange={handleTimeChange}
                  />,

                  <TransactionsDrawerExpensesTab
                    key="expenses"
                    clientApproval={clientApproval}
                    expenses={expenseItems}
                    start={start}
                    end={end}
                    onStartChange={handleStartChange}
                    onEndChange={handleEndChange}
                    onChange={handleExpensesChange}
                  />,
                ][tabIndex]
              }
            </Content>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ApplyButton onClick={handleApply} isLoading={isSubmitting}>
                  Apply
                </ApplyButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

export default TransactionsDrawer;
