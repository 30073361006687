import _ from 'lodash';

import React from 'react';
import Tag from './Tag';
import creditNoteStatuses from '~/lookups/credit-note-statuses';

export default function CreditNoteStatusTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Credit Note Status" {...props}>
      {[value]
        .flat()
        .map((v) => v?.name ?? creditNoteStatuses[v].name)
        .join('; ')}
    </Tag>
  );
}
