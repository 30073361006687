import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';

function DeleteButton(props) {
  return <Button {...props} isOutline />;
}

export default styled(DeleteButton)`
  color: ${colors.danger};
  border-color: ${colors.danger};

  &:hover:not(:disabled) {
    color: ${colors.white};
    background-color: ${colors.danger};
    border-color: ${colors.danger};
  }

  &:disabled {
    border-color: ${colors.grey25};
    color: ${colors.grey40};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      border-color: ${colors.grey25};
      color: ${colors.grey40};
    `}
`;
