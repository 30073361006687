const authProviders = {
  ruddr: { id: 'ruddr', name: 'Ruddr' },
  google: {
    id: 'google',
    name: 'Google Workspace',
    description: 'Single sign-on using a Google Workspace account.',
    method: 'saml',
  },
  microsoft: {
    id: 'microsoft',
    name: 'Microsoft Entra ID',
    description: 'Single sign-on using a Microsoft Entra ID account.',
    method: 'saml',
  },
  okta: {
    id: 'okta',
    name: 'Okta',
    description: 'Single sign-on using an Okta account.',
    method: 'saml',
  },
  other: {
    id: 'other',
    name: 'Other',
    description: 'Single sign-on using an account from another provider.',
    method: 'saml',
  },
};

export default authProviders;
