import { Button, Icon } from '~/components';
import { useDocumentTitle, useFeatures } from '~/hooks';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PageTitle = styled.h2`
  font-size: 1.625rem;
  font-weight: ${weights.light};
  line-height: 1.2;
  margin-bottom: 1rem;
`;

const AccordionButton = styled(Button)`
  font-size: 1rem;
  color: ${colors.grey55};
  opacity: 0.5;
  height: 100%;
  margin-left: 0.25rem;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

const NavBox = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  color: ${colors.black};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.black};
    border-color: transparent;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  }
`;

const Section = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
`;

const SectionHeader = styled.div`
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  border-top: solid 1px ${colors.grey10};

  h2 {
    cursor: pointer;

    color: ${colors.grey40};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;

    &:hover {
      color: ${colors.grey55};
    }
  }
  small {
    font-size: 0.75rem;
    font-style: italic;
    color: ${colors.grey75};
    font-weight: ${weights.normal};
    text-transform: none;
  }
`;

const Title = styled.div`
  font-weight: ${weights.bold};
`;

const Description = styled.div`
  padding-top: 0.25rem;
  font-size: 0.875rem;
`;

function CustomDataSection({ title, subtitle, expanded, children, onToggle }) {
  return (
    <>
      <SectionHeader>
        <h2 onClick={onToggle}>
          {title}
          <Icon style={{ marginLeft: '0.25rem' }} icon={expanded ? 'angle-down' : 'angle-up'} />
        </h2>

        {!expanded && (
          <p>
            <small>{subtitle}</small>
          </p>
        )}
      </SectionHeader>

      {expanded && children}
    </>
  );
}

export default function CustomDataListPage() {
  useDocumentTitle('Custom Data');

  const { url } = useRouteMatch();
  const features = useFeatures();

  const [sections, setSections] = useState({
    billing: true,
    clientsAndProjects: true,
    general: true,
    members: true,
    pipeline: true,
  });

  const all = Object.keys(sections).every((section) => sections[section]);

  const toggleSection = (section) => {
    setSections({ ...sections, [section]: !sections[section] });
  };

  const toggleAllSections = () => {
    setSections({
      billing: !all,
      clientsAndProjects: !all,
      general: !all,
      members: !all,
      pipeline: !all,
    });
  };

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          Custom Data
          <AccordionButton isAnchor onClick={toggleAllSections}>
            <Icon
              style={{ marginTop: '0.25rem', marginLeft: '0.5rem' }}
              icon={all ? 'angle-double-up' : 'angle-double-down'}
            />
          </AccordionButton>
        </PageTitle>
      </PageHeader>

      <CustomDataSection
        title="Billing"
        subtitle={<>Invoice Items, Payment Methods and Tax Rates.</>}
        expanded={sections.billing}
        onToggle={() => toggleSection('billing')}>
        <Section>
          <NavBox to={url.concat('/invoice-items')}>
            <Title>Invoice Items</Title>
            <Description>
              Every line item on a Ruddr invoice must have an invoice item. The invoice item is the category of the
              charge to the customer, such as "Service" or "Expense".
            </Description>
          </NavBox>

          <NavBox to={url.concat('/payment-methods')}>
            <Title>Payment Methods</Title>
            <Description>Every payment in Ruddr can be assigned a payment method.</Description>
          </NavBox>

          <NavBox to={url.concat('/tax-rates')}>
            <Title>Tax Rates</Title>
            <Description>
              Tax rates are used to associate a label with a specific tax rate percentage. These tax rates can then be
              used to apply tax to line items on Ruddr invoices.
            </Description>
          </NavBox>
        </Section>
      </CustomDataSection>

      <CustomDataSection
        title="Clients and Projects"
        subtitle={
          <>
            Client Tags, Industries, Project Tags, Project Types, Task Tags, Task Templates, and Workspace Roles and
            Rates.
          </>
        }
        expanded={sections.clientsAndProjects}
        onToggle={() => toggleSection('clientsAndProjects')}>
        <Section>
          <NavBox to={url.concat('/client-tags')}>
            <Title>Client Tags</Title>
            <Description>
              Client tags can be assigned to each client in Ruddr. This allows you to define custom client attributes.
              You can then filter reports using Client tags.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/industries')}>
            <Title>Industries</Title>
            <Description>
              Each workspace client can be assigned an industry. You can also filter reports by industry.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/project-tags')}>
            <Title>Project Tags</Title>
            <Description>
              Project tags can be assigned to each project in Ruddr. This allows you to define custom project
              attributes. You can then filter reports using Project tags.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/project-types')}>
            <Title>Project Types</Title>
            <Description>
              Every project in Ruddr can be assigned a project type. Reports can then be filtered by project type to
              analyze performance by type.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/task-tags')}>
            <Title>Task Tags</Title>
            <Description>
              Task tags can be assigned to each task within a project. This allows you to define custom task attributes.
              You can then filter reports using Task tags.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/task-templates')}>
            <Title>Task Templates</Title>
            <Description>Task Templates allow standard tasks to be easily added to projects.</Description>
          </NavBox>

          {features.workspaceRoles && (
            <NavBox to={url.concat('/workspace-roles-and-rates')}>
              <Title>Workspace Roles and Rates</Title>
              <Description>
                Workspace Roles and Rates allow you to create a company-wide rate card. These roles and rates can then
                be added or linked to client-specific rate cards.
              </Description>
            </NavBox>
          )}
        </Section>
      </CustomDataSection>

      <CustomDataSection
        title="General"
        subtitle={
          <>
            Disciplines, Holidays, Holiday Schedules, Locations, Practices, Resource Placeholders, and Time Off Types.
          </>
        }
        expanded={sections.general}
        onToggle={() => toggleSection('general')}>
        <Section>
          {features.disciplines && (
            <NavBox to={url.concat('/disciplines')} data-testid="discipline_box">
              <Title>Disciplines</Title>
              <Description>
                A discipline is a grouping of similar job functions. For example, various project managers within an
                organization could be assigned to a single "Project Management" discipline. Reports can then be filtered
                by discipline.
              </Description>
            </NavBox>
          )}

          <NavBox to={url.concat('/expense-categories')}>
            <Title>Expense Categories</Title>
            <Description>
              Each item on an expense report must be assigned an expense category. Also, you can build an expense budget
              for a project using expense categories.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/holidays')}>
            <Title>Holidays</Title>
            <Description>
              Ruddr has several built-in standard holidays that can be added to your holiday schedules. If your company
              observes a holiday that isn't built-in, you can create a custom holiday.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/holiday-schedules')}>
            <Title>Holiday Schedules</Title>
            <Description>
              You can create multiple holiday schedules for your workspace and apply a holiday schedule to each member.
              This allows you to have a different set of holidays for each country where you have personnel.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/locations')}>
            <Title>Locations</Title>
            <Description>
              Each workspace client and member can be assigned a location. You can also filter reports by location.
            </Description>
          </NavBox>

          {features.practices && (
            <NavBox to={url.concat('/practices')}>
              <Title>Practices</Title>
              <Description>
                Larger professional services companies often organize service offerings and personnel into practices.
                You can assign a practice to each member, client, and project in Ruddr. Reports can then be filtered by
                practice.
              </Description>
            </NavBox>
          )}

          {features.allocations && (
            <NavBox to={url.concat('/resource-placeholders')}>
              <Title>Resource Placeholders</Title>
              <Description>
                Within the Resources area of Ruddr, you can allocate members or resource placeholders to projects.
                Resource placeholders should be used when you aren't certain which team member will ultimately be
                assigned to the project.
              </Description>
            </NavBox>
          )}

          <NavBox to={url.concat('/time-off-types')}>
            <Title>Time Off Types</Title>
            <Description>
              Workspace members can track both project time and time off. Each workspace can have custom time off types,
              such as vacation or sick leave.
            </Description>
          </NavBox>
        </Section>
      </CustomDataSection>

      <CustomDataSection
        title="Members"
        subtitle={<>Job Titles, Skills, and Member Tags.</>}
        expanded={sections.members}
        onToggle={() => toggleSection('members')}>
        <Section>
          <NavBox to={url.concat('/job-titles')}>
            <Title>Job Titles</Title>
            <Description>
              Each workspace member can be assigned a job title. You can also filter reports by job title.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/skills')}>
            <Title>Skills</Title>
            <Description>
              Skills can be assigned to members and placeholders. You can also filter reports by skills.
            </Description>
          </NavBox>

          <NavBox to={url.concat('/member-tags')}>
            <Title>Member Tags</Title>
            <Description>
              Member tags can be assigned to each member in Ruddr. This allows you to define custom member attributes.
              You can then filter reports using Member tags.
            </Description>
          </NavBox>
        </Section>
      </CustomDataSection>

      {features.pipeline && (
        <CustomDataSection
          title="Pipeline"
          subtitle={
            <>
              Company Spend Tiers, Company Types, Opportunity Lead Sources, Opportunity Stages, Opportunity Types, and
              Pipeline Activity Types.
            </>
          }
          expanded={sections.pipeline}
          onToggle={() => toggleSection('pipeline')}>
          <Section>
            <NavBox to={url.concat('/company-spend-tiers')}>
              <Title>Company Spend Tiers</Title>
              <Description>
                Company spend tiers are used to categorize companies based on their annual spend.
              </Description>
            </NavBox>

            <NavBox to={url.concat('/company-types')}>
              <Title>Company Types</Title>
              <Description>Company types are used to categorize Companies within the Pipeline.</Description>
            </NavBox>

            <NavBox to={url.concat('/opportunity-lead-sources')}>
              <Title>Opportunity Lead Sources</Title>
              <Description>
                Opportunity Lead Sources are used to track how you found out about an opportunity.
              </Description>
            </NavBox>

            <NavBox to={url.concat('/opportunity-stages')}>
              <Title>Opportunity Stages</Title>
              <Description>Opportunity Stages are used to track the progress of opportunities.</Description>
            </NavBox>

            <NavBox to={url.concat('/opportunity-types')}>
              <Title>Opportunity Types</Title>
              <Description>Opportunity Types are used to categorize Opportunities.</Description>
            </NavBox>

            <NavBox to={url.concat('/pipeline-activity-types')}>
              <Title>Pipeline Activity Types</Title>
              <Description>
                Pipeline Activity Types are used to track the types of activities that occur during the sales process.
              </Description>
            </NavBox>
          </Section>
        </CustomDataSection>
      )}
    </Page>
  );
}
