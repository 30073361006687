import {
  ApprovalStatusFilter,
  BackLink,
  Checkbox,
  ClientApprovalStatusFilter,
  DateTime,
  HelpTooltip,
  Hours,
  Icon,
  Inline,
  Level,
  Percent,
  PeriodFilter,
  SingleSelect,
  Stack,
  Table,
  Widget,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import {
  useAuth,
  useDocumentTitle,
  useFeatures,
  usePercentFormat,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import _ from 'lodash';
import employmentTypes from '~/lookups/employment-types';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import intervalsByScope from '~/utils/intervalsByScope';

const columns = {
  capacity: { default: 'capacity', time_off_adjusted: 'capacityTimeOffAdjusted' },
  billableUtilization: { default: 'billableUtilization', time_off_adjusted: 'billableUtilizationTimeOffAdjusted' },
  clientUtilization: { default: 'clientUtilization', time_off_adjusted: 'clientUtilizationTimeOffAdjusted' },
  productiveUtilization: {
    default: 'productiveUtilization',
    time_off_adjusted: 'productiveUtilizationTimeOffAdjusted',
  },
  totalUtilization: { default: 'totalUtilization', time_off_adjusted: 'totalUtilizationTimeOffAdjusted' },
};

function UtilizationByTimeUnit() {
  useDocumentTitle('Utilization by Time Unit');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();

  const memberFilters = useMemberFilters(() => ({
    employmentTypes: { default: [employmentTypes.employee], ...searchParamsConfig.employmentTypes },
    memberBillableType: reportsSearchParamsConfig.utilization.memberBillableType,
  }));

  const [params, setParams] = useState({
    period: null,
    unit: '',
    approvalStatuses: [],
    clientApprovalStatuses: [],
    utilizationMode: 'default',
    includeMembersWithoutCapacity: false,
    sort: new QuerySort('start', 'asc'),
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervalOptions.this_year,
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'week':
                  return intervalOptions.past_12_weeks;

                case 'month':
                  return intervalOptions.this_year;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['week', 'month'] },
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        utilizationMode: {
          valid: ['default', 'time_off_adjusted'],
          default: workspace.defaultAdjustForTimeOff ? 'time_off_adjusted' : 'default',
        },
        includeMembersWithoutCapacity: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('start', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, memberFilters, workspace.defaultAdjustForTimeOff],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      includeMembersWithoutCapacity: params.includeMembersWithoutCapacity ? 'true' : undefined,
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().utilizationByTimeUnit(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleUtilizationModeChange = (e) => {
    const utilizationMode = { true: 'time_off_adjusted', false: 'default' }[e.target.checked];

    let sort = params.sort;

    const key = _.findKey(columns, (v) => v[params.utilizationMode] === params.sort.column);

    if (key) {
      const column = columns[key][utilizationMode];
      sort = new QuerySort(column, params.sort.direction);
      setQuery((state) => ({ ...state, status: 'sorting' }));
    }

    setParams({ ...params, sort, utilizationMode });
    searchParams.set({ utilizationMode, sort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .utilizationByTimeUnit(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/utilization`} />

        <Stack>
          <h1>Utilization by Time Unit</h1>

          <Tags>
            <PeriodNavTag
              value={params.period}
              intervals={intervalsByScope[params.unit]}
              onChange={(period) => handleApplyFilters({ period })}
            />

            <Tag label="Unit" value={{ month: 'Month', week: 'Week' }[params.unit]} />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            {params.includeMembersWithoutCapacity && <Tag>Include members without capacity</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data
                  query={query}
                  params={params}
                  onSort={handleSort}
                  onUtilizationModeChange={handleUtilizationModeChange}
                />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onUtilizationModeChange }) {
  const report = query.data;
  const records = _.orderBy(report.records, 'start');
  const totals = {
    default: {
      capacity: report.totals.capacity,
      billableUtilization: report.totals.billableUtilization,
      clientUtilization: report.totals.clientUtilization,
      productiveUtilization: report.totals.productiveUtilization,
      totalUtilization: report.totals.totalUtilization,
    },

    time_off_adjusted: {
      capacity: report.totals.capacityTimeOffAdjusted,
      billableUtilization: report.totals.billableUtilizationTimeOffAdjusted,
      clientUtilization: report.totals.clientUtilizationTimeOffAdjusted,
      productiveUtilization: report.totals.productiveUtilizationTimeOffAdjusted,
      totalUtilization: report.totals.totalUtilizationTimeOffAdjusted,
    },
  }[params.utilizationMode];

  const percentFormat = {
    tooltip: usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
    ticks: usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  };

  return (
    <>
      <Report.Summary>
        <Widget style={{ height: '20rem' }}>
          <Line
            data={{
              labels: records.map(
                (record) =>
                  ({
                    week: moment(record.start).format(dateFormats.compactDate),
                    month: moment(record.start).format(dateFormats.monthYear),
                  })[params.unit],
              ),
              datasets: [
                {
                  label: 'Billable Utilization',
                  fill: false,
                  backgroundColor: colors.success,
                  borderColor: colors.success,
                  data: records.map((record) =>
                    params.utilizationMode === 'default'
                      ? Math.round(record.billableUtilization * 10000) / 100
                      : Math.round(record.billableUtilizationTimeOffAdjusted * 10000) / 100,
                  ),
                  tension: 0.4,
                },
                {
                  label: 'Client Utilization',
                  fill: false,
                  backgroundColor: colors.warning,
                  borderColor: colors.warning,
                  data: records.map((record) =>
                    params.utilizationMode === 'default'
                      ? Math.round(record.clientUtilization * 10000) / 100
                      : Math.round(record.clientUtilizationTimeOffAdjusted * 10000) / 100,
                  ),
                  tension: 0.4,
                },
                {
                  label: 'Productive Utilization',
                  fill: false,
                  backgroundColor: colors.danger,
                  borderColor: colors.danger,
                  data: records.map((record) =>
                    params.utilizationMode === 'default'
                      ? Math.round(record.productiveUtilization * 10000) / 100
                      : Math.round(record.productiveUtilizationTimeOffAdjusted * 10000) / 100,
                  ),
                  tension: 0.4,
                },
                {
                  label: 'Total Utilization',
                  fill: false,
                  backgroundColor: colors.primary50,
                  borderColor: colors.primary50,
                  data: records.map((record) =>
                    params.utilizationMode === 'default'
                      ? Math.round(record.totalUtilization * 10000) / 100
                      : Math.round(record.totalUtilizationTimeOffAdjusted * 10000) / 100,
                  ),
                  tension: 0.4,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,

              plugins: {
                legend: {
                  onClick: null,
                  labels: {
                    pointStyleWidth: 10,
                    boxHeight: 7,
                    usePointStyle: true,
                  },
                },

                tooltip: {
                  callbacks: {
                    label: (tooltip) => percentFormat.tooltip.format(tooltip.parsed.y / 100),
                  },
                },
              },

              scales: {
                x: {
                  grid: { color: colors.grey10, drawBorder: false },
                  ticks: {
                    color: colors.grey40,
                    font: {
                      size: 12,
                      weight: 'bold',
                    },
                  },
                },

                y: {
                  grid: { color: colors.grey10, drawBorder: false },
                  min: 0,
                  suggestedMax: 100,
                  beginAtZero: true,
                  ticks: {
                    stepSize: 20,
                    callback: (value) => percentFormat.ticks.format(value / 100),
                  },
                },
              },
            }}
          />
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Level style={{ marginBottom: '1rem' }}>
          <Level.Item>
            <Inline>
              <Checkbox
                label="Adjust for Time Off"
                checked={params.utilizationMode === 'time_off_adjusted'}
                onChange={onUtilizationModeChange}
              />
              <HelpTooltip
                style={{ marginLeft: '.5rem' }}
                message="Reduces the capacity by the number of Time Off hours taken during the period."
              />
            </Inline>
          </Level.Item>
          <Level.Item>
            <TotalResults style={{ display: 'flex', alignItems: 'center' }}>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total
                label={{ month: 'Month', week: 'Week' }[params.unit]}
                style={{ marginBottom: 0 }}
                value={report.records.length}
              />
              {report.partialResults && <PartialResultsTooltip style={{ marginBottom: 0 }} />}
            </TotalResults>
          </Level.Item>
        </Level>
        <Table>
          <Table.Header sticky>
            <Table.Column name="start" onSort={onSort} sort={params.sort}>
              {{ month: 'Month', week: 'Week' }[params.unit]}
            </Table.Column>
            <Table.Column
              name={columns.capacity[params.utilizationMode]}
              width="8.5rem"
              align="right"
              onSort={onSort}
              sort={params.sort}>
              Capacity
            </Table.Column>
            <Table.Column name="billableHours" width="8.5rem" align="right" onSort={onSort} sort={params.sort}>
              Billable Hours
            </Table.Column>
            <Table.Column name="clientHours" width="8.5rem" align="right" onSort={onSort} sort={params.sort}>
              Client Hours
            </Table.Column>
            <Table.Column name="productiveHours" width="8.5rem" align="right" onSort={onSort} sort={params.sort}>
              Productive Hours
            </Table.Column>
            <Table.Column name="totalHours" width="8.5rem" align="right" onSort={onSort} sort={params.sort}>
              Total Hours
            </Table.Column>
            <Table.Column
              name={columns.billableUtilization[params.utilizationMode]}
              width="8.5rem"
              align="right"
              onSort={onSort}
              sort={params.sort}>
              Billable Utilization
            </Table.Column>
            <Table.Column
              name={columns.clientUtilization[params.utilizationMode]}
              width="8.5rem"
              align="right"
              onSort={onSort}
              sort={params.sort}>
              Client Utilization
            </Table.Column>
            <Table.Column
              name={columns.productiveUtilization[params.utilizationMode]}
              width="8.5rem"
              align="right"
              onSort={onSort}
              sort={params.sort}>
              Productive Utilization
            </Table.Column>
            <Table.Column
              name={columns.totalUtilization[params.utilizationMode]}
              width="8.5rem"
              align="right"
              onSort={onSort}
              sort={params.sort}>
              Total Utilization
            </Table.Column>
          </Table.Header>

          <TableBody fade={query.status === 'sorting'}>
            {report.records.map((period) => {
              const values = {
                default: {
                  capacity: period.capacity,
                  billableUtilization: period.billableUtilization,
                  clientUtilization: period.clientUtilization,
                  productiveUtilization: period.productiveUtilization,
                  totalUtilization: period.totalUtilization,
                },

                time_off_adjusted: {
                  capacity: period.capacityTimeOffAdjusted,
                  billableUtilization: period.billableUtilizationTimeOffAdjusted,
                  clientUtilization: period.clientUtilizationTimeOffAdjusted,
                  productiveUtilization: period.productiveUtilizationTimeOffAdjusted,
                  totalUtilization: period.totalUtilizationTimeOffAdjusted,
                },
              }[params.utilizationMode];

              return (
                <Table.Row key={period.start}>
                  <Table.Cell>
                    {
                      { week: <DateTime value={period.start} />, month: moment(period.start).format('MMMM, YYYY') }[
                        params.unit
                      ]
                    }
                  </Table.Cell>
                  <Table.Cell>
                    <Hours value={values.capacity} />
                  </Table.Cell>
                  {/* This does not click-through because the Time Detail report doesn't support
                        including a subset of members based on member-permissions only. */}
                  <Table.Cell>
                    <Hours value={period.billableHours} />
                  </Table.Cell>
                  <Table.Cell>
                    <Hours value={period.clientHours} />
                  </Table.Cell>
                  <Table.Cell>
                    <Hours value={period.productiveHours} />
                  </Table.Cell>
                  <Table.Cell>
                    <Hours value={period.totalHours} />
                  </Table.Cell>
                  <Table.Cell>
                    <Percent value={values.billableUtilization} />
                  </Table.Cell>
                  <Table.Cell>
                    <Percent value={values.clientUtilization} />
                  </Table.Cell>
                  <Table.Cell>
                    <Percent value={values.productiveUtilization} />
                  </Table.Cell>
                  <Table.Cell>
                    <Percent value={values.totalUtilization} />
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <Hours value={totals.capacity} />
              </Table.Cell>
              <Table.Cell>
                {/* This does not click-through because the Time Detail report doesn't support
                    including a subset of members based on member-permissions only. */}
                <Hours value={report.totals.billableHours} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.clientHours} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.productiveHours} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.totalHours} />
              </Table.Cell>
              <Table.Cell>
                <Percent value={totals.billableUtilization} />
              </Table.Cell>
              <Table.Cell>
                <Percent value={totals.clientUtilization} />
              </Table.Cell>
              <Table.Cell>
                <Percent value={totals.productiveUtilization} />
              </Table.Cell>
              <Table.Cell>
                <Percent value={totals.totalUtilization} />
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeMembersWithoutCapacity}
          label="Include members without capacity"
          onChange={({ target: { checked } }) => handleFilter({ includeMembersWithoutCapacity: checked })}
        />
      </Filter>

      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                week: intervalOptions.past_12_weeks,
                month: intervalOptions.this_year,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      {auth.reports.utilization && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <FiltersGroup label="Time Filters" filters={[filters.approvalStatuses, filters.clientApprovalStatuses]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}
      </FiltersGroup>
    </Report.Filters>
  );
}

export default UtilizationByTimeUnit;
