import _ from 'lodash';

function calculateInvoiceAmounts(invoice) {
  const transactions = invoice.lines.filter((line) => _.isNumber(line.amount));

  const linesSubtotal = _.round(_.sumBy(transactions, 'amount'), 2);
  const discountPercent = invoice.discountAmount / linesSubtotal;
  const discountRatio = 1 - discountPercent;

  const nonTaxableSubtotal = _.round(
    _(transactions)
      .filter((line) => !line.taxable)
      .sumBy((line) => line.amount * discountRatio),
    2,
  );

  const taxableSubtotal = _.round(
    _(transactions)
      .filter((line) => line.taxable)
      .sumBy((line) => line.amount * discountRatio),
    2,
  );

  const tax = _.round(
    _(transactions)
      .filter((line) => line.taxable)
      .sumBy((line) => line.amount * discountRatio * ((invoice.taxRate || 0) / 100)),
    2,
  );

  const total = _.round(linesSubtotal + tax - invoice.discountAmount, 2);

  return {
    nonTaxableSubtotal,
    taxableSubtotal,
    tax,
    total,
  };
}

export default calculateInvoiceAmounts;
