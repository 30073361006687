import moment from 'moment';

// Find the current period's end date based on the next period's start date.
// If the start date is not set, find the first available period.
function calculatePeriodEnd(periods, period, start = period.start) {
  const nextPeriod = periods
    // Prevent mutating the original array
    .slice()
    // Set the periods in reverse chronological order
    .reverse()
    .find((ap) => period.id !== ap.id && (!start || moment(start).isBefore(ap.start, 'day')));

  return nextPeriod ? moment(nextPeriod.start).subtract(1, 'day') : null;
}

export default calculatePeriodEnd;
