import React from 'react';
import styled from 'styled-components';
import { weights } from '~/styles';
import RoleAssignmentsTable from './RoleAssignmentsTable';
import RolesTable from './RolesTable';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    flex: 0;
  }

  > hr {
    margin-bottom: 1.75rem;
  }

  h4 {
    font-weight: ${weights.bold};
    margin-bottom: 1rem;
  }
`;

function RolesTab({ project, projectModel, onRolesChange, onRoleAssignmentsChange }) {
  return (
    <Container>
      <h4>Project Roles</h4>
      <RolesTable project={project} projectModel={projectModel} onChange={onRolesChange} />

      <hr />

      <h4>Role Assignments</h4>
      <RoleAssignmentsTable projectModel={projectModel} onChange={onRoleAssignmentsChange} />
    </Container>
  );
}
export default RolesTab;
