import _ from 'lodash';
import React from 'react';
import Tag from './Tag';

export default function SecurityRoleTag({ value, ...props }) {
  if (_.isEmpty(value)) return null;
  return (
    <Tag label="Security Role" {...props}>
      {[value]
        .flat()
        .map((v) => v.name)
        .join('; ')}
    </Tag>
  );
}
