import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Circle = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: ${colors.primary};
  font-size: 0.875rem;
  border: solid 1px ${colors.grey10};
  border-radius: 50%;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  color: ${colors.black};
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: ${colors.grey40};

    ${Circle} {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }
`;

const Input = styled.input`
  position: absolute;
  display: inline-block;
  outline: none;
  opacity: 0;
  user-select: none;
  cursor: pointer;
`;

const SelectedCircle = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${colors.primary};
  border-radius: 50%;
`;

const Text = styled.span`
  padding-top: 0.0625rem;
`;

const Radio = React.forwardRef(({ label, disabled, ...props }, ref) => {
  return (
    <Label className={classNames({ disabled })}>
      <Input {...props} ref={ref} type="radio" disabled={disabled} />
      <Circle>{props.checked && <SelectedCircle />}</Circle>
      <Text>{label}</Text>
    </Label>
  );
});

export default Radio;
export { Label, Circle };
