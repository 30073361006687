import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Field from './Field';
import Radio from './Radio';

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  margin: -0.375rem -0.75rem;

  > * {
    margin: 0.375rem 0.75rem;
  }
`;

function InitRadio(element, field, helpers, selected, groupProps) {
  if (element.type === Radio) {
    // initialize the radio button for group
    return React.cloneElement(element, {
      name: field.name,
      onBlur: () => {
        helpers.setTouched(true);
      },
      onChange: groupProps && groupProps.onChange ? groupProps.onChange : () => helpers.setValue(element.props.value),
      checked: element.props.checked ?? element.props.value === selected,
    });
  } else if (element.props) {
    // return element with recursive init on children
    return React.cloneElement(element, {
      children: React.Children.map(element?.props?.children, (child) => InitRadio(child, field, helpers, selected)),
    });
  } else {
    // simple element so just return it
    return element;
  }
}

function FieldRadioGroup({ direction = 'horizontal', children, ...props }) {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;
  const selected = field.value;

  return (
    <Field.Control error={error}>
      <Group direction={direction}>
        {React.Children.map(children, (child) => {
          return InitRadio(child, field, helpers, selected, props);
        })}
      </Group>
    </Field.Control>
  );
}

export default FieldRadioGroup;
