import { Button } from '~/components';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import theme from '../theme';

export const HeaderStyles = styled.header`
  /* Layout */
  display: flex;
  overflow-x: hidden;
  z-index: 1;
  position: relative;
`;

export const HeaderScrollPad = styled.div`
  /* Layout */
  flex: 0 0;
  position: sticky;
  right: 0;
  background: ${theme.line.soft};
  border-top-right-radius: 5px;
  z-index: 2;
  margin-left: auto;
`;

export const HeaderTimeAxis = styled.div`
  /* Layout */
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 4px solid ${theme.line.strong};
`;

export const HeaderSidebar = styled.div`
  /* Layout */
  flex-shrink: 0;

  /* Styles */
  background: ${theme.line.strong};
  margin-right: 0.25rem;
  box-shadow: 0.25rem 0rem 0rem ${theme.line.strong};
  border-bottom: 4px solid ${theme.line.strong};
`;

export const HeaderSidebarContent = styled.div`
  border-radius: 5px 0 0 5px;
  background: ${colors.white};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
`;

export const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 0.3125rem;
  border-color: ${colors.white};
  background: ${colors.grey10};
  padding: 0.3125rem;
`;

export const HeaderNavButton = styled(Button)`
  position: relative;
  flex: 1;
  flex-shrink: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  border-radius: 3px;

  &:first-child {
    margin-left: 0;
  }

  ${({ $isActive }) =>
    $isActive
      ? css`
          color: ${colors.white};
          background-color: ${colors.primary};
          font-weight: ${weights.bold};

          &:hover {
            background-color: ${colors.accent};
          }
        `
      : css`
          color: ${colors.grey75};
          background-color: ${colors.grey10};
          font-weight: ${weights.normal};

          &:hover {
            color: ${colors.grey55};
            background-color: ${colors.grey10};
          }
        `}

  &:disabled {
    color: ${colors.grey75};
    background-color: ${colors.grey10};
    font-weight: ${weights.normal};
  }
`;

export const HeaderColumn = styled.div`
  /* Layout */
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  /* Layout */
  flex: 1;
  display: flex;

  &:not(:first-child) {
    border-top: 1px solid ${theme.line.strong};
  }
`;

export const HeaderCell = styled.div`
  /* Layout */
  display: flex;
  justify-content: center;

  /* Styles */
  padding: 0.5rem;

  border-right: 1px solid ${theme.line.strong};

  &:last-child {
    border-right: 1px solid ${theme.line.strong};
  }

  &:hover {
    cursor: pointer;
    background: ${rgba(colors.grey5, 0.2)};
  }

  ${({ today }) =>
    today &&
    css`
      color: ${theme.today.color};
      font-weight: ${weights.bold};
      background: ${colors.primary5};
      box-shadow: inset 0 -4px 0 0 ${colors.primary};
    `}
`;

export const HeaderGroupCellContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  overflow: hidden;
`;

export const HeaderCellContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  height: 2rem;
`;
