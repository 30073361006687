import moment from 'moment';

function calculateDaysInPeriod(start, end) {
  let daysInPeriod = null;
  if (start) {
    daysInPeriod =
      moment
        .duration(
          moment(end || new Date())
            .endOf('day')
            .diff(moment(start).startOf('day'), 'days'),
          'days',
        )
        .asDays() + 1;
    if (isNaN(daysInPeriod) || daysInPeriod < 1) daysInPeriod = null;
  }
  return daysInPeriod;
}

export default calculateDaysInPeriod;
