import { BillableIcon, Currency, Field, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { weights } from '~/styles';
import { calculateRevenue, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ProjectDrawerContext } from '../../ProjectDrawer';
import calculateMonthlyBudget from './calculateMonthlyBudget';

function MonthlyBudgetRolesTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = (value) => {
    const roles = projectModel.roles.map((pr) =>
      pr.id === value.id
        ? emptyStringToNull({
            ...pr,
            estimatedMonthlyBillableHours: value.estimatedMonthlyBillableHours,
            estimatedMonthlyNonBillableHours: value.estimatedMonthlyNonBillableHours,
          })
        : pr,
    );

    setEditIndex(null);
    onChange(roles);
  };

  const budget = calculateMonthlyBudget(projectModel);

  const currency = projectModel.currency;

  return (
    <Table small>
      <Table.BoxHeader>
        <Table.Column>Project Role</Table.Column>
        <Table.Column align="right" width="7.5rem" isVisible={projectModel.isBillable}>
          Billable
        </Table.Column>
        <Table.Column align="right" width="7.5rem">
          Non-Billable
        </Table.Column>
        <Table.Column
          align="right"
          width="7.5rem"
          isVisible={projectModel.isBillable && projectModel.billingTypeId === 'tm'}>
          Rate
        </Table.Column>
        <Table.Column
          align="right"
          width="7.5rem"
          isVisible={projectModel.isBillable && projectModel.billingTypeId === 'tm'}>
          Revenue
        </Table.Column>
        <Table.BoxActionsColumn />
      </Table.BoxHeader>
      <Table.Body>
        {projectModel.roles.length > 0 && (
          <>
            {projectModel.roles.map((item, index) => (
              <MonthlyBudgetRoleRow
                key={item.id}
                projectModel={projectModel}
                projectRole={item}
                disableActions={editIndex !== null}
                isEditing={editIndex === index}
                currency={currency}
                onEdit={() => setEditIndex(index)}
                onCancel={() => setEditIndex(null)}
                onSaved={handleSave}
              />
            ))}

            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell />
              <Table.Cell>{budget.billableHours}</Table.Cell>
              <Table.Cell>{budget.nonBillableHours}</Table.Cell>
              <Table.Cell>
                <Currency value={budget.effectiveRate} currency={currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={budget.revenue} currency={currency} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
}

function MonthlyBudgetRoleRow({
  currency,
  projectModel,
  projectRole,
  disableActions,
  isEditing,
  onEdit,
  onCancel,
  onSaved,
}) {
  async function handleSubmit(values) {
    onSaved(values);
  }

  if (!isEditing)
    return (
      <MonthlyBudgetRoleRowDetails
        currency={currency}
        projectModel={projectModel}
        projectRole={projectRole}
        disableActions={disableActions}
        onEdit={onEdit}
      />
    );

  return (
    <MonthlyBudgetRoleRowForm
      currency={currency}
      projectModel={projectModel}
      projectRole={projectRole}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
}

function MonthlyBudgetRoleRowDetails({
  currency,
  projectModel,
  projectRole: { name, isActive, estimatedMonthlyBillableHours, estimatedMonthlyNonBillableHours, rate, isBillable },
  disableActions,
  onEdit,
}) {
  const isActuallyBillable = projectModel.isBillable && isBillable;
  const actualRate = isActuallyBillable ? rate : null;

  return (
    <Table.BoxRow isDisabled={!isActive}>
      <Table.Cell>
        <BillableIcon value={isActuallyBillable} />
        {name}
      </Table.Cell>
      <Table.Cell>{isBillable ? estimatedMonthlyBillableHours ?? '-' : '-'}</Table.Cell>
      <Table.Cell>{estimatedMonthlyNonBillableHours ?? '-'}</Table.Cell>
      <Table.Cell>
        <Currency value={actualRate} maximumFractionDigits={7} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={calculateRevenue(estimatedMonthlyBillableHours, actualRate)} currency={currency} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function MonthlyBudgetRoleRowForm({
  currency,
  projectModel,
  projectRole,
  projectRole: { id, name, rate, isBillable },
  onSubmit,
  onCancel,
}) {
  // Submit the row if the drawer's "Save" button is clicked
  const form = useRef();
  const { setForms } = useContext(ProjectDrawerContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'monthly_budget_role', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'monthly_budget_role'));
    };
  }, [setForms]);

  const initialValues = mergeValues(
    {
      id,
      estimatedMonthlyBillableHours: '',
      estimatedMonthlyNonBillableHours: '',
    },
    projectRole,
  );

  const isActuallyBillable = projectModel.isBillable && isBillable;
  const actualRate = isActuallyBillable ? rate : null;

  return (
    <Formik
      innerRef={form}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        estimatedMonthlyBillableHours: Yup.number().label('Billable Hours').min(0).max(9999.99).nullable(),
        estimatedMonthlyNonBillableHours: Yup.number().label('Non-Billable Hours').min(0).max(9999.99).nullable(),
      })}>
      {({ values: { estimatedMonthlyBillableHours }, submitForm }) => {
        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              <BillableIcon value={isActuallyBillable} />
              {name}
            </Table.Cell>
            <Table.Cell>
              {isActuallyBillable ? (
                <Field.Number
                  autoFocus={isActuallyBillable}
                  name="estimatedMonthlyBillableHours"
                  materialPlaceholder="Billable"
                  materialAlwaysVisible
                  min={0}
                  precision={2}
                />
              ) : (
                '-'
              )}
            </Table.Cell>
            <Table.Cell>
              <Field.Number
                autoFocus={!isActuallyBillable}
                name="estimatedMonthlyNonBillableHours"
                materialPlaceholder="Non-Billable"
                materialAlwaysVisible
                min={0}
                precision={2}
              />
            </Table.Cell>
            <Table.Cell>
              <Currency value={actualRate} maximumFractionDigits={7} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={calculateRevenue(estimatedMonthlyBillableHours, actualRate)} currency={currency} />
            </Table.Cell>

            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

export default MonthlyBudgetRolesTable;
