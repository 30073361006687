import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import qboIcon from '~/assets/public/qbo-icon.png';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { useIsMounted } from '~/hooks';
import { Buttons, CancelButton, DeleteButton, FormMessage, ModalCard } from '~/components';
import { Card } from '~/components/ModalCard';
import QBDSetupDrawer from './QBDSetupDrawer';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10.25rem;
  height: 9.375rem;
  margin: 1.25rem 0.625rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border: solid 0.125rem ${colors.white};
  border-radius: 0.3125rem;
  overflow: hidden;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Image = styled.img`
  width: 2.625rem;
  height: 2.625rem;
`;

const Name = styled.p`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  height: 2rem;
  background-color: ${colors.grey10};
`;

const ActionStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0;
  color: ${colors.black};
  font-size: 0.75rem;
  font-weight: ${weights.normal};
  background-color: ${colors.grey5};
  border-radius: 0;

  &:not(:first-child) {
    margin-left: 1px;
  }

  &:hover {
    color: ${colors.black};
    background-color: ${colors.grey10};
  }
`;

const DeleteDescription = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ActionAnchor = styled.a`
  ${ActionStyle}
`;

const DeleteModalCard = styled(ModalCard)`
  ${Card} {
    width: 47rem;
  }
`;

const ActionButton = styled.button`
  ${ActionStyle}

  position: relative;

  &:disabled {
    box-shadow: none;
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

function RemoveQBDIntegration({ onClose, onRemove }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isRemoving, setIsRemoving] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  async function handleRemove() {
    setIsRemoving(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).qbd.remove();

      if (typeof onRemove === 'function') {
        await onRemove();
      }
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }

  return (
    <DeleteModalCard title="Remove QuickBooks Desktop Integration" onClose={onClose}>
      <ModalCard.Body>
        <DeleteDescription>This will remove the QuickBooks Desktop Integration and its settings.</DeleteDescription>
        {errorMessage && <FormMessage.Error spaceTop>{errorMessage}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
            Cancel
          </CancelButton>
          <DeleteButton isLoading={isRemoving} onClick={handleRemove}>
            Remove
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </DeleteModalCard>
  );
}

export default function QBDIntegration({ integration, onChange }) {
  const confirmation = useConfirmation();
  const [setupIntegration, setSetupIntegration] = useState(false);
  const { notify } = useSubscription();

  const handleRemove = () => {
    confirmation.prompt((resolve) => (
      <RemoveQBDIntegration
        onClose={resolve}
        onRemove={() => {
          if (typeof onChange === 'function') {
            onChange();
          }
          notify(useSubscription.keys.integrations_changed);
          resolve(true);
        }}
      />
    ));
  };

  const handleFinish = () => {
    if (typeof onChange === 'function') {
      onChange();
    }
    notify(useSubscription.keys.integrations_changed);
  };

  return (
    <>
      <Item>
        <Description>
          <Image src={qboIcon} />
          <Name>QuickBooks Desktop</Name>
        </Description>
        {integration.isConnected ? (
          <Actions>
            <ActionButton onClick={() => setSetupIntegration(true)}>Settings</ActionButton>
            <ActionButton onClick={handleRemove}>Remove</ActionButton>
          </Actions>
        ) : (
          <Actions>
            <ActionAnchor
              href="https://help.ruddr.io/hc/en-us/articles/20526280182935-QuickBooks-Desktop-Support"
              target="_blank"
              rel="noopener noreferrer">
              Details
            </ActionAnchor>

            <ActionButton onClick={() => setSetupIntegration(true)}>Install</ActionButton>
          </Actions>
        )}
      </Item>
      {setupIntegration && (
        <QBDSetupDrawer onClose={() => setSetupIntegration(false)} onSaved={handleFinish} integration={integration} />
      )}
    </>
  );
}
