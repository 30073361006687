import { Button, Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const TagStyles = styled.span`
  border-radius: 0.3125rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 0.25rem;

  strong {
    white-space: nowrap;
    padding-right: 0.25rem;
  }

  > .button {
    margin-left: 0.5rem;
    color: ${colors.danger};

    &:hover {
      color: ${colors.danger50};
    }
  }
`;

function Tag({ label, value, children, onRemove }) {
  if (!value && React.Children.count(children) === 0) return null;

  return (
    <TagStyles>
      {label && <strong>{label}:</strong>} {value}
      {children}
      {onRemove && (
        <Button isAnchor onClick={onRemove}>
          <Icon icon="times" />
        </Button>
      )}
    </TagStyles>
  );
}

export default Tag;
