import { BillableIcon, Button, Currency, Field, Hours, Icon, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ProjectTaskFormContext } from './ProjectTaskForm';

function ProjectTaskMembersTable({ project, value, task, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSubmit = (item) => {
    const valueItem = value.find((v) => v.projectMemberId === item.projectMemberId);
    if (valueItem) {
      onChange(value.map((v) => (v.projectMemberId === item.projectMemberId ? item : v)));
    } else {
      onChange([...value, item]);
    }

    setEditIndex(null);
  };

  const handleDelete = (index) => {
    onChange(value.filter((e, i) => i !== index));
  };

  const totalHours = _.sumBy(value, 'hours');

  const totalFees = task.isBillable
    ? _.sumBy(value, ({ hours, projectMember }) => {
        return _.isNumber(hours) && _.isNumber(projectMember?.actualRate) ? hours * projectMember?.actualRate : null;
      })
    : 0;

  const currency = project.currency;

  return (
    <Table data-testid="members_edit">
      <Table.BoxHeader>
        <Table.Column>Project Member</Table.Column>
        <Table.Column align="right" width="6.5rem">
          Hours
        </Table.Column>
        <Table.Column align="right" width="8rem" isVisible={project.isBillable && project.billingTypeId === 'tm'}>
          Amount
        </Table.Column>
        <Table.BoxActionsColumn />
      </Table.BoxHeader>
      <Table.Body>
        {value.map((item, index) =>
          index === editIndex ? (
            <MemberRowForm
              key={item.projectMember.id}
              currency={currency}
              project={project}
              task={task}
              taskMember={item}
              taskMembers={value}
              onSubmit={handleSubmit}
              onCancel={() => setEditIndex(null)}
            />
          ) : (
            <MemberRowDetails
              key={item.projectMember.id}
              currency={currency}
              taskMember={item}
              task={task}
              disableActions={editIndex !== null}
              onEdit={() => setEditIndex(index)}
              onDelete={() => handleDelete(index)}
            />
          ),
        )}

        {editIndex === -1 ? (
          <MemberRowForm
            currency={currency}
            project={project}
            task={task}
            taskMembers={value}
            onSubmit={handleSubmit}
            onCancel={() => setEditIndex(null)}
          />
        ) : (
          <Table.Row>
            <Table.Cell>
              <Button isAnchor isStrong disabled={editIndex !== null} onClick={() => setEditIndex(-1)}>
                <Icon icon="plus" size="xs" spaceRight />
                Quick Add
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Hours value={totalHours} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={totalFees} currency={currency} />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function MemberRowDetails({
  currency,
  taskMember: {
    hours,
    projectMember,
    projectMember: { member },
  },
  task,
  disableActions,
  onEdit,
  onDelete,
}) {
  const fees =
    task.isBillable && _.isNumber(hours) && _.isNumber(projectMember?.actualRate)
      ? hours * projectMember?.actualRate
      : null;

  return (
    <Table.BoxRow>
      <Table.Cell>
        <BillableIcon value={projectMember.isActuallyBillable} /> {member.name}
      </Table.Cell>
      <Table.Cell align="right">
        <Hours value={hours} />
      </Table.Cell>
      <Table.Cell align="right">
        <Currency value={fees} currency={currency} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Delete disabled={disableActions} onClick={onDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function MemberRowForm({ currency, project, taskMember, taskMembers, task, onSubmit, onCancel }) {
  // Submit the row if the drawer's "Save" button is clicked
  const form = useRef();
  const { setForms } = useContext(ProjectTaskFormContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'member', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'member'));
    };
  }, [setForms]);

  const initialValues = mergeValues(
    {
      projectMember: null,
      hours: '',
    },
    taskMember,
  );

  const excludedMembers = taskMembers.map(({ projectMember }) => projectMember.id);

  const handleSubmit = (values) => {
    const { hours, projectMember } = emptyStringToNull(values);
    onSubmit({ hours, projectMember, projectMemberId: projectMember.id });
  };

  return (
    <Formik
      innerRef={form}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        projectMember: Yup.mixed().label('Project Member').required(),
        hours: Yup.number().min(0).label('Hours').nullable(),
      })}>
      {({ values: { hours, projectMember }, submitForm }) => {
        const fees =
          task.isBillable && _.isNumber(hours) && _.isNumber(projectMember?.actualRate)
            ? hours * projectMember?.actualRate
            : null;

        return (
          <Table.BoxRow focused onEnter={submitForm}>
            {taskMember ? (
              <Table.Cell>{taskMember.projectMember.member.name}</Table.Cell>
            ) : (
              <Table.Cell autoFocus={!taskMember}>
                <Field.ProjectMemberSelect
                  name="projectMember"
                  placeholder="Member"
                  project={project}
                  exclude={excludedMembers}
                />
              </Table.Cell>
            )}
            <Table.Cell>
              <Field.Number
                autoFocus={!!taskMember}
                name="hours"
                placeholder="Hrs"
                materialPlaceholder="Hours"
                min={0}
                precision={2}
              />
            </Table.Cell>
            <Table.Cell>
              <Currency value={fees} currency={currency} />
            </Table.Cell>

            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

export default ProjectTaskMembersTable;
