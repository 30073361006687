import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import ClientApprovalsList from './ClientApprovalsList';

export default function ClientApprovalsTab({ project }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  return (
    <Switch>
      <Route exact path={path.concat('/new')}>
        <ClientApprovalsList
          project={project}
          showCreateForm
          onCreateFormClose={() => history.push(url)}
          onCreated={(clientApproval) => history.push(`${url}/${clientApproval.id}`)}
        />
      </Route>

      <Route path={path}>
        <ClientApprovalsList project={project} />
      </Route>
    </Switch>
  );
}
