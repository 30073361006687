import { BackLink, Currency, DateTime, Icon, Level, Stack, Table, Widget } from '~/components';
import { Filter } from '~/components/filters';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import {
  useDocumentTitle,
  useFeatures,
  useIsMounted,
  useNumberFormat,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { default as React, useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PageLoader from '~/routes/public/pages/PageLoader';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString, dateFormats, intervalOptions, mimeTypes } from '~/utils';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import PeriodNavTag from '../components/PeriodNavTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import { Link as DefaultLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

const Link = styled(DefaultLink)`
  color: ${colors.black};
`;

function OpportunitiesByMonth() {
  useDocumentTitle('Opportunities by Month');
  const { workspace } = useWorkspace();
  const api = useApi();
  const isMounted = useIsMounted();

  const [query, setQuery] = useState({ report: null, status: 'loading', loading: { table: false } });
  const [params, setParams] = useState({
    period: null,
    sort: new QuerySort('start', 'desc'),
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.this_year },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('start', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().opportunitiesByMonth(urlSearchParams);
    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .opportunitiesByMonth(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/pipeline`} />
        <Stack>
          <h1>Opportunities by Month</h1>
          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const { workspace } = useWorkspace();
  const report = query.data;
  const features = useFeatures();
  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  const getOpportunitiesRoute = (oppData) =>
    `/app/${workspace.key}/reports/pipeline/opportunity-detail?${new QueryString({ ...oppData, opportunityStageStatusId: 'all' })}`;

  return (
    <>
      <Report.Summary>
        <TimeSeriesGraph report={report} />
      </Report.Summary>
      <Report.Table style={{ marginTop: '1rem' }}>
        <Level>
          {features.multicurrency && (
            <Level.Item>
              <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
            </Level.Item>
          )}

          <Level.Item right>
            <TotalResults>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total label="Month" value={report.records.length} />
            </TotalResults>
          </Level.Item>
        </Level>
        <Table>
          <Table.Header sticky>
            <Table.Column name="start" onSort={onSort} sort={params.sort}>
              Month
            </Table.Column>
            <Table.Column align="right">Created</Table.Column>
            <Table.Column align="right">Closing</Table.Column>
            <Table.Column align="right">Weighted Created</Table.Column>
            <Table.Column align="right">Gross Created</Table.Column>
            <Table.Column align="right">Weighted Closing</Table.Column>
            <Table.Column align="right">Gross Closing</Table.Column>
          </Table.Header>

          <TableBody>
            {report.records.map((month) => (
              <Table.Row key={month.start}>
                <Table.Cell>
                  <DateTime value={month.start} format={'MMMM, YYYY'} />
                </Table.Cell>
                <Table.Cell>
                  <Link to={getOpportunitiesRoute({ createdStart: month.start, createdEnd: month.end })}>
                    {month.createdCount}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={getOpportunitiesRoute({ closedStart: month.start, closedEnd: month.end })}>
                    {month.closingCount}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Currency value={month.createdConvertedWeightedAmount} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={month.createdConvertedAmount} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={month.closingConvertedWeightedAmount} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={month.closingConvertedAmount} currency={report.currency} />
                </Table.Cell>
              </Table.Row>
            ))}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>{numberFormat.format(report.totals.createdCount)}</Table.Cell>
              <Table.Cell>{numberFormat.format(report.totals.closingCount)}</Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.createdConvertedAmount} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.createdConvertedWeightedAmount} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.closingConvertedAmount} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.closingConvertedWeightedAmount} currency={report.currency} />
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          clearable={false}
          value={filters.period}
          scope="month"
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>
    </Report.Filters>
  );
}

function TimeSeriesGraph({ report }) {
  const history = useHistory();
  const { workspace } = useWorkspace();

  const data = report.records;

  // Preparing the data for the chart
  const chartData = {
    labels: data.map((record) => moment(record.start).format(dateFormats.monthYear)),
    datasets: getDatasets(data),
  };

  const handleClick = (e, [element]) => {
    if (!element) return;
    const month = e.chart.data.labels[element.index];
    const period = {
      start: moment(month).format(dateFormats.isoDate),
      end: moment(month).endOf('month').format(dateFormats.isoDate),
    };
    const params = e.chart.data.datasets[element.datasetIndex].label.includes('Created')
      ? {
          createdStart: period.start,
          createdEnd: period.end,
          opportunityStageStatusId: 'all',
          currency: report.currency,
        }
      : {
          closedStart: period.start,
          closedEnd: period.end,
          opportunityStageStatusId: 'all',
          currency: report.currency,
        };

    history.push(`/app/${workspace.key}/reports/pipeline/opportunity-detail?${new QueryString(params)}`);
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: null,
        labels: {
          pointStyleWidth: 10,
          boxHeight: 7,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {},
      },
    },
    scales: {
      x: {
        grid: { color: colors.grey10, drawBorder: false },
        stacked: true,
        ticks: {
          color: colors.grey40,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      y: {
        grid: { color: colors.grey10, drawBorder: false },
        stacked: true,
        min: 0,
        beginAtZero: true,
      },
    },

    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        let value = tooltipItem.yLabel;
        let comment = '';

        if (tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 1) {
          // For 'Created' datasets
          comment = data.labels[tooltipItem.index].commentCreated;
        } else {
          // For 'Closing' datasets
          comment = data.labels[tooltipItem.index].commentClosed;
        }

        return `${label}: ${value} (${comment})`;
      },
    },

    onHover: (e, chartElement) => {
      e.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
    },

    onClick: handleClick,
  };

  return (
    <Widget style={{ height: '20rem' }}>
      <Bar data={chartData} options={options} />
    </Widget>
  );
}

const getDatasets = (data) => [
  {
    label: 'Weighted Created',
    data: data.map((item) => item.createdConvertedWeightedAmount),
    backgroundColor: colors.primary50,
    stack: 'Weighted Created',
  },
  {
    label: 'Gross Created',
    data: data.map((item) => item.createdConvertedAmount),
    backgroundColor: colors.primary10,
    stack: 'Gross Created',
  },
  {
    label: 'Weighted Closing',
    data: data.map((item) => item.closingConvertedWeightedAmount),
    backgroundColor: colors.success100,
    stack: 'Weighted Closing',
  },
  {
    label: 'Gross Closing',
    data: data.map((item) => item.closingConvertedAmount),
    backgroundColor: colors.success10,
    stack: 'Gross Closing',
  },
];

export default OpportunitiesByMonth;
