import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { Buttons, CancelButton, ModalCard } from '~/components';
import { useWorkspace } from '~/contexts';
import { useAuth, useLocalStorage } from '~/hooks';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function WelcomeDialog() {
  const { workspace } = useWorkspace();
  const auth = useAuth();
  const welcomeDialog = useLocalStorage({ key: `welcome_dialog` });
  const [visible, setVisible] = useState(() => {
    return workspace.sampleData && auth.workspace.manage && !welcomeDialog.hasValue();
  });

  const handleClose = () => {
    welcomeDialog.set('true');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <ModalCard title="Welcome to Ruddr!" onClose={() => handleClose()}>
      <ModalCard.Body>
        <P>
          Your workspace has been pre-loaded with sample data to help you get a better feel for Ruddr's features and
          functionality. You will notice sample members, time entries, expenses, invoices, allocations, and so on. To
          remove this sample data, simply go to the workspace{' '}
          <Link to={`/app/${workspace.key}/settings`}>settings</Link> and click the "Remove Sample Data" button.
        </P>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => handleClose()}>Close</CancelButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
