import classNames from 'classnames';
import Input from '~/components/Input';
import useAutoComplete from '~/hooks/useAutoComplete';
import _ from 'lodash';
import { darken } from 'polished';
import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';
import Dropdown, { Menu } from './Dropdown';
import Icon from './Icon';
import TextInput from './TextInput';
import Tooltip from './Tooltip';

const Search = styled(TextInput)`
  cursor: default;
  flex: 1;

  &:focus {
    cursor: text;
    border-color: ${colors.primary};
  }

  && {
    padding-right: 2.5rem;

    &.clearable {
      padding-right: 5rem;
    }
  }
`;

const Trigger = styled(Dropdown.Trigger)`
  display: flex;
  align-items: center;

  > div {
    flex: 1;
  }
`;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 100%;
  position: absolute;
  right: 0;
  color: ${colors.grey25};
`;

const Option = styled(Dropdown.Item)`
  padding: ${({ padding = '0.5rem 1rem' }) => padding};
  &.is-active,
  &.is-active:hover {
    background-color: ${colors.grey10};
  }

  ${({ grey = false }) =>
    grey &&
    css`
      color: ${colors.grey40};
    `}
`;

const Loading = styled(Icon)`
  color: ${colors.primary};
`;

const ClearIndicator = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: calc(100% - 2px);
  position: absolute;
  right: 2.5rem;
  border-right: 1px solid ${colors.grey5};
  color: ${colors.primary};
  font-size: 0.875rem;
  border-radius: 0;

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

const AutocompleteDropdown = styled(Dropdown)`
  && > ${Menu} {
    max-width: 100%;
  }
`;

const AutoCompleteContext = React.createContext();

const AutoComplete = React.forwardRef(
  (
    {
      onSearch,
      onChange,
      defaultOptions,
      value,
      displayText = '',
      renderOption,
      renderValue = renderOption,
      noOptionsMessage,
      className,
      position,
      align,
      offsetToShowMenuOnTop,
      isOpen: isOpenProp,
      onBlur,
      children,
      disableKeyboard,
      clearable = true,
      ...props
    },
    inputRef,
  ) => {
    const defaultInputRef = useRef();
    if (!inputRef) inputRef = defaultInputRef;

    const autoComplete = useAutoComplete({
      onSearch,
      onChange: (value) => onChange && onChange({ target: { name: props.name, value } }),
      onBlur: () => onBlur && onBlur({ target: { name: props.name, value } }),
      defaultOptions,
      value,
      displayText,
      isOpen: isOpenProp,
      inputRef,
    });

    const {
      handleBlur,
      handleClear,
      handleFocus,
      handleInputChange,
      handleKeyDown,
      inputValue,
      isLoading,
      isOpen,
      selectedOption,
    } = autoComplete;

    const inputText = selectedOption ? (renderValue ? renderValue(selectedOption) : selectedOption) : inputValue;

    return (
      <AutoCompleteContext.Provider value={{ ...autoComplete, renderOption }}>
        <AutocompleteDropdown
          isOpen={isOpen}
          onClose={handleBlur}
          position={position}
          align={align}
          offsetToShowMenuOnTop={offsetToShowMenuOnTop}
          className={classNames({ 'is-loading': isLoading })}
          data-testid={props.name}>
          <Trigger>
            <Search
              ref={inputRef}
              component={Input}
              autoComplete="off"
              className={classNames(className, { clearable })}
              value={inputText}
              onChange={handleInputChange}
              onKeyDown={disableKeyboard ? undefined : handleKeyDown}
              onMouseDown={handleFocus}
              wait={300}
              {...props}
            />
            {value && clearable && (
              <ClearIndicator isAnchor tabIndex={-1} onClick={handleClear}>
                <Icon icon="times" />
              </ClearIndicator>
            )}
            <Indicator onClick={props.disabled ? undefined : isOpen ? handleBlur : handleFocus}>
              {isLoading ? <Loading icon="spinner" spin /> : <Icon icon="angle-down" />}
            </Indicator>
          </Trigger>
          <Dropdown.Menu scrollable>
            {children ? (
              _.isFunction(children) ? (
                children(autoComplete)
              ) : (
                children
              )
            ) : (
              <AutoCompleteResults showNoResults noResultsMessage={noOptionsMessage} />
            )}
          </Dropdown.Menu>
        </AutocompleteDropdown>
      </AutoCompleteContext.Provider>
    );
  },
);

function AutoCompleteResults({ showNoResults, noResultsMessage }) {
  const autoComplete = useContext(AutoCompleteContext);
  const { options, renderOption } = autoComplete;

  if (options.length > 0) {
    let index = 0;
    return options.map((option) => (
      <AutoCompleteOption key={index} index={!option.disabled ? index++ : undefined} value={option}>
        {renderOption(option)}
      </AutoCompleteOption>
    ));
  }

  if (showNoResults) {
    return <Dropdown.Text>{noResultsMessage || 'None'}</Dropdown.Text>;
  }
  return null;
}

function AutoCompleteOption({ index, value, className, ...props }) {
  const autoComplete = useContext(AutoCompleteContext);
  const { handleSelect, selectedIndex } = autoComplete;

  return value.tooltip ? (
    <Tooltip message={value.tooltip} delay={1000}>
      <Option
        className={classNames(className, { 'is-active': index === selectedIndex })}
        onClick={() => handleSelect(value)}
        value={value}
        {...props}
      />
    </Tooltip>
  ) : (
    <Option
      className={classNames(className, { 'is-active': index === selectedIndex })}
      onClick={() => handleSelect(value)}
      value={value}
      {...props}
    />
  );
}

const Panel = styled.div`
  padding: 0.5rem 1rem;

  h4 {
    font-weight: bold;
  }

  > div {
    padding: 0.5rem 0;
  }
`;

const Separator = styled.hr`
  margin: 0.25rem 0;
`;

const AddNewOption = styled(Dropdown.Item)`
  color: ${colors.primary};
`;

AutoComplete.Option = AutoCompleteOption;
AutoComplete.Results = AutoCompleteResults;
AutoComplete.Panel = Panel;
AutoComplete.Separator = Separator;
AutoComplete.AddNewOption = AddNewOption;

export default AutoComplete;
export { Indicator, Search };
