import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  Radio,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function WorkspaceRoleDrawer({ workspaceRole, onSaved, onClose, onDeleted }) {
  const title = workspaceRole.id ? 'Edit Workspace Role' : 'New Workspace Role';
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const [hasDependencies, setHasDependencies] = useState(true);
  const features = useFeatures();
  const toast = useToast();
  const confirmation = useConfirmation();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (!workspaceRole.id) {
      return;
    }
    async function fetchData() {
      const { data: hasDependencies } = await api.www
        .workspaces(workspace.id)
        .workspaceRoles(workspaceRole.id)
        .hasDependencies();

      setHasDependencies(hasDependencies);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, workspace, workspaceRole]);

  const initialValues = mergeValues(
    {
      currency: workspace.currency,
      name: '',
      rate: '',
      isBillable: true,
      practice: null,
      location: null,
      discipline: null,
    },
    workspaceRole,
  );

  return (
    <>
      <Drawer
        isOpen
        title={title}
        byline="Custom Data"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values, { resetForm }) {
            try {
              form.submit(formRef.current.status.action);

              const body = emptyStringToNull({
                ..._.omit(values, 'discipline', 'practice', 'location'),
                disciplineId: values.discipline?.id ?? null,
                practiceId: values.practice?.id ?? null,
                locationId: values.location?.id ?? null,
              });
              const { data } = await api.www
                .workspaces(workspace.id)
                .workspaceRoles(workspaceRole ? workspaceRole.id : undefined)
                .upsert(body);

              await onSaved(data);
              form.save(formRef.current.status.action);

              switch (formRef.current.status.action) {
                case 'new':
                  resetForm();
                  toast.success('Workspace Role successfully saved.');
                  break;
                case 'close':
                  closeDrawer();
                  break;
              }
              form.done();
            } catch ({ message }) {
              form.error({ message });
            }
          }

          async function handleDelete() {
            const confirm = await confirmation.prompt((resolve) => (
              <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Role?</DeleteConfirmation>
            ));
            if (!confirm) return;
            try {
              await api.www.workspaces(workspace.id).workspaceRoles(workspaceRole.id).delete();
              await onDeleted();
              closeDrawer();
            } catch ({ message }) {
              toast.error(message);
            }
          }

          return (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                isBillable: Yup.bool().label('Is Billable'),
                currency: Yup.string()
                  .label('Currency')
                  .when('isBillable', { is: true, then: (schema) => schema.required() }),
                name: Yup.string().label('Name').max(255).required(),
                rate: Yup.number().label('Rate').min(0).max(99999999999).nullable(),
              })}>
              {(formik) => {
                const submit = async (action) => {
                  formik.setStatus({ action });
                  formik.submitForm();
                };

                return (
                  <Form>
                    <Form.Section title="General">
                      <Form.Control>
                        <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                      </Form.Control>
                      {features.practices && (
                        <Form.Control>
                          <Field.PracticeSelect name="practice" placeholder="Practice" />
                        </Form.Control>
                      )}
                      <Form.Control>
                        <Field.LocationSelect name="location" placeholder="Location" />
                      </Form.Control>
                      {features.disciplines && (
                        <Form.Control>
                          <Field.DisciplineSelect name="discipline" placeholder="Discipline" />
                        </Form.Control>
                      )}
                      <Form.Control>
                        <Form.Control>
                          <Field.RadioGroup name="isBillable">
                            <Radio value={true} label="Billable" />
                            <Radio value={false} label="Non-billable" />
                          </Field.RadioGroup>
                        </Form.Control>
                      </Form.Control>
                      {formik.values.isBillable && (
                        <>
                          <Form.Control>
                            <Field.WorkspaceCurrencySelect
                              name="currency"
                              materialPlaceholder="Currency"
                              clearable={false}
                              disabled={!features.multicurrency && workspaceRole.currency === workspace.currency}
                            />
                          </Form.Control>
                          <Form.Control>
                            <Field.Currency
                              name="rate"
                              placeholder="Rate"
                              precision={7}
                              currency={formik.values.currency}
                            />
                          </Form.Control>
                        </>
                      )}
                    </Form.Section>

                    {status && <FormMessage.Error>{message}</FormMessage.Error>}

                    <Drawer.Actions>
                      {workspaceRole.id && !hasDependencies && (
                        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                        {!workspaceRole.id && (
                          <ActionButton
                            isLoading={isSubmitting === 'new'}
                            ok={saved === 'new'}
                            type="submit"
                            onClick={() => submit('new')}>
                            Save &amp; New
                          </ActionButton>
                        )}

                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          type="submit"
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </>
  );
}
