import isFalsy from '../helpers/is-falsy';
import isTruthy from '../helpers/is-truthy';
import { parseDate } from '../helpers/parse-date';

const projectTasks = {
  'Import ID': {
    requirementType: 'required',
    description:
      'This ID can be a text value (or numeric), but needs to be unique for each record that is imported. This value is leveraged for identifying each imported record in the event that errors are found and also designates the records as an “imported” record, as opposed to a record entered via the Ruddr application.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },

  'Client Name': {
    requirementType: 'required',
    description:
      'The Name of the client associated with the task. This field must match the Name of a Client exactly, including spaces, punctuation and capitalization, otherwise the row may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  'Project Name': {
    requirementType: 'required',
    description:
      'The name of the project associated with the task. This field must match the name of a project exactly, including spaces, punctuation and capitalization, otherwise the row may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  'Task Name': {
    requirementType: 'required',
    description: 'Name of the task, which should be unique per project.',
    dataType: 'string',
    validator: () => true,
  },
  Code: {
    requirementType: 'optional',
    description: 'An optional code used as short hand to identify the task.',
    dataType: 'string',
    validator: () => true,
  },
  Description: {
    requirementType: 'optional',
    description: 'The description, instruction, or notes that you wish to associate with the task.',
    dataType: 'string',
    validator: () => true,
  },
  Start: {
    requirementType: 'optional',
    description: 'Start date of the task in "YYYY-MM-DD" or "MM/DD/YYYY" format.',
    dataType: 'date',
    validator: (value) => {
      const startDate = parseDate(value);
      return !!startDate || !value;
    },
  },
  End: {
    requirementType: 'optional',
    description: 'End date of the task in "YYYY-MM-DD" or "MM/DD/YYYY" format and must be after Start date value.',
    dataType: 'date',
    validator: (value, row) => {
      if (!value) return true;

      const endDate = parseDate(value);
      const startDate = parseDate(row.Start);

      if (!endDate || !startDate) {
        return false;
      }

      // Check that the end date is not before the start date
      return endDate >= startDate;
    },
  },
  Billable: {
    requirementType: 'required',
    description: '"No" if you consider this task as not billable to the project.',
    dataType: 'boolean',
    validator: (value) => {
      return value && (isTruthy(value) || isFalsy(value));
    },
  },

  'Estimated Hours': {
    requirementType: 'optional',
    description: 'The estimated number of hours that is required to complete the task.',
    dataType: 'number',
    validator: (value) => {
      return !value || (!isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0);
    },
  },

  'Estimated Fees': {
    requirementType: 'optional',
    description: 'The estimated fees that will be represented by the task.',
    dataType: 'number',
    validator: (value) => {
      return !value || (!isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0);
    },
  },

  Status: {
    requirementType: 'required',
    description: 'Provide a value of "not_started", "in_progress" or "completed" for the status of task.',
    dataType: 'string',
    validator: (value) => ['not_started', 'in_progress', 'completed'].includes(value.trim().toLowerCase()),
  },

  'Lock Time': {
    requirementType: 'optional',
    description: '"Yes" if you consider this task locked for time entry.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },

  'Require Notes': {
    requirementType: 'optional',
    description: '"Yes" if you consider this task locked for time entry.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },

  'Strict Assignment': {
    requirementType: 'optional',
    description: '"Yes" if you consider this task locked for time entry.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },

  Order: {
    requirementType: 'optional',
    description: 'The order number of the task within the project. Automatically assigned if left blank.',
    dataType: 'number',
    validator: (value) => {
      return value && !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0;
    },
  },

  'Cap Hours': {
    requirementType: 'optional',
    description: '"Yes" if you consider this task locked for time entry after this the hours cap is reached.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },

  'Cap Fees': {
    requirementType: 'optional',
    description: '"Yes" if you consider this task locked for time entry after this the fees cap is reached.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },

  Tags: {
    requirementType: 'optional',
    description:
      'Provide a semi-colon separated set of tags to associate with the task. Example: "Kick-off;Meeting;Phase 1" may be appropriate Tags for a task named "Launch Project"',
    dataType: 'string',
    validator: (value) => {
      if (value === '') return true;
      else if (typeof value !== 'string' && value !== undefined && value !== null) return false;
      else return true;
    },
  },

  Archived: {
    requirementType: 'optional',
    description: '"Yes" if you consider this task archived and will not be used moving forward.',
    dataType: 'boolean',
    validator: (value) => {
      return !value || (value && (isTruthy(value) || isFalsy(value)));
    },
  },
};

export default projectTasks;
