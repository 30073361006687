import { colors } from '~/styles';
import interpolateColor from './interpolateColor';

export default function getGradientColor(probability) {
  // Assuming probability is a value between 0 and 100
  const factor = probability / 100;
  const startColor = colors.warning; // Color associated with the lowest probability
  const endColor = colors.success50; // Color associated with the highest probability
  return interpolateColor(startColor, endColor, factor);
}
