import { useField } from '~/hooks';
import _ from 'lodash';
import React, { useMemo } from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

const options = [
  { id: 'employee', name: 'Employee' },
  { id: 'contractor', name: 'Contractor' },
  { id: 'other', name: 'Other' },
];

function EmploymentTypeSelect(props) {
  const text = useMemo(() => _.find(options, { id: props.value })?.name, [props.value]);

  return (
    <SingleSelect valueRenderer={text} {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldEmploymentTypeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <EmploymentTypeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default EmploymentTypeSelect;
export { FieldEmploymentTypeSelect };
