import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ErrorPage } from '~/routes/public/pages';
import { emptyStringToNull } from '~/utils';

export default function ProjectTaskCloneConfirmation({ target: task, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const taskId = task?.id;
  const projectId = task.projectId;

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull({
        ...values,
      });
      const { data } = await api.www.workspaces(workspace.id).projects(projectId).tasks(taskId).clone(body);
      await onSaved(data);
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  if (!task) return <ErrorPage.NotFound publicSite={false} />;

  const initialValues = {
    name: task ? `Copy of ${task?.name}` : '',
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ModalCard title="Clone Task" onClose={onClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('Title').max(255).required(),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.Text autoFocus name="name" placeholder="New Name" maxLength={255} />
                </Form.Control>
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
