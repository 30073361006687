import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Button, Table } from '~/components';
import { useApi } from '~/contexts';
import { DownloadArea, EmptyReport, Report, ReportLoader, Workspace } from './components';

export default function MissingAssociatedMembers() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getMissingAssociatedMembers();
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getMissingAssociatedMembers();
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <Table>
        <Table.Header>
          <Table.Column>Workspace</Table.Column>
          <Table.Column>Associated Workspace</Table.Column>
          <Table.Column width="22rem">Member Email</Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={`${item.workspaceId}_${item.memberEmail}`}>
            <Table.Cell>
              <Workspace
                data={{
                  name: item.workspaceName,
                  key: item.workspaceKey,
                  imageUrl: item.workspaceImageUrl,
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Workspace
                data={{
                  name: item.associatedWorkspaceName,
                  key: item.associatedWorkspaceKey,
                  imageUrl: item.associatedWorkspaceImageUrl,
                }}
              />
            </Table.Cell>
            <Table.Cell>{item.memberEmail}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
