import { ActionButton, Buttons, CancelButton, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';

function LoadFromQuickBooksDialog({ invoice, resolve }) {
  const { workspace } = useWorkspace();
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    try {
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).loadFromQuickBooks();
      await resolve(data);
      toast.success(`Invoice #${data.invoice.number} has been reloaded from QuickBooks.`);
    } catch (error) {
      toast.error(error.message ?? 'An error has occurred. The invoice has not been reloaded from QuickBooks.');
      form.done();
    }
  };

  return (
    <ModalCard title="Reload from QuickBooks" onClose={() => resolve(false)}>
      <ModalCard.Body>Are you sure you want to reload this invoice from QuickBooks?</ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
            Load
          </ActionButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default LoadFromQuickBooksDialog;
