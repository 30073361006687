import { Button, Icon } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import FilePreview from './FilePreview';
import MemberFilesDrawer from './MemberFilesDrawer';

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const WarningIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const ManageButton = styled(Button)`
  margin-left: auto;
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  margin-top: 2rem;
`;

const NoFilesMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.bold};
`;

export default function MemberFilesTab({ member }) {
  useDocumentTitle(`${member.name} Files`);

  const api = useApi();
  const { workspace } = useWorkspace();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [files, setFiles] = useState([]);

  const hasFiles = useMemo(() => files?.length > 0, [files]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).files().get();
      setFiles(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace.id, member.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : hasError ? (
        <ErrorContainer>
          <ErrorMessage>
            <WarningIcon icon="exclamation-triangle" />
            There was a problem getting the member files
          </ErrorMessage>
          <Button onClick={() => fetchData()}>Retry</Button>
        </ErrorContainer>
      ) : (
        <>
          {hasFiles ? (
            <>
              <TitleContainer>
                <Title>Member Files</Title>
                <ManageButton onClick={() => setShowDrawer(true)}>Manage Files</ManageButton>
              </TitleContainer>
              <Files>
                {_(files)
                  .map((file) => <FilePreview key={file.id} file={file} />)
                  .value()}
              </Files>
            </>
          ) : (
            <>
              <TitleContainer>
                <ManageButton onClick={() => setShowDrawer(true)}>Manage Files</ManageButton>
              </TitleContainer>
              <NoFilesMessage>No Files have been uploaded.</NoFilesMessage>
            </>
          )}
        </>
      )}
      {showDrawer && (
        <MemberFilesDrawer member={member} onClose={() => setShowDrawer(false)} onChanged={() => fetchData()} />
      )}
    </>
  );
}
