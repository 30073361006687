import styled from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';

const CancelButton = styled(Button)`
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

export default CancelButton;
