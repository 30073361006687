import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import Tag from './Tag';

export default function PracticeTag({ value, type, ...props }) {
  const features = useFeatures();

  if (_.isEmpty(value) || !features.practices) return null;

  const label =
    {
      member: 'Member Practice',
      client: 'Client Practice',
      project: 'Project Practice',
    }[type] ?? 'Practice';

  return (
    <Tag label={label} {...props}>
      {[value]
        .flat()
        .map((v) => v.name)
        .join('; ')}
    </Tag>
  );
}
