import { BillableIcon, DateTime, Duration, Form, Icon, Tag, Tags } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import TaskProgress from './TaskProgress';

const Title = styled.h5`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const Subtitle = styled.div`
  margin-top: 1.5rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const Highlights = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const Highlight = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey55};
  font-size: 0.75rem;

  &:not(:first-child) {
    padding-left: 0.75rem;
  }

  &:not(:last-child) {
    padding-right: 0.75rem;
    border-right: 1px solid ${colors.grey10};
  }
`;

const Billable = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
  color: white;
  font-size: 0.6rem;
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey40)};
  border-radius: 50%;
`;

const Status = styled.span`
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
  background-color: ${({ status }) =>
    ({ not_started: colors.warning, in_progress: colors.success, completed: colors.black })[status] || colors.grey40};
  border-radius: 50%;
`;

const Description = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
`;

const AssignedTo = styled.div`
  margin-top: 1rem;
  font-size: 0.75rem;

  ${Tags} {
    margin: -0.25rem -0.5rem;
  }
`;

function TaskDetails({ taskId, memberId, projectRoleId, entryRoleWorkedMinutes, entryMinutes, entryWorkedMinutes }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [task, setTask] = useState(null);

  const fetchData = useCallback(async () => {
    if (!taskId || !memberId) {
      setTask(null);
      return;
    }

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .timeEntries()
        .taskDetails(taskId)
        .get({ projectRoleId, memberId });
      setTask(data);
    } catch (error) {
      setTask(null);
    }
  }, [api, workspace.id, taskId, memberId, projectRoleId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const data = useMemo(() => {
    if (!task) return null;

    if (task.project.useRoles) {
      if (!task.role) return null;
      return {
        name: task.role.name,
        hours: { budget: task.role.budgetedHours, worked: task.role.workedHours, entry: entryRoleWorkedMinutes },
      };
    } else {
      return {
        name: task.member.name,
        hours: { budget: task.member.budgetedHours, worked: task.member.workedHours, entry: entryWorkedMinutes },
      };
    }
  }, [task, entryRoleWorkedMinutes, entryWorkedMinutes]);

  if (!task) return null;

  return (
    <Form.Section title="Task Details">
      <div>
        <Title>{task.name}</Title>
        <Subtitle>
          Hours summary
          {task.originalEstimatedMinutes > 0 && (
            <>
              {' '}
              - <Duration isReadable minutes={task.originalEstimatedMinutes} /> budgeted
            </>
          )}
        </Subtitle>
        <TaskProgress
          worked={task.hours * 60 - entryWorkedMinutes}
          pending={entryMinutes}
          total={task.originalEstimatedMinutes}
        />
        {data && (
          <>
            <Subtitle>
              Hours by {data.name}
              {data.hours.budget > 0 && (
                <>
                  {' '}
                  - <Duration isReadable minutes={data.hours.budget * 60} /> budgeted
                </>
              )}
            </Subtitle>
            <TaskProgress
              worked={data.hours.worked * 60 - data.hours.entry}
              pending={entryMinutes}
              total={data.hours.budget * 60}
            />
          </>
        )}
        <Subtitle>Details</Subtitle>
        <Highlights>
          <Highlight>
            <Status status={task.status.id} />
            {task.status.name}
          </Highlight>
          <Highlight>
            <Billable isBillable={task.isActuallyBillable}>
              <Icon icon="dollar-sign" />
            </Billable>
            {task.isActuallyBillable ? 'Billable' : 'Non-billable'}
          </Highlight>
          {(task.start || task.end) && (
            <Highlight>
              {task.start && task.end && (
                <span>
                  <DateTime value={task.start} /> - <DateTime value={task.end} />
                </span>
              )}
              {task.start && !task.end && (
                <span>
                  Starts: <DateTime value={task.start} />
                </span>
              )}
              {!task.start && task.end && (
                <span>
                  Ends: <DateTime value={task.start} />
                </span>
              )}
            </Highlight>
          )}
        </Highlights>
        {(task.members.length > 0 || (task.project.useRoles && task.roles.length > 0)) && (
          <>
            <Subtitle>Assigned To</Subtitle>

            <AssignedTo>
              <Tags>
                {_(task.members)
                  .map((taskMember) => taskMember.projectMember.member)
                  .uniqBy('id')
                  .sortBy('name')
                  .map((member) => (
                    <Tag style={{ backgroundColor: colors.primary10 }} key={member.id}>
                      {member.name}
                    </Tag>
                  ))
                  .value()}

                {task.project.useRoles &&
                  _(task.roles)
                    .map((taskRole) => taskRole.role)
                    .uniqBy('id')
                    .sortBy('name')
                    .map((role) => (
                      <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
                        <BillableIcon value={role.isActuallyBillable} />
                        {role.name}
                      </Tag>
                    ))
                    .value()}
              </Tags>
            </AssignedTo>
          </>
        )}

        {task.description && (
          <>
            <Subtitle>Notes</Subtitle>
            <Description>{task.description}</Description>
          </>
        )}
      </div>
    </Form.Section>
  );
}

export default TaskDetails;
