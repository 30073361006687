import {
  ActionButton,
  Buttons,
  CancelButton,
  Checkbox,
  DeleteButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Tab,
  Tabs,
  Icon,
  TooltipButton,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import SecurityRoleHistory from './SecurityRoleHistory';

const Permissions = styled.div`
  > div:not(:last-child) {
    border-bottom: 1px solid ${colors.grey10};
  }

  > section {
    &:not(:first-child) {
      padding-top: 1.25rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.grey10};
      padding-bottom: 1.25rem;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

function SecurityRoleForm({ onClose, onDelete, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const [securityRole, setSecurityRole] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const { securityRoleId } = useParams();

  const [{ isSubmitting, saved, status, message }, form] = useForm();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const fetchData = useCallback(async () => {
    if (!securityRoleId) {
      setSecurityRole({});
    }

    const { data } = await api.www.workspaces(workspace.id).securityRoles(securityRoleId).get();

    setSecurityRole(data);
  }, [api, securityRoleId, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!securityRole) return null;

  const manageWorkspace = securityRole.manageWorkspace;

  const defaultValues = {
    name: '',
    description: '',
    managePipeline: false,
    viewPipeline: false,
    manageMemberTimeAndExpenses: [],
    viewMemberTimeAndExpenses: [],
    manageMemberAllocations: [],
    viewMemberAllocations: [],
    createClients: false,
    viewClients: ['project_team'],
    deleteClients: [],
    editClients: [],
    managePublishedInvoices: [],
    manageDraftInvoices: [],
    viewPublishedInvoices: [],
    createProjects: false,
    viewClientProjects: ['project_team'],
    deleteProjects: [],
    editProjects: [],
    manageProjectPublishedInvoices: [],
    manageProjectDraftInvoices: [],
    viewProjectPublishedInvoices: [],
    manageProjectTimeAndExpenses: [],
    viewProjectTimeAndExpenses: [],
    viewProjectRates: [],
    viewProjectRevenue: [],
    viewProjectMargin: [],
    manageProjectAllocations: [],
    viewProjectAllocations: [],
  };

  const initialValues = mergeValues(defaultValues, securityRole);

  const tabErrors = {
    securityRole: ['name', 'description'],
  };

  const isNew = !securityRole || !securityRole.id;
  const title = isNew ? 'New Security Role' : 'Edit Security Role';

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => isNew && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);

            await api.www.workspaces(workspace.id).securityRoles(securityRoleId).upsert(body);

            form.save();

            onSaved();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const result = await onDelete(securityRole);
          if (result) closeDrawer();
        }

        return (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              description: Yup.string().label('Description').max(5000).required(),
              name: Yup.string().label('Name').required(),
            })}>
            {(formik) => {
              const { submitForm, setValues } = formik;

              const {
                managePipeline,
                viewPipeline,
                manageMemberTimeAndExpenses,
                viewMemberTimeAndExpenses,
                manageMemberAllocations,
                viewMemberAllocations,
                createClients,
                viewClients,
                editClients,
                deleteClients,
                managePublishedInvoices,
                manageDraftInvoices,
                viewPublishedInvoices,
                createProjects,
                viewClientProjects,
                editProjects,
                deleteProjects,
                manageProjectPublishedInvoices,
                manageProjectDraftInvoices,
                viewProjectPublishedInvoices,
                manageProjectTimeAndExpenses,
                viewProjectTimeAndExpenses,
                viewProjectRates,
                viewProjectRevenue,
                viewProjectMargin,
                manageProjectAllocations,
                viewProjectAllocations,
              } = formik.values;

              return (
                <Form>
                  <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <Tab>
                      Security Role
                      {tabErrors.securityRole.some((key) => !!formik.errors[key]) && (
                        <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                      )}
                    </Tab>
                    {!isNew && <Tab>History</Tab>}
                  </Tabs>
                  <Content>
                    {[
                      () => (
                        <>
                          <Form.Section title="Name and Description">
                            <Form.Control>
                              <Field.Text
                                ref={firstFieldRef}
                                name="name"
                                placeholder="Name"
                                disabled={manageWorkspace}
                                maxLength={255}
                              />
                            </Form.Control>
                            <Form.Control>
                              <Field.TextArea
                                name="description"
                                placeholder="Description"
                                disabled={manageWorkspace}
                                maxLength={5000}
                              />
                            </Form.Control>
                          </Form.Section>

                          <Form.Section title="Workspace Admin Permissions">
                            <Permissions>
                              <section>
                                <Form.Control>
                                  <Checkbox
                                    checked={manageWorkspace}
                                    label="Administer all workspace settings and members"
                                    subText="Only available for the Workspace Admin security role."
                                    disabled
                                  />
                                </Form.Control>
                              </section>
                            </Permissions>
                          </Form.Section>

                          {features.pipeline && (
                            <Form.Section title="Pipeline Permissions">
                              <Form.Control>
                                <Field.Checkbox
                                  name="managePipeline"
                                  label="Administer all companies, contacts and opportunities"
                                  checked={managePipeline}
                                  disabled={manageWorkspace}
                                  onChange={({ target: { checked } }) => {
                                    let values = {
                                      ...formik.values,
                                      managePipeline: checked,
                                    };

                                    if (values.managePipeline) {
                                      values.viewPipeline = true;
                                      values.createClients = true;
                                      values.viewClients = ['always', 'practice', 'project_team'];
                                      values.editClients = ['always', 'practice'];
                                      values.createProjects = true;
                                      values.viewClientProjects = ['always', 'practice', 'project_team'];
                                      if (!editProjects.includes('project_administrator')) {
                                        values.editProjects = [...editProjects, 'project_administrator'];
                                      }
                                      if (!viewProjectRates.includes('project_administrator')) {
                                        values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                      }
                                      if (!viewProjectRevenue.includes('project_administrator')) {
                                        values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                      }
                                    }

                                    setValues(values);
                                  }}
                                />
                              </Form.Control>

                              <Form.Control>
                                <Field.Checkbox
                                  name="viewPipeline"
                                  label="View all companies, contacts and opportunities"
                                  checked={viewPipeline}
                                  disabled={manageWorkspace || managePipeline}
                                  onChange={({ target: { checked } }) => {
                                    let values = {
                                      ...formik.values,
                                      viewPipeline: checked,
                                    };

                                    if (values.viewPipeline) {
                                      values.viewClients = ['always', 'practice', 'project_team'];
                                      values.viewClientProjects = ['always', 'practice', 'project_team'];
                                    }

                                    setValues(values);
                                  }}
                                />
                              </Form.Control>
                            </Form.Section>
                          )}

                          <Form.Section title="Member Permissions">
                            <Permissions>
                              <section>
                                <p>Administer time and expenses for:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All workspace members"
                                    name="manageMemberTimeAndExpenses"
                                    checked={manageMemberTimeAndExpenses.includes('always')}
                                    disabled={manageWorkspace}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageMemberTimeAndExpenses: checked
                                          ? ['always', 'practice', 'subordinates']
                                          : manageMemberTimeAndExpenses.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewMemberTimeAndExpenses = ['always', 'practice', 'subordinates'];
                                        values.viewClients = ['always', 'practice', 'project_team'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.manageProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="All members of this member's practice"
                                      name="manageMemberTimeAndExpenses"
                                      checked={manageMemberTimeAndExpenses.includes('practice')}
                                      disabled={manageWorkspace || manageMemberTimeAndExpenses.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageMemberTimeAndExpenses: checked
                                            ? [...manageMemberTimeAndExpenses, 'practice']
                                            : manageMemberTimeAndExpenses.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.viewMemberTimeAndExpenses.includes('practice')) {
                                            values.viewMemberTimeAndExpenses = [
                                              ...viewMemberTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          values.viewClients = ['always', 'practice', 'project_team'];
                                          values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}

                                <Form.Control>
                                  <Field.Checkbox
                                    label={<MemberSubordinatesLabel />}
                                    name="manageMemberTimeAndExpenses"
                                    checked={manageMemberTimeAndExpenses.includes('subordinates')}
                                    disabled={manageWorkspace || manageMemberTimeAndExpenses.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageMemberTimeAndExpenses: checked
                                          ? [...manageMemberTimeAndExpenses, 'subordinates']
                                          : manageMemberTimeAndExpenses.filter((v) => v !== 'subordinates'),
                                      };

                                      if (checked) {
                                        if (!values.viewMemberTimeAndExpenses.includes('subordinates')) {
                                          values.viewMemberTimeAndExpenses = [
                                            ...viewMemberTimeAndExpenses,
                                            'subordinates',
                                          ];
                                        }

                                        values.viewClients = ['always', 'practice', 'project_team'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>
                              </section>

                              <section>
                                <p>View time and expenses for:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All workspace members"
                                    name="viewMemberTimeAndExpenses"
                                    checked={viewMemberTimeAndExpenses.includes('always')}
                                    disabled={manageWorkspace || manageMemberTimeAndExpenses.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewMemberTimeAndExpenses: checked
                                          ? ['always', 'practice', 'subordinates']
                                          : viewMemberTimeAndExpenses.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewClients = ['always', 'practice', 'project_team'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="All members of this member's practice"
                                      name="viewMemberTimeAndExpenses"
                                      checked={viewMemberTimeAndExpenses.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        manageMemberTimeAndExpenses.includes('practice') ||
                                        viewMemberTimeAndExpenses.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          viewMemberTimeAndExpenses: checked
                                            ? [...viewMemberTimeAndExpenses, 'practice']
                                            : viewMemberTimeAndExpenses.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          values.viewClients = ['always', 'practice', 'project_team'];
                                          values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}

                                <Form.Control>
                                  <Field.Checkbox
                                    label={<MemberSubordinatesLabel />}
                                    name="viewMemberTimeAndExpenses"
                                    checked={viewMemberTimeAndExpenses.includes('subordinates')}
                                    disabled={
                                      manageWorkspace ||
                                      manageMemberTimeAndExpenses.includes('subordinates') ||
                                      viewMemberTimeAndExpenses.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewMemberTimeAndExpenses: checked
                                          ? [...viewMemberTimeAndExpenses, 'subordinates']
                                          : viewMemberTimeAndExpenses.filter((v) => v !== 'subordinates'),
                                      };

                                      if (checked) {
                                        values.viewClients = ['always', 'practice', 'project_team'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>
                              </section>

                              {features.allocations && (
                                <>
                                  <section>
                                    <p>Administer resource allocations for:</p>

                                    <Form.Control>
                                      <Field.Checkbox
                                        label="All workspace members"
                                        name="manageMemberAllocations"
                                        checked={manageMemberAllocations.includes('always')}
                                        disabled={manageWorkspace}
                                        onChange={({ target: { checked } }) => {
                                          const values = {
                                            ...formik.values,
                                            manageMemberAllocations: checked
                                              ? ['always', 'practice', 'subordinates']
                                              : manageMemberAllocations.filter((v) => v !== 'always'),
                                          };

                                          if (checked) {
                                            values.viewMemberAllocations = ['always', 'practice', 'subordinates'];
                                            values.viewClients = ['always', 'practice', 'project_team'];
                                            values.viewClientProjects = ['always', 'practice', 'project_team'];
                                            values.manageProjectAllocations = [
                                              'always',
                                              'project_team',
                                              'project_administrator',
                                              'practice',
                                            ];
                                            values.viewProjectAllocations = [
                                              'always',
                                              'project_team',
                                              'project_administrator',
                                              'practice',
                                            ];
                                          }

                                          setValues(values);
                                        }}
                                      />
                                    </Form.Control>

                                    {features.practices && (
                                      <Form.Control>
                                        <Field.Checkbox
                                          label="All members of this member's practice"
                                          name="manageMemberAllocations"
                                          checked={manageMemberAllocations.includes('practice')}
                                          disabled={manageWorkspace || manageMemberAllocations.includes('always')}
                                          onChange={({ target: { checked } }) => {
                                            const values = {
                                              ...formik.values,
                                              manageMemberAllocations: checked
                                                ? [...manageMemberAllocations, 'practice']
                                                : manageMemberAllocations.filter((v) => v !== 'practice'),
                                            };

                                            if (checked) {
                                              if (!values.viewMemberAllocations.includes('practice')) {
                                                values.viewMemberAllocations = [...viewMemberAllocations, 'practice'];
                                              }

                                              values.viewClients = ['always', 'practice', 'project_team'];
                                              values.viewClientProjects = ['always', 'practice', 'project_team'];
                                            }

                                            setValues(values);
                                          }}
                                        />
                                      </Form.Control>
                                    )}

                                    <Form.Control>
                                      <Field.Checkbox
                                        label={<MemberSubordinatesLabel />}
                                        name="manageMemberAllocations"
                                        checked={manageMemberAllocations.includes('subordinates')}
                                        disabled={manageWorkspace || manageMemberAllocations.includes('always')}
                                        onChange={({ target: { checked } }) => {
                                          const values = {
                                            ...formik.values,
                                            manageMemberAllocations: checked
                                              ? [...manageMemberAllocations, 'subordinates']
                                              : manageMemberAllocations.filter((v) => v !== 'subordinates'),
                                          };

                                          if (checked) {
                                            if (!values.viewMemberAllocations.includes('subordinates')) {
                                              values.viewMemberAllocations = [...viewMemberAllocations, 'subordinates'];
                                            }

                                            values.viewClients = ['always', 'practice', 'project_team'];
                                            values.viewClientProjects = ['always', 'practice', 'project_team'];
                                          }

                                          setValues(values);
                                        }}
                                      />
                                    </Form.Control>
                                  </section>

                                  <section>
                                    <p>View resource allocations for:</p>

                                    <Form.Control>
                                      <Field.Checkbox
                                        label="All workspace members"
                                        name="viewMemberAllocations"
                                        checked={viewMemberAllocations.includes('always')}
                                        disabled={manageWorkspace || manageMemberAllocations.includes('always')}
                                        onChange={({ target: { checked } }) => {
                                          const values = {
                                            ...formik.values,
                                            viewMemberAllocations: checked
                                              ? ['always', 'practice', 'subordinates']
                                              : viewMemberAllocations.filter((v) => v !== 'always'),
                                          };

                                          if (checked) {
                                            values.viewClients = ['always', 'practice', 'project_team'];
                                            values.viewClientProjects = ['always', 'practice', 'project_team'];
                                            values.viewProjectAllocations = [
                                              'always',
                                              'project_team',
                                              'project_administrator',
                                              'practice',
                                            ];
                                          }

                                          setValues(values);
                                        }}
                                      />
                                    </Form.Control>

                                    {features.practices && (
                                      <Form.Control>
                                        <Field.Checkbox
                                          label="All members of this member's practice"
                                          name="viewMemberAllocations"
                                          checked={viewMemberAllocations.includes('practice')}
                                          disabled={
                                            manageWorkspace ||
                                            manageMemberAllocations.includes('practice') ||
                                            viewMemberAllocations.includes('always')
                                          }
                                          onChange={({ target: { checked } }) => {
                                            const values = {
                                              ...formik.values,
                                              viewMemberAllocations: checked
                                                ? [...viewMemberAllocations, 'practice']
                                                : viewMemberAllocations.filter((v) => v !== 'practice'),
                                            };

                                            if (checked) {
                                              values.viewClients = ['always', 'practice', 'project_team'];
                                              values.viewClientProjects = ['always', 'practice', 'project_team'];
                                            }

                                            setValues(values);
                                          }}
                                        />
                                      </Form.Control>
                                    )}

                                    <Form.Control>
                                      <Field.Checkbox
                                        label={<MemberSubordinatesLabel />}
                                        name="viewMemberAllocations"
                                        checked={viewMemberAllocations.includes('subordinates')}
                                        disabled={
                                          manageWorkspace ||
                                          manageMemberAllocations.includes('subordinates') ||
                                          viewMemberAllocations.includes('always')
                                        }
                                        onChange={({ target: { checked } }) => {
                                          const values = {
                                            ...formik.values,
                                            viewMemberAllocations: checked
                                              ? [...viewMemberAllocations, 'subordinates']
                                              : viewMemberAllocations.filter((v) => v !== 'subordinates'),
                                          };

                                          if (checked) {
                                            values.viewClients = ['always', 'practice', 'project_team'];
                                            values.viewClientProjects = ['always', 'practice', 'project_team'];
                                          }

                                          setValues(values);
                                        }}
                                      />
                                    </Form.Control>
                                  </section>
                                </>
                              )}
                            </Permissions>
                          </Form.Section>

                          <Form.Section title="Client Permissions">
                            <Permissions>
                              <section>
                                <Form.Control>
                                  <Field.Checkbox
                                    name="createClients"
                                    label="Create client"
                                    checked={createClients}
                                    disabled={manageWorkspace || managePipeline}
                                    onChange={() => {
                                      let values = { ...formik.values, createClients: createClients ? false : true };
                                      if (values.createClients) {
                                        values.viewClients = ['always', 'practice', 'project_team'];
                                      }
                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>
                              </section>

                              <section>
                                <p>Access these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    name="viewClients"
                                    label="All clients"
                                    checked={viewClients.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewClients: checked
                                          ? ['always', 'practice', 'project_team']
                                          : viewClients.filter((v) => v !== 'always'),
                                      });
                                    }}
                                    disabled={
                                      !_.isEmpty(viewMemberTimeAndExpenses) ||
                                      !_.isEmpty(viewMemberAllocations) ||
                                      createClients ||
                                      viewPipeline
                                    }
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      name="viewClients"
                                      label="Clients assigned to this member's practice"
                                      checked={viewClients.includes('practice')}
                                      disabled={
                                        !_.isEmpty(viewMemberTimeAndExpenses) ||
                                        !_.isEmpty(viewMemberAllocations) ||
                                        createClients ||
                                        viewClients.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewClients: checked
                                            ? [...viewClients, 'practice']
                                            : viewClients.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}

                                <Form.Control>
                                  <Field.Checkbox
                                    name="viewClients"
                                    label="Clients where this member can access a project"
                                    checked={viewClients.includes('project_team')}
                                    disabled
                                  />
                                </Form.Control>
                              </section>

                              <section>
                                <p>Edit these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All clients this member can access"
                                    name="editClients"
                                    checked={editClients.includes('always')}
                                    disabled={manageWorkspace || managePipeline}
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        editClients: checked
                                          ? ['always', 'practice']
                                          : editClients.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Clients that are assigned to this member's practice"
                                      name="editClients"
                                      checked={editClients.includes('practice')}
                                      disabled={manageWorkspace || editClients.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          editClients: checked
                                            ? [...editClients, 'practice']
                                            : editClients.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Delete or archive these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All clients this member can access"
                                    name="deleteClients"
                                    checked={deleteClients.includes('always')}
                                    disabled={manageWorkspace}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        deleteClients: checked
                                          ? ['always', 'practice']
                                          : deleteClients.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.deleteProjects = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Clients that are assigned to this member's practice"
                                      name="deleteClients"
                                      checked={deleteClients.includes('practice')}
                                      disabled={manageWorkspace || deleteClients.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          deleteClients: checked
                                            ? ['practice']
                                            : deleteClients.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!deleteProjects.includes('practice')) {
                                            values.deleteProjects = [...deleteProjects, 'practice'];
                                          }

                                          if (!viewClientProjects.includes('practice')) {
                                            values.viewClientProjects = [...viewClientProjects, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Publish and send invoices, and record payments, for these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All clients this member can access"
                                    name="managePublishedInvoices"
                                    checked={managePublishedInvoices.includes('always')}
                                    disabled={manageWorkspace}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        managePublishedInvoices: checked
                                          ? ['always', 'practice']
                                          : managePublishedInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.manageDraftInvoices = ['always', 'practice'];
                                        values.viewPublishedInvoices = ['always', 'practice'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.manageProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.manageProjectDraftInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Clients that are assigned to this member’s practice"
                                      name="managePublishedInvoices"
                                      checked={managePublishedInvoices.includes('practice')}
                                      disabled={manageWorkspace || managePublishedInvoices.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          managePublishedInvoices: checked
                                            ? ['practice']
                                            : managePublishedInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.manageDraftInvoices.includes('practice')) {
                                            values.manageDraftInvoices = [...manageDraftInvoices, 'practice'];
                                          }

                                          if (!values.viewPublishedInvoices.includes('practice')) {
                                            values.viewPublishedInvoices = [...viewPublishedInvoices, 'practice'];
                                          }

                                          if (!values.viewClientProjects.includes('practice')) {
                                            values.viewClientProjects = [...viewClientProjects, 'practice'];
                                          }

                                          if (!manageProjectPublishedInvoices.includes('practice')) {
                                            values.manageProjectPublishedInvoices = [
                                              ...manageProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!manageProjectDraftInvoices.includes('practice')) {
                                            values.manageProjectDraftInvoices = [
                                              ...manageProjectDraftInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectPublishedInvoices.includes('practice')) {
                                            values.viewProjectPublishedInvoices = [
                                              ...viewProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Create and edit draft invoices for these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All clients this member can access"
                                    name="manageDraftInvoices"
                                    checked={manageDraftInvoices.includes('always')}
                                    disabled={manageWorkspace || managePublishedInvoices.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageDraftInvoices: checked
                                          ? ['always', 'practice']
                                          : manageDraftInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewPublishedInvoices = ['always', 'practice'];
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.manageProjectDraftInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Clients that are assigned to this member’s practice"
                                      name="manageDraftInvoices"
                                      checked={manageDraftInvoices.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        managePublishedInvoices.includes('practice') ||
                                        manageDraftInvoices.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageDraftInvoices: checked
                                            ? ['practice']
                                            : manageDraftInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.viewPublishedInvoices.includes('practice')) {
                                            values.viewPublishedInvoices = [...viewPublishedInvoices, 'practice'];
                                          }

                                          if (!values.viewClientProjects.includes('practice')) {
                                            values.viewClientProjects = [...viewClientProjects, 'practice'];
                                          }

                                          if (!manageProjectDraftInvoices.includes('practice')) {
                                            values.manageProjectDraftInvoices = [
                                              ...manageProjectDraftInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectPublishedInvoices.includes('practice')) {
                                            values.viewProjectPublishedInvoices = [
                                              ...viewProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View published invoices for these clients:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All clients this member can access"
                                    name="viewPublishedInvoices"
                                    checked={viewPublishedInvoices.includes('always')}
                                    disabled={manageWorkspace || manageDraftInvoices.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewPublishedInvoices: checked
                                          ? ['always', 'practice']
                                          : viewPublishedInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewClientProjects = ['always', 'practice', 'project_team'];
                                        values.viewProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Clients that are assigned to this member’s practice"
                                      name="viewPublishedInvoices"
                                      checked={viewPublishedInvoices.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        manageDraftInvoices.includes('practice') ||
                                        viewPublishedInvoices.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          viewPublishedInvoices: checked
                                            ? ['practice']
                                            : viewPublishedInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.viewClientProjects.includes('practice')) {
                                            values.viewClientProjects = [...viewClientProjects, 'practice'];
                                          }

                                          if (!viewProjectPublishedInvoices.includes('practice')) {
                                            values.viewProjectPublishedInvoices = [
                                              ...viewProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>
                            </Permissions>
                          </Form.Section>

                          <Form.Section title="Project Permissions">
                            <Permissions>
                              <section>
                                <Form.Control>
                                  <Field.Checkbox
                                    name="createProjects"
                                    label="Create project"
                                    checked={createProjects}
                                    disabled={manageWorkspace || managePipeline}
                                    onChange={() => {
                                      let values = { ...formik.values, createProjects: createProjects ? false : true };
                                      if (values.createProjects) {
                                        if (!editProjects.includes('project_administrator')) {
                                          values.editProjects = [...editProjects, 'project_administrator'];
                                        }
                                        if (!viewProjectRates.includes('project_administrator')) {
                                          values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                        }
                                        if (!viewProjectRevenue.includes('project_administrator')) {
                                          values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                        }
                                      }
                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>
                              </section>

                              <section>
                                <p>Access these projects within a client:</p>
                                <Form.Control>
                                  <Field.Checkbox
                                    name="viewClientProjects"
                                    label="All projects"
                                    checked={viewClientProjects.includes('always')}
                                    disabled={
                                      !_.isEmpty(viewMemberTimeAndExpenses) ||
                                      !_.isEmpty(viewMemberAllocations) ||
                                      deleteClients.includes('always') ||
                                      viewPublishedInvoices.includes('always') ||
                                      managePipeline ||
                                      viewPipeline
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewClientProjects: checked
                                          ? ['always', 'practice', 'project_team']
                                          : viewClientProjects.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      name="viewClientProjects"
                                      label="Projects assigned to this member's practice"
                                      checked={viewClientProjects.includes('practice')}
                                      disabled={
                                        !_.isEmpty(viewMemberTimeAndExpenses) ||
                                        !_.isEmpty(viewMemberAllocations) ||
                                        deleteClients.includes('practice') ||
                                        viewPublishedInvoices.includes('practice') ||
                                        viewClientProjects.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewClientProjects: checked
                                            ? [...viewClientProjects, 'practice']
                                            : viewClientProjects.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}

                                <Form.Control>
                                  <Field.Checkbox
                                    name="viewClientProjects"
                                    label="Projects where this member is on the project team"
                                    checked={viewClientProjects.includes('project_team')}
                                    disabled
                                  />
                                </Form.Control>
                              </section>

                              <section>
                                <p>Edit these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="editProjects"
                                    checked={editProjects.includes('always')}
                                    disabled={manageWorkspace}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        editProjects: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : editProjects.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];

                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="editProjects"
                                    checked={editProjects.includes('project_team')}
                                    disabled={manageWorkspace || editProjects.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        editProjects: checked
                                          ? _.union(editProjects, ['project_team', 'project_administrator'])
                                          : editProjects.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        values.viewProjectRates = _.union(viewProjectRates, [
                                          'project_team',
                                          'project_administrator',
                                        ]);

                                        values.viewProjectRevenue = _.union(viewProjectRevenue, [
                                          'project_team',
                                          'project_administrator',
                                        ]);
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="editProjects"
                                    checked={editProjects.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      createProjects ||
                                      editProjects.includes('always') ||
                                      editProjects.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        editProjects: checked
                                          ? [...editProjects, 'project_administrator']
                                          : editProjects.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (checked) {
                                        if (!values.viewProjectRates.includes('project_administrator')) {
                                          values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                        }

                                        if (!values.viewProjectRevenue.includes('project_administrator')) {
                                          values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="editProjects"
                                      checked={editProjects.includes('practice')}
                                      disabled={manageWorkspace || editProjects.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          editProjects: checked
                                            ? [...editProjects, 'practice']
                                            : editProjects.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }
                                          if (!values.viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Delete or archive these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="deleteProjects"
                                    checked={deleteProjects.includes('always')}
                                    disabled={manageWorkspace || deleteClients.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        deleteProjects: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : deleteProjects.filter((v) => v !== 'always'),
                                      };

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="deleteProjects"
                                    checked={deleteProjects.includes('project_team')}
                                    disabled={manageWorkspace || deleteProjects.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        deleteProjects: checked
                                          ? _.union(deleteProjects, ['project_team', 'project_administrator'])
                                          : deleteProjects.filter((v) => v !== 'project_team'),
                                      };

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="deleteProjects"
                                    checked={deleteProjects.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      deleteProjects.includes('always') ||
                                      deleteProjects.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        deleteProjects: checked
                                          ? [...deleteProjects, 'project_administrator']
                                          : deleteProjects.filter((v) => v !== 'project_administrator'),
                                      };

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="deleteProjects"
                                      checked={deleteProjects.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        deleteClients.includes('practice') ||
                                        deleteProjects.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          deleteProjects: checked
                                            ? [...deleteProjects, 'practice']
                                            : deleteProjects.filter((v) => v !== 'practice'),
                                        };

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Publish and send invoices for these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="manageProjectPublishedInvoices"
                                    checked={manageProjectPublishedInvoices.includes('always')}
                                    disabled={manageWorkspace || managePublishedInvoices.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectPublishedInvoices: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : manageProjectPublishedInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.manageProjectDraftInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="manageProjectPublishedInvoices"
                                    checked={manageProjectPublishedInvoices.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      managePublishedInvoices.includes('project_team') ||
                                      manageProjectPublishedInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectPublishedInvoices: checked
                                          ? _.union(manageProjectPublishedInvoices, [
                                              'project_team',
                                              'project_administrator',
                                            ])
                                          : manageProjectPublishedInvoices.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        if (!values.manageProjectDraftInvoices.includes('project_team')) {
                                          values.manageProjectDraftInvoices = _.union(manageProjectDraftInvoices, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!values.viewProjectPublishedInvoices.includes('project_team')) {
                                          values.viewProjectPublishedInvoices = _.union(viewProjectPublishedInvoices, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectTimeAndExpenses.includes('project_team')) {
                                          values.viewProjectTimeAndExpenses = _.union(viewProjectTimeAndExpenses, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRates.includes('project_team')) {
                                          values.viewProjectRates = _.union(viewProjectRates, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRevenue.includes('project_team')) {
                                          values.viewProjectRevenue = _.union(viewProjectRevenue, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="manageProjectPublishedInvoices"
                                    checked={manageProjectPublishedInvoices.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      managePublishedInvoices.includes('project_administrator') ||
                                      manageProjectPublishedInvoices.includes('always') ||
                                      manageProjectPublishedInvoices.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectPublishedInvoices: checked
                                          ? _.union(manageProjectPublishedInvoices, ['project_administrator'])
                                          : manageProjectPublishedInvoices.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (checked) {
                                        if (!values.manageProjectDraftInvoices.includes('project_administrator')) {
                                          values.manageProjectDraftInvoices = [
                                            ...manageProjectDraftInvoices,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!values.viewProjectPublishedInvoices.includes('project_administrator')) {
                                          values.viewProjectPublishedInvoices = [
                                            ...viewProjectPublishedInvoices,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!viewProjectTimeAndExpenses.includes('project_administrator')) {
                                          values.viewProjectTimeAndExpenses = [
                                            ...viewProjectTimeAndExpenses,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!viewProjectRates.includes('project_administrator')) {
                                          values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                        }

                                        if (!viewProjectRevenue.includes('project_administrator')) {
                                          values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Projects that are assigned to this member's practice"
                                      name="manageProjectPublishedInvoices"
                                      checked={manageProjectPublishedInvoices.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        managePublishedInvoices.includes('practice') ||
                                        manageProjectPublishedInvoices.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageProjectPublishedInvoices: checked
                                            ? _.union(manageProjectPublishedInvoices, ['practice'])
                                            : manageProjectPublishedInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.manageProjectDraftInvoices.includes('practice')) {
                                            values.manageProjectDraftInvoices = [
                                              ...manageProjectDraftInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!values.viewProjectPublishedInvoices.includes('practice')) {
                                            values.viewProjectPublishedInvoices = [
                                              ...viewProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>Create and edit draft invoices for these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="manageProjectDraftInvoices"
                                    checked={manageProjectDraftInvoices.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      manageDraftInvoices.includes('always') ||
                                      manageProjectPublishedInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectDraftInvoices: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : manageProjectDraftInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewProjectPublishedInvoices = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="manageProjectDraftInvoices"
                                    checked={manageProjectDraftInvoices.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      manageDraftInvoices.includes('project_team') ||
                                      manageProjectPublishedInvoices.includes('project_team') ||
                                      manageProjectDraftInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectDraftInvoices: checked
                                          ? _.union(manageProjectDraftInvoices, [
                                              'project_team',
                                              'project_administrator',
                                            ])
                                          : manageProjectDraftInvoices.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        if (!values.viewProjectPublishedInvoices.includes('project_team')) {
                                          values.viewProjectPublishedInvoices = _.union(viewProjectPublishedInvoices, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectTimeAndExpenses.includes('project_team')) {
                                          values.viewProjectTimeAndExpenses = _.union(viewProjectTimeAndExpenses, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRates.includes('project_team')) {
                                          values.viewProjectRates = _.union(viewProjectRates, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRevenue.includes('project_team')) {
                                          values.viewProjectRevenue = _.union(viewProjectRevenue, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="manageProjectDraftInvoices"
                                    checked={manageProjectDraftInvoices.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      manageDraftInvoices.includes('project_administrator') ||
                                      manageProjectPublishedInvoices.includes('project_administrator') ||
                                      manageProjectDraftInvoices.includes('always') ||
                                      manageProjectDraftInvoices.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectDraftInvoices: checked
                                          ? _.union(manageProjectDraftInvoices, ['project_administrator'])
                                          : manageProjectDraftInvoices.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (checked) {
                                        if (!values.viewProjectPublishedInvoices.includes('project_administrator')) {
                                          values.viewProjectPublishedInvoices = [
                                            ...viewProjectPublishedInvoices,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!viewProjectTimeAndExpenses.includes('project_administrator')) {
                                          values.viewProjectTimeAndExpenses = [
                                            ...viewProjectTimeAndExpenses,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!viewProjectRates.includes('project_administrator')) {
                                          values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                        }

                                        if (!viewProjectRevenue.includes('project_administrator')) {
                                          values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Projects that are assigned to this member's practice"
                                      name="manageProjectDraftInvoices"
                                      checked={manageProjectDraftInvoices.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        manageDraftInvoices.includes('practice') ||
                                        manageProjectPublishedInvoices.includes('practice') ||
                                        manageProjectDraftInvoices.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageProjectDraftInvoices: checked
                                            ? _.union(manageProjectDraftInvoices, ['practice'])
                                            : manageProjectDraftInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!values.viewProjectPublishedInvoices.includes('practice')) {
                                            values.viewProjectPublishedInvoices = [
                                              ...viewProjectPublishedInvoices,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View published invoices for these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectPublishedInvoices"
                                    checked={viewProjectPublishedInvoices.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      manageProjectDraftInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewProjectPublishedInvoices: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectPublishedInvoices.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRates = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                        values.viewProjectRevenue = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>
                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectPublishedInvoices"
                                    checked={viewProjectPublishedInvoices.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('project_team') ||
                                      manageProjectDraftInvoices.includes('project_team') ||
                                      viewProjectPublishedInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewProjectPublishedInvoices: checked
                                          ? _.union(viewProjectPublishedInvoices, [
                                              'project_team',
                                              'project_administrator',
                                            ])
                                          : viewProjectPublishedInvoices.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        if (!viewProjectTimeAndExpenses.includes('project_team')) {
                                          values.viewProjectTimeAndExpenses = _.union(viewProjectTimeAndExpenses, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRates.includes('project_team')) {
                                          values.viewProjectRates = _.union(viewProjectRates, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }

                                        if (!viewProjectRevenue.includes('project_team')) {
                                          values.viewProjectRevenue = _.union(viewProjectRevenue, [
                                            'project_team',
                                            'project_administrator',
                                          ]);
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectPublishedInvoices"
                                    checked={viewProjectPublishedInvoices.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('project_administrator') ||
                                      manageProjectDraftInvoices.includes('project_administrator') ||
                                      viewProjectPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        viewProjectPublishedInvoices: checked
                                          ? _.union(viewProjectPublishedInvoices, ['project_administrator'])
                                          : viewProjectPublishedInvoices.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (checked) {
                                        if (!viewProjectTimeAndExpenses.includes('project_administrator')) {
                                          values.viewProjectTimeAndExpenses = [
                                            ...viewProjectTimeAndExpenses,
                                            'project_administrator',
                                          ];
                                        }

                                        if (!viewProjectRates.includes('project_administrator')) {
                                          values.viewProjectRates = [...viewProjectRates, 'project_administrator'];
                                        }

                                        if (!viewProjectRevenue.includes('project_administrator')) {
                                          values.viewProjectRevenue = [...viewProjectRevenue, 'project_administrator'];
                                        }
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label="Projects that are assigned to this member's practice"
                                      name="viewProjectPublishedInvoices"
                                      checked={viewProjectPublishedInvoices.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        viewPublishedInvoices.includes('practice') ||
                                        manageProjectDraftInvoices.includes('practice') ||
                                        viewProjectPublishedInvoices.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          viewProjectPublishedInvoices: checked
                                            ? _.union(viewProjectPublishedInvoices, ['practice'])
                                            : viewProjectPublishedInvoices.filter((v) => v !== 'practice'),
                                        };

                                        if (checked) {
                                          if (!viewProjectTimeAndExpenses.includes('practice')) {
                                            values.viewProjectTimeAndExpenses = [
                                              ...viewProjectTimeAndExpenses,
                                              'practice',
                                            ];
                                          }

                                          if (!viewProjectRates.includes('practice')) {
                                            values.viewProjectRates = [...viewProjectRates, 'practice'];
                                          }

                                          if (!viewProjectRevenue.includes('practice')) {
                                            values.viewProjectRevenue = [...viewProjectRevenue, 'practice'];
                                          }
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <span>Administer and approve time entries and expense items on these projects:</span>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="manageProjectTimeAndExpenses"
                                    checked={manageProjectTimeAndExpenses.includes('always')}
                                    disabled={manageWorkspace || manageMemberTimeAndExpenses.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectTimeAndExpenses: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : manageProjectTimeAndExpenses.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewProjectTimeAndExpenses = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="manageProjectTimeAndExpenses"
                                    checked={manageProjectTimeAndExpenses.includes('project_team')}
                                    disabled={manageWorkspace || manageProjectTimeAndExpenses.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectTimeAndExpenses: checked
                                          ? _.union(manageProjectTimeAndExpenses, [
                                              'project_team',
                                              'project_administrator',
                                            ])
                                          : manageProjectTimeAndExpenses.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        values.viewProjectTimeAndExpenses = _.union(viewProjectTimeAndExpenses, [
                                          'project_team',
                                          'project_administrator',
                                        ]);
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="manageProjectTimeAndExpenses"
                                    checked={manageProjectTimeAndExpenses.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      manageProjectTimeAndExpenses.includes('always') ||
                                      manageProjectTimeAndExpenses.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectTimeAndExpenses: checked
                                          ? [...manageProjectTimeAndExpenses, 'project_administrator']
                                          : manageProjectTimeAndExpenses.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (
                                        checked &&
                                        !values.viewProjectTimeAndExpenses.includes('project_administrator')
                                      ) {
                                        values.viewProjectTimeAndExpenses = [
                                          ...viewProjectTimeAndExpenses,
                                          'project_administrator',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="manageProjectTimeAndExpenses"
                                      checked={manageProjectTimeAndExpenses.includes('practice')}
                                      disabled={manageWorkspace || manageProjectTimeAndExpenses.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageProjectTimeAndExpenses: checked
                                            ? [...manageProjectTimeAndExpenses, 'practice']
                                            : manageProjectTimeAndExpenses.filter((v) => v !== 'practice'),
                                        };

                                        if (checked && !values.viewProjectTimeAndExpenses.includes('practice')) {
                                          values.viewProjectTimeAndExpenses = [
                                            ...viewProjectTimeAndExpenses,
                                            'practice',
                                          ];
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View time entries and expense items on these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectTimeAndExpenses"
                                    checked={viewProjectTimeAndExpenses.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      viewMemberTimeAndExpenses.includes('always') ||
                                      manageProjectTimeAndExpenses.includes('always') ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectTimeAndExpenses: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectTimeAndExpenses.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectTimeAndExpenses"
                                    checked={viewProjectTimeAndExpenses.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_team') ||
                                      manageProjectTimeAndExpenses.includes('project_team') ||
                                      viewProjectTimeAndExpenses.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectTimeAndExpenses: checked
                                          ? _.union(viewProjectTimeAndExpenses, [
                                              'project_team',
                                              'project_administrator',
                                            ])
                                          : viewProjectTimeAndExpenses.filter((v) => v !== 'project_team'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectTimeAndExpenses"
                                    checked={viewProjectTimeAndExpenses.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_administrator') ||
                                      manageProjectTimeAndExpenses.includes('project_administrator') ||
                                      viewProjectTimeAndExpenses.includes('always') ||
                                      viewProjectTimeAndExpenses.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectTimeAndExpenses: checked
                                          ? [...viewProjectTimeAndExpenses, 'project_administrator']
                                          : viewProjectTimeAndExpenses.filter((v) => v !== 'project_administrator'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="viewProjectTimeAndExpenses"
                                      checked={viewProjectTimeAndExpenses.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        viewPublishedInvoices.includes('practice') ||
                                        viewProjectPublishedInvoices.includes('practice') ||
                                        manageProjectTimeAndExpenses.includes('practice') ||
                                        viewProjectTimeAndExpenses.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewProjectTimeAndExpenses: checked
                                            ? [...viewProjectTimeAndExpenses, 'practice']
                                            : viewProjectTimeAndExpenses.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View bill rates on these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectRates"
                                    checked={viewProjectRates.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('always') ||
                                      editProjects.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRates: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectRates.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectRates"
                                    checked={viewProjectRates.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_team') ||
                                      editProjects.includes('project_team') ||
                                      viewProjectRates.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRates: checked
                                          ? _.union(viewProjectRates, ['project_team', 'project_administrator'])
                                          : viewProjectRates.filter((v) => v !== 'project_team'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectRates"
                                    checked={viewProjectRates.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_administrator') ||
                                      editProjects.includes('project_administrator') ||
                                      viewProjectRates.includes('always') ||
                                      viewProjectRates.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRates: checked
                                          ? [...viewProjectRates, 'project_administrator']
                                          : viewProjectRates.filter((v) => v !== 'project_administrator'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="viewProjectRates"
                                      checked={viewProjectRates.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        viewPublishedInvoices.includes('practice') ||
                                        viewProjectPublishedInvoices.includes('practice') ||
                                        editProjects.includes('practice') ||
                                        viewProjectRates.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewProjectRates: checked
                                            ? [...viewProjectRates, 'practice']
                                            : viewProjectRates.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View revenue on these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectRevenue"
                                    checked={viewProjectRevenue.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('always') ||
                                      editProjects.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRevenue: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectRevenue.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectRevenue"
                                    checked={viewProjectRevenue.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_team') ||
                                      editProjects.includes('project_team') ||
                                      viewProjectRevenue.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRevenue: checked
                                          ? _.union(viewProjectRevenue, ['project_team', 'project_administrator'])
                                          : viewProjectRevenue.filter((v) => v !== 'project_team'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectRevenue"
                                    checked={viewProjectRevenue.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      viewPublishedInvoices.includes('always') ||
                                      viewProjectPublishedInvoices.includes('project_administrator') ||
                                      editProjects.includes('project_administrator') ||
                                      viewProjectRevenue.includes('always') ||
                                      viewProjectRevenue.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectRevenue: checked
                                          ? [...viewProjectRevenue, 'project_administrator']
                                          : viewProjectRevenue.filter((v) => v !== 'project_administrator'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="viewProjectRevenue"
                                      checked={viewProjectRevenue.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        viewPublishedInvoices.includes('practice') ||
                                        viewProjectPublishedInvoices.includes('practice') ||
                                        editProjects.includes('practice') ||
                                        viewProjectRevenue.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewProjectRevenue: checked
                                            ? [...viewProjectRevenue, 'practice']
                                            : viewProjectRevenue.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View cost and profit on these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectMargin"
                                    checked={viewProjectMargin.includes('always')}
                                    disabled={manageWorkspace}
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectMargin: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectMargin.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectMargin"
                                    checked={viewProjectMargin.includes('project_team')}
                                    disabled={manageWorkspace || viewProjectMargin.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectMargin: checked
                                          ? _.union(viewProjectMargin, ['project_team', 'project_administrator'])
                                          : viewProjectMargin.filter((v) => v !== 'project_team'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectMargin"
                                    checked={viewProjectMargin.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      viewProjectMargin.includes('always') ||
                                      viewProjectMargin.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectMargin: checked
                                          ? [...viewProjectMargin, 'project_administrator']
                                          : viewProjectMargin.filter((v) => v !== 'project_administrator'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="viewProjectMargin"
                                      checked={viewProjectMargin.includes('practice')}
                                      disabled={manageWorkspace || viewProjectMargin.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewProjectMargin: checked
                                            ? [...viewProjectMargin, 'practice']
                                            : viewProjectMargin.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <span>Administer resource allocations on these projects:</span>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="manageProjectAllocations"
                                    checked={manageProjectAllocations.includes('always')}
                                    disabled={manageWorkspace || manageMemberAllocations.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectAllocations: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : manageProjectAllocations.filter((v) => v !== 'always'),
                                      };

                                      if (checked) {
                                        values.viewProjectAllocations = [
                                          'always',
                                          'project_team',
                                          'project_administrator',
                                          'practice',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="manageProjectAllocations"
                                    checked={manageProjectAllocations.includes('project_team')}
                                    disabled={manageWorkspace || manageProjectAllocations.includes('always')}
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectAllocations: checked
                                          ? _.union(manageProjectAllocations, ['project_team', 'project_administrator'])
                                          : manageProjectAllocations.filter((v) => v !== 'project_team'),
                                      };

                                      if (checked) {
                                        values.viewProjectAllocations = _.union(viewProjectAllocations, [
                                          'project_team',
                                          'project_administrator',
                                        ]);
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="manageProjectTimeAndExpenses"
                                    checked={manageProjectAllocations.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      manageProjectAllocations.includes('always') ||
                                      manageProjectAllocations.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      const values = {
                                        ...formik.values,
                                        manageProjectAllocations: checked
                                          ? [...manageProjectAllocations, 'project_administrator']
                                          : manageProjectAllocations.filter((v) => v !== 'project_administrator'),
                                      };

                                      if (checked && !values.viewProjectAllocations.includes('project_administrator')) {
                                        values.viewProjectAllocations = [
                                          ...viewProjectAllocations,
                                          'project_administrator',
                                        ];
                                      }

                                      setValues(values);
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="manageProjectTimeAndExpenses"
                                      checked={manageProjectAllocations.includes('practice')}
                                      disabled={manageWorkspace || manageProjectAllocations.includes('always')}
                                      onChange={({ target: { checked } }) => {
                                        const values = {
                                          ...formik.values,
                                          manageProjectAllocations: checked
                                            ? [...manageProjectAllocations, 'practice']
                                            : manageProjectAllocations.filter((v) => v !== 'practice'),
                                        };

                                        if (checked && !values.viewProjectAllocations.includes('practice')) {
                                          values.viewProjectAllocations = [...viewProjectAllocations, 'practice'];
                                        }

                                        setValues(values);
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>

                              <section>
                                <p>View resource allocations on these projects:</p>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="All projects this member can access"
                                    name="viewProjectAllocations"
                                    checked={viewProjectAllocations.includes('always')}
                                    disabled={
                                      manageWorkspace ||
                                      viewMemberAllocations.includes('always') ||
                                      manageProjectAllocations.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectAllocations: checked
                                          ? ['always', 'project_team', 'project_administrator', 'practice']
                                          : viewProjectAllocations.filter((v) => v !== 'always'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is on the project team"
                                    name="viewProjectAllocations"
                                    checked={viewProjectAllocations.includes('project_team')}
                                    disabled={
                                      manageWorkspace ||
                                      manageProjectAllocations.includes('project_team') ||
                                      viewProjectAllocations.includes('always')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectAllocations: checked
                                          ? _.union(viewProjectAllocations, ['project_team', 'project_administrator'])
                                          : viewProjectAllocations.filter((v) => v !== 'project_team'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                <Form.Control>
                                  <Field.Checkbox
                                    label="Projects where this member is the Project Admin"
                                    name="viewProjectAllocations"
                                    checked={viewProjectAllocations.includes('project_administrator')}
                                    disabled={
                                      manageWorkspace ||
                                      manageProjectAllocations.includes('project_administrator') ||
                                      viewProjectAllocations.includes('always') ||
                                      viewProjectAllocations.includes('project_team')
                                    }
                                    onChange={({ target: { checked } }) => {
                                      setValues({
                                        ...formik.values,
                                        viewProjectAllocations: checked
                                          ? [...viewProjectAllocations, 'project_administrator']
                                          : viewProjectAllocations.filter((v) => v !== 'project_administrator'),
                                      });
                                    }}
                                  />
                                </Form.Control>

                                {features.practices && (
                                  <Form.Control>
                                    <Field.Checkbox
                                      label={<ProjectPracticeLabel />}
                                      name="viewProjectAllocations"
                                      checked={viewProjectAllocations.includes('practice')}
                                      disabled={
                                        manageWorkspace ||
                                        manageProjectAllocations.includes('practice') ||
                                        viewProjectAllocations.includes('always')
                                      }
                                      onChange={({ target: { checked } }) => {
                                        setValues({
                                          ...formik.values,
                                          viewProjectAllocations: checked
                                            ? [...viewProjectAllocations, 'practice']
                                            : viewProjectAllocations.filter((v) => v !== 'practice'),
                                        });
                                      }}
                                    />
                                  </Form.Control>
                                )}
                              </section>
                            </Permissions>
                          </Form.Section>
                        </>
                      ),
                      () => <SecurityRoleHistory securityRoleId={securityRole?.id} />,
                    ][tabIndex]()}

                    {status && <FormMessage.Error spaceRight>{message}</FormMessage.Error>}
                  </Content>

                  <Drawer.Actions>
                    {!manageWorkspace && securityRoleId && _.isFunction(onDelete) && (
                      <TooltipButton
                        component={DeleteButton}
                        disabled={manageWorkspace || securityRole.memberCount > 0}
                        tooltip={
                          manageWorkspace
                            ? 'The workspace admin security role cannot be deleted.'
                            : securityRole.memberCount > 0
                              ? 'This item is currently in use.'
                              : undefined
                        }
                        onClick={handleDelete}>
                        Delete
                      </TooltipButton>
                    )}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      {!manageWorkspace && (
                        <ActionButton ok={saved} isLoading={isSubmitting} onClick={submitForm}>
                          Save &amp; Close
                        </ActionButton>
                      )}
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

const ProjectPracticeLabel = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    Projects assigned to this member’s practice
    <HelpTooltip
      style={{ marginLeft: '.5rem' }}
      message="Includes projects where either the client or the project is assigned to this member's practice."
    />
  </div>
);

const MemberSubordinatesLabel = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    All subordinates of this member
    <HelpTooltip
      style={{ marginLeft: '.5rem' }}
      message="Includes this member plus all subordinates including direct and indirect reports."
    />
  </div>
);

export default SecurityRoleForm;
