import React from 'react';
import Tag from './Tag';

export default function JobTitleTag({ value, ...props }) {
  if (!value || value.length === 0) return null;
  return (
    <Tag label="Member Job Title" {...props}>
      {value.map((pt) => pt.name).join('; ')}
    </Tag>
  );
}
