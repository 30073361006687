import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const PathHistoryContext = React.createContext();

function PathHistoryProvider({ children }) {
  const history = useHistory();
  // The raw sequence of paths as navigation occurs
  const pathHistoryRef = useRef([]);
  // A key/value store of path keys and last path values
  const lastPathHistoryRef = useRef({});
  // A history of back path links used when chaining back links since those
  // are forward (PUSH) navigations, not browser back/forward (POP) and new
  // path keys are generated
  const backPathHistoryRef = useRef([]);
  // Used to identify the current number of back link clicks
  // It is the index from the end of the array, not from the beginning
  const backIndexRef = useRef(0);

  // Handles any navigation changes
  useEffect(() => {
    // Used to set the initial path on page load
    const { pathname, search } = history.location;
    pathHistoryRef.current.push(`${pathname}${search}`);

    // Will get fired on each navigation event
    // The return cleans up the listener on unload
    return history.listen(({ pathname, search, state }, action) => {
      // If the last link to get pressed was a `BackLink`, the next loaded
      // link should use the previous page's back link, not the "last path"
      if (state?.goBack === true) {
        backIndexRef.current++;
      }
      // On any forward navigation (not a POP or REPLACE action), if a back
      // link was preivously clicked, reset the state
      else if (action === 'PUSH' && backIndexRef.current > 0) {
        backIndexRef.current = 0;
        backPathHistoryRef.current = [];
      }

      // Add the current path to the history
      pathHistoryRef.current.push(`${pathname}${search}`);

      // Keep the path history small to avoid an array growing forever
      // Technically, we only need the last 2 entries for `lastPath()` to work properly
      if (pathHistoryRef.current.length > 5) {
        pathHistoryRef.current.splice(0, pathHistoryRef.current.length - 5);
      }
    });
  }, [history]);

  // Returns the last path of the previous page
  const lastPath = useCallback(() => {
    // If the back button was clicked, use the previous back link as the last path
    // This can return undefined in some instances, use the default path in that case
    if (backIndexRef.current > 0) {
      const index = backPathHistoryRef.current.length - (backIndexRef.current + 1);
      return backPathHistoryRef.current[index];
    }
    // If this is the very first page on load, there will be no last path
    if (pathHistoryRef.current.length < 2) {
      return null;
    }
    // When this is called, the current page will be in the last spot, so the last
    // path will be in the second to last spot
    return pathHistoryRef.current[pathHistoryRef.current.length - 2];
  }, []);

  // Sets the key for a URL to the last path, this is helpful when using browser
  // forward/back to keep the link the same
  const setLastPath = useCallback((key, path) => {
    // Ensures the key is only ever set once
    if (!(key in lastPathHistoryRef.current)) {
      lastPathHistoryRef.current[key] = path;
    }
    // Always return the set value in case it is different than what was passed in
    const actualPath = lastPathHistoryRef.current[key];
    // Ensure the history is up-to-date if not a back click
    if (backIndexRef.current === 0) {
      const index = backPathHistoryRef.current.length - 1;
      backPathHistoryRef.current[index] = actualPath;
    }
    return actualPath;
  }, []);

  // When a new instance of the `BackLink` shows up, log the last path URL
  const initBackPath = useCallback((path) => {
    if (backIndexRef.current === 0) {
      backPathHistoryRef.current.push(path);
    }
  }, []);

  const value = {
    lastPath,
    setLastPath,
    initBackPath,
  };
  return <PathHistoryContext.Provider value={value}>{children}</PathHistoryContext.Provider>;
}

function usePathHistory() {
  return useContext(PathHistoryContext);
}

export { PathHistoryContext, usePathHistory, PathHistoryProvider };
