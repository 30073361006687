const entities = {
  allocation: { id: 'allocation', name: 'Allocation' },
  client: { id: 'client', name: 'Client' },
  client_approval: { id: 'client_approval', name: 'Client Approval' },
  credit_note: { id: 'credit_note', name: 'Credit Note' },
  expense_item: { id: 'expense_item', name: 'Expense Item' },
  invoice: { id: 'invoice', name: 'Invoice' },
  member: { id: 'member', name: 'Member' },
  project: { id: 'project', name: 'Project' },
  project_task: { id: 'project_task', name: 'Project Task' },
  security_role: { id: 'security_role', name: 'Security Role' },
  time_entry: { id: 'time_entry', name: 'Time Entry' },
  workspace: { id: 'workspace', name: 'Workspace' },
};

export default entities;
