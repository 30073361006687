import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useApi, useWorkspace } from '~/contexts';
import { Buttons, CancelButton, Drawer, Icon } from '~/components';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';

const Label = styled.p`
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  text-align: ${({ align }) => (align === 'right' ? 'right' : 'left')};
  background-color: ${colors.white};
`;

const Invoices = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1.5fr;
  row-gap: 1px;
  width: 100%;
  background-color: ${colors.grey10};
`;

const InvoiceData = styled.div`
  display: flex;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
  padding: 0.5rem 0;
  background-color: ${colors.white};
`;

export default function ViewInvoices({ onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [invoices, setInvoices] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchInvoices = async () => {
      const { data } = await api.www.workspaces(workspace.id).stripe.getInvoices();
      setInvoices(data);
      setIsReady(true);
    };
    fetchInvoices();
    return fetchInvoices.cancel;
  }, [api, workspace]);

  return (
    <Drawer isOpen title="Invoices" onClose={onClose}>
      {(closeDrawer) => (
        <>
          {isReady ? (
            <Invoices>
              <Label>Number</Label>
              <Label>Date</Label>
              <Label>Status</Label>
              <Label align="right">Total</Label>
              <Label align="right">PDF</Label>
              {invoices.map((invoice) => (
                <React.Fragment key={invoice.id}>
                  <InvoiceData>{invoice.number}</InvoiceData>
                  <InvoiceData>{moment.unix(invoice.created).format(dateFormats.longDate)}</InvoiceData>
                  <InvoiceData>{invoice.stripeStatus.name}</InvoiceData>
                  <InvoiceData align="right">${(invoice.total / 100).toFixed(2)}</InvoiceData>
                  <InvoiceData align="right">
                    {invoice.invoicePdf && (
                      <a href={invoice.invoicePdf}>
                        Download <Icon icon="file-pdf" />
                      </a>
                    )}
                  </InvoiceData>
                </React.Fragment>
              ))}
            </Invoices>
          ) : (
            <PageLoader />
          )}
          <Drawer.Actions>
            <Buttons align="right">
              <CancelButton onClick={closeDrawer}>Close</CancelButton>
            </Buttons>
          </Drawer.Actions>
        </>
      )}
    </Drawer>
  );
}
