import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '~/components';
import { useApi } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { ResponsiveContainer } from '../../components';

const Container = styled(ResponsiveContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const LoadingIcon = styled(Icon)`
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  font-size: 2rem;
`;

export default function DeviceLoginToken() {
  useDocumentTitle('Login');

  const api = useApi();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const redirectToError = () => history.push('/device/login/error');

    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      redirectToError();
      return;
    }

    const token = params.get('token');
    if (!token) {
      redirectToError();
      return;
    }

    (async () => {
      try {
        await api.www.verifyLoginToken(token);
        history.replace({
          pathname: `/device/login/token/verified`,
          search: new URLSearchParams({ token }).toString(),
        });
      } catch (error) {
        redirectToError();
      }
    })();
  }, [api, history, location.search]);

  return (
    <Container>
      <Title>Login</Title>
      <Byline>We're logging you in, please wait.</Byline>
      <LoadingIcon icon="spinner" spin />
    </Container>
  );
}
