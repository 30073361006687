import React, { useState, useContext, useMemo } from 'react';
import { Toast } from '~/components';
import _ from 'lodash';

const ToastContext = React.createContext();

function ToastProvider({ children, minDuration = 3000, durationPerCharacter = 100 }) {
  const [toasts, setToasts] = useState([]);

  const close = ({ id, timer }) => {
    clearTimeout(timer);
    setToasts((toasts) => toasts.filter((item) => item.id !== id));
  };

  const toast = useMemo(
    () => ({
      open: ({ message, variant }) => {
        const id = _.uniqueId('toast_');
        const duration = Math.max(minDuration, message.length * durationPerCharacter);
        const timer = setTimeout(() => close(toast), duration);
        const toast = { id, message, variant, timer };
        setToasts((toasts) => [...toasts, toast]);
        return toast;
      },
      success: (message) => toast.open({ message, variant: 'success' }),
      warning: (message) => toast.open({ message, variant: 'warning' }),
      error: (message = 'An error has occurred.') => toast.open({ message, variant: 'danger' }),
      clear: () => setToasts([]),
    }),
    [durationPerCharacter, minDuration],
  );

  return (
    <ToastContext.Provider value={toast}>
      <>
        {children}
        <Toast.Container>
          {_.map(toasts, (toast) => {
            const { id, message, variant } = toast;
            return (
              <Toast key={id} variant={variant} onClose={() => close(toast)}>
                {message}
              </Toast>
            );
          })}
        </Toast.Container>
      </>
    </ToastContext.Provider>
  );
}

function useToast() {
  return useContext(ToastContext);
}

export { ToastContext, useToast, ToastProvider };
