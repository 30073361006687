import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, post, patch } from './agent';
import invoiceFiles from './invoiceFiles';

const invoices =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'invoices', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      openInvoicesKpi: (query = {}) =>
        get(url.concat('/kpi/open-invoices').concat(new QueryString(query).toString(true))),
      create: (body) => post(url, body),
      createReadyToBill: (body) => post(url.concat('/ready-to-bill'), body),
      update: (body) => patch(url, body),
      delete: () => del(url),
      publish: () => post(url.concat('/publish')),
      unpublish: () => post(url.concat('/unpublish')),
      saveToQuickBooks: () => post(url.concat('/save-to-quickbooks')),
      loadFromQuickBooks: () => post(url.concat('/load-from-quickbooks')),
      removeFromQuickBooks: () => post(url.concat('/remove-from-quickbooks')),
      saveToXero: () => post(url.concat('/save-to-xero')),
      loadFromXero: () => post(url.concat('/load-from-xero')),
      removeFromXero: () => post(url.concat('/remove-from-xero')),
      sendTestEmail: (body) => post(url.concat('/send-email?test=true'), body),
      sendEmail: (body) => post(url.concat('/send-email'), body),
      readyToBill: (query = {}) =>
        get(url.concat('/ready-to-bill').concat(new QueryString(query, { multi: true }).toString(true))),
      generateLines: (body) => post(url.concat('/draft/generate-lines'), body),
      files: invoiceFiles(url.concat('/files')),
      getLineItemStatus: (query = {}) =>
        get(url.concat('/draft/line-item-status').concat(new QueryString(query).toString(true))),
      getTimeTransactions: (query = {}) =>
        get(url.concat('/transactions/time').concat(new QueryString(query, { multi: true }).toString(true))),
      getMilestoneTransactions: (query = {}) =>
        get(url.concat('/transactions/milestones').concat(new QueryString(query, { multi: true }).toString(true))),
      getExpenseTransactions: (query = {}) =>
        get(url.concat('/transactions/expenses').concat(new QueryString(query, { multi: true }).toString(true))),
      getOtherItemTransactions: (query = {}) =>
        get(url.concat('/transactions/other-items').concat(new QueryString(query, { multi: true }).toString(true))),
      validateTransactionNumber: (body) => post(url.concat('/validate-transaction-number'), body),
      history: () => get(url.concat('/history')),
      projects: (query = {}) => get(url.concat('/projects').concat(new QueryString(query).toString(true))),
      client: () => get(url.concat(`/client`)),
    };
  };

export default invoices;
