import Button from './Button';
import { colors } from '~/styles';
import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import Icon from './Icon';

const StyledButton = styled(Button)`
  &.ok {
    background-color: ${colors.success};
    color: transparent;
    pointer-events: none;
    cursor: default;
    user-select: none;
    position: relative;
  }
`;

const Ok = styled(Icon)`
  color: white;
  position: absolute;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
`;

function ActionButton({ ok, className, children, ...props }) {
  return (
    <StyledButton {...props} className={classNames(className, { ok })}>
      {children}
      {ok && <Ok icon="check" />}
    </StyledButton>
  );
}

export default ActionButton;
