import React, { useContext, useRef, useCallback } from 'react';

const ModalContext = React.createContext();

function ModalProvider({ children }) {
  const instanceCount = useRef(0);

  const addInstance = useCallback(() => {
    instanceCount.current++;

    if (instanceCount.current > 0) {
      document.documentElement.classList.add('no-scroll');
    }
  }, []);

  const removeInstance = useCallback(() => {
    instanceCount.current--;

    if (instanceCount.current <= 0) {
      document.documentElement.classList.remove('no-scroll');
    }
  }, []);

  const manageInstances = useCallback(() => {
    addInstance();
    return removeInstance;
  }, [addInstance, removeInstance]);

  return (
    <ModalContext.Provider value={{ addInstance, removeInstance, manageInstances }}>{children}</ModalContext.Provider>
  );
}

function useModal() {
  return useContext(ModalContext);
}

export { ModalContext, useModal, ModalProvider };
