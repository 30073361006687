import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';

const LoadingIcon = styled(Icon)`
  margin: 4rem;
  font-size: 2rem;
`;

export default function Loading(props) {
  return <LoadingIcon icon="spinner" spin={true} {...props} />;
}
