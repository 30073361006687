import React, { useContext, useEffect, useState } from 'react';
import { useApi } from './ApiContext';
import { useSubscription } from './SubscriptionContext';
import { useWorkspace } from './WorkspaceContext';

const IntegrationsContext = React.createContext();

function IntegrationsProvider({ children }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { subscribe, unsubscribe } = useSubscription();

  const [integrations, setIntegrations] = useState({ xero: null, qbo: null });
  useEffect(() => {
    if (!workspace?.id) return;

    const fetchData = async (sid) => {
      try {
        const { data } = await api.www.workspaces(workspace.id).integrations().getStatus();
        setIntegrations(data);
      } catch (error) {
        // If there's an API error, unsubscribe this event
        unsubscribe(sid);
      }
    };

    const sid = subscribe([useSubscription.keys.integrations_changed], (subscription) => {
      fetchData(subscription.id);
    });

    fetchData(sid);

    return () => {
      if (fetchData.cancel) {
        fetchData.cancel();
      }
      unsubscribe(sid);
    };
  }, [api, workspace?.id, subscribe, unsubscribe]);

  return <IntegrationsContext.Provider value={integrations}>{children}</IntegrationsContext.Provider>;
}

function useIntegrations() {
  return useContext(IntegrationsContext);
}

export { IntegrationsContext, IntegrationsProvider, useIntegrations };
