import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';

const projectTasks =
  (projectTasksUrl) =>
  (id = '') => {
    const url = _([projectTasksUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query, { multi: true }).toString(true))),
      getExport: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      history: () => get(url.concat('/history')),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      role: (query = {}) => get(url.concat('/role').concat(new QueryString(query).toString(true))),
      member: (query = {}) => get(url.concat('/member').concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      createFromTemplates: ({ ids }) => post(url.concat('/create-from-templates'), { ids }),
      clone: (body) => post(url.concat('/clone'), body),
      order: (body) => patch(url.concat('/order'), body),
      setRecordStatus: ({ recordStatusId }) => put(url.concat('/record-status'), { recordStatusId }),
    };
  };

export default projectTasks;
