import React, { useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Button, Table } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { ChartArea, DownloadArea, EmptyReport, Report, ReportLoader } from './components';

const formatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 2,
});

export default function WeeklyActiveUsers({ start, end }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      setData();
      try {
        const { data } = await api.admin.reports.getMonthlyActiveUsers({ start, end });
        setData(data);
      } finally {
        setIsLoading(false);
      }
    };
    getReport();
    return getReport.cancel;
  }, [api, start, end]);

  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: 'Users',
          data: data.map((item) => item.count),
          backgroundColor: colors.primary25,
          borderColor: colors.primary,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }, [data]);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await api.admin.reportCSVs.getMonthlyActiveUsers({ start, end });
      const disposition = response.headers['content-disposition'];
      const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
      const filename = filenameMatch.groups['name'];
      const blob = new Blob([response.data], { type: 'text/csv' });

      saveAs(blob, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return <ReportLoader />;
  }
  if (!data || !data.length) {
    return <EmptyReport />;
  }
  return (
    <Report>
      <ChartArea>
        <Line
          data={chartData}
          options={{
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'month',
                },
              },

              y: {
                ticks: {
                  precision: 0,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },

              tooltip: {
                callbacks: {
                  title: ([tooltip]) => moment(tooltip.parsed.x).format('MMMM YYYY'),
                },
              },
            },
          }}
        />
      </ChartArea>
      <Table>
        <Table.Header>
          <Table.Column name="date" sort={{ column: 'date', direction: 'asc' }}>
            Date
          </Table.Column>
          <Table.Column width="14rem" align="right">
            Users
          </Table.Column>
        </Table.Header>
        {data.map((item) => (
          <Table.Row key={item.date}>
            <Table.Cell>{moment(item.date).format(dateFormats.compactDate)}</Table.Cell>
            <Table.Cell>{formatter.format(item.count)}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <DownloadArea>
        <Button isLoading={isDownloading} onClick={handleDownload}>
          Download CSV
        </Button>
      </DownloadArea>
    </Report>
  );
}
