import { Checkbox, Currency, DateTime, Dropdown, ExpenseLockIcon } from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ExpenseAmountWidget from '../ExpenseAmountWidget';
import expenseLockedReasons from '../expenseLockedReason';

const SelectionArea = styled.div`
  display: flex;
`;

function ExpenseAuditRow({ item, onDelete, onReimbursedStatusChange, selection, onSelectionChange }) {
  const { id, vendorName, lockStatusId, expenseCategory, unitAmount, unitCount, permissions, project, member, date } =
    item;
  const isLocked = lockStatusId !== 'unlocked';
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();

  const handleOpenItem = (item, readOnly = false) => {
    history.push({
      pathname: readOnly || isLocked ? `${url}/item/${item.id}/view` : `${url}/item/${item.id}/edit`,
      search: location.search,
      state: { scrollToTop: false },
    });
  };

  const checked = !!selection.find((s) => s.id === item.id);

  const handleSelectionChange = () =>
    onSelectionChange(
      checked
        ? selection.filter((s) => s.id !== item.id)
        : [...selection, { id: item.id, isReimbursable: item.isReimbursable, isReimbursed: item.isReimbursed }],
    );

  return (
    <>
      <Table.BoxRow key={id} onClick={() => handleOpenItem(item)}>
        <Table.Cell onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={checked} onChange={handleSelectionChange} />
        </Table.Cell>
        <Table.Cell>
          <SelectionArea>
            {isLocked && <ExpenseLockIcon value={lockStatusId} message={expenseLockedReasons[lockStatusId]} />}
          </SelectionArea>
        </Table.Cell>
        <Table.Cell>
          <DateTime value={date} />
        </Table.Cell>
        <Table.Cell>{member?.name}</Table.Cell>
        <Table.Cell>
          <Table.CellContent>
            {expenseCategory.name}
            {vendorName && !expenseCategory.unitName && <small>{vendorName}</small>}
            {expenseCategory.unitName && (
              <small>
                {unitCount} {expenseCategory.unitName}(s) x <Currency value={unitAmount} maximumFractionDigits={4} />{' '}
                per {expenseCategory.unitName}
              </small>
            )}
          </Table.CellContent>
        </Table.Cell>
        <Table.Cell>
          {project && (
            <Table.CellContent>
              {project.name}
              <small>{project.client.name}</small>
            </Table.CellContent>
          )}
        </Table.Cell>
        <Table.Cell>
          <ExpenseAmountWidget item={item} />
        </Table.Cell>
        <TableBoxRowActions>
          {isLocked ? (
            <TableBoxRowActions.View onClick={() => handleOpenItem(item, true)} />
          ) : (
            <TableBoxRowActions.Edit onClick={() => handleOpenItem(item)} />
          )}

          <hr />
          <TableBoxRowActions.Dropdown>
            {({ setIsOpen }) => {
              const handleAction = (action) => setIsOpen(false) || action;

              return (
                <>
                  <Dropdown.Item onClick={() => handleOpenItem(item, true)}>View</Dropdown.Item>
                  <Dropdown.Item
                    disabled={isLocked}
                    tooltip={isLocked ? expenseLockedReasons[lockStatusId] : undefined}
                    onClick={() => handleOpenItem(item)}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={isLocked}
                    tooltip={isLocked ? expenseLockedReasons[lockStatusId] : undefined}
                    onClick={() =>
                      handleAction(
                        onDelete({
                          name: item.expenseCategory.name,
                          typeName: 'expense item',
                          expenseItemId: item.id,
                        }),
                      )
                    }>
                    Delete
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={!permissions.reimburse || !item.isReimbursable}
                    tooltip={
                      !permissions.reimburse
                        ? 'You do not have administrative privileges to set the reimbursed status.'
                        : !item.isReimbursable
                          ? 'Expense item is not set as reimbursable.'
                          : undefined
                    }
                    onClick={() => handleAction(onReimbursedStatusChange(item, !item.isReimbursed))}>
                    {item.isReimbursed ? 'Set as Not Reimbursed' : 'Set as Reimbursed'}
                  </Dropdown.Item>
                </>
              );
            }}
          </TableBoxRowActions.Dropdown>
        </TableBoxRowActions>
      </Table.BoxRow>
    </>
  );
}

export default ExpenseAuditRow;
