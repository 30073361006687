import { Button, Buttons, CancelButton, Currency, ModalCard } from '~/components';
import React from 'react';

export default function RecognizeRemainingRevenueConfirmation({
  currency,
  billingScheduleRevene,
  revenueRecognizedToDate,
  remainingRevenue,
  onConfirm,
  onClose,
}) {
  return (
    <ModalCard title="Recognize Remaining Revenue" onClose={onClose}>
      <ModalCard.Body>
        This project has a total scheduled revenue of <Currency value={billingScheduleRevene} currency={currency} /> but
        only <Currency value={revenueRecognizedToDate} currency={currency} /> has been recognized. Would you like a{' '}
        <Currency value={remainingRevenue} currency={currency} /> entry added to this project's revenue recognition
        ledger?
      </ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => onConfirm('no')}>No</CancelButton>
          <Button onClick={() => onConfirm('yes')}>Yes</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
