import { Button, Buttons, CancelButton, ModalCard } from '~/components';
import pluralize from 'pluralize';
import React, { useState } from 'react';

export default function SetToApprovedConfirmation({ count, onResolve }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onResolve(null);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await onResolve(true);
  };

  return (
    <ModalCard title="Set Time to Approved" onClose={handleClose}>
      <ModalCard.Body>
        This action will bypass the approval workflow and set {count} time {pluralize('entry', count)} to Approved.
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button isLoading={isLoading} onClick={handleSubmit}>
            Set {count} time {pluralize('entry', count)} to Approved
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
