import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';
import Dropdown from './Dropdown';
import Icon from './Icon';

const SplitButton = styled.div`
  display: flex;

  > ${Button}, > .button {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .icon {
        font-size: 0.8rem;
        margin-left: 0.2rem;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: 0.1rem;

      .icon {
        font-size: 0.8rem;
        margin-left: 0.2rem;
      }
    }
  }

  ${Dropdown.Trigger} > ${Button} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0.1rem;
    padding: 0.5rem 0;
    width: 2.5rem;
    background-color: ${colors.primary10};
    color: ${colors.primary};

    .icon {
      font-size: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

function SplitButtonMenu({ children, disabled, ...props }) {
  return (
    <Dropdown align="right" {...props}>
      {({ setIsOpen, ...props }) => (
        <>
          <Dropdown.Trigger>
            <Button
              disabled={disabled}
              onClick={(event) => {
                event.stopPropagation();
                if (children) {
                  setIsOpen(true);
                }
              }}>
              {children && <Icon icon="chevron-down" />}
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Menu {...props}>
            {typeof children === 'function' ? children({ setIsOpen }) : children}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
}

SplitButton.Menu = SplitButtonMenu;
SplitButton.Item = Dropdown.Item;
SplitButton.Link = Dropdown.Link;
SplitButton.DeleteItem = Dropdown.DeleteItem;

export default SplitButton;
