import { useFeatures } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import TimeApprovalSummaryByMember from './TimeApprovalSummaryByMember';
import TimeAttachments from './TimeAttachments';
import TimeDetail from './TimeDetail';
import TimeOffByMember from './TimeOffByMember';
import TimeReportsPage from './TimeReportsPage';
import TimeSummaryByClient from './TimeSummaryByClient';
import TimeSummaryByClientAndMember from './TimeSummaryByClientAndMember';
import TimeSummaryByClientAndProject from './TimeSummaryByClientAndProject';
import TimeSummaryByMember from './TimeSummaryByMember';
import TimeSummaryByMemberAndClient from './TimeSummaryByMemberAndClient';
import TimeSummaryByMemberAndProject from './TimeSummaryByMemberAndProject';
import TimeSummaryByMemberPractice from './TimeSummaryByMemberPractice';
import TimeSummaryByProject from './TimeSummaryByProject';
import TimeSummaryByProjectAndMember from './TimeSummaryByProjectAndMember';
import TimeSummaryByProjectAndRole from './TimeSummaryByProjectAndRole';
import TimeSummaryByProjectAndTask from './TimeSummaryByProjectAndTask';
import TimeSummaryByProjectByTimeUnit from './TimeSummaryByProjectByTimeUnit';
import TimeSummaryByProjectType from './TimeSummaryByProjectType';
import UnsubmittedTimesheets from './UnsubmittedTimesheets';
import TimeEntriesPendingApproval from './TimeEntriesPendingApproval';
import TimeSummaryByClientByTimeUnit from './TimeSummaryByClientByTimeUnit';

function TimeReportsArea() {
  const { path, url } = useRouteMatch();
  const features = useFeatures();
  const { search: searchParams } = useLocation();

  return (
    <Switch>
      <Route path={path} exact>
        <TimeReportsPage />
      </Route>

      <Route path={path.concat('/time-summary-by-project')}>
        <TimeSummaryByProject />
      </Route>

      <Route path={path.concat('/time-summary-by-project-type')}>
        <TimeSummaryByProjectType />
      </Route>

      <Route path={path.concat('/time-summary-by-client')}>
        <TimeSummaryByClient />
      </Route>

      <Route path={path.concat('/time-summary-by-client-and-member')}>
        <TimeSummaryByClientAndMember />
      </Route>

      <Route path={path.concat('/time-summary-by-client-and-project')}>
        <TimeSummaryByClientAndProject />
      </Route>

      <Route path={path.concat('/time-summary-by-client-by-time-unit')}>
        <TimeSummaryByClientByTimeUnit />
      </Route>

      <Route path={path.concat('/time-summary-by-member')}>
        <TimeSummaryByMember />
      </Route>

      {features.practices && (
        <Route path={path.concat('/time-summary-by-member-practice')}>
          <TimeSummaryByMemberPractice />
        </Route>
      )}

      <Route path={path.concat('/time-approval-summary-by-member')}>
        <TimeApprovalSummaryByMember />
      </Route>

      <Route path={path.concat('/time-summary-by-member-and-client')}>
        <TimeSummaryByMemberAndClient />
      </Route>

      <Route path={path.concat('/time-summary-by-member-and-project')}>
        <TimeSummaryByMemberAndProject />
      </Route>

      <Route path={path.concat('/time-summary-by-project-and-member')}>
        <TimeSummaryByProjectAndMember />
      </Route>

      <Route path={path.concat('/time-summary-by-project-and-task')}>
        <TimeSummaryByProjectAndTask />
      </Route>

      <Route path={path.concat('/time-summary-by-project-and-role')}>
        <TimeSummaryByProjectAndRole />
      </Route>

      <Route path={path.concat('/time-summary-by-project-by-time-unit')}>
        <TimeSummaryByProjectByTimeUnit />
      </Route>

      <Route path={path.concat('/time-off-by-member')}>
        <TimeOffByMember />
      </Route>

      <Route path={path.concat('/time-entries')}>
        <TimeDetail />
      </Route>
      <Redirect path={path.concat('/time-detail')} to={path.concat(`/time-entries${searchParams}`)} />

      <Route path={path.concat('/time-entries-pending-approval')}>
        <TimeEntriesPendingApproval />
      </Route>

      {features.timesheets && (
        <Route path={path.concat('/unsubmitted-timesheets')}>
          <UnsubmittedTimesheets />
        </Route>
      )}

      {features.timeAttachments && (
        <Route path={path.concat('/time-attachments')}>
          <TimeAttachments />
        </Route>
      )}

      <Redirect from="/" to={url} />
    </Switch>
  );
}

export default TimeReportsArea;
