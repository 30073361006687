import React from 'react';
import { usePercentFormat } from '~/hooks';
import { Tooltip } from '~/components';
import _ from 'lodash';

export default function MarginTooltip({ value, children }) {
  const percentFormat = usePercentFormat();

  if (!_.isNumber(value)) return children;

  const percent = percentFormat.format(Math.abs(value));

  let message;
  if (value === 0) message = 'On budget.';
  else if (value < 0) message = `${percent} above budget.`;
  else if (value > 0) message = `${percent} under budget.`;

  return (
    <Tooltip placement="top" message={message}>
      {children}
    </Tooltip>
  );
}
