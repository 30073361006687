import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '~/components';
import { useApi, useSession, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { Footer, Header, ResponsiveContainer } from '../components';

const Container = styled(ResponsiveContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const LoadingIcon = styled(Icon)`
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  font-size: 2rem;
`;

export default function LoginToken() {
  useDocumentTitle('Login');

  const api = useApi();
  const { isReady, identify } = useSession();
  const history = useHistory();
  const location = useLocation();
  const { navigateWorkspace } = useWorkspace();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const params = new URLSearchParams(location.search);

    const token = params.get('token');
    if (!token) {
      history.push('/login/error');
      return;
    }

    (async () => {
      try {
        const {
          data: { workspaceKey },
        } = await api.www.useLoginToken(token);
        await identify();
        const redirect = params.get('redirect');
        if (redirect) {
          history.push(decodeURIComponent(redirect));
        } else {
          navigateWorkspace(workspaceKey);
        }
      } catch {
        history.push('/login/error');
      }
    })();
  }, [isReady, identify, api, history, location.search, navigateWorkspace]);

  return (
    <>
      <Header />
      <Container>
        <Title>Login</Title>
        <Byline>We're logging you in, please wait.</Byline>
        <LoadingIcon icon="spinner" spin />
      </Container>
      <Footer />
    </>
  );
}
