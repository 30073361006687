import classNames from 'classnames';
import { useToast } from '~/contexts';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { imageFileTypes, imagePreviewFileTypes } from '~/utils/fileTypes';
import Avatar from './Avatar';
import Button from './Button';
import FieldControl from './FieldControl';
import Icon from './Icon';

const Camera = styled.div`
  position: absolute;
  bottom: -1rem;
  color: ${colors.primary};
  background-color: white;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
`;

const Container = styled.div`
  display: flex;

  label {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    justify-content: center;

    &:hover ${Camera} {
      box-shadow: 0px 5px 15px 0px ${colors.grey25};
    }
  }

  input[type='file'] {
    display: none;
  }

  button {
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
    font-size: 0.6rem;
  }

  figure {
    width: 5.625rem;
    height: 5.625rem;

    span {
      font-size: 2rem;
    }
  }

  &.square {
    label {
      border: 1px solid ${colors.grey25};
      border-radius: 4px;
    }

    figure {
      padding: 1rem;
    }
  }

  .fa-image-slash {
    font-size: 4em;
    color: ${colors.white};
  }
`;

function AvatarFileInput({
  // eslint-disable-next-line no-unused-vars
  label,
  className = '',
  preview: { imageUrl, name = '', icon = null } = {},
  onChange,
  size = 90,
  variant = 'square',
  value,
  ...props
}) {
  const [preview, setPreview] = useState(!imageUrl && !icon ? null : { imageUrl, icon });
  const inputRef = useRef();
  const toast = useToast();

  const handleChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          if (imagePreviewFileTypes.includes(file.type)) {
            setPreview({ ...preview, imageUrl: reader.result });
          } else {
            setPreview({ imageUrl: null, icon: 'image-slash' });
          }
          onChange(file, reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('This control supports uploading JPEG, PNG, WebP, GIF, AVIF, TIFF and SVG images.');
      }
    }
  };

  const handleRemove = (event) => {
    event.preventDefault();
    onChange(null);
    setPreview(null);
    inputRef.current.value = null;
  };

  const variantProps =
    variant === 'square'
      ? {
          showButton: false,
          hasBackground: false,
          isCircle: false,
          hasBorder: false,
        }
      : {
          showButton: true,
          hasBackground: true,
          isCircle: true,
          hasBorder: true,
        };

  useEffect(() => {
    if (value === undefined) setPreview((v) => ({ ...v, imageUrl }));
  }, [value, imageUrl]);

  return (
    <Container className={classNames(className, variant)}>
      <label>
        <input ref={inputRef} type="file" onChange={handleChange} accept={imageFileTypes} {...props} />
        <Avatar
          value={{ name, imageUrl: preview?.imageUrl, icon: preview?.icon }}
          size={size}
          isCircle={variantProps.isCircle}
          hasBackground={variantProps.hasBackground}
          hasBorder={variantProps.hasBorder}
        />
        {(value || preview?.imageUrl) && (
          <Button isAnchor onClick={handleRemove}>
            <Icon icon="times" />
          </Button>
        )}
        {variantProps.showButton && (
          <Camera>
            <Icon icon="camera" />
          </Camera>
        )}
      </label>
    </Container>
  );
}

function FieldAvatarFileInput({ label, className = '', previewUrlFieldName, ...props }) {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = (file, previewUrl) => {
    formik.setFieldValue(field.name, file);
    if (previewUrlFieldName) {
      formik.setFieldValue(previewUrlFieldName, previewUrl);
    }
    if (error) formik.validateField(field.name);
  };

  return (
    <FieldControl {...{ label, error, className }}>
      <AvatarFileInput {...field} onChange={handleChange} {...props} />
    </FieldControl>
  );
}
export default AvatarFileInput;
export { FieldAvatarFileInput };
