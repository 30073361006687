import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { mergeValues } from '~/utils';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function ProjectRequiredModal({ integration, onClose, onSave }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = mergeValues(
    {
      requireProject: true,
    },
    integration.settings,
  );

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).expensify.updateProjectRequired(values);
      onSave();
      onClose();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <ModalCard title="Project Required" onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          requireProject: Yup.bool().required(),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
                <Description>
                  If project is required, only expenses that match a valid project will be imported. Should project be
                  required?
                </Description>
                <Form.Control>
                  <Field.RadioGroup name="requireProject">
                    <Radio value={true} label="Project is required" />
                    <Radio value={false} label="Project is NOT required" />
                  </Field.RadioGroup>
                </Form.Control>
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                    Close
                  </CancelButton>
                  <Button type="submit" isLoading={isLoading}>
                    Save & Close
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
