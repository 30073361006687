import {
  Avatar,
  ClientLink,
  Confirmation,
  DateTime,
  Dropdown,
  InternalClientTooltip,
  MemberContactPopover,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useSession, useWorkspace } from '~/contexts';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import ClientDeleteConfirmation from './ClientDeleteConfirmation';
import ClientCollapseConfirmation from './ClientCollapseConfirmation';

const ClientName = styled.span`
  display: flex;
  color: ${colors.black};
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.span`
  margin-left: 0.5rem;
`;

function ClientRow({ client, onSaved, onDeleted, onCollapse }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();

  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { isAdmin: isSysAdmin } = useSession();
  const confirmation = useConfirmation();

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <ClientDeleteConfirmation
        client={client}
        onClose={resolve}
        onDelete={() => {
          history.push({ pathname: `/app/${workspace.key}/clients`, search: location.search });
          if (onDeleted) onDeleted(client.id);
          resolve(true);
        }}
      />
    ));
  };

  const handleCollapse = () => {
    confirmation.prompt((resolve) => (
      <ClientCollapseConfirmation
        client={client}
        onClose={resolve}
        onCollapse={() => {
          history.push({ pathname: `/app/${workspace.key}/clients`, search: location.search });
          if (onCollapse) onCollapse(client.id);
          resolve(true);
        }}
      />
    ));
  };

  async function handleSetRecordStatus(recordStatusId) {
    try {
      const { data } = await api.www.workspaces(workspace.id).clients(client.id).setRecordStatus({ recordStatusId });
      await onSaved(data);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const clientUrl = `${url}/${client.key}`;

  const handleClientStatement = (client) => {
    history.push(
      `/app/${workspace.key}/reports/financial/client-statement?client=${client.id}&currency=${client.currency}`,
    );
  };

  const handleEdit = (client) => history.push({ pathname: `${url}/edit/${client.key}`, search: location.search });

  const handleView = () => history.push(clientUrl);

  return (
    <Table.BoxRow isDisabled={client.recordStatus.id === 'archived'} onClick={handleView}>
      <Table.Cell>
        <ClientName>
          <ClientLink client={client} onClick={(e) => e.stopPropagation()} />
          {client.isInternal && <InternalClientTooltip />}
        </ClientName>
      </Table.Cell>
      <Table.Cell style={{ wordBreak: 'break-word' }}>{client.code}</Table.Cell>
      <Table.Cell>
        {client.owner && (
          <Owner>
            <MemberContactPopover member={client.owner} placement="left">
              <Avatar value={client.owner} isCircle hasBackground initialsFontSize=".9rem" />
            </MemberContactPopover>
            <OwnerInfo>{client.owner.name}</OwnerInfo>
          </Owner>
        )}
      </Table.Cell>
      <Table.Cell>
        <DateTime value={client.createdAt} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.View onClick={handleView} />

        <hr />

        <TableBoxRowActions.Dropdown>
          {({ setIsOpen }) => {
            const handleAction = (action) => setIsOpen(false) || action;

            const handleArchive = async () => {
              setIsOpen(false);

              const confirm = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  Are you sure that you want to archive this client? This will also archive all projects within the
                  client.
                </Confirmation>
              ));

              if (!confirm) return;

              handleSetRecordStatus('archived');
            };

            const handleUnarchive = async () => {
              setIsOpen(false);

              const confirm = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  This action will unarchive the client. Note that this will not unarchive any projects within the
                  client. Projects must be unarchived separately.
                </Confirmation>
              ));

              if (!confirm) return;

              handleSetRecordStatus('active');
            };

            return (
              <>
                <Dropdown.Item onClick={() => handleAction(handleView())}>View</Dropdown.Item>

                <Dropdown.Item
                  disabled={!client.permissions.edit}
                  tooltip={!client.permissions.edit ? 'Insufficient permissions to edit this client.' : undefined}
                  onClick={() => handleEdit(client)}>
                  Edit
                </Dropdown.Item>

                {client.recordStatus.id === 'active' ? (
                  <Dropdown.Item
                    disabled={!client.permissions.delete || client.isInternal}
                    tooltip={
                      client.isInternal
                        ? `The workspace's internal client cannot be archived.`
                        : !client.permissions.delete
                          ? 'Insufficient permissions to archive this client.'
                          : undefined
                    }
                    onClick={handleArchive}>
                    Archive
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    disabled={!client.permissions.delete}
                    tooltip={
                      !client.permissions.delete ? 'Insufficient permissions to unarchive this client.' : undefined
                    }
                    onClick={handleUnarchive}>
                    Unarchive
                  </Dropdown.Item>
                )}

                {client.permissions.viewPublishedInvoices && !client.isInternal && (
                  <Dropdown.Item onClick={() => handleClientStatement(client)}>Client Statement</Dropdown.Item>
                )}

                <Dropdown.Item
                  disabled={client.isInternal || !client.permissions.delete}
                  tooltip={
                    client.isInternal
                      ? `The workspace's internal client cannot be deleted.`
                      : !client.permissions.delete
                        ? 'Insufficient permissions to delete this client.'
                        : undefined
                  }
                  onClick={handleDelete}>
                  Delete
                </Dropdown.Item>
                {isSysAdmin && (
                  <Dropdown.Item
                    disabled={!client.permissions.edit}
                    tooltip={!client.permissions.edit ? 'Insufficient permissions to edit this client.' : undefined}
                    onClick={() => handleCollapse(client)}>
                    Collapse
                  </Dropdown.Item>
                )}
              </>
            );
          }}
        </TableBoxRowActions.Dropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

export default ClientRow;
