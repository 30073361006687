import { FieldControl } from '~/components';
import { useField } from '~/hooks';
import React from 'react';
import CreditNoteProjectSelect from './CreditNoteProjectSelect';

export default function CreditNoteProjectSelectField(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <CreditNoteProjectSelect {...field} {...props} />
    </FieldControl>
  );
}
