import React, { useMemo, useState } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Icon, Tooltip } from '~/components';
import { colors, weights } from '~/styles';
import { getIconFromMIME } from '~/utils';
import InvoiceReceiptsDialog from './InvoiceReceiptsDialog';

const Page = styled.div`
  background-color: white;
  padding: 1.875rem 3.125rem;
  border: 1px solid ${colors.grey10};
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};

  @media print {
    box-shadow: none;
    border: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const ActionButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 100%;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

const ReceiptContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
  border-bottom: solid 1px ${colors.grey10};

  @media print {
    display: ${({ showImage }) => (showImage ? 'inherit' : 'none')};
    border: none;
  }

  &:first-child {
    border-top: solid 1px ${colors.grey10};

    @media print {
      border: none;
    }
  }
`;

const FileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
`;

const FileIcon = styled(Icon)`
  margin-bottom: 0.5rem;
  color: ${colors.grey55};
  font-size: 3rem;
`;

const FileNameInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  color: ${colors.grey55};
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const FileExt = styled.div`
  flex-shrink: 0;
`;

function Receipt({ receipt }) {
  const showImage = receipt.contentType?.includes('image') || false;

  const fileNameInfo = useMemo(() => {
    const fullName = receipt.fileName;
    const nameArray = fullName.split('.');
    if (nameArray.length < 2) {
      return {
        name: fullName,
        ext: '',
      };
    }
    const ext = '.' + nameArray.pop();
    const name = nameArray.join('.');
    return { name, ext };
  }, [receipt]);

  return (
    <ReceiptContainer showImage={showImage}>
      {showImage ? (
        <a href={receipt.imageUrl} target="_blank" rel="noopener noreferrer">
          <img src={receipt.imageUrl} alt={receipt.fileName} />
        </a>
      ) : (
        <a href={receipt.imageUrl} target="_blank" rel="noopener noreferrer">
          <FileIconContainer>
            <FileIcon icon={getIconFromMIME(receipt.contentType)} />
            <FileNameInfo>
              <FileName>{fileNameInfo.name}</FileName>
              <FileExt>{fileNameInfo.ext}</FileExt>
            </FileNameInfo>
          </FileIconContainer>
        </a>
      )}
    </ReceiptContainer>
  );
}

function InvoicePreviewReceipts({ receipts, invoice }) {
  const [receiptsDialogVisible, setReceiptsDialogVisible] = useState(false);

  return (
    <>
      <Page>
        <Header>
          <Title>Invoice Receipts - Invoice #{invoice.number}</Title>
          <Tooltip message="Download receipts ZIP.">
            <ActionButton onClick={() => setReceiptsDialogVisible((isVisible) => !isVisible)}>
              <Icon icon="file-zip" />
            </ActionButton>
          </Tooltip>
        </Header>
        <Container>
          {receipts.map((r) => (
            <Receipt key={r.id} receipt={r} />
          ))}
        </Container>
      </Page>

      {receiptsDialogVisible && (
        <InvoiceReceiptsDialog invoice={invoice} onClose={() => setReceiptsDialogVisible(false)} />
      )}
    </>
  );
}

export default InvoicePreviewReceipts;
