import React from 'react';
import { colors } from '~/styles';

function Logo({ color = colors.primary, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.901 24"
      aria-hidden="true"
      focusable="false"
      role="img"
      {...props}>
      <g transform="translate(-40.5 -442.391)">
        <path
          d="M67.029,470.736A5.685,5.685,0,0,0,68.4,460.327a12.224,12.224,0,0,0-3.576-1.23c-.186.405-.385.8-.594,1.179a12.229,12.229,0,0,1-2.48,3.229,5.832,5.832,0,0,1-2.6,1.4,3.827,3.827,0,0,1-.89.1l-.322-.018c.072.127.145.255.223.383a14.732,14.732,0,0,0,4.554,4.8A5.646,5.646,0,0,0,67.029,470.736Z"
          transform="translate(-4.84 -4.636)"
          fill={color}
        />
        <path
          d="M56.128,442.391a5.689,5.689,0,0,0-5.682,5.682,12.506,12.506,0,0,0,.741,3.709c.438-.04.874-.065,1.3-.073.1,0,.2,0,.306,0a11.954,11.954,0,0,1,3.731.535,5.771,5.771,0,0,1,2.592,1.644,3.912,3.912,0,0,1,.457.637l.138.28c.074-.126.149-.255.221-.387a14.724,14.724,0,0,0,1.878-6.342A5.688,5.688,0,0,0,56.128,442.391Z"
          transform="translate(-2.76)"
          fill={color}
        />
        <path
          d="M40.7,463.789a5.684,5.684,0,0,0,8.329,6.391,12.531,12.531,0,0,0,2.859-2.473c-.26-.367-.5-.739-.73-1.113a12.2,12.2,0,0,1-1.556-3.761,5.827,5.827,0,0,1,.089-2.955,3.811,3.811,0,0,1,.359-.822l.175-.264h-.188l-.258,0a14.74,14.74,0,0,0-6.432,1.545A5.647,5.647,0,0,0,40.7,463.789Z"
          transform="translate(0 -4.551)"
          fill={color}
        />
        <path
          d="M57.522,458.748A2.256,2.256,0,1,0,59.777,461,2.258,2.258,0,0,0,57.522,458.748Zm0,3.16a.9.9,0,1,1,.9-.9A.9.9,0,0,1,57.522,461.908Z"
          transform="translate(-4.098 -4.539)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default Logo;
