import { Currency, Icon, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { intervalOptions } from '~/utils';

const Rate = styled.div`
  font-size: 3.5rem;

  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.2;
    `}
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;

  .icon {
    margin-right: 0.5rem;
    color: ${colors.warning};
    font-size: 1rem;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const Footer = styled.div`
  margin-top: 1.5rem;
  border-top: 1px solid ${colors.grey10};
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0.75rem;
  position: relative;
`;

const Target = styled.div`
  position: absolute;
  top: -1rem;
  border-radius: 1rem;
  min-width: 9.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-align: center;
  color: ${colors.grey75};
  background-color: ${colors.grey10};

  ${({ status }) =>
    ({
      greener: css`
        color: ${colors.success};
        background-color: ${colors.success10};
      `,
      green: css`
        color: ${colors.success};
        background-color: ${colors.success10};
      `,
      yellow: css`
        color: ${colors.warning};
        background-color: ${colors.warning10};
      `,
      red: css`
        color: ${colors.danger};
        background-color: ${colors.danger10};
      `,
    })[status]}
`;

const Message = ({ children }) => (
  <Info>
    <Icon color={colors.warn} icon="exclamation-circle" />
    <div>{children}</div>
  </Info>
);

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function EffectiveRateWidget({ project, period }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const { url } = useRouteMatch();

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .getEffectiveRateKPI({ date: period.start ?? undefined });

    actions.ready({ data });
    // Intentionally passing the full "project" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, project, period, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const helpMessage = 'Effective rate is calculated by dividing earned services revenue by hours worked';

  const EditProjectLink = ({ children }) =>
    project.permissions.edit ? <Link to={`${url}/edit`}>{children}</Link> : children;

  const currency = project.currency;

  return (
    <Widget stretched centered loading={!isReady} style={{ height: '18.75rem' }}>
      <Widget.Header>
        {data && helpMessage && <Widget.Help message={helpMessage} />}
        <Widget.Title>Effective Rate</Widget.Title>
      </Widget.Header>
      <Widget.Content>
        {data && (
          <>
            <Rate blurred={data.errors.includes('missing_project_hours')}>
              {data.errors.includes('missing_project_hours') ? (
                'N/A'
              ) : (
                <Currency value={data.actual} currency={currency} />
              )}
            </Rate>

            {data.billingTypeId === 'tm' &&
              (data.errors.includes('missing_project_hours') ? (
                period.key === intervalOptions.all_time.key ? (
                  <Message>No time has been tracked</Message>
                ) : (
                  <Message>No time has been tracked for the selected period</Message>
                )
              ) : data.errors.includes('missing_bill_rates') ? (
                <Message>
                  One or more <EditProjectLink>bill rates</EditProjectLink> are missing
                </Message>
              ) : null)}

            {['fixed', 'fixed_recurring'].some((b) => data.billingTypeId === b) &&
              (data.errors.includes('missing_project_hours') ? (
                period.key === intervalOptions.all_time.key ? (
                  <Message>No time has been tracked</Message>
                ) : (
                  <Message>No time has been tracked for the selected period</Message>
                )
              ) : data.errors.includes('missing_project_revenue') ? (
                <Message>There is no services revenue</Message>
              ) : null)}
          </>
        )}
      </Widget.Content>

      {data?.budget != null && (
        <Footer>
          <Target status={data.status}>
            <BudgetLabel data={data} /> <Currency value={data.budget} currency={currency} />
          </Target>
        </Footer>
      )}
    </Widget>
  );
}

function BudgetLabel({ data }) {
  switch (data.status) {
    case 'greener':
      return 'Above budget of';

    case 'green':
      return 'On budget of';

    case 'yellow':
    case 'red':
      return 'Below budget of';

    default:
      return 'Budget of';
  }
}

export default EffectiveRateWidget;
