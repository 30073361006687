import { AutoComplete } from '~/components';
import { useApi, useWorkspace, useToast } from '~/contexts';
import { useAuth, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoCompleteMulti from './AutoCompleteMulti';
import Icon from './Icon';

function ProjectTaskTagMultiSelect({ allowNew, ...props }) {
  const auth = useAuth();
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).projectTaskTags().get({
        q,
        isActive: true,
        size: 1000,
      });

      return data;
    },
    [workspace.id, api],
  );

  const handleAddNew = async (value) => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projectTaskTags().upsert({ name: value });
      props.onChange({ target: { name: props.name, value: [...props.value, data] } });
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const filterOptions = (options) => options.filter((o) => !props.value.some((v) => v.id === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.name}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      {...props}>
      {({ options, inputValue, setInputValue, handleBlur, handleLoading }) => {
        const showAddNew =
          allowNew &&
          auth.workspace.manage &&
          inputValue &&
          !props.value?.map((v) => v.name).includes(inputValue) &&
          !options.some((option) => option.name === inputValue);

        return (
          <>
            {showAddNew && (
              <>
                <AutoComplete.AddNewOption
                  disabled={!inputValue}
                  onClick={async () => {
                    handleLoading(true);
                    await handleAddNew(inputValue);
                    setInputValue('');
                    handleBlur();
                    await refreshDefaultOptions();
                    handleLoading(false);
                  }}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Add {inputValue ? <strong>&nbsp;{inputValue}</strong> : ' New'}
                </AutoComplete.AddNewOption>
                {options.length > 0 && <AutoComplete.Separator />}
              </>
            )}

            <AutoComplete.Results showNoResults={!showAddNew} />
          </>
        );
      }}
    </AutoCompleteMulti>
  );
}

export default ProjectTaskTagMultiSelect;
