import styled from 'styled-components';

const CellHeatmap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
`;

export default CellHeatmap;
