import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ClientPaymentsListPage from './ClientPaymentsListPage';

export default function PaymentsArea({ children }) {
  const { path, url } = useRouteMatch();

  return (
    children || (
      <Switch>
        <Route path={`${path}`}>
          <ClientPaymentsListPage />
        </Route>

        <Redirect path="/" to={`${url}`} />
      </Switch>
    )
  );
}
