import { DeleteButton, TooltipButton } from '~/components';
import moment from 'moment';
import React, { useMemo } from 'react';
import UnsubmitTimesheetConfirmation from './UnsubmitTimesheetConfirmation';
import { useTimesheets } from './TimesheetContext';
import { useConfirmation } from '~/contexts';

export default function UnsubmitTimesheetButton({ date, member, onUnsubmitTimesheet }) {
  const timesheetContext = useTimesheets();

  const timesheetStatus = useMemo(() => {
    const start = moment(date).startOf('isoWeek');
    const end = moment(date).endOf('isoWeek');
    const isSubmitted = timesheetContext.isTimesheetOpen({
      start,
      end,
      isOpen: false,
    });
    if (!isSubmitted) return 'not_submitted';
    const periodTimesheets = timesheetContext.timesheets.filter(
      (ts) => moment(ts.start).isSameOrBefore(end) && moment(ts.end).isSameOrAfter(start),
    );
    if (periodTimesheets.some((t) => t.permissions.delete)) return 'enabled';
    return 'disabled';
  }, [timesheetContext, date]);

  const confirmation = useConfirmation();

  const handleWeekUnsubmitted = () => {
    onUnsubmitTimesheet();
    timesheetContext.fetchTimesheets();
  };

  const handleUnsubmit = async () => {
    await confirmation.prompt((resolve) => (
      <UnsubmitTimesheetConfirmation
        date={date}
        member={member}
        onUnsubmitTimesheet={handleWeekUnsubmitted}
        resolve={resolve}
        timesheetContext={timesheetContext}
      />
    ));
  };

  switch (timesheetStatus) {
    case 'not_submitted':
    case 'disabled':
      return null;
    case 'enabled':
      return (
        <TooltipButton component={DeleteButton} onClick={handleUnsubmit}>
          Unsubmit Timesheet
        </TooltipButton>
      );
  }
}
