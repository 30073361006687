import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, FormMessage, ModalCard, SingleSelect } from '~/components';
import { useApi } from '~/contexts';
import { useForm } from '~/hooks';
import { weights } from '~/styles';

const Name = styled.p`
  margin-bottom: 1rem;

  > span {
    font-weight: ${weights.bold};
  }
`;

export default function ChangeStatus({ workspace, onChange, onClose }) {
  const [{ isSubmitting, message, status }, form] = useForm();
  const [statusId, setStatusId] = useState(workspace.statusId);
  const api = useApi();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    try {
      await api.admin.workspaces.changeStatus(workspace.id, statusId);

      onChange();
      onClose();
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  return (
    <ModalCard title="Change Workspace Status" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        <Name>
          <span>{workspace.name}</span> ({workspace.key})
        </Name>
        <SingleSelect placeholder="Status" value={statusId} onChange={(event) => setStatusId(event.target.value)}>
          <option value="active">Active</option>
          <option value="restricted">Restricted</option>
          <option value="disabled">Disabled</option>
        </SingleSelect>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Save
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
