import { useApi, useWorkspace, useToast } from '~/contexts';
import { useAuth, useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';
import Icon from './Icon';

function CompanyTypeSelect({ value, initialValue, allowNew, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const auth = useAuth();
  const toast = useToast();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .companyTypes()
        .get({ q, isActive: true, include: initialValue ? initialValue.id : undefined });

      return data;
    },
    [workspace.id, initialValue, api],
  );

  const handleAddNew = async (value) => {
    try {
      const { data } = await api.www.workspaces(workspace.id).companyTypes().upsert({ name: value });

      props.onChange({ target: { name: props.name, value: data } });
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => option.name}
      onSearch={handleSearch}
      {...props}>
      {({ options, inputValue, handleBlur, handleLoading }) => {
        const showAddNew =
          allowNew &&
          auth.workspace.manage &&
          inputValue &&
          inputValue !== value?.name &&
          !options.some((option) => option.name === inputValue);

        return (
          <>
            {showAddNew && inputValue && inputValue !== value?.name && (
              <>
                <AutoComplete.AddNewOption
                  onClick={async () => {
                    handleLoading(true);
                    await handleAddNew(inputValue);
                    await refreshDefaultOptions();
                    handleBlur();
                    handleLoading(false);
                  }}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Add {inputValue ? <strong>&nbsp;{inputValue}</strong> : ' New'}
                </AutoComplete.AddNewOption>
                {options.length > 0 && <AutoComplete.Separator />}
              </>
            )}

            <AutoComplete.Results showNoResults={!showAddNew} noResultsMessage="No company types available" />
          </>
        );
      }}
    </AutoComplete>
  );
}

function FieldCompanyTypeSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <CompanyTypeSelect {...field} {...props} />
    </FieldControl>
  );
}

export default CompanyTypeSelect;
export { FieldCompanyTypeSelect };
