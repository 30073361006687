import { Formik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useWorkspace, useApi } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { mergeValues, emptyStringToNull } from '~/utils';

export default function SyncSettings({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      autoSync: false,
      syncMembers: true,
      syncTimeOff: true,
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Sync Settings"
      byline="BambooHR Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);
            await api.www.workspaces(workspace.id).bamboohr.updateSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              autoSync: Yup.bool()
                .label('Automatically Sync')
                .required()
                .test({
                  name: 'sync-one',
                  message: 'When automatically syncing, you must select at least one of members or time off requests',
                  test: (value, context) => !value || context.parent.syncMembers || context.parent.syncTimeOff,
                }),
              syncMembers: Yup.bool().label('Sync Members').required(),
              syncTimeOff: Yup.bool().label('Sync Time Off Requests').required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Automatically Sync"
                    subtitle="If turned on, every hour this will sync members and/or approved time off requests.">
                    <Form.Control>
                      <Field.RadioGroup name="autoSync" direction="vertical">
                        <Radio value={true} label="On - Automatically sync data every hour" />
                        <Radio value={false} label="Off - Do not sync periodically" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Sync Members"
                    subtitle="When turned on along with auto sync, members will be automatically synced every hour.">
                    <Form.Control>
                      <Field.RadioGroup name="syncMembers" direction="vertical">
                        <Radio value={true} label="On - Sync members when automatically syncing" />
                        <Radio value={false} label="Off - Do not sync members when automatically syncing" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Sync Time Off Requests"
                    subtitle="When turned on along with auto sync, approved time off requests will be automatically synced every hour.">
                    <Form.Control>
                      <Field.RadioGroup name="syncTimeOff" direction="vertical">
                        <Radio value={true} label="On - Sync time off requests when automatically syncing" />
                        <Radio value={false} label="Off - Do not sync time off requests when automatically syncing" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
