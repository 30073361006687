import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useApi, useWorkspace } from '~/contexts';

export default function useHeaderMetrics({ headers, resources, start, end, unit, allocations, onFetched }) {
  const viewed = useRef([]);

  const { workspace } = useWorkspace();
  const api = useApi();

  const fetchData = useCallback(
    async ({ start, end, unit, metric, resourceIds, allocationIds }) => {
      if (resourceIds.length === 0) return;

      const { data: metrics } = await api.www.workspaces(workspace.id).allocations().memberSchedule.metrics.headers({
        start,
        end,
        unit,
        metric,
        resourceIds,
        allocationIds,
      });

      if (metrics.length === 0) return;

      onFetched(metrics);
    },
    [api, workspace.id, onFetched],
  );

  const load = useCallback(
    ({ start, end, unit, allocations }) => {
      const headerIds = headers.map((h) => h.id);
      if (headerIds.length > 0) {
        const resourceIds = resources.filter((r) => !!r.resource).map((r) => r.id);
        const allocationIds = (allocations ?? []).map((a) => a.id);
        fetchData({ start, end, unit, allocationIds, resourceIds });
      }
      viewed.current = headerIds;
    },
    [headers, fetchData, resources],
  );

  const initialize = useCallback(() => {
    viewed.current = [];
  }, []);

  useEffect(() => {
    const visibleHeaders = headers.filter((r) => ['total', 'member', 'placeholder'].includes(r.id)).map((r) => r.id);
    const headerIds = visibleHeaders.filter((g) => !viewed.current.includes(g));
    if (headerIds.length === 0) return;
    viewed.current = viewed.current.concat(headerIds);
    const resourceIds = resources.filter((r) => !!r.resource).map((r) => r.id);
    const allocationIds = (allocations ?? []).map((a) => a.id);
    fetchData({ start, end, unit, allocationIds, resourceIds });
  }, [fetchData, start, end, unit, allocations, headers, resources]);

  return useMemo(() => ({ load, initialize }), [load, initialize]);
}
