import moment from 'moment';

export default function parseTime(time) {
  if (!time) {
    return 0;
  }

  const [rawHours, rawMinutes] = time.split(/[:.,]+/);

  let hours = rawHours ? parseInt(rawHours) : 0;
  let minutes = rawMinutes ? parseInt(rawMinutes) : 0;

  if (isNaN(hours) || isNaN(minutes)) {
    return NaN;
  }

  if (time.includes('.') || time.includes(',')) {
    minutes = Math.round(parseFloat(`0.${rawMinutes}`) * 60);
  }

  const duration = moment.duration().add(hours, 'hours').add(minutes, 'minutes');
  return duration.isValid() ? duration.asMinutes() : NaN;
}
