import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const elementOptions = {
  style: {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      color: colors.black,
    },
    invalid: {
      color: colors.error,
    },
  },
  disableLink: true,
};

const CardWrapper = styled.div`
  padding: 0.5em 0.875em;
  border: solid 1px ${({ hasFocus }) => (hasFocus ? colors.primary : colors.grey10)};
  border-radius: 0.3125rem;
`;

const ErrorMessage = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

export default function EditPaymentMethod({ onClose, onSaved }) {
  const api = useApi();
  const stripe = useStripe();
  const elements = useElements();
  const { workspace } = useWorkspace();
  const [hasFocus, setHasFocus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message ?? true);
      setIsSubmitting(false);
      return;
    }

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .stripe.updatePaymentMethod({ paymentMethodId: paymentMethod.id });

      onSaved(data);
    } catch (error) {
      setError(error.message ?? true);
      setIsSubmitting(false);
      return;
    }

    onClose();
  };

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  };

  return (
    <ModalCard title="Credit Card" onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalCard.Body>
          {error && (
            <ErrorMessage>
              {typeof error === 'string'
                ? error
                : 'There was a problem saving your credit card information. Please try again later.'}
            </ErrorMessage>
          )}
          <CardWrapper hasFocus={hasFocus}>
            <CardElement
              options={elementOptions}
              disabled={!stripe}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
              onReady={(element) => element.focus()}
            />
          </CardWrapper>
        </ModalCard.Body>
        <ModalCard.Footer>
          <Buttons align="right">
            <CancelButton disabled={isSubmitting} onClick={handleClose}>
              Cancel
            </CancelButton>
            <Button isLoading={isSubmitting} type="submit">
              Save
            </Button>
          </Buttons>
        </ModalCard.Footer>
      </form>
    </ModalCard>
  );
}
