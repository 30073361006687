import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Icon, ModalCard, Tooltip } from '~/components';
import { colors } from '~/styles';
import { subscribe, getStatus } from '~/version.js';

const VersionIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
`;

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
`;

export default function AppVersion() {
  const [status, setStatus] = useState(getStatus());
  const [showAlert, setShowAlert] = useState(status === 'noisy');

  useEffect(() => subscribe((newStatus) => setStatus(newStatus)), []);

  useEffect(() => {
    if (status === 'noisy') {
      setShowAlert(true);
    }
  }, [status]);

  const handleClose = () => setShowAlert(false);

  if (!status || !['quiet', 'noisy'].includes(status)) {
    return null;
  }
  return (
    <>
      <Tooltip
        Container={VersionIndicator}
        message="There is a new version of Ruddr available!"
        onClick={() => setShowAlert(true)}>
        <WarningIcon icon="exclamation-triangle" />
      </Tooltip>
      {showAlert && (
        <ModalCard
          title={
            <>
              <WarningIcon icon="exclamation-triangle" /> App Update
            </>
          }
          onClose={handleClose}>
          <ModalCard.Body>
            There is a new version of Ruddr available. In order to make sure you've got the latest and greatest, you'll
            need to reload this page.
          </ModalCard.Body>
          <ModalCard.Footer>
            <Buttons align="right">
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <ActionButton onClick={() => window.location.reload(true)}>Reload</ActionButton>
            </Buttons>
          </ModalCard.Footer>
        </ModalCard>
      )}
    </>
  );
}
