import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Hours, Percent, Tooltip, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const Container = styled.div`
  font-size: 3rem;

  sup {
    font-size: 1.5rem;
  }

  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.2;
    `}
`;

const TooltipContainer = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const TooltipLabel = styled.div`
  width: 7rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const TooltipValue = styled.div`
  white-space: wrap;
`;

function TooltipHours({ value }) {
  return (
    <>
      <Hours value={value} /> {_.isNumber(value) && pluralize('hours', value)}
    </>
  );
}

function Variance({ value }) {
  if (!value) return <Hours value={null} />;

  return (
    <Tooltip
      message={
        <TooltipContainer>
          <TooltipInfo>
            <TooltipLabel>Worked</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.worked} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Planned</TooltipLabel>
            <TooltipHours value={value.planned} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Variance</TooltipLabel>
            <TooltipValue style={{ color: value.variance < 0 ? colors.danger : colors.black }}>
              <TooltipHours value={value.variance} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Variance %</TooltipLabel>
            <TooltipValue style={{ color: value.variancePercentage < 0 ? colors.danger : colors.black }}>
              <Percent value={value.variancePercentage} />
            </TooltipValue>
          </TooltipInfo>
        </TooltipContainer>
      }>
      <span style={{ color: value.variance < 0 ? colors.danger : colors.black }}>
        <Hours value={value.variance} />
      </span>
    </Tooltip>
  );
}

function VarianceWidget({ start, end, memberId }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [query, setQuery] = useState({ isReady: false, data: null, error: null });

  const fetchData = useCallback(async () => {
    setQuery({ isReady: false });

    const { data } = await api.www.workspaces(workspace.id).personalDashboard().variance({ start, end, memberId });

    setQuery({ isReady: true, data });
  }, [workspace.id, api, start, end, memberId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!query.isReady}>
      <Widget.Header>
        <Widget.Help message="The variance between your worked hours and your allocated hours." />
        <Widget.Title>Variance</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '4.1875rem' }}></Widget.Preview>
      <Widget.Content>
        <Container blurred={query.data === null}>
          {query.data != null ? <Variance value={query.data} /> : 'N/A'}
        </Container>
      </Widget.Content>
    </Widget>
  );
}

export default VarianceWidget;
