import Big from 'big.js';
import classNames from 'classnames';
import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  Drawer,
  FormMessage,
  Hours,
  Icon,
  Table,
  Tooltip,
} from '~/components';
import { Header } from '~/components/table/TableBoxHeader';
import { Row as BoxRow } from '~/components/table/TableBoxRow';
import { Row } from '~/components/table/TableRow';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useCurrencyFormat, useDateTimeFormat, useDocumentTitle, useForm, useNumberFormat } from '~/hooks';
import _, { sumBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';

const P = styled.p`
  white-space: pre-wrap;
`;

const Small = styled.small`
  display: block;
  font-size: 0.75rem;
  color: ${colors.grey40};
  padding-top: 0.25rem;
`;

const Container = styled.div`
  font-size: 0.875rem;

  input {
    font-size: inherit;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  margin-bottom: 1rem;
`;

const GroupedTable = styled.div`
  ${Header} {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`;

const Group = styled.div`
  position: sticky;
  border: 1px solid ${colors.grey10};
  border-radius: 5px;
  background-color: white;
  z-index: 2;
  margin-top: 0.375rem;

  &:last-child {
    border-bottom-width: 1px;
    margin-bottom: 0;
  }

  ${BoxRow} {
    margin: 0;
    border-width: 0;
    position: sticky;
    top: 2.75rem;
    background-color: white;
    z-index: 2;
  }

  &.expanded ${BoxRow} {
    border-bottom-width: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:last-child {
      border-bottom: none;
    }
  }

  ${Row}:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const selectLine = (line, transactions) => {
  const newLine = {
    id: line.id,
    transactionType: line.transactionType,
    transactions,
    quantity: line.quantity,
    rate: line.rate,
    amount: line.amount,
  };

  if (line.transactionType && line.amount === line.transactionAmount) {
    switch (line.transactionType) {
      case 'time':
        if (line.quantity === sumBy(line.transactions, 'hours')) {
          newLine.quantity = sumBy(transactions, 'hours');
          newLine.amount = Big(newLine.quantity).times(newLine.rate).toNumber();
        }
        break;

      case 'expense':
      case 'milestone':
      case 'other_item':
        newLine.amount = sumBy(transactions, 'amount');
        break;
    }
  }

  return newLine;
};

export default function IssueCreditNoteDrawer({ invoiceId, onClose, onSaved }) {
  useDocumentTitle('Issue Credit Note');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ invoice, ...invoiceQuery }, setInvoice] = useState({ invoice: null, isReady: false });
  const [{ status, message, isSubmitting }, form] = useForm();
  const toast = useToast();

  const fetchData = useCallback(async () => {
    try {
      const { data: invoice } = await api.www.workspaces(workspace.id).creditNotes().issue.get(invoiceId);
      setInvoice({ invoice, isReady: true });
    } catch (error) {
      toast.error('An error occurred while loading the invoice.');
      setInvoice({ creditNote: null, isReady: true });
      onClose();
    }
  }, [workspace.id, invoiceId, api, toast, onClose]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    onClose();
  }

  const [selectedLines, setSelectedLines] = useState([]);

  const { checked, partial } = useMemo(() => {
    if (!invoice?.lines) return { selected: false, partial: false };

    return {
      checked: selectedLines.length > 0,
      partial: _.flatMap(selectedLines, 'transactions').length < _.flatMap(invoice.lines, 'transactions').length,
    };
  }, [selectedLines, invoice?.lines]);

  if (!invoiceQuery.isReady) return null;
  if (!invoice) return <ErrorPage publicSite={false} />;

  const currency = invoice.currency;

  const total = sumBy(selectedLines, 'amount');

  const handleSelectAllChange = () => {
    if (checked) {
      setSelectedLines([]);
    } else {
      setSelectedLines(invoice.lines.map((line) => selectLine(line, line.transactions)));
    }
  };

  return (
    <Drawer isOpen title="Issue Credit Note" width="70rem" onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => closeDrawer();

        const handleSubmit = async () => {
          try {
            form.submit();
            const body = { invoiceId, lines: selectedLines };
            const { data } = await api.www.workspaces(workspace.id).creditNotes().issue.post(body);
            form.save();
            closeDrawer();
            if (onSaved) onSaved(data);
          } catch (error) {
            form.error(error);
          }
        };

        return (
          <>
            <Container>
              {invoice.lines.length === 0 ? (
                'There are no invoice lines for the selected invoice.'
              ) : (
                <GroupedTable>
                  <Table>
                    <Table.BoxHeader>
                      <Table.Column width="2.5rem" />
                      <Table.Column width="2.5rem">
                        <StyledCheckbox>
                          <Checkbox checked={checked} partial={partial} onChange={handleSelectAllChange} />
                        </StyledCheckbox>
                      </Table.Column>
                      <Table.Column width="10rem">Item</Table.Column>
                      <Table.Column width="19.5rem">Details</Table.Column>
                      <Table.Column width="5rem" align="right">
                        QTY
                      </Table.Column>
                      <Table.Column width="7rem" align="right">
                        Rate
                      </Table.Column>
                      <Table.Column width="10rem" align="right">
                        Amount
                      </Table.Column>
                      <Table.Column width="4rem" align="right">
                        Tax
                      </Table.Column>
                    </Table.BoxHeader>

                    <Table.Body>
                      {invoice.lines.map((line) => {
                        return (
                          <LineRow
                            key={line.id}
                            currency={currency}
                            line={line}
                            selectedLines={selectedLines}
                            onChange={setSelectedLines}
                          />
                        );
                      })}
                    </Table.Body>
                  </Table>
                </GroupedTable>
              )}
            </Container>

            {status && <FormMessage.Error>{message}</FormMessage.Error>}

            <Drawer.Actions style={{ zIndex: 2 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <strong style={{ marginRight: '.5rem' }}>Total Credit:</strong>
                <Currency value={total} currency={currency} /> (open balance of{' '}
                <Currency currency={currency} value={invoice.balance} />)
              </div>

              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <Button type="submit" onClick={handleSubmit} isLoading={isSubmitting}>
                  Save &amp; Close
                </Button>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

const ToggleSwitch = styled.div`
  flex: 1;
  display: flex;
  min-width: 2rem;
  justify-content: center;
`;

const TransactionsTable = styled(Table)`
  div.table-cell {
    font-size: 0.75rem;
  }
`;

function LineRow({ currency, line, selectedLines, onChange }) {
  const quantityFormat = useNumberFormat({ minimumFractionDigits: 0 });
  const dateTimeFormat = useDateTimeFormat();

  const [expanded, setExpanded] = useState(false);
  const handleToggleClick = async () => {
    setExpanded(!expanded);
  };

  const selectedLine = selectedLines.find(({ id }) => id === line.id);
  const checked = !!selectedLine;
  const partial = line.transactions?.length > 0 && selectedLine?.transactions?.length < line.transactions?.length;
  const clickable = line.transactions?.length > 0;

  const handleChange = (transactions) => {
    let selection = selectedLines.filter(({ id }) => id !== line.id);

    if (!checked || transactions?.length > 0) {
      selection = [...selection, selectLine(line, transactions)];
    }

    onChange(selection);
  };

  return (
    <Group key={line.id} className={classNames({ expanded })}>
      <Table.BoxRow onClick={clickable ? handleToggleClick : undefined}>
        <Table.Cell flex="0" padding="1.5rem 0.25rem 1.5rem 0.75rem">
          {clickable && (
            <ToggleSwitch>
              <Icon color={colors.grey25} icon={expanded ? 'chevron-down' : 'chevron-right'} />
            </ToggleSwitch>
          )}
        </Table.Cell>

        <Table.Cell onClick={(event) => event.stopPropagation()}>
          <StyledCheckbox>
            <Checkbox
              checked={checked}
              partial={partial}
              onChange={() => handleChange(checked ? null : line.transactions)}
            />
          </StyledCheckbox>
        </Table.Cell>

        <Table.Cell>{line.item}</Table.Cell>

        <Table.Cell>
          <div>
            <P>{line.description}</P>
          </div>
        </Table.Cell>

        <Table.Cell align="right">
          <div>
            <span style={{ display: 'flex' }}>
              {line.quantity ? quantityFormat.format(line.quantity) : null}
              <LineQuantityOffTooltip line={line} />
            </span>
            {line.transactionType === 'time' &&
              selectedLine?.quantity > 0 &&
              line.quantity !== selectedLine.quantity && <Small>{quantityFormat.format(selectedLine.quantity)}</Small>}
          </div>
        </Table.Cell>

        <Table.Cell align="right">
          <Currency value={line.rate} useDash={false} maximumFractionDigits={7} currency={currency} />
        </Table.Cell>

        <Table.Cell align="right">
          <div>
            <span style={{ display: 'flex' }}>
              <Currency value={line.amount} useDash={false} currency={currency} />
              <LineAmountOffTooltip line={line} currency={currency} />
            </span>
            {selectedLine?.amount > 0 && line.amount !== selectedLine.amount && (
              <Small>
                <Currency value={checked ? selectedLine.amount : 0} useDash={false} currency={currency} />
              </Small>
            )}
          </div>
        </Table.Cell>

        <Table.Cell>{line.taxable && <Icon icon="check" color={colors.success} />}</Table.Cell>
      </Table.BoxRow>

      {clickable && expanded && (
        <TransactionsTable>
          <Table.Header style={{ display: 'none' }}>
            <Table.Column width="2.5rem" />
            <Table.Column width="2.5rem" align="center" />
            <Table.Column width="29.5rem" />
            <Table.Column width="5rem" align="right" />
            <Table.Column width="7rem" />
            <Table.Column width="10rem" align="right" />
            <Table.Column width="4rem" />
          </Table.Header>

          <Table.Body style={{ fontSize: '0.75rem' }}>
            {line.transactions.map((t) => {
              const checked = !!selectedLine?.transactions?.some(({ id }) => id === t.id);

              const handleTransactionChange = () => {
                handleChange(
                  checked
                    ? selectedLine.transactions.filter(({ id }) => id !== t.id)
                    : [...(selectedLine?.transactions ?? []), t],
                );
              };
              switch (line.transactionType) {
                case 'time':
                  return (
                    <Table.Row key={t.id}>
                      <Table.Cell />
                      <Table.Cell>
                        <StyledCheckbox style={{ marginLeft: '1.5rem' }}>
                          <Checkbox checked={checked} onChange={handleTransactionChange} />
                        </StyledCheckbox>
                      </Table.Cell>
                      <Table.Cell>
                        {_.compact([
                          dateTimeFormat.format(t.date),
                          t.project.name,
                          t.project.useRoles ? t.role?.name : null,
                          t.member?.name,
                          t.task?.name,
                        ]).join(' - ')}
                      </Table.Cell>
                      <Table.Cell>{quantityFormat.format(t.hours)}</Table.Cell>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell />
                    </Table.Row>
                  );

                case 'expense':
                  return (
                    <Table.Row key={t.id}>
                      <Table.Cell />
                      <Table.Cell>
                        <StyledCheckbox style={{ marginLeft: '1.5rem' }}>
                          <Checkbox checked={checked} onChange={handleTransactionChange} />
                        </StyledCheckbox>
                      </Table.Cell>
                      <Table.Cell>
                        {_.compact([dateTimeFormat.format(t.date), t.project.name, t.member?.name]).join(' - ')}
                      </Table.Cell>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell>
                        <Currency value={t.amount} useDash={false} currency={currency} />
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  );

                case 'milestone':
                case 'other_item':
                  return (
                    <Table.Row key={t.id}>
                      <Table.Cell />
                      <Table.Cell>
                        <StyledCheckbox style={{ marginLeft: '1.5rem' }}>
                          <Checkbox checked={checked} onChange={handleTransactionChange} />
                        </StyledCheckbox>
                      </Table.Cell>
                      <Table.Cell>
                        {_.compact([dateTimeFormat.format(t.date), t.project.name, t.details]).join(' - ')}
                      </Table.Cell>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell>
                        <Currency value={t.amount} useDash={false} currency={currency} />
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  );

                default:
                  return <Table.Row key={t.id} />;
              }
            })}
          </Table.Body>
        </TransactionsTable>
      )}
    </Group>
  );
}

const TooltipContainer = styled.div`
  flex: 0;
  align-self: center;
`;

const LineAmountOffTooltip = ({ line, currency, ...props }) => {
  const currencyFormat = useCurrencyFormat({ currency });

  if (!line.transactionType) return null;
  if ((line.transactionAmount || 0) === (line.amount || 0)) return null;

  return (
    <TooltipContainer {...props}>
      <Tooltip
        message={`This total doesn't match the total of ${currencyFormat.format(
          line.transactionAmount,
        )} represented by ${
          {
            time: 'time entries',
            milestone: 'the billing schedule',
            expense: 'expense items',
            other_item: 'other items',
          }[line.transactionType]
        }.`}>
        <Icon spaceLeft icon="exclamation-circle" color={colors.warning} />
      </Tooltip>
    </TooltipContainer>
  );
};

const LineQuantityOffTooltip = ({ line, ...props }) => {
  if (line.transactionType !== 'time') return null;

  const hours = sumBy(line.transactions, 'hours');

  if ((line.quantity || 0) === (hours || 0)) return null;

  return (
    <TooltipContainer {...props}>
      <Tooltip
        message={
          <>
            This total doesn't match the total of <Hours value={hours} minimumFractionDigits={0} />{' '}
            {pluralize('hour', hours)} represented by its time entries.
          </>
        }>
        <Icon spaceLeft icon="exclamation-circle" color={colors.warning} />
      </Tooltip>
    </TooltipContainer>
  );
};
