import React from 'react';
import _ from 'lodash';
import { useField } from '~/hooks';
import FieldControl from './FieldControl';
import TextInput from './TextInput';

const FieldText = React.forwardRef(({ hideErrorMessage, errorPlacement, onProcessErrors, ...props }, ref) => {
  const [field, meta] = useField(props);
  let error = meta.touched && meta.error;
  if (error && _.isFunction(onProcessErrors)) {
    error = onProcessErrors(error);
  }

  return (
    <FieldControl error={error} hideErrorMessage={hideErrorMessage} errorPlacement={errorPlacement}>
      <TextInput ref={ref} {...field} {...props} />
    </FieldControl>
  );
});

export default FieldText;
