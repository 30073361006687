import React from 'react';
import styled from 'styled-components';
import { Avatar, Icon } from '~/components';
import { colors, weights } from '~/styles';

const NoMember = styled.div`
  display: flex;
  align-items: center;
`;

const ImpersonationIcon = styled(Icon)`
  margin-right: 1rem;
  color: ${colors.grey55};
  font-size: 1.5rem;
`;

const ImpersonationLabel = styled.div`
  font-weight: ${weights.bold};
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  max-width: 32rem;
  flex-shrink: 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Logo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1.25rem;
`;

const WorkspaceImage = styled.img`
  max-height: 100%;
  object-fit: contain;
`;

const BuildingIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

export default function CurrentImpersonation({ member }) {
  if (!member) {
    return (
      <NoMember>
        <ImpersonationIcon icon="user-secret" />
        <ImpersonationLabel>Impersonation</ImpersonationLabel>
      </NoMember>
    );
  }
  return (
    <Member>
      <Avatar value={member} size={40} isCircle hasBackground />
      <Info>
        <Title>{member.name}</Title>
        <Subtitle>{member.email}</Subtitle>
      </Info>
      <Logo>
        {member.workspace.imageUrl ? (
          <WorkspaceImage src={member.workspace.imageUrl} alt={member.workspace.name} />
        ) : (
          <BuildingIcon icon="building" />
        )}
      </Logo>
      <Info>
        <Title>{member.workspace.name}</Title>
        <Subtitle>/app/{member.workspace.key}</Subtitle>
      </Info>
    </Member>
  );
}
