import { useEffect, useState } from 'react';

function DelayedContent({ delay = 500, children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return isVisible && children;
}

export default DelayedContent;
