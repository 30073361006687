const sizes = {
  mobileMax: '767px',
  tabletMin: '768px',
  tabletMax: '1365px',
  desktopMin: '1366px',
};

export default {
  mobile: `(max-width: ${sizes.mobileMax})`,
  tablet: `(min-width: ${sizes.tabletMin}) and (max-width: ${sizes.tabletMax})`,
  desktop: `(min-width: ${sizes.desktopMin})`,
  touch: `(max-width: ${sizes.tabletMax})`,
  tabletPlus: `(min-width: ${sizes.tabletMin})`,
};
