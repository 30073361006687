import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import Tag from './Tag';

export default function MemberDisciplineTag({ value, ...props }) {
  const features = useFeatures();

  if (_.isEmpty(value) || !features.disciplines) return null;

  return (
    <Tag label="Member Discipline" {...props}>
      {[value]
        .flat()
        .map((v) => v.name)
        .join('; ')}
    </Tag>
  );
}
