import _ from 'lodash';
import { del, get, post, patch } from './agent';

const salesforce = {
  public: {
    postRedirectUri: ({ uri }) => post('/salesforce/redirect-uri', { uri }),
  },
  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'salesforce']).compact().join('/');

    return {
      get: () => get(url),
      getSyncs: () => get(url.concat('/syncs')),
      getSyncLog: (id) => get(url.concat('/syncs/', id, '/log')),
      updateSyncSettings: (body) => patch(url.concat('/sync-settings'), body),
      manualSync: (body) => post(url.concat('/manual-sync'), body),
      setup: (body) => post(url, body),
      remove: () => del(url),
      getOpportunityTypes: () => get(url.concat('/opportunity-types')),
      getConfiguration: () => get(url.concat('/configuration')),
      syncData: () => get(url.concat('/sync-data')),
    };
  },
};

export default salesforce;
