import React from 'react';
import ClientRecordStatusTag from './ClientRecordStatusTag';
import ClientRelationshipOwnerTag from './ClientRelationshipOwnerTag';
import ClientSalesRepresentativeTag from './ClientSalesRepresentativeTag';
import ClientTag from './ClientTag';
import ClientTags from './ClientTags';
import ClientLocationTag from './ClientLocationTag';
import PracticeTag from './PracticeTag';
import ClientIndustryTag from './ClientIndustryTag';

function ClientTagsGroup({ filters, onChange }) {
  return (
    <>
      <ClientTag value={filters.clients} onRemove={() => onChange({ clients: [] })} />

      <ClientRecordStatusTag
        value={filters.clientRecordStatusId}
        onRemove={() => onChange({ clientRecordStatusId: null })}
      />

      <PracticeTag type="client" value={filters.clientPractices} onRemove={() => onChange({ clientPractices: [] })} />

      <ClientSalesRepresentativeTag
        type="client"
        value={filters.clientSalesRepresentatives}
        onRemove={() => onChange({ clientSalesRepresentatives: [] })}
      />

      <ClientRelationshipOwnerTag value={filters.clientOwners} onRemove={() => onChange({ clientOwners: [] })} />

      <ClientTags value={filters.clientTags} onRemove={() => onChange({ clientTags: [] })} />

      <ClientLocationTag value={filters.clientLocations} onRemove={() => onChange({ clientLocations: [] })} />

      <ClientIndustryTag value={filters.clientIndustries} onRemove={() => onChange({ clientIndustries: [] })} />
    </>
  );
}

export default ClientTagsGroup;
