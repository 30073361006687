import {
  BillableIcon,
  Currency,
  Dropdown,
  ExportDialog,
  HoursProgress,
  Level,
  Percent,
  Spinner,
  Table,
  SingleSelect,
  MemberSelect,
  Avatar,
  MemberContactPopover,
  Tag,
  Tooltip,
} from '~/components';
import moment from 'moment';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import ProjectTimeViewSelect from './ProjectTimeViewSelect';
import ReplaceRoleDialog from './ReplaceRoleDialog';
import styled from 'styled-components';
import { TableBoxRowActions } from '~/components/table';

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Container = styled.div`
  font-size: 1rem;
`;

const Members = styled.div`
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0.25rem;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const RoleName = styled.div`
  color: ${({ active }) => (active ? colors.grey100 : colors.grey40)};
`;

const MembersByRole = ({ role }) => {
  if (_.isEmpty(role.members)) return null;

  const membersLength = role.members.length;
  const trimmedMembers = membersLength > 5 ? role.members.slice(5) : null;
  const members = membersLength > 5 ? role.members.slice(0, 5) : role.members;

  return (
    <>
      <Members>
        {members.map((member) => (
          <Member key={member.id}>
            <MemberContactPopover member={member} placement="left">
              <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
            </MemberContactPopover>
          </Member>
        ))}
      </Members>
      {trimmedMembers && (
        <Tooltip
          placement="right"
          message={
            <Container>
              <Title>Assigned To</Title>
              {trimmedMembers.map((member) => (
                <Tag style={{ backgroundColor: colors.grey5 }} key={member.id}>
                  <small>{member.name}</small>
                </Tag>
              ))}
            </Container>
          }>
          <Tag
            style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default', marginLeft: '0.50rem' }}>
            <small>+{trimmedMembers.length}</small>
          </Tag>
        </Tooltip>
      )}
    </>
  );
};

function ProjectTimeByRole({ project, view, onViewChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({
    refreshCounter: 0,
    member: null,
    billableTypeId: null,
  });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .timeByRole({
          projectMemberId: params.member?.id,
          billableTypeId: params.billableTypeId ?? undefined,
        });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const handleDataChange = () => {
    setParams({ ...params, refreshCounter: params.refreshCounter + 1 });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .timeByRole(
            {
              projectMemberId: params.member?.id,
              billableTypeId: params.billableTypeId ?? undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Level style={{ marginTop: '2.5rem' }}>
        <Level.Item flex="0.3">
          <MemberSelect
            materialPlaceholder="Member"
            placeholder="All"
            materialAlwaysVisible
            value={params.member}
            onChange={({ target: { value } }) => handleFilter({ member: value })}
          />
        </Level.Item>

        <Level.Item flex="0.3">
          <SingleSelect
            materialPlaceholder="Billable"
            placeholder="All"
            materialAlwaysVisible
            showEmptyOption
            value={params.billableTypeId}
            onChange={({ target: { value } }) => handleFilter({ billableTypeId: value })}>
            <option value="billable">Yes</option>
            <option value="non_billable">No</option>
          </SingleSelect>
        </Level.Item>

        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_time_by_role.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_time_by_role.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>

        <ProjectTimeViewSelect project={project} value={view} onChange={onViewChange} />
      </Level>

      <Data data={data} project={project} params={params} isReady={isReady} onChange={handleDataChange} />
    </>
  );
}

function Data({ data, project, params, isReady, onChange }) {
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();

  if (!isReady) return <PageLoader />;

  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      member: params.member?.id,
      billableType: params.billableTypeId
        ? project.client.isInternal && params.billableTypeId === 'non_billable'
          ? 'internal'
          : params.billableTypeId
        : undefined,
      ...query,
    }).toString(true)}`;

  const url = {
    total: (projectRole) => timeDetail({ projectRole }),
  };

  const currency = project.currency;

  const handleReplace = async (role) => {
    return await confirmation.prompt((resolve) => (
      <ReplaceRoleDialog
        roleId={role.id}
        projectId={project.id}
        currency={project.currency}
        onClose={() => resolve()}
        onSaved={() => {
          onChange();
          resolve(true);
        }}
      />
    ));
  };

  return (
    <>
      <Table.Status style={{ marginTop: '2.5rem' }}>
        {!isReady && <Spinner />}
        <Table.Total value={records.length} />
      </Table.Status>

      <Table>
        <Table.Header sticky>
          <Table.Column width="2rem" />

          <Table.Column>Role</Table.Column>

          <Table.Column>Assigned To</Table.Column>

          <Table.Column width="12rem">Hours</Table.Column>

          <Table.Column align="right" width="7rem" isVisible={columns.realizationRate}>
            Realization Rate
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.budgetRevenue}>
            Budget Revenue
          </Table.Column>

          <Table.Column align="right" width="8rem" isVisible={columns.revenue}>
            Earned Revenue
          </Table.Column>

          <Table.Column align="right" width="7rem" isVisible={columns.budgetEffectiveRate}>
            Budget Effective Rate
          </Table.Column>

          <Table.Column align="right" width="7rem" isVisible={columns.effectiveRate}>
            Effective Rate
          </Table.Column>
          <Table.Column width="3.25rem"></Table.Column>
        </Table.Header>

        <Table.Body>
          {records.map((role) => {
            return (
              <Table.Row key={role.id} data-testid="row">
                <Table.Cell>
                  <BillableIcon value={role.isBillable} />
                </Table.Cell>

                <Table.Cell>
                  <RoleName active={role.isActive}>{role.name}</RoleName>
                </Table.Cell>

                <Table.Cell>
                  <MembersByRole role={role} />
                </Table.Cell>

                <Table.Cell>
                  <HoursProgress worked={role.totalHours} estimated={role.budgetTotalHours} to={url.total(role.id)} />
                </Table.Cell>

                <Table.Cell>
                  <Percent value={role.realizationRate} />
                </Table.Cell>

                <Table.Cell>
                  <Currency value={role.budgetTotalRevenue} currency={currency} />
                </Table.Cell>

                <Table.Cell>
                  <Currency value={role.revenue} currency={currency} />
                </Table.Cell>

                <Table.Cell>
                  <Currency value={role.budgetEffectiveRate} currency={currency} />
                </Table.Cell>

                <Table.Cell>
                  <Currency value={role.effectiveRate} currency={currency} />
                </Table.Cell>
                <TableBoxRowActions>
                  <TableBoxRowActions.Dropdown>
                    {({ setIsOpen }) => {
                      const handleAction = async (action) => {
                        setIsOpen(false);
                        await action();
                      };
                      return (
                        <Dropdown.Item onClick={() => handleAction(() => handleReplace(role))}>
                          Replace Role
                        </Dropdown.Item>
                      );
                    }}
                  </TableBoxRowActions.Dropdown>
                </TableBoxRowActions>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell />

            <Table.Cell>Total</Table.Cell>

            <Table.Cell />

            <Table.Cell>
              <HoursProgress worked={totals.totalHours} estimated={totals.budgetTotalHours} to={url.total()} />
            </Table.Cell>

            <Table.Cell>
              <Percent value={totals.realizationRate} />
            </Table.Cell>

            <Table.Cell>
              <Currency value={totals.budgetTotalRevenue} currency={currency} />
            </Table.Cell>

            <Table.Cell>
              <Currency value={totals.revenue} currency={currency} />
            </Table.Cell>

            <Table.Cell>
              <Currency value={totals.budgetEffectiveRate} currency={currency} />
            </Table.Cell>

            <Table.Cell>
              <Currency value={totals.effectiveRate} currency={currency} />
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

export default ProjectTimeByRole;
