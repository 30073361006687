import { Hours, Tooltip, Percent } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import RouteLink from './RouteLink';

const ProgressContainer = styled.div`
  font-size: 0.75rem;
  flex: 1;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
`;

const Legend = styled.legend`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
`;

const Worked = styled.span`
  color: ${({ color }) => color};
`;

const Budget = styled.span`
  color: ${colors.grey25};
`;

function HoursProgress({ worked = 0, estimated = null, to = null }) {
  const percent = (estimated && estimated > 0 ? worked / estimated : worked > 0 ? 1 : 0) * 100;

  let statusColor = worked > 0 ? colors.primary : colors.grey25;

  if (estimated > 0) {
    if (percent > 100) statusColor = colors.danger;
    else if (percent > 80) statusColor = colors.warning;
  }

  return (
    <ProgressContainer data-testid="hours_bar">
      <Tooltip
        message={
          estimated > 0 && (
            <ul>
              <li>
                <Percent value={percent / 100} /> of budget used.
              </li>
              <li>
                <Percent value={1 - percent / 100} /> of budget left.
              </li>
            </ul>
          )
        }>
        <Legend>
          <Worked color={statusColor}>
            {to ? (
              <RouteLink to={to}>
                <Hours value={worked} />
              </RouteLink>
            ) : (
              <Hours value={worked} />
            )}
          </Worked>

          {estimated > 0 && (
            <Budget>
              <Hours value={estimated} />
            </Budget>
          )}
        </Legend>

        <Progress>
          <ProgressLine width={`${Math.min(percent, 100)}%`} color={statusColor} />
        </Progress>
      </Tooltip>
    </ProgressContainer>
  );
}

export default HoursProgress;
