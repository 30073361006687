const projectStatuses = {
  tentative: { id: 'tentative', name: 'Tentative' },
  not_started: { id: 'not_started', name: 'Not Started' },
  in_progress: { id: 'in_progress', name: 'In Progress' },
  paused: { id: 'paused', name: 'Paused' },
  completed: { id: 'completed', name: 'Completed' },
  cancelled: { id: 'cancelled', name: 'Cancelled' },
};

export default projectStatuses;
