import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, ModalCard, FormMessage, Checkbox } from '~/components';
import { useRating } from '~/contexts';

const P = styled.p`
  margin-bottom: 2rem;
`;

const ReviewButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
`;

function ReviewModal({ ratingId, onClose }) {
  const [reviewed, setReviewed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const { submitReview } = useRating();

  const handleSubmit = async () => {
    if (!reviewed) {
      onClose();
      return;
    }

    setIsSubmitting(true);
    const { success, message } = await submitReview(ratingId);

    if (success) {
      onClose();
    } else {
      setError(message || 'An error has occurred.');
    }

    setIsSubmitting(false);
  };

  const handleClose = () => {
    onClose();
  };

  if (!ratingId) return null;

  return (
    <ModalCard title="Review Ruddr" onClose={handleClose}>
      <ModalCard.Body>
        <P>
          We love to get feedback from our users! Receive $50 by providing a review of Ruddr on G2 and Capterra via the
          buttons below.
        </P>

        <ReviewButtons>
          <Button
            onClick={() =>
              window.open(
                'https://www.g2.com/contributor/ruddr?secure%5Bpage_id%5D=ruddr&secure%5Brewards%5D=true&secure%5Btoken%5D=7747f8ada4420fe1718d28f2193c7f4f99367a0529eef49329457af63fccd54e',
                '_blank',
              )
            }>
            Review Ruddr on G2
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://reviews.capterra.com/products/new/424a38ce-9b2a-4668-86c0-4a172e5bc678/d703ac7c-d663-4184-a1fc-b8aeb88aa7e9?lang=en',
                '_blank',
              )
            }>
            Review Ruddr on Capterra
          </Button>
        </ReviewButtons>

        <Checkbox
          checked={reviewed}
          onChange={(e) => setReviewed(e.target.checked)}
          label="I have already reviewed Ruddr on G2 and Capterra."
        />

        {error && <FormMessage.Error>{error}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton data-testid="submit_review" onClick={handleSubmit} isLoading={isSubmitting}>
            Close
          </CancelButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default ReviewModal;
