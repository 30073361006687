import moment from 'moment';
import React, { useMemo } from 'react';
import theme from '../theme';
import { BackgroundCanvasColumn } from './CanvasStyles';

function DayBackgroundCanvas({ start, end, dateWidth }) {
  const dates = useMemo(() => {
    const dayCount = moment(end).diff(start, 'days') + 1;

    const dates = [];

    for (let index = 0; index < dayCount; index++) {
      let date = moment(start).add(index, 'days');

      dates.push({
        style: {
          left: dateWidth * index,
          width: dateWidth,
          borderColor: index % 7 === 6 ? theme.line.strong : theme.line.soft,
        },
        today: moment().isSame(date, 'day'),
      });
    }

    return dates;
  }, [start, end, dateWidth]);

  return dates.map((date, index) => {
    return <BackgroundCanvasColumn key={index} style={date.style} index={index} today={date.today} />;
  });
}

export default React.memo(DayBackgroundCanvas);
