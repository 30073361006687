import { Button } from '~/components';
import { useConfirmation } from '~/contexts';
import moment from 'moment';
import React, { useMemo } from 'react';
import SubmitTimesheetConfirmation from './SubmitTimesheetConfirmation';
import { useTimesheets } from './TimesheetContext';

export default function SubmitTimesheetButton({ date, member, onSubmit }) {
  const timesheetContext = useTimesheets();

  const isTimesheetOpen = useMemo(() => {
    return timesheetContext.isTimesheetOpen({
      start: moment(date).startOf('isoWeek'),
      end: moment(date).endOf('isoWeek'),
    });
  }, [timesheetContext, date]);

  const confirmation = useConfirmation();

  const handleWeekSubmitted = () => {
    onSubmit();
    timesheetContext.fetchTimesheets();
  };

  const handleSubmit = async () => {
    await confirmation.prompt((resolve) => (
      <SubmitTimesheetConfirmation
        date={date}
        member={member}
        onSubmit={handleWeekSubmitted}
        resolve={resolve}
        timesheetContext={timesheetContext}
      />
    ));
  };

  if (!isTimesheetOpen) return null;

  return <Button onClick={handleSubmit}>Submit Timesheet</Button>;
}
