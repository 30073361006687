import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useForm } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import { FieldAssignProjectSelect } from './AssignProjectProjectSelect';
import AssignProjectRoleMultiSelect from './AssignProjectRoleMultiSelect';

export default function AssignProjectModal({ member, onClose, onSaved }) {
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleSubmit = async (values) => {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['project', 'roles']),
        projectId: values.project.id,
        roleIds: values.roles.map((role) => role.id),
      });

      const { data } = await api.www.workspaces(workspace.id).members(member.id).projects.assign(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  const initialValues = {
    project: null,
    isBillable: true,
    rate: '',
    roles: [],
  };

  return (
    <ModalCard title="Assign Project" onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          project: Yup.mixed().label('Project').required(),
          isBillable: Yup.bool(),
          rate: Yup.number().label('Rate').min(0).nullable(),
        })}>
        {(formik) => {
          const handleProjectChange = ({ target: { value } }) => {
            // Reset rate / isBillable / roles
            formik.setValues({
              ...formik.values,
              project: value,
              isBillable: value?.isBillable ?? true,
              rate: '',
              roles: [],
            });
          };

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <FieldAssignProjectSelect
                    name="project"
                    placeholder="Project"
                    memberId={member.id}
                    onChange={handleProjectChange}
                  />
                </Form.Control>

                {formik.values.project?.isBillable && !formik.values.project.useRoles && (
                  <>
                    <Form.Control style={{ alignItems: 'center' }}>
                      <Field.Checkbox name="isBillable" label="Billable" />
                      <Field.Currency
                        name="rate"
                        placeholder="Rate"
                        precision={7}
                        disabled={!formik.values.isBillable}
                        currency={formik.values.project.currency}
                      />
                    </Form.Control>
                  </>
                )}

                {formik.values.project?.useRoles && (
                  <>
                    <Form.Control>
                      <AssignProjectRoleMultiSelect
                        placeholder="Roles"
                        project={formik.values.project}
                        value={formik.values.roles}
                        onChange={({ target: { value } }) => formik.setFieldValue('roles', value)}
                      />
                    </Form.Control>
                  </>
                )}

                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose}>Close</CancelButton>
                  <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                    Assign Project
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
