import {
  BillableIcon,
  Button,
  DeleteConfirmation,
  Dropdown,
  ExportDialog,
  Hours,
  Level,
  SearchInput,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { mimeTypes } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import { CustomDataHeader } from '../custom-data/components';
import ProjectTaskTemplateDrawer from './ProjectTaskTemplateDrawer';

const StyledNotes = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  white-space: nowrap;
`;

function ProjectTaskTemplatesPage() {
  const documentTitle = useDocumentTitle('Task Templates');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projectTaskTemplates().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .projectTaskTemplates()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (template) => {
    setDialog(
      <ProjectTaskTemplateDrawer
        template={template}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Task Templates');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  const handleDelete = async (template) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Task Template?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .projectTaskTemplates(template ? template.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Task Templates</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Task Templates allow standard tasks to be easily added to projects.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Button isStrong onClick={() => handleEdit({})}>
            New Task Template
          </Button>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Name"
            materialAlwaysVisible
            onChange={handleFilterChange}
          />
        </Level.Item>
        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('task_templates.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('task_templates.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Template" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column width="3.5rem" />
          <Table.Column width="20rem">Name</Table.Column>
          <Table.Column>Notes</Table.Column>
          <Table.Column align="right">Hours Budget</Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((template) => {
            const { id, name, budgetHours, notes, isBillable } = template;

            return (
              <Table.BoxRow onClick={() => handleEdit(template)} key={id}>
                <Table.Cell>
                  <BillableIcon value={isBillable} />
                </Table.Cell>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                  <StyledNotes>{notes}</StyledNotes>
                </Table.Cell>
                <Table.Cell>
                  <Hours value={budgetHours} />
                </Table.Cell>
                <TableBoxRowActions>
                  <TableBoxRowActions.Edit
                    onClick={() => {
                      handleEdit(template);
                    }}
                  />

                  <hr />

                  <TableBoxRowActions.Dropdown>
                    {({ setIsOpen }) => (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            handleEdit(template);
                            setIsOpen(false);
                          }}>
                          Edit
                        </Dropdown.Item>

                        <Dropdown.DeleteItem
                          tooltip="This item is currently in use."
                          onClick={() => {
                            handleDelete(template);
                          }}>
                          Delete
                        </Dropdown.DeleteItem>
                      </>
                    )}
                  </TableBoxRowActions.Dropdown>
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </Table>

      {dialog}
    </>
  );
}

export default ProjectTaskTemplatesPage;
