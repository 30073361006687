import styled from 'styled-components';
import { devices } from '~/styles';

const ResponsiveContainer = styled.div`
  /* Mobile: 360px - 60px padding = 300px (18.75rem) usable space */
  width: 100%;
  max-width: 18.75rem;
  margin: 0 auto;

  @media ${devices.tablet} {
    /* Tablet: 768px - 100px padding = 668px (41.75rem) usable space */
    max-width: 41.75rem;
  }

  @media ${devices.desktop} {
    /* Desktop: 1366px - 166px padding = 1200px (75rem) usable space */
    max-width: 75rem;
  }
`;

export default ResponsiveContainer;
