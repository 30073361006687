import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AllocationContent } from './AllocationStyles';

export default function AlwaysVisibleContent({ x, canvas, children }) {
  const [offsetX, setOffsetX] = useState(0);

  const request = useRef();

  const handleRequest = useCallback(() => {
    const scrollLeft = canvas?.current?.scrollLeft || 0;
    const offsetX = Math.max(scrollLeft - x, 0);
    setOffsetX(offsetX);

    request.current = window.requestAnimationFrame(handleRequest);
  }, [canvas, x]);

  useLayoutEffect(() => {
    request.current = window.requestAnimationFrame(handleRequest);
    return () => window.cancelAnimationFrame(request.current);
  }, [handleRequest]);

  const style = useMemo(() => ({ transform: `translateX(${offsetX}px)` }), [offsetX]);

  return <AllocationContent style={style}>{children}</AllocationContent>;
}
