import slackIcon from '~/assets/public/slack-icon.svg';
import {
  Avatar,
  DateTime,
  Dropdown,
  Icon,
  InfiniteScrollingObserver,
  MemberContactPopover,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import TableBody from '~/components/table/TableBody';
import { useMember } from '~/contexts';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { SuppressedTooltip } from './components';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

const SuppressedIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

const Tag = styled.span`
  padding: 0.1rem 0.4rem;
  background-color: ${colors.primary};
  border-radius: 4px;
  font-size: 0.5rem;
  color: ${colors.white};
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const SlackIcon = styled.img`
  display: block;
  width: 1rem;
  height: 1rem;
  filter: ${({ isConnected }) => (isConnected ? 'none' : 'grayscale(100%)')};
`;

export default function MembersList({
  data,
  action,
  query,
  isSlackConnected,
  onLoadMore,
  onRowClick,
  onSendInvitation,
  onActiveStatusChange,
  onRemove,
  onSort,
}) {
  const { url } = useRouteMatch();
  const { member: currentMember } = useMember();

  return (
    <Table data-testid="members-list">
      <Table.Header sticky>
        <Table.Column name="member.name" onSort={onSort} sort={query.sort}>
          Name
        </Table.Column>
        <Table.Column name="employmentTypeId" width="7.5rem" onSort={onSort} sort={query.sort}>
          Type
        </Table.Column>
        <Table.Column name="isBillable" width="7.5rem" onSort={onSort} sort={query.sort}>
          Billability
        </Table.Column>
        <Table.Column name="securityRole.name" width="13rem" onSort={onSort} sort={query.sort}>
          Security Role
        </Table.Column>
        <Table.Column name="isActive" width="6rem" onSort={onSort} sort={query.sort}>
          Status
        </Table.Column>
        <Table.Column name="manager:member.name" onSort={onSort} sort={query.sort}>
          Manager
        </Table.Column>
        <Table.Column name="accessedAt" width="8.5rem" align="right" onSort={onSort} sort={query.sort}>
          Last Active
        </Table.Column>
        <Table.BoxActionsColumn />
      </Table.Header>

      <TableBody fade={action === 'filter'}>
        {data.results.map((member) => {
          const {
            name,
            id,
            employmentType,
            isBillable,
            securityRole,
            isActive,
            invitationStatusId,
            invitationCreatedAt,
            slackUserId,
            loginEnabled,
            accessedAt,
            manager,
            suppressedEmail,
            sampleDataId,
          } = member;
          const isCurrentMember = id === currentMember.id;

          return (
            <Table.BoxRow key={id} isDisabled={!isActive} onClick={() => onRowClick(member)}>
              <Table.Cell>
                <Member>
                  <MemberContactPopover member={member} placement="right">
                    <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
                  </MemberContactPopover>
                  <MemberInfo>
                    {name}
                    {suppressedEmail && (
                      <SuppressedTooltip suppressedEmail={suppressedEmail}>
                        <SuppressedIcon icon="exclamation-circle" color={colors.danger} />
                      </SuppressedTooltip>
                    )}
                  </MemberInfo>
                  {isSlackConnected && (
                    <span style={{ marginLeft: '.5rem' }} onClick={(event) => event.stopPropagation()}>
                      <Tooltip message={slackUserId ? 'Connected to Slack.' : 'Not connected to Slack.'}>
                        <Link to={`${url}/details/${id}/general#slack-identity`}>
                          <SlackIcon src={slackIcon} alt="Slack" isConnected={!!slackUserId} />
                        </Link>
                      </Tooltip>
                    </span>
                  )}
                </Member>
              </Table.Cell>
              <Table.Cell>{employmentType?.name}</Table.Cell>
              <Table.Cell>{isBillable ? 'Billable' : 'Non-Billable'}</Table.Cell>
              <Table.Cell>{securityRole.name}</Table.Cell>
              <Table.Cell>{isActive ? 'Active' : 'Inactive'}</Table.Cell>
              <Table.Cell>
                {manager && (
                  <Member>
                    <MemberContactPopover member={manager} placement="left">
                      <Avatar value={manager} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <MemberInfo>{manager.name}</MemberInfo>
                  </Member>
                )}
              </Table.Cell>
              <Table.Cell>
                {accessedAt ? <DateTime value={accessedAt} /> : 'Never'}
                {loginEnabled && invitationStatusId !== 'accepted' && (
                  <span>
                    <Tooltip
                      message={
                        <>
                          <span>Invitation Sent: </span>
                          {invitationCreatedAt ? (
                            <DateTime value={invitationCreatedAt} format={dateFormats.compactDateTime} />
                          ) : (
                            'Never'
                          )}
                        </>
                      }>
                      <Icon type="far" icon="envelope" color={colors.primary} spaceLeft />
                    </Tooltip>
                  </span>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                {isCurrentMember ? (
                  <TagContainer>
                    <Tag>You</Tag>
                  </TagContainer>
                ) : sampleDataId ? (
                  <TagContainer>
                    <Tag>Sample</Tag>
                  </TagContainer>
                ) : (
                  <>
                    <TableBoxRowActions.Edit onClick={() => onRowClick(member)} />
                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => {
                        return (
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                onRowClick(member);
                                setIsOpen(false);
                              }}>
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                !isActive || !loginEnabled || invitationStatusId === 'accepted' || suppressedEmail
                              }
                              tooltip={(() => {
                                if (invitationStatusId === 'accepted' && isActive)
                                  return 'This member has already accepted the invitation.';
                                if (!loginEnabled) return 'This member is not allowed to login.';
                                if (!isActive) return 'This member is inactive.';
                                if (suppressedEmail)
                                  return 'Ruddr is not sending emails to this address because an undeliverable email occurred. Please chat with support or email help@ruddr.io if you would like Ruddr to resume sending emails to this address.';
                              })()}
                              onClick={async () => {
                                await onSendInvitation(member);
                                setIsOpen(false);
                              }}>
                              Send Invitation
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => {
                                onActiveStatusChange(member, !isActive);
                                setIsOpen(false);
                              }}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>
                            <Dropdown.DeleteItem
                              tooltip="This member is associated with data in Ruddr such as projects, time entries, or expense items. In order to delete this member, those associations must first be removed."
                              onCheckDependencies={async (workspace) =>
                                (await workspace.members(id).hasDependencies()).data
                              }
                              onClick={() => setIsOpen(false) || onRemove(member)}>
                              Delete
                            </Dropdown.DeleteItem>
                          </>
                        );
                      }}
                    </TableBoxRowActions.Dropdown>
                  </>
                )}
              </TableBoxRowActions>
            </Table.BoxRow>
          );
        })}
        {data.total > data.results.length && (
          <InfiniteScrollingObserver key={data.results.length} onIntersecting={onLoadMore} />
        )}
      </TableBody>
    </Table>
  );
}
