import { Checkbox, DateTime, Hours, Icon, Table } from '~/components';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { colors } from '~/styles';
import {
  ApprovalAction,
  ApprovalActions,
  Approve,
  Cell,
  Details,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  PublicList,
  Reject,
  RejectionReason,
  Row,
  SelectAll,
  ToggleSwitch,
} from '../components/ListComponents';

export default function TimeTab({
  readOnly,
  clientApproval,
  isSubmitting,
  selection,
  locale,
  onSelectionChange,
  onStatusChange,
  onGroupAction,
}) {
  const groups = useMemo(() => {
    return _.orderBy(
      clientApproval.timeEntries.reduce((a, v) => {
        a[v.member.id] = a[v.member.id] || { member: v.member, entries: [] };
        a[v.member.id].entries.push(v);
        return a;
      }, {}),
      'member.name',
    );
  }, [clientApproval.timeEntries]);

  const handleSelectAllChange = () =>
    onSelectionChange(selection.length > 0 ? [] : clientApproval.timeEntries.map((item) => item.id));

  return (
    <PublicList>
      <Table.Total value={clientApproval.timeEntries.length} label="Time Entry" />

      <ListHeader>
        <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem">
          <SelectAll>
            <Checkbox
              checked={selection.length > 0}
              partial={selection.length < clientApproval.timeEntries.length}
              disabled={readOnly || isSubmitting || clientApproval.timeEntries.length === 0}
              onChange={handleSelectAllChange}
            />
          </SelectAll>
        </Cell>

        <Cell padding="0.875rem 1rem">Member</Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Pending
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Approved
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Rejected
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Total
        </Cell>

        <Cell $width="8rem" padding="0.875rem 1rem" />
      </ListHeader>

      {clientApproval.timeEntries.length === 0 && (
        <NoResultsCell>There are no time entries on this client approval.</NoResultsCell>
      )}

      {_.map(groups, (group, key) => {
        return (
          <Group
            key={key}
            group={group}
            selection={selection}
            readOnly={readOnly}
            isSubmitting={isSubmitting}
            locale={locale}
            onSelectionChange={onSelectionChange}
            onAction={onGroupAction}>
            {group.entries.map((entry) => {
              const checked = !!selection?.includes(entry.id);

              const handleSelectionChange = () =>
                onSelectionChange(checked ? selection.filter((id) => id !== entry.id) : [...selection, entry.id]);

              const handleActionClick = (statusId) => {
                if (statusId === entry.clientStatusId) statusId = null;
                onStatusChange(entry, statusId);
              };

              const submitting =
                (isSubmitting === 'batch' && selection?.includes(entry.id)) ||
                isSubmitting?.group?.entries.some((timeEntry) => timeEntry.id === entry.id) ||
                isSubmitting === entry.id;

              const disableActions = readOnly || submitting;

              return (
                <Row key={entry.id} data-testid="row">
                  <Cell flex="0" $width="2.5rem" padding="0.5rem 0rem 0.5rem 2rem" onClick={(e) => e.stopPropagation()}>
                    <Checkbox checked={checked} disabled={disableActions} onChange={handleSelectionChange} />
                  </Cell>

                  <Cell $width="8rem">
                    <DateTime value={entry.date} />
                  </Cell>

                  {clientApproval.project.useRoles && (
                    <Cell $width="12rem">{entry.role?.name && <p>{entry.role.name}</p>}</Cell>
                  )}

                  <Cell>
                    <Details>
                      {entry.task && <p>{entry.task.name}</p>}

                      <Notes>{entry.notes ? <em>"{entry.notes}"</em> : 'No notes provided'}</Notes>

                      {entry.clientStatusId === 'rejected' && entry.clientRejectionReason && (
                        <RejectionReason>{entry.clientRejectionReason}</RejectionReason>
                      )}
                    </Details>
                  </Cell>

                  <Cell right $width="8rem">
                    <Hours value={entry.minutes / 60} locale={locale} />
                  </Cell>

                  <Cell right $width="8rem">
                    <ApprovalActions onClick={(e) => e.stopPropagation()}>
                      <ApprovalAction>
                        <Approve
                          disabled={disableActions}
                          status={entry.clientStatusId}
                          onClick={() => handleActionClick('approved')}>
                          <Icon icon="thumbs-up" />
                        </Approve>
                      </ApprovalAction>
                      <ApprovalAction>
                        <Reject
                          disabled={disableActions}
                          status={entry.clientStatusId}
                          onClick={() => handleActionClick('rejected')}>
                          <Icon icon="thumbs-down" />
                        </Reject>
                      </ApprovalAction>
                    </ApprovalActions>
                  </Cell>
                </Row>
              );
            })}
          </Group>
        );
      })}
    </PublicList>
  );
}

const filter = (status) => (entry) => entry.clientStatusId === status;
const sum = (entry) => _.round(entry.minutes / 60, 2);

function Group({ group, selection, isSubmitting, readOnly, locale, children, onSelectionChange, onAction }) {
  const [collapsed, setCollapsed] = useState(true);

  const hours = useMemo(() => {
    return {
      pending: _.round(_(group.entries).filter(filter(null)).sumBy(sum), 2),
      approved: _.round(_(group.entries).filter(filter('approved')).sumBy(sum), 2),
      rejected: _.round(_(group.entries).filter(filter('rejected')).sumBy(sum), 2),
      total: _.round(_(group.entries).sumBy(sum), 2),
    };
  }, [group]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const groupSelection = useMemo(() => {
    return selection?.filter((id) => group.entries.some((entry) => entry.id === id));
  }, [group, selection]);

  const groupSelected = useMemo(() => {
    return group.entries.some((e) => groupSelection.includes(e.id));
  }, [group, groupSelection]);

  const handleGroupSelectionChange = () => {
    onSelectionChange(
      groupSelected
        ? selection.filter((id) => !group.entries.map(({ id }) => id).includes(id))
        : [...selection, ...group.entries.map(({ id }) => id)],
    );
  };

  const status = useMemo(() => {
    return {
      hasPending: _.some(group.entries, (entry) => entry.clientStatusId === null),
      hasApproved: _.some(group.entries, (entry) => entry.clientStatusId === 'approved'),
      hasRejected: _.some(group.entries, (entry) => entry.clientStatusId === 'rejected'),

      get approved() {
        if (!this.hasApproved) return null;
        if (this.hasApproved && !this.hasRejected && !this.hasPending) return 'approved';
        return 'partially_approved';
      },

      get rejected() {
        if (!this.hasRejected) return null;
        if (this.hasRejected && !this.hasApproved && !this.hasPending) return 'rejected';
        return 'partially_rejected';
      },
    };
  }, [group.entries]);

  const handleActionClick = (statusId) => {
    let entries = group.entries;

    switch (status[statusId]) {
      case 'approved':
      case 'rejected':
        statusId = null;
        break;

      case 'partially_approved':
      case 'partially_rejected':
        entries = group.entries.filter((entry) => entry.clientStatusId === statusId);
        statusId = null;
        break;
    }

    onAction({ ...group, entries }, statusId);
  };

  const submitting = isSubmitting?.group?.member.id === group.member.id || (isSubmitting === 'batch' && groupSelected);

  const disableActions = readOnly || submitting;

  return (
    <ListGroup>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell flex="0" padding="0" onClick={(event) => event.stopPropagation()}>
          <Checkbox
            disabled={disableActions}
            checked={groupSelected}
            partial={groupSelection.length < group.entries.length}
            onChange={handleGroupSelectionChange}
          />
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right color={colors.warning} $width="8rem">
          <Hours value={hours.pending} locale={locale} />
        </Cell>

        <Cell right color={colors.success} $width="8rem">
          <Hours value={hours.approved} locale={locale} />
        </Cell>

        <Cell right color={colors.danger} $width="8rem">
          <Hours value={hours.rejected} locale={locale} />
        </Cell>

        <Cell right $width="8rem">
          <Hours value={hours.total} locale={locale} />
        </Cell>

        <Cell right $width="8rem">
          <ApprovalActions onClick={(e) => e.stopPropagation()}>
            <ApprovalAction>
              <Approve disabled={disableActions} status={status.approved} onClick={() => handleActionClick('approved')}>
                <Icon icon="thumbs-up" />
              </Approve>
            </ApprovalAction>
            <ApprovalAction>
              <Reject disabled={disableActions} status={status.rejected} onClick={() => handleActionClick('rejected')}>
                <Icon icon="thumbs-down" />
              </Reject>
            </ApprovalAction>
          </ApprovalActions>
        </Cell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}
