import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateTime, Icon, Percent } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import FeaturesList from './components/FeaturesList';
import IntegrationList from './components/IntegrationsList';

const Sections = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 2rem; /* Add gap between sections */
`;

const Section = styled.section`
  margin: 1rem 0;

  h3 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
    font-weight: ${weights.light};
  }
`;

const BreakDown = styled.div`
  display: flex;
  flex-direction: row;
  &:last-child {
    border-bottom: solid 0.125rem ${colors.grey5};
  }
`;

const BreakdownLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.825rem;
`;

const BreakdownValue = styled.div`
  color: ${colors.black};
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const BreakdownItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  padding-right: 2rem;
  border: solid 0.125rem ${colors.grey5};
  border-right: none;
  border-bottom: none;
  cursor: pointer;

  &:last-child {
    border-right: solid 0.125rem ${colors.grey5};
  }

  &:hover ${BreakdownValue} {
    color: ${colors.grey55};
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  padding-top: 0;
  border-top: solid 1px ${colors.grey10};
`;

const SecondaryValue = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-style: italic;
`;

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: ${colors.grey75};
`;

export default function WorkspaceDetails({ workspaceId }) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState();

  useEffect(() => {
    const getWorkspaceDetails = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.admin.workspaces.getUsage(workspaceId);
        setDetails(data);
      } finally {
        setIsLoading(false);
      }
    };
    getWorkspaceDetails();
    return getWorkspaceDetails.cancel;
  }, [api, workspaceId]);

  return (
    <Details>
      {isLoading ? (
        <LoadingIndicator>
          <Icon icon="spinner" spin={true} />
        </LoadingIndicator>
      ) : (
        <>
          {details && (
            <>
              <Section>
                <BreakDown>
                  <BreakdownItem>
                    <BreakdownLabel>Clients</BreakdownLabel>
                    <BreakdownValue>
                      <div>{details.clientCount}</div>
                      <SecondaryValue>
                        Last Created: <DateTime value={details.clientRecentlyCreated} />
                      </SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>

                  <BreakdownItem>
                    <BreakdownLabel>Projects</BreakdownLabel>
                    <div>{details.projectCount}</div>
                    <BreakdownValue>
                      <SecondaryValue>
                        Last Created: <DateTime value={details.projectRecentlyCreated} />
                      </SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>

                  <BreakdownItem>
                    <BreakdownLabel>Invoices</BreakdownLabel>
                    <div>{details.invoices.count}</div>
                    <BreakdownValue>
                      <SecondaryValue>
                        Last Created: <DateTime value={details.invoices.lastCreated} />
                      </SecondaryValue>
                      <SecondaryValue>Created in Last 30 days: {details.invoices.recentCreatedCount}</SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>

                  <BreakdownItem>
                    <BreakdownLabel>Payments</BreakdownLabel>
                    <div>{details.payments.count}</div>
                    <BreakdownValue>
                      <SecondaryValue>
                        Last Created: <DateTime value={details.payments.lastCreated} />
                      </SecondaryValue>
                      <SecondaryValue>Created in Last 30 days: {details.payments.recentCreatedCount}</SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>
                </BreakDown>
                <BreakDown>
                  <BreakdownItem>
                    <BreakdownLabel>Members</BreakdownLabel>
                    <div>{details.members.count}</div>
                    <BreakdownValue>
                      <SecondaryValue>
                        Last Created: <DateTime value={details.members.lastCreated} />
                      </SecondaryValue>
                      <SecondaryValue>
                        Time Tracking last 7 days: <Percent value={details.members.activeTimeEntryPercentage} />
                      </SecondaryValue>
                      <SecondaryValue>
                        Expense Tracking last 7 days: <Percent value={details.members.activeExpensePercentage} />
                      </SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>
                  <BreakdownItem>
                    <BreakdownLabel>Usage Score</BreakdownLabel>
                    <div>{details.overallUsageScore}</div>
                    <BreakdownValue>
                      <SecondaryValue>Time Entry Score: {details.timeEntryUsageScore.toFixed(1)}</SecondaryValue>
                      <SecondaryValue>Expense Score: {details.expenseUsageScore.toFixed(1)}</SecondaryValue>
                      <SecondaryValue>Client Score: {details.clientScore.toFixed(1)}</SecondaryValue>
                      <SecondaryValue>Project Score: {details.projectScore.toFixed(1)}</SecondaryValue>
                      <SecondaryValue>Integration Score: {details.integrationsScore.toFixed(1)}</SecondaryValue>
                      <SecondaryValue>Feature Score: {details.featuresScore?.toFixed(1)}</SecondaryValue>
                    </BreakdownValue>
                  </BreakdownItem>
                </BreakDown>
              </Section>

              <Sections>
                <Section>
                  <h3>Integration</h3>
                  <IntegrationList integrations={details.integrations} />
                </Section>
                <Section>
                  <h3>Features</h3>
                  <FeaturesList features={details.features} />
                </Section>
              </Sections>
            </>
          )}
        </>
      )}
    </Details>
  );
}
