import { Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useForm } from '~/hooks';
import { devices } from '~/styles';

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  max-width: 41.5rem;
`;

const FormAction = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ForgotLink = styled(Link)`
  margin-left: 1rem;

  @media ${devices.mobile} {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`;

export default function ChangePassword() {
  useDocumentTitle('Change Password');

  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const [formState, form] = useForm();

  const { status, message, isSubmitting } = formState;

  async function handleSubmit(values, actions) {
    try {
      form.submit();
      await api.www.workspaces(workspace.id).myProfile.changePassword(values);
      actions.resetForm();
      form.done();
      toast.success('Your password was successfully changed.');
    } catch ({ message }) {
      form.error({ message: message || 'There was a problem changing your password.' });
      window.scrollTo({ top: 0 });
    }
  }

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        newPasswordVerification: '',
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().label('Current Password').required(),
        newPassword: Yup.string()
          .label('New Password')
          .min(8, 'Password must be at least 8 characters long')
          .max(255, 'Password must be at most 255 characters long')
          .required(),
        newPasswordVerification: Yup.string()
          .label('Repeat Password')
          .required()
          .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
      })}>
      {() => (
        <FormWrapper>
          <Form>
            {status && <FormMessage.Error spaceBottom>{message}</FormMessage.Error>}
            <Form.Control>
              <Field.Text name="currentPassword" placeholder="Current Password" type="password" autoFocus />
            </Form.Control>
            <Form.Control>
              <Field.Text name="newPassword" placeholder="New Password" type="password" maxLength={255} />
            </Form.Control>
            <Form.Control>
              <Field.Text
                name="newPasswordVerification"
                placeholder="Repeat New Password"
                type="password"
                maxLength={255}
              />
            </Form.Control>
            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Update password
              </Button>
              <ForgotLink
                to={`/password-reset?${new URLSearchParams({ email: workspace.member.email, workspace: workspace.key }).toString()}`}>
                I forgot my password
              </ForgotLink>
            </FormAction>
          </Form>
        </FormWrapper>
      )}
    </Formik>
  );
}
