import React, { useContext } from 'react';
import api from '~/api/index.js';

const ApiContext = React.createContext();

function ApiProvider({ children }) {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

function useApi() {
  return useContext(ApiContext);
}

export { ApiContext, ApiProvider, useApi };
