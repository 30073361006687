import { Button, Buttons, CancelButton, FormMessage, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const Errors = styled.ul`
  list-style: disc;

  li {
    margin-left: 2rem;

    padding-top: 0.75rem;
  }
`;

export default function ClientApprovalScheduleRunModal({ clientApprovalScheduleId, projectId, onResolve }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [{ isSubmitting, saved, message, status }, form] = useForm();
  const [errors, setErrors] = useState(null);

  const toast = useToast();

  const handleSubmit = async () => {
    form.submit();

    try {
      await api.www
        .workspaces(workspace.id)
        .projects(projectId)
        .clientApprovalSchedules(clientApprovalScheduleId)
        .run();

      toast.success('The client approval was succcessfully created.');
      onResolve(true);
    } catch (error) {
      form.error(error);
      setErrors(error.response?.data?.errors);
    }
  };

  const handleClose = () => onResolve(false);

  return (
    <ModalCard title="Run Client Approval Schedule" onClose={handleClose}>
      <ModalCard.Body>
        This will create a client approval based on the client approval schedule settings.
        {status && (
          <FormMessage.Error spaceTop>
            {message}

            {errors?.length > 0 && (
              <Errors>
                {errors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </Errors>
            )}
          </FormMessage.Error>
        )}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={handleClose}>Close</CancelButton>
          <Button isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
            Run
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
