import React from 'react';
import timeLockedReasons from '~/routes/app/time/timeLockedReasons';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

function TimeLockIcon({ value, ...props }) {
  const locked = value !== 'unlocked';

  if (!locked) return null;

  const tooltip = timeLockedReasons[value];

  return (
    <Tooltip message={tooltip}>
      <Icon icon="lock" color={colors.grey40} {...props} />
    </Tooltip>
  );
}

export default TimeLockIcon;
