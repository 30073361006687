import { Confirmation } from '~/components';
import { useConfirmation } from '~/contexts';
import React, { useCallback } from 'react';

function useDirtyCheck(dirtyCheck) {
  const confirmation = useConfirmation();

  const validate = useCallback(
    async (callback) => {
      if (dirtyCheck()) {
        const confirm = await confirmation.prompt((resolve) => (
          <Confirmation resolve={resolve}>Unsaved changes will be lost. Do you want to continue?</Confirmation>
        ));

        if (!confirm) return;
      }

      callback();
    },
    [confirmation, dirtyCheck],
  );

  return validate;
}

export default useDirtyCheck;
