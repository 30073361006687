import moment from 'moment';
import React, { useMemo } from 'react';
import { dateFormats } from '~/utils';
import { BackgroundCanvasStyles } from './CanvasStyles';
import DayBackgroundCanvas from './DayBackgroundCanvas';
import MonthBackgroundCanvas from './MonthBackgroundCanvas';
import WeekBackgroundCanvas from './WeekBackgroundCanvas';

function BackgroundCanvas({ start, end, unit, styles }) {
  const endDate = useMemo(
    () =>
      ({
        day: moment(end).add(6, 'weeks').format(dateFormats.isoDate),
        week: moment(end).add(6, 'weeks').format(dateFormats.isoDate),
        month: moment(end).add(6, 'months').format(dateFormats.isoDate),
      })[unit],

    [end, unit],
  );

  return (
    <BackgroundCanvasStyles>
      {{
        day: () => <DayBackgroundCanvas start={start} end={endDate} dateWidth={styles.date.width} />,
        week: () => <WeekBackgroundCanvas start={start} end={endDate} dateWidth={styles.date.width} />,
        month: () => <MonthBackgroundCanvas start={start} end={endDate} dateWidth={styles.date.width} />,
      }[unit]()}
    </BackgroundCanvasStyles>
  );
}

export default React.memo(BackgroundCanvas);
