import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const NavBox = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: solid 1px ${colors.grey10};
  border-radius: 5px;
  padding: 1rem;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
    z-index: 1;
    border-color: transparent;
  }
`;

const Title = styled.div`
  font-weight: ${weights.bold};
  color: ${colors.grey100};
  font-size: 1rem;
`;

const Description = styled.div`
  font-weight: ${weights.normal};
  color: ${colors.grey100};
  font-size: 0.875rem;
  padding-top: 0.5rem;
`;

NavBox.Title = Title;
NavBox.Description = Description;

export default NavBox;
