import styled from 'styled-components';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-height: 2rem;
  margin: 0.25rem -0.25rem 0.25rem;
`;

export default Tags;
