import * as React from "react";
const SvgOtherItems = (props) => /* @__PURE__ */ React.createElement("svg", { height: 100, width: 100, fill: "#00b2a9", x: "0px", y: "0px", viewBox: "0 0 100 100", ...props }, /* @__PURE__ */ React.createElement("g", { transform: "translate(0,-952.36218)" }, /* @__PURE__ */ React.createElement("flowRoot", { xmlSpace: "preserve", style: {
  fontSize: 40,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: "125%",
  letterSpacing: 0,
  wordSpacing: 0,
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  stroke: "#00b2a9",
  strokeWidth: 3.73913044,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  strokeDashoffset: 0,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "sans-serif"
}, transform: "matrix(0.53488372,0,0,0.53488372,32.790695,485.7499)" }, /* @__PURE__ */ React.createElement("flowRegion", null, /* @__PURE__ */ React.createElement("rect", { width: 25.466377, height: 46.319279, x: 25.835455, y: 1.2716562, style: {
  color: "#00b2a9",
  enableBackground: "accumulate"
}, fill: "#00b2a9", stroke: "#00b2a9", strokeWidth: 3.73913044, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 4, strokeOpacity: 1, strokeDasharray: "none", strokeDashoffset: 0, marker: "none", visibility: "visible", display: "inline", overflow: "visible" })), /* @__PURE__ */ React.createElement("flowPara", null)), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 12.8125,959.375 a 1.0001,1.0001 0 0 0 -0.8125,1 l 0,68 a 1.0001,1.0001 0 1 0 2,0 l 0,-67 38.59375,0 L 66,974.78125 66,1004.375 a 1.0001,1.0001 0 1 0 2,0 l 0,-30 a 1.0001,1.0001 0 0 0 -0.28125,-0.71875 l -14,-14 A 1.0001,1.0001 0 0 0 53,959.375 l -40,0 a 1.0001,1.0001 0 0 0 -0.1875,0 z m 0,74 a 1.0043849,1.0043849 0 1 0 0.1875,2 l 41.25,0 a 1.0001,1.0001 0 1 0 0,-2 l -41.25,0 a 1.0001,1.0001 0 0 0 -0.1875,0 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "M 52.875,960.34375 A 1.0001,1.0001 0 0 0 52,961.375 l 0,13 a 1.0001,1.0001 0 0 0 1,1 l 13,0 a 1.0001,1.0001 0 1 0 0,-2 l -12,0 0,-12 a 1.0001,1.0001 0 0 0 -1.125,-1.03125 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 34.8125,1021.375 a 1.0043849,1.0043849 0 1 0 0.1875,2 l 18.5,0 a 1.0001,1.0001 0 1 0 0,-2 l -18.5,0 a 1.0001,1.0001 0 0 0 -0.1875,0 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 29.90625,1015.3438 a 1.0001,1.0001 0 0 0 -0.65625,0.375 l -5.28125,6.1874 -2.25,-2.25 a 1.0054956,1.0054956 0 1 0 -1.4375,1.4063 l 3,3 A 1.0001,1.0001 0 0 0 24.75,1024 l 6,-7 a 1.0001,1.0001 0 0 0 -0.84375,-1.6562 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2.35294104,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 69.999999,1009.3528 c 9.929281,0 18.009375,8.0801 18.009375,18.0094 0,9.9293 -8.080094,18.0094 -18.009375,18.0094 -9.929281,0 -18.009375,-8.0801 -18.009375,-18.0094 0,-9.9293 8.080094,-18.0094 18.009375,-18.0094 z m 0,2.0188 c -8.8484,0 -15.990625,7.1422 -15.990625,15.9906 0,8.8484 7.142225,15.9906 15.990625,15.9906 8.848401,0 15.990625,-7.1422 15.990625,-15.9906 0,-8.8484 -7.142224,-15.9906 -15.990625,-15.9906 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 34.8125,1006.375 a 1.0043849,1.0043849 0 1 0 0.1875,2 l 24,0 a 1.0001,1.0001 0 1 0 0,-2 l -24,0 a 1.0001,1.0001 0 0 0 -0.1875,0 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 29.90625,1000.3438 a 1.0001,1.0001 0 0 0 -0.65625,0.375 l -5.28125,6.1874 -2.25,-2.25 a 1.0054956,1.0054956 0 1 0 -1.4375,1.4063 l 3,3 A 1.0001,1.0001 0 0 0 24.75,1009 l 6,-7 a 1.0001,1.0001 0 0 0 -0.84375,-1.6562 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 34.8125,991.375 a 1.0043849,1.0043849 0 1 0 0.1875,2 l 24,0 a 1.0001,1.0001 0 1 0 0,-2 l -24,0 a 1.0001,1.0001 0 0 0 -0.1875,0 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 29.90625,985.34375 a 1.0001,1.0001 0 0 0 -0.65625,0.375 l -5.28125,6.1875 -2.25,-2.25 a 1.0054782,1.0054782 0 1 0 -1.4375,1.40625 l 3,3 A 1.0001,1.0001 0 0 0 24.75,994 l 6,-7 a 1.0001,1.0001 0 0 0 -0.84375,-1.65625 z" }), /* @__PURE__ */ React.createElement("flowRoot", { xmlSpace: "preserve", style: {
  fontSize: 40,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: "125%",
  letterSpacing: 0,
  wordSpacing: 0,
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  stroke: "#00b2a9",
  strokeWidth: 3.73913044,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  strokeDashoffset: 0,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "sans-serif"
}, transform: "matrix(0.53488372,0,0,0.53488372,93.790695,521.7499)" }, /* @__PURE__ */ React.createElement("flowRegion", null, /* @__PURE__ */ React.createElement("rect", { width: 25.466377, height: 46.319279, x: 25.835455, y: 1.2716562, style: {
  color: "#00b2a9",
  enableBackground: "accumulate"
}, fill: "#00b2a9", stroke: "#00b2a9", strokeWidth: 3.73913044, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 4, strokeOpacity: 1, strokeDasharray: "none", strokeDashoffset: 0, marker: "none", visibility: "visible", display: "inline", overflow: "visible" })), /* @__PURE__ */ React.createElement("flowPara", null)), /* @__PURE__ */ React.createElement("flowRoot", { xmlSpace: "preserve", style: {
  fontSize: 40,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: "125%",
  letterSpacing: 0,
  wordSpacing: 0,
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  stroke: "#00b2a9",
  strokeWidth: 3.73913045,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  strokeDashoffset: 0,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "sans-serif"
}, transform: "matrix(0.3782199,0.37821991,-0.37821991,0.3782199,538.86395,687.55575)" }, /* @__PURE__ */ React.createElement("flowRegion", { style: {}, strokeWidth: 7.47826004 }, /* @__PURE__ */ React.createElement("rect", { width: 25.466377, height: 46.319279, x: 25.835455, y: 1.2716562, style: {
  color: "#00b2a9",
  enableBackground: "accumulate"
}, fill: "#00b2a9", stroke: "#00b2a9", strokeWidth: 3.73913045, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 4, strokeOpacity: 1, strokeDasharray: "none", strokeDashoffset: 0, marker: "none", visibility: "visible", display: "inline", overflow: "visible" })), /* @__PURE__ */ React.createElement("flowPara", { style: {}, strokeWidth: 3.73913045, stroke: "#00b2a9", strokeMiterlimit: 4, strokeDasharray: "none", fill: "#00b2a9" })), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "M 69.875,1016.3438 A 1.0001,1.0001 0 0 0 69,1017.375 l 0,2.75 a 1.0001,1.0001 0 1 0 2,0 l 0,-2.75 a 1.0001,1.0001 0 0 0 -1.125,-1.0312 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 70,1019.375 c -1.185136,0.011 -2.381578,0.3485 -3.34375,1.0938 C 65.694078,1021.214 65,1022.4241 65,1023.875 c 0.02407,1.3711 0.649164,2.4856 1.5625,3.1562 0.913336,0.6707 2.012854,1.0128 3.1875,1.3126 1.111071,0.2835 1.99464,0.5664 2.5,0.9374 0.50536,0.3711 0.734576,0.7153 0.75,1.5938 0,0.8745 -0.33456,1.3939 -0.875,1.8125 -0.54044,0.4186 -1.320639,0.6799 -2.125,0.6875 -1.890804,0 -2.647774,-0.8708 -3.09375,-1.7812 a 1.0063276,1.0063276 0 1 0 -1.8125,0.875 c 0.696882,1.4227 2.320454,2.9062 4.90625,2.9062 1.18517,-0.011 2.381576,-0.3485 3.34375,-1.0938 C 74.305924,1033.536 75,1032.3259 75,1030.875 a 1.0001,1.0001 0 0 0 0,-0.031 c -0.02407,-1.3712 -0.649163,-2.4856 -1.5625,-3.1563 -0.913337,-0.6707 -2.012854,-0.9815 -3.1875,-1.2813 -1.111071,-0.2835 -1.994641,-0.5976 -2.5,-0.9687 -0.497731,-0.3655 -0.728446,-0.7132 -0.75,-1.5625 -3.3e-4,-0.013 2.33e-4,-0.018 0,-0.031 0.0088,-0.8533 0.341981,-1.3997 0.875,-1.8126 0.540443,-0.4186 1.320605,-0.6485 2.125,-0.6562 1.890804,0 2.647774,0.8395 3.09375,1.75 a 1.0063276,1.0063276 0 1 0 1.8125,-0.875 c -0.696882,-1.4227 -2.320454,-2.875 -4.90625,-2.875 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  opacity: 1,
  color: "#00b2a9",
  fill: "#00b2a9",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 2,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 69.875,1033.6875 a 1.0001,1.0001 0 0 0 -0.875,1 l 0,2.6875 a 1.0001,1.0001 0 1 0 2,0 l 0,-2.6875 a 1.0001,1.0001 0 0 0 -1.125,-1 z" })));
export default SvgOtherItems;
