import Papa from 'papaparse';

export default function checkCSVRows({ file, rules }) {
  return new Promise((resolve, reject) => {
    let indexedErrors = {};
    let errorCounts = {};
    let rowIndex = 1;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      step: (result) => {
        const row = result.data;

        // Validate row
        Object.entries(row).forEach(([header, value]) => {
          const rule = rules[header];
          if (rule && (!rule.validator || !rule.validator(value, row))) {
            const cellError = {
              row: rowIndex,
              column: header,
              value,
              errorType: 'Invalid value',
              errorMessage: value ? `Invalid value for ${header} "${value}"` : `Value not provided`,
              ruleDescription: rule.description,
            };

            if (!indexedErrors[rowIndex]) indexedErrors[rowIndex] = [];
            indexedErrors[rowIndex].push(cellError);

            // Increment error count for the column
            errorCounts[header] = (errorCounts[header] || 0) + 1;
          }
        });

        rowIndex++;
      },
      complete: () => {
        if (Object.keys(indexedErrors).length > 0) {
          resolve({
            type: 'error',
            summary: { passesCheck: false },
            messages: indexedErrors,
            errorCounts, // Include the error counts in the response
          });
        } else {
          resolve({
            type: 'success',
            summary: { passesCheck: true },
            messages: ['All rows have valid data.'],
          });
        }
      },
      error: (error) => {
        reject({
          type: 'error',
          summary: { passesCheck: false },
          messages: [`Error parsing CSV file: ${error.message}`],
        });
      },
    });
  });
}
