import { Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, usePercentFormat } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors } from '~/styles';

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

export default function ProjectMarginWidget({ project, period }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .getProjectMarginKPI({ date: period.start ?? undefined });

    actions.ready({ data });
    // Intentionally passing the full "project" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, project, period, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const helpMessage = 'Margin is calculated by dividing the profit by the revenue';

  return (
    <Widget stretched centered loading={!isReady} style={{ height: '18.75rem' }}>
      <Widget.Header>
        {data && helpMessage && <Widget.Help message={helpMessage} />}
        <Widget.Title>Margin</Widget.Title>
      </Widget.Header>
      <Widget.Content>{data && <Data project={project} data={data} period={period} />}</Widget.Content>
    </Widget>
  );
}

function Data({ data }) {
  const percentFormat = {
    tooltip: usePercentFormat(),
    ticks: usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  };

  const chartData = {
    labels: ['Invoiced', 'Earned'],
    datasets: [
      {
        label: 'Margin',
        data: [data.invoiced, data.earned],
        backgroundColor: [colors.primary, colors.warning],
        maxBarThickness: 100,
      },
    ],
  };

  if (data.budget != null) {
    chartData.labels.push('Budget');
    chartData.datasets[0].data.push(data.budget);
    chartData.datasets[0].backgroundColor.push(colors.grey55);
  }

  const chartOptions = {
    maintainAspectRatio: false,

    layout: {
      padding: { top: 12 },
    },

    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltip) => {
            let label = (tooltip.label || '').trim();
            if (label) {
              label += ' Margin: ';
            }
            label += percentFormat.tooltip.format(tooltip.parsed.y);
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          color: colors.grey40,
        },
      },

      y: {
        display: true,
        grid: { display: true, color: colors.grey10 },
        border: { display: false },
        suggestedMin: 0,
        max: 1,
        type: 'linear',
        ticks: {
          maxTicksLimit: 10,
          color: colors.grey40,
          callback: (label) => percentFormat.ticks.format(label),
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
}
