import { useCallback, useState } from 'react';
import _ from 'lodash';
import { useApi, useTimeEntries, useToast, useWorkspace } from '~/contexts';

export default function useTimeEntryTimer() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const { entries, updateEntry } = useTimeEntries();
  const [timerChanging, setTimerChanging] = useState(false);
  const toast = useToast();

  const startStopTimer = useCallback(
    async (entry) => {
      if (timerChanging) {
        return null;
      }

      setTimerChanging(true);

      let returnEntry = null;

      const previousTimer = _.find(entries, (e) => !!e.timerStartedAt && e.id !== entry.id);

      const baseApi = api.www.workspaces(workspace.id).timeEntries(entry.id);
      const apiMethod = entry.timerStartedAt ? baseApi.stopTimer : baseApi.startTimer;

      try {
        const { data: entry } = await apiMethod();
        updateEntry(entry);
        returnEntry = entry;

        if (previousTimer) {
          const { data: previousEntry } = await api.www.workspaces(workspace.id).timeEntries(previousTimer.id).get();
          updateEntry(previousEntry);
        }
      } catch (ex) {
        if (ex.message) toast.error(ex.message);
      } finally {
        setTimerChanging(false);
      }

      return returnEntry;
    },
    [api, workspace.id, entries, timerChanging, updateEntry, toast],
  );

  return { startStopTimer, timerChanging };
}
