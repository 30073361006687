import React, { useContext } from 'react';
import { useWorkspace } from './WorkspaceContext';

const MemberContext = React.createContext();

function MemberProvider({ children }) {
  const { workspace, updateMember } = useWorkspace();

  return (
    <MemberContext.Provider value={{ member: workspace?.member ?? null, updateMember }}>
      {children}
    </MemberContext.Provider>
  );
}

function useMember() {
  return useContext(MemberContext);
}

export { MemberContext, MemberProvider, useMember };
