import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function CompanySpendTierSelect({ value, initialValue, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .companySpendTiers()
        .get({ q, isActive: true, include: initialValue ? initialValue.id : undefined });

      return data;
    },
    [workspace.id, initialValue, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => option.name}
      onSearch={handleSearch}
      {...props}>
      {({ options }) => {
        return <AutoComplete.Results showNoResults={!options.length} noResultsMessage="No spend tiers available" />;
      }}
    </AutoComplete>
  );
}

function FieldCompanySpendTierSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <CompanySpendTierSelect {...field} {...props} />
    </FieldControl>
  );
}

export default CompanySpendTierSelect;
export { FieldCompanySpendTierSelect };
