/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, Icon } from '~/components';
import {
  useApi,
  useChat,
  useConfirmation,
  useMember,
  useRating,
  useSession,
  useSubscription,
  useWorkspace,
} from '~/contexts';
import { useActions, useAuth } from '~/hooks';
import { colors } from '~/styles';
import { PUBLIC_BASE_URL } from '~/utils/redirectPublic';
import LogoutConfirmation from './LogoutConfirmation';
import moment from 'moment';

const Selector = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 20rem;
  padding: 0 0.625rem; /* 1 - border width */
  cursor: pointer;
  user-select: none;
  border: solid 0.1875rem ${({ adminImpersonating }) => (adminImpersonating ? colors.danger : 'transparent')};

  &:hover {
    border-color: ${({ adminImpersonating }) => (adminImpersonating ? colors.danger : colors.grey5)};
  }

  .icon {
    margin-left: 1rem;
    margin-right: 0.25rem;
    color: ${colors.grey40};
  }
`;

const Name = styled.div`
  margin-left: 1rem;
  margin-right: auto;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SubText = styled.p`
  font-size: 0.75rem;
  color: ${colors.grey40};
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0.375rem;
  right: 0.375rem;
  display: none;
  flex-direction: column;
  max-height: calc(100vh - 5.75rem);
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.875rem;
    border: solid 0.875rem transparent;
    border-bottom-color: ${colors.white};
  }

  a,
  span {
    display: flex;
    flex: 1;
    margin: 0 1.25rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 999rem;
    cursor: pointer;

    &.alt {
      justify-content: center;
      font-weight: bold;
    }

    &:not(.alt) {
      color: ${colors.grey75};

      &:hover {
        background-color: ${colors.grey5};
        color: ${colors.black};

        .icon {
          color: ${colors.grey75};
        }

        .subText {
          color: ${colors.grey75};
        }
      }

      .icon {
        color: ${colors.grey40};
      }
    }

    &.workspace {
      .icon {
        font-size: 0.75rem;
      }

      &.is-active {
        .icon {
          color: ${colors.success};
        }
      }
    }

    .icon {
      margin-top: 0.25rem;
      margin-right: 0.5rem;
      font-size: 0.625rem;
    }
  }

  hr {
    margin: 0.5rem 0;
  }
`;

const MenuLinks = styled.div`
  padding: 0.5rem 0;
  overflow-y: auto;
`;

const Links = styled.div`
  margin: 0.5rem 0;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-left: solid 1px ${colors.grey10};
  background-color: ${({ adminImpersonating }) => (adminImpersonating ? colors.danger10 : 'transparent')};

  &.is-open {
    ${Selector} {
      border-color: ${({ adminImpersonating }) => (adminImpersonating ? colors.danger : colors.grey5)};
    }

    ${Menu} {
      display: flex;
    }
  }
`;

const initialState = { workspaces: [], isReady: false };

const handlers = {
  ready: ({ workspaces }) => ({ workspaces, isReady: true }),
};

export default function () {
  const api = useApi();
  const auth = useAuth();
  const chat = useChat();
  const { isAdmin, isImpersonation } = useSession();
  const { workspace } = useWorkspace();
  const { member } = useMember();
  const { subscribe, unsubscribe } = useSubscription();
  const { ratingState, openRatingModal, openReviewModal } = useRating();
  const [{ workspaces }, actions] = useActions(handlers, initialState);
  const [isOpen, setOpen] = useState(false);
  const selectorElement = useRef();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces().get();
      actions.ready({ workspaces: data });
    } catch (error) {
      actions.ready({ workspaces: [] });
    }
  }, [actions, api]);

  useEffect(() => {
    const sid = subscribe([useSubscription.keys.workspace_changed, useSubscription.keys.workspace_deleted], () => {
      fetchData();
    });
    fetchData();
    return () => {
      unsubscribe(sid);
    };
  }, [fetchData, subscribe, unsubscribe]);

  useEffect(() => {
    function closeMenu(event) {
      // Prevent this firing when the user clicks the selector again
      if (selectorElement.current && selectorElement.current.contains(event.target)) {
        return false;
      }
      setOpen(false);
    }

    // Only attach event listener if menu is open
    if (isOpen) {
      document.addEventListener('mouseup', closeMenu);
    }

    return function cleanup() {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, [isOpen]);

  const confirmation = useConfirmation();

  const handleLogoutClick = async (event) => {
    if (workspaces.length > 1) {
      event.preventDefault();
      await confirmation.prompt((resolve) => <LogoutConfirmation onResolve={resolve} />);
    }
  };

  const handleShowRating = () => {
    openRatingModal();
  };

  const handleShowReview = () => {
    openReviewModal();
  };

  return (
    <>
      <Container
        adminImpersonating={isImpersonation}
        className={classNames({ 'is-open': isOpen })}
        data-testid="workspace_menu">
        <Selector ref={selectorElement} onClick={() => setOpen(!isOpen)} adminImpersonating={isImpersonation}>
          {member && (
            <>
              <Avatar value={member} size={40} isCircle hasBackground />
              <Name>
                <p>{member.name}</p>
                <p>
                  <strong>{workspace.name}</strong>
                </p>
              </Name>
            </>
          )}
          <Icon icon="angle-down" />
        </Selector>
        <Menu>
          <MenuLinks>
            {!isImpersonation && workspaces.length > 1 && (
              <>
                <Links>
                  {_.map(workspaces, ({ id, name, key, securityRoleName }) => (
                    <Link
                      key={id}
                      to={`/app/${key}`}
                      className={classNames('workspace', { 'is-active': workspace && id === workspace.id })}>
                      <Icon icon="check-circle" />
                      <div>
                        {name}
                        {securityRoleName && <SubText className="subText">{securityRoleName}</SubText>}
                      </div>
                    </Link>
                  ))}
                </Links>
                <hr role="none" />
              </>
            )}
            {isAdmin && (
              <>
                <Link to="/admin">
                  <Icon icon="wrench" />
                  Admin
                </Link>
                <hr role="none" />
              </>
            )}
            <Links>
              <Link to={`/app/${workspace.key}/my-exports`}>
                <Icon icon="file" style={{ width: 10 }} />
                My Exports
              </Link>
              <Link to={`/app/${workspace.key}/my-profile`}>
                <Icon icon="id-card" type="far" style={{ width: 10 }} />
                My Profile
              </Link>
              <hr role="none" />
            </Links>
            <Links>
              {auth.workspace.manage && moment().diff(moment(workspace.createdAt), 'days') < 90 && (
                <a
                  href="https://help.ruddr.io/hc/en-us/articles/7891003800471-The-Ruddr-Guide-to-Setting-up-Your-Workspace"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon icon="rocket" />
                  Setting Up Your Workspace
                </a>
              )}
              <a href="https://help.ruddr.io" target="_blank" rel="noopener noreferrer">
                <Icon type="far" icon="book" />
                Help Center
              </a>
              <a onClick={() => chat.open()}>
                <Icon type="far" icon="comment-lines" />
                Chat Support
              </a>
              <a
                href="https://ruddr.notion.site/What-s-New-in-Ruddr-03e6cbfdf87a4b0484c207c29e06f805"
                target="_blank"
                rel="noopener noreferrer">
                <Icon icon="lightbulb" style={{ width: 10 }} />
                What's New
              </a>
              <a href={PUBLIC_BASE_URL} target="_blank" rel="noopener noreferrer">
                <Icon icon="home" />
                Visit ruddr.com
              </a>
              {ratingState.isEligibleForRating && (
                <span onClick={handleShowRating}>
                  <Icon icon="star" />
                  How are we Doing?
                </span>
              )}
              {ratingState.isEligibleForReview && (
                <span onClick={handleShowReview}>
                  <Icon icon="star" />
                  Receive $50 for Reviewing Ruddr!
                </span>
              )}
            </Links>
            <hr role="none" />
            <Link to="/login" className="alt">
              Login to Another Workspace
            </Link>
            {!isImpersonation && (
              <>
                <hr role="none" />
                <Link to="/logout" className="alt" onClick={handleLogoutClick}>
                  Logout
                </Link>
              </>
            )}
          </MenuLinks>
        </Menu>
      </Container>
    </>
  );
}
