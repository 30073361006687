import _ from 'lodash';
import { useMemo } from 'react';
import useLocale from './useLocale';

const defaultOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

/**
 *
 * @param {{ locale, minimumFractionDigits, maximumFractionDigits }} options
 * @returns A localized hours formatter.
 */
export default function useHoursFormat({ locale, ...options } = {}) {
  locale = useLocale(locale);

  return useMemo(() => Intl.NumberFormat(locale, _.defaults({}, options, defaultOptions)), [locale, options]);
}
