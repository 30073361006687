import _ from 'lodash';

const version = {
  initial: null,
  current: null,
};

const subscriptions = {};

const parseVersion = (version) =>
  version
    ? version
        .split('.')
        .map((ver) => parseInt(ver, 10))
        .filter((ver) => _.isNumber(ver))
    : [];

export function update(ver) {
  if (!version.initial) {
    version.initial = ver;
  }
  if (version.current !== ver) {
    const notify = !!version.current && !_.isEmpty(subscriptions);
    version.current = ver;
    if (notify) {
      const status = getStatus();
      for (const callback of Object.values(subscriptions)) {
        callback(status);
      }
    }
  }
}

export function subscribe(callback) {
  const id = _.uniqueId('versub_');
  subscriptions[id] = callback;
  return () => {
    delete subscriptions[id];
  };
}

export function getStatus() {
  if (version.initial === version.current) {
    return null;
  }

  const initialVersions = parseVersion(version.initial);
  const currentVersions = parseVersion(version.current);
  if (initialVersions.length !== 3 || currentVersions.length !== 3) {
    return null;
  }

  if (currentVersions[0] > initialVersions[0]) {
    return 'noisy';
  }
  if (currentVersions[1] > initialVersions[1]) {
    return 'quiet';
  }
  if (currentVersions[2] > initialVersions[2]) {
    return 'silent';
  }
  return null;
}
