import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, put, del } from './agent';

const apiKeys =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'auth-providers', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      setDefault: () => post(url.concat('/set-default')),
      delete: () => del(url),
    };
  };

export default apiKeys;
