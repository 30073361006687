import qboIcon from '~/assets/public/qbo-icon.png';
import { BackArrowLink, Level, Page } from '~/components';
import { useWorkspace } from '~/contexts';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import QBOSettingsPage from './QBOSettingsPage';

const Header = styled.div`
  padding: 2rem 2rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h1`
  margin-bottom: 0.75rem;
  font-size: 3rem;
  font-weight: 300;
`;

const Byline = styled.h3`
  strong {
    color: ${colors.grey40};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
  }

  small {
    font-size: 0.75rem;
    color: ${colors.grey25};
  }
`;

const QBOLogo = styled.img`
  width: 3.875rem;
  height: 3.875rem;
  max-width: unset;
`;

export default function QBOSettingsArea() {
  const { workspace } = useWorkspace();

  return (
    <>
      <Header>
        <Page.Header>
          <BackArrowLink to={`/app/${workspace.key}/settings/integrations`} />
          <InfoContainer>
            <Level>
              <Level.Item>
                <Byline>
                  <strong>Settings</strong>
                </Byline>

                <Title>QuickBooks Online</Title>
              </Level.Item>
              <Level.Item right narrow>
                <QBOLogo src={qboIcon} />
              </Level.Item>
            </Level>
          </InfoContainer>
        </Page.Header>
      </Header>

      <QBOSettingsPage />
    </>
  );
}
