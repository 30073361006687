import { AutoComplete, Avatar, Dropdown, MemberContactPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function WorkspaceMemberSelect({ projectId, filterMembers, onChange }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).projects(projectId).workspaceMembers({
        q,
        size: 1000,
      });

      if (filterMembers) {
        _.remove(data, (x) => filterMembers.includes(x.id));
      }

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.isActive ? 'Active' : 'Inactive';

          a[key] = a[key] || { key, members: [] };
          a[key].members.push(v);
          return a;
        }, {}),
        'key',
      );

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', group, disabled: true });
        group.members.forEach((member) => {
          options.push({ type: 'member', member, disabled: false });
        });
      }

      return options;
    },
    [workspace.id, projectId, filterMembers, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target.name, value: event.target.value?.member } };
    onChange(changeArgument);
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      placeholder="Select a member">
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.group.key}>
                    <strong>{o.group.key}</strong>
                  </Group>
                );
              case 'member':
                return (
                  <AutoComplete.Option
                    grey={!o.member.isActive ? 'true' : undefined}
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.member.id}
                    value={o}
                    index={index++}>
                    <MemberContactPopover member={o.member} placement="left">
                      <Avatar value={o.member} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
                    </MemberContactPopover>
                    <span style={{ marginLeft: '.5rem' }}>{o.member.name}</span>
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

export default WorkspaceMemberSelect;
