import { Avatar, MemberContactPopover, PlaceholderAvatar, PlaceholderPopover } from '~/components';
import React from 'react';
import { Byline, Info, Label, Resource } from '../../components/schedule/sidebar';

export default function Row({ row }) {
  if (!row.resource) return null;
  return (
    <Resource>
      {
        {
          placeholder: (
            <PlaceholderPopover placeholderId={row.resource.id} placement="right">
              <PlaceholderAvatar value={row.resource} hasBackground isCircle size="1.75rem" initialsFontSize=".75rem" />
            </PlaceholderPopover>
          ),
          member: (
            <MemberContactPopover member={row.resource} placement="right">
              <Avatar value={row.resource} hasBackground isCircle size="1.75rem" initialsFontSize=".75rem" />
            </MemberContactPopover>
          ),
        }[row.resource.resourceType]
      }

      <Info style={{ marginLeft: '.5rem' }}>
        <Label>{row.resource.name}</Label>
        {row.resource.practice && <Byline>{row.resource.practice.name}</Byline>}
      </Info>
    </Resource>
  );
}
