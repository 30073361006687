import { SingleSelect } from '~/components';
import React from 'react';

export default function ActiveStatusSelect(props) {
  return (
    <SingleSelect
      name="isActive"
      placeholder="All"
      materialPlaceholder="Status"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </SingleSelect>
  );
}
