import React from 'react';
import DayHeader from './DayHeader';
import MonthHeader from './MonthHeader';
import WeekHeader from './WeekHeader';

function HeaderSchedule({ start, end, styles, unit, onDateChange }) {
  return {
    day: () => <DayHeader start={start} end={end} styles={styles} onDateChange={onDateChange} />,
    week: () => <WeekHeader start={start} end={end} styles={styles} onDateChange={onDateChange} />,
    month: () => <MonthHeader start={start} end={end} styles={styles} onDateChange={onDateChange} />,
  }[unit]();
}

export default React.memo(HeaderSchedule);
