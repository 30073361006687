import { Buttons, CancelButton, DeleteButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useSubscription, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useForm } from '~/hooks';
import React from 'react';
import * as Yup from 'yup';

function WorkspaceDeleteConfirmation({ workspace: { name }, isSubmitting, status, message, onClose, onSubmit }) {
  return (
    <ModalCard title="Delete Workspace" onClose={onClose}>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('Name').oneOf([name]).required(),
        })}>
        {({ isValid, values }) => {
          return (
            <Form>
              <ModalCard.Body>
                <FormMessage spaceBottom variant="warning">
                  Deleting the <strong>{name}</strong> workspace will delete all of its data. This action cannot be
                  undone.
                </FormMessage>
                <Form.Control>
                  <Field.Text
                    autoFocus
                    name="name"
                    placeholder="Enter this workspace's name to confirm"
                    hideErrorMessage
                  />
                </Form.Control>
                {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose}>Cancel</CancelButton>
                  <DeleteButton type="submit" disabled={!values.name || !isValid} isLoading={isSubmitting}>
                    Delete workspace
                  </DeleteButton>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default function ({ onClose }) {
  const { workspace, deleteWorkspace } = useWorkspace();
  const [formState, form] = useForm();
  const toast = useToast();
  const { notify } = useSubscription();

  async function handleSubmit(values) {
    try {
      form.submit();
      await deleteWorkspace(values);
      onClose();
      toast.success(`Successfully deleted workspace ${workspace.name}.`);
      notify(useSubscription.keys.workspace_deleted);
    } catch ({ status, message }) {
      return form.error({ message });
    }
  }
  return <WorkspaceDeleteConfirmation {...formState} {...{ workspace, onClose }} onSubmit={handleSubmit} />;
}
export { WorkspaceDeleteConfirmation };
