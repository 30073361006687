import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { redirectPublic } from '~/utils';

export default function RedirectPublic({ to }) {
  const location = useLocation();

  useEffect(() => {
    if (to) {
      redirectPublic(to);
    } else {
      redirectPublic(location.pathname);
    }
  }, [to, location]);

  return null;
}
