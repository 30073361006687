import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { Description } from './SalesforceSetup';

export function CaptureCredentials({ username, passphrase, onClose, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const initialValues = {
    username,
    passphrase,
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      await api.www.workspaces(workspace.id).salesforce.setup(values);
      onFinish();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        username: Yup.string().label('username').max(255).required(),
        passphrase: Yup.string().label('passphrase').max(255).required(),
      })}>
      {() => {
        return (
          <Form>
            <ModalCard.Body>
              <Description>
                To setup an integration with Salesforce, add your username and security passphrase, which is
                <code> password + securityToken</code>.
              </Description>
              <Form.Control>
                <Field.Text name="username" placeholder="Username" maxLength={255} />
              </Form.Control>
              <Form.Control>
                <Field.Text name="passphrase" type="password" placeholder="Security Passphrase" maxLength={255} />
              </Form.Control>
              {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                  Close
                </CancelButton>
                <Button type="submit" isLoading={isLoading}>
                  Save
                </Button>
              </Buttons>
            </ModalCard.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}
