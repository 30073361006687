import styled from 'styled-components';
import { colors } from '~/styles';

const DetailsPopover = styled.div`
  display: flex;
  flex-direction: column;
  width: 16rem;
  align-items: center;
  border-radius: 0.3125rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  z-index: 300;
  left: 0;
  position: relative;
  transition: opacity 50ms ease-out;

  &::before {
    content: '';
    position: absolute;
    border: solid 0.875rem transparent;
    z-index: 299;
  }

  &[data-placement='left'] {
    &::before {
      top: calc(50% - 0.875rem);
      left: 100%;
      border-left-color: ${colors.white};
    }
  }

  &[data-placement='right'] {
    &::before {
      top: calc(50% - 0.875rem);
      right: 100%;
      border-right-color: ${colors.white};
    }
  }

  &[data-placement='top'] {
    &::before {
      top: 100%;
      left: calc(50% - 0.875rem);
      border-top-color: ${colors.white};
    }
  }

  &[data-placement='bottom'] {
    &::before {
      bottom: 100%;
      left: calc(50% - 0.875rem);
      border-bottom-color: ${colors.white};
    }
  }
`;

export default DetailsPopover;
