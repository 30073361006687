import { BackLink, Currency, Hours, Icon, Level, Percent, RouteLink, Stack, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import projectBillingTypes from '~/lookups/project-billing-types.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString, mimeTypes } from '~/utils';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

export default function PerformanceByClientPractice() {
  useDocumentTitle('Performance by Client Practice');

  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectBillingTypes: {
      ...searchParamsConfig.projectBillingTypes,
      default: [projectBillingTypes.fixed, projectBillingTypes.fixed_recurring, projectBillingTypes.tm],
    },
  }));

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performanceByClientPractice(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceByClientPractice(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Performance by Client Practice</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const performanceByProject = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/financial/performance-by-project?${new QueryString(
        {
          start: params.period?.start ?? undefined,
          end: params.period?.end ?? undefined,
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters],
  );

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          billableType: ['billable', 'non_billable', 'internal'],
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters],
  );

  const {
    workspace: {
      member: { securityRole },
    },
  } = useWorkspace();

  const viewMargin = securityRole.viewProjectMargin.includes('always');

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Practice" value={report.records.filter((p) => p.id).length} />
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="name" onSort={onSort} sort={params.sort}>
            Practice
          </Table.Column>
          <Table.Column width="7rem" align="right" name="totalHours" onSort={onSort} sort={params.sort}>
            Hours
          </Table.Column>
          <Table.Column width="8rem" align="right" name="realizationRate" onSort={onSort} sort={params.sort}>
            Realization Rate
          </Table.Column>
          <Table.Column width="10rem" align="right" name="totalRevenue" onSort={onSort} sort={params.sort}>
            Earned Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right" name="effectiveRate" onSort={onSort} sort={params.sort}>
            Effective Bill Rate
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="totalCost"
            isVisible={viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Cost
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="totalProfit"
            isVisible={viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Profit
          </Table.Column>
          <Table.Column
            width="6rem"
            align="right"
            name="totalMargin"
            isVisible={viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Margin
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {report.records.map((practice) => {
            return (
              <Table.Row key={practice.id}>
                <Table.Cell>
                  {practice.id ? practice.name : <span style={{ color: colors.grey25 }}>No Client Practice</span>}
                </Table.Cell>
                <Table.Cell>
                  {practice.id ? (
                    <RouteLink to={timeDetail({ clientPractice: practice.id })}>
                      <Hours value={practice.totalHours} />
                    </RouteLink>
                  ) : (
                    <Hours value={practice.totalHours} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Percent value={practice.realizationRate} />
                </Table.Cell>
                <Table.Cell>
                  {practice.id ? (
                    <RouteLink to={performanceByProject({ clientPractice: practice.id })}>
                      <Currency value={practice.totalRevenue} currency={report.currency} />
                    </RouteLink>
                  ) : (
                    <Currency value={practice.totalRevenue} currency={report.currency} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Currency value={practice.effectiveRate} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={practice.totalCost} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={practice.totalProfit} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={practice.totalMargin} currency={report.currency} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <RouteLink to={timeDetail()}>
                <Hours value={report.totals.totalHours} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.realizationRate} />
            </Table.Cell>
            <Table.Cell>
              <RouteLink to={performanceByProject()}>
                <Currency value={report.totals.totalRevenue} currency={report.currency} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.effectiveRate} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalCost} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalProfit} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.totalMargin} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          clearable={false}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
