import React from 'react';
import TableColumn from './TableColumn';

function TableActionsColumn(props) {
  return (
    <TableColumn width="8.25rem" align="center" {...props}>
      Actions
    </TableColumn>
  );
}

export default TableActionsColumn;
