import { useNumberFormat } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const ProgressContainer = styled.div`
  font-size: 0.75rem;
  flex: 1;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
`;

const Legend = styled.legend`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
`;

const Used = styled.span`
  color: ${({ color }) => color};
`;

function AttachmentsSize({ used = 0, maxSize = 5242880, ...props }) {
  const numberFormat = useNumberFormat({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const usedInMB = numberFormat.format(used / 1048576);
  const maxSizeInMB = numberFormat.format(maxSize / 1048576);

  const percent = (used / maxSize) * 100;

  let statusColor = used > 0 ? colors.primary : colors.grey25;

  if (percent > 100) statusColor = colors.danger;
  else if (percent > 80) statusColor = colors.warning;

  return (
    <ProgressContainer {...props}>
      <Legend>
        <Used color={statusColor}>
          <p>{usedInMB} MB</p>
        </Used>

        {maxSize > 0 && <p>{maxSizeInMB} MB</p>}
      </Legend>

      <Progress>
        <ProgressLine width={`${Math.min(percent, 100)}%`} color={statusColor} />
      </Progress>
    </ProgressContainer>
  );
}

export default AttachmentsSize;
