import { Icon, Currency, Tooltip } from '~/components';
import React from 'react';
import { colors } from '~/styles';

export default function PastDueTooltip({ value, currency }) {
  if (!(value > 0)) return null;

  return (
    <Tooltip
      style={{ marginLeft: '0.25rem' }}
      message={
        <>
          Does not include{' '}
          <Currency value={value} currency={currency} minimumFractionDigits={0} maximumFractionDigits={0} /> in past
          uninvoiced fixed fee services revenue.
        </>
      }>
      <Icon icon="exclamation-triangle" color={colors.warning} />
    </Tooltip>
  );
}
