import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';

const Styled = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: ${({ variant }) => (variant ? colors.white : colors.black)};
  background-color: ${({ variant }) => (variant ? colors[variant] || colors.primary : 'transparent')};

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: ${colors.grey10};
    }
  }

  ${({ spaceTop }) =>
    spaceTop &&
    css`
      margin-top: 0.75rem;
    `}

  ${({ spaceBottom }) =>
    spaceBottom &&
    css`
      margin-bottom: 0.75rem;
    `}

  ${({ spaceRight }) =>
    spaceRight &&
    css`
      margin-right: 0.75rem;
    `}
`;

function FormMessage(props) {
  return <Styled {...props} />;
}

function HelpError({ children, ...props }) {
  return (
    <FormMessage {...props} variant="danger">
      {children || 'An error has occurred.'}
    </FormMessage>
  );
}

FormMessage.Error = HelpError;

export default FormMessage;
