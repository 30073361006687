import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApi } from './ApiContext';

const SessionContext = React.createContext({ session: null });

function SessionProvider({ children }) {
  const api = useApi();
  const [isReady, setReady] = useState(false);
  const [session, setSession] = useState(null);

  const identify = useCallback(async () => {
    try {
      const { data } = await api.www.session();
      setSession(data);
      return data;
    } catch (error) {
      if (error.status === 401) {
        setSession(null);
      } else {
        throw error;
      }
    } finally {
      setReady(true);
    }
  }, [api]);

  const login = useCallback(
    async (credentials) => {
      const { data } = await api.www.login(credentials);
      await identify();
      return data;
    },
    [api, identify],
  );

  const loginMfa = useCallback(
    async (credentials) => {
      const { data } = await api.www.loginMfa(credentials);
      await identify();
      return data;
    },
    [api, identify],
  );

  const loginRecoveryCode = useCallback(
    async (credentials) => {
      const { data } = await api.www.loginRecoveryCode(credentials);
      await identify();
      return data;
    },
    [api, identify],
  );

  const logout = useCallback(
    async (workspaceId) => {
      await api.www.logout(workspaceId);
      const session = await identify();
      if (!session) {
        if (sessionStorage) {
          sessionStorage.clear();
        }
      }
      return session;
    },
    [api, identify],
  );

  // Identify session on load
  useEffect(() => {
    identify();
  }, [identify]);

  return (
    <SessionContext.Provider
      value={{
        session,
        admin: session?.admin,
        isAdmin: !!session?.admin,
        impersonatedMemberId: session?.impersonatedMemberId,
        isImpersonation: !!session?.impersonatedMemberId,
        setSession,
        identify,
        login,
        loginMfa,
        loginRecoveryCode,
        logout,
        isReady,
        isLoggedIn: session?.memberSessions?.length > 0,
      }}>
      {children}
    </SessionContext.Provider>
  );
}

function useSession() {
  return useContext(SessionContext);
}

export { SessionContext, useSession, SessionProvider };
