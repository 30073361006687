import React, { useMemo } from 'react';
import { Hours } from '~/components';
import { theme } from '../../components/schedule';
import { GroupCell } from '../../components/schedule/cells';
import Tooltip from './Tooltip';

function AllocatedHoursCell({ cell }) {
  const { background, color } = useMemo(() => {
    switch (cell.mode) {
      case 'header':
        return {
          background: theme.cell.total.background,
          color: cell.allocated === 0 ? theme.cell.empty.color : theme.cell.total.color,
        };

      default:
        return {
          background: cell.allocated === 0 ? theme.cell.empty.background : theme.cell.filled.background,
          color: cell.allocated === 0 ? theme.cell.empty.color : theme.cell.filled.color,
        };
    }
  }, [cell.mode, cell.allocated]);

  return (
    <GroupCell style={{ background, color }}>
      <Tooltip cell={cell}>
        <Hours value={cell.allocated} minimumFractionDigits={0} />
      </Tooltip>
    </GroupCell>
  );
}

export default React.memo(AllocatedHoursCell);
