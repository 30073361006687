import { Buttons, CancelButton, DeleteButton, Field, Form, ModalCard } from '~/components';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

function RejectExpenseDialog({ count, onResolve }) {
  const handleClose = () => {
    onResolve(null);
  };

  const handleSubmit = (values) => {
    onResolve(values.notes);
  };

  return (
    <ModalCard title="Reject Expense" onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={{ notes: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          notes: Yup.string().label('Reason').max(255).required(),
        })}>
        <Form>
          <ModalCard.Body>
            <Form.Control>
              <Field.Text name="notes" placeholder="Reason" maxLength={255} autoFocus />
            </Form.Control>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Buttons align="right">
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <DeleteButton type="submit">
                Reject {count} expense {count === 1 ? 'item' : 'items'}
              </DeleteButton>
            </Buttons>
          </ModalCard.Footer>
        </Form>
      </Formik>
    </ModalCard>
  );
}

export default RejectExpenseDialog;
