import { AutoCompleteMulti } from '~/components';
import { useApi } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

export default function CurrencyMultiSelect({ value, name, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.currencies().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const handleChange = (value) => {
    onChange({ target: { name, value } });
  };

  const handleAdd = ({ code }) => {
    if (!_.some(value, code)) {
      handleChange([...value, code]);
    }
  };

  const handleRemove = (code) => {
    handleChange(value.filter((c) => c !== code));
  };

  const availableOptions = useMemo(() => {
    return options?.filter((o) => o.isActive);
  }, [options]);

  const handleSearch = (q) => {
    return availableOptions.filter((o) => [o.code, o.name].some((f) => f.toLowerCase().includes(q.toLowerCase())));
  };

  const filterOptions = (options) => options.filter(({ code }) => !value.some((v) => v === code));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      placeholder="Currencies"
      defaultOptions={filterOptions(availableOptions)}
      renderOption={(option) => `${option.name} (${option.code})`}
      renderTag={(code, props) => {
        const option = options.find((o) => o.code === code);

        if (!option)
          return (
            <AutoCompleteMulti.Tag key={code} {...props}>
              {code}
            </AutoCompleteMulti.Tag>
          );

        return (
          <AutoCompleteMulti.Tag key={option.code} {...props}>
            {option.name} ({option.code})
          </AutoCompleteMulti.Tag>
        );
      }}
      value={value}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}
    />
  );
}
