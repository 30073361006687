import { AutoCompleteMulti, Avatar, MemberContactPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

function MemberMultiSelect({ value, initialValue, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).members().options({ q, size: 1000, isActive: true });
      return data;
    },
    [workspace.id, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, initialValue, isMounted]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name: value.name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(value, { id: selectedValue.id })) {
      handleChange([...value, selectedValue]);
    }
  };

  const handleRemove = (item) => {
    handleChange(value.filter(({ id }) => id !== item.id));
  };

  const filterOptions = (options) => options.filter((o) => !value?.some((v) => v.id === o?.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => (
        <>
          <MemberContactPopover member={option} placement="left">
            <Avatar value={option} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
          </MemberContactPopover>
          <span style={{ marginLeft: '.5rem' }}>{option.name}</span>
        </>
      )}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.id} {...props}>
          <Avatar
            value={option}
            showName
            isCircle
            hasBackground
            size="1.5rem"
            fontSize="1rem"
            initialsFontSize=".625rem"
          />
        </AutoCompleteMulti.Tag>
      )}
      value={value}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}
    />
  );
}

export default MemberMultiSelect;
