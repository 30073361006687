import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Avatar, Button, Icon, SingleSelect } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull } from '~/utils';
import Actions from './Actions';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

const StatusInput = styled.div`
  width: 13rem;
  margin-left: 1rem;
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
  user-select: none;
`;

const Info = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 10rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Logo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

const WorkspaceImage = styled.img`
  max-height: 100%;
  object-fit: contain;
`;

const BuildingIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.125rem 0.875rem;
`;

const Tag = styled.div`
  display: flex;
  margin: 0.125rem;
  padding: 0.125rem;
  font-size: 0.625rem;
  background-color: ${({ color }) => color ?? colors.grey55};
  border-radius: 0.25rem;
`;

const TagLabel = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.grey55};
  background-color: ${colors.white};
  border-radius: 0.25rem;
`;

const TagValue = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
`;

const ResultIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  transform: rotate(-90deg);
  transition: transform 0.1s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(0deg);
    `}
`;

const ResultIcon = styled(Icon)`
  color: ${colors.grey55};
`;

const DetailNav = styled.ul`
  display: flex;
  padding: 1rem 1.25rem;
  border-top: solid 1px ${colors.grey10};
`;

const DetailNavItem = styled.li`
  font-weight: ${({ isActive }) => (isActive ? weights.bold : weights.normal)};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &:hover {
    color: ${colors.grey75};
  }
`;

function getColorForStatus(statusId) {
  switch (statusId) {
    case 'disabled':
      return colors.danger;
    default:
      return undefined;
  }
}

export default function Search() {
  const api = useApi();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('active');
  const [searchResults, setSearchResults] = useState();
  const [viewState, setViewState] = useState({});
  const searchRef = useRef();

  const search = useCallback(async () => {
    const params = { text: searchText, statusId: searchStatus };
    const cleanedParams = _.omitBy(emptyStringToNull(params), _.isNil);

    setIsSearching(true);
    try {
      const { data } = await api.admin.members.get(cleanedParams);
      setSearchResults(data);
    } finally {
      setIsSearching(false);
    }
  }, [api, searchText, searchStatus]);

  useEffect(() => {
    search();
    return search.cancel;
  }, [search]);

  useEffect(() => {
    if (!searchText) {
      setSearchResults();
      return;
    }
    search(searchText);
    return search.cancel;
  }, [search, searchText]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitSearchChange = useCallback(
    _.debounce((value) => setSearchText(value), 250),
    [],
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    emitSearchChange(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const toggleDetails = (memberId) => {
    setViewState((current) => {
      const next = { ...current };
      if (!next[memberId]) {
        next[memberId] = {
          open: false,
          view: 'actions',
        };
      }
      next[memberId].open = !next[memberId].open;
      return next;
    });
  };

  const changeView = (memberId, view) => {
    setViewState((current) => {
      const next = { ...current };
      next[memberId].view = view;
      return next;
    });
  };

  return (
    <SearchArea>
      <SearchFilters>
        <SearchInput>
          <input
            ref={searchRef}
            value={searchValue}
            onChange={handleSearchChange}
            type="search"
            placeholder="Find a member..."
          />
          {!!searchValue && (
            <ClearButton isAnchor={true} onClick={handleClearSearch}>
              <Icon icon="times" />
            </ClearButton>
          )}
          {isSearching && (
            <SearchIndicator>
              <Icon icon="spinner" spin={true} />
            </SearchIndicator>
          )}
        </SearchInput>
        <StatusInput>
          <SingleSelect
            placeholder="Status"
            showEmptyOption={true}
            value={searchStatus}
            onChange={(event) => setSearchStatus(event.target.value)}>
            <option value="active">Active</option>
            <option value="disabled">Disabled</option>
          </SingleSelect>
        </StatusInput>
      </SearchFilters>
      {searchResults && (
        <SearchResults>
          {searchResults.map((member) => (
            <SearchResult key={`member_${member.id}`}>
              <Result onClick={() => toggleDetails(member.id)}>
                <Avatar value={member} size={40} isCircle hasBackground />
                <Info>
                  <Title>{member.name}</Title>
                  <Subtitle>{member.email}</Subtitle>
                </Info>
                <Logo>
                  {member.workspace.imageUrl ? (
                    <WorkspaceImage src={member.workspace.imageUrl} alt={member.workspace.name} />
                  ) : (
                    <BuildingIcon icon="building" />
                  )}
                </Logo>
                <Info>
                  <Title>{member.workspace.name}</Title>
                  <Subtitle>/app/{member.workspace.key}</Subtitle>
                </Info>
                <Tags>
                  <Tag color={member.isActive ? colors.primary : colors.warning}>
                    <TagValue>{member.isActive ? 'Active' : 'Not Active'}</TagValue>
                  </Tag>
                  <Tag color={member.isBillable ? colors.primary : colors.warning}>
                    <TagValue>{member.isBillable ? 'Billable' : 'Not Billable'}</TagValue>
                  </Tag>
                  <Tag color={member.isVerified ? colors.primary : colors.warning}>
                    <TagValue>{member.isVerified ? 'Verified' : 'Not Verified'}</TagValue>
                  </Tag>
                  <Tag color={getColorForStatus(member.statusId)}>
                    <TagLabel>Internal Status</TagLabel>
                    <TagValue>{member.statusId}</TagValue>
                  </Tag>
                  <Tag>
                    <TagLabel>Created</TagLabel>
                    <TagValue>{moment(member.createdAt).format(dateFormats.compactDate)}</TagValue>
                  </Tag>
                  {!!member.accessedAt && (
                    <Tag>
                      <TagLabel>Last Active</TagLabel>
                      <TagValue>{moment(member.accessedAt).format(dateFormats.compactDateTime)}</TagValue>
                    </Tag>
                  )}
                </Tags>
                <ResultIndicator isOpen={viewState[member.id]?.open === true}>
                  <ResultIcon icon="angle-down" />
                </ResultIndicator>
              </Result>
              {viewState[member.id]?.open === true && (
                <>
                  <DetailNav>
                    <DetailNavItem
                      isActive={viewState[member.id]?.view === 'actions'}
                      onClick={() => changeView(member.id, 'actions')}>
                      Actions
                    </DetailNavItem>
                  </DetailNav>
                  {viewState[member.id]?.view === 'actions' && <Actions member={member} onChange={() => search()} />}
                </>
              )}
            </SearchResult>
          ))}
        </SearchResults>
      )}
    </SearchArea>
  );
}
