import slugify from '@sindresorhus/slugify';
import { BackLink, Currency, DateTime, ExportDialog, Icon, Level, Page } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import path from 'path-browserify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { mimeTypes } from '~/utils';
import ExportDropdown from './ExportDropdown';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Area = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const ExpenseReportName = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const EditLink = styled(Link)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.grey10};
  }

  transition: all 100ms ease-in-out;
`;

function Header({ expenseReport, member }) {
  const api = useApi();
  const location = useLocation();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const {
    title,
    number,
    date,
    expenseItems,
    isExternallyManaged,
    id,
    convertedTotalAmount,
    convertedBillableAmount,
    convertedReimbursableAmount,
  } = expenseReport;

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .expenseReports(id)
          .export({
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  const getFileName = () => {
    const reportMember = member || workspace.member;

    let memberName = slugify(reportMember.name, { separator: '_', lowercase: false });

    if (!memberName) {
      memberName = slugify(reportMember.email.split('@')[0], { separator: '_', lowercase: false });
    }

    const fileName = `${memberName}_${number}`;

    return fileName;
  };

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/expenses/reports`} />
      <InfoContainer>
        <Area>Expense Report</Area>
        <ExpenseReportName>
          {title} (#{number})
        </ExpenseReportName>
        <Tags>
          {(member || workspace.member).permissions?.manageTimeAndExpenses && <Tag>{expenseReport.member?.name}</Tag>}
          <Tag>
            <DateTime value={date} />
          </Tag>
          <Tag>{expenseItems?.length} item(s)</Tag>
          <Tag>
            <Currency value={convertedReimbursableAmount} /> reimbursable
          </Tag>
          <Tag>
            <Currency value={convertedBillableAmount} /> billable
          </Tag>
          <Tag>
            <strong>
              <Currency value={convertedTotalAmount} /> total
            </strong>
          </Tag>
        </Tags>
      </InfoContainer>

      <Level style={{ marginLeft: 'auto' }}>
        <Level.Item narrow rigth>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    const fileName = getFileName();
                    await handleExport(`${fileName}.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    const fileName = getFileName();
                    await handleExport(`${fileName}.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    const fileName = getFileName();
                    await handleExport(`${fileName}.pdf`, mimeTypes.pdf);
                    setIsOpen(false);
                  }}>
                  Export to PDF
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    const fileName = getFileName();
                    await handleExport(`${fileName}.zip`, mimeTypes.zip);
                    setIsOpen(false);
                  }}>
                  Export to ZIP
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
        {!isExternallyManaged && (
          <Level.Item>
            <EditLink to={resolveEditPath('edit', location)}>
              <Icon icon="pencil-alt" />
            </EditLink>
          </Level.Item>
        )}
      </Level>
    </Page.Header>
  );
}

export default Header;
