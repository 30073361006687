import React, { useContext, useState } from 'react';

const ConfirmationContext = React.createContext();

function ConfirmationProvider({ children }) {
  const [dialog, setDialog] = useState();

  const handlePrompt = (component) => {
    const promise = new Promise((resolve) => {
      const handleResolve = (value) => {
        setDialog(null);
        resolve(value);
      };

      setDialog(component(handleResolve));
    });

    return promise;
  };

  return (
    <ConfirmationContext.Provider value={{ prompt: handlePrompt }}>
      {children}
      {dialog}
    </ConfirmationContext.Provider>
  );
}

function useConfirmation() {
  return useContext(ConfirmationContext);
}

export { ConfirmationContext, useConfirmation, ConfirmationProvider };
