import { useAuth, useDocumentTitle } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

function FinancialReportsPage() {
  useDocumentTitle('Financial Reports');

  const { url } = useRouteMatch();
  const auth = useAuth();

  return (
    <LandingPage>
      {auth.payments.view && (
        <NavBox to={url.concat('/accounts-receivable-aging')}>
          <NavBox.Title>Accounts Receivable Aging</NavBox.Title>
          <NavBox.Description>
            Receivables by client grouped by current, <nobr>1-30</nobr> days late, <nobr>31-60</nobr> days late,{' '}
            <nobr>61-90</nobr> days late, and over 90 days late.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.client && (
        <NavBox to={url.concat('/client-statement')}>
          <NavBox.Title>Client Statement</NavBox.Title>
          <NavBox.Description>View invoices, credit notes, payments and balance for a client.</NavBox.Description>
        </NavBox>
      )}

      {auth.creditNotes.view && (
        <NavBox to={url.concat('/credit-notes')}>
          <NavBox.Title>Credit Notes</NavBox.Title>
          <NavBox.Description>
            Credit notes including each credit note's number, client, projects, issued date, status, balance and total
            amount.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.creditNotes.view && (
        <NavBox to={url.concat('/credit-note-detail')}>
          <NavBox.Title>Credit Note Detail</NavBox.Title>
          <NavBox.Description>
            Credit notes including the credit note summary information and each of the credit note line items.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.fixedFeeRevenue && (
        <NavBox to={url.concat('/fixed-fee-revenue')}>
          <NavBox.Title>Fixed Fee Services Revenue by Client and Project</NavBox.Title>
          <NavBox.Description>
            A summary of fixed fee services revenue, including recognized, unrecognized, invoiced and uninvoiced
            revenue, grouped by client and project.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.hourlyFees && (
        <NavBox to={url.concat('/hourly-revenue-by-client-and-project')}>
          <NavBox.Title>Hourly Revenue by Client and Project</NavBox.Title>
          <NavBox.Description>
            A summary of hourly revenue earned by project role or member, grouped by client and project.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.project && (
        <NavBox to={url.concat('/invoices')}>
          <NavBox.Title>Invoices</NavBox.Title>

          <NavBox.Description>
            Invoices including each invoice's number, client, projects, issued date, due date, status, balance and total
            amount.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.project && (
        <NavBox to={url.concat('/invoices-by-client')}>
          <NavBox.Title>Invoices by Client</NavBox.Title>
          <NavBox.Description>
            Invoices grouped by client including each invoice's number, projects, issued date, due date, status, balance
            and total amount.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.project && (
        <NavBox to={url.concat('/invoice-detail')}>
          <NavBox.Title>Invoice Detail</NavBox.Title>
          <NavBox.Description>
            Invoices including the invoice summary information and each of the invoice line items.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.project && (
        <NavBox to={url.concat('/payments')}>
          <NavBox.Title>Payments</NavBox.Title>
          <NavBox.Description>
            Payments including each payment's date, client, related invoices, reference number and total amount.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByClient && (
        <NavBox to={url.concat('/performance-by-client')}>
          <NavBox.Title>Performance by Client</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each client including the hours, realization rate, services revenue, effective
            bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByClientPractice && (
        <NavBox to={url.concat('/performance-by-client-practice')}>
          <NavBox.Title>Performance by Client Practice</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each client grouped by its practice, including the hours, realization rate,
            earned revenue, effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByMember && (
        <NavBox to={url.concat('/performance-by-member')}>
          <NavBox.Title>Performance by Member</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each member including the hours, realization rate, services revenue, effective
            bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByMemberPractice && (
        <NavBox to={url.concat('/performance-by-member-practice')}>
          <NavBox.Title>Performance by Member Practice</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each member grouped by its practice, including the hours, realization rate,
            services revenue, effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByProject && (
        <NavBox to={url.concat('/performance-by-project')}>
          <NavBox.Title>Performance by Project</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each project including the hours, realization rate, budget revenue, earned
            revenue, budget left, effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByProjectAndMember && (
        <NavBox to={url.concat('/performance-by-project-and-member')}>
          <NavBox.Title>Performance by Project and Member</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each project and its members, including the hours, realization rate, revenue,
            effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByProjectAndTask && (
        <NavBox to={url.concat('/performance-by-project-and-task')}>
          <NavBox.Title>Performance by Project and Task</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each project and its tasks, including the hours, realization rate, services
            revenue, effective bill rate, labor cost, services profit and services margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByProjectPractice && (
        <NavBox to={url.concat('/performance-by-project-practice')}>
          <NavBox.Title>Performance by Project Practice</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each project grouped by its practice, including the hours, realization rate,
            earned revenue, effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.performanceByProjectType && (
        <NavBox to={url.concat('/performance-by-project-type')}>
          <NavBox.Title>Performance by Project Type</NavBox.Title>
          <NavBox.Description>
            The high-level performance of each project grouped by its type, including the hours, realization rate,
            earned revenue, effective bill rate, cost, profit and margin.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.projects.viewRevenue && (
        <NavBox to={url.concat('/scheduled-items')}>
          <NavBox.Title>Scheduled Items</NavBox.Title>
          <NavBox.Description>
            A list of scheduled items including fixed fee milestones, other items and project expense items.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.invoices.view.project && (
        <NavBox to={url.concat('/transaction-summary-by-invoice-item')}>
          <NavBox.Title>Transaction Summary by Invoice Item</NavBox.Title>
          <NavBox.Description>
            A summary of all transactions grouped by invoice item including the item name, type and amount.
          </NavBox.Description>
        </NavBox>
      )}

      {auth.reports.uninvoicedRevenue && (
        <NavBox to={url.concat('/uninvoiced-revenue')}>
          <NavBox.Title>Uninvoiced Revenue</NavBox.Title>
          <NavBox.Description>
            A list of time entries, expense report items, fixed fee milestones, project expenses, and other items that
            have not been invoiced.
          </NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}

export default FinancialReportsPage;
