import { Currency, DateTime, Dropdown } from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import _ from 'lodash';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components';
import { colors, weights } from '~/styles';

const ReportInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const StatusIndicator = styled.div`
  min-width: 100%;
`;

const StyledCurrency = styled.span`
  font-weight: ${weights.normal};
`;

const Meter = styled.div`
  display: flex;
  min-height: 0.75rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey10};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.125rem;
  min-width: 100%;
`;

const InsideBar = styled.div`
  min-width: 0.1rem;
  border-radius: 0.25rem;
  background: ${colors.primary100};
`;

const StatusDescription = styled.div`
  min-width: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: ${colors.grey75};
`;

function Row({
  expenseReport,
  expenseReport: { notes, number, title, date, convertedTotalAmount },
  onDelete,
  onClone,
}) {
  const { url } = useRouteMatch();
  const history = useHistory();

  const viewURL = `${url}/${expenseReport.number}`;
  const editURL = `${url}/edit/${expenseReport.number}`;
  const approvedCount = _.sumBy(expenseReport.expenseItems, (item) => (item.statusId === 'approved' ? 1 : 0));
  const itemCount = expenseReport.expenseItems?.length;
  const percentApproved = (itemCount ? 100 * (approvedCount / itemCount) : 0).toFixed(2) + '%';

  const allowEdit = !expenseReport.isExternallyManaged;
  const allowClone = !expenseReport.isExternallyManaged;
  const allowDelete = !expenseReport.isExternallyManaged;

  const handleEdit = () => history.push(editURL);
  const handleView = () => history.push(viewURL);
  const handleClone = () => {
    onClone(expenseReport);
  };

  return (
    <Table.BoxRow onClick={handleView}>
      <Table.Cell>{number}</Table.Cell>
      <Table.Cell>
        <ReportInfo>
          <p>
            <Link style={{ color: colors.black }} to={viewURL}>
              {title}
            </Link>
          </p>
          {notes && <small>{notes}</small>}
        </ReportInfo>
      </Table.Cell>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>
        <StatusIndicator>
          <Meter>
            <InsideBar style={{ width: percentApproved }}></InsideBar>
          </Meter>
          <StatusDescription>
            {itemCount === 0 && <>No expense items</>}
            {itemCount > 0 && approvedCount === itemCount && <>All {itemCount} items approved.</>}
            {approvedCount !== itemCount && (
              <>
                {approvedCount} out of {itemCount} items approved
              </>
            )}
          </StatusDescription>
        </StatusIndicator>
      </Table.Cell>
      <Table.Cell>
        <StyledCurrency>
          <Currency value={convertedTotalAmount} />
        </StyledCurrency>
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.View onClick={() => handleView(expenseReport)} />
        <hr />
        <TableBoxRowActions.Dropdown offsetToShowMenuOnTop={125}>
          <>
            <Dropdown.Item onClick={() => handleView(expenseReport)}>View</Dropdown.Item>
            <Dropdown.Item disabled={!allowEdit} onClick={handleEdit}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item disabled={!allowClone} onClick={handleClone}>
              Clone
            </Dropdown.Item>
            <Dropdown.DeleteItem
              disabled={!allowDelete}
              tooltip={'One or more of the items in this expense report is locked.'}
              onCheckDependencies={async (workspace) =>
                (await workspace.expenseReports(expenseReport.id).hasDependencies()).data
              }
              onClick={() =>
                onDelete({
                  name: expenseReport.title,
                  typeName: 'expense report',
                  expenseReportId: expenseReport.id,
                })
              }>
              Delete
            </Dropdown.DeleteItem>
          </>
        </TableBoxRowActions.Dropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

export default Row;
