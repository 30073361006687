import React, { useEffect, useState } from 'react';
import { Button, Buttons, CancelButton, Icon, ModalCard } from '~/components';
import { colors } from '~/styles';
import { saveAs } from 'file-saver';

function ExportDialog({ filename, onLoad, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [report, setReport] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await onLoad;
        setReport(data);
      } catch {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [onLoad]);

  return (
    <ModalCard title="Download Report" onClose={onClose}>
      <ModalCard.Body>
        {!!isLoading && <p>Please wait while we generate your file...</p>}
        {!!hasError && (
          <p>
            <Icon icon="exclamation-circle" color={colors.danger} /> There was a problem getting your file, please try
            again later.
          </p>
        )}
        {!isLoading & !hasError ? <p>Your file is now ready to download.</p> : null}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
          {!!isLoading && <Button isLoading={true} />}
          {!isLoading & !hasError ? (
            <Button
              onClick={async () => {
                saveAs(report, filename);
              }}>
              Download
            </Button>
          ) : null}
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default ExportDialog;
