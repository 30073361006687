import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function ClientInstruction() {
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>No</td>
            <td>The ID of the client to import, it will be used as a reference for other imported data types.</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>Yes</td>
            <td>Name of the client, this is a required field.</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>No</td>
            <td>Email address of the client.</td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>No</td>
            <td>Notes related to the client.</td>
          </tr>
          <tr>
            <td>Street Address</td>
            <td>No</td>
            <td>Street Address of the client</td>
          </tr>
          <tr>
            <td>QBO Customer ID</td>
            <td>No</td>
            <td>
              Quickbooks Online uses an ID that is a short-reference to a customer. Leave this column blank if you don't
              use quickbooks.
            </td>
          </tr>
          <tr>
            <td>Payment Terms</td>
            <td>No</td>
            <td>
              Use one of the following 'net_10', 'net_15', 'net_30', 'net_45', 'net_60', 'net_90' or 'due_on_receipt'.
            </td>
          </tr>
          <tr>
            <td>Practice</td>
            <td>No</td>
            <td>
              Set's the client's practice assignment by matching the name of a Practice already in the workspace. The
              name matching is strict and must be accurate on captialization, spacing, and punctuation. If no match is
              found no practice will be assigned during this import.
            </td>
          </tr>
          <tr>
            <td>Relationship Owner</td>
            <td>No</td>
            <td>
              The email address of the relationship owner. The value provided must match a Ruddr member's email as it
              appears in the member's workspace profile.
            </td>
          </tr>
          <tr>
            <td>Tags</td>
            <td>No</td>
            <td>
              Client tags are used in filtering and organizing clients into groups. This field can include one tag or be
              a semi-colon delimited list of tags associated with the client. If a tag doesn't exist in your workspace,
              it will automatically be created with this import.
            </td>
          </tr>
          <tr>
            <td>Archived</td>
            <td>No</td>
            <td>
              Provde a value of 'yes' if the client is archived, otherwise the imported client will be considered
              active.
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td>No</td>
            <td>
              Set's the client's location assignment by matching the name of a Location already in the workspace. The
              name matching is strict and must be accurate on captialization, spacing, and punctuation. If no match is
              found no practice will be assigned during this import.
            </td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
