import { ActionButton, Button, Buttons, CancelButton, FieldControl, Form, ModalCard } from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const BodyContainer = styled.div`
  padding: 2.5rem;
`;

const Actions = styled(Buttons)`
  margin-top: 1.75rem;

  ${Button} {
    width: 11rem;

    &:last-child {
      margin-left: 1rem;
    }
  }
`;

function ClientApprovalWebLinkModal({ clientApproval, onClose }) {
  const { workspace } = useWorkspace();
  const [ok, setOk] = useState(false);
  const isMounted = useIsMounted();

  const url = `${window.location.origin}/${workspace.key}/client-approvals/${clientApproval.id}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setOk(true);
    setTimeout(() => {
      if (isMounted) setOk(false);
    }, 1000);
  };

  return (
    <ModalCard title="Web Link to Client Approval" onClose={onClose}>
      <ModalCard.Body>
        <BodyContainer>
          <Form.Control>
            <FieldControl>
              <ReadTextbox label="Web Link URL" value={url} />
            </FieldControl>
          </Form.Control>
          <Actions align="center">
            <CancelButton onClick={onClose}>Close</CancelButton>
            <ActionButton ok={ok} onClick={handleCopy}>
              Copy URL
            </ActionButton>
          </Actions>
        </BodyContainer>
      </ModalCard.Body>
    </ModalCard>
  );
}

export default ClientApprovalWebLinkModal;
