import { useState, useCallback } from 'react';
import _ from 'lodash';

function useSubscription() {
  const [subscriptions, setSubscriptions] = useState([]);

  const subscribe = useCallback((type, cb) => {
    const subscription = { id: _.uniqueId('subscription_'), type, cb };
    setSubscriptions((subscriptions) => [...subscriptions, subscription]);
    return subscription.id;
  }, []);

  const unsubscribe = useCallback((id) => {
    setSubscriptions((subscriptions) => subscriptions.filter((s) => s.id !== id));
  }, []);

  const notify = useCallback(
    (type) => {
      subscriptions.forEach((s) => {
        if ((_.isArray(s.type) && s.type.some((t) => t === type)) || s.type === type) {
          s.cb(s);
        }
      });
    },
    [subscriptions],
  );

  return { subscribe, unsubscribe, notify };
}

export default useSubscription;
