import { AutoComplete, Dropdown, InternalClientTooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function ProjectSelect({ value, client, initialValue, onChange, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .opportunities()
        .projects({
          q,
          recordStatusId: 'active',
          clientId: client ? client.id : undefined,
          include: initialValue ? initialValue.id : undefined,
        });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.client.id;

          a[key] = a[key] || { client: v.client, projects: [] };
          a[key].projects.push(v);
          return a;
        }, {}),
        'key',
      );

      groups.forEach((group) => {
        group.projects = _.orderBy(group.projects, 'name');
      });

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', client: group.client });
        group.projects.forEach((project) => {
          options.push({ type: 'project', project });
        });
      }

      return options;
    },
    [workspace.id, initialValue, client, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target?.name, value: event.target.value?.project } };
    onChange(changeArgument);
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      renderValue={(option) => option.project?.name}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group key={o.client?.id}>
                    <strong>{o.client?.name}</strong>
                    {o.client.isInternal && <InternalClientTooltip />}
                  </Group>
                );
              case 'project':
                return (
                  <AutoComplete.Option padding="0.25rem 0 0.25rem 2rem" key={o.project?.id} value={o} index={index++}>
                    {o.project?.name}
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

export default ProjectSelect;
