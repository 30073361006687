import { Confirmation, DateTime, Hours, Icon, Table, TimeLockIcon, Tooltip } from '~/components';
import { useConfirmation } from '~/contexts';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { colors } from '~/styles';
import {
  Cell,
  DeleteButton,
  DeleteCell,
  Details,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  ProjectList,
  RejectionReason,
  Row,
  ToggleSwitch,
} from '../components/ListComponents';

export default function TimeTab({ clientApproval, onTimeEntryClick, onDelete }) {
  const confirmation = useConfirmation();

  const groups = useMemo(() => {
    return _.orderBy(
      clientApproval.timeEntries.reduce((a, v) => {
        a[v.member.id] = a[v.member.id] || { member: v.member, entries: [] };
        a[v.member.id].entries.push(v);
        return a;
      }, {}),
      'member.name',
    );
  }, [clientApproval.timeEntries]);

  const handleDelete = async (entries) => {
    await confirmation.prompt((resolve) => (
      <Confirmation
        title="Remove Time"
        resolve={async (result) => {
          if (result) await onDelete(entries);
          resolve(result);
        }}>
        Are you sure you want to remove{' '}
        {entries.length === 1 ? 'this time entry' : `these ${entries.length} time entries`} from the client approval?
      </Confirmation>
    ));
  };

  return (
    <ProjectList>
      <Table.Total value={clientApproval.timeEntries.length} label="Time Entry" />

      <ListHeader>
        <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem" />

        <Cell padding="0.875rem 1rem">Member</Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Pending
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Client Approved
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Client Rejected
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Total
        </Cell>

        <Cell $width="4rem" padding="0.875rem 1rem" />

        <Cell $width="calc(3.25rem + 1px)" padding="0.875rem 1rem" />
      </ListHeader>

      {clientApproval.timeEntries.length === 0 && (
        <NoResultsCell>There are no time entries on this client approval.</NoResultsCell>
      )}

      {_.map(groups, (group, key) => {
        const handleDeleteGroup = (e) => {
          e.stopPropagation();
          handleDelete(group.entries.map((e) => e.id));
        };

        return (
          <Group key={key} group={group} status={clientApproval.statusId} onDelete={onDelete && handleDeleteGroup}>
            {group.entries.map((entry) => {
              const handleRowClick = () => {
                onTimeEntryClick(entry, entry.isLocked ? 'view' : 'edit');
              };

              const handleDeleteEntry = (e) => {
                e.stopPropagation();
                handleDelete([entry.id]);
              };

              return (
                <Row key={entry.id} clickable onClick={handleRowClick} data-testid="row">
                  <Cell $width="2.875rem" padding="0 0 0 1.25rem">
                    <TimeLockIcon value={entry.lockStatusId} />
                  </Cell>

                  <Cell $width="8rem">
                    <DateTime value={entry.date} />
                  </Cell>

                  {clientApproval.project.useRoles && (
                    <Cell $width="12rem">{entry.role?.name && <p>{entry.role.name}</p>}</Cell>
                  )}

                  <Cell>
                    <Details>
                      {entry.task && <p>{entry.task.name}</p>}

                      <Notes>{entry.notes ? <em>"{entry.notes}"</em> : 'No notes provided'}</Notes>

                      {entry.clientStatusId === 'rejected' && entry.clientRejectionReason && (
                        <RejectionReason>{entry.clientRejectionReason}</RejectionReason>
                      )}
                    </Details>
                  </Cell>

                  <Cell right $width="8rem">
                    <Hours value={entry.minutes / 60} />
                  </Cell>

                  <Cell right $width="4rem">
                    {clientApproval.statusId === 'submitted' &&
                      {
                        approved: (
                          <Tooltip message="Client Approved">
                            <Icon icon="thumbs-up" color={colors.primary50} />
                          </Tooltip>
                        ),
                        rejected: (
                          <Tooltip message="Client Rejected">
                            <Icon icon="thumbs-down" color={colors.danger50} />
                          </Tooltip>
                        ),
                      }[entry.clientStatusId]}
                  </Cell>

                  <DeleteCell>
                    <DeleteButton onClick={handleDeleteEntry}>
                      <Icon icon="trash" />
                    </DeleteButton>
                  </DeleteCell>
                </Row>
              );
            })}
          </Group>
        );
      })}
    </ProjectList>
  );
}

const filter = (status) => (entry) => entry.clientStatusId === status;
const sum = (entry) => _.round(entry.minutes / 60, 2);

function Group({ group, status, children, onDelete }) {
  const [collapsed, setCollapsed] = useState(true);

  const hours = useMemo(() => {
    return {
      pending: status !== 'submitted' ? _.round(_(group.entries).sumBy(sum), 2) : 0,
      approved: status === 'submitted' ? _.round(_(group.entries).filter(filter('approved')).sumBy(sum), 2) : 0,
      rejected: status === 'submitted' ? _.round(_(group.entries).filter(filter('rejected')).sumBy(sum), 2) : 0,
      total: _.round(_(group.entries).sumBy(sum), 2),
    };
  }, [group, status]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ListGroup>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right color={colors.warning} $width="8rem">
          <Hours value={hours.pending} />
        </Cell>

        <Cell right color={colors.success} $width="8rem">
          <Hours value={hours.approved} />
        </Cell>

        <Cell right color={colors.danger} $width="8rem">
          <Hours value={hours.rejected} />
        </Cell>

        <Cell right $width="8rem">
          <Hours value={hours.total} />
        </Cell>

        <Cell right $width="4rem" />

        <DeleteCell>
          <DeleteButton onClick={onDelete}>
            <Icon icon="trash" />
          </DeleteButton>
        </DeleteCell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}
