import { Icon, Level, OpportunityStageFilter, SearchInput, SingleSelect, Stack } from '~/components';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import OpportunitiesListPage from '../pipeline/opportunities/OpportunitiesListPage';

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const Filters = ({ params, handleChange }) => {
  const { url } = useRouteMatch();

  return (
    <Level padding="0.5rem 0 0 0">
      <Level.Item width="20rem">
        <SearchInput
          value={params.q}
          placeholder="All"
          materialPlaceholder="Search"
          materialAlwaysVisible
          onChange={handleChange}
        />
      </Level.Item>

      <Level.Item width="20rem">
        <SingleSelect
          name="opportunityStageStatusId"
          placeholder="All"
          showEmptyOption
          materialPlaceholder="Stage Category"
          materialAlwaysVisible
          value={params.opportunityStageStatusId}
          onChange={handleChange}>
          <option value="open">Open</option>
          <option value="won">Won</option>
          <option value="lost">Lost</option>
        </SingleSelect>
      </Level.Item>

      <Level.Item>
        <OpportunityStageFilter
          name="opportunityStages"
          value={params.opportunityStages}
          opportunityStageStatusId={params.opportunityStageStatusId}
          onChange={handleChange}
        />
      </Level.Item>

      <Level.Item right narrow>
        <AddLink to={`${url}/new`} className="button">
          <Icon icon="fa-plus" />
        </AddLink>
      </Level.Item>
    </Level>
  );
};

function CompanyOpportunitiesTab({ companyId }) {
  return (
    <Stack margin="2rem 0 0">
      <OpportunitiesListPage mode="tab" companyId={companyId} renderFilters={(props) => <Filters {...props} />} />
    </Stack>
  );
}

export default CompanyOpportunitiesTab;
