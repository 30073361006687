import _ from 'lodash';
import { Hours } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Container = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const Info = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.div`
  width: 10rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const Value = styled.div`
  white-space: wrap;
`;

const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.white};
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  bottom: -4px;
`;

const ProjectDetails = styled.div`
  max-width: 21rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  z-index: 300;
`;

function ProjectPopover({ projectId, placement = 'top-start', ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const modifiers = [
    { name: 'offset', options: { offset: [-24, 12] } },
    { name: 'arrow', options: { element: arrowElement } },
  ];

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement,
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  useEffect(() => {
    if (!projectId || !visible || query.isReady) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).allocations().getProjectDetails(projectId);
        setQuery({ isReady: true, data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, projectId, visible, query.isReady]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const project = query.data;

  return (
    <>
      <span ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        query.isReady &&
        ReactDOM.createPortal(
          <ProjectDetails
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Container>
              <Info>
                <Label>Project Name</Label>
                <Value>{project.name}</Value>
              </Info>

              <Info>
                <Label>Project Status</Label>
                <Value>{project.status.name}</Value>
              </Info>

              {project.useBudget && _.isNumber(project.budgetHoursLeft) && (
                <Info>
                  <Label>Budget Left</Label>
                  <Value>
                    <Hours value={project.budgetHoursLeft} minimumFractionDigits={0} />{' '}
                    {pluralize('hours', project.budgetHoursLeft)}
                  </Value>
                </Info>
              )}

              <Info>
                <Label>Allocated Left</Label>
                <Value>
                  <Hours value={project.allocatedHoursLeft} minimumFractionDigits={0} />{' '}
                  {pluralize('hours', project.allocatedHoursLeft)}
                </Value>
              </Info>

              {project.administrators?.length > 0 && (
                <Info>
                  <Label>Project Admin</Label>
                  <Value>{project.administrators.map(({ member }) => member.name).join(', ')}</Value>
                </Info>
              )}

              {project.projectType && (
                <Info>
                  <Label>Project Type</Label>
                  <Value>{project.projectType.name}</Value>
                </Info>
              )}

              {project.practice && (
                <Info>
                  <Label>Project Practice</Label>
                  <Value>{project.practice.name}</Value>
                </Info>
              )}

              <Info>
                <Label>Billing Type</Label>
                <Value>{project.billingType?.name}</Value>
              </Info>

              {project.code && (
                <Info>
                  <Label>Project Code</Label>
                  <Value>{project.code}</Value>
                </Info>
              )}

              {project.tags?.length > 0 && (
                <Info>
                  <Label>Tags</Label>
                  <Value>{project.tags.map((tag) => tag.name).join(', ')}</Value>
                </Info>
              )}
            </Container>
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </ProjectDetails>,
          document.body,
        )}
    </>
  );
}

export default ProjectPopover;
