import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import { useMemo } from 'react';
import useLocale from './useLocale';

const defaultOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

/**
 *
 * @param {{ locale, currency, minimumFractionDigits, maximumFractionDigits }} options
 * @returns A localized currency formatter.
 */
export default function useCurrencyFormat({ locale, currency, ...options } = {}) {
  locale = useLocale(locale);

  const { workspace } = useWorkspace();
  if (!currency) {
    if (workspace) currency = workspace.currency;
  }
  if (!currency) throw new Error(`Missing argument 'currency'.`);

  return useMemo(
    () =>
      Intl.NumberFormat(
        locale,
        _.defaults(
          {
            style: 'currency',
            currency,
          },
          options,
          defaultOptions,
        ),
      ),
    [locale, currency, options],
  );
}
