import { ActionButton, Buttons, CancelButton, Checkbox, Level, ModalCard } from '~/components';
import { useApi, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function UnpublishInvoiceDialog({ invoice, resolve }) {
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();
  const [removeFromQuickBooks, setRemoveFromQuickBooks] = useState(false);
  const [removeFromXero, setRemoveFromXero] = useState(false);
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    if (removeFromQuickBooks) {
      try {
        await api.www.workspaces(workspace.id).invoices(invoice.id).removeFromQuickBooks();
      } catch (error) {
        toast.error(error.message ?? 'An error has occurred. The invoice has not been removed from QuickBooks.');
      }
    } else if (removeFromXero) {
      try {
        await api.www.workspaces(workspace.id).invoices(invoice.id).removeFromXero();
      } catch (error) {
        toast.error(error.message ?? 'An error has occurred. The invoice has not been removed from Xero.');
      }
    }

    await api.www.workspaces(workspace.id).invoices(invoice.id).unpublish();
    await resolve();
    form.save();
    toast.success(`Invoice #${invoice.number} has been unpublished`);
  };

  return (
    <ModalCard title="Unpublish Invoice" onClose={() => resolve(false)}>
      <ModalCard.Body>
        <P>Are you sure you want to unpublish this invoice?</P>
      </ModalCard.Body>

      <ModalCard.Footer>
        <Level>
          {integrations.qbo && invoice.qboInvoiceId && (
            <Level.Item>
              <Checkbox
                label="Remove from QuickBooks"
                checked={removeFromQuickBooks}
                onChange={() => setRemoveFromQuickBooks(!removeFromQuickBooks)}
              />
            </Level.Item>
          )}

          {integrations.xero && invoice.xeroInvoiceId && (
            <Level.Item>
              <Checkbox
                label="Remove from Xero"
                checked={removeFromXero}
                onChange={() => setRemoveFromXero(!removeFromXero)}
              />
            </Level.Item>
          )}

          <Level.Item>
            <Buttons align="right">
              <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
              <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
                Unpublish
              </ActionButton>
            </Buttons>
          </Level.Item>
        </Level>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default UnpublishInvoiceDialog;
