import { SingleSelect } from '~/components';
import React from 'react';

function MemberBillableTypeFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Member Workspace Billability"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="billable">Billable Members</option>
      <option value="non_billable">Non-Billable Members</option>
    </SingleSelect>
  );
}

export default MemberBillableTypeFilter;
