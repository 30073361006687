import {
  Button,
  ClientSelect,
  Currency,
  DateTime,
  Dropdown,
  ExportDialog,
  InfiniteScrollingObserver,
  InlineTooltip,
  InvoiceStatusSelect,
  Level,
  Page,
  PracticeSelect,
  RouteLink,
  SearchInput,
  SingleSelect,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { useActions, useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import IssueCreditNoteDrawer from '../../billing/credit-notes/dialogs/IssueCreditNoteDrawer';
import { sumBy } from '~/utils/math';
import QBOIndicator from '../components/QBOIndicator';
import SentIndicator from '../components/SentIndicator';
import XeroIndicator from '../components/XeroIndicator';
import InvoiceWebLinkModal from '../invoice-actions/InvoiceWebLinkModal';
import SendInvoiceDrawer from '../invoice-actions/send-invoice-drawer/SendInvoiceDrawer';
import DeleteInvoiceDialog from '../invoice-dialogs/DeleteInvoiceDialog';
import LoadFromQuickBooksDialog from '../invoice-dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../invoice-dialogs/LoadFromXeroDialog';
import PublishInvoiceDialog from '../invoice-dialogs/PublishInvoiceDialog';
import SaveToQuickBooksDialog from '../invoice-dialogs/SaveToQuickBooksDialog';
import SaveToXeroDialog from '../invoice-dialogs/SaveToXeroDialog';
import UnpublishInvoiceDialog from '../invoice-dialogs/UnpublishInvoiceDialog';
import ClientPaymentForm from '../payments/ClientPaymentForm';
import CreateClientInvoiceDialog from './CreateClientInvoiceDialog';
import InvoicesSummaryWidget from './InvoicesSummaryWidget';
import agingIntervals from './agingIntervals';
import intervals from './intervals';
import ExportDropdown from '../../settings/ExportDropdown.jsx';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 2rem;
  margin: 0 -2rem;
  margin-top: -2rem;
`;

const Due = styled.span`
  white-space: nowrap;

  ${({ isLate }) =>
    isLate &&
    css`
      color: ${colors.danger};
    `}
`;

const Small = styled.small`
  display: block;
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    statusIds: null,
    aging: null,
    sort: new QuerySort('issuedOn', 'desc'),
    page: 0,
    practice: null,
    size: 50,

    // These filters are used in the client/project dashboard's invoices tab
    statusId: null,
    client: null,
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItems: (items, { data }) => ({
    data: {
      ...data,
      results: data.results.map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        return item ? { ...result, ...item } : result;
      }),
    },
  }),
  removeItem: (id, { data }) => ({
    dialog: null,
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function InvoicesListPage({
  projectId,
  clientId,
  published,
  mode = 'page',
  showProjectColumn = true,
  renderFilters,
  initialQueryState = {},
  onRowClick,
  sessionKey,
}) {
  const documentTitle = useDocumentTitle();
  if (mode === 'page') documentTitle.set('Invoices');

  const { workspace } = useWorkspace();
  const api = useApi();

  const history = useHistory();
  const { url } = useRouteMatch();

  const finalInitialState = _.merge({}, initialState, { query: initialQueryState });

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, finalInitialState);
  const [dialog, setDialog] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const auth = useAuth();
  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        statusIds: {
          default: finalInitialState.query.statusIds,
          valid: ['all', 'draft,open', 'draft', 'open', 'paid'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        period: {
          default: initialState.query.period,
          valid: ['all', ..._.keys(intervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        practice: searchParamsConfig.practice,
        aging: {
          default: initialState.query.dueOn,
          valid: ['all', ..._.keys(agingIntervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        client: searchParamsConfig.client,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },

        // This filter is used in the client/project dashboard's invoices tab
        statusId: { valid: ['open', 'paid'] },
      }),

      [searchParamsConfig, finalInitialState.query.statusIds],
    ),
    sessionKey,
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions, mode]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = intervals[query.period] || {};
      const { start: dueOnStart, end: dueOnEnd } = agingIntervals[query.aging] || {};

      const { data } = await api.www
        .workspaces(workspace.id)
        .invoices()
        .get({
          ..._.omit(query, 'period', 'client', 'aging', 'practice'),
          start,
          end,
          dueOnStart: dueOnStart || undefined,
          dueOnEnd: dueOnEnd || undefined,
          q: query.q || undefined,
          statusIds: query.aging ? 'open' : query.statusIds || undefined,
          statusId: query.statusId || undefined,
          practiceId: query.practice?.id,
          projectId,
          clientId: query.client?.id || clientId,
          published,
        });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: { total: 0, results: [] } });
    }
  }, [actions, workspace.id, query, api, projectId, clientId, published]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const features = useFeatures();

  const integrations = useIntegrations();

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    actions.setParams({ ...value });
    searchParams.set({ ...value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleRowClick = (invoice) => {
    if (onRowClick) onRowClick(invoice);
    else history.push(`${url}/${invoice.id}`);
  };

  const refreshSummary = () => {
    setRefreshKey(refreshKey + 1);
  };

  const reloadInvoices = async (...ids) => {
    const invoices = await api.www
      .workspaces(workspace.id)
      .invoices()
      .get({ ids })
      .then((res) => res.data);

    actions.updateItems(invoices);

    refreshSummary();

    return invoices;
  };

  const handleDelete = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <DeleteInvoiceDialog
        invoice={invoice}
        onClose={resolve}
        onDelete={() => {
          actions.removeItem(invoice.id);
          refreshSummary();
          resolve();
        }}
      />
    ));
  };

  const handlePublish = async (invoice) => {
    return await confirmation.prompt((resolve) => (
      <PublishInvoiceDialog
        invoiceId={invoice.id}
        onClose={() => {
          resolve();
        }}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve(true);
        }}
      />
    ));
  };

  const handleUnpublish = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <UnpublishInvoiceDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleGetWebLink = (invoice) => {
    setDialog({ type: 'webLink', invoice });
  };

  const handleSend = async (invoice) => {
    const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).get();
    setDialog({ type: 'send', invoice: data });
  };

  const handlePublishAndSend = async (invoice) => {
    const isPublished = await handlePublish(invoice);
    if (isPublished) {
      await handleSend(invoice);
    }
  };

  const handlePayment = (invoice) => {
    setDialog({ type: 'payment', invoice });
  };

  const handleIssueCreditNote = (invoice) => {
    setDialog({ type: 'issueCreditNote', invoice });
  };

  const handleSaveToQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleSaveToXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToXeroDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handlePaymentReceived = async (payment) => {
    await reloadInvoices(payment.paymentInvoices.map((pi) => pi.invoiceId));
  };

  const handleCreditNoteIssued = async (creditNote) => {
    history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`);
  };

  const handleInvoiceSent = async (invoice) => {
    await reloadInvoices(invoice.id);
  };

  const handleCreateInvoice = () => {
    setDialog({ type: 'create' });
  };

  const handleExport = async (filename, mimeType) => {
    const { start, end } = intervals[query.period] || {};
    const { start: dueOnStart, end: dueOnEnd } = agingIntervals[query.aging] || {};

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .invoices()
          .export(
            {
              ..._.omit(query, 'period', 'client', 'aging', 'practice'),
              start,
              end,
              dueOnStart: dueOnStart || undefined,
              dueOnEnd: dueOnEnd || undefined,
              q: query.q || undefined,
              size: null,
              statusIds: query.aging ? 'open' : query.statusIds || undefined,
              statusId: query.statusId || undefined,
              practiceId: query.practice?.id,
              clientId: query.client?.id || clientId,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  const totals = {
    balance: sumBy(data.results, 'convertedBalance'),
    total: sumBy(data.results, 'convertedTotal'),
  };

  const { start, end } = intervals[query.period] || {};

  const Container = mode === 'page' ? Page : React.Fragment;

  return (
    <Container>
      {mode === 'page' && (
        <SummarySection>
          <InvoicesSummaryWidget
            key={refreshKey}
            start={start}
            end={end}
            invoices={data.results}
            projectId={projectId}
          />
        </SummarySection>
      )}

      {renderFilters ? (
        renderFilters({ query, handleFilter })
      ) : (
        <>
          <Level margin="2rem 0 1.5rem 0">
            <Level.Item>
              <SearchInput
                value={query.q}
                placeholder="Search"
                materialPlaceholder="Client or Invoice #"
                materialAlwaysVisible
                onChange={({ target: { value } }) => handleFilter({ q: value })}
              />
            </Level.Item>

            <Level.Item>
              <ClientSelect
                name="client"
                placeholder="All"
                materialAlwaysVisible
                materialPlaceholder="Client"
                activeOnly={false}
                value={query.client}
                onChange={({ target: { value } }) => handleFilter({ client: value })}
              />
            </Level.Item>

            <Level.Item>
              <InvoiceStatusSelect
                value={query.statusIds}
                onChange={({ target: { value } }) => handleFilter({ statusIds: value })}
              />
            </Level.Item>

            <Level.Item right narrow>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`invoices.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`invoices.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            <Level.Item right narrow>
              <Level.Item right narrow>
                <Button
                  disabled={!auth.invoices.manage.client}
                  style={{ position: 'relative' }}
                  onClick={handleCreateInvoice}>
                  Create Invoice
                  {!auth.invoices.manage.client && (
                    <InlineTooltip message="Your security role prohibits you from creating blank invoices." />
                  )}
                </Button>
              </Level.Item>
            </Level.Item>
          </Level>

          <Level margin="0 0 2.5rem 0">
            <Level.Item width="20rem">
              <SingleSelect
                placeholder="All"
                materialPlaceholder="Issue Date"
                materialAlwaysVisible
                showEmptyOption
                value={query.period}
                onChange={({ target: { value } }) => handleFilter({ period: value })}>
                {_.map(intervals, ({ label }, key) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </SingleSelect>
            </Level.Item>
            <Level.Item width="20rem">
              <SingleSelect
                placeholder="All"
                materialPlaceholder="Aging"
                materialAlwaysVisible
                showEmptyOption
                value={query.aging}
                onChange={({ target: { value } }) => handleFilter({ aging: value })}>
                {_.map(agingIntervals, ({ label }, key) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </SingleSelect>
            </Level.Item>

            {features.practices && (
              <Level.Item width="20rem">
                <PracticeSelect
                  name="practice"
                  placeholder="All"
                  materialPlaceholder="Client Practice"
                  materialAlwaysVisible
                  value={query.practice}
                  onChange={({ target: { value } }) => handleFilter({ practice: value })}
                />
              </Level.Item>
            )}
          </Level>
        </>
      )}

      <section>
        <Table.Status>
          {!!action && <Spinner />}
          <Table.Total value={data.total} label="Invoice" />
        </Table.Status>

        <Table>
          <Table.BoxHeader sticky>
            <Table.Column name="transactionNumber" onSort={handleSort} sort={query.sort}>
              #
            </Table.Column>
            <Table.Column name="client.name" isVisible={showProjectColumn} onSort={handleSort} sort={query.sort}>
              Client
            </Table.Column>
            <Table.Column isVisible={showProjectColumn}>Projects</Table.Column>
            <Table.Column
              width={showProjectColumn ? '10rem' : undefined}
              name="issuedOn"
              onSort={handleSort}
              sort={query.sort}>
              Issued
            </Table.Column>
            <Table.Column
              width={showProjectColumn ? '8rem' : undefined}
              name="dueOn"
              onSort={handleSort}
              sort={query.sort}>
              Due
            </Table.Column>
            <Table.Column
              width={showProjectColumn ? '6rem' : undefined}
              name="actualStatusId"
              onSort={handleSort}
              sort={query.sort}>
              Status
            </Table.Column>
            <Table.Column width="8rem" align="right" name="convertedBalance" onSort={handleSort} sort={query.sort}>
              Balance
            </Table.Column>
            <Table.Column width="8rem" align="right" name="convertedTotal" onSort={handleSort} sort={query.sort}>
              Total
            </Table.Column>
            <Table.BoxActionsColumn isVisible={mode === 'page'} />
          </Table.BoxHeader>
          <Table.Body fade={action === 'filter'}>
            {data.results.map((invoice) => {
              const {
                id,
                number,
                issuedOn,
                dueOn,
                isLate,
                daysLate,
                status,
                hasPayments,
                hasCreditNotes,
                qboInvoiceId,
                xeroInvoiceId,
                sentAt,
              } = invoice;

              const receivePayment = !invoice.permissions.receivePayment
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to receive a payment on this invoice.',
                  }
                : !features.multicurrency && invoice.currency !== workspace.currency
                  ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
                  : { disabled: false, tooltip: undefined };

              const issueCreditNote = !invoice.permissions.applyCredit
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to issue a credit note for this invoice.',
                  }
                : !features.multicurrency && invoice.currency !== workspace.currency
                  ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
                  : { disabled: false, tooltip: undefined };

              return (
                <Table.BoxRow key={id} data-testid={id} onClick={() => handleRowClick(invoice)}>
                  <Table.Cell>
                    <RouteLink to={`/app/${workspace.key}/billing/invoices/${id}`} style={{ wordBreak: 'break-word' }}>
                      {number}
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>{invoice.client.name}</Table.Cell>
                  <Table.Cell>
                    <FirstProject invoice={invoice} />
                    <Projects invoice={invoice} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={issuedOn} />
                    {sentAt && <SentIndicator sentAt={sentAt} />}
                  </Table.Cell>
                  <Table.Cell>
                    <Due isLate={isLate}>
                      <DateTime value={dueOn} />
                      {isLate && (
                        <p>
                          ({daysLate} {daysLate === 1 ? 'day' : 'days'})
                        </p>
                      )}
                    </Due>
                  </Table.Cell>
                  <Table.Cell>
                    {status.name}
                    {qboInvoiceId && <QBOIndicator />}
                    {xeroInvoiceId && <XeroIndicator />}
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={invoice.convertedBalance} currency={workspace.currency} />
                      {invoice.currency !== workspace.currency && (
                        <Small>
                          <Currency value={invoice.balance} currency={invoice.currency} />
                        </Small>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={invoice.convertedTotal} currency={workspace.currency} />
                      {invoice.currency !== workspace.currency && (
                        <Small>
                          <Currency value={invoice.total} currency={invoice.currency} />
                        </Small>
                      )}
                    </p>
                  </Table.Cell>

                  <TableBoxRowActions isVisible={mode === 'page'}>
                    {status.id === 'draft' ? (
                      <TableBoxRowActions.Edit
                        onClick={() => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
                      />
                    ) : (
                      <TableBoxRowActions.View
                        onClick={() => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
                      />
                    )}

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return {
                          draft: (
                            <>
                              <Dropdown.Item
                                disabled={!invoice.permissions.publish || invoice.total < 0}
                                tooltip={
                                  !invoice.permissions.publish
                                    ? 'Insufficient permissions to publish this invoice.'
                                    : invoice.total < 0
                                      ? 'Invoice total must be zero or greater.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handlePublish(invoice))}>
                                Publish
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!invoice.permissions.publish || invoice.total < 0}
                                tooltip={
                                  !invoice.permissions.publish
                                    ? 'Insufficient permissions to publish this invoice.'
                                    : invoice.total < 0
                                      ? 'Invoice total must be zero or greater.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handlePublishAndSend(invoice))}>
                                Publish & Send
                              </Dropdown.Item>

                              <Dropdown.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                Edit
                              </Dropdown.Link>

                              <Dropdown.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </Dropdown.Link>

                              <Dropdown.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </Dropdown.Item>
                            </>
                          ),

                          open: (
                            <>
                              <Dropdown.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to send this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(invoice))}>
                                Send
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={receivePayment.disabled}
                                tooltip={receivePayment.tooltip}
                                onClick={() => handleAction(() => handlePayment(invoice))}>
                                Receive Payment
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={issueCreditNote.disabled}
                                tooltip={issueCreditNote.tooltip}
                                onClick={() => handleAction(() => handleIssueCreditNote(invoice))}>
                                Issue Credit Note
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={hasPayments || hasCreditNotes || !invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to unpublish this invoice.'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be unpublished.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be unpublished.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleUnpublish(invoice))}>
                                Unpublish
                              </Dropdown.Item>

                              <Dropdown.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                View
                              </Dropdown.Link>

                              <Dropdown.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </Dropdown.Link>

                              <Dropdown.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </Dropdown.Item>

                              {integrations.qbo &&
                                (invoice.qboInvoiceId ? (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                                    Reload from QuickBooks
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                                    Save to QuickBooks
                                  </Dropdown.Item>
                                ))}

                              {integrations.xero &&
                                (invoice.xeroInvoiceId ? (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                                    Reload from Xero
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                                    Save to Xero
                                  </Dropdown.Item>
                                ))}

                              <Dropdown.Item
                                disabled={!invoice.permissions.manage || hasPayments || hasCreditNotes}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be deleted.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be deleted.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </Dropdown.Item>
                            </>
                          ),

                          paid: (
                            <>
                              <Dropdown.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to send this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(invoice))}>
                                Send
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={hasPayments || hasCreditNotes || !invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to unpublish this invoice.'
                                    : invoice.hasPayments
                                      ? 'An invoice with received payments cannot be unpublished.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be unpublished.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleUnpublish(invoice))}>
                                Unpublish
                              </Dropdown.Item>

                              <Dropdown.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                View
                              </Dropdown.Link>

                              <Dropdown.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </Dropdown.Link>

                              <Dropdown.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </Dropdown.Item>

                              {integrations.qbo &&
                                (invoice.qboInvoiceId ? (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                                    Reload from QuickBooks
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                                    Save to QuickBooks
                                  </Dropdown.Item>
                                ))}

                              {integrations.xero &&
                                (invoice.xeroInvoiceId ? (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                                    Reload from Xero
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                                    Save to Xero
                                  </Dropdown.Item>
                                ))}

                              <Dropdown.Item
                                disabled={!invoice.permissions.manage || hasPayments || hasCreditNotes}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be deleted.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be deleted.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </Dropdown.Item>
                            </>
                          ),
                        }[status.id];
                      }}
                    </TableBoxRowActions.Dropdown>
                  </TableBoxRowActions>
                </Table.BoxRow>
              );
            })}
          </Table.Body>
          {data.results.length > 0 && (
            <Table.Row style={{ fontWeight: weights.bold, marginTop: '0.5rem', fontSize: '0.875rem' }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell data-testid="balance">
                <Currency value={totals.balance} currency={workspace.currency} />
              </Table.Cell>
              <Table.Cell data-testid="total">
                <Currency value={totals.total} currency={workspace.currency} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          )}
        </Table>

        {data.total > data.results.length && (
          <InfiniteScrollingObserver key={data.results.length} onIntersecting={actions.loadMore} />
        )}
      </section>

      {dialog &&
        {
          webLink: () => <InvoiceWebLinkModal invoice={dialog.invoice} onClose={handleCloseDialog} />,
          send: () => (
            <SendInvoiceDrawer invoice={dialog.invoice} onSent={handleInvoiceSent} onClose={handleCloseDialog} />
          ),
          payment: () => (
            <ClientPaymentForm
              invoiceId={dialog.invoice.id}
              onSaved={handlePaymentReceived}
              onClose={handleCloseDialog}
            />
          ),
          issueCreditNote: () => (
            <IssueCreditNoteDrawer
              invoiceId={dialog.invoice.id}
              onSaved={handleCreditNoteIssued}
              onClose={handleCloseDialog}
            />
          ),
          create: () => <CreateClientInvoiceDialog onClose={handleCloseDialog} />,
        }[dialog.type]()}
    </Container>
  );
}

const FirstProject = ({ invoice }) => {
  const project = invoice.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ invoice }) => {
  let projectsCount = invoice.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {invoice.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default InvoicesListPage;
