import { Button, Buttons, CancelButton, Field, Form, FormMessage, Icon, ModalCard } from '~/components';
import { useApi, useSession, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDocumentTitle, useForm } from '~/hooks';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import * as Yup from 'yup';
import { Footer, Header } from '../components';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  text-align: center;
`;

const BoxTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;

  + form {
    margin-top: 2rem;
  }
`;

const Byline = styled.div`
  padding-top: 0.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
  text-align: center;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  color: ${colors.white};
  background-color: ${colors.danger};
  border-radius: 0.625rem;

  a {
    color: ${colors.white};
    text-decoration: underline;

    &:hover {
      color: ${colors.grey10};
    }
  }
`;

function PasswordResetForm({ view, status, message, email, token, isSubmitting, onClose, onSubmit }) {
  return (
    <>
      <Header />
      <Container>
        {
          {
            form: (
              <PasswordResetForm.Form
                {...{
                  email,
                  token,
                  status,
                  message,
                  isSubmitting,
                  onSubmit,
                  onClose,
                }}
              />
            ),
            success: <Success />,
            'validate-token-error': (
              <>
                <Title>Password Reset</Title>
                <ErrorMessage>
                  The password reset link is invalid or has expired. If you still need to reset your password, please
                  use the <Link to="/password-reset">reset password form</Link> to request a new link. Send an email to{' '}
                  <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
                </ErrorMessage>
              </>
            ),
            'validate-token': null,
          }[view]
        }
      </Container>

      <Footer />
    </>
  );
}

PasswordResetForm.Form = function ({ email, token, message, isSubmitting, onSubmit }) {
  return (
    <Box>
      <BoxTitle>Password Reset</BoxTitle>

      <Formik
        initialValues={{
          email,
          token,
          password: '',
          passwordVerification: '',
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .label('New Password')
            .min(8, 'New Password must be at least 8 characters long')
            .max(255, 'New Password must be at most 255 characters long')
            .required(),
          passwordVerification: Yup.string()
            .label('Repeat Password')
            .required()
            .oneOf([Yup.ref('password')], 'Passwords do not match'),
        })}>
        {() => (
          <Form>
            <Form.Control>
              <Field.Text name="email" placeholder="Email" type="email" className="is-static" readOnly disabled />
            </Form.Control>
            <Form.Control>
              <Field.Text autoFocus name="password" placeholder="New Password" type="password" maxLength={255} />
            </Form.Control>
            <Form.Control>
              <Field.Text
                name="passwordVerification"
                placeholder="Password Verification"
                type="password"
                maxLength={255}
              />
            </Form.Control>

            {message && <PasswordResetForm.SubmitError message={message} />}

            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Reset Password
              </Button>
            </FormAction>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

function Success() {
  return (
    <Box>
      <BoxTitle>Password Reset</BoxTitle>

      <Byline>Your password was successfully reset.</Byline>

      <FormAction>
        <Link to="/login" className="button">
          Login
        </Link>
      </FormAction>
    </Box>
  );
}

PasswordResetForm.ValidateToken = function ({ onClose }) {
  return (
    <>
      <ModalCard.Body>
        <FormMessage variant="primary">
          Validating Password Reset Token <Icon icon="spinner" spaceLeft spin={true} />
        </FormMessage>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
        </Buttons>
      </ModalCard.Footer>
    </>
  );
};

PasswordResetForm.ValidateTokenError = function () {
  return (
    <ErrorMessage>
      <FormMessage.Error>The password reset link is invalid or has expired.</FormMessage.Error>
    </ErrorMessage>
  );
};

PasswordResetForm.SubmitError = function ({ message }) {
  return <FormMessage.Error>{message}</FormMessage.Error>;
};

const initialState = {
  view: 'validate-token',
  isSubmitting: false,
  status: null,
  message: null,
  email: null,
};
const handlers = {
  tokenValid: ({ email }) => ({ view: 'form', email }),
  tokenInvalid: () => ({ email: '', view: 'validate-token-error' }),
  success: () => ({ isSubmitting: false, view: 'success' }),
};

export default function () {
  useDocumentTitle('Password Reset');

  const api = useApi();
  const { logout } = useSession();
  const { clearWorkspace } = useWorkspace();
  const [{ view, email }, actions] = useActions(handlers, initialState);
  const [formState, form] = useForm();

  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (view !== 'validate-token') return;

    (async () => {
      try {
        const {
          data: { email },
        } = await api.www.validatePasswordResetToken({ token });
        actions.tokenValid({ email, token });
      } catch (error) {
        actions.tokenInvalid();
      }
    })();
  }, [token, actions, view, api]);

  async function handleSubmit(values) {
    try {
      form.submit();
      await api.www.resetPassword(values);
      await logout();
      clearWorkspace();
      form.done();
      actions.success();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  return (
    <PasswordResetForm
      onClose={() => history.push('/')}
      onSubmit={handleSubmit}
      {...formState}
      {...{ view, email, token }}
    />
  );
}

export { PasswordResetForm };
