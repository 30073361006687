import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Button from '../Button';
import Icon from '../Icon';
import { colors } from '~/styles';
import { alignToJustify } from './TableColumn';
import { useTable } from './TableContext';

const Edit = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: all 150ms ease-in-out;

  ${Button} {
    width: 2.5rem;
    padding: 0;
    color: ${colors.primary};
    background-color: transparent;

    &:hover {
      color: ${colors.accent};
    }
  }
`;

const Cell = styled.div`
  flex: ${({ column }) => (column?.width ? `0 0 ${column?.width}` : '1')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ column }) => alignToJustify(column.align)};
  min-width: ${({ column }) => column.width ?? 'auto'};

  padding: ${({ column, padding }) => (padding ? padding : column?.padding ? column.padding : '0.75rem 1rem')};
  text-align: ${({ column }) => column.align};
  font-size: ${({ column }) => column.fontSize ?? '1em'};
  &.is-editable {
    padding-right: 1.5rem;
  }

  &:hover {
    ${Edit} {
      visibility: visible;
      opacity: 1;
    }
  }

  &.has-input {
    padding: 0 1rem 0 0.07rem;
  }
`;

function TableCell({ className, children, onEdit, index, ...props }) {
  const table = useTable();
  const column = table.columns[index];

  const onClick = (event) => {
    event.stopPropagation();
    if (onEdit) {
      onEdit(column);
    }
  };

  if (!column?.isVisible) return null;
  return (
    <Cell
      {...props}
      column={column}
      className={classNames(className, 'table-cell', {
        [`cell-${column?.name}`]: column?.name,
        'is-editable': !!onEdit,
      })}>
      {children}
      {onEdit && (
        <Edit>
          <Button onClick={onClick}>
            <Icon icon="pencil-alt" />
          </Button>
        </Edit>
      )}
    </Cell>
  );
}

export default styled(TableCell)``;
