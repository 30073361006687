import { useWorkspace } from '~/contexts';
import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ClientArea from './ClientArea';
import ClientCreateForm from './ClientCreateForm';

import ClientsListPage from './ClientsListPage';

function ClientsArea() {
  const { workspace } = useWorkspace();
  const auth = useAuth();

  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <Switch>
        <Route path={[`${path}/`, `${path}/new`, `${path}/edit/:clientId`]} exact>
          <ClientsListPage />
        </Route>
        <Route path={`${path}/:clientId`}>
          <ClientArea />
        </Route>
      </Switch>
      <Route path={`${path}/new`}>
        {auth.clients.create ? (
          <ClientCreateForm
            onSaved={(client) => history.push(`${url}/${client.key}/overview/edit`)}
            onClose={() => {
              history.push({ pathname: url, search: location.search });
            }}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/clients`} />
        )}
      </Route>
    </>
  );
}

export default ClientsArea;
