import { Button, Buttons, CancelButton, Checkbox, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useForm } from '~/hooks';
import React, { useEffect, useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

const initialState = { isReady: false, opportunityTypes: null };
const handlers = {
  ready: ({ opportunityTypes }) => ({ isReady: true, opportunityTypes }),
};

export default function SyncSettingsDrawer({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const [{ isReady, opportunityTypes }, actions] = useActions(handlers, initialState);
  const api = useApi();
  const { workspace } = useWorkspace();

  useEffect(() => {
    (async () => {
      const { data: opportunityTypes } = await api.www.workspaces(workspace.id).salesforce.getOpportunityTypes();
      actions.ready({ opportunityTypes });
    })();
  }, [actions, api, workspace.id]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      autoSync: false,
      syncStartDate: '',
      minimumProbability: '0',
      minimumAmount: '0',
      syncInterval: 240,
      opportunityTypes: [],
    },
    integration.settings,
  );
  if (!isReady) return null;
  return (
    <Drawer
      isOpen
      title="Sync Settings"
      byline="Salesforce Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull({
              ...values,
            });

            await api.www.workspaces(workspace.id).salesforce.updateSyncSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              autoSync: Yup.bool().label('Automatically Sync').required('Automatically Sync must be selected'),

              syncInterval: Yup.number()
                .label('Sync Interval')
                .min(60, 'Sync Interval must be at least hourly')
                .max(1440, 'Sync Interval cannot exceed daily')
                .required('Sync Interval is required'),

              minimumProbability: Yup.number()
                .label('Minimum Probability')
                .min(0, 'Minimum Probability cannot be less than 0')
                .max(100, 'Minimum Probability cannot exceed 100')
                .nullable()
                .when('autoSync', {
                  is: true,
                  then: Yup.number().required('Minimum Probability is required when Auto Sync is enabled'),
                  otherwise: Yup.number().notRequired(),
                }),

              minimumAmount: Yup.number()
                .label('Minimum Amount')
                .min(0, 'Minimum Amount cannot be less than 0')
                .max(10000000000000, 'Minimum Amount is too large')
                .nullable(),

              syncStartDate: Yup.date().label('Start Date').nullable().required('Start Date is required'),

              opportunityTypes: Yup.array()
                .label('Opportunity Types')
                .of(Yup.string())
                .min(1, 'At least one Opportunity Type is required')
                .required('Opportunity Types are required'),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Automatically Sync"
                    subtitle="If turned on, your Salesforce connection will sync changes to Ruddr at the selected frequency.">
                    <Form.Control>
                      <Field.RadioGroup name="autoSync">
                        <Radio value={true} label="On" />
                        <Radio value={false} label="Off" />
                      </Field.RadioGroup>
                    </Form.Control>
                    <Form.Control>
                      <Field.SingleSelect name="syncInterval" placeholder="Frequency" clearable={false}>
                        <option value={60}>Every Hour</option>
                        <option value={120}>Every Two Hours</option>
                        <option value={180}>Every Three Hours</option>
                        <option value={240}>Every Four Hours</option>
                        <option value={480}>Every Eight Hours</option>
                        <option value={720}>Every Twelve Hours</option>
                        <option value={1440}>Daily</option>
                      </Field.SingleSelect>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Beginning"
                    subtitle="Any opportunity created prior to the Start Date will not be synced.">
                    <Form.Control>
                      <Field.DayPicker name="syncStartDate" placeholder="Start Date" clearable={false} />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Opporunity Minimums"
                    subtitle="Any opportunities with less than these minimum probability and amount values will not be synced.">
                    <Form.Control>
                      <Field.Number
                        name="minimumProbability"
                        placeholder="Minimum Probability"
                        min={0}
                        max={100}
                        precision={2}
                        prefix="%"
                        style={{ textAlign: 'left' }}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.Number
                        name="minimumAmount"
                        placeholder="Minimum Amount"
                        min={0}
                        precision={2}
                        prefix="$"
                      />
                    </Form.Control>
                  </Form.Section>
                  {opportunityTypes && (
                    <Form.Section
                      title="Opportunity Types"
                      subtitle="Any opportunities with the selected types will be synced.">
                      <Form.Control>
                        <Field.CheckboxGroup direction="vertical" name="opportunityTypes">
                          {opportunityTypes.map((option, index) => (
                            <Checkbox key={index} value={option} label={option} />
                          ))}
                        </Field.CheckboxGroup>
                      </Form.Control>
                    </Form.Section>
                  )}
                  {!opportunityTypes && <>Loading opportunity types from Salesforce...</>}
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
