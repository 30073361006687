import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors, weights } from '~/styles';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

const WorkspaceImage = styled.img`
  max-height: 100%;
  object-fit: contain;
`;

const BuildingIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default function Workspace({ data }) {
  const { name, key, imageUrl } = data;
  return (
    <Container>
      <Logo>{imageUrl ? <WorkspaceImage src={imageUrl} alt={name} /> : <BuildingIcon icon="building" />}</Logo>
      <Info>
        <Title>{name}</Title>
        <Subtitle>/app/{key}</Subtitle>
      </Info>
    </Container>
  );
}
