import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import ForecastReportsPage from './ForecastReportsPage';
import HoursForecastByProjectAndResource from './HoursForecastByProjectAndResource';
import HoursForecastByResourceAndProject from './HoursForecastByResourceAndProject';
import PerformanceForecastByProject from './PerformanceForecastByProject';
import ServicesRevenueForecastByClientAndProject from './ServicesRevenueForecastByClientAndProject';
import UtilizationForecastByMember from './UtilizationForecastByMember';
import UtilizationForecastByMemberPractice from './UtilizationForecastByMemberPractice';

export default function ForecastReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={path} exact>
        <ForecastReportsPage />
      </Route>

      <Route path={path.concat('/hours-forecast-by-project-and-resource')}>
        {auth.reports.hoursForecast ? (
          <HoursForecastByProjectAndResource />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-forecast-by-resource-and-project')}>
        {auth.reports.hoursForecast ? (
          <HoursForecastByResourceAndProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-forecast-by-project')}>
        {auth.reports.performanceForecastByProject ? <PerformanceForecastByProject /> : <ErrorPage.Forbidden />}
      </Route>

      <Route path={path.concat('/services-revenue-forecast-by-client-and-project')}>
        {auth.reports.revenueForecast ? (
          <ServicesRevenueForecastByClientAndProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/utilization-forecast-by-member')}>
        {auth.reports.utilizationForecastByMember ? (
          <UtilizationForecastByMember />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/utilization-forecast-by-member-practice')}>
        {auth.reports.utilizationForecastByMemberPractice ? (
          <UtilizationForecastByMemberPractice />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}
