import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';
import _ from 'lodash';
import expenseItems from './expenseItems';

const expenseReports =
  (expenseReportsUrl) =>
  (id = '') => {
    const url = _([expenseReportsUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      clone: (body) => post(url.concat('/clone'), body),
      submit: () => put(url.concat('/submit')),
      expenseItems: expenseItems(url.concat('/expense-items')),
      export: (config) => {
        return get(url.concat('/export'), config);
      },
    };
  };

export default expenseReports;
