import moment from 'moment';
import { dateFormats, intervalOptions } from '~/utils';

export const intervals = {
  get custom() {
    return intervalOptions.custom;
  },

  get next_30_days() {
    return {
      key: 'next_30_days',
      label: '30 days',
      start: moment().format(dateFormats.isoDate),
      end: moment().add(30, 'days').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get next_60_days() {
    return {
      key: 'next_60_days',
      label: '60 days',
      start: moment().format(dateFormats.isoDate),
      end: moment().add(60, 'days').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get next_90_days() {
    return {
      key: 'next_90_days',
      label: '90 days',
      start: moment().format(dateFormats.isoDate),
      end: moment().add(90, 'days').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get next_4_weeks() {
    return {
      key: 'next_4_weeks',
      label: '4 weeks',
      start: moment().startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().add(4, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get next_12_weeks() {
    return {
      key: 'next_12_weeks',
      label: '12 weeks',
      start: moment().startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().add(12, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get next_26_weeks() {
    return {
      key: 'next_26_weeks',
      label: '26 weeks',
      start: moment().startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().add(26, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get next_3_months() {
    return {
      key: 'next_3_months',
      label: '3 months',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment().add(3, 'months').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get next_6_months() {
    return {
      key: 'next_6_months',
      label: '6 months',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment().add(6, 'months').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get next_9_months() {
    return {
      key: 'next_9_months',
      label: '9 months',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment().add(9, 'months').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get next_12_months() {
    return {
      key: 'next_12_months',
      label: '12 months',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment().add(12, 'months').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
};

export const intervalsByScope = {
  get day() {
    return [intervals.custom, intervals.next_30_days, intervals.next_60_days, intervals.next_90_days];
  },

  get week() {
    return [intervals.custom, intervals.next_4_weeks, intervals.next_12_weeks, intervals.next_26_weeks];
  },

  get month() {
    return [
      intervals.custom,
      intervals.next_3_months,
      intervals.next_6_months,
      intervals.next_9_months,
      intervals.next_12_months,
    ];
  },

  get all() {
    return [
      intervals.custom,
      intervals.next_30_days,
      intervals.next_60_days,
      intervals.next_90_days,
      intervals.next_4_weeks,
      intervals.next_12_weeks,
      intervals.next_26_weeks,
      intervals.next_3_months,
      intervals.next_6_months,
      intervals.next_9_months,
      intervals.next_12_months,
    ];
  },
};
