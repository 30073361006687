import _ from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { useWeekFiles } from './WeekFilesContext';
import WeekFilePreview from './WeekFilePreview';

const Title = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: ${weights.bold};
`;

const ProjectTitle = styled.span`
  color: ${colors.grey55};
  font-weight: ${weights.normal};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  span {
    color: ${colors.black};
  }
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
`;

const File = styled.div`
  margin: 0.5rem;
`;

export default function WeekFilesList() {
  const weekFiles = useWeekFiles();

  const groupedFiles = useMemo(() => {
    const groups = _.reduce(
      weekFiles.files,
      (acc, file) => {
        const { projectId, project } = file;

        let obj = _.find(acc, { projectId });
        if (!obj) {
          obj = { projectId, project, files: [] };
          acc.push(obj);
        }

        obj.files.push(file);
        obj.files = _.sortBy(obj.files, ['name']);

        return acc;
      },
      [],
    );
    return _.sortBy(groups, (group) => [!!group.project, group.project?.client.name, group.project?.name]);
  }, [weekFiles.files]);

  if (groupedFiles.length === 0) {
    return null;
  }
  return groupedFiles.map((groupedFile) => (
    <React.Fragment key={groupedFile.projectId}>
      <Title>
        Files
        {groupedFile.project && (
          <>
            :{' '}
            <ProjectTitle>
              <span>{groupedFile.project.client.name}</span> / {groupedFile.project.name}
            </ProjectTitle>
          </>
        )}
      </Title>
      <Files>
        {groupedFile.files.map((file) => (
          <File key={file.id}>
            <WeekFilePreview file={file} />
          </File>
        ))}
      </Files>
    </React.Fragment>
  ));
}
