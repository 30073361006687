import React from 'react';
import styled from 'styled-components';
import { Page } from '~/components';
import { useDocumentTitle } from '~/hooks';
import { weights } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 64rem;
`;

const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: ${weights.light};
  line-height: 1.2;
`;

const Description = styled.p`
  margin-top: 1rem;
`;

export default function WorkspaceDisabled() {
  useDocumentTitle('Workspace Locked');

  return (
    <Page>
      <Wrapper>
        <Title>Workspace Locked</Title>
        <Description>There is a problem with this workspace and it has been disabled.</Description>
        <Description>
          To continue using this workspace, click the "Need Help?" link at the bottom of the screen or email us at{' '}
          <a href="mailto:help@ruddr.io">help@ruddr.io</a>.
        </Description>
      </Wrapper>
    </Page>
  );
}
