import styled from 'styled-components';

const Stack = styled.div`
  flex: ${({ flex = '1' }) => flex};
  display: flex;
  flex-direction: column;
  margin: ${({ margin = 'unset' }) => margin};
`;

export default Stack;
