import { useVirtualizer } from '@tanstack/react-virtual';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

export default function useSchedule({ data, start, end, unit, groups, parentRef }) {
  const { rows, allocations, cells, styles } = useMemo(() => {
    const dayCount = moment(end).diff(start, 'days') + 1;

    const styles = {
      sidebar: {
        width: 250,
      },

      date: {
        get width() {
          return {
            day: 80,
            week: 30,
            month: 5,
          }[unit];
        },
      },

      scrollbar: {
        width: 17,
        height: 6,
      },

      canvas: {
        get width() {
          return dayCount * styles.date.width;
        },

        height: 0,
      },

      row: {
        height: 50,
      },

      allocation: {
        margin: 4,
      },

      splitter: {
        height: 4,
      },
    };

    if (!data) return { rows: [], allocations: [], cells: [], styles };

    const allocations = [];

    const cells = [];

    const rows = data.rows
      .filter((row) => !row.parentId || groups[row.parentId]?.state === 'expanded')
      .map((row, rowIndex) => {
        const lanes = [];

        if (row.allocations) {
          const rowAllocations = _.orderBy(row.allocations, ['start', 'end']).map((allocation) => ({
            ...allocation,
            lane: 1,
          }));

          _.forEach(rowAllocations, (allocation) => {
            const allocationStart = moment(allocation.start);
            const allocationEnd = moment(allocation.end);

            // Find a lane with an allocation start date that's after the lane's end date
            let laneIndex = _.findIndex(lanes, (lane) => allocationStart.isAfter(lane.end));

            // If the lane is not found, add a new lane
            if (laneIndex === -1) {
              lanes.push({ end: allocation.end });
              laneIndex = lanes.length - 1;
            } else {
              // If the lane is found, set the lane's end date to the allocation's end date
              lanes[laneIndex].end = allocation.end;
            }

            const daysSinceStart = allocationStart.diff(start, 'days');
            const x = daysSinceStart * styles.date.width;

            const y = styles.canvas.height + laneIndex * styles.row.height + styles.allocation.margin;

            const days = allocationEnd.diff(allocationStart, 'days') + 1;
            const width = days * styles.date.width - 1;

            const height = styles.row.height - styles.allocation.margin;

            allocations.push({
              ...allocation,
              meta: {
                lane: laneIndex,
                row: row.id,
                rowIndex,
                days,
                coords: { x, y },
                style: {
                  transform: `translate(${x}px, ${y}px)`,
                  width,
                  height,
                },
              },
            });
          });
        }

        const rowHeight = Math.max(styles.row.height, lanes.length * styles.row.height) + styles.allocation.margin;
        const top = styles.canvas.height;

        if (row.cells) {
          _.forEach(row.cells, (cell, cellIndex) => {
            const unitWidth = {
              day: styles.date.width,
              week: styles.date.width * 7,
              month: styles.date.width * moment(cell.date).daysInMonth(),
            }[unit];

            const prev = cells[cellIndex - 1];

            // Calculate the x offset based on the previous element's offset + width
            const x = prev ? prev.meta.x + prev.meta.style.width + 1 : 0;
            const width = unitWidth - 1;

            // The height and positioning varies between groups and rows.
            let y = top;
            let height = rowHeight;

            if (cell.type !== 'row') {
              y += styles.allocation.margin;
              height -= styles.allocation.margin;
            }

            cells.push({
              ...cell,
              meta: {
                row: row.id,
                rowIndex,
                cellIndex,
                // Used to determine the current position based on the previous cell's position
                x,
                style: {
                  transform: `translate(${x}px, ${y}px)`,
                  width,
                  height,
                },
              },
            });
          });
        }

        styles.canvas.height += rowHeight;

        return {
          ...row,
          meta: {
            index: rowIndex,
            lanes: lanes.length,
            style: {
              height: rowHeight,
              transform: `translateY(${top}px)`,
            },
          },
        };
      });

    return { rows, allocations, cells, styles };
  }, [data, start, end, groups, unit]);

  const virtualizer = useVirtualizer({
    count: rows?.length ?? 0,
    getScrollElement: useCallback(() => parentRef.current, [parentRef]),
    estimateSize: useCallback((index) => rows[index].meta.style.height, [rows]),
    overscan: 0,
  });

  const virtualItems = virtualizer.getVirtualItems();

  const virtualRows = useMemo(() => {
    if (virtualItems.length === 0) return [];

    const indexes = {
      start: virtualItems[0].index,
      end: virtualItems[virtualItems.length - 1].index + 1,
    };

    return rows.slice(indexes.start, indexes.end);
  }, [virtualItems, rows]);

  return { rows, allocations, cells, styles, virtualRows };
}
