import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, Drawer, Icon, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

const NotFound = styled.div`
  color: ${colors.grey25};
`;

export default function TimeOffTypePreview({ integration, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [internalTimeOffTypes, setInternalTimeOffTypes] = useState([]);
  const [externalTimeOffTypes, setExternalTimeOffTypes] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: internalTimeOffTypes } = await api.www
          .workspaces(workspace.id)
          .bamboohr.getInternalTimeOffTypes();

        const { data: externalTimeOffTypes } = await api.www
          .workspaces(workspace.id)
          .bamboohr.getExternalTimeOffTypes();

        setInternalTimeOffTypes(internalTimeOffTypes);
        setExternalTimeOffTypes(externalTimeOffTypes);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  const mergedTypes = useMemo(() => {
    const list = internalTimeOffTypes.map((internalType) => {
      const externalType = _.find(externalTimeOffTypes, { name: internalType.name });
      return {
        id: internalType.id,
        internalName: internalType.name,
        externalName: externalType ? externalType.name : null,
      };
    });
    for (const externalType of externalTimeOffTypes) {
      const internalType = _.find(internalTimeOffTypes, { name: externalType.name });
      if (!internalType) {
        list.push({
          id: externalType.id,
          internalName: null,
          externalName: externalType.name,
        });
      }
    }
    return list;
  }, [internalTimeOffTypes, externalTimeOffTypes]);

  return (
    <Drawer isOpen title="Time Off Type Matching" byline="BambooHR Integration" onClose={onClose}>
      {(closeDrawer) => {
        if (!isReady) {
          return <PageLoader />;
        }
        return (
          <>
            <Table style={{ marginBottom: '2.5rem' }}>
              <Table.BoxHeader>
                <Table.Column>Ruddr Time Off Type</Table.Column>
                <Table.Column>BambooHR Time Off Type</Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {mergedTypes.map((type) => (
                  <Table.BoxRow key={type.id}>
                    <Table.Cell>
                      {type.internalName ? (
                        type.internalName
                      ) : (
                        <NotFound>
                          {integration.settings.createTimeOffTypes ? (
                            <>
                              <Icon icon="check-circle" color={colors.primary} /> Type will be created, as necessary
                            </>
                          ) : (
                            <>
                              <Icon icon="exclamation-triangle" color={colors.warning} /> No matching time off type
                            </>
                          )}
                        </NotFound>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {type.externalName ? (
                        type.externalName
                      ) : (
                        <NotFound>
                          <Icon icon="exclamation-triangle" color={colors.warning} /> No matching time off type
                        </NotFound>
                      )}
                    </Table.Cell>
                  </Table.BoxRow>
                ))}
              </Table.Body>
            </Table>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={closeDrawer}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
