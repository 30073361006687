import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from './ApiContext';
import { useWorkspace } from './WorkspaceContext';
import { RatingModal, ReviewModal } from '~/components';

const RatingContext = createContext();

const RatingProvider = ({ children }) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [ratingState, setRatingState] = useState({
    autoOpenRatingModal: false,
    isEligibleForRating: false,
    isEligibleForReview: false,
    ratingId: null,
  });

  const fetchRating = useCallback(async () => {
    if (!workspace?.id) return null;

    try {
      const { data } = await api.www.workspaces(workspace.id).ratings().checkEligibility();
      setRatingState({
        autoOpenRatingModal: data.autoOpenRatingModal,
        isEligibleForRating: data.isEligibleForRating,
        isEligibleForReview: data.isEligibleForReview,
        ratingId: data.ratingId,
      });

      return data;
    } catch (error) {
      return null;
    }
  }, [api, workspace?.id]);

  useEffect(() => {
    fetchRating();
  }, [fetchRating]);

  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const autoOpenRatingModal = useCallback(async () => {
    const ratingState = await fetchRating();

    if (ratingState.autoOpenRatingModal) {
      setShowRatingModal(true);
    }
  }, [fetchRating]);

  const openRatingModal = useCallback(async () => {
    const ratingState = await fetchRating();

    if (ratingState.isEligibleForRating) {
      setShowRatingModal(true);
    }
  }, [fetchRating]);

  const openReviewModal = useCallback(async () => {
    const ratingState = await fetchRating();

    if (ratingState.isEligibleForReview) {
      setShowReviewModal(true);
    }
  }, [fetchRating]);

  const submitRating = useCallback(
    async (values, status) => {
      try {
        await api.www
          .workspaces(workspace.id)
          .ratings()
          .create({
            submissionType: status,
            score: status === 'dismissed' ? null : values.score,
            feedback: status === 'dismissed' ? null : values.feedback,
          });

        await fetchRating();

        return { success: true, message: 'Thank you for your feedback!' };
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
    [api, workspace?.id, fetchRating],
  );

  const submitReview = useCallback(
    async (ratingId) => {
      try {
        await api.www.workspaces(workspace.id).ratings(ratingId).setReviewed();

        await fetchRating();

        return { success: true };
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
    [api, workspace?.id, fetchRating],
  );

  const values = useMemo(() => {
    return {
      ratingState,
      autoOpenRatingModal,
      openRatingModal,
      openReviewModal,
      submitRating,
      submitReview,
    };
  }, [ratingState, autoOpenRatingModal, openRatingModal, openReviewModal, submitRating, submitReview]);

  return (
    <RatingContext.Provider value={values}>
      {children}
      {showRatingModal && <RatingModal onClose={handleCloseRatingModal} />}
      {showReviewModal && <ReviewModal ratingId={ratingState.ratingId} onClose={handleCloseReviewModal} />}
    </RatingContext.Provider>
  );
};

function useRating() {
  return useContext(RatingContext);
}

export { RatingContext, useRating, RatingProvider };
