const queryStringSeparator = '.';

class QuerySort {
  static FromQueryString(queryString) {
    const [column, direction] = queryString.split(queryStringSeparator);
    return new QuerySort(column, direction);
  }

  constructor(column, direction) {
    this.column = column;
    this.direction = direction;
  }

  toString() {
    return `${this.column}:${this.direction}`;
  }

  toQueryString() {
    return `${this.column}${queryStringSeparator}${this.direction}`;
  }
}

export default QuerySort;
