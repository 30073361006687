import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  Icon,
  Level,
  ModalCard,
  ToggleSwitch,
  Tooltip,
} from '~/components';
import { Card } from '~/components/ModalCard';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Page = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

const Box = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
  color: ${colors.black};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
  background-color: ${colors.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${weights.bold};
`;

const ProIcon = styled(Icon)`
  margin-left: 0.5rem;
  color: ${colors.primary};
`;

const Description = styled.div`
  padding-top: 0.25rem;
  font-size: 0.875rem;
`;

const Toggle = styled.div`
  margin-left: auto;
`;

export default function WorkspaceFeatures() {
  useDocumentTitle('Features');

  const api = useApi();
  const { workspace, updateWorkspace } = useWorkspace();

  const confirmation = useConfirmation();

  const lockNotPro = workspace.stripeProductKey !== 'pro';
  const values = workspace.features;

  const toast = useToast();

  const toggle = ({ path, flag, title, content, message }) =>
    confirmation.prompt((resolve) => (
      <ToggleConfirmation
        title={title}
        content={content}
        flag={flag}
        onResolve={async (result) => {
          if (result) {
            try {
              const { data } = flag
                ? await api.www.workspaces(workspace.id).features.activate(path)
                : await api.www.workspaces(workspace.id).features.deactivate(path);

              toast.success(message);

              updateWorkspace({ features: data });
            } catch (error) {
              toast.error(error?.message || 'An error has occurred. Please try again.');
            }
          }
          resolve(result);
        }}
      />
    ));

  const features = {
    billing: {
      label: 'Billing',
      locked: true,
      description: `Billing in Ruddr includes both invoices and payments. You can easily create invoices, send those via email to clients, and record client payments when they are received. The ability to disable this feature is coming soon.`,
    },

    clientAndProjectFiles: {
      label: 'Client and Project Files',
      locked: false,
      description: `Allows files to be uploaded to a Files tab on the Client Dashboard and Project Dashboard.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Client and Project Files',
              content: <p>The Client and Project Files feature will be turned on. Do you want to continue?</p>,
              message: 'The Client and Project Files feature has been turned on.',
            }
          : {
              title: 'Turn Off Client and Project Files',
              content: <p>The Client and Project Files feature will be turned off. Do you want to continue?</p>,
              message: 'The Client and Project Files feature has been turned off.',
            };

        return toggle({ path: 'client-and-project-files', flag, title, content, message });
      },
    },

    clientApprovals: {
      label: 'Client Approvals',
      pro: true,
      locked: lockNotPro,
      description: `In addition to internal time and expense approval, client approvals allow you to send time and expenses to the client contact for approval.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Client Approvals',
              content: <p>The Client Approvals feature will be turned on. Do you want to continue?</p>,
              message: 'The Client Approvals feature has been turned on.',
            }
          : {
              title: 'Turn Off Client Approvals',
              content: <p>The Client Approvals feature will be turned off. Do you want to continue?</p>,
              message: 'The Client Approvals feature has been turned off.',
            };

        return toggle({ path: 'client-approvals', flag, title, content, message });
      },
    },

    clients: {
      label: 'Clients',
      locked: true,
      description: values.practices
        ? `Every project in Ruddr must be associated with a client. The client entity includes important data related to the practice, relationship owner, invoices, and contacts. By default, every Ruddr workspace has an internal client for tracking internal projects. This feature can't be disabled.`
        : `Every project in Ruddr must be associated with a client. By default, every Ruddr workspace has an internal client for tracking internal projects. This feature can't be disabled.`,
    },

    disciplines: {
      label: 'Disciplines',
      locked: false,
      description: `Disciplines are used to group a common set of capabilities within the organization. For example, all levels of project managers within an organization could be assigned to a Project Management discipline. You can then filter Ruddr reports by discipline to evaluate performance.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Disciplines',
              content: <p>The Disciplines feature will be turned on. Do you want to continue?</p>,
              message: 'The Disciplines feature has been turned on.',
            }
          : {
              title: 'Turn Off Disciplines',
              content: <p>The Disciplines feature will be turned off. Do you want to continue?</p>,
              message: 'The Disciplines feature has been turned off.',
            };

        return toggle({ path: 'disciplines', flag, title, content, message });
      },
    },

    expenseReporting: {
      label: 'Expense Reporting',
      locked: false,
      description: `You can track both internal expenses and client expenses. Client expenses must be associated with a project and can optionally be billed back to the client.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Expense Reporting',
              content: <p>The Expense Reporting feature will be turned on. Do you want to continue?</p>,
              message: 'The Expense Reporting feature has been turned on.',
            }
          : {
              title: 'Turn Off Expense Reporting',
              content: <p>The Expense Reporting feature will be turned off. Do you want to continue?</p>,
              message: 'The Expense Reporting feature has been turned off.',
            };

        return toggle({ path: 'expense-reporting', flag, title, content, message });
      },
    },

    forecast: {
      label: 'Forecast and Variance Reporting',
      pro: true,
      locked: lockNotPro,
      description: `Forecasting enhances your ability to predict future business performance. This feature enables a Forecast section of reports and projected hours and services revenue on the project dashboard.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Forecast and Variance Reporting',
              content: (
                <p>
                  The Forecast and Variance Reporting feature will be turned on.{' '}
                  {!values.allocations && (
                    <span>In addition, the Resourcing feature will be turned on, as it is a dependency. </span>
                  )}
                  Do you want to continue?
                </p>
              ),
              message: `The Forecast and Variance Reporting feature ${
                !values.allocations ? 'and the Resourcing feature have ' : 'has '
              }been turned on.`,
            }
          : {
              title: 'Turn Off Forecast and Variance Reporting',
              content: <p>The Forecast and Variance Reporting feature will be turned off. Do you want to continue?</p>,
              message: 'The Forecast and Variance Reporting feature has been turned off.',
            };

        return toggle({ path: 'forecast', flag, title, content, message });
      },
    },

    manualRevenueRecognition: {
      label: 'Manual Revenue Recognition',
      pro: true,
      locked: lockNotPro,
      description: `Allows configuring fixed fee and fixed monthly projects to use the manual revenue recognition method. When this feature is off, all fixed fee and fixed monthly projects will use the "As Invoiced" revenue recognition method.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Manual Revenue Recognition',
              content: <p>The Manual Revenue Recognition feature will be turned on. Do you want to continue?</p>,
              message: 'The Manual Revenue Recognition feature has been turned on.',
            }
          : {
              title: 'Turn Off Manual Revenue Recognition',
              content: (
                <Warning>
                  By turning off Manual Revenue Recognition, all fixed fee and fixed monthly projects will use the "As
                  Invoiced" revenue recognition method. This can impact historical revenue recognition and reporting. Do
                  you want to continue?
                </Warning>
              ),
              message: 'The Manual Revenue Recognition feature has been turned off.',
            };

        return toggle({ path: 'manual-revenue-recognition', flag, title, content, message });
      },
    },

    multicurrency: {
      label: 'Multicurrency',
      pro: true,
      locked: lockNotPro,
      description: `Multicurrency allows selecting a currency for each client, project and invoice and enables automatic currency conversion.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Multicurrency',
              content: <p>The Multicurrency feature will be turned on. Do you want to continue?</p>,
              message: 'The Multicurrency feature has been turned on.',
            }
          : {
              title: 'Turn Off Multicurrency',
              content: <p>The Multicurrency feature will be turned off. Do you want to continue?</p>,
              message: 'The Multicurrency feature has been turned off.',
            };

        return toggle({ path: 'multicurrency', flag, title, content, message });
      },
    },

    approvalWorkflow: {
      label: 'Multistep Approval Workflow',
      pro: true,
      locked: lockNotPro,
      description: `Allows configuring custom approval workflows for time and expenses on a per-project basis. When this feature is disabled, the Project Admin will be the sole approver for projects configured for manual approval of time or expenses.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Multistep Approval Workflow',
              content: <p>The Multistep Approval Workflow feature will be turned on. Do you want to continue?</p>,
              message: 'The Multistep Approval Workflow feature has been turned on.',
            }
          : {
              title: 'Turn Off Multistep Approval Workflow',
              content: (
                <Warning>
                  By turning off Multistep Approval Workflow, all existing time and expense workflows will be reverted
                  to a single-step workflow with the Project Admin as the approver. Do you want to continue?
                </Warning>
              ),
              message: 'The Multistep Approval Workflow feature has been turned off.',
            };

        return toggle({ path: 'approval-workflow', flag, title, content, message });
      },
    },

    pipeline: {
      label: 'Pipeline',
      pro: true,
      locked: lockNotPro,
      description: `Pipeline features include Opportunities, Companies which can be promoted to clients, and Contacts which can have activities and associations that provide basic CRM capability.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Pipeline',
              content: <p>The Pipeline feature will be turned on. Do you want to continue?</p>,
              message: 'The Pipeline feature has been turned on.',
            }
          : {
              title: 'Turn Off Pipeline',
              content: <p>The Pipeline feature will be turned off. Do you want to continue?</p>,
              message: 'The Pipeline feature has been turned off.',
            };

        return toggle({ path: 'pipeline', flag, title, content, message });
      },
    },

    practices: {
      label: 'Practices',
      pro: true,
      locked: lockNotPro,
      description: `Practices help larger companies organize work and members into distinct groups. Clients, projects, and workspace members can be assigned to a practice. Ruddr security roles can leverage practices as well.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Practices',
              content: <p>The Practices feature will be turned on. Do you want to continue?</p>,
              message: 'The Practices feature has been turned on.',
            }
          : {
              title: 'Turn Off Practices',
              content: <p>The Practices feature will be turned off. Do you want to continue?</p>,
              message: 'The Practices feature has been turned off.',
            };

        return toggle({ path: 'practices', flag, title, content, message });
      },
    },

    projects: {
      label: 'Projects',
      locked: true,
      description: `When time is tracked in Ruddr, it must be associated with either a project or a time off type. Projects are the primary grouping of work in Ruddr and they can be either billable or non-billable. This feature can't be disabled.`,
    },

    allocations: {
      label: 'Resource Allocations',
      pro: true,
      locked: lockNotPro,
      description: `Resource allocations allow you to plan out projects and ensure that all team members have an adequate amount of work. These allocations allow you to predict key workspace metrics such as billable utilization and target attainment.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Resource Allocations',
              content: <p>The Resource Allocations feature will be turned on. Do you want to continue?</p>,
              message: 'The Resource Allocations feature has been turned on.',
            }
          : {
              title: 'Turn Off Resource Allocations',
              content: (
                <p>
                  The Resource Allocations feature will be turned off.{' '}
                  {values.forecast && (
                    <span>
                      In addition, the Forecast and Variance Reporting feature will be turned off, as it is dependent on
                      the Resource Allocation feature.{' '}
                    </span>
                  )}
                  Do you want to continue?
                </p>
              ),
              message: `The Resource Allocations feature ${
                values.forecast ? 'and the Forecast and Variance Reporting feature have ' : 'has '
              }been turned off.`,
            };

        return toggle({ path: 'allocations', flag, title, content, message });
      },
    },

    projectHealth: {
      label: 'Project Health Reporting',
      pro: true,
      locked: lockNotPro,
      description: `Project Health Reporting allows you to score a project's health with respect to the budget, schedule, client satisfaction, and team satisfaction.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Project Health Reporting',
              content: <p>The Project Health Reporting feature will be turned on. Do you want to continue?</p>,
              message: 'The Project Health Reporting feature has been turned on.',
            }
          : {
              title: 'Turn Off Project Health Reporting ',
              content: <p>The Project Health Reporting feature will be turned off. Do you want to continue?</p>,
              message: 'The Project Health Reporting feature has been turned off.',
            };

        return toggle({ path: 'project-health', flag, title, content, message });
      },
    },

    timeAttachments: {
      label: 'Time Attachments',
      locked: false,
      description: `Allows members to upload files to the week view of the time screen. This is particularly useful when a member needs to show time entries that have been entered into a client's time tracking system.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Time Attachments',
              content: <p>The Time Attachments feature will be turned on. Do you want to continue?</p>,
              message: 'The Time Attachments feature has been turned on.',
            }
          : {
              title: 'Turn Off Time Attachments',
              content: <p>The Time Attachments feature will be turned off. Do you want to continue?</p>,
              message: 'The Time Attachments feature has been turned off.',
            };

        return toggle({ path: 'time-attachments', flag, title, content, message });
      },
    },

    timeTracking: {
      label: 'Time Tracking',
      locked: true,
      description: `Time tracking is the most fundamental function in Ruddr. Time can be tracked toward projects or time off types. This feature can't be disabled.`,
    },

    timesheets: {
      label: 'Timesheets',
      locked: false,
      description: `When timesheets are enabled, time entries get submitted on a weekly basis. Once a timesheet is submitted, no additional time entries can be added to that period. When timesheets are disabled, time entries can be submitted on a daily, weekly, or monthly basis and time entries can be added to a day that has already been submitted.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Timesheets',
              content: <p>The Timesheets feature will be turned on. Do you want to continue?</p>,
              message: 'The Timesheets feature has been turned on.',
            }
          : {
              title: 'Turn Off Timesheets',
              content: <p>The Timesheets feature will be turned off. Do you want to continue?</p>,
              message: 'The Timesheets feature has been turned off.',
            };

        return toggle({ path: 'timesheets', flag, title, content, message });
      },
    },

    utilization: {
      label: 'Utilization Tracking',
      locked: false,
      description: `Utilization tracking helps evaluate individual and workforce productivity. Ruddr provides three utilization metrics including billable utilization, client utilization, and total utilization.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Utilization Tracking',
              content: <p>The Utilization Tracking feature will be turned on. Do you want to continue?</p>,
              message: 'The Utilization Tracking feature has been turned on.',
            }
          : {
              title: 'Turn Off Utilization Tracking',
              content: <p>The Utilization Tracking feature will be turned off. Do you want to continue?</p>,
              message: 'The Utilization Tracking feature has been turned off.',
            };

        return toggle({ path: 'utilization', flag, title, content, message });
      },
    },

    workspaceRoles: {
      label: 'Workspace and Client Rate Cards',
      pro: true,
      locked: lockNotPro,
      description: `Define standard roles and rates for the workspace. Create a rate card for each client and add those roles/rates to client projects.`,
      onToggle: (flag) => {
        const { title, content, message } = flag
          ? {
              title: 'Turn On Workspace and Client Rate Cards',
              content: <p>The Workspace and Client Rate Cards feature will be turned on. Do you want to continue?</p>,
              message: 'The Workspace and Client Rate Cards feature has been turned on.',
            }
          : {
              title: 'Turn Off Workspace and Client Rate Cards',
              content: <p>The Workspace and Client Rate Cards feature will be turned off. Do you want to continue?</p>,
              message: 'The Workspace and Client Rate Cards feature has been turned off.',
            };

        return toggle({ path: 'workspace-roles', flag, title, content, message });
      },
    },
  };

  const handleFeaturesChange = (key) => {
    features[key].onToggle(!values[key]);
  };

  return (
    <Page>
      {_.map(features, (value, key) => (
        <Box key={key}>
          <Content>
            <Title>
              <strong>{value.label}</strong>
              {value.pro && <ProIcon icon="rectangle-pro" />}
            </Title>

            <Description>{value.description}</Description>
          </Content>

          <Toggle>
            <Tooltip
              data-testid={key}
              message={
                value.locked
                  ? value.pro
                    ? 'This feature is only available in the Pro plan. Upgrade your plan on the Billing screen to access this feature.'
                    : `This feature can't be disabled.`
                  : null
              }>
              {value.locked ? (
                <ToggleSwitch checked={value.pro ? values[key] : true} disabled />
              ) : (
                <ToggleSwitch checked={values[key]} onChange={() => handleFeaturesChange(key)} />
              )}
            </Tooltip>
          </Toggle>
        </Box>
      ))}
    </Page>
  );
}

const Modal = styled(ModalCard)`
  ${Card} {
    width: 48rem;
  }
`;

function ToggleConfirmation({ title, content, flag, onResolve }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await onResolve(true);
  };

  return (
    <Modal title={title} onClose={() => onResolve(false)}>
      <ModalCard.Body>{content}</ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => onResolve(false)}>Cancel</CancelButton>
          {flag ? (
            <Button isLoading={isLoading} onClick={handleSubmit}>
              Turn On
            </Button>
          ) : (
            <DeleteButton isLoading={isLoading} onClick={handleSubmit}>
              Turn Off
            </DeleteButton>
          )}
        </Buttons>
      </ModalCard.Footer>
    </Modal>
  );
}

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
  font-size: 2.5rem;
`;

function Warning({ children }) {
  return (
    <Level>
      <Level.Item narrow>
        <WarningIcon icon="exclamation-triangle" />
      </Level.Item>
      <Level.Item>{children}</Level.Item>
    </Level>
  );
}
