import React from 'react';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableColumn from './TableColumn';
import TableContents from './TableContents';
import { TableProvider } from './TableContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableCellContent from './TableCellContent';
import TableFooterRow from './TableFooterRow';
import { TableMenu, TableMenuItem } from './TableMenu';
import TableActionsColumn from './TableActionsColumn';
import TableTotal from './TableTotal';
import TableBoxRow from './TableBoxRow';
import TableBoxHeader from './TableBoxHeader';
import TableBoxActionsColumn from './TableBoxActionsColumn';
import TableStatus from './TableStatus';

function Table({ ...props }) {
  return (
    <TableProvider name small>
      <TableContents {...props} />
    </TableProvider>
  );
}

Table.Header = TableHeader;
Table.Header = TableHeader;
Table.Column = TableColumn;
Table.ActionsColumn = TableActionsColumn;
Table.Body = TableBody;
Table.Row = TableRow;
Table.FooterRow = TableFooterRow;
Table.Cell = TableCell;
Table.Menu = TableMenu;
Table.MenuItem = TableMenuItem;
Table.Total = TableTotal;
Table.CellContent = TableCellContent;
Table.BoxRow = TableBoxRow;
Table.BoxHeader = TableBoxHeader;
Table.BoxActionsColumn = TableBoxActionsColumn;
Table.Status = TableStatus;

export default Table;
