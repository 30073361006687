import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { DeleteButton, Page, ActionButton } from '~/components';
import { useAuth, useDocumentTitle } from '~/hooks';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import WorkspaceDeleteConfirmation from '../settings/general/WorkspaceDeleteConfirmation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 64rem;
`;

const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: ${weights.light};
  line-height: 1.2;
`;

const Description = styled.p`
  margin-top: 1rem;
`;

const Actions = styled.p`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const LinkButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  padding: 0.5em 1.75em;
  color: ${colors.white};
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border: solid 1px transparent;
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

export default function WorkspaceBillingLocked() {
  useDocumentTitle('Workspace Locked');

  const api = useApi();
  const auth = useAuth();
  const { url } = useRouteMatch();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const [showDelete, setShowDelete] = useState(false);
  const [trialRequested, setTrialRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ok, setOk] = useState(false);

  const requestTrial = async () => {
    setIsLoading(true);
    try {
      await api.www.workspaces(workspace.id).trialRequest();
      toast.success('Your request has been sent to our team. We will be in touch shortly regarding a new free trial.');
      setIsLoading(false);
      setOk(true);
      setTimeout(() => {
        setOk(false);
        setTrialRequested(true);
      }, 1000);
    } catch ({ message }) {
      setIsLoading(false);
      toast.error(message);
    }
  };

  return (
    <Page>
      <Wrapper>
        <Title>Workspace Locked</Title>
        <Description>There is a problem with the subscription for this workspace.</Description>
        {auth.workspace.manage ? (
          <>
            <Description>
              To continue using this workspace, click the "Review Billing" button below. For other questions or
              assistance, click the "Need Help?" link at the bottom of the screen or email us at{' '}
              <a href="mailto:help@ruddr.io">help@ruddr.io</a>.
            </Description>
            <Actions>
              <LinkButton to={`${url}/settings/billing`}>Review Billing</LinkButton>
              <ActionButton onClick={requestTrial} disabled={trialRequested} isLoading={isLoading} ok={ok}>
                Request New Free Trial
              </ActionButton>
              <DeleteButton onClick={() => setShowDelete(true)}>Delete Workspace</DeleteButton>
            </Actions>
          </>
        ) : (
          <Description>
            To continue using this workspace, ask a Workspace Admin to log in and check the status of the subscription.
            For other questions or assistance, click the "Need Help?" link at the bottom of the screen or email us at{' '}
            <a href="mailto:help@ruddr.io">help@ruddr.io</a>.
          </Description>
        )}
      </Wrapper>
      {showDelete && <WorkspaceDeleteConfirmation onClose={() => setShowDelete(false)} />}
    </Page>
  );
}
