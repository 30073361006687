import {
  BackLink,
  ClientLink,
  Currency,
  Hours,
  Icon,
  InternalClientTooltip,
  Level,
  MemberFilter,
  Percent,
  ProjectLink,
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
  RouteLink,
  Stack,
  Table,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import CurrencyDropdown from '../components/CurrencyDropdown';
import FiltersGroup from '../components/FiltersGroup';
import ProjectTaskTag from '../components/ProjectTaskTag';
import ProjectTaskStatusTag from '../components/ProjectTaskStatusTag';
import ProjectTaskRecordStatusTag from '../components/ProjectTaskRecordStatusTag';
import ProjectTaskTags from '../components/ProjectTaskTags';
import TimeMemberExcludeTag from '../components/TimeMemberExcludeTag';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Task = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const NoTask = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: ${colors.grey25};
`;

const Byline = styled.small`
  display: block;
`;

export default function PerformanceByProjectAndTask() {
  useDocumentTitle('Performance by Project and Task');
  const { workspace } = useWorkspace();
  const api = useApi();
  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    excludeMembers: [],
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        excludeMembers: searchParamsConfig.excludeMembers,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      excludeMemberId: params.excludeMembers?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performanceByProjectAndTask(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceByProjectAndTask(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Performance by Project and Task</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTaskTag value={params.projectTasks} onRemove={() => handleApplyFilters({ projectTasks: [] })} />

            <ProjectTaskStatusTag
              value={params.projectTaskStatuses}
              onRemove={() => handleApplyFilters({ projectTaskStatuses: [] })}
            />

            <ProjectTaskRecordStatusTag
              value={params.projectTaskRecordStatusId}
              onRemove={() => handleApplyFilters({ projectTaskRecordStatusId: null })}
            />

            <ProjectTaskTags
              value={params.projectTaskTags}
              onRemove={() => handleApplyFilters({ projectTaskTags: [] })}
            />

            <TimeMemberExcludeTag
              value={params.excludeMembers}
              onRemove={() => handleApplyFilters({ excludeMembers: [] })}
            />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters],
  );

  const { projects, tasksByProjectId } = useMemo(() => {
    return {
      projects: report.projects,
      tasksByProjectId: _.groupBy(report.tasks, 'projectId'),
    };
  }, [report.projects, report.tasks]);

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Project" value={report.projects.length} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Hours
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right">
            Effective Bill Rate
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Cost
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Services Profit
          </Table.Column>
          <Table.Column width="6rem" align="right">
            Services Margin
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.status === 'sorting'}>
          {projects.map((project) => {
            return (
              <React.Fragment key={project.id}>
                <GroupRow top="4.075rem">
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ project: project.id })}>
                        <Hours value={project.totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesRevenue} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesRevenue} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedEffectiveRate} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.effectiveRate} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesCost} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesCost} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Currency value={project.convertedServicesProfit} currency={report.currency} />
                      {project.currency !== report.currency && (
                        <Byline>
                          <Currency value={project.servicesProfit} currency={project.currency} />
                        </Byline>
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <Percent value={project.servicesMargin} />
                    </strong>
                  </Table.Cell>
                </GroupRow>

                {tasksByProjectId[project.id]?.map((task) => {
                  return (
                    <Table.Row key={task.id}>
                      <Table.Cell>{task.id ? <Task>{task.name}</Task> : <NoTask>{task.name}</NoTask>}</Table.Cell>
                      <Table.Cell>
                        {task.id ? (
                          <RouteLink to={timeDetail({ project: project.id, projectTask: task.id })}>
                            <Hours value={task.totalHours} />
                          </RouteLink>
                        ) : (
                          <Hours value={task.totalHours} />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesRevenue} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesRevenue} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedEffectiveRate} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.effectiveRate} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesCost} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesCost} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          <Currency value={task.convertedServicesProfit} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={task.servicesProfit} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Percent value={task.servicesMargin} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          clearable={false}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Task Filters"
        filters={[
          filters.projectTasks,
          filters.projectTaskRecordStatusId,
          filters.projectTaskStatuses,
          filters.projectTaskTags,
        ]}>
        {filters.projects.length === 1 && (
          <Filter>
            <ProjectTaskFilter
              project={filters.projects[0]}
              value={filters.projectTasks}
              onChange={({ target: { value } }) => handleFilter({ projectTasks: value })}
            />
          </Filter>
        )}
        <Filter>
          <ProjectTaskStatusFilter
            value={filters.projectTaskStatuses}
            onChange={({ target: { value } }) => handleFilter({ projectTaskStatuses: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskRecordStatusFilter
            value={filters.projectTaskRecordStatusId}
            onChange={({ target: { value } }) => handleFilter({ projectTaskRecordStatusId: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskTagFilter
            value={filters.projectTaskTags}
            onChange={({ target: { value } }) => handleFilter({ projectTaskTags: value })}
          />
        </Filter>
      </FiltersGroup>

      <FiltersGroup label="Time Filters" filters={[filters.excludeMembers]}>
        <Filter>
          <MemberFilter
            value={filters.excludeMembers}
            placeholder="Exclude Member"
            onChange={({ target: { value } }) => handleFilter({ excludeMembers: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}
