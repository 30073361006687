import { ExportDialog, InfiniteScrollingObserver, Page, Spinner, Table } from '~/components';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, dateFormats } from '~/utils';
import ProjectDeleteConfirmation from './ProjectDeleteConfirmation';
import ProjectsListFilters from './ProjectsListFilters';
import ProjectsTable from './ProjectsTable';
import ProjectDrawer from './project-drawer/ProjectDrawer';

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    practice: null,
    status: null,
    client: null,
    sort: new QuerySort('name', 'asc'),
    page: 0,
    size: 25,
    withGraph: 'administrators,budget,tags',
    recordStatusId: 'active',
    billingTypeId: null,
    tags: [],
    administrator: null,
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)),
    },
  }),
  removeItem: (id, { data }) => ({
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function ProjectsListPage({ clientId, memberId, mode = 'page', renderFilters, showCreateButton, sessionKey }) {
  const documentTitle = useDocumentTitle();
  const { url } = useRouteMatch();
  useEffect(() => {
    if (mode === 'page') documentTitle.set('Projects');
  }, [mode, documentTitle]);

  const { workspace } = useWorkspace();
  const api = useApi();

  const history = useHistory();
  const { clientKey, projectKey } = useParams();
  const location = useLocation();

  const [timeEntryDrawer, setTimeEntryDrawer] = useState(null);
  const { notify } = useSubscription();

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);

  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        practice: searchParamsConfig.practice,
        client: searchParamsConfig.client,
        status: searchParamsConfig.projectStatus,
        recordStatusId: { default: initialState.query.recordStatusId, ...searchParamsConfig.recordStatusId },
        billingTypeId: searchParamsConfig.projectBillingType,
        tags: searchParamsConfig.projectTags,
        administrator: searchParamsConfig.member,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),

    sessionKey,

    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects()
        .get({
          ..._.omit(query, 'client', 'practice', 'tags', 'administrator'),
          practiceId: query.practice?.id || undefined,
          tagIds: query.tags.map((tag) => tag.id).join(',') || undefined,
          clientId: query.client?.id || clientId,
          status: query.status ?? undefined,
          billingTypeId: query.billingTypeId ?? undefined,
          recordStatusId: query.recordStatusId ?? undefined,
          administratorId: query.administrator?.id,
          memberId,
        });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: { total: 0, results: [] } });
    }
  }, [actions, workspace.id, query, api, clientId, memberId]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilter = (values) => {
    actions.setParams({ ...values });
    searchParams.set({ ..._.omit(values, 'sort') });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  async function handleSaved(project) {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects()
      .get({ ids: project.id, withGraph: 'administrators,budget' });
    actions.updateItem(data[0]);
  }

  async function handleDeleted(project) {
    actions.removeItem(project.id);
  }

  function handleClose() {
    history.push({ pathname: `/app/${workspace.key}/projects`, search: location.search }, { scrollToTop: false });
    documentTitle.set('Projects');
  }

  async function handleDeleteConfirmation(project) {
    confirmation.prompt((resolve) => (
      <ProjectDeleteConfirmation
        project={project}
        onClose={resolve}
        onDelete={() => {
          handleDeleted(project);
          resolve(true);
        }}
      />
    ));
  }

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects()
          .export(
            {
              ..._.omit(query, 'client', 'practice', 'tags', 'administrator'),
              practiceId: query.practice?.id || undefined,
              tagIds: query.tags.map((tag) => tag.id).join(',') || undefined,
              clientId: query.client?.id || clientId,
              status: query.status ?? undefined,
              billingTypeId: query.billingTypeId ?? undefined,
              size: null,
              recordStatusId: query.recordStatusId ?? undefined,
              administratorId: query.administrator?.id,
              memberId,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady && !data) return <PageLoader />;

  const Container = mode === 'page' ? Page : React.Fragment;

  const isEdit = clientKey && projectKey && url === `/app/${workspace.key}/projects/edit/${clientKey}/${projectKey}`;

  return (
    <>
      <Container>
        {renderFilters ? (
          renderFilters({ query, onFilter: handleFilter })
        ) : (
          <Page.Section>
            <ProjectsListFilters
              filters={query}
              onExport={handleExport}
              showCreateButton={showCreateButton}
              onChange={handleFilter}
            />
          </Page.Section>
        )}

        <Page.Section>
          <Table.Status>
            {!!action && <Spinner />}
            <Table.Total value={data.total} label="Project" />
          </Table.Status>

          <ProjectsTable
            projects={data.results}
            query={query}
            mode={mode}
            action={action}
            onRowClick={({ key, client }) => history.push(`/app/${workspace.key}/projects/${client.key}/${key}`)}
            onEdit={(project) =>
              history.push({
                pathname: `${url}/edit/${project.client.key}/${project.key}`,
                search: location.search,
                state: { scrollToTop: false },
              })
            }
            onClone={(project) =>
              history.push({
                pathname: `${url}/clone/${project.client.key}/${project.key}`,
                search: location.search,
                state: { scrollToTop: false },
              })
            }
            onDelete={handleDeleteConfirmation}
            onSaved={handleSaved}
            onSort={handleSort}
            onDrawerClose={() => documentTitle.set('Projects')}
            onTrackTime={(value) => setTimeEntryDrawer(value)}
          />

          {data.total > data.results.length && (
            <InfiniteScrollingObserver key={data.results.length} onIntersecting={actions.loadMore} />
          )}
        </Page.Section>
      </Container>

      {isEdit && (
        <ProjectDrawer
          clientKey={clientKey}
          projectKey={projectKey}
          onSaved={handleSaved}
          onDeleted={handleDeleted}
          onClose={handleClose}
        />
      )}
      {timeEntryDrawer && (
        <EditTimeEntry
          initialValues={{ projectId: timeEntryDrawer.project.id, date: moment().format(dateFormats.isoDate) }}
          onClose={() => {
            setTimeEntryDrawer(null);
            documentTitle.set('Projects');
          }}
          onSaved={(timeEntry) => {
            notify(useSubscription.keys.refresh_timer);
            handleSaved(timeEntry.project);
          }}
        />
      )}
    </>
  );
}

export default ProjectsListPage;
