function slugify(source) {
  return source
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-') // Replace non word characters with hyphens
    .replace(/^[^\w]+/, '') // Remove leading hyphens
    .replace(/[^\w]+$/, '') // Remove trailing hyphens
    .replace(/-+/g, '-'); // Remove repeated hyphens
}

export default slugify;
