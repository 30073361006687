const expenseLockedReasons = {
  invoice_published: 'Expense item is associated with a published invoice.',
  externally_managed: 'Expense item is managed by an external integration.',
  feature_disabled: 'The Expense Reporting feature is disabled.',
  max_age: 'Expense item is older than the workspace limit.',
  reimbursed: 'Expense item has been reimbursed.',
  project_archived: 'Expense item is associated with an archived project.',
  project_locked: 'Expense items for this project have been locked.',
  approved: 'Expense item has been manually approved.',
  step_approved: 'Expense item has an approved workflow step.',
  client_approved: 'Expense item has been approved by the client.',
  not_assigned: 'Expense item is associated with a project to which you are no longer assigned.',
  insufficient_rights: 'Expense item is not yours and you do not have administrative privileges to it.',
};

export default expenseLockedReasons;
