import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function ExpenseInstruction() {
  return (
    <>
      <p>Organize your CSV contents into the following columns: </p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Required?</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>Yes</td>
            <td>
              The ID of the expense item to import, it will be used to check for duplicates if multiple imports are run.
            </td>
          </tr>
          <tr>
            <td>Date</td>
            <td>Yes</td>
            <td>Date of the expense item in 'MM/DD/YYYY' format.</td>
          </tr>
          <tr>
            <td>Category</td>
            <td>Yes</td>
            <td>
              The expense category associated with the expense item. This must match the Name of an expense category
              exactly on white space, case, and punctuations or the row will be skipped.
            </td>
          </tr>
          <tr>
            <td>Member Email</td>
            <td>Yes</td>
            <td>
              Email address of the member for the expense item. It should be lowercase and match the member's profile in
              Ruddr.
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>Yes</td>
            <td>
              'approved', 'pending_approval' or 'not_submitted' are valid options for this field. If none of these are
              provided the default will be 'not_submitted'.
            </td>
          </tr>
          <tr>
            <td>Unit Amount</td>
            <td>No</td>
            <td>The cost of each unit purchased for the expense item.</td>
          </tr>
          <tr>
            <td>Unit Count</td>
            <td>No</td>
            <td>The number of units associated with this expense item.</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>Yes</td>
            <td>
              The expense amount should be a numeric value without any currency symbols. This value will be ignored and
              re-calculated if both unit cost and unit amount are provided.
            </td>
          </tr>
          <tr>
            <td>Vendor Name</td>
            <td>No</td>
            <td>Vendor or seller's name for this expense item.</td>
          </tr>
          <tr>
            <td>Client Name</td>
            <td>No</td>
            <td>
              The Name of the client associated with the expense item. This must match the Name of a client exactly on
              white space, case, and punctuations. This will be used in conjunction with the project name to assign the
              project to the expense item.
            </td>
          </tr>
          <tr>
            <td>Project Name</td>
            <td>No</td>
            <td>
              The Name of the project associated with this expense item. This must match the Name of a project exactly
              on white space, case, and punctuations. This will be used in conjunction with the client name to assign
              the project to the expense item.
            </td>
          </tr>
          <tr>
            <td>Billable</td>
            <td>No</td>
            <td>Set this value to 'Yes' if billable, otherwise will be treated as non-billable.</td>
          </tr>
          <tr>
            <td>Reimbursable</td>
            <td>No</td>
            <td>
              Set this value to 'Yes' if reimbursable to the member, otherwise will be treated as not reimbursable.
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>No</td>
            <td>Provide some description or notes related to the expense item.</td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
