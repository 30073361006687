import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTimer, useWorkspace, useMember } from '~/contexts';
import { formatDuration } from '~/utils';

function Duration({ minutes = 0, timerStartedAt, useDecimal, isReadable, showSeconds, trim }) {
  const { workspace } = useWorkspace();
  const { nowRef, subTimer, unsubTimer } = useTimer();
  const [, setRefresh] = useState({});
  const { member } = useMember();

  // If `timerStartedAt` is supplied, then refresh this component on the global timer
  useEffect(() => {
    const id = timerStartedAt ? subTimer(() => setRefresh({})) : null;
    return () => (id ? unsubTimer(id) : null);
  }, [timerStartedAt, subTimer, unsubTimer]);

  const duration = moment.duration(minutes, 'minutes');
  if (timerStartedAt) {
    // Math.max used to ensure the timer diff is never below 0
    // in case "now" is a bit behind or the user/app clock is a bit off
    duration.add(Math.max(moment(nowRef.current).diff(timerStartedAt), 0));
  }

  // This component respects the workspace setting to determine decimal vs hh:mm format
  // unless manually overridden by passing in a prop
  return formatDuration(duration, {
    useDecimal: useDecimal ?? member?.useDecimalTimeEntry ?? workspace?.useDecimalTimeEntry ?? false,
    isReadable,
    showSeconds,
    trim,
    locale: member.locale,
  });
}

export default Duration;
