import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox, DayPickerInput, SingleSelect } from '~/components';
import { useDocumentTitle } from '~/hooks';
import { dateFormats } from '~/utils';
import { Header, Page } from '../components';
import DailyActiveUsers from './DailyActiveUsers';
import HourlyActiveUsers from './HourlyActiveUsers';
import InvoicesPerWorkspace from './InvoicesPerWorkspace';
import MembersPerWorkspace from './MembersPerWorkspace';
import MissingAssociatedMembers from './MissingAssociatedMembers';
import MonthlyActiveUsers from './MonthlyActiveUsers';
import TrackedHoursPerDay from './TrackedHoursPerDay';
import TrackedHoursPerWorkspace from './TrackedHoursPerWorkspace';
import TrialsEndingSoon from './TrialsEndingSoon';
import WeeklyActiveUsers from './WeeklyActiveUsers';
import Ratings from './Ratings';

const reports = [
  'hau',
  'dau',
  'wau',
  'mau',
  'hours-tracked-daily',
  'hours-tracked',
  'members',
  'invoices',
  'ratings',
  'trials',
  'missing-members',
];

const Filters = styled.div`
  display: flex;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 1.5rem;
  }
`;

function Report({ report, ...props }) {
  switch (report) {
    case 'hau':
      return <HourlyActiveUsers {...props} />;
    case 'dau':
      return <DailyActiveUsers {...props} />;
    case 'wau':
      return <WeeklyActiveUsers {...props} />;
    case 'mau':
      return <MonthlyActiveUsers {...props} />;
    case 'ratings':
      return <Ratings {...props} />;
    case 'hours-tracked-daily':
      return <TrackedHoursPerDay {...props} />;
    case 'hours-tracked':
      return <TrackedHoursPerWorkspace {...props} />;
    case 'members':
      return <MembersPerWorkspace {...props} />;
    case 'missing-members':
      return <MissingAssociatedMembers {...props} />;
    case 'invoices':
      return <InvoicesPerWorkspace {...props} />;
    case 'trials':
      return <TrialsEndingSoon {...props} />;
    default:
      return null;
  }
}

export default function Reports() {
  useDocumentTitle('Reports - Admin');

  const history = useHistory();
  const route = useRouteMatch('/admin/reports/:report?');
  const routeReport = route?.params?.report;
  const [report, setReport] = useState();
  const [timespan, setTimespan] = useState('this-month');
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [showPaying, setShowPaying] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    switch (timespan) {
      case 'all':
        setStart();
        setEnd();
        break;
      case 'this-month':
        setStart(moment().startOf('month').format(dateFormats.isoDate));
        setEnd(moment().endOf('month').format(dateFormats.isoDate));
        break;
      case 'last-month':
        setStart(moment().subtract(1, 'month').startOf('month').format(dateFormats.isoDate));
        setEnd(moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate));
        break;
      default:
        break;
    }
    setIsReady(true);
  }, [timespan]);

  useEffect(() => {
    if (!routeReport || !reports.includes(routeReport)) {
      setReport('dau');
      history.replace({ pathname: '/admin/reports/dau', search: window.location.search });
    } else {
      setReport(routeReport);
    }
  }, [history, routeReport]);

  useEffect(() => {
    const pathname = `/admin/reports/${report}`;
    if (report === undefined || window.location.pathname === pathname) {
      return;
    }
    history.push({ pathname, search: window.location.search });
  }, [history, report]);

  return (
    <Page>
      <Header />
      <Filters>
        <Filter style={{ minWidth: '20rem' }}>
          <SingleSelect placeholder="Report" value={report} onChange={(event) => setReport(event.target.value)}>
            <option value="hau">Hourly Active Users (HAUs)</option>
            <option value="dau">Daily Active Users (DAUs)</option>
            <option value="wau">Weekly Active Users (WAUs)</option>
            <option value="mau">Monthly Active Users (MAUs)</option>
            <option value="ratings">User Ratings and Feedback</option>
            <option value="hours-tracked-daily">Tracked Hours Per Day</option>
            <option value="hours-tracked">Tracked Hours Per Workspace</option>
            <option value="members">Members Per Workspace</option>
            <option value="invoices">Invoices Per Workspace</option>
            <option value="trials">Trials Ending Soon</option>
            <option value="missing-members">Missing Associated Members</option>
          </SingleSelect>
        </Filter>
        {report && !['trials', 'missing-members'].includes(report) && (
          <>
            <Filter style={{ minWidth: '12rem' }}>
              <SingleSelect
                placeholder={!['members'].includes(report) ? 'Timespan' : 'Workspace Created'}
                value={timespan}
                onChange={(event) => setTimespan(event.target.value)}>
                <option value="all">All</option>
                <option value="this-month">This Month</option>
                <option value="last-month">Last Month</option>
                <option value="custom">Custom</option>
              </SingleSelect>
            </Filter>
            {timespan === 'custom' && (
              <>
                <Filter>
                  <DayPickerInput
                    placeholder="Start"
                    value={start}
                    onChange={(value) => setStart(value ?? undefined)}
                  />
                </Filter>
                <Filter>
                  <DayPickerInput placeholder="End" value={end} onChange={(value) => setEnd(value ?? undefined)} />
                </Filter>
              </>
            )}
            {!['all', 'custom'].includes(timespan) && (
              <Filter>
                {!!start && moment(start).format(dateFormats.compactDate)} -{' '}
                {!!end && moment(end).format(dateFormats.compactDate)}
              </Filter>
            )}
          </>
        )}
        {report && ['members', 'trials'].includes(report) && (
          <Filter>
            <Checkbox
              label="Show only paying customers"
              checked={showPaying}
              onChange={() => setShowPaying((value) => !value)}
            />
          </Filter>
        )}
      </Filters>
      {report && isReady && <Report report={report} start={start} end={end} showPaying={showPaying} />}
    </Page>
  );
}
