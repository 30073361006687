import { useFeatures } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import UtilizationByTimeUnit from './UtilizationByTimeUnit';
import UtilizationByMember from './UtilizationByMember';
import UtilizationByMemberPractice from './UtilizationByMemberPractice';
import UtilizationReportsPage from './UtilizationReportsPage';

function UtilizationReportsArea() {
  const { path, url } = useRouteMatch();
  const features = useFeatures();

  return (
    <Switch>
      <Route path={path} exact>
        <UtilizationReportsPage />
      </Route>

      <Route path={path.concat('/utilization-by-member')}>
        <UtilizationByMember />
      </Route>

      {features.practices && (
        <Route path={path.concat('/utilization-by-member-practice')}>
          <UtilizationByMemberPractice />
        </Route>
      )}

      <Route path={path.concat('/utilization-by-time-unit')}>
        <UtilizationByTimeUnit />
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}

export default UtilizationReportsArea;
