import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ExportDialog, Level, Table } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ActiveStatusSelect, CustomDataHeader, HolidayScheduleFilter } from '../custom-data/components';
import HolidayDrawer from './HolidayDrawer';
import HolidayRow from './HolidayRow';
import ExportDropdown from '../ExportDropdown';
import { mimeTypes } from '~/utils';

function HolidaysPage() {
  const title = 'Holidays';
  const documentTitle = useDocumentTitle(title);

  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ isActive: 'true', holidayScheduleId: '', withSchedules: true });

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).holidays().get(params);
      setData(data);
    } catch {
      setData([]);
    }
  }, [api, workspace, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleEdit(item) {
    history.push(url.concat(`/${item.id}/edit`));
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/custom-data/holidays`);
    documentTitle.set(title);
  }

  const handleSaved = (holiday) => {
    const newData = _(data).reject({ id: holiday.id }).concat(holiday).sortBy('name').value();
    setData(newData);
  };

  const handleActiveStatusChange = async (holiday, flag) => {
    try {
      const { data } = await api.www.workspaces(workspace.id).holidays(holiday.id).setActiveStatus(flag);
      handleSaved(data);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleDelete = async (holiday) => {
    try {
      await api.www.workspaces(workspace.id).holidays(holiday.id).delete();
      const newData = _.reject(data, { id: holiday.id });
      setData(newData);
      return true;
    } catch ({ message }) {
      toast.error(message);
      return false;
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .holidays()
          .get({ isActive: params.isActive }, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Holidays</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Ruddr has several built-in standard holidays that can be added to your{' '}
            <Link to={`/app/${workspace.key}/settings/custom-data/holiday-schedules`}>holiday schedules</Link>. If your
            company observes a holiday that isn't built-in, you can create a custom holiday.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Link to={url.concat('/new')} className="button">
            New Custom Holiday
          </Link>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <HolidayScheduleFilter
            materialPlaceholder="Holiday Schedule"
            materialAlwaysVisible
            placeholder="All"
            showEmptyOption={true}
            value={params.holidayScheduleId}
            name="holidayScheduleId"
            onChange={handleChange}
          />
        </Level.Item>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
        </Level.Item>

        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holidays.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holidays.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Holiday" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column>Name</Table.Column>
          <Table.Column>Holiday Schedule</Table.Column>
          <Table.Column>Next Occurrence</Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((item) => (
            <HolidayRow
              key={item.id}
              holiday={item}
              onDelete={handleDelete}
              onEdit={() => handleEdit(item)}
              onActiveStatusChange={handleActiveStatusChange}
            />
          ))}
        </Table.Body>
      </Table>
      <Switch>
        <Route path={`${path}/new`}>
          <HolidayDrawer onClose={handleCloseDrawer} onSaved={handleSaved} />
        </Route>
        <Route path={`${path}/:holidayId/edit`}>
          <HolidayDrawer onClose={handleCloseDrawer} onDelete={handleDelete} onSaved={handleSaved} />
        </Route>
      </Switch>
    </>
  );
}

export default HolidaysPage;
