import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

export const ResizeHandle = styled.div`
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 0.75rem;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey55};
  top: 0;
  transition: opacity 150ms ease-in-out;
  font-size: 0.75rem;
  overflow: hidden;

  &:hover {
    cursor: ew-resize;
  }

  &:last-child {
    min-width: 4px;
  }
`;

export const AllocationStyles = styled.div`
  /* Layout */
  position: absolute;
  min-width: 1px;
  min-height: 1px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  pointer-events: all;

  /* Styles */
  &:hover {
    ${ResizeHandle} {
      opacity: 0.75;
    }
  }

  ${({ resizing }) =>
    resizing &&
    css`
      ${ResizeHandle} {
        opacity: 0.75;
      }

      cursor: ew-resize;
    `}
`;

export const AllocationBox = styled.div`
  flex: 1;
  display: flex;
  border-radius: 5px;
  transition: all 250ms ease-in-out;

  ${({ allocation: { billableTypeId, isTentative } }) =>
    ({
      billable: css`
        background: ${isTentative
          ? `repeating-linear-gradient(
              45deg,
              ${rgba(colors.success10, 0.75)},
              ${rgba(colors.success10, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 10px
            )`
          : rgba(colors.success10, 0.75)};
        color: ${colors.success};
        border: 1px solid ${colors.success25};
        cursor: pointer;

        &:hover {
          border-color: ${colors.success50};
        }
      `,

      non_billable: css`
        background: ${isTentative
          ? `repeating-linear-gradient(
              45deg,
              ${rgba(colors.danger10, 0.75)},
              ${rgba(colors.danger10, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 10px
            )`
          : rgba(colors.danger10, 0.75)};
        color: ${colors.danger};
        border: 1px solid ${colors.danger25};
        cursor: pointer;

        &:hover {
          border-color: ${colors.danger50};
        }
      `,

      internal: css`
        background: ${isTentative
          ? `repeating-linear-gradient(
              45deg,
              ${rgba(colors.warning10, 0.75)},
              ${rgba(colors.warning10, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 5px,
              ${rgba(colors.white, 0.75)} 10px
            )`
          : rgba(colors.warning10, 0.75)};
        color: ${colors.warning};
        border: 1px solid ${colors.warning25};
        cursor: pointer;

        &:hover {
          border-color: ${colors.warning50};
        }
      `,

      time_off: css`
        background: ${rgba(colors.primary10, 0.75)};
        color: ${colors.primary};
        border: 1px solid ${colors.primary25};

        &:hover {
          border-color: ${colors.primary50};
        }
      `,
    })[billableTypeId]}

  ${({ allocation: { entity } }) =>
    ({
      allocation: css`
        cursor: pointer;
      `,
      time_entry: css`
        border-style: dashed;
      `,
      holiday: css`
        border-style: dashed;
      `,
    })[entity]}
`;

export const AllocationContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  margin: 0 0.75rem;
`;

export const AllocationContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: ${weights.bold};
`;

export const AllocationDetails = styled.div`
  /* Style */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${colors.white};
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

export const AllocationTooltip = styled.div`
  min-width: 7.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 300;

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`;
