import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';

const contacts =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'contacts', id]).compact().join('/');

    return {
      get: (query = {}) => {
        return get(url.concat(new QueryString(query).toString(true)));
      },
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      create: (body) => {
        return post(url, body);
      },
      update: (body) => {
        return patch(url, body);
      },
      delete: () => del(url),
      hasDependencies: () => get(url.concat('/has-dependencies')),

      clients: (clientId = '') => {
        const clientUrl = _([url, 'clients', clientId]).compact().join('/');
        return {
          setActiveStatus: (flag = true) => put(clientUrl.concat('/is-active'), { isActive: flag }),
          add: () => post(clientUrl),
          remove: () => del(clientUrl),
        };
      },

      companies: (companyId = '') => {
        const companyUrl = _([url, 'companies', companyId]).compact().join('/');
        return {
          setActiveStatus: (flag = true) => put(companyUrl.concat('/is-active'), { isActive: flag }),
          setPrimaryStatus: (flag = true) => put(companyUrl.concat('/is-primary'), { isPrimary: flag }),
          add: () => post(companyUrl),
          remove: () => del(companyUrl),
        };
      },

      opportunities: (opportunityId = '') => {
        const opportunityUrl = _([url, 'opportunities', opportunityId]).compact().join('/');
        return {
          setActiveStatus: (flag = true) => put(opportunityUrl.concat('/is-active'), { isActive: flag }),
          setPrimaryStatus: (flag = true) => put(opportunityUrl.concat('/is-primary'), { isPrimary: flag }),
          add: (body) => post(opportunityUrl, body),
          remove: () => del(opportunityUrl),
        };
      },
    };
  };

export default contacts;
