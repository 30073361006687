import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { useImpersonation } from '../contexts';
import CurrentImpersonation from './CurrentImpersonation';
import RecentImpersonations from './RecentImpersonations';

const LoadingIcon = styled(Icon)`
  font-size: 1.25rem;
`;

const AngleIcon = styled(Icon)`
  margin-left: 1.25rem;
`;

const Selector = styled.div`
  display: flex;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.25rem;
  border: solid 1px ${({ isImpersonating }) => (isImpersonating ? colors.danger25 : colors.grey10)};
  border-radius: 0.3125rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${({ isImpersonating }) => (isImpersonating ? colors.danger : colors.grey75)};
  }

  ${AngleIcon} {
    color: ${({ isImpersonating }) => (isImpersonating ? colors.danger50 : colors.grey25)};
  }

  &:hover ${AngleIcon} {
    color: ${({ isImpersonating }) => (isImpersonating ? colors.danger : colors.grey75)};
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 5rem;
  right: 0;
  display: none;
  flex-direction: column;
  width: 35rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey25};
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  margin-left: auto;

  ${({ isOpen = false }) =>
    isOpen &&
    css`
      ${Selector} {
        border-color: ${({ isImpersonating }) => (isImpersonating ? colors.danger : colors.grey75)};
      }

      ${AngleIcon} {
        color: ${({ isImpersonating }) => (isImpersonating ? colors.danger : colors.grey75)};
      }

      ${Menu} {
        display: flex;
      }
    `}
`;

const ActionsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const ActionsLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-align: center;
  text-transform: uppercase;
`;

const ActionButton = styled(Button)`
  margin-right: 0.5rem;
`;

const CopyButton = styled(Button)`
  width: 2.5rem;
  padding: 0;
`;

export default function Impersonation() {
  const { impersonatedMemberId, impersonatedMember } = useImpersonation();
  const { navigateWorkspace } = useWorkspace();

  const [isOpen, setOpen] = useState(false);
  const menuElement = useRef();
  const selectorElement = useRef();

  const isReady = useMemo(
    () => !impersonatedMemberId || impersonatedMember,
    [impersonatedMemberId, impersonatedMember],
  );

  useEffect(() => {
    function closeMenu(event) {
      // Prevent this firing when the user clicks the menu/selector
      if (menuElement.current?.contains(event.target) || selectorElement.current?.contains(event.target)) {
        return false;
      }
      setOpen(false);
    }

    // Only attach event listener if menu is open
    if (isOpen) {
      document.addEventListener('mouseup', closeMenu);
    }

    return function cleanup() {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, [isOpen]);

  const handleWorkspaceNavigation = (workspaceKey) => {
    navigateWorkspace(workspaceKey);
  };

  const handleWorkspaceIdCopy = (workspaceId) => {
    navigator.clipboard.writeText(workspaceId);
  };

  return (
    <Container isOpen={isOpen} isImpersonating={!!impersonatedMemberId}>
      <Selector ref={selectorElement} onClick={() => setOpen(!isOpen)} isImpersonating={!!impersonatedMemberId}>
        {isReady ? <CurrentImpersonation member={impersonatedMember} /> : <LoadingIcon icon="spinner" spin={true} />}
        <AngleIcon icon="angle-down" />
      </Selector>
      <Menu ref={menuElement}>
        {impersonatedMember && (
          <ActionsArea>
            <ActionsLabel>Actions</ActionsLabel>
            <Actions>
              <ActionButton onClick={() => handleWorkspaceNavigation(impersonatedMember.workspace.key)}>
                Go To Workspace
              </ActionButton>
              <CopyButton onClick={() => handleWorkspaceIdCopy(impersonatedMember.workspace.id)}>
                <Icon icon="clipboard" />
              </CopyButton>
            </Actions>
          </ActionsArea>
        )}
        <RecentImpersonations />
      </Menu>
    </Container>
  );
}
