import _ from 'lodash';
import { get, patch, put } from './agent';

const myProfile = (workspacesUrl) => {
  const url = _([workspacesUrl, 'my-profile']).compact().join('/');

  return {
    get: () => get(url),
    patch: ({ image, locale, name, timeZoneId, useDecimalTimeEntry }) => {
      const formData = new FormData();
      if (image !== false) {
        formData.append('image', image);
      }
      formData.append('locale', locale);
      formData.append('name', name);
      formData.append('timeZoneId', timeZoneId);
      formData.append('useDecimalTimeEntry', useDecimalTimeEntry ?? '');
      return patch(url, formData);
    },
    changePassword: ({ currentPassword, newPassword, newPasswordVerification }) =>
      put(`${url}/password`, {
        currentPassword,
        newPassword,
        newPasswordVerification,
      }),
  };
};

export default myProfile;
