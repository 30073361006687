import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useIsMounted } from '~/hooks';
import MyAllocationsTooltipMessage from './MyAllocationsTooltipMessage';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const delay = 250;

function MyAllocationsTooltip({ cell, ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const hoverHandler = useRef(null);
  const isMounted = useIsMounted();

  const handleMouseEnter = () => {
    if (hoverHandler.current) clearTimeout(hoverHandler.current);
    hoverHandler.current = () => setVisible(true);

    setTimeout(() => {
      if (hoverHandler.current && isMounted.current) hoverHandler.current();
      hoverHandler.current = null;
    }, delay);
  };

  const handleMouseLeave = () => {
    setVisible(false);

    if (hoverHandler.current) {
      clearTimeout(hoverHandler.current);
      hoverHandler.current = null;
    }
  };

  return (
    <>
      <Container ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible && <MyAllocationsTooltipMessage cell={cell} referenceElement={referenceElement} />}
    </>
  );
}

export default MyAllocationsTooltip;
