import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Button, DeleteButton, Icon } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import { useImpersonation } from '../contexts';

const MembersArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  border-top: solid 1px ${colors.grey10};
`;

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey75};
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem 0;

  &:not(:first-child) {
    border-top: solid 1px ${colors.grey5};
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Info = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 10rem;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.125rem 0.875rem;
`;

const Tag = styled.div`
  display: flex;
  margin: 0.125rem;
  padding: 0.125rem;
  font-size: 0.625rem;
  background-color: ${({ color }) => color ?? colors.grey55};
  border-radius: 0.25rem;
`;

const TagLabel = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.grey55};
  background-color: ${colors.white};
  border-radius: 0.25rem;
`;

const TagValue = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
`;

const Actions = styled.div`
  margin-left: auto;
`;

const WorkspaceAdminStar = styled.span`
  color: ${colors.yellow};
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.75rem;
  padding-left: 0.5rem;
`;

export default function Members({ workspaceId }) {
  const api = useApi();
  const { impersonatedMemberId, impersonate, stopImpersonation } = useImpersonation();
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState();

  useEffect(() => {
    const getMembers = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.admin.workspaces.getMembers(workspaceId);
        setMembers(data);
      } finally {
        setIsLoading(false);
      }
    };
    getMembers();
    return getMembers.cancel;
  }, [api, workspaceId]);

  return (
    <MembersArea>
      {isLoading ? (
        <LoadingIndicator>
          <Icon icon="spinner" spin={true} />
        </LoadingIndicator>
      ) : (
        members.map((m) => (
          <Member key={m.id}>
            <Avatar value={m} size={40} isCircle hasBackground />
            <Info>
              <Title>
                {m.name}
                <WorkspaceAdminStar>
                  {m.securityRole.manageWorkspace === true && <Icon icon="star"></Icon>}
                </WorkspaceAdminStar>
              </Title>
              <Subtitle>{m.email}</Subtitle>
            </Info>
            <Tags>
              <Tag color={m.isActive ? colors.primary : colors.warning}>
                <TagValue>{m.isActive ? 'Active' : 'Not Active'}</TagValue>
              </Tag>
              <Tag color={m.isBillable ? colors.primary : colors.warning}>
                <TagValue>{m.isBillable ? 'Billable' : 'Not Billable'}</TagValue>
              </Tag>
              <Tag color={m.isVerified ? colors.primary : colors.warning}>
                <TagValue>{m.isVerified ? 'Verified' : 'Not Verified'}</TagValue>
              </Tag>
              <Tag>
                <TagLabel>Security Role</TagLabel>
                <TagValue>{m.securityRole.name}</TagValue>
              </Tag>
              <Tag>
                <TagLabel>Created</TagLabel>
                <TagValue>{moment(m.createdAt).format(dateFormats.compactDate)}</TagValue>
              </Tag>
              {!!m.accessedAt && (
                <Tag>
                  <TagLabel>Last Active</TagLabel>
                  <TagValue>{moment(m.accessedAt).format(dateFormats.compactDateTime)}</TagValue>
                </Tag>
              )}
            </Tags>
            <Actions>
              {impersonatedMemberId === m.id ? (
                <DeleteButton onClick={() => stopImpersonation()}>Deactivate</DeleteButton>
              ) : (
                <Button disabled={!m.isActive || !m.isVerified} onClick={() => impersonate(m.id)}>
                  Impersonate
                </Button>
              )}
            </Actions>
          </Member>
        ))
      )}
    </MembersArea>
  );
}
