import React from 'react';
import { Field, Form, FormMessage, Icon, Tooltip } from '~/components';
import { useWorkspace } from '~/contexts';
import styled from 'styled-components';
import { colors } from '~/styles';

const UseDefaultButton = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.grey40};
  background: ${colors.grey5};
  border-radius: 0.3125rem;
  cursor: pointer;

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

function EmailTab({ message, formik, status }) {
  const { workspace } = useWorkspace();

  const handleEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('email', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('emails', values);
  };

  const processEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/emails\[(\d+)\]/, (match, p1) => `To email #${parseInt(p1) + 1}`);
    });
  };

  const handleCcEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('ccEmail', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('ccEmails', values);
  };

  const processCcEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/ccEmails\[(\d+)\]/, (match, p1) => `Cc email #${parseInt(p1) + 1}`);
    });
  };

  return (
    <Form>
      {status && <FormMessage.Error>{message}</FormMessage.Error>}
      <Form.Section title="Email Details">
        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
          <Field.Text
            name="emails"
            placeholder="To"
            value={formik.values.email}
            onChange={handleEmailChange}
            onProcessErrors={processEmailErrors}
          />
        </Form.Control>
        <Form.Control help='The name to display in the "from" part of the email. If not provided, "Ruddr" will be used.'>
          <Field.Text name="emailFromName" placeholder="From Name" maxLength={255} />
          {workspace.invoiceEmailFromName && formik.values.emailFromName !== workspace.invoiceEmailFromName && (
            <Tooltip
              Container={UseDefaultButton}
              message={`Use workspace default: ${workspace.invoiceEmailFromName}`}
              onClick={() => formik.setFieldValue('emailFromName', workspace.invoiceEmailFromName)}>
              <Icon icon="clipboard" />
            </Tooltip>
          )}
        </Form.Control>
        <Form.Control help='In order to ensure deliverability, all emails will come from invoices@ruddr.io, but this will be used as the "Reply-To" email adddress.'>
          <Field.Text name="replyTo" placeholder="Reply To" type="email" maxLength={255} />
        </Form.Control>
        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
          <Field.Text
            name="ccEmails"
            placeholder="Cc"
            value={formik.values.ccEmail}
            onChange={handleCcEmailChange}
            onProcessErrors={processCcEmailErrors}
          />
        </Form.Control>
        <Form.Control>
          <Field.Checkbox name="sendBcc" label="Bcc Me" />
        </Form.Control>
        <Form.Control>
          <Field.Text name="emailSubject" placeholder="Subject" maxLength={255} />
        </Form.Control>
        <Form.Control>
          <Field.TextArea name="emailBody" placeholder="Body" maxLength={5000} />
        </Form.Control>
      </Form.Section>
    </Form>
  );
}

export default EmailTab;
