import React from 'react';
import styled from 'styled-components';

import { colors } from '~/styles';
import { alignToJustify } from './TableColumn';

const CellContent = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    justify-content: ${({ align }) => alignToJustify(align)};
    padding-top: 0.25rem;
  }
`;

function TableCellContent({ children, align }) {
  return <CellContent align={align}>{children}</CellContent>;
}

export default TableCellContent;
