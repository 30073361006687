import { useEffect, useRef, useState } from 'react';

export default function useHover(active = true) {
  const mousePos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      mousePos.current = { x: event.clientX, y: event.clientY };
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Hover
  const [event, setEvent] = useState(null);
  const hoverHandler = useRef(null);

  useEffect(() => {
    if (!active) {
      setEvent(null);
      if (hoverHandler.current) {
        clearTimeout(hoverHandler.current);
        hoverHandler.current = null;
      }
    }
  }, [active]);

  const handleEnter = () => {
    if (hoverHandler.current) clearTimeout(hoverHandler.current);
    if (!active) return;

    hoverHandler.current = () => setEvent(mousePos.current);

    setTimeout(() => {
      if (hoverHandler.current) {
        hoverHandler.current();
      }

      hoverHandler.current = null;
    }, 500);
  };

  const handleLeave = () => {
    setEvent(null);
    clearTimeout(hoverHandler.current);
    hoverHandler.current = null;
  };

  return { event, handleEnter, handleLeave };
}
