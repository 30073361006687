import { Icon, Tooltip } from '~/components';
import projectStatuses from '~/lookups/project-statuses';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import ProjectPopover from '../../components/ProjectPopover';
import { Byline, Label } from '../../components/schedule/sidebar';

const TooltipContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;

  &::after {
    content: ' ';
    opacity: 0.25;
    height: 100%;
    width: 100%;
    background: repeating-linear-gradient(
      45deg,
      ${colors.grey10},
      ${colors.grey10} 5px,
      ${colors.white} 5px,
      ${colors.white} 10px
    );
  }

  > div {
    position: absolute;
    right: 0;
    pointer-events: all;
    margin-left: auto;
    padding-right: 0.75rem;
  }
`;

export default function Row({ row, options }) {
  if (!row.assignment) return null;
  return (
    <>
      {{
        project: () => (
          <>
            <Label>
              <ProjectPopover projectId={row.assignment.id}>{row.assignment.name}</ProjectPopover>
            </Label>

            {row.assignment.client && <Byline>{row.assignment.client.name}</Byline>}

            <Info row={row} options={options} />
          </>
        ),
        time_off: () => <Label>{row.assignment.name}</Label>,
      }[row.assignment.assignmentType]()}
    </>
  );
}

function Info({ row, options }) {
  if (options.showAssigned && !row.assignment.assigned) {
    return (
      <TooltipContainer>
        <Tooltip message="You are not currently assigned to this project.">
          <Icon icon="info-circle" color={colors.grey25} />
        </Tooltip>
      </TooltipContainer>
    );
  }

  if (options.showTentative && row.assignment.statusId === projectStatuses.tentative.id) {
    return (
      <TooltipContainer>
        <Tooltip message="This is a tentative project.">
          <Icon icon="info-circle" color={colors.grey25} />
        </Tooltip>
      </TooltipContainer>
    );
  }

  return null;
}
