import { Form, Field } from '~/components';
import React from 'react';
import { ControlWithText } from '../CSVImportPage';

export function SelectImportTypeSection(isAdmin, handleTypeSelected) {
  return (
    <Form.Section title="Step 1: Select type" subtitle="Select the type of data you'd like to import.">
      <ControlWithText>
        <span>Importing CSV data for </span>
        {isAdmin && (
          <Field.SingleSelect
            onChange={handleTypeSelected}
            name="selectedDataType"
            placeholder="Select data type"
            materialPlaceholder={false}>
            <option value="members">Workspace Members</option>
            <option value="clients">Clients</option>
            <option value="contacts">Contacts</option>
            <option value="projects">Projects</option>
            <option value="time">Time Entries</option>
            <option value="expenses">Expenses</option>
            <option value="projectLedger">Project Ledgers</option>
            <option value="allocations">Allocations</option>
            <option value="projectTasks">Project Tasks</option>
            <option value="memberSkillAssignments">Member Skill Assignments - Ruddr Admin Only</option>
            <option value="memberTags">Member Tags - Ruddr Admin Only</option>
            <option value="costs">Cost Periods - Ruddr Admin Only</option>
            <option value="targets">Target Util Periods - Ruddr Admin Only</option>
            <option value="availability">Availability PeriodAdmin Only</option>
            <option value="projectTags">Project Tags - Ruddr Admin Only</option>
            <option value="projectTaskTags">Project Task Tags - Ruddr Admin Only</option>
            <option value="projectTaskRoleMember">Project Task Role/Member Assignment - Ruddr Admin Only</option>
            <option value="projectAssignments">Project Assignments - Ruddr Admin Only</option>
            <option value="targets">Target Util Periods - Ruddr Admin Only</option>
            <option value="timesheets">Generate Timesheets - Ruddr Admin Only</option>
            <option value="invoice">Invoices - Ruddr Admin Only</option>
            <option value="invoiceLines">Invoice Lines - Ruddr Admin Only</option>
          </Field.SingleSelect>
        )}
        {!isAdmin && (
          <Field.SingleSelect
            onChange={handleTypeSelected}
            name="selectedDataType"
            placeholder="Select data type"
            materialPlaceholder={false}>
            <option value="members">Workspace Members</option>
            <option value="clients">Clients</option>
            <option value="contacts">Contacts</option>
            <option value="projects">Projects</option>
            <option value="time">Time Entries</option>
            <option value="expenses">Expenses</option>
            <option value="projectLedger">Project Ledgers</option>
            <option value="allocations">Allocations</option>
            <option value="projectTasks">Project Tasks</option>
          </Field.SingleSelect>
        )}
      </ControlWithText>
    </Form.Section>
  );
}
