import React from 'react';
import Tag from './Tag';

export default function ExpenseBillableTypeTag({ value, ...props }) {
  if (!value) return null;
  return (
    <Tag label="Billable Type" {...props}>
      {{ billable: 'Billable', non_billable: 'Non-Billable' }[value] || 'All'}
    </Tag>
  );
}
