import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const MessageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2.5rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
  background-color: ${colors.black};
  border-bottom: 1px solid ${colors.grey10};
  z-index: 81;
`;

const AppLink = styled(Link)`
  margin-left: 0.25rem;
`;

export default function WorkspaceMessage() {
  const match = useRouteMatch();
  const { hasMessage, message } = useWorkspace();

  if (!hasMessage) {
    return null;
  }
  return (
    <MessageArea>
      {message}
      <AppLink to={`${match.url}/settings/billing`}>Update Billing.</AppLink>
    </MessageArea>
  );
}
