import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionButton, Buttons, CancelButton, Drawer, Icon, SingleSelect, Table, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

export default function ProjectMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ projects, qboProjects, isReady }, setState] = useState({ projects: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: projects } = await api.www.workspaces(workspace.id).qbo.projects.ruddr();
      const { data: qboProjects } = await api.www.workspaces(workspace.id).qbo.projects.qbo();

      if (!isMounted.current) return;
      setState({ projects, qboProjects, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updateProjectMapping = ({ projectId, qboProjectId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      projects: projects.map((c) => (c.id === projectId ? { ...c, qboProjectId } : c)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Project Mappings"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = projects.map((project) => ({
              projectId: project.id,
              qboProjectId: project.qboProjectId,
            }));
            await api.www.workspaces(workspace.id).qbo.updateProjectMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column width="40%">Project</Table.Column>
                <Table.Column>QuickBooks Project</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {projects.map((project) => {
                  const handleProjectChange = (event) => {
                    updateProjectMapping({
                      projectId: project.id,
                      qboProjectId: event.target.value || null,
                    });
                  };

                  const customerProjects = qboProjects.filter(
                    (p) =>
                      p.id === project.qboProjectId ||
                      (project.client.qboCustomerId && p.customer?.id === project.client.qboCustomerId),
                  );

                  return (
                    <Table.BoxRow key={project.id}>
                      <Table.Cell>
                        <div>
                          <p>{project.name}</p>
                          <p>
                            <small style={{ color: colors.grey40 }}>{project.client.name}</small>
                          </p>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <ProjectSelect
                          value={project.qboProjectId}
                          projects={customerProjects}
                          disabled={
                            (!project.qboProjectId && !project.client.qboCustomerId) || customerProjects.length === 0
                          }
                          onChange={handleProjectChange}
                        />

                        {!project.client.qboCustomerId && (
                          <div style={{ flex: 0, display: 'flex', alignItems: 'center' }}>
                            <Tooltip message="This project's client is not mapped to a QuickBooks customer.">
                              <Icon spaceLeft icon="exclamation-triangle" color={colors.warning} />
                            </Tooltip>
                          </div>
                        )}
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function ProjectSelect({ projects, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return projects;
    }

    const fuse = new Fuse(projects, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [projects, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const project = _.find(projects, { id: value });
    if (!project)
      return {
        id: value,
        name: (
          <Tooltip message="Project not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Project ID: {value}
          </Tooltip>
        ),
      };
    return project;
  }, [projects, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
