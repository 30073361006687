import { AutoCompleteMulti, Avatar } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

function ClientApprovalScheduleMemberMultiSelect({ value, initialValue, name, project, exclude, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  // Since useEffect/useCallback only performs shallow equality, prevent firing the search function for deep-equal arrays
  const excludeRef = useRef(exclude);
  if (JSON.stringify(exclude) === JSON.stringify(excludeRef.current)) exclude = excludeRef.current;
  excludeRef.current = exclude;

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .members()
        .get({
          q,
          isActive: true,
          include: _.join(
            (initialValue || []).map((m) => m.projectMemberId),
            ',',
          ),
          exclude,
          size: 1000,
          withGraph: 'member',
        });

      return data;
    },
    [workspace.id, project.id, initialValue, exclude, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, initialValue, isMounted]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(value, { projectMemberId: selectedValue.id })) {
      handleChange([
        ...value,
        { projectMemberId: selectedValue.id, name: selectedValue.member.name, imageUrl: selectedValue.member.imageUrl },
      ]);
    }
  };

  const handleRemove = (item) => {
    handleChange(value.filter(({ projectMemberId }) => projectMemberId !== item.projectMemberId));
  };

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.projectMemberId === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.member.name}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.projectMemberId} {...props}>
          <Avatar value={option} showName isCircle hasBackground size={18} fontSize="1rem" initialsFontSize=".625rem" />
        </AutoCompleteMulti.Tag>
      )}
      value={value}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}
    />
  );
}

export default ClientApprovalScheduleMemberMultiSelect;
