import {
  ApprovalStatusFilter,
  BackLink,
  Checkbox,
  ClientApprovalStatusFilter,
  ClientLink,
  Hours,
  Icon,
  InternalClientTooltip,
  InvoicedFilter,
  Level,
  Percent,
  RouteLink,
  Stack,
  Table,
  Widget,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import colors from '~/styles/colors';
import weights from '~/styles/weights';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import InvoicedTag from '../components/InvoicedTag';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const ClientInfo = styled.div`
  line-height: 1;
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export default function TimeSummaryByClientAndMember() {
  useDocumentTitle('Time Summary by Client and Member');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    projectRoles: [],
    includeMembersWithoutTime: false,
    sort: new QuerySort('client.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        projectRoles: searchParamsConfig.projectRoles,
        includeMembersWithoutTime: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      projectRoleId: params.projectRoles?.map((v) => v.id),
      includeMembersWithoutTime: params.includeMembersWithoutTime ? 'true' : undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByClientAndMember(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByClientAndMember(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />

        <Stack>
          <h1>Time Summary by Client and Member</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            {params.includeMembersWithoutTime && <Tag>Include project team members without tracked time</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          projectRole: params.projectRoles?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary>
        <Widget>
          <Meter>
            <Meter.Value
              label="Client Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Hours value={report.totals.billable} /> hours (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Client Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Hours value={report.totals.nonBillable} /> hours (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Internal"
              value={report.totals.internal}
              color={colors.warning}
              tooltip={
                <>
                  <Hours value={report.totals.internal} /> hours (
                  <Percent value={report.totals.internal / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.billable} />
                </Metric.Value>
                <Metric.Label>Client Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'non_billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.nonBillable} />
                </Metric.Value>
                <Metric.Label>Client Non-Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'internal' })}>
                <Metric.Value>
                  <Hours value={report.totals.internal} />
                </Metric.Value>
                <Metric.Label>Internal</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                <Metric.Value>
                  <Hours value={report.totals.total} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table style={{ marginTop: '1rem' }}>
        <TotalResults>
          {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
          <Table.Total label="Client" value={report.records.length} />
          {report.partialResults && <PartialResultsTooltip />}
        </TotalResults>

        <Table>
          <Table.Header sticky>
            <Table.Column name="client.name" onSort={onSort} sort={params.sort}>
              Client
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Client
              <br />
              Billable
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Client
              <br />
              Non-Billable
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Internal
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Total
            </Table.Column>
          </Table.Header>

          <TableBody fade={query.status === 'sorting'}>
            {report.records.map((client) => {
              return (
                <React.Fragment key={client.id}>
                  <GroupRow top="4.075rem">
                    <Table.Cell>
                      <ClientInfo>
                        <p style={{ display: 'flex' }}>
                          <ClientLink client={client} />
                          {client.isInternal && <InternalClientTooltip />}
                        </p>
                      </ClientInfo>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ client: client.id, billableType: 'billable' })}>
                          <Hours value={client.billableHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ client: client.id, billableType: 'non_billable' })}>
                          <Hours value={client.nonBillableHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ client: client.id, billableType: 'internal' })}>
                          <Hours value={client.internalHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ client: client.id })}>
                          <Hours value={client.totalHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                  </GroupRow>

                  {client.members.map((projectMember) => {
                    return (
                      <Table.Row key={projectMember.member.id}>
                        <Table.Cell>
                          <Member>{projectMember.member.name}</Member>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({
                              client: client.id,
                              member: projectMember.member.id,
                              billableType: 'billable',
                            })}>
                            <Hours value={projectMember.billableHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({
                              client: client.id,
                              member: projectMember.member.id,
                              billableType: 'non_billable',
                            })}>
                            <Hours value={projectMember.nonBillableHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({
                              client: client.id,
                              member: projectMember.member.id,
                              billableType: 'internal',
                            })}>
                            <Hours value={projectMember.internalHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink to={timeDetail({ client: client.id, member: projectMember.member.id })}>
                            <Hours value={projectMember.totalHours} />
                          </RouteLink>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </React.Fragment>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <RouteLink
                  to={timeDetail({
                    billableType: 'billable',
                  })}>
                  <Hours value={report.totals.billable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink
                  to={timeDetail({
                    billableType: 'non_billable',
                  })}>
                  <Hours value={report.totals.nonBillable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink
                  to={timeDetail({
                    billableType: 'internal',
                  })}>
                  <Hours value={report.totals.internal} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                  <Hours value={report.totals.total} />
                </RouteLink>
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeMembersWithoutTime}
          label="Include project team members without tracked time"
          onChange={({ target: { checked } }) => handleFilter({ includeMembersWithoutTime: checked })}
        />
      </Filter>

      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Time Filters"
        filters={[filters.approvalStatuses, filters.clientApprovalStatuses, filters.invoiced]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}
