import { DayPickerInput, FieldControl, SingleSelect } from '~/components';
import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { intervalOptions } from '~/utils';
import { sortIntervals } from '~/utils/intervalOptions';

function PeriodSelect({ intervals, value, clearable = false, scope, showDayPicker, onChange, ...props }) {
  showDayPicker = showDayPicker || intervals.some((i) => i.key === intervalOptions.custom.key);

  const handleChange = (newValue) => {
    onChange({
      target: {
        value: {
          ...value,
          ...newValue,
        },
      },
    });
  };

  const handlePeriodChange = (event) => {
    const interval = _.find(intervals, { key: event.target.value });

    handleChange({
      key: interval.key,
      start: interval.start !== undefined ? interval.start : value.start,
      end: interval.end !== undefined ? interval.end : value.end,
    });
  };

  const handleStartChange = (start) => {
    if (clearable || start) {
      if (scope === 'month') start = moment(start).format('YYYY-MM');
      const option = _.find(sortIntervals(intervals), (i) => i.start === start && i.end === value.end);
      handleChange({ start: start ?? null, key: option ? option.key : intervalOptions.custom.key });
    }
  };

  const handleEndChange = (end) => {
    if (clearable || end) {
      if (scope === 'month') end = moment(end).format('YYYY-MM');
      const option = _.find(sortIntervals(intervals), (i) => i.start === value.start && i.end === end);
      handleChange({ end: end ?? null, key: option ? option.key : intervalOptions.custom.key });
    }
  };

  return (
    <>
      <SingleSelect {...props} value={value.key} onChange={handlePeriodChange}>
        {_.map(intervals, (value) => {
          return (
            <option key={value.key} value={value.key}>
              {value.label}
            </option>
          );
        })}
      </SingleSelect>

      {showDayPicker && (
        <>
          <DayPickerInput
            value={value.start}
            placeholder="From"
            clearable={clearable}
            scope={scope}
            onChange={handleStartChange}
            data-testid="period_start"
          />

          <DayPickerInput
            value={value.end}
            placeholder="To"
            clearable={clearable}
            scope={scope}
            onChange={handleEndChange}
            data-testid="period_end"
          />
        </>
      )}
    </>
  );
}

function FieldPeriodSelect(props) {
  const [field, meta, helpers] = useField(props);
  let error = meta.touched && meta.error;

  // Get the first error
  if (error && Object.keys(error).length > 0) error = error[Object.keys(error)[0]];

  const formik = useFormikContext();

  const handleChange = async ({ target: { value } }) => {
    helpers.setValue(value);
    if (error) formik.validateField(error.name);
  };

  return (
    <FieldControl error={error}>
      <PeriodSelect {...field} {...props} onChange={handleChange} />
    </FieldControl>
  );
}

export default PeriodSelect;
export { FieldPeriodSelect };
