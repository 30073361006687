import {
  ClientProjectSelect,
  ExportDialog,
  Level,
  MemberSelect,
  Page,
  PracticeSelect,
  SingleSelect,
  Stack,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, intervalOptions, mimeTypes } from '~/utils';
import EditTimeEntry from '../edit-time-entry';
import ViewTimeEntry from '../view-time-entry';
import TimeAuditingResults from './TimeAuditingResults';
import ExportDropdown from '../../settings/ExportDropdown.jsx';

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    period: 'past_30_days',
    project: null,
    statusId: null,
    billableTypeId: null,
    member: null,
    memberPractice: null,
    page: 0,
    size: 25,
    sort: new QuerySort('date', 'desc'),
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  updateItems: (items, { data }) => ({
    data: {
      ...data,
      results: data.results.map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        item = _.pick(
          item,
          'date',
          'isActuallyBillable',
          'minutes',
          'project',
          'role',
          'statusId',
          'task',
          'timeOffType',
          'timerStartedAt',
        );

        return item ? { ...result, ...item } : result;
      }),
    },
  }),
  removeItem: (id, { data }) => ({
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function TimeAuditingPage() {
  const documentTitle = useDocumentTitle('Time Auditing');

  const { workspace } = useWorkspace();
  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [drawer, setDrawer] = useState(null);
  const api = useApi();
  const { notify } = useSubscription();
  const features = useFeatures();
  const confirmation = useConfirmation();

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'today',
        'yesterday',
        'this_week',
        'this_week_to_date',
        'this_month',
        'this_month_to_date',
        'this_quarter',
        'this_quarter_to_date',
        'this_year',
        'this_year_to_date',
        'last_week',
        'last_month',
        'last_quarter',
        'last_year',
        'past_7_days',
        'past_30_days',
        'past_90_days',
        'past_180_days',
        'past_365_days',
      ),
    [],
  );

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: initialState.query.period,
          valid: ['all', ..._.keys(intervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        statusId: searchParamsConfig.approvalStatus,
        billableTypeId: searchParamsConfig.timeBillableType,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        project: searchParamsConfig.project,
        member: searchParamsConfig.member,
        memberPractice: searchParamsConfig.practice,
      }),
      [searchParamsConfig, intervals],
    ),
    sessionKey: 'time_auditing',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = intervals[query.period] || {};

      const params = {
        ..._.omit(query, ['project', 'member', 'memberPractice', 'period']),
        statusId: query.statusId ?? undefined,
        billableTypeId: query.billableTypeId ?? undefined,
        projectId: query.project?.id,
        memberId: query.member?.id,
        memberPracticeId: query.memberPractice?.id,
        start,
        end,
      };

      const { data } = await api.www.workspaces(workspace.id).timeAdmin().getAuditing(params);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query, api, intervals]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = ({ target }) => {
    actions.setParams({ [target.name]: target.value });
    searchParams.set({ [target.name]: target.value });
  };

  const handleResultClick = (entry, mode) => {
    setDrawer({ entry, mode });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set('Time Auditing');
  };

  const handleDelete = async (entry) => {
    await api.www.workspaces(workspace.id).timeAdmin(entry.id).delete();

    handleEntryDeleted(entry);
  };

  const handleEntrySaved = (entry) => {
    actions.updateItems([entry]);
    notify(useSubscription.keys.refresh_timer);
  };

  const handleEntryDeleted = (entry) => {
    actions.removeItem(entry.id);
    notify(useSubscription.keys.refresh_timer);
  };

  const handleTimerChange = (entry) => {
    // Stop all timers for the member
    const entries = data.results
      .filter((e) => !!e.timerStartedAt && e.member.id === entry.member.id)
      .map((e) => ({
        ...e,
        minutes: moment.duration(e.minutes, 'minutes').add(moment().diff(e.timerStartedAt)).asMinutes(),
        timerStartedAt: null,
      }));

    actions.updateItems([...entries, entry]);
    notify(useSubscription.keys_refresh_timer);
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleExport = async (filename, mimeType) => {
    const { start, end } = intervals[query.period] || {};

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .timeAdmin()
          .exportAuditing(
            {
              ..._.omit(query, ['project', 'member', 'memberPractice', 'period']),
              statusId: query.statusId ?? undefined,
              billableTypeId: query.billableTypeId ?? undefined,
              projectId: query.project?.id,
              memberId: query.member?.id,
              memberPracticeId: query.memberPractice?.id,
              size: null,
              start,
              end,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady) return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Section>
          <Level>
            <Level.Item>
              <SingleSelect
                name="period"
                placeholder="All"
                materialPlaceholder="Time Entry Date"
                materialAlwaysVisible
                showEmptyOption
                value={query.period}
                onChange={handleFilterChange}>
                {_.map(intervals, ({ label }, key) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </SingleSelect>
            </Level.Item>

            <Level.Item>
              <ClientProjectSelect
                name="project"
                placeholder="All"
                materialPlaceholder="Project"
                materialAlwaysVisible
                activeOnly={false}
                pastTime={true}
                value={query.project}
                onChange={handleFilterChange}
              />
            </Level.Item>

            <Level.Item>
              <SingleSelect
                name="statusId"
                placeholder="All"
                materialPlaceholder="Approval Status"
                materialAlwaysVisible
                showEmptyOption
                value={query.statusId}
                onChange={handleFilterChange}>
                <option value="not_submitted">Not Submitted</option>
                <option value="pending_approval">Pending Approval</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </SingleSelect>
            </Level.Item>

            <Level.Item right narrow>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`time_auditing.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`time_auditing.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>
          </Level>
          <Level style={{ marginTop: '1.5rem' }}>
            <Level.Item>
              <SingleSelect
                placeholder="All"
                materialPlaceholder="Time Type"
                materialAlwaysVisible
                showEmptyOption
                name="billableTypeId"
                value={query.billableTypeId}
                onChange={handleFilterChange}>
                <option value="billable">Client Billable</option>
                <option value="non_billable">Client Non-Billable</option>
                <option value="internal">Internal</option>
                <option value="time_off">Time Off</option>
              </SingleSelect>
            </Level.Item>

            {!_.isEmpty(workspace.member.securityRole.viewProjectTimeAndExpenses) ? (
              <>
                <Level.Item>
                  <MemberSelect
                    name="member"
                    placeholder="All"
                    materialPlaceholder="Member"
                    materialAlwaysVisible
                    value={query.member}
                    onChange={handleFilterChange}
                  />
                </Level.Item>

                <Level.Item>
                  {features.practices && (
                    <PracticeSelect
                      name="memberPractice"
                      placeholder="All"
                      materialPlaceholder="Member Practice"
                      materialAlwaysVisible
                      value={query.memberPractice}
                      onChange={handleFilterChange}
                    />
                  )}
                </Level.Item>
              </>
            ) : (
              <>
                <Level.Item />
                <Level.Item />
              </>
            )}
          </Level>
        </Page.Section>

        <Page.Section>
          <Stack>
            <TimeAuditingResults
              data={data}
              sort={query.sort}
              onLoadMore={actions.loadMore}
              onSort={handleSort}
              onResultClick={handleResultClick}
              onDelete={handleDelete}
              action={action}
            />
          </Stack>
        </Page.Section>
      </Page>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={drawer.entry.id}
              memberId={drawer.entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(drawer.entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={handleEntrySaved}
              onDeleted={handleEntryDeleted}
              onClose={handleCloseDrawer}
              onTimerChange={handleTimerChange}
            />
          ),
          view: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} />,
        }[drawer.mode]()}
    </>
  );
}

export default TimeAuditingPage;
