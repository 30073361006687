import { Buttons, CancelButton, Checkbox, DateTime, DeleteButton, HelpTooltip, Level, ModalCard } from '~/components';
import { useApi, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function DeleteDialog({ creditNote, onDelete, onClose }) {
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();
  const [removeFromQuickBooks, setRemoveFromQuickBooks] = useState(false);
  const [removeFromXero, setRemoveFromXero] = useState(false);
  const [{ isSubmitting }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    if (removeFromQuickBooks) {
      try {
        await api.www.workspaces(workspace.id).creditNotes(creditNote.id).removeFromQuickBooks();
      } catch (error) {
        toast.error(error.message ?? 'An error has occurred. The credit note has not been removed from QuickBooks.');
      }
    } else if (removeFromXero) {
      try {
        await api.www.workspaces(workspace.id).creditNotes(creditNote.id).removeFromXero();
      } catch (error) {
        toast.error(error.message ?? 'An error has occurred. The credit note has not been removed from Xero.');
      }
    }

    await api.www.workspaces(workspace.id).creditNotes(creditNote.id).delete();

    if (onDelete) await onDelete();
    form.done();
    toast.success(`Credit Note #${creditNote.number} has been deleted.`);
  };

  const title = creditNote.status.id === 'draft' ? 'Delete Draft Credit Note' : 'Delete Published Credit Note';

  return (
    <ModalCard title={title} onClose={onClose}>
      <ModalCard.Body>
        <P>
          Are you sure you want to delete {creditNote.status.id === 'draft' ? 'draft' : 'published'} credit note #
          {creditNote.number} for the <strong>{creditNote.client.name}</strong> client?
        </P>
        {creditNote.sentAt && (
          <P>
            This credit note was sent to the client on <DateTime value={creditNote.sentAt} />.
          </P>
        )}
      </ModalCard.Body>

      <ModalCard.Footer>
        <Level>
          {integrations.qbo && creditNote.qboCreditMemoId && (
            <Level.Item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  label="Remove from QuickBooks"
                  checked={removeFromQuickBooks}
                  onChange={() => setRemoveFromQuickBooks(!removeFromQuickBooks)}
                />
                <HelpTooltip
                  message="Any payments associated with this credit note will be removed from QuickBooks."
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
            </Level.Item>
          )}

          {integrations.xero && creditNote.xeroCreditNoteId && (
            <Level.Item>
              <Checkbox
                label="Remove from Xero"
                checked={removeFromXero}
                onChange={() => setRemoveFromXero(!removeFromXero)}
              />
            </Level.Item>
          )}

          <Level.Item>
            <Buttons align="right">
              <CancelButton onClick={onClose}>Cancel</CancelButton>
              <DeleteButton isLoading={isSubmitting} onClick={handleSubmit}>
                Delete
              </DeleteButton>
            </Buttons>
          </Level.Item>
        </Level>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DeleteDialog;
