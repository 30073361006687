import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import Fuse from 'fuse.js';
import * as Yup from 'yup';
import { Button, FieldControl, Icon, SingleSelect } from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { mergeValues } from '~/utils';

function HolidayForm({ holiday, holidays, selectedHolidays, setEditForm, onCancel, onSave }) {
  const formRef = useRef();
  const [filterValue, setFilterValue] = useState('');

  const allHolidays = useMemo(() => {
    let data = _.cloneDeep(holidays);
    // Remove any holidays that have already been selected
    for (const item of selectedHolidays) {
      if (holiday && holiday.id == item.id) {
        continue;
      }
      data = _.reject(data, { id: item.id });
    }
    // If holiday does not exist in holidays, add it.
    // This might occur if the holiday is inactive, but already added to the schedule.
    if (holiday && !_.find(data, { id: holiday.id })) {
      data.push(holiday);
    }
    return _.sortBy(data, ['name']);
  }, [holiday, holidays, selectedHolidays]);

  const options = useMemo(() => {
    if (!filterValue) {
      return allHolidays;
    }

    const fuse = new Fuse(allHolidays, { keys: ['id', 'name'] });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [allHolidays, filterValue]);

  const initialValues = mergeValues(
    {
      id: '',
    },
    holiday,
  );

  useEffect(() => {
    setEditForm(formRef);
    return () => {
      setEditForm(null);
    };
  }, [setEditForm]);

  const handleSave = (values) => {
    const selectedHoliday = _.find(allHolidays, { id: values.id });
    onSave(selectedHoliday);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        id: Yup.string().required('Select a holiday to continue.'),
      })}>
      {({ errors, setFieldValue, submitForm, touched, values }) => {
        const selectedOption = _.find(allHolidays, { id: values.id });
        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              <FieldControl error={touched.id && errors.id} style={{ flex: 1 }}>
                <SingleSelect
                  showFilter={true}
                  noOptionsMessage="No more holidays available"
                  onChange={(event) => setFieldValue('id', event.target.value)}
                  onFilterChange={(event) => setFilterValue(event.target.value)}
                  value={values.id}
                  valueRenderer={selectedOption?.name || ''}>
                  {options.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </SingleSelect>
              </FieldControl>
            </Table.Cell>
            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

function HolidayScheduleHolidayRow({
  holiday,
  holidays,
  selectedHolidays,
  isEditing,
  setEditForm,
  onCancel,
  onDelete,
  onEdit,
  onSave,
}) {
  if (!isEditing && !holiday) {
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
  if (!isEditing) {
    return (
      <Table.BoxRow>
        <Table.Cell>{holiday.name}</Table.Cell>
        <TableBoxRowActions>
          <TableBoxRowActions.Delete onClick={() => onDelete(holiday)} />
        </TableBoxRowActions>
      </Table.BoxRow>
    );
  }
  return (
    <HolidayForm
      holiday={holiday}
      holidays={holidays}
      selectedHolidays={selectedHolidays}
      setEditForm={setEditForm}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
}

export default HolidayScheduleHolidayRow;
