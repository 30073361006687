import { useApi, useWorkspace } from '~/contexts';
import { useFeatures, useIsMounted } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const TimesheetContext = React.createContext();

function TimesheetProvider({ memberId, children }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const [{ isReady, timesheets }, setQuery] = useState({ isReady: false, timesheets: null });

  const isMounted = useIsMounted();

  const fetchTimesheets = useCallback(async () => {
    // If the workspace doesn't use timesheets, do not fetch the list of timesheets
    if (!features.timesheets) {
      setQuery({ isReady: true, timesheets: null });
      return;
    }

    const { data } = await api.www.workspaces(workspace.id).timesheets().get({ memberId });

    if (!isMounted.current) return;
    setQuery({ isReady: true, timesheets: data });
  }, [api, workspace.id, memberId, features.timesheets, isMounted]);

  useEffect(() => {
    fetchTimesheets();
  }, [fetchTimesheets]);

  const isTimesheetOpen = useCallback(
    ({ start, end, isOpen = true }) => {
      if (!features.timesheets) return true;
      if (!timesheets) return false;

      // Only check the timesheets that contain the period
      const periodTimesheets = timesheets.filter(
        (ts) => moment(ts.start).isSameOrBefore(end) && moment(ts.end).isSameOrAfter(start),
      );

      const dayCount = moment(end).diff(moment(start), 'days') + 1;

      for (let index = 0; index < dayCount; index++) {
        const date = moment(start).add(index, 'days');
        const isDateOpen = !periodTimesheets.some((timesheet) =>
          moment(date).isBetween(moment(timesheet.start), moment(timesheet.end), 'day', []),
        );

        if (isDateOpen === isOpen) return true;
      }

      return false;
    },
    [timesheets, features.timesheets],
  );

  const isTimesheetSubmitted = useCallback(
    ({ start, end }) => isReady && !isTimesheetOpen({ start, end }),
    [isTimesheetOpen, isReady],
  );

  const value = useMemo(() => {
    return {
      useTimesheets: features.timesheets,
      isReady,
      timesheets,
      isTimesheetOpen,
      isTimesheetSubmitted,
      fetchTimesheets,
    };
  }, [features.timesheets, isReady, timesheets, fetchTimesheets, isTimesheetOpen, isTimesheetSubmitted]);

  return <TimesheetContext.Provider value={value}>{children}</TimesheetContext.Provider>;
}

function useTimesheets() {
  return useContext(TimesheetContext);
}

export { useTimesheets, TimesheetContext, TimesheetProvider };
