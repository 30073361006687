import React from 'react';
import { Table } from '~/components';
import { calculateTotalDaysInPeriods } from '../utils';

function PeriodsFooterRow({ periods, member, label }) {
  const total = calculateTotalDaysInPeriods(periods, member);

  return (
    <Table.FooterRow>
      <Table.Cell>
        {periods.length} {label} {periods.length === 1 ? 'period' : 'periods'}
      </Table.Cell>
      <Table.Cell />
      <Table.Cell>{total > 0 && `${total} ${total === 1 ? 'day' : 'days'}`}</Table.Cell>
      <Table.Cell />
    </Table.FooterRow>
  );
}

export default PeriodsFooterRow;
