import _ from 'lodash';
import { del, get, post } from './agent';

const mfa =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'mfa', id]).compact().join('/');

    return {
      get: () => get(url),
      initialize: (body) => post(url, body),
      setupSMS: (body) => post(url.concat('/sms'), body),
      verify: (body) => post(url.concat('/verify'), body),
      enable: () => post(url.concat('/enable')),
      disable: () => del(url),
    };
  };

export default mfa;
