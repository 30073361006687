import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Button from '../Button';
import Icon from '../Icon';
import TableCell from './TableCell';

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > ${Button}, > .button {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${colors.white};
      color: ${colors.primary};
      border: 2px solid ${colors.grey5};
      padding: 0.5rem 0;
      width: 3.5rem;

      .icon {
        font-size: 0.8rem;
        margin-left: 0.2rem;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: 0.1rem;
      background-color: ${colors.grey5};
      color: ${colors.danger};
      padding: 0.5rem 0;
      width: 2.5rem;
      border: 0;

      .icon {
        font-size: 0.8rem;
        margin-right: 0.2rem;
      }
    }
  }
`;

function TableFormActions({ onCancel, onSubmit, isLoading, disabled, submitButtonType = 'submit', ...props }) {
  return (
    <TableCell {...props}>
      <Buttons>
        <Button disabled={disabled} isLoading={isLoading} type={submitButtonType} onClick={onSubmit}>
          <Icon icon="check" />
        </Button>
        <Button onClick={onCancel} disabled={isLoading} className="cancel">
          <Icon icon="times" />
        </Button>
      </Buttons>
    </TableCell>
  );
}

export default TableFormActions;
export { Buttons };
