import { NumberInput } from '~/components';
import { useField } from 'formik';
import { useLocale } from '~/hooks';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Days = styled.div`
  display: flex;
`;

const Day = styled.div`
  margin-right: 0.5rem;
  text-align: center;

  > label {
    display: block;
    padding-bottom: 0.1rem;
    font-size: 0.625rem;
    color: ${colors.grey40};
    font-weight: ${weights.medium};
    text-transform: uppercase;
  }

  input {
    padding: 0.25rem;
    width: 3rem;
    height: 2.5rem;
    text-align: center;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${({ error }) =>
      error &&
      css`
        border-color: ${colors.danger};

        &:focus {
          border-color: ${colors.danger};
        }
      `}
  }
`;

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function HoursPerDayField({ name, autoFocus }) {
  const locale = useLocale();

  const [field, , helpers] = useField(name);
  const { value } = field;

  const handleChange = (newValue, index) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(i === index ? (value[i] = newValue) : value[i]);
    }
    helpers.setValue(days);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <Days>
      {days.map((day, index) => {
        const hours = Number(value[index]);
        const error = isNaN(hours) || hours < 0 || hours > 24;

        const handleBlur = () => {
          if (value[index] === null || isNaN(hours)) handleChange(0, index);
          if (hours < 0) handleChange(0, index);
          if (hours > 24) handleChange(24, index);
        };

        return (
          <Day key={index} error={error}>
            <label>{day}</label>
            <NumberInput
              data-testid={`${day}_input`}
              autoFocus={autoFocus && index === 0}
              min={0}
              max={24}
              precision={2}
              value={value[index]}
              locale={locale}
              onChange={(value) => handleChange(value, index)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Day>
        );
      })}
    </Days>
  );
}

export default HoursPerDayField;
