import _ from 'lodash';
import React from 'react';
import { DateTime, IconTooltip } from '~/components';
import { useSession } from '~/contexts/SessionContext.jsx';
import { colors } from '~/styles';
import dateFormats from '~/utils/dateFormats.js';

export default function Timestamp({ timestamp = new Date(), onClick }) {
  const { isAdmin } = useSession();

  return (
    <IconTooltip
      icon="calendar-alt"
      type="far"
      color={colors.grey25}
      style={{ cursor: isAdmin && _.isFunction(onClick) ? 'pointer' : 'help' }}
      onClick={isAdmin ? onClick : null}
      message={
        <>
          {timestamp && (
            <p>
              Updated at <DateTime value={timestamp} format={dateFormats.compactDateTime} />.
            </p>
          )}
        </>
      }
    />
  );
}
