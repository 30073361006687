import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components';
import { useApi, useSession, useWorkspace } from '~/contexts';

const Description = styled.p`
  margin-top: 2rem;
`;

export default function BillingNotConfigured({ setCustomer }) {
  const api = useApi();
  const { isAdmin } = useSession();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);

    const { data } = await api.www.workspaces(workspace.id).stripe.create();

    setIsLoading(false);
    setCustomer(data || null);
  };

  return (
    <>
      <Description>Your workspace is not configured to use billing.</Description>
      {isAdmin && (
        <Description>
          <Button isLoading={isLoading} onClick={handleClick}>
            Setup Billing
          </Button>
        </Description>
      )}
    </>
  );
}
