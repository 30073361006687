import { DateTime, Icon, Table, Tag, Tags, TooltipButton } from '~/components';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import RejectExpenseDialog from '~/routes/app/expenses/approvals/RejectExpenseDialog';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3125rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  z-index: 300;
  left: 0;
  position: relative;
  transition: opacity 50ms ease-out;

  &::before {
    content: '';
    position: absolute;
    border: solid 0.875rem transparent;
    z-index: 299;
  }

  &[data-placement='left'] {
    &::before {
      top: calc(50% - 0.875rem);
      left: 100%;
      border-left-color: ${colors.white};
    }
  }

  &[data-placement='right'] {
    &::before {
      top: calc(50% - 0.875rem);
      right: 100%;
      border-right-color: ${colors.white};
    }
  }

  &[data-placement='top'] {
    &::before {
      top: 100%;
      left: calc(50% - 0.875rem);
      border-top-color: ${colors.white};
    }
  }

  &[data-placement='bottom'] {
    &::before {
      bottom: 100%;
      left: calc(50% - 0.875rem);
      border-bottom-color: ${colors.white};
    }
  }
`;

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

export const ApprovalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
`;

export const ApprovalAction = styled.div`
  &:last-child {
    margin-left: 1rem;
  }
`;

export const Action = styled(TooltipButton)`
  font-size: 0.75rem;
  background: ${colors.grey5};
  color: ${colors.grey25};
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.5rem;
  border-radius: 100%;
  transition: all 100ms ease-in-out;
`;

export const Approve = styled(Action)`
  &:hover {
    color: ${colors.primary};
    background: ${colors.primary10};

    &:disabled {
      background: ${colors.grey5};
      color: ${colors.grey25};
    }
  }

  ${({ status }) =>
    ({
      approved: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.primary};
        }
      `,

      partially_approved: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.primary50};
        }
      `,
    })[status]}
`;

export const Reject = styled(Action)`
  &:hover {
    background: ${colors.danger10};
    color: ${colors.danger};
    &:disabled {
      background: ${colors.grey5};
      color: ${colors.grey25};
    }
  }

  ${({ status }) =>
    ({
      rejected: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.danger};
        }
      `,

      partially_rejected: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.danger50};
        }
      `,
    })[status]}
`;

export default function ExpenseApprovalPopover({
  expenseItemId,
  placement = 'auto',
  showActions = false,
  onChange,
  children,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  const fetchData = useCallback(async () => {
    if (!visible) return;

    try {
      const { data } = await api.www.workspaces(workspace.id).expenseItems(expenseItemId).approvalWorkflow.get();
      setQuery({ isReady: true, data });
    } catch {
      // Do nothing
    }
  }, [api, workspace.id, expenseItemId, visible]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const confirmation = useConfirmation();
  const toast = useToast();
  const { notify } = useSubscription();

  const entry = query.data;
  const steps = entry?.approval?.steps;

  const isPopoverVisible = visible && entry?.approval;

  return (
    <>
      <div ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {isPopoverVisible &&
        ReactDOM.createPortal(
          <Container
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Content style={{ width: showActions ? '43rem' : '35rem' }}>
              <Table>
                <Table.Header>
                  <Table.Column width="4rem" align="center">
                    Step
                  </Table.Column>
                  <Table.Column>Approver(s)</Table.Column>
                  <Table.Column>Approval Status</Table.Column>
                  <Table.Column
                    isVisible={showActions && !entry.isLocked && entry.permissions.approve}
                    width="8rem"
                    align="right"
                  />
                </Table.Header>

                <Table.Body>
                  {steps.map((step, index) => {
                    const handleActionClick = async (statusId) => {
                      if (step.result?.statusId === statusId) statusId = null;

                      let notes;
                      if (statusId === 'rejected') {
                        notes = await confirmation.prompt((resolve) => {
                          setVisible(false);

                          return (
                            <RejectExpenseDialog
                              style={{ zIndex: 999999 }}
                              count={1}
                              onResolve={(notes) => resolve(notes)}
                            />
                          );
                        });
                        if (!notes) return;
                      }

                      try {
                        await api.www.workspaces(workspace.id).expenseItems(expenseItemId).approvalWorkflow.post({
                          expenseApprovalStepId: step.id,
                          statusId,
                          notes,
                        });

                        await fetchData();
                        if (_.isFunction(onChange)) await onChange();
                        notify(useSubscription.keys.refresh_expense_approval_count);
                      } catch (error) {
                        toast.error(error.message);
                      }
                    };

                    // The step can be updated only if:
                    // - The step has a result and the next step doesn't have a result (undo).
                    // - The step doesn't have a result and the previous step is approved.
                    const enabled = step.result
                      ? !steps[index + 1]?.result
                      : !steps[index - 1] || steps[index - 1]?.result?.statusId === 'approved';

                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell>
                          <Tags style={{ fontSize: '.75rem' }}>
                            {step.approvers.map((a) => {
                              switch (a.type) {
                                case 'role':
                                  return (
                                    <Tag key={a.approvalRoleKey} variant="primary10">
                                      {a.approvalRole.name}
                                    </Tag>
                                  );

                                case 'member':
                                  return (
                                    <Tag key={a.memberId} variant="grey5">
                                      {a.member.name}
                                    </Tag>
                                  );
                              }
                            })}
                          </Tags>
                        </Table.Cell>
                        <Table.Cell>
                          <div>
                            <p>
                              {{
                                approved: <strong style={{ color: colors.green }}>Approved</strong>,
                                rejected: <strong style={{ color: colors.red }}>Rejected</strong>,
                              }[step.result?.statusId] ?? <strong style={{ color: colors.yellow }}>Pending</strong>}
                            </p>
                            {step.result && (
                              <p>
                                <small>
                                  <i>
                                    by {step.result.member.name} on <DateTime value={step.result.createdAt} />
                                  </i>
                                </small>
                              </p>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <ApprovalActions onClick={(e) => e.stopPropagation()}>
                            <ApprovalAction>
                              <Approve
                                disabled={!enabled}
                                status={step.result?.statusId}
                                onClick={() => handleActionClick('approved')}>
                                <Icon icon="thumbs-up" />
                              </Approve>
                            </ApprovalAction>
                            <ApprovalAction>
                              <Reject
                                disabled={!enabled}
                                status={step.result?.statusId}
                                onClick={() => handleActionClick('rejected')}>
                                <Icon icon="thumbs-down" />
                              </Reject>
                            </ApprovalAction>
                          </ApprovalActions>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Content>
          </Container>,
          document.body,
        )}
    </>
  );
}
