import React, { useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { colors } from '~/styles';

const InputContainer = styled.div`
  position: relative;

  textarea {
    &:disabled {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const DefaultTextArea = React.forwardRef((props, ref) => <textarea {...props} ref={ref} />);

const TextArea = React.forwardRef(
  (
    { component, container, value, placeholder, materialPlaceholder = true, materialAlwaysVisible = false, ...props },
    ref,
  ) => {
    const Component = component || DefaultTextArea;
    const Container = container || InputContainer;

    const materialPlaceholderValue = useMemo(() => {
      if (!materialPlaceholder) {
        return '';
      } else if (_.isString(materialPlaceholder)) {
        return materialPlaceholder;
      } else if (_.isString(placeholder) && !!placeholder) {
        return placeholder;
      }
      return '';
    }, [placeholder, materialPlaceholder]);

    return (
      <Container>
        {!!materialPlaceholderValue && (
          <Placeholder isVisible={materialAlwaysVisible || !!value}>{materialPlaceholderValue}</Placeholder>
        )}
        <Component {...props} ref={ref} value={value} placeholder={placeholder} />
      </Container>
    );
  },
);

export default TextArea;
