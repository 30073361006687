import { AutoCompleteMulti, Avatar, MemberContactPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

function ProjectTaskMemberMultiSelect({ value, initialValue, name, project, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).members().get({
        q,
        isActive: true,
        size: 1000,
        withGraph: 'member',
      });

      return data;
    },
    [workspace.id, project.id, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, initialValue, isMounted]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(value, { projectMemberId: selectedValue.id })) {
      handleChange([...value, { projectMemberId: selectedValue.id, projectMember: selectedValue }]);
    }
  };

  const handleRemove = (item) => {
    handleChange(value.filter(({ projectMemberId }) => projectMemberId !== item.projectMemberId));
  };

  const filterOptions = (options) => options.filter((o) => !value.some((v) => v.projectMemberId === o.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => (
        <>
          <MemberContactPopover member={option.member} placement="left">
            <Avatar value={option.member} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
          </MemberContactPopover>
          <span style={{ marginLeft: '.5rem' }}>{option.member.name}</span>
        </>
      )}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag {...props}>
          <Avatar
            value={option.projectMember.member}
            showName
            isCircle
            hasBackground
            size="1.5rem"
            fontSize="1rem"
            initialsFontSize=".625rem"
          />
        </AutoCompleteMulti.Tag>
      )}
      value={value}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}
    />
  );
}

export default ProjectTaskMemberMultiSelect;
