import classNames from 'classnames';
import { useField, Field } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from '~/components/Icon';

const Box = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: ${colors.primary};
  font-size: 0.875rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.grey5};
  border-radius: 0.25rem;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  color: ${colors.grey75};

  &.disabled {
    cursor: not-allowed;
    color: ${colors.grey40};

    ${Box} {
      background-color: ${colors.grey5};
      cursor: not-allowed;
    }
  }

  &:focus-within {
    ${Box} {
      border-color: ${colors.primary};
    }
  }
`;

const Text = styled.span`
  padding-top: 0.0625rem;
`;

function ReadCheckbox({ label, disabled, partial, ...props }) {
  return (
    <Label className={classNames({ disabled })}>
      <Box>{props.checked ? partial ? <Icon icon="minus" /> : <Icon icon="check" /> : null}</Box>
      <Text>{label}</Text>
    </Label>
  );
}

function FieldReadCheckbox(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <Field.Control error={error}>
      <ReadCheckbox checked={field.value} {...field} {...props} />
    </Field.Control>
  );
}

export default ReadCheckbox;
export { FieldReadCheckbox };
