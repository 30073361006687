import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from '~/components';

const CircleButton = styled(Button)`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
`;

const CenteredTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default function AttachButton({ isOutline, tooltip, onClick }) {
  return (
    <CircleButton isOutline={isOutline} onClick={onClick}>
      <CenteredTooltip message={tooltip}>
        <Icon icon="paperclip-vertical" type="far" />
      </CenteredTooltip>
    </CircleButton>
  );
}
