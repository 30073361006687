import { useEffect, useState, useRef } from 'react';

function useAutoComplete({
  onSearch,
  onChange,
  defaultOptions: defaultOptionsProp,
  value,
  displayText = '',
  isOpen: isOpenProp,
  inputRef,
  onBlur,
}) {
  const defaultOptions = defaultOptionsProp || [];
  const [options, setOptions] = useState(defaultOptions);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(isOpenProp || false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const enabledOptions = options?.filter ? options.filter((option) => !option.disabled) : [];
  const selectedOption = enabledOptions[selectedIndex];

  const displayTextRef = useRef(displayText);
  displayTextRef.current = displayText;

  const handleSearch = async (value) => {
    setSelectedIndex(-1);
    setIsLoading(true);
    try {
      const results = await onSearch(value);
      setOptions(results);
    } catch (error) {
      setOptions(defaultOptions);
    } finally {
      setIsLoading(false);
      setIsOpen(true);
    }
  };

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    handleSearch(value);
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    setSelectedIndex(-1);
    setInputValue(displayTextRef.current);
    setOptions(defaultOptions);
    setIsOpen(false);

    if (onBlur) onBlur();
    if (inputRef) inputRef.current.blur();
  };

  const handleSelect = (option) => {
    setSelectedIndex(-1);
    onChange(option);
    setInputValue(displayTextRef.current);
    setIsOpen(false);
    setOptions(defaultOptions);

    if (inputRef) inputRef.current.focus();
  };

  const handleClear = () => {
    onChange(null);
    handleBlur();
  };

  const handleKeyDown = (event) => {
    const { keyCode } = event;
    let index = selectedIndex;
    switch (keyCode) {
      case 38: // arrow up
      case 40: // arrow down
        setIsOpen(true);
        index = keyCode === 40 ? index + 1 : index - 1;
        break;
      case 9: // tab
        if (index > -1) {
          handleSelect(index > -1 ? enabledOptions[index] : value);
        } else {
          setIsOpen(false);
        }
        break;
      case 13: // enter
        if (index > -1) {
          handleSelect(index > -1 ? enabledOptions[index] : value);
          event.stopPropagation();
          event.preventDefault();
        } else {
          setIsOpen(false);
        }
        break;
      case 27: // escape
        setSelectedIndex(-1);
        setIsOpen(false);
        break;

      default:
        break;
    }

    if (index !== selectedIndex) {
      if (index === enabledOptions.length) index = -1;
      if (index < -1) index += enabledOptions.length + 1;
      setSelectedIndex(index);
    }
  };

  const handleLoading = (flag) => setIsLoading(flag);

  useEffect(() => {
    setInputValue(displayText);
  }, [displayText]);

  useEffect(() => {
    setOptions(defaultOptionsProp || []);
  }, [defaultOptionsProp]);

  return {
    handleBlur,
    handleClear,
    handleFocus,
    handleInputChange,
    handleKeyDown,
    handleSearch,
    handleSelect,
    handleLoading,
    setInputValue,
    inputValue,
    isLoading,
    isOpen,
    selectedIndex,
    options,
    enabledOptions,
    selectedOption,
  };
}

export default useAutoComplete;
