import { SingleSelect } from '~/components';
import React from 'react';

function ExpenseBillableTypeFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Billable Type"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="billable">Billable</option>
      <option value="non_billable">Non-Billable</option>
    </SingleSelect>
  );
}

export default ExpenseBillableTypeFilter;
