import { useWorkspace } from '~/contexts';
import React from 'react';
import RouteLink from './RouteLink';
import { ProjectPopover } from '~/components';

function ProjectLink({
  project,
  client = project.client,
  children,
  popoverPlacement = 'right',
  tab = 'overview',
  ...props
}) {
  const { workspace } = useWorkspace();
  const url = `/app/${workspace.key}/projects/${client.key}/${project.key}`;

  const projectDashboard = {
    overview: url,
    health: url.concat('/health'),
  };

  return (
    <RouteLink to={projectDashboard[tab]} {...props}>
      <ProjectPopover projectId={project.id} placement={popoverPlacement}>
        {children || project.name}
      </ProjectPopover>
    </RouteLink>
  );
}

export default ProjectLink;
