import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';

const TimeEntryContext = React.createContext();

function TimeEntryProvider({ children }) {
  const [entries, setEntries] = useState([]);

  const updateEntries = useCallback(
    (entries) =>
      setEntries((currentEntries) => {
        const newEntries = _.cloneDeep(currentEntries);

        _.each(entries, (entry) => {
          if (!entry || !entry.id) {
            return;
          }

          // TODO: Figure out if this is the right spot for this...
          if (entry.project?.useRoles === false && !!entry.role) {
            entry.role = null;
          }

          const currentEntry = _.find(newEntries, { id: entry.id });
          if (currentEntry) {
            _.assign(currentEntry, entry);
          } else {
            newEntries.push(entry);
          }
        });

        const sortedEntries = _.orderBy(newEntries, ['date', 'createdAt', 'id'], ['desc', 'desc', 'asc']);

        return _.isEqual(currentEntries, sortedEntries) ? currentEntries : sortedEntries;
      }),
    [],
  );

  const updateEntry = useCallback((entry) => updateEntries([entry]), [updateEntries]);

  const removeEntries = useCallback(
    (entries) =>
      setEntries((currentEntries) => {
        const newEntries = _.filter(currentEntries, (currentEntry) => {
          return !_.find(entries, { id: currentEntry.id });
        });
        return _.isEqual(currentEntries, newEntries) ? currentEntries : newEntries;
      }),
    [],
  );

  const removeEntry = useCallback((entry) => removeEntries([entry]), [removeEntries]);

  // Clear the time entries but keep the running timer
  const clearEntries = useCallback(
    () => setEntries((currentEntries) => currentEntries.filter((e) => !!e.timerStartedAt)),
    [],
  );

  const providerValue = {
    entries,
    updateEntry,
    updateEntries,
    removeEntry,
    removeEntries,
    clearEntries,
  };
  return <TimeEntryContext.Provider value={providerValue}>{children}</TimeEntryContext.Provider>;
}

function useTimeEntries() {
  return useContext(TimeEntryContext);
}

export { useTimeEntries, TimeEntryContext, TimeEntryProvider };
