import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

export default function CompanyCreateForm({ onClose, onSaved }) {
  const api = useApi();
  const features = useFeatures();

  const { workspace } = useWorkspace();

  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['companyType', 'industry', 'owner']),
        companyTypeId: values.companyType?.id ?? null,
        industryId: values.industry?.id ?? null,
        ownerId: values.owner?.id ?? null,
      });

      const { data } = await api.www.workspaces(workspace.id).companies().upsert(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    companyType: null,
    currency: workspace.currency,
    industry: null,
    name: '',
    owner: null,
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <>
      <ModalCard title="New Company" onClose={handleClose}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            currency: Yup.string().label('Currency').required(),
            name: Yup.string().label('Company Name').max(255).required(),
            owner: Yup.object().label('Owner').nullable(),
          })}>
          {(formik) => {
            return (
              <Form>
                <ModalCard.Body>
                  <>
                    <Form.Control>
                      <Field.Text
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        autoFocus
                        name="name"
                        style={{ minWidth: '37rem' }}
                        placeholder="Company Name"
                        maxLength={127}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.MemberSelect name="owner" placeholder="Relationship Owner" />
                    </Form.Control>
                    <Form.Control>
                      <Field.IndustrySelect name="industry" placeholder="Industry" allowNew />
                    </Form.Control>
                    <Form.Control>
                      <Field.CompanyTypeSelect name="companyType" placeholder="Company Type" allowNew />
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        placeholder="Currency"
                        clearable={false}
                        disabled={!features.multicurrency}
                      />
                    </Form.Control>
                  </>

                  {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={handleClose}>Close</CancelButton>
                    <Button onClick={formik.submitForm} isLoading={isSubmitting}>
                      Save
                    </Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </>
  );
}
