import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

const BillableIconContainer = styled.div`
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey25)};
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight ?? '0.4375rem'};
  flex-shrink: 0;
`;

function BillableIcon({ value, showTooltip = true, ...props }) {
  return (
    <BillableIconContainer isBillable={value} {...props}>
      {showTooltip ? (
        <Tooltip message={value ? 'Billable' : 'Non-billable'}>
          <Icon icon="dollar-sign" />
        </Tooltip>
      ) : (
        <Icon icon="dollar-sign" />
      )}
    </BillableIconContainer>
  );
}

export default BillableIcon;
export { BillableIconContainer };
