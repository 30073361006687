import {
  BillableIcon,
  Button,
  ClientSelect,
  DateTime,
  Hours,
  InternalClientTooltip,
  Level,
  Page,
  ProjectAdmins,
  ProjectLink,
  ProjectStatusSelect,
  SearchInput,
  SingleSelect,
  Stack,
  Table,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ProjectDrawer from '~/routes/app/projects/project-drawer/ProjectDrawer';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import AssignProjectModal from './AssignProjectModal';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Status = styled.span`
  color: ${({ status }) =>
    ({
      tentative: colors.grey25,
      not_started: colors.warning,
      in_progress: colors.success,
      paused: colors.danger,
      completed: colors.black,
      cancelled: colors.grey55,
    })[status]};
`;

function MemberProjectsTab({ member }) {
  useDocumentTitle(`${member.name} Projects`);

  const api = useApi();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const [editProjectDrawer, setEditProjectDrawer] = useState(null);
  const [assignProjectModal, setAssignProjectModal] = useState(false);

  const initialParams = useMemo(() => {
    return {
      q: '',
      client: null,
      status: null,
      recordStatusId: 'active',
      sort: new QuerySort('name', 'asc'),
    };
  }, []);

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        client: searchParamsConfig.client,
        status: searchParamsConfig.projectStatus,
        recordStatusId: { default: 'active', ...searchParamsConfig.recordStatusId },
        sort: { default: initialParams.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig, initialParams],
    ),

    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const [query, setQuery] = useState({ isReady: false, data: null });

  const [params, setParams] = useState(initialParams);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .members(member.id)
        .projects.assigned({
          q: params.q,
          clientId: params.client?.id,
          status: params.status ?? undefined,
          recordStatusId: params.recordStatusId ?? undefined,
          sort: params.sort,
        });

      setQuery({ isReady: true, data });
    } catch {
      setQuery({ isReady: true, data: [] });
    }
  }, [workspace.id, member.id, params, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilter = (value) => {
    const filters = { ...params, ...value };
    setParams(filters);
    searchParams.set({ ...value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    handleFilter({ sort: querySort });
  };

  const handleProjectSaved = async () => {
    fetchData();
  };

  const handleProjectDeleted = async () => {
    await fetchData();
    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    setEditProjectDrawer(null);
  };

  const handleAssignProjectClick = () => {
    setAssignProjectModal(true);
  };

  const handleProjectAssigned = () => {
    fetchData();
    setAssignProjectModal(false);
  };

  if (!query.isReady) return <PageLoader />;

  return (
    <>
      <Stack margin="2rem 0 0">
        <Page.Section>
          <Level>
            <Level.Item>
              <SearchInput
                value={params.q}
                placeholder="Search"
                materialPlaceholder="Project or Client Name"
                materialAlwaysVisible
                onChange={({ target: { value } }) => handleFilter({ q: value })}
              />
            </Level.Item>

            <Level.Item>
              <ClientSelect
                name="client"
                placeholder="All"
                materialPlaceholder="Client"
                materialAlwaysVisible
                activeOnly={false}
                value={params.client}
                onChange={({ target: { value } }) => handleFilter({ client: value })}
              />
            </Level.Item>

            <Level.Item>
              <ProjectStatusSelect
                name="status"
                placeholder="All"
                materialPlaceholder="Project Status"
                materialAlwaysVisible
                value={params.status}
                showEmptyOption
                onChange={({ target: { value } }) => handleFilter({ status: value })}
              />
            </Level.Item>

            <Level.Item>
              <SingleSelect
                name="recordStatusId"
                placeholder="All"
                materialPlaceholder="Project Archived"
                materialAlwaysVisible
                value={params.recordStatusId}
                showEmptyOption
                onChange={({ target: { value } }) => handleFilter({ recordStatusId: value })}>
                <option value="active">No</option>
                <option value="archived">Yes</option>
              </SingleSelect>
            </Level.Item>

            <Level.Item right narrow>
              <Button onClick={handleAssignProjectClick}>Assign Project</Button>
            </Level.Item>
          </Level>
        </Page.Section>

        <Page.Section>
          <Table.Total value={query.data.length} label="Project" />

          <Table>
            <Table.BoxHeader>
              <Table.Column name="name" onSort={handleSort} sort={params.sort}>
                Project
              </Table.Column>

              <Table.Column width="10rem">Status</Table.Column>

              <Table.Column width="16rem">Project Admin</Table.Column>

              <Table.Column>Roles</Table.Column>

              <Table.Column width="6rem" align="right" name="hoursWorked" onSort={handleSort} sort={params.sort}>
                Hours
              </Table.Column>

              <Table.Column name="createdAt" onSort={handleSort} sort={params.sort} width="8rem" align="right">
                Created
              </Table.Column>
              <Table.BoxActionsColumn />
            </Table.BoxHeader>

            <Table.Body>
              {query.data.map((project) => {
                const handleView = () => {
                  history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}`);
                };

                const handleEdit = () => {
                  setEditProjectDrawer(project);
                };

                return (
                  <Table.BoxRow key={project.id} onClick={handleView}>
                    <Table.Cell>
                      <ProjectInfo>
                        <div style={{ display: 'flex' }}>
                          <ProjectLink project={project} onClick={(e) => e.stopPropagation()} />
                        </div>
                        <small>
                          {project.client.name}
                          {project.client.isInternal && <InternalClientTooltip />}
                        </small>
                      </ProjectInfo>
                    </Table.Cell>

                    <Table.Cell>
                      <Status status={project.status.id}>{project.status.name}</Status>
                    </Table.Cell>

                    <Table.Cell>
                      <ProjectAdmins project={project} />
                    </Table.Cell>

                    <Table.Cell>
                      <Tags>
                        <FirstRole project={project} />
                        <Roles project={project} />
                      </Tags>
                    </Table.Cell>

                    <Table.Cell>
                      <Hours value={project.hoursWorked} />
                    </Table.Cell>

                    <Table.Cell>
                      <DateTime value={project.createdAt} />
                    </Table.Cell>

                    <TableBoxRowActions>
                      <TableBoxRowActions.View onClick={handleView} />

                      <hr />

                      <TableBoxRowActions.Edit onClick={handleEdit} />
                    </TableBoxRowActions>
                  </Table.BoxRow>
                );
              })}
            </Table.Body>
          </Table>
        </Page.Section>
      </Stack>
      {editProjectDrawer && (
        <ProjectDrawer
          projectId={editProjectDrawer.id}
          onSaved={handleProjectSaved}
          onDeleted={handleProjectDeleted}
          onClose={handleCloseDrawer}
        />
      )}
      {assignProjectModal && (
        <AssignProjectModal
          member={member}
          onClose={() => setAssignProjectModal(false)}
          onSaved={handleProjectAssigned}
        />
      )}
    </>
  );
}

const FirstRole = ({ project }) => {
  if (!project.useRoles) return null;

  const role = project.roles[0];
  if (!role) return null;

  return (
    <Tag style={{ backgroundColor: colors.grey5 }}>
      <BillableIcon value={project.isBillable && role.isBillable} />
      <small>{role.name}</small>
    </Tag>
  );
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Roles = ({ project }) => {
  if (!project.useRoles) return null;

  let rolesCount = -1; // Remove the first role because it already shows a tag
  rolesCount += project.roles.length; // Add roles
  if (rolesCount <= 0) return null;

  return (
    <Tooltip
      style={{ display: 'flex' }}
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Roles</Title>

          {project.roles.map((role) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
              <BillableIcon value={project.isBillable && role.isBillable} />
              <small>{role.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{rolesCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default MemberProjectsTab;
