import { Button, Drawer, Icon, TimeApprovalPopover, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures } from '~/hooks';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import HistoryTab from '../edit-time-entry/HistoryTab';
import timeLockedReasons from '../timeLockedReasons';
import BillingTab from './BillingTab';
import GeneralTab from './GeneralTab';
import AttachmentsTab from '../edit-time-entry/AttachmentsTab';

const Statuses = styled.div`
  position: absolute;
  top: 0;
  right: 4rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 0.625rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.875rem;
  height: 1.875rem;
  padding: 0 1rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 999rem;
  box-shadow: 0 0.1875rem 0.5rem ${rgba(colors.black, 0.2)};

  .icon {
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    font-size: 0.625rem;
  }
`;

const InvoiceStatus = styled(Status)`
  color: ${colors.grey100};
`;

const LockStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.875rem;
  height: 1.875rem;
  padding: 0 1rem;
  color: ${colors.black};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 999rem;
  box-shadow: 0 0.1875rem 0.5rem ${rgba(colors.black, 0.2)};

  .icon {
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    font-size: 0.625rem;
  }
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 2.25rem;
  border-bottom: solid 1px ${colors.grey10};

  > * {
    margin-right: 2.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: flex-start;
  height: 1.75rem;
  margin-bottom: -1px;
  padding: 0;
  color: ${colors.black};
  font-size: 0.875rem;
  font-weight: ${({ isActive }) => (isActive ? weights.bold : weights.normal)};
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  border-bottom: solid 3px ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  border-radius: 0;
  cursor: pointer;

  &:hover {
    color: ${colors.black};
    background-color: transparent;
    border-color: ${({ isActive }) => (isActive ? colors.primary : colors.primary50)};
  }
`;

const CancelButton = styled(Button)`
  margin-left: auto;
  color: ${colors.grey40};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.grey55};
    background-color: ${colors.grey10};
  }
`;

export default function ViewTimeEntry({ id, member, onClose, tab = 'general' }) {
  useDocumentTitle('View Time Entry');

  const api = useApi();
  const { workspace } = useWorkspace();
  if (!member) member = workspace.member;
  const [entry, setEntry] = useState();
  const [project, setProject] = useState();
  const [files, setFiles] = useState();
  const [initialized, setInitialized] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tab);
  const features = useFeatures();

  const isOwner = entry?.member.id === member.id;

  const billingEnabled =
    project?.permissions.manageTimeAndExpenses && entry?.project?.isBillable && entry?.permissions.viewRates;

  const tabs = useMemo(() => {
    const value = ['general'];
    if (billingEnabled) {
      value.push('billing');
    }
    if (files?.length > 0) {
      value.push('attachments');
    }
    if (entry?.history?.length > 0) {
      value.push('history');
    }
    return value;
  }, [billingEnabled, entry, files]);

  const fetchEntry = useCallback(async () => {
    if (!id) {
      setEntry();
      setInitialized(true);
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries(id).get();
      setEntry(data);
    } catch (error) {
      setEntry();
    } finally {
      setInitialized(true);
    }
  }, [api, id, workspace]);

  useEffect(() => {
    fetchEntry();
  }, [fetchEntry]);

  const fetchProject = useCallback(async () => {
    if (!entry?.project?.id) return;

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().projects(entry?.project?.id).get();
      setProject(data);
    } catch (error) {
      setProject();
    }
  }, [api, entry?.project?.id, workspace]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  const fetchFiles = useCallback(async () => {
    if (!id) {
      setFiles();
      return;
    }
    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries(id).getWeekFiles();

      setFiles(data);
    } catch (error) {
      setFiles(null);
    }
  }, [api, workspace, id]);

  useEffect(() => {
    if (!features.timeAttachments) return;
    fetchFiles();
  }, [fetchFiles, features.timeAttachments]);

  const actions = (closeDrawer) => <CancelButton onClick={() => closeDrawer()}>Close</CancelButton>;
  const rejectedHistoryItem = useMemo(() => _.find(entry?.history, { statusId: 'rejected' }), [entry]);
  const statusMessage = !entry?.status
    ? 'New time entry not yet submitted for approval.'
    : {
        approved: `Time entry has been ${entry.statusModeId === `auto` ? 'automatically' : `manually`} approved.`,
        rejected: `Time entry has been rejected.`,
        not_submitted: 'Time entry has not been submitted for approval.',
        pending_approval: 'Time entry has been submitted and is pending approval.',
      }[entry.status?.id];

  const clientStatusMessage = {
    approved: `Time entry has been approved by the client.`,
    rejected: `Time entry has been rejected by the client.`,
  }[entry?.clientStatus?.id];

  return (
    <Drawer title="View Entry" byline="Time" actions={initialized ? actions : null} onClose={onClose} isOpen>
      {initialized ? (
        <>
          <Statuses>
            {entry?.invoice?.number && (
              <Tooltip
                message={`Time entry is associated with ${
                  { open: 'published', draft: 'draft' }[entry?.invoice?.statusId]
                } invoice #${entry?.invoice?.number}.`}>
                <InvoiceStatus>
                  <Icon icon="file-invoice" color={colors.grey40} />
                  Invoiced
                </InvoiceStatus>
              </Tooltip>
            )}
            {entry?.isLocked && (
              <Tooltip message={timeLockedReasons[entry.lockStatusId]}>
                <LockStatus>
                  <Icon icon="lock" color={colors.grey40} />
                  Locked
                </LockStatus>
              </Tooltip>
            )}

            {features.clientApprovals && entry?.clientStatus?.id && (
              <Tooltip message={clientStatusMessage}>
                <Status status={entry.clientStatus.id}>
                  <Icon icon="circle" />
                  {entry.clientStatus.name}
                </Status>
              </Tooltip>
            )}

            {entry?.status?.id && (
              <>
                {entry.status.id === 'pending_approval' && entry.typeId === 'project_time' ? (
                  <TimeApprovalPopover timeEntryId={entry.id} placement="left">
                    <Status status={entry.status.id}>
                      <Icon icon="circle" />
                      {entry.status.name}
                    </Status>
                  </TimeApprovalPopover>
                ) : (
                  <Tooltip message={statusMessage}>
                    <Status status={entry.status.id}>
                      <Icon icon="circle" />
                      {entry.status.name}
                    </Status>
                  </Tooltip>
                )}
              </>
            )}
          </Statuses>

          {tabs.length > 1 && (
            <Tabs>
              {_.includes(tabs, 'general') && (
                <Tab isActive={selectedTab === 'general'} onClick={() => setSelectedTab('general')}>
                  General
                </Tab>
              )}
              {_.includes(tabs, 'billing') && (
                <Tab isActive={selectedTab === 'billing'} onClick={() => setSelectedTab('billing')}>
                  Billing
                </Tab>
              )}
              {_.includes(tabs, 'attachments') && (
                <Tab isActive={selectedTab === 'attachments'} onClick={() => setSelectedTab('attachments')}>
                  Attachments
                </Tab>
              )}
              {_.includes(tabs, 'history') && (
                <Tab isActive={selectedTab === 'history'} onClick={() => setSelectedTab('history')}>
                  History
                </Tab>
              )}
            </Tabs>
          )}
          {selectedTab === 'general' && (
            <GeneralTab entry={entry} isOwner={isOwner} rejectedHistoryItem={rejectedHistoryItem} />
          )}
          {selectedTab === 'billing' && <BillingTab entry={entry} />}
          {selectedTab === 'attachments' && <AttachmentsTab files={files} />}
          {selectedTab === 'history' && <HistoryTab history={entry?.history} />}
        </>
      ) : (
        <PageLoader />
      )}
    </Drawer>
  );
}
