import { useFormikContext } from 'formik';
import { useField, useOnClickOutside } from '~/hooks';
import React, { useCallback, useRef, useState } from 'react';
import DayPickerInput from './DayPickerInput';
import FieldControl from './FieldControl';

function FieldDayPicker({ errorPlacement, ...props }) {
  const [field, meta, helpers] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(
    ref,
    useCallback(() => {
      if (focused) helpers.setTouched(true);
    }, [helpers, focused]),
  );

  // TODO: allow to set disabled dates and display styles for specific dates
  return (
    <FieldControl
      error={error}
      errorPlacement={errorPlacement}
      ref={ref}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={(event) => {
        if (event.keyCode === 9) helpers.setTouched(true);
      }}>
      <DayPickerInput
        {...field}
        autoComplete="off"
        onChange={async (value) => {
          if (value !== undefined) {
            await helpers.setValue(value);
          }

          if (error) formik.validateField(field.name);
        }}
        onBlur={() => {
          // This event is triggered even if the focus changes to the calendar popover,
          // which causes a validation error to appear when the containing form is set to
          // validate on blur. The blur behavior is overridden by this wrapper component.
          // Blur is only triggered when a click event happens outside of this container,
          // or when the user uses the "tab" command.
          // TODO: focusing the input and then clicking the panel doesn't trigger the blur event
          // in this container. At some point, the DayPickerInput may need to handle these
          // scenarios internally. For example, by preventing "blur" to be triggered when the
          // focus switches between the text input and the calendar overlay.
        }}
        {...props}
      />
    </FieldControl>
  );
}

export default FieldDayPicker;
