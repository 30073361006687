import { QueryString } from '~/utils/index.js';
import { del, get, patch, post } from './agent.js';

const url = '/admin';
const reportsUrl = `${url}/reports`;
const membersUrl = `${url}/members`;
const emailsUrl = `${url}/emails`;
const workspacesUrl = `${url}/workspaces`;
const xeroUrl = `${url}/xero`;

export default {
  login: (credentials) => post(`${url}/login`, credentials),
  logout: (credentials) => post(`${url}/logout`, credentials),
  session: () => get(`${url}/session`),
  workspaces: {
    get: (params = {}) => get(workspacesUrl.concat(new QueryString(params).toString(true))),
    getMembers: (workspaceId) => get(`${workspacesUrl}/${workspaceId}/members`),
    getUsage: (workspaceId) => get(`${workspacesUrl}/${workspaceId}/usage`),
    clone: ({ workspaceId, workspaceName, workspaceKey }) =>
      post(`${workspacesUrl}/${workspaceId}/clone`, { workspaceName, workspaceKey }),
    changeStatus: (workspaceId, statusId) => patch(`${workspacesUrl}/${workspaceId}/status`, { statusId }),
    createDemoData: (workspaceId) => post(`${workspacesUrl}/${workspaceId}/create-demo-data`),
  },
  members: {
    get: (params = {}) => get(membersUrl.concat(new QueryString(params).toString(true))),
    getByIds: (memberIds) => get(`${membersUrl}/select?memberIds=${memberIds.join(',')}`),
    getById: (memberId) => get(`${membersUrl}/${memberId}`),
    getWorkspaces: (memberId) => get(`${membersUrl}/${memberId}/workspaces`),
    impersonate: (memberId) => post(`${membersUrl}/impersonation`, { memberId }),
    stopImpersonation: () => del(`${membersUrl}/impersonation`),
    changeStatus: (memberId, statusId) => patch(`${membersUrl}/${memberId}/status`, { statusId }),
  },
  emails: {
    get: (params = {}) => get(emailsUrl.concat(new QueryString(params).toString(true))),
    sync: () => post(`${emailsUrl}/sync`),
    remove: (suppressionId) => del(`${emailsUrl}/${suppressionId}`),
  },
  reports: {
    getDailyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/daily-active-users').concat(new QueryString(params).toString(true))),
    getHourlyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/hourly-active-users').concat(new QueryString(params).toString(true))),
    getInvoicesPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/invoices-per-workspace').concat(new QueryString(params).toString(true))),
    getMembersPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/members-per-workspace').concat(new QueryString(params).toString(true))),
    getMissingAssociatedMembers: (params = {}) =>
      get(reportsUrl.concat('/missing-associated-members').concat(new QueryString(params).toString(true))),
    getMonthlyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/monthly-active-users').concat(new QueryString(params).toString(true))),
    getRatings: (params = {}) => get(reportsUrl.concat('/ratings').concat(new QueryString(params).toString(true))),
    getTrackedHoursPerDay: (params = {}) =>
      get(reportsUrl.concat('/tracked-hours-per-day').concat(new QueryString(params).toString(true))),
    getTrackedHoursPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/tracked-hours-per-workspace').concat(new QueryString(params).toString(true))),
    getTrialsEndingSoon: (params = {}) =>
      get(reportsUrl.concat('/trials-ending-soon').concat(new QueryString(params).toString(true))),
    getWeeklyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/weekly-active-users').concat(new QueryString(params).toString(true))),
  },
  reportCSVs: {
    getDailyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/daily-active-users/csv').concat(new QueryString(params).toString(true))),
    getHourlyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/hourly-active-users/csv').concat(new QueryString(params).toString(true))),
    getInvoicesPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/invoices-per-workspace/csv').concat(new QueryString(params).toString(true))),
    getMembersPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/members-per-workspace/csv').concat(new QueryString(params).toString(true))),
    getMissingAssociatedMembers: (params = {}) =>
      get(reportsUrl.concat('/missing-associated-members/csv').concat(new QueryString(params).toString(true))),
    getMonthlyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/monthly-active-users/csv').concat(new QueryString(params).toString(true))),
    getRatings: (params = {}) => get(reportsUrl.concat('/ratings/csv').concat(new QueryString(params).toString(true))),
    getTrackedHoursPerDay: (params = {}) =>
      get(reportsUrl.concat('/tracked-hours-per-day/csv').concat(new QueryString(params).toString(true))),
    getTrackedHoursPerWorkspace: (params = {}) =>
      get(reportsUrl.concat('/tracked-hours-per-workspace/csv').concat(new QueryString(params).toString(true))),
    getTrialsEndingSoon: (params = {}) =>
      get(reportsUrl.concat('/trials-ending-soon/csv').concat(new QueryString(params).toString(true))),
    getWeeklyActiveUsers: (params = {}) =>
      get(reportsUrl.concat('/weekly-active-users/csv').concat(new QueryString(params).toString(true))),
  },
  xero: {
    getIntegrations: () => get(xeroUrl.concat('/integrations')),
    getConnections: (params = {}) => get(xeroUrl.concat('/connections').concat(new QueryString(params).toString(true))),
    removeConnection: (id) => del(xeroUrl.concat(`/connections/${id}`)),
  },
};
