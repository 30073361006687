import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

const IconTooltip = styled(Tooltip)`
  display: inline-flex;
  font-size: 1rem;
  vertical-align: text-top;

  .icon {
    cursor: help;
  }
`;

function HelpTooltip(props) {
  return (
    <IconTooltip {...props}>
      <Icon type="far" icon="question-circle" color={colors.grey25} />
    </IconTooltip>
  );
}

export default HelpTooltip;
