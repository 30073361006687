import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  font-size: 2rem;
`;

export default function ReportLoader() {
  return (
    <Container>
      <Icon icon="spinner" spin={true} />
    </Container>
  );
}
