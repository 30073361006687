import { useLocale } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  text-align: right;
`;

const NumberInput = React.forwardRef(
  ({ locale, min, max, precision = 0, value, onBlur, onChange, onFocus, ...props }, ref) => {
    if (value === undefined) throw new Error(`NumberInput must be controlled. Missing prop: 'value'.`);
    if (onChange === undefined) throw new Error(`NumberInput must be controlled. Missing prop: 'onChange'.`);
    if (!_.isNumber(precision)) throw new Error(`NumberInput precision must be a number.`);

    locale = useLocale(locale);

    const numberFormat = useMemo(
      () =>
        Intl.NumberFormat(locale, {
          useGrouping: false,
          minimumFractionDigits: 0,
          maximumFractionDigits: 20,
        }),
      [locale],
    );

    const decimalSeparator = useMemo(
      () =>
        Intl.NumberFormat(locale)
          .formatToParts(1.1)
          .find((part) => part.type === 'decimal').value,
      [locale],
    );

    const toString = useCallback((value) => (_.isNumber(value) ? numberFormat.format(value) : ''), [numberFormat]);

    const toNumber = (value) => {
      // parse the value to a number, replacing the decimal separator with a period.
      let number = parseFloat(value.replace(decimalSeparator, '.'));
      return isNaN(number) ? null : number;
    };

    const [text, setText] = useState(() => toString(value, locale));

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (!isFocused) setText(toString(value));
    }, [value, toString, isFocused]);

    const handleBlur = (event) => {
      let newValue = value;

      if (value !== null) {
        if (_.isNumber(min) && newValue < min) newValue = min;
        if (_.isNumber(max) && newValue > max) newValue = max;
      }

      if (newValue !== value) {
        event.target.value = newValue;
        onChange(newValue, event);
      }

      if (onBlur) onBlur(event);

      setIsFocused(false);
    };

    const handleChange = (event) => {
      const newValue = event.target.value;

      if (newValue === '') onChange('', event);

      let pattern;

      if (precision === 0) {
        pattern = `^-?\\d*$`;
      } else {
        pattern = `^-?\\d*\\${decimalSeparator}?\\d{0,${precision}}$`;
      }

      // Only allow digits, negative sign and decimal separator.
      if (!new RegExp(pattern).test(newValue)) return;

      // Do not allow more than one leading zero.
      if (newValue.startsWith('00')) return;

      // Set the internal text to the value the user typed.
      setText(newValue);

      // Call onChange with the value parsed as a number.
      onChange(toNumber(newValue), event);
    };

    const handleFocus = (event) => {
      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    return (
      <Input
        ref={ref}
        {...props}
        type="text"
        value={text}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    );
  },
);

export default NumberInput;
