import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { colors } from '~/styles';

export default function SidebarScrollbar({ ...props }) {
  const renderView = ({ style, ...props }) => {
    const customStyle = {
      display: 'flex',
      flexDirection: 'column',
    };

    return <div style={{ ...style, ...customStyle }} {...props} />;
  };

  const renderTrack = ({ style, ...props }) => {
    const customStyle = {
      width: '0.25rem',
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: colors.grey25,
    };

    return <div style={{ ...style, ...customStyle }} {...props} />;
  };

  const renderThumb = ({ style, ...props }) => {
    const customStyle = {
      backgroundColor: colors.primary,
    };

    return <div style={{ ...style, ...customStyle }} {...props} />;
  };

  return (
    <Scrollbars
      renderView={renderView}
      renderTrackVertical={renderTrack}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );
}
