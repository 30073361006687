import React, { useCallback, useContext, useMemo, useState } from 'react';

const TableContext = React.createContext();

function TableProvider({ children }) {
  const [columns, setColumns] = useState([]);
  const setColumn = useCallback(
    (props) =>
      setColumns((state) =>
        state.some((column) => column.index === props.index)
          ? state.map((column) => (column.index === props.index ? props : column))
          : [...state, props],
      ),
    [],
  );

  const table = useMemo(() => ({ setColumn, columns }), [setColumn, columns]);
  return <TableContext.Provider value={table}>{children}</TableContext.Provider>;
}

function useTable() {
  return useContext(TableContext);
}

export { TableContext, useTable, TableProvider };
