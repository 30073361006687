import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { DetailsPopover } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

const Info = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-align: center;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  flex: 1;
  text-align: center;
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

function CompanyPopover({ companyId, placement = 'auto', ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  useEffect(() => {
    if (!companyId || !visible || query.isReady) return;

    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).companies(companyId).detailsCard();
        setQuery({ isReady: true, data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, companyId, visible, query.isReady]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const company = query.data;

  return (
    <>
      <span ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        query.isReady &&
        ReactDOM.createPortal(
          <DetailsPopover
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Content data-testid="company_popover">
              <InfoBlock title="Company Name">{company.name}</InfoBlock>
              {company.companySpendTier && (
                <InfoBlock title="Company Spend Tier">{company.companySpendTier.name}</InfoBlock>
              )}
              {company.companyType && <InfoBlock title="Company Type">{company.companyType.name}</InfoBlock>}
              {company.client && <InfoBlock title="Client">{company.client.name}</InfoBlock>}
              {company.industry && <InfoBlock title="Industry">{company.industry.name}</InfoBlock>}
              {company.owner && <InfoBlock title="Relationship Owner">{company.owner.name}</InfoBlock>}
            </Content>
          </DetailsPopover>,
          document.body,
        )}
    </>
  );
}

export default CompanyPopover;
