import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';

const StyledToast = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 0.3rem 1.5rem 0.3rem 0.75rem;
  animation: slide 0.1s ease-out;
  width: auto;
  min-width: 30rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  border: 1px solid;
  border-color: ${colors.primary50};
  color: ${colors.primary};
  background-color: ${colors.primary5};

  > div > button {
    color: ${colors.primary};
  }

  ${({ variant }) =>
    ({
      success: css`
        border-color: ${colors.success50};
        color: ${colors.success};
        background-color: ${colors.success10};

        > div > button {
          color: ${colors.success};
        }
      `,
      warning: css`
        border-color: ${colors.warning50};
        color: ${colors.warning};
        background-color: ${colors.warning10};

        > div > button {
          color: ${colors.warning};
        }
      `,
      danger: css`
        border-color: ${colors.danger50};
        color: ${colors.danger};
        background-color: ${colors.danger10};

        > div > button {
          color: ${colors.danger};
        }
      `,
    })[variant]}

  @keyframes slide {
    0% {
      opacity: 1;
      transform: translateY(-5rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;

  > button {
    background-color: transparent;
    margin-right: 0.5rem;
    padding: 0;

    &:hover {
    }
  }
`;

const Container = styled.div`
  position: fixed;
  top: 3.9rem;
  width: 90%;
  margin: 0 auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

function Toast({ children, onClose, ...props }) {
  return (
    <StyledToast {...props}>
      <Close onClick={onClose}>
        <button aria-label="close" className="toast" onClick={onClose}>
          <Icon icon="times" />
        </button>
      </Close>
      {children}
    </StyledToast>
  );
}

function ToastContainer({ children }) {
  return ReactDOM.createPortal(<Container>{children}</Container>, document.body);
}

Toast.Container = ToastContainer;

export default Toast;
