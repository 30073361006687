import { Dropdown } from '~/components';
import styled from 'styled-components';
import { colors } from '~/styles';

const MetricDropdown = styled(Dropdown)`
  font-size: 0.875rem;
  white-space: nowrap;
  width: 9.5rem;
  text-align: right;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.grey75};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

export default MetricDropdown;
