import _ from 'lodash';
import { del, get, patch, post } from './agent';

const bamboohr = (workspacesUrl) => {
  const url = _([workspacesUrl, 'bamboohr']).compact().join('/');

  return {
    get: () => get(url),
    getSyncs: () => get(url.concat('/syncs')),
    getSyncLog: (id) => get(url.concat('/syncs/', id, '/log')),
    getMembers: () => get(url.concat('/members')),
    getEmployees: () => get(url.concat('/employees')),
    getMemberMappings: () => get(url.concat('/member-mappings')),
    getExternalTimeOffTypes: () => get(url.concat('/external-time-off-types')),
    getInternalTimeOffTypes: () => get(url.concat('/internal-time-off-types')),
    getTimeOffTypeMappings: () => get(url.concat('/time-off-type-mappings')),
    setup: (body) => post(url, body),
    updateSettings: (body) => patch(url.concat('/settings'), body),
    updateMemberMappings: (body) => post(url.concat('/member-mappings'), body),
    updateTimeOffTypeMappings: (body) => post(url.concat('/time-off-type-mappings'), body),
    manualSync: (body) => post(url.concat('/manual-sync'), body),
    remove: () => del(url),
    deleteData: () => del(url.concat('/data')),
  };
};

export default bamboohr;
