import { ActionButton } from '~/components';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

export const Mapping = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${colors.grey10};
`;

export const Actions = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;
export const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  padding-right: 2rem;
  color: ${colors.grey40};
  font-size: 1.375rem;
  border-right: 1px solid ${colors.grey10};

  &:hover {
    color: ${colors.grey55};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MemberName = styled.h1`
  margin-bottom: 0.75rem;
`;

export const MemberType = styled.h3`
  strong {
    color: ${colors.grey40};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
  }

  small {
    font-size: 0.75rem;
    color: ${colors.grey25};
  }
`;

export const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const StatusTag = styled(Tag)`
  color: ${({ isActive }) => (isActive ? colors.green : colors.grey25)};

  .icon {
    font-size: 0.625rem;
  }
`;

export const Page = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.75rem 2rem 2rem;
`;
