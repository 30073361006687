import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useApi, useWorkspace } from '~/contexts';

export default function useClientMetrics({ clients, projects, start, end, unit, allocations, onFetched }) {
  const viewed = useRef([]);

  const { workspace } = useWorkspace();
  const api = useApi();

  const fetchData = useCallback(
    async ({ start, end, unit, projectIds, allocationIds }) => {
      if (projectIds.length === 0) return;

      const { data: metrics } = await api.www.workspaces(workspace.id).allocations().projectSchedule.metrics.clients({
        start,
        end,
        unit,
        projectIds,
        allocationIds,
      });

      if (metrics.length === 0) return;

      onFetched(metrics);
    },
    [api, workspace.id, onFetched],
  );

  const load = useCallback(
    ({ start, end, unit, allocations }) => {
      const clientIds = clients.map((c) => c.id);
      const projectIds = projects.filter((r) => clientIds.includes(r.project?.client.id)).map((r) => r.id);
      const allocationIds = (allocations ?? []).filter((a) => projectIds.includes(a.projectId)).map((a) => a.id);
      fetchData({ start, end, unit, allocationIds, projectIds });
      viewed.current = clientIds;
    },
    [clients, projects, fetchData],
  );

  const initialize = useCallback(() => {
    viewed.current = [];
  }, []);

  useEffect(() => {
    const clientIds = clients.map((c) => c.id).filter((g) => !viewed.current.includes(g));
    if (clientIds.length === 0) return;
    viewed.current = viewed.current.concat(clientIds);
    const projectIds = projects.filter((r) => clientIds.includes(r.project?.client.id)).map((r) => r.id);
    const allocationIds = (allocations ?? []).filter((a) => projectIds.includes(a.projectId)).map((a) => a.id);
    fetchData({ start, end, unit, allocationIds, projectIds });
  }, [fetchData, start, end, unit, allocations, clients, projects]);

  return useMemo(() => ({ load, initialize }), [load, initialize]);
}
