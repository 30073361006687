import {
  MemberFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectRecordStatusFilter,
  ProjectRoleFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  ProjectHealthFilter,
} from '~/components';
import { Filter } from '~/components/filters';
import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React from 'react';
import FiltersGroup from './FiltersGroup';

function ProjectFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Project Filters"
      filters={[
        filters.projects,
        filters.projectRoles,
        filters.projectBillingTypes,
        filters.projectStatuses,
        filters.projectRecordStatusId,
        filters.projectTags,
        filters.projectPractices,
        filters.projectTypes,
        filters.projectAdmins,
        filters.projectSalesRepresentatives,
        filters.projectBudgetHealth,
        filters.projectScheduleHealth,
        filters.projectClientSatisfaction,
        filters.projectTeamSatisfaction,
      ]}>
      <Filter>
        <ProjectFilter
          clients={filters.clients}
          value={filters.projects}
          onChange={({ target: { value } }) => {
            const result = {
              projects: value,
            };

            if (_.has(filters, 'projectRoles'))
              result.projectRoles =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectRoles;

            if (_.has(filters, 'projectTasks'))
              result.projectTasks =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectTasks;

            onChange(result);
          }}
        />
      </Filter>

      {_.has(filters, 'projectRoles') && filters.projects?.length === 1 && filters.projects[0]?.useRoles && (
        <Filter>
          <ProjectRoleFilter
            project={filters.projects[0]}
            value={filters.projectRoles}
            onChange={({ target: { value } }) => onChange({ projectRoles: value })}
          />
        </Filter>
      )}

      {features.projectHealth && (
        <>
          {_.has(filters, 'projectBudgetHealth') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Budget Health"
                value={filters.projectBudgetHealth}
                onChange={({ target: { value } }) => onChange({ projectBudgetHealth: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectScheduleHealth') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Schedule Health"
                value={filters.projectScheduleHealth}
                onChange={({ target: { value } }) => onChange({ projectScheduleHealth: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectClientSatisfaction') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Client Satisfaction"
                value={filters.projectClientSatisfaction}
                onChange={({ target: { value } }) => onChange({ projectClientSatisfaction: value })}
              />
            </Filter>
          )}

          {_.has(filters, 'projectTeamSatisfaction') && (
            <Filter>
              <ProjectHealthFilter
                materialPlaceholder="Team Satisfaction"
                value={filters.projectTeamSatisfaction}
                onChange={({ target: { value } }) => onChange({ projectTeamSatisfaction: value })}
              />
            </Filter>
          )}
        </>
      )}

      <Filter>
        <ProjectBillingTypeFilter
          value={filters.projectBillingTypes}
          onChange={({ target: { value } }) => onChange({ projectBillingTypes: value })}
        />
      </Filter>

      <Filter>
        <ProjectStatusFilter
          value={filters.projectStatuses}
          onChange={({ target: { value } }) => onChange({ projectStatuses: value })}
        />
      </Filter>

      <Filter>
        <ProjectRecordStatusFilter
          value={filters.projectRecordStatusId}
          onChange={({ target: { value } }) => onChange({ projectRecordStatusId: value })}
        />
      </Filter>

      <Filter>
        <ProjectTagFilter
          value={filters.projectTags}
          onChange={({ target: { value } }) => onChange({ projectTags: value })}
        />
      </Filter>

      {features.practices && (
        <Filter>
          <PracticeFilter
            value={filters.projectPractices}
            materialPlaceholder="Project Practice"
            onChange={({ target: { value } }) => onChange({ projectPractices: value })}
          />
        </Filter>
      )}

      <Filter>
        <ProjectTypeFilter
          value={filters.projectTypes}
          onChange={({ target: { value } }) => onChange({ projectTypes: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.projectAdmins}
          materialPlaceholder="Project Admin"
          onChange={({ target: { value } }) => onChange({ projectAdmins: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.projectSalesRepresentatives}
          materialPlaceholder="Project Sales Representative"
          onChange={({ target: { value } }) => onChange({ projectSalesRepresentatives: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}

export default ProjectFiltersGroup;
