import { Form, FormMessage } from '~/components';
import React, { useState, useEffect, useRef } from 'react';
import checkCSV from '../helpers/check-csv';
import HeaderCheckIndicator from './HeaderCheckIndicator';
import RowsCheckIndicator from './RowsCheckIndicator';
import { ImportResults } from './ImportResults';
import ReferenceSection from './ReferenceSection';
import { DropzoneContainer, DropzoneInterior, SaveButton } from './styles';
import rules from '../rules/index.js';

export default function MainImportSection(props) {
  const { isSubmitting, saved, formik, result, onFileChanged, status, message, dataType } = props;
  const [validationPassed, setValidationPassed] = useState(false);
  const [progressHeaderCheck, setProgressHeaderCheck] = useState(null);
  const [progressRowsCheck, setProgressRowsCheck] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Reset the validation states
    setValidationPassed(false);
    setProgressHeaderCheck(null);
    setProgressRowsCheck(null);

    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, [dataType]);

  const handleFileChange = (event) => {
    setValidationPassed(false);
    const file = event.target.files[0];
    if (file) {
      onFileChanged(event);
      setProgressHeaderCheck(null);
      setProgressRowsCheck(null);
      checkCSV({
        file,
        rules: rules.csvRules[dataType],
        onProgress: (progress) => {
          if (progress.stepsPassed === 1) setProgressHeaderCheck(progress);
          if (progress.stepsPassed === 2) {
            setProgressRowsCheck(progress);
            setValidationPassed(true);
          }
        },
      });
    }
  };

  const validationRules = rules.csvRules[dataType];
  const sampleFile = rules.samplefiles[dataType];
  return (
    <>
      <Form.Section
        title="Step 2: Provide a CSV file"
        subtitle="A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values, and is commonly edited in Microsoft Excel. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. Utilize the sample file to ensure your data is accurately read.">
        <>
          {dataType && (
            <DropzoneContainer>
              <DropzoneInterior>
                <input
                  ref={fileInputRef}
                  name="csvFile"
                  type="file"
                  accept=".csv, text/csv"
                  onChange={handleFileChange}
                />
              </DropzoneInterior>
            </DropzoneContainer>
          )}

          <ReferenceSection validationRules={validationRules} sampleFile={sampleFile} />

          {progressHeaderCheck && <HeaderCheckIndicator progress={progressHeaderCheck} />}
          {progressRowsCheck && <RowsCheckIndicator progress={progressRowsCheck} csvRules={validationRules} />}

          {validationPassed && (
            <Form.Control>
              <SaveButton isLoading={isSubmitting} ok={saved} onClick={formik.submitForm}>
                Submit for Import
              </SaveButton>
            </Form.Control>
          )}
          {status && <FormMessage.Error>{message} The file you provided could not be imported.</FormMessage.Error>}
        </>
      </Form.Section>
      {result && (
        <Form.Section
          title="Results"
          subtitle="This is a summary of the import and describes if and why any items were skipped.">
          <>
            <ImportResults result={result}></ImportResults>
          </>
        </Form.Section>
      )}
    </>
  );
}
