import React from 'react';
import { Button, Icon } from '~/components';
import styled from 'styled-components';
import { colors } from '~/styles';

const TagContainer = styled.div`
  display: flex;
`;

const TagStyle = styled.span`
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 0.25rem;

  strong {
    white-space: nowrap;
    padding-right: 0.25rem;
  }

  > .button {
    margin-left: 0.5rem;
    color: ${colors.danger};

    &:hover {
      color: ${colors.danger50};
    }
  }
`;

function SortTag({ label = 'Sort By', value, onRemove }) {
  if (!value) return;

  return (
    <TagContainer>
      <TagStyle data-testid="sorting_tag">
        {label && <strong>{label}:</strong>}
        {value}
        {onRemove && (
          <Button isAnchor onClick={onRemove}>
            <Icon icon="times" />
          </Button>
        )}
      </TagStyle>
    </TagContainer>
  );
}

export default SortTag;
