import { useWorkspace } from '~/contexts';
import { useCurrencyFormat, useDateTimeFormat, usePercentFormat } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const HelpText = styled.div`
  margin: 0.5rem;
  margin-bottom: 0;
  color: ${colors.grey75};
  font-size: 0.875rem;
`;

const HelpList = styled.ul`
  li {
    display: flex;
    &:not(:first-child) {
      margin-top: 0.2rem;
    }
  }

  span {
    width: 8rem;
  }
`;

export default function RegionalFormatExamples({ currency, locale }) {
  if (!locale) locale = undefined;

  const number = new Intl.NumberFormat(locale);

  const { workspace } = useWorkspace();
  const currencyFormat = useCurrencyFormat({ locale, currency: currency ?? workspace?.currency ?? 'USD' });

  const date = new Date();
  const dateTimeFormat = {
    date: useDateTimeFormat({ locale, format: dateFormats.compactDate }),
    time: useDateTimeFormat({ locale, format: dateFormats.compactDateTime }),
  };

  const percentFormat = usePercentFormat({ locale });

  return (
    <HelpText>
      <p style={{ marginBottom: '1rem' }}>The regional format affects date, time, number and currency formatting.</p>

      <HelpList>
        <li>
          <span>Date</span>
          {dateTimeFormat.date.format(date)}
        </li>

        <li>
          <span>Date and Time</span>
          {dateTimeFormat.time.format(date)}
        </li>

        <li>
          <span>Number</span>
          {number.format(123456.78)}
        </li>

        <li>
          <span>Currency</span>
          {currencyFormat.format(123456.78)}
        </li>

        <li>
          <span>Percent</span>
          {percentFormat.format(0.12345)}
        </li>
      </HelpList>
    </HelpText>
  );
}
