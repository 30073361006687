import { useHoursFormat } from '~/hooks';
import _ from 'lodash';

export default function Hours({ value, ...props }) {
  const hoursFormat = useHoursFormat(props);

  if (!_.isNumber(value) || isNaN(value)) return '-';

  return hoursFormat.format(value);
}
