import _ from 'lodash';
import { del, get, patch, post } from './agent';

const qbo = {
  public: {
    postRedirectUri: ({ uri }) => post('/qbo/redirect-uri', { uri }),
  },

  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'qbo']).compact().join('/');

    return {
      getIntegration: () => get(url.concat('/integration')),
      remove: () => del(url.concat('/integration')),
      getConnection: () => get(url.concat('/connection')),
      getConfiguration: () => get(url.concat('/configuration')),
      updateConfiguration: (body) => patch(url.concat('/configuration'), body),
      setupConfiguration: () => post(url.concat('/setup/configuration')),
      getCompany: () => get(url.concat('/company')),
      getCustomers: () => get(url.concat('/customers')),
      getClientMappings: () => get(url.concat('/mapping/clients')),
      updateClientMappings: (body) => post(url.concat('/mapping/clients'), body),
      setupClients: () => post(url.concat('/setup/clients')),
      getItems: () => get(url.concat('/items')),
      getInvoiceItemMappings: () => get(url.concat('/mapping/invoice-items')),
      updateInvoiceItemMappings: (body) => post(url.concat('/mapping/invoice-items'), body),
      setupInvoiceItems: () => post(url.concat('/setup/invoice-items')),
      getPaymentMethods: () => get(url.concat('/payment-methods')),
      getPaymentMethodMappings: () => get(url.concat('/mapping/payment-methods')),
      updatePaymentMethodMappings: (body) => post(url.concat('/mapping/payment-methods'), body),
      setupPaymentMethods: () => post(url.concat('/setup/payment-methods')),
      getTaxCodes: () => get(url.concat('/tax-codes')),
      getTaxRateMappings: () => get(url.concat('/mapping/tax-rates')),
      updateTaxRateMappings: (body) => post(url.concat('/mapping/tax-rates'), body),
      setupTaxRates: () => post(url.concat('/setup/tax-rates')),
      projects: {
        qbo: () => get(url.concat('/projects/qbo')),
        ruddr: () => get(url.concat('/projects/ruddr')),
      },
      getProjectMappings: () => get(url.concat('/mapping/projects')),
      updateProjectMappings: (body) => post(url.concat('/mapping/projects'), body),
      preferences: {
        projectsEnabled: () => get(url.concat('/preferences/projects-enabled')),
      },
      getDepositAccounts: () => get(url.concat('/deposit-accounts')),
    };
  },
};

export default qbo;
