import { BackLink, Checkbox, ClientLink, Currency, DayPickerInput, Icon, Level, Stack, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import DateTag from '../components/DateTag';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import CurrencyDropdown from '../components/CurrencyDropdown';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import Tag from '../components/Tag';

export default function AccountsReceivableAging() {
  useDocumentTitle('Accounts Receivable Aging');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();

  const [params, setParams] = useState({
    date: null,
    currency: workspace.currency,
    includeClientsWithZeroBalance: false,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        currency: searchParamsConfig.currency,
        includeClientsWithZeroBalance: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      date: params.date ?? undefined,
      currency: params.currency ?? undefined,
      includeClientsWithZeroBalance: params.includeClientsWithZeroBalance ? 'true' : undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().accountsReceivableAging(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .accountsReceivableAging(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Accounts Receivable Aging</h1>

          <Tags>
            <DateTag label="As Of" value={params.date} onRemove={() => handleApplyFilters({ date: null })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            {params.includeClientsWithZeroBalance && <Tag>Include clients with zero balance</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Client" value={report.records.length} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column align="right">Current</Table.Column>
          <Table.Column align="right">1-30</Table.Column>
          <Table.Column align="right">31-60</Table.Column>
          <Table.Column align="right">61-90</Table.Column>
          <Table.Column align="right">91+</Table.Column>
          <Table.Column align="right">Total</Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {report.records.map((client) => {
            return (
              <Table.Row key={client.id}>
                <Table.Cell>
                  <ClientLink client={client} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client.current} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['01-30']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['31-60']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['61-90']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['91+']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client.total} currency={report.currency} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Currency value={report.total.current} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['01-30']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['31-60']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['61-90']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['91+']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeClientsWithZeroBalance}
          label="Include clients with a zero balance"
          onChange={({ target: { checked } }) => handleFilter({ includeClientsWithZeroBalance: checked })}
        />
      </Filter>

      <Filter>
        <DayPickerInput
          value={filters.date}
          placeholder="As Of"
          clearable={true}
          scope={'day'}
          onChange={(value) => handleFilter({ date: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
