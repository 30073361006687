import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dateFormats } from '~/utils';

export default function useResize({ allocation, dateWidth, canvas, onResize, onUpdate, onResizeEnd }) {
  const [event, setEvent] = useState(null);
  const [handle, setHandle] = useState(null);

  // Resize
  const [startX, setStartX] = useState(allocation.meta.coords.x);
  const [endX, setEndX] = useState(() => allocation.meta.coords.x + allocation.meta.style.width);
  const [elementOffset, setElementOffset] = useState(null);

  const { start, end } = useMemo(() => {
    const startDaysOffset =
      Math.floor((startX + dateWidth / 2) / dateWidth) - Math.floor(allocation.meta.coords.x / dateWidth);

    const endDaysOffset =
      Math.floor((endX + dateWidth / 2) / dateWidth) -
      Math.floor((allocation.meta.coords.x + allocation.meta.style.width) / dateWidth) -
      1;

    let start = moment(allocation.start).add(startDaysOffset, 'days');
    let end = moment(allocation.end).add(endDaysOffset, 'days');

    switch (allocation.unit) {
      case 'week':
        start = start.startOf('isoWeek');
        end = end.endOf('isoWeek');
        break;

      case 'month':
        start = start.startOf('month');
        end = end.endOf('month');
        break;
    }

    return {
      start: start.format(dateFormats.isoDate),
      end: end.format(dateFormats.isoDate),
    };
  }, [
    startX,
    endX,
    dateWidth,
    allocation.meta.coords.x,
    allocation.meta.style.width,
    allocation.unit,
    allocation.start,
    allocation.end,
  ]);

  // Drag & Drop
  const handleResizeStart = useCallback((event, handle) => {
    if (event.button !== 0) return;

    event.stopPropagation();

    // Store the mouse position when clicking the the draggable element
    let offset;

    switch (handle) {
      case 'start':
        offset = event.currentTarget.getBoundingClientRect().left;
        break;

      case 'end':
        offset = event.currentTarget.getBoundingClientRect().right;
        break;
    }

    setElementOffset(event.pageX - offset);
    setEvent('down');
    setHandle(handle);
  }, []);

  const handleResize = useCallback(
    (event) => {
      const container = canvas.current;

      setEvent('move');

      const coords = {
        mouse: {
          // The mouse X coordinates.
          x: event.pageX,

          // The mouse left offset relative to the clicked element.
          offset: elementOffset,
        },

        canvas: {
          // The canvas' left offset relative to the page.
          offsetLeft: container.getBoundingClientRect().left,

          // The canvas' left scrolling offset.
          scrollLeft: container.scrollLeft,
        },

        element: {
          // The X coordinates of the handle
          get x() {
            return coords.mouse.x - coords.mouse.offset - coords.canvas.offsetLeft + coords.canvas.scrollLeft;
          },
        },
      };

      switch (handle) {
        case 'start':
          setStartX(Math.min(coords.element.x, endX - dateWidth));
          break;

        case 'end':
          setEndX(Math.max(coords.element.x, startX + dateWidth));
          break;
      }

      // Update the tooltip position
      if (onResize) onResize();
    },
    [canvas, elementOffset, handle, startX, endX, dateWidth, onResize],
  );

  const handleResizeEnd = useCallback(async () => {
    setEvent(null);
    setHandle(null);
    setElementOffset(null);

    switch (event) {
      case 'move':
        {
          if (onResizeEnd) {
            onUpdate(true);
            setStartX(allocation.meta.coords.x);
            setEndX(allocation.meta.coords.x + allocation.meta.style.width);
            await onResizeEnd({ ...allocation, start: start, end: end });
            onUpdate(false);
          }
        }
        break;
    }
  }, [event, allocation, start, end, onResizeEnd, onUpdate]);

  useEffect(() => {
    setStartX(allocation.meta.coords.x);
    setEndX(allocation.meta.coords.x + allocation.meta.style.width);
  }, [allocation.meta.coords.x, allocation.meta.style.width]);

  useEffect(() => {
    if (event) {
      window.addEventListener('pointermove', handleResize);
      window.addEventListener('pointerup', handleResizeEnd);
    }

    return () => {
      window.removeEventListener('pointermove', handleResize);
      window.removeEventListener('pointerup', handleResizeEnd);
    };
  }, [event, handleResize, handleResizeEnd]);

  return { handleResizeStart, startX, endX, event, handle, start, end };
}
