import { Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useHoursFormat } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors } from '~/styles';

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function ClientHoursWidget({ client }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).clients(client.id).getClientHoursKPI();
    actions.ready({ data });
    // Intentionally passing the full "client" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, client, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!isReady}>
      <Widget.Header>
        <Widget.Title>Hours</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '14rem' }} />
      <Widget.Content style={{ minHeight: '14rem' }}>{data && <Data client={client} data={data} />}</Widget.Content>
    </Widget>
  );
}

function Data({ data, client }) {
  const hoursFormat = {
    tooltip: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
    ticks: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  };

  const chartData = {
    labels: ['Actual'],
    datasets: [],
  };

  if (!client.isInternal) {
    chartData.datasets.push({
      id: 'billable',
      // There's no option to configure the legend spacing, so adding spaces to separate each legend
      // TODO: find a way to render a custom legend to match the design more closely
      label: 'Billable        ',
      data: [data.actual.billable],
      backgroundColor: colors.success,
      maxBarThickness: 100,
    });
  }

  chartData.datasets.push({
    id: 'non-billable',
    label: 'Non-Billable        ',
    data: [data.actual.nonBillable],
    backgroundColor: colors.danger,
    maxBarThickness: 100,
  });

  const chartOptions = {
    maintainAspectRatio: false,

    layout: {
      padding: { top: 12 },
    },

    plugins: {
      legend: {
        position: 'bottom',
        onClick: null,
        labels: {
          font: {
            size: 12,
          },
          pointStyleWidth: 14,
          boxHeight: 10,
          filter: (item) => !item.text.includes('Total'),
          usePointStyle: true,
        },
      },

      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltip) => {
            let label = (tooltip.dataset.label || '').trim();
            if (label) {
              label += ': ';
            }
            label += hoursFormat.tooltip.format(tooltip.parsed.y);
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          color: colors.grey40,
        },
      },

      y: {
        stacked: true,
        grid: { display: true, color: colors.grey10 },
        border: { display: false },
        display: true,
        min: 0,
        suggestedMax: 100,
        ticks: {
          maxTicksLimit: 10,
          color: colors.grey40,
          callback: (label) => hoursFormat.ticks.format(label),
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
}

export default ClientHoursWidget;
