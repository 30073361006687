import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AutoComplete from '../AutoComplete';
import AutoCompleteMulti from '../AutoCompleteMulti';
import Dropdown from '../Dropdown';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

export default function DisciplineFilter(props) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).disciplines().get({ q, size: 1000 });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const key = v.isActive ? 'Active' : 'Inactive';
          a[key] = a[key] || { key, disciplines: [] };
          a[key].disciplines.push(v);
          return a;
        }, {}),
        'key',
      );

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', group, disabled: true });
        group.disciplines.forEach((discipline) => {
          options.push({ type: 'discipline', discipline, status: group.key, disabled: false });
        });
      }
      return options;
    },
    [workspace.id, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name: props.name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(props.value, { id: selectedValue.discipline.id })) {
      handleChange([...props.value, selectedValue.discipline]);
    }
  };

  const handleRemove = (item) => {
    handleChange(props.value.filter(({ id }) => id !== item.id));
  };
  const filterOptions = (options) => options.filter((o) => !props.value.some((v) => v.id === o.discipline?.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.discipline.name}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.id} {...props}>
          {option.name}
        </AutoCompleteMulti.Tag>
      )}
      compare={(a, b) => a.id === b.id}
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      placeholder="All"
      materialAlwaysVisible
      data-testid="discipline_filter"
      {...props}>
      {({ options }) => {
        let index = 0;
        const filteredOptions = options.filter((o) => {
          if (o.type === 'group') {
            const result = options.some((v) => v.status == o.group.key);
            return result;
          } else {
            return true;
          }
        });

        return (
          <>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((o) => {
                switch (o.type) {
                  case 'group':
                    return (
                      <Group key={o.group.key}>
                        <strong>{o.group.key}</strong>
                      </Group>
                    );
                  case 'discipline':
                    return (
                      <AutoComplete.Option
                        grey={o.discipline.isActive === false ? 'true' : undefined}
                        padding="0.25rem 0 0.25rem 2rem"
                        key={o.discipline.id}
                        value={o}
                        index={index++}>
                        {o.discipline.name}
                      </AutoComplete.Option>
                    );
                  default:
                    return null;
                }
              })
            ) : (
              <Dropdown.Text>None</Dropdown.Text>
            )}
          </>
        );
      }}
    </AutoCompleteMulti>
  );
}
