import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Button, DeleteButton, Icon } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull } from '~/utils';
import RemoveEmail from './Remove';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
  margin-right: 1rem;
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Info = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 10rem;
  margin: auto 0;
  margin-left: 0.75rem;
`;

const Email = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.125rem 0.875rem;
`;

const Tag = styled.div`
  display: flex;
  margin: auto 0.125rem;
  padding: 0.125rem;
  font-size: 0.625rem;
  background-color: ${({ color }) => color ?? colors.grey55};
  border-radius: 0.25rem;
`;

const TagLabel = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.grey55};
  background-color: ${colors.white};
  border-radius: 0.25rem;
`;

const TagValue = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;

  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

export default function Search() {
  const api = useApi();
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [removeSuppression, setRemoveSuppression] = useState();
  const searchRef = useRef();

  const search = useCallback(async () => {
    const params = { text: searchText };
    const cleanedParams = _.omitBy(emptyStringToNull(params), _.isNil);

    setIsSearching(true);
    try {
      const { data } = await api.admin.emails.get(cleanedParams);
      setSearchResults(data);
    } finally {
      setIsSearching(false);
    }
  }, [api, searchText]);

  const sync = useCallback(async () => {
    setIsSyncing(true);

    try {
      await api.admin.emails.sync();
      await search();
    } finally {
      setIsSyncing(false);
    }
  }, [api, search]);

  useEffect(() => {
    search();
    return search.cancel;
  }, [search]);

  useEffect(() => {
    if (!searchText) {
      setSearchResults();
      return;
    }
    search(searchText);
    return search.cancel;
  }, [search, searchText]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitSearchChange = useCallback(
    _.debounce((value) => setSearchText(value), 250),
    [],
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    emitSearchChange(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const handleSync = () => {
    sync();
  };

  return (
    <>
      <SearchArea>
        <SearchFilters>
          <SearchInput>
            <input
              ref={searchRef}
              value={searchValue}
              onChange={handleSearchChange}
              type="search"
              placeholder="Find a suppressed email..."
            />
            {!!searchValue && (
              <ClearButton isAnchor={true} onClick={handleClearSearch}>
                <Icon icon="times" />
              </ClearButton>
            )}
            {isSearching && (
              <SearchIndicator>
                <Icon icon="spinner" spin={true} />
              </SearchIndicator>
            )}
          </SearchInput>
          <Button isLoading={isSyncing} onClick={handleSync}>
            AWS Sync
          </Button>
        </SearchFilters>
        {searchResults && (
          <SearchResults>
            {searchResults.map((suppression) => (
              <SearchResult key={`suppression_${suppression.id}`}>
                <Info>
                  <Email>{suppression.email}</Email>
                </Info>
                <Tags>
                  <Tag>
                    <TagLabel>Blocked</TagLabel>
                    <TagValue>{moment(suppression.lastUpdated).format(dateFormats.compactDate)}</TagValue>
                  </Tag>
                  <Tag>
                    <TagLabel>Reason</TagLabel>
                    <TagValue>{suppression.reason}</TagValue>
                  </Tag>
                </Tags>
                <Actions>
                  <DeleteButton onClick={() => setRemoveSuppression(suppression)}>Remove</DeleteButton>
                </Actions>
              </SearchResult>
            ))}
          </SearchResults>
        )}
      </SearchArea>
      {removeSuppression && (
        <RemoveEmail suppression={removeSuppression} onChange={() => search()} onClose={() => setRemoveSuppression()} />
      )}
    </>
  );
}
