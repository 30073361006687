import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useEffect, useState } from 'react';
import settings from '~/settings.js';

export default function Stripe({ children }) {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (!settings.stripePubKey) return;
    setStripePromise(loadStripe(settings.stripePubKey));
  }, []);

  if (!settings.stripePubKey) return children;

  return <Elements stripe={stripePromise}>{children}</Elements>;
}
