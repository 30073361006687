import _ from 'lodash';

const calculateEntries = (entries) => ({
  billableHours: _.round(
    entries.reduce((a, v) => a + (v.billableHours || 0), 0),
    2,
  ),
  nonBillableHours: _.round(
    entries.reduce((a, v) => a + (v.nonBillableHours || 0), 0),
    2,
  ),
  revenue: _.round(
    entries.reduce((a, v) => a + (v.billableHours * v.rate || 0), 0),
    2,
  ),
});

const calculateMonthlyBudget = (project) => {
  let data;

  switch (project.monthlyBudgetMode) {
    case 'detailed':
      if (project.useRoles) {
        data = calculateEntries(
          project.roles.map((pr) => ({
            billableHours: pr.isBillable ? pr.estimatedMonthlyBillableHours : 0,
            nonBillableHours: pr.estimatedMonthlyNonBillableHours,
            rate: pr.rate,
          })),
        );
      } else {
        data = calculateEntries(
          project.members.map((pm) => ({
            billableHours: pm.isBillable ? pm.estimatedMonthlyBillableHours : 0,
            nonBillableHours: pm.estimatedMonthlyNonBillableHours,
            rate: project.isBillable && pm.isBillable ? pm.rate : null,
          })),
        );
      }
      break;

    case 'summary':
      data = {
        billableHours: project.estimatedMonthlyBillableHours,
        nonBillableHours: project.estimatedMonthlyNonBillableHours,
        revenue: project.estimatedMonthlyServiceRevenue,
      };
      break;
  }

  if (project.billingTypeId === 'fixed_recurring') data.revenue = project.estimatedMonthlyServiceRevenue;

  return {
    ...data,
    get hours() {
      return this.billableHours + this.nonBillableHours;
    },
    get effectiveRate() {
      return this.hours > 0 ? this.revenue / this.hours : null;
    },
  };
};

export default calculateMonthlyBudget;
