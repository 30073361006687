import { Checkbox, Field, Form, Radio } from '~/components';
import React from 'react';

function LineSettingsTimeTab({ formik: { values, setFieldValue } }) {
  const handleTimeFieldChange = (name) => {
    let fields;
    if (values.timeFields.some((v) => v === name)) {
      fields = values.timeFields.filter((v) => v !== name);
    } else {
      fields = [...values.timeFields, name];
    }
    setFieldValue('timeFields', fields);
  };

  return (
    <>
      <Form.Section title="General">
        <Form.Control>
          <Field.Checkbox label="Include the project name on each service invoice line" name="includeServiceProject" />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Time Grouping">
        <Form.Control>
          <Field.RadioGroup name="groupTime">
            <Radio value={true} label="Group time entries" />
            <Radio value={false} label="Show all time entries" />
          </Field.RadioGroup>
        </Form.Control>
        {values.groupTime ? (
          <div style={{ marginTop: '1rem' }}>
            Group by:
            <Form.Control style={{ marginTop: '1rem' }}>
              <Field.RadioGroup name="groupTimeBy">
                <Radio value="project" label="Project" />
                <Radio value="role" label="Project Role" />
                <Radio value="role_and_person" label="Project Role and Person" />
                <Radio value="task" label="Project Task" />
                <Radio value="task_and_person" label="Project Task and Person" />
                <Radio value="person" label="Person" />
              </Field.RadioGroup>
            </Form.Control>
            <Form.Control>
              <Field.Checkbox
                name="includeTimeDateRange"
                label="Include the time entry date range on grouped invoice line items"
              />
            </Form.Control>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            Include these fields:
            <Form.Control style={{ marginTop: '1rem' }}>
              <Checkbox
                label="Date"
                checked={values.timeFields.some((v) => v === 'date')}
                onChange={() => handleTimeFieldChange('date')}
              />
              <Checkbox
                label="Role"
                checked={values.timeFields.some((v) => v === 'role')}
                onChange={() => handleTimeFieldChange('role')}
              />
              <Checkbox
                label="Task"
                checked={values.timeFields.some((v) => v === 'task')}
                onChange={() => handleTimeFieldChange('task')}
              />
              <Checkbox
                label="Person"
                checked={values.timeFields.some((v) => v === 'person')}
                onChange={() => handleTimeFieldChange('person')}
              />
              <Checkbox
                label="Notes"
                checked={values.timeFields.some((v) => v === 'notes')}
                onChange={() => handleTimeFieldChange('notes')}
              />
            </Form.Control>
          </div>
        )}
      </Form.Section>
    </>
  );
}

export default LineSettingsTimeTab;
