import Big from 'big.js';
import { Button, Buttons, CancelButton, Drawer, Field, Form } from '~/components';
import { Formik } from 'formik';
import { useDirtyCheck } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import CreditNoteProjectSelectField from '../components/CreditNoteProjectSelectField';

const Amount = styled.div`
  display: flex;

  > div {
    width: 100%;
  }
`;

function LineDrawer({ projects, line, currency, onApply, onClose }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      amount: '',
      description: '',
      id: null,
      invoiceItem: null,
      project: null,
      quantity: '',
      rate: '',
      taxable: false,
      timeEntries: [],
      expenses: [],
      milestones: [],
      otherItems: [],
    },
    line,
  );

  const schema = Yup.object().shape({
    amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable(),
    description: Yup.string().label('Details').max(4000),
    invoiceItem: Yup.object()
      .label('Item')
      .nullable()
      .when('amount', (amount, schema) => (_.isNumber(amount) ? schema.required() : schema)),
    quantity: Yup.number().label('Quantity').min(-99999999999).max(99999999999).nullable(),
    rate: Yup.number().label('Rate').min(-99999999999).max(99999999999).nullable(),
  });

  return (
    <>
      <Drawer
        isOpen
        title="Credit Note Line Details"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(formValues) {
            onApply(formValues);
            closeDrawer();
          }

          return (
            <>
              <Formik
                innerRef={formRef}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}>
                {(formik) => {
                  const updateLine = ({
                    quantity = formik.values.quantity,
                    rate = formik.values.rate,
                    amount = formik.values.amount,
                  }) => {
                    formik.setValues({
                      ...formik.values,
                      quantity,
                      rate,
                      amount,
                    });
                  };

                  const handleFocus = (event) => {
                    event.target.previousValue = event.target.value;
                  };

                  const handleQuantityRateBlur = (event) => {
                    if (event.target.previousValue === event.target.value) return;

                    const quantity = _.isNumber(formik.values.quantity)
                      ? _.round(formik.values.quantity, 7)
                      : formik.values.quantity;
                    const rate = _.isNumber(formik.values.rate) ? _.round(formik.values.rate, 7) : formik.values.rate;

                    // If quantity is not a number, consider it as "1"
                    const amount = Big(_.isNumber(quantity) ? quantity : 1)
                      // If rate is not a number, consider it as "0"
                      .times(_.isNumber(rate) ? rate : 0)
                      .round(2)
                      .toNumber();

                    updateLine({ quantity, rate, amount });
                  };

                  const handleAmountBlur = (event) => {
                    if (event.target.previousValue === event.target.value) return;

                    const quantity = _.isNumber(formik.values.quantity) ? formik.values.quantity : 1;
                    const amount = _.isNumber(formik.values.amount) ? _.round(formik.values.amount, 2) : '';

                    let rate = formik.values.rate;
                    if (!_.isNumber(amount) || quantity === 0) rate = '';
                    else if (_.isNumber(rate)) rate = Big(amount).div(quantity).round(7).toNumber();

                    updateLine({ amount, rate });
                  };

                  return (
                    <Form>
                      <Form.Section title="Line Details">
                        <Form.Control>
                          <Field.InvoiceItemSelect name="invoiceItem" autoFocus placeholder="Item" />
                        </Form.Control>

                        <Form.Control>
                          <CreditNoteProjectSelectField name="project" placeholder="Project" options={projects} />
                        </Form.Control>

                        <Form.Control>
                          <Field.TextArea name="description" placeholder="Details" maxLength={4000} />
                        </Form.Control>

                        <Form.Control>
                          <Field.Number
                            name="quantity"
                            placeholder="Quantity"
                            min={0}
                            max={99999999999}
                            precision={2}
                            onFocus={handleFocus}
                            onBlur={handleQuantityRateBlur}
                          />

                          <Field.Currency
                            name="rate"
                            placeholder="Rate"
                            currency={currency}
                            min={0}
                            max={99999999999}
                            precision={7}
                            onFocus={handleFocus}
                            onBlur={handleQuantityRateBlur}
                          />
                        </Form.Control>

                        <Form.Control>
                          <Amount>
                            <Field.Currency
                              name="amount"
                              placeholder="Amount"
                              currency={currency}
                              min={0}
                              max={99999999999}
                              onFocus={handleFocus}
                              onBlur={handleAmountBlur}
                            />
                          </Amount>

                          <Field.Checkbox name="taxable" label="Tax" />
                        </Form.Control>
                      </Form.Section>

                      <Drawer.Actions>
                        <Buttons align="right">
                          <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                          <Button onClick={formik.submitForm}>Apply</Button>
                        </Buttons>
                      </Drawer.Actions>
                    </Form>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </Drawer>
    </>
  );
}

export default LineDrawer;
