import React from 'react';
import styled from 'styled-components';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { ResponsiveContainer } from '../../components';

const Container = styled(ResponsiveContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const Heading = styled.div`
  text-align: center;
  max-width: 39rem;
`;

const ErrorMessage = styled.div`
  max-width: 49.5rem;
  margin-top: 2rem;
  padding: 2rem;
  color: ${colors.white};
  background-color: ${colors.danger};
  border-radius: 0.625rem;

  a {
    color: ${colors.white};
    text-decoration: underline;

    &:hover {
      color: ${colors.grey10};
    }
  }
`;

export default function DeviceLoginError() {
  useDocumentTitle('Login');

  return (
    <Container>
      <Heading>
        <Title>Login</Title>
        <Byline>Login failed.</Byline>
      </Heading>
      <ErrorMessage>
        There has been a problem logging in to your Ruddr account.
        <br />
        <br />
        Send an email to <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
      </ErrorMessage>
    </Container>
  );
}
