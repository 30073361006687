import { Icon, Tooltip } from '~/components';
import React from 'react';
import { colors } from '~/styles';

export default function SocialMediaLink({ icon, url }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: colors.grey40 }}>
      <Tooltip message={url} placement="top" maxWidth="auto">
        <Icon type="fab" icon={icon} />
      </Tooltip>
    </a>
  );
}
