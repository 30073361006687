import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';
import _ from 'lodash';

const expenseItems =
  (expenseItemsUrl) =>
  (id = '') => {
    const url = _([expenseItemsUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      approvals: (query = {}) => get(url.concat('/?', new QueryString(query).toString())),
      approval: (body) => {
        return put(url.concat('/approval'), body);
      },
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      deleteReceipt: (receiptId) => del(url.concat('/receipt-image/', receiptId)),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      import: (file, reportId) => {
        return put(url.concat('/import').concat(new QueryString({ reportId }).toString(true)), file, {
          headers: { 'Content-Type': 'text/csv' },
        });
      },
      uploadReceipt: (receipt) =>
        put(url.concat('/receipt-image/', receipt.id), receipt.image, {
          headers: { 'Content-Type': receipt.contentType },
        }),
      approvalWorkflow: {
        get: () => get(url.concat('/approval-workflow')),
        post: (body) => post(url.concat('/approval-workflow'), body),
      },
    };
  };

export default expenseItems;
