import { ProjectLink, Tag as TagComponent, Tooltip } from '~/components';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

export default function ProjectTags({ client, projects }) {
  if (_.isEmpty(projects)) return null;

  return (
    <>
      <ProjectLink project={projects[0]} client={client} />

      {projects.length > 1 && (
        <Tooltip
          message={
            <div style={{ fontSize: '1rem' }}>
              <Title>Projects</Title>

              {projects.map((projects) => (
                <TagComponent style={{ backgroundColor: colors.grey5 }} key={projects.id}>
                  <small>{projects.name}</small>
                </TagComponent>
              ))}
            </div>
          }>
          <TagComponent style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
            <small>+{projects.length - 1}</small>
          </TagComponent>
        </Tooltip>
      )}
    </>
  );
}
