import React from 'react';
import { InternalClientTooltip } from '~/components';
import ClientPopover from '../../components/ClientPopover';
import { Label } from '../../components/schedule/sidebar';

export default function HeaderLabel({ row }) {
  if (row.id === 'total') return <Label>{row.label}</Label>;

  return (
    <>
      <Label>
        <ClientPopover clientId={row.client.id}>{row.client.name}</ClientPopover>
      </Label>
      {row.client?.isInternal && <InternalClientTooltip />}
    </>
  );
}
