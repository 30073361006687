import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Page } from '~/components';
import { useWorkspace } from '~/contexts';
import { ApiKeys } from './api-keys';
import AuthProviders from './auth-providers/AuthProviders';
import { Billing } from './billing';
import { CustomDataArea } from './custom-data';
import WorkspaceFeatures from './features/WorkspaceFeatures';
import { WorkspaceSettingsPage } from './general';
import { Integrations } from './integrations';
import HarvestIntegration from './integrations/HarvestIntegration';
import { BambooArea } from './integrations/bamboohr';
import CSVImportPage from './integrations/csv-import/CSVImportPage';
import ExpensifyArea from './integrations/expensify/ExpensifyArea';
import WorkspaceInvoicesForm from './invoices/WorkspaceInvoicesForm';
import { MembersPage } from './members';
import SecurityRolesListPage from './security-roles/SecurityRolesListPage';
import SalesforceSettingsArea from './integrations/salesforce/SalesforceSettingsArea';

function WorkspaceSettingsArea(props) {
  const { path, url } = useRouteMatch();
  const { workspace } = useWorkspace();

  const isBillingLocked = !workspace.billingStatus?.isValid;

  return (
    <Page>
      <Page.Section style={{ flex: 1 }}>
        {props.children || (
          <Switch>
            <Route path={`${path}`} exact>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <WorkspaceSettingsPage />}
            </Route>
            <Route path={`${path}/api-keys`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <ApiKeys />}
            </Route>
            <Route path={`${path}/authentication`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <AuthProviders />}
            </Route>
            <Route path={`${path}/custom-data`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <CustomDataArea />}
            </Route>
            <Route path={`${path}/invoices`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <WorkspaceInvoicesForm />}
            </Route>
            <Route path={`${path}/members`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <MembersPage />}
            </Route>
            <Route path={`${path}/security-roles`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <SecurityRolesListPage />}
            </Route>
            <Route path={`${path}/features`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <WorkspaceFeatures />}
            </Route>
            <Route path={`${path}/integrations/bamboohr`}>
              <BambooArea />
            </Route>
            <Route path={`${path}/integrations/harvest`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <HarvestIntegration />}
            </Route>
            <Route path={`${path}/integrations/csv`}>
              <CSVImportPage />
            </Route>
            <Route path={`${path}/integrations/expensify`}>
              <ExpensifyArea />
            </Route>
            <Route path={`${path}/integrations/salesforce`}>
              <SalesforceSettingsArea />
            </Route>
            <Route path={`${path}/integrations`}>
              {isBillingLocked ? <Redirect to={`${url}/billing`} /> : <Integrations />}
            </Route>
            <Route path={`${path}/billing`}>
              <Billing />
            </Route>
            <Route>{isBillingLocked ? <Redirect to={`${url}/billing`} /> : <Redirect to={url} />}</Route>
          </Switch>
        )}
      </Page.Section>
    </Page>
  );
}

export default WorkspaceSettingsArea;
