const defaultOptions = {
  multi: false,
};

const formatValue = (value) => {
  if (value instanceof Date) {
    value = value.toUTCString();
  }

  return value;
};

class QueryString extends URLSearchParams {
  /**
   * Constructor for QueryString
   * @param  {Object} obj         A simple JSON object that represents URL query parameters
   * @param  {Object} formatters  A simple object with format strings for types Ex: { dateFormat: 'YYYY-MM-DD'}
   */
  constructor(obj, options = {}) {
    super();
    options = { ...defaultOptions, ...options };

    const appendParameter = (key, value) => {
      this.append(key, value && value.toString());
    };

    Object.setPrototypeOf(this, QueryString.prototype);
    if (obj != null && obj.constructor.name === 'Object') {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined) {
          let value = obj[key];
          if (options.multi && value instanceof Array) {
            for (const item of value) {
              appendParameter(key, formatValue(item));
            }
          } else {
            appendParameter(key, formatValue(value));
          }
        }
      });
    }
  }

  toString(prependQueryCharacter = false) {
    return prependQueryCharacter ? `?${super.toString()}` : super.toString();
  }
}

export default QueryString;
