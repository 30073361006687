import { Button, Dropdown, Icon, InlineTooltip, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/styles';
import ClientMappings from './ClientMappings';
import ConfigurationDrawer from './ConfigurationDrawer';
import InvoiceItemMappings from './InvoiceItemMappings';
import { Page } from './StyledComponents';
import TaxRateMappings from './TaxRateMappings';

const Loading = () => <Icon icon="spinner" spin />;

const OK = () => <Icon icon="check" color={colors.success} />;

const Error = ({ message = "Something's not right. Try reconnecting to your Xero organisation." }) => (
  <Tooltip message={message}>
    <Icon icon="exclamation-circle" color={colors.danger} />
  </Tooltip>
);

const Status = ({ status, message }) => {
  switch (status) {
    case 'ready':
      return <OK />;

    case 'error':
      return <Error message={message} />;

    default:
      return <Loading />;
  }
};

const Errors = styled.ul`
  font-size: 0.75rem;
  list-style: inside;

  li {
    list-style-type: disc;
  }
`;

export default function XeroSettingsPage() {
  const { workspace } = useWorkspace();
  const api = useApi();
  const isMounted = useIsMounted();
  const toast = useToast();
  const { notify } = useSubscription();

  const location = useLocation();
  const setup = new URLSearchParams(location.search).get('action') === 'setup';
  useEffect(() => {
    if (setup) window.history.pushState(null, null, window.location.href.split('?')[0]);
  }, [setup]);

  useEffect(() => {
    if (setup) {
      notify(useSubscription.keys.integrations_changed);
    }
  }, [setup, notify]);

  const [dialog, setDialog] = useState(null);
  const closeDialog = () => {
    setDialog(null);
  };

  // Organisation
  const [organisation, setOrganisation] = useState({ data: null, status: 'loading', message: null });

  const loadOrganisation = useCallback(async () => {
    setOrganisation((state) => ({ ...state, status: 'loading' }));

    try {
      const { data } = await api.www.workspaces(workspace.id).xero.getOrganisation();

      if (!isMounted.current) return;
      setOrganisation({ data, status: 'ready', message: null });
    } catch (error) {
      setOrganisation({ data: null, status: 'error', message: error.message });
    }
  }, [api, workspace.id, isMounted]);

  useEffect(() => {
    loadOrganisation();
  }, [loadOrganisation]);

  // Configuration
  const [configuration, setConfiguration] = useState({ data: null, status: 'loading', message: null });

  const loadConfiguration = useCallback(async () => {
    setConfiguration((state) => ({ ...state, status: 'loading' }));

    try {
      const { data } = await api.www.workspaces(workspace.id).xero.getConfiguration();

      if (!isMounted.current) return;
      if (data.code === 'ready') setConfiguration({ data, status: 'ready', message: null });
      else setConfiguration({ data, status: 'error', message: 'Review the configuration settings.' });
    } catch (error) {
      setConfiguration({ data: null, status: 'error', message: error.message });
    }
  }, [api, workspace.id, isMounted]);

  const handleEditConfiguration = () => {
    setDialog('configuration');
  };

  const handleCloseConfiguration = async () => {
    await loadConfiguration();
    closeDialog();
  };

  const setupConfiguration = useCallback(async () => {
    setConfiguration((state) => ({ ...state, status: 'loading' }));
    await api.www.workspaces(workspace.id).xero.setupConfiguration();
    toast.success(`Loaded default configuration settings from Xero.`);
    await loadConfiguration();
  }, [api, workspace.id, toast, loadConfiguration]);

  useEffect(() => {
    if (setup) setupConfiguration();
    else loadConfiguration();
  }, [loadConfiguration, setupConfiguration, setup]);

  // Integration
  const [integration, setIntegration] = useState({ data: null, status: 'loading', message: null });

  const loadIntegration = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).xero.getIntegration();

    if (!isMounted.current) return;
    setIntegration({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  useEffect(() => {
    loadIntegration();
  }, [loadIntegration]);

  // Clients
  const [clients, setClients] = useState({ data: null, status: 'loading', message: null });

  const loadClients = useCallback(async () => {
    setClients((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).xero.getClientMappings();

    if (!isMounted.current) return;
    setClients({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const handleEditClients = () => {
    setDialog('clients');
  };

  const handleCloseClients = async () => {
    await loadClients();
    closeDialog();
  };

  const setupClients = useCallback(async () => {
    setClients((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).xero.setupClients();
    toast.success(`Loaded ${data.length} Contacts from Xero.`);
    await loadClients();
  }, [api, workspace.id, toast, loadClients]);

  useEffect(() => {
    if (setup) setupClients();
    else loadClients();
  }, [loadClients, setupClients, setup]);

  // Invoice Items
  const [invoiceItems, setInvoiceItems] = useState({ data: null, status: 'loading', message: null });

  const loadInvoiceItems = useCallback(async () => {
    setInvoiceItems((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).xero.getInvoiceItemMappings();

    if (!isMounted.current) return;
    setInvoiceItems({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const handleEditInvoiceItems = () => {
    setDialog('invoice_items');
  };

  const handleCloseInvoiceItems = async () => {
    await loadInvoiceItems();
    closeDialog();
  };

  const setupInvoiceItems = useCallback(async () => {
    setInvoiceItems((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).xero.setupInvoiceItems();
    toast.success(`Loaded ${data.length} Products/Services from Xero.`);
    await loadInvoiceItems();
  }, [api, workspace.id, toast, loadInvoiceItems]);

  useEffect(() => {
    if (setup) setupInvoiceItems();
    else loadInvoiceItems();
  }, [loadInvoiceItems, setupInvoiceItems, setup]);

  // Tax Rates
  const [taxRates, setTaxRates] = useState({ data: null, status: 'loading', message: null });

  const loadTaxRates = useCallback(async () => {
    setTaxRates((state) => ({ ...state, status: 'loading' }));

    const { data } = await api.www.workspaces(workspace.id).xero.getTaxRateMappings();

    if (!isMounted.current) return;
    setTaxRates({ data, status: 'ready', message: null });
  }, [api, workspace.id, isMounted]);

  const handleEditTaxRates = () => {
    setDialog('tax_rates');
  };

  const handleCloseTaxRates = async () => {
    await loadTaxRates();
    closeDialog();
  };

  const setupTaxRates = useCallback(async () => {
    setTaxRates((state) => ({ ...state, status: 'loading' }));
    const { data } = await api.www.workspaces(workspace.id).xero.setupTaxRates();
    toast.success(`Loaded ${data.length} Tax Rates from Xero.`);
    await loadTaxRates();
  }, [api, workspace.id, toast, loadTaxRates]);

  useEffect(() => {
    if (setup) setupTaxRates();
    else loadTaxRates();
  }, [loadTaxRates, setupTaxRates, setup]);

  const disabled = organisation.status !== 'ready';

  return (
    <>
      <Page>
        <Table>
          <Table.Header>
            <Table.Column width="12rem">Category</Table.Column>
            <Table.Column width="8rem" align="center">
              Status
            </Table.Column>
            <Table.Column>Details</Table.Column>
            <Table.BoxActionsColumn />
          </Table.Header>

          <Table.Body>
            <Table.BoxRow>
              <Table.Cell>
                <strong>Organisation</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={organisation.status} message={organisation.message} />
              </Table.Cell>
              <Table.Cell>
                {organisation.status === 'ready' && organisation.data.name}

                {organisation.status === 'error' && integration.status === 'ready' && (
                  <a href={integration.data.authorizeUri}>Fix Connection</a>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <Button
                  disabled={integration.status !== 'ready'}
                  onClick={() => {
                    window.location = integration.data.authorizeUri;
                  }}>
                  <Icon icon="wrench" />
                  <InlineTooltip message="Reauthorize the Xero connection." placement="left" />
                </Button>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Configuration</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={configuration.status} message={configuration.message} />
              </Table.Cell>
              <Table.Cell>
                {configuration.status !== 'loading' &&
                  (configuration.data?.code === 'ready' ? (
                    'Ready'
                  ) : (
                    <Errors>
                      {configuration.data?.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </Errors>
                  ))}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditConfiguration} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditConfiguration()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupConfiguration()}>
                          Load from Xero
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Clients</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={clients.status} message={clients.message} />
              </Table.Cell>
              <Table.Cell>
                {clients.data && (
                  <>
                    {clients.data.filter((client) => !!client.xeroContactId).length} of {clients.data.length} connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditClients} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditClients()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupClients()}>Load from Xero</Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Invoice Items</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={invoiceItems.status} message={invoiceItems.message} />
              </Table.Cell>
              <Table.Cell>
                {invoiceItems.data && (
                  <>
                    {invoiceItems.data.filter((invoiceItem) => !!invoiceItem.xeroItemId).length} of{' '}
                    {invoiceItems.data.length} connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditInvoiceItems} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditInvoiceItems()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupInvoiceItems()}>
                          Load from Xero
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>

            <Table.BoxRow>
              <Table.Cell>
                <strong>Tax Rates</strong>
              </Table.Cell>
              <Table.Cell>
                <Status status={taxRates.status} message={taxRates.message} />
              </Table.Cell>
              <Table.Cell>
                {taxRates.data && (
                  <>
                    {taxRates.data.filter((taxRates) => !!taxRates.xeroTaxType).length} of {taxRates.data.length}{' '}
                    connected
                  </>
                )}
              </Table.Cell>

              <TableBoxRowActions>
                <TableBoxRowActions.Edit disabled={disabled} onClick={handleEditTaxRates} />

                <hr />

                <TableBoxRowActions.Dropdown disabled={disabled}>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <Dropdown.Item onClick={() => setIsOpen(false) || handleEditTaxRates()}>
                          Edit Mappings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsOpen(false) || setupTaxRates()}>
                          Load from Xero
                        </Dropdown.Item>
                      </>
                    );
                  }}
                </TableBoxRowActions.Dropdown>
              </TableBoxRowActions>
            </Table.BoxRow>
          </Table.Body>
        </Table>
      </Page>

      {dialog &&
        {
          configuration: () => <ConfigurationDrawer onClose={handleCloseConfiguration} />,
          clients: () => <ClientMappings onClose={handleCloseClients} />,
          invoice_items: () => <InvoiceItemMappings onClose={handleCloseInvoiceItems} />,
          tax_rates: () => <TaxRateMappings onClose={handleCloseTaxRates} />,
        }[dialog]()}
    </>
  );
}
