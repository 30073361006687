import { Avatar, BackLink, DateTime, Icon, Level, MemberContactPopover, RouteLink, Stack, Table } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

function MembersList() {
  useDocumentTitle('Members');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const memberFilters = useMemberFilters(() => ({
    memberStatus: { ...searchParamsConfig.memberStatus, default: 'active' },
  }));

  const [params, setParams] = useState({
    securityRoles: [],
    sort: new QuerySort('member.name', 'asc'),
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        securityRoles: searchParamsConfig.securityRoles,
        sort: { default: new QuerySort('member.name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      securityRoleId: params.securityRoles?.map((v) => v.id),
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().membersList(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .membersList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

        <Stack>
          <h1>Members</h1>

          <Tags>
            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const auth = useAuth();
  const { workspace } = useWorkspace();

  const report = query.data;

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <TotalResults>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Member" value={report.records.length} />
      </TotalResults>

      <Table>
        <Table.Header sticky>
          <Table.Column name="member.name" onSort={onSort} sort={params.sort}>
            Name
          </Table.Column>
          <Table.Column name="employmentTypeId" width="7.5rem" onSort={onSort} sort={params.sort}>
            Type
          </Table.Column>
          <Table.Column name="isBillable" width="7.5rem" onSort={onSort} sort={params.sort}>
            Billability
          </Table.Column>
          <Table.Column name="securityRole.name" width="13rem" onSort={onSort} sort={params.sort}>
            Security Role
          </Table.Column>
          <Table.Column name="isActive" width="6rem" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column name="manager:member.name" onSort={onSort} sort={params.sort}>
            Manager
          </Table.Column>
          <Table.Column name="accessedAt" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Last Active
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.status === 'sorting'}>
          {report.records.map((member) => {
            return (
              <Table.Row key={member.id}>
                <Table.Cell>
                  <Member>
                    <MemberContactPopover member={member} placement="right">
                      <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <MemberInfo>
                      {auth.workspace.manage ? (
                        <RouteLink to={`/app/${workspace.key}/settings/members/details/${member.id}`}>
                          {member.name}
                        </RouteLink>
                      ) : (
                        member.name
                      )}
                    </MemberInfo>
                  </Member>
                </Table.Cell>
                <Table.Cell>{member.employmentType?.name}</Table.Cell>
                <Table.Cell>{member.isBillable ? 'Billable' : 'Non-Billable'}</Table.Cell>
                <Table.Cell>{member.securityRole.name}</Table.Cell>
                <Table.Cell>{member.isActive ? 'Active' : 'Inactive'}</Table.Cell>
                <Table.Cell>
                  {member.manager && (
                    <Member>
                      <MemberContactPopover member={member.manager} placement="left">
                        <Avatar value={member.manager} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>
                      <MemberInfo>
                        {auth.workspace.manage ? (
                          <RouteLink to={`/app/${workspace.key}/settings/members/details/${member.manager.id}`}>
                            {member.manager.name}
                          </RouteLink>
                        ) : (
                          member.manager.name
                        )}
                      </MemberInfo>
                    </Member>
                  )}
                </Table.Cell>
                <Table.Cell>{member.accessedAt ? <DateTime value={member.accessedAt} /> : 'Never'}</Table.Cell>
              </Table.Row>
            );
          })}
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}

export default MembersList;
