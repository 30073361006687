import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

export const ChartContainer = styled.div`
  padding: 1rem 1rem;
  border-radius: 5px;
  height: 20rem;
  max-height: 20rem;

  canvas {
    /* Force width to prevent shrink/resize issue */
    width: 100% !important;
    max-height: 40rem;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Value = styled.div`
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Boxes = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  margin: 0 -1.25rem -1.25rem -1.25rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${colors.primary};
      }
    `}

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }

  a {
    display: inline-block;
  }
`;

export const UpperBox = styled.div`
  padding: 1.75rem 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const SmallBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }

  ${Value} {
    font-size: 0.75rem;
  }
`;

export const Metric = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  padding: 0.75rem 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.grey10};
  }

  color: ${({ danger = false, status }) => {
    switch (status) {
      case 'success':
        return colors.success;
      case 'warning':
        return colors.warning;
      case 'danger':
        return colors.danger;
      default:
        return danger ? colors.danger : colors.grey75;
    }
  }};
  background-color: ${({ danger = false, status }) => {
    switch (status) {
      case 'success':
        return colors.success10;
      case 'warning':
        return colors.warning10;
      case 'danger':
        return colors.danger5;
      default:
        return danger ? colors.danger5 : colors.white;
    }
  }};
`;

export const LowerBoxes = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  width: 100%;
`;

export const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

export const StyledPercent = styled.div`
  font-size: small;
  font-weight: ${weights.light};
`;
