import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { combineNames, emptyStringToNull } from '~/utils';

import * as Yup from 'yup';

export default function ContactCreateForm({ clientId, companyId, opportunityId, onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ...values,
        name: combineNames(values.firstName, values.lastName),
      });

      const { data } = await api.www.workspaces(workspace.id).contacts().create(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    clientId: clientId ?? undefined,
    companyId: companyId ?? undefined,
    opportunityId: opportunityId ?? undefined,
    email: '',
    firstName: '',
    lastName: '',
    name: '',
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <>
      <ModalCard title="New Contact" onClose={handleClose}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            email: Yup.string().label('Email Address').email().max(255).required(),
            firstName: Yup.string().label('First Name').max(127).required(),
            lastName: Yup.string().label('Last Name').max(127).required(),
          })}>
          {(formik) => {
            return (
              <Form>
                <ModalCard.Body>
                  <>
                    <Form.Control>
                      <Field.Text ref={firstFieldRef} name="firstName" placeholder="First Name" maxLength={127} />
                      <Field.Text name="lastName" placeholder="Last Name" maxLength={127} />
                    </Form.Control>
                    <Form.Control>
                      <Field.Text
                        name="name"
                        placeholder="Full Name"
                        maxLength={255}
                        disabled
                        value={
                          formik.values.firstName && formik.values.lastName
                            ? combineNames(formik.values.firstName, formik.values.lastName)
                            : ''
                        }
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.Text name="email" placeholder="Email Address" type="email" />
                    </Form.Control>
                  </>
                  {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={handleClose}>Close</CancelButton>
                    <Button onClick={formik.submitForm} isLoading={isSubmitting}>
                      Save
                    </Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </>
  );
}
