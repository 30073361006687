import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import WorkspaceReportsPage from './WorkspaceReportsPage';
import ClientContacts from './ClientContacts';
import ClientsList from './ClientsList';
import History from './History';
import MembersList from './MembersList';
import ProjectMembers from './ProjectMembers';
import ProjectsList from './ProjectsList';
import ProjectTasksList from './ProjectTasksList';
import ProjectHealthByProject from './ProjectHealthByProject';

function WorkspaceReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();
  const { search: searchParams } = useLocation();

  return (
    <Switch>
      <Route path={path} exact>
        <WorkspaceReportsPage />
      </Route>

      <Route path={path.concat('/members')}>
        {auth.workspace.manage ? <MembersList /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>
      <Redirect path={path.concat('/members-list')} to={path.concat(`/members${searchParams}`)} />

      <Route path={path.concat('/clients')}>
        <ClientsList />
      </Route>
      <Redirect path={path.concat('/clients-list')} to={path.concat(`/clients${searchParams}`)} />

      <Route path={path.concat('/client-contacts')}>
        <ClientContacts />
      </Route>

      <Route path={path.concat('/history')}>
        {auth.workspace.manage ? <History /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/projects')}>
        <ProjectsList />
      </Route>
      <Redirect path={path.concat('/projects-list')} to={path.concat(`/projects${searchParams}`)} />

      <Route path={path.concat('/project-tasks')}>
        <ProjectTasksList />
      </Route>
      <Redirect path={path.concat('/project-tasks-list')} to={path.concat(`/project-tasks${searchParams}`)} />

      <Route path={path.concat('/project-health-by-project')}>
        {auth.reports.projectHealthByProject ? <ProjectHealthByProject /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/project-team-members')}>
        <ProjectMembers />
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}

export default WorkspaceReportsArea;
