import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  Icon,
  Level,
  PeriodFilter,
  ProjectLink,
  SingleSelect,
  Stack,
  Table,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { weights } from '~/styles';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tag from '../components/Tag';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import { intervals, intervalsByScope } from './intervals';

const Byline = styled.small`
  display: block;
`;

export default function ServicesRevenueForecastByClientAndProject() {
  useDocumentTitle('Services Revenue Forecast by Client and Project');
  const { workspace } = useWorkspace();
  const api = useApi();

  const searchParamsConfig = useSearchParamsConfig();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
  }));

  const [params, setParams] = useState({
    period: null,
    unit: 'month',
    currency: workspace.currency,
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervals.next_6_months,
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['day', 'week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'day':
                  return intervals.next_30_days;

                case 'week':
                  return intervals.next_12_weeks;

                case 'month':
                  return intervals.next_6_months;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },
        currency: searchParamsConfig.currency,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,
      currency: params.currency ?? undefined,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().servicesRevenueForecastByProject(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .servicesRevenueForecastByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/forecast`} />

        <Stack>
          <h1>Services Revenue Forecast by Client and Project</h1>

          <Tags>
            <PeriodNavTag
              value={params.period}
              intervals={intervalsByScope[params.unit]}
              onChange={(period) => handleApplyFilters({ period })}
            />

            <Tag label="Unit" value={{ month: 'Month', week: 'Week', day: 'Day' }[params.unit]} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();

  const { clientsById, projectsById, revenuePeriods, periods } = useMemo(() => {
    const clientsById = _(report.projects)
      .map((p) => p.client)
      .keyBy('id')
      .value();

    const projectsById = _.keyBy(report.projects, 'id');

    const revenuePeriods = _.orderBy(report.periods, (a) => [
      projectsById[a.projectId].client.name.toLowerCase(),
      projectsById[a.projectId].name.toLowerCase(),
    ]).reduce(
      (a, v) => {
        a.total += v.convertedRevenue;

        const project = projectsById[v.projectId];
        const client = project.client;

        // Clients
        a.clients[client.id] = a.clients[client.id] || { total: 0, periods: {}, projects: {} };
        a.clients[client.id].total += v.convertedRevenue;
        if (!a.clients[client.id].periods[v.start]) a.clients[client.id].periods[v.start] = 0;
        a.clients[client.id].periods[v.start] += v.convertedRevenue;
        a.clients[client.id].projects[v.projectId] = a.clients[client.id].projects[v.projectId] || {
          total: 0,
          convertedTotal: 0,
          periods: {},
        };

        // Projects
        a.clients[client.id].projects[v.projectId].total += v.revenue;
        a.clients[client.id].projects[v.projectId].convertedTotal += v.convertedRevenue;
        a.clients[client.id].projects[v.projectId].periods[v.start] = {
          revenue: v.revenue,
          convertedRevenue: v.convertedRevenue,
        };

        // Periods
        if (!a.periods[v.start]) a.periods[v.start] = 0;
        a.periods[v.start] += v.convertedRevenue;

        return a;
      },
      { clients: {}, periods: {}, total: 0 },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { clientsById, projectsById, revenuePeriods, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Client" value={_.size(clientsById)} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table style={{ position: 'absolute', minWidth: 'calc(100% - 4rem)' }}>
        <Table.Header sticky>
          <Table.Column width="16rem">Client</Table.Column>

          {periods.map((p) => (
            <Table.Column key={p} align="right" width="10rem">
              <DateTime value={p} />
            </Table.Column>
          ))}

          <Table.Column align="right" width="10rem">
            Total
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {_.map(revenuePeriods.clients, (clientRevenue, clientId) => {
            const client = clientsById[clientId];

            return (
              <React.Fragment key={client.id}>
                <GroupRow top="2.95rem" style={{ fontWeight: weights.bold }}>
                  <Table.Cell>
                    <ClientLink client={client} />
                  </Table.Cell>

                  {periods.map((period) => {
                    const revenue = clientRevenue.periods[period];

                    return (
                      <Table.Cell key={period}>
                        <Currency value={revenue} currency={report.currency} />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <Currency value={clientRevenue.total} currency={report.currency} />
                  </Table.Cell>
                </GroupRow>

                {_.map(clientRevenue.projects, (projectRevenue, projectId) => {
                  const project = projectsById[projectId];

                  return (
                    <Table.Row key={projectId}>
                      <Table.Cell>
                        <p style={{ marginLeft: '1rem' }}>
                          <ProjectLink project={project} />
                        </p>
                      </Table.Cell>

                      {periods.map((period) => {
                        const { revenue, convertedRevenue } = projectRevenue.periods[period] || {};

                        return (
                          <Table.Cell key={period}>
                            <p>
                              <Currency value={convertedRevenue} currency={report.currency} />
                              {project.currency !== report.currency && (
                                <Byline>
                                  <Currency value={revenue} currency={project.currency} />
                                </Byline>
                              )}
                            </p>
                          </Table.Cell>
                        );
                      })}

                      <Table.Cell>
                        <p>
                          <Currency value={projectRevenue.convertedTotal} currency={report.currency} />
                          {project.currency !== report.currency && (
                            <Byline>
                              <Currency value={projectRevenue.total} currency={project.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            {periods.map((period) => {
              const revenue = revenuePeriods.periods[period];

              return (
                <Table.Cell key={period}>
                  <Currency value={revenue} currency={report.currency} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <Currency value={revenuePeriods.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
