import styled from 'styled-components';
import { colors, weights } from '~/styles/index.js';
import React from 'react';

const List = styled.div`
  max-width: 30rem;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.div`
  width: 11.5rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const Value = styled.div`
  white-space: nowrap;
  text-align: right;
  flex-grow: 1;
`;

function ListItem({ label, children }) {
  return (
    <Item>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </Item>
  );
}

List.Item = ListItem;

export default List;
