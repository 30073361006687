import { useField, useFormikContext } from 'formik';
import { useCurrencyFormat, useLocale } from '~/hooks';
import React from 'react';
import FieldControl from './FieldControl';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

const CurrencyInput = React.forwardRef(({ locale, currency, ...props }, ref) => {
  locale = useLocale(locale);

  const symbol = useCurrencyFormat({ locale, currency })
    .formatToParts(1)
    .find((part) => part.type === 'currency').value;

  return <TextInput ref={ref} component={NumberInput} locale={locale} precision={2} prefix={symbol} {...props} />;
});

const FieldCurrency = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = (value) => {
    formik.setFieldValue(field.name, value);
    if (error) formik.validateField(field.name);
  };

  return (
    <FieldControl error={error}>
      <CurrencyInput ref={ref} {...field} onChange={handleChange} {...props} />
    </FieldControl>
  );
});

export default CurrencyInput;
export { FieldCurrency };
