import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useField } from '~/hooks';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

const initialState = {
  options: [],
  isReady: false,
  isDefaultReady: false,
};

const handlers = {
  ready: (options) => ({ options, isReady: true }),
  defaultReady: () => ({ isDefaultReady: true }),
};

function SecurityRoleSelect({ initialValue, useDefault = false, onDefault, ...props }) {
  const { name, value, onChange } = props;
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ options, isReady, isDefaultReady }, actions] = useActions(handlers, initialState);

  useEffect(() => {
    (async () => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .securityRoles()
        .get({ isActive: true, include: initialValue ? initialValue.id : undefined });
      actions.ready(data);
    })();
  }, [actions, workspace.id, api, initialValue]);

  useEffect(() => {
    if (useDefault && isReady && !isDefaultReady) {
      const defaultRole = _.find(options, { isDefault: true });
      if (defaultRole) {
        if (_.isFunction(onDefault)) {
          onDefault(defaultRole.id);
        }
        if (_.isFunction(onChange)) {
          onChange({ target: { name, value: defaultRole.id } });
        }
      }
      actions.defaultReady();
    }
  }, [isReady, isDefaultReady, useDefault, options, name, onChange, onDefault, actions]);

  const text = useMemo(() => _.find(options, { id: value })?.name, [options, value]);

  return (
    <SingleSelect disabled={!isReady} valueRenderer={text} {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldSecurityRoleSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <SecurityRoleSelect {...field} {...props} />
    </FieldControl>
  );
}

export default SecurityRoleSelect;
export { FieldSecurityRoleSelect };
