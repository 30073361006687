import { ActionButton, Buttons, CancelButton, ModalCard } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React from 'react';

function SavePaymentToQuickBooksDialog({ payment, resolve }) {
  const { workspace } = useWorkspace();
  const [{ isSubmitting, saved }, form] = useForm();
  const toast = useToast();
  const api = useApi();

  const handleSubmit = async () => {
    form.submit();

    try {
      await api.www.workspaces(workspace.id).clientPayments(payment.id).saveToQuickBooks();
      await resolve();
      toast.success(`Payment has been saved to QuickBooks.`);
    } catch (error) {
      toast.error(
        error.message ??
          'An error has occurred. The payment has not been saved to QuickBooks. Please review the QuickBooks integration settings and try again.',
      );
      form.done();
    }
  };

  return (
    <ModalCard title="Save to QuickBooks" onClose={() => resolve(false)}>
      <ModalCard.Body>Are you sure you want to save this payment to QuickBooks?</ModalCard.Body>

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSubmit}>
            Save
          </ActionButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default SavePaymentToQuickBooksDialog;
