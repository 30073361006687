import { useDocumentTitle, useFeatures } from '~/hooks';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import NavBox from '../components/NavBox';

function UtilizationReportsPage() {
  useDocumentTitle('Utilization Reports');
  const { url } = useRouteMatch();
  const features = useFeatures();

  return (
    <LandingPage>
      <NavBox to={url.concat('/utilization-by-time-unit')}>
        <NavBox.Title>Utilization by Time Unit</NavBox.Title>
        <NavBox.Description>
          Utilization by month or week, including the total billable hours, client hours, productive hours and total
          capacity for each period.
        </NavBox.Description>
      </NavBox>

      <NavBox to={url.concat('/utilization-by-member')}>
        <NavBox.Title>Utilization by Member</NavBox.Title>
        <NavBox.Description>
          Utilization for each workspace member, including each member's billable hours and capacity.
        </NavBox.Description>
      </NavBox>

      {features.practices && (
        <NavBox to={url.concat('/utilization-by-member-practice')}>
          <NavBox.Title>Utilization by Member Practice</NavBox.Title>
          <NavBox.Description>
            Utilization for each workspace member practice, including each member's billable hours and capacity.
          </NavBox.Description>
        </NavBox>
      )}
    </LandingPage>
  );
}

export default UtilizationReportsPage;
