import { useField } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import FieldControl from './FieldControl';

const Select = React.forwardRef(({ placeholder, showEmptyOption, children, ...props }, ref) => {
  // Currently, `required` is needed in order for the placeholder to show up properly with an `:invalid` state
  return (
    <select required {...props} ref={ref}>
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {showEmptyOption && <option value="" />}
      {children}
    </select>
  );
});

const SelectControl = styled(Select)`
  && {
    border-color: ${({ error }) => (error ? colors.danger : colors.grey10)};
  }
`;

function FieldSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <SelectControl {...field} {...props} />
    </FieldControl>
  );
}

export default Select;
export { FieldSelect };
