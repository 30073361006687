/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import HelpTooltip from './HelpTooltip';

function Form(props) {
  return <FormikForm noValidate {...props} />;
}

function FormHeading(props) {
  return <h3 className="subtitle is-3" {...props} />;
}

function FormSubheading(props) {
  return (
    <>
      <Subheading {...props} />
      <hr />
    </>
  );
}

const Subheading = styled.h5`
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  + hr {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    background-color: ${colors.grey10};
  }
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0;
  position: relative;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  margin-bottom: -2.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: ${colors.white};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    box-shadow: 6px -10px 6px -6px ${colors.grey10};
    height: 18px;
    top: 0;
    width: 100%;
    left: -2rem;
    width: calc(100% + 2rem);
  }
`;

const Section = styled.div`
  display: flex;
  padding: 2.5rem 0;
  border-top: solid 1px ${colors.grey10};

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding-right: 3rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const SectionSubtitle = styled.div`
  font-size: 0.75rem;
  font-weight: ${weights.normal};
  text-transform: none;
  color: ${colors.black};
  margin-top: 1rem;
  font-style: italic;
  letter-spacing: 0;
`;

const SectionTooltip = styled.div`
  margin-top: 0.25rem;
`;

const SectionContent = styled.div`
  flex: 1;
`;

function FormSection({ children, title, subtitle, help, ...props }) {
  return (
    <Section {...props}>
      <SectionTitle>
        {title}
        {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        {help && (
          <SectionTooltip>
            <HelpTooltip message={help} />
          </SectionTooltip>
        )}
      </SectionTitle>
      <SectionContent>{children}</SectionContent>
    </Section>
  );
}

export const ControlTooltip = styled.div`
  position: absolute;
  top: 0.75rem;
  right: calc(100% + 1rem);
`;

export const ControlWrapper = styled.div`
  position: relative;
  padding: 0.75rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:first-child) {
    ${ControlTooltip} {
      top: 1.5rem;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Controls = styled.div`
  display: flex;

  > * {
    flex: 1;
    padding: 0 0.75rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

function FormControl({ help, isVisible = true, children, ...props }) {
  return isVisible ? (
    <ControlWrapper>
      {help && (
        <ControlTooltip>
          <HelpTooltip message={help} />
        </ControlTooltip>
      )}
      <Controls {...props}>{children}</Controls>
    </ControlWrapper>
  ) : (
    children
  );
}

Form.Heading = FormHeading;
Form.Subheading = FormSubheading;
Form.Actions = FormActions;
Form.Section = FormSection;
Form.Control = FormControl;

export default styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export { Section, SectionTitle };
