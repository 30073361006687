import { SingleSelect } from '~/components';
import React from 'react';

function ExpenseReimbursableTypeFilter(props) {
  return (
    <SingleSelect
      placeholder="All"
      materialPlaceholder="Reimbursable Type"
      materialAlwaysVisible
      showEmptyOption
      {...props}>
      <option value="reimbursable">Reimbursable</option>
      <option value="non_reimbursable">Non-Reimbursable</option>
    </SingleSelect>
  );
}

export default ExpenseReimbursableTypeFilter;
