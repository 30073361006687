import { QueryString } from '~/utils';
import { get, post, patch, del } from './agent';
import _ from 'lodash';

const timeAdmin =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'time-admin', id]).compact().join('/');

    return {
      getApprovals: (query = {}) => get(url.concat('/approvals', new QueryString(query).toString(true))),
      getPendingApprovalsCount: (query = {}) =>
        get(url.concat('/pending-approvals-count', new QueryString(query).toString(true))),
      batchUpdateStatus: (body) => post(url.concat('/approvals/batch'), body),
      setStatus: (body) => post(url.concat('/approvals/set-status'), body),
      getAuditing: (query = {}) => get(url.concat('/auditing', new QueryString(query).toString(true))),
      exportAuditing: (query = {}, headers = {}) =>
        get(url.concat('/auditing/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      update: (body) => patch(url, body),
      delete: () => del(url),
    };
  };

export default timeAdmin;
