import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getInitials } from '~/utils';
import { colors } from '~/styles';
import Icon from './Icon';

const Div = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  figure {
    display: flex;
    border-radius: 4px;

    &.has-background {
      background-color: ${colors.grey25};
    }

    &.is-circle {
      border-radius: 50%;

      img {
        border-radius: 50%;
      }
    }

    + span {
      margin-left: 0.5rem;
    }

    img {
      object-fit: cover;
      max-height: unset;
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }

    &.initials {
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      user-select: none;

      &.is-circle {
        border-radius: 50%;
      }

      span {
        text-align: center;
        font-weight: bold;
        color: ${colors.grey40};
      }

      &.has-background {
        span {
          color: ${colors.white};
        }
      }
    }

    &.has-border {
      border: 4px solid ${colors.grey5};
    }
  }
`;

function Avatar({
  value,
  size = 32,
  fontSize = `${size / 32}rem`,
  initialsFontSize = fontSize,
  showName = false,
  isCircle,
  hasBackground,
  hasBorder,
  className,
}) {
  const initials = getInitials(value.name);

  return (
    <Div className={className}>
      <figure
        className={classNames('avatar image', {
          initials: !value.imageUrl,
          'is-circle': isCircle,
          'has-background': hasBackground,
          'has-border': hasBorder,
        })}
        style={{ width: size, height: size }}>
        {value.imageUrl ? (
          <img src={value.imageUrl} alt={value.name} />
        ) : value.icon ? (
          <Icon icon={value.icon} />
        ) : (
          <span style={{ fontSize: initialsFontSize }}>{initials}</span>
        )}
      </figure>
      {showName && <span style={{ fontSize }}>{value.name}</span>}
    </Div>
  );
}

export default Avatar;
