import { Confirmation, Currency, DateTime, ExpenseLockIcon, Icon, Table, Tooltip } from '~/components';
import { useConfirmation } from '~/contexts';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { colors } from '~/styles';
import {
  Cell,
  DeleteButton,
  DeleteCell,
  Details,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  ProjectList,
  RejectionReason,
  Row,
  ToggleSwitch,
} from '../components/ListComponents';

export default function ExpensesTab({ clientApproval, onExpenseItemClick, onDelete }) {
  const confirmation = useConfirmation();

  const groups = useMemo(() => {
    return _.orderBy(
      clientApproval.expenseItems.reduce((a, v) => {
        a[v.member.id] = a[v.member.id] || { member: v.member, items: [] };
        a[v.member.id].items.push(v);
        return a;
      }, {}),
      'member.name',
    );
  }, [clientApproval.expenseItems]);

  const handleDelete = async (entries) => {
    await confirmation.prompt((resolve) => (
      <Confirmation
        title="Remove Expenses"
        resolve={async (result) => {
          if (result) await onDelete(entries);
          resolve(result);
        }}>
        Are you sure you want to remove{' '}
        {entries.length === 1 ? 'this expense item' : `these ${entries.length} expense items`} from the client approval?
      </Confirmation>
    ));
  };

  const currency = clientApproval.project.currency;

  return (
    <ProjectList>
      <Table.Total value={clientApproval.expenseItems.length} label="Expense Item" />

      <ListHeader>
        <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem" />

        <Cell padding="0.875rem 1rem">Member</Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Pending
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Client Approved
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Client Rejected
        </Cell>

        <Cell right $width="8rem" padding="0.875rem 1rem">
          Total
        </Cell>

        <Cell $width="4rem" padding="0.875rem 1rem" />

        <Cell $width="calc(3.25rem + 1px)" padding="0.875rem 1rem" />
      </ListHeader>

      {clientApproval.expenseItems.length === 0 && (
        <NoResultsCell>There are no expense items on this client approval.</NoResultsCell>
      )}

      {_.map(groups, (group, key) => {
        const handleDeleteGroup = (e) => {
          e.stopPropagation();
          handleDelete(group.items.map((e) => e.id));
        };

        return (
          <Group
            key={key}
            group={group}
            status={clientApproval.statusId}
            currency={currency}
            onDelete={onDelete && handleDeleteGroup}>
            {group.items.map((item) => {
              const handleRowClick = () => {
                onExpenseItemClick(item);
              };

              const handleDeleteItem = () => {
                handleDelete([item.id]);
              };

              return (
                <Row key={item.id} clickable onClick={handleRowClick} data-testid="row">
                  <Cell $width="2.875rem" padding="0 0 0 1.25rem">
                    <ExpenseLockIcon value={item.lockStatusId} />
                  </Cell>

                  <Cell $width="8rem">
                    <DateTime value={item.date} />
                  </Cell>

                  <Cell $width="12rem">{item.expenseCategory && <p>{item.expenseCategory.name}</p>}</Cell>

                  <Cell>
                    <Details>
                      {item.vendorName && <p>{item.vendorName}</p>}

                      <Notes>{item.notes ? <em>"{item.notes}"</em> : 'No notes provided'}</Notes>

                      {item.clientStatusId === 'rejected' && item.clientRejectionReason && (
                        <RejectionReason>{item.clientRejectionReason}</RejectionReason>
                      )}
                    </Details>
                  </Cell>

                  <Cell right $width="8rem">
                    <Currency value={item.expenseAmount} currency={currency} />
                  </Cell>

                  <Cell right $width="4rem">
                    {clientApproval.statusId === 'submitted' &&
                      {
                        approved: (
                          <Tooltip message="Client Approved">
                            <Icon icon="thumbs-up" color={colors.primary50} />
                          </Tooltip>
                        ),
                        rejected: (
                          <Tooltip message="Client Rejected">
                            <Icon icon="thumbs-down" color={colors.danger50} />
                          </Tooltip>
                        ),
                      }[item.clientStatusId]}
                  </Cell>

                  <DeleteCell>
                    <DeleteButton onClick={handleDeleteItem}>
                      <Icon icon="trash" />
                    </DeleteButton>
                  </DeleteCell>
                </Row>
              );
            })}
          </Group>
        );
      })}
    </ProjectList>
  );
}

const filter = (status) => (item) => item.clientStatusId === status;
const sum = (item) => _.round(item.expenseAmount, 2);

function Group({ group, status, children, currency, onDelete }) {
  const [collapsed, setCollapsed] = useState(true);

  const amount = useMemo(() => {
    return {
      pending: status !== 'submitted' ? _.round(_(group.items).filter(filter(null)).sumBy(sum), 2) : 0,
      approved: status === 'submitted' ? _.round(_(group.items).filter(filter('approved')).sumBy(sum), 2) : 0,
      rejected: status === 'submitted' ? _.round(_(group.items).filter(filter('rejected')).sumBy(sum), 2) : 0,
      total: _.round(_(group.items).sumBy(sum), 2),
    };
  }, [group, status]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ListGroup>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right color={colors.warning} $width="8rem">
          <Currency value={amount.pending} currency={currency} />
        </Cell>

        <Cell right color={colors.success} $width="8rem">
          <Currency value={amount.approved} currency={currency} />
        </Cell>

        <Cell right color={colors.danger} $width="8rem">
          <Currency value={amount.rejected} currency={currency} />
        </Cell>

        <Cell right $width="8rem">
          <Currency value={amount.total} currency={currency} />
        </Cell>

        <Cell right $width="4rem" />

        <DeleteCell>
          <DeleteButton onClick={onDelete}>
            <Icon icon="trash" />
          </DeleteButton>
        </DeleteCell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}
