import isPropValid from '@emotion/is-prop-valid';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { Clarity, ScrollToTop, Sentry, Stripe } from '~/components';
import {
  ApiProvider,
  ChatProvider,
  ConfirmationProvider,
  IntegrationsProvider,
  MemberProvider,
  ModalProvider,
  PathHistoryProvider,
  RatingProvider,
  ReferrerProvider,
  SessionProvider,
  SubscriptionProvider,
  TimerProvider,
  ToastProvider,
  WorkspaceProvider,
} from '~/contexts';
import Routes from '~/routes';
import { GlobalStyle } from '~/styles';

function Root() {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop />
        <PathHistoryProvider>
          <ApiProvider>
            <SubscriptionProvider>
              <ToastProvider>
                <ModalProvider>
                  <SessionProvider>
                    <WorkspaceProvider>
                      <MemberProvider>
                        <ChatProvider>
                          <IntegrationsProvider>
                            <TimerProvider>
                              <ConfirmationProvider>
                                <RatingProvider>
                                  <ReferrerProvider>
                                    <Stripe>
                                      <Sentry />
                                      <Routes />
                                      <Clarity />
                                    </Stripe>
                                  </ReferrerProvider>
                                </RatingProvider>
                              </ConfirmationProvider>
                            </TimerProvider>
                          </IntegrationsProvider>
                        </ChatProvider>
                      </MemberProvider>
                    </WorkspaceProvider>
                  </SessionProvider>
                </ModalProvider>
              </ToastProvider>
            </SubscriptionProvider>
          </ApiProvider>
        </PathHistoryProvider>
      </BrowserRouter>
    </StyleSheetManager>
  );
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

export default Root;
