import { Button, DeleteConfirmation, Dropdown, Icon } from '~/components';
import { Form, Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useEditableRow, useForm } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import { DaysInPeriod, HoursPerWeek, PeriodDateRange, PeriodDateRangeField } from '../components';
import { periodStartDateValidator } from '../utils';
import HoursPerDay from './HoursPerDay';
import HoursPerDayField from './HoursPerDayField';

function AvailabilityPeriodRow({
  member,
  period,
  periods,
  disableActions,
  isEditing,
  onEdit,
  onCancel,
  onSaved,
  onDeleted,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const [rowState, row] = useEditableRow();
  const [formState, form] = useForm();

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull(values);
      const { data } = await api.www
        .workspaces(workspace.id)
        .members(member.id)
        .availabilityPeriods(period ? period.id : undefined)
        .upsert(body);
      await onSaved(data);
      form.done();
      row.save();
    } catch ({ message }) {
      form.error({ message });
      toast.error(message);
    }
  }

  async function handleDelete() {
    try {
      await api.www
        .workspaces(workspace.id)
        .members(member.id)
        .availabilityPeriods(period ? period.id : undefined)
        .delete();
      await onDeleted(period.id);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const isDefault = period.isDefault;

  if (!isEditing)
    return (
      <AvailabilityPeriodRowDetails
        period={period}
        member={member}
        isDefault={isDefault}
        saved={rowState.saved}
        disableActions={disableActions}
        onEdit={onEdit}
        onDelete={handleDelete}
      />
    );

  return (
    <AvailabilityPeriodRowForm
      period={period}
      periods={periods}
      member={member}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDefault={isDefault}
      {...formState}
    />
  );
}

const HoursPerDeyCell = styled(Table.Cell)`
  padding-top: 0;
  padding-bottom: 0.5rem;
`;

function AvailabilityPeriodRowDetails({
  period,
  period: { id, hoursPerDay },
  member,
  isDefault,
  saved,
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this availability period?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <PeriodDateRange period={period} member={member} />
      </Table.Cell>
      <HoursPerDeyCell>
        <HoursPerDay value={hoursPerDay} />
      </HoursPerDeyCell>
      <Table.Cell data-testid="weeklyhours_cell">
        <HoursPerWeek value={hoursPerDay} />
      </Table.Cell>
      <Table.Cell>
        <DaysInPeriod period={period} member={member} />
      </Table.Cell>

      <TableBoxRowActions>
        {saved ? (
          <TableBoxRowActions.Success />
        ) : (
          <>
            <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

            <hr />
            <TableBoxRowActions.Dropdown disabled={disableActions}>
              <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item
                disabled={isDefault}
                tooltip={isDefault ? 'The default period cannot be deleted.' : undefined}
                onClick={handleDelete}>
                Delete
              </Dropdown.Item>
            </TableBoxRowActions.Dropdown>
          </>
        )}
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

const FormHoursPerDeyCell = styled(Table.Cell)`
  && {
    padding-top: 0.4rem;
    padding-bottom: 0.5rem;
  }
`;

function AvailabilityPeriodRowForm({ period, periods, isSubmitting, onSubmit, onCancel, member, isDefault }) {
  const { workspace } = useWorkspace();

  const initialValues = {
    // Only the default period may have a null start date
    start: period.start === undefined ? new Date() : period.start,
    // Set the default hours per day based on the workspace's business days
    hoursPerDay: period.hoursPerDay ? [...period.hoursPerDay] : workspace.businessDays.map((bd) => (bd ? 8 : 0)),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        hoursPerDay: Yup.array().of(Yup.number().min(0).max(24).nullable().required()).min(7).max(7),
        start: periodStartDateValidator({ periods, period, member }),
      })}>
      {({ values }) => {
        return (
          <Table.BoxRow focused>
            <Form>
              <Table.Cell>
                <PeriodDateRangeField
                  autoFocus={!period.id}
                  periods={periods}
                  period={period}
                  member={member}
                  start={values.start}
                  isDefault={isDefault}
                />
              </Table.Cell>
              <FormHoursPerDeyCell>
                <HoursPerDayField autoFocus={!!period.id} name="hoursPerDay" />
              </FormHoursPerDeyCell>
              <Table.Cell data-testid="weeklyhours_edit_cell">
                <HoursPerWeek value={values.hoursPerDay} />
              </Table.Cell>
              <Table.Cell>
                <DaysInPeriod periods={periods} period={period} member={member} start={values.start} />
              </Table.Cell>
              <TableBoxRowActions.Form submit isLoading={isSubmitting} onCancel={onCancel} />
            </Form>
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

export default AvailabilityPeriodRow;
