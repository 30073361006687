import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { rgba } from 'polished';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Input } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

// isDisabled is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledLink = forwardRef(({ isDisabled, ...props }, ref) => <Link ref={ref} {...props} />);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-shrink: 0;
  padding: 0 0.625rem;
`;

const Search = styled.div`
  flex: 1;
  position: relative;
`;

const Control = styled.div`
  flex: 1;
  display: flex;
  height: 2.5rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  border-radius: 999rem;
  box-shadow: ${({ shadow }) =>
    shadow ? `0 0.1875rem 0.375rem ${rgba(colors.black, 0.15)}` : `0 0 0 ${rgba(colors.black, 0)}`};
  overflow: hidden;
  transition: box-shadow 100ms ease-in-out;
`;

const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 100%;
  padding: 0 0.75rem 0 1.25rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  .icon {
    color: ${colors.grey40};
  }
`;

const SelectText = styled.span`
  flex: 1;
  font-weight: ${weights.bold};

  span {
    font-weight: ${weights.normal};
  }
`;

const TypeMenu = styled.div`
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0.375rem;
  width: 7.25rem;
  padding: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.875rem;
    border: solid 0.875rem transparent;
    border-bottom-color: ${colors.white};
    pointer-events: none;
  }
`;

const TypeButton = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  margin-top: 0.25rem;
  padding: 0 1rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.black)};
  font-size: 0.875rem;
  font-weight: ${({ isActive }) => (isActive ? weights.bold : weights.normal)};
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
  border-radius: 999rem;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  }
`;

const SearchInput = styled(Input)`
  && {
    flex: 1;
    height: 100%;
    padding-left: 1rem;
    padding-right: 0;
    border: none;
    border-left: solid 1px ${colors.grey10};
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &:focus {
      border-color: ${colors.grey10};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const CancelQuery = styled.div`
  position: absolute;
  top: 50%;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  color: ${colors.grey40};
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    color: ${colors.danger};
  }
`;

const Cap = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  color: ${colors.primary};
  border-left: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'text')};
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  right: 5rem;
  transform: translateY(-50%);
`;

const Results = styled.div`
  position: absolute;
  top: 100%;
  left: calc(8rem + 2px);
  right: 1rem;
  max-height: 20rem;
  background-color: ${colors.white};
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.15)};
  overflow: hidden;
  overflow-y: auto;
`;

const NoResults = styled(Results)`
  padding: 1rem;
`;

const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  color: ${colors.white};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-image: linear-gradient(to right, ${colors.grey25}, ${colors.grey10});
`;

const ResultGroup = styled.div`
  padding: 1rem;
`;

const StyledItemLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 1rem;
  color: ${({ isDisabled }) => (isDisabled ? colors.grey40 : colors.black)};
  font-size: 0.875rem;
  border-radius: 999rem;

  &:hover {
    color: ${({ isDisabled }) => (isDisabled ? colors.grey40 : colors.black)};
    background-color: ${colors.grey5};
  }
`;

const LinkPart = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LinkPartSelected = styled(LinkPart)`
  flex-shrink: 0;
  max-width: 60%;
  font-weight: ${weights.bold};
`;

const LinkSeparator = styled.span`
  flex-shrink: 0;
  display: block;
  width: 0.125rem;
  height: 0.625rem;
  margin: 0 0.5rem;
  background-color: ${colors.grey40};
`;

const entityTypes = [
  {
    id: undefined,
    name: 'All',
    label: (
      <>
        <span>Search</span> All
      </>
    ),
  },
  { id: 'client', name: 'Clients', label: 'Clients' },
  { id: 'project', name: 'Projects', label: 'Projects' },
  { id: 'task', name: 'Tasks', label: 'Tasks' },
];

const ResultLink = forwardRef(function ResultLink({ entity, entityTypeId, ...props }, ref) {
  const { workspace } = useWorkspace();
  const recordStatusId = entity[entityTypeId].project?.recordStatusId ?? entity[entityTypeId].recordStatusId;
  const isDisabled = recordStatusId === 'archived';
  switch (entityTypeId) {
    case 'client':
      return (
        <StyledItemLink
          ref={ref}
          to={`/app/${workspace.key}/clients/${entity.client.key}`}
          isDisabled={isDisabled}
          {...props}>
          <LinkPartSelected>{entity.client.name}</LinkPartSelected>
        </StyledItemLink>
      );
    case 'project':
      return (
        <StyledItemLink
          ref={ref}
          to={`/app/${workspace.key}/projects/${entity.project.client.key}/${entity.project.key}`}
          isDisabled={isDisabled}
          {...props}>
          <LinkPart>{entity.project.client.name}</LinkPart>
          <LinkSeparator />
          <LinkPartSelected>{entity.project.name}</LinkPartSelected>
        </StyledItemLink>
      );
    case 'task':
      return (
        <StyledItemLink
          ref={ref}
          to={`/app/${workspace.key}/projects/${entity.task.project.client.key}/${entity.task.project.key}/tasks/${entity.task.number}/details`}
          isDisabled={isDisabled}
          {...props}>
          <LinkPart>{entity.task.project.client.name}</LinkPart>
          <LinkSeparator />
          <LinkPart>{entity.task.project.name}</LinkPart>
          <LinkSeparator />
          <LinkPartSelected>{entity.task.name}</LinkPartSelected>
        </StyledItemLink>
      );
    default:
      return null;
  }
});

export default function WorkspaceSearch() {
  const api = useApi();
  const history = useHistory();
  const { workspace } = useWorkspace();
  const [entityTypeId, setEntityTypeId] = useState();
  const [query, setQuery] = useState('');
  const queryRef = useRef(query);
  const [results, setResults] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [arrowKey, setArrowKey] = useState();
  const containerElement = useRef();
  const itemListRef = useRef();
  const itemRef = useRef(null);

  const isLocked = useMemo(() => workspace.statusId === 'disabled' || !workspace.billingStatus?.isValid, [workspace]);

  const isOpen = useMemo(() => isResultsOpen || isTypeOpen, [isResultsOpen, isTypeOpen]);

  const entityType = useMemo(() => _.find(entityTypes, { id: entityTypeId }), [entityTypeId]);

  const indexRoutes = useMemo(() => {
    const orderedRoutes = [];

    if (results) {
      _.forEach(entityTypes, (entityType) => {
        if (!_.has(results, entityType.id)) {
          return;
        }
        _.forEach(results[entityType.id], (entity) => {
          switch (entityType.id) {
            case 'client':
              orderedRoutes.push(`/app/${workspace.key}/clients/${entity.client.key}`);
              break;
            case 'project':
              orderedRoutes.push(`/app/${workspace.key}/projects/${entity.project.client.key}/${entity.project.key}`);
              break;
            case 'task':
              orderedRoutes.push(
                `/app/${workspace.key}/projects/${entity.task.project.client.key}/${entity.task.project.key}/tasks/${entity.task.number}/details`,
              );
              break;
          }
        });
      });
    }

    return orderedRoutes;
  }, [results, workspace.key]);

  const fetchData = useCallback(async () => {
    if (!query) {
      setResults(null);
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await api.www.workspaces(workspace.id).search().get({ query, type: entityTypeId });

      if (query === queryRef.current) {
        setResults(data);
        setIsResultsOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace.id, query, entityTypeId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  useEffect(() => {
    function closeMenu(event) {
      if (containerElement.current && containerElement.current.contains(event.target)) {
        return false;
      }
      setIsResultsOpen(false);
      setIsTypeOpen(false);
      setSelectedIndex(-1);
    }

    if (isOpen) {
      document.addEventListener('mousedown', closeMenu);
    }
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [isOpen]);

  const handleOpenSelect = () => {
    if (isLocked) {
      return;
    }

    setIsTypeOpen((isOpen) => !isOpen);
    setIsResultsOpen(false);
  };

  const handleSelectType = (typeId) => {
    if (entityTypeId !== typeId) {
      setEntityTypeId(typeId);
    } else if (query.length > 0) {
      setIsResultsOpen(true);
    }
    setIsTypeOpen(false);
  };

  const handleFocus = () => {
    setIsResultsOpen(true);
    setIsTypeOpen(false);
  };

  const handleKeyDown = (event) => {
    const { keyCode } = event;

    switch (keyCode) {
      case 9: // tab
      case 27: // escape
        setIsResultsOpen(false);
        setIsTypeOpen(false);
        setSelectedIndex(-1);
        break;
      case 13: // enter
        history.push(indexRoutes[selectedIndex]);
        setIsResultsOpen(false);
        setIsTypeOpen(false);
        break;
      case 38: // arrow up
        if (selectedIndex >= 0) {
          setSelectedIndex((prev) => prev - 1);
          if (arrowKey === 'up') {
            itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
          setArrowKey('up');
          if (selectedIndex <= 0) {
            itemListRef.current?.scrollTo({
              top: Math.max(0, itemListRef.current.scrollTop - 60),
              behavior: 'smooth',
            });
          }
        }
        break;
      case 40: // arrow down
        if (selectedIndex < itemListIndex - 1) {
          setSelectedIndex((prev) => prev + 1);
          if (arrowKey === 'down') {
            itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
          setArrowKey('down');
        }
        break;
      default:
        break;
    }
  };

  let itemListIndex = 0;

  const handleChange = (event) => {
    setSelectedIndex(-1);
    setQuery(event.target.value);
  };

  return (
    <Container>
      <Search ref={containerElement}>
        <Control shadow={isOpen}>
          <Select isDisabled={isLocked} onClick={handleOpenSelect}>
            <SelectText>{entityType.label}</SelectText>
            <Icon icon="angle-down" />
          </Select>
          <SearchInput
            id="header-search-input"
            type="search"
            placeholder="Search Clients, Projects, or Tasks"
            wait={300}
            value={query}
            disabled={isLocked}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onChange={(event) => handleChange(event)}
          />
          {query && (
            <CancelQuery onClick={() => setQuery('')}>
              <Icon icon="times" />
            </CancelQuery>
          )}
          <Cap isDisabled={isLocked} htmlFor="header-search-input">
            <Icon icon="search" />
          </Cap>
          {isLoading && (
            <Loading>
              <Icon icon="spinner" spin={true} />
            </Loading>
          )}
        </Control>
        {isTypeOpen && (
          <TypeMenu>
            {_.map(entityTypes, (entityType) => (
              <TypeButton
                key={entityType.id || ''}
                onClick={handleSelectType.bind(this, entityType.id)}
                isActive={entityType.id === entityTypeId}>
                {entityType.name}
              </TypeButton>
            ))}
          </TypeMenu>
        )}
        {isResultsOpen &&
          query &&
          !isLoading &&
          (_.keys(results).length > 0 ? (
            <Results ref={itemListRef}>
              {_.map(entityTypes, (entityType) => {
                if (!_.has(results, entityType.id)) {
                  return null;
                }
                return (
                  <React.Fragment key={entityType.id}>
                    <ResultHeader>{entityType.name}</ResultHeader>
                    <ResultGroup>
                      {_.map(results[entityType.id], (entity) => {
                        const index = itemListIndex++;
                        return (
                          <ResultLink
                            key={`${entityType.id}_${entity[entityType.id].id}`}
                            entity={entity}
                            entityTypeId={entityType.id}
                            onClick={() => setIsResultsOpen(false)}
                            ref={
                              arrowKey === 'down' && selectedIndex + 1 === index
                                ? itemRef
                                : arrowKey === 'up' && selectedIndex - 1 === index
                                  ? itemRef
                                  : null
                            }
                            style={selectedIndex === index ? { backgroundColor: colors.grey5 } : {}}
                          />
                        );
                      })}
                    </ResultGroup>
                  </React.Fragment>
                );
              })}
            </Results>
          ) : (
            <NoResults>No results found</NoResults>
          ))}
      </Search>
    </Container>
  );
}
