import { Button, Buttons, CancelButton, Icon, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { saveAs } from 'file-saver';
import { useLogarithmicInterval } from '~/hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const WarningList = styled.ul`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  list-style: disc inside;
`;

export default function IIFExportDialogAsync({ onLoad, onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [reportFile, setReportFile] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await onLoad;
        setReportFile(data);
      } catch (error) {
        setReportFile({ statusId: 'failed' });
      }
    })();
  }, [onLoad]);

  useLogarithmicInterval(async () => {
    if (['pending', 'processing'].includes(reportFile?.statusId)) {
      try {
        const { data } = await api.www.workspaces(workspace.id).reports().reportFiles(reportFile.id).get();
        setReportFile(data);
      } catch (error) {
        setReportFile({ statusId: 'failed' });
      }
    }
  });

  const handleClose = async () => {
    // If the file is not ready when closing the modal, send the file via email
    if (['pending', 'processing'].includes(reportFile?.statusId)) {
      await api.www.workspaces(workspace.id).reports().reportFiles(reportFile.id).sendEmail();
    }
    onClose();
  };

  return (
    <ModalCard title="Download Report" onClose={handleClose}>
      <ModalCard.Body>
        {(() => {
          switch (reportFile?.statusId) {
            case 'completed':
              return <p>Your file is now ready to download.</p>;

            case 'failed':
              return (
                <p>
                  <Icon icon="exclamation-circle" color={colors.danger} /> There was a problem getting your file, please
                  try again later.
                </p>
              );

            default:
              return (
                <p>
                  Your report export is being created. If you do not want to wait, just click the Close button below and
                  you'll receive an email when the file is ready to download.
                </p>
              );
          }
        })()}
        <div style={{ marginTop: '2rem' }}>
          Before importing this file into QuickBooks Desktop or Enterprise, make sure that:
        </div>
        <WarningList>
          <li>You create a backup of your QuickBooks company file since the invoice import cannot be undone.</li>
          <li>QuickBooks customer names have the same spelling as the corresponding client names in Ruddr.</li>
          <li>
            QuickBooks item names have the same spelling as the corresponding invoice items and tax rates in Ruddr.
          </li>
          <li>
            If your invoices use discounts, the discount account name has been provided within the QuickBooks Desktop
            integration settings in Ruddr.
          </li>
        </WarningList>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={handleClose}>Close</CancelButton>

          <Button
            isLoading={!reportFile || ['pending', 'processing'].includes(reportFile?.statusId)}
            disabled={reportFile?.statusId !== 'completed'}
            onClick={() => {
              saveAs(reportFile.url, reportFile.name);
            }}>
            Download
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
