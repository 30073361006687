import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useApi } from '~/contexts';
import { useField } from '~/hooks';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';

function StripeCountrySelect({ disabled, ...props }) {
  const api = useApi();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return data;
    }

    const fuse = new Fuse(data, { keys: ['id', 'name'] });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [data, filterValue]);

  const selectedOption = useMemo(() => _.find(data, { id: props.value }), [data, props.value]);

  useEffect(() => {
    (async () => {
      const { data } = await api.stripe.getCountries();
      setData(data);
      setIsReady(true);
    })();
  }, [api]);

  return (
    <SingleSelect
      disabled={disabled || !isReady}
      showFilter={true}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption?.name || ''}
      {...props}>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldStripeCountrySelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <StripeCountrySelect {...field} {...props} />
    </FieldControl>
  );
}

export default StripeCountrySelect;
export { FieldStripeCountrySelect };
