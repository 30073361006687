import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Field as FormikField } from 'formik';
import { FieldControl, SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

const SlackUserSelect = React.forwardRef(({ value, disabled, ...props }, ref) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [slackUsers, setSlackUsers] = useState();
  const [filterValue, setFilterValue] = useState('');
  const isMountedRef = useRef(false);

  const fitleredUsers = useMemo(
    () => _.filter(slackUsers, (user) => user.name.includes(filterValue) || user.realName.includes(filterValue)),
    [slackUsers, filterValue],
  );

  const slackUser = useMemo(() => _.find(slackUsers, { id: value }), [slackUsers, value]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { data } = await api.www.workspaces(workspace.id).integrations().slack.getUsers();

      if (!isMountedRef.current) {
        return;
      }

      setIsLoading(false);
      setSlackUsers(data);
    })();
  }, [api, workspace.id]);

  return (
    <SingleSelect
      {...props}
      showEmptyOption={true}
      showFilter={true}
      placeholder="Slack User"
      noOptionsMessage="No slack users available"
      value={value}
      valueRenderer={slackUser ? `${slackUser.realName} (@${slackUser.name})` : ''}
      disabled={disabled || isLoading}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      ref={ref}>
      {fitleredUsers?.map((slackUser) => (
        <option key={slackUser.id} value={slackUser.id}>
          {slackUser.realName} (@{slackUser.name})
        </option>
      ))}
    </SingleSelect>
  );
});

const FieldSlackUserSelect = React.forwardRef(
  ({ field, form: { touched, errors }, label, hideErrorMessage, ...props }, ref) => {
    const error = touched[field.name] && errors[field.name];
    return (
      <FieldControl {...{ label, error, hideErrorMessage }}>
        <SlackUserSelect {...props} value={field.value} onChange={field.onChange} name={field.name} ref={ref} />
      </FieldControl>
    );
  },
);

const FormikFieldSlackUserSelect = (props) => <FormikField {...props} component={FieldSlackUserSelect} />;

export default FormikFieldSlackUserSelect;
