import { Button, Buttons, DeleteButton, Level, SplitButton } from '~/components';
import React from 'react';
import { Actions } from '../components/PageComponents';

export default function ProjectActions({ onDelete, onGetWebLink, onSelectTimeAndExpenses, onSend }) {
  return (
    <Actions>
      <Level>
        <Level.Item narrow>
          <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        </Level.Item>

        <Level.Item right>
          <Buttons align="right">
            <SplitButton>
              <Button onClick={onSend}>Send</Button>

              <SplitButton.Menu position="top">
                {({ setIsOpen }) => {
                  return (
                    <>
                      <SplitButton.Item
                        onClick={() => {
                          setIsOpen(false);
                          onGetWebLink();
                        }}>
                        Get Web Link
                      </SplitButton.Item>

                      <SplitButton.Item
                        onClick={() => {
                          setIsOpen(false);
                          onSelectTimeAndExpenses();
                        }}>
                        Select Items
                      </SplitButton.Item>
                    </>
                  );
                }}
              </SplitButton.Menu>
            </SplitButton>
          </Buttons>
        </Level.Item>
      </Level>
    </Actions>
  );
}
