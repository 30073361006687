import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Icon, SingleSelect, Tooltip } from '~/components';
import { colors } from '~/styles';

export default function TaxCodeSelect({ taxCodes, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return taxCodes;
    }

    const fuse = new Fuse(taxCodes, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [taxCodes, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const taxCode = _.find(taxCodes, { id: value });
    if (!taxCode)
      return {
        id: value,
        name: (
          <Tooltip message="Tax Code not found in QuickBooks.">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Tax Code ID: {value}
          </Tooltip>
        ),
      };
    return taxCode;
  }, [taxCodes, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
