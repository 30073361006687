import {
  ApprovalStatusFilter,
  BackLink,
  BillableIcon,
  ClientApprovalStatusFilter,
  Currency,
  DateTime,
  ExpenseBillableTypeFilter,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  Icon,
  InfiniteScrollingObserver,
  InvoicedFilter,
  Level,
  Percent,
  Stack,
  Table,
  Tooltip,
  Widget,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import expenseLockedReasons from '~/routes/app/expenses/expenseLockedReason';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExpenseBillableTypeTag from '../components/ExpenseBillableTypeTag';
import ExpenseCategoryTag from '../components/ExpenseCategoryTag';
import ExpenseReimbursableTypeTag from '../components/ExpenseReimbursableTypeTag';
import ExpenseStatusTag from '../components/ExpenseStatusTag';
import ExpenseTypeFilter from '../components/ExpenseTypeFilter';
import ExpenseTypeTag from '../components/ExpenseTypeTag';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import InvoicedTag from '../components/InvoicedTag';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import PeriodTag from '../components/PeriodTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import ReimbursedTag from '../components/ReimbursedTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const Byline = styled.small`
  display: block;
`;

function ExpenseDetail() {
  const documentTitle = useDocumentTitle('Expense Items');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const isMounted = useIsMounted();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [query, setQuery] = useState({
    report: null,
    params: {
      period: null,
      createdPeriod: null,
      modifiedPeriod: null,
      approvalStatuses: [],
      clientApprovalStatuses: [],
      expenseType: null,
      billableType: null,
      reimbursableType: null,
      isReimbursed: null,
      expenseCategories: [],
      invoiced: null,
      currency: workspace.currency,
      sort: new QuerySort('date', 'asc'),
      page: 0,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
      ...memberFilters.filters,
    },
    action: 'load',
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.report ||
        state.report.records.total <= state.report.records.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        expenseType: searchParamsConfig.expenseType,
        billableType: searchParamsConfig.expenseBillableType,
        reimbursableType: searchParamsConfig.expenseReimbursableType,
        isReimbursed: searchParamsConfig.isReimbursed,
        expenseCategories: searchParamsConfig.expenseCategories,
        invoiced: searchParamsConfig.invoiced,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('date', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(() => {
    return {
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      expenseType: params.expenseType ?? undefined,
      billableTypeId: params.billableType ?? undefined,
      reimbursableTypeId: params.reimbursableType ?? undefined,
      isReimbursed: params.isReimbursed ?? undefined,
      expenseCategoryId: params.expenseCategories?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    };
  }, [params, features.clientApprovals, clientFilters, projectFilters, memberFilters]);

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().expenseDetail(urlSearchParams);

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      action: null,
      status: 'ready',
      loading: { summary: false, table: false },
      report: {
        ...data,
        records: {
          ...data.records,
          results:
            state.action === 'load-more'
              ? [...state.report.records.results, ...data.records.results]
              : data.records.results,
        },
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, page: 0, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const handleRowClick = (item) => {
    history.push({ pathname: `${route.url}/${item.id}`, search: location.search, state: { scrollToTop: false } });
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: route.url, search: location.search, state: { scrollToTop: false } });
    documentTitle.set('Expense Items');
  };

  const handleRowSaved = async (expenseItem) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .expenseDetail({
        ...urlSearchParams,
        expenseItemId: expenseItem.id,
      });

    if (!isMounted.current) return;

    const item = data.records.find((record) => record.id === expenseItem.id);

    setQuery((state) => ({
      ...state,
      report: {
        ...data,
        records: {
          total: item ? state.report.records.total : state.report.records.total - 1,
          results: item
            ? state.report.records.results.map((result) => (result.id === expenseItem.id ? item : result))
            : state.report.records.results.filter((result) => result.id !== expenseItem.id),
        },
      },
    }));
  };

  const handleRowDeleted = async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .expenseDetail({
        ...urlSearchParams,
        page: 0,
        // Refetch every loaded page
        size: (query.params.page + 1) * query.params.size,
      });

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      report: data,
    }));

    handleCloseDrawer();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .expenseDetail(
            { ...urlSearchParams, page: undefined, size: null },
            {
              headers: { accept: mimeType },
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Header>
          <BackLink defaultPath={`/app/${workspace.key}/reports/expenses`} />

          <Stack>
            <h1>Expense Items</h1>

            <Tags>
              <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

              <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

              <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

              <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

              <PeriodTag
                value={params.createdPeriod}
                label="Created Date Range"
                onRemove={() => handleApplyFilters({ createdPeriod: intervalOptions.all_dates })}
              />

              <PeriodTag
                value={params.modifiedPeriod}
                label="Modified Date Range"
                onRemove={() => handleApplyFilters({ modifiedPeriod: intervalOptions.all_dates })}
              />

              <ExpenseStatusTag
                value={params.approvalStatuses}
                onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
              />

              {features.clientApprovals && (
                <ClientApprovalStatusTag
                  value={params.clientApprovalStatuses}
                  onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
                />
              )}

              <ExpenseTypeTag value={params.expenseType} onRemove={() => handleApplyFilters({ expenseType: null })} />

              <ExpenseBillableTypeTag
                value={params.billableType}
                onRemove={() => handleApplyFilters({ billableType: null })}
              />

              <ExpenseReimbursableTypeTag
                value={params.reimbursableType}
                onRemove={() => handleApplyFilters({ reimbursableType: null })}
              />

              <ReimbursedTag value={params.isReimbursed} onRemove={() => handleApplyFilters({ isReimbursed: null })} />

              <ExpenseCategoryTag
                value={params.expenseCategories}
                onRemove={() => handleApplyFilters({ expenseCategories: [] })}
              />

              <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />
            </Tags>
          </Stack>

          <Level>
            <Level.Item narrow right>
              <ExportDropdown data-testid="download-button">
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.pdf);
                        setIsOpen(false);
                      }}>
                      Export to PDF
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            <Level.Item narrow right>
              <FilterButton isOutline onClick={showFilters} />
            </Level.Item>
          </Level>
        </Header>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data
                    query={query}
                    onLoadMore={loadMore}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    onCurrencyChange={handleCurrencyChange}
                  />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      <Switch>
        <Route path={route.path.concat('/:expenseItemId')}>
          <ExpenseItemDrawer onSaved={handleRowSaved} onDeleted={handleRowDeleted} onClose={handleCloseDrawer} />
        </Route>
      </Switch>
    </>
  );
}

function Data({ query, onLoadMore, onSort, onRowClick, onCurrencyChange }) {
  const features = useFeatures();
  const { report, params } = query;

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            <Meter.Value
              label="Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Currency value={report.totals.billable} currency={report.currency} /> (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Currency value={report.totals.nonBillable} currency={report.currency} /> (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.billable} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.nonBillable} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Non-Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.total} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Total</Metric.Label>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Level>
          {features.multicurrency && (
            <Level.Item>
              <CurrencyDropdown value={query.params.currency} onChange={onCurrencyChange} />
            </Level.Item>
          )}

          <Level.Item right>
            <TotalResults>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total label="Expense" value={report.records.total} />
              {report.partialResults && <PartialResultsTooltip />}
            </TotalResults>
          </Level.Item>
        </Level>

        <Table>
          <Table.Header sticky>
            <Table.Column width="3.5rem" />
            <Table.Column name="date" width="7.5rem" onSort={onSort} sort={params.sort}>
              Date
            </Table.Column>
            <Table.Column name="member_name" onSort={onSort} sort={params.sort}>
              Member
            </Table.Column>
            <Table.Column name="project_name" onSort={onSort} sort={params.sort}>
              Project/Client
            </Table.Column>
            <Table.Column>Category/Vendor</Table.Column>
            <Table.Column
              name="clientApprovalStatusId"
              width="12rem"
              isVisible={features.clientApprovals}
              onSort={onSort}
              sort={params.sort}>
              Client Approval Status
            </Table.Column>
            <Table.Column name="statusId" width="9rem" onSort={onSort} sort={params.sort}>
              Approval Status
            </Table.Column>
            <Table.Column width="3.5rem" />
            <Table.Column name="converted_amount" width="7rem" align="right" onSort={onSort} sort={params.sort}>
              Amount
            </Table.Column>
            <Table.Column width="3.5rem" />
          </Table.Header>

          <TableBody fade={query.loading.table}>
            {report.records.results.map((item) => {
              switch (item.expenseType) {
                case 'member': {
                  const handleRowClick = () => onRowClick(item);

                  return (
                    <Table.Row key={item.id} onClick={handleRowClick}>
                      <Table.Cell>
                        <BillableIcon value={item.isBillable} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={item.date} />
                      </Table.Cell>
                      <Table.Cell>{item.member.name}</Table.Cell>
                      <Table.Cell>
                        {item.project && (
                          <p>
                            {item.project.name}
                            <Byline>{item.project.client.name}</Byline>
                          </p>
                        )}
                      </Table.Cell>
                      <Table.Cell flex="2">
                        <p>
                          {item.expenseCategory?.name}
                          {item.vendorName && <Byline>{item.vendorName}</Byline>}
                        </p>
                      </Table.Cell>

                      <Table.Cell>{clientApprovalStatuses[item.clientApprovalStatusId]?.name}</Table.Cell>

                      <Table.Cell>{item.status.name}</Table.Cell>

                      <Table.Cell>
                        <Tooltip
                          message={
                            item.isReimbursed
                              ? 'Expense Item is Reimbursed.'
                              : item.isReimbursable
                                ? 'Expense Item is Reimbursable.'
                                : 'Expense Item is not Reimbursable.'
                          }>
                          <Icon
                            icon="repeat"
                            color={
                              item.isReimbursed ? colors.green : item.isReimbursable ? colors.black : colors.grey25
                            }
                          />
                        </Tooltip>
                      </Table.Cell>

                      <Table.Cell>
                        <p>
                          <Currency value={item.convertedAmount} currency={report.currency} />
                          {item.currency !== report.currency && (
                            <Byline>
                              <Currency value={item.expenseAmount} currency={item.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>

                      <Table.Cell>
                        {item.lockStatusId !== 'unlocked' && (
                          <Tooltip message={expenseLockedReasons[item.lockStatusId]}>
                            <Icon color={colors.grey40} icon="lock" size="1x" />
                          </Tooltip>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                }

                case 'project':
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        <BillableIcon value={item.isBillable} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={item.date} />
                      </Table.Cell>
                      <Table.Cell />
                      <Table.Cell>
                        <p>
                          {item.project.name}
                          <Byline>{item.project.client.name}</Byline>
                        </p>
                      </Table.Cell>
                      <Table.Cell flex="2">
                        <p>
                          {item.expenseCategory?.name}
                          {item.vendorName && <Byline>{item.vendorName}</Byline>}
                        </p>
                      </Table.Cell>

                      <Table.Cell />

                      <Table.Cell />

                      <Table.Cell />

                      <Table.Cell>
                        <p>
                          <Currency value={item.convertedAmount} currency={report.currency} />
                          {item.currency !== report.currency && (
                            <Byline>
                              <Currency value={item.expenseAmount} currency={item.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>

                      <Table.Cell />
                    </Table.Row>
                  );
              }
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell />
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={report.totals.total} currency={report.currency} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </TableBody>
        </Table>
        {report.records.total > report.records.results.length && (
          <InfiniteScrollingObserver onIntersecting={onLoadMore} />
        )}
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      {auth.expenses.view && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <FiltersGroup
        label="Expense Filters"
        filters={[
          filters.createdPeriod?.start,
          filters.createdPeriod?.end,
          filters.modifiedPeriod?.start,
          filters.modifiedPeriod?.end,
          filters.approvalStatuses,
          filters.clientApprovalStatuses,
          filters.invoiced,
          filters.expenseCategories,
          filters.expenseType,
          filters.billableType,
          filters.reimbursableType,
          filters.isReimbursed,
        ]}>
        <Filter>
          <ReportPeriodFilter
            value={filters.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
          />
        </Filter>

        <Filter>
          <ReportPeriodFilter
            value={filters.modifiedPeriod}
            label="Modified Date Range"
            onChange={({ target: { value } }) => handleFilter({ modifiedPeriod: value })}
          />
        </Filter>

        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>

        <Filter>
          <ExpenseCategoryFilter
            value={filters.expenseCategories}
            onChange={({ target: { value } }) => handleFilter({ expenseCategories: value })}
          />
        </Filter>

        <Filter>
          <ExpenseTypeFilter
            value={filters.expenseType}
            onChange={({ target: { value } }) => handleFilter({ expenseType: value })}
          />
        </Filter>

        <Filter>
          <ExpenseBillableTypeFilter
            value={filters.billableType}
            onChange={({ target: { value } }) => handleFilter({ billableType: value })}
          />
        </Filter>

        <Filter>
          <ExpenseReimbursableTypeFilter
            value={filters.reimbursableType}
            onChange={({ target: { value } }) => handleFilter({ reimbursableType: value })}
          />
        </Filter>

        <Filter>
          <ReimbursedFilter
            value={filters.isReimbursed}
            onChange={({ target: { value } }) => handleFilter({ isReimbursed: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}

export default ExpenseDetail;
