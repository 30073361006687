import { Checkbox, Field, HelpTooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function CapFeesCheckbox({ project }) {
  switch (project.billingTypeId) {
    case 'tm':
      return (
        <Control>
          <Field.Checkbox name="capFees" label="Cap the services revenue on this task" />

          <HelpTooltip
            message="For time and materials projects, do not allow a time entry to be saved if it will cause the project task to exceed its services revenue budget."
            style={{ marginLeft: '.5rem' }}
          />
        </Control>
      );

    case 'fixed':
    case 'fixed_recurring':
      return (
        <Control>
          <Checkbox label="Cap the services revenue on this task" disabled value={false} />

          <HelpTooltip
            message="Fees cannot be capped on a fixed fee or fixed monthly project."
            style={{ marginLeft: '.5rem' }}
          />
        </Control>
      );

    default:
      return null;
  }
}
