import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useWorkspace } from '~/contexts';

export default function () {
  const history = useHistory();
  const { workspace } = useWorkspace();

  useEffect(() => {
    const lastView = localStorage.getItem('time-view') ?? 'day';
    history.replace(`/app/${workspace.key}/time/${lastView}`);
  }, [history, workspace]);

  return null;
}
