import { colors } from '~/styles';

export default function interpolateColor(color1 = colors.primary10, color2 = colors.primary100, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  var result = color1
    .slice(1)
    .match(/.{2}/g)
    .map(function (hex) {
      return parseInt(hex, 16);
    });
  var color2Components = color2
    .slice(1)
    .match(/.{2}/g)
    .map(function (hex) {
      return parseInt(hex, 16);
    });
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2Components[i] - result[i]));
  }
  return (
    '#' +
    result
      .map(function (value) {
        return ('0' + value.toString(16)).slice(-2);
      })
      .join('')
  );
}
