import { BackLink, ClientLink, Currency, DateTime, Icon, Level, RouteLink, Stack, Table } from '~/components';
import CreditNoteSentFilter from '~/components/filters/CreditNoteSentFilter';
import CreditNoteStatusFilter from '~/components/filters/CreditNoteStatusFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useNumberFormat, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import creditNoteStatuses from '~/lookups/credit-note-statuses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QBOIndicator from '~/routes/app/billing/credit-notes/components/QBOIndicator';
import SentIndicator from '~/routes/app/billing/credit-notes/components/SentIndicator';
import XeroIndicator from '~/routes/app/billing/credit-notes/components/XeroIndicator';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CreditNoteSentTag from '../components/CreditNoteSentTag';
import CreditNoteStatusTag from '../components/CreditNoteStatusTag';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const Byline = styled.small`
  display: block;
`;

export default function CreditNoteDetail() {
  useDocumentTitle('Credit Note Detail');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    statuses: [creditNoteStatuses.open],
    creditNoteSent: null,
    currency: workspace.currency,
    sort: new QuerySort('creditNote.issuedOn', 'desc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.all_dates },
        statuses: {
          multi: true,
          key: 'creditNoteStatus',
          default: [creditNoteStatuses.draft, creditNoteStatuses.open],
          valid: ['all', ..._.map(creditNoteStatuses, (v) => v.id)],
          serialize: function (value) {
            return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
          },
          deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => creditNoteStatuses[id])),
        },
        creditNoteSent: {
          valid: ['all', 'yes', 'no'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('creditNote.issuedOn', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      statusId: params.statuses.map((v) => v.id),
      creditNoteSent: params.creditNoteSent ?? undefined,
      includeLines: true,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().creditNoteDetail(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .creditNoteDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  if (!query.data) return null;
  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Credit Note Detail</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <CreditNoteStatusTag value={params.statuses} onRemove={() => handleApplyFilters({ statuses: [] })} />

            <CreditNoteSentTag
              value={params.creditNoteSent}
              onRemove={() => handleApplyFilters({ creditNoteSent: null })}
            />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} data-testid="filters_button" />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const quantityFormat = useNumberFormat({ minimumFractionDigits: 0 });

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}
        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Credit Note" value={report.records.length} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column width="12rem" name="creditNote.transactionNumber" onSort={onSort} sort={params.sort}>
            #
          </Table.Column>
          <Table.Column name="client.name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column width="8rem" align="right" name="creditNote.issuedOn" onSort={onSort} sort={params.sort}>
            Issued
          </Table.Column>
          <Table.Column width="6rem" name="statusId" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedTax" onSort={onSort} sort={params.sort}>
            Tax
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedBalance" onSort={onSort} sort={params.sort}>
            Remaining
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedTotal" onSort={onSort} sort={params.sort}>
            Total
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {report.records.map((creditNote) => {
            return (
              <React.Fragment key={creditNote.id}>
                <GroupRow top="2.95rem" style={{ fontWeight: weights.bold }}>
                  <Table.Cell>
                    <RouteLink to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                      {creditNote.number}
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <ClientLink client={creditNote.client} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={creditNote.issuedOn} />
                    {creditNote.sentAt && <SentIndicator sentAt={creditNote.sentAt} />}
                  </Table.Cell>
                  <Table.Cell>
                    {creditNote.status.name}
                    {creditNote.qboCreditMemoId && (
                      <span>
                        <QBOIndicator />
                      </span>
                    )}
                    {creditNote.xeroCreditNoteId && (
                      <span>
                        <XeroIndicator />
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={creditNote.convertedTax} currency={report.currency} />
                      {creditNote.currency !== report.currency && (
                        <Byline>
                          <Currency value={creditNote.tax} currency={creditNote.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={creditNote.convertedBalance} currency={report.currency} />
                      {creditNote.currency !== report.currency && (
                        <Byline>
                          <Currency value={creditNote.balance} currency={creditNote.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={creditNote.convertedTotal} currency={report.currency} />
                      {creditNote.currency !== report.currency && (
                        <Byline>
                          <Currency value={creditNote.total} currency={creditNote.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                </GroupRow>

                {creditNote.lines.length > 0 && (
                  <Table style={{ fontSize: '.75rem' }}>
                    <Table.Header style={{ background: colors.white }}>
                      <Table.Column width="12rem">Item</Table.Column>
                      <Table.Column>Details</Table.Column>
                      <Table.Column align="center" width="5.5rem">
                        Taxable
                      </Table.Column>
                      <Table.Column align="right" width="7.5rem">
                        Quantity
                      </Table.Column>
                      <Table.Column align="right" width="7.5rem">
                        Rate
                      </Table.Column>
                      <Table.Column align="right" width="10rem">
                        Amount
                      </Table.Column>
                    </Table.Header>

                    <Table.Body>
                      {creditNote.lines.map((line) => {
                        return (
                          <Table.Row key={line.id}>
                            <Table.Cell>{line.invoiceItem?.name}</Table.Cell>
                            <Table.Cell>{line.description}</Table.Cell>
                            <Table.Cell>{line.taxable && <Icon icon="check" />}</Table.Cell>
                            <Table.Cell>{line.quantity ? quantityFormat.format(line.quantity) : ''}</Table.Cell>
                            <Table.Cell>
                              <p>
                                <Currency
                                  value={line.convertedRate}
                                  currency={report.currency}
                                  maximumFractionDigits={7}
                                />
                                {line.convertedRate != null && creditNote.currency !== report.currency && (
                                  <Byline>
                                    <Currency
                                      value={line.rate}
                                      currency={creditNote.currency}
                                      maximumFractionDigits={7}
                                    />
                                  </Byline>
                                )}
                              </p>
                            </Table.Cell>
                            <Table.Cell>
                              <p>
                                <Currency value={line.convertedAmount} currency={report.currency} />
                                {line.convertedAmount != null && creditNote.currency !== report.currency && (
                                  <Byline>
                                    <Currency value={line.amount} currency={creditNote.currency} />
                                  </Byline>
                                )}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.balance} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Credit Note Filters" filters={[filters.statuses, filters.creditNoteSent]}>
        <Filter>
          <CreditNoteStatusFilter
            value={filters.statuses}
            onChange={({ target: { value } }) => handleFilter({ statuses: value })}
          />
        </Filter>

        <Filter>
          <CreditNoteSentFilter
            value={filters.creditNoteSent}
            onChange={({ target: { value } }) => handleFilter({ creditNoteSent: value })}
          />
        </Filter>
      </FiltersGroup>
    </Report.Filters>
  );
}
