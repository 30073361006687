import { Formik } from 'formik';
import React, { useRef } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ActionButton, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useRating, useToast } from '~/contexts';
import { useForm } from '~/hooks';
import { colors } from '~/styles';

const P = styled.p`
  margin-bottom: 1rem;
`;

const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ScoreBox = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? colors.primary : colors.grey10)};
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? colors.accent : colors.grey20)};
  }
`;

function RatingModal({ onClose }) {
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const toast = useToast();
  const { submitRating } = useRating();

  const initialValues = {
    score: null,
    feedback: '',
  };

  async function handleSubmit(values) {
    form.submit();
    const { success, message } = await submitRating(values, formRef.current.status);

    if (success) {
      if (formRef.current.status === 'submitted') {
        toast.success(message);
      }
      onClose();
    } else {
      form.error({ message });
    }

    form.done();
  }

  return (
    <ModalCard title="How are we doing?" onClose={handleSubmit}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          feedback: Yup.string().label('Optional Feedback').max(5000),
        })}>
        {(formik) => {
          const handleScoreChange = (value) => {
            formik.setFieldValue('score', value);
          };

          return (
            <Form>
              <ModalCard.Body>
                <P>Please rate your experience with Ruddr on a scale from 1 to 10 (with 10 being the best rating).</P>
                <ScoreWrapper>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <ScoreBox
                      key={value}
                      isSelected={value === formik.values.score}
                      onClick={() => handleScoreChange(value)}>
                      {value}
                    </ScoreBox>
                  ))}
                </ScoreWrapper>

                <Form.Control>
                  <Field.TextArea name="feedback" placeholder="Optional Feedback" maxLength={5000} rows={2} />
                </Form.Control>

                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton
                    type="submit"
                    isLoading={formik.status === 'dismissed' ? isSubmitting : false}
                    onClick={() => formik.setStatus('dismissed')}>
                    Dismiss
                  </CancelButton>
                  <ActionButton
                    data-testid="submit_rating"
                    type="submit"
                    isLoading={formik.status === 'submitted' ? isSubmitting : false}
                    disabled={!formik.values.score}
                    onClick={() => formik.setStatus('submitted')}>
                    Submit
                  </ActionButton>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default RatingModal;
