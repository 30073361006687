import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useApi, useWorkspace } from '~/contexts';
import SingleSelect from '~/components/SingleSelect';

function HolidayScheduleFilter({ disabled, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    let options = data;

    if (filterValue) {
      const fuse = new Fuse(data, { keys: ['id', 'name'] });
      const results = fuse.search(filterValue);
      options = results.map((result) => result.item);
    }

    return options;
  }, [data, filterValue]);

  const selectedOption = useMemo(
    () => _.find([...data, { id: 'none', name: 'None' }], { id: props.value }),
    [data, props.value],
  );

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).holidaySchedules().lookup();
      setData(data);
      setIsReady(true);
    })();
  }, [api, workspace]);

  return (
    <SingleSelect
      disabled={disabled || !isReady}
      showFilter={true}
      noOptionsMessage="No holiday schedules available."
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption?.name || ''}
      {...props}>
      {
        <option name="holidayScheduleId" value="none">
          None
        </option>
      }
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

export default HolidayScheduleFilter;
