import { SingleSelect } from '~/components';
import React from 'react';

export default function ReimbursedFilter(props) {
  return (
    <SingleSelect placeholder="All" materialPlaceholder="Reimbursed" materialAlwaysVisible showEmptyOption {...props}>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </SingleSelect>
  );
}
