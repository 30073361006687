import { Button, Buttons, CancelButton, Confirmation, DayPickerInput, Drawer, Level, Tab, Tabs } from '~/components';
import { useConfirmation } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import InvoiceProjectSelect from '../components/InvoiceProjectSelect';
import TransactionsDrawerExpensesTab from './TransactionsDrawerExpensesTab';
import TransactionsDrawerMilestonesTab from './TransactionsDrawerMilestonesTab';
import TransactionsDrawerOtherItemsTab from './TransactionsDrawerOtherItemsTab';
import TransactionsDrawerTimeTab from './TransactionsDrawerTimeTab';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const ApplyButton = styled(Button)`
  width: 10.5rem;
`;

function TransactionsDrawer({ invoice, values, tab, currency, onApply, onClose }) {
  const [tabIndex, setTabIndex] = useState({ services: 0, expenses: 1 }[tab] || 0);
  const [{ isSubmitting }, form] = useForm();
  const [timeEntries, setTimeEntries] = useState(() => values.lines.map((line) => line.timeEntries).flat(1));
  const [milestones, setMilestones] = useState(() => values.lines.map((line) => line.milestones).flat(1));
  const [expenses, setExpenses] = useState(() =>
    values.lines
      .map((line) => [
        ...line.expenses.map((e) => ({ ...e, expenseType: 'member' })),
        ...line.projectExpenses.map((pe) => ({ ...pe, expenseType: 'project' })),
      ])
      .flat(1),
  );
  const [otherItems, setOtherItems] = useState(() => values.lines.map((line) => line.otherItems).flat(1));
  const [start, setStart] = useState(values.periodStart);
  const [end, setEnd] = useState(values.periodEnd);
  const [project, setProject] = useState(() => (values.projects.length === 1 ? values.projects[0] : null));
  const projectId = useMemo(
    () => (project ? project.id : values.projects.map((p) => p.id)),
    [project, values.projects],
  );
  const confirmation = useConfirmation();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  function handleClose() {
    onClose();
  }

  const handleStartChange = (start) => {
    setStart(start);
  };

  const handleEndChange = (end) => {
    setEnd(end);
  };

  const handleProjectChange = (project) => {
    setProject(project);
  };

  const handleTimeChange = (value) => {
    setTimeEntries(value);
    setDirty(true);
  };

  const handleMilestonesChange = (value) => {
    setMilestones(value);
    setDirty(true);
  };

  const handleExpensesChange = (value) => {
    setExpenses(value);
    setDirty(true);
  };

  const handleOtherItemsChange = (value) => {
    setOtherItems(value);
    setDirty(true);
  };

  return (
    <Drawer
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      isOpen
      title="Items to Bill"
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleApply() {
          const confirm = await confirmation.prompt((resolve) => (
            <Confirmation resolve={resolve}>
              <p style={{ marginBottom: '1rem' }}>
                Applying these changes will rebuild the invoice. It will also stop any running timers for the selected
                time entries.
              </p>
              <p>Are you sure you want to continue?</p>
            </Confirmation>
          ));
          if (!confirm) return;

          form.submit();

          await onApply({
            timeEntries,
            milestones,
            expenses: expenses.filter((e) => e.expenseType === 'member'),
            projectExpenses: expenses.filter((e) => e.expenseType === 'project'),
            otherItems,
            periodStart: start,
            periodEnd: end,
          });

          closeDrawer();
        }

        return (
          <>
            <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab>Time</Tab>
              <Tab>Fixed Fee Milestones</Tab>
              <Tab>Expenses</Tab>
              <Tab>Other Items</Tab>
            </Tabs>
            <Level margin="2rem 0 0 0">
              <Level.Item style={{ flex: 2.3 }}>
                <InvoiceProjectSelect
                  value={project}
                  options={values.projects}
                  onChange={({ target: { value } }) => handleProjectChange(value)}
                />
              </Level.Item>
              <Level.Item>
                <DayPickerInput
                  align="right"
                  placeholder="Start"
                  value={start}
                  onChange={(value) => handleStartChange(value)}
                />
              </Level.Item>
              <Level.Item>
                <DayPickerInput
                  align="right"
                  placeholder="End"
                  value={end}
                  onChange={(value) => handleEndChange(value)}
                />
              </Level.Item>
            </Level>
            <Content>
              {
                [
                  <TransactionsDrawerTimeTab
                    key="services"
                    invoice={invoice}
                    timeEntries={timeEntries}
                    start={start}
                    end={end}
                    projectId={projectId}
                    currency={currency}
                    onChange={handleTimeChange}
                  />,

                  <TransactionsDrawerMilestonesTab
                    key="milestones"
                    invoice={invoice}
                    milestones={milestones}
                    start={start}
                    end={end}
                    projectId={projectId}
                    currency={currency}
                    onChange={handleMilestonesChange}
                  />,

                  <TransactionsDrawerExpensesTab
                    key="expenses"
                    invoice={invoice}
                    expenses={expenses}
                    start={start}
                    end={end}
                    projectId={projectId}
                    currency={currency}
                    onChange={handleExpensesChange}
                  />,

                  <TransactionsDrawerOtherItemsTab
                    key="otherItems"
                    invoice={invoice}
                    otherItems={otherItems}
                    start={start}
                    end={end}
                    projectId={projectId}
                    currency={currency}
                    onChange={handleOtherItemsChange}
                  />,
                ][tabIndex]
              }
            </Content>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ApplyButton onClick={handleApply} isLoading={isSubmitting}>
                  Apply
                </ApplyButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

export default TransactionsDrawer;
