import {
  Avatar,
  BillableIcon,
  Buttons,
  CancelButton,
  Currency,
  DateTime,
  Drawer,
  Hours,
  MultilineText,
  Stack,
  Tab,
  Table,
  Tabs,
  Tag,
  Tags,
} from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useCurrencyFormat, useDocumentTitle } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import ProjectTaskHistory from './ProjectTaskHistory';

const TagContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0.625em;
  padding-right: 2.5em;
  max-width: 100%;
  background-color: ${colors.grey5};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const TagItem = styled.div`
  border-radius: 0.3125rem;
  background-color: ${colors.grey10};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 0.5rem 0.25rem 0.25rem 1rem;
`;

const TagPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  transition: opacity 100ms;
  pointer-events: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const Control = ({ visible = true, children }) => (visible ? <ReadForm.Control>{children}</ReadForm.Control> : null);

const Description = ({ visible = true, label, children }) =>
  visible ? <ReadTextbox label={label} value={children} /> : null;

const initialState = { task: null, isReady: false };
const handlers = {
  ready: ({ task }) => ({ isReady: true, task }),
};

function ProjectTaskDetails({ taskId, project, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, task }, actions] = useActions(handlers, initialState);
  const currencyFormat = useCurrencyFormat({ currency: project.currency });
  const [tabIndex, setTabIndex] = useState(0);

  const params = useParams();
  if (!taskId) taskId = params.taskId;

  const fetchData = useCallback(async () => {
    if (!taskId) {
      actions.ready({ task: {} });
      return;
    }
    try {
      const { data: task } = await api.www.workspaces(workspace.id).projects(project.id).tasks(taskId).get();
      actions.ready({ task });
    } catch (error) {
      actions.ready({ task: null });
    }
  }, [actions, workspace.id, project.id, taskId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useDocumentTitle('Task Details');

  if (!isReady) return null;
  if (!task) return <ErrorPage.NotFound publicSite={false} />;

  const currency = project.currency;

  return (
    <Drawer title="Task Details" isOpen onClose={onClose}>
      {(closeDrawer) => {
        return (
          <Stack>
            <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab>Task</Tab>
              {task.permissions.manage && <Tab>History</Tab>}
            </Tabs>

            <Content>
              {
                [
                  <>
                    <ReadForm.Section title="Basic Information">
                      <Control>
                        <Description label="Task Name">{task.name}</Description>
                      </Control>

                      <Control visible={!!task.description}>
                        <Description label="Notes">
                          <MultilineText value={task.description} />
                        </Description>
                      </Control>

                      <Control>
                        <Description label="Status">{task.status.name}</Description>
                      </Control>

                      <Control>
                        <Description label="Hours Budget">
                          <Hours value={task.budgetHours} />
                        </Description>

                        {task.permissions.viewRates && task.permissions.viewRevenue && (
                          <ReadTextbox
                            label="Services Revenue Budget"
                            value={currencyFormat.format(task.budgetRevenue)}
                          />
                        )}
                      </Control>

                      <Control visible={!!(task.start || task.end)}>
                        <Description label="Start" visible={!!task.start}>
                          <DateTime value={task.start} />
                        </Description>

                        <Description label="End" visible={!!task.end}>
                          <DateTime value={task.end} />
                        </Description>
                      </Control>

                      <Control>
                        <Description label="Is Billable?">{task.isActuallyBillable ? 'Yes' : 'No'}</Description>
                      </Control>
                      <Control visible={task.tags.length > 0}>
                        <TagContainer>
                          {task.tags.map((tag) => (
                            <TagItem key={tag.id}>{tag.name}</TagItem>
                          ))}
                          <TagPlaceholder>Tags</TagPlaceholder>
                        </TagContainer>
                      </Control>
                    </ReadForm.Section>

                    {project.useRoles ? (
                      <>
                        <ReadForm.Section title="Role assignment &amp; Budget">
                          {task.roles.length > 0 ? (
                            <Table data-testid="roles_table">
                              <Table.BoxHeader>
                                <Table.Column>Project Role</Table.Column>
                                <Table.Column align="right" width="6rem">
                                  Hours
                                </Table.Column>
                                <Table.Column
                                  align="right"
                                  width="8rem"
                                  isVisible={
                                    project.isBillable &&
                                    project.billingTypeId === 'tm' &&
                                    task.permissions.viewRates &&
                                    task.permissions.viewRevenue
                                  }>
                                  Fees
                                </Table.Column>
                              </Table.BoxHeader>
                              <Table.Body>
                                {task.roles.map((taskRole) => {
                                  const fees =
                                    task.isBillable &&
                                    _.isNumber(taskRole.hours) &&
                                    _.isNumber(taskRole.role?.actualRate)
                                      ? taskRole.hours * taskRole.role?.actualRate
                                      : null;

                                  return (
                                    <Table.BoxRow key={taskRole.role.id}>
                                      <Table.Cell>
                                        <BillableIcon value={taskRole.role.isActuallyBillable} /> {taskRole.role.name}
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Hours value={taskRole.hours} />
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Currency value={fees} currency={currency} />
                                      </Table.Cell>
                                    </Table.BoxRow>
                                  );
                                })}

                                <Table.Row>
                                  <Table.Cell>
                                    <strong>Total</strong>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Hours value={_.sumBy(task.roles, 'hours')} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Currency
                                      value={
                                        task.isBillable
                                          ? _.sumBy(task.roles, ({ hours, role }) => {
                                              return _.isNumber(hours) && _.isNumber(role?.actualRate)
                                                ? hours * role?.actualRate
                                                : null;
                                            })
                                          : 0
                                      }
                                      currency={currency}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          ) : (
                            'No roles are assigned to this task.'
                          )}
                        </ReadForm.Section>

                        <ReadForm.Section title="Member assignment">
                          {task.members.length > 0 ? (
                            <Tags data-testid="member_tags">
                              {task.members.map((taskMember) => (
                                <Tag variant="grey5" key={taskMember.projectMember.id}>
                                  <Avatar
                                    value={taskMember.projectMember.member}
                                    showName
                                    isCircle
                                    hasBackground
                                    size={18}
                                    fontSize="1rem"
                                    initialsFontSize=".625rem"
                                  />
                                </Tag>
                              ))}
                            </Tags>
                          ) : (
                            'No members are assigned to this task.'
                          )}
                        </ReadForm.Section>
                      </>
                    ) : (
                      <ReadForm.Section title="Member assignment &amp; Budget">
                        {task.members.length > 0 ? (
                          <Table data-testid="members_table">
                            <Table.BoxHeader>
                              <Table.Column>Project Member</Table.Column>
                              <Table.Column align="right" width="6rem">
                                Hours
                              </Table.Column>
                              <Table.Column
                                align="right"
                                width="8rem"
                                isVisible={
                                  project.isBillable &&
                                  project.billingTypeId === 'tm' &&
                                  task.permissions.viewRates &&
                                  task.permissions.viewRevenue
                                }>
                                Fees
                              </Table.Column>
                            </Table.BoxHeader>
                            <Table.Body>
                              {task.members.map((taskMember) => {
                                const fees =
                                  task.isBillable &&
                                  _.isNumber(taskMember.hours) &&
                                  _.isNumber(taskMember.projectMember?.actualRate)
                                    ? taskMember.hours * taskMember.projectMember?.actualRate
                                    : null;

                                return (
                                  <Table.BoxRow key={taskMember.projectMember.id}>
                                    <Table.Cell>
                                      <BillableIcon value={taskMember.projectMember.isActuallyBillable} />
                                      {taskMember.projectMember.member.name}
                                    </Table.Cell>
                                    <Table.Cell align="right">
                                      <Hours value={taskMember.hours} />
                                    </Table.Cell>
                                    <Table.Cell align="right">
                                      <Currency value={fees} currency={currency} />
                                    </Table.Cell>
                                  </Table.BoxRow>
                                );
                              })}

                              <Table.Row>
                                <Table.Cell>
                                  <strong>Total</strong>
                                </Table.Cell>
                                <Table.Cell>
                                  <Hours value={_.sumBy(task.members, 'hours')} />
                                </Table.Cell>
                                <Table.Cell>
                                  <Currency
                                    value={
                                      task.isBillable
                                        ? _.sumBy(task.members, ({ hours, projectMember }) => {
                                            return _.isNumber(hours) && _.isNumber(projectMember?.actualRate)
                                              ? hours * projectMember?.actualRate
                                              : null;
                                          })
                                        : 0
                                    }
                                    currency={currency}
                                  />
                                </Table.Cell>
                                <Table.Cell />
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        ) : (
                          'No members are assigned to this task.'
                        )}
                      </ReadForm.Section>
                    )}
                  </>,
                  <>
                    <ProjectTaskHistory projectId={project.id} taskId={task.id} />
                  </>,
                ][tabIndex]
              }
            </Content>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={closeDrawer}>Close</CancelButton>
              </Buttons>
            </Drawer.Actions>
          </Stack>
        );
      }}
    </Drawer>
  );
}

export default ProjectTaskDetails;
