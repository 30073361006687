import { Button, DateTime, Table, Tooltip } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useWorkspace } from '~/contexts';
import { saveAs } from 'file-saver';
import { useDocumentTitle, useNumberFormat } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { dateFormats, getIconFromMIME, mimeTypes } from '~/utils';
import Icon from '../../../components/Icon';
import Report from '../reports/components/Report';
import TotalResults from '../reports/components/TotalResults';

export default function MyExports() {
  useDocumentTitle('My Exports');
  const [query, setQuery] = useState({ data: null, status: 'loading' });

  const api = useApi();
  const { workspace } = useWorkspace();

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).personalDashboard().myExports();
    setQuery({ data, status: 'ready' });
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (!query.data) return;

    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('downloadId');
    if (!id) return;

    const file = query.data.find((file) => file.id === id);
    if (file) {
      saveAs(file.url, file.name);
    }
    history.push(location.pathname);
  }, [query.data, location.pathname, location.search, history]);

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return (
    <Report>
      {(() => {
        switch (query.status) {
          case 'loading':
            return <PageLoader />;

          default:
            return (
              <>
                <TotalResults>
                  {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
                  <Table.Total label="Export" value={query.data?.length} />
                </TotalResults>

                <Table>
                  <Table.BoxHeader>
                    <Table.Column>Created</Table.Column>
                    <Table.Column>Expires</Table.Column>
                    <Table.Column>Title</Table.Column>
                    <Table.Column>Status</Table.Column>
                    <Table.Column width="7.5rem" align="right">
                      Rows
                    </Table.Column>
                    <Table.BoxActionsColumn />
                  </Table.BoxHeader>

                  <Table.Body>
                    {query.data?.map((file) => {
                      return (
                        <Table.BoxRow key={file.id}>
                          <Table.Cell>
                            <DateTime value={file.createdAt} format={dateFormats.dateTime} />
                          </Table.Cell>
                          <Table.Cell>
                            {file.expiresAt && <DateTime value={file.expiresAt} format={dateFormats.dateTime} />}
                          </Table.Cell>
                          <Table.Cell>
                            <Tooltip
                              message={
                                {
                                  [mimeTypes.csv]: 'CSV format',
                                  [mimeTypes.xlsx]: 'Excel format',
                                  [mimeTypes.pdf]: 'PDF format',
                                  [mimeTypes.iif]: 'IIF format',
                                }[file.type]
                              }>
                              <Icon color={colors.primary} icon={getIconFromMIME(file.type)} spaceRight /> {file.title}
                            </Tooltip>
                          </Table.Cell>
                          <Table.Cell>{file.status}</Table.Cell>
                          <Table.Cell>{_.isNumber(file.rowCount) && numberFormat.format(file.rowCount)}</Table.Cell>
                          <TableBoxRowActions>
                            <Button
                              disabled={file.statusId !== 'completed' || !file.url}
                              onClick={() => {
                                saveAs(file.url, file.name);
                              }}>
                              <Icon icon="download" />
                            </Button>
                          </TableBoxRowActions>
                        </Table.BoxRow>
                      );
                    })}
                  </Table.Body>
                </Table>
              </>
            );
        }
      })()}
    </Report>
  );
}
