const countryCodes = [
  {
    iso: 'AF',
    name: 'Afghanistan',
    code: '93',
  },
  {
    iso: 'AL',
    name: 'Albania',
    code: '355',
  },
  {
    iso: 'DZ',
    name: 'Algeria',
    code: '213',
  },
  {
    iso: 'AD',
    name: 'Andorra',
    code: '376',
  },
  {
    iso: 'AI',
    name: 'Anguilla',
    code: '1-264',
  },
  {
    iso: 'AG',
    name: 'Antigua and Barbuda',
    code: '1-268',
  },
  {
    iso: 'AR',
    name: 'Argentina',
    code: '54',
  },
  {
    iso: 'AM',
    name: 'Armenia',
    code: '374',
  },
  {
    iso: 'AW',
    name: 'Aruba',
    code: '297',
  },
  {
    iso: 'AU',
    name: 'Australia',
    code: '61',
  },
  {
    iso: 'AT',
    name: 'Austria',
    code: '43',
  },
  {
    iso: 'AZ',
    name: 'Azerbaijan',
    code: '994',
  },
  {
    iso: 'BS',
    name: 'Bahamas',
    code: '1-242',
  },
  {
    iso: 'BH',
    name: 'Bahrain',
    code: '973',
  },
  {
    iso: 'BD',
    name: 'Bangladesh',
    code: '880',
  },
  {
    iso: 'BB',
    name: 'Barbados',
    code: '1-246',
  },
  {
    iso: 'BY',
    name: 'Belarus',
    code: '375',
  },
  {
    iso: 'BE',
    name: 'Belgium',
    code: '32',
  },
  {
    iso: 'BZ',
    name: 'Belize',
    code: '501',
  },
  {
    iso: 'BM',
    name: 'Bermuda',
    code: '1-441',
  },
  {
    iso: 'BT',
    name: 'Bhutan',
    code: '975',
  },
  {
    iso: 'BO',
    name: 'Bolivia',
    code: '591',
  },
  {
    iso: 'BA',
    name: 'Bosnia and Herzegovina',
    code: '387',
  },
  {
    iso: 'BW',
    name: 'Botswana',
    code: '267',
  },
  {
    iso: 'BR',
    name: 'Brazil',
    code: '55',
  },
  {
    iso: 'BN',
    name: 'Brunei Darussalam',
    code: '673',
  },
  {
    iso: 'BG',
    name: 'Bulgaria',
    code: '359',
  },
  {
    iso: 'BF',
    name: 'Burkina Faso',
    code: '226',
  },
  {
    iso: 'BI',
    name: 'Burundi',
    code: '257',
  },
  {
    iso: 'KH',
    name: 'Cambodia',
    code: '855',
  },
  {
    iso: 'CM',
    name: 'Cameroon',
    code: '237',
  },
  {
    iso: 'CA',
    name: 'Canada',
    code: '1',
  },
  {
    iso: 'CV',
    name: 'Cape Verde',
    code: '238',
  },
  {
    iso: 'KY',
    name: 'Cayman Islands',
    code: '1-345',
  },
  {
    iso: 'CF',
    name: 'Central African Republic',
    code: '236',
  },
  {
    iso: 'TD',
    name: 'Chad',
    code: '235',
  },
  {
    iso: 'CL',
    name: 'Chile',
    code: '56',
  },
  {
    iso: 'CN',
    name: 'China',
    code: '86',
  },
  {
    iso: 'CO',
    name: 'Colombia',
    code: '57',
  },
  {
    iso: 'KM',
    name: 'Comoros',
    code: '269',
  },
  {
    iso: 'CK',
    name: 'Cook Islands',
    code: '682',
  },
  {
    iso: 'CR',
    name: 'Costa Rica',
    code: '506',
  },
  {
    iso: 'HR',
    name: 'Croatia',
    code: '385',
  },
  {
    iso: 'CY',
    name: 'Cyprus',
    code: '357',
  },
  {
    iso: 'CZ',
    name: 'Czechia (Czech Republic)',
    code: '420',
  },
  {
    iso: 'CD',
    name: 'Democratic Republic of the Congo',
    code: '243',
  },
  {
    iso: 'DK',
    name: 'Denmark',
    code: '45',
  },
  {
    iso: 'DJ',
    name: 'Djibouti',
    code: '253',
  },
  {
    iso: 'DM',
    name: 'Dominica',
    code: '1-767',
  },
  {
    iso: 'DO',
    name: 'Dominican Republic',
    code: '1',
  },
  {
    iso: 'EC',
    name: 'Ecuador',
    code: '593',
  },
  {
    iso: 'EG',
    name: 'Egypt',
    code: '20',
  },
  {
    iso: 'SV',
    name: 'El Salvador',
    code: '503',
  },
  {
    iso: 'GQ',
    name: 'Equitorial Guinea',
    code: '240',
  },
  {
    iso: 'ER',
    name: 'Eritrea',
    code: '291',
  },
  {
    iso: 'EE',
    name: 'Estonia',
    code: '372',
  },
  {
    iso: 'ET',
    name: 'Ethiopia',
    code: '251',
  },
  {
    iso: 'FO',
    name: 'Faroe Islands',
    code: '298',
  },
  {
    iso: 'FJ',
    name: 'Fiji',
    code: '679',
  },
  {
    iso: 'FI',
    name: 'Finland',
    code: '358',
  },
  {
    iso: 'FR',
    name: 'France',
    code: '33',
  },
  {
    iso: 'GF',
    name: 'French Guiana',
    code: '594',
  },
  {
    iso: 'PF',
    name: 'French Polynesia',
    code: '689',
  },
  {
    iso: 'GA',
    name: 'Gabon',
    code: '241',
  },
  {
    iso: 'GM',
    name: 'Gambia',
    code: '220',
  },
  {
    iso: 'GE',
    name: 'Georgia',
    code: '995',
  },
  {
    iso: 'DE',
    name: 'Germany',
    code: '49',
  },
  {
    iso: 'GH',
    name: 'Ghana',
    code: '233',
  },
  {
    iso: 'GI',
    name: 'Gibraltar',
    code: '350',
  },
  {
    iso: 'GR',
    name: 'Greece',
    code: '30',
  },
  {
    iso: 'GL',
    name: 'Greenland',
    code: '299',
  },
  {
    iso: 'GD',
    name: 'Grenada',
    code: '1-473',
  },
  {
    iso: 'GP',
    name: 'Guadeloupe',
    code: '590',
  },
  {
    iso: 'GU',
    name: 'Guam',
    code: '1-671',
  },
  {
    iso: 'GT',
    name: 'Guatemala',
    code: '502',
  },
  {
    iso: 'GG',
    name: 'Guernsey',
    code: '44-1481',
  },
  {
    iso: 'GN',
    name: 'Guinea',
    code: '224',
  },
  {
    iso: 'GW',
    name: 'Guinea-Bissau',
    code: '245',
  },
  {
    iso: 'GY',
    name: 'Guyana',
    code: '592',
  },
  {
    iso: 'HT',
    name: 'Haiti',
    code: '509',
  },
  {
    iso: 'HN',
    name: 'Honduras',
    code: '504',
  },
  {
    iso: 'HK',
    name: 'Hong Kong',
    code: '852',
  },
  {
    iso: 'HU',
    name: 'Hungary',
    code: '36',
  },
  {
    iso: 'IS',
    name: 'Iceland',
    code: '354',
  },
  {
    iso: 'IN',
    name: 'India',
    code: '91',
  },
  {
    iso: 'ID',
    name: 'Indonesia',
    code: '62',
  },
  {
    iso: 'IQ',
    name: 'Iraq',
    code: '964',
  },
  {
    iso: 'IE',
    name: 'Ireland',
    code: '353',
  },
  {
    iso: 'IM',
    name: 'Isle of Man',
    code: '44-1624',
  },
  {
    iso: 'IL',
    name: 'Israel',
    code: '972',
  },
  {
    iso: 'IT',
    name: 'Italy',
    code: '39',
  },
  {
    iso: 'CI',
    name: 'Ivory Coast',
    code: '225',
  },
  {
    iso: 'JM',
    name: 'Jamaica',
    code: '1-876',
  },
  {
    iso: 'JP',
    name: 'Japan',
    code: '81',
  },
  {
    iso: 'JE',
    name: 'Jersey',
    code: '44-1434',
  },
  {
    iso: 'JO',
    name: 'Jordan',
    code: '962',
  },
  {
    iso: 'KZ',
    name: 'Kazakhstan',
    code: '7',
  },
  {
    iso: 'KE',
    name: 'Kenya',
    code: '254',
  },
  {
    iso: 'XK',
    name: 'Kosovo',
    code: '383',
  },
  {
    iso: 'KW',
    name: 'Kuwait',
    code: '965',
  },
  {
    iso: 'KG',
    name: 'Kyrgyzstan',
    code: '996',
  },
  {
    iso: 'LA',
    name: 'Laos',
    code: '856',
  },
  {
    iso: 'LV',
    name: 'Latvia',
    code: '371',
  },
  {
    iso: 'LB',
    name: 'Lebanon',
    code: '961',
  },
  {
    iso: 'LS',
    name: 'Lesotho',
    code: '266',
  },
  {
    iso: 'LR',
    name: 'Liberia',
    code: '231',
  },
  {
    iso: 'LY',
    name: 'Libya',
    code: '218',
  },
  {
    iso: 'LI',
    name: 'Liechtenstein',
    code: '423',
  },
  {
    iso: 'LT',
    name: 'Lithuania',
    code: '370',
  },
  {
    iso: 'LU',
    name: 'Luxembourg',
    code: '352',
  },
  {
    iso: 'MO',
    name: 'Macau',
    code: '853',
  },
  {
    iso: 'MK',
    name: 'Macedonia',
    code: '389',
  },
  {
    iso: 'MG',
    name: 'Madagascar',
    code: '261',
  },
  {
    iso: 'MW',
    name: 'Malawi',
    code: '265',
  },
  {
    iso: 'MY',
    name: 'Malaysia',
    code: '60',
  },
  {
    iso: 'MV',
    name: 'Maldives',
    code: '960',
  },
  {
    iso: 'ML',
    name: 'Mali',
    code: '223',
  },
  {
    iso: 'MT',
    name: 'Malta',
    code: '356',
  },
  {
    iso: 'MH',
    name: 'Marshall Islands',
    code: '692',
  },
  {
    iso: 'MQ',
    name: 'Martinique',
    code: '596',
  },
  {
    iso: 'MR',
    name: 'Mauritania',
    code: '222',
  },
  {
    iso: 'MU',
    name: 'Mauritius',
    code: '230',
  },
  {
    iso: 'YT',
    name: 'Mayotte',
    code: '262',
  },
  {
    iso: 'MX',
    name: 'Mexico',
    code: '52',
  },
  {
    iso: 'FM',
    name: 'Micronesia (Federated States of)',
    code: '691',
  },
  {
    iso: 'MD',
    name: 'Moldova, Republic of',
    code: '373',
  },
  {
    iso: 'MC',
    name: 'Monaco',
    code: '377',
  },
  {
    iso: 'MN',
    name: 'Mongolia',
    code: '976',
  },
  {
    iso: 'ME',
    name: 'Montenegro',
    code: '382',
  },
  {
    iso: 'MS',
    name: 'Montserrat',
    code: '1-664',
  },
  {
    iso: 'MA',
    name: 'Morocco',
    code: '212',
  },
  {
    iso: 'MZ',
    name: 'Mozambique',
    code: '258',
  },
  {
    iso: 'MM',
    name: 'Myanmar',
    code: '95',
  },
  {
    iso: 'NA',
    name: 'Namibia',
    code: '264',
  },
  {
    iso: 'NP',
    name: 'Nepal',
    code: '977',
  },
  {
    iso: 'NL',
    name: 'Netherlands',
    code: '31',
  },
  {
    iso: 'AN',
    name: 'Netherlands Antilles',
    code: '599',
  },
  {
    iso: 'NC',
    name: 'New Caledonia',
    code: '687',
  },
  {
    iso: 'NZ',
    name: 'New Zealand',
    code: '64',
  },
  {
    iso: 'NI',
    name: 'Nicaragua',
    code: '505',
  },
  {
    iso: 'NE',
    name: 'Niger',
    code: '227',
  },
  {
    iso: 'NG',
    name: 'Nigeria',
    code: '234',
  },
  {
    iso: 'NU',
    name: 'Niue',
    code: '683',
  },
  {
    iso: 'NO',
    name: 'Norway',
    code: '47',
  },
  {
    iso: 'OM',
    name: 'Oman',
    code: '968',
  },
  {
    iso: 'PK',
    name: 'Pakistan',
    code: '92',
  },
  {
    iso: 'PS',
    name: 'Palestine',
    code: '970',
  },
  {
    iso: 'PA',
    name: 'Panama',
    code: '507',
  },
  {
    iso: 'PG',
    name: 'Papua New Guinea',
    code: '675',
  },
  {
    iso: 'PY',
    name: 'Paraguay',
    code: '595',
  },
  {
    iso: 'PE',
    name: 'Peru',
    code: '51',
  },
  {
    iso: 'PH',
    name: 'Philippines',
    code: '63',
  },
  {
    iso: 'PL',
    name: 'Poland',
    code: '48',
  },
  {
    iso: 'PT',
    name: 'Portugal',
    code: '351',
  },
  {
    iso: 'PR',
    name: 'Puerto Rico',
    code: '1',
  },
  {
    iso: 'QA',
    name: 'Qatar',
    code: '974',
  },
  {
    iso: 'CG',
    name: 'Republic of the Congo',
    code: '242',
  },
  {
    iso: 'RE',
    name: 'Réunion (France)',
    code: '262',
  },
  {
    iso: 'RO',
    name: 'Romania',
    code: '40',
  },
  {
    iso: 'RU',
    name: 'Russia',
    code: '7',
  },
  {
    iso: 'RW',
    name: 'Rwanda',
    code: '250',
  },
  {
    iso: 'KN',
    name: 'Saint Kitts and Nevis',
    code: '1-869',
  },
  {
    iso: 'LC',
    name: 'Saint Lucia',
    code: '1-758',
  },
  {
    iso: 'WS',
    name: 'Samoa',
    code: '685',
  },
  {
    iso: 'SM',
    name: 'San Marino',
    code: '378',
  },
  {
    iso: 'ST',
    name: 'São Tomé and Príncipe',
    code: '239',
  },
  {
    iso: 'SA',
    name: 'Saudi Arabia',
    code: '966',
  },
  {
    iso: 'SN',
    name: 'Senegal',
    code: '221',
  },
  {
    iso: 'RS',
    name: 'Serbia',
    code: '381',
  },
  {
    iso: 'SC',
    name: 'Seychelles',
    code: '248',
  },
  {
    iso: 'SL',
    name: 'Sierra Leone',
    code: '232',
  },
  {
    iso: 'SG',
    name: 'Singapore',
    code: '65',
  },
  {
    iso: 'SK',
    name: 'Slovakia',
    code: '421',
  },
  {
    iso: 'SI',
    name: 'Slovenia',
    code: '386',
  },
  {
    iso: 'SB',
    name: 'Solomon Islands',
    code: '677',
  },
  {
    iso: 'SO',
    name: 'Somalia',
    code: '252',
  },
  {
    iso: 'ZA',
    name: 'South Africa',
    code: '27',
  },
  {
    iso: 'KR',
    name: 'South Korea',
    code: '82',
  },
  {
    iso: 'SS',
    name: 'South Sudan',
    code: '211',
  },
  {
    iso: 'ES',
    name: 'Spain',
    code: '34',
  },
  {
    iso: 'LK',
    name: 'Sri Lanka',
    code: '94',
  },
  {
    iso: 'SR',
    name: 'Suriname',
    code: '597',
  },
  {
    iso: 'SZ',
    name: 'Swaziland',
    code: '268',
  },
  {
    iso: 'SE',
    name: 'Sweden',
    code: '46',
  },
  {
    iso: 'CH',
    name: 'Switzerland',
    code: '41',
  },
  {
    iso: 'TW',
    name: 'Taiwan',
    code: '886',
  },
  {
    iso: 'TJ',
    name: 'Tajikistan',
    code: '992',
  },
  {
    iso: 'TZ',
    name: 'Tanzania',
    code: '255',
  },
  {
    iso: 'TH',
    name: 'Thailand',
    code: '66',
  },
  {
    iso: 'TL',
    name: 'Timor-Leste',
    code: '670',
  },
  {
    iso: 'TG',
    name: 'Togo',
    code: '228',
  },
  {
    iso: 'TO',
    name: 'Tonga',
    code: '676',
  },
  {
    iso: 'TT',
    name: 'Trinidad and Tobago',
    code: '1-868',
  },
  {
    iso: 'TN',
    name: 'Tunisia',
    code: '216',
  },
  {
    iso: 'TR',
    name: 'Turkey',
    code: '90',
  },
  {
    iso: 'TM',
    name: 'Turkmenistan',
    code: '993',
  },
  {
    iso: 'TC',
    name: 'Turks and Caicos Islands',
    code: '1-649',
  },
  {
    iso: 'TV',
    name: 'Tuvalu',
    code: '688',
  },
  {
    iso: 'UG',
    name: 'Uganda',
    code: '256',
  },
  {
    iso: 'UA',
    name: 'Ukraine',
    code: '380',
  },
  {
    iso: 'AE',
    name: 'United Arab Emirates (UAE)',
    code: '971',
  },
  {
    iso: 'GB',
    name: 'United Kingdom',
    code: '44',
  },
  {
    iso: 'US',
    name: 'United States',
    code: '1',
  },
  {
    iso: 'UY',
    name: 'Uruguay',
    code: '598',
  },
  {
    iso: 'UZ',
    name: 'Uzbekistan',
    code: '998',
  },
  {
    iso: 'VU',
    name: 'Vanuatu',
    code: '678',
  },
  {
    iso: 'VE',
    name: 'Venezuela',
    code: '58',
  },
  {
    iso: 'VN',
    name: 'Vietnam',
    code: '84',
  },
  {
    iso: 'VG',
    name: 'Virgin Islands, British',
    code: '1-284',
  },
  {
    iso: 'VI',
    name: 'Virgin Islands, US',
    code: '1-340',
  },
  {
    iso: 'YE',
    name: 'Yemen',
    code: '967',
  },
  {
    iso: 'ZM',
    name: 'Zambia',
    code: '260',
  },
  {
    iso: 'ZW',
    name: 'Zimbabwe',
    code: '263',
  },
];

export default countryCodes;
