import { useWorkspace } from '~/contexts';
import React from 'react';
import RouteLink from './RouteLink';

function TaskLink({ task, project = task.project, client = project?.client, children, ...props }) {
  const { workspace } = useWorkspace();
  const url = `/app/${workspace.key}/projects/${client.key}/${project.key}/tasks/${task.number}/details`;

  return (
    <RouteLink to={url} {...props}>
      {children || task.name}
    </RouteLink>
  );
}

export default TaskLink;
