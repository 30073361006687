import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Field from './Field';
import Checkbox from './Checkbox';

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  margin: -0.375rem -0.75rem;

  > * {
    margin: 0.375rem 0.75rem;
  }
`;

function InitCheckbox(element, field, helpers, selectedValues, groupProps) {
  if (element.type === Checkbox) {
    const isSelected = selectedValues.includes(element.props.value);

    // initialize the checkbox for the group
    return React.cloneElement(element, {
      name: field.name,
      onBlur: () => {
        helpers.setTouched(true);
      },
      onChange:
        groupProps && groupProps.onChange
          ? groupProps.onChange
          : () => {
              if (isSelected) {
                helpers.setValue(selectedValues.filter((val) => val !== element.props.value));
              } else {
                helpers.setValue([...selectedValues, element.props.value]);
              }
            },
      checked: element.props.checked ?? isSelected,
    });
  } else if (element.props) {
    // return element with recursive init on children
    return React.cloneElement(element, {
      children: React.Children.map(element?.props?.children, (child) =>
        InitCheckbox(child, field, helpers, selectedValues),
      ),
    });
  } else {
    // simple element so just return it
    return element;
  }
}

function FieldCheckboxGroup({ direction = 'horizontal', children, ...props }) {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;
  const selectedValues = field.value || [];

  return (
    <Field.Control error={error}>
      <Group direction={direction}>
        {React.Children.map(children, (child) => {
          return InitCheckbox(child, field, helpers, selectedValues, props);
        })}
      </Group>
    </Field.Control>
  );
}

export default FieldCheckboxGroup;
