import { Confirmation, Icon, InlineTooltip } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useHoursFormat, usePercentFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import {
  AllocationBox,
  AllocationContainer,
  AllocationDetails,
  AllocationStyles,
  ResizeHandle,
} from './AllocationStyles';
import AlwaysVisibleContent from './AlwaysVisibleContent';
import ContextMenu from './ContextMenu';
import DragTooltip from './DragTooltip';
import HoverTooltip from './HoverTooltip';
import ResizeTooltip from './ResizeTooltip';
import useDrag from './useDrag';
import useHover from './useHover';
import useResize from './useResize';

const SplitLine = styled.div`
  position: absolute;
  height: 98%;
  border: 1px dashed ${colors.grey40};
`;

export default function Allocation({
  allocation,
  canvas,
  dateWidth,
  readOnly,
  onDrop,
  onResize,
  onView,
  onEdit,
  onClone,
  onSplit,
  onSplitByDay,
  onSplitByWeek,
  onSplitByMonth,
  onRemoveOverlapping,
  onDelete,
}) {
  const [updating, setUpdating] = useState(false);

  // Define popper parameters
  const [referenceElement, setReferenceElement] = useState(null);
  const updatePopperRef = useRef(null);

  const [menu, setMenu] = useState(false);
  const closeMenu = useCallback(() => setMenu(false), []);
  const openMenu = useCallback((coords) => setMenu(coords), []);

  // Drag & Drop
  const drag = useDrag({
    allocation,
    dateWidth,
    canvas,
    onDrag: updatePopperRef.current,
    onUpdate: setUpdating,
    onClick: (allocation, event) => {
      openMenu({
        clientX: event.clientX,
        pageX: event.pageX,
      });
    },
    onDrop,
  });

  // Resize
  const resize = useResize({
    allocation,
    dateWidth,
    canvas,
    onResize: updatePopperRef.current,
    onUpdate: setUpdating,
    onResizeEnd: onResize,
  });

  // Hover
  const hover = useHover(!menu);

  // Allocation Styles
  const style = useMemo(() => {
    if (drag.event === 'move') {
      return {
        ...allocation.meta.style,
        transform: `translate(${drag.x}px, ${allocation.meta.coords.y}px)`,
        zIndex: 6,
      };
    }

    if (resize.event === 'move') {
      return {
        ...allocation.meta.style,
        transform: `translate(${resize.startX}px, ${allocation.meta.coords.y}px)`,
        width: resize.endX - resize.startX,
        zIndex: 6,
      };
    }

    if (hover.event) {
      return { ...allocation.meta.style, zIndex: 6 };
    }

    return {
      ...allocation.meta.style,
    };
  }, [
    allocation.meta.style,
    allocation.meta.coords.y,
    drag.event,
    drag.x,
    resize.event,
    resize.startX,
    resize.endX,
    hover.event,
  ]);

  const boxStyle = useMemo(() => {
    if (updating || drag.event === 'move' || resize.event === 'move' ? 0.4 : undefined) {
      return {
        opacity: updating || drag.event === 'move' || resize.event === 'move' ? 0.4 : undefined,
        color: colors.grey40,
      };
    }
  }, [updating, drag.event, resize.event]);

  // Tooltip
  const tooltip = useMemo(() => {
    if (updating) return null;

    if (drag.event === 'move')
      return (
        <DragTooltip
          start={drag.start}
          end={drag.end}
          referenceElement={referenceElement}
          updatePopperRef={updatePopperRef}
        />
      );
    else if (resize.event === 'move')
      return (
        <ResizeTooltip
          handle={resize.handle}
          start={resize.start}
          end={resize.end}
          referenceElement={referenceElement}
          updatePopperRef={updatePopperRef}
        />
      );
    else if (hover.event) {
      return (
        <HoverTooltip
          allocationId={allocation.id}
          canvas={canvas}
          referenceElement={referenceElement}
          left={hover.event.x}
        />
      );
    }

    return null;
  }, [
    hover.event,
    drag.event,
    drag.start,
    drag.end,
    resize.event,
    resize.handle,
    resize.start,
    resize.end,
    allocation,
    referenceElement,
    updating,
    canvas,
  ]);

  const split = useMemo(() => {
    if (!menu) return;

    let tooltip;

    switch (allocation.unit) {
      case 'allocation':
      case 'day':
      case 'ratio_of_capacity':
        if (moment(allocation.start).isSame(allocation.end, 'day'))
          tooltip = 'Cannot split a daily allocation spanning a single day.';
        break;

      case 'week':
        if (moment(allocation.start).isSame(allocation.end, 'isoWeek'))
          tooltip = 'Cannot split a weekly allocation spanning a single week.';
        break;

      case 'month':
        if (moment(allocation.start).isSame(allocation.end, 'month'))
          tooltip = 'Cannot split a monthly allocation spanning a single month.';
        break;
    }

    if (tooltip) return { disabled: true, tooltip, date: null, line: null };

    const container = canvas.current;

    const coords = {
      mouse: {
        // The mouse X coordinates.
        x: menu.clientX,
      },

      canvas: {
        // The canvas' left offset relative to the page.
        offsetLeft: container.getBoundingClientRect().left,

        // The canvas' left scrolling offset.
        scrollLeft: container.scrollLeft,
      },

      element: {
        // The X coordinates of the element
        get x() {
          return coords.mouse.x - coords.canvas.offsetLeft + coords.canvas.scrollLeft;
        },
      },
    };

    let date;
    let line;

    switch (allocation.unit) {
      case 'allocation':
      case 'day':
      case 'ratio_of_capacity': {
        const daysOffset = Math.floor((coords.element.x - allocation.meta.coords.x + dateWidth / 2) / dateWidth);
        date = moment(allocation.start).add(daysOffset, 'days').format(dateFormats.isoDate);
        if (moment(date).isSame(allocation.start))
          date = moment(allocation.start).add(1, 'day').format(dateFormats.isoDate);
        if (moment(date).isAfter(allocation.end)) date = moment(allocation.end).format(dateFormats.isoDate);
        line = { left: moment(date).diff(allocation.start, 'days') * dateWidth - 1 };
        break;
      }

      case 'week': {
        let weekWidth = dateWidth * 7;
        const weeksOffset = Math.floor((coords.element.x - allocation.meta.coords.x + weekWidth / 2) / weekWidth);
        date = moment(allocation.start).add(weeksOffset, 'weeks').format(dateFormats.isoDate);
        if (moment(date).isSame(allocation.start))
          date = moment(allocation.start).add(1, 'week').format(dateFormats.isoDate);
        if (moment(date).isAfter(allocation.end)) date = moment(allocation.end).format(dateFormats.isoDate);
        line = { left: moment(date).diff(allocation.start, 'weeks') * weekWidth - 1 };
        break;
      }

      case 'month': {
        // Using 30 as an approximate value since months have different number of days.
        let monthWidth = dateWidth * 30;
        const monthsOffset = Math.floor((coords.element.x - allocation.meta.coords.x + monthWidth / 2) / monthWidth);
        date = moment(allocation.start).add(monthsOffset, 'months').startOf('month').format(dateFormats.isoDate);
        if (moment(date).isSame(allocation.start))
          date = moment(allocation.start).add(1, 'month').format(dateFormats.isoDate);
        if (moment(date).isAfter(allocation.end)) date = moment(allocation.end).format(dateFormats.isoDate);

        let monthsCount = moment(date).diff(allocation.start, 'months');
        let left = -1;
        for (let index = 0; index < monthsCount; index++) {
          left += moment(allocation.start).add(index, 'months').daysInMonth() * dateWidth;
        }
        line = { left };
        break;
      }
    }

    return { disabled: false, tooltip: null, date, line };
  }, [allocation, menu, dateWidth, canvas]);

  const handleSplit = () => {
    onSplit(allocation, split.date);
  };

  const splitByDay = useMemo(() => {
    if (allocation.unit !== 'day') return { disabled: true, tooltip: 'Only daily allocations can be split by day.' };

    const days = moment(allocation.end).diff(moment(allocation.start), 'days');
    if (days === 0) return { disabled: true, tooltip: 'Cannot split an allocation spanning a single day.' };

    return { disabled: false, tooltip: null };
  }, [allocation]);

  const { workspace } = useWorkspace();
  const api = useApi();
  const confirmation = useConfirmation();
  const handleSplitByDay = async () => {
    const { data: count } = await api.www.workspaces(workspace.id).allocations(allocation.id).splitByDayCount();

    await confirmation.prompt((resolve) => {
      return (
        <Confirmation
          title="Split by Day"
          resolve={async (result) => {
            if (result) await onSplitByDay(allocation);
            resolve();
          }}>
          This will split the current allocation into {pluralize('allocation', count, true)}. Are you sure you want to
          continue?
        </Confirmation>
      );
    });
  };

  const splitByWeek = useMemo(() => {
    if (allocation.unit !== 'week') return { disabled: true, tooltip: 'Only weekly allocations can be split by week.' };

    const weeks = moment(allocation.end).diff(moment(allocation.start), 'weeks');
    if (weeks === 0) return { disabled: true, tooltip: 'Cannot split a weekly allocation spanning a single week.' };

    return { disabled: false, tooltip: null };
  }, [allocation]);

  const handleSplitByWeek = async () => {
    const { data: count } = await api.www.workspaces(workspace.id).allocations(allocation.id).splitByWeekCount();

    await confirmation.prompt((resolve) => {
      return (
        <Confirmation
          title="Split by Week"
          resolve={async (result) => {
            if (result) await onSplitByWeek(allocation);
            resolve();
          }}>
          This will split the current allocation into {pluralize('allocation', count, true)}. Are you sure you want to
          continue?
        </Confirmation>
      );
    });
  };

  const splitByMonth = useMemo(() => {
    if (allocation.unit !== 'month')
      return { disabled: true, tooltip: 'Only monthly allocations can be split by month.' };

    const weeks = moment(allocation.end).diff(moment(allocation.start), 'months');
    if (weeks === 0) return { disabled: true, tooltip: 'Cannot split a monthly allocation spanning a single month.' };

    return { disabled: false, tooltip: null };
  }, [allocation]);

  const handleSplitByMonth = async () => {
    const { data: count } = await api.www.workspaces(workspace.id).allocations(allocation.id).splitByMonthCount();

    await confirmation.prompt((resolve) => {
      return (
        <Confirmation
          title="Split by Month"
          resolve={async (result) => {
            if (result) await onSplitByMonth(allocation);
            resolve();
          }}>
          This will split the current allocation into {pluralize('allocation', count, true)}. Are you sure you want to
          continue?
        </Confirmation>
      );
    });
  };

  const interactive = allocation.entity === 'allocation' && allocation.permissions.manage && !readOnly;

  const hoursFormat = useHoursFormat({ minimumFractionDigits: 0 });
  const percentFormat = usePercentFormat({ minimumFractionDigits: 0 });

  const actions = useMemo(() => {
    return {
      get view() {
        if (!onView) return null;
        return { disabled: false };
      },
      get edit() {
        if (!onEdit) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return { disabled: false };
      },
      get clone() {
        if (!onClone) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return { disabled: false };
      },
      get split() {
        if (!onSplit) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return split;
      },
      get splitByDay() {
        if (!onSplitByDay) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return splitByDay;
      },
      get splitByWeek() {
        if (!onSplitByWeek) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return splitByWeek;
      },
      get splitByMonth() {
        if (!onSplitByMonth) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return splitByMonth;
      },
      get removeOverlapping() {
        if (!onRemoveOverlapping) return null;
        if (allocation.resourceTypeId !== 'member') return null;
        if (!allocation.permissions.manageMemberAllocations)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return { disabled: false };
      },
      get delete() {
        if (!onDelete) return null;
        if (!allocation.permissions.manage)
          return { disabled: true, tooltip: 'Insufficient permissions to perform this action.' };
        return { disabled: false };
      },
    };
  }, [
    allocation,
    split,
    splitByDay,
    splitByWeek,
    splitByMonth,
    onView,
    onEdit,
    onClone,
    onSplit,
    onSplitByDay,
    onSplitByWeek,
    onSplitByMonth,
    onDelete,
    onRemoveOverlapping,
  ]);

  const details = useMemo(() => {
    const items = [allocation.role?.name, allocation.task?.name];

    switch (allocation.entity) {
      case 'allocation':
        switch (allocation.unit) {
          case 'day':
            items.push(
              `${hoursFormat.format(allocation.hoursPerDay)} ${pluralize('hour', allocation.hoursPerDay)} per day`,
            );
            break;

          case 'week':
            items.push(
              `${hoursFormat.format(allocation.hoursPerWeek)} ${pluralize('hour', allocation.hoursPerWeek)} per week`,
            );
            break;

          case 'month':
            items.push(
              `${hoursFormat.format(allocation.hoursPerMonth)} ${pluralize('hour', allocation.hoursPerMonth)} per month`,
            );
            break;

          case 'allocation':
            items.push(
              `${hoursFormat.format(allocation.hoursPerAllocation)} ${pluralize('hour', allocation.hoursPerAllocation)} over ${pluralize('day', allocation.days, true)}`,
            );
            break;

          case 'ratio_of_capacity':
            items.push(`${percentFormat.format(allocation.hoursRatioOfCapacity)} of capacity`);
        }

        break;

      case 'time_entry':
      case 'holiday':
        items.push(`${hoursFormat.format(allocation.hoursPerDay)} ${pluralize('hour', allocation.hoursPerDay)}`);
        break;
    }

    return _.compact(items).join(' - ');
  }, [allocation, hoursFormat, percentFormat]);

  return (
    <>
      <AllocationStyles
        key={allocation.id}
        entity={allocation.entity}
        style={style}
        resizing={resize.event}
        data-testid={`allocation_${allocation.id}`}
        data-testid-row={allocation.meta.row}
        data-testid-lane={allocation.meta.lane}
        data-testid-days={allocation.meta.days}
        onClick={allocation.entity === 'allocation' && !interactive ? () => onView(allocation) : undefined}
        onPointerDown={interactive ? drag.handleDragStart : undefined}
        onPointerEnter={hover.handleEnter}
        onPointerLeave={hover.handleLeave}
        ref={setReferenceElement}>
        <AllocationBox allocation={allocation} style={boxStyle}>
          {interactive && (
            <ResizeHandle style={{ left: 0 }} onPointerDown={(event) => resize.handleResizeStart(event, 'start')}>
              <Icon icon="grip-lines-vertical" />
            </ResizeHandle>
          )}

          <AllocationContainer>
            <AlwaysVisibleContent x={drag.x} canvas={canvas}>
              <AllocationDetails>{details}</AllocationDetails>
            </AlwaysVisibleContent>

            {split?.line && <SplitLine style={{ left: `${split.line.left}px` }} />}
          </AllocationContainer>

          {interactive && (
            <ResizeHandle style={{ right: 0 }} onPointerDown={(event) => resize.handleResizeStart(event, 'end')}>
              <Icon icon="grip-lines-vertical" />
            </ResizeHandle>
          )}
        </AllocationBox>
      </AllocationStyles>

      {!menu && tooltip}

      {menu && (
        <ContextMenu
          referenceElement={referenceElement}
          canvas={canvas}
          left={menu.clientX}
          onClose={() => setMenu(false)}>
          {actions.view && (
            <ContextMenu.Item
              onClick={() => {
                closeMenu();
                onView(allocation);
              }}>
              View
            </ContextMenu.Item>
          )}
          {actions.edit && (
            <ContextMenu.Item
              disabled={actions.edit.disabled}
              onClick={() => {
                closeMenu();
                onEdit(allocation);
              }}>
              Edit
              {actions.edit.tooltip && <InlineTooltip message={actions.edit.tooltip} />}
            </ContextMenu.Item>
          )}
          {actions.clone && (
            <ContextMenu.Item
              disabled={actions.clone.disabled}
              onClick={() => {
                closeMenu();
                onClone(allocation);
              }}>
              Clone
              {actions.clone.tooltip && <InlineTooltip message={actions.clone.tooltip} />}
            </ContextMenu.Item>
          )}
          {actions.split && (
            <ContextMenu.Item
              disabled={actions.split.disabled}
              onClick={() => {
                closeMenu();
                handleSplit();
              }}>
              Split
              {actions.split.tooltip && <InlineTooltip message={actions.split.tooltip} />}
            </ContextMenu.Item>
          )}
          {allocation.unit === 'day' && (
            <ContextMenu.Item
              disabled={actions.splitByDay.disabled}
              onClick={() => {
                closeMenu();
                handleSplitByDay();
              }}>
              Split by Day
              {actions.splitByDay.tooltip && <InlineTooltip message={actions.splitByDay.tooltip} />}
            </ContextMenu.Item>
          )}
          {allocation.unit === 'week' && (
            <ContextMenu.Item
              disabled={actions.splitByWeek.disabled}
              onClick={() => {
                closeMenu();
                handleSplitByWeek();
              }}>
              Split by Week
              {actions.splitByWeek.tooltip && <InlineTooltip message={actions.splitByWeek.tooltip} />}
            </ContextMenu.Item>
          )}
          {allocation.unit === 'month' && (
            <ContextMenu.Item
              disabled={actions.splitByMonth.disabled}
              onClick={() => {
                closeMenu();
                handleSplitByMonth();
              }}>
              Split by Month
              {actions.splitByMonth.tooltip && <InlineTooltip message={actions.splitByMonth.tooltip} />}
            </ContextMenu.Item>
          )}
          {actions.removeOverlapping && (
            <ContextMenu.Item
              disabled={actions.removeOverlapping.disabled}
              onClick={() => {
                closeMenu();
                onRemoveOverlapping(allocation);
              }}>
              Remove Overlapping
              {actions.removeOverlapping.tooltip && <InlineTooltip message={actions.removeOverlapping.tooltip} />}
            </ContextMenu.Item>
          )}
          {actions.delete && (
            <ContextMenu.Item
              disabled={actions.delete.disabled}
              onClick={() => {
                closeMenu();
                onDelete(allocation);
              }}>
              Delete
              {actions.delete.tooltip && <InlineTooltip message={actions.delete.tooltip} />}
            </ContextMenu.Item>
          )}
        </ContextMenu>
      )}
    </>
  );
}
