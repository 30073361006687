import { useAuth, useFeatures } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import WorkspaceReportsArea from './workspace/WorkspaceReportsArea';
import ExpenseReportsArea from './expenses/ExpenseReportsArea';
import FinancialReportsArea from './financial/FinancialReportsArea';
import ForecastReportsArea from './forecast/ForecastReportsArea';
import TimeReportsArea from './time/TimeReportsArea';
import UtilizationReportsArea from './utilization/UtilizationReportsArea';
import VarianceReportsArea from './variance/VarianceReportsArea';
import PipelineReportsArea from './pipeline/PipelineReportsArea';

function ReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();
  const features = useFeatures();

  return (
    <Switch>
      <Route path={path.concat('/time')}>
        <TimeReportsArea />
      </Route>

      <Route path={path.concat('/expenses')}>
        <ExpenseReportsArea />
      </Route>

      {features.utilization && (
        <Route path={path.concat('/utilization')}>
          <UtilizationReportsArea />
        </Route>
      )}

      {auth.reports.forecast && (
        <Route path={path.concat('/forecast')}>
          <ForecastReportsArea />
        </Route>
      )}

      {auth.reports.variance && (
        <Route path={path.concat('/variance')}>
          <VarianceReportsArea />
        </Route>
      )}

      {auth.reports.pipeline && (
        <Route path={path.concat('/pipeline')}>
          <PipelineReportsArea />
        </Route>
      )}

      <Route path={path.concat('/financial')}>
        <FinancialReportsArea />
      </Route>

      <Route path={path.concat('/workspace')}>
        <WorkspaceReportsArea />
      </Route>

      <Redirect from="/" to={url.concat('/time')} />
    </Switch>
  );
}

export default ReportsArea;
