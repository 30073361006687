import { QueryString } from '~/utils';
import { get, post, patch, del } from './agent';
import _ from 'lodash';

const subEntity =
  (name, currentUrl) =>
  (id = '') => {
    const url = _([currentUrl, name, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
    };
  };

const projects =
  (currentUrl) =>
  (id = '') => {
    const url = _([currentUrl, 'projects', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      roles: subEntity('roles', url),
      tasks: subEntity('tasks', url),
    };
  };

const timeEntries =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'time-entries', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      getList: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      getDay: (query = {}) => get(url.concat('/day', new QueryString(query).toString(true))),
      getWeek: (query = {}) => get(url.concat('/week', new QueryString(query).toString(true))),
      getWeekAllocations: (query = {}) => get(url.concat('/week/allocations', new QueryString(query).toString(true))),
      getPreviousWeekGroups: (query = {}) => get(url.concat('/week/previous', new QueryString(query).toString(true))),
      copyPreviousTimeEntries: (query = {}) =>
        post(url.concat('/week/previous/copy-entries', new QueryString(query).toString(true))),
      getMonth: (query = {}) => get(url.concat('/month', new QueryString(query).toString(true))),
      getApprovals: (query = {}) => get(url.concat('/approvals', new QueryString(query).toString(true))),
      getAuditing: (query = {}) => get(url.concat('/auditing', new QueryString(query).toString(true))),
      getWeekFiles: (query = {}) => get(url.concat('/week-files', new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      upsertDay: (body) => post(url.concat('/day'), body),
      updateAuditing: (body) => patch(url.concat('/auditing'), body),
      delete: () => del(url),
      deleteWeek: (query = {}) => del(url.concat('/week', new QueryString(query).toString(true))),
      submitDay: (query = {}) => post(url.concat('/submit/day', new QueryString(query).toString(true))),
      submitWeek: (query = {}) => post(url.concat('/submit/week', new QueryString(query).toString(true))),
      submitMonth: (query = {}) => post(url.concat('/submit/month', new QueryString(query).toString(true))),
      getTimer: () => get(url.concat('/timer')),
      startTimer: () => post(url.concat('/timer')),
      stopTimer: () => del(url.concat('/timer')),
      memberTargets: (query = {}) => get(url.concat('/member-targets', new QueryString(query).toString(true))),
      timeOffTypes: (query = {}) => get(url.concat('/time-off-types', new QueryString(query).toString(true))),
      projects: projects(url),
      roles: subEntity('roles', url),
      taskDetails: subEntity('task-details', url),
      tasks: subEntity('tasks', url),
      batchUpdateStatus: (body) => post(url.concat('/approvals/batch'), body),
      approvalWorkflow: {
        get: () => get(url.concat('/approval-workflow')),
        post: (body) => post(url.concat('/approval-workflow'), body),
      },
    };
  };

export default timeEntries;
