import _ from 'lodash';
import { del, get, patch, post } from './agent';
import { QueryString } from '~/utils';

const qbd = (workspacesUrl) => {
  const url = _([workspacesUrl, 'qbd']).compact().join('/');

  return {
    get: () => get(url),
    setup: (body) => post(url, body),
    updateSettings: (body) => patch(url.concat('/settings'), body),
    remove: () => del(url),
    getProjects: (query = {}) => get(url.concat('/projects').concat(new QueryString(query).toString(true))),
    getSelectedProjects: () => get(url.concat('/selected-projects')),
  };
};

export default qbd;
