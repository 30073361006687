import { useCallback, useMemo, useState } from 'react';
import useSearchParamsConfig from './useSearchParamsConfig';

export default function useMemberFilters(defaultSearchParams) {
  const filters = useMemo(
    () => ({
      members: [],
      employmentTypes: [],
      memberBillableType: null,
      memberStatus: null,
      memberDisciplines: [],
      jobTitles: [],
      memberPractices: [],
      managers: [],
      memberSkills: [],
      memberTags: [],
      memberCostMethod: null,
      memberLocations: [],
      memberInvitationStatuses: [],
    }),
    [],
  );

  const [defaultParams] = useState(defaultSearchParams);

  const searchParamsConfig = useSearchParamsConfig();

  const memberSearchParamsConfig = useMemo(
    () => ({
      members: searchParamsConfig.members,
      employmentTypes: searchParamsConfig.employmentTypes,
      memberBillableType: searchParamsConfig.memberBillableType,
      memberStatus: searchParamsConfig.memberStatus,
      memberDisciplines: { ...searchParamsConfig.disciplines, key: 'memberDiscipline' },
      jobTitles: searchParamsConfig.jobTitles,
      projectPractices: { ...searchParamsConfig.practices, key: 'projectPractice' },
      memberPractices: { ...searchParamsConfig.practices, key: 'memberPractice' },
      managers: { ...searchParamsConfig.members, key: 'manager' },
      memberSkills: { ...searchParamsConfig.skills, key: 'memberSkill' },
      memberTags: searchParamsConfig.memberTags,
      memberCostMethod: searchParamsConfig.memberCostMethod,
      memberLocations: { ...searchParamsConfig.locations, key: 'memberLocation' },
      memberInvitationStatuses: searchParamsConfig.memberInvitationStatuses,
      ...defaultParams,
    }),
    [searchParamsConfig, defaultParams],
  );

  const mapUrlSearchParams = useCallback(
    (filters) => ({
      memberId: filters.members?.map((v) => v.id),
      employmentTypeId: filters.employmentTypes?.map((v) => v.id),
      memberBillableTypeId: filters.memberBillableType ?? undefined,
      memberStatusId: filters.memberStatus ?? undefined,
      memberDisciplineId: filters.memberDisciplines?.map((v) => v.id),
      jobTitleId: filters.jobTitles?.map((v) => v.id),
      memberPracticeId: filters.memberPractices?.map((v) => v.id),
      managerId: filters.managers?.map((v) => v.id),
      memberSkillId: filters.memberSkills?.map((v) => v.id),
      memberTagId: filters.memberTags?.map((v) => v.id),
      memberCostMethodId: filters.memberCostMethod ?? undefined,
      memberLocationId: filters.memberLocations?.map((v) => v.id),
      memberInvitationStatusId: filters.memberInvitationStatuses?.map((v) => v.id),
    }),
    [],
  );

  const mapClickThroughParams = useCallback(
    (filters) => ({
      member: filters.members?.map((v) => v.id),
      employmentType: filters.employmentTypes?.map((v) => v.id),
      memberBillableType: filters.memberBillableType ?? undefined,
      memberStatus: filters.memberStatus ?? undefined,
      memberDiscipline: filters.memberDisciplines?.map((v) => v.id),
      jobTitle: filters.jobTitles?.map((v) => v.id),
      memberPractice: filters.memberPractices?.map((v) => v.id),
      manager: filters.managers?.map((v) => v.id),
      memberSkill: filters.memberSkills?.map((v) => v.id),
      memberTag: filters.memberTags?.map((v) => v.id),
      memberCostMethod: filters.memberCostMethod ?? undefined,
      memberLocation: filters.memberLocations?.map((v) => v.id),
      memberInvitationStatus: filters.memberInvitationStatuses?.map((v) => v.id),
    }),
    [],
  );

  return useMemo(
    () => ({ filters, searchParamsConfig: memberSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams }),
    [filters, memberSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams],
  );
}
