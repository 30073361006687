import React from 'react';
import EmploymentTypeTag from './EmploymentTypeTag';
import JobTitleTag from './JobTitleTag';
import MemberCostMethodTag from './MemberCostMethodTag';
import ManagerTag from './ManagerTag';
import MemberBillableTypeTag from './MemberBillableTypeTag';
import MemberDisciplineTag from './MemberDisciplineTag';
import MemberLocationTag from './MemberLocationTag';
import MemberSkillsTag from './MemberSkillsTag';
import MemberStatusTag from './MemberStatusTag';
import MemberTag from './MemberTag';
import MemberTags from './MemberTags';
import PracticeTag from './PracticeTag';
import SecurityRoleTag from './SecurityRoleTag';
import MemberInvitationStatusTag from './MemberInvitationStatusTag';
import PeriodTag from './PeriodTag';
import intervalOptions from '~/utils/intervalOptions';

function MemberTagsGroup({ filters, onChange }) {
  return (
    <>
      <PeriodTag
        value={filters.memberActive}
        label="Member Active"
        onRemove={() => onChange({ memberActive: intervalOptions.all_dates })}
      />

      <MemberTag value={filters.members} onRemove={() => onChange({ members: [] })} />

      <SecurityRoleTag value={filters.securityRoles} onRemove={() => onChange({ securityRoles: [] })} />

      <EmploymentTypeTag value={filters.employmentTypes} onRemove={() => onChange({ employmentTypes: [] })} />

      <MemberBillableTypeTag
        value={filters.memberBillableType}
        onRemove={() => onChange({ memberBillableType: null })}
      />

      <MemberStatusTag value={filters.memberStatus} onRemove={() => onChange({ memberStatus: null })} />

      <MemberDisciplineTag value={filters.memberDisciplines} onRemove={() => onChange({ memberDisciplines: [] })} />

      <JobTitleTag value={filters.jobTitles} onRemove={() => onChange({ jobTitles: [] })} />

      <PracticeTag type="member" value={filters.memberPractices} onRemove={() => onChange({ memberPractices: [] })} />

      <ManagerTag value={filters.managers} onRemove={() => onChange({ managers: [] })} />

      <MemberSkillsTag value={filters.memberSkills} onRemove={() => onChange({ memberSkills: [] })} />

      <MemberTags value={filters.memberTags} onRemove={() => onChange({ memberTags: [] })} />

      <MemberCostMethodTag value={filters.memberCostMethod} onRemove={() => onChange({ memberCostMethod: null })} />

      <MemberLocationTag value={filters.memberLocations} onRemove={() => onChange({ memberLocations: [] })} />

      <MemberInvitationStatusTag
        value={filters.memberInvitationStatuses}
        onRemove={() => onChange({ memberInvitationStatuses: [] })}
      />
    </>
  );
}

export default MemberTagsGroup;
