import { Formik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useWorkspace, useApi } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { mergeValues, emptyStringToNull } from '~/utils';

export default function MemberSettings({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      inviteMembers: false,
      setBillable: false,
      updateMemberStatus: false,
      updateActiveDates: false,
      updateName: false,
      updateJobTitle: false,
      updateManager: false,
      updateInternalId: false,
      usePreferredName: true,
      useMiddleName: false,
      autoMemberMapping: true,
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Member Settings"
      byline="BambooHR Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);
            await api.www.workspaces(workspace.id).bamboohr.updateSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              inviteMembers: Yup.bool().label('Invite Created Members').required(),
              setBillable: Yup.bool().label('Create Billable Members').required(),
              updateMemberStatus: Yup.bool().label('Update Member Status').required(),
              updateActiveDates: Yup.bool().label('Update Member Active Dates').required(),
              updateName: Yup.bool().label('Update Name').required(),
              updateJobTitle: Yup.bool().label('Update Job Title').required(),
              updateManager: Yup.bool().label('Update Manager').required(),
              updateInternalId: Yup.bool().label('Update Internal ID').required(),
              autoMemberMapping: Yup.bool().label('Member Mapping').required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Invite Created Members"
                    subtitle="When members are created in Ruddr, automatically send them an invite.">
                    <Form.Control>
                      <Field.RadioGroup name="inviteMembers" direction="vertical">
                        <Radio value={true} label="On - Newly created members will automatically be sent an invite" />
                        <Radio value={false} label="Off - Do not send invites to newly created members" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Create Billable Members"
                    subtitle="When members are created in Ruddr, set them as billable members.">
                    <Form.Control>
                      <Field.RadioGroup name="setBillable" direction="vertical">
                        <Radio value={true} label="On - Newly created members will be set as billable" />
                        <Radio value={false} label="Off - Newly created members will be set as non-billable" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Status"
                    subtitle="Sync the active or inactive employee status from BambooHR to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberStatus" direction="vertical">
                        <Radio value={true} label="On - Set the member status based on the BambooHR setting" />
                        <Radio value={false} label="Off - Do not set the member status based on the BambooHR setting" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Active Dates"
                    subtitle="Sync the BambooHR hire date and termination dates as the Ruddr member's active date range.">
                    <Form.Control>
                      <Field.RadioGroup name="updateActiveDates" direction="vertical">
                        <Radio
                          value={true}
                          label="On - Set and update the member active date range based on the BambooHR settings"
                        />
                        <Radio
                          value={false}
                          label="Off - Do not set or update the member active date range based on the BambooHR settings"
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Name"
                    subtitle="Sync the employee name from BambooHR to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="updateName" direction="vertical">
                        <Radio value={true} label="On - Update the member names" />
                        <Radio value={false} label="Off - Do not update member names" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Job Title"
                    subtitle="Sync the job title from BambooHR to Ruddr members. This will create additional job titles in Ruddr if they do not exist already.">
                    <Form.Control>
                      <Field.RadioGroup name="updateJobTitle" direction="vertical">
                        <Radio value={true} label="On - Set and update job titles" />
                        <Radio value={false} label="Off - Do not set or update job titles" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Manager"
                    subtitle="Set the manager for Ruddr members. This will depend on the manager email address being mapped to a Ruddr member.">
                    <Form.Control>
                      <Field.RadioGroup name="updateManager" direction="vertical">
                        <Radio value={true} label="On - Set and update a Ruddr member's manager" />
                        <Radio value={false} label="Off - Do not set or update a Ruddr member's manager" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Internal ID"
                    subtitle="Set the Ruddr member's internal ID to the BambooHR employee ID.">
                    <Form.Control>
                      <Field.RadioGroup name="updateInternalId" direction="vertical">
                        <Radio value={true} label="On - Set and update the Ruddr member's internal ID" />
                        <Radio value={false} label="Off - Do not set or update a Ruddr member's internal ID" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Use Preferred Name"
                    subtitle="Use the BambooHR employee's preferred name (if it exists) as the Ruddr member's first name.">
                    <Form.Control>
                      <Field.RadioGroup name="usePreferredName" direction="vertical">
                        <Radio
                          value={true}
                          label="On - Use the employee's preferred name when setting or updating the member's first name"
                        />
                        <Radio
                          value={false}
                          label="Off - Do not use the employee's preferred name when setting or updating the member's first name"
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Include Middle Name"
                    subtitle="Add the BambooHR employee's middle name to the Ruddr member's name.">
                    <Form.Control>
                      <Field.RadioGroup name="useMiddleName" direction="vertical">
                        <Radio
                          value={true}
                          label="On - Include the middle name when setting or updating the member's name"
                        />
                        <Radio
                          value={false}
                          label="Off - Do not include the middle name when setting or updating the member's name"
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Member Mapping"
                    subtitle="Choose the type of mapping you would like to use for BambooHR employees to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="autoMemberMapping" direction="vertical">
                        <Radio value={true} label="Automatic - Map automatically by matching on work email address" />
                        <Radio value={false} label="Manual - Manually control the mapping" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
