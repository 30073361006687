import moment from 'moment';
import React, { useMemo } from 'react';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import theme from '../theme';
import { HeaderCell, HeaderCellContent, HeaderColumn, HeaderGroupCellContent, HeaderRow } from './HeaderStyles';

function DayHeader({ start, end, styles, onDateChange }) {
  const days = useMemo(() => {
    const dayCount = moment(end).diff(start, 'days') + 1;

    const days = [];

    for (let index = 0; index < dayCount; index++) {
      const date = moment(start).add(index, 'days');

      days.push({
        key: date.format(dateFormats.isoDate),
        day: date.format('D'),
        weekDay: date.format('ddd').substr(0, 1),
        today: moment().isSame(date, 'day'),
        style: {
          width: styles.date.width,
          borderColor: date.isoWeekday() === 7 ? theme.line.strong : theme.line.soft,
        },
      });
    }

    return days;
  }, [start, end, styles.date.width]);

  const weeks = useMemo(() => {
    const weekCount = moment(end).diff(start, 'weeks') + 1;

    const weeks = [];

    for (let index = 0; index < weekCount; index++) {
      const date = moment(start).add(index, 'weeks');

      const startMonth = date.format('MMM');
      const endMonth = date.clone().endOf('isoWeek').format('MMM');

      let month = startMonth;
      if (startMonth !== endMonth) month.concat(` - ${endMonth}`);

      weeks.push({
        key: date.format(dateFormats.isoDate),
        week: date.format('W'),
        month,
        year: date.format('YYYY'),
        style: {
          width: styles.date.width * 7,
          borderColor: colors.grey10,
        },
      });
    }

    return weeks;
  }, [start, end, styles.date.width]);

  return (
    <HeaderColumn>
      <HeaderRow>
        {weeks.map((week) => {
          const handleClick = () => onDateChange(week.key);

          return (
            <HeaderCell key={week.key} style={week.style} onClick={handleClick}>
              <HeaderGroupCellContent>
                <span>Week {week.week}</span>
                <span>{week.month}</span>
              </HeaderGroupCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>

      <HeaderRow>
        {days.map((day) => {
          const handleClick = () => onDateChange(day.key);

          return (
            <HeaderCell key={day.key} style={day.style} today={day.today} onClick={handleClick}>
              <HeaderCellContent style={{ justifyContent: 'center', textAlign: 'center' }}>
                {day.day}
                <br />
                {day.weekDay}
              </HeaderCellContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>
    </HeaderColumn>
  );
}

export default React.memo(DayHeader);
