import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { Footer, Header } from '../components';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Heading = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 39rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  margin-top: 2rem;
  padding: 0 2em;
  color: ${colors.white};
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

export default function EmailVerified() {
  const urlParams = new URLSearchParams(window.location.search);
  const workspace = urlParams.get('workspace');

  return (
    <>
      <Header />
      <Container data-testid="email_verified">
        <Heading>
          <Title>Your email has been verified</Title>
          <Byline>Your Ruddr account has been activated.</Byline>
        </Heading>
        <Content>
          <ActionLink to={workspace ? `/app/${workspace}` : '/app'}>Go to Dashboard</ActionLink>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
