import {
  Checkbox,
  ClientTagMultiSelect,
  CloudFolderLink,
  Field,
  Form,
  Icon,
  InvoiceVariablesDialog,
  RegionalFormatExamples,
  Tooltip,
} from '~/components';
import { useConfirmation, useWorkspace } from '~/contexts';
import { useFeatures } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import * as Yup from 'yup';

const ControlLabel = styled.p`
  display: flex;
  color: ${colors.grey75};

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

const ViewCompanyIcon = styled(Icon)`
  left: calc(100% + 1rem);
  position: absolute;
  top: 1.5rem;
  color: ${colors.grey25};

  &:hover {
    color: ${colors.grey55};
    cursor: pointer;
  }
`;

const CompanySelectWrapper = styled.div`
  flex-grow: 1;
`;

const VariablesContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  > a {
    margin-left: auto;
  }
`;

const StyledLink = styled.a`
  margin-top: -1rem;
`;

const taxableItems = {
  services: 'Services',
  expenses: 'Expenses',
  other_items: 'Other Items to Bill',
};

function ClientForm({ client, formik }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const confirmation = useConfirmation();
  const invoiceNotesRef = useRef(null);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('email', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('emails', values);
  };

  const handleCcEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('ccEmail', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('ccEmails', values);
  };

  const processEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/emails\[(\d+)\]/, (match, p1) => `To Email Address #${parseInt(p1) + 1}`);
    });
  };

  const processCcEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/ccEmails\[(\d+)\]/, (match, p1) => `Cc Email Address #${parseInt(p1) + 1}`);
    });
  };

  const handleTaxableItemsChange = (name) => {
    let fields;
    if (formik.values.invoiceTaxableItems.some((v) => v === name)) {
      fields = formik.values.invoiceTaxableItems.filter((v) => v !== name);
    } else {
      fields = [...formik.values.invoiceTaxableItems, name];
    }
    formik.setFieldValue('invoiceTaxableItems', fields);
  };

  const handleOpenInvoiceVariablesDialog = async () => {
    await confirmation.prompt((resolve) => (
      <InvoiceVariablesDialog
        onClose={() => resolve(true)}
        onSaved={(variables) => {
          let selectionStart = invoiceNotesRef.current.selectionStart;
          if (!formik.touched.invoiceNotes) {
            selectionStart = formik.values.invoiceNotes.length;
          }

          const updatedValue =
            formik.values.invoiceNotes.slice(0, selectionStart) +
            variables.join(' ') +
            formik.values.invoiceNotes.slice(selectionStart);

          formik.setFieldValue('invoiceNotes', updatedValue);
          resolve(true);
        }}
      />
    ));
  };

  return (
    <Form>
      <Form.Section title="Basic Information">
        <Form.Control>
          <Field.Text name="name" placeholder="Name" maxLength={255} />
        </Form.Control>

        <Form.Control help="The client identifier used in URLs. Must be unique. Changing this will break existing links to the client and all projects assigned to the client.">
          <Field.Text name="key" placeholder="Client URL ID" maxLength={255} />
        </Form.Control>

        {features.pipeline && (
          <Form.Control>
            <div style={{ display: 'flex' }}>
              <CompanySelectWrapper>
                <Field.CompanySelect
                  name="company"
                  placeholder="Company"
                  allowNew
                  initialValue={client.company}
                  withoutClientAssigned
                />
              </CompanySelectWrapper>
              {formik.values.company && (
                <a
                  href={`/app/${workspace.key}/pipeline/companies/${formik.values.company.id}/overview`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Tooltip message={'View Company'} placement="top">
                    <ViewCompanyIcon icon="external-link-alt" />
                  </Tooltip>
                </a>
              )}
            </div>
          </Form.Control>
        )}

        <Form.Control>
          <Field.IndustrySelect name="industry" placeholder="Industry" allowNew />
        </Form.Control>

        {features.practices && (
          <Form.Control>
            <Field.PracticeSelect name="practice" placeholder="Practice" allowNew />
          </Form.Control>
        )}

        {!client.isInternal && (
          <>
            <Form.Control>
              <Field.MemberSelect name="owner" placeholder="Relationship Owner" initialValue={client && client.owner} />
            </Form.Control>
            <Form.Control>
              <Field.MemberSelect
                name="salesRepresentative"
                placeholder="Sales Representative"
                initialValue={client && client.salesRepresentative}
              />
            </Form.Control>
          </>
        )}
        <Form.Control>
          <Field.Text name="code" placeholder="Client Code" maxLength={255} />
        </Form.Control>
        <Form.Control>
          <Field.Control>
            <ClientTagMultiSelect
              name="tags"
              placeholder="Tags"
              value={formik.values.tags}
              allowNew
              onChange={({ target: { value } }) => formik.setFieldValue('tags', value)}
            />
          </Field.Control>
        </Form.Control>

        <Form.Control>
          <Field.LocationSelect name="location" placeholder="Location" allowNew />
        </Form.Control>

        <Form.Control>
          <Field.TextArea name="notes" placeholder="Notes" maxLength={5000} />
        </Form.Control>
      </Form.Section>

      <Form.Section
        title="Regional Settings"
        subtitle="These settings allow you to control the currency and regional format.">
        <Form.Control>
          <Field.WorkspaceCurrencySelect
            name="currency"
            clearable={false}
            disabled={!features.multicurrency && client.currency === workspace.currency}
          />
        </Form.Control>

        <Form.Control>
          <div>
            <Field.RegionalFormatSelect name="locale" />
            <RegionalFormatExamples locale={formik.values.locale ?? workspace.locale} />
          </div>
        </Form.Control>
      </Form.Section>

      <Form.Section
        title="Cloud Folder"
        help="Assign a shared cloud folder to this client to let team members view files.">
        <Form.Control>
          <div>
            <Field.Text name="cloudFolderUrl" placeholder="Cloud Folder URL" maxLength={1024} />
          </div>
          {!!formik.values.cloudFolderUrl && Yup.string().url().isValidSync(formik.values.cloudFolderUrl) && (
            <div style={{ flex: '0 0 3rem', alignSelf: 'center' }}>
              <CloudFolderLink url={formik.values.cloudFolderUrl} />
            </div>
          )}
        </Form.Control>
      </Form.Section>

      {!client.isInternal && (
        <Form.Section title="Invoice details">
          <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
            <Field.Text
              name="emails"
              placeholder="To Email Address"
              value={formik.values.email}
              onChange={handleEmailChange}
              onProcessErrors={processEmailErrors}
            />
          </Form.Control>
          <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
            <Field.Text
              name="ccEmails"
              placeholder="Cc Email Address"
              value={formik.values.ccEmail}
              onChange={handleCcEmailChange}
              onProcessErrors={processCcEmailErrors}
            />
          </Form.Control>
          <Form.Control>
            <Field.TextArea
              name="streetAddress"
              placeholder="Client's Invoice Street Address"
              maxLength={5000}
              rows={4}
            />
          </Form.Control>
          <Form.Control>
            <Field.Checkbox name="useWorkspaceInvoiceDetails" label="Use workspace invoice details" />
          </Form.Control>

          {formik.values.useWorkspaceInvoiceDetails ? (
            <>
              <Form.Control>
                <Field.PaymentTermsSelect
                  name="paymentTermsId"
                  placeholder="Payment Terms"
                  value={workspace.paymentTermsId}
                  disabled
                />
              </Form.Control>
              <Form.Control>
                <Field.TaxRateSelect
                  name="invoiceTaxRate"
                  placeholder="Tax Rate"
                  value={workspace.invoiceTaxRate}
                  clearable={false}
                  disabled
                />
              </Form.Control>
              <ControlLabel>The following items are taxable by default:</ControlLabel>
              <Form.Control>
                <Checkboxes>
                  {_.map(taxableItems, (value, key) => (
                    <Checkbox
                      key={key}
                      label={value}
                      checked={workspace.invoiceTaxableItems.some((v) => v === key)}
                      disabled
                    />
                  ))}
                </Checkboxes>
              </Form.Control>
              <Form.Control>
                <Field.TextArea
                  name="invoiceNotes"
                  placeholder="Invoice Note"
                  disabled
                  maxLength={5000}
                  value={workspace.invoiceNotes || ''}
                />
              </Form.Control>
            </>
          ) : (
            <>
              <Form.Control>
                <Field.PaymentTermsSelect name="paymentTermsId" placeholder="Payment Terms" />
              </Form.Control>
              <Form.Control>
                <Field.TaxRateSelect name="invoiceTaxRate" placeholder="Tax Rate" />
              </Form.Control>
              <ControlLabel>The following items are taxable by default:</ControlLabel>
              <Form.Control>
                <Checkboxes>
                  {_.map(taxableItems, (value, key) => (
                    <Checkbox
                      key={key}
                      label={value}
                      checked={formik.values.invoiceTaxableItems.some((v) => v === key)}
                      onChange={() => handleTaxableItemsChange(key)}
                    />
                  ))}
                </Checkboxes>
              </Form.Control>
              <VariablesContainer>
                <StyledLink onClick={() => handleOpenInvoiceVariablesDialog()}>Insert Variables</StyledLink>
                <Form.Control>
                  <Field.TextArea
                    ref={invoiceNotesRef}
                    name="invoiceNotes"
                    placeholder="Invoice Note"
                    maxLength={5000}
                  />
                </Form.Control>
              </VariablesContainer>
            </>
          )}
        </Form.Section>
      )}
    </Form>
  );
}

export default ClientForm;
