import React from 'react';
import { Buttons, CancelButton, ModalCard } from '~/components';

function SlackError({ onClose }) {
  return (
    <ModalCard title="Slack Integration Issue" onClose={onClose}>
      <ModalCard.Body>
        The Slack workspace is already integrated with another Ruddr workspace. Please remove that integration before
        continuing.
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>OK</CancelButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default SlackError;
