import {
  Button,
  DeleteConfirmation,
  Dropdown,
  ExportDialog,
  Icon,
  Level,
  SearchInput,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect, CustomDataHeader } from '../custom-data/components';
import ExportDropdown from '../ExportDropdown';
import TimeOffTypeDrawer from './TimeOffTypeDrawer';
import { colors } from '~/styles';

function TimeOffTypesPage() {
  const documentTitle = useDocumentTitle('Time Off Types');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).timeOffTypes().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .timeOffTypes()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (timeOffType) => {
    setDialog(
      <TimeOffTypeDrawer
        timeOffType={timeOffType}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Time Off Types');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).timeOffTypes(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(timeOffType) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Time Off Type?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .timeOffTypes(timeOffType ? timeOffType.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Time Off Types</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Workspace members can track both project time and time off. Each workspace can have custom time off types,
            such as vacation or sick leave.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Button onClick={() => handleEdit({})}>New Time Off Type</Button>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Name"
            materialAlwaysVisible
            onChange={handleFilterChange}
          />
        </Level.Item>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </Level.Item>
        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('time_off_types.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('time_off_types.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Time Off Type" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column>Name</Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((timeOffType) => {
            const { name, isActive, id, typeId } = timeOffType;

            return (
              <Table.BoxRow onClick={() => handleEdit(timeOffType)} key={id} isDisabled={!isActive}>
                <Table.Cell>
                  {name}
                  {typeId === 'holiday' && (
                    <Tooltip message="This Time Off Type is used by Ruddr when automatically creating holiday time off time entries.">
                      <Icon icon="calendar-alt" type="far" color={colors.grey40} spaceLeft />
                    </Tooltip>
                  )}
                </Table.Cell>
                <TableBoxRowActions>
                  <>
                    <TableBoxRowActions.Edit onClick={() => handleEdit(timeOffType)} />

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => (
                        <>
                          <Dropdown.Item onClick={() => handleEdit(timeOffType)}>Edit</Dropdown.Item>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleActiveStatusChange(timeOffType, !isActive);
                              setIsOpen(false);
                            }}>
                            {isActive ? 'Deactivate' : 'Activate'}
                          </Dropdown.Item>
                          <Dropdown.DeleteItem
                            tooltip={
                              typeId === 'holiday'
                                ? 'This Time Off Type is used by Ruddr when automatically creating holiday time off time entries.'
                                : 'This item is currently in use.'
                            }
                            onCheckDependencies={async (workspace) =>
                              (await workspace.timeOffTypes(id).hasDependencies()).data
                            }
                            disabled={typeId === 'holiday'}
                            onClick={() => handleDelete(timeOffType)}>
                            Delete
                          </Dropdown.DeleteItem>
                        </>
                      )}
                    </TableBoxRowActions.Dropdown>
                  </>
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </Table>

      {dialog}
    </>
  );
}

export default TimeOffTypesPage;
