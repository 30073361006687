import React from 'react';
import styled from 'styled-components';
import { Avatar, Button, DeleteButton, Icon } from '~/components';
import { colors, weights } from '~/styles';
import { useImpersonation } from '../contexts';

const RecentArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 0;
`;

const RecentLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-align: center;
  text-transform: uppercase;
`;

const None = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 12rem;
  color: ${colors.grey10};
  font-size: 2rem;
  font-weight: ${weights.black};
`;

const Member = styled.div`
  position: relative;
  display: flex;
  padding: 1.5rem 0;

  &:not(:last-child) {
    border-bottom: solid 1px ${colors.grey5};
  }
`;

const MemberInfo = styled.div`
  display: flex;
  flex: 1;
`;

const MemberDetails = styled.div`
  display: flex;
  width: 13.5rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Logo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1.25rem;
`;

const WorkspaceImage = styled.img`
  max-height: 100%;
  object-fit: contain;
`;

const BuildingIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 1.25rem;
`;

const ImpersonateButton = styled(Button)`
  width: 2.5rem;
  padding: 0;
`;

const DeactivateButton = styled(DeleteButton)`
  width: 2.5rem;
  padding: 0;
`;

const RemoveButton = styled(Button)`
  margin-left: 1.25rem;
  color: ${colors.grey25};

  &:hover {
    color: ${colors.danger};
  }
`;

export default function LatestImpersonations() {
  const { impersonatedMemberId, impersonate, stopImpersonation, latestMembers, removeFromLatest } = useImpersonation();

  return (
    <RecentArea>
      <RecentLabel>Recent</RecentLabel>
      {latestMembers.length === 0 ? (
        <None>None</None>
      ) : (
        latestMembers.map((member) => (
          <Member key={member.id}>
            <Actions>
              {member.id === impersonatedMemberId ? (
                <DeactivateButton onClick={() => stopImpersonation()}>
                  <Icon icon="user-secret" />
                </DeactivateButton>
              ) : (
                <ImpersonateButton onClick={() => impersonate(member.id)}>
                  <Icon icon="user-secret" />
                </ImpersonateButton>
              )}
            </Actions>
            <MemberInfo>
              <MemberDetails>
                <Avatar value={member} size={40} isCircle hasBackground />
                <Info>
                  <Title>{member.name}</Title>
                  <Subtitle>{member.email}</Subtitle>
                </Info>
              </MemberDetails>
              <MemberDetails>
                <Logo>
                  {member.workspace.imageUrl ? (
                    <WorkspaceImage src={member.workspace.imageUrl} alt={member.workspace.name} />
                  ) : (
                    <BuildingIcon icon="building" />
                  )}
                </Logo>
                <Info>
                  <Title>{member.workspace.name}</Title>
                  <Subtitle>/app/{member.workspace.key}</Subtitle>
                </Info>
              </MemberDetails>
            </MemberInfo>
            {member.id !== impersonatedMemberId && (
              <RemoveButton isAnchor={true} onClick={() => removeFromLatest(member.id)}>
                <Icon icon="times" />
              </RemoveButton>
            )}
          </Member>
        ))
      )}
    </RecentArea>
  );
}
