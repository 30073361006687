import { Formik } from 'formik';
import _ from 'lodash';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useWorkspace, useApi } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { mergeValues, emptyStringToNull } from '~/utils';

export default function CategorySettingsDrawer({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      createCategories: true,
      defaultExpenseCategory: null,
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Expense Category Settings"
      byline="Expensify Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull({
              ..._.omit(values, 'defaultExpenseCategory'),
              defaultExpenseCategoryId: values.defaultExpenseCategory?.id ?? null,
            });

            await api.www.workspaces(workspace.id).expensify.updateCatgorySettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              createCategories: Yup.bool().label('Create Expense Categories').required(),
              defaultExpenseCategory: Yup.object().label('Default Expense Category').nullable(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Creating Categories"
                    subtitle="Allow Expensify categories to be created in Ruddr, as-needed.">
                    <Form.Control>
                      <Field.RadioGroup name="createCategories" direction="vertical">
                        <Radio value={true} label="On - Import an Expensify category if it doesn't exist in Ruddr" />
                        <Radio value={false} label="Off - Do not import expenses without a matching Ruddr category" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Default Expense Category"
                    subtitle="This will be used when an expense doesn't have a category. If not selected, the expense will not be imported.">
                    <Form.Control>
                      <Field.ExpenseCategorySelect
                        name="defaultExpenseCategory"
                        placeholder="Default Expense Category"
                        initialValue={integration?.defaultExpenseCategory?.id}
                      />
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
