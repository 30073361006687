import _ from 'lodash';
import moment from 'moment';

function formatDecimal(duration, trim, locale) {
  let options;

  const hours = Math.round(duration.asHours() * 100) / 100;

  if (_.isInteger(hours) && trim) {
    options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
  } else {
    options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
  }

  return Intl.NumberFormat(locale ?? undefined, options).format(hours);
}

function formatClock(duration, isReadable, showSeconds, trim) {
  let format = showSeconds ? 'h:mm:ss' : 'h:mm';
  const options = { trim: false, trunc: true };

  if (isReadable) {
    format = showSeconds ? 'h __ m __' : 'h __ m __ s __';
    options.trim = 'all';
  } else if (trim) {
    options.trim = 'small';
    options.stopTrim = 'h';
  }
  return duration.format(format, options);
}

export default function formatDuration(duration, options) {
  if (!moment.isDuration(duration)) {
    throw new TypeError('duration must be a moment duration object');
  }

  const formatOptions = _.defaults({}, options, {
    isReadable: false,
    useDecimal: false,
    showSeconds: false,
    trim: false,
    locale: undefined,
  });

  const { isReadable, useDecimal, showSeconds, trim, locale } = formatOptions;

  if (isReadable || !useDecimal) {
    return formatClock(duration, isReadable, showSeconds, trim);
  }
  return formatDecimal(duration, trim, locale);
}
