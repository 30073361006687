import styled from 'styled-components';
import { colors } from '~/styles';

export const GridContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  background: ${colors.white};
  min-height: 353px;
`;

export const Body = styled.div`
  /* Layout */
  overflow: hidden;
  overflow-y: scroll;
  flex: 1 1 0%;
  position: relative;
  margin-top: -4px;
`;

export const Content = styled.div`
  /* Layout */
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  transition: opacity 150ms ease-in-out;
`;
