import React from 'react';
import { Table } from '~/components';
import { useWorkspace } from '~/contexts';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Table.Row)`
  &:not(:first-child) {
    margin-top: 1rem;
  }

  p {
    font-weight: ${weights.bold};
  }

  position: sticky;
  top: ${({ hasMessage, top }) => `calc(${hasMessage ? '6.25rem' : '3.75rem'} + ${top})`};
  background: ${colors.white};
  z-index: 1;
`;

export default function GroupRow(props) {
  const { hasMessage } = useWorkspace();

  return <Group {...props} hasMessage={hasMessage} />;
}
