import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post } from './agent';

const projectRoles =
  (projectRolesUrl) =>
  (id = '') => {
    const url = _([projectRolesUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat(`/has-dependencies`)),
      replace: (body) => post(url.concat('/replace'), body),
    };
  };

export default projectRoles;
