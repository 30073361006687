import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSession, useWorkspace, useApi } from '~/contexts';
import { TooltipButton } from '~/components';
import { useActions, useDocumentTitle } from '~/hooks';
import { colors, devices, weights } from '~/styles';
import { ErrorPage, PageLoader } from '../pages';
import { Footer, Header, ResponsiveContainer } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.mobile} {
    padding-top: 2.625rem;
    padding-bottom: 3.125rem;
  }

  @media ${devices.tablet} {
    padding: 4.375rem 0;
  }

  @media ${devices.desktop} {
    padding: 4rem 12.75rem;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 1.2;

  @media ${devices.mobile} {
    font-size: 1.875rem;
  }
`;

const Description = styled.p`
  margin-top: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.6;

  @media ${devices.mobile} {
    font-size: 1.125rem;
  }
`;

const SlackInstance = styled.p`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  line-height: 1.6;

  @media ${devices.mobile} {
    font-size: 1.125rem;
  }
`;

const SlackImage = styled.img`
  margin-right: 0.5rem;
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.4375rem -0.625rem;
  margin-top: 1.3125rem;
`;

const ActionLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  margin: 0.4375rem 0.625rem;
  padding: 0 2em;
  color: ${colors.white};
  font-size: 1.25rem;
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

const Workspaces = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.6;
  border-top: solid 1px ${colors.grey10};

  @media ${devices.mobile} {
    font-size: 1.125rem;
  }
`;

const Workspace = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: solid 1px ${colors.grey10};
`;

const WorkspaceIdentity = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

const WorkspaceImage = styled.img`
  margin-right: 0.5rem;
`;

function Authenticate() {
  const location = useLocation();
  const redirectUrl = encodeURIComponent(`${location.pathname}${location.search}`);

  return (
    <>
      <Header />
      <ResponsiveContainer>
        <Container>
          <Title>Slack Integration</Title>
          <Description>Log in or create an account to integrate Slack with Ruddr.</Description>
          <Actions>
            <ActionLink to={`/login?redirect=${redirectUrl}`}>Login</ActionLink>
            <ActionLink to={`/signup?redirect=${redirectUrl}`}>Sign Up</ActionLink>
          </Actions>
        </Container>
      </ResponsiveContainer>
      <Footer />
    </>
  );
}

const workspacesInitialState = {
  workspaces: [],
  isWorkspacesReady: false,
};
const workspacesHandlers = {
  workspacesReady: (data) => ({ workspaces: data, isWorkspacesReady: true }),
};
const selectWorkspaceInitialState = {
  isSubmitting: false,
  error: null,
};
const selectWorkspaceHandlers = {
  submit: () => ({ isSubmitting: true, error: null }),
  done: () => ({ isSubmitting: false }),
  error: (error) => ({ isSubmitting: false, error }),
};
function SelectWorkspace({ info, token }) {
  const api = useApi();
  const { navigateWorkspace } = useWorkspace();
  const [{ workspaces, isWorkspacesReady }, workspacesActions] = useActions(workspacesHandlers, workspacesInitialState);
  const [{ isSubmitting, error }, selectActions] = useActions(selectWorkspaceHandlers, selectWorkspaceInitialState);

  const fetchWorkspaces = useCallback(async () => {
    try {
      const { data } = await api.slack.getWorkspaces();
      workspacesActions.workspacesReady(data);
    } catch (error) {
      workspacesActions.workspacesReady([]);
    }
  }, [workspacesActions, api]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  async function handleSelect(workspaceId) {
    selectActions.submit();

    try {
      const { data } = await api.slack.patchAuthorization({ token, workspaceId });
      if (data.isConnected) {
        navigateWorkspace(data.workspaceKey, '/settings/integrations/slack');
      } else {
        selectActions.done();
      }
    } catch (error) {
      selectActions.error(error);
    }
  }

  if (!isWorkspacesReady) return <PageLoader />;
  if (!isSubmitting && error) return <ErrorPage />;
  return (
    <>
      <Header />
      <ResponsiveContainer>
        <Container>
          <Title>Slack Registration</Title>
          <SlackInstance>
            {info.slackIcon && <SlackImage src={info.slackIcon} alt={info.slackName} width="36" height="36" />}
            {info.slackName}
          </SlackInstance>
          <Description>Select your Ruddr workspace.</Description>
          <Workspaces>
            {workspaces.map((workspace) => (
              <Workspace key={workspace.id}>
                <WorkspaceIdentity>
                  {workspace.imageUrl && (
                    <WorkspaceImage src={workspace.imageUrl} alt={workspace.name} width="36" height="36" />
                  )}
                  {workspace.name}
                </WorkspaceIdentity>
                <TooltipButton
                  onClick={handleSelect.bind(this, workspace.id)}
                  disabled={!workspace.canManageWorkspace || isSubmitting}
                  tooltip={
                    !workspace.canManageWorkspace
                      ? 'You do not have the right permissions to manage this workspace.'
                      : undefined
                  }>
                  Select
                </TooltipButton>
              </Workspace>
            ))}
          </Workspaces>
        </Container>
      </ResponsiveContainer>
      <Footer />
    </>
  );
}

const registrationInitialState = {
  data: {},
  error: null,
  isReady: false,
};
const registrationHandlers = {
  ready: (data) => ({ data, isReady: true }),
  error: (error) => ({ error, data: {}, isReady: true }),
};
export default function () {
  useDocumentTitle('Slack Registration');

  const api = useApi();
  const history = useHistory();
  const { isReady: isSessionReady, session } = useSession();
  const { workspace, navigateWorkspace } = useWorkspace();
  const [{ data, error, isReady }, actions] = useActions(registrationHandlers, registrationInitialState);
  const redirecting = useMemo(() => Boolean(isReady && session && data && data.isConnected), [isReady, data, session]);

  const { token } = useParams();

  const fetchTokenData = useCallback(async () => {
    try {
      const { data } = await api.slack.getAuthorization({ token });
      actions.ready(data);
    } catch (error) {
      actions.error(error);
    }
  }, [actions, token, api]);

  useEffect(() => {
    if (session) {
      fetchTokenData();
    }
  }, [fetchTokenData, session]);

  useEffect(() => {
    if (!redirecting) {
      return;
    }
    if (data.isWorkspaceMember) {
      navigateWorkspace(data.workspaceKey, 'settings/integrations/slack');
    } else if (workspace) {
      navigateWorkspace(workspace.key, 'settings/integrations?error=slack-in-use');
    } else {
      history.push('/');
    }
  }, [redirecting, data, workspace, navigateWorkspace, history]);

  if (!isSessionReady || redirecting) return <PageLoader />;
  if (!session) return <Authenticate />;
  if (!isReady) return <PageLoader />;
  if (error) return <ErrorPage />;
  return <SelectWorkspace info={data} token={token} />;
}
