import _ from 'lodash';
import React from 'react';
import PracticeTag from './PracticeTag';
import ProjectAdminTag from './ProjectAdminTag';
import ProjectBillingTypeTag from './ProjectBillingTypeTag';
import ProjectRecordStatusTag from './ProjectRecordStatusTag';
import ProjectRoleTag from './ProjectRoleTag';
import ProjectSalesRepresentativeTag from './ProjectSalesRepresentativeTag';
import ProjectStatusTag from './ProjectStatusTag';
import ProjectTag from './ProjectTag';
import ProjectTags from './ProjectTags';
import ProjectTypeTag from './ProjectTypeTag';
import ProjectBudgetHealthTag from './ProjectBudgetHealthTag';
import ProjectScheduleHealthTag from './ProjectScheduleHealthTag';
import ProjectClientSatisfactionTag from './ProjectClientSatisfactionTag';
import ProjectTeamSatisfactionTag from './ProjectTeamSatisfactionTag';

function ProjectTagsGroup({ filters, onChange }) {
  return (
    <>
      <ProjectTag
        value={filters.projects}
        onRemove={() => {
          const result = { projects: [] };

          if (_.has(filters, 'projectRoles')) result.projectRoles = [];

          if (_.has(filters, 'projectTasks')) result.projectTasks = [];

          onChange(result);
        }}
      />

      <ProjectRoleTag value={filters.projectRoles} onRemove={() => onChange({ projectRoles: [] })} />

      <ProjectBudgetHealthTag
        value={filters.projectBudgetHealth}
        onRemove={() => onChange({ projectBudgetHealth: [] })}
      />

      <ProjectScheduleHealthTag
        value={filters.projectScheduleHealth}
        onRemove={() => onChange({ projectScheduleHealth: [] })}
      />

      <ProjectClientSatisfactionTag
        value={filters.projectClientSatisfaction}
        onRemove={() => onChange({ projectClientSatisfaction: [] })}
      />

      <ProjectTeamSatisfactionTag
        value={filters.projectTeamSatisfaction}
        onRemove={() => onChange({ projectTeamSatisfaction: [] })}
      />

      <ProjectBillingTypeTag
        value={filters.projectBillingTypes}
        onRemove={() => onChange({ projectBillingTypes: [] })}
      />

      <ProjectStatusTag value={filters.projectStatuses} onRemove={() => onChange({ projectStatuses: [] })} />

      <ProjectRecordStatusTag
        value={filters.projectRecordStatusId}
        onRemove={() => onChange({ projectRecordStatusId: null })}
      />

      <ProjectTags value={filters.projectTags} onRemove={() => onChange({ projectTags: [] })} />

      <PracticeTag
        type="project"
        value={filters.projectPractices}
        onRemove={() => onChange({ projectPractices: [] })}
      />

      <ProjectTypeTag value={filters.projectTypes} onRemove={() => onChange({ projectTypes: [] })} />

      <ProjectAdminTag value={filters.projectAdmins} onRemove={() => onChange({ projectAdmins: [] })} />

      <ProjectSalesRepresentativeTag
        type="project"
        value={filters.projectSalesRepresentatives}
        onRemove={() => onChange({ projectSalesRepresentatives: [] })}
      />
    </>
  );
}

export default ProjectTagsGroup;
