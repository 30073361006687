import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  ClientSelect,
  Drawer,
  Field,
  Form,
  FormMessage,
  Level,
  SearchInput,
  SingleSelect,
  Tab,
  Table,
  Tabs,
} from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { colors } from '~/styles';
import _ from 'lodash';
import { PageLoader } from '~/routes/public/pages';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const SetupDescription = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function QBDSetupDrawerGeneralTab() {
  return (
    <div>
      <SetupDescription>
        With this integration installed, the Invoice Detail report in Ruddr can be exported to the Intuit Interchange
        Format (.IIF) and imported into QuickBooks Desktop or Enterprise.
      </SetupDescription>
      <Form.Section title="Settings">
        <Form.Control
          help="To allow an invoice discount to be properly
        exported, provide the exact name of the desired discount account used in QuickBooks Desktop or Enterprise.">
          <Field.Text name="discountAccount" placeholder="Discount Account" maxLength={255} />
        </Form.Control>
        <Form.Control>
          <Field.Checkbox name="defaultProjectsAsSubcustomers" label="Default new projects as sub-customers" />
        </Form.Control>
      </Form.Section>
    </div>
  );
}

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 1rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }

  opacity: ${({ fade }) => (fade ? 0.1 : 1)};
`;

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

function QBDSetupDrawerProjectsTab({ query, setProjects, setQuery, projects, filters, handleFiltersChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleBulkSelectionChange = () => {
    setProjects(
      selected.some
        ? projects.filter(({ id }) => !query.data.some((project) => id === project.id))
        : [...projects, ...query.data.map(({ id }) => ({ id }))],
    );
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).qbd.getProjects({
        clientId: filters.client?.id,
        recordStatusId: filters.recordStatusId,
        q: filters.q,
      });

      setQuery({ status: 'ready', data });
    } catch (error) {
      setQuery({ status: 'ready', data: [] });
    }
  }, [api, workspace, filters, setQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!query.data) return {};

    const projectsById = _.groupBy(projects, 'id');

    return {
      projects: query.data.filter(({ id }) => !!projectsById[id]),

      get some() {
        return this.projects.length > 0;
      },
      get all() {
        return this.projects.length === query.data.length;
      },
    };
  }, [query.data, projects]);

  const projectsById = _.groupBy(projects, 'id');

  return (
    <div>
      <Level>
        <Level.Item flex="1">
          <SearchInput
            value={filters.q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleFiltersChange}
          />
        </Level.Item>
      </Level>
      <Level margin="1rem 0 0 0">
        <Level.Item style={{ flex: 2.3 }}>
          <ClientSelect value={filters.client} name="client" placeholder="Client" onChange={handleFiltersChange} />
        </Level.Item>
        <Level.Item>
          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Project Archived"
            materialAlwaysVisible
            value={filters.recordStatusId}
            showEmptyOption
            onChange={handleFiltersChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>
        </Level.Item>
      </Level>
      <StyledTable>
        <Table.Header>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox
                checked={selected.some || false}
                partial={!selected.all || false}
                onChange={handleBulkSelectionChange}
              />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column width="20rem">Name</Table.Column>
          <Table.Column width="17rem">Client</Table.Column>
          <Table.Column>Status</Table.Column>
        </Table.Header>
        {(() => {
          switch (query.status) {
            case 'loading':
              return <PageLoader style={{ color: colors.grey40, marginTop: '7rem' }} />;
            default:
              return (
                <Table.Body fade={query.status === 'filtering'}>
                  {query.data.map((project) => {
                    const checked = !!projectsById[project.id];

                    const handleSelectionChange = () => {
                      setProjects(
                        checked ? projects.filter(({ id }) => id !== project.id) : [...projects, { id: project.id }],
                      );
                    };

                    return (
                      <Table.Row key={project.id} stretch>
                        <Table.Cell>
                          <StyledCheckbox>
                            <Checkbox checked={checked} onChange={handleSelectionChange} />
                          </StyledCheckbox>
                        </Table.Cell>
                        <Table.Cell>{project.name}</Table.Cell>
                        <Table.Cell>{project.client.name}</Table.Cell>
                        <Table.Cell>{project.recordStatusId === 'active' ? 'Active' : 'Inactive'}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              );
          }
        })()}
      </StyledTable>
    </div>
  );
}

function QBDSetupDrawer({ onSaved, onClose, integration }) {
  const formRef = useRef();
  const toast = useToast();
  const api = useApi();

  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();

  const dirtyCheck = useDirtyCheck(() => {
    let projectsHaveChanged = false;
    if (query.data) {
      const projectsById = _.groupBy(projects, 'id');

      // Select projects that have changed their qbdIsSubcustomer value
      projectsHaveChanged = query.data.some((project) =>
        project.qbdIsSubcustomer ? !projectsById[project.id] : !!projectsById[project.id],
      );
    }

    return formRef.current.dirty || projectsHaveChanged;
  });

  const [query, setQuery] = useState({
    status: 'loading',
    data: null,
  });
  const [filters, setFilters] = useState({
    client: null,
    recordStatusId: 'active',
    q: '',
  });

  const [projects, setProjects] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchSelectedProjects = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).qbd.getSelectedProjects();
    setProjects(data);
  }, [api, workspace, setProjects]);

  useEffect(() => {
    fetchSelectedProjects();
  }, [fetchSelectedProjects]);

  function handleClose() {
    onClose();
  }

  const handleFiltersChange = ({ target }) => {
    setQuery((q) => ({ ...q, status: 'filtering' }));
    setFilters({ ...filters, [target.name]: target.value });
  };

  const initialValues = mergeValues(
    {
      discountAccount: '',
      defaultProjectsAsSubcustomers: false,
    },
    integration.configuration,
  );

  return (
    <Drawer
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      isOpen
      title="QBD Setup"
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSubmit = async (values) => {
          try {
            form.submit();

            const projectsById = _.groupBy(projects, 'id');

            // Select projects that have changed their qbdIsSubcustomer value
            let modifiedProjects = [];
            if (query.data) {
              modifiedProjects = query.data
                .filter((project) =>
                  project.qbdIsSubcustomer ? !projectsById[project.id] : !!projectsById[project.id],
                )
                .map((project) => ({ id: project.id, qbdIsSubcustomer: !project.qbdIsSubcustomer }));
            }

            const settings = emptyStringToNull(values);

            if (integration.isConnected) {
              await api.www.workspaces(workspace.id).qbd.updateSettings({ settings, projects: modifiedProjects });
            } else {
              await api.www.workspaces(workspace.id).qbd.setup({ settings, projects: modifiedProjects });
            }

            onSaved();
            form.done();
            closeDrawer();
          } catch ({ message }) {
            toast.error(message);
          }
        };

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              discountAccount: Yup.string().label('Discount Account').max(255),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <Tab>General</Tab>
                    <Tab>Projects</Tab>
                  </Tabs>
                  <Content>
                    {
                      [
                        <QBDSetupDrawerGeneralTab key="general" />,
                        <QBDSetupDrawerProjectsTab
                          key="projects"
                          query={query}
                          setProjects={setProjects}
                          setQuery={setQuery}
                          projects={projects}
                          filters={filters}
                          handleFiltersChange={handleFiltersChange}
                        />,
                      ][tabIndex]
                    }
                  </Content>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} type="submit" onClick={formik.submit}>
                        {integration.isConnected ? 'Save & Close' : 'Install'}
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default QBDSetupDrawer;
