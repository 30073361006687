import pluralize from 'pluralize';
import { rgba } from 'polished';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Hours, Percent } from '~/components';
import { useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import { Arrow } from '../../components/schedule/allocations/AllocationStyles';

const Container = styled.div`
  max-width: 22rem;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.div`
  width: 11.5rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: nowrap;
`;

const Value = styled.div`
  white-space: nowrap;
`;

function Allocated({ cell }) {
  return (
    <Info>
      <Label>Allocated Hours</Label>
      <Value>
        <Hours value={cell.allocated} minimumFractionDigits={0} />{' '}
        {cell.capacity > 0 && (
          <>
            (<Percent value={cell.allocated / cell.capacity} minimumFractionDigits={0} /> allocated)
          </>
        )}
      </Value>
    </Info>
  );
}

function Capacity({ cell }) {
  if (cell.capacity == null) return null;

  return (
    <Info>
      <Label>Capacity</Label>
      <Value>
        <Hours value={cell.capacity} minimumFractionDigits={0} /> {pluralize('hours', cell.capacity)}
      </Value>
    </Info>
  );
}

function Available({ cell }) {
  if (cell.available == null) return null;

  return (
    <Info>
      <Label>Available Hours</Label>
      <Value>
        <Hours value={cell.available} minimumFractionDigits={0} />{' '}
        {cell.capacity > 0 && (
          <>
            (<Percent value={cell.available / cell.capacity} minimumFractionDigits={0} /> available)
          </>
        )}
      </Value>
    </Info>
  );
}

function Utilization({ cell }) {
  if (cell.utilization == null) return null;

  return (
    <Info>
      <Label>Billable Utilization</Label>
      <Value>
        {!isNaN(cell.utilization) ? <Percent value={cell.utilization} minimumFractionDigits={0} /> : 'N/A'} (
        <Hours value={cell.billable} minimumFractionDigits={0} /> {pluralize('billable hours', cell.billable)})
      </Value>
    </Info>
  );
}

function ProductiveUtilization({ cell }) {
  if (cell.productiveUtilization == null) return null;

  return (
    <Info>
      <Label>Productive Utilization</Label>
      <Value>
        {!isNaN(cell.productiveUtilization) ? (
          <Percent value={cell.productiveUtilization} minimumFractionDigits={0} />
        ) : (
          'N/A'
        )}{' '}
        (
        <Hours value={cell.productive} minimumFractionDigits={0} /> {pluralize('productive hours', cell.productive)})
      </Value>
    </Info>
  );
}

function UtilizationTarget({ cell }) {
  if (cell.targetBillable == null) return null;

  const target = cell.targetBillable / cell.capacity;

  return (
    <Info>
      <Label>Utilization Target</Label>
      <Value>
        {!isNaN(target) ? <Percent value={target} minimumFractionDigits={0} /> : 'N/A'} (
        <Hours value={cell.targetBillable} minimumFractionDigits={0} />{' '}
        {pluralize('billable hours', cell.targetBillable)})
      </Value>
    </Info>
  );
}

function TargetAttainment({ cell }) {
  if (cell.targetAttainment == null) return null;

  return (
    <Info>
      <Label>Target Attainment</Label>
      <Value>
        {!isNaN(cell.targetAttainment) ? <Percent value={cell.targetAttainment} minimumFractionDigits={0} /> : 'N/A'}
      </Value>
    </Info>
  );
}

export default function MyAllocationsTooltipMessage({ cell, referenceElement }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
    placement: 'auto',
    strategy: 'fixed',
  });

  const features = useFeatures();

  if (!cell) return null;

  return ReactDOM.createPortal(
    <CellTooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Container>
        <Capacity cell={cell} />

        <Allocated cell={cell} />

        <Available cell={cell} />

        {features.utilization && (
          <>
            <Utilization cell={cell} />

            <ProductiveUtilization cell={cell} />

            <UtilizationTarget cell={cell} />

            <TargetAttainment cell={cell} />
          </>
        )}
      </Container>

      <Arrow ref={setArrowElement} style={styles.arrow} />
    </CellTooltip>,
    document.body,
  );
}

const CellTooltip = styled.div`
  min-width: 7.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 300;

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`;
