import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ContactCreateForm from './ContactCreateForm';
import ContactsListPage from './ContactsListPage';
import ContactArea from './ContactArea';

export default function ContactsArea() {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleCreate = (contact) => {
    history.push(`${url}/${contact.id}/overview`);
  };

  return (
    <>
      <Switch>
        <Route path={[`${path}/`, `${path}/new`, `${path}/edit/:contactId`]} exact>
          <ContactsListPage />
        </Route>
        <Route path={`${path}/:contactId`}>
          <ContactArea />
        </Route>
      </Switch>
      <Route path={`${path}/new`}>
        <ContactCreateForm
          onSaved={handleCreate}
          onClose={() => {
            history.push({ pathname: url, search: location.search });
          }}
        />
      </Route>
    </>
  );
}
