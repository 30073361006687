import { ActionButton, Buttons, CancelButton, Drawer, Icon, SingleSelect, Table, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm, useIsMounted } from '~/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import Fuse from 'fuse.js';
import { colors } from '~/styles';
import _ from 'lodash';

export default function InvoiceItemMappings({ onClose }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const [{ isSubmitting, saved }, form] = useForm();
  const isMounted = useIsMounted();
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);

  const [{ invoiceItems, items, isReady }, setState] = useState({ invoiceItems: null, isReady: false });

  useEffect(() => {
    (async () => {
      const { data: invoiceItems } = await api.www.workspaces(workspace.id).xero.getInvoiceItems();

      const { data: items } = await api.www.workspaces(workspace.id).xero.getItems();

      if (!isMounted.current) return;
      setState({ invoiceItems, items, isReady: true });
    })();
  }, [api, workspace.id, isMounted]);

  const updateInvoiceItemMapping = ({ invoiceItemId, xeroItemId }) => {
    setDirty(true);

    setState((state) => ({
      ...state,
      invoiceItems: invoiceItems.map((i) => (i.id === invoiceItemId ? { ...i, xeroItemId } : i)),
    }));
  };

  return (
    <Drawer
      isOpen
      title="Invoice Item Mappings"
      width="70rem"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          try {
            const mappings = invoiceItems.map((invoiceItem) => ({
              invoiceItemId: invoiceItem.id,
              xeroItemId: invoiceItem.xeroItemId,
              xeroItemCode: items.find((item) => item.id === invoiceItem.xeroItemId)?.code ?? null,
            }));
            await api.www.workspaces(workspace.id).xero.updateInvoiceItemMappings(mappings);
            form.save();
            closeDrawer();
          } catch (error) {
            toast.error(error.message);
            form.done();
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            <Table style={{ marginBottom: '2rem' }}>
              <Table.BoxHeader>
                <Table.Column width="40%">Invoice Item</Table.Column>

                <Table.Column>
                  <strong>Xero Product/Service</strong>
                </Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {invoiceItems.map((invoiceItem) => {
                  const handleItemChange = (event) => {
                    updateInvoiceItemMapping({
                      invoiceItemId: invoiceItem.id,
                      xeroItemId: event.target.value || null,
                    });
                  };

                  return (
                    <Table.BoxRow key={invoiceItem.id}>
                      <Table.Cell>{invoiceItem.name}</Table.Cell>
                      <Table.Cell>
                        <ItemSelect items={items} value={invoiceItem.xeroItemId} onChange={handleItemChange} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>

            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}

function ItemSelect({ items, value, ...props }) {
  const [filterValue, setFilterValue] = useState('');

  const options = useMemo(() => {
    if (!filterValue) {
      return items;
    }

    const fuse = new Fuse(items, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(filterValue);
    return results.map((result) => result.item);
  }, [items, filterValue]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const client = _.find(items, { id: value });
    if (!client)
      return {
        id: value,
        name: (
          <Tooltip message="Invoice Item not found in Xero">
            <Icon icon="warning" color={colors.warning} spaceRight />
            Invoice Item ID: {value}
          </Tooltip>
        ),
      };
    return client;
  }, [items, value]);

  return (
    <SingleSelect
      showEmptyOption
      showFilter
      value={value}
      valueRenderer={selectedOption?.name ?? ''}
      onFilterChange={(event) => setFilterValue(event.target.value)}
      {...props}>
      {options.map((option) => (
        // eslint-disable-next-line react/no-unknown-property
        <option key={option.id} value={option.id} tooltip={option.name}>
          {option.name}
        </option>
      ))}
    </SingleSelect>
  );
}
