import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import HoursVarianceByMemberAndProject from './HoursVarianceByMemberAndProject';
import HoursVarianceByProjectAndResource from './HoursVarianceByProjectAndResource';
import HoursVarianceByProjectAndRole from './HoursVarianceByProjectAndRole';
import VarianceReportsPage from './VarianceReportsPage';

export default function VarianceReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={path} exact>
        <VarianceReportsPage />
      </Route>

      <Route path={path.concat('/hours-variance-by-member-and-project')}>
        {auth.reports.hoursVarianceByMemberAndProject ? (
          <HoursVarianceByMemberAndProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-variance-by-project-and-resource')}>
        {auth.reports.hoursVarianceByProjectAndResource ? (
          <HoursVarianceByProjectAndResource />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-variance-by-project-and-role')}>
        {auth.reports.hoursVarianceByProjectAndRole ? (
          <HoursVarianceByProjectAndRole />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}
