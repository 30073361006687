import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useApi, useWorkspace } from '~/contexts';

export default function useHeaderMetrics({ headers, projects, start, end, unit, allocations, onFetched }) {
  const viewed = useRef([]);

  const { workspace } = useWorkspace();
  const api = useApi();

  const fetchData = useCallback(
    async ({ start, end, unit, metric, projectIds, allocationIds }) => {
      if (projectIds.length === 0) return;

      const { data: metrics } = await api.www.workspaces(workspace.id).allocations().projectSchedule.metrics.headers({
        start,
        end,
        unit,
        metric,
        projectIds,
        allocationIds,
      });

      if (metrics.length === 0) return;

      onFetched(metrics);
    },
    [api, workspace.id, onFetched],
  );

  const load = useCallback(
    ({ start, end, unit, allocations }) => {
      const headerIds = headers.map((h) => h.id);
      if (headerIds.length > 0) {
        const projectIds = projects.filter((r) => !!r.project).map((r) => r.id);
        const allocationIds = (allocations ?? []).map((a) => a.id);
        fetchData({ start, end, unit, allocationIds, projectIds });
      }
      viewed.current = headerIds;
    },
    [headers, fetchData, projects],
  );

  const initialize = useCallback(() => {
    viewed.current = [];
  }, []);

  useEffect(() => {
    const visibleHeaders = headers.filter((r) => r.id === 'total').map((r) => r.id);
    const headerIds = visibleHeaders.filter((g) => !viewed.current.includes(g));
    if (headerIds.length === 0) return;
    viewed.current = viewed.current.concat(headerIds);
    const projectIds = projects.filter((r) => !!r.project).map((r) => r.id);
    const allocationIds = (allocations ?? []).map((a) => a.id);
    fetchData({ start, end, unit, allocationIds, projectIds });
  }, [fetchData, start, end, unit, allocations, headers, projects]);

  return useMemo(() => ({ load, initialize }), [load, initialize]);
}
